import Form from '@bfly/ui2/Form';
import ItemGrid from '@bfly/ui2/ItemGrid';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import isLastPublicArchive from '../utils/isLastPublicArchive';
import ArchivePrivacyControl from './ArchivePrivacyControl';
import { ArchivePrivacyFormMutation } from './__generated__/ArchivePrivacyFormMutation.graphql';
import { ArchivePrivacyForm_archive$data as Archive } from './__generated__/ArchivePrivacyForm_archive.graphql';
import { ArchivePrivacyForm_organization$data as Organization } from './__generated__/ArchivePrivacyForm_organization.graphql';

interface Props {
  archive: Archive;
  organization: Organization;
}

function ArchivePrivacyForm({ archive, organization }: Props) {
  const toast = useToast();

  const [setArchivePrivacy, loading] =
    useMutationWithError<ArchivePrivacyFormMutation>(
      graphql`
        mutation ArchivePrivacyFormMutation($input: SetArchivePrivacyInput!) {
          setArchivePrivacyOrError(input: $input) {
            ... on SetArchivePrivacyPayload {
              archive {
                isPrivate
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `,
      {
        onError: (e) => {
          toast.error(
            <FormattedMessage
              id="archiveSettings.notifications.toggle.failure"
              defaultMessage="An error has occurred"
            />,
          );
          throw e;
        },
      },
    );

  const handleSelect = useCallback(
    (nextIsPrivate) => {
      setArchivePrivacy({
        input: {
          archiveId: archive.id,
          isPrivate: nextIsPrivate,
        },
      });
    },
    [archive.id, setArchivePrivacy],
  );

  const isOnlyPublicArchive = isLastPublicArchive(archive, organization);

  const disabled = loading || isOnlyPublicArchive;

  return (
    <Form.FieldSet
      className="mb-6"
      legend={
        <FormattedMessage
          id="archiveSettings.permissions"
          defaultMessage="Permissions"
        />
      }
    >
      {isOnlyPublicArchive && (
        <FormattedMessage
          id="archiveSettings.permissions.mustHavePublicArchive"
          defaultMessage="At least one archive in your organization must be public."
        />
      )}
      <ItemGrid
        role="list"
        templateColumns="minmax(0, 1fr) auto"
        data-bni-id="ArchivePrivacyFieldSet"
      >
        <ArchivePrivacyControl
          name="archive-privacy"
          archive={archive}
          isPrivate={false}
          disabled={disabled}
          onSelect={handleSelect}
        />
        <ArchivePrivacyControl
          name="archive-privacy"
          archive={archive}
          isPrivate
          disabled={disabled}
          onSelect={handleSelect}
        />
      </ItemGrid>
    </Form.FieldSet>
  );
}

export default createFragmentContainer(ArchivePrivacyForm, {
  organization: graphql`
    fragment ArchivePrivacyForm_organization on Organization {
      ...isLastPublicArchive_organization
    }
  `,
  archive: graphql`
    fragment ArchivePrivacyForm_archive on Archive {
      id
      isPrivate
      ...ArchivePrivacyControl_archive
      ...isLastPublicArchive_archive
    }
  `,
});
