import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PageTitle from 'components/PageTitle';
import PlusButton from 'components/PlusButton';
import useModalState from 'hooks/useModalState';
import actions from 'messages/actions';

import AddDomainRoleModal from './AddDomainRoleModal';
import { DomainRolesPage_viewer$data as Viewer } from './__generated__/DomainRolesPage_viewer.graphql';

interface Props {
  viewer: Viewer;
  children: React.ReactElement;
}

function DomainRolesPage({ children, viewer }: Props) {
  const [openAddRole, addRoleProps] = useModalState(false);

  return (
    <>
      <Page.Container className="flex-col">
        <Page.Header>
          <PageTitle
            title={defineMessage({
              id: 'domainRolesPage.title',
              defaultMessage: 'Butterfly Access Role',
            })}
          />

          <PlusButton
            data-bni-id="AddDomainRoleButton"
            className="ml-auto"
            onClick={openAddRole}
          >
            <FormattedMessage {...actions.add} />
          </PlusButton>
        </Page.Header>
        <MainContent>{children}</MainContent>
      </Page.Container>
      <AddDomainRoleModal {...addRoleProps} viewer={viewer} />
    </>
  );
}

export default createFragmentContainer(DomainRolesPage, {
  viewer: graphql`
    fragment DomainRolesPage_viewer on Viewer {
      ...AddDomainRoleModal_viewer
    }
  `,
});
