import { Descendant, Text } from 'slate';

function nodeToString(node: Descendant): string {
  if (Text.isText(node)) {
    return node.text;
  }

  if (node.type === 'mention') {
    return `@${node.user.userProfile.name}`;
  }

  return node.children.map((n) => nodeToString(n)).join('');
}

export const getEditorStateAsString = (editorState: Descendant[]) => {
  return editorState.map(nodeToString).join('');
};

export const isEditorStateEmpty = (editorState: Descendant[]) => {
  return !getEditorStateAsString(editorState).trim().length;
};
