import BlankSlate from '@bfly/ui2/BlankSlate';
import useRouter from 'found/useRouter';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useFilteredSelectedItemsByKey } from 'components/MultiSelectContext';
import { StudyListFilter } from 'utils/StudyConstants';

import emptyArchives from '../../assets/blank-archive.svg';
import { EmptyArchiveScreen_organization$data as Organization } from './__generated__/EmptyArchiveScreen_organization.graphql';

interface Props {
  organization: Organization;
}

function EmptyArchiveScreen({ organization }: Props) {
  const {
    match: { location },
  } = useRouter();

  useFilteredSelectedItemsByKey([]);

  if (!organization.hasReadyStudies)
    return (
      <BlankSlate data-bni-id="EmptyArchivePage">
        <BlankSlate.Img src={emptyArchives} />
        <BlankSlate.Title>
          <FormattedMessage
            id="emptyArchive.downloadApp.title"
            defaultMessage="Archive is Empty"
          />
        </BlankSlate.Title>
        <BlankSlate.Body>
          <FormattedMessage
            defaultMessage="To upload studies to the cloud, download the Butterfly iQ App for iOS or Android."
            id="emptyArchive.downloadApp.body.iosOrAndroid"
          />
        </BlankSlate.Body>
      </BlankSlate>
    );

  const { status } = location.query;
  let titleMessage, bodyMessage;
  if (status === StudyListFilter.DRAFTS) {
    titleMessage = (
      <FormattedMessage
        id="emptyArchive.noDrafts.title"
        defaultMessage="No Drafts Found"
      />
    );
    bodyMessage = (
      <FormattedMessage
        id="emptyArchive.noDrafts.body"
        defaultMessage="Drafts can be created and saved from the Butterfly iQ app. Once finalized, drafts will appear as “Complete Studies”."
      />
    );
  } else {
    titleMessage = (
      <FormattedMessage
        id="emptyArchive.noStudies.title"
        defaultMessage="No Studies Found"
      />
    );
    bodyMessage = (
      <FormattedMessage
        id="emptyArchive.noStudies.body"
        defaultMessage="Save and review studies right from your device. Select this archive destination while uploading a study."
      />
    );
  }
  return (
    <BlankSlate data-bni-id="EmptyArchivePage">
      <BlankSlate.Img src={emptyArchives} />
      <BlankSlate.Title>{titleMessage}</BlankSlate.Title>
      <BlankSlate.Body>{bodyMessage}</BlankSlate.Body>
    </BlankSlate>
  );
}

export default createFragmentContainer(EmptyArchiveScreen, {
  organization: graphql`
    fragment EmptyArchiveScreen_organization on Organization {
      hasReadyStudies
    }
  `,
});
