/**
 * @generated SignedSource<<e8f90c61ff961832e92ff7f3c7acfbdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type archive_DeletedStudiesPage_Query$variables = {
  organizationSlug: string;
};
export type archive_DeletedStudiesPage_Query$data = {
  readonly organization: {
    readonly id: string;
  } | null;
};
export type archive_DeletedStudiesPage_Query = {
  response: archive_DeletedStudiesPage_Query$data;
  variables: archive_DeletedStudiesPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "alias": "organization",
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "organizationSlug"
      }
    ],
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "organizationBySlug",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "archive_DeletedStudiesPage_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "archive_DeletedStudiesPage_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9024d5c1b06ee777b64cf7f4acb07f40",
    "id": null,
    "metadata": {},
    "name": "archive_DeletedStudiesPage_Query",
    "operationKind": "query",
    "text": "query archive_DeletedStudiesPage_Query(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae6305b47e0e8fc00fad55a36dec41a8";

export default node;
