import useMountEffect from '@restart/hooks/useMountEffect';
import { useRef } from 'react';

export default function useObserver(
  getTarget: () => Node | null | undefined,
  callback: MutationCallback,
  options?: MutationObserverInit,
) {
  const observer = useRef<MutationObserver>();
  useMountEffect(() => {
    return () => observer.current?.disconnect();
  });

  return () => {
    if (observer.current) observer.current?.disconnect();
    const target = getTarget();
    if (!target) return;
    const nextObserver = new MutationObserver(callback);
    nextObserver.observe(target, options);
    observer.current = nextObserver;
  };
}
