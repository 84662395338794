/**
 * @generated SignedSource<<969f250d2421b5dd19639f492dd72b4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DayOfWeekType = "FRIDAY" | "MONDAY" | "SATURDAY" | "SUNDAY" | "THURSDAY" | "TUESDAY" | "WEDNESDAY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type workflowDigests_schedule$data = {
  readonly daysOfWeek: ReadonlyArray<DayOfWeekType | null>;
  readonly time: any | null;
  readonly timezone: any | null;
  readonly " $fragmentType": "workflowDigests_schedule";
};
export type workflowDigests_schedule$key = {
  readonly " $data"?: workflowDigests_schedule$data;
  readonly " $fragmentSpreads": FragmentRefs<"workflowDigests_schedule">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "workflowDigests_schedule"
};

(node as any).hash = "5254db21f42beb133af322a26fe3d755";

export default node;
