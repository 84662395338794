import Button, { ButtonProps } from '@bfly/ui2/Button';
import clsx from 'clsx';
import { Ref, forwardRef } from 'react';

const PromptButton = forwardRef(
  (
    {
      disabled,
      children,
      className,
      invalid,
      ...props
    }: ButtonProps & { invalid?: boolean },
    ref: Ref<Element>,
  ) => {
    return (
      <Button
        {...props}
        ref={ref}
        size="lg"
        variant={null}
        className={clsx(
          'bg-contrast bg-opacity-10 border-2',
          !disabled
            ? 'hover:text-opacity-60 active:text-opacity-50 hover:filter-none active:filter-none'
            : 'disabled:bg-opacity-5 disabled:text-contrast/50 disabled:filter-none',
          invalid ? 'border-red-40' : 'border-blue',
          'text-contrast',
          className || 'px-2',
        )}
        disabled={disabled}
      >
        {children}
      </Button>
    );
  },
);

export default PromptButton;
