import React from 'react';
import { FormattedMessage } from 'react-intl';

import DeviceTooSmallSuccessPage from './DeviceTooSmallSuccessPage';

function InviteAcceptedPage() {
  return (
    <DeviceTooSmallSuccessPage
      title={
        <FormattedMessage
          id="inviteAccepted.title"
          defaultMessage="Invite Accepted"
        />
      }
    />
  );
}

export default InviteAcceptedPage;
