import ListIcon from '@bfly/icons/List';
import TilesIcon from '@bfly/icons/Tiles';
import Button from '@bfly/ui2/Button';
import SrOnly from '@bfly/ui2/SrOnly';
import { FormattedMessage } from 'react-intl';

import { StudyView } from 'hooks/usePreferredStudyListView';

interface Props {
  view: StudyView;
  onToggleView: (view: StudyView) => void;
}

function StudyListViewToggleButton({ view, onToggleView }: Props) {
  return (
    <Button
      variant={null}
      aria-pressed={view === 'grid'}
      data-bni-id="ToggleStudyViewButton"
      onClick={() => onToggleView(view === 'grid' ? 'list' : 'grid')}
      className="h-8 w-10 px-0 text-white hover:bg-white/10 self-center"
    >
      {view === 'grid' && <TilesIcon height="18" />}
      {view === 'list' && <ListIcon height="20" />}
      <SrOnly>
        <FormattedMessage
          id="studyListViewToggleButton.toggle"
          defaultMessage="Toggle study list view"
        />
      </SrOnly>
    </Button>
  );
}

export default StudyListViewToggleButton;
