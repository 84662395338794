/**
 * @generated SignedSource<<e4e28184a5fff1e22e1aa91ca5917400>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type archiveSchema_organization$data = {
  readonly archiveConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly label: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly deletedArchiveConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly label: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "archiveSchema_organization";
};
export type archiveSchema_organization$key = {
  readonly " $data"?: archiveSchema_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"archiveSchema_organization">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "archiveSchema_organization"
};

(node as any).hash = "524c891797fba5e06ff17fbc21d3218f";

export default node;
