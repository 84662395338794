/**
 * @generated SignedSource<<7dac9ad33aa45bfc9209669ae50f2be0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyImageDownloadControl_image$data = {
  readonly burnedInFile: {
    readonly dataUrl: string | null;
  } | null;
  readonly study: {
    readonly finalizedAt: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImageDownloadModal_image">;
  readonly " $fragmentType": "StudyImageDownloadControl_image";
};
export type StudyImageDownloadControl_image$key = {
  readonly " $data"?: StudyImageDownloadControl_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImageDownloadControl_image">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyImageDownloadControl_image",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyImageDownloadModal_image"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BurnedInFile",
      "kind": "LinkedField",
      "name": "burnedInFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Study",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyImage",
  "abstractKey": null
};

(node as any).hash = "da0ba5835fa0f220ada7b806fa474557";

export default node;
