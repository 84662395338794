import TabNav from '@bfly/ui2/TabNav';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import { useAdminRoutes } from 'routes/admin';

interface Props {
  className?: string;
}

function EnterpriseAdminTabNav({ className }: Props) {
  const domainRoutes = useAdminRoutes({ organizationSlug: null });
  return (
    <TabNav className={clsx(className, 'mb-50')}>
      <TabNav.LinkItem
        to={domainRoutes.domainMembersActive()}
        data-bni-id="ActiveUsersTab"
      >
        <FormattedMessage
          id="enterprise.settings.menu.activeUsers"
          defaultMessage="Active Users"
        />
      </TabNav.LinkItem>
      <TabNav.LinkItem
        to={domainRoutes.domainMembersInactive()}
        data-bni-id="InactiveUsersTab"
      >
        <FormattedMessage
          id="enterprise.settings.menu.inactiveUsers"
          defaultMessage="Inactive Users"
        />
      </TabNav.LinkItem>
    </TabNav>
  );
}

export default EnterpriseAdminTabNav;
