import Layout from '@4c/layout';
import FailureIcon from '@bfly/icons/Failure';
import Button from '@bfly/ui2/Button';
import FieldSet from '@bfly/ui2/FieldSet';
import Text from '@bfly/ui2/Text';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import useRouter from 'found/useRouter';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useAdminRoutes } from 'routes/admin';

import { DomainRoleDeleteFieldSet_membershipRole$data as MembershipRole } from './__generated__/DomainRoleDeleteFieldSet_membershipRole.graphql';
import { DomainRoleDeleteFieldSet_viewer$data as Viewer } from './__generated__/DomainRoleDeleteFieldSet_viewer.graphql';

const mutation = graphql`
  mutation DomainRoleDeleteFieldSetMutation(
    $input: DeleteMembershipRoleInput!
  ) {
    deleteMembershipRoleOrError(input: $input) {
      ... on DeleteMembershipRolePayload {
        deletedId
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface Props {
  membershipRole: MembershipRole;
  viewer: Viewer;
}

function DomainRoleDeleteFieldSet({ membershipRole, viewer }: Props) {
  const toast = useToast();
  const { router } = useRouter();
  const domainRoutes = useAdminRoutes({ organizationSlug: null });

  const [attemptedDeleteOccupiedRole, setAttemptedDeleteOccupiedRole] =
    useState(false);

  const [deleteRole, loading] = useMutationWithError(mutation, {
    input: {
      membershipRoleId: membershipRole.id,
    },
    onError: (e: any) => {
      if (e.errorType === 'CannotDeleteOccupiedRoleError') {
        setAttemptedDeleteOccupiedRole(true);
      }
      toast.error(
        <FormattedMessage
          id="domainRoleDeleteFieldSet.delete.error"
          defaultMessage="Failed to delete {roleName}."
          values={{
            roleName: membershipRole.name,
          }}
        />,
      );
    },
    onCompleted: () => {
      toast.success(
        <FormattedMessage
          id="domainRoleDeleteFieldSet.delete.success"
          defaultMessage="{roleName} was successfully deleted."
          values={{
            roleName: membershipRole.name,
          }}
        />,
      );
      router.replace(domainRoutes.domainRoles());
    },
    updater: (store) => {
      rangeDeleteUpdater(store, {
        parentId: viewer!.domain!.id,
        connectionKey: 'MembershipRoleConnection',
        deletedId: membershipRole.id,
      });
    },
  });

  return (
    <FieldSet
      legend={
        <FormattedMessage
          id="domainRoleDeleteFieldSet.delete.header"
          defaultMessage="Delete Access Role"
        />
      }
    >
      <Layout justify="space-between" align="center">
        {!attemptedDeleteOccupiedRole ? (
          <FormattedMessage
            id="domainRoleDeleteFieldSet.delete.warning"
            defaultMessage="This action will delete the role and all of its contents."
          />
        ) : (
          <Layout>
            <FailureIcon className="mr-1 text-danger" css="width: 1.5rem" />
            <Text color="danger">
              <FormattedMessage
                id="domainRoleDeleteFieldSet.delete.occupiedRoleError"
                defaultMessage="There are active users with this Butterfly Access Role. 
                You cannot delete until no active users have this role."
              />
            </Text>
          </Layout>
        )}
        <Button
          onClick={() => deleteRole()}
          disabled={loading || attemptedDeleteOccupiedRole}
          variant="danger"
        >
          <FormattedMessage
            id="domainRoleDeleteFieldSet.delete.control"
            defaultMessage="Delete Butterfly Access Role"
          />
        </Button>
      </Layout>
    </FieldSet>
  );
}

export default createFragmentContainer(DomainRoleDeleteFieldSet, {
  viewer: graphql`
    fragment DomainRoleDeleteFieldSet_viewer on Viewer {
      domain {
        id
      }
    }
  `,
  membershipRole: graphql`
    fragment DomainRoleDeleteFieldSet_membershipRole on MembershipRole {
      id
      name
    }
  `,
});
