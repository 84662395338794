/* eslint-disable relay/must-colocate-fragment-spreads */
import { graphql, usePagination } from 'relay-hooks';

import {
  SearchStudyPaginationQuery,
  StudySorting,
} from './__generated__/SearchStudyPaginationQuery.graphql';
import { StudyPagination_search_organization$key as SearchOrganizationKey } from './__generated__/StudyPagination_search_organization.graphql';

export type StudySortArgument = StudySorting;

export function useSearchStudyPagination(
  organizationRef: SearchOrganizationKey,
) {
  return usePagination<SearchStudyPaginationQuery, SearchOrganizationKey>(
    graphql`
      fragment StudyPagination_search_organization on Organization
      @argumentDefinitions(
        cursor: { type: "String" }
        isDeleted: { type: "[Boolean!]", defaultValue: [false] }
      )
      @refetchable(queryName: "SearchStudyPaginationQuery") {
        studyConnection(
          first: $pageSize
          after: $cursor
          createdByHandle: $userHandle
          primaryAuthor: $primaryAuthor
          archiveHandle: $archiveHandle
          tagHandles: $studyTagHandles
          examTypeHandles: $examTypes
          search: $search
          sort: $sort
          isDraft: $isDraft
          needsReview: $needsReview
          hasRequestedFinalization: $hasRequestedFinalization
          hasRequestedFinalizationFromViewer: $hasRequestedFinalizationFromViewer
          readyAtMin: $readyAtMin
          readyAtMax: $readyAtMax
          isDeleted: $isDeleted
          workflowStatus: $workflowStatus
        ) @connection(key: "Organization_studyConnection") {
          edges {
            ...StudyListContent_studies @arguments(showArchive: true)
          }
        }
      }
    `,
    organizationRef,
  );
}
