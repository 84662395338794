/**
 * @generated SignedSource<<25943f144a3f885323c43b6ca60c3885>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type studyTagsPageQuery$variables = {
  organizationSlug?: string | null;
};
export type studyTagsPageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_tenant" | "StudyTagPage_tenant">;
  } | null;
};
export type studyTagsPageQuery = {
  response: studyTagsPageQuery$data;
  variables: studyTagsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdomainLabel",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "studyTagsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StudyTagPage_tenant"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "RouteAccessControl_tenant",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "studyTagsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "StudyTagConnection",
            "kind": "LinkedField",
            "name": "studyTagConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyTagEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyTag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numStudies",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "studyTagConnection(first:50)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Tenant_studyTagConnection",
            "kind": "LinkedHandle",
            "name": "studyTagConnection"
          },
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "organizationId",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Organization",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "domainId",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Domain",
                "abstractKey": null
              }
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8cbf47d032a028c10ac9267a5d073bf",
    "id": null,
    "metadata": {},
    "name": "studyTagsPageQuery",
    "operationKind": "query",
    "text": "query studyTagsPageQuery(\n  $organizationSlug: String\n) {\n  tenant(slug: $organizationSlug) {\n    __typename\n    ...StudyTagPage_tenant\n    ...RouteAccessControl_tenant\n    id\n  }\n}\n\nfragment RouteAccessControl_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    subdomainLabel\n  }\n  ... on Organization {\n    slug\n  }\n}\n\nfragment StudyTagPage_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ... on Organization {\n    slug\n  }\n  studyTagConnection(first: 50) {\n    edges {\n      node {\n        id\n        name\n        numStudies\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ...useTenantIdInput_tenant\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cafe002151d50fc9ba1aa6906345e686";

export default node;
