/**
 * @generated SignedSource<<7b22aeeba07eb904eda8da5945a1b9b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppLeadingNav_viewer$data = {
  readonly domain: {
    readonly " $fragmentSpreads": FragmentRefs<"AppSearchGlobal_tenant">;
  } | null;
  readonly " $fragmentType": "AppLeadingNav_viewer";
};
export type AppLeadingNav_viewer$key = {
  readonly " $data"?: AppLeadingNav_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppLeadingNav_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppLeadingNav_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AppSearchGlobal_tenant"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "7d27c6315b1b6319fa968c27f428efe1";

export default node;
