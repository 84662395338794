import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ModalityWorklistItemPhysicianInformation from './ModalityWorklistItemPhysicianInformation';
import WorklistItemDicomDocumentListItem from './WorklistItemDicomDocumentListItem';
import { WorklistItemDicomDocument_worklistItem$data as WorklistItem } from './__generated__/WorklistItemDicomDocument_worklistItem.graphql';

interface Props {
  worklistItem: WorklistItem;
}

function WorklistItemDicomDocument({ worklistItem }: Props) {
  const {
    typename,
    studyDescription,
    visitNumber,
    visitLocation,
    admittedAt,
  } = worklistItem;

  return (
    // TODO: check if list none is needed
    <ul className="list-none my-1 mx-0">
      <WorklistItemDicomDocumentListItem>
        <FormattedMessage
          id="worklistItem.visitLocation"
          defaultMessage="Encounter Location: {value}"
          values={{ value: visitLocation }}
        />
      </WorklistItemDicomDocumentListItem>
      {studyDescription && (
        <WorklistItemDicomDocumentListItem>
          <FormattedMessage
            id="worklistItem.procedureDescription"
            defaultMessage="Procedure Description: {value}"
            values={{ value: studyDescription }}
          />
        </WorklistItemDicomDocumentListItem>
      )}
      {visitNumber && (
        <WorklistItemDicomDocumentListItem>
          <FormattedMessage
            id="worklistItem.visitNumber"
            defaultMessage="Encounter Number: {value}"
            values={{ value: visitNumber }}
          />
        </WorklistItemDicomDocumentListItem>
      )}
      {admittedAt && (
        <WorklistItemDicomDocumentListItem>
          <FormattedMessage
            id="worklistItem.encounterDate"
            defaultMessage="Encounter Date: {value}"
            values={{
              value: <FormattedDateTime value={admittedAt} />,
            }}
          />
        </WorklistItemDicomDocumentListItem>
      )}
      {typename === 'ModalityWorklistItem' && (
        <ModalityWorklistItemPhysicianInformation
          worklistItem={worklistItem}
        />
      )}
    </ul>
  );
}

export default createFragmentContainer(WorklistItemDicomDocument, {
  worklistItem: graphql`
    fragment WorklistItemDicomDocument_worklistItem on WorklistItemInterface {
      typename: __typename
      studyDescription
      ... on ModalityWorklistItem {
        ...ModalityWorklistItemPhysicianInformation_worklistItem
      }
      ... on EncounterBasedWorklistItem {
        visitNumber
        visitLocation
        admittedAt
      }
    }
  `,
});
