import notNullish from '@bfly/utils/notNullish';
import { createFragmentContainer, graphql } from 'react-relay';

import ExamAuthorsField from 'components/ExamAuthorsField';
import { useVariation } from 'components/LaunchDarklyContext';
import useExamAuthorMutations from 'mutations/ExamAuthorMutations';
import Analytics, { AnalyticsEvent } from 'utils/Analytics';

import { ExamAuthorsRefresh_study$data as Study } from './__generated__/ExamAuthorsRefresh_study.graphql';

interface Props {
  study: Study;
  id?: string;
}

function ExamAuthorsRefresh({ study }: Props) {
  const studyId = study.id;
  const archiveId = study.archive!.id;
  const organizationId = study.organization!.id;
  const hasPrimaryAuthor = !!study?.createdBy;

  const {
    removeAuthor,
    setPrimaryAuthor,
    setScribe,
    unsetScribe,
    addAuthor,
    replaceAuthor,
    loading,
  } = useExamAuthorMutations(organizationId, studyId);

  const allowMultipleAuthorControls = useVariation('multiple-study-authors');

  const analyticsTrack = (userId: string, event: AnalyticsEvent) =>
    Analytics.track(event, {
      studyId,
      organizationId,
      userId,
    });

  return (
    <ExamAuthorsField
      archiveId={archiveId}
      organizationId={organizationId}
      studyId={studyId}
      loading={loading}
      value={{
        createdBy: study.createdBy?.id || undefined,
        secondaryAuthors: study.secondaryAuthors?.map((a) => a!.id!) || [],
        transcribedBy: study.transcribedBy?.id || undefined,
      }}
      onChangeAction={(user, action) => {
        const userId = user.id;
        const input = { authorUserId: userId, studyId: study.id };

        if (action === 'removeAuthor') {
          removeAuthor({ input });
          analyticsTrack(userId, 'authorFieldAuthorRemoved');
        }
        if (action === 'select') {
          if (allowMultipleAuthorControls || !hasPrimaryAuthor) {
            addAuthor({ input });
            analyticsTrack(userId, 'authorFieldAuthorAdded');
          } else {
            replaceAuthor({ input });
            analyticsTrack(userId, 'authorFieldAuthorReplaced');
          }
        }
        if (action === 'setPrimaryAuthor') {
          setPrimaryAuthor({ input });
          analyticsTrack(userId, 'authorFieldPrimaryAuthorSet');
        }
        if (action === 'toggleScribe') {
          if (user.id !== study.transcribedBy?.id) {
            setScribe({ input });
            analyticsTrack(userId, 'authorFieldScribeSet');
          } else {
            unsetScribe({ input });
            analyticsTrack(userId, 'authorFieldScribeUnset');
          }
        }
      }}
      readOnly={!!study.finalizedAt}
      users={[study.createdBy, ...(study.secondaryAuthors || [])].filter(
        notNullish,
      )}
      showDefaultUser
    />
  );
}

export default createFragmentContainer(ExamAuthorsRefresh, {
  study: graphql`
    fragment ExamAuthorsRefresh_study on Study {
      id
      archive {
        id
      }
      organization {
        id
      }
      finalizedAt
      createdBy {
        id
        ...ExamAuthorsField_users
      }
      secondaryAuthors {
        id
        ...ExamAuthorsField_users
      }
      transcribedBy {
        id
        ...ExamAuthorsField_users
      }
    }
  `,
});
