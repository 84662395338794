import ExpandIcon from '@bfly/icons/Expand';
import GridIcon from '@bfly/icons/Grid';
import ScreenCollapseIcon from '@bfly/icons/ScreenCollapse';
import ScreenExpandIcon from '@bfly/icons/ScreenExpand';
import Button from '@bfly/ui2/Button';
import Link from '@bfly/ui2/Link';
import SrOnly from '@bfly/ui2/SrOnly';
import { useRouter } from 'found';
import { FormattedMessage } from 'react-intl';

import { useActiveRouteWithState } from 'components/isRouteActive';

function ExamImageOverlayControls({
  examGallery,
  examImage,
  exam,
  hideGalleryButton,
}: {
  exam: (props: { studyHandle: string }) => string;
  examGallery: (props: { studyHandle: string }) => string;
  examImage: (props: {
    studyImageHandle: string | null;
    studyHandle: string;
  }) => string;
  hideGalleryButton?: boolean;
}) {
  const { router, match } = useRouter();
  const isRouteActive = useActiveRouteWithState({ router, match });
  const { location } = match;

  const { studyHandle } = match.params;

  const studyImageHandle =
    location.query.selected || match.params.studyImageHandle || null;

  const isFullScreen = location.query.fullscreen;

  const galleryUrl = examGallery({ studyHandle });

  const isGallery = isRouteActive({ pathname: galleryUrl });

  const studyLink = studyImageHandle
    ? examImage({ studyHandle, studyImageHandle })
    : exam({
        studyHandle,
      });

  return (
    <>
      {!hideGalleryButton && !isGallery && !isFullScreen && (
        <Link
          data-bni-id="ShowGalleryButton"
          to={{
            ...location,
            pathname: galleryUrl,
            query: studyImageHandle ? { selected: studyImageHandle } : {},
          }}
        >
          {({ active: _, ...linkProps }) => (
            <Button {...linkProps} variant="text-secondary">
              <GridIcon />
              <SrOnly>
                <FormattedMessage
                  id="examImageOverlayControls.showGallery"
                  defaultMessage="Show Gallery"
                />
              </SrOnly>
            </Button>
          )}
        </Link>
      )}
      {isGallery && (
        <Link data-bni-id="ShowImageButton" to={studyLink}>
          {({ active: _, ...linkProps }) => (
            <Button {...linkProps} variant="text-secondary">
              <ExpandIcon />
              <SrOnly>
                <FormattedMessage
                  id="examImageOverlayControls.showImage"
                  defaultMessage="Show Image"
                />
              </SrOnly>
            </Button>
          )}
        </Link>
      )}
      {!isGallery && (
        <Link
          to={{
            ...location,
            query: {
              ...location.query,
              fullscreen: isFullScreen ? undefined : true,
            },
          }}
        >
          {({ active: _, ...linkProps }) => (
            <Button
              {...linkProps}
              variant="text-secondary"
              data-bni-id="ExamFullscreenButton"
            >
              {isFullScreen ? (
                <ScreenCollapseIcon height={16} width={16} />
              ) : (
                <ScreenExpandIcon height={16} width={16} />
              )}
              <SrOnly>
                <FormattedMessage
                  id="examImageOverlayControls.fullscreen"
                  defaultMessage="Full screen"
                />
              </SrOnly>
            </Button>
          )}
        </Link>
      )}
    </>
  );
}

export default ExamImageOverlayControls;
