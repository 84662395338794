import Layout from '@4c/layout';
import PauseIcon from '@bfly/icons/Pause';
import PlayIcon from '@bfly/icons/Play';
import ScreenCollapseIcon from '@bfly/icons/ScreenCollapse';
import ScreenExpandIcon from '@bfly/icons/ScreenExpand';
import Fade from '@bfly/ui2/Fade';
import useGlobalKeyDownListener from '@bfly/ui2/useGlobalKeyDownListener';
import { useRafInterval } from '@restart/hooks';
import useGlobalListener from '@restart/hooks/useGlobalListener';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import useTimeout from '@restart/hooks/useTimeout';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import canUseDOM from 'dom-helpers/canUseDOM';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import IconButton from 'components/IconButton';
import useKeyboardNavListener from 'hooks/useKeyboardNavListener';

// use true for the SSR case to get the correct default UI
const PointerEventsSupported = canUseDOM ? 'PointerEvent' in window : true;

const styles = stylesheet`
  .container {
    overflow: hidden;

    & ::-webkit-media-controls {
      display: none;
    }
  }

  .controls {
    @apply absolute bottom-0 inset-x-0 z-10 p-1;

    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    transform: translateY(100%);
    opacity: 0;

    &:focus-within,
    &.show {
      transform: translateY(0);
      opacity: 1;
    }

    // hide video controls in percy because it's very flaky
    @media only percy {
      visibility: hidden;
    }
  }

  .range {
    --thumb-size: 1.2rem;
    --thumb-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
    
    --track-height: 0.4rem;

    @apply rounded w-full appearance-none bg-transparent p-0 h-5 cursor-pointer;

    &::-moz-focus-outer {
      border: 0;
    }
    
    // Slider Thumb
    &::-webkit-slider-thumb {
      @apply bg-white rounded-full border-white appearance-none;

      width: var(--thumb-size);
      height: var(--thumb-size);
      box-shadow: var(--thumb-shadow);
      margin-top: calc((var(--track-height) - var(--thumb-size)) / 2); // webkit specific
    }
    &::-moz-range-thumb {
      @apply bg-white rounded-full border-white appearance-none;

      width: var(--thumb-size);
      height: var(--thumb-size);
      box-shadow: var(--thumb-shadow);
    }
    &::-ms-thumb {
      @apply bg-white rounded-full border-white appearance-none;

      width: var(--thumb-size);
      height: var(--thumb-size);
      box-shadow: var(--thumb-shadow);
      margin-top: 0; // Edge specific
    }
    // ---
    
    // Slider track
    &::-webkit-slider-runnable-track {
      @apply bg-white/40 w-full rounded-full border-transparent text-transparent cursor-pointer;

      height: var(--track-height);
      // webkit doesn't allow styling the "fill" color so use a linear gradient to mimic it
      background-image: linear-gradient(to right, theme('colors.primary') var(--fill-value, 0%), transparent var(--fill-value, 0%))
    }
    &::-moz-range-track {
      @apply bg-white/40 w-full rounded-full border-transparent text-transparent cursor-pointer;

      height: var(--track-height);
    }
    &::-moz-range-progress {
      @apply rounded-full bg-primary;

      height: var(--track-height);
    }

    &::-ms-track {
      @apply bg-white/40 w-full rounded-full border-transparent text-transparent cursor-pointer;

      height: var(--track-height);
      border-width: calc(var(--thumb-size) / 2);
    }
    &::-ms-fill-lower {
      @apply bg-white/40 rounded-full cursor-pointer bg-primary;

      height: var(--track-height);
    }
    &::-ms-fill-upper {
      @apply bg-white/40 rounded-full cursor-pointer;

      height: var(--track-height);
    }
  }

  .time {
    composes: tabular-nums text-headline px-1 from global;

    // Biotif does support tabular nums completely and we 
    // really need it to work completely here
    font-family: sans-serif;
  }

  .tooltip {
    composes: tabular-nums fixed bg-white shadow-sm z-fixed px-2 py-1 rounded surface-light text-body pointer-events-none from global;

    font-family: sans-serif;
    transform: translateY(10px) scale(0.8);
    transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
    visibility: hidden;

    &:global(.show) {
      visibility: visible;
      transform: translateY(0) scale(1);
    }

    &::before {
      content: '';
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid white;
      bottom: calc(4px * -1);
      height: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 0;
      z-index: 2;
    }
  }
`;

interface Props extends React.ComponentPropsWithoutRef<'video'> {
  fullscreenEnabled?: boolean;
  hideVideoControls?: boolean;
}

function formatTime(timeSeconds: number) {
  const secs = Math.trunc(timeSeconds % 60);
  const mins = Math.trunc((timeSeconds / 60) % 60);
  const hours = Math.trunc((timeSeconds / 60 / 60) % 60);

  let display = `${String(mins).padStart(2, '0')}:${String(secs).padStart(
    2,
    '0',
  )}`;
  if (hours) display = `${String(hours).padStart(21, '0')}:${display}`;
  return display;
}

interface TooltipSpec {
  left: number;
  top: number;
  value: string;
}

function ScrubberTooltip({
  in: inProp,
  positionRef,
}: {
  in: boolean;
  positionRef: React.MutableRefObject<TooltipSpec | null>;
}) {
  const ref = useRef<HTMLDivElement>(null);
  // We pass in a ref because the position changes very rapidly when it updates
  // To reduce the amount of updates we run updates in RAF loop isolated to this component
  const [position, setPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  useRafInterval(() => {
    if (!inProp) return;

    if (positionRef.current) {
      const { left, top } = positionRef.current;
      const box = ref.current!.getBoundingClientRect();

      setPosition({ left: left - box.width / 2, top: top - box.height - 4 });
    } else {
      setPosition(null);
    }
  }, 0);

  return (
    <Fade
      in={inProp}
      onExited={() => {
        positionRef.current = null;
      }}
    >
      <div
        ref={ref}
        style={position ?? { top: 0, left: 0 }}
        className={clsx(styles.tooltip)}
      >
        <time>{positionRef.current?.value}</time>
      </div>
    </Fade>
  );
}

const VideoPlayer = React.forwardRef<HTMLVideoElement, Props>(
  (
    {
      autoPlay,
      className,
      fullscreenEnabled = true,
      hideVideoControls,
      ...props
    },
    outerRef,
  ) => {
    const showTimer = useTimeout();
    const controlsTimer = useTimeout();
    const rootRef = useRef<HTMLDivElement>(null);
    const ref = useRef<HTMLVideoElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const positionRef = useRef<TooltipSpec | null>(null);

    const [time, setTime] = useState(0);
    const [duration, setDuration] = useState<number | null>(null);
    const [playing, setPlaying] = useState(autoPlay);
    const [fullscreen, setFullscreen] = useState(false);
    const [showControls, setShowControls] = useState(
      !PointerEventsSupported && !hideVideoControls,
    ); // hide if pointer events are supported
    const [showTooltip, setShowTooltip] = useState(false);
    const isKeyboardNavigating = useKeyboardNavListener();

    function handleSeek(e: React.ChangeEvent<HTMLInputElement>) {
      const video = ref.current!;

      video.pause();
      video.currentTime = e.currentTarget.valueAsNumber;
      setTime(video.currentTime);
    }

    function showControlsWithTimeout(e?: React.PointerEvent<HTMLDivElement>) {
      if (hideVideoControls) return;
      setShowControls(true);
      controlsTimer.set(
        () => {
          setShowControls(false);
        },
        // wait a bit longer on touch devices
        e?.pointerType === 'touch' ? 3000 : 2000,
      );
    }
    function handlePlayback() {
      const video = ref.current!;

      showControlsWithTimeout();
      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }
    }

    useGlobalListener('fullscreenchange', () => {
      setFullscreen(!!document.fullscreenElement);
    });

    useGlobalKeyDownListener((e) => {
      if (e.key === ' ') handlePlayback();
    });

    useRafInterval(() => {
      const video = ref.current;

      if (!video) return;
      if (!video.paused) {
        setTime((prevTime) => {
          // We increment the progress bar in .25 chunks while
          // playing, but the user may seek to an in between value
          // in order to not snap to an earlier time only update
          // when the new clamped time is a whole interval past the current time
          const nextTime = Math.floor(video.currentTime * 4) / 4;
          return nextTime === Math.floor(prevTime * 4) / 4
            ? prevTime
            : nextTime;
        });
      }

      if (video.ended) {
        setPlaying(false);
      }
    }, 0);

    function updatePosition(e: React.PointerEvent<HTMLInputElement>) {
      const box = e.currentTarget.getBoundingClientRect();

      const tooltipTime =
        (Math.max(e.clientX - box.left, 0) / box.width) * duration!;

      positionRef.current = {
        left: Math.min(Math.max(e.clientX, box.left), box.left + box.width),
        top: box.top,
        value: formatTime(tooltipTime > duration! ? duration! : tooltipTime),
      };
    }

    return (
      <div
        ref={rootRef}
        className={clsx(className, styles.container, 'relative')}
        onPointerEnter={showControlsWithTimeout}
        onPointerMove={showControlsWithTimeout}
        onPointerLeave={(e) => {
          // this tends to fire on pointer up as well
          if (e.pointerType === 'touch') return;
          controlsTimer.set(() => {
            setShowControls(false);
          }, 500);
        }}
      >
        <video
          {...props}
          playsInline
          controls={false}
          autoPlay={autoPlay}
          className="w-full h-full object-contain"
          ref={useMergedRefs(outerRef, ref)}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onLoadedMetadata={(e) => {
            setDuration(e.currentTarget.duration);
          }}
          onPointerUp={(e) => {
            e.preventDefault();
            if (e.pointerType === 'touch') {
              showControlsWithTimeout();
            } else {
              handlePlayback();
            }
          }}
        />
        <ScrubberTooltip in={showTooltip} positionRef={positionRef} />

        <Layout
          pad={1}
          align="center"
          className={clsx(styles.controls, showControls && styles.show)}
          onPointerEnter={() => {
            if (hideVideoControls) return;
            setShowControls(true);
            controlsTimer.clear();
          }}
          onPointerMove={(e) => {
            e.stopPropagation();
          }}
        >
          <IconButton
            titleSrOnly
            placement="top"
            icon={playing ? <PauseIcon /> : <PlayIcon />}
            onClick={handlePlayback}
            data-bni-id="VideoPlayerPlayButton"
            title={
              playing ? (
                <FormattedMessage
                  id="videoPlayer.pause"
                  defaultMessage="Pause"
                />
              ) : (
                <FormattedMessage
                  id="videoPlayer.play"
                  defaultMessage="Play"
                />
              )
            }
          />

          <input
            type="range"
            ref={inputRef}
            step=".01"
            value={time}
            min={0}
            onChange={handleSeek}
            max={duration ?? undefined}
            data-bni-id="VideoPlayerScrubber"
            onKeyDown={(e) => {
              if (e.key === ' ') {
                e.preventDefault();
                handlePlayback();
              }
            }}
            style={
              duration
                ? { ['--fill-value' as any]: `${(time / duration) * 100}%` }
                : undefined
            }
            className={clsx(
              styles.range,
              // focus-visible shows focus when you hit space as which looks odd in this case
              isKeyboardNavigating && 'focus-visible:ring',
              'flex-grow outline-none',
            )}
            aria-valuetext={`${formatTime(time)} of ${formatTime(
              duration || 0,
            )}`}
            onPointerEnter={(e) => {
              if (e.pointerType === 'touch') return;

              e.currentTarget.setPointerCapture(e.pointerId);
              // must be outside the tooltip b/c the event goes stale
              updatePosition(e);
              showTimer.set(() => {
                setShowTooltip(true);
              }, 100);
            }}
            onPointerMove={(e) => {
              if (e.pointerType === 'touch') return;
              updatePosition(e);
            }}
            onPointerLeave={(e) => {
              if (e.pointerType === 'touch') return;

              showTimer.clear();
              setShowTooltip(false);
            }}
          />

          <time className={styles.time} data-bni-id="VideoPlayerTime">
            {formatTime(time ?? 0)}
          </time>
          {fullscreenEnabled && (
            <IconButton
              titleSrOnly
              data-bni-id="VideoPlayerFullscreenButton"
              onClick={() => {
                if (!document.fullscreenElement) {
                  rootRef.current?.requestFullscreen({ navigationUI: 'hide' });
                } else {
                  document.exitFullscreen();
                }
              }}
              icon={
                fullscreen ? (
                  <ScreenCollapseIcon height={16} width={16} />
                ) : (
                  <ScreenExpandIcon height={16} width={16} />
                )
              }
              title={
                <FormattedMessage
                  id="videoPlayer.fullscreen"
                  defaultMessage="Full screen"
                />
              }
            />
          )}
        </Layout>
      </div>
    );
  },
);

export default VideoPlayer;
