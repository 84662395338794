/**
 * @generated SignedSource<<58730480e970b96b366ace4c96180b7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canSaveMeasurements$data = {
  readonly finalizedAt: string | null;
  readonly " $fragmentType": "StudyPermissions_canSaveMeasurements";
};
export type StudyPermissions_canSaveMeasurements$key = {
  readonly " $data"?: StudyPermissions_canSaveMeasurements$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canSaveMeasurements">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canSaveMeasurements"
};

(node as any).hash = "84e47515ea17e3fcfbea2508ed9bc185";

export default node;
