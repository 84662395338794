import React from 'react';

export default function joinNonemptyNodes(
  joinWith: string,
  nodes: Array<React.ReactNode | null>,
) {
  const joinedNodes: Array<React.ReactNode> = [];

  nodes.filter(Boolean).forEach((node, index) => {
    if (index) {
      joinedNodes.push(joinWith as any);
    }

    joinedNodes.push(node);
  });

  return joinedNodes;
}
