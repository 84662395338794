import captureException from '@bfly/utils/captureException';

const fetchFromRouterUpstream = async (path: string): Promise<any | null> => {
  try {
    const resp = await fetch(
      new URL(path, globalThis.bflyConfig.ROUTER_UPSTREAM).toString(),
      { headers: { 'Content-Type': 'application/json' } },
    );
    if (!resp.ok) return null;
    return await resp.json();
  } catch (err: any) {
    captureException(err);
    return null;
  }
};

export default fetchFromRouterUpstream;
