/**
 * @generated SignedSource<<26a4739a9285025c2ad18e76298dec55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorksheetsExportControl_tenant$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"useStudyStatisticsExport_tenant">;
  readonly " $fragmentType": "WorksheetsExportControl_tenant";
};
export type WorksheetsExportControl_tenant$key = {
  readonly " $data"?: WorksheetsExportControl_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetsExportControl_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorksheetsExportControl_tenant",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useStudyStatisticsExport_tenant",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};
})();

(node as any).hash = "10110e1e9680294d9c3ef36677b8c2a0";

export default node;
