/**
 * @generated SignedSource<<3a8afb4b40ed420e9ef436efbf7a0aac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ScanLabProvider_statusCounts_Query$variables = {
  canNotReview: boolean;
  viewerId?: ReadonlyArray<string> | null;
};
export type ScanLabProvider_statusCounts_Query$data = {
  readonly portfolio: {
    readonly reviewRequested: number | null;
    readonly reviewed: number | null;
    readonly unsubmitted: number | null;
  } | null;
  readonly review?: {
    readonly reviewRequested: number | null;
    readonly reviewed: number | null;
  } | null;
};
export type ScanLabProvider_statusCounts_Query = {
  response: ScanLabProvider_statusCounts_Query$data;
  variables: ScanLabProvider_statusCounts_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "canNotReview"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewerId"
},
v2 = {
  "kind": "Variable",
  "name": "authors",
  "variableName": "viewerId"
},
v3 = {
  "kind": "Literal",
  "name": "isReviewed",
  "value": false
},
v4 = {
  "alias": "unsubmitted",
  "args": [
    (v2/*: any*/),
    {
      "kind": "Literal",
      "name": "isReviewRequested",
      "value": false
    },
    (v3/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "eduNumStudies",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "isReviewRequested",
  "value": true
},
v6 = {
  "alias": "reviewRequested",
  "args": [
    (v2/*: any*/),
    (v5/*: any*/),
    (v3/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "eduNumStudies",
  "storageKey": null
},
v7 = {
  "kind": "Literal",
  "name": "isReviewed",
  "value": true
},
v8 = {
  "alias": "reviewed",
  "args": [
    (v2/*: any*/),
    (v7/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "eduNumStudies",
  "storageKey": null
},
v9 = {
  "alias": "reviewRequested",
  "args": [
    (v5/*: any*/),
    (v3/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "eduNumStudies",
  "storageKey": "eduNumStudies(isReviewRequested:true,isReviewed:false)"
},
v10 = {
  "alias": "reviewed",
  "args": [
    (v7/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "eduNumStudies",
  "storageKey": "eduNumStudies(isReviewed:true)"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScanLabProvider_statusCounts_Query",
    "selections": [
      {
        "alias": "portfolio",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "canNotReview",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "review",
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ScanLabProvider_statusCounts_Query",
    "selections": [
      {
        "alias": "portfolio",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "canNotReview",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "review",
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "78c9a61bae8295d3d246d9931d49899e",
    "id": null,
    "metadata": {},
    "name": "ScanLabProvider_statusCounts_Query",
    "operationKind": "query",
    "text": "query ScanLabProvider_statusCounts_Query(\n  $viewerId: [ID!]\n  $canNotReview: Boolean!\n) {\n  portfolio: viewer {\n    unsubmitted: eduNumStudies(authors: $viewerId, isReviewRequested: false, isReviewed: false)\n    reviewRequested: eduNumStudies(authors: $viewerId, isReviewRequested: true, isReviewed: false)\n    reviewed: eduNumStudies(authors: $viewerId, isReviewed: true)\n    id\n  }\n  review: viewer @skip(if: $canNotReview) {\n    reviewRequested: eduNumStudies(isReviewRequested: true, isReviewed: false)\n    reviewed: eduNumStudies(isReviewed: true)\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "89ad24856fc959b90e2f3028fddc2a9a";

export default node;
