import Layout from '@4c/layout';
import SuccessIcon from '@bfly/icons/Success';
import ListItem from '@bfly/ui2/ListItem';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import PhysicianInfo from 'components/PhysicianInfo';

import StudyInfo from './StudyInfo';
import VetPatientInfo from './VetPatientInfo';
import { StudySubtitle_study$data as Study } from './__generated__/StudySubtitle_study.graphql';

interface Props {
  study: Study;
}

function StudySubtitle({ study }: Props) {
  const { notes } = study;

  const canUseReviews = useVariation('worksheets-review');
  const isDraft = !study.finalizedAt;

  return (
    <>
      <ListItem.Subtitle data-bni-id="StudySubtitle">
        {study.practiceType === 'HUMAN' ? (
          <StudyInfo study={study} />
        ) : (
          <VetPatientInfo
            vetPatient={study.vetPatient!}
            accessionNumber={study.accessionNumber}
          />
        )}
      </ListItem.Subtitle>
      <ListItem.Subtitle>
        <PhysicianInfo study={study} />
      </ListItem.Subtitle>
      <ListItem.Subtitle
        data-bni-id="StudyNotes"
        css="overflow-wrap: break-word;"
      >
        {notes}
      </ListItem.Subtitle>
      <Layout
        as={ListItem.Subtitle}
        pad={4}
        css={css`
          &:empty {
            display: none;
          }
        `}
      >
        {canUseReviews && !isDraft && !!study.numQaEntries && (
          <Layout pad={2} align="center">
            <SuccessIcon
              width={12}
              height={12}
              css={css`
                :global(.foreground) {
                  fill: transparent;
                  fill-opacity: 0;
                }
              `}
            />
            <Text variant="sm">
              <FormattedMessage
                id="study.qa.title"
                defaultMessage="Reviewed"
              />
            </Text>
          </Layout>
        )}
      </Layout>
    </>
  );
}

export default createFragmentContainer(StudySubtitle, {
  study: graphql`
    fragment StudySubtitle_study on Study {
      notes
      practiceType
      numQaEntries
      finalizedAt
      accessionNumber
      vetPatient {
        ...VetPatientInfo_vetPatient
      }
      ...StudyInfo_study
      ...PhysicianInfo_study
    }
  `,
});
