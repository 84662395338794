export default function makeStoreUrl(
  pathname: string,
  {
    organizationSlug = null,
    includeRedirectUri = false,
  }: {
    organizationSlug?: string | null;
    includeRedirectUri?: boolean;
  } = {},
) {
  const url = `${globalThis.bflyConfig!.STORE_URL}/${pathname}`;

  const params = new URLSearchParams([['force_credentials_refresh', 'true']]);

  if (organizationSlug) {
    params.append('organization_slug', organizationSlug);
  }

  if (includeRedirectUri) {
    params.append('redirect_uri', window.location.href);
  }

  return `${url}?${params}`;
}
