/**
 * @generated SignedSource<<46d97d74eb288fff6cf626f190aa2583>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConfirmEmailInput = {
  clientMutationId?: string | null;
  token: string;
};
export type VerifyEmailChangePage_ConfirmMutation$variables = {
  input: ConfirmEmailInput;
};
export type VerifyEmailChangePage_ConfirmMutation$data = {
  readonly confirmEmailOrError: {
    readonly __typename: string;
    readonly message?: string | null;
  } | null;
};
export type VerifyEmailChangePage_ConfirmMutation = {
  response: VerifyEmailChangePage_ConfirmMutation$data;
  variables: VerifyEmailChangePage_ConfirmMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "confirmEmailOrError",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "ErrorInterface",
        "abstractKey": "__isErrorInterface"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyEmailChangePage_ConfirmMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyEmailChangePage_ConfirmMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7909b0eac13cc6aaf968170507f86f79",
    "id": null,
    "metadata": {},
    "name": "VerifyEmailChangePage_ConfirmMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyEmailChangePage_ConfirmMutation(\n  $input: ConfirmEmailInput!\n) {\n  confirmEmailOrError(input: $input) {\n    __typename\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "97c9c4314a74a1665bcbd0e21e0b917a";

export default node;
