/**
 * @generated SignedSource<<1abd91e1a94e00964c492f2f41a920c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentMention_userProfile$data = {
  readonly name: string | null;
  readonly " $fragmentType": "CommentMention_userProfile";
};
export type CommentMention_userProfile$key = {
  readonly " $data"?: CommentMention_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentMention_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentMention_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "e95dacbd5b655f9e9badab4ddde3542e";

export default node;
