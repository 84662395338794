/**
 * @generated SignedSource<<156448f4cb8373039f503a259213f921>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type isLastPublicArchive_archive$data = {
  readonly id: string;
  readonly isPrivate: boolean | null;
  readonly " $fragmentType": "isLastPublicArchive_archive";
};
export type isLastPublicArchive_archive$key = {
  readonly " $data"?: isLastPublicArchive_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"isLastPublicArchive_archive">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "isLastPublicArchive_archive"
};

(node as any).hash = "d186458eeb328e1a32e2bedf4baf6f6a";

export default node;
