/**
 * @generated SignedSource<<c88c3b32d056a756e4086ad596a2c0cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarList_studyTag$data = {
  readonly name: string | null;
  readonly " $fragmentType": "SearchBarList_studyTag";
};
export type SearchBarList_studyTag$key = {
  readonly " $data"?: SearchBarList_studyTag$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarList_studyTag">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarList_studyTag"
};

(node as any).hash = "5cfee60cd060612640148adcaf73a13a";

export default node;
