import { defineMessages } from 'react-intl';

export default defineMessages({
  logIn: {
    id: 'account.logIn',
    defaultMessage: 'Log In',
  },
  email: {
    id: 'account.email',
    defaultMessage: 'Email',
  },
  firstName: {
    id: 'account.firstName',
    defaultMessage: 'First Name',
  },
  middleName: {
    id: 'account.middleName',
    defaultMessage: 'Middle Name',
  },
  lastName: {
    id: 'account.lastName',
    defaultMessage: 'Last Name',
  },
  prefix: {
    id: 'account.prefix',
    defaultMessage: 'Prefix',
  },
  suffix: {
    id: 'account.suffix',
    defaultMessage: 'Suffix',
  },
});
