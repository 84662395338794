/**
 * @generated SignedSource<<f413d8624328478df76531fafe8685bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EducationContentDuration = "FROM_1_TO_5" | "FROM_5_TO_10" | "LESS_THAN_1" | "MORE_THAN_10" | "%future added value";
export type EducationContentType = "COURSE" | "VIDEO" | "%future added value";
export type EducationVideoType = "ACQUISITION" | "INTERPRETATION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EducationVideoPageHeader_lms$data = {
  readonly assigned: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null> | null;
  } | null;
  readonly educationContentCategories: ReadonlyArray<{
    readonly handle: string | null;
    readonly name: string | null;
    readonly ordering: number | null;
  } | null> | null;
  readonly educationContentFilters: {
    readonly contentType: ReadonlyArray<EducationContentType | null> | null;
    readonly duration: ReadonlyArray<EducationContentDuration | null> | null;
    readonly videoType: ReadonlyArray<EducationVideoType | null> | null;
  } | null;
  readonly saved: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "EducationVideoPageHeader_lms";
};
export type EducationVideoPageHeader_lms$key = {
  readonly " $data"?: EducationVideoPageHeader_lms$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationVideoPageHeader_lms">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EducationContentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EducationContent",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "assigned"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "saved"
        ]
      }
    ]
  },
  "name": "EducationVideoPageHeader_lms",
  "selections": [
    {
      "alias": "assigned",
      "args": [
        {
          "kind": "Literal",
          "name": "viewerHasCompleted",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "viewerIsAssigned",
          "value": true
        }
      ],
      "concreteType": "EducationContentConnection",
      "kind": "LinkedField",
      "name": "__EducationVideoPageHeader_assigned_connection",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "__EducationVideoPageHeader_assigned_connection(viewerHasCompleted:false,viewerIsAssigned:true)"
    },
    {
      "alias": "saved",
      "args": [
        {
          "kind": "Literal",
          "name": "viewerHasSaved",
          "value": true
        }
      ],
      "concreteType": "EducationContentConnection",
      "kind": "LinkedField",
      "name": "__EducationVideoPageHeader_saved_connection",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "__EducationVideoPageHeader_saved_connection(viewerHasSaved:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContentCategory",
      "kind": "LinkedField",
      "name": "educationContentCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ordering",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContentFilters",
      "kind": "LinkedField",
      "name": "educationContentFilters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lms",
  "abstractKey": null
};
})();

(node as any).hash = "01ea69eef36791346ce0506501c5c651";

export default node;
