import useSubscription from '@bfly/ui2/useSubscription';
import getNodes from '@bfly/utils/getNodes';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import OutageMessage from 'components/OutageMessage';
import StudyPacsJobDetail from 'components/StudyPacsJobDetail';

import StudyEhrJobDetail from './StudyEhrJobDetail';
import { StudyEndpointsModalContentEhrSubscription as EhrSubscription } from './__generated__/StudyEndpointsModalContentEhrSubscription.graphql';
import { StudyEndpointsModalContentPacsSubscription as PacsSubscription } from './__generated__/StudyEndpointsModalContentPacsSubscription.graphql';
import { StudyEndpointsModalContent_study$data as Study } from './__generated__/StudyEndpointsModalContent_study.graphql';

interface Props {
  study: Study;
}

function StudyEndpointsModalContent({ study }: Props) {
  const { pacsPushJobs, ehrOruJobs, organization, archive } = study;
  const canUseHl7Connector = useVariation('hl7-connector');

  useSubscription<EhrSubscription>(
    graphql`
      subscription StudyEndpointsModalContentEhrSubscription(
        $input: StudyEhrOruStatusUpdatedSubscriptionInput!
      ) {
        studyEhrOruStatusUpdated(input: $input) {
          study {
            ...StudySyncStatus_study
            ehrOruJobs {
              ...EhrSyncStatus_job
              ...SendStudyToEhrButton_job
              ehr {
                id
              }
              status
            }
          }
        }
      }
    `,
    {
      input: { studyId: study.id },
    },
  );
  useSubscription<PacsSubscription>(
    graphql`
      subscription StudyEndpointsModalContentPacsSubscription(
        $input: StudyPacsPushStatusUpdatedSubscriptionInput!
      ) {
        studyPacsPushStatusUpdated(input: $input) {
          study {
            ...StudySyncStatus_study
            pacsPushJobs {
              ...PacsSyncStatus_job
              ...SendStudyToPacsButton_job
              pacs {
                id
              }
              status
            }
          }
        }
      }
    `,
    {
      input: { studyId: study.id },
    },
  );

  if (!organization?.pacsConnection || !organization?.ehrConnection)
    return <OutageMessage />;

  const pacses = getNodes(organization?.pacsConnection);
  const ehrs = getNodes(organization?.ehrConnection);

  if (!pacses.length && !ehrs.length) {
    return (
      <p>
        {canUseHl7Connector ? (
          <FormattedMessage
            id="study.endpoints.noEndpoints"
            defaultMessage="No endpoints available"
          />
        ) : (
          <FormattedMessage
            id="study.pacs.noPacses"
            defaultMessage="No DICOM servers available"
          />
        )}
      </p>
    );
  }

  const connectedPacsIds = new Set(
    archive!.pacsAssociations!.map((a) => a!.pacs!.id),
  );
  const connectedEhrIds = new Set(
    archive!.ehrAssociations!.map((a) => a!.ehr!.id),
  );

  // show connected servers first
  const sortedPacses = sortBy(pacses, ({ id }) =>
    connectedPacsIds.has(id) ? 0 : 1,
  );
  const sortedEhrs = sortBy(ehrs, ({ id }) =>
    connectedEhrIds.has(id) ? 0 : 1,
  );

  const jobsByPacsId = keyBy(pacsPushJobs!, (i) => i!.pacs!.id);
  const jobsByEhrId = keyBy(ehrOruJobs!, (i) => i!.ehr!.id);

  return (
    <>
      <p>
        {canUseHl7Connector ? (
          <FormattedMessage
            id="study.endpoints.sendToEndpointDescription"
            defaultMessage="Send or re-send this study to one or more of your endpoints below"
          />
        ) : (
          <FormattedMessage
            id="study.pacs.sendToDicomDescription"
            defaultMessage="Send or re-send this study to one or more of your connections below"
          />
        )}
      </p>
      <div>
        {sortedPacses.map((pacs) => {
          return (
            <StudyPacsJobDetail
              key={pacs.id}
              job={jobsByPacsId[pacs.id] || null}
              pacs={pacs}
              study={study}
              title={pacs.name!}
            />
          );
        })}
        {sortedEhrs.map((ehr) => {
          return (
            <StudyEhrJobDetail
              key={ehr.id}
              job={jobsByEhrId[ehr.id] || null}
              ehr={ehr}
              study={study}
              title={ehr.name!}
            />
          );
        })}
      </div>
    </>
  );
}

export default createFragmentContainer(StudyEndpointsModalContent, {
  study: graphql`
    fragment StudyEndpointsModalContent_study on Study {
      id
      ...StudyPacsJobDetail_study
      ...StudyEhrJobDetail_study
      pacsPushJobs {
        pacs {
          id
        }
        ...StudyPacsJobDetail_job
      }
      ehrOruJobs {
        ehr {
          id
        }
        ...StudyEhrJobDetail_job
      }
      organization {
        pacsConnection(first: 2147483647)
          @connection(key: "Organization_pacsConnection") {
          edges {
            node {
              id
              name
              ...StudyPacsJobDetail_pacs
            }
          }
        }
        ehrConnection(first: 2147483647)
          @connection(key: "Organization_ehrConnection") {
          edges {
            node {
              id
              name
              ...StudyEhrJobDetail_ehr
            }
          }
        }
      }
      archive {
        pacsAssociations {
          pacs {
            id
          }
        }
        ehrAssociations {
          ehr {
            id
          }
        }
      }
    }
  `,
});
