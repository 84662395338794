import React from 'react';
import { RelayProp, createFragmentContainer, graphql } from 'react-relay';

import { Thumbnail_image$data as Image } from './__generated__/Thumbnail_image.graphql';

function getBestFitThumnail<T extends { readonly width: number | null }>(
  maxWidth: number,
  images: readonly (T | null)[],
) {
  for (const image of images) {
    if ((image?.width || 0) >= maxWidth) {
      return image!;
    }
  }
  return images[images.length - 1]!;
}

interface Props extends React.ComponentPropsWithoutRef<'img'> {
  image: Image | null;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
  relay: RelayProp;
  /* describe the maximum width (in pixels) the thumbnail will occupy to optimize which thumbnals are used */
  maxWidth?: number;
}

const Thumbnail = React.forwardRef<HTMLImageElement, Props>(
  ({ image, alt = '', relay: _, maxWidth, ...props }, ref) => {
    const thumbnails = image?.thumbnails;
    if (!thumbnails) return null;

    const lastImage = thumbnails![thumbnails!.length - 1];
    const srcProps = maxWidth
      ? { src: getBestFitThumnail(maxWidth, thumbnails!).url! }
      : {
          src: lastImage!.url!,
          srcSet: thumbnails!.map((t) => `${t!.url!} ${t!.width!}w`).join(),
        };

    return <img alt={alt} ref={ref} {...props} {...srcProps} />;
  },
);

Thumbnail.displayName = 'Thumbnail';

export default createFragmentContainer(Thumbnail, {
  image: graphql`
    fragment Thumbnail_image on ThumbnailedInterface {
      thumbnails {
        url
        width
      }
    }
  `,
});
