import type {
  DateRangePickerValue,
  ValueDates,
  ValueDays,
} from '@bfly/ui2/DateRangePicker';

export function isValidDateValue(value: any): value is ValueDates {
  if (!value || typeof value !== 'object') return false;

  if (
    'startDate' in value &&
    // invalid start date
    (!(value.startDate instanceof Date) || Number.isNaN(value.startDate))
  )
    return false;

  if (
    'endDate' in value &&
    // invalid end date
    (!(value.endDate instanceof Date) || Number.isNaN(value.endDate))
  )
    return false;

  return true;
}

export function isValidDayValue(value: any): value is ValueDays {
  return (
    value &&
    typeof value === 'object' &&
    'days' in value &&
    Number.isFinite(value.days) &&
    !Number.isNaN(value.days) &&
    value.days > 0
  );
}

export function isValidDateRangeValue(
  value: any,
): value is DateRangePickerValue {
  return isValidDateValue(value) || isValidDayValue(value);
}

export function dateRangePickerValueToDates(
  value?: DateRangePickerValue | null,
): {
  start: Date | undefined;
  end: Date | undefined;
} {
  if (isValidDayValue(value)) {
    const end = new Date();
    end.setMilliseconds(0);
    const start = new Date(end);
    start.setMilliseconds(0);
    start.setDate(start.getDate() - value.days);
    return { start, end };
  }

  if (isValidDateValue(value)) {
    return {
      start: value.startDate || undefined,
      end: value.endDate || undefined,
    };
  }

  return { start: undefined, end: undefined };
}
