import QuickCopyIcon from '@bfly/icons/QuickCopy';
import Button from '@bfly/ui2/Button';
import Text, { TextProps } from '@bfly/ui2/Text';
import clsx from 'clsx';
import { MouseEvent, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import useCopyTextToClipboard from 'hooks/useCopyTextToClipboard';

interface Props
  extends TextProps,
    Omit<React.ComponentPropsWithoutRef<'button'>, 'color'> {
  /** Provide explict text to copy if different from the rendered text */
  textToCopy?: string;
}

function QuickCopyText({ children, textToCopy, ...props }: Props) {
  const ref = useRef<HTMLSpanElement>(null);
  const copyTextToClipboard = useCopyTextToClipboard();

  function handleCopy(evt: MouseEvent<HTMLButtonElement>) {
    copyTextToClipboard(
      textToCopy != null ? textToCopy : ref.current?.textContent || '',
      <FormattedMessage
        id="headerPatientInfo.successToast"
        defaultMessage="Text copied to your clipboard"
      />,
    );

    props.onClick?.(evt);
  }

  return (
    <Text {...props} className={clsx(props.className, 'inline-flex')}>
      <span className="contents" ref={ref}>
        {children}
      </span>
      <Button
        type="button"
        size="flush"
        variant="text-secondary"
        onClick={handleCopy}
        className="cursor-pointer ml-1.5 px-0"
      >
        {/* TODO scale with font-size */}
        <QuickCopyIcon height={14} />
      </Button>
    </Text>
  );
}

export default QuickCopyText;
