/**
 * @generated SignedSource<<4298309c6848e5f9a9873d3e1f1872f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyList_studiesWithImages$data = ReadonlyArray<{
  readonly id: string;
  readonly imageConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"StudyListItem_studyImages">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "StudyList_studiesWithImages";
}>;
export type StudyList_studiesWithImages$key = ReadonlyArray<{
  readonly " $data"?: StudyList_studiesWithImages$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyList_studiesWithImages">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudyList_studiesWithImages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 6
        }
      ],
      "concreteType": "StudyImageConnection",
      "kind": "LinkedField",
      "name": "imageConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyImageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyImage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyListItem_studyImages"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "imageConnection(first:6)"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "3a89c451fb91d3cc973caf5257a50374";

export default node;
