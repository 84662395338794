/**
 * @generated SignedSource<<48a3cb925a51a4b282e0d4b932b67a5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EduStudyPage_study$data = {
  readonly capturedAt: string | null;
  readonly createdBy: {
    readonly handle: string | null;
    readonly id: string;
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  } | null;
  readonly examTypes: ReadonlyArray<{
    readonly name: string | null;
  } | null> | null;
  readonly handle: string | null;
  readonly reviewRequestedAt: string | null;
  readonly standardQa: {
    readonly createdAt: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EduStudySidebar_study">;
  readonly " $fragmentType": "EduStudyPage_study";
};
export type EduStudyPage_study$key = {
  readonly " $data"?: EduStudyPage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"EduStudyPage_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EduStudyPage_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EduStudySidebar_study"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_userProfile"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capturedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examTypes",
      "plural": true,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewRequestedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EduStandardQa",
      "kind": "LinkedField",
      "name": "standardQa",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EduStudy",
  "abstractKey": null
};
})();

(node as any).hash = "1ef42b0710ce31540fafbef3584c44af";

export default node;
