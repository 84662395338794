/**
 * @generated SignedSource<<62c3b8e0a52e6cbc66887f2993aafc9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PacsPushJobStatus = "CANCELED" | "COMPLETE" | "FAILED" | "PENDING" | "RETRYING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PacsSyncStatus_job$data = {
  readonly completedAt: string | null;
  readonly isOutdated: boolean | null;
  readonly pacs: {
    readonly name: string | null;
    readonly paused: boolean | null;
  } | null;
  readonly status: PacsPushJobStatus | null;
  readonly " $fragmentType": "PacsSyncStatus_job";
};
export type PacsSyncStatus_job$key = {
  readonly " $data"?: PacsSyncStatus_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"PacsSyncStatus_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PacsSyncStatus_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pacs",
      "kind": "LinkedField",
      "name": "pacs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paused",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOutdated",
      "storageKey": null
    }
  ],
  "type": "PacsPushJob",
  "abstractKey": null
};

(node as any).hash = "1faf6a8a831ac04bdf136d022e2686f9";

export default node;
