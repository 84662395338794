/**
 * @generated SignedSource<<7a95b2e2bf972fe177245563dd6d4ca9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useInterpretationImage_interpretation$data = {
  readonly attachment: {
    readonly dataUrl: string | null;
  } | null;
  readonly staticRender: {
    readonly dataUrl: string | null;
  } | null;
  readonly stillBurnIn: {
    readonly dataUrl: string | null;
  } | null;
  readonly volumeRender: {
    readonly dataUrl: string | null;
  } | null;
  readonly " $fragmentType": "useInterpretationImage_interpretation";
};
export type useInterpretationImage_interpretation$key = {
  readonly " $data"?: useInterpretationImage_interpretation$data;
  readonly " $fragmentSpreads": FragmentRefs<"useInterpretationImage_interpretation">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useInterpretationImage_interpretation"
};

(node as any).hash = "0a3636f46bb77c153985ad28303fe5e2";

export default node;
