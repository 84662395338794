/**
 * @generated SignedSource<<3eef381bced34198341f7825884e2577>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type viewerContext_viewerPermissions$data = {
  readonly accessAllOrganizations: PermissionLevel | null;
  readonly archiveManagement: PermissionLevel | null;
  readonly bulkDataExport: PermissionLevel | null;
  readonly connectivityManagement: PermissionLevel | null;
  readonly credentialManagement: PermissionLevel | null;
  readonly dataDeletion: PermissionLevel | null;
  readonly dataExport: PermissionLevel | null;
  readonly educationManagement: PermissionLevel | null;
  readonly examTypeManagement: PermissionLevel | null;
  readonly fleetManagement: PermissionLevel | null;
  readonly iqCareRestrictedExperience: PermissionLevel | null;
  readonly loginAndSecurityManagement: PermissionLevel | null;
  readonly organizationManagement: PermissionLevel | null;
  readonly qa: PermissionLevel | null;
  readonly qaManagement: PermissionLevel | null;
  readonly signing: PermissionLevel | null;
  readonly studyDocumentation: PermissionLevel | null;
  readonly studyDocumentationManagement: PermissionLevel | null;
  readonly studyReversion: PermissionLevel | null;
  readonly userManagement: PermissionLevel | null;
  readonly userPermissions: PermissionLevel | null;
  readonly " $fragmentType": "viewerContext_viewerPermissions";
};
export type viewerContext_viewerPermissions$key = {
  readonly " $data"?: viewerContext_viewerPermissions$data;
  readonly " $fragmentSpreads": FragmentRefs<"viewerContext_viewerPermissions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "viewerContext_viewerPermissions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessAllOrganizations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archiveManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bulkDataExport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connectivityManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "credentialManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataDeletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataExport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "educationManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examTypeManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fleetManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iqCareRestrictedExperience",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loginAndSecurityManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qaManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyDocumentation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyDocumentationManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyReversion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userPermissions",
      "storageKey": null
    }
  ],
  "type": "ViewerPermissions",
  "abstractKey": null
};

(node as any).hash = "f079568e8a158c4e25ded1abd385339a";

export default node;
