import PlusIcon from '@bfly/icons/Plus';
import Button, { ButtonProps } from '@bfly/ui2/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import actionMessages from '../messages/actions';

const PlusButton = React.forwardRef(
  (
    {
      children = <FormattedMessage {...actionMessages.add} />,
      ...props
    }: ButtonProps,
    ref: React.Ref<any>,
  ) => {
    return (
      <Button {...props} ref={ref}>
        <PlusIcon className="mr-1.5 w-3 h-3" />
        {children}
      </Button>
    );
  },
);

PlusButton.displayName = 'PlusButton';

export default PlusButton;
