/**
 * @generated SignedSource<<82e6a5cb31677a74b6585f316b525cb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CredentialGroupSorting = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "NAME_ASC" | "NAME_DESC" | "%future added value";
export type credentialGroupsRoute_CredentialGroupsPageQuery$variables = {
  after?: string | null;
  first?: number | null;
  organizationSlug?: string | null;
  search?: string | null;
  sort?: ReadonlyArray<CredentialGroupSorting> | null;
};
export type credentialGroupsRoute_CredentialGroupsPageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"CredentialGroupsPage_tenant" | "RouteAccessControl_tenant">;
  } | null;
};
export type credentialGroupsRoute_CredentialGroupsPageQuery = {
  response: credentialGroupsRoute_CredentialGroupsPageQuery$data;
  variables: credentialGroupsRoute_CredentialGroupsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": 20,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v5 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdomainLabel",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "credentialGroupsRoute_CredentialGroupsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "RouteAccessControl_tenant",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CredentialGroupsPage_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "credentialGroupsRoute_CredentialGroupsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "CredentialGroupConnection",
            "kind": "LinkedField",
            "name": "credentialGroupConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "index",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numEdges",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CredentialGroupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CredentialGroup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dueAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startsAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numRequirements",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numMembers",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": [
              "sort",
              "search"
            ],
            "handle": "connection",
            "key": "Tenant_credentialGroupConnection",
            "kind": "LinkedHandle",
            "name": "credentialGroupConnection"
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f7c59e74e6198d18aac46161940a03b",
    "id": null,
    "metadata": {},
    "name": "credentialGroupsRoute_CredentialGroupsPageQuery",
    "operationKind": "query",
    "text": "query credentialGroupsRoute_CredentialGroupsPageQuery(\n  $organizationSlug: String\n  $first: Int = 20\n  $after: String\n  $sort: [CredentialGroupSorting!]\n  $search: String\n) {\n  tenant(slug: $organizationSlug) {\n    __typename\n    ...RouteAccessControl_tenant\n    ...CredentialGroupsPage_tenant\n    id\n  }\n}\n\nfragment CredentialGroupsPage_credentialGroupsConnection on TenantInterface {\n  __isTenantInterface: __typename\n  credentialGroupConnection(first: $first, after: $after, sort: $sort, search: $search) {\n    index\n    numEdges\n    edges {\n      node {\n        id\n        name\n        dueAt\n        ...CredentialGroupsTable_credentialGroups\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment CredentialGroupsPage_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Organization {\n    slug\n  }\n  ...CredentialGroupsPage_credentialGroupsConnection\n  ...CredentialGroupsTable_tenant\n}\n\nfragment CredentialGroupsTable_credentialGroups on CredentialGroup {\n  id\n  name\n  dueAt\n  startsAt\n  createdAt\n  createdBy {\n    name\n    id\n  }\n  numRequirements\n  numMembers\n}\n\nfragment CredentialGroupsTable_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  id\n}\n\nfragment RouteAccessControl_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    subdomainLabel\n  }\n  ... on Organization {\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e72b3d5c496a3b8dd1d86622f690cf0";

export default node;
