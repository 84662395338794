import getNodes from '@bfly/utils/getNodes';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import HospitalServerArchiveAssociationForm from './HospitalServerArchiveAssociationForm';
import { PacsArchiveAssociationForm_Mutation as Mutation } from './__generated__/PacsArchiveAssociationForm_Mutation.graphql';
import { PacsArchiveAssociationForm_archive$data as Archive } from './__generated__/PacsArchiveAssociationForm_archive.graphql';
import { PacsArchiveAssociationForm_organization$data as Organization } from './__generated__/PacsArchiveAssociationForm_organization.graphql';

export const MAX_PACS_ASSOCIATIONS = 3;

const messages = defineMessages({
  fieldLabel: {
    id: 'archive.settings.pacsSync.label',
    defaultMessage: 'PACS {index}',
  },
  emptyDropdownPlaceholder: {
    id: 'archive.noStorageSelected',
    defaultMessage: 'No PACS Selected',
  },
  emptyDropdownMenu: {
    id: 'changePacsArchiveAssociations.noStorage',
    defaultMessage:
      'No Storages available.{br}You can create a DICOM storage connector from the DICOM Connections page.',
  },
});

const mutation = graphql`
  mutation PacsArchiveAssociationForm_Mutation(
    $input: SetArchivePacsAssociationsInput!
  ) {
    setArchivePacsAssociationsOrError(input: $input) {
      ... on SetArchivePacsAssociationsPayload {
        archive {
          pacsAssociations {
            id
          }
          ...PacsArchiveAssociationForm_archive
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

type Pacs = NonNullable<
  NonNullable<NonNullable<Archive>['pacsAssociations']>[0]
>['pacs'];

interface Props {
  archive: Archive;
  organization: Organization;
}

function PacsArchiveAssociationForm({ archive, organization }: Props) {
  const currentPacses = useMemo(
    () => archive.pacsAssociations!.map((a) => a!.pacs),
    [archive],
  );

  const availablePacses = useMemo(
    () =>
      getNodes(organization.pacsConnection).filter(
        ({ id }) => !currentPacses.find((p) => p!.id === id),
      ),
    [currentPacses, organization],
  );

  return (
    <HospitalServerArchiveAssociationForm<Mutation, Pacs>
      currentAssociations={currentPacses}
      formMessages={messages}
      mutation={mutation}
      data={availablePacses}
      maxAssociations={MAX_PACS_ASSOCIATIONS}
      getInput={(associations) => ({
        archiveId: archive.id,
        pacsIds: associations
          .filter(Boolean)
          .map((association) => association!.id),
      })}
    />
  );
}

export default createFragmentContainer(PacsArchiveAssociationForm, {
  archive: graphql`
    fragment PacsArchiveAssociationForm_archive on Archive {
      id
      pacsAssociations {
        pacs {
          id
          name
        }
      }
    }
  `,
  organization: graphql`
    fragment PacsArchiveAssociationForm_organization on Organization {
      pacsConnection(first: 2147483647)
        @connection(key: "Organization_pacsConnection") {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
