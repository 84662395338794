import Layout from '@4c/layout';
import InformationIcon from '@bfly/icons/Information';
import Heading from '@bfly/ui2/Heading';
import Popover from '@bfly/ui2/Popover';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ClinicalInsightsCards from './ClinicalInsightsCards';
import ExamStatusPerformanceIndicator from './ExamStatusPerformanceIndicator';
import ImageQualityPerformanceIndicator from './ImageQualityPerformanceIndicator';
import ReviewedExamsPerformanceIndicator from './ReviewedExamsPerformanceIndicator';
import { AllTimeStatsCards_tenant$data as Tenant } from './__generated__/AllTimeStatsCards_tenant.graphql';

interface AllTimeStatsCardsProps {
  tenant: Tenant | null;
}

function AllTimeStatsCards({ tenant }: AllTimeStatsCardsProps) {
  return (
    <Layout pad={2} wrap className="px-16 py-6 border-b border-divider">
      <Layout as={Section} direction="column" pad={2}>
        <Heading flush className="mb-2">
          <FormattedMessage
            id="AllTimeStatsCards.performanceIndicators.header"
            defaultMessage="Performance indicators"
          />
        </Heading>

        <Layout
          pad={2}
          css={css`
            & > * {
              flex: 1 1 0;
            }
          `}
        >
          <ExamStatusPerformanceIndicator tenant={tenant} />
          <ReviewedExamsPerformanceIndicator tenant={tenant} />
          <ImageQualityPerformanceIndicator tenant={tenant} />
        </Layout>
      </Layout>

      <Layout as={Section} direction="column" pad={2}>
        <Heading flush className="mb-2">
          <FormattedMessage
            id="AllTimeStatsCards.clinicalInsights.header"
            defaultMessage="Clinical insights"
          />
          <Popover.Trigger
            trigger="passive"
            id="allTimeStats-clinicalInsights-information"
            placement="top"
            variant="dark"
            popover={
              <div className="text-center">
                <div>
                  <Text variant="body">
                    <FormattedMessage
                      id="AllTimeStatsCards.information.top"
                      defaultMessage="Counts derived from the Butterfly Standard QA questions"
                    />
                  </Text>
                </div>
                <div>
                  <Text variant="body-bold">
                    <FormattedMessage
                      id="AllTimeStatsCards.information.bottom"
                      defaultMessage="Image interpretation accuracy as presented"
                    />
                  </Text>
                </div>
              </div>
            }
          >
            <InformationIcon width={16} className="ml-1" />
          </Popover.Trigger>
        </Heading>

        <ClinicalInsightsCards className="h-full" tenant={tenant} />
      </Layout>
    </Layout>
  );
}

export default createFragmentContainer(AllTimeStatsCards, {
  tenant: graphql`
    fragment AllTimeStatsCards_tenant on TenantInterface
    @argumentDefinitions(filter: { type: "StudyStatisticsFilterInput!" }) {
      ...ExamStatusPerformanceIndicator_tenant
      ...ReviewedExamsPerformanceIndicator_tenant
      ...ImageQualityPerformanceIndicator_tenant
      ...ClinicalInsightsCards_tenant @arguments(filter: $filter)
    }
  `,
});
