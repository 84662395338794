import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import FormControlWithAddon from '@bfly/ui2/FormControlWithAddon';
import getNodes from '@bfly/utils/getNodes';
import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import { css } from 'astroturf';
import { useCallback, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import {
  RelayPaginationProp,
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { object, string } from 'yup';

import RelayForm from 'components/RelayForm';
import { renderUserSuggestion } from 'components/UserSuggestionListItem';
import useSearchQuery from 'hooks/useSearchQuery';
import actionMessages from 'messages/actions';

import { ArchiveInviteFormSearchQuery } from './__generated__/ArchiveInviteFormSearchQuery.graphql';
import type { ArchiveInviteForm_CreateArchiveMembershipMutation as CreateArchiveMembershipMutation } from './__generated__/ArchiveInviteForm_CreateArchiveMembershipMutation.graphql';
import type { ArchiveInviteForm_archive$data as Archive } from './__generated__/ArchiveInviteForm_archive.graphql';

const schema = object({
  // This is not required to avoid showing an error message when blank.
  user: object({
    id: string().nullable(),
    email: string(),
  }).default(undefined),
});

const DEFAULT_VALUE = schema.getDefault();

interface Props {
  archive: Archive;
  relay: RelayPaginationProp;
}

function ArchiveInviteForm({ archive }: Props) {
  const [value, setValue] = useState(DEFAULT_VALUE);
  const [search, setSearch] = useState('');

  const resetValue = useCallback(() => {
    setValue(DEFAULT_VALUE);
  }, []);

  const { data } = useSearchQuery<ArchiveInviteFormSearchQuery>(
    graphql`
      query ArchiveInviteFormSearchQuery($slug: String!, $search: String) {
        organizationBySlug(slug: $slug) {
          membershipConnection(
            first: 30
            sort: USER_NAME_ASC
            search: $search
          ) {
            edges {
              node {
                userProfile {
                  id
                  name
                }
                email
                ...UserSuggestionListItem_userInfo
              }
            }
          }
        }
      }
    `,
    search || null,
    { slug: archive.organization!.slug! },
  );

  if (!archive.isPrivate) {
    return null;
  }

  const busy = !!search && !data;

  return (
    <RelayForm<CreateArchiveMembershipMutation, typeof schema>
      mutation={graphql`
        mutation ArchiveInviteForm_CreateArchiveMembershipMutation(
          $input: CreateArchiveMembershipInput!
        ) {
          createArchiveMembershipOrError(input: $input) {
            ... on CreateArchiveMembershipPayload {
              archiveMembershipEdge {
                node {
                  ...ArchiveMemberItemGridRow_membership
                }
              }
            }
            ...RelayForm_error @relay(mask: false)
          }
        }
      `}
      getInput={({ user }) => ({
        organizationId: archive.organization!.id,
        archiveId: archive.id,
        userId: user!.id!,
      })}
      updater={(store) => {
        rangeAddUpdater(store, {
          parentId: archive.id,
          connectionKey: 'Archive_membershipConnection',
          rootFieldName: 'createArchiveMembershipOrError',
          edgeName: 'archiveMembershipEdge',
        });
      }}
      successMessage={
        <FormattedMessage
          id="archiveInviteForm.success"
          defaultMessage="Member added"
        />
      }
      schema={schema}
      value={value}
      onChange={setValue}
      onCompleted={resetValue}
      // FIXME: this is a bad API but we want to ignore conflict errors
      // until we can efficiently determine ahead of time if a user is already a member
      toastUnknownErrors={false}
    >
      <Form.FieldSet
        legend={
          <FormattedMessage
            id="archiveInviteForm.title"
            defaultMessage="Add a Member"
          />
        }
      >
        <Form.FieldGroup name="user" validateOn="blur">
          {({ onChange, ...fieldProps }, meta) => (
            <FormControlWithAddon {...fieldProps}>
              {(inputProps) => (
                <>
                  <DropdownList
                    {...inputProps}
                    data={
                      data
                        ? getNodes(
                            data.organizationBySlug!.membershipConnection,
                          )
                        : []
                    }
                    busy={!!search && !data}
                    value={fieldProps.value}
                    allowCreate={false}
                    onChange={(nextValue: any) => {
                      onChange({
                        id: nextValue.userProfile!.id,
                        email: nextValue.email,
                      });
                    }}
                    textField="email"
                    searchTerm={search}
                    onSearch={setSearch}
                    filter={() => true}
                    hideSelectedOptionIndicator
                    placeholder={defineMessage({
                      id: 'archiveInviteForm.placeholder',
                      defaultMessage: 'Name or email address',
                    })}
                    renderListItem={renderUserSuggestion}
                    messages={{
                      emptyList:
                        !search || busy ? (
                          <FormattedMessage
                            id="archiveInviteForm.noSearch"
                            defaultMessage="Type to search by name or email…"
                          />
                        ) : (
                          <FormattedMessage
                            id="archiveInviteForm.emptySearch"
                            defaultMessage="No one matching this search found"
                          />
                        ),
                    }}
                    className="flex-1"
                    css={css`
                      & :global(.rw-widget-picker) {
                        border: 0;
                        box-shadow: none; // the focus shadow
                        // FIXME: use theme(formControl.height)
                        min-height: calc(4rem - 2px);
                      }
                    `}
                  />

                  <Form.Submit
                    variant="primary"
                    disabled={!meta.schema!.isValidSync(fieldProps.value)}
                  >
                    <FormattedMessage {...actionMessages.add} />
                  </Form.Submit>
                </>
              )}
            </FormControlWithAddon>
          )}
        </Form.FieldGroup>
      </Form.FieldSet>
    </RelayForm>
  );
}

export default createFragmentContainer(ArchiveInviteForm, {
  archive: graphql`
    fragment ArchiveInviteForm_archive on Archive {
      id
      isPrivate
      organization {
        id
        slug
      }
    }
  `,
});
