import { createContext, useContext } from 'react';

import { ScanLabContext } from './types';

export const scanLabContext = createContext<ScanLabContext | null>(null);

export const useScanLabContext = () => {
  const context = useContext(scanLabContext);

  if (!context) {
    throw new Error(
      'useScanLabContext must be called within the ScanLabProvider',
    );
  }
  return context;
};
