/**
 * @generated SignedSource<<da2f13a5e3a23cf63a0e6e89059a1005>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canFinalizeStudy$data = {
  readonly finalizedAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "StudyPermissions_canFinalizeStudy";
};
export type StudyPermissions_canFinalizeStudy$key = {
  readonly " $data"?: StudyPermissions_canFinalizeStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canFinalizeStudy">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canFinalizeStudy"
};

(node as any).hash = "a7ea4d48f9c233345bc5db5c07b7eaf3";

export default node;
