/**
 * @generated SignedSource<<9e14f206f609f53df04ac6bc08a54137>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Avatar_userProfile$data = {
  readonly avatar: {
    readonly " $fragmentSpreads": FragmentRefs<"Thumbnail_image">;
  } | null;
  readonly name: string | null;
  readonly " $fragmentType": "Avatar_userProfile";
};
export type Avatar_userProfile$key = {
  readonly " $data"?: Avatar_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Avatar_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Thumbnail_image"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "057499fc7fe5323854070168d1e8721a";

export default node;
