import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkIsScopedUnderTenantOr404,
  checkTenantPermission,
} from 'utils/RouteAccessControl';

import { studyTagsPageQuery as StudyTagsPageQuery } from './__generated__/studyTagsPageQuery.graphql';
import DomainStudyTagPage from './components/DomainStudyTagPage';

export const organizationStudyTagsRoute = (
  <Route<StudyTagsPageQuery>
    path="study-tags"
    prerender={chainPrerenders(
      checkFlagsOr404('study-tags'),
      checkIsScopedUnderTenantOr404,
      checkTenantPermission('studyDocumentation', 'BASIC'),
    )}
    query={graphql`
      query studyTagsPageQuery($organizationSlug: String) {
        tenant(slug: $organizationSlug) {
          ...StudyTagPage_tenant
          ...RouteAccessControl_tenant
        }
      }
    `}
    getComponent={() =>
      import(/* webpackChunkName: "admin" */ './components/StudyTagPage').then(
        (m) => m.default,
      )
    }
  />
);

export const domainStudyTagsRoute = (
  <Route Component={DomainStudyTagPage}>{organizationStudyTagsRoute}</Route>
);
