import Layout from '@4c/layout';
import Heading from '@bfly/ui2/Heading';
import Modal from '@bfly/ui2/Modal';
import TabNav from '@bfly/ui2/TabNav';
import { css } from 'astroturf';
import FeatureFlags from 'config/FeatureFlags';
import { ReactNode } from 'react';

import { PodTargetingHeaders } from 'utils/podTargetingHeaders';

import HeaderOverrides from './HeaderOverrides';
import LaunchDarklyFlags from './LaunchDarklyFlags';
import Playground from './Playground/Playground';
import { Section, useDevContext } from './devContext';

type Props = {
  accessToken: string | null;
  featureFlagOverrides: Partial<FeatureFlags> | null;
  featureFlags: FeatureFlags | null;
  headerOverrides: PodTargetingHeaders | null;
  onHide(): void;
  show: boolean;
};

const Tab = ({
  show = true,
  label,
  badge,
  section,
  activeSection,
  setActiveTab,
}: {
  show?: boolean | any;
  label: ReactNode;
  badge?: number;
  section: Section;
  activeSection: Section;
  setActiveTab: (activeSection: Section) => void;
}) =>
  !show ? null : (
    <TabNav.Tab
      className="flex items-center"
      active={activeSection === section}
      onClick={() => setActiveTab(section)}
    >
      {label}
      {!!badge && (
        <span className="bg-red flex flex-row items-center justify-center ml-2 w-5 text-sm font-bold rounded-lg text-white">
          {badge}
        </span>
      )}
    </TabNav.Tab>
  );

const ModalContent = ({
  accessToken,
  featureFlagOverrides,
  featureFlags,
  headerOverrides,
}: Omit<Props, 'show' | 'onHide'>) => {
  const { activeSection, setDevState } = useDevContext();

  const setActiveTab = (next: Section) =>
    setDevState((p) => ({ ...p, activeSection: next }));

  const tabProps = {
    activeSection,
    setActiveTab,
  };

  return (
    <>
      <Modal.Header className="shrink-0">
        <Heading variant="xl" className="mb-0">
          Developer Tools
        </Heading>
      </Modal.Header>
      <TabNav className="px-5">
        <Tab
          section="headerOverrides"
          label="Local Overrides"
          badge={Object.keys(headerOverrides || {}).length}
          {...tabProps}
        />
        <Tab
          show={featureFlags}
          section="launchDarklyFlags"
          label="Launch Darkly Flags"
          badge={Object.keys(featureFlagOverrides || {}).length}
          {...tabProps}
        />
        <Tab
          show={accessToken}
          section="playground"
          label="GQL Playground"
          {...tabProps}
        />
      </TabNav>
      <Layout
        direction="column"
        className="p-5 min-w-0 min-h-0 flex-1 relative"
      >
        {activeSection === 'headerOverrides' && (
          <HeaderOverrides accessToken={accessToken} />
        )}
        {activeSection === 'launchDarklyFlags' && featureFlags && (
          <LaunchDarklyFlags
            featureFlags={featureFlags}
            featureFlagOverrides={featureFlagOverrides || {}}
          />
        )}
        {activeSection === 'playground' && accessToken && (
          <Playground accessToken={accessToken} />
        )}
      </Layout>
    </>
  );
};

export default function DeveloperControlsModal({
  show,
  onHide,
  ...props
}: Props) {
  const { activeSection } = useDevContext();
  return (
    <Modal
      show={show}
      onHide={onHide}
      variant="dark"
      className={css`
        > div:first-child {
          @apply max-h-12;
        }
      `}
      size={activeSection === 'playground' ? 'lg' : 'md'}
    >
      <ModalContent {...(props as any)} onHide={onHide} show={show} />
    </Modal>
  );
}
