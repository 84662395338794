/**
 * @generated SignedSource<<57a927de942d26c2c2f5dbb216847a31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostStudyImageCommentInput = {
  body: string;
  clientMutationId?: string | null;
  idempotencyKey?: string | null;
  imageId: any;
  organizationId: any;
};
export type ExamImageComments_PostCommentMutation$variables = {
  input: PostStudyImageCommentInput;
};
export type ExamImageComments_PostCommentMutation$data = {
  readonly postStudyImageCommentOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
    readonly studyImageCommentEdge?: {
      readonly node: {
        readonly image: {
          readonly numComments: number | null;
          readonly study: {
            readonly numImageComments: number | null;
          } | null;
          readonly viewerIsFollowing: boolean | null;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"CommentListItem_comment" | "CommentPreview_comments">;
      } | null;
    } | null;
  } | null;
};
export type ExamImageComments_PostCommentMutation = {
  response: ExamImageComments_PostCommentMutation$data;
  variables: ExamImageComments_PostCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numImageComments",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numComments",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsFollowing",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamImageComments_PostCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "postStudyImageCommentOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyImageCommentEdge",
                "kind": "LinkedField",
                "name": "studyImageCommentEdge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyImageComment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyImage",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Study",
                            "kind": "LinkedField",
                            "name": "study",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CommentPreview_comments"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CommentListItem_comment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "PostStudyImageCommentPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamImageComments_PostCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "postStudyImageCommentOrError",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyImageCommentEdge",
                "kind": "LinkedField",
                "name": "studyImageCommentEdge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyImageComment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyImage",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Study",
                            "kind": "LinkedField",
                            "name": "study",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Avatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Thumbnail",
                                    "kind": "LinkedField",
                                    "name": "thumbnails",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "width",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "ThumbnailedInterface",
                                "abstractKey": "__isThumbnailedInterface"
                              }
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "mentions",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "handle",
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerCanDelete",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "PostStudyImageCommentPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e52c15652ae8b9079be41d13d436aed7",
    "id": null,
    "metadata": {},
    "name": "ExamImageComments_PostCommentMutation",
    "operationKind": "mutation",
    "text": "mutation ExamImageComments_PostCommentMutation(\n  $input: PostStudyImageCommentInput!\n) {\n  postStudyImageCommentOrError(input: $input) {\n    __typename\n    ... on PostStudyImageCommentPayload {\n      studyImageCommentEdge {\n        node {\n          image {\n            study {\n              numImageComments\n              id\n            }\n            numComments\n            viewerIsFollowing\n            id\n          }\n          ...CommentPreview_comments\n          ...CommentListItem_comment\n          id\n        }\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment CommentAuthor_comment on StudyImageComment {\n  createdBy {\n    ...Avatar_userProfile\n    name\n    id\n  }\n}\n\nfragment CommentBody_comment on StudyImageComment {\n  body\n  mentions {\n    handle\n    ...CommentMention_userProfile\n    id\n  }\n}\n\nfragment CommentListItem_comment on StudyImageComment {\n  createdBy {\n    ...Avatar_userProfile\n    name\n    id\n  }\n  id\n  createdAt\n  viewerCanDelete\n  ...CommentAuthor_comment\n  ...CommentBody_comment\n  ...DeleteStudyImageCommentControl_comment\n  ...CommentListItem_isCommentAuthor\n}\n\nfragment CommentListItem_isCommentAuthor on StudyImageComment {\n  createdBy {\n    id\n  }\n}\n\nfragment CommentMention_userProfile on UserProfile {\n  name\n}\n\nfragment CommentPreview_comments on StudyImageComment {\n  ...CommentAuthor_comment\n  ...CommentBody_comment\n}\n\nfragment DeleteStudyImageCommentControl_comment on StudyImageComment {\n  id\n  image {\n    id\n  }\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cdab0c97957146c1445421bafe59ce2";

export default node;
