import { OpenDialogOptions, useDialog } from '@bfly/ui2/DialogContext';
import useRouter from 'found/useRouter';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export function useShowDiscardChangesDialog(
  options?: OpenDialogOptions<boolean>,
) {
  const dialog = useDialog();
  return () =>
    dialog
      .open(
        <FormattedMessage
          id="form.confirmDiscardChanges.content"
          defaultMessage="Are you sure you want to leave without saving? Your changes will be lost."
        />,
        {
          title: (
            <FormattedMessage
              id="form.confirmDiscardChanges.title"
              defaultMessage="Discard Unsaved Changes?"
            />
          ),
          ...options,
        },
      )
      .then(Boolean);
}

export default function useChangesDialog(
  options?: OpenDialogOptions<boolean>,
) {
  const dialog = useDialog();
  const routerState = useRouter();
  // can actually be null
  const router = routerState?.router;

  const showDiscardChangesDialog = useShowDiscardChangesDialog(options);

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    if (!router) return undefined;

    const removeNavigationListener = router.addNavigationListener(
      // This function must not be async, as we need a synchronous return
      //  value for the beforeunload handler.
      (location) => {
        if (!location) return false;

        if (!unsavedChanges) {
          return true;
        }

        return showDiscardChangesDialog().then((confirmed) => {
          if (confirmed) {
            removeNavigationListener();
          }

          return confirmed;
        });
      },
      { beforeUnload: unsavedChanges },
    );

    return removeNavigationListener;
  }, [dialog, unsavedChanges, options, router, showDiscardChangesDialog]);

  return {
    setUnsavedChanges,
  };
}
