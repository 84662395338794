import Form, { getter } from '@bfly/ui2/Form';
import type { FormErrors, FormProps } from '@bfly/ui2/Form';
import useMutationWithError, {
  MutationErrorFragment,
  MutationParametersWithError,
  UseMutatationWithErrorsConfig,
  isMutationError,
} from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import isEqual from 'lodash/isEqual';
import { join } from 'property-expr';
import React, { useCallback, useMemo, useRef } from 'react';
import { type FormHandle } from 'react-formal/Form';
import { FormattedMessage } from 'react-intl';
import { GraphQLTaggedNode, graphql } from 'react-relay';
import type { AnyObjectSchema, Asserts } from 'yup';

import useSafeUseState from 'hooks/useSafeUseState';

import errorMessages from '../messages/error';
import type { RelayForm_error$data as RelayFormError } from './__generated__/RelayForm_error.graphql';

interface RelayFormErrorFragment extends MutationErrorFragment {
  readonly fields?: ReadonlyArray<{
    readonly message: string;
    readonly path: ReadonlyArray<string>;
  }>;
}

export interface RelayOperationWithFormError<T = unknown>
  extends MutationParametersWithError<T> {
  response: {
    [index: string]: (T & Partial<RelayFormErrorFragment>) | null;
  };
}

export type { FormHandle };
export interface RelayFormProps<
  T extends RelayOperationWithFormError,
  TSchema extends AnyObjectSchema,
> extends FormProps<TSchema> {
  id?: string;
  mutation: GraphQLTaggedNode;
  formRef?: React.Ref<FormHandle>;
  onChange?: (value: any, updatedPaths: string[]) => void;
  getInput: (value: Asserts<TSchema>) => T['variables']['input'];
  onCompleted?: (
    response: T['response'] & { formValue: Asserts<TSchema> },
  ) => void;
  toastUnknownErrors?: boolean;
  updater?: UseMutatationWithErrorsConfig<T>['updater'];
  /** Show non-field mutation errors in the form instead of with toasts. */
  addServerErrorsToFormErrors?: boolean;
  /** Transform server errors to match client errors. */
  transformServerErrors?: (errors: FormErrors) => FormErrors;
  children?: React.ReactNode;
}

const _ = graphql`
  fragment RelayForm_error on ErrorInterface {
    ... on InvalidInputError {
      fields {
        message
        path
      }
    }
    ... on MaxSeatsExceededError {
      message
    }
    ...mutationError_error @relay(mask: false)
  }
`;

function RelayForm<
  T extends RelayOperationWithFormError,
  TSchema extends AnyObjectSchema,
>(props: RelayFormProps<T, TSchema>) {
  const {
    mutation,
    value,
    onChange,
    getInput,
    onCompleted,
    updater,
    addServerErrorsToFormErrors = false,
    transformServerErrors = (errors) => errors,
    toastUnknownErrors = true,
    // Allow errors to be controlled
    errors: propsErrors,
    onError,
    formRef,
    ...formProps
  } = props;

  const toast = useToast();

  const [clientErrors, setClientErrors] = useSafeUseState<FormErrors>({});
  const [serverErrors, setServerErrors] = useSafeUseState<FormErrors>({});

  const valueRef = useRef(value);
  const prevValue = valueRef.current;
  valueRef.current = value;

  // FIXME: === should be enough here, but e.g. <EnterpriseConnectorForm>
  //  updates value on every render.
  if (!isEqual(value, prevValue)) {
    const nextServerErrors = { ...serverErrors };

    // Server errors on any changed fields are no longer relevant.
    Object.keys(serverErrors).forEach((path) => {
      if (getter(path, value) !== getter(path, prevValue)) {
        delete nextServerErrors[path];
      }
    });

    // Form-level server errors are irrelevant now, too.
    delete nextServerErrors[''];

    setServerErrors(nextServerErrors);
  }

  const errors = useMemo(
    () => ({
      ...(propsErrors || clientErrors),
      ...serverErrors,
    }),
    [clientErrors, propsErrors, serverErrors],
  );

  const [mutate] = useMutationWithError<T>(mutation, {
    updater,
  });

  const handleMutationError = useCallback(
    (error: Error) => {
      const nextServerErrors = {};

      if (isMutationError<RelayFormError>(error)) {
        if (error.errorType === 'InvalidInputError') {
          for (const { message, path } of error.data.fields!) {
            // Build yup path.
            const fieldName = join(path.slice(1));
            // Showing toast for general schema errors that are not field specific
            if (fieldName === 'schema') {
              toast.error(error.message);
            }
            nextServerErrors[fieldName] = [{ message }];
          }
        } else if (error.errorType === 'MaxSeatsExceededError') {
          toast.error(error.message);
        } else if (addServerErrorsToFormErrors) {
          nextServerErrors[''] = [{ message: error.message }];
        } else if (toastUnknownErrors) {
          toast.error(error.message);
        }
      } else {
        toast.error(<FormattedMessage {...errorMessages.request} />);
      }

      setServerErrors(transformServerErrors(nextServerErrors));
    },
    [
      addServerErrorsToFormErrors,
      setServerErrors,
      toast,
      transformServerErrors,
      toastUnknownErrors,
    ],
  );

  const submitForm = useCallback(
    async (formValue: any) => {
      try {
        await mutate({
          input: getInput(formValue),
          onCompleted: (response) => {
            if (onCompleted) {
              onCompleted({ ...response, formValue });
            }
          },
        });
      } catch (e: any) {
        // We can't use onError here, because we want the promise to reject
        // on a mutation error.
        handleMutationError(e);
        throw e;
      }

      setServerErrors({});
    },
    [setServerErrors, mutate, getInput, onCompleted, handleMutationError],
  );

  return (
    <Form<TSchema>
      {...formProps}
      value={value}
      errors={errors}
      ref={formRef}
      onChange={onChange}
      onError={(e) => {
        onError?.(e);
        setClientErrors(e);
      }}
      submitForm={submitForm}
    />
  );
}

export default RelayForm;
