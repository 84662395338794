/**
 * @generated SignedSource<<07fcadc7b978665a2b5e62d2b811a179>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyHistoryModalContent_finalizationRequestedStudyEvent$data = {
  readonly finalizationRequests: ReadonlyArray<{
    readonly recipient: {
      readonly name: string | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "StudyHistoryModalContent_finalizationRequestedStudyEvent";
};
export type StudyHistoryModalContent_finalizationRequestedStudyEvent$key = {
  readonly " $data"?: StudyHistoryModalContent_finalizationRequestedStudyEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyHistoryModalContent_finalizationRequestedStudyEvent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyHistoryModalContent_finalizationRequestedStudyEvent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyFinalizationRequest",
      "kind": "LinkedField",
      "name": "finalizationRequests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "recipient",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FinalizationRequestedStudyEvent",
  "abstractKey": null
};

(node as any).hash = "fc5d45cc7decc3a0a7f12d726f709e04";

export default node;
