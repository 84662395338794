import DropdownForm, { DropdownFormProps } from '@bfly/ui2/DropdownForm';
import Form from '@bfly/ui2/Form';
import Multiselect, { MultiselectProps } from '@bfly/ui2/Multiselect';
import { css } from 'astroturf';
import clsx from 'clsx';
import {
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MessageDescriptor } from 'react-intl';
import { dataItem, dataText } from 'react-widgets/Accessors';
import { useUncontrolledProp } from 'uncontrollable';
import { array, object } from 'yup';

import { CheckGroupSelectButtonLabel } from './CheckGroupSelectButton';

type BaseMultiselectProps<TData> = Pick<
  MultiselectProps<TData>,
  | 'dataKey'
  | 'textField'
  | 'renderTagValue'
  | 'renderListItem'
  | 'busy'
  | 'searchTerm'
  | 'onSearch'
  | 'filter'
>;

const schema = object({
  value: array().nullable(),
}).nullable();

export const DropdownMultiselect: Multiselect = forwardRef<
  HTMLDivElement,
  MultiselectProps<unknown>
>((props, ref) => {
  return (
    <Multiselect
      showPlaceholderWithValues
      {...props}
      ref={ref}
      inline
      css={css`
        :global(.rw-multiselect-input) {
          padding: 0;
        }

        :global(.rw-multiselect-tag:last-of-type) {
          margin-right: theme('margin.3');
        }
        :global(.rw-list-optgroup) {
          color: white;
        }
        :global(.rw-list-option) {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        [role='group'] + [role='group'] {
          margin-top: 2rem;
        }
      `}
      containerClassName={clsx(
        props.containerClassName,
        'mx-4 border-0 border-b border-solid bg-transparent border-divider rounded-none shadow-none focus-within:border-primary w-auto',
      )}
    />
  );
});

DropdownMultiselect.displayName = 'DropdownMultiselect';

export interface MultiselectSelectButtonProps<TData>
  extends BaseMultiselectProps<TData>,
    Omit<DropdownFormProps<any>, 'onChange' | 'value' | 'children' | 'label'> {
  data: TData[];
  value: readonly any[] | null;
  onChange: (value: TData[] | null) => void;
  placeholder?: ReactNode;
  searchPlaceholder?: MessageDescriptor;
  renderValue?: (options: { item: TData }) => ReactNode;
  renderOverflowItem?: (options: { item: TData }) => ReactNode;
}

function MultiselectSelectButton<TData>({
  data,
  value = null,
  onChange,
  dataKey,
  textField,
  placeholder,
  searchPlaceholder,
  renderTagValue,
  renderValue = ({ item }) => dataText(item, textField),
  renderListItem = renderValue,
  renderOverflowItem = renderValue,
  busy,
  searchTerm: searchTermProp,
  onSearch: onSearchProp,
  filter,
  ...props
}: MultiselectSelectButtonProps<TData>) {
  const ref = useRef<HTMLDivElement>(null);
  const [searchTerm, onSearch] = useUncontrolledProp(
    searchTermProp,
    '',
    onSearchProp,
  );

  const [show, setShow] = useState(false);

  const handleChange = (formValue: { value: any } | null) => {
    onChange(formValue?.value);
  };

  const valueDataItems = useMemo(
    () => value?.map((item) => dataItem(data, item, dataKey)) ?? null,
    [value, data, dataKey],
  );

  const formValue = useMemo(() => (value?.length ? { value } : null), [value]);

  useEffect(() => {
    if (show) {
      // the first open the dropdown doesn't exist so give it a tick to render
      requestAnimationFrame(() => {
        ref.current?.focus();
      });
    }
  }, [show]);

  return (
    <DropdownForm
      {...props}
      show={show}
      schema={schema}
      variant="secondary"
      value={formValue}
      onChange={handleChange}
      onToggle={(nextShow) => {
        setShow(nextShow);
        if (!nextShow) {
          onSearch('', { action: 'clear' });
        }
      }}
      placement="bottom-end"
      label={
        !valueDataItems?.length ? (
          placeholder
        ) : (
          <CheckGroupSelectButtonLabel
            show={show}
            dataItems={valueDataItems}
            renderValue={renderValue}
            renderOverflowItem={renderOverflowItem}
          />
        )
      }
    >
      <Form.Field name="value">
        {(inputProps) => (
          <DropdownMultiselect<TData>
            {...inputProps}
            filter={filter}
            ref={ref}
            data={data}
            busy={busy}
            dataKey={dataKey}
            value={inputProps.value || []}
            renderTagValue={renderTagValue}
            renderListItem={renderListItem}
            textField={textField}
            menuVariant="dark"
            searchTerm={searchTerm}
            showPlaceholderWithValues
            placeholder={searchPlaceholder}
            onChange={(nextValue) => {
              onSearch('', { action: 'clear' });
              inputProps.onChange(nextValue);
            }}
            onSearch={(nextSearchTerm, meta) => {
              if (meta.action === 'clear') return;
              onSearch(nextSearchTerm, meta);
            }}
          />
        )}
      </Form.Field>
      <hr className="mx-4" />
      <DropdownForm.Footer />
    </DropdownForm>
  );
}

export default MultiselectSelectButton;
