import { Column, ColumnDef, ColumnState, isColumnGroup } from '../types';

function applySortToColumns(cols: ColumnDef[], state: ColumnState[]) {
  const getStateIndex = (col: ColumnDef) => {
    const colId = isColumnGroup(col) ? col.children[0].colId : col.colId;
    return state.findIndex((s) => s.colId === colId);
  };

  return cols.sort((c1, c2) => {
    const c1Index = getStateIndex(c1);
    const c2Index = getStateIndex(c2);
    if (c1Index === c2Index) return 0;
    return c1Index > c2Index ? 1 : -1;
  });
}

export default function applyStateToColumns(
  cols: ColumnDef[],
  columnsState: ColumnState[],
): ColumnDef[] {
  const applyStateToColumn = (col: Column): Column => {
    const state = columnsState.find((s) => s.colId === col.colId);
    return {
      ...col,
      width: typeof state?.width === 'number' ? state.width : col.width,
      hide: typeof state?.hide === 'boolean' ? state.hide : col.hide,
      pinned: typeof state?.pinned === 'boolean' ? state.pinned : col.pinned,
    };
  };

  // apply state defaults
  return applySortToColumns(cols, columnsState).map(
    (col): ColumnDef =>
      isColumnGroup(col)
        ? {
            ...col,
            children: col.children.map(applyStateToColumn),
          }
        : applyStateToColumn(col),
  );
}
