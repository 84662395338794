/**
 * @generated SignedSource<<45567b16da0b80ee6038449e4b01a7af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalStudySearchDomainPage_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_tenant">;
  readonly " $fragmentType": "GlobalStudySearchDomainPage_tenant";
};
export type GlobalStudySearchDomainPage_tenant$key = {
  readonly " $data"?: GlobalStudySearchDomainPage_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchDomainPage_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalStudySearchDomainPage_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BaseAppPage_tenant"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "078ca36af258e2a23402fd69f52e2d28";

export default node;
