/**
 * @generated SignedSource<<60c5822251ce9f1df360928bce1d8a69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetArchivePrivacyInput = {
  archiveId: string;
  clientMutationId?: string | null;
  isPrivate: boolean;
};
export type ArchivePrivacyFormMutation$variables = {
  input: SetArchivePrivacyInput;
};
export type ArchivePrivacyFormMutation$data = {
  readonly setArchivePrivacyOrError: {
    readonly __typename?: string;
    readonly archive?: {
      readonly isPrivate: boolean | null;
    } | null;
    readonly message?: string | null;
  } | null;
};
export type ArchivePrivacyFormMutation = {
  response: ArchivePrivacyFormMutation$data;
  variables: ArchivePrivacyFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPrivate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchivePrivacyFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setArchivePrivacyOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Archive",
                "kind": "LinkedField",
                "name": "archive",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "SetArchivePrivacyPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArchivePrivacyFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setArchivePrivacyOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Archive",
                "kind": "LinkedField",
                "name": "archive",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SetArchivePrivacyPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d83a6340431332eb5174045b4ee0c0d",
    "id": null,
    "metadata": {},
    "name": "ArchivePrivacyFormMutation",
    "operationKind": "mutation",
    "text": "mutation ArchivePrivacyFormMutation(\n  $input: SetArchivePrivacyInput!\n) {\n  setArchivePrivacyOrError(input: $input) {\n    __typename\n    ... on SetArchivePrivacyPayload {\n      archive {\n        isPrivate\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86f3b9b75f313128dd36934fbb69a245";

export default node;
