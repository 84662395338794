import Dropdown from '@bfly/ui2/Dropdown';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DeleteControl from 'components/DeleteControl';

import { DeleteStudyImageCommentControlMutation } from './__generated__/DeleteStudyImageCommentControlMutation.graphql';
import { DeleteStudyImageCommentControl_comment$data as StudyImageComment } from './__generated__/DeleteStudyImageCommentControl_comment.graphql';

const mutation = graphql`
  mutation DeleteStudyImageCommentControlMutation(
    $input: DeleteStudyImageCommentInput!
  ) {
    deleteStudyImageCommentOrError(input: $input) {
      ... on DeleteStudyImageCommentPayload {
        studyImage {
          numComments
          study {
            numImageComments
          }
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  comment: StudyImageComment;
}

function DeleteStudyImageCommentControl({ comment }: Props) {
  const [mutate] =
    useMutationWithError<DeleteStudyImageCommentControlMutation>(mutation, {
      input: {
        studyImageCommentId: comment.id,
        studyImageId: comment.image!.id,
      },
      updater: (store) => {
        rangeDeleteUpdater(store, {
          parentId: comment.image!.id,
          connectionKey: 'StudyImage_commentConnection',
          deletedId: comment.id,
        });
      },
    });

  return (
    <DeleteControl
      as={Dropdown.Item}
      data-bni-id="DeleteComment"
      runCommit={mutate}
      dialogTitle={
        <FormattedMessage
          id="comment.delete.dialog.title"
          defaultMessage="Delete Comment?"
        />
      }
      buttonText={
        <FormattedMessage
          id="comment.delete"
          defaultMessage="Delete Comment"
        />
      }
    />
  );
}

export default createFragmentContainer(DeleteStudyImageCommentControl, {
  comment: graphql`
    fragment DeleteStudyImageCommentControl_comment on StudyImageComment {
      id
      image {
        id
      }
    }
  `,
});
