/**
 * @generated SignedSource<<66710b915404855241f83cb57eb00af5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSavedSearchModal_tenant$data = {
  readonly id: string;
  readonly slug?: string | null;
  readonly " $fragmentType": "CreateSavedSearchModal_tenant";
};
export type CreateSavedSearchModal_tenant$key = {
  readonly " $data"?: CreateSavedSearchModal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateSavedSearchModal_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateSavedSearchModal_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "type": "Organization",
      "abstractKey": null
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};

(node as any).hash = "64ec571ded8e65398ee0d134e55c6992";

export default node;
