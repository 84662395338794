/**
 * @generated SignedSource<<b63399d3095f16742bbe59e55b12c87a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddExamTypeInput = {
  clientMutationId?: string | null;
  examTypeId: string;
  studyId: string;
};
export type useHandleExamTypeChange_AddMutation$variables = {
  input: AddExamTypeInput;
};
export type useHandleExamTypeChange_AddMutation$data = {
  readonly addExamTypeOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
    readonly study?: {
      readonly examTypes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
      } | null> | null;
      readonly id: string;
    } | null;
  } | null;
};
export type useHandleExamTypeChange_AddMutation$rawResponse = {
  readonly addExamTypeOrError: {
    readonly __typename: "AddExamTypePayload";
    readonly __isErrorInterface: "AddExamTypePayload";
    readonly message: string | null;
    readonly study: {
      readonly examTypes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
      } | null> | null;
      readonly id: string;
    } | null;
  } | {
    readonly __typename: string;
    readonly __isErrorInterface: string;
    readonly message: string | null;
  } | null;
};
export type useHandleExamTypeChange_AddMutation = {
  rawResponse: useHandleExamTypeChange_AddMutation$rawResponse;
  response: useHandleExamTypeChange_AddMutation$data;
  variables: useHandleExamTypeChange_AddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Study",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamType",
          "kind": "LinkedField",
          "name": "examTypes",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AddExamTypePayload",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useHandleExamTypeChange_AddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addExamTypeOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHandleExamTypeChange_AddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addExamTypeOrError",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a68424bd39ddd3bfb3f5523e036957d",
    "id": null,
    "metadata": {},
    "name": "useHandleExamTypeChange_AddMutation",
    "operationKind": "mutation",
    "text": "mutation useHandleExamTypeChange_AddMutation(\n  $input: AddExamTypeInput!\n) {\n  addExamTypeOrError(input: $input) {\n    __typename\n    ... on AddExamTypePayload {\n      study {\n        id\n        examTypes {\n          id\n          name\n        }\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d46265e2816fe329857d2137b81aed1f";

export default node;
