/**
 * @generated SignedSource<<0da174da34fc88f311d7e1b4d2113fee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppLeadingNav_searchNodes$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"AppSearchGlobal_searchNodes">;
  readonly " $fragmentType": "AppLeadingNav_searchNodes";
}>;
export type AppLeadingNav_searchNodes$key = ReadonlyArray<{
  readonly " $data"?: AppLeadingNav_searchNodes$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppLeadingNav_searchNodes">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AppLeadingNav_searchNodes",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppSearchGlobal_searchNodes"
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};

(node as any).hash = "ea446e060e8dff7a2f5ae36e8c6b3a3c";

export default node;
