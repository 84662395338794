import { Redirect } from 'found';
import { graphql } from 'react-relay';

import Route, {
  Match,
  renderStaleWhileFetching,
  renderWithoutData,
} from 'components/Route';
import { prepareVariablesWithPagination } from 'hooks/usePagedConnection';
import { routes } from 'routes/analytics';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';

import { analyticsRoutes_AllTimeStatsCardsQuery as AllTimeStatsCardsQuery } from './__generated__/analyticsRoutes_AllTimeStatsCardsQuery.graphql';
import { analyticsRoutes_CredentialingPageQuery as CredentialingPageQuery } from './__generated__/analyticsRoutes_CredentialingPageQuery.graphql';
import { analyticsRoutes_CredentialingPerformanceIndicatorsQuery as CredentialingPerformanceIndicatorsQuery } from './__generated__/analyticsRoutes_CredentialingPerformanceIndicatorsQuery.graphql';
import AllTimeStatsCards from './components/AllTimeStatsCards';
import AllTimeStatsPage from './components/AllTimeStatsPage';
import AllTimeStatsUsers from './components/AllTimeStatsUsers';
import AnalyticsBasePage from './components/AnalyticsBasePage';
import CredentialingPage, {
  getCredentialingFilters,
  serializeCredentialingFilters,
} from './components/CredentialingPage';
import CredentialingPerformanceIndicators from './components/CredentialingPerformanceIndicators';
import CredentialingProgressSection from './components/CredentialingProgressSection';
import SushiRolls from './components/SushiRolls';
import { prepareAllTimeStatsVariables } from './schemas/statsFilters';

function prepareCredentialingVariables(
  variables: Record<string, unknown>,
  { location }: Match,
) {
  const filter = serializeCredentialingFilters(
    getCredentialingFilters(location),
  );
  const vars = {
    ...variables,
    filter,
    userProgressSort: location.query?.sort ? [location.query?.sort] : null,
    studyStatsFilter: {
      author: filter.user,
      credentialGroup: filter.credentialGroup,
      examType: filter.examType,
    },
  };
  return vars;
}

function prepareProficiencyPerformanceIndicators(
  variables: any,
  match: Match,
) {
  const vars = prepareCredentialingVariables(variables, match);
  return {
    ...vars,
    studyStatsFilter: {
      ...vars.studyStatsFilter,
      isRelevantForCredentialing: true,
    },
    lmsStatisticsFilterInputValue: {
      groupId: vars.filter.credentialGroup,
      userId: vars.filter.user,
    },
  };
}

export const analyticsRoutes = (
  <Route
    path="analytics"
    hideSearch
    Component={AnalyticsBasePage}
    query={graphql`
      query analyticsRoutes_AnalyticsBasePage_Query {
        viewer {
          ...AnalyticsBasePage_viewer
        }
      }
    `}
  >
    <Redirect
      to={(match) => ({
        pathname: routes.stats({
          organizationSlug: match.params?.organizationSlug || '-',
        }),
      })}
    />
    <Redirect
      from="qa"
      to={(match) => ({
        ...match.location,
        pathname: routes.stats({
          organizationSlug: match.params?.organizationSlug || '-',
        }),
      })}
    />
    <Route<CredentialingPageQuery>
      hideSidePanel
      path="proficiency"
      prerender={checkFlagsOr404('credentialing-analytics-page')}
      Component={CredentialingPage}
      render={renderStaleWhileFetching}
      prepareVariables={(variables, { location }) => {
        const credentialGroupId =
          getCredentialingFilters(location)?.credentialGroup?.[0];
        return {
          ...variables,
          credentialGroupId: credentialGroupId || 'none',
          hasCredentialGroup: !!credentialGroupId,
        };
      }}
      query={graphql`
        query analyticsRoutes_CredentialingPageQuery(
          $organizationSlug: String
          $credentialGroupId: ID!
          $hasCredentialGroup: Boolean!
        ) {
          tenant(slug: $organizationSlug) {
            ...CredentialingPage_tenant
          }
          credentialGroup: node(id: $credentialGroupId)
            @include(if: $hasCredentialGroup) {
            ...CredentialingPage_credentialGroup
          }
        }
      `}
    >
      {{
        performanceIndicators: (
          <Route<CredentialingPerformanceIndicatorsQuery>
            defer
            Component={CredentialingPerformanceIndicators}
            render={renderWithoutData}
            prepareVariables={prepareProficiencyPerformanceIndicators}
            query={graphql`
              query analyticsRoutes_CredentialingPerformanceIndicatorsQuery(
                $organizationSlug: String
                $filter: CredentialingPageFilterInput!
                $studyStatsFilter: StudyStatisticsFilterInput!
                $lmsStatisticsFilterInputValue: LmsStatisticsFilterInput!
              ) {
                tenant(slug: $organizationSlug) {
                  ...CredentialingPerformanceIndicators_tenant
                }
              }
            `}
          />
        ),
        sushiRolls: (
          <Route
            Component={SushiRolls}
            render={renderStaleWhileFetching}
            prepareVariables={prepareCredentialingVariables}
            query={graphql`
              query analyticsRoutes_SushiRollsQuery(
                $organizationSlug: String
                $filter: CredentialingPageFilterInput!
              ) {
                tenant(slug: $organizationSlug) {
                  ...SushiRolls_tenant
                }
              }
            `}
          />
        ),
        userProgressList: (
          <Route
            Component={CredentialingProgressSection}
            prepareVariables={prepareVariablesWithPagination(
              prepareCredentialingVariables,
            )}
            query={graphql`
              query analyticsRoutes_CredentialingProgressSectionQuery(
                $organizationSlug: String
                $filter: CredentialingPageFilterInput!
                $userProgressSort: [CredentialGroupMemberProgressSorting!]
                $studyStatsFilter: StudyStatisticsFilterInput!
                $after: String
                $first: Int
              ) {
                tenant(slug: $organizationSlug) {
                  ...CredentialingProgressSection_tenant
                    @arguments(
                      after: $after
                      first: $first
                      filter: $filter
                      userProgressSort: $userProgressSort
                      studyStatsFilter: $studyStatsFilter
                    )
                }
              }
            `}
          />
        ),
      }}
    </Route>
    <Route
      hideSidePanel
      name="all_time_stats"
      path="stats"
      prerender={checkFlagsOr404('worksheets-review')}
      Component={AllTimeStatsPage}
      query={graphql`
        query analyticsRoutes_AllTimeStatsPage_Query(
          $organizationSlug: String
        ) {
          tenant(slug: $organizationSlug) {
            ...AllTimeStatsPage_tenant
          }
        }
      `}
    >
      {{
        topLevelStats: (
          <Route<AllTimeStatsCardsQuery>
            defer
            Component={AllTimeStatsCards}
            render={renderWithoutData}
            prepareVariables={prepareAllTimeStatsVariables}
            query={graphql`
              query analyticsRoutes_AllTimeStatsCardsQuery(
                $organizationSlug: String
                $filter: StudyStatisticsFilterInput!
              ) {
                tenant(slug: $organizationSlug) {
                  ...AllTimeStatsCards_tenant @arguments(filter: $filter)
                }
              }
            `}
          />
        ),
        usersList: (
          <Route
            Component={AllTimeStatsUsers}
            prepareVariables={prepareVariablesWithPagination(
              prepareAllTimeStatsVariables,
            )}
            query={graphql`
              query analyticsRoutes_AllTimeStatsUsersQuery(
                $organizationSlug: String
                $filter: StudyStatisticsFilterInput!
              ) {
                tenant(slug: $organizationSlug) {
                  ...AllTimeStatsUsers_tenant @arguments(filter: $filter)
                }
              }
            `}
          />
        ),
      }}
    </Route>
  </Route>
);
