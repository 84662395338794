/**
 * @generated SignedSource<<87ef32b80da748e78555db0bb71c83da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RestrictedFinalizationForm_archive$data = {
  readonly attestationMessage: string | null;
  readonly hasRestrictedFinalization: boolean | null;
  readonly id: string;
  readonly " $fragmentType": "RestrictedFinalizationForm_archive";
};
export type RestrictedFinalizationForm_archive$key = {
  readonly " $data"?: RestrictedFinalizationForm_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"RestrictedFinalizationForm_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RestrictedFinalizationForm_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasRestrictedFinalization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attestationMessage",
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "b7d74c84b8ba5bf12c27b725b0837996";

export default node;
