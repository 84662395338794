import useMountEffect from '@restart/hooks/useMountEffect';
import { createFragmentContainer, graphql } from 'react-relay';

import LoadingIndicatorPage from 'components/LoadingIndicatorPage';
import { RoutePageProps } from 'components/Route';
import useZendeskLocale from 'hooks/useZendeskLocale';
import ExternalRedirect from 'utils/ExternalRedirect';

import type { ZendeskSsoPage_viewer$data as Viewer } from './__generated__/ZendeskSsoPage_viewer.graphql';

interface Props extends RoutePageProps {
  viewer: Viewer;
}

const { ZENDESK_URL } = globalThis.bflyConfig;

function ZendeskSsoPage({ viewer, match }: Props) {
  const zendeskLocale = useZendeskLocale();

  useMountEffect(() => {
    const search = new URLSearchParams([
      ['return_to', match.location.query.return_to || `/hc/${zendeskLocale}`],
      ['jwt', viewer.zendeskToken!.jwt!],
    ]);

    ExternalRedirect.redirect(`${ZENDESK_URL}/access/jwt?${search}`);
  });

  return <LoadingIndicatorPage />;
}

export default createFragmentContainer(ZendeskSsoPage, {
  viewer: graphql`
    fragment ZendeskSsoPage_viewer on Viewer {
      zendeskToken {
        jwt
      }
    }
  `,
});
