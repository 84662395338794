/**
 * @generated SignedSource<<499333422fcded17938571f0b5525397>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantMembershipAutoComplete_tenant$data = {
  readonly slug?: string | null;
  readonly " $fragmentType": "TenantMembershipAutoComplete_tenant";
};
export type TenantMembershipAutoComplete_tenant$key = {
  readonly " $data"?: TenantMembershipAutoComplete_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TenantMembershipAutoComplete_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TenantMembershipAutoComplete_tenant",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "type": "Organization",
      "abstractKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "5a3537c97c50e3660fcf5762fd817225";

export default node;
