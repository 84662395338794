import { graphql, readInlineData } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import {
  studyOrArchiveIsDeleted,
  studyReviewStatus,
} from 'utils/StudyPermissions';

import { examPageSidebar_useSections_study$key as UseExamSidebarSectionsKey } from './__generated__/examPageSidebar_useSections_study.graphql';
import { examPageSidebar_useShowQa_study$key as UseShowQaKey } from './__generated__/examPageSidebar_useShowQa_study.graphql';

export function useShowQa(studyRef: UseShowQaKey) {
  const canUseReviews = useVariation('worksheets-review');
  const canQaWithoutWorksheet = useVariation('qa-without-a-worksheet');
  const study = readInlineData(
    graphql`
      fragment examPageSidebar_useShowQa_study on Study @inline {
        viewerCanQa
        finalizedAt
        numWorksheets
        archive {
          worksheetRequiredForQa
        }
        ...StudyPermissions_studyOrArchiveIsDeleted
        ...StudyPermissions_studyReviewStatus
      }
    `,
    studyRef,
  );

  // can't use reviews, or not finalized, or it's deleted
  if (!canUseReviews || !study.finalizedAt || studyOrArchiveIsDeleted(study)) {
    return false;
  }

  // has been reviewed show it
  if (studyReviewStatus(study) === 'REVIEWED') {
    return true;
  }

  // user doesn't have qa permissions
  if (!study.viewerCanQa) {
    return false;
  }

  // no worksheets and
  // 'qa-without-a-worksheet' LD flag is false or
  // archive requires worksheet fo QA
  if (
    !study.numWorksheets &&
    (!canQaWithoutWorksheet || study.archive?.worksheetRequiredForQa)
  ) {
    return false;
  }

  return true;
}

export function useSections(studyRef: UseExamSidebarSectionsKey) {
  const study = readInlineData(
    graphql`
      fragment examPageSidebar_useSections_study on Study @inline {
        finalizedAt
        numWorksheets
        archive {
          worksheetRequiredForQa
        }
        ...examPageSidebar_useShowQa_study
      }
    `,
    studyRef,
  );

  const canUseWorksheets = useVariation('worksheets');
  const canQaWithoutWorksheet = useVariation('qa-without-a-worksheet');

  const showWorksheets =
    canUseWorksheets && (!study.finalizedAt || !!study.numWorksheets);

  const showQa = useShowQa(study);

  const showEmptyWorksheet =
    canUseWorksheets &&
    !study.numWorksheets &&
    (study.archive!.worksheetRequiredForQa ||
      !canQaWithoutWorksheet ||
      (canQaWithoutWorksheet && !study.archive!.worksheetRequiredForQa));

  // show actions in header if anything shows up in sidebar
  // otherwise show actions in sidebar, sidebar can't be empty
  const showHeaderActions = showEmptyWorksheet || showQa || showWorksheets;

  return {
    showHeaderActions,
    showEmptyWorksheet,
    showWorksheets,
    showQa,
  };
}
