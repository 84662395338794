/**
 * @generated SignedSource<<3f14e43d12a20a3886553da2bb863ac1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyAuthorsListItem_author$data = {
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"AvatarList_user">;
  readonly " $fragmentType": "StudyAuthorsListItem_author";
};
export type StudyAuthorsListItem_author$key = {
  readonly " $data"?: StudyAuthorsListItem_author$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyAuthorsListItem_author">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyAuthorsListItem_author",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarList_user"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "a6b46837cbb125b3334248534dcfbee9";

export default node;
