/**
 * @generated SignedSource<<0e15e616a622954915228e6b12a43533>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyImageCommentNotification_notification$data = {
  readonly studyImageComment?: {
    readonly createdBy: {
      readonly " $fragmentSpreads": FragmentRefs<"StudyNotification_userProfile">;
    } | null;
    readonly image: {
      readonly study: {
        readonly deletedAt: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"StudyNotification_study">;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"StudyNotification_image">;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"NotFoundNotification_notification" | "StudyNotification_notification">;
  readonly " $fragmentType": "StudyImageCommentNotification_notification";
};
export type StudyImageCommentNotification_notification$key = {
  readonly " $data"?: StudyImageCommentNotification_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImageCommentNotification_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyImageCommentNotification_notification",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyImageComment",
          "kind": "LinkedField",
          "name": "studyImageComment",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyNotification_userProfile"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyImage",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Study",
                  "kind": "LinkedField",
                  "name": "study",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deletedAt",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StudyNotification_study"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyNotification_image"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "StudyImageCommentNotification",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotFoundNotification_notification"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyNotification_notification"
    }
  ],
  "type": "NotificationInterface",
  "abstractKey": "__isNotificationInterface"
};

(node as any).hash = "700570c658b60adfd16fce3e802f5168";

export default node;
