import CheckIcon from '@bfly/icons/CheckMarkSmall';
import PlusIcon from '@bfly/icons/Plus';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import React from 'react';

const styles = stylesheet`
  @import '../education-variables';

  $tag-color: $category-color;
  $tag-color-hover: white;
  $tag-color-active: $category-color-active;

  .wrapper {
    composes: inline-flex relative font-extrabold from global;

    height: 4rem;
    border-radius: 2rem;
    cursor: pointer;
    user-select: none;
    align-items: center;
    padding: 0 1.5rem;
    color: $tag-color;
    border: 1px solid $tag-color;

    &:hover {
      transition: all 200ms;
      border-color: $tag-color-hover;
      color: $tag-color-hover;
    }

    &.checked {
      color: $tag-color-hover;
      background-color: $tag-color-active;
      border-color: $tag-color-active;
    }
  }

  .light {
    &:hover:not(.checked) {
      border-color: $tag-color-active;
      color: $tag-color-active;
    }
  }

  .input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;

    &,
    &:focus {
      outline: none;
    }
  }

  .check {
    margin-left: 0.5rem;
  }
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string;
  theme?: string;
  disabled?: boolean;
  checked?: boolean;
}

function VideoTagCheck({
  children,
  title = '',
  checked,
  className,
  theme = 'dark',
  ...props
}: Props) {
  return (
    <label
      title={title}
      className={clsx(
        styles.wrapper,
        checked && styles.checked,
        theme && styles[theme],
        className,
      )}
    >
      {children}
      <input
        {...props}
        type="checkbox"
        className={styles.input}
        checked={checked}
      />
      {checked ? (
        <CheckIcon width={13} className={styles.check} />
      ) : (
        <PlusIcon width={13} className={styles.check} />
      )}
    </label>
  );
}

export default VideoTagCheck;
