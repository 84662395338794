import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Text from '@bfly/ui2/Text';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import AgGrid, { Props as AgGridBaseProps } from 'components/AgGrid';
import { ColumnDef } from 'components/AgGrid/types';
import useGridEvents from 'components/AgGrid/utils/gridEvents';
import useEditConfirmationModal from 'components/AgGrid/utils/useEditConfirmationModal';
import useSaveConfirmationModal from 'components/AgGrid/utils/useSaveConfirmationModal';

import { roleOptions, rowData, stateOptions } from './data';

const settingsDefault: Partial<AgGridBaseProps> = {
  columnPicker: true,
  readOnly: false,
  selectable: false,
  loading: false,
  confirmDiscard: true,
};

const columnHeaders = defineMessages({
  user: {
    defaultMessage: 'User',
    id: 'demo.agGrid.column.user',
  },
  firstName: {
    defaultMessage: 'First Name',
    id: 'demo.agGrid.column.firstName',
  },
  middleName: {
    defaultMessage: 'Middle Name',
    id: 'demo.agGrid.column.middleName',
  },
  lastName: {
    defaultMessage: 'Last Name',
    id: 'demo.agGrid.column.lastName',
  },
  state: {
    defaultMessage: 'State',
    id: 'demo.agGrid.column.state',
  },
  status: {
    defaultMessage: 'Status',
    id: 'demo.agGrid.column.status',
  },
  department: {
    defaultMessage: 'Department',
    id: 'demo.agGrid.column.department',
  },
  login: {
    defaultMessage: 'Last Login',
    id: 'demo.agGrid.column.login',
  },
  role: {
    defaultMessage: 'Role',
    id: 'demo.agGrid.column.role',
  },
});

const statusMessages = defineMessages({
  draft: {
    defaultMessage: 'Draft',
    id: 'demo.agGrid.status.draft',
  },
  published: {
    defaultMessage: 'Published',
    id: 'demo.agGrid.status.published',
  },
});

function Component({ settings }: { settings: Partial<AgGridBaseProps> }) {
  const { formatMessage } = useIntl();

  const columns: ColumnDef[] = [
    {
      headerName: formatMessage(columnHeaders.user),
      pinned: 'left',
      groupId: 'user',
      children: [
        {
          headerName: formatMessage(columnHeaders.firstName),
          colId: 'firstName',
          width: 150,
          editable: true,
          initialSort: 'asc',
          secondarySort: 'asc',
        },
        {
          headerName: formatMessage(columnHeaders.middleName),
          colId: 'middleName',
          columnGroupShow: 'open',
        },
        {
          headerName: formatMessage(columnHeaders.lastName),
          colId: 'lastName',
          columnGroupShow: 'open',
        },
      ],
    },
    {
      headerName: formatMessage(columnHeaders.state),
      colId: 'state',
      type: 'array',
      editable: true,
      options: stateOptions,
      multiple: true,
    },
    {
      headerName: formatMessage(columnHeaders.status),
      colId: 'status',
      editable: true,
      type: 'array',
      options: [
        {
          label: formatMessage(statusMessages.draft),
          value: 'draft',
          render: (
            <Text color="draft">{formatMessage(statusMessages.draft)}</Text>
          ),
        },
        {
          label: formatMessage(statusMessages.published),
          value: 'published',
          render: (
            <Text color="success">
              {formatMessage(statusMessages.published)}
            </Text>
          ),
        },
      ],
    },
    {
      headerName: formatMessage(columnHeaders.department),
      colId: 'department',
      editable: true,
      hideFilter: true,
    },
    {
      headerName: formatMessage(columnHeaders.role),
      colId: 'role',
      width: 100,
      type: 'array',
      editable: true,
      options: roleOptions,
      multiple: true,
    },
    {
      headerName: formatMessage(columnHeaders.login),
      colId: 'login',
      type: 'date',
    },
  ];

  const [readOnly, setReadOnly] = useState(true);
  useEffect(() => setReadOnly(!!settings.readOnly), [settings.readOnly]);

  const editConfirmation = useEditConfirmationModal();
  const saveConfirmation = useSaveConfirmationModal();

  const [selected, setSelected] = useState<unknown[]>([]);

  const { deselectAll } = useGridEvents({
    onSelect: (selectedRows) => {
      setSelected(selectedRows);
    },
    onReadOnlyChange: setReadOnly,
  });

  return (
    <>
      <Layout className="min-h-6 mt-4">
        <Text variant="lg" className="mr-auto">
          Name of your Grid
        </Text>
        {!!selected.length && (
          <Layout className=" items-center justify-center">
            <Text>{selected.length} Selected</Text>
            <Button variant="secondary" className="ml-2" onClick={deselectAll}>
              Deselect All
            </Button>
          </Layout>
        )}
        {!readOnly && (
          <Button
            className={clsx('ml-2')}
            onClick={() =>
              editConfirmation().then((confirmed) => setReadOnly(!confirmed))
            }
          >
            Edit
          </Button>
        )}
      </Layout>
      <AgGrid
        id="demoGrid"
        rowData={rowData}
        columns={columns}
        onSaveStateUpdate={({ changes, error, success, cancelled }) => {
          saveConfirmation(changes.length)
            .then((confirmed) => {
              if (confirmed) {
                success(changes.length);
                // eslint-disable-next-line no-console
                console.table(changes);
              } else {
                cancelled();
              }
            })
            .catch(() => error());
        }}
        columnPicker
        {...settings}
        showResetColumnState
        readOnly={readOnly}
        // we want to know about the readonly state outside the grid
      />
    </>
  );
}

export default {
  component: Component,
  settings: settingsDefault,
  path: 'ag-grid',
  title: 'AG Grid',
};
