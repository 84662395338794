/**
 * @generated SignedSource<<c12f77f279a68fdc31e82ee575b779f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VetClientName_vetPatient$data = {
  readonly clientNameFirst: string | null;
  readonly clientNameLast: string | null;
  readonly clientNameMiddle: string | null;
  readonly clientNamePrefix: string | null;
  readonly clientNameSuffix: string | null;
  readonly " $fragmentType": "VetClientName_vetPatient";
};
export type VetClientName_vetPatient$key = {
  readonly " $data"?: VetClientName_vetPatient$data;
  readonly " $fragmentSpreads": FragmentRefs<"VetClientName_vetPatient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VetClientName_vetPatient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNameFirst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNameMiddle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNameLast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNamePrefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNameSuffix",
      "storageKey": null
    }
  ],
  "type": "VetPatient",
  "abstractKey": null
};

(node as any).hash = "8d82feed28f9792bf8b9502b412b6c6a";

export default node;
