/**
 * @generated SignedSource<<61aa9949693e1801318164da24164e55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyFinalizationRequestedNotification_notification$data = {
  readonly study?: {
    readonly createdBy: {
      readonly " $fragmentSpreads": FragmentRefs<"StudyNotification_userProfile">;
    } | null;
    readonly deletedAt: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"StudyNotification_study">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"NotFoundNotification_notification" | "StudyNotification_notification">;
  readonly " $fragmentType": "StudyFinalizationRequestedNotification_notification";
};
export type StudyFinalizationRequestedNotification_notification$key = {
  readonly " $data"?: StudyFinalizationRequestedNotification_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyFinalizationRequestedNotification_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyFinalizationRequestedNotification_notification",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Study",
          "kind": "LinkedField",
          "name": "study",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deletedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyNotification_userProfile"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StudyNotification_study"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "StudyFinalizationRequestedNotification",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotFoundNotification_notification"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyNotification_notification"
    }
  ],
  "type": "NotificationInterface",
  "abstractKey": "__isNotificationInterface"
};

(node as any).hash = "37a78370e1c3d83edf5f37cc4feaf824";

export default node;
