/**
 * @generated SignedSource<<e5f2a3738ba5bcb2940e5d4ed5ed08a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DownloadPage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AppPage_viewer">;
  readonly " $fragmentType": "DownloadPage_viewer";
};
export type DownloadPage_viewer$key = {
  readonly " $data"?: DownloadPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DownloadPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DownloadPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppPage_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "8f170b122883eaf1457774a3c2a8a42a";

export default node;
