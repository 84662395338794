import Layout from '@4c/layout';
import PreviewItem from '@bfly/ui2/PreviewItem';
import PreviewSizeContext from '@bfly/ui2/PreviewSizeContext';
import { stylesheet } from 'astroturf';
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const MIN_ITEM_SIZE_PX = 140;
const ITEM_SPACING_PX = 10;

const styles = stylesheet`
  .placeholder {
    height: calc(${MIN_ITEM_SIZE_PX}px + ${ITEM_SPACING_PX}px);
  }

  .spinner {
    height: 18px;
    margin: 5px 0; // Match the 28px tall button.
  }
`;

interface Props {
  numItems: number;
  maxPreviews: number;
  overflowProps: Partial<React.ComponentProps<typeof PreviewItem.Overflow>>;
  children: React.ReactNode;
}

function PreviewList({
  numItems,
  maxPreviews,
  overflowProps,
  children,
}: Props) {
  const [width, setWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  // TODO: Move this into a shared calculator to avoid repeating this work?
  const onResize = useCallback(() => {
    const { width: nextWidth } = containerRef.current!.getBoundingClientRect();
    setWidth(nextWidth);
  }, []);

  useLayoutEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  const numPreviewsCapacity = useMemo(
    () => Math.floor(width / (MIN_ITEM_SIZE_PX + ITEM_SPACING_PX)),
    [width],
  );

  const numPreviews = Math.min(maxPreviews, numPreviewsCapacity);
  if (!numPreviews) {
    return (
      <div
        ref={containerRef}
        className={numItems ? styles.placeholder : undefined}
      />
    );
  }

  const items = React.Children.toArray(children).slice(0, numPreviews);

  const numPlaceholders = Math.max(numPreviews - numItems, 0);
  const numOverflowItems = Math.max(0, numItems - numPreviews);

  return (
    <PreviewSizeContext.Provider value={width / numPreviews - ITEM_SPACING_PX}>
      <Layout
        ref={containerRef as any}
        // Let flexbox handle horizontal spacing here. This should recover the
        // intended spacing, but if we got the wrong width because of e.g. a
        // page scrollbar, we'll still get something reasonable.
        justify={numPreviews === 1 ? 'flex-end' : 'space-between'}
      >
        {items}
        {Array.from({ length: numPlaceholders }, (_, index) => (
          <PreviewItem.Placeholder
            key={index} // eslint-disable-line react/no-array-index-key
            className={styles.item}
          />
        ))}
        {!!numOverflowItems && (
          <PreviewItem.Overflow
            {...overflowProps}
            data-bni-id="PreviewItemOverflow"
            numOverflowItems={numOverflowItems + 1}
          />
        )}
      </Layout>
    </PreviewSizeContext.Provider>
  );
}

export default Object.assign(PreviewList, {
  Item: PreviewItem,
});
