import captureException from '@bfly/utils/captureException';
import pick from 'lodash/pick';
import {
  Component,
  type ErrorInfo,
  PropsWithChildren,
  type ReactNode,
} from 'react';

import { noticeError } from 'utils/newRelic';
import {
  ViewerContext,
  useViewerAllowMissingContext,
} from 'utils/viewerContext';

import OutageMessage from './OutageMessage';

type Props = PropsWithChildren<{
  fallbackComponent?: ReactNode;
  viewer?: ViewerContext | null;
}>;

/**
 * ErrorBoundary shows a fallback UI when any child components throw an error
 * @param {any} props:Props
 * @property {ReactNode} children - ReactNode to catch any errors from.
 * @property {ReactNode} [fallbackComponent] - ReactNode to show if any errors are caught. Defaults to <OutageMessage />
 *
 * @returns {any}
 */
class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);

    noticeError(new Error(`Error Boundary: URL: ${window.location}`), {
      ...pick(this.props.viewer, ['id', 'email', 'domain.id']),
      originalError: `${error}`,
      errorInfo,
    });

    captureException(error, errorInfo as Record<string, any>);
  }

  render() {
    if (!this.state.hasError) return <>{this.props.children}</>;

    return this.props.fallbackComponent || <OutageMessage />;
  }
}

export default function ErrorBoundaryWithViewerContext(
  props: Omit<Props, 'viewer'>,
) {
  return <ErrorBoundary {...props} viewer={useViewerAllowMissingContext()} />;
}
