/**
 * @generated SignedSource<<14ecbe5fce97282bdee8326955a7224b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCredentialingProgressGrid_credentialStatisticsByMembers$data = ReadonlyArray<{
  readonly examTypeProgress: ReadonlyArray<{
    readonly credentialGroupProgress: ReadonlyArray<{
      readonly credentialGroup: {
        readonly name: string | null;
      } | null;
      readonly numExamsCompleted: number | null;
      readonly numExamsRequired: number | null;
    } | null> | null;
    readonly examType: {
      readonly id: string;
      readonly name: string;
      readonly systemId: string | null;
    } | null;
    readonly numExamsCompleted: number | null;
    readonly numExamsRequired: number | null;
    readonly type: "CredentialStatisticsByMemberExamTypeProgress";
  } | null>;
  readonly id: string;
  readonly lmsUserStatistics: {
    readonly averageProgress: number | null;
    readonly count: number | null;
    readonly lastCompletedAt: string | null;
    readonly type: "LmsUserStatistic";
  };
  readonly numExamsCompleted: number | null;
  readonly numExamsRequired: number | null;
  readonly studyStatistics: {
    readonly examTypeStatistics: ReadonlyArray<{
      readonly examType: {
        readonly id: string;
      } | null;
      readonly numCountsTowardsCredentialing: number | null;
      readonly numFinalizedStudies: number | null;
      readonly numReviewedStudies: number | null;
      readonly studyStatusStatistics: ReadonlyArray<{
        readonly key: string;
        readonly numStudies: number;
      } | null> | null;
    } | null> | null;
    readonly numCountsTowardsCredentialing: number | null;
    readonly numExamTypeAssociations: number | null;
    readonly numFinalizedStudies: number | null;
    readonly numReviewedStudies: number | null;
    readonly studyStatusStatistics: ReadonlyArray<{
      readonly key: string;
      readonly numStudies: number;
    } | null> | null;
    readonly userHistoricalProficiency: {
      readonly examTypeCounts: ReadonlyArray<{
        readonly count: number | null;
        readonly examType: {
          readonly name: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly type: "CredentialStatisticsByMember";
  readonly userProfile: {
    readonly handle: string | null;
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "UserCredentialingProgressGrid_credentialStatisticsByMembers";
}>;
export type UserCredentialingProgressGrid_credentialStatisticsByMembers$key = ReadonlyArray<{
  readonly " $data"?: UserCredentialingProgressGrid_credentialStatisticsByMembers$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCredentialingProgressGrid_credentialStatisticsByMembers">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numExamsRequired",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numExamsCompleted",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numCountsTowardsCredentialing",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numFinalizedStudies",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numReviewedStudies",
  "storageKey": null
},
v8 = [
  (v2/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "StudyStatusStatistics",
  "kind": "LinkedField",
  "name": "studyStatusStatistics",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numStudies",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "studyStatsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserCredentialingProgressGrid_credentialStatisticsByMembers",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "userProfile",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "studyStatsFilter"
        }
      ],
      "concreteType": "StudyStatisticsByAuthor",
      "kind": "LinkedField",
      "name": "studyStatistics",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numExamTypeAssociations",
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserHistoricalProficiency",
          "kind": "LinkedField",
          "name": "userHistoricalProficiency",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamTypeCount",
              "kind": "LinkedField",
              "name": "examTypeCounts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExamType",
                  "kind": "LinkedField",
                  "name": "examType",
                  "plural": false,
                  "selections": (v8/*: any*/),
                  "storageKey": null
                },
                (v9/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamTypeStudyStatistics",
          "kind": "LinkedField",
          "name": "examTypeStatistics",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamType",
              "kind": "LinkedField",
              "name": "examType",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v5/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "CredentialStatisticsByMemberExamTypeProgress",
        "kind": "LinkedField",
        "name": "examTypeProgress",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamType",
            "kind": "LinkedField",
            "name": "examType",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "systemId",
                "storageKey": null
              },
              {
                "kind": "RequiredField",
                "field": (v2/*: any*/),
                "action": "THROW",
                "path": "examTypeProgress.examType.name"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CredentialStatisticsByMemberExamTypeGroupProgress",
            "kind": "LinkedField",
            "name": "credentialGroupProgress",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CredentialGroup",
                "kind": "LinkedField",
                "name": "credentialGroup",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "examTypeProgress"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "LmsUserStatistic",
        "kind": "LinkedField",
        "name": "lmsUserStatistics",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "averageProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastCompletedAt",
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "lmsUserStatistics"
    }
  ],
  "type": "CredentialStatisticsByMember",
  "abstractKey": null
};
})();

(node as any).hash = "1e9103545bc28cec5b4b48bd1700f52d";

export default node;
