import Layout from '@4c/layout';
import PlayCircleInverted from '@bfly/icons/PlayCircleInverted';
import Anchor from '@bfly/ui2/Anchor';
import CloseButton from '@bfly/ui2/CloseButton';
import Heading from '@bfly/ui2/Heading';
import Link from '@bfly/ui2/Link';
import useRouter from 'found/useRouter';
import type { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Thumbnail from 'components/Thumbnail';
import useCountdown from 'hooks/useCountdown';
import { useEducationRoutes } from 'routes/education';

import { NextContentPopout_educationContent$data as EducationContent } from './__generated__/NextContentPopout_educationContent.graphql';

interface Props {
  educationContent: EducationContent;
  onClose: () => void;
}

const COUNTDOWN_SECONDS = 10;

function NextContentPopout({ educationContent, onClose }: Props) {
  const educationRoutes = useEducationRoutes();
  const { router } = useRouter();

  const route = educationRoutes.educationContent({
    handle: educationContent.handle!,
  });

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    onClose();
  };

  const countdown = useCountdown(() => {
    router.replace(route);
  }, COUNTDOWN_SECONDS);

  return (
    <Link to={route}>
      {({ active: _, ...linkProps }) => (
        <Layout
          as={Anchor}
          {...linkProps}
          className="absolute right-0 bottom-20 h-32 bg-edu-grey md:h-40 md:w-2/5 md:min-w-96 md:bottom-20"
        >
          <div className="flex-none relative h-32 w-32 md:h-40 md:w-40">
            <PlayCircleInverted className="absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 decoration-white w-12 h-12" />
            <Thumbnail
              image={educationContent}
              className="w-full h-full object-cover"
            />
          </div>
          <Layout
            direction="column"
            grow
            className="overflow-hidden p-2.5 font-extrabold md:pt-12 md:pr-2.5 md:pb-10 md:pl-8"
          >
            <Heading truncate className="flex-1">
              {educationContent.name}
            </Heading>
            <div className="w-40">
              <FormattedMessage
                id="education.nextContent.startingIn"
                defaultMessage="Starting in <countdown>{numSeconds} seconds…</countdown>"
                values={{
                  countdown: (msg: string) => (
                    <span className="decoration-danger">{msg}</span>
                  ),
                  numSeconds: countdown,
                }}
              />
            </div>
          </Layout>
          <CloseButton
            onClick={handleClick}
            className="flex-none self-start m-0"
          />
        </Layout>
      )}
    </Link>
  );
}

export default createFragmentContainer(NextContentPopout, {
  educationContent: graphql`
    fragment NextContentPopout_educationContent on EducationContent {
      handle
      name
      ...Thumbnail_image
    }
  `,
});
