import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import messages from 'messages/brand';

function AppDocumentTitle() {
  const { formatMessage } = useIntl();
  const butterflyCloud = formatMessage(messages.butterflyCloud);

  return (
    <Helmet
      defaultTitle={butterflyCloud}
      titleTemplate={`%s - ${butterflyCloud}`}
    />
  );
}

export default AppDocumentTitle;
