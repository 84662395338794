import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import { ConnectionNodeType } from '@bfly/utils//types';
import getNodes from '@bfly/utils/getNodes';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import List, { ListHandle } from 'react-widgets/List';
import ListOption, { ListOptionProps } from 'react-widgets/ListOption';
import { graphql } from 'relay-hooks';

import useOrganizationSlug from 'hooks/useOrganizationSlug';
import {
  SearchConstants,
  SearchData,
  mapStoredSearchToSearchData,
  messages,
} from 'utils/Search';

import { useVariation } from './LaunchDarklyContext';
import SearchBarListRecentSearchOption from './SearchBarListRecentSearchOption';
import SearchBarListSavedSearchOption from './SearchBarListSavedSearchOption';
import SearchBarListSuggestionOption, {
  SearchSuggestion,
} from './SearchBarListSuggestionOption';
import { SearchBarGlobalList_SavedSearches_Query as SearchesQuery } from './__generated__/SearchBarGlobalList_SavedSearches_Query.graphql';

export interface RecentSearch {
  id: string;
  type: SearchConstants.RECENT_SEARCH;
  searchData?: SearchData;
  search?: ConnectionNodeType<
    SearchesQuery['response']['tenant'],
    'viewerRecentSearchConnection'
  >;
}

export interface SavedSearch {
  type: SearchConstants.SAVED_SEARCH;
  id: string;
  searchData?: SearchData;
  search: ConnectionNodeType<
    SearchesQuery['response']['tenant'],
    'viewerSavedSearchConnection'
  >;
}

const MAX_STORED_SEARCHES = 3;

const GROUP_NAMES = {
  [SearchConstants.SAVED_SEARCH]: (
    <FormattedMessage {...messages.savedSearches} />
  ),
};

function renderGroup({ group }, showGlobalRecentSearches) {
  if (showGlobalRecentSearches) {
    GROUP_NAMES[SearchConstants.RECENT_SEARCH] = (
      <FormattedMessage {...messages.recentSearches} />
    );
  }
  if (!GROUP_NAMES[group]) return null;

  return (
    <Text
      color="subtitle"
      variant="body-bold"
      className="flex items-center border-t border-grey-50 mt-3 pt-2"
    >
      {GROUP_NAMES[group]}
    </Text>
  );
}

function useStoredSearchData(showGlobalRecentSearches) {
  const organizationSlug = useOrganizationSlug();

  const { data } = useQuery<SearchesQuery>(
    graphql`
      query SearchBarGlobalList_SavedSearches_Query(
        $organizationSlug: String
        $maxStoredSearches: Int!
        $showGlobalRecentSearches: Boolean!
      ) {
        tenant(slug: $organizationSlug) {
          viewerSavedSearchConnection(first: $maxStoredSearches)
            @connection(key: "Organization_viewerSavedSearchConnection") {
            edges {
              node {
                id
                ...SearchBarListSavedSearchOption_studySavedSearch
                ...Search_searchToData
              }
            }
          }
          viewerRecentSearchConnection(first: $maxStoredSearches)
            @include(if: $showGlobalRecentSearches) {
            edges {
              node {
                id
                ...SearchBarListRecentSearchOption_studyRecentSearch
                ...Search_searchToData
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        organizationSlug,
        maxStoredSearches: MAX_STORED_SEARCHES,
        showGlobalRecentSearches,
      },
    },
  );

  return useMemo(() => {
    const recentSearches = data?.tenant?.viewerRecentSearchConnection
      ? getNodes(data?.tenant.viewerRecentSearchConnection)
      : [];

    const savedSearches = data?.tenant?.viewerSavedSearchConnection
      ? getNodes(data?.tenant.viewerSavedSearchConnection)
      : [];

    return data
      ? [
          ...(showGlobalRecentSearches
            ? recentSearches
                // manually select three to cover cases where mutations add to the connection cache
                .slice(0, MAX_STORED_SEARCHES)
                .map((search) => ({
                  type: SearchConstants.RECENT_SEARCH,
                  id: search.id,
                  searchData: mapStoredSearchToSearchData(search),
                  search,
                }))
            : []),
          ...savedSearches
            // manually select three to cover cases where mutations add to the connection cache
            .slice(0, MAX_STORED_SEARCHES)
            .map((search) => ({
              type: SearchConstants.SAVED_SEARCH,
              id: search.id,
              searchData: mapStoredSearchToSearchData(search),
              search,
            })),
        ]
      : [];
  }, [data, showGlobalRecentSearches]);
}
export interface FreeTextItem {
  type: SearchConstants.FREE_TEXT_SEARCH;
}

export const FREE_TEXT_SEARCH_ITEM: FreeTextItem = {
  type: SearchConstants.FREE_TEXT_SEARCH,
};

const SearchBarGlobalList = React.forwardRef<ListHandle, any>(
  ({ busy, onClearOption, data, ...props }, ref) => {
    const showGlobalRecentSearches = useVariation('recent-search');
    // We are constructing this component in render to close over the clear button
    // it's important to memoize this otherwise React does a lot more work to render the list
    const Option = useCallback(
      function SearchBarGlobalListOption(
        optionProps: ListOptionProps<
          SearchSuggestion | SavedSearch | RecentSearch | FreeTextItem
        >,
      ) {
        const { dataItem } = optionProps;

        if (dataItem.type === SearchConstants.FREE_TEXT_SEARCH) {
          return (
            <ListOption {...optionProps} className="sr-only">
              <FormattedMessage
                id="searchBarGlobal.searchFor"
                defaultMessage="Search for “{searchTerm}”"
                values={{ searchTerm: optionProps.searchTerm }}
              />
            </ListOption>
          );
        }

        if (dataItem.type === SearchConstants.RECENT_SEARCH) {
          return (
            <SearchBarListRecentSearchOption
              {...optionProps}
              onClear={() => onClearOption(dataItem)}
              studyRecentSearch={dataItem.search!}
            />
          );
        }

        if (dataItem.type === SearchConstants.SAVED_SEARCH) {
          return (
            <SearchBarListSavedSearchOption
              {...optionProps}
              studySavedSearch={dataItem.search!}
            />
          );
        }

        return (
          <SearchBarListSuggestionOption
            {...optionProps}
            dataItem={dataItem as SearchSuggestion}
          />
        );
      },
      [onClearOption],
    );

    const storedSearchDataItems = useStoredSearchData(
      showGlobalRecentSearches,
    );

    return (
      // XXX: this should be in ui providing the theme
      <div className="overflow-hidden surface-dark">
        {/* FIXME: actually type the data items */}
        <List<any>
          {...props}
          data={[...data, ...storedSearchDataItems]}
          ref={ref}
          renderGroup={(item) => renderGroup(item, showGlobalRecentSearches)}
          className={clsx(busy && 'hidden')}
          optionComponent={Option}
        />
      </div>
    );
  },
);

export default SearchBarGlobalList;
