/**
 * @generated SignedSource<<7258b9858eeae7acfe6ba2bc27f342b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canUndeleteStudy$data = {
  readonly archive: {
    readonly deletedAt: string | null;
  } | null;
  readonly deletedAt: string | null;
  readonly " $fragmentType": "StudyPermissions_canUndeleteStudy";
};
export type StudyPermissions_canUndeleteStudy$key = {
  readonly " $data"?: StudyPermissions_canUndeleteStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canUndeleteStudy">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canUndeleteStudy"
};

(node as any).hash = "cd06363e8ce299310b9133a8ca7c9397";

export default node;
