import Layout from '@4c/layout';
import styled, { css } from 'astroturf/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const SwitchItem = styled('div')<{ active?: boolean }>`
  flex: 1;
  min-width: 8rem;
  border-radius: theme('borderRadius.DEFAULT');
  padding: 0.1rem theme('spacing.3');
  text-align: center;
  cursor: pointer;
  color: theme('colors.white');

  &:not(.active):hover {
    color: theme('colors.grey[25]');
  }

  &.active {
    background-color: theme('colors.blue[70]');
  }
`;

interface Props {
  checked: boolean;
  onToggle: (checked: boolean) => void;
  interpretationSwitchItemMessage: React.ReactNode;
  interpretationDescription: React.ReactNode;
}

function InterpretationRenderSwitch({
  checked,
  onToggle,
  interpretationSwitchItemMessage,
  interpretationDescription,
}: Props) {
  const handleToggle = () => onToggle(!checked);

  return (
    <Layout
      as="label"
      align="center"
      justify="center"
      className="p-1 bg-grey-85 rounded"
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={handleToggle}
        css={css`
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          opacity: 0;

          &:focus {
            outline: none;
          }
        `}
      />
      <span className="sr-only">{interpretationDescription}</span>
      <SwitchItem active={checked === false} aria-hidden="true">
        <FormattedMessage
          id="interpretationSwitch.cine"
          defaultMessage="Cine"
        />
      </SwitchItem>

      <SwitchItem active={checked === true} aria-hidden="true">
        {interpretationSwitchItemMessage}
      </SwitchItem>
    </Layout>
  );
}

export default InterpretationRenderSwitch;
