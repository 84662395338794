import Button, { ButtonProps } from '@bfly/ui2/Button';
import { useRouter } from 'found';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import SortIndicator from 'components/SortIndicator';

function StudySortFilterToggle(props: ButtonProps) {
  const {
    match: { location },
    router,
  } = useRouter();
  const { sort } = location.query;

  const isOldestFirst = sort === 'CAPTURED_AT_ASC';

  const handleToggleSort = useCallback(() => {
    router.replace({
      ...location,
      query: {
        ...location.query,
        sort: isOldestFirst ? 'CAPTURED_AT_DESC' : 'CAPTURED_AT_ASC',
      },
    });
  }, [router, location, isOldestFirst]);

  return (
    <Button {...props} variant="text-secondary" onClick={handleToggleSort}>
      {isOldestFirst ? (
        <FormattedMessage
          id="search.oldest.first"
          defaultMessage="Oldest first"
        />
      ) : (
        <FormattedMessage
          id="search.newest.first"
          defaultMessage="Newest first"
        />
      )}{' '}
      <SortIndicator desc={!isOldestFirst} height="12" />
    </Button>
  );
}

export default StudySortFilterToggle;
