import BlankSlate from '@bfly/ui2/BlankSlate';
import MainContent from '@bfly/ui2/MainContent';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { marketingWebsiteUrl } from 'config/app';

export default function SharedBundleExpiredPage() {
  return (
    <MainContent centerText data-bni-id="NotFoundPage">
      <BlankSlate>
        <BlankSlate.Title>
          <FormattedMessage
            id="sharedBundleExpired.title"
            defaultMessage="Shared Images Expired"
          />
        </BlankSlate.Title>
        <BlankSlate.Body>
          <FormattedMessage
            id="sharedBundleExpired.body"
            defaultMessage="The shared images have expired. You will need to ask for a new link to access them."
          />
        </BlankSlate.Body>
        <BlankSlate.Action
          // Intentionally open in same page since this is a dead-end.
          href={marketingWebsiteUrl}
          size="lg"
        >
          <FormattedMessage
            id="sharedBundle.learnMoreAboutIQ"
            defaultMessage="Learn more about Butterfly iQ"
          />
        </BlankSlate.Action>
      </BlankSlate>
    </MainContent>
  );
}
