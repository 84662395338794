/**
 * @generated SignedSource<<4fdbfda49795a0e75e670fc312d0504f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarTags_archive$data = {
  readonly label: string | null;
  readonly " $fragmentType": "SearchBarTags_archive";
};
export type SearchBarTags_archive$key = {
  readonly " $data"?: SearchBarTags_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarTags_archive">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarTags_archive"
};

(node as any).hash = "c098030c7b3a6fd67c0858a761670105";

export default node;
