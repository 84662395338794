import { useCallback, useState } from 'react';

export default function useModalToggleState(defaultShow = false) {
  const [show, setShow] = useState(defaultShow);

  const toggle = useCallback((nextShow: boolean) => setShow(nextShow), []);

  return {
    show: () => toggle(true),
    hide: () => toggle(false),
    props: {
      show,
      onHide: () => toggle(false),
    },
  };
}
