/**
 * @generated SignedSource<<e9503122b206fd7845d606a2c86e2649>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainMembers_DomainMembersGrid_Query$variables = {};
export type domainMembers_DomainMembersGrid_Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DomainMembersGridWrapper_viewer">;
  } | null;
};
export type domainMembers_DomainMembersGrid_Query = {
  response: domainMembers_DomainMembersGrid_Query$data;
  variables: domainMembers_DomainMembersGrid_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "sort",
  "value": "NAME_ASC"
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  (v0/*: any*/)
],
v7 = {
  "kind": "Literal",
  "name": "first",
  "value": 2147483647
},
v8 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "domainMembers_DomainMembersGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainMembersGridWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "domainMembers_DomainMembersGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numAvailableSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DomainUserConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainUser",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "integrationDisplayNameFirst",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "integrationDisplayNameMiddle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "integrationDisplayNameLast",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canQa",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canFinalize",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DomainUserDicomField",
                            "kind": "LinkedField",
                            "name": "dicomFields",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DomainDicomFieldTemplate",
                                "kind": "LinkedField",
                                "name": "dicomFieldTemplate",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DomainUserIntegrationConfig",
                            "kind": "LinkedField",
                            "name": "integrationConfigs",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Ehr",
                                "kind": "LinkedField",
                                "name": "ehr",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "interfaceCode",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MembershipRole",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organizations",
                            "plural": true,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dateLastActive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "users(first:500,sort:\"NAME_ASC\")"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [
                  "search",
                  "sort",
                  "isDeleted"
                ],
                "handle": "connection",
                "key": "domain_grid_users",
                "kind": "LinkedHandle",
                "name": "users"
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "OrganizationConnection",
                "kind": "LinkedField",
                "name": "organizationConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "organizationConnection(first:2147483647)"
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "DomainDicomFieldTemplateConnection",
                "kind": "LinkedField",
                "name": "dicomFieldTemplateConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainDicomFieldTemplateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainDicomFieldTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "dicomFieldTemplateConnection(first:2147483647)"
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "EhrConnection",
                "kind": "LinkedField",
                "name": "ehrConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EhrEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Ehr",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v5/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ehrConnection(first:2147483647)"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v7/*: any*/),
              {
                "kind": "Literal",
                "name": "roleType",
                "value": [
                  "SYSTEM_DEFINED",
                  "USER_DEFINED"
                ]
              },
              (v1/*: any*/)
            ],
            "concreteType": "MembershipRoleConnection",
            "kind": "LinkedField",
            "name": "membershipRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembershipRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDisabled",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "membershipRoles(first:2147483647,roleType:[\"SYSTEM_DEFINED\",\"USER_DEFINED\"],sort:\"NAME_ASC\")"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3df6d1320324f66ce15152bfb3e0e58",
    "id": null,
    "metadata": {},
    "name": "domainMembers_DomainMembersGrid_Query",
    "operationKind": "query",
    "text": "query domainMembers_DomainMembersGrid_Query {\n  viewer {\n    ...DomainMembersGridWrapper_viewer\n    id\n  }\n}\n\nfragment DomainMembersGridWrapper_viewer on Viewer {\n  ...DomainMembersGrid_viewer_3MnuXK\n  membershipRoles(first: 2147483647, sort: NAME_ASC, roleType: [SYSTEM_DEFINED, USER_DEFINED]) {\n    edges {\n      node {\n        id\n        isDisabled\n        name\n      }\n    }\n  }\n  domain {\n    id\n    organizationConnection(first: 2147483647) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    dicomFieldTemplateConnection(first: 2147483647) {\n      edges {\n        node {\n          label\n          id\n        }\n      }\n    }\n    ehrConnection(first: 2147483647) {\n      edges {\n        node {\n          name\n          handle\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment DomainMembersGrid_viewer_3MnuXK on Viewer {\n  domain {\n    name\n    numAvailableSeats\n    users(first: 500, sort: NAME_ASC) {\n      edges {\n        node {\n          id\n          email\n          integrationDisplayNameFirst\n          integrationDisplayNameMiddle\n          integrationDisplayNameLast\n          canQa\n          canFinalize\n          type\n          dicomFields {\n            dicomFieldTemplate {\n              id\n              label\n            }\n            value\n          }\n          integrationConfigs {\n            ehr {\n              id\n              handle\n            }\n            interfaceCode\n          }\n          role {\n            name\n            id\n          }\n          organizations {\n            id\n            name\n          }\n          profile {\n            id\n          }\n          updatedAt\n          dateLastActive\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "10ece678f733fb5be8482c88c3d89e54";

export default node;
