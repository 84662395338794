import Layout from '@4c/layout';
import BackArrowLink from '@bfly/ui2/BackArrowLink';
import { css } from 'astroturf';
import clsx from 'clsx';
import { LocationDescriptor } from 'farce';
import useLocation from 'found/useLocation';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

function TopLeft({
  children,
  backTo,
  className,
}: PropsWithChildren & {
  backTo?: LocationDescriptor;
  className?: string;
}) {
  const location = useLocation();
  return (
    <Layout
      justify="flex-start"
      style={{ gridArea: 'left' }}
      className={className}
    >
      {backTo && (
        <BackArrowLink
          data-bni-id="backArrowLink"
          to={backTo}
          backIndex={location.state?.studyListNavConfig?.backIndex ?? -1}
          className="p-0"
        >
          <span className="ml-3 font-semibold">
            <FormattedMessage id="ExamHeader.back" defaultMessage="Back" />
          </span>
        </BackArrowLink>
      )}
      {children}
    </Layout>
  );
}

function TopCenter({ children }: PropsWithChildren) {
  return (
    <Layout
      justify="center"
      className="min-w-64 overflow-hidden items-center"
      style={{ gridArea: 'center' }}
    >
      {children}
    </Layout>
  );
}

function TopRight({
  children,
  className,
}: PropsWithChildren & { className?: string }) {
  return (
    <Layout
      justify="flex-end"
      style={{ gridArea: 'right ' }}
      className={className}
    >
      {children}
    </Layout>
  );
}

type Props = PropsWithChildren & {
  status?: 'draft' | 'pending' | 'finalized';
};

function ExamHeader({ children, status }: Props) {
  return (
    <div
      data-bni-id="ExamHeader"
      className={clsx(
        'bg-black sticky top-0 flex-shrink-0 items-center w-full px-6',
        status === 'draft' && 'border-t-[3px] border-draft',
        status === 'pending' && 'border-t-[3px] border-red-40',
        status === 'finalized' && 'border-t-[3px] border-white',
      )}
    >
      <div
        className="grid items-center w-full"
        css={css`
          grid-template-rows: theme('height.20');
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-template-areas: 'left    center  right';
          @screen md-max {
            grid-template-rows: theme('height.4') theme('height.16');
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-areas:
              'left    right'
              'center center';
          }
        `}
      >
        {children}
      </div>
    </div>
  );
}

export default Object.assign(ExamHeader, {
  TopLeft,
  TopCenter,
  TopRight,
});
