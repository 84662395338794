import { graphql } from 'react-relay';

import asyncCommitMutation from 'utils/asyncCommitMutation';

const mutation = graphql`
  mutation SetNotificationCenterLastSeenAtMutation(
    $input: SetNotificationCenterLastSeenAtInput!
  ) {
    setNotificationCenterLastSeenAt(input: $input) {
      notificationCenter {
        numUnseenNotifications
      }
    }
  }
`;

function commit(environment, notificationCenterId, latestNotificationId) {
  return asyncCommitMutation(environment, {
    mutation,
    variables: {
      input: { notificationCenterId, latestNotificationId },
    },
  });
}
export default { commit };
