import { graphql } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import LaunchDarklyRoute from 'components/LaunchDarklyRoute';
import Route, { renderStaleWhileFetching } from 'components/Route';
import { prepareVariablesWithPagination } from 'hooks/usePagedConnection';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';
import {
  prepareGlobalSearchStudyQueryVariables,
  prepareSearchStudyQueryVariables,
} from 'utils/StudyFilters';

import GlobalStudySearchDomainPage from './components/GlobalStudySearchDomainPage';
import GlobalStudySearchList from './components/GlobalStudySearchList';
import GlobalStudySearchPage from './components/GlobalStudySearchPage';
import StudySearchPage from './components/StudySearchPage';

export const domainSearchRoutes = (
  <Route
    path="search"
    name="name_studySearch"
    render={renderStaleWhileFetching}
    Component={GlobalStudySearchDomainPage}
    prerender={checkFlagsOr404('global-search')}
    query={graphql`
      query search_GlobalStudySearchDomainPage_DomainSearchQuery {
        viewer {
          ...GlobalStudySearchDomainPage_viewer
        }
        tenant {
          ...GlobalStudySearchDomainPage_tenant
        }
      }
    `}
  >
    <Route
      render={renderStaleWhileFetching}
      Component={GlobalStudySearchList}
      query={graphql`
        query search_GlobalStudySearchListDomainQuery(
          $first: Int
          $after: String
          $last: Int
          $before: String
          $sort: [StudySorting!]! # Not actually required.
          $search: StudySearchInput
        ) {
          tenant {
            ...GlobalStudySearchList_tenant
          }
          viewer {
            ...GlobalStudySearchList_viewer
          }
        }
      `}
      prepareVariables={prepareVariablesWithPagination(
        prepareGlobalSearchStudyQueryVariables as any,
      )}
    />
  </Route>
);

export default (
  <LaunchDarklyRoute
    path="search"
    name="name_studySearch"
    render={renderStaleWhileFetching}
    getVariation={(ld) => {
      return getVariation(ld, 'global-search')
        ? {
            Component: GlobalStudySearchPage,
            query: graphql`
              query search_GlobalStudySearchPage_Query(
                $organizationSlug: String!
              ) {
                organization: organizationBySlug(slug: $organizationSlug) {
                  ...GlobalStudySearchPage_organization
                }
                viewer {
                  ...GlobalStudySearchPage_viewer
                }
              }
            `,
          }
        : {
            Component: StudySearchPage,
            query: graphql`
              query search_StudySearchPage_Query(
                $searchArchiveHandle: String
                $searchUserHandle: String
                $organizationSlug: String!
                $archiveHandle: [String!]
                $hasArchive: Boolean!
                $hasUser: Boolean!
                $studyTagHandles: [String!]
                $examTypes: [String!]
                $hasTag: Boolean!
                $pageSize: Int
                $userHandle: [String!]
                $primaryAuthor: [String!]
                $search: String
                $sort: [StudySorting!]! # Not actually required.
                $isDraft: [Boolean!]
                $needsReview: [Boolean!]
                $hasRequestedFinalization: [Boolean!]
                $hasRequestedFinalizationFromViewer: [Boolean!]
                $readyAtMax: DateTime
                $readyAtMin: DateTime
                $workflowStatus: [StudySearchInputStatus!]
              ) {
                archive(handle: $searchArchiveHandle)
                  @include(if: $hasArchive) {
                  ...StudySearchPage_archive
                }
                userProfile(handle: $searchUserHandle) @include(if: $hasUser) {
                  ...StudySearchPage_userProfile
                }
                studyTags(handles: $studyTagHandles) @include(if: $hasTag) {
                  ...StudySearchPage_studyTags
                }
                organization: organizationBySlug(slug: $organizationSlug) {
                  ...StudySearchPage_organization
                }
                viewer {
                  ...StudySearchPage_viewer
                }
              }
            `,
            prepareVariables: prepareSearchStudyQueryVariables,
          };
    }}
  >
    <LaunchDarklyRoute
      render={renderStaleWhileFetching}
      getVariation={(ld) =>
        getVariation(ld, 'global-search')
          ? {
              Component: GlobalStudySearchList,
              prepareVariables: prepareVariablesWithPagination(
                prepareGlobalSearchStudyQueryVariables as any,
              ),
              query: graphql`
                query search_GlobalStudySearchList_Query(
                  $first: Int
                  $after: String
                  $last: Int
                  $before: String
                  $organizationSlug: String!
                  $sort: [StudySorting!]! # Not actually required.
                  $search: StudySearchInput
                ) {
                  tenant(slug: $organizationSlug) {
                    ...GlobalStudySearchList_tenant
                  }
                  viewer {
                    ...GlobalStudySearchList_viewer
                  }
                }
              `,
            }
          : {}
      }
    />
  </LaunchDarklyRoute>
);
