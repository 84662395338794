import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import FormCheck from '@bfly/ui2/FormCheck';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql, useMutation } from 'react-relay';

import AvatarList from 'components/AvatarList';
import useModalState from 'hooks/useModalState';
import actionMessages from 'messages/actions';

import ExamQaNotificationSearchControl, {
  ArchiveMembership,
} from './ExamQaNotificationSearchControl';
import { ExamQaNotificationControl_Mutation as Mutation } from './__generated__/ExamQaNotificationControl_Mutation.graphql';
import { ExamQaNotificationControl_study$data as Study } from './__generated__/ExamQaNotificationControl_study.graphql';

const notificationMessages = defineMessages({
  noneNotified: {
    id: 'examQaNotificationRecipientsModal.noneNotified',
    defaultMessage: 'No members will be notified of QA results. <a>Notify</a>',
  },
  someNotified: {
    id: 'examQaNotificationRecipientsModal.someNotified',
    defaultMessage: 'Some members will be notified of QA results. <a>Edit</a>',
  },
  allAuthorsNotified: {
    id: 'examQaNotificationRecipientsModal.allAuthors',
    defaultMessage: 'All authors will be notified. <a>Edit</a>',
  },
});

const recipientTypeMessages = defineMessages({
  primaryAuthor: {
    id: 'examQaNotificationRecipientsModal.primaryAuthor',
    defaultMessage: 'Primary author',
  },
  secondaryAuthor: {
    id: 'examQaNotificationRecipientsModal.secondaryAuthor',
    defaultMessage: 'Secondary author',
  },
  scribe: {
    id: 'examQaNotificationRecipientsModal.scribe',
    defaultMessage: 'Scribe',
  },
});

type Recipient = NonNullable<ArchiveMembership['userProfile']> & {
  isChecked?: boolean;
  type?: keyof typeof recipientTypeMessages;
};

function defaultAuthorRecipients(study: Study) {
  const recipientOptions: Recipient[] = [];

  const hasBeenSaved = !!study.qaNotificationRecipients?.submittedAt;
  const savedRecipients = study.qaNotificationRecipients?.recipients || [];

  const isChecked =
    study.archive?.defaultQaNotificationRecipients === 'EVERYONE' &&
    savedRecipients.length === 0 &&
    !hasBeenSaved;

  // add all authors as recipient options
  if (study.createdBy)
    recipientOptions.push({
      ...study.createdBy,
      isChecked,
      type: 'primaryAuthor',
    });

  if (study.secondaryAuthors?.length) {
    study.secondaryAuthors.forEach((secondaryAuthor) => {
      if (secondaryAuthor && study.transcribedBy?.id !== secondaryAuthor.id)
        recipientOptions.push({
          ...secondaryAuthor,
          isChecked,
          type: 'secondaryAuthor',
        });
    });
  }

  if (study.transcribedBy) {
    recipientOptions.push({
      ...study.transcribedBy,
      isChecked,
      type: 'scribe',
    });
  }

  if (savedRecipients.length > 0) {
    savedRecipients.forEach((savedRecipient) => {
      const authorIndex = recipientOptions.findIndex(
        (recipientOption) => recipientOption.id === savedRecipient!.id,
      );

      // make authors checked if they are a saved recipient
      if (authorIndex > -1) {
        recipientOptions[authorIndex].isChecked = true;
      }

      // add savedRecipients that aren't authors as as recipient options
      else
        recipientOptions.push({
          ...savedRecipient!,
          isChecked: true,
        });
    });
  }

  return recipientOptions;
}

function ExamQaNotificationControl({
  study,
  ...divProps
}: Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> & {
  study: Study;
}) {
  const [showModal, modalProps] = useModalState();
  const initialRecipients = defaultAuthorRecipients(study);

  const [recipients, setRecipients] = useState(initialRecipients);

  const toggleRecipient = (recipientId: string) => {
    /// update item in array
    setRecipients((prevValue) =>
      prevValue.map((recipient) => ({
        ...recipient,
        isChecked:
          recipient.id === recipientId
            ? !recipient.isChecked
            : recipient.isChecked,
      })),
    );
  };

  const addRecipient = (newRecipient: Recipient) => {
    setRecipients((prevRecipients) => {
      return [
        {
          ...newRecipient,
          isChecked: true,
        },
        /// ensure Recipient isn't already in list
        ...prevRecipients.filter(
          (prevRecipient) => prevRecipient.id !== newRecipient.id,
        ),
      ];
    });
  };

  const [updateRecipients, loading] = useMutation<Mutation>(
    graphql`
      mutation ExamQaNotificationControl_Mutation(
        $input: UpdateQaNotificationRecipientsInput!
      ) {
        updateQaNotificationRecipients(input: $input) {
          study {
            qaNotificationRecipients {
              submittedAt
              recipients {
                name
                id
                ...AvatarList_user
              }
            }
          }
        }
      }
    `,
  );

  const handleSave = useCallback(() => {
    updateRecipients({
      variables: {
        input: {
          studyId: study.id!,
          qaNotificationRecipients: recipients
            .filter((recipient) => recipient.isChecked)
            .map((recipient) => ({
              recipientId: recipient.id,
            })),
        },
      },
      onCompleted: () => {
        modalProps.onHide();
      },
    });
  }, [modalProps, recipients, study.id, updateRecipients]);

  const handleCancel = () => {
    // reset checked Recipient back to saved recipientIds
    setRecipients(initialRecipients);
    modalProps.onHide();
  };

  const launchQaNotificationAuthorModal = (msg: ReactNode[]) => (
    <Button variant="text-primary" className="p-0" onClick={showModal}>
      {msg}
    </Button>
  );

  const notificationMessageKey: keyof typeof notificationMessages =
    useMemo(() => {
      if (!recipients.some((recipient) => recipient.isChecked))
        return 'noneNotified';

      if (
        recipients.some((recipient) => recipient.type) &&
        recipients.every((recipient) => !recipient.type || recipient.isChecked)
      )
        return 'allAuthorsNotified';

      return 'someNotified';
    }, [recipients]);

  return (
    <div {...divProps}>
      <FormattedMessage
        {...notificationMessages[notificationMessageKey]}
        values={{ a: launchQaNotificationAuthorModal }}
      />
      <Modal {...modalProps} variant="dark" size="md" onHide={handleCancel}>
        <Modal.Body
          scrollable={false}
          className="flex flex-col overflow-hidden"
        >
          <Modal.Title className="text-headline mb-3">
            <FormattedMessage
              id="examQaNotificationRecipientsModal.title"
              defaultMessage="Notify members of QA results"
            />
          </Modal.Title>
          <Text as="div" variant="sm" color="subtitle" className="mb-4">
            <FormattedMessage
              id="examQaNotificationRecipientsModal.description"
              defaultMessage="The selected members will be notified of the QA results because FP, FN, or the study did not pass QA was selected. You can change your default notification settings under enterprise settings."
            />
          </Text>

          <ExamQaNotificationSearchControl
            variant="secondary"
            archiveId={study!.archive!.id}
            onSelect={(item) => {
              if (typeof item !== 'string' && item.userProfile)
                addRecipient(item.userProfile!);
            }}
          />
          <AvatarList data-bni-id="StudyAuthorsList" variant="secondary">
            {recipients.map((recipient) => (
              <Layout align="center" key={recipient.id}>
                <FormCheck
                  className="mt-3 mr-4"
                  onChange={() => toggleRecipient(recipient.id)}
                  name="savedSearch"
                  checked={recipient.isChecked}
                />
                <AvatarList.Item avatarSize={32} user={recipient}>
                  <Layout direction="column">
                    <Text variant="body" color="headline">
                      {recipient.name}
                    </Text>
                  </Layout>
                  <Layout pad align="center">
                    {recipient.type && (
                      <Text color="subtitle">
                        <FormattedMessage
                          {...recipientTypeMessages[recipient.type]}
                        />
                      </Text>
                    )}
                  </Layout>
                </AvatarList.Item>
              </Layout>
            ))}
          </AvatarList>
        </Modal.Body>
        <Modal.Footer>
          <Modal.ButtonGroup>
            <Button
              size="lg"
              onClick={handleSave}
              disabled={loading}
              busy={loading}
            >
              <FormattedMessage {...actionMessages.save} />
            </Button>
            <Button
              variant="secondary"
              size="lg"
              onClick={handleCancel}
              disabled={loading}
            >
              <FormattedMessage {...actionMessages.cancel} />
            </Button>
          </Modal.ButtonGroup>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default createFragmentContainer(ExamQaNotificationControl, {
  study: graphql`
    fragment ExamQaNotificationControl_study on Study {
      id
      archive {
        id
        defaultQaNotificationRecipients
      }
      qaNotificationRecipients {
        submittedAt
        recipients {
          name
          id
          ...AvatarList_user
        }
      }
      createdBy {
        id
        name
        ...AvatarList_user
      }
      secondaryAuthors {
        name
        id
        ...AvatarList_user
      }
      transcribedBy {
        name
        id
        ...AvatarList_user
      }
    }
  `,
});
