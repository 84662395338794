/**
 * @generated SignedSource<<1ae0b718e244dcd8c512166af332cdb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type viewerContext_organization$data = {
  readonly handle: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly setupAt: string | null;
  readonly slug: string | null;
  readonly subscription: {
    readonly planType: string | null;
  } | null;
  readonly viewerCanQa: boolean | null;
  readonly viewerIsMember: boolean | null;
  readonly viewerLaunchDarklyConfig: {
    readonly state: Record<string, any> | null;
  } | null;
  readonly viewerPermissions: {
    readonly accessAllOrganizations: PermissionLevel | null;
    readonly archiveManagement: PermissionLevel | null;
    readonly bulkDataExport: PermissionLevel | null;
    readonly connectivityManagement: PermissionLevel | null;
    readonly credentialManagement: PermissionLevel | null;
    readonly dataDeletion: PermissionLevel | null;
    readonly dataExport: PermissionLevel | null;
    readonly educationManagement: PermissionLevel | null;
    readonly examTypeManagement: PermissionLevel | null;
    readonly fleetManagement: PermissionLevel | null;
    readonly iqCareRestrictedExperience: PermissionLevel | null;
    readonly loginAndSecurityManagement: PermissionLevel | null;
    readonly organizationManagement: PermissionLevel | null;
    readonly qa: PermissionLevel | null;
    readonly qaManagement: PermissionLevel | null;
    readonly signing: PermissionLevel | null;
    readonly studyDocumentation: PermissionLevel | null;
    readonly studyDocumentationManagement: PermissionLevel | null;
    readonly studyReversion: PermissionLevel | null;
    readonly userManagement: PermissionLevel | null;
    readonly userPermissions: PermissionLevel | null;
  } | null;
  readonly " $fragmentType": "viewerContext_organization";
};
export type viewerContext_organization$key = {
  readonly " $data"?: viewerContext_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"viewerContext_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "viewerContext_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "setupAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerIsMember",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessAllOrganizations",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archiveManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bulkDataExport",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "connectivityManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "credentialManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataDeletion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataExport",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "educationManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "examTypeManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fleetManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iqCareRestrictedExperience",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loginAndSecurityManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organizationManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "qa",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "qaManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signing",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studyDocumentation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studyDocumentationManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studyReversion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userManagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userPermissions",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "platform",
          "value": "CLOUD"
        }
      ],
      "concreteType": "LaunchDarklyConfig",
      "kind": "LinkedField",
      "name": "viewerLaunchDarklyConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": "viewerLaunchDarklyConfig(platform:\"CLOUD\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "planType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "492aae54e3a7a6ab8f159766fbe70a9c";

export default node;
