import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import useQuery from '@bfly/ui2/useQuery';
import React from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ServerLogModal from 'components/ServerLogModal';
import withModal from 'utils/withModal';

import ThirdPartyUltrasoundLogList from './ThirdPartyUltrasoundLogList';
import { ThirdPartyUltrasoundLogModalLogListQuery as LogListQuery } from './__generated__/ThirdPartyUltrasoundLogModalLogListQuery.graphql';
import { ThirdPartyUltrasoundLogModal_archive$data as Archive } from './__generated__/ThirdPartyUltrasoundLogModal_archive.graphql';

interface Props {
  archive: Archive;
  onHide: () => void;
}

function ThirdPartyUltrasoundLogModal({ archive, onHide }: Props) {
  const intl = useIntl();

  const { data } = useQuery<LogListQuery>(
    graphql`
      query ThirdPartyUltrasoundLogModalLogListQuery($id: ID!) {
        archive: node(id: $id) {
          ...ThirdPartyUltrasoundLogList_archive
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: { id: archive.id },
    },
  );

  return (
    <ServerLogModal
      onHide={onHide}
      name={intl.formatMessage({
        id: 'thirdPartyUltrasoundLogModal.listenerName',
        defaultMessage: 'third-party integration',
      })}
    >
      {data ? (
        <ThirdPartyUltrasoundLogList archive={data.archive!} />
      ) : (
        <LoadingIndicator />
      )}
    </ServerLogModal>
  );
}

export default createFragmentContainer(
  withModal(ThirdPartyUltrasoundLogModal, { backdrop: true, variant: 'dark' }),
  {
    archive: graphql`
      fragment ThirdPartyUltrasoundLogModal_archive on Archive {
        id
        label
      }
    `,
  },
);
