import ChevronLeftIcon from '@bfly/icons/ChevronLeft';
import ChevronRightIcon from '@bfly/icons/ChevronRight';
import PlusIcon from '@bfly/icons/Plus';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import SearchFilter from '@bfly/ui2/SearchFilter';
import Text, { TextProps } from '@bfly/ui2/Text';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import useClickOutside from '@restart/ui/useClickOutside';
import { css } from 'astroturf';
import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import React, {
  KeyboardEventHandler,
  forwardRef,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { RelayProp, createFragmentContainer } from 'react-relay';
import { fetchQuery, graphql } from 'relay-runtime';

import ListCardContainer from 'components/ListCardContainer';
import PopoverList from 'components/PopoverList';
import * as Worksheets from 'schemas/worksheet';
import Analytics from 'utils/Analytics';

import { WorksheetAndPrefillSelectAddMutation } from './__generated__/WorksheetAndPrefillSelectAddMutation.graphql';
import {
  WorksheetAndPrefillSelect_OrganizationQuery,
  WorksheetAndPrefillSelect_OrganizationQuery$data,
} from './__generated__/WorksheetAndPrefillSelect_OrganizationQuery.graphql';
import { WorksheetAndPrefillSelect_TemplatePrefillQuery } from './__generated__/WorksheetAndPrefillSelect_TemplatePrefillQuery.graphql';
import { WorksheetAndPrefillSelect_study$data as Study } from './__generated__/WorksheetAndPrefillSelect_study.graphql';

const FormattedOverflowList = forwardRef(
  (
    {
      name,
      numOverflowing,
      ...props
    }: TextProps & { name: string; numOverflowing: number },
    ref: React.Ref<HTMLSpanElement>,
  ) => {
    if (!numOverflowing) {
      return (
        <span ref={ref}>
          <Text {...props} truncate>
            {name}
          </Text>
        </span>
      );
    }

    return (
      <span ref={ref}>
        <Text {...props} className="flex overflow-hidden">
          <FormattedMessage
            id="WorksheetAndPrefillSelect.formattedOverflow"
            description="Displays the first of a list of items followed by the number of additional items in the list: 'My worksheet, +3'"
            defaultMessage="<first>{name}</first><overflow>, +{numAdditionalItems}</overflow>"
            values={{
              name,
              numAdditionalItems: numOverflowing ?? 0,
              first: (c) => (
                <span className="truncate" title={name}>
                  {c}
                </span>
              ),
              overflow: (c) => <span>{c}</span>,
            }}
          />
        </Text>
      </span>
    );
  },
);

export type WorksheetTemplate = ConnectionNodeType<
  WorksheetAndPrefillSelect_OrganizationQuery$data['organization'],
  'worksheetTemplateConnection'
>;

export type WorksheetPrefill = ConnectionNodeType<
  ConnectionNodeType<
    WorksheetAndPrefillSelect_OrganizationQuery$data['organization'],
    'worksheetTemplateConnection'
  >,
  'prefillConnection'
>;

interface Props {
  study: Study;
  onChange: (worksheetHandle: string) => void;
  onKeyDown: KeyboardEventHandler<HTMLDivElement>;
  onClickOutside: () => unknown;
  autoFocus?: boolean;
  relay: RelayProp;
}

const plusButton = (
  <div
    className="rounded-full p-0 w-5 h-5 bg-grey-50 text-grey-80 flex justify-center items-center"
    css={css`
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    `}
  >
    <PlusIcon width={12} height={12} />
  </div>
);

function ButtonListItem({
  padStart,
  ...props
}: React.ComponentPropsWithoutRef<'button'> & { padStart?: boolean }) {
  return (
    <button
      type="button"
      {...props}
      className={clsx(
        'flex w-full items-center py-2 text-left focus:outline-none focus-visible:ring hover:bg-blue-70 select-none cursor-pointer',
        padStart ? 'pl-8 pr-4' : 'px-4',
      )}
    />
  );
}

function ListGroup({ groupTitle, children, color = 'subtitle' }) {
  return (
    <div role="group" className="mb-4 last:mb-0">
      <Text
        role="separator"
        color={color as any}
        variant="body"
        className="mb-3 block px-4"
      >
        {groupTitle}
      </Text>
      {children}
    </div>
  );
}

function WorksheetSelect({
  study,
  nodes,
  onSelect,
  autoFocus,
}: {
  study: Study;
  nodes: WorksheetTemplate[];
  autoFocus?: boolean;
  onSelect: (template: WorksheetTemplate) => void;
}) {
  const suggestedWorksheetsByExamType = useMemo(() => {
    const result = new Set<WorksheetTemplate['handle']>();

    const studyExamTypeIds = study.examTypes?.map((e) => e!.id) ?? [];

    for (const { handle, latestVersion } of nodes) {
      const worksheetTemplateExamTypeIds =
        latestVersion!.examTypes?.map((e) => e?.id) ?? [];

      // if there are any items in common, add it to the suggested list
      if (
        intersection(studyExamTypeIds, worksheetTemplateExamTypeIds).length
      ) {
        result.add(handle);
      }
    }

    return result;
  }, [study, nodes]);

  // sort the array so that any worksheets with related exam types appear first, and all others second
  // copy the array because sort will sort the array in place - we don't want to mutate the input
  const sortedNodes = useMemo(
    () =>
      nodes.sort(
        (templateA, templateB) =>
          +!suggestedWorksheetsByExamType.has(templateA.handle) -
          +!suggestedWorksheetsByExamType.has(templateB.handle),
      ),
    [nodes, suggestedWorksheetsByExamType],
  );

  return (
    <SearchFilter
      items={sortedNodes}
      variant="secondary"
      className="mb-px"
      debounceDelay={0}
      autoFocus={autoFocus}
      data-bni-id="WorksheetSearchInput"
      css={css`
        & > * {
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;

          &:global(.ring) {
            box-shadow: none !important;
          }

          // TODO allow hiding icon
          & > svg {
            display: none;
          }
        }
      `}
      filter={(searchTerm, item) =>
        item
          .latestVersion!.title!.toLowerCase()
          .includes(searchTerm.toLowerCase())
      }
      placeholder={defineMessage({
        id: 'WorksheetAndPrefillSelect.placeholder',
        defaultMessage: 'Search…',
      })}
    >
      {({ filteredItems }) => {
        const { suggested, rest } = groupBy(filteredItems, ({ handle }) =>
          suggestedWorksheetsByExamType.has(handle) ? 'suggested' : 'rest',
        );

        function renderGroup(
          groupItems: WorksheetTemplate[],
          padStart = true,
        ) {
          return groupItems.map((template) => {
            const { handle, latestVersion, prefillConnection } = template;
            return (
              <ButtonListItem
                key={handle}
                padStart={padStart}
                onClick={() => {
                  onSelect(template);
                  Analytics.track('worksheetSelected', {
                    worksheetId: template.id,
                    templateVersionId: latestVersion?.id,
                    studyId: study.id,
                    organizationId: study.organization?.id,
                    isSuggested: !!suggested?.find(
                      (each) => each.handle === template.handle,
                    ),
                  });
                }}
              >
                <Text className="flex-grow" truncate>
                  {latestVersion!.title}
                </Text>
                {!!latestVersion?.examTypes?.length && (
                  <PopoverList
                    show={
                      latestVersion.examTypes.length > 1 ? undefined : false
                    }
                    listItems={
                      <>
                        {latestVersion.examTypes.slice(1).map((et) => (
                          <li key={et!.name}>{et!.name}</li>
                        ))}
                      </>
                    }
                  >
                    <FormattedOverflowList
                      color="body"
                      name={latestVersion.examTypes[0]!.name!}
                      numOverflowing={latestVersion.examTypes.length - 1}
                    />
                  </PopoverList>
                )}

                <ChevronRightIcon
                  height={14}
                  className={clsx(
                    'ml-2',
                    !prefillConnection!.edges!.length && 'invisible',
                  )}
                />
              </ButtonListItem>
            );
          });
        }
        return (
          <ListCardContainer
            variant="secondary"
            className="rounded-t-none py-4"
            data-bni-id="WorksheetSelectListbox"
            flush
          >
            {suggestedWorksheetsByExamType.size ? (
              <>
                {!!suggested?.length && (
                  <ListGroup
                    groupTitle={
                      <FormattedMessage
                        defaultMessage="Suggested worksheet templates"
                        id="WorksheetAndPrefillSelect.group.suggested"
                      />
                    }
                  >
                    {renderGroup(suggested)}
                  </ListGroup>
                )}
                {!!rest?.length && (
                  <ListGroup
                    groupTitle={
                      <FormattedMessage
                        defaultMessage="All worksheet templates"
                        id="WorksheetAndPrefillSelect.group.all"
                      />
                    }
                  >
                    {renderGroup(rest)}
                  </ListGroup>
                )}
              </>
            ) : (
              <>{renderGroup(filteredItems, false)}</>
            )}
          </ListCardContainer>
        );
      }}
    </SearchFilter>
  );
}

function renderPrefills(
  study: Study,
  template: WorksheetTemplate,
  onSelectPrefill: (prefill: WorksheetPrefill | null) => void,
  onBack: () => void,
) {
  const prefills = getNodes(template.prefillConnection);

  const analyticsAttributes = {
    templateId: template.id,
    studyId: study.id,
    organizationId: study.organization?.id,
  };

  return (
    <ListCardContainer
      variant="secondary"
      className="pb-4"
      data-bni-id="WorksheetPrefillSelectListbox"
      flush
    >
      <Text
        as="button"
        onClick={onBack}
        className="text-left flex items-center pb-5 pt-4 px-4"
        color="headline"
        variant="body-bold"
      >
        <ChevronLeftIcon width={8} />
        <span className="ml-3">{template.latestVersion!.title}</span>
      </Text>
      <ListGroup
        color="headline"
        groupTitle={
          <FormattedMessage
            id="worksheetAndPrefillSelect.blankGroup"
            defaultMessage="Start from a blank worksheet:"
          />
        }
      >
        <ButtonListItem
          autoFocus
          onClick={() => {
            onSelectPrefill(null);
            Analytics.track('blankPrefillSelected', analyticsAttributes);
          }}
        >
          {plusButton}
          <span className="ml-2">
            {' '}
            <FormattedMessage
              id="worksheetAndPrefillSelect.blankPrefill"
              defaultMessage="Blank worksheet"
            />
          </span>
        </ButtonListItem>
      </ListGroup>
      <ListGroup
        color="headline"
        groupTitle={
          <FormattedMessage
            id="worksheetAndPrefillSelect.prefilGroup"
            defaultMessage="Or choose from a prefilled worksheet:"
          />
        }
      >
        {prefills.map((prefill) => (
          <ButtonListItem
            key={prefill.id}
            onClick={() => {
              onSelectPrefill(prefill);
              Analytics.track('prefillSelected', {
                ...analyticsAttributes,
                prefillId: prefill.id,
              });
            }}
          >
            {plusButton}
            <span className="ml-2">{prefill.title}</span>
          </ButtonListItem>
        ))}
      </ListGroup>
    </ListCardContainer>
  );
}

const addWorksheetMutation = graphql`
  mutation WorksheetAndPrefillSelectAddMutation($input: AddWorksheetInput!) {
    addWorksheetOrError(input: $input) {
      ... on AddWorksheetPayload {
        study {
          ...ExamExamType_study
          worksheets {
            id
            # handle is needed by the onCompleted callback passed by WorksheetPanel
            handle
            ...ExamPageSidebarWorksheets
          }
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

function WorksheetAndPrefillSelect({
  study,
  onChange,
  onKeyDown,
  autoFocus,
  onClickOutside,
  relay,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<null | WorksheetTemplate>(null);

  const { data } = useQuery<WorksheetAndPrefillSelect_OrganizationQuery>(
    graphql`
      query WorksheetAndPrefillSelect_OrganizationQuery(
        $organizationHandle: String
      ) {
        organization(handle: $organizationHandle) {
          worksheetTemplateConnection(first: 2147483647, isPublished: true)
            @connection(key: "Organization_worksheetTemplateConnection") {
            edges {
              node {
                id
                handle
                latestVersion {
                  id
                  title
                  examTypes {
                    id
                    name
                  }
                }
                prefillConnection {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        organizationHandle: study.organization?.handle,
      },
    },
  );

  const [addWorksheet] =
    useMutationWithError<WorksheetAndPrefillSelectAddMutation>(
      addWorksheetMutation,
    );

  const selectTemplateAndOrPrefill = async (
    worksheetTemplate: WorksheetTemplate,
    prefill: WorksheetPrefill | null,
  ) => {
    if (prefill === undefined) {
      return setSelectedTemplate(worksheetTemplate);
    }

    setLoading(true);

    try {
      const hydratedTemplateAndPrefill =
        await fetchQuery<WorksheetAndPrefillSelect_TemplatePrefillQuery>(
          relay.environment,
          graphql`
            query WorksheetAndPrefillSelect_TemplatePrefillQuery(
              $templateVersionId: ID!
              $prefillId: ID!
              $hasPrefill: Boolean!
            ) {
              templateVersion: node(id: $templateVersionId)
                @required(action: THROW) {
                ... on WorksheetTemplateVersion {
                  ...worksheet_templateVersion
                }
              }
              prefill: node(id: $prefillId)
                @include(if: $hasPrefill)
                @required(action: THROW) {
                ... on WorksheetPrefill {
                  values
                }
              }
            }
          `,
          {
            hasPrefill: !!prefill,
            prefillId: prefill?.id || '@@not-valid',
            templateVersionId: worksheetTemplate!.latestVersion!.id,
          },
        ).toPromise();
      const defaultValue = Worksheets.deserializeFromTemplate(
        hydratedTemplateAndPrefill!.prefill as any,
        hydratedTemplateAndPrefill!.templateVersion,
        { stripIncompatible: !!prefill },
      );

      const { addWorksheetOrError } = await addWorksheet({
        input: {
          values: defaultValue.values,
          templateVersionId: worksheetTemplate.latestVersion!.id,
          organizationId: study.organization!.id,
          studyId: study.id,
        },
      });
      const worksheetsUpdated = addWorksheetOrError!.study!.worksheets;

      return onChange(
        worksheetsUpdated![worksheetsUpdated!.length - 1]!.handle!,
      );
    } finally {
      setLoading(false);
    }
  };

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClickOutside, { disabled: loading });

  return (
    <div className="relative" onKeyDown={onKeyDown} ref={ref}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading || !data ? (
        <div className="bg-black/25 absolute inset-0 z-10 flex items-center justify-center">
          <LoadingIndicator size="sm" />
        </div>
      ) : !selectedTemplate ? (
        <WorksheetSelect
          study={study}
          nodes={getNodes(data?.organization?.worksheetTemplateConnection)}
          autoFocus={autoFocus}
          onSelect={(template) => {
            if (!template.prefillConnection!.edges!.length) {
              // no prefills available, simply select template
              return selectTemplateAndOrPrefill(template, null);
            }

            return setSelectedTemplate(template);
          }}
        />
      ) : (
        renderPrefills(
          study,
          selectedTemplate,
          (prefill: WorksheetPrefill) =>
            selectTemplateAndOrPrefill(selectedTemplate, prefill),
          () => setSelectedTemplate(null),
        )
      )}
    </div>
  );
}

export default createFragmentContainer(WorksheetAndPrefillSelect, {
  study: graphql`
    fragment WorksheetAndPrefillSelect_study on Study {
      id
      examTypes {
        id
      }
      organization {
        id
        handle
      }
    }
  `,
});
