/* eslint-disable react/no-unused-prop-types */
/**
 * ConditionalContainer is a component that conditionally renders its children
 * In the future may be used to help debug hidden components.
 */
export default function ConditionalContainer({
  condition,
  children,
}: {
  condition: any | any[];
  children: React.ReactNode;
  description: string | string[];
  id?: string;
}) {
  const show = Array.isArray(condition) ? condition.some((c) => c) : condition;
  return show ? <>{children}</> : null;
}
