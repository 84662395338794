import { useRouter } from 'found';

import usePreferences from './usePreferences';

export const STUDY_LIST_VIEW_PAGE_SIZE = 8;

export const STUDY_GRID_VIEW_PAGE_SIZE = 20;

export type StudyView = 'list' | 'grid';

export default function usePreferredStudyListView(shouldClearStatus = true) {
  const {
    router,
    match: { location },
  } = useRouter();
  const [studyView, setStudyView] = usePreferences<StudyView>('studyView');

  return [
    studyView || 'list',
    (nextView: StudyView) => {
      // save in DB
      setStudyView(nextView);

      // clear sort and filter when toggling since they aren't compatible between
      // views, maintain other search params
      const nextQuery = shouldClearStatus
        ? {
            ...location.query,
            status: undefined,
            sort: undefined,
          }
        : { ...location.query };

      setTimeout(() => {
        router.replace({
          ...location,
          query: nextQuery,
        });
      });
    },
  ] as const;
}
