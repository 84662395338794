/**
 * @generated SignedSource<<cf761cd9ff0a989bddfad861f96aabec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LmsUserStatisticSorting = "AVERAGE_PROGRESS_ASC" | "AVERAGE_PROGRESS_DESC" | "COUNT_ASC" | "COUNT_DESC" | "NAME_ASC" | "NAME_DESC" | "%future added value";
export type education_UsersOverviewGrid_Query$variables = {
  groupId?: ReadonlyArray<string> | null;
  organizationId?: ReadonlyArray<string> | null;
  search?: string | null;
  sort?: ReadonlyArray<LmsUserStatisticSorting> | null;
};
export type education_UsersOverviewGrid_Query$data = {
  readonly lms: {
    readonly " $fragmentSpreads": FragmentRefs<"UsersOverviewGrid_lms">;
  } | null;
};
export type education_UsersOverviewGrid_Query = {
  response: education_UsersOverviewGrid_Query$data;
  variables: education_UsersOverviewGrid_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = {
  "kind": "Variable",
  "name": "groupId",
  "variableName": "groupId"
},
v5 = {
  "kind": "Variable",
  "name": "organizationId",
  "variableName": "organizationId"
},
v6 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v7 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "education_UsersOverviewGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "args": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "UsersOverviewGrid_lms"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "education_UsersOverviewGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "LmsUserStatisticConnection",
            "kind": "LinkedField",
            "name": "userStatisticConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LmsUserStatisticEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LmsUserStatistic",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Avatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Thumbnail",
                                    "kind": "LinkedField",
                                    "name": "thumbnails",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "width",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "ThumbnailedInterface",
                                "abstractKey": "__isThumbnailedInterface"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "averageProgress",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": [
              "search",
              "organizationId",
              "groupId",
              "sort"
            ],
            "handle": "connection",
            "key": "lms_userStatisticConnection",
            "kind": "LinkedHandle",
            "name": "userStatisticConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b87cf5e1b9e4fc72003ff6f2729dbf90",
    "id": null,
    "metadata": {},
    "name": "education_UsersOverviewGrid_Query",
    "operationKind": "query",
    "text": "query education_UsersOverviewGrid_Query(\n  $search: String\n  $sort: [LmsUserStatisticSorting!]\n  $organizationId: [String!]\n  $groupId: [ID!]\n) {\n  lms {\n    ...UsersOverviewGrid_lms_1q4cju\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n\nfragment UserInfo_userProfile on UserProfile {\n  name\n  ...Avatar_userProfile\n}\n\nfragment UsersOverviewCourseGrid_lmsUserStatistic on LmsUserStatistic {\n  id\n  handle\n  count\n}\n\nfragment UsersOverviewGrid_lms_1q4cju on Lms {\n  userStatisticConnection(first: 30, search: $search, organizationId: $organizationId, groupId: $groupId, sort: $sort) {\n    edges {\n      node {\n        id\n        profile {\n          id\n        }\n        ...UsersOverviewRow_lmsUserStatistic\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UsersOverviewRow_lmsUserStatistic on LmsUserStatistic {\n  id\n  count\n  averageProgress\n  profile {\n    id\n    handle\n    ...UserInfo_userProfile\n  }\n  ...UsersOverviewCourseGrid_lmsUserStatistic\n}\n"
  }
};
})();

(node as any).hash = "5c101ad197ac9e77bb1dc58816ecb9f9";

export default node;
