/* eslint-disable max-classes-per-file */
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import { LDClient } from 'config/FeatureFlags';
import type {
  // eslint-disable-next-line no-restricted-imports
  Match as BaseMatch,
  RouteProps as BaseRouteProps,
  RouteObject,
  RouteRenderArgs,
  RouterProps,
} from 'found';
import HttpError from 'found/HttpError';
// eslint-disable-next-line no-restricted-imports
import BaseRoute from 'found/Route';
import { ComponentType } from 'react';
import { Environment, GraphQLTaggedNode, Variables } from 'relay-runtime';

import { getNullDataProps } from 'utils/RouteUtils';
import { ViewerContext } from 'utils/viewerContext';

import StaleWhileFetching, {
  ShouldInvalidate,
} from '../../components/StaleWhileFetching';
import type LaunchDarklyManager from '../../utils/LaunchDarklyManager';
import type ViewerContextManager from '../../utils/ViewerContextManager';

type Obj = Record<string, any>;

// TODO: Move this upstream to Found Relay.
export type FetchPolicy = 'store-and-network' | 'store-or-network';

export interface OperationType<V = Variables> {
  readonly variables: V;
  readonly response: Obj;
}

function defaultGetFetchPolicy({ location }) {
  return location.action === 'POP' ? 'store-or-network' : 'store-and-network';
}

export interface MatchContext {
  environment: Environment;
  domainSubdomainLabel: string | null;
  viewerLocalId: string | null;
  launchDarkly: LaunchDarklyManager;
  viewerManager: ViewerContextManager;
}

export type Match = BaseMatch<MatchContext>;

export interface RouteMatch extends Match {
  route: RouteObject;
}

export type RoutePageProps = RouterProps<MatchContext> & {
  children?: React.ReactElement | React.ReactElement[];
};
export interface RelayRouteRenderArgs<P extends Obj = Obj>
  extends Omit<RouteRenderArgs, 'props'> {
  Component: ComponentType<any>; // this is not strictly true if you use getComponent, but most code assumes it and it's awkward
  match: Match;
  resolving?: boolean;
  error?: Error;
  props?: RoutePageProps & P;
  variables: Variables;
  ldClient: LDClient | null;
  viewerContext: ViewerContext | null;
}

export interface RouteRenderFetchedArgs<P extends Obj = Obj>
  extends RelayRouteRenderArgs {
  Component: ComponentType<any>;
  props: RoutePageProps & P;
  ldClient: LDClient;
  viewerContext: ViewerContext;
}

type RouteRenderValue =
  | React.ReactElement
  | null
  | undefined
  | ((props: any) => React.ReactElement | null);

export type Prerender<P extends Obj = Obj> = (
  renderArgs: RelayRouteRenderArgs<P>,
) => void;

export type Render<P extends Obj = Obj> = (
  renderArgs: RelayRouteRenderArgs<P>,
) => RouteRenderValue;

export type RenderFetched<P extends Obj = Obj> = (
  renderArgs: RouteRenderFetchedArgs<P>,
) => RouteRenderValue;

/**
 * Renders a route component with stale props while fetching the next route
 *
 *
 */
export function renderStaleWhileFetching(
  renderArgs: RelayRouteRenderArgs,
): React.ReactElement;
export function renderStaleWhileFetching(
  shouldInvalidate: ShouldInvalidate,
): (renderArgs: RelayRouteRenderArgs) => React.ReactElement;
export function renderStaleWhileFetching(
  argsOrShouldInvalidate: ShouldInvalidate | RelayRouteRenderArgs,
) {
  if (typeof argsOrShouldInvalidate === 'function') {
    return (args) => (
      <StaleWhileFetching
        {...args}
        shouldInvalidate={argsOrShouldInvalidate}
      />
    );
  }
  return <StaleWhileFetching {...argsOrShouldInvalidate} />;
}

/**
 * A router render function that does not wait for data to render itself.
 *
 * Useful if your component does not require its data or you want to control the loading
 * feedback directly
 */
export function renderWithoutData({
  Component,
  props,
  match,
}: RelayRouteRenderArgs) {
  const nullProps = getNullDataProps(match);

  return Component ? (
    <Component {...nullProps} match={match} router={match.router} {...props} />
  ) : null;
}

/**
 * Combine _n_ prerender functions into one. Throw an error to
 * short-circuit the chain.
 *
 * @param prerenders 1 or more prerender functions
 * @returns Prerender
 */
export function chainPrerenders<P extends Obj, O extends P>(
  ...prerenders: Prerender<O>[]
): Prerender<P> {
  return (renderArgs) => {
    for (const fn of prerenders) {
      /// XXX
      fn(renderArgs as any);
    }
  };
}

export function createRender({
  prerender,
  render,
  renderFetched,
}: RouteProps) {
  return (renderArgs: RelayRouteRenderArgs) => {
    const { Component, props, error, match } = renderArgs;

    if (error) {
      // TODO: What if we're not resolving?
      throw new HttpError(500);
    }
    renderArgs.ldClient = match?.context.launchDarkly.client;
    renderArgs.viewerContext = match?.context.viewerManager.state;

    if (prerender) {
      prerender(renderArgs);
    }

    if (render) {
      return render(renderArgs);
    }

    if (props && renderFetched) {
      return renderFetched(renderArgs as RouteRenderFetchedArgs);
    }

    // An undefined component means the route has no defined component, in
    //  which case we want to ignore the route for rendering to match upstream.
    if (Component === undefined) {
      return null;
    }

    // A null component or props means that the route is not done loading,
    //  unless someone has done something weird and explicitly set Component
    //  to null.
    if (!Component || !props) {
      return <LoadingIndicator />;
    }

    return <Component {...props} />;
  };
}

export type PrepareVariables<TQuery extends OperationType = any> = (
  variables: TQuery['variables'],
  routeMatch: RouteMatch,
) => Record<string, unknown>;

export interface RouteProps<TQuery extends OperationType = any, Props = any>
  extends Omit<BaseRouteProps, 'render'> {
  Component?: React.ComponentType<Props>;

  getComponent?: (
    match: RouteMatch,
  ) => React.ComponentType<Props> | Promise<React.ComponentType<Props>>;

  query?: GraphQLTaggedNode;

  getQuery?: (
    match: Match,
  ) => GraphQLTaggedNode | Promise<GraphQLTaggedNode> | null;

  prepareVariables?: PrepareVariables<TQuery>;

  prerender?: Prerender<TQuery['response']>;

  render?: Render<TQuery['response']>;

  renderFetched?: RenderFetched<TQuery['response']>;

  getFetchPolicy?: (match: RouteMatch) => FetchPolicy | undefined;
}

class Route extends BaseRoute {
  constructor(props: any) {
    if (props.query || props.getQuery) {
      // Translate the `defer` property to a cacheConfig, which
      // is currently the only way I know how to pass context to the
      // Relay network layer, in order to split this query out from the batch
      // so that it can render later (if needed)
      if (props.defer === true) {
        props.cacheConfig = {
          ...props.cacheConfig,
          metadata: { ...props.cacheConfig?.metadata, defer: true },
        };
      }
      props.getFetchPolicy = defaultGetFetchPolicy;
    }

    super({
      ...props,
      render: createRender(props),
    });
  }
}

interface JSXRoute {
  <TQuery extends OperationType>(
    props: RouteProps<TQuery>,
    context?: any,
  ): React.ReactElement | null;

  displayName?: string;
}

export { Route as RouteClass };

export default Route as any as JSXRoute;
