/**
 * @generated SignedSource<<b2dbb2cd27f664c84d4952ccb35abc1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainMemberPage_domainUser$data = {
  readonly id: string;
  readonly profile: {
    readonly name: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"formatDomainUserName_domainUser">;
  readonly " $fragmentType": "DomainMemberPage_domainUser";
};
export type DomainMemberPage_domainUser$key = {
  readonly " $data"?: DomainMemberPage_domainUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMemberPage_domainUser">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainMemberPage_domainUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "formatDomainUserName_domainUser",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisplayNameFirst",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisplayNameMiddle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisplayNameLast",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "DomainUser",
  "abstractKey": null
};
})();

(node as any).hash = "4096fed03825842905ad742ea7cbcbad";

export default node;
