/**
 * @generated SignedSource<<0dac0a46cd9c3cfa8cb00fb491f55e16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamStatus_finalizationRequests$data = {
  readonly createdAt: string | null;
  readonly recipient: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "ExamStatus_finalizationRequests";
};
export type ExamStatus_finalizationRequests$key = {
  readonly " $data"?: ExamStatus_finalizationRequests$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamStatus_finalizationRequests">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamStatus_finalizationRequests",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "recipient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyFinalizationRequest",
  "abstractKey": null
};

(node as any).hash = "bee1f5010e5da5778f4894395363d097";

export default node;
