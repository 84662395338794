import { css } from 'astroturf';
import React from 'react';

interface Props {
  aspectRatio?: number;
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
}

function AspectRatioBox({
  aspectRatio = 1 / 1,
  children,
  as: Component = 'div',
  ...rest
}: Props) {
  return (
    <Component
      {...rest}
      style={{ '--aspect-ratio': aspectRatio }}
      css={css`
        position: relative;

        &::before {
          content: '';
          display: block;
          height: 0;
          // This is weird looking. It's a classic trick to create a specific
          // aspect ratio for a box. the percent is based off the items width. so using
          // 100% creates a 1/1 aspect ratio, i.e. a square.
          padding-bottom: 100%; // fallback just in case
          padding-bottom: calc(100% / var(--aspect-ratio));
        }
      `}
    >
      <div className="inset-0 absolute">{children}</div>
    </Component>
  );
}

export default AspectRatioBox;
