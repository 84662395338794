import { graphql } from 'react-relay';

import asyncCommitMutation from 'utils/asyncCommitMutation';

const mutation = graphql`
  mutation UpdateArchiveMutation($input: UpdateArchiveInput!) {
    updateArchive(input: $input) {
      archive {
        label
      }
    }
  }
`;

function commit(environment, archiveId, label) {
  return asyncCommitMutation(environment, {
    mutation,
    variables: {
      input: { archiveId, label },
    },
  });
}

export default { commit };
