/**
 * @generated SignedSource<<2cf41275a4a5dbfa02dcd7013d0ce492>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EducationWelcomeModal_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CategoriesFormField_query">;
  readonly " $fragmentType": "EducationWelcomeModal_query";
};
export type EducationWelcomeModal_query$key = {
  readonly " $data"?: EducationWelcomeModal_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationWelcomeModal_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "practiceType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationWelcomeModal_query",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "practiceType",
          "variableName": "practiceType"
        }
      ],
      "kind": "FragmentSpread",
      "name": "CategoriesFormField_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a1a703ccf5bdac5ddb42cf01a9dccc3f";

export default node;
