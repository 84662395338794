/**
 * @generated SignedSource<<13bcf0af881607f5b032c4a343ab1cda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSharedImageList_images$data = ReadonlyArray<{
  readonly handle: string | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSharedImageCarousel_image" | "ImagePreviewRoll_images">;
  readonly " $fragmentType": "DesktopSharedImageList_images";
}>;
export type DesktopSharedImageList_images$key = ReadonlyArray<{
  readonly " $data"?: DesktopSharedImageList_images$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSharedImageList_images">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DesktopSharedImageList_images",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopSharedImageCarousel_image"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ImagePreviewRoll_images"
    }
  ],
  "type": "SharedBundleImage",
  "abstractKey": null
};

(node as any).hash = "7d233901b200fcc35b165adb59d48690";

export default node;
