/**
 * @generated SignedSource<<078fac28934c3b5799090b54c89de36b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DicomDocumentPath = "OPERATORS_NAME" | "REFERRING_PHYSICIAN" | "REQUESTING_PHYSICIAN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DomainMemberDicomFieldsFieldSet_dicomFieldTemplates$data = ReadonlyArray<{
  readonly dicomDocumentPath: DicomDocumentPath | null;
  readonly id: string;
  readonly label: string | null;
  readonly " $fragmentType": "DomainMemberDicomFieldsFieldSet_dicomFieldTemplates";
}>;
export type DomainMemberDicomFieldsFieldSet_dicomFieldTemplates$key = ReadonlyArray<{
  readonly " $data"?: DomainMemberDicomFieldsFieldSet_dicomFieldTemplates$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMemberDicomFieldsFieldSet_dicomFieldTemplates">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DomainMemberDicomFieldsFieldSet_dicomFieldTemplates",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomDocumentPath",
      "storageKey": null
    }
  ],
  "type": "DomainDicomFieldTemplate",
  "abstractKey": null
};

(node as any).hash = "3c578e6ee31977a3f86ab91554158c30";

export default node;
