/**
 * @generated SignedSource<<dee660fddaae0eaaeff8de6e081f2782>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MoveStudyModal_domain$data = {
  readonly id: string;
  readonly " $fragmentType": "MoveStudyModal_domain";
};
export type MoveStudyModal_domain$key = {
  readonly " $data"?: MoveStudyModal_domain$data;
  readonly " $fragmentSpreads": FragmentRefs<"MoveStudyModal_domain">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MoveStudyModal_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};

(node as any).hash = "a1c40d16e56f95843dc5ef373893a910";

export default node;
