import { useInterval } from '@restart/hooks';
import { useEffect, useState } from 'react';

export default function useCountdown(fn: () => void, seconds: number) {
  const [count, setCount] = useState<number>(seconds);

  useInterval(() => setCount((c) => Math.max(c - 1, 0)), 1000);

  useEffect(() => {
    if (count === 0) {
      fn();
    }
  }, [count, fn]);

  return count;
}
