import { reflow } from '@bfly/ui2/Transition';
import clsx from 'clsx';
import setStyle from 'dom-helpers/css';
import { useEffect, useLayoutEffect, useRef } from 'react';

class Ticker {
  status: number | null = null;

  private queue: (fn: (next: () => void) => void) => void;

  constructor(private element: HTMLElement) {
    const pending = [] as ((next: () => void) => void)[];

    const next = () => {
      const fn = pending.shift();
      window.requestAnimationFrame(() => {
        fn?.(next);
      });
    };

    this.queue = (fn: (next: () => void) => void) => {
      pending.push(fn);
      if (pending.length === 1) next();
    };

    setStyle(this.element, {
      opacity: '1',
      transition: `none`,
      transform: 'translate3d(-100%, 0, 0)',
    });

    this.set(0);
    this.tick();
  }

  tick = () => {
    setTimeout(() => {
      if (this.status == null) return;
      this.inc();
      this.tick();
    }, 200);
  };

  inc(amount?: number) {
    const { status } = this;

    if (status == null) return;
    if (amount == null) {
      if (status >= 0 && status < 0.2) {
        amount = 0.1;
      } else if (status >= 0.2 && status < 0.5) {
        amount = 0.04;
      } else if (status >= 0.5 && status < 0.8) {
        amount = 0.02;
      } else if (status >= 0.8 && status < 0.99) {
        amount = 0.005;
      } else {
        amount = 0;
      }
    }

    this.set(Math.min(status + amount, 0.994));
  }

  done() {
    this.inc(0.3 + 0.5 * Math.random());
    this.set(1);
  }

  set(num: number) {
    const speed = 200;

    reflow(this.element);

    this.status = num >= 1 ? null : num;

    this.queue((next) => {
      const percent = (-1 + num) * 100;

      setStyle(this.element, {
        transform: `translate3d(${percent}%, 0, 0)`,
        transition: `all ${speed}ms linear`,
      });

      if (num === 1) {
        setTimeout(() => {
          setStyle(this.element, {
            opacity: '0',
          });
          setTimeout(() => {
            next();
          }, speed);
        }, speed);
      } else {
        setTimeout(next, speed);
      }
    });
  }
}

interface Props {
  loading: boolean;
  className?: string;
}

function NProgress({ loading = false, className }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    ref.current!.style.opacity = '0';
  }, []);

  useEffect(() => {
    if (!loading) return undefined;

    const progress = new Ticker(ref.current!);
    return () => {
      progress.done();
    };
  }, [loading]);

  return (
    <div className={clsx(className, 'w-fill h-0.5 overflow-hidden')}>
      <div ref={ref} className="w-fill h-0.5 bg-blue" />
    </div>
  );
}

export default NProgress;
