import ArrowRightIcon from '@bfly/icons/ArrowRight';
import Dropdown from '@bfly/ui2/Dropdown';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import useModalState from 'hooks/useModalState';
import useRedirectToPrevLocation from 'hooks/useRedirectToPrevLocation';
import { canMoveStudy } from 'utils/StudyPermissions';

import MoveStudyModal from './MoveStudyModal';
import MoveStudyModalRefresh from './MoveStudyModalRefresh';
import { MoveStudyDropdownItem_study$data as Study } from './__generated__/MoveStudyDropdownItem_study.graphql';

interface Props {
  study: Study;
}

function MoveStudyDropdownItem({ study }: Props) {
  const [showModal, modalProps] = useModalState();
  const redirectToPrevLocation = useRedirectToPrevLocation();

  const studyAndArchiveId = useMemo(
    () => [
      {
        studyId: study.id,
        archiveId: study.archive!.id,
      },
    ],
    [study.id, study.archive],
  );

  function handleOrgChange() {
    redirectToPrevLocation();
  }

  const canUseMoveStudyOrg = useVariation('move-study-org');

  if (!canMoveStudy(study)) {
    return null;
  }

  return (
    <>
      <Dropdown.Item
        icon={
          <ArrowRightIcon
            // TODO: Sizing required for Safari and Firefox; remove after
            // upstream upgrade.
            width={17}
            height={14}
          />
        }
        onClick={showModal}
        data-bni-id="MoveStudyDropdownItem"
      >
        <FormattedMessage id="study.move" defaultMessage="Move Study" />
      </Dropdown.Item>
      {canUseMoveStudyOrg ? (
        <MoveStudyModalRefresh
          {...modalProps}
          organization={study.organization!}
          domain={study.organization!.domain}
          studyAndArchiveIds={studyAndArchiveId}
          onOrganizationChange={handleOrgChange}
        />
      ) : (
        <MoveStudyModal
          {...modalProps}
          organization={study.organization!}
          domain={study.organization!.domain}
          studyAndArchiveIds={studyAndArchiveId}
        />
      )}
    </>
  );
}

export default createFragmentContainer(MoveStudyDropdownItem, {
  study: graphql`
    fragment MoveStudyDropdownItem_study on Study {
      id
      organization {
        id
        handle
        ...MoveStudyModal_organization
        ...MoveStudyModalRefresh_organization
        domain {
          ...MoveStudyModalRefresh_domain
          ...MoveStudyModal_domain
        }
      }
      archive {
        id
      }
      ...StudyPermissions_canMoveStudy
    }
  `,
});
