import { Severity } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';

import sessionStore from './sessionStore';

const STATE_CSRF_KEY = 'bfly:state_csrf';

export function generateStateCsrf() {
  const csrf = uuidv4();
  Sentry.addBreadcrumb({
    level: Severity.Info,
    message: 'generated sessionStore',
    data: {
      storeUsed: (sessionStore as any).storage.name || 'unknown',
      version: sessionStore.version,
    },
  });
  sessionStore.set(STATE_CSRF_KEY, csrf);
  return csrf;
}

export function getStoredCsrfState() {
  Sentry.addBreadcrumb({
    level: Severity.Info,
    message: 'read sessionStore',
    data: {
      storeUsed: (sessionStore as any).storage.name || 'unknown',
      version: sessionStore.version,
    },
  });
  return sessionStore.get(STATE_CSRF_KEY);
}
