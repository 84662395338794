import Pdf from '@bfly/icons/Pdf';
import Dropdown from '@bfly/ui2/Dropdown';
import Tooltip from '@bfly/ui2/Tooltip';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useModalState from 'hooks/useModalState';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { useCanGenerateStudyPdf } from 'utils/StudyPermissions';

import StudyDownloadPdfModal from './StudyDownloadPdfModal';
import { StudyDownloadPdfDropdownItem_study$data as Study } from './__generated__/StudyDownloadPdfDropdownItem_study.graphql';

interface Props {
  study: Study;
}

function StudyDownloadPdfDropdownItem({ study }: Props) {
  const [showModal, modalProps] = useModalState();
  const canGenerateStudyPdf = useCanGenerateStudyPdf(study);
  const { hasStudySharing } = useSubscriptionInfo(study.organization!);

  if (hasStudySharing === 'hidden' || !canGenerateStudyPdf) {
    return null;
  }

  return (
    <>
      <Tooltip.Trigger
        placement="top"
        variant="subscription"
        show={hasStudySharing === 'disabled' ? undefined : false}
        tooltip={
          <FormattedMessage
            id="StudyDownloadPdfDropdownItem.disabled"
            defaultMessage="Upgrade your membership to get access to this feature."
          />
        }
      >
        <Dropdown.Item
          disabled={hasStudySharing === 'disabled'}
          icon={<Pdf width={18} height={18} />}
          data-bni-id="CreatePdf"
          onClick={showModal}
        >
          <FormattedMessage
            id="StudyDownloadPdfDropdownItem.createPdfDisabled"
            defaultMessage="Create PDF"
          />
        </Dropdown.Item>
      </Tooltip.Trigger>
      <StudyDownloadPdfModal study={study} {...modalProps} />
    </>
  );
}

export default createFragmentContainer(StudyDownloadPdfDropdownItem, {
  study: graphql`
    fragment StudyDownloadPdfDropdownItem_study on Study {
      ...StudyDownloadPdfModal_study
      ...StudyPermissions_canGenerateStudyPdf

      organization {
        ...useSubscriptionInfo_organization
      }
    }
  `,
});
