import { FormVariant } from '@bfly/ui2/Form';
import React from 'react';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DebouncedSearchControl from './DebouncedSearchControl';
import { WorklistSearchBar_worklist$data as Worklist } from './__generated__/WorklistSearchBar_worklist.graphql';

interface Props {
  worklist: Worklist;
  defaultValue: string | null;
  onChange: (search: string) => void;
  variant?: FormVariant;
  autoFocus?: boolean;
}

const messages = defineMessages({
  placeholderEbw: {
    id: 'worklistPage.search.placeholder.ebw',
    defaultMessage: 'Search by name or Patient ID',
  },
  placeholderMwl: {
    id: 'worklistPage.search.placeholder.mwl',
    defaultMessage: 'Search by name, Patient ID or ACC#',
  },
});

function WorklistSearchBar({
  worklist,
  onChange,
  defaultValue,
  variant,
  autoFocus,
}: Props) {
  const placeholder =
    worklist.type === 'EncounterBasedWorklist'
      ? messages.placeholderEbw
      : messages.placeholderMwl;
  return (
    <DebouncedSearchControl
      className="mb-6"
      autoFocus={autoFocus}
      defaultValue={defaultValue ?? undefined}
      placeholder={placeholder}
      onChange={onChange}
      variant={variant}
    />
  );
}

export default createFragmentContainer(WorklistSearchBar, {
  worklist: graphql`
    fragment WorklistSearchBar_worklist on WorklistInterface {
      type: __typename
    }
  `,
});
