import { array, string } from 'yup';

export function join(_schema: any, items: string[]): string {
  return items.join(',');
}

export function split(_schema: any, items: string): string[] {
  return items.split(',');
}

export const arrayFromString = array().of(string()).transform(split);
