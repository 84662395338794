import BlankSlate from '@bfly/ui2/BlankSlate';
import Link from '@bfly/ui2/Link';
import MainContent from '@bfly/ui2/MainContent';
import { FormattedMessage } from 'react-intl';

import { useConfigRoutes } from 'routes/config';

export default function NotFoundPage() {
  const configRoutes = useConfigRoutes();
  return (
    <MainContent centerText data-bni-id="NotFoundPage">
      <BlankSlate>
        <BlankSlate.Title>
          {/* TODO: Change to "Not Found"? */}
          <FormattedMessage id="404.title" defaultMessage="404" />
        </BlankSlate.Title>
        <BlankSlate.Body>
          <FormattedMessage
            id="404.body"
            defaultMessage="The page you are looking for cannot be found."
          />
        </BlankSlate.Body>
        <Link as={BlankSlate.Action} to={configRoutes.rootRoute()} size="lg">
          <FormattedMessage id="404.returnHome" defaultMessage="Return Home" />
        </Link>
      </BlankSlate>
    </MainContent>
  );
}
