/**
 * @generated SignedSource<<42db0da26a78bc4b430db4f756d95190>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExamTypeSelectButtonTenantQuery$variables = {
  tenantId: string;
};
export type ExamTypeSelectButtonTenantQuery$data = {
  readonly tenant: {
    readonly examTypeConnection?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly handle: string | null;
          readonly hiddenAt: string | null;
          readonly id: string;
          readonly isAssociatedWithStudies: boolean | null;
          readonly name: string | null;
          readonly systemId: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly id?: string;
  } | null;
};
export type ExamTypeSelectButtonTenantQuery = {
  response: ExamTypeSelectButtonTenantQuery$data;
  variables: ExamTypeSelectButtonTenantQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 2147483647
    }
  ],
  "concreteType": "ExamTypeConnection",
  "kind": "LinkedField",
  "name": "examTypeConnection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "handle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hiddenAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isAssociatedWithStudies",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "systemId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "examTypeConnection(first:2147483647)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamTypeSelectButtonTenantQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "TenantInterface",
            "abstractKey": "__isTenantInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamTypeSelectButtonTenantQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "TenantInterface",
            "abstractKey": "__isTenantInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d344cd268aa88b6d42360bf39259bd8",
    "id": null,
    "metadata": {},
    "name": "ExamTypeSelectButtonTenantQuery",
    "operationKind": "query",
    "text": "query ExamTypeSelectButtonTenantQuery(\n  $tenantId: ID!\n) {\n  tenant: node(id: $tenantId) {\n    __typename\n    ... on TenantInterface {\n      __isTenantInterface: __typename\n      id\n      examTypeConnection(first: 2147483647) {\n        edges {\n          node {\n            id\n            handle\n            name\n            hiddenAt\n            isAssociatedWithStudies\n            systemId\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc82a5e260872f5c2361dff874628dbf";

export default node;
