/**
 * @generated SignedSource<<02eb671f6444410439ac653355232f9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_studyOrArchiveIsDeleted$data = {
  readonly archive: {
    readonly deletedAt: string | null;
  } | null;
  readonly deletedAt: string | null;
  readonly " $fragmentType": "StudyPermissions_studyOrArchiveIsDeleted";
};
export type StudyPermissions_studyOrArchiveIsDeleted$key = {
  readonly " $data"?: StudyPermissions_studyOrArchiveIsDeleted$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_studyOrArchiveIsDeleted"
};

(node as any).hash = "b80f62f7302d7b7094e3c899b26c3d4c";

export default node;
