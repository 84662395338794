import React, { useEffect, useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { EducationContentPlayer_educationContent$data as EducationContent } from './__generated__/EducationContentPlayer_educationContent.graphql';

// Plyr under Vimeo hides the vimeo controls by increasing the
// height of the iframe and overlaying the Plyr controls above.
// this causes weird issues with trying to contain the video
// section within the required area.

function createVimeoUrl(vimeoId: string) {
  return `${globalThis.bflyConfig.VIMEO_PLAYER_URL}/${vimeoId}?loop=false&byline=false&title=false&transparent=false&gesture=media&dnt=false&autoplay=true`;
}

interface Props {
  educationContent: EducationContent;
  onEnded: () => void;
}

function EducationContentPlayer({ educationContent, onEnded }: Props) {
  const playerRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    // @ts-ignore
    import(/* webpackChunkName: "vimeo" */ '@vimeo/player').then(
      ({ default: Player }) => {
        if (playerRef.current) {
          const player = new Player(playerRef.current);
          player.on('ended', onEnded);
        }
      },
    );
  }, [onEnded, playerRef]);

  return (
    <iframe
      title={educationContent!.name!}
      src={createVimeoUrl(educationContent!.video!.vimeoId!)}
      allowFullScreen
      ref={playerRef}
      className="w-full h-full border-0"
    />
  );
}

export default createFragmentContainer(EducationContentPlayer, {
  educationContent: graphql`
    fragment EducationContentPlayer_educationContent on EducationContent {
      name
      video {
        vimeoId
      }
    }
  `,
});
