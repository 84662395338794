/**
 * @generated SignedSource<<2a98b7fe7f5f2a30cbd36c98915310b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getConcreteTenant_tenant$data = {
  readonly type: "Domain";
  readonly " $fragmentType": "getConcreteTenant_tenant";
} | {
  readonly type: "Organization";
  readonly " $fragmentType": "getConcreteTenant_tenant";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly type: "%other";
  readonly " $fragmentType": "getConcreteTenant_tenant";
};
export type getConcreteTenant_tenant$key = {
  readonly " $data"?: getConcreteTenant_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"getConcreteTenant_tenant">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getConcreteTenant_tenant"
};

(node as any).hash = "79b64f6b302c76ca650a6a9200043b65";

export default node;
