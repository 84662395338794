import BlankSlate from '@bfly/ui2/BlankSlate';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import FormCheck from '@bfly/ui2/FormCheck';
import ItemGrid from '@bfly/ui2/ItemGrid';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import notNullish from '@bfly/utils//notNullish';
import getNodes from '@bfly/utils/getNodes';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import * as yup from 'yup';

import SearchTags from 'components/SearchTags';
import actionMessages from 'messages/actions';
import {
  SearchData,
  mapStoredSearchToSearchData,
  messages,
} from 'utils/Search';
import withModal from 'utils/withModal';

import { GlobalStudySearchRecentSearchesModal_Query } from './__generated__/GlobalStudySearchRecentSearchesModal_Query.graphql';

const schema = yup.object({
  selectedSearchId: yup.string().required(),
});

interface Props {
  onHide: () => void;
  onApply: (query: SearchData) => void;
  organizationSlug: string | null;
}

function SearchRecentSearchModal({
  onHide,
  onApply,
  organizationSlug,
}: Props) {
  const { data } = useQuery<GlobalStudySearchRecentSearchesModal_Query>(
    graphql`
      query GlobalStudySearchRecentSearchesModal_Query($slug: String) {
        tenant(slug: $slug) {
          viewerRecentSearchConnection(first: 2147483647) {
            edges {
              node {
                id
                lastExecutedAt
                criteria {
                  ...SearchTags_studySearchCriteria
                }
                ...Search_searchToData
              }
            }
          }
        }
      }
    `,
    {
      variables: { slug: organizationSlug },
      fetchPolicy: 'store-and-network',
    },
  );

  const searches = data
    ? getNodes(data?.tenant?.viewerRecentSearchConnection)
    : [];

  const isLoading = !data;
  const hasSearches = !!searches.length;

  const handleFormSubmit = ({ selectedSearchId }) => {
    if (!selectedSearchId) return;

    const searchData = mapStoredSearchToSearchData(
      searches.find((s) => s.id === selectedSearchId)!,
    );
    onApply(searchData);
    onHide();
  };

  return (
    <Form schema={schema} onSubmit={handleFormSubmit}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...messages.recentSearches} />
        </Modal.Title>
      </Modal.Header>
      {isLoading && (
        <Modal.Body>
          <LoadingIndicator />
        </Modal.Body>
      )}
      {!isLoading && !hasSearches && (
        <Modal.Body>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                id="globalStudySearchRecentSearchesModal.noSearches"
                defaultMessage="No recent searches available"
              />
            </BlankSlate.Title>
          </BlankSlate>
        </Modal.Body>
      )}
      {!isLoading && hasSearches && (
        <>
          <Modal.Body className="max-h-[70vh]">
            <Form.Field name="selectedSearchId">
              {({ value: selected, onChange: setSelected }) => (
                <ItemGrid templateColumns="35px auto 80px">
                  <thead>
                    <ItemGrid.Row>
                      <ItemGrid.Header />
                      <ItemGrid.Header className="justify-start">
                        <FormattedMessage {...messages.details} />
                      </ItemGrid.Header>
                      <ItemGrid.Header className="justify-start">
                        <FormattedMessage {...messages.date} />
                      </ItemGrid.Header>
                    </ItemGrid.Row>
                  </thead>
                  <tbody>
                    {searches.map(({ id, criteria, lastExecutedAt }) => (
                      <ItemGrid.Row
                        key={id}
                        onClickCapture={() => setSelected(id)}
                        aria-selected={selected === id}
                      >
                        <ItemGrid.Cell
                          onClick={() => setSelected(id)}
                          className="pl-3"
                        >
                          <FormCheck
                            type="radio"
                            name="savedSearch"
                            onChange={() => setSelected(id)}
                            checked={selected === id}
                          />
                        </ItemGrid.Cell>
                        <ItemGrid.Cell className="justify-start">
                          <SearchTags
                            variant="light"
                            nodes={null}
                            studySearchCriteria={criteria!.filter(notNullish)}
                            wrap
                          />
                        </ItemGrid.Cell>
                        <ItemGrid.Cell className="pr-3 justify-start">
                          <FormattedDate value={lastExecutedAt!} />
                        </ItemGrid.Cell>
                      </ItemGrid.Row>
                    ))}
                  </tbody>
                </ItemGrid>
              )}
            </Form.Field>
          </Modal.Body>
          <Modal.Footer>
            <Modal.ButtonGroup>
              <Form.Submit size="lg">
                <FormattedMessage {...messages.apply} />
              </Form.Submit>
              <Form.Message for="selected">
                {() => (
                  <Text
                    variant="sm"
                    color="danger"
                    className="mt-2 mr-2 ml-auto"
                  >
                    <FormattedMessage
                      id="globalStudySearchRecentSearchesModal.selectSearch"
                      defaultMessage="Select a recent search"
                    />
                  </Text>
                )}
              </Form.Message>
              <Button size="lg" variant="secondary" onClick={() => onHide()}>
                <FormattedMessage {...actionMessages.cancel} />
              </Button>
            </Modal.ButtonGroup>
          </Modal.Footer>
        </>
      )}
    </Form>
  );
}

export default withModal(SearchRecentSearchModal);
