/**
 * @generated SignedSource<<5a5e61569b96f0032459463e64037fbc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DuplicateDomainRolePage_membershipRole$data = {
  readonly " $fragmentSpreads": FragmentRefs<"roles_defaultValue">;
  readonly " $fragmentType": "DuplicateDomainRolePage_membershipRole";
};
export type DuplicateDomainRolePage_membershipRole$key = {
  readonly " $data"?: DuplicateDomainRolePage_membershipRole$data;
  readonly " $fragmentSpreads": FragmentRefs<"DuplicateDomainRolePage_membershipRole">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DuplicateDomainRolePage_membershipRole",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "roles_defaultValue",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permissions",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "MembershipRole",
  "abstractKey": null
};

(node as any).hash = "959eeaa0594b8d693dd260973dacf33d";

export default node;
