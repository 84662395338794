import localStore from 'store/dist/store.modern';

import { Match } from 'components/Route';

export const DEBOUNCE_TIMEOUT = 300;

export enum UserPractice {
  HUMAN = 'HUMAN',
  VETERINARY = 'VETERINARY',
  NONE = 'NONE',
}

export const getLastShownVideoTypesKey = ({
  context: { viewerLocalId },
}: Match): string => `${viewerLocalId}:shownVideoTypes`;

export const getLastShownVideoType = (
  context: Match,
): UserPractice | undefined =>
  localStore.get(getLastShownVideoTypesKey(context));

export const setLastShownVideoType = (
  context: Match,
  videoType: UserPractice,
): void => localStore.set(getLastShownVideoTypesKey(context), videoType);

export const clearLastShownVideoType = (context: Match) =>
  localStore.remove(getLastShownVideoTypesKey(context));
