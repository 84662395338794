import { useRouter } from 'found';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { SelectedItemsByKey } from 'components/MultiSelectContext';

export type User = { id: string; name?: string };
export type Course = {
  id: string;
  name: string | null;
  dueDate?: Date | null;
};

type EnrollmentContextValue = {
  selectedUsers: SelectedItemsByKey<User>;
  setSelectedUsers: Dispatch<SetStateAction<SelectedItemsByKey<User>>>;
  selectedCourses: SelectedItemsByKey<Course>;
  setSelectedCourses: Dispatch<SetStateAction<SelectedItemsByKey<Course>>>;
  resetSelection: () => void;
  setCourseDueDate: (
    courseId: string | string[],
    dueDate: Date | undefined | null,
  ) => void;
};

const EnrollmentContext = React.createContext<EnrollmentContextValue>(
  null as any,
);

export default EnrollmentContext;

export function EnrollmentContextProvider({ children }) {
  const { match } = useRouter();
  const user: User | null = match.location?.state?.user;

  const [selectedUsers, setSelectedUsers] = useState<SelectedItemsByKey<User>>(
    user ? new Map([[user.id, user]]) : new Map(),
  );

  const [selectedCourses, setSelectedCourses] = useState<
    SelectedItemsByKey<Course>
  >(new Map());

  const resetSelection = useCallback(() => {
    setSelectedUsers(new Map());
    setSelectedCourses(new Map());
  }, []);

  const setCourseDueDate = useCallback(
    (courseId: string | string[], dueDate: Date | null) => {
      const courseIds = Array.isArray(courseId) ? courseId : [courseId];
      const updatedCourses = new Map(selectedCourses);

      courseIds.forEach((id) => {
        if (selectedCourses.has(id)) {
          updatedCourses.set(id, { ...selectedCourses.get(id)!, dueDate });
        }
      });

      setSelectedCourses(updatedCourses);
    },
    [selectedCourses],
  );

  const contextValue = useMemo(
    () => ({
      selectedUsers,
      setSelectedUsers,
      selectedCourses,
      setSelectedCourses,
      resetSelection,
      setCourseDueDate,
    }),
    [resetSelection, selectedCourses, selectedUsers, setCourseDueDate],
  );

  return (
    <EnrollmentContext.Provider value={contextValue}>
      {children}
    </EnrollmentContext.Provider>
  );
}

export function useEnrollmentContext() {
  const context = useContext(EnrollmentContext) as EnrollmentContextValue;

  if (!context) {
    throw new Error(
      'useEnrollmentContext must be called within EnrollmentContext provider',
    );
  }

  return context;
}
