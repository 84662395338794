import Logo from '@bfly/icons/Logo';
import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import Heading from '@bfly/ui2/Heading';
import MainContent from '@bfly/ui2/MainContent';
import { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { InferType, object, string } from 'yup';

import { useAuth } from 'components/AuthContext';
import getGraphqlUpstreamKey from 'utils/regionKeys';

import { AuthType } from '../../utils/auditLogs';

const messages = defineMessages({
  regionSelectLabel: {
    id: 'regionSelect.label',
    defaultMessage: 'Select your region',
  },
});

const schema = object({ region: string() });

export type FormValue = InferType<typeof schema>;

export interface Props {
  apiUrlsByRegion: Record<string, string>;
  accessToken: string;
}

function UserRegionSelectForm({ apiUrlsByRegion, accessToken }: Props) {
  const auth = useAuth();
  const [disabled, setDisabled] = useState(true);

  const onChange = (value: FormValue) => setDisabled(!value.region);

  const onSubmit = async ({ region }: FormValue) => {
    if (!region) return;

    const apiUrlUpstream = apiUrlsByRegion[region];
    const KEY = getGraphqlUpstreamKey();
    sessionStorage.setItem(KEY, apiUrlUpstream);
    globalThis.bflyConfig.GRAPHQL_UPSTREAM = apiUrlUpstream;

    await auth.setAccessToken(accessToken, {
      trackLogin: true,
      authType: AuthType.AUTH0,
    });
  };

  return (
    <MainContent centerText>
      <Logo className="mb-5 w-10" />
      <Heading>
        <FormattedMessage
          id="userRegionSelectForm.title"
          defaultMessage="Select your region to view applicable organizations"
        />
      </Heading>
      <Form<typeof schema>
        defaultValue={{ region: null }}
        onChange={onChange}
        onSubmit={onSubmit}
        className="max-w-[27rem] m-auto text-left"
      >
        <Form.FieldGroup
          name="region"
          data={Object.keys(apiUrlsByRegion)}
          dataKey="value"
          placeholder={messages.regionSelectLabel}
          as={DropdownList}
        />
        <Form.Submit
          variant="primary"
          size="lg"
          className="w-full"
          disabled={disabled}
        >
          <FormattedMessage
            id="userRegionSelectForm.next"
            defaultMessage="Next"
          />
        </Form.Submit>
      </Form>
    </MainContent>
  );
}

export default UserRegionSelectForm;
