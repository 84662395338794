/**
 * @generated SignedSource<<34e530639ec639e84babada1507e0728>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LmsCourseSorting = "NAME_ASC" | "NAME_DESC" | "%future added value";
export type education_CourseOverviewGrid_Query$variables = {
  groupId?: ReadonlyArray<string> | null;
  organizationId?: ReadonlyArray<string> | null;
  search?: string | null;
  sort?: ReadonlyArray<LmsCourseSorting> | null;
};
export type education_CourseOverviewGrid_Query$data = {
  readonly lms: {
    readonly " $fragmentSpreads": FragmentRefs<"CourseOverviewGrid_lms">;
  } | null;
};
export type education_CourseOverviewGrid_Query = {
  response: education_CourseOverviewGrid_Query$data;
  variables: education_CourseOverviewGrid_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = {
  "kind": "Variable",
  "name": "groupId",
  "variableName": "groupId"
},
v5 = {
  "kind": "Variable",
  "name": "organizationId",
  "variableName": "organizationId"
},
v6 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v7 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "education_CourseOverviewGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "args": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "CourseOverviewGrid_lms"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "education_CourseOverviewGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "LmsCourseConnection",
            "kind": "LinkedField",
            "name": "courseConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LmsCourseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LmsCourse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "concreteType": "LmsCourseStatistic",
                        "kind": "LinkedField",
                        "name": "lmsCourseStatistic",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "averageProgress",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": [
              "search",
              "sort"
            ],
            "handle": "connection",
            "key": "lms_courseConnection",
            "kind": "LinkedHandle",
            "name": "courseConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d39c6286290643e9789d9263bd5c1112",
    "id": null,
    "metadata": {},
    "name": "education_CourseOverviewGrid_Query",
    "operationKind": "query",
    "text": "query education_CourseOverviewGrid_Query(\n  $search: String\n  $sort: [LmsCourseSorting!]\n  $organizationId: [String!]\n  $groupId: [ID!]\n) {\n  lms {\n    ...CourseOverviewGrid_lms_1q4cju\n  }\n}\n\nfragment CourseOverviewGrid_lms_1q4cju on Lms {\n  courseConnection(first: 30, search: $search, sort: $sort) {\n    edges {\n      node {\n        id\n        ...CourseOverviewRow_lmsCourse_4hSOAf\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CourseOverviewMemberGrid_lmsCourse_4hSOAf on LmsCourse {\n  id\n  handle\n  lmsCourseStatistic(organizationId: $organizationId, groupId: $groupId) {\n    count\n    id\n  }\n}\n\nfragment CourseOverviewRow_lmsCourse_4hSOAf on LmsCourse {\n  id\n  name\n  description\n  lmsCourseStatistic(organizationId: $organizationId, groupId: $groupId) {\n    averageProgress\n    count\n    id\n  }\n  ...CourseOverviewMemberGrid_lmsCourse_4hSOAf\n}\n"
  }
};
})();

(node as any).hash = "f3447c82e653dee763507dd359d5360d";

export default node;
