/**
 * @generated SignedSource<<e002d278369aafe47b0064166364aebc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorksheetAndPrefillSelect_study$data = {
  readonly examTypes: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly id: string;
  readonly organization: {
    readonly handle: string | null;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "WorksheetAndPrefillSelect_study";
};
export type WorksheetAndPrefillSelect_study$key = {
  readonly " $data"?: WorksheetAndPrefillSelect_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetAndPrefillSelect_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorksheetAndPrefillSelect_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "fc8040c125b13b3007b513409443169d";

export default node;
