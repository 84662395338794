import Button from '@bfly/ui2/Button';
import Link from '@bfly/ui2/Link';
import useToast from '@bfly/ui2/useToast';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { object, ref, string } from 'yup';

import AuthContent from 'components/AuthContent';
import AuthForm from 'components/AuthForm';
import MultiTextInputGroup from 'components/MultiTextInputGroup';
import { RoutePageProps } from 'components/Route';
import accountMessages from 'messages/account';
import { routes } from 'routes/config';

import passwordMessages from '../messages/password';
import ConfirmLogoutDialog from './ConfirmLogoutDialog';
import { ResetPasswordPageMutation } from './__generated__/ResetPasswordPageMutation.graphql';

type Props = RoutePageProps;

const mutation = graphql`
  mutation ResetPasswordPageMutation($input: ResetPasswordInput!) {
    resetPasswordOrError(input: $input) {
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

const resetPasswordSchema = object({
  password: string()
    .required(passwordMessages.required as any)
    .min(8, passwordMessages.length as any),
  confirmPassword: string()
    .required(passwordMessages.required as any)
    .min(8, passwordMessages.length as any)
    .oneOf([ref('password')], passwordMessages.mismatch as any),
});

const defaultValue = resetPasswordSchema.getDefault();

function ResetPasswordPage({
  router,
  match: {
    location: { query },
  },
}: Props) {
  const toast = useToast();
  const handleCompleted = () => {
    router.replace(routes.rootRoute());

    toast.success(
      <FormattedMessage
        id="resetPassword.success"
        defaultMessage="Password reset successfully"
      />,
    );
  };

  return (
    <AuthContent>
      <AuthContent.Title>
        <FormattedMessage
          id="resetPassword.title"
          defaultMessage="Enter New Password"
        />
      </AuthContent.Title>
      <ConfirmLogoutDialog>
        <FormattedMessage
          id="resetPassword.confirmLogout"
          defaultMessage="You are already logged in. Do you want to log out to reset your password?"
        />
      </ConfirmLogoutDialog>
      <AuthForm<ResetPasswordPageMutation, typeof resetPasswordSchema>
        schema={resetPasswordSchema}
        defaultValue={defaultValue}
        mutation={mutation}
        getInput={(input) => ({
          password: input.password,
          token: query.token || query.reset_token,
        })}
        onCompleted={handleCompleted}
      >
        <MultiTextInputGroup>
          <AuthForm.FieldGroup
            name="password"
            type="password"
            label={passwordMessages.password}
            help={passwordMessages.help}
            placeholder={passwordMessages.newPassword}
          />
          <AuthForm.FieldGroup
            name="confirmPassword"
            type="password"
            label={passwordMessages.confirmPassword}
            labelSrOnly
            placeholder={passwordMessages.confirmPassword}
          />
        </MultiTextInputGroup>
        <AuthForm.Submit>
          <FormattedMessage
            defaultMessage="Reset Password"
            id="resetPassword.submit"
          />
        </AuthForm.Submit>
      </AuthForm>
      <Link as={Button} variant="text-primary" to={routes.rootRoute()}>
        <FormattedMessage {...accountMessages.logIn} />
      </Link>
    </AuthContent>
  );
}

export default ResetPasswordPage;
