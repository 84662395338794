/**
 * @generated SignedSource<<17827585f37464d06302d20bd3fbc63d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
export type StudyVersionReason = "ADDED_QA_ENTRY" | "ADDED_WORKSHEET" | "CREATED" | "DELETED" | "FINALIZED_DRAFT" | "INFO_UPDATED" | "MARKED_DRAFT" | "MOVED" | "REMOVED_QA_ENTRY" | "REMOVED_WORKSHEET" | "UNDELETED" | "UPDATED_QA_ENTRY" | "UPDATED_WORKSHEET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyHistoryModalContent_versionCreatedStudyEvent$data = {
  readonly version: {
    readonly accessionNumber: string | null;
    readonly archive: {
      readonly deletedAt: string | null;
      readonly label: string | null;
    } | null;
    readonly id: string;
    readonly notes: string | null;
    readonly patient: {
      readonly " $fragmentSpreads": FragmentRefs<"PatientInfo_patient" | "PatientName_patient">;
    } | null;
    readonly practiceType: StudyPracticeType | null;
    readonly studyDescription: string | null;
    readonly versionCommittedAt: string | null;
    readonly versionCommittedBy: {
      readonly name: string | null;
    } | null;
    readonly versionReason: StudyVersionReason | null;
    readonly vetPatient: {
      readonly " $fragmentSpreads": FragmentRefs<"VetPatientInfo_vetPatient" | "VetPatientName_vetPatient">;
    } | null;
  } | null;
  readonly " $fragmentType": "StudyHistoryModalContent_versionCreatedStudyEvent";
};
export type StudyHistoryModalContent_versionCreatedStudyEvent$key = {
  readonly " $data"?: StudyHistoryModalContent_versionCreatedStudyEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyHistoryModalContent_versionCreatedStudyEvent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyHistoryModalContent_versionCreatedStudyEvent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyVersion",
      "kind": "LinkedField",
      "name": "version",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Archive",
          "kind": "LinkedField",
          "name": "archive",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deletedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "practiceType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessionNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Patient",
          "kind": "LinkedField",
          "name": "patient",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PatientName_patient"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PatientInfo_patient"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VetPatient",
          "kind": "LinkedField",
          "name": "vetPatient",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "VetPatientName_vetPatient"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "VetPatientInfo_vetPatient"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studyDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "versionCommittedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "versionReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "versionCommittedBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "VersionCreatedStudyEvent",
  "abstractKey": null
};

(node as any).hash = "0c72ec0c16d04cf92bd267c230b8d3e9";

export default node;
