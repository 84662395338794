import clsx from 'clsx';
import useParams from 'found/useParams';
import React, { useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import ExamImageThumbnail from './ExamImageThumbnail';
import { ExamImageRoll_images$data as Images } from './__generated__/ExamImageRoll_images.graphql';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  images: Images;
}

function ExamImageRoll({ images, ...props }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const params = useParams();

  // FIXME: this is scrolling the page oddly
  // useEffect(() => {
  //   ref
  //     .current!.querySelector('[aria-selected="true"]')
  //     ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  // }, [params.studyImageHandle]);

  return (
    <div
      {...props}
      ref={ref}
      data-bni-id="ExamImageRoll"
      className={clsx(props.className, 'flex items-center scrollable')}
    >
      {images &&
        images.map((image, idx) => {
          return (
            <ExamImageThumbnail
              key={image.handle}
              image={image}
              index={idx}
              selected={params.studyImageHandle === image.handle}
            />
          );
        })}
    </div>
  );
}

export default createFragmentContainer(ExamImageRoll, {
  images: graphql`
    fragment ExamImageRoll_images on StudyImageInterface @relay(plural: true) {
      ... on StudyImage {
        handle
        ...ExamImageThumbnail_image
      }
      ... on EduStudyImage {
        handle
        ...ExamImageThumbnail_image
      }
    }
  `,
});
