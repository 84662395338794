/**
 * @generated SignedSource<<93ef055c9fcf61cddfeef6724543bc7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type domainUser_defaultValue$data = {
  readonly canFinalize: boolean | null;
  readonly canQa: boolean | null;
  readonly dicomFields: ReadonlyArray<{
    readonly dicomFieldTemplate: {
      readonly id: string;
    } | null;
    readonly value: string | null;
  }>;
  readonly email: string | null;
  readonly integrationConfigs: ReadonlyArray<{
    readonly ehr: {
      readonly id: string;
    } | null;
    readonly interfaceCode: string | null;
  }>;
  readonly integrationDisplayNameFirst: string | null;
  readonly integrationDisplayNameLast: string | null;
  readonly integrationDisplayNameMiddle: string | null;
  readonly isNurse: boolean | null;
  readonly role: {
    readonly id: string;
  } | null;
  readonly type: DomainUserType | null;
  readonly " $fragmentType": "domainUser_defaultValue";
};
export type domainUser_defaultValue$key = {
  readonly " $data"?: domainUser_defaultValue$data;
  readonly " $fragmentSpreads": FragmentRefs<"domainUser_defaultValue">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "domainUser_defaultValue"
};

(node as any).hash = "57038e795eacbba14cb9784dd2654a65";

export default node;
