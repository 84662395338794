/**
 * @generated SignedSource<<fdaf7bfae2c4dae4c7b4d114720b2e31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WorksheetAndPrefillSelect_OrganizationQuery$variables = {
  organizationHandle?: string | null;
};
export type WorksheetAndPrefillSelect_OrganizationQuery$data = {
  readonly organization: {
    readonly worksheetTemplateConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly handle: string | null;
          readonly id: string;
          readonly latestVersion: {
            readonly examTypes: ReadonlyArray<{
              readonly id: string;
              readonly name: string | null;
            } | null> | null;
            readonly id: string;
            readonly title: string | null;
          } | null;
          readonly prefillConnection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly title: string | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type WorksheetAndPrefillSelect_OrganizationQuery = {
  response: WorksheetAndPrefillSelect_OrganizationQuery$data;
  variables: WorksheetAndPrefillSelect_OrganizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "organizationHandle"
  }
],
v2 = {
  "kind": "Literal",
  "name": "isPublished",
  "value": true
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "WorksheetTemplateEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorksheetTemplate",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorksheetTemplateVersion",
            "kind": "LinkedField",
            "name": "latestVersion",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamType",
                "kind": "LinkedField",
                "name": "examTypes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorksheetPrefillConnection",
            "kind": "LinkedField",
            "name": "prefillConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorksheetPrefillEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorksheetPrefill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorksheetAndPrefillSelect_OrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": "worksheetTemplateConnection",
            "args": [
              (v2/*: any*/)
            ],
            "concreteType": "WorksheetTemplateConnection",
            "kind": "LinkedField",
            "name": "__Organization_worksheetTemplateConnection_connection",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "__Organization_worksheetTemplateConnection_connection(isPublished:true)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorksheetAndPrefillSelect_OrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "WorksheetTemplateConnection",
            "kind": "LinkedField",
            "name": "worksheetTemplateConnection",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "worksheetTemplateConnection(first:2147483647,isPublished:true)"
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [
              "isPublished"
            ],
            "handle": "connection",
            "key": "Organization_worksheetTemplateConnection",
            "kind": "LinkedHandle",
            "name": "worksheetTemplateConnection"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce744c1716458c2537618301c8a8bb74",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "organization",
            "worksheetTemplateConnection"
          ]
        }
      ]
    },
    "name": "WorksheetAndPrefillSelect_OrganizationQuery",
    "operationKind": "query",
    "text": "query WorksheetAndPrefillSelect_OrganizationQuery(\n  $organizationHandle: String\n) {\n  organization(handle: $organizationHandle) {\n    worksheetTemplateConnection(first: 2147483647, isPublished: true) {\n      edges {\n        node {\n          id\n          handle\n          latestVersion {\n            id\n            title\n            examTypes {\n              id\n              name\n            }\n          }\n          prefillConnection {\n            edges {\n              node {\n                id\n                title\n              }\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e8a7f0f2e817a7d819407b81d9527836";

export default node;
