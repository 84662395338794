/**
 * @generated SignedSource<<5fb4935840264f7ee7139fc714ae4ef4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThirdPartyUltrasoundListenerConfigurationContent_archive$data = {
  readonly id: string;
  readonly " $fragmentType": "ThirdPartyUltrasoundListenerConfigurationContent_archive";
};
export type ThirdPartyUltrasoundListenerConfigurationContent_archive$key = {
  readonly " $data"?: ThirdPartyUltrasoundListenerConfigurationContent_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThirdPartyUltrasoundListenerConfigurationContent_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThirdPartyUltrasoundListenerConfigurationContent_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "4ded4a5c0b6f34223610f7c36b71821d";

export default node;
