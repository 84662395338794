/**
 * @generated SignedSource<<d8fe7bcaa15dc23617cd4748a57990ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyAuthorsListItem_study$data = {
  readonly createdBy: {
    readonly __typename: "UserProfile";
  } | null;
  readonly finalizedAt: string | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"studyAuthors_study">;
  readonly " $fragmentType": "StudyAuthorsListItem_study";
};
export type StudyAuthorsListItem_study$key = {
  readonly " $data"?: StudyAuthorsListItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyAuthorsListItem_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyAuthorsListItem_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "studyAuthors_study",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "secondaryAuthors",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "d701908a46fa598711206b5652136d71";

export default node;
