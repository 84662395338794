/**
 * @generated SignedSource<<2f71813cac2286723569922fa9cf8f11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PatientName_patient$data = {
  readonly nameFirst: string | null;
  readonly nameLast: string | null;
  readonly nameMiddle: string | null;
  readonly namePrefix: string | null;
  readonly nameSuffix: string | null;
  readonly " $fragmentType": "PatientName_patient";
};
export type PatientName_patient$key = {
  readonly " $data"?: PatientName_patient$data;
  readonly " $fragmentSpreads": FragmentRefs<"PatientName_patient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PatientName_patient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameFirst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameLast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameMiddle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namePrefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameSuffix",
      "storageKey": null
    }
  ],
  "type": "PatientInterface",
  "abstractKey": "__isPatientInterface"
};

(node as any).hash = "095b3e1e79e6266c55e24fe622081170";

export default node;
