import { Params, route } from '@4c/spa-routing';
import { useRouter } from 'found';
import { useMemo } from 'react';

import partialRoutes from '../utils/partialRoutes';

export const orgRoutes = {
  dashboard: route('/:organizationSlug/notifications'),
};

export function useNotificationsRoutes(newOrgSlug?: string | null) {
  const { match } = useRouter();
  // If params are passed use them, otherwise use existing ones
  const organizationSlug = newOrgSlug || match.params.organizationSlug;

  return useMemo(() => {
    const params: Params<'organizationSlug'> = {
      organizationSlug,
    };

    const locations = partialRoutes(orgRoutes, params);

    return {
      ...locations,
    };
  }, [organizationSlug]);
}
