import DropdownIcon from '@bfly/icons/Dropdown';
import Help from '@bfly/icons/Help';
import Button from '@bfly/ui2/Button';
import Popover from '@bfly/ui2/Popover';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DataGrid, { ColumnProp } from 'components/DataGrid';
import {
  LaunchDarklyClient,
  getVariation,
  useLaunchDarklyClient,
  useVariation,
} from 'components/LaunchDarklyContext';

import useStudyStatisticsColumnsUserPreference, {
  StudyStatisticsColumnUserPreferenceKey,
} from '../hooks/useStudyStatisticsColumnsUserPreference';
import {
  ExpandedHistoricalProficiency,
  ExpandedStudyStatistics,
  expandHistoricalProficiency,
  expandStudyStatistics,
} from '../utils/StudyStatistics';
import { StudyStatisticsGrid_studyStatisticsByAuthor$data as StudyStatisticsByAuthors } from './__generated__/StudyStatisticsGrid_studyStatisticsByAuthor.graphql';

const styles = stylesheet`

  .non-author-row {
    & td {
      @apply min-h-6 border-b-0;
    }

  }
  .author-row + .non-author-row,
  .non-author-row + .author-row {
    & td {
      @apply mt-2;
    }
  }

  .non-author-row + .author-row {
    & td {
      @apply border-t border-grey-85;
    }
  }

  th .header {
    @apply py-2 whitespace-normal items-center flex flex-1 w-[10rem];
  }

`;

type StudyStatisticsByAuthor = StudyStatisticsByAuthors[0];
type StudyAuthorStatistic = StudyStatisticsByAuthor & {
  type: 'StudyStatisticsByAuthor';
  level: number;
};

type StudyExamTypeStatistic = NonNullable<
  NonNullable<StudyAuthorStatistic['examTypeStatistics']>[0]
> & {
  id: string;
  type: 'ExamTypeStudyStatistics';
  level: number;
};

type StudyWorksheetStatistic = NonNullable<
  NonNullable<StudyExamTypeStatistic['worksheetStatistics']>[0]
> & {
  id: string;
  type: 'ExamTypeWorksheetsStatistics';
  level: number;
};

type WorksheetHeader = { id: string; type: 'WorksheetHeader'; level: number };

type ExpandedStudyAuthorStatistic = StudyAuthorStatistic &
  ExpandedStudyStatistics &
  ExpandedHistoricalProficiency;
type ExpandedStudyExamTypeStatistic = StudyExamTypeStatistic &
  ExpandedStudyStatistics &
  ExpandedHistoricalProficiency;

type StatisticDatum =
  | ExpandedStudyAuthorStatistic
  | ExpandedStudyExamTypeStatistic
  | StudyWorksheetStatistic
  | WorksheetHeader;

interface StudyStatisticsColumnContext {
  setRowExpanded(id: string, expand: boolean): void;
  isRowExpanded(id: string): boolean;
  ldClient: LaunchDarklyClient;
}

function StatText({
  item,
  children,
}: {
  item: StatisticDatum;
  children?: ReactNode;
}) {
  return (
    <Text
      variant={item.type === 'StudyStatisticsByAuthor' ? 'body-bold' : 'body'}
    >
      {children}
    </Text>
  );
}

type StudyStatisticColumn = ColumnProp<
  StudyStatisticsColumnContext,
  StatisticDatum
>;

function StudyStatisticHeaderColumn({
  item,
  setRowExpanded,
  isRowExpanded,
}: {
  item: StatisticDatum;
  isRowExpanded: (id: string) => boolean;
  setRowExpanded: (id: string, expand: boolean) => void;
}) {
  const expanded = isRowExpanded(item.id);
  const { formatMessage } = useIntl();

  let title: string | null | undefined = '';
  switch (item.type) {
    case 'ExamTypeStudyStatistics':
      title =
        item.examType?.name ||
        formatMessage({
          id: 'StudyStatisticsGrid.noExamType',
          defaultMessage: 'No exam type',
        });
      break;
    case 'ExamTypeWorksheetsStatistics':
      title =
        item?.worksheetTemplates
          ?.map((t) => t?.latestVersion?.title ?? '')
          .filter(Boolean)
          .sort((t1, t2) => t1.localeCompare(t2))
          .join(' | ') ||
        formatMessage({
          id: 'StudyStatisticsGrid.noWorksheetTitle',
          defaultMessage: 'No worksheet',
        });
      break;
    case 'StudyStatisticsByAuthor':
      title = item.userProfile!.name;
      break;
    case 'WorksheetHeader':
      title = formatMessage({
        id: 'studyStatisticsGrid.worksheetsTitle',
        defaultMessage: 'Worksheets',
      });
      break;
    default:
      return <> </>;
  }

  let expandRowButton: JSX.Element | null = null;
  if (
    item.type === 'StudyStatisticsByAuthor' ||
    (item.type === 'ExamTypeStudyStatistics' && item.worksheetStatistics)
  ) {
    expandRowButton = (
      <Button
        className="w-8 p-0 ml-4"
        aria-controls={item.id}
        aria-expanded={expanded}
        onClick={() => setRowExpanded(item.id, !expanded)}
        variant="text-secondary"
      >
        <DropdownIcon className={expanded ? '-rotate-180' : undefined} />
      </Button>
    );
  }

  return (
    <div
      className={clsx(
        'max-w-xs truncate',
        item.type === 'ExamTypeStudyStatistics' && 'ml-6',
        (item.type === 'ExamTypeWorksheetsStatistics' ||
          item.type === 'WorksheetHeader') &&
          'ml-10 whitespace-normal',
        !expandRowButton && 'pl-[32px]',
        item.type === 'WorksheetHeader' && 'text-grey-50',
      )}
      title={title || ''}
    >
      {expandRowButton}
      {title}
    </div>
  );
}

const HeaderLabel = ({ children }: { children: ReactNode }) => (
  <span className={styles.header}>{children}</span>
);

const nameColumn: StudyStatisticColumn = {
  key: 'name',
  frozen: true,
  picker: { show: false },
  width: '26.8rem',
  label: (
    <Text className="ml-12">
      <FormattedMessage id="StudyStatisticsGrid.name" defaultMessage="Name" />
    </Text>
  ),
  render(item, { setRowExpanded, isRowExpanded }) {
    return (
      <StudyStatisticHeaderColumn
        item={item}
        setRowExpanded={setRowExpanded}
        isRowExpanded={isRowExpanded}
      />
    );
  },
};

const examTypeTagsCountColumn: StudyStatisticColumn = {
  key: 'examTypeTagsCount',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.examTypeTagsCount"
        defaultMessage="Exam type tags count"
      />
    </HeaderLabel>
  ),
  render(item) {
    if (item.type === 'WorksheetHeader') {
      return '';
    }
    return item.type !== 'StudyStatisticsByAuthor'
      ? item.numFinalizedStudies
      : '';
  },
};

const numExamTypeAssociationsColumn: StudyStatisticColumn = {
  key: 'numExamTypeAssociations',
  label: (
    <HeaderLabel>
      <div className="flex-1">
        <FormattedMessage
          id="StudyStatisticsGrid.numExamTypeAssociations"
          defaultMessage="Exam type tag counts"
        />
      </div>
      <Tooltip.Trigger
        tooltip={
          <FormattedMessage
            id="StudyStatisticsGrid.examTypeTagsCountDesc"
            defaultMessage="Total number of exam type tags associated with a user’s exams"
          />
        }
      >
        <Help className="w-4 h-4 shrink-0" />
      </Tooltip.Trigger>
    </HeaderLabel>
  ),

  picker: {
    show: true,
    label: (
      <FormattedMessage
        id="StudyStatisticsGrid.numExamTypeAssociations2"
        defaultMessage="Exam type tag counts"
      />
    ),
  },
  render: (item) => {
    switch (item.type) {
      case 'StudyStatisticsByAuthor':
        return <StatText item={item}>{item.numExamTypeAssociations}</StatText>;
      case 'ExamTypeStudyStatistics':
        return <StatText item={item}>{item.studyExamTypeTotal}</StatText>;
      default:
        return null;
    }
  },
};

const studyStatusStatisticsTotalColumn: StudyStatisticColumn = {
  key: 'studyStatusStatisticsTotal',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.studyStatusStatisticsTotal"
        defaultMessage="Total exams"
      />
    </HeaderLabel>
  ),
  render: (item) => {
    switch (item.type) {
      case 'StudyStatisticsByAuthor':
      case 'ExamTypeStudyStatistics':
        return (
          <StatText item={item}>{item.studyStatusStatisticsTotal}</StatText>
        );
      default:
        return null;
    }
  },
};

const studyStatusStatisticsDraftsColumn: StudyStatisticColumn = {
  key: 'studyStatusStatisticsDrafts',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.studyStatusStatisticsDrafts"
        defaultMessage="Total drafts"
      />
    </HeaderLabel>
  ),
  render: (item) => {
    switch (item.type) {
      case 'StudyStatisticsByAuthor':
      case 'ExamTypeStudyStatistics':
        return (
          <StatText item={item}>{item.studyStatusStatisticsDrafts}</StatText>
        );
      default:
        return null;
    }
  },
};

const studyStatusStatisticsPendingAttestationColumn: StudyStatisticColumn = {
  key: 'studyStatusStatisticsPendingAttestation',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.studyStatusStatisticsPendingAttestation"
        defaultMessage="Total pending attestation"
      />
    </HeaderLabel>
  ),
  render: (item) => {
    switch (item.type) {
      case 'StudyStatisticsByAuthor':
      case 'ExamTypeStudyStatistics':
        return (
          <StatText item={item}>
            {item.studyStatusStatisticsPendingAttestation}
          </StatText>
        );
      default:
        return null;
    }
  },
};

const totalFinalizedExamsColumn: StudyStatisticColumn = {
  key: 'totalFinalizedExams',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.totalFinalizedExams"
        defaultMessage="Total finalized exams"
      />
    </HeaderLabel>
  ),
  render: (item) => {
    switch (item.type) {
      case 'StudyStatisticsByAuthor':
      case 'ExamTypeStudyStatistics':
        return <StatText item={item}>{item.numFinalizedStudies}</StatText>;
      default:
        return null;
    }
  },
};

const totalQAedExamsColumn: StudyStatisticColumn = {
  key: 'totalQAedExams',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.totalQAedExams"
        defaultMessage="Total QA'ed exams"
      />
    </HeaderLabel>
  ),
  render: (item) => {
    switch (item.type) {
      case 'StudyStatisticsByAuthor':
      case 'ExamTypeStudyStatistics':
        return <StatText item={item}>{item.numReviewedStudies}</StatText>;
      default:
        return null;
    }
  },
};

const totalNeedsQAColumn: StudyStatisticColumn = {
  key: 'totalNeedsQa',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.totalNeedsQA"
        defaultMessage="Total exams needing QA"
      />
    </HeaderLabel>
  ),
  render: (item) => {
    switch (item.type) {
      case 'StudyStatisticsByAuthor':
      case 'ExamTypeStudyStatistics':
        return (
          <StatText item={item}>
            {item.studyStatusStatisticsNeedsReview}
          </StatText>
        );
      default:
        return null;
    }
  },
};

const averageImageQualityColumn: StudyStatisticColumn = {
  key: 'averageImageQuality',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.averageImageQuality"
        defaultMessage="Image quality"
      />
    </HeaderLabel>
  ),
  render(item) {
    switch (item.type) {
      case 'StudyStatisticsByAuthor':
      case 'ExamTypeStudyStatistics':
        return (
          <StatText item={item}>
            {item.averageImageQuality?.toFixed(1) || ''}
          </StatText>
        );
      default:
        return null;
    }
  },
};

const accuracyAsPresentedStatisticsColumn: StudyStatisticColumn = {
  key: 'accuracyAsPresentedStatistics',
  label: (
    <FormattedMessage
      id="StudyStatisticsGrid.accuracyAsPresentedStatistics"
      defaultMessage="As Presented"
    />
  ),
  picker: { show: 'collapsed' },
  columns: [
    {
      key: 'accuracyAsPresentedStatisticsTp',
      label: <>TP</>,
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.accuracyAsPresentedStatisticsTP}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'accuracyAsPresentedStatisticsTn',
      label: <>TN</>,
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.accuracyAsPresentedStatisticsTN}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'accuracyAsPresentedStatisticsFp',
      label: <>FP</>,
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.accuracyAsPresentedStatisticsFP}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'accuracyAsPresentedStatisticsFn',
      label: <>FN</>,
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.accuracyAsPresentedStatisticsFN}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
  ],
};

const accuracyComparedToGoldStandardStatistics: StudyStatisticColumn = {
  key: 'accuracyComparedToGoldStandardStatistics',
  label: (
    <FormattedMessage
      id="StudyStatisticsGrid.accuracyComparedToGoldStandardStatistics"
      defaultMessage="Compared to GS"
    />
  ),
  picker: { show: 'collapsed' },
  columns: [
    {
      key: 'accuracyComparedToGoldStandardStatisticsTp',
      label: <>TP</>,
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.accuracyComparedToGoldStandardStatisticsTP}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'accuracyComparedToGoldStandardStatisticsTn',
      label: <>TN</>,
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.accuracyComparedToGoldStandardStatisticsTN}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'accuracyComparedToGoldStandardStatisticsFp',
      label: <>FP</>,
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.accuracyComparedToGoldStandardStatisticsFP}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'accuracyComparedToGoldStandardStatisticsFn',
      label: <>FN</>,
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.accuracyComparedToGoldStandardStatisticsFN}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
  ],
};

const feedbackStatisticsColumn: StudyStatisticColumn = {
  key: 'feedbackStatistics',
  label: (
    <FormattedMessage
      id="StudyStatisticsGrid.feedbackStatistics"
      defaultMessage="Feedback"
    />
  ),
  columns: [
    {
      key: 'feedbackStatisticsAdjustGain',
      label: (
        <HeaderLabel>
          <FormattedMessage
            id="StudyStatisticsGrid.feedbackStatisticsAdjustGain"
            defaultMessage="Adjust gain"
          />
        </HeaderLabel>
      ),
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.feedbackStatisticsAdjustGain}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'feedbackStatisticsAdjustDepth',
      label: (
        <HeaderLabel>
          <FormattedMessage
            id="StudyStatisticsGrid.feedbackStatisticsAdjustDepth"
            defaultMessage="Adjust depth"
          />
        </HeaderLabel>
      ),
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.feedbackStatisticsAdjustDepth}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'feedbackStatisticsIncompleteMeasurement',
      label: (
        <HeaderLabel>
          <FormattedMessage
            id="StudyStatisticsGrid.feedbackStatisticsIncompleteMeasurement"
            defaultMessage="Incomplete measurement"
          />
        </HeaderLabel>
      ),
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.feedbackStatisticsIncompleteMeasurement}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'feedbackStatisticsIncorrectPreset',
      label: (
        <HeaderLabel>
          <FormattedMessage
            id="StudyStatisticsGrid.feedbackStatisticsIncorrectPreset"
            defaultMessage="Incorrect preset"
          />
        </HeaderLabel>
      ),
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.feedbackStatisticsIncorrectPreset}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'feedbackStatisticsIncorrectOrientation',
      label: (
        <HeaderLabel>
          <FormattedMessage
            id="StudyStatisticsGrid.feedbackStatisticsIncorrectOrientation"
            defaultMessage="Incorrect orientation"
          />
        </HeaderLabel>
      ),
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.feedbackStatisticsIncorrectOrientation}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'feedbackStatisticsIncorrectTransducer',
      label: (
        <HeaderLabel>
          <FormattedMessage
            id="StudyStatisticsGrid.feedbackStatisticsIncorrectTransducer"
            defaultMessage="Incorrect transducer"
          />
        </HeaderLabel>
      ),
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.feedbackStatisticsIncorrectTransducer}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'feedbackStatisticsMissingStandardViews',
      label: (
        <HeaderLabel>
          <FormattedMessage
            id="StudyStatisticsGrid.feedbackStatisticsMissingStandardViews"
            defaultMessage="Missing standard views"
          />
        </HeaderLabel>
      ),
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.feedbackStatisticsMissingStandardViews}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
    {
      key: 'feedbackStatisticsNotClinicallyIndicated',
      label: (
        <HeaderLabel>
          <FormattedMessage
            id="StudyStatisticsGrid.feedbackStatisticsNotClinicallyIndicated"
            defaultMessage="Not clinically indicated"
          />
        </HeaderLabel>
      ),
      render: (item) => {
        switch (item.type) {
          case 'StudyStatisticsByAuthor':
          case 'ExamTypeStudyStatistics':
            return (
              <StatText item={item}>
                {item.feedbackStatisticsNotClinicallyIndicated}
              </StatText>
            );
          default:
            return null;
        }
      },
    },
  ],
};

const numCountsTowardsCredentialingColumn: StudyStatisticColumn = {
  key: 'numCountsTowardsCredentialing',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.numCountsTowardsCredentialing"
        defaultMessage="Counts towards proficiency"
      />
    </HeaderLabel>
  ),
  render: (item, { ldClient }) => {
    if (
      item.type === 'StudyStatisticsByAuthor' ||
      item.type === 'ExamTypeStudyStatistics'
    ) {
      const butterflyCount =
        item.type === 'StudyStatisticsByAuthor'
          ? item.numCountsTowardCredentialingButterfly
          : item.numCountsTowardsCredentialing;
      if (!getVariation(ldClient, 'historical-proficiency')) {
        return <span>{butterflyCount}</span>;
      }
      const count =
        item.type === 'StudyStatisticsByAuthor'
          ? item.numCountsTowardCredentialingWithHistorical
          : (item.numCountsTowardsCredentialing || 0) +
            item.numCountsTowardCredentialingHistorical;
      const historicalCount = item.numCountsTowardCredentialingHistorical;
      return (
        <Popover.Trigger
          trigger="passive"
          placement="top"
          variant="dark"
          popover={
            <span>
              <FormattedMessage
                id="StudyStatisticsGrid.numCountsTowardCredentialingPopover"
                defaultMessage="Includes {historicalCount} count from outside Butterfly"
                values={{ historicalCount }}
              />
            </span>
          }
        >
          <span>{count}</span>
        </Popover.Trigger>
      );
    }
    return <span>0</span>;
  },
};

const numCountsTowardsCredentialingHistoricalColumn: StudyStatisticColumn = {
  key: 'numCountsTowardCredentialingHistorical',
  label: (
    <HeaderLabel>
      <FormattedMessage
        id="StudyStatisticsGrid.numCountsTowardsCredentialingHistorical"
        defaultMessage="Counts towards proficiency: manually entered data"
      />
    </HeaderLabel>
  ),
  disabled: ({ ldClient }) =>
    !getVariation(ldClient, 'historical-proficiency'),
  render: (item) => {
    if (
      item.type === 'ExamTypeStudyStatistics' ||
      item.type === 'StudyStatisticsByAuthor'
    ) {
      return <span>{item.numCountsTowardCredentialingHistorical}</span>;
    }
    return <span>0</span>;
  },
};

const existingColumns: StudyStatisticColumn[] = [
  nameColumn,
  totalFinalizedExamsColumn,
  totalQAedExamsColumn,
  examTypeTagsCountColumn,
];

const newColumns: StudyStatisticColumn[] = [
  nameColumn,
  numExamTypeAssociationsColumn,
  studyStatusStatisticsTotalColumn,
  studyStatusStatisticsDraftsColumn,
  studyStatusStatisticsPendingAttestationColumn,
  totalFinalizedExamsColumn,
  totalQAedExamsColumn,
  totalNeedsQAColumn,
  averageImageQualityColumn,
  accuracyAsPresentedStatisticsColumn,
  accuracyComparedToGoldStandardStatistics,
  feedbackStatisticsColumn,
  numCountsTowardsCredentialingColumn,
  numCountsTowardsCredentialingHistoricalColumn,
];

function RowComponent({
  item,
  children,
  ...props
}: {
  item: StatisticDatum | WorksheetHeader;
  context: StudyStatisticsColumnContext;
  children: ReactElement;
}) {
  return (
    <DataGrid.Row
      {...props}
      id={item.id}
      aria-level={item.level}
      className={clsx(
        item.type === 'StudyStatisticsByAuthor' && styles.authorRow,
        item.type !== 'StudyStatisticsByAuthor' && styles.nonAuthorRow,
      )}
    >
      {children}
    </DataGrid.Row>
  );
}

const NONE = 'none';

interface Props {
  studyStatisticsByAuthor: StudyStatisticsByAuthors;
  viewportScrolling?: boolean;
  showUpdatedColumns?: boolean;
  allowScrollToLastColumn?: boolean;
  rowsExpanded: Map<string, Set<string>>;
  onRowExpand: (nextRowsExpanded: Map<string, Set<string>>) => void;
  columnsPreferenceKey?: StudyStatisticsColumnUserPreferenceKey;
  fetching: boolean;
}

function StudyStatisticsGrid({
  studyStatisticsByAuthor,
  viewportScrolling,
  showUpdatedColumns,
  allowScrollToLastColumn,
  rowsExpanded,
  onRowExpand,
  columnsPreferenceKey = StudyStatisticsColumnUserPreferenceKey.STUDY_STATISTICS_LIST,
  fetching,
}: Props) {
  const useNewColumns = useVariation('columns-for-qa-updates');
  const ldClient = useLaunchDarklyClient();
  const showUpdatedQaColumns = showUpdatedColumns || useNewColumns;
  const columns = showUpdatedQaColumns ? newColumns : existingColumns;
  const [userColumns, setUserColumns] =
    useStudyStatisticsColumnsUserPreference(columnsPreferenceKey);

  const [columnOrder, columnVisibility] = useMemo(() => {
    const order =
      (userColumns[columnsPreferenceKey]?.[0] as string[]) || undefined;

    const visibility =
      (userColumns[columnsPreferenceKey]?.[1] as Record<string, boolean>) ||
      undefined;

    return [order, visibility] as const;
  }, [userColumns, columnsPreferenceKey]);

  function handleColumnChange(
    nextOrder: string[],
    nextVisibility: Record<string, boolean>,
  ) {
    const nextColumns = [nextOrder, nextVisibility];

    setUserColumns({
      ...userColumns,
      [columnsPreferenceKey]: nextColumns,
    });
  }

  const columnContext = useMemo(
    () => ({
      setRowExpanded(id: string, expand: boolean) {
        const [authorId, examTypeId] = id.split(':');

        if (examTypeId) {
          if (!expand) {
            rowsExpanded.get(authorId)?.delete(examTypeId);
          } else {
            const current = rowsExpanded.get(authorId) || new Set<string>();

            current.add(examTypeId);
            rowsExpanded.set(authorId, current);
          }
        } else if (expand) {
          rowsExpanded.set(authorId, new Set());
        } else {
          rowsExpanded.delete(authorId);
        }

        onRowExpand(new Map(rowsExpanded));
      },
      isRowExpanded(id: string) {
        const [authorId, examTypeId] = id.split(':');
        return examTypeId
          ? !!rowsExpanded.get(authorId)?.has(examTypeId)
          : rowsExpanded.has(authorId);
      },
      ldClient,
    }),
    [rowsExpanded, onRowExpand, ldClient],
  );

  const flatData = useMemo(() => {
    let authorCounter = 0;
    const studyStatisticsByAuthorSorted = [...studyStatisticsByAuthor].sort(
      (a: any, b: any) => (a.userProfile?.name > b.userProfile?.name ? 1 : -1),
    );

    return studyStatisticsByAuthorSorted.reduce((data, authorStat) => {
      const authorId = `${authorStat.id}/${++authorCounter}`;

      data.push({
        ...authorStat,
        ...expandStudyStatistics(authorStat),
        ...expandHistoricalProficiency(authorStat),
        type: 'StudyStatisticsByAuthor',
        level: 1,
        id: authorId,
      });

      const rowExpanded = rowsExpanded.get(authorId);

      if (rowExpanded) {
        sortBy(
          authorStat.examTypeStatistics!,
          (es) => es!.examType?.name,
        ).forEach((examTypeStat) => {
          const examTypeId = examTypeStat!.examType?.handle || NONE;
          const examTypeName = examTypeStat!.examType?.name || '';
          const examTypeExpanded = rowExpanded.has(examTypeId);

          data.push({
            ...examTypeStat!,
            ...expandStudyStatistics(examTypeStat!, ['studyStatisticsTotal']),
            ...expandHistoricalProficiency(authorStat, examTypeName),
            type: 'ExamTypeStudyStatistics',
            id: `${authorId}:${examTypeId}`,
            level: 2,
          });

          if (examTypeExpanded) {
            data.push({
              id: `${authorId}:${examTypeId}:title`,
              type: 'WorksheetHeader',
              level: 3,
            });
            examTypeStat!.worksheetStatistics?.forEach(
              (wkStat: StudyWorksheetStatistic, idx) => {
                data.push({
                  ...wkStat!,
                  level: 3,
                  id: `${authorId}:${examTypeId}:${idx}`,
                });
              },
            );
          }
        });
      }
      return data;
    }, [] as StatisticDatum[]);
  }, [rowsExpanded, studyStatisticsByAuthor]);

  return (
    <DataGrid
      loading={fetching}
      data={flatData}
      scrollKey="stats"
      columns={columns}
      columnContext={columnContext}
      rowComponent={RowComponent}
      columnOrder={columnOrder}
      columnVisibility={columnVisibility}
      defaultVisibility={{ numCountsTowardCredentialingHistorical: false }}
      viewportScrolling={viewportScrolling}
      allowScrollToLastColumn={allowScrollToLastColumn}
      onColumnVisibilityChange={(nextVisiblity) =>
        handleColumnChange(columnOrder, nextVisiblity)
      }
      onColumnOrderChange={(nextOrder) => {
        handleColumnChange(nextOrder, columnVisibility);
      }}
    />
  );
}

export default createFragmentContainer(StudyStatisticsGrid, {
  studyStatisticsByAuthor: graphql`
    fragment StudyStatisticsGrid_studyStatisticsByAuthor on StudyStatisticsByAuthor
    @relay(plural: true)
    @argumentDefinitions(
      showWorksheetStats: { type: "Boolean", defaultValue: true }
    ) {
      type: __typename
      id
      userInfo {
        email
      }
      userProfile {
        handle
        name
      }
      numFinalizedStudies
      numReviewedStudies
      numExamTypeAssociations
      numCountsTowardsCredentialing
      userHistoricalProficiency {
        examTypeCounts {
          examType {
            name
          }
          count
        }
      }
      examTypeStatistics {
        type: __typename
        examType {
          name
          handle
        }
        numFinalizedStudies
        numReviewedStudies
        numCountsTowardsCredentialing
        averageImageQuality
        studyStatusStatistics {
          key
          numStudies
        }
        accuracyAsPresentedStatistics {
          key
          numFinalizedStudies
        }
        accuracyComparedToGoldStandardStatistics {
          key
          numFinalizedStudies
        }
        feedbackStatistics {
          key
          numFinalizedStudies
        }
        worksheetStatistics @include(if: $showWorksheetStats) {
          type: __typename
          worksheetTemplates {
            latestVersion {
              id
              title
            }
          }
          numFinalizedStudies
          numReviewedStudies
        }
      }
      averageImageQuality
      studyStatusStatistics {
        key
        numStudies
      }
      accuracyAsPresentedStatistics {
        key
        numFinalizedStudies
      }
      accuracyComparedToGoldStandardStatistics {
        key
        numFinalizedStudies
      }
      feedbackStatistics {
        key
        numFinalizedStudies
      }
    }
  `,
});
