import Layout from '@4c/layout';
import ArrowLeft from '@bfly/icons/ArrowLeft';
import FailureIcon from '@bfly/icons/Failure';
import Button from '@bfly/ui2/Button';
import { NestedForm, useField } from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import formatName from '@bfly/utils/formatName';
import { useCallback, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import PromptButton from 'components/PromptButton';
import examMessages from 'messages/examMessages';
import Analytics from 'utils/Analytics';

import AssociateExamWorklistItemModalContent from '../../components/AssociateExamWorklistItemModalContent';
import { formatWorklistItem } from '../../schemas/baseStudyInfo';
import { Sex } from '../../schemas/humanStudyInfo';
import SplitStudyPatientHumanFields from './SplitStudyPatientHumanFields';
import SplitStudyPatientVetFields from './SplitStudyPatientVetFields';
import { SplitStudyPatientEdit_sourceStudy$data as Study } from './__generated__/SplitStudyPatientEdit_sourceStudy.graphql';

export type FormattedWorklistItem = ReturnType<typeof formatWorklistItem>;

interface Props {
  sourceStudy: Study;
  onHide: () => void;
}

const messages = {
  backToSplitStudy: defineMessage({
    id: 'SplitStudyPatientEdit.backToSplitStudy',
    defaultMessage: 'Back to split details',
  }),
};

function SplitStudyPatientEdit({ sourceStudy: study, onHide }: Props) {
  const [{ onChange: setPatient }] = useField('patient');
  const [{ onChange: setVetPatient }] = useField('vetPatient');
  const [{ value: accessionNumber, onChange: setAccessionNumber }] =
    useField('accessionNumber');
  const [{ value: sourceWorklistItemId, onChange: setSourceWorklistItemId }] =
    useField('sourceWorklistItemId');
  const [{ onChange: setDicomDocument }] = useField('dicomDocument');

  const isPushing = study.pacsPushStatus !== 'NOT_PUSHING';
  const canEditPhi = !useVariation('disable-phi-entry');

  const showAssociateWorklistButton =
    !study.finalizedAt && !!study.organization!.primaryWorklist;

  const [showAssociateWorklistItem, setShowAssociateWorklistItem] = useState(
    showAssociateWorklistButton,
  );

  const disabledInput =
    !!study.finalizedAt ||
    !canEditPhi ||
    accessionNumber ||
    sourceWorklistItemId;

  const noPatientData =
    !study.patient ||
    (!formatName(study.patient) && !study.patient.medicalRecordNumber);

  const showWorklistRequired = !!(
    study.practiceType === 'HUMAN' &&
    study.archive!.worklistRequiredToFinalize &&
    !study.hasAssociatedWorklistItem
  );

  const resetPatient = useCallback(() => {
    setAccessionNumber(undefined);
    setSourceWorklistItemId(undefined);
    setDicomDocument({});
    if (study?.practiceType === 'HUMAN') {
      setPatient({
        birthDate: null,
        nameFirst: '',
        nameLast: '',
        nameMiddle: '',
        namePrefix: '',
        nameSuffix: '',
        internalId: '',
        medicalRecordNumber: '',
        sex: null,
      });
    } else if (study?.practiceType === 'VETERINARY') {
      setVetPatient({
        breed: '',
        birthDate: null,
        species: 'UNKNOWN',
        speciesFreeform: '',
        clientNameFirst: '',
        clientNameLast: '',
        clientNameMiddle: '',
        clientNameSuffix: '',
        clientNamePrefix: '',
        clientOrganizationName: '',
        name: '',
        sex: 'UNKNOWN',
        neuterStatus: null,
        patientIdNumber: '',
        microchipNumber: '',
        weightKilograms: null,
      });
    }
  }, [
    study,
    setPatient,
    setVetPatient,
    setSourceWorklistItemId,
    setAccessionNumber,
    setDicomDocument,
  ]);

  const selectWorklistItem = useCallback(
    (worklistItem: FormattedWorklistItem) => {
      setAccessionNumber(worklistItem.accessionNumber);
      setSourceWorklistItemId(worklistItem.sourceWorklistItemId);
      setDicomDocument(worklistItem.dicomDocument);
      if (study?.practiceType === 'HUMAN') {
        setPatient({
          nameFirst: worklistItem.nameFirst,
          nameLast: worklistItem.nameLast,
          nameMiddle: worklistItem.nameMiddle,
          namePrefix: worklistItem.namePrefix,
          nameSuffix: worklistItem.nameSuffix,
          internalId: worklistItem.internalId,
          medicalRecordNumber: worklistItem.medicalRecordNumber,
          birthDate: worklistItem.birthDate
            ? new Date(worklistItem.birthDate)
            : null,
          sex: worklistItem.sex as keyof typeof Sex,
        });
      }
    },
    [
      study,
      setPatient,
      setAccessionNumber,
      setSourceWorklistItemId,
      setDicomDocument,
    ],
  );

  if (showAssociateWorklistItem) {
    return (
      <AssociateExamWorklistItemModalContent
        primaryWorklist={study.organization!.primaryWorklist!}
        onHide={onHide}
        onBack={() => setShowAssociateWorklistItem(false)}
        onSelect={(worklistItem) => {
          const formatted = formatWorklistItem(worklistItem);
          selectWorklistItem(formatted);
          onHide();
          Analytics.track('splitStudyPatientSelected', {
            studyId: study.id,
            organizationId: study.organization?.id,
          });
        }}
      />
    );
  }

  return (
    <>
      <Modal.Header>
        <Button
          variant="text-secondary"
          onClick={onHide}
          className="px-0 mb-3"
          data-bni-id="BackFromWorklistButton"
        >
          <ArrowLeft width={20} className="mr-2" />
          <FormattedMessage {...messages.backToSplitStudy} />
        </Button>

        <Modal.Title>
          <FormattedMessage {...examMessages.editPatient} />
        </Modal.Title>
        {showWorklistRequired && noPatientData && (
          <FormattedMessage {...examMessages.requiresSelection} />
        )}
        {showWorklistRequired && !noPatientData && (
          <Layout align="center" className="text-danger mt-2">
            <FailureIcon
              className="mr-1"
              height={15}
              css={`
                :global(.foreground) {
                  fill: transparent;
                }
              `}
            />
            <FormattedMessage {...examMessages.requiresSelection} />
          </Layout>
        )}
        {showAssociateWorklistButton && (
          <PromptButton
            variant="secondary"
            onClick={() => setShowAssociateWorklistItem(true)}
            className="w-full mt-3"
            data-bni-id="ChooseFromWorklistButton"
          >
            {study.sourceWorklistItem ? (
              <FormattedMessage {...examMessages.changePatient} />
            ) : (
              <FormattedMessage {...examMessages.chooseFromWorklist} />
            )}
          </PromptButton>
        )}
      </Modal.Header>
      <Modal.Body>
        {study.practiceType === 'HUMAN' && (
          <NestedForm name="patient">
            <SplitStudyPatientHumanFields
              study={study}
              disabled={disabledInput}
            />
          </NestedForm>
        )}
        {study.practiceType === 'VETERINARY' && (
          <NestedForm name="vetPatient">
            <SplitStudyPatientVetFields
              study={study}
              disabled={disabledInput}
            />
          </NestedForm>
        )}
        {isPushing && (
          <p className="text-sm text-body my-0 mx-5 text-center">
            <FormattedMessage {...examMessages.saveNote} />
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Layout pad={5}>
            {!study.finalizedAt && (
              <Button
                data-bni-id="ResetPatientInfoButton"
                size="lg"
                variant="text-secondary"
                onClick={resetPatient}
              >
                <FormattedMessage {...examMessages.clear} />
              </Button>
            )}

            <Button
              data-bni-id="SavePatientInfoButton"
              size="lg"
              onClick={onHide}
            >
              <FormattedMessage {...examMessages.save} />
            </Button>
          </Layout>

          <Button size="lg" variant="secondary" onClick={onHide}>
            <FormattedMessage {...examMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(SplitStudyPatientEdit, {
  sourceStudy: graphql`
    fragment SplitStudyPatientEdit_sourceStudy on Study {
      id
      pacsPushStatus
      studyDescription
      practiceType
      finalizedAt
      accessionNumber
      dicomDocument
      hasAssociatedWorklistItem
      hasGeneratedAccessionNumber
      organization {
        id
        primaryWorklist {
          ...AssociateExamWorklistItemModalContent_primaryWorklist
        }
      }
      archive {
        worklistRequiredToFinalize
      }
      patient {
        medicalRecordNumber
        nameFirst
        nameLast
        nameMiddle
        namePrefix
        nameSuffix
      }
      sourceWorklistItem {
        handle
      }
      ...SplitStudyPatientHumanFields_study
      ...SplitStudyPatientVetFields_study
      ...humanStudyInfo_study
      ...vetStudyInfo_study
    }
  `,
});
