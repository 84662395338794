/**
 * @generated SignedSource<<9eb41043283b0d8eebc0de6dffa3c81d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type account_EulaPage_Query$variables = {};
export type account_EulaPage_Query$data = {
  readonly latestEula: {
    readonly " $fragmentSpreads": FragmentRefs<"EulaPage_latestEula">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"EulaPage_viewer">;
  } | null;
};
export type account_EulaPage_Query = {
  response: account_EulaPage_Query$data;
  variables: account_EulaPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "account_EulaPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EulaPage_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Eula",
        "kind": "LinkedField",
        "name": "latestEula",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EulaPage_latestEula"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "account_EulaPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasAcceptedLatestEula",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Eula",
        "kind": "LinkedField",
        "name": "latestEula",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "textHtml",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d0bac7bafa984f79ecbfa53e69ebfad",
    "id": null,
    "metadata": {},
    "name": "account_EulaPage_Query",
    "operationKind": "query",
    "text": "query account_EulaPage_Query {\n  viewer {\n    ...EulaPage_viewer\n    id\n  }\n  latestEula {\n    ...EulaPage_latestEula\n    id\n  }\n}\n\nfragment EulaPage_latestEula on Eula {\n  version\n  textHtml\n}\n\nfragment EulaPage_viewer on Viewer {\n  hasAcceptedLatestEula\n}\n"
  }
};
})();

(node as any).hash = "a7ae07cfbb840676327700b103a18638";

export default node;
