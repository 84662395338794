import Layout from '@4c/layout';
import SuccessIcon from '@bfly/icons/CheckMarkSmall';
import FailureIcon from '@bfly/icons/FailureSmall';
import { stylesheet } from 'astroturf';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SyncingSpinner from './SyncingSpinner';

const styles = stylesheet`
  .icon {
    width: 1.5rem;
    margin-left: -0.2rem;
  }
`;

interface Server {
  name: string | null;
  paused: boolean | null;
}

interface Job {
  completedAt: string | null;
  status: any | null;
  isOutdated: boolean | null;
}

interface Props {
  job: Job;
  server: Server;
}

function getSyncStatusIcon(status) {
  switch (status) {
    case 'FAILED':
      return <FailureIcon className={styles.icon} />;
    case 'COMPLETE':
      return <SuccessIcon className={styles.icon} />;
    case 'PENDING':
      return <SyncingSpinner className={styles.icon} />;
    case 'RETRYING':
      return <SyncingSpinner className={styles.icon} />;
    case 'CANCELED':
      return null;
    default:
      throw new Error(`invalid status: ${status}`);
  }
}

export default function JobSyncStatus({ job, server, ...props }: Props) {
  return (
    <Layout
      {...props}
      align="baseline"
      pad
      className={job.status === 'FAILED' ? 'text-danger' : ''}
    >
      {getSyncStatusIcon(job.status)}
      <Layout direction="column">
        <FormattedMessage
          id="jobSyncStatus.syncMessage"
          defaultMessage={`{status, select,
            FAILED {Failed to send to {name}}
            COMPLETE {Sent to {name} - {completedAt, date, short} at {completedAt, time, short}}
            CANCELED {Send to {name} canceled}
            other {Sending to {name}{paused, select, true {\u2000(paused)} other {}}…}
          }.`}
          values={{
            status: job.status,
            name: server.name,
            paused: server.paused,
            completedAt: new Date(job.completedAt!),
          }}
          tagName="span"
        />
        {job.isOutdated && (
          <span className="text-xs">
            <FormattedMessage
              id="jobSyncStatus.outdated"
              defaultMessage="Study updated since last send"
              tagName="span"
            />
          </span>
        )}
      </Layout>
    </Layout>
  );
}
