import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from 'react-relay';

import ServerLogList from 'components/ServerLogList';

import ThirdPartyUltrasoundLogListItem from './ThirdPartyUltrasoundLogListItem';
import { ThirdPartyUltrasoundLogList_archive$data as Archive } from './__generated__/ThirdPartyUltrasoundLogList_archive.graphql';

interface Props {
  archive: Archive;
  relay: RelayPaginationProp;
}

function ThirdPartyUltrasoundLogList({ archive, relay }: Props) {
  const logs = archive.thirdPartyUltrasoundListener
    ? getNodes(archive?.thirdPartyUltrasoundListener.logConnection)
    : [];

  return (
    <ServerLogList relayPagination={relay} hasData={logs.length > 0}>
      {logs.map((log) => (
        <ThirdPartyUltrasoundLogListItem log={log} key={log.id!} />
      ))}
    </ServerLogList>
  );
}

export default createPaginationContainer(
  ThirdPartyUltrasoundLogList,
  {
    archive: graphql`
      fragment ThirdPartyUltrasoundLogList_archive on Archive
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 15 } # PAGE_SIZE.
        cursor: { type: "String" }
      ) {
        id
        thirdPartyUltrasoundListener {
          logConnection(first: $count, after: $cursor)
            @connection(key: "tpus_logConnection") {
            edges {
              node {
                ... on Node {
                  id
                }
                ...ThirdPartyUltrasoundLogListItem_log
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: ({ archive }) =>
      archive.thirdPartyUltrasoundListener?.logConnection,
    getVariables: ({ archive }, { count, cursor }) => ({
      archiveId: archive.id,
      count,
      cursor,
    }),
    query: graphql`
      query ThirdPartyUltrasoundLogListPaginationQuery(
        $archiveId: ID!
        $count: Int!
        $cursor: String
      ) {
        archive: node(id: $archiveId) {
          id
          ...ThirdPartyUltrasoundLogList_archive
            @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
