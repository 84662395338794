/**
 * @generated SignedSource<<5339188dda173ccc74f5d972212d5658>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type formatDomainUserName_domainUser$data = {
  readonly integrationDisplayNameFirst: string | null;
  readonly integrationDisplayNameLast: string | null;
  readonly integrationDisplayNameMiddle: string | null;
  readonly profile: {
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "formatDomainUserName_domainUser";
};
export type formatDomainUserName_domainUser$key = {
  readonly " $data"?: formatDomainUserName_domainUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"formatDomainUserName_domainUser">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "formatDomainUserName_domainUser"
};

(node as any).hash = "d90257d2ed88124b7baa26b96e05e756";

export default node;
