import Layout from '@4c/layout';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import SplitStudyTagsSelector from './SplitStudyTagsSelector';
import { SplitStudyTagsField_sourceStudy$data as Study } from './__generated__/SplitStudyTagsField_sourceStudy.graphql';

type Props = {
  sourceStudy: Study;
};

function SplitStudyTagsField({ sourceStudy }: Props) {
  return (
    <Layout direction="row">
      <Text>
        <FormattedMessage
          defaultMessage="Tags"
          id="SplitStudyTagsField.tags"
        />
      </Text>
      <SplitStudyTagsSelector
        id="SplitStudyExamTagsSelector"
        className="ml-2"
        studyId={sourceStudy.id}
        organizationHandle={sourceStudy.organization!.handle!}
        overflowThreshold={4}
      />
    </Layout>
  );
}

export default createFragmentContainer(SplitStudyTagsField, {
  sourceStudy: graphql`
    fragment SplitStudyTagsField_sourceStudy on Study {
      id
      organization {
        handle
      }
    }
  `,
});
