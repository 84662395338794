/**
 * @generated SignedSource<<44ace26dc71803bbade7b89567600a76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorksheetsExportControl_archive$data = {
  readonly id: string;
  readonly organization: {
    readonly worksheetTemplateConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly latestVersion: {
            readonly title: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "WorksheetsExportControl_archive";
};
export type WorksheetsExportControl_archive$key = {
  readonly " $data"?: WorksheetsExportControl_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetsExportControl_archive">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorksheetsExportControl_archive",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksheetTemplateConnection",
          "kind": "LinkedField",
          "name": "worksheetTemplateConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorksheetTemplateEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WorksheetTemplate",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WorksheetTemplateVersion",
                      "kind": "LinkedField",
                      "name": "latestVersion",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};
})();

(node as any).hash = "75ad1bc4ef1880c88ddf4162eb09a5c6";

export default node;
