/**
 * @generated SignedSource<<88e0f1693feecba04128331976be9c4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AuthorsUpdatedStudyEventAction = "ADDED" | "PRIMARY_SET" | "REMOVED" | "SET_SCRIBE" | "UNSET_SCRIBE" | "%future added value";
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
export type StudyVersionReason = "ADDED_QA_ENTRY" | "ADDED_WORKSHEET" | "CREATED" | "DELETED" | "FINALIZED_DRAFT" | "INFO_UPDATED" | "MARKED_DRAFT" | "MOVED" | "REMOVED_QA_ENTRY" | "REMOVED_WORKSHEET" | "UNDELETED" | "UPDATED_QA_ENTRY" | "UPDATED_WORKSHEET" | "%future added value";
export type TagsUpdatedStudyEventAction = "ADDED" | "REMOVED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyHistoryModalContent_study$data = {
  readonly eventConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly author?: {
          readonly name: string | null;
        } | null;
        readonly authorAction?: AuthorsUpdatedStudyEventAction | null;
        readonly createdAt: string | null;
        readonly createdBy: {
          readonly name: string | null;
        } | null;
        readonly destinationArchive?: {
          readonly deletedAt: string | null;
          readonly label: string | null;
        } | null;
        readonly destinationOrganization?: {
          readonly name: string | null;
        } | null;
        readonly finalizationRequests?: ReadonlyArray<{
          readonly recipient: {
            readonly name: string | null;
          } | null;
        } | null> | null;
        readonly id?: string;
        readonly image?: {
          readonly capturedAt: string | null;
        } | null;
        readonly sourceArchive?: {
          readonly deletedAt: string | null;
          readonly label: string | null;
        } | null;
        readonly sourceOrganization?: {
          readonly name: string | null;
        } | null;
        readonly tag?: {
          readonly name: string | null;
        } | null;
        readonly tagAction?: TagsUpdatedStudyEventAction | null;
        readonly type: string;
        readonly version?: {
          readonly accessionNumber: string | null;
          readonly archive: {
            readonly deletedAt: string | null;
            readonly label: string | null;
          } | null;
          readonly id: string;
          readonly notes: string | null;
          readonly patient: {
            readonly " $fragmentSpreads": FragmentRefs<"PatientInfo_patient" | "PatientName_patient">;
          } | null;
          readonly practiceType: StudyPracticeType | null;
          readonly studyDescription: string | null;
          readonly versionCommittedAt: string | null;
          readonly versionCommittedBy: {
            readonly name: string | null;
          } | null;
          readonly versionReason: StudyVersionReason | null;
          readonly vetPatient: {
            readonly " $fragmentSpreads": FragmentRefs<"VetPatientInfo_vetPatient" | "VetPatientName_vetPatient">;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "StudyHistoryModalContent_study";
};
export type StudyHistoryModalContent_study$key = {
  readonly " $data"?: StudyHistoryModalContent_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyHistoryModalContent_study">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyHistoryModalContent_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyEventInterfaceConnection",
      "kind": "LinkedField",
      "name": "eventConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyEventInterfaceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "createdBy",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": "type",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v2/*: any*/),
                  "type": "Node",
                  "abstractKey": "__isNode"
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v2/*: any*/),
                  "type": "QaSubmittedStudyEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "authorAction",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "action",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserProfile",
                      "kind": "LinkedField",
                      "name": "author",
                      "plural": false,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "AuthorsUpdatedStudyEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "tagAction",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "action",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudyTag",
                      "kind": "LinkedField",
                      "name": "tag",
                      "plural": false,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "TagsUpdatedStudyEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v2/*: any*/),
                  "type": "ImagesDeletedStudyEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudyImage",
                      "kind": "LinkedField",
                      "name": "image",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "capturedAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "ImageAddedStudyEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudyVersion",
                      "kind": "LinkedField",
                      "name": "version",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Archive",
                          "kind": "LinkedField",
                          "name": "archive",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/),
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "practiceType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "accessionNumber",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Patient",
                          "kind": "LinkedField",
                          "name": "patient",
                          "plural": false,
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "PatientName_patient"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "PatientInfo_patient"
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "VetPatient",
                          "kind": "LinkedField",
                          "name": "vetPatient",
                          "plural": false,
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "VetPatientName_vetPatient"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "VetPatientInfo_vetPatient"
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "notes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "studyDescription",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "versionCommittedAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "versionReason",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserProfile",
                          "kind": "LinkedField",
                          "name": "versionCommittedBy",
                          "plural": false,
                          "selections": (v0/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "VersionCreatedStudyEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudyFinalizationRequest",
                      "kind": "LinkedField",
                      "name": "finalizationRequests",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserProfile",
                          "kind": "LinkedField",
                          "name": "recipient",
                          "plural": false,
                          "selections": (v0/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "FinalizationRequestedStudyEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudyVersion",
                      "kind": "LinkedField",
                      "name": "version",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Archive",
                      "kind": "LinkedField",
                      "name": "sourceArchive",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Archive",
                      "kind": "LinkedField",
                      "name": "destinationArchive",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Organization",
                      "kind": "LinkedField",
                      "name": "sourceOrganization",
                      "plural": false,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Organization",
                      "kind": "LinkedField",
                      "name": "destinationOrganization",
                      "plural": false,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "MovedStudyEvent",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "fd56249867cb16ac2c6139f73fa23ed6";

export default node;
