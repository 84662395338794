import { graphql } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkTenantPermission,
} from 'utils/RouteAccessControl';

export const domainQaRoute = (
  <Route
    path="qa"
    prerender={chainPrerenders(
      checkFlagsOr404(
        (ldClient) =>
          getVariation(ldClient, 'worksheets-review') &&
          getVariation(ldClient, 'domain-workflow-settings'),
      ),
      checkTenantPermission('qaManagement', 'BASIC'),
    )}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/DomainQaSettingsContent'
        ).then((m) => m.default)
      }
      query={graphql`
        query domainQaRoutes_DomainQaSettingsContent_Query {
          viewer {
            ...DomainQaSettingsContent_viewer
          }
        }
      `}
    />
    <Route
      fullScreen
      path="-/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/CreateQaTemplateForm'
        ).then((m) => m.default)
      }
      query={graphql`
        query domainQaRoutes_DomainCreateQaTemplateForm_Query {
          viewer {
            domain {
              ...CreateQaTemplateForm_workflowConfig
            }
          }
        }
      `}
      renderFetched={({ Component, props }) => (
        <Component {...props} workflowConfig={props.viewer.domain} />
      )}
    />
    <Route fullScreen path=":templateHandle">
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateQaTemplateForm'
          ).then((m) => m.default)
        }
        query={graphql`
          query domainQaRoutes_DomainUpdateQaTemplateForm_Query(
            $templateHandle: String!
          ) {
            template: qaTemplate(handle: $templateHandle) {
              ...UpdateQaTemplateForm_template
            }
          }
        `}
      />
    </Route>
  </Route>
);
