/**
 * @generated SignedSource<<37a426bab88831b9526967052f3d480a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AllOrganizationsStudyList_studies$data = {
  readonly id: string;
  readonly studySearchConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_studies">;
    } | null> | null;
    readonly index: number | null;
    readonly numEdges: number | null;
  } | null;
  readonly " $fragmentType": "AllOrganizationsStudyList_studies";
};
export type AllOrganizationsStudyList_studies$key = {
  readonly " $data"?: AllOrganizationsStudyList_studies$data;
  readonly " $fragmentSpreads": FragmentRefs<"AllOrganizationsStudyList_studies">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "studySearchConnection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./GlobalStudiesListPageWithSearchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "AllOrganizationsStudyList_studies",
  "selections": [
    {
      "alias": "studySearchConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "StudyConnection",
      "kind": "LinkedField",
      "name": "__Global_studySearchConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "index",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numEdges",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "lazyLoadImages",
                  "value": true
                },
                {
                  "kind": "Literal",
                  "name": "listEnabled",
                  "value": false
                }
              ],
              "kind": "FragmentSpread",
              "name": "StudyListContent_studies"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Study",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Domain",
  "abstractKey": null
};
})();

(node as any).hash = "4bdedb1d34c6d37b94cfb82516730a14";

export default node;
