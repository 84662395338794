import Layout from '@4c/layout';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import React from 'react';

export default function LoadingPage() {
  return (
    <Layout direction="column">
      <LoadingIndicator />
    </Layout>
  );
}
