/**
 * @generated SignedSource<<9f4c43c6bc18a7ffee45a13dfba621ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EducationVideoPracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type education_EducationVideoListPage_Query$variables = {
  practiceType?: ReadonlyArray<EducationVideoPracticeType> | null;
};
export type education_EducationVideoListPage_Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"EducationVideoListPage_viewer">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EducationVideoListPage_query">;
};
export type education_EducationVideoListPage_Query = {
  response: education_EducationVideoListPage_Query$data;
  variables: education_EducationVideoListPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "practiceType"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "practiceType",
    "variableName": "practiceType"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 2147483647
},
v7 = [
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "viewerHasCompleted",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "viewerIsAssigned",
    "value": true
  }
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EducationContentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EducationContent",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = [
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "viewerHasSaved",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "education_EducationVideoListPage_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "EducationVideoListPage_query"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EducationVideoListPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "education_EducationVideoListPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EducationCategory",
        "kind": "LinkedField",
        "name": "educationCategories",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EducationContentCategory",
            "kind": "LinkedField",
            "name": "educationContentCategories",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ordering",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "assigned",
            "args": (v7/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContentConnection",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "educationContentConnection(first:2147483647,viewerHasCompleted:false,viewerIsAssigned:true)"
          },
          {
            "alias": "assigned",
            "args": (v7/*: any*/),
            "filters": [
              "viewerIsAssigned",
              "viewerHasCompleted"
            ],
            "handle": "connection",
            "key": "EducationVideoPageHeader_assigned",
            "kind": "LinkedHandle",
            "name": "educationContentConnection"
          },
          {
            "alias": "saved",
            "args": (v9/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContentConnection",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "educationContentConnection(first:2147483647,viewerHasSaved:true)"
          },
          {
            "alias": "saved",
            "args": (v9/*: any*/),
            "filters": [
              "viewerHasSaved"
            ],
            "handle": "connection",
            "key": "EducationVideoPageHeader_saved",
            "kind": "LinkedHandle",
            "name": "educationContentConnection"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EducationContentFilters",
            "kind": "LinkedField",
            "name": "educationContentFilters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasUnexpiredMembership",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerMembership",
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0153594beb204b50b9b7b47dabf2b8d",
    "id": null,
    "metadata": {},
    "name": "education_EducationVideoListPage_Query",
    "operationKind": "query",
    "text": "query education_EducationVideoListPage_Query(\n  $practiceType: [EducationVideoPracticeType!]\n) {\n  ...EducationVideoListPage_query_3eWWTT\n  viewer {\n    ...EducationVideoListPage_viewer\n    id\n  }\n}\n\nfragment CategoriesFormField_query_3eWWTT on Query {\n  educationCategories(practiceType: $practiceType) {\n    handle\n    name\n    id\n  }\n  lms {\n    educationContentCategories {\n      handle\n      name\n      ordering\n      id\n    }\n  }\n}\n\nfragment EducationVideoListPage_query_3eWWTT on Query {\n  ...VideoFilters_query_3eWWTT\n  ...EducationWelcomeModal_query_3eWWTT\n  lms {\n    ...EducationVideoPageHeader_lms\n  }\n}\n\nfragment EducationVideoListPage_viewer on Viewer {\n  email\n  hasUnexpiredMembership\n  ...SubscriberContentMessage_viewer\n}\n\nfragment EducationVideoPageHeader_lms on Lms {\n  assigned: educationContentConnection(first: 2147483647, viewerIsAssigned: true, viewerHasCompleted: false) {\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  saved: educationContentConnection(first: 2147483647, viewerHasSaved: true) {\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  educationContentCategories {\n    handle\n    name\n    ordering\n    id\n  }\n  educationContentFilters {\n    duration\n    videoType\n    contentType\n  }\n}\n\nfragment EducationWelcomeModal_query_3eWWTT on Query {\n  ...CategoriesFormField_query_3eWWTT\n}\n\nfragment SubscriberContentMessage_viewer on Viewer {\n  hasUnexpiredMembership\n  memberships {\n    organization {\n      name\n      slug\n      id\n    }\n    id\n  }\n}\n\nfragment VideoFilters_query_3eWWTT on Query {\n  educationCategories(practiceType: $practiceType) {\n    __typename\n    id\n  }\n  ...CategoriesFormField_query_3eWWTT\n}\n"
  }
};
})();

(node as any).hash = "8a51c93e358daaacc57a32afc3018516";

export default node;
