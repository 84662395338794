import { join, route } from '@4c/spa-routing';

const rootRoute = route('');

const settings = join(rootRoute, '-/settings');
const userSetup = join(rootRoute, '-/user-setup');
const eula = join(rootRoute, '-/eula');
const inactiveAccount = join(rootRoute, '-/inactive');

const forgotPassword = join(rootRoute, '-/forgot-password');
const acceptInvite = join(rootRoute, '-/accept-invite');
const accountCreated = join(rootRoute, '-/account-created');
const inviteAccepted = join(rootRoute, '-/:organizationSlug/invite-accepted');
const enterpriseLogin = join(rootRoute, '-/enterprise-login');

const accountRouteUrls = {
  settings,
  userSetup,
  eula,
  inactiveAccount,
  forgotPassword,
  acceptInvite,
  accountCreated,
  inviteAccepted,
  enterpriseLogin,
};

export default accountRouteUrls;

export function useAccountRoutes() {
  return accountRouteUrls;
}
