/**
 * @generated SignedSource<<d7f6f2e4e2b2dda9de5d93ab39b18249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type integrations_DomainCreateFhirIntegrationPage_Query$variables = {};
export type integrations_DomainCreateFhirIntegrationPage_Query$data = {
  readonly viewer: {
    readonly domain: {
      readonly " $fragmentSpreads": FragmentRefs<"CreateFhirIntegrationPage_connectivityConfig">;
    } | null;
  } | null;
};
export type integrations_DomainCreateFhirIntegrationPage_Query = {
  response: integrations_DomainCreateFhirIntegrationPage_Query$data;
  variables: integrations_DomainCreateFhirIntegrationPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "integrations_DomainCreateFhirIntegrationPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreateFhirIntegrationPage_connectivityConfig"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "integrations_DomainCreateFhirIntegrationPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "hospitalConnections",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "alias": "type",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isHospitalConnectionInterface"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isSecured",
                            "storageKey": null
                          }
                        ],
                        "type": "DicomTlsConnection",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "organizationId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Organization",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "domainId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Domain",
                        "abstractKey": null
                      }
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "type": "ConnectivityConfigInterface",
                "abstractKey": "__isConnectivityConfigInterface"
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "059a33398052e6f6dfe4be6b7a662735",
    "id": null,
    "metadata": {},
    "name": "integrations_DomainCreateFhirIntegrationPage_Query",
    "operationKind": "query",
    "text": "query integrations_DomainCreateFhirIntegrationPage_Query {\n  viewer {\n    domain {\n      ...CreateFhirIntegrationPage_connectivityConfig\n      id\n    }\n    id\n  }\n}\n\nfragment CreateButterflyLinkControl_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  hospitalConnections {\n    __typename\n    type: __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  ...useTenantIdInput_tenant\n}\n\nfragment CreateFhirIntegrationPage_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ...useTenantIdInput_tenant\n  ...FhirIntegrationForm_connectivityConfig\n}\n\nfragment FhirIntegrationForm_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  hospitalConnections {\n    __typename\n    type: __typename\n    ...HospitalServerForm_hospitalConnections\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  ...CreateButterflyLinkControl_connectivityConfig\n}\n\nfragment HospitalServerForm_hospitalConnections on HospitalConnectionInterface {\n  __isHospitalConnectionInterface: __typename\n  name\n  type: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ... on DicomTlsConnection {\n    isSecured\n  }\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8000e56e90ed852bc2d75b19cd8057c0";

export default node;
