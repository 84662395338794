import DatePicker from '@bfly/ui2/DatePicker';
import Form from '@bfly/ui2/Form';
import { LabelValue } from '@bfly/ui2/FormattedLabel';
import { stylesheet } from 'astroturf';

import patientMessages from 'messages/patient';

const styles = stylesheet`
.date-picker-field {
  :global(.rw-picker-btn) {
    border-left: 0px;
  }
  :global(.rw-calendar-popup) {
    position: fixed !important;
    top: auto !important;
    left: auto !important;
    margin-left: -3px;
  }
}`;

interface Props {
  open: boolean;
  setOpen: (newState: boolean) => any;
  disabled?: boolean;
  label: string | LabelValue;
}

const ExamInfoDatePickerField = ({
  open,
  setOpen,
  disabled,
  label,
}: Props) => {
  return (
    <Form.FieldGroup
      fluid
      name="birthDate"
      label={label}
      placeholder={patientMessages.dateOfBirthLabel}
      disabled={disabled}
    >
      {({ value, ...props }, meta) => {
        return (
          <DatePicker
            {...props}
            value={value || null}
            invalid={meta.invalid}
            menuVariant="dark"
            className={styles.datePickerField}
            open={open}
            inputProps={{
              onClick: (event) => {
                setOpen(!open);
                setTimeout(() => (event.target as HTMLElement).focus(), 100);
              },
              readOnly: disabled,
            }}
            onToggle={setOpen}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === ' ') {
                setOpen(true);
              }
            }}
          />
        );
      }}
    </Form.FieldGroup>
  );
};

export default ExamInfoDatePickerField;
