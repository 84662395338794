/**
 * @generated SignedSource<<66db4109a47250477bcccaf9f869a298>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type CreateDomainUserInput = {
  canFinalize?: boolean | null;
  canQa?: boolean | null;
  clientMutationId?: string | null;
  createButterflyLogin?: boolean | null;
  dicomFields?: ReadonlyArray<CreateDomainUserInputDomainUserDicomField> | null;
  domainId: string;
  email: string;
  integrationConfigs?: ReadonlyArray<CreateDomainUserInputDomainUserIntegrationConfig> | null;
  integrationDisplayNameFirst?: string | null;
  integrationDisplayNameLast?: string | null;
  integrationDisplayNameMiddle?: string | null;
  isNurse?: boolean | null;
  roleId?: string | null;
  type: DomainUserType;
  userId?: string | null;
};
export type CreateDomainUserInputDomainUserIntegrationConfig = {
  integrationId: string;
  interfaceCode: string;
};
export type CreateDomainUserInputDomainUserDicomField = {
  templateId: string;
  value: string;
};
export type AddDomainMemberModalMutation$variables = {
  input: CreateDomainUserInput;
};
export type AddDomainMemberModalMutation$data = {
  readonly createDomainUserOrError: {
    readonly __typename?: string;
    readonly domainUserEdge?: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"DomainMembersGrid_domainUser">;
      } | null;
    } | null;
    readonly fields?: ReadonlyArray<{
      readonly message: string;
      readonly path: ReadonlyArray<string>;
    }>;
    readonly message?: string | null;
  } | null;
};
export type AddDomainMemberModalMutation = {
  response: AddDomainMemberModalMutation$data;
  variables: AddDomainMemberModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvalidInputErrorField",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvalidInputError",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddDomainMemberModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createDomainUserOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserEdge",
                "kind": "LinkedField",
                "name": "domainUserEdge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DomainMembersGrid_domainUser"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CreateDomainUserPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddDomainMemberModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createDomainUserOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserEdge",
                "kind": "LinkedField",
                "name": "domainUserEdge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "integrationDisplayNameFirst",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "integrationDisplayNameMiddle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "integrationDisplayNameLast",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canQa",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canFinalize",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainUserDicomField",
                        "kind": "LinkedField",
                        "name": "dicomFields",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DomainDicomFieldTemplate",
                            "kind": "LinkedField",
                            "name": "dicomFieldTemplate",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "label",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainUserIntegrationConfig",
                        "kind": "LinkedField",
                        "name": "integrationConfigs",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Ehr",
                            "kind": "LinkedField",
                            "name": "ehr",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "handle",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "interfaceCode",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MembershipRole",
                        "kind": "LinkedField",
                        "name": "role",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organizations",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dateLastActive",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CreateDomainUserPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5118d1772fb5c130e394a56496aff303",
    "id": null,
    "metadata": {},
    "name": "AddDomainMemberModalMutation",
    "operationKind": "mutation",
    "text": "mutation AddDomainMemberModalMutation(\n  $input: CreateDomainUserInput!\n) {\n  createDomainUserOrError(input: $input) {\n    __typename\n    ... on CreateDomainUserPayload {\n      domainUserEdge {\n        node {\n          ...DomainMembersGrid_domainUser\n          id\n        }\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      ... on InvalidInputError {\n        fields {\n          message\n          path\n        }\n      }\n      ... on MaxSeatsExceededError {\n        message\n      }\n      __typename\n      message\n    }\n  }\n}\n\nfragment DomainMembersGrid_domainUser on DomainUser {\n  id\n  email\n  integrationDisplayNameFirst\n  integrationDisplayNameMiddle\n  integrationDisplayNameLast\n  canQa\n  canFinalize\n  type\n  dicomFields {\n    dicomFieldTemplate {\n      id\n      label\n    }\n    value\n  }\n  integrationConfigs {\n    ehr {\n      id\n      handle\n    }\n    interfaceCode\n  }\n  role {\n    name\n    id\n  }\n  organizations {\n    id\n    name\n  }\n  profile {\n    id\n  }\n  updatedAt\n  dateLastActive\n}\n"
  }
};
})();

(node as any).hash = "362580af9a758e6fe0803f51c64faab0";

export default node;
