import { graphql } from 'react-relay';
import { Disposable, Environment, commitMutation } from 'relay-runtime';

enum EventCategory {
  AUTHORIZATION = 'authorization',
}

enum EventAction {
  LOGIN = 'login',
  LOGOUT = 'logout',
}

enum AgentType {
  USER = 'User',
}

export enum AuthType {
  SSO = 'sso',
  AUTH0 = 'auth0',
}

interface SessionData {
  userId: string;
  domainId: string;
  authType?: AuthType;
}

interface EventData {
  eventCategory: string;
  eventAction: string;
  agentType: string;
  eventMetadata: object;
  userId: string;
  organizationId?: string;
  domainId: string;
}

const sendLog = (environment: Environment, data: EventData): Disposable => {
  return commitMutation<any>(environment, {
    mutation: graphql`
      mutation auditLogs_Mutation($input: CreateAuditLogsEventInput!) {
        createAuditLogsEventOrError(input: $input) {
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
    variables: {
      input: {
        ...data,
      },
    },
  });
};

export const registerLoginEvent = (
  environment: Environment,
  sessionData: SessionData,
) => {
  sendLog(environment, {
    eventCategory: EventCategory.AUTHORIZATION,
    eventAction: EventAction.LOGIN,
    agentType: AgentType.USER,
    userId: sessionData.userId,
    domainId: sessionData.domainId,
    eventMetadata: {
      loginType: 'web browser log in',
      authType: sessionData.authType,
    },
  });
};

export const registerLogoutEvent = (
  environment: Environment,
  sessionData: SessionData,
) => {
  sendLog(environment, {
    eventCategory: EventCategory.AUTHORIZATION,
    eventAction: EventAction.LOGOUT,
    agentType: AgentType.USER,
    userId: sessionData.userId,
    domainId: sessionData.domainId,
    eventMetadata: {
      logoutType: 'web browser',
    },
  });
};
