import { useEffect, useState } from 'react';
import engine from 'store/src/store-engine';

const localStore = engine.createStore([
  require('store/storages/localStorage'),
]);

export default localStore;

type Listener<T> = (data: T | null) => void;

export function LocalStore<T extends Record<string, any> | null>(key: string) {
  let subscriptions: Listener<T>[] = [];

  const subscribe = (callback: Listener<T>) => {
    subscriptions.push(callback);
  };

  const unsubscribe = (callback: Listener<T>) => {
    subscriptions = subscriptions.filter((s) => s !== callback);
  };

  return {
    get(defaultValue?: T): T {
      return { ...defaultValue, ...localStore.get(key) };
    },
    set(data: T) {
      localStore.set(key, data);
      subscriptions.forEach((callback) => callback(data));
      return data;
    },
    clear() {
      localStore.remove(key);
      subscriptions.forEach((callback) => callback(null));
    },

    entries(): [key: string, value: any][] {
      const value: T = localStore.get(key);
      if (!value) return [];
      return Object.getOwnPropertyNames(value).map((prop) => [
        prop,
        value[prop],
      ]);
    },

    subscribe,

    unsubscribe,

    useState() {
      const [state, setState] = useState<T | null>(localStore.get(key));

      useEffect(() => {
        subscribe(setState);
        return () => unsubscribe(setState);
      }, []);

      return state;
    },
  };
}
