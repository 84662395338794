import { defineMessages } from 'react-intl';

export default defineMessages({
  butterflyLink: {
    id: 'connectivitySettings.connections.butterflyLink',
    defaultMessage: 'Butterfly Link',
  },
  dicom: {
    id: 'connectivitySettings.connections.dicom',
    defaultMessage: 'DICOM-TLS',
  },
  dicomUnsecured: {
    id: 'connectivitySettings.connections.dicomUnsecured',
    defaultMessage: 'DICOM-TLS (Unsecured)',
  },
  timezone: {
    id: 'connections.timezone',
    defaultMessage: 'Timezone',
  },
  port: {
    id: 'connections.port',
    defaultMessage: 'Port',
  },
  portPlaceholder: {
    id: 'connections.placeholder',
    defaultMessage: '0000',
    description: 'A Port mask',
  },
  scuAet: {
    id: 'connections.scuAet',
    defaultMessage: 'SCU AET',
  },
  scuAetPlaceholder: {
    id: 'connections.scuAet.placeholder',
    description: 'AE title of the third-party device (i.e. the DICOM SCU)',
    defaultMessage: 'AE title of the third-party device (i.e. the DICOM SCU)',
  },
});
