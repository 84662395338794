/**
 * @generated SignedSource<<d6aa444ebd6c7e2ba40bbe83ab4fdcf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestPasswordResetInput = {
  clientMutationId?: string | null;
  email: string;
  redirectUri?: string | null;
};
export type ForgotPasswordPage_RequestPasswordResetMutation$variables = {
  input: RequestPasswordResetInput;
};
export type ForgotPasswordPage_RequestPasswordResetMutation$data = {
  readonly requestPasswordResetOrError: {
    readonly __typename?: string;
    readonly fields?: ReadonlyArray<{
      readonly message: string;
      readonly path: ReadonlyArray<string>;
    }>;
    readonly message?: string | null;
  } | null;
};
export type ForgotPasswordPage_RequestPasswordResetMutation = {
  response: ForgotPasswordPage_RequestPasswordResetMutation$data;
  variables: ForgotPasswordPage_RequestPasswordResetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvalidInputErrorField",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvalidInputError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgotPasswordPage_RequestPasswordResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "requestPasswordResetOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "RequestPasswordResetPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgotPasswordPage_RequestPasswordResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "requestPasswordResetOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "389fa9161e4c90155b401e1fc812754e",
    "id": null,
    "metadata": {},
    "name": "ForgotPasswordPage_RequestPasswordResetMutation",
    "operationKind": "mutation",
    "text": "mutation ForgotPasswordPage_RequestPasswordResetMutation(\n  $input: RequestPasswordResetInput!\n) {\n  requestPasswordResetOrError(input: $input) {\n    __typename\n    ... on RequestPasswordResetPayload {\n      __typename\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      ... on InvalidInputError {\n        fields {\n          message\n          path\n        }\n      }\n      ... on MaxSeatsExceededError {\n        message\n      }\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ce7f3fca9b2b0e2f9c7067c7acb4aec";

export default node;
