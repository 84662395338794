/**
 * @generated SignedSource<<727a7ae6275afa73858d3309ab208298>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationCenterMarkAllReadControl_notificationCenter$data = {
  readonly id: string;
  readonly notifications: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id?: string;
        readonly readAt?: string | null;
        readonly typename: string;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "NotificationCenterMarkAllReadControl_notificationCenter";
};
export type NotificationCenterMarkAllReadControl_notificationCenter$key = {
  readonly " $data"?: NotificationCenterMarkAllReadControl_notificationCenter$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationCenterMarkAllReadControl_notificationCenter">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "readAt",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 24,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "notifications"
        ]
      }
    ]
  },
  "name": "NotificationCenterMarkAllReadControl_notificationCenter",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "notifications",
      "args": null,
      "concreteType": "NotificationInterfaceConnection",
      "kind": "LinkedField",
      "name": "__NotificationCenterMarkAllReadControl_notifications_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationInterfaceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "type": "Node",
                  "abstractKey": "__isNode"
                },
                {
                  "alias": "typename",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "StudyImageCommentNotification",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "WorksheetReviewedNotification",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "StudyFinalizationRequestedNotification",
                  "abstractKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NotificationCenter",
  "abstractKey": null
};
})();

(node as any).hash = "5390416ea5ea2a791f9787a5553614c1";

export default node;
