import SplitStudiesIcon from '@bfly/icons/SplitStudies';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useModalState from 'hooks/useModalState';
import Analytics from 'utils/Analytics';
import { useCanSplitStudies } from 'utils/StudyPermissions';

import SplitStudyRefreshModal from './SplitStudyRefreshModal';
import { SplitStudyControl_study$data as Study } from './__generated__/SplitStudyControl_study.graphql';

interface Props {
  study: Study;
  as: React.ElementType;
}

function SplitStudyControl({ as: Component, study }: Props) {
  const canSplitStudies = useCanSplitStudies(study);
  const [showModal, modalProps] = useModalState();
  const handleSplitStudyShow = useCallback(() => {
    showModal();
    Analytics.track('splitStudyOpened', {
      studyId: study.id,
      organizationId: study.organization?.id,
    });
  }, [showModal, study.id, study.organization?.id]);

  if (!canSplitStudies) {
    return null;
  }

  return (
    <>
      <Component
        icon={<SplitStudiesIcon width={20} />}
        data-bni-id="SplitStudyControl"
        onClick={handleSplitStudyShow}
      >
        <FormattedMessage
          id="splitStudiesControl.splitExamButton"
          defaultMessage="Split Exam"
        />
      </Component>
      <SplitStudyRefreshModal study={study} {...modalProps} />
    </>
  );
}

export default createFragmentContainer(SplitStudyControl, {
  study: graphql`
    fragment SplitStudyControl_study on Study {
      id
      organization {
        id
      }
      ...SplitStudyRefreshModal_study
      ...SplitStudyModal_study
      ...StudyPermissions_canSplitStudies
    }
  `,
});
