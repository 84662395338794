/**
 * @generated SignedSource<<0447b782678630bb3f50872144a76a77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyFilterDropdown_archive$data = {
  readonly hasRestrictedFinalization: boolean | null;
  readonly viewerCanQa: boolean | null;
  readonly viewerMustRequestFinalization: boolean | null;
  readonly " $fragmentType": "StudyFilterDropdown_archive";
};
export type StudyFilterDropdown_archive$key = {
  readonly " $data"?: StudyFilterDropdown_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyFilterDropdown_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyFilterDropdown_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerMustRequestFinalization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasRestrictedFinalization",
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "22646c49c5c075d09b708318fe7b2f10";

export default node;
