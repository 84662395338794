/**
 * @generated SignedSource<<e1182d10b057e41f83f3eb0dd91343c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPageRenderer_organization$data = {
  readonly slug: string | null;
  readonly " $fragmentType": "AppPageRenderer_organization";
};
export type AppPageRenderer_organization$key = {
  readonly " $data"?: AppPageRenderer_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppPageRenderer_organization">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AppPageRenderer_organization"
};

(node as any).hash = "134e70390a0d26621032476e2e69e617";

export default node;
