/**
 * @generated SignedSource<<57bfbf9de6263ddd5c56f49f0b736073>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainAdminRoutes_EnterpriseSettingsPage_Query$variables = {};
export type domainAdminRoutes_EnterpriseSettingsPage_Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"EnterpriseSettingsPage_viewer">;
  } | null;
};
export type domainAdminRoutes_EnterpriseSettingsPage_Query = {
  response: domainAdminRoutes_EnterpriseSettingsPage_Query$data;
  variables: domainAdminRoutes_EnterpriseSettingsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "domainAdminRoutes_EnterpriseSettingsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EnterpriseSettingsPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "domainAdminRoutes_EnterpriseSettingsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  }
                ],
                "type": "TenantInterface",
                "abstractKey": "__isTenantInterface"
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6b504a53bae2ec542096750f79b2dbe",
    "id": null,
    "metadata": {},
    "name": "domainAdminRoutes_EnterpriseSettingsPage_Query",
    "operationKind": "query",
    "text": "query domainAdminRoutes_EnterpriseSettingsPage_Query {\n  viewer {\n    ...EnterpriseSettingsPage_viewer\n    id\n  }\n}\n\nfragment AppLeadingNav_viewer on Viewer {\n  domain {\n    ...AppSearchGlobal_tenant\n    id\n  }\n}\n\nfragment AppSearchGlobal_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ...SearchBarGlobal_tenant\n}\n\nfragment BaseAppPage_viewer on Viewer {\n  ...AppLeadingNav_viewer\n}\n\nfragment DomainSettingsSidePanelPage_viewer on Viewer {\n  ...BaseAppPage_viewer\n  domain {\n    __typename\n    id\n  }\n}\n\nfragment EnterpriseSettingsPage_viewer on Viewer {\n  ...DomainSettingsSidePanelPage_viewer\n}\n\nfragment SearchBarGlobal_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ...useSearchState_tenant\n  ...getConcreteTenant_tenant\n  ... on Organization {\n    slug\n  }\n}\n\nfragment getConcreteTenant_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    type: __typename\n  }\n  ... on Organization {\n    type: __typename\n  }\n}\n\nfragment useSearchState_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Organization {\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "0377bbff303831c054ac39769b9b377b";

export default node;
