import { UserPermission } from '@bfly/olympus-protobuf';
import Form from '@bfly/ui2/Form';
import FeatureFlags from 'config/FeatureFlags';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  getVariation,
  useLaunchDarklyClient,
} from 'components/LaunchDarklyContext';

import permissionBuckets from '../messages/DomainRolesMessages';
import PermissionBucketCard from './PermissionBucketCard';
import PERMISSION_BUCKETS from './permissionBuckets';

interface Props {
  readOnly?: boolean;
}

function DomainRolePermissionsFieldSet({ readOnly }: Props) {
  const { formatMessage } = useIntl();

  const ldclient = useLaunchDarklyClient();

  const requiredFeatureFlagsFilter = (item: {
    requiredFeatureFlags?: Partial<FeatureFlags>;
  }) => {
    if (!item.requiredFeatureFlags) return true;
    return Object.keys(item.requiredFeatureFlags).every(
      (flag: keyof FeatureFlags) =>
        getVariation(ldclient, flag) ===
        (item.requiredFeatureFlags![flag] as any),
    );
  };

  return (
    <Form.FieldSet
      legend={formatMessage(permissionBuckets.permissionsFieldSetTitle)}
    >
      {PERMISSION_BUCKETS.filter(requiredFeatureFlagsFilter).map((bucket) => (
        <PermissionBucketCard
          key={bucket.permissionId}
          header={<FormattedMessage {...bucket.label} />}
          permissionId={UserPermission.Bucket[bucket.permissionId]}
          allowReadWrite={bucket.allowReadWrite}
          customTooltip={
            bucket.parentPermission ? (
              <PermissionBucketCard.ParentPermissionTooltip
                bucket={bucket}
                buckets={PERMISSION_BUCKETS}
              />
            ) : undefined
          }
          readOnly={readOnly}
        >
          {bucket.items
            .filter(requiredFeatureFlagsFilter)
            .map((item, index) => (
              <PermissionBucketCard.ListItem
                key={`${bucket.permissionId}-${index + 1}`}
              >
                <FormattedMessage {...item.label} />
              </PermissionBucketCard.ListItem>
            ))}
        </PermissionBucketCard>
      ))}
    </Form.FieldSet>
  );
}

export default DomainRolePermissionsFieldSet;
