/**
 * @generated SignedSource<<e3f4cb8dd117d354d1ab2f350efe06eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type auth_ZendeskSsoPage_Query$variables = {};
export type auth_ZendeskSsoPage_Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"ZendeskSsoPage_viewer">;
  } | null;
};
export type auth_ZendeskSsoPage_Query = {
  response: auth_ZendeskSsoPage_Query$data;
  variables: auth_ZendeskSsoPage_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "auth_ZendeskSsoPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ZendeskSsoPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "auth_ZendeskSsoPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ZendeskToken",
            "kind": "LinkedField",
            "name": "zendeskToken",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jwt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cae229e79a79c0d7a0bf3ecabe0a208a",
    "id": null,
    "metadata": {},
    "name": "auth_ZendeskSsoPage_Query",
    "operationKind": "query",
    "text": "query auth_ZendeskSsoPage_Query {\n  viewer {\n    ...ZendeskSsoPage_viewer\n    id\n  }\n}\n\nfragment ZendeskSsoPage_viewer on Viewer {\n  zendeskToken {\n    jwt\n  }\n}\n"
  }
};

(node as any).hash = "18d28f161ed50f45b9f3640515a86ff6";

export default node;
