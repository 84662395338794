import { defineMessages } from 'react-intl';

export default defineMessages({
  readWrite: {
    id: 'permission.read&Write.detail',
    defaultMessage: 'Read & Write',
  },
  read: {
    id: 'permission.read.detail',
    defaultMessage: 'Read',
  },
  included: {
    id: 'permission.included.detail',
    defaultMessage: 'Enabled',
  },
  notIncluded: {
    id: 'permission.notIncluded.detail',
    defaultMessage: 'Disabled',
  },
  permissionsFieldSetTitle: {
    id: 'domainRoleDetailFieldSet.title',
    defaultMessage: 'Permissions',
  },
});
