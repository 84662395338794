import Heading from '@bfly/ui2/Heading';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import { useCallback } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DebouncedSearchControl from 'components/DebouncedSearchControl';
import PageTitle from 'components/PageTitle';
import { RoutePageProps } from 'components/Route';

import { DomainOrganizationsPage_viewer$data as Viewer } from './__generated__/DomainOrganizationsPage_viewer.graphql';

interface Props extends RoutePageProps {
  viewer: Viewer;
}

function DomainOrganizationsPage({ children, viewer, router, match }: Props) {
  const { location } = match;
  const { search } = location.query;

  const handleSearch = useCallback(
    (searchTerm) => {
      router.push({
        ...location,
        query: { search: searchTerm || undefined },
      });
    },
    [router, location],
  );

  return (
    <Page.Container className="flex-col">
      <Page.Header>
        <PageTitle
          title={defineMessage({
            id: 'domainOrganizationsPage.title',
            defaultMessage: 'Enterprise organizations',
          })}
          values={{
            numOrganizations:
              viewer.domain!.organizationConnection!.edges?.length ?? 0,
          }}
        />
      </Page.Header>
      <MainContent>
        <Heading ruled className="mb-5">
          <FormattedMessage
            id="domainOrganizationsPage.header"
            defaultMessage="{numOrganizations} Organizations"
            values={{
              numOrganizations:
                viewer.domain!.organizationConnection!.edges?.length ?? 0,
            }}
          />
        </Heading>
        <DebouncedSearchControl
          value={search}
          onChange={handleSearch}
          placeholder={defineMessage({
            id: 'domainOrganizationsPage.searchPlaceholder',
            defaultMessage: 'Search for an organization…',
          })}
          className="mb-6"
        />
        {children}
      </MainContent>
    </Page.Container>
  );
}

export default createFragmentContainer(DomainOrganizationsPage, {
  viewer: graphql`
    fragment DomainOrganizationsPage_viewer on Viewer {
      domain {
        organizationConnection {
          edges {
            node {
              __typename
            }
          }
        }
      }
    }
  `,
});
