import { ReactNode, createContext, useContext, useMemo } from 'react';

const NotificationCenterContext = createContext<{ id: string }>({
  id: '',
});

export function useNotificationCentertContext(): { id: string } {
  const context = useContext(NotificationCenterContext);
  return context;
}

export function NotificationCenterContextProvider({
  notificationCenter,
  children,
}: {
  notificationCenter: { id: string };
  children: ReactNode;
}) {
  const value = useMemo(
    () => ({
      id: notificationCenter.id,
    }),
    [notificationCenter.id],
  );

  return (
    <NotificationCenterContext.Provider value={value}>
      {children}
    </NotificationCenterContext.Provider>
  );
}
