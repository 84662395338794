import Popover from '@bfly/ui2/Popover';
import Text from '@bfly/ui2/Text';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import FormattedShortDateTime from 'components/FormattedShortDateTime';
import { studyStatusMessages } from 'components/StudyStatus';
import { StudyStatus } from 'utils/StudyConstants';
import { studyReviewStatus } from 'utils/StudyPermissions';

import { ExamStatus_study$data as Study } from './__generated__/ExamStatus_study.graphql';

const statusMessages = {
  [StudyStatus.DRAFT]: studyStatusMessages.DRAFT,
  [StudyStatus.FINALIZED]: defineMessage({
    defaultMessage: 'Final',
    id: 'studyStatus.short.finalized',
  }),
  [StudyStatus.PENDING_ATTESTATION]: defineMessage({
    defaultMessage: 'Signature',
    id: 'studyStatus.short.signature.requested',
  }),
};

const tooltipMessages = {
  uploadedBy: defineMessage({
    defaultMessage: 'Uploaded by {name} on {date}.',
    id: 'studyStatus.tooltip.uploadedby',
  }),
  signedPreliminaryBy: defineMessage({
    defaultMessage: 'Signed preliminary by {name} on {date}.',
    id: 'studyStatus.tooltip.signedPreliminaryBy',
  }),
  awaitingSignatureFrom: defineMessage({
    defaultMessage: 'Awaiting signature from {name} on {date}.',
    id: 'studyStatus.tooltip.awaitingSignatureFrom',
  }),
  signedFinalBy: defineMessage({
    defaultMessage: 'Signed final by {name} on {date}.',
    id: 'studyStatus.tooltip.signedFinalBy',
  }),
  needsQaOn: defineMessage({
    defaultMessage: 'Needs QA on {date}.',
    id: 'studyStatus.tooltip.needsQAon',
  }),
};

const styles = stylesheet`
  .status {
      padding: 0.2rem 1.5rem;
      border-radius: 4px;
  }
  @mixin dotted-status($pos) {
      @extend .status;
      :nth-child(#{$pos}).dot {
        background-color: transparent;
        border: 3.5px solid;
        border-color: inherit;
      }
  }
  .status-border-finalized {
    @extend .status;

    border: 1px theme('colors.white') solid;
    color: theme('colors.white')
  }

  .status-border-draft {
    @include dotted-status(1);

    border: 2px theme('colors.draft') solid;
    color: theme('colors.draft');
  }
  .status-border-pending-attestation {
    @include dotted-status(3);
    
    :first-child.dot {
      border: 5px theme('colors.grey[50]') solid;
    }

    border: 1px theme('colors.red[40]') solid;
    color: theme('colors.red[40]');
  }

  .dot {
    display: inline-flex;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: theme('colors.grey[25]') solid 1px;
  }
  .separator {
    height: 0px;
    width: 6px;
    border-top: solid 1px theme('colors.grey[50]');
  }
`;

const dotElem = (
  <span className={styles.dot} data-bni-id="ExamStatusHeaderDot" />
);
const separatorElem = <span className={styles.separator} />;

interface ExamStatusProps {
  study: Study;
  studyStatus:
    | StudyStatus.PENDING_ATTESTATION
    | StudyStatus.DRAFT
    | StudyStatus.FINALIZED;
}

const ExamStatus = ({ study, studyStatus }: ExamStatusProps) => {
  const {
    viewerCanFinalize,
    finalizedAt,
    finalizationRequests,
    finalizedBy,
    createdBy,
    readyAt,
    preliminarySignatureAt,
  } = study;
  const isReadyForQa =
    studyReviewStatus(study) === 'NEEDS_REVIEW' &&
    studyStatus === StudyStatus.FINALIZED;

  return (
    <div
      className="p-1 ml-1.5 flex items-center"
      data-bni-id="ExamStatusHeader"
    >
      <div
        className={clsx(
          'font-bold whitespace-nowrap',
          studyStatus === StudyStatus.DRAFT && styles.statusBorderDraft,
          studyStatus === StudyStatus.PENDING_ATTESTATION &&
            styles.statusBorderPendingAttestation,
          studyStatus === StudyStatus.FINALIZED &&
            styles.statusBorderFinalized,
        )}
      >
        {studyStatus === StudyStatus.FINALIZED ? (
          <Popover.Trigger
            placement="bottom"
            variant="dark"
            popover={
              <FormattedMessage
                {...tooltipMessages.signedFinalBy}
                values={{
                  name: finalizedBy?.name,
                  date: finalizedAt && (
                    <FormattedShortDateTime value={finalizedAt} />
                  ),
                }}
              />
            }
          >
            <div>
              <FormattedMessage {...statusMessages[StudyStatus.FINALIZED]} />
            </div>
          </Popover.Trigger>
        ) : (
          <FormattedMessage {...statusMessages[studyStatus]} />
        )}
        {studyStatus !== StudyStatus.FINALIZED && (
          <span className="inline-flex justify-center items-center ml-2 ">
            <Popover.Trigger
              variant="dark"
              placement="bottom"
              popover={
                <>
                  <FormattedMessage
                    {...tooltipMessages.uploadedBy}
                    values={{
                      name: createdBy?.name,
                      date: readyAt && (
                        <FormattedShortDateTime value={readyAt} />
                      ),
                    }}
                  />
                  {preliminarySignatureAt && (
                    <div className="mt-2">
                      <FormattedMessage
                        {...tooltipMessages.signedPreliminaryBy}
                        values={{
                          name: createdBy?.name,
                          date: (
                            <FormattedShortDateTime
                              value={preliminarySignatureAt}
                            />
                          ),
                        }}
                      />
                    </div>
                  )}
                </>
              }
            >
              {dotElem}
            </Popover.Trigger>
            {separatorElem}
            {finalizationRequests!.length > 0 ? (
              <Popover.Trigger
                placement="bottom"
                variant="dark"
                popover={
                  <div>
                    {finalizationRequests!.map((req, idx) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={idx}
                      >
                        <FormattedMessage
                          {...tooltipMessages.awaitingSignatureFrom}
                          values={{
                            name: req!.recipient?.name,
                            date: (
                              <FormattedShortDateTime
                                value={req!.createdAt!}
                              />
                            ),
                          }}
                        />
                      </div>
                    ))}
                  </div>
                }
              >
                {dotElem}
              </Popover.Trigger>
            ) : (
              dotElem
            )}
            {/* If a study is pending attestation, force to show three-dotted variant */}
            {(!viewerCanFinalize ||
              studyStatus === StudyStatus.PENDING_ATTESTATION) && (
              <>
                {separatorElem}
                {dotElem}
              </>
            )}
          </span>
        )}
      </div>
      {isReadyForQa && (
        <Popover.Trigger
          placement="bottom"
          variant="dark"
          popover={
            <FormattedMessage
              {...tooltipMessages.needsQaOn}
              values={{
                date: <FormattedShortDateTime value={finalizedAt!} />,
              }}
            />
          }
        >
          <div>
            <Text className="ml-2 text-white cursor-default" variant="sm">
              <FormattedMessage
                id="examStatus.isReadyForQa"
                defaultMessage="Ready for QA"
              />
            </Text>
          </div>
        </Popover.Trigger>
      )}
    </div>
  );
};

const _ = graphql`
  fragment ExamStatus_finalizationRequests on StudyFinalizationRequest {
    createdAt
    recipient {
      id
      name
    }
  }
`;

export default createFragmentContainer(ExamStatus, {
  study: graphql`
    fragment ExamStatus_study on Study {
      handle
      finalizedAt
      viewerCanFinalize
      finalizationRequests {
        ...ExamStatus_finalizationRequests @relay(mask: false)
      }
      finalizedAt
      createdBy {
        name
      }
      readyAt
      preliminarySignatureAt
      finalizedAt
      finalizedBy {
        name
      }
      ...StudyPermissions_studyReviewStatus
    }
  `,
});
