/**
 * @generated SignedSource<<70495cfca88ca7952f689d678c068223>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySearchInputStatus = "DRAFT" | "FINALIZED" | "NEEDS_REVIEW" | "PENDING_ATTESTATION" | "REVIEWED" | "%future added value";
export type StudyStatisticsFilterInput = {
  archive?: ReadonlyArray<string> | null;
  author?: ReadonlyArray<string> | null;
  authorDeleted?: ReadonlyArray<boolean> | null;
  capturedAt?: DateTimeRangeInput | null;
  credentialGroup?: ReadonlyArray<string> | null;
  domain?: ReadonlyArray<string> | null;
  examType?: ReadonlyArray<string> | null;
  isDeleted?: ReadonlyArray<boolean> | null;
  isRelevantForCredentialing?: ReadonlyArray<boolean> | null;
  organization?: ReadonlyArray<string> | null;
  reviewer?: ReadonlyArray<string> | null;
  status?: ReadonlyArray<StudySearchInputStatus> | null;
  worksheetTemplate?: ReadonlyArray<string> | null;
};
export type DateTimeRangeInput = {
  days?: number | null;
  endDate?: string | null;
  startDate?: string | null;
};
export type analyticsRoutes_AllTimeStatsCardsQuery$variables = {
  filter: StudyStatisticsFilterInput;
  organizationSlug?: string | null;
};
export type analyticsRoutes_AllTimeStatsCardsQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"AllTimeStatsCards_tenant">;
  } | null;
};
export type analyticsRoutes_AllTimeStatsCardsQuery = {
  response: analyticsRoutes_AllTimeStatsCardsQuery$data;
  variables: analyticsRoutes_AllTimeStatsCardsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numStudies",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numFinalizedStudies",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numReviewedStudies",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "analyticsRoutes_AllTimeStatsCardsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "AllTimeStatsCards_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "analyticsRoutes_AllTimeStatsCardsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          {
            "alias": "examStatusStudyStatistics",
            "args": (v3/*: any*/),
            "concreteType": "StudyStatistics",
            "kind": "LinkedField",
            "name": "studyStatistics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyStatusStatistics",
                "kind": "LinkedField",
                "name": "studyStatusStatistics",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "reviewedStudyStatistics",
            "args": (v3/*: any*/),
            "concreteType": "StudyStatistics",
            "kind": "LinkedField",
            "name": "studyStatistics",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numReviewedStudiesWithWorksheets",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyExamTypeStatistics",
                "kind": "LinkedField",
                "name": "studyExamTypeStatistics",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamType",
                    "kind": "LinkedField",
                    "name": "examType",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "systemId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyStatusStatistics",
                "kind": "LinkedField",
                "name": "studyStatusStatistics",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "imageStudyStatistics",
            "args": (v3/*: any*/),
            "concreteType": "StudyStatistics",
            "kind": "LinkedField",
            "name": "studyStatistics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "averageImageQuality",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageQualityStatistics",
                "kind": "LinkedField",
                "name": "imageQualityStatistics",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "type",
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "StudyStatistics",
            "kind": "LinkedField",
            "name": "studyStatistics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InterpretationStatistics",
                "kind": "LinkedField",
                "name": "accuracyAsPresentedStatistics",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eaa9ffb2130c05facd221321dc63f3bf",
    "id": null,
    "metadata": {},
    "name": "analyticsRoutes_AllTimeStatsCardsQuery",
    "operationKind": "query",
    "text": "query analyticsRoutes_AllTimeStatsCardsQuery(\n  $organizationSlug: String\n  $filter: StudyStatisticsFilterInput!\n) {\n  tenant(slug: $organizationSlug) {\n    __typename\n    ...AllTimeStatsCards_tenant_Vt7Yj\n    id\n  }\n}\n\nfragment AllTimeStatsCards_tenant_Vt7Yj on TenantInterface {\n  __isTenantInterface: __typename\n  ...ExamStatusPerformanceIndicator_tenant\n  ...ReviewedExamsPerformanceIndicator_tenant\n  ...ImageQualityPerformanceIndicator_tenant\n  ...ClinicalInsightsCards_tenant_Vt7Yj\n}\n\nfragment ClinicalInsightsCards_tenant_Vt7Yj on TenantInterface {\n  __isTenantInterface: __typename\n  type: __typename\n  studyStatistics(filter: $filter) {\n    accuracyAsPresentedStatistics {\n      key\n      numFinalizedStudies\n    }\n  }\n}\n\nfragment ExamStatusPerformanceIndicator_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  examStatusStudyStatistics: studyStatistics(filter: $filter) {\n    studyStatusStatistics {\n      key\n      numStudies\n    }\n  }\n}\n\nfragment ImageQualityPerformanceIndicator_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  imageStudyStatistics: studyStatistics(filter: $filter) {\n    averageImageQuality\n    imageQualityStatistics {\n      key\n      numFinalizedStudies\n    }\n  }\n}\n\nfragment ReviewedExamsPerformanceIndicator_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  reviewedStudyStatistics: studyStatistics(filter: $filter) {\n    numFinalizedStudies\n    numReviewedStudies\n    numReviewedStudiesWithWorksheets\n    studyExamTypeStatistics {\n      examType {\n        id\n        name\n        systemId\n      }\n      numFinalizedStudies\n      numReviewedStudies\n    }\n    studyStatusStatistics {\n      numStudies\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1df48394f99f707b297fde0d29b150d6";

export default node;
