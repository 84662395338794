/**
 * @generated SignedSource<<7b785ef54299c43d181beb6774ddb875>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetArchiveEhrAssociationsInput = {
  archiveId: string;
  clientMutationId?: string | null;
  ehrIds: ReadonlyArray<string>;
};
export type EhrArchiveAssociationForm_Mutation$variables = {
  input: SetArchiveEhrAssociationsInput;
};
export type EhrArchiveAssociationForm_Mutation$data = {
  readonly setArchiveEhrAssociationsOrError: {
    readonly __typename?: string;
    readonly archive?: {
      readonly " $fragmentSpreads": FragmentRefs<"EhrArchiveAssociationForm_archive">;
    } | null;
    readonly message?: string | null;
  } | null;
};
export type EhrArchiveAssociationForm_Mutation = {
  response: EhrArchiveAssociationForm_Mutation$data;
  variables: EhrArchiveAssociationForm_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EhrArchiveAssociationForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setArchiveEhrAssociationsOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Archive",
                "kind": "LinkedField",
                "name": "archive",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EhrArchiveAssociationForm_archive"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SetArchiveEhrAssociationsPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EhrArchiveAssociationForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setArchiveEhrAssociationsOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Archive",
                "kind": "LinkedField",
                "name": "archive",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EhrArchiveAssociation",
                    "kind": "LinkedField",
                    "name": "ehrAssociations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Ehr",
                        "kind": "LinkedField",
                        "name": "ehr",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SetArchiveEhrAssociationsPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e86cbb905c3af85486f03b8404870634",
    "id": null,
    "metadata": {},
    "name": "EhrArchiveAssociationForm_Mutation",
    "operationKind": "mutation",
    "text": "mutation EhrArchiveAssociationForm_Mutation(\n  $input: SetArchiveEhrAssociationsInput!\n) {\n  setArchiveEhrAssociationsOrError(input: $input) {\n    __typename\n    ... on SetArchiveEhrAssociationsPayload {\n      archive {\n        ...EhrArchiveAssociationForm_archive\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n\nfragment EhrArchiveAssociationForm_archive on Archive {\n  id\n  ehrAssociations {\n    ehr {\n      id\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "621720c3a3bdb5ce59c5060934142ee8";

export default node;
