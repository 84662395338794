import TrashCanIcon from '@bfly/icons/TrashCan';
import useDialog from '@bfly/ui2/useDialog';
import { handleNavigate } from '@bfly/ui2/useGoBackOnNormalClick';
import { globalIdToLocalId } from '@bfly/utils/codecs';
import useRouter from 'found/useRouter';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useDeleteStudies from 'hooks/useDeleteStudies';
import actionMessages from 'messages/actions';
import { useOrganizationRoutes } from 'routes/organization';
import { useCanDeleteStudy } from 'utils/StudyPermissions';
import { isStudyAuthor } from 'utils/studyAuthors';
import { useViewerContext } from 'utils/viewerContext';

import { DeleteStudyControl_study$data as Study } from './__generated__/DeleteStudyControl_study.graphql';

interface Props {
  as: React.ElementType;
  study: Study;
}

function DeleteStudyControl({ as: Component, study }: Props) {
  const orgRoutes = useOrganizationRoutes();
  const dialog = useDialog();
  const routerState = useRouter();
  const { match, router } = routerState;
  const { location } = match;
  const [deleteStudies] = useDeleteStudies();
  const canDeleteStudy = useCanDeleteStudy();
  const viewer = useViewerContext();

  const runConfirm = useCallback(
    () =>
      deleteStudies(
        [
          {
            studyId: study.id,
            archiveId: study.archive!.id,
          },
        ],
        study.organization!.id,
        '',
        (store) => {
          const isDraft = !study.finalizedAt;
          if (isStudyAuthor(study, viewer.profile!.id) && isDraft) {
            const localViewerId = globalIdToLocalId(viewer.profile!.id);
            const primaryDraftVars = {
              search: { status: ['DRAFT'], primaryAuthor: localViewerId },
            };
            const primarySecondaryDraftVars = {
              search: { status: ['DRAFT'], author: localViewerId },
            };
            const organizationNode = store.get(study!.organization!.id)!;
            const numViewerDraftPrimary = organizationNode?.getValue(
              'numStudies',
              primaryDraftVars,
            ) as number;
            const numViewerDraftPrimarySecondary = organizationNode?.getValue(
              'numStudies',
              primarySecondaryDraftVars,
            ) as number;

            if (numViewerDraftPrimary) {
              const updatedNumViewerDraftPrimary = Math.max(
                numViewerDraftPrimary - 1,
                0,
              );
              organizationNode.setValue(
                updatedNumViewerDraftPrimary,
                'numStudies',
                primaryDraftVars,
              );
            }

            if (numViewerDraftPrimarySecondary) {
              const updatedNumViewerDraftPrimarySecondary = Math.max(
                numViewerDraftPrimarySecondary - 1,
                0,
              );
              organizationNode.setValue(
                updatedNumViewerDraftPrimarySecondary,
                'numStudies',
                primarySecondaryDraftVars,
              );
            }
          }
        },
      ),
    [deleteStudies, study, viewer.profile],
  );

  const handleClick = useCallback(async () => {
    const deleted = await dialog.open(
      <FormattedMessage
        id="study.delete.dialog.content"
        defaultMessage="This exam contains {numImages} {numImages, plural, one {image} other {images}}."
        values={{ numImages: study.numImages }}
      />,
      {
        runConfirm,
        confirmButtonProps: { variant: 'danger', size: 'lg' },
        modalVariant: 'dark',
        title: (
          <FormattedMessage
            id="study.delete.dialog.title"
            defaultMessage="Delete Exam?"
          />
        ),
        confirmLabel: <FormattedMessage {...actionMessages.delete} />,
      },
    );

    if (!deleted) {
      return;
    }

    // If we're on a study detail view, navigate away after deleting the study.
    if (match.params.studyHandle) {
      handleNavigate(
        routerState,
        location.state?.studyListNavConfig?.backIndex ?? -1,
      );
      router.replace(orgRoutes.organizationRoot());
    }
  }, [
    dialog,
    study.numImages,
    runConfirm,
    match.params.studyHandle,
    routerState,
    location.state?.studyListNavConfig?.backIndex,
    router,
    orgRoutes,
  ]);

  if (!canDeleteStudy(study)) {
    return null;
  }

  return (
    <Component
      icon={<TrashCanIcon />}
      onClick={handleClick}
      variant="danger"
      data-bni-id="DeleteStudyControl"
    >
      <FormattedMessage id="study.deleteExam" defaultMessage="Delete exam" />
    </Component>
  );
}

export default createFragmentContainer(DeleteStudyControl, {
  study: graphql`
    fragment DeleteStudyControl_study on Study {
      id
      organization {
        id
        slug
      }
      archive {
        id
        handle
      }
      numImages
      finalizedAt
      ...StudyPermissions_canDeleteStudy
      ...studyAuthors_study
    }
  `,
});
