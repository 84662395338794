import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DicomName from 'components/DicomName';

import joinNonemptyNodes from '../utils/joinNonemptyNodes';
import { PhysicianInfo_study$data as Study } from './__generated__/PhysicianInfo_study.graphql';

interface Props {
  study: Study;
}

function PhysicianInfo({ study }: Props) {
  return (
    <>
      {joinNonemptyNodes(', ', [
        study.dicomReferringPhysician && (
          <React.Fragment key="referringPhysician">
            <FormattedMessage
              id="physicianInfo.referring"
              defaultMessage="Referring: {value}"
              values={{
                value: <DicomName value={study.dicomReferringPhysician} />,
              }}
            />
          </React.Fragment>
        ),
        study.dicomRequestingPhysician && (
          <React.Fragment key="requestingPhysician">
            <FormattedMessage
              id="physicianInfo.requesting"
              defaultMessage="Requesting: {value}"
              values={{
                value: <DicomName value={study.dicomRequestingPhysician} />,
              }}
            />
          </React.Fragment>
        ),
        study.dicomOperatorsName && (
          <React.Fragment key="operatorsName">
            <FormattedMessage
              id="physicianInfo.operator"
              defaultMessage="Operator: {value}"
              values={{
                value: <DicomName value={study.dicomOperatorsName} />,
              }}
            />
          </React.Fragment>
        ),
      ])}
    </>
  );
}

export default createFragmentContainer(PhysicianInfo, {
  study: graphql`
    fragment PhysicianInfo_study on Study {
      dicomOperatorsName
      dicomReferringPhysician
      dicomRequestingPhysician
    }
  `,
});
