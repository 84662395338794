/**
 * @generated SignedSource<<2eb0c27c7b8aa6049724258d6ef234a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainLoginPage_inviteInfo$data = {
  readonly email: string | null;
  readonly userHasButterflyLogin: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"LoginSubtitle_inviteInfo">;
  readonly " $fragmentType": "DomainLoginPage_inviteInfo";
};
export type DomainLoginPage_inviteInfo$key = {
  readonly " $data"?: DomainLoginPage_inviteInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainLoginPage_inviteInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainLoginPage_inviteInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userHasButterflyLogin",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoginSubtitle_inviteInfo"
    }
  ],
  "type": "OrganizationInviteInfo",
  "abstractKey": null
};

(node as any).hash = "ea31941a57551d4aff397c2ee79f2dfe";

export default node;
