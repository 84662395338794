/**
 * @generated SignedSource<<551bf635d7347f5ce34e58b3e47ae0bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainQaRoutes_DomainCreateQaTemplateForm_Query$variables = {};
export type domainQaRoutes_DomainCreateQaTemplateForm_Query$data = {
  readonly viewer: {
    readonly domain: {
      readonly " $fragmentSpreads": FragmentRefs<"CreateQaTemplateForm_workflowConfig">;
    } | null;
  } | null;
};
export type domainQaRoutes_DomainCreateQaTemplateForm_Query = {
  response: domainQaRoutes_DomainCreateQaTemplateForm_Query$data;
  variables: domainQaRoutes_DomainCreateQaTemplateForm_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "domainQaRoutes_DomainCreateQaTemplateForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreateQaTemplateForm_workflowConfig"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "domainQaRoutes_DomainCreateQaTemplateForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "organizationId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Organization",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "domainId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Domain",
                        "abstractKey": null
                      }
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "type": "WorkflowConfigInterface",
                "abstractKey": "__isWorkflowConfigInterface"
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c633786a7b7afbb6954a9bc1f4cb79a4",
    "id": null,
    "metadata": {},
    "name": "domainQaRoutes_DomainCreateQaTemplateForm_Query",
    "operationKind": "query",
    "text": "query domainQaRoutes_DomainCreateQaTemplateForm_Query {\n  viewer {\n    domain {\n      ...CreateQaTemplateForm_workflowConfig\n      id\n    }\n    id\n  }\n}\n\nfragment CreateQaTemplateForm_workflowConfig on WorkflowConfigInterface {\n  __isWorkflowConfigInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ...useTenantIdInput_tenant\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9db14c434375055df63bac87276c3964";

export default node;
