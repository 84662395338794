/**
 * @generated SignedSource<<6ed96ec5584d103faa2d9fd43bb04e23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteStudyImageCommentInput = {
  clientMutationId?: string | null;
  studyImageCommentId: any;
  studyImageId?: any | null;
};
export type DeleteStudyImageCommentControlMutation$variables = {
  input: DeleteStudyImageCommentInput;
};
export type DeleteStudyImageCommentControlMutation$data = {
  readonly deleteStudyImageCommentOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
    readonly studyImage?: {
      readonly numComments: number | null;
      readonly study: {
        readonly numImageComments: number | null;
      } | null;
    } | null;
  } | null;
};
export type DeleteStudyImageCommentControlMutation = {
  response: DeleteStudyImageCommentControlMutation$data;
  variables: DeleteStudyImageCommentControlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numComments",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numImageComments",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteStudyImageCommentControlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteStudyImageCommentOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyImage",
                "kind": "LinkedField",
                "name": "studyImage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Study",
                    "kind": "LinkedField",
                    "name": "study",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "DeleteStudyImageCommentPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteStudyImageCommentControlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteStudyImageCommentOrError",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyImage",
                "kind": "LinkedField",
                "name": "studyImage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Study",
                    "kind": "LinkedField",
                    "name": "study",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "DeleteStudyImageCommentPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6e6c0978a79dddfecac937380f36152",
    "id": null,
    "metadata": {},
    "name": "DeleteStudyImageCommentControlMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteStudyImageCommentControlMutation(\n  $input: DeleteStudyImageCommentInput!\n) {\n  deleteStudyImageCommentOrError(input: $input) {\n    __typename\n    ... on DeleteStudyImageCommentPayload {\n      studyImage {\n        numComments\n        study {\n          numImageComments\n          id\n        }\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b17edd38cd81b1176061eef3a09f6585";

export default node;
