import {
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { LocalStore } from 'utils/localStore';
import podTargetingHeaders from 'utils/podTargetingHeaders';

export type Section = 'headerOverrides' | 'launchDarklyFlags' | 'playground';

export type DevState = {
  activeSection: Section;
  search: string;
  headerOverrideOrder: string[];
};

interface DevContext extends DevState {
  setDevState: (next: SetStateAction<DevState>) => void;
}

const devContext = createContext<null | DevContext>(null);

const devStateLocal = LocalStore<DevState>('bfly:devControlsState');

export function useDevContext() {
  return useContext(devContext) as DevContext;
}

export function DevContextProvider({ children }: PropsWithChildren) {
  const DevContext = devContext;

  const [devState, setState] = useState<DevState>(
    devStateLocal.get({
      activeSection: 'headerOverrides',
      search: '',
      headerOverrideOrder: Object.keys(podTargetingHeaders.get()),
    }),
  );

  const setDevState = useCallback((next: SetStateAction<DevState>) => {
    setState((prev) => {
      const nextState =
        typeof next === 'function'
          ? (next as (prevState: DevState) => DevState)(prev)
          : next;

      devStateLocal.set(nextState);
      return nextState;
    });
  }, []);

  return (
    <DevContext.Provider
      value={useMemo(
        () => ({ ...devState, setDevState }),
        [devState, setDevState],
      )}
    >
      {children}
    </DevContext.Provider>
  );
}
