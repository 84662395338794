import { useEffect } from 'react';

export default function useHotKey(
  command: () => void,
  ...keys: (string | 'ctrlKey')[]
) {
  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (
        keys.includes(event.key) &&
        (!keys.includes('ctrlKey') || event.ctrlKey)
      ) {
        command();
      }
    }

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  });
}
