/**
 * @generated SignedSource<<2b6bd6cfc208f2ad77f8bb1cc3e8cbab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyAuthorSearchControl_study$data = {
  readonly archive: {
    readonly id: string;
  } | null;
  readonly finalizedAt: string | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"studyAuthors_study">;
  readonly " $fragmentType": "StudyAuthorSearchControl_study";
};
export type StudyAuthorSearchControl_study$key = {
  readonly " $data"?: StudyAuthorSearchControl_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyAuthorSearchControl_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyAuthorSearchControl_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "studyAuthors_study",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "secondaryAuthors",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "a09e63875b79e3d0ae79bdb9038184eb";

export default node;
