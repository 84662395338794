/**
 * @generated SignedSource<<6085386f3542f397388793aab748c4c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySearchPage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_viewer">;
  readonly " $fragmentType": "StudySearchPage_viewer";
};
export type StudySearchPage_viewer$key = {
  readonly " $data"?: StudySearchPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySearchPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySearchPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "8aecf948f9398af00de3138f070e49b4";

export default node;
