/**
 * @generated SignedSource<<98b5b40acccbd32c9cd6d893ff48de63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentSuggestionEntry_mentionable$data = {
  readonly email: string | null;
  readonly userProfile: {
    readonly " $fragmentSpreads": FragmentRefs<"UserSuggestion_userProfile">;
  } | null;
  readonly " $fragmentType": "CommentSuggestionEntry_mentionable";
};
export type CommentSuggestionEntry_mentionable$key = {
  readonly " $data"?: CommentSuggestionEntry_mentionable$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentSuggestionEntry_mentionable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentSuggestionEntry_mentionable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "userProfile",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserSuggestion_userProfile"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "Mentionable",
  "abstractKey": null
};

(node as any).hash = "6e845d23d218c8525bda55c4647fdec9";

export default node;
