import CloseSheet from '@bfly/icons/CloseSheet';
import Button from '@bfly/ui2/Button';
import notNullish from '@bfly/utils/notNullish';
import { createFragmentContainer, graphql } from 'react-relay';
import ListOption, { ListOptionProps } from 'react-widgets/ListOption';

import { RecentSearchIcon } from 'utils/Search';

import SearchTags from './SearchTags';
import { SearchBarListRecentSearchOption_studyRecentSearch$data as StudyRecentSearch } from './__generated__/SearchBarListRecentSearchOption_studyRecentSearch.graphql';

interface Props extends ListOptionProps<any> {
  studyRecentSearch: StudyRecentSearch;
  onClear: () => void;
}

function SearchBarListRecentSearchOption({
  studyRecentSearch,
  onClear,
  ...props
}: Props) {
  return (
    <ListOption
      className="flex items-center justify-center btn-toolbar"
      {...props}
    >
      <RecentSearchIcon className="text-subtitle mr-1 shrink-0" />
      <SearchTags
        nodes={null}
        searchData={null}
        studySearchCriteria={studyRecentSearch.criteria!.filter(notNullish)}
        variant="dark"
      />
      <Button
        variant="text-secondary"
        className="ml-auto"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClear();
        }}
      >
        <CloseSheet className="w-3 h-3" />
      </Button>
    </ListOption>
  );
}

export default createFragmentContainer(SearchBarListRecentSearchOption, {
  studyRecentSearch: graphql`
    fragment SearchBarListRecentSearchOption_studyRecentSearch on StudyRecentSearch {
      criteria {
        ...SearchTags_studySearchCriteria
      }
    }
  `,
});
