/**
 * @generated SignedSource<<3c451b798d56fe8818b5a272483f8ca8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type studyProtocols_StudyProtocolPage_Query$variables = {
  organizationSlug: string;
  studyProtocolHandle: string;
};
export type studyProtocols_StudyProtocolPage_Query$data = {
  readonly organization: {
    readonly id: string;
  } | null;
  readonly studyProtocol: {
    readonly " $fragmentSpreads": FragmentRefs<"StudyProtocolPage_studyProtocol">;
  } | null;
};
export type studyProtocols_StudyProtocolPage_Query = {
  response: studyProtocols_StudyProtocolPage_Query$data;
  variables: studyProtocols_StudyProtocolPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studyProtocolHandle"
},
v2 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "studyProtocolHandle"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "organization",
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "organizationSlug"
    }
  ],
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organizationBySlug",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "studyProtocols_StudyProtocolPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "StudyProtocol",
        "kind": "LinkedField",
        "name": "studyProtocol",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StudyProtocolPage_studyProtocol"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "studyProtocols_StudyProtocolPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "StudyProtocol",
        "kind": "LinkedField",
        "name": "studyProtocol",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "systemId",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyProtocolView",
            "kind": "LinkedField",
            "name": "views",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "presetId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9972e248aab84c76e75f313699d17545",
    "id": null,
    "metadata": {},
    "name": "studyProtocols_StudyProtocolPage_Query",
    "operationKind": "query",
    "text": "query studyProtocols_StudyProtocolPage_Query(\n  $studyProtocolHandle: String!\n  $organizationSlug: String!\n) {\n  studyProtocol(handle: $studyProtocolHandle) {\n    ...StudyProtocolPage_studyProtocol\n    id\n  }\n  organization: organizationBySlug(slug: $organizationSlug) {\n    id\n  }\n}\n\nfragment DeleteProtocolModal_studyProtocol on StudyProtocol {\n  id\n  name\n}\n\nfragment StudyProtocolPage_studyProtocol on StudyProtocol {\n  systemId\n  ...SystemStudyProtocol_studyProtocol\n  ...UpdateStudyProtocolPage_studyProtocol\n}\n\nfragment SystemStudyProtocol_studyProtocol on StudyProtocol {\n  name\n  views {\n    name\n    presetId\n  }\n}\n\nfragment UpdateStudyProtocolPage_studyProtocol on StudyProtocol {\n  id\n  name\n  views {\n    name\n    presetId\n    mode\n    type\n  }\n  ...DeleteProtocolModal_studyProtocol\n}\n"
  }
};
})();

(node as any).hash = "84ac7d1dcbb4015b937918190cb6f96f";

export default node;
