/**
 * @generated SignedSource<<601ed103ba325ce5b160747d1b444fdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamAuthorsRefresh_study$data = {
  readonly archive: {
    readonly id: string;
  } | null;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ExamAuthorsField_users">;
  } | null;
  readonly finalizedAt: string | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly secondaryAuthors: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ExamAuthorsField_users">;
  } | null> | null;
  readonly transcribedBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ExamAuthorsField_users">;
  } | null;
  readonly " $fragmentType": "ExamAuthorsRefresh_study";
};
export type ExamAuthorsRefresh_study$key = {
  readonly " $data"?: ExamAuthorsRefresh_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamAuthorsRefresh_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ExamAuthorsField_users"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamAuthorsRefresh_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "secondaryAuthors",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "transcribedBy",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "199b79218f3a6d61a785429f5256ce79";

export default node;
