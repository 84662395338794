/**
 * @generated SignedSource<<3bdbaf2391622961dc89c97e4d54538f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamQa_templateVersion$data = {
  readonly fieldDefinitions: ReadonlyArray<{
    readonly handle: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"FieldDefinitionFormFieldGroup_fieldDefinition" | "TemplatedFieldListItem_fieldDefinition">;
  } | null> | null;
  readonly id: string;
  readonly title: string | null;
  readonly " $fragmentType": "ExamQa_templateVersion";
};
export type ExamQa_templateVersion$key = {
  readonly " $data"?: ExamQa_templateVersion$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamQa_templateVersion">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamQa_templateVersion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "fieldDefinitions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TemplatedFieldListItem_fieldDefinition"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FieldDefinitionFormFieldGroup_fieldDefinition"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QaTemplateVersion",
  "abstractKey": null
};

(node as any).hash = "f79129cfba43e45a07ad05189300038c";

export default node;
