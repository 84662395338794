import Layout from '@4c/layout';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import Page from '@bfly/ui2/Page';
import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import clsx from 'clsx';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from 'components/Avatar';
import ExamHeader from 'components/ExamHeader';
import ExamNav from 'components/ExamNav';
import { RoutePageProps } from 'components/Route';
import { routes as scanLabRoutes } from 'routes/scanLab';

import ExamImageOverlayControls from '../../../shared/components/ExamImageOverlayControls';
import FullScreenExamPane from '../../exams/components/FullScreenExamPane';
import { getReviewStatus } from '../constants';
import { useScanLabContext } from '../context';
import { STATUS_MESSAGES } from '../messages';
import { ReviewStatus } from '../types';
import EduStudySidebar from './EduStudySidebar';
import {
  EduStudyPage_pagination_Query as PagingQuery,
  EduStudyPage_pagination_Query$data as PagingResponse,
} from './__generated__/EduStudyPage_pagination_Query.graphql';
import { EduStudyPage_study$data as EduStudy } from './__generated__/EduStudyPage_study.graphql';

type Props = RoutePageProps & {
  study: EduStudy;
  leftSide: ReactNode;
};

type PagingEdge = NonNullable<
  NonNullable<NonNullable<PagingResponse['viewer']>['prev']>['edges']
>[0];

function useExamPagination() {
  const { queryParams, toStudyLocation } = useScanLabContext();

  const toDirectionLocation = (edge?: PagingEdge | null) => {
    if (!edge?.node?.handle || !edge.cursor || !queryParams) return null;

    return toStudyLocation({
      studyHandle: edge.node.handle,
      cursor: edge.cursor,
    });
  };

  const { data } = useQuery<PagingQuery>(
    graphql`
      query EduStudyPage_pagination_Query(
        $sort: [EduStudySorting!]
        $examTypeHandles: [String!]
        $conductedAtMin: DateTime
        $conductedAtMax: DateTime
        $author: [String!]
        $isReviewed: Boolean
        $isReviewRequested: Boolean
        $cursor: String
        $groupId: [ID!]
      ) {
        viewer {
          prev: eduStudyConnection(
            last: 1
            before: $cursor

            sort: $sort
            examTypeHandles: $examTypeHandles
            conductedAtMin: $conductedAtMin
            conductedAtMax: $conductedAtMax
            author: $author
            isReviewed: $isReviewed
            isReviewRequested: $isReviewRequested
            groupId: $groupId
          ) {
            edges {
              cursor
              node {
                handle
              }
            }
          }
          next: eduStudyConnection(
            first: 1
            after: $cursor

            sort: $sort
            examTypeHandles: $examTypeHandles
            conductedAtMin: $conductedAtMin
            conductedAtMax: $conductedAtMax
            author: $author
            isReviewed: $isReviewed
            isReviewRequested: $isReviewRequested
            groupId: $groupId
          ) {
            edges {
              cursor
              node {
                handle
              }
            }
          }
        }
      }
    `,
    {
      skip: !queryParams,
      variables: queryParams!,
    },
  );

  return {
    next: toDirectionLocation(data?.viewer?.next?.edges?.[0]),
    prev: toDirectionLocation(data?.viewer?.prev?.edges?.[0]),
  };
}

const ReviewStatusIndicator = ({ status }: { status: ReviewStatus }) => {
  const circleClassName =
    'w-[9px] h-[9px] shrink-0 rounded-full border-grey-50 border';
  const lineClassName = 'w-1 h-0 border-b-[1px] border-b-grey-50';

  return (
    <div
      data-bni-id="reviewStatusIndicator"
      className={clsx(
        'py-0 px-4 border rounded flex flex-row items-center justify-center font-bold ml-4',
        status === 'unsubmitted' && 'border-red-40 text-red-40',
        status === 'reviewRequested' && 'border-draft text-draft',
        status === 'reviewed' && 'border-white text-headline',
      )}
    >
      <FormattedMessage {...STATUS_MESSAGES[status]} />
      {status !== 'reviewed' && (
        <div className="flex flex-row relative items-center justify-center ml-4">
          <div
            className={clsx(
              circleClassName,
              status === 'unsubmitted' && ' border-red-40 border-[3px]',
            )}
          />
          <div className={lineClassName} />
          <div
            className={clsx(
              circleClassName,
              status === 'reviewRequested' && ' border-draft border-[3px]',
            )}
          />
          <div className={lineClassName} />
          <div className={circleClassName} />
        </div>
      )}
    </div>
  );
};

function EduStudyPage({ study, leftSide }: Props) {
  const [status, setStatus] = useState(getReviewStatus(study));
  const { toListRoute, canRequestReview } = useScanLabContext();

  useEffect(
    () => setStatus(getReviewStatus(study)),
    [study, study.reviewRequestedAt, study.standardQa?.createdAt],
  );

  const backTo = toListRoute(status);
  const paginationNav = useExamPagination();

  return (
    <Page layout="fill">
      <ExamHeader>
        <ExamHeader.TopLeft backTo={backTo}>
          {canRequestReview && <ReviewStatusIndicator status={status} />}
        </ExamHeader.TopLeft>
        <ExamHeader.TopCenter>
          <ExamNav {...paginationNav}>
            <Layout className="min-w-32 lg:min-w-48" justify="center">
              <Text variant="lg" color="headline" data-bni-id="examType">
                {study.examTypes?.[0]?.name}
              </Text>
            </Layout>
          </ExamNav>
        </ExamHeader.TopCenter>
        <ExamHeader.TopRight className="">
          <div className="text-grey-25">
            <FormattedMessage
              id="scanLab.EduStudyPage.capturedAt"
              defaultMessage="Captured"
            />
          </div>
          <time
            className="ml-3 text-headline font-bold"
            data-bni-id="capturedDate"
            dateTime={new Date(study.capturedAt!).toISOString()}
          >
            <FormattedDateTime value={study.capturedAt!} />
          </time>
        </ExamHeader.TopRight>
      </ExamHeader>
      <Layout
        className="bg-grey-90 py-2 px-6 border-b border-grey-80"
        align="center"
        data-bni-id="author"
      >
        <FormattedMessage
          id="scanLab.EduStudyPage.author"
          defaultMessage="Author"
        />
        <Layout className="rounded bg-grey-80 py-1.5 px-2 justify-start max-w-[max-content] ml-4">
          <Avatar width={20} className="mr-2" userProfile={study.createdBy} />
          <Text color="headline" variant="body-bold">
            {study.createdBy?.name}
          </Text>
        </Layout>
      </Layout>

      <Page.Container
        className={clsx(
          'bg-body top-auto md:grid lg:grid-cols-[1fr,48rem] xl:grid-cols-[1fr,60rem]',
        )}
      >
        <div className="page-main relative">
          <FullScreenExamPane className="flex flex-col bg-black">
            <div className="absolute top-4 right-4 z-10 flex flex-col space-y-2 m-[-1rem]">
              <ExamImageOverlayControls
                exam={scanLabRoutes.exam}
                examGallery={scanLabRoutes.examGallery}
                examImage={scanLabRoutes.examImage}
                hideGalleryButton
              />
            </div>
            {leftSide}
          </FullScreenExamPane>
        </div>
        <EduStudySidebar
          key={study.handle}
          study={study}
          status={status}
          setStatus={(next) => setStatus(next)}
        />
      </Page.Container>
    </Page>
  );
}

export default createFragmentContainer(EduStudyPage, {
  study: graphql`
    fragment EduStudyPage_study on EduStudy {
      ...EduStudySidebar_study
      handle
      createdBy {
        id
        handle
        name
        ...Avatar_userProfile
      }
      capturedAt
      examTypes {
        name
      }
      reviewRequestedAt
      standardQa {
        createdAt
      }
    }
  `,
});
