import { useIntl } from 'react-intl';

// Zendesk needs an exact, case-sensitive match for these locales.
const LANGUAGE_LOCALES: Record<string, string> = {
  da: 'da-dk',
  de: 'de-de',
  es: 'es-es',
  fi: 'fi',
  fr: 'fr-fr',
  it: 'it-it',
  nb: 'nb-no',
  nl: 'nl-nl',
  pl: 'pl-pl',
  pt: 'pt-pt',
  sv: 'sv',
};

const DEFAULT_LOCALE = 'en-us';

export default function useZendeskLocale() {
  const intl = useIntl();
  const languageCode = intl.locale.slice(0, 2);

  // TODO: Switch this to use getClosestLocale once that supports evaluating
  //  fallbacks at runtime.
  return LANGUAGE_LOCALES[languageCode] || DEFAULT_LOCALE;
}
