import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import React from 'react';

function LoadingIndicatorPage() {
  return (
    <Page>
      <Page.Container>
        <MainContent>
          <LoadingIndicator />
        </MainContent>
      </Page.Container>
    </Page>
  );
}

export default LoadingIndicatorPage;
