import uniqueId from 'lodash/uniqueId';
import { graphql, readInlineData } from 'relay-runtime';
import { InferType, TypeOf, array, number, object, string } from 'yup';

import {
  eduStandardQa_eduStudy$key as EduStudyKey,
  EduStandardQaFeedback as _EduStandardQaFeedback,
} from './__generated__/eduStandardQa_eduStudy.graphql';

export type EduStandardQaFeedback =
  ExcludeFutureAddedValue<_EduStandardQaFeedback>;

export const standardQaFeedbackOptions: EduStandardQaFeedback[] = [
  'ADJUST_GAIN',
  'ADJUST_DEPTH',
  'INCOMPLETE_MEASUREMENTS',
  'INCORRECT_PRESET',
  'INCORRECT_ORIENTATION',
  'INCORRECT_TRANSDUCER',
  'MISSING_STANDARD_VIEWS',
];

export const schema = object({
  imageQuality: number().required().nullable(),
  feedback: array(string().enum(standardQaFeedbackOptions))
    .optional()
    .nullable(),
  comments: string().optional().nullable(),
});

export type EduStandardQaForm = InferType<typeof schema>;
export type DeserializedEduStandardQa = TypeOf<typeof schema>;

export function templateVersionToEntry(templateVersion: {
  id: string;
  title: string | null;
}) {
  return templateVersion
    ? { values: {}, templateVersion, id: uniqueId(templateVersion.id) }
    : null;
}

export const deserialize = (studyRef: EduStudyKey) => {
  const study = readInlineData(
    graphql`
      fragment eduStandardQa_eduStudy on EduStudy @inline {
        standardQa {
          imageQuality
          feedback
          comments
        }
      }
    `,
    studyRef,
  );

  return schema.cast({
    ...study.standardQa,
  });
};

export const serialize = (nextValue: EduStandardQaForm, studyId: string) => {
  return {
    ...nextValue,
    studyId,
  };
};
