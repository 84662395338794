import CheckMedalFilled from '@bfly/icons/CheckMedalFilled';
import Popover from '@bfly/ui2/Popover';
import RingChart, { Data } from '@bfly/ui2/RingChart';
import Text from '@bfly/ui2/Text';
import { colorVariables } from '@bfly/ui2/tailwind/colors';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

function PopoverContent({
  nComplete,
  nPartial,
  singleUser,
  total,
}: {
  nComplete: number;
  nPartial: number;
  singleUser: boolean;
  total: number;
}) {
  return (
    <div className="w-40" data-bni-id="ExamTypeSushiRollPopover">
      {singleUser ? (
        <>
          <Text as="div" variant="body-bold" color="headline">
            <FormattedMessage
              id="sushiRoll.examsCompleted"
              defaultMessage="Exams Completed"
            />
          </Text>
          <Text as="div">
            <FormattedMessage
              id="sushiRoll.partOfWhole"
              defaultMessage="({part} of {whole})"
              values={{ part: Math.min(nComplete, total), whole: total }}
            />
          </Text>
        </>
      ) : (
        <>
          <Text as="div" variant="body-bold" color="headline">
            <FormattedMessage
              id="sushiRoll.usersCompletedProficiency"
              defaultMessage="Users Completed Proficiency"
            />
          </Text>
          <div>
            <Text variant="body" color="headline">
              <FormattedNumber style="percent" value={nComplete / total} />
            </Text>{' '}
            <Text variant="sm" color="subtitle">
              <FormattedMessage
                id="sushiRoll.partOutOfWhole"
                defaultMessage="({part} of {whole} Complete)"
                values={{ part: nComplete, whole: total }}
              />
            </Text>
          </div>
          <div>
            <Text variant="sm" color="subtitle">
              <em>
                <FormattedMessage
                  id="sushiRoll.inProgress"
                  defaultMessage="{nPartial} started"
                  values={{ nPartial }}
                />
              </em>
            </Text>
          </div>
          <div>
            <Text variant="sm" color="subtitle">
              <em>
                <FormattedMessage
                  id="sushiRoll.notStarted"
                  defaultMessage="{nNotStarted} not started"
                  values={{ nNotStarted: total - nComplete - nPartial }}
                />
              </em>
            </Text>
          </div>
        </>
      )}
    </div>
  );
}

export interface SushiRollProps extends React.ComponentPropsWithoutRef<'div'> {
  // the color for the center of the sushi roll
  color: string;
  // the amount of completions towards filling the sushi roll, shown in white
  nComplete: number;
  // the amount of partial completions filling the sushi roll, shown in light grey
  nPartial: number;
  // set to true if the sushi roll is displaying stats for a single user
  singleUser: boolean;
  // the amount needed to fill the sushi roll
  total: number;
  // the icon shown in the center of the sushi roll
  icon?: ReactElement;
  // the title shown below the sushi roll
  sushiRollTitle: string | null | undefined;
}

/**
 * A component for rendering a Sushi Roll
 * @returns
 */
function SushiRoll({
  color,
  nComplete,
  nPartial,
  singleUser,
  total,
  icon,
  sushiRollTitle,
  ...props
}: SushiRollProps): JSX.Element {
  // If the completed count surpasses total, just report the total.
  const axisWidth = 13;
  let data: Data[];
  if (total <= nComplete) {
    data = [
      {
        value: total,
        width: axisWidth,
        color: colorVariables.white,
      },
    ];
  } else if (nComplete === 0) {
    if (nPartial === 0) {
      data = [];
    } else {
      data = [
        {
          value: nPartial,
          width: axisWidth,
          color: colorVariables.grey[50],
        },
      ];
    }
  } else {
    data = [
      { value: nComplete, width: axisWidth, color: colorVariables.white },
      {
        value: nPartial,
        width: axisWidth,
        color: colorVariables.grey[50],
      },
    ];
  }

  return (
    <Popover.Trigger
      trigger="passive"
      placement="top"
      variant="dark"
      popover={
        <PopoverContent
          nComplete={nComplete}
          nPartial={nPartial}
          singleUser={singleUser}
          total={total}
        />
      }
    >
      <div
        {...props}
        className={clsx(props.className, 'flex flex-col items-center w-20')}
      >
        <div className="relative">
          <RingChart
            data={data.filter(({ value }) => !!value)}
            radius={31}
            axisColor={colorVariables.grey[80]}
            axisFill={color}
            axisWidth={axisWidth}
            total={total}
            className="w-20"
          >
            {icon}
          </RingChart>
          {total <= nComplete && total > 0 && (
            <CheckMedalFilled
              className="absolute top-0 left-0"
              height={24}
              width={24}
            />
          )}
        </div>
        <Text
          className="mt-1 text-center line-clamp-2 w-24 break-normal"
          variant="sm"
        >
          {sushiRollTitle}
        </Text>
      </div>
    </Popover.Trigger>
  );
}

export default SushiRoll;
