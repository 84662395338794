import FeatureFlags from 'config/FeatureFlags';
import HttpError from 'found/HttpError';
import { graphql, readInlineData } from 'react-relay';

import {
  Prerender,
  RelayRouteRenderArgs,
  RouteRenderFetchedArgs,
} from 'components/Route';
import { hasPermissionWithState } from 'utils/viewerContext';

import { RouteAccessControl_tenant$key as TenantKey } from './__generated__/RouteAccessControl_tenant.graphql';
import { Permission, PermissionLevel } from './viewerPermissions';

type BooleanFeatureFlags = {
  [K in keyof FeatureFlags as FeatureFlags[K] extends boolean
    ? K
    : never]: FeatureFlags[K];
};

type FlagChecker = (ldClient: RouteRenderFetchedArgs['ldClient']) => boolean;

/**
 * Check if a boolean feature flag resolves to `true` otherwise 404
 * @param key A boolean feature flag
 */
export function checkFlagsOr404(key: keyof BooleanFeatureFlags): Prerender;
/**
 * For complex or multiple flag checking provider a function that takes the LD client and
 * returns a boolean value: `true` allows the route, any falsey value will trigger a 404
 * @param flagChecker A function to check for valid flag state
 */
export function checkFlagsOr404(flagChecker: FlagChecker): Prerender;
export function checkFlagsOr404(
  flagCheckerOrFlag: FlagChecker | keyof BooleanFeatureFlags,
): Prerender {
  const flagChecker: FlagChecker =
    typeof flagCheckerOrFlag === 'function'
      ? flagCheckerOrFlag
      : (ldClient) => ldClient.variation(flagCheckerOrFlag);

  return ({ props, ldClient }) => {
    if (props && ldClient && !flagChecker(ldClient!)) {
      if (!bflyConfig.IS_PROD)
        console.error('checkFlagsOr404Fail', flagCheckerOrFlag);
      throw new HttpError(404);
    }
  };
}

const tenantFragment = graphql`
  fragment RouteAccessControl_tenant on TenantInterface @inline {
    ... on Domain {
      subdomainLabel
    }
    ... on Organization {
      slug
    }
  }
`;

/**
 * Ensures that a route is not displayed for an Organization
 * when in a Domain. This check depends on `organizationSlug` param
 * as the indicator of the route scoping. If a slug is present AND the
 * tenant is a Domain, the route will 404
 */
export function checkIsScopedUnderTenantOr404({
  props,
  match,
}: RelayRouteRenderArgs<{ tenant: TenantKey | null }>) {
  if (!props || !match) return;
  const tenant = readInlineData(tenantFragment, props.tenant);

  if (!tenant?.slug && match.params.organizationSlug) {
    throw new HttpError(404);
  }
}

/**
 * Once a route is fully loaded then check if it should 404
 * @param shouldBe404 A function that takes the prerender props and if it returns true, the route will 404'd
 */
export function isFetched404(
  shouldBe404: (prerenderProps: RouteRenderFetchedArgs) => any,
): Prerender {
  return (prerenderProps) => {
    const { match, props, ldClient, viewerContext } = prerenderProps;
    if (!props || !match || !ldClient || !viewerContext) return;
    if (
      shouldBe404({
        ...prerenderProps,
        props,
        ldClient,
        viewerContext,
      })
    ) {
      throw new HttpError(404);
    }
  };
}

/**
 * Check if the viewer has a permission at the tenant level
 */
export function checkTenantPermission(
  permissionName: Permission,
  requestedLevel: PermissionLevel,
): Prerender {
  return isFetched404(
    ({ viewerContext }) =>
      !hasPermissionWithState(
        viewerContext,
        permissionName,
        requestedLevel,
        'tenant',
      ),
  );
}

/**
 * Check if the viewer has a permission at the specified level
 */
export function checkRoutePermission<P extends Record<string, any> = any>(
  permissionName: Permission,
  requestedLevel: PermissionLevel,
  permissionsOn: 'domain' | 'organization' | 'tenant' = 'tenant',
): Prerender<P> {
  return isFetched404(
    ({ viewerContext }) =>
      !hasPermissionWithState(
        viewerContext,
        permissionName,
        requestedLevel,
        permissionsOn,
      ),
  );
}
