/**
 * @generated SignedSource<<5037f42d0de2a019027fa45f74dc9005>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type account_UserSetupPage_Query$variables = {};
export type account_UserSetupPage_Query$data = {
  readonly specialties: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserSetupPage_specialties">;
  } | null> | null;
  readonly viewer: {
    readonly setupAt: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"UserSetupPage_viewer">;
  } | null;
};
export type account_UserSetupPage_Query = {
  response: account_UserSetupPage_Query$data;
  variables: account_UserSetupPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "setupAt",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "account_UserSetupPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSetupPage_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Specialty",
        "kind": "LinkedField",
        "name": "specialties",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSetupPage_specialties"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "account_UserSetupPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Specialty",
            "kind": "LinkedField",
            "name": "specialty",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "specialtyFreeform",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceOfWork",
            "kind": "LinkedField",
            "name": "placeOfWork",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "placeOfWorkFreeform",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inferredCountry",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasHumanMembership",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasVeterinaryMembership",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Specialty",
        "kind": "LinkedField",
        "name": "specialties",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f53333c52b58e4e47fdca4b3157b31d",
    "id": null,
    "metadata": {},
    "name": "account_UserSetupPage_Query",
    "operationKind": "query",
    "text": "query account_UserSetupPage_Query {\n  viewer {\n    setupAt\n    ...UserSetupPage_viewer\n    id\n  }\n  specialties {\n    ...UserSetupPage_specialties\n  }\n}\n\nfragment SpecialtyInput_specialties on Specialty {\n  key\n  message\n}\n\nfragment SpecialtyInput_viewer on Viewer {\n  hasHumanMembership\n  hasVeterinaryMembership\n}\n\nfragment UserDataFieldSet_specialties on Specialty {\n  ...SpecialtyInput_specialties\n}\n\nfragment UserDataFieldSet_viewer on Viewer {\n  inferredCountry\n  ...SpecialtyInput_viewer\n}\n\nfragment UserSetupPage_specialties on Specialty {\n  ...UserDataFieldSet_specialties\n}\n\nfragment UserSetupPage_viewer on Viewer {\n  ...userData_viewer\n  ...UserDataFieldSet_viewer\n  setupAt\n  domain {\n    __typename\n    id\n  }\n}\n\nfragment userData_viewer on Viewer {\n  profile {\n    name\n    id\n  }\n  specialty {\n    key\n    message\n  }\n  specialtyFreeform\n  phoneNumber\n  placeOfWork {\n    id\n    name\n  }\n  placeOfWorkFreeform\n}\n"
  }
};
})();

(node as any).hash = "b228e6c1c4f9896a0c3b8efcce94d279";

export default node;
