/**
 * @generated SignedSource<<5a4a8dd732559ee2090ece2a98ee6bff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StoragePagedArchivePage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PagedArchivePage_viewer">;
  readonly " $fragmentType": "StoragePagedArchivePage_viewer";
};
export type StoragePagedArchivePage_viewer$key = {
  readonly " $data"?: StoragePagedArchivePage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StoragePagedArchivePage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoragePagedArchivePage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PagedArchivePage_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "309970876bcb110c3d53798fc74d92e8";

export default node;
