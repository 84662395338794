import Dropdown from '@bfly/ui2/Dropdown';
import { PickerCaret } from '@bfly/ui2/Pickers';
import Text from '@bfly/ui2/Text';
import useFocusManager from '@restart/hooks/useFocusManager';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useField } from 'react-formal';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { CheckGroupSelectButtonLabel } from 'components/CheckGroupSelectButton';
import { DropdownMultiselect } from 'components/MultiselectSelectButton';
import useSuggestedExamTypesGroups from 'hooks/useSuggestedExamTypesGroups';
import Analytics from 'utils/Analytics';

import { SplitStudyExamTypeDropdown_study$data as Study } from './__generated__/SplitStudyExamTypeDropdown_study.graphql';

const suggestedGroupTitle = (
  <FormattedMessage
    id="SplitStudyExamTypeDropdown.suggested"
    defaultMessage="Suggested"
  />
);
const remainingGroupTitle = (
  <FormattedMessage
    id="SplitStudyExamTypeDropdown.othertypes"
    defaultMessage="Exam types"
  />
);

interface Props {
  study: Study;
  id?: string;
  className?: string;
  fieldName?: string;
}

function SplitStudyExamTypeDropdown({
  study,
  className,
  id,
  fieldName = 'examTypeIds',
}: Props) {
  const [show, setShow] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const [{ value: selectedExamTypeIds }, { update: setSelectedExamTypeIds }] =
    useField(fieldName);
  const { groupBy, examTypes } = useSuggestedExamTypesGroups(
    study,
    suggestedGroupTitle,
    remainingGroupTitle,
  );
  const examTypeName = examTypes.reduce((acc, next) => {
    acc[next.id] = next.name;
    return acc;
  }, {});
  const selectedExamTypes = ((selectedExamTypeIds as string[]) || []).map(
    (etid) => ({
      id: etid,
      name: examTypeName[etid],
    }),
  );

  const focusHandlers = useFocusManager({
    isDisabled: () => false,
    onChange: (focused) => {
      if (!focused && show) setShow(false);
    },
  });

  const handleChange = (
    values: { id: string; name: string | null }[],
    meta,
  ) => {
    const { action } = meta;
    if (action === 'insert') {
      // values has the latest item at the end but the backend sticks them in front, reorder
      values = [values.pop()!, ...values];
      setSelectedExamTypeIds(values.map((v) => v.id));
    }
    if (action === 'remove') {
      values.pop();
      setSelectedExamTypeIds(values.map((v) => v.id));
    }
    Analytics.track('splitStudyExamTypesChanged', {
      studyId: study.id,
      organizationId: study.organization?.id,
      action,
      examTypeIds: [values.map((v) => v.id)],
    });
  };

  const hasValue = selectedExamTypeIds?.length > 0;

  useEffect(() => {
    if (show) {
      // the first open the dropdown doesn't exist so give it a tick to render
      requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }
  }, [show]);

  const fixOverflows = css`
    :global(.rw-multiselect-taglist) {
      @apply max-w-full truncate;
    }
    :global(.rw-multiselect-tag) {
      @apply max-w-full truncate;
    }
    :global(.rw-multiselect-tag-label) {
      @apply block leading-[3.2rem] max-w-full truncate;
    }
    :global(.rw-list-option) {
      @apply max-w-full truncate;
    }
  `;

  return (
    <Dropdown
      show={show}
      onToggle={setShow}
      className="group"
      {...focusHandlers}
    >
      <Dropdown.Toggle
        data-bni-id="SplitStudyExamTypeDropdown"
        className={clsx(
          className,
          'bg-grey-80 hover:filter-none focus:ring text-left',
          show && 'ring',
        )}
        id={id}
        variant={{ type: 'text', primary: 'var(--bni-white)' }}
      >
        {hasValue ? (
          <CheckGroupSelectButtonLabel
            show={show}
            dataItems={selectedExamTypes}
            renderValue={(i) => i.item!.name}
            renderOverflowItem={(i) => i.item!.name}
          />
        ) : (
          <Text
            color="subtitle"
            css="text-overflow: ellipsis; overflow: auto;"
          >
            <FormattedMessage
              id="SplitStudyExamTypeDropdown.placeholder"
              defaultMessage="Add exam types…"
            />
          </Text>
        )}
        <PickerCaret className="ml-2" />
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark" placement="bottom" className="w-full">
        <DropdownMultiselect
          data={examTypes}
          ref={inputRef}
          dataKey="id"
          textField="name"
          variant="secondary"
          menuVariant="dark"
          placeholder={defineMessage({
            id: 'SplitStudyExamTypeDropdown.searchPlaceholder',
            defaultMessage: 'Search…',
          })}
          groupBy={groupBy}
          value={selectedExamTypes}
          onChange={(values, meta) => handleChange(values || [], meta)}
          className={fixOverflows}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default createFragmentContainer(SplitStudyExamTypeDropdown, {
  study: graphql`
    fragment SplitStudyExamTypeDropdown_study on Study {
      ...useSuggestedExamTypesGroups_study
      id
      organization {
        id
        slug
      }
      examTypes {
        id
        name
      }
    }
  `,
});
