/**
 * @generated SignedSource<<b1ab9556eace4c31b9a3fdd5a277ade3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyDownloadPdfModal_study$data = {
  readonly id: string;
  readonly numImages: number | null;
  readonly practiceType: StudyPracticeType | null;
  readonly worksheets: ReadonlyArray<{
    readonly __typename: "Worksheet";
  } | null> | null;
  readonly " $fragmentType": "StudyDownloadPdfModal_study";
};
export type StudyDownloadPdfModal_study$key = {
  readonly " $data"?: StudyDownloadPdfModal_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyDownloadPdfModal_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyDownloadPdfModal_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksheet",
      "kind": "LinkedField",
      "name": "worksheets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "22dad7389a4e3b84ce4404d27dfae2ca";

export default node;
