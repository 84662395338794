import Heading from '@bfly/ui2/Heading';
import Popover from '@bfly/ui2/Popover';
import RingChart, {
  Data as BaseData,
  RingChartProps,
} from '@bfly/ui2/RingChart';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import { colorVariables } from '@bfly/ui2/tailwind/colors';
import clsx from 'clsx';
import { PropsWithChildren, ReactNode, useState } from 'react';
import {
  FormatNumberOptions,
  FormattedMessage,
  FormattedNumber,
  MessageDescriptor,
} from 'react-intl';

import SkeletonCard from 'components/SkeletonCard';

export type RingChartData = (BaseData & {
  title: string;
})[];

type RingChartOptions = Partial<
  Pick<
    RingChartProps,
    | 'spacerColor'
    | 'axisColor'
    | 'spacerWidth'
    | 'spacerGap'
    | 'radius'
    | 'axisWidth'
    | 'axisShadow'
    | 'className'
  >
>;

function messageOrLabel(props: {
  message?: MessageDescriptor;
  label?: ReactNode;
}) {
  return (
    (props.message && <FormattedMessage {...props.message} />) || props.label
  );
}

function popoverContentDefault({
  data,
  hoverIndex,
  total,
}: {
  data: RingChartData;
  hoverIndex: number | null;
  total: number;
}) {
  if (hoverIndex === null || !data[hoverIndex]) return null;

  const { value, title, color } = data[hoverIndex];

  return (
    <div key={title} className="flex space-x-2">
      <Text as="div" style={{ color }} variant="sm-bold" className="-mb-1">
        <FormattedNumber value={value / total} style="percent" />
      </Text>
      <Text as="div" color="subtitle" variant="sm-bold" className="-mb-1">
        {title}
      </Text>
    </div>
  );
}

export type Props = {
  id: string;
  header?: {
    value: number;
    label?: ReactNode;
    message?: MessageDescriptor;
    style?: FormatNumberOptions['style'];
  };
  chartInside?: {
    message: MessageDescriptor;
    color?: string;
  };
  loading?: boolean;
  data: RingChartData;
  total: number;
  chartOptions?: RingChartOptions;
  popoverContent?: (props: {
    data: RingChartData;
    hoverIndex: number | null;
    total: number;
  }) => ReactNode;
  className?: string;
  percentages?: RingChartData;
};

export default function RingChartCard({
  id,
  header,
  loading: busy,
  chartInside,
  data,
  children,
  chartOptions,
  total,
  popoverContent = popoverContentDefault,
  className,
  percentages = [],
}: PropsWithChildren<Props>) {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  return (
    <SkeletonCard
      busy={busy}
      className={clsx(
        className,
        'flex-row space-x-2 px-4 pt-4 pb-5 items-center justify-between',
      )}
    >
      <Section className="contents">
        <div className="mr-5 self-start">
          {header && (
            <Heading flush>
              <Text as="div" variant="display-sm" data-bni-id={`${id}Header`}>
                <FormattedNumber
                  value={header.value}
                  style={header.style || 'decimal'}
                />
              </Text>
              <div>{messageOrLabel(header)}</div>
            </Heading>
          )}
          {(percentages.length || children) && (
            <hr className="my-2 border-divider border-b" />
          )}
          <div data-bni-id={`${id}Body`}>
            {percentages && (
              <div className="grid gap-x-2 grid-cols-[max-content_auto]">
                {percentages?.map(({ title, color, value }, index) => (
                  <div
                    key={`${index * 1}key`}
                    data-bni-id={`${id}Percentage-${index}`}
                    className="contents"
                  >
                    <Text style={{ color }} align="right">
                      <FormattedNumber style="percent" value={value} />
                    </Text>
                    <Text>{title}</Text>
                  </div>
                ))}
              </div>
            )}
            {children}
          </div>
        </div>
        <Popover.Trigger
          trigger="passive"
          placement="top"
          variant="dark"
          show={!!popoverContent && hoverIndex !== null}
          popover={popoverContent?.({ data, hoverIndex, total })}
        >
          <div>
            <RingChart
              spacerColor={colorVariables.grey[50]}
              axisColor={colorVariables.grey[80]}
              spacerWidth={8}
              spacerGap={8}
              radius={40}
              axisWidth={30}
              axisShadow={1}
              className="w-24"
              total={total}
              {...chartOptions}
              data={data.filter(({ value }) => !!value)}
              onHoverIndex={setHoverIndex}
            >
              {chartInside && (
                <Text style={{ color: chartInside.color }} variant="sm">
                  <div className="mb-1 leading-[100%]">
                    <FormattedMessage {...chartInside.message} />
                  </div>
                </Text>
              )}
            </RingChart>
          </div>
        </Popover.Trigger>
      </Section>
    </SkeletonCard>
  );
}
