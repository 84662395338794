import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import JobSyncStatus from './JobSyncStatus';
import type { PacsSyncStatus_job$data as Job } from './__generated__/PacsSyncStatus_job.graphql';

interface Props {
  job: Job;
}

function PacsSyncStatus({ job }: Props) {
  return <JobSyncStatus job={job} server={job.pacs!} />;
}

export default createFragmentContainer(PacsSyncStatus, {
  job: graphql`
    fragment PacsSyncStatus_job on PacsPushJob {
      completedAt
      pacs {
        name
        paused
      }
      status
      isOutdated
    }
  `,
});
