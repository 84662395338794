/**
 * @generated SignedSource<<cebb2adc66e8f89e1bb79b033663d083>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NextContentPopout_educationContent$data = {
  readonly handle: string | null;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Thumbnail_image">;
  readonly " $fragmentType": "NextContentPopout_educationContent";
};
export type NextContentPopout_educationContent$key = {
  readonly " $data"?: NextContentPopout_educationContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"NextContentPopout_educationContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NextContentPopout_educationContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Thumbnail_image"
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};

(node as any).hash = "e5b7f734331b8c6caeb91a7ce36a1279";

export default node;
