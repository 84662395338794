/**
 * @generated SignedSource<<518486fbaabc75641f7bc4498973e2fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BulkCreateDomainUsersInput = {
  clientMutationId?: string | null;
  domainId: string;
  users: ReadonlyArray<BulkInputDomainUser | null>;
};
export type BulkInputDomainUser = {
  canFinalize?: boolean | null;
  canQa?: boolean | null;
  dicomFields?: ReadonlyArray<BulkInputDomainUserDicomField> | null;
  email: string;
  integrationConfigs?: ReadonlyArray<BulkInputDomainUserIntegrationConfig> | null;
  integrationDisplayNameFirst?: string | null;
  integrationDisplayNameLast?: string | null;
  integrationDisplayNameMiddle?: string | null;
  isNurse?: boolean | null;
  roleId?: string | null;
};
export type BulkInputDomainUserIntegrationConfig = {
  integrationId: string;
  interfaceCode: string;
};
export type BulkInputDomainUserDicomField = {
  templateId: string;
  value: string;
};
export type BulkDomainMemberUploadModalMutation$variables = {
  input: BulkCreateDomainUsersInput;
};
export type BulkDomainMemberUploadModalMutation$data = {
  readonly bulkCreateDomainUsers: {
    readonly failures: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"BulkUploadResults_failures">;
    }>;
    readonly undeletedUsers: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"BulkUploadResults_undeletedUsers">;
        } | null;
      } | null> | null;
    } | null;
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"DomainMemberSettingsPage_domainUser">;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type BulkDomainMemberUploadModalMutation = {
  response: BulkDomainMemberUploadModalMutation$data;
  variables: BulkDomainMemberUploadModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DomainUserEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DomainUser",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canQa",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canFinalize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isNurse",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipRole",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserIntegrationConfig",
            "kind": "LinkedField",
            "name": "integrationConfigs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Ehr",
                "kind": "LinkedField",
                "name": "ehr",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "interfaceCode",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserDicomField",
            "kind": "LinkedField",
            "name": "dicomFields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainDicomFieldTemplate",
                "kind": "LinkedField",
                "name": "dicomFieldTemplate",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "integrationDisplayNameFirst",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "integrationDisplayNameMiddle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "integrationDisplayNameLast",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkDomainMemberUploadModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkCreateDomainUsersPayload",
        "kind": "LinkedField",
        "name": "bulkCreateDomainUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DomainMemberSettingsPage_domainUser"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BulkDomainUserCreateFailure",
            "kind": "LinkedField",
            "name": "failures",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BulkUploadResults_failures"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserConnection",
            "kind": "LinkedField",
            "name": "undeletedUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "BulkUploadResults_undeletedUsers"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkDomainMemberUploadModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkCreateDomainUsersPayload",
        "kind": "LinkedField",
        "name": "bulkCreateDomainUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BulkDomainUserCreateFailure",
            "kind": "LinkedField",
            "name": "failures",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserConnection",
            "kind": "LinkedField",
            "name": "undeletedUsers",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ef3da1f061d4bc85359164fe06889b1",
    "id": null,
    "metadata": {},
    "name": "BulkDomainMemberUploadModalMutation",
    "operationKind": "mutation",
    "text": "mutation BulkDomainMemberUploadModalMutation(\n  $input: BulkCreateDomainUsersInput!\n) {\n  bulkCreateDomainUsers(input: $input) {\n    users {\n      edges {\n        node {\n          id\n          ...DomainMemberSettingsPage_domainUser\n        }\n      }\n    }\n    failures {\n      ...BulkUploadResults_failures\n    }\n    undeletedUsers {\n      edges {\n        node {\n          id\n          ...BulkUploadResults_undeletedUsers\n        }\n      }\n    }\n  }\n}\n\nfragment BulkUploadResults_failures on BulkDomainUserCreateFailure {\n  code\n  email\n}\n\nfragment BulkUploadResults_undeletedUsers on DomainUser {\n  id\n  type\n  email\n  profile {\n    name\n    id\n  }\n  ...domainUser_defaultValue\n  ...DomainMemberDetailFieldSet_domainUser\n  ...DomainMemberDeleteFieldSet_domainUser\n}\n\nfragment DomainMemberDeleteFieldSet_domainUser on DomainUser {\n  id\n  profile {\n    name\n    id\n  }\n}\n\nfragment DomainMemberDetailFieldSet_domainUser on DomainUser {\n  id\n  email\n  type\n  canQa\n  canFinalize\n  isNurse\n  integrationDisplayNameFirst\n  integrationDisplayNameMiddle\n  integrationDisplayNameLast\n}\n\nfragment DomainMemberSettingsPage_domainUser on DomainUser {\n  id\n  type\n  email\n  profile {\n    name\n    id\n  }\n  ...domainUser_defaultValue\n  ...DomainMemberDetailFieldSet_domainUser\n  ...DomainMemberDeleteFieldSet_domainUser\n}\n\nfragment domainUser_defaultValue on DomainUser {\n  type\n  email\n  canQa\n  canFinalize\n  isNurse\n  role {\n    id\n  }\n  integrationConfigs {\n    ehr {\n      id\n    }\n    interfaceCode\n  }\n  dicomFields {\n    dicomFieldTemplate {\n      id\n    }\n    value\n  }\n  integrationDisplayNameFirst\n  integrationDisplayNameMiddle\n  integrationDisplayNameLast\n}\n"
  }
};
})();

(node as any).hash = "0c5a9724236b9bb71856cc46d887ef38";

export default node;
