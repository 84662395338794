import captureException from '@bfly/utils/captureException';
import ReactDOM from 'react-dom';

import getClosestLocale, { DEFAULT_LOCALE } from 'utils/getClosestLocale';
import getDomainSubdomainLabel from 'utils/getDomainSubdomainLabel';
import getUrlSearchParam from 'utils/getUrlSearchParam';
import regionApiUrls from 'utils/regionApiUrls';
import getGraphqlUpstreamKey from 'utils/regionKeys';

import polyfills from './additional-polyfills';
import loadScript from './utils/loadScript';

async function setApiUpstream() {
  const subdomainLabel = getDomainSubdomainLabel();
  const region = getUrlSearchParam('region');
  const graphqlUpstreamKey = getGraphqlUpstreamKey(subdomainLabel);

  // Users who log into public cloud via the accept-invite flow
  // need to have their API url set for them based on the region=<region>
  // param in the the accept invite link. Public cloud org invites
  // are now supported across regions.

  // Users who are resetting password from either public cloud or private cloud will go through this flow below,
  // they share the same reset password page,
  // and subdomainLabel won't be found on the password reset page regardless of the public or private cloud.
  if (
    !subdomainLabel &&
    region &&
    (globalThis.location.pathname?.includes('accept-invite') ||
      globalThis.location.pathname?.includes('verify-email-change') ||
      globalThis.location.pathname?.includes('reset-password'))
  ) {
    const apiUrl = await regionApiUrls.forRegion(region);
    if (apiUrl) {
      sessionStorage.setItem(graphqlUpstreamKey, apiUrl);
      globalThis.bflyConfig.GRAPHQL_UPSTREAM = apiUrl;
    }
  } else if (
    !subdomainLabel ||
    !globalThis.bflyConfig.ROUTER_UPSTREAM ||
    // @ts-ignore
    !!window.cypressIntegrationTest
  ) {
    return;
  }

  try {
    let apiUrl = sessionStorage.getItem(graphqlUpstreamKey);
    if (apiUrl) {
      globalThis.bflyConfig.GRAPHQL_UPSTREAM = apiUrl;
      return;
    }

    const resp = await fetch(
      // FIXME: The new relic snippet barfs on a URL object, we need to update it
      new URL(
        `/domains/${subdomainLabel}`,
        globalThis.bflyConfig.ROUTER_UPSTREAM,
      ).toString(),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (!resp.ok) return;

    ({ apiUrl } = await resp.json());

    if (apiUrl) {
      sessionStorage.setItem(graphqlUpstreamKey, apiUrl);
      globalThis.bflyConfig.GRAPHQL_UPSTREAM = apiUrl;
    }
  } catch (err: any) {
    captureException(err);
  }
}

async function loadTranslations(): Promise<
  [string, Record<string, any> | undefined]
> {
  const locale = getClosestLocale();

  if (locale === DEFAULT_LOCALE) {
    return [DEFAULT_LOCALE, undefined];
  }
  const url = `${window.location.origin}${__webpack_public_path__}messages.${locale}.json`;

  try {
    const resp = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!resp.ok) return [locale, undefined];
    const json: Record<string, string> = await resp.json();
    return [locale, json];
  } catch (err: any) {
    captureException(err);
    return [locale, undefined];
  }
}

Promise.all([
  loadTranslations(),
  setApiUpstream(),
  polyfills,
  loadScript('/static/newrelic-agent.js'),
  loadScript('/static/segment.js'),
]).then(async ([[locale, messages]]) => {
  const { default: createClientApplication } = await import(
    /* webpackMode: "eager" */
    './createClientApplication'
  );

  ReactDOM.render(
    createClientApplication(locale, messages),
    document.getElementById('app'),
  );
});
