import CrayonIcon from '@bfly/icons/Crayon';
import Link from '@bfly/ui2/Link';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAdminRoutes } from 'routes/admin';
import { usePermissions } from 'utils/viewerContext';

import { useVariation } from './LaunchDarklyContext';

function HistoricalProficiencyLink({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  const routes = useAdminRoutes();
  const { hasBasicPermission } = usePermissions();
  const canUseHistoricalProficiency = useVariation('historical-proficiency');

  const hasCredentialManagementPermission = hasBasicPermission(
    'credentialManagement',
  );

  const hasPermission =
    canUseHistoricalProficiency && hasCredentialManagementPermission;

  if (!hasPermission) return null;

  return (
    <Link
      data-bni-id="HistoricalProficiencyLink"
      to={routes.historicalProficiency()}
      className={className}
    >
      <Tooltip.Trigger
        id="historicalProficiencyInfo"
        placement="bottom"
        tooltip={
          <Text as="div" className="text-center">
            <FormattedMessage
              id="HistoricalProficiencyLink.tooltip"
              defaultMessage="Enter historical proficiency data from outside Butterfly"
            />
          </Text>
        }
      >
        <div className="flex">
          {children || <CrayonIcon className="text-headline" height={18} />}
        </div>
      </Tooltip.Trigger>
    </Link>
  );
}

export default HistoricalProficiencyLink;
