import Popover, { Placement } from '@bfly/ui2/Popover';
import usePopper from '@bfly/ui2/usePopper';
import useCallbackRef from '@restart/hooks/useCallbackRef';
import React from 'react';

const OFFSETS = {
  top: { start: 'left', end: 'right' },
  bottom: { start: 'left', end: 'right' },
  left: { start: 'top', end: 'bottom' },
  right: { start: 'top', end: 'bottom' },
};

interface Props {
  target: HTMLElement | null;
  header?: React.ReactNode;
  message: React.ReactNode;
  strategy?: 'fixed' | 'absolute';
  onDismiss: () => void;
  placement?: Placement;
}

function ExperiencePopover({
  message,
  header,
  target,
  placement = 'bottom',
  strategy,
  onDismiss,
  ...props
}: Props) {
  const [arrow, attachArrow] = useCallbackRef<HTMLElement>();
  const [popper, attachPopper] = useCallbackRef<HTMLElement>();

  const { styles, attributes, state } = usePopper(target, popper, {
    placement,
    strategy,
    modifiers: [
      { name: 'flip', enabled: true },
      { name: 'preventOverflow', enabled: true, options: { padding: 12 } },
      { name: 'offset', options: { offset: [0, 12] } },
      {
        name: 'arrow',
        enabled: true,
        options: { element: arrow, padding: 8 },
      },
    ],
  });

  const [position, modifier] = placement.split('-');
  const arrowCenterOffset = state?.modifiersData.arrow?.centerOffset ?? 0;

  return (
    <Popover
      {...props}
      ref={attachPopper}
      variant="primary"
      hidden={!state}
      placement={placement}
      style={styles.popper as any}
      {...attributes.popper}
      arrowProps={{
        ref: attachArrow,
        style:
          arrowCenterOffset === 0
            ? (styles.arrow as any)
            : { [OFFSETS[position][modifier]]: 8 },
        ...attributes.arrow,
      }}
    >
      <Popover.CloseButton
        onClick={onDismiss}
        data-bni-id="ExperienceCloseButton"
      />
      {header && <Popover.Header>{header}</Popover.Header>}
      {message}
    </Popover>
  );
}

ExperiencePopover.Header = Popover.Header;

export default ExperiencePopover;
