import Redirect from 'found/Redirect';

export default class RenameRedirect extends Redirect {
  constructor({ from, to }) {
    super({
      from,
      to: ({ location, params }) => ({ ...location, name: to, params }),
    });
  }
}
