/**
 * @generated SignedSource<<5ed1d9b51bc6ad0b2d448a7d8d5bc5b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBar_userProfile$data = {
  readonly handle: string | null;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarList_userProfile" | "SearchBarTags_userProfile">;
  readonly " $fragmentType": "SearchBar_userProfile";
};
export type SearchBar_userProfile$key = {
  readonly " $data"?: SearchBar_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBar_userProfile">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "Avatar_userProfile"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBar_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "SearchBarTags_userProfile",
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "SearchBarList_userProfile",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};
})();

(node as any).hash = "50bb51cc1ca8e007ccfd2fcbd2ddc503";

export default node;
