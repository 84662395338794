/**
 * @generated SignedSource<<1bfdb165af3cb3aa6422e0afabc563ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EhrOruJobStatus = "CANCELED" | "COMPLETE" | "FAILED" | "PENDING" | "RETRYING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EhrSyncStatus_job$data = {
  readonly completedAt: string | null;
  readonly ehr: {
    readonly name: string | null;
    readonly paused: boolean | null;
  } | null;
  readonly isOutdated: boolean | null;
  readonly status: EhrOruJobStatus | null;
  readonly " $fragmentType": "EhrSyncStatus_job";
};
export type EhrSyncStatus_job$key = {
  readonly " $data"?: EhrSyncStatus_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"EhrSyncStatus_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EhrSyncStatus_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Ehr",
      "kind": "LinkedField",
      "name": "ehr",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paused",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOutdated",
      "storageKey": null
    }
  ],
  "type": "EhrOruJob",
  "abstractKey": null
};

(node as any).hash = "ab168067a71401c4572c550b758c1085";

export default node;
