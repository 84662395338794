/**
 * @generated SignedSource<<f323047e29809b3c70e6b84b9b3b5e40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorksheetDisplay_worksheet$data = {
  readonly templateVersion: {
    readonly sections: ReadonlyArray<{
      readonly fieldDefinitions: ReadonlyArray<{
        readonly handle: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"TemplatedFieldListItem_fieldDefinition">;
      } | null> | null;
      readonly handle: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"WorksheetSectionFieldSet_section" | "WorksheetSection_section">;
    } | null> | null;
  } | null;
  readonly values: Record<string, any> | null;
  readonly " $fragmentType": "WorksheetDisplay_worksheet";
};
export type WorksheetDisplay_worksheet$key = {
  readonly " $data"?: WorksheetDisplay_worksheet$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetDisplay_worksheet">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorksheetDisplay_worksheet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "values",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorksheetTemplateVersion",
      "kind": "LinkedField",
      "name": "templateVersion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksheetSectionDefinition",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "fieldDefinitions",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TemplatedFieldListItem_fieldDefinition"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WorksheetSection_section"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WorksheetSectionFieldSet_section"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worksheet",
  "abstractKey": null
};
})();

(node as any).hash = "491bbb1fac0dcfdd6145f5b66feed07e";

export default node;
