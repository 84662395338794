import { defineMessages } from 'react-intl';

export default defineMessages({
  customRangeMessage: {
    id: 'dateRangePicker.rangeMessage',
    defaultMessage: 'Select range',
  },
  customOptionMessage: {
    id: 'dateRangePicker.customOptionMessage',
    defaultMessage: 'Custom date',
  },
  customDatePlaceholder: {
    id: 'dateRangePicker.datePlaceholder',
    defaultMessage: 'Date',
  },
  customHideRangeMessage: {
    id: 'dateRangePicker.hideRangeMessage',
    defaultMessage: 'Select date',
  },
});
