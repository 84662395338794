/**
 * @generated SignedSource<<7b16e14ddefba4fd0493e75c64793d4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PagedStudiesListPage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_viewer">;
  readonly " $fragmentType": "PagedStudiesListPage_viewer";
};
export type PagedStudiesListPage_viewer$key = {
  readonly " $data"?: PagedStudiesListPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PagedStudiesListPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PagedStudiesListPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "24a95d49a54dddaf0bb550b13a7fa9f4";

export default node;
