/**
 * @generated SignedSource<<06600bbfb920c322be9ce374e2ef223b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetNotificationCenterLastSeenAtInput = {
  clientMutationId?: string | null;
  latestNotificationId?: string | null;
  notificationCenterId?: string | null;
};
export type SetNotificationCenterLastSeenAtMutation$variables = {
  input: SetNotificationCenterLastSeenAtInput;
};
export type SetNotificationCenterLastSeenAtMutation$data = {
  readonly setNotificationCenterLastSeenAt: {
    readonly notificationCenter: {
      readonly numUnseenNotifications: number | null;
    } | null;
  } | null;
};
export type SetNotificationCenterLastSeenAtMutation = {
  response: SetNotificationCenterLastSeenAtMutation$data;
  variables: SetNotificationCenterLastSeenAtMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numUnseenNotifications",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetNotificationCenterLastSeenAtMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetNotificationCenterLastSeenAtPayload",
        "kind": "LinkedField",
        "name": "setNotificationCenterLastSeenAt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationCenter",
            "kind": "LinkedField",
            "name": "notificationCenter",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetNotificationCenterLastSeenAtMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetNotificationCenterLastSeenAtPayload",
        "kind": "LinkedField",
        "name": "setNotificationCenterLastSeenAt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationCenter",
            "kind": "LinkedField",
            "name": "notificationCenter",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ffe21ff9ecf4cbe2a558d3fbeb53c47",
    "id": null,
    "metadata": {},
    "name": "SetNotificationCenterLastSeenAtMutation",
    "operationKind": "mutation",
    "text": "mutation SetNotificationCenterLastSeenAtMutation(\n  $input: SetNotificationCenterLastSeenAtInput!\n) {\n  setNotificationCenterLastSeenAt(input: $input) {\n    notificationCenter {\n      numUnseenNotifications\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "564fbae24ba9cba40c187f8f763630d7";

export default node;
