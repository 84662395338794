import { defineMessages } from 'react-intl';

export default defineMessages({
  patient: {
    id: 'patient',
    defaultMessage: 'Patient',
  },
  name: {
    id: 'patient.name',
    defaultMessage: 'Name',
  },
  patientName: {
    id: 'patient.patientName',
    defaultMessage: 'Patient Name',
  },
  nameMiddle: {
    id: 'patient.nameMiddle',
    defaultMessage: 'Middle Name',
  },
  namePrefix: {
    id: 'patient.namePrefix',
    defaultMessage: 'Prefix',
  },
  nameSuffix: {
    id: 'patient.nameSuffix',
    defaultMessage: 'Suffix',
  },
  sex: {
    id: 'patient.sex',
    defaultMessage: 'Sex',
  },
  birthDate: {
    id: 'patient.birthDate',
    defaultMessage: 'DOB',
  },
  birthDatePlaceholder: {
    id: 'patient.birthDate.placeholder',
    defaultMessage: 'Date of birth',
  },
  medicalRecordNumber: {
    id: 'patient.medicalRecordNumber',
    defaultMessage: 'Patient ID',
  },
  dateOfBirthLabel: {
    id: 'patient.dateOfBirthLabel',
    defaultMessage: 'Date of Birth',
  },
  accessionNumberLabel: {
    id: 'patient.accessionNumberLabel',
    defaultMessage: 'Accession Number',
  },
  noMedicalRecordNumber: {
    id: 'patient.noMRNo',
    defaultMessage: 'No patient ID',
  },
  age: {
    id: 'patient.age',
    defaultMessage: 'Age',
  },
  addPatient: {
    id: 'headerPatientInfo.addPatient',
    defaultMessage: 'Add patient',
  },
  encounterNumber: {
    id: 'headerPatientInfo.encounterNumber',
    defaultMessage: 'Encounter Number',
  },
});

export const sexMessages = defineMessages({
  MALE: {
    id: 'patient.sex.MALE',
    defaultMessage: 'Male',
  },
  FEMALE: {
    id: 'patient.sex.FEMALE',
    defaultMessage: 'Female',
  },
  OTHER: {
    id: 'patient.sex.OTHER',
    defaultMessage: 'Other',
  },
  UNKNOWN: {
    id: 'patient.sex.UNKNOWN',
    defaultMessage: 'Unknown',
  },
});
