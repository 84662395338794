/**
 * @generated SignedSource<<3477d3da4df652ddd6446dc03cdb61fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type examPageSidebar_useSections_study$data = {
  readonly archive: {
    readonly worksheetRequiredForQa: boolean | null;
  } | null;
  readonly finalizedAt: string | null;
  readonly numWorksheets: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"examPageSidebar_useShowQa_study">;
  readonly " $fragmentType": "examPageSidebar_useSections_study";
};
export type examPageSidebar_useSections_study$key = {
  readonly " $data"?: examPageSidebar_useSections_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"examPageSidebar_useSections_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "examPageSidebar_useSections_study"
};

(node as any).hash = "ae581db9a63ce5b2dbdc0d17c29b8308";

export default node;
