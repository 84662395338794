/**
 * @generated SignedSource<<a7a347a9c604360c8f33370d22854d2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmptyArchiveScreen_organization$data = {
  readonly hasReadyStudies: boolean | null;
  readonly " $fragmentType": "EmptyArchiveScreen_organization";
};
export type EmptyArchiveScreen_organization$key = {
  readonly " $data"?: EmptyArchiveScreen_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmptyArchiveScreen_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmptyArchiveScreen_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasReadyStudies",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3960f8f1f10e68479b7f10c0db396f63";

export default node;
