import HttpError from 'found/HttpError';
import Redirect from 'found/Redirect';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import adminRoutes from 'routes/admin';
import { canAccessEnterpriseSettings } from 'utils/viewerContext';

import { domainAdminRoutes_EnterpriseSettingsPage_Query as EnterpriseSettingsPageQuery } from './__generated__/domainAdminRoutes_EnterpriseSettingsPage_Query.graphql';
import { domainAuditLogsRoute } from './routes/auditLogs';
import { domainConnectivityRoutes } from './routes/connectivity';
import credentialGroupsRoute from './routes/credentialGroups';
import dicomFieldTemplatesRoute from './routes/dicomFieldTemplates';
import domainMembersRoute from './routes/domainMembers';
import domainRolesRoute from './routes/domainRoles';
import { domainExamTypesRoutes } from './routes/examTypes';
import fleetRoute from './routes/fleet';
import historicalProficiencyRoute from './routes/historicalProficiency';
import inactivityRoute from './routes/inactivity';
import mdmRoute from './routes/mdm';
import { domainOrganizationsRoute } from './routes/organizations';
import { domainQaRoute } from './routes/qa';
import ssoRoute from './routes/sso';
import { domainStudyTagsRoute } from './routes/study-tags';
import workflowDigestsRoutes from './routes/workflowDigests';
import { domainWorksheetsRoute } from './routes/worksheets';

export const enterpriseSettings = (
  <>
    <Redirect
      from="domain/settings/*"
      to={({ location }) => location.pathname.replace('domain', 'enterprise')}
    />
    <Route<EnterpriseSettingsPageQuery>
      hideSearch
      name="enterpriseSettings"
      path="enterprise/settings"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/EnterpriseSettingsPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query domainAdminRoutes_EnterpriseSettingsPage_Query {
          viewer {
            ...EnterpriseSettingsPage_viewer
          }
        }
      `}
      prerender={(renderArgs) => {
        const { props, ldClient, viewerContext } = renderArgs;

        if (!props || !ldClient || !viewerContext) return;

        if (!canAccessEnterpriseSettings(viewerContext)) {
          throw new HttpError(404);
        }
      }}
    >
      <Redirect to={(match) => adminRoutes(match).organizations()} />
      {domainOrganizationsRoute}
      {domainMembersRoute}
      {domainRolesRoute}
      {ssoRoute}
      {fleetRoute}
      {inactivityRoute}
      {mdmRoute}
      {domainConnectivityRoutes}
      {domainStudyTagsRoute}
      {domainExamTypesRoutes}
      {credentialGroupsRoute}
      {domainQaRoute}
      {domainWorksheetsRoute}
      {dicomFieldTemplatesRoute}
      {domainAuditLogsRoute}
      {historicalProficiencyRoute}
      {workflowDigestsRoutes}
    </Route>
  </>
);
