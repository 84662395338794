/**
 * @generated SignedSource<<e3c6e4c9bfcc2ffdeffbfcf5d4d8c6ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchivePrivacyForm_organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"isLastPublicArchive_organization">;
  readonly " $fragmentType": "ArchivePrivacyForm_organization";
};
export type ArchivePrivacyForm_organization$key = {
  readonly " $data"?: ArchivePrivacyForm_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchivePrivacyForm_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchivePrivacyForm_organization",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "isLastPublicArchive_organization",
      "selections": [
        {
          "alias": "archiveConnection",
          "args": null,
          "concreteType": "ArchiveConnection",
          "kind": "LinkedField",
          "name": "__Organization_archiveConnection_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArchiveEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Archive",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isPrivate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "1f2e8314fb0e093c62eba49de13a48d5";

export default node;
