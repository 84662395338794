import CheckIcon from '@bfly/icons/CheckMarkSmall';
import TrashCanIcon from '@bfly/icons/TrashCan';
import Heading from '@bfly/ui2/Heading';
import Spinner from '@bfly/ui2/Spinner';
import getNodes from '@bfly/utils/getNodes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Errors } from 'react-formal/types';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import IconButton from 'components/IconButton';
import { useVariation } from 'components/LaunchDarklyContext';
import PlusIconButton from 'components/PlusIconButton';
import PromptButton from 'components/PromptButton';
import useDeleteWorksheet from 'hooks/useDeleteWorksheet';
import Analytics from 'utils/Analytics';

import { useSections } from '../utils/examPageSidebar';
import BLineSummaryResults from './BLineSummaryResults';
import { useExamContext, useExamSetterContext } from './ExamContext';
import ScrollSpy, { ScrollSpyHandle } from './ScrollSpy';
import WorksheetAndPrefillSelect from './WorksheetAndPrefillSelect';
import WorksheetAutosaveForm from './WorksheetAutosaveForm';
import WorksheetDisplay from './WorksheetDisplay';
import { ExamPageSidebarWorksheets_study$data as Study } from './__generated__/ExamPageSidebarWorksheets_study.graphql';

const NEW_WORKSHEET_ID = 'new-worksheet';

const addWorksheet = (
  <FormattedMessage
    id="examPageSidebar.addWorksheet"
    defaultMessage="Add worksheet"
  />
);

function WorksheetAutosaveIndicator() {
  const { saving } = useExamContext();
  const hasSavedRef = useRef(false);

  if (saving) {
    hasSavedRef.current = true;
  }

  if (!hasSavedRef.current) return null;

  return (
    <div className="text-sm text-headline normal-case flex items-center ml-3">
      {saving ? (
        <>
          <Spinner size={null} className="h-4 mr-1" />
          <FormattedMessage
            tagName="span"
            id="examPageSidebar.saving"
            defaultMessage="Saving…"
          />
        </>
      ) : (
        <>
          <CheckIcon width={12} className="text-subtitle mr-1" />
          <FormattedMessage
            tagName="span"
            id="examPageSidebar.saved"
            defaultMessage="Saved"
          />
        </>
      )}
    </div>
  );
}

function WorksheetHeading({
  worksheet,
  study,
  readOnly,
}: {
  study: Study;
  worksheet: NonNullable<Study['worksheets']>[0];
  readOnly: boolean;
}) {
  const [deleteWorksheet, deleteDetails] = useDeleteWorksheet({
    studyId: study.id,
    worksheetId: worksheet!.id,
  });
  const { setShowErrors } = useExamSetterContext();

  return (
    <Heading className="flex">
      <span>{worksheet!.templateVersion!.title}</span>
      {!readOnly && (
        <div className="ml-3 grow">
          <IconButton
            variant={null}
            className="h-5 px-1"
            placement="top"
            data-bni-id="RemoveWorksheetButton"
            title={
              <FormattedMessage
                id="examPageSidebar.removeWorksheet"
                defaultMessage="Remove worksheet"
              />
            }
            onClick={async () => {
              await deleteWorksheet();
              setShowErrors(false);
              Analytics.track('worksheetRemoved', {
                studyId: study.id,
                organizationId: study.organization?.id,
                worksheetId: worksheet!.id,
              });
            }}
            busy={deleteDetails.loading}
            disabled={deleteDetails.disabled}
            icon={<TrashCanIcon className="text-grey-50 m-0" width={14} />}
          />
        </div>
      )}
    </Heading>
  );
}

interface Props {
  study: Study;
}

function ExamPageSidebarWorksheets({ study }: Props) {
  const isPACEExam = useVariation('bft-prototype');
  const { setWorksheetsErrors } = useExamSetterContext();
  const [showWorksheetSelect, setShowWorksheetSelect] = useState(false);
  const worksheetsErrorsRef = useRef<Record<string, Errors>>({});
  const scrollSpyRef = useRef<ScrollSpyHandle>(null);

  const isDraft = !study.finalizedAt;
  const worksheets = study.worksheets!;
  const numWorksheets = worksheets.length ?? 0;
  const { showEmptyWorksheet, showWorksheets } = useSections(study);
  const noFilledWorksheet =
    showEmptyWorksheet && !isDraft && !study.worksheets?.length;
  const showLargeAddWorksheetButton =
    isDraft && !showWorksheetSelect && !numWorksheets;
  const showSmallAddWorksheetButton =
    isDraft && !!numWorksheets && !!study.archive!.multipleWorksheetsEnabled;

  const showNewWorksheet = useCallback(() => {
    scrollSpyRef.current?.scrollIntoView(NEW_WORKSHEET_ID);
  }, [scrollSpyRef]);

  useEffect(() => {
    const currentErrors = worksheetsErrorsRef.current;
    const nextErrors = {};
    worksheets.forEach((w) => {
      if (currentErrors[w!.id]) {
        nextErrors[w!.id] = currentErrors[w!.id];
      }
    });
    worksheetsErrorsRef.current = nextErrors;
    setWorksheetsErrors(nextErrors);
  }, [worksheets, setWorksheetsErrors]);

  const handleAddWorksheet = useCallback(() => {
    setShowWorksheetSelect(true);
    showNewWorksheet();
    Analytics.track('addWorksheetClicked', {
      studyId: study.id,
      organizationId: study.organization?.id,
    });
  }, [setShowWorksheetSelect, showNewWorksheet, study]);

  return (
    <>
      {isPACEExam && noFilledWorksheet && study.imageConnection ? (
        <BLineSummaryResults
          interpretations={getNodes(study.imageConnection)
            .flatMap((node) => node.interpretations)
            .flatMap((p) => (!p ? [] : [p]))}
        />
      ) : (
        <>
          <Heading
            color="subtitle"
            variant="sm-bold"
            transform="uppercase"
            className="flex items-center mt-4 mb-4 px-5"
            flush
          >
            <div className="mr-auto">
              <FormattedMessage
                tagName="span"
                id="examPageSidebar.worksheet"
                defaultMessage="Worksheet"
              />
            </div>
            {isDraft && <WorksheetAutosaveIndicator />}
            {showSmallAddWorksheetButton && (
              <div className="ml-3">
                <PlusIconButton
                  placement="top"
                  title={addWorksheet}
                  active={showWorksheetSelect}
                  data-bni-id="AddWorksheetButton"
                  onClick={handleAddWorksheet}
                />
              </div>
            )}
          </Heading>
          {noFilledWorksheet && (
            <span className="text-white mb-2 px-5">
              <FormattedMessage
                tagName="span"
                id="examPageSidebar.noWorksheet"
                defaultMessage="There was no worksheet filled out for this exam."
              />
            </span>
          )}
        </>
      )}
      {showWorksheets && (
        <>
          {showLargeAddWorksheetButton && (
            <PromptButton
              onClick={handleAddWorksheet}
              data-bni-id="AddWorksheetButton"
              className="mb-6 min-h-[55px] mx-5"
            >
              {addWorksheet}
            </PromptButton>
          )}
          <ScrollSpy ref={scrollSpyRef}>
            {worksheets.length > 1 && (
              <ScrollSpy.Tabs>
                {worksheets.map((worksheet) => (
                  <ScrollSpy.Tab
                    key={worksheet!.handle}
                    sectionId={worksheet!.handle!}
                  >
                    {worksheet!.templateVersion!.title}
                  </ScrollSpy.Tab>
                ))}
              </ScrollSpy.Tabs>
            )}
            <ScrollSpy.ScrollView>
              {worksheets.map((worksheet) => (
                <ScrollSpy.Section
                  id={worksheet!.handle!}
                  key={worksheet!.handle!}
                  data-bni-id="WorksheetSection"
                >
                  <WorksheetHeading
                    study={study}
                    worksheet={worksheet!}
                    readOnly={!isDraft}
                  />
                  {study.finalizedAt ? (
                    <WorksheetDisplay worksheet={worksheet!} />
                  ) : (
                    <WorksheetAutosaveForm
                      worksheet={worksheet!}
                      onError={(errors) => {
                        worksheetsErrorsRef.current = {
                          ...worksheetsErrorsRef.current,
                          [worksheet!.id]: errors,
                        };
                        setWorksheetsErrors(worksheetsErrorsRef.current);
                      }}
                    />
                  )}
                </ScrollSpy.Section>
              ))}
              {showWorksheetSelect && (
                <ScrollSpy.Section
                  id={NEW_WORKSHEET_ID}
                  className="scroll-mt-[8rem]"
                  data-bni-id="NewWorksheetSection"
                >
                  <WorksheetAndPrefillSelect
                    autoFocus
                    study={study}
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') {
                        setShowWorksheetSelect(false);
                      }
                    }}
                    onClickOutside={() => {
                      setShowWorksheetSelect(false);
                    }}
                    onChange={(worksheetHandle) => {
                      scrollSpyRef.current?.scrollIntoView(worksheetHandle);
                      setShowWorksheetSelect(false);
                    }}
                  />
                </ScrollSpy.Section>
              )}
            </ScrollSpy.ScrollView>
          </ScrollSpy>
        </>
      )}
    </>
  );
}

const _ = graphql`
  fragment ExamPageSidebarWorksheets on Worksheet {
    id
    handle
    templateVersion {
      title
    }
    ...WorksheetAutosaveForm_worksheet
    ...WorksheetDisplay_worksheet
  }
`;

export default createFragmentContainer(ExamPageSidebarWorksheets, {
  study: graphql`
    fragment ExamPageSidebarWorksheets_study on Study
    @argumentDefinitions(
      isPACEExam: { type: "Boolean", defaultValue: false }
    ) {
      id
      finalizedAt
      deletedAt
      archive {
        deletedAt
        multipleWorksheetsEnabled
        worksheetRequiredForQa
      }
      worksheets {
        ...ExamPageSidebarWorksheets @relay(mask: false)
      }
      organization {
        id
      }
      imageConnection @include(if: $isPACEExam) {
        edges {
          node {
            interpretations(interpretationType: PACE_BLINE) {
              ...BLineSummaryResults_interpretations
            }
          }
        }
      }
      notes
      ...WorksheetAndPrefillSelect_study
      ...examPageSidebar_useSections_study
    }
  `,
});
