import Layout from '@4c/layout';
import icon from '@bfly/assets/archive-small.svg';
import { usePrevious } from '@restart/hooks';
import useAnimationFrame from '@restart/hooks/useAnimationFrame';
import useEventCallback from '@restart/hooks/useEventCallback';
import { stylesheet } from 'astroturf';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const styles = stylesheet`

  .drag-preview {
    z-index: calc(theme('zIndex.fixed') + 1);
    position: fixed;
    pointer-events: none;
  }

  .title {
    composes: truncate text-sm text-white from global;

    flex: 1;
  }

  .container {
    box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.5);
    background-color: theme('colors.grey[80]');
    border-radius: 0.375rem;
    padding: 1rem;
  }

  .main {
    composes: container;

    max-width: 20rem;
    min-width: 8rem;
    z-index: 1;
  }

  .back {
    composes: container;
    composes: absolute w-full h-full from global;

    margin-top: 0.6rem;
    margin-left: 0.6rem;
  }

  .number {
    composes: text-sm text-white rounded-full absolute h-4 w-4 text-center bg-primary from global;

    margin-left: 1rem;
    margin-top: -0.8rem;
  }
`;

interface Props {
  title: React.ReactNode;
  numDraggedStudies: number;
  styleRef?: React.MutableRefObject<{ top: number; left: number } | null>;
}

function useRafLoop(fn) {
  const callback = useEventCallback(fn);
  const animationFrame = useAnimationFrame();

  useEffect(() => {
    function tick() {
      callback();
      animationFrame.request(tick);
    }
    animationFrame.request(tick);
  }, [callback, animationFrame]);
}

function StudyMoveItem({ title, numDraggedStudies, styleRef }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const prevStyle = usePrevious(styleRef?.current);

  useRafLoop(() => {
    const style = styleRef?.current;

    if (!ref.current) return;
    if (!style) {
      ref.current.style.visibility = 'hidden';
      return;
    }

    if (
      !prevStyle ||
      prevStyle.top !== style.top ||
      prevStyle.left !== style.left
    ) {
      ref.current.style.visibility = 'visible';
      ref.current.style.top = `${style.top}px`;
      ref.current.style.left = `${style.left}px`;
    }
  });

  return ReactDOM.createPortal(
    <Layout direction="column" className={styles.dragPreview} ref={ref as any}>
      <Layout className={styles.main}>
        <Layout align="center" className="mr-2">
          {numDraggedStudies > 1 && (
            <div className={styles.number}>{numDraggedStudies}</div>
          )}
          <img src={icon} alt="" />
        </Layout>
        <div className={styles.title}>{title}</div>
      </Layout>
      {numDraggedStudies > 1 && <div className={styles.back} />}
    </Layout>,
    document.body,
  );
}

export default StudyMoveItem;
