/**
 * @generated SignedSource<<fea40159b63c7cbe44a8dddfa585c849>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyEhrOruStatus = "CANCELED" | "COMPLETE" | "FAILED" | "NOT_SENDING" | "PENDING" | "RETRYING" | "%future added value";
export type StudyPacsPushStatus = "CANCELED" | "COMPLETE" | "FAILED" | "NOT_PUSHING" | "PENDING" | "RETRYING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudySyncStatus_study$data = {
  readonly ehrOruHasPausedJob: boolean | null;
  readonly ehrOruStatus: StudyEhrOruStatus | null;
  readonly pacsPushHasPausedJob: boolean | null;
  readonly pacsPushStatus: StudyPacsPushStatus | null;
  readonly " $fragmentType": "StudySyncStatus_study";
};
export type StudySyncStatus_study$key = {
  readonly " $data"?: StudySyncStatus_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySyncStatus_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySyncStatus_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pacsPushStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pacsPushHasPausedJob",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ehrOruStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ehrOruHasPausedJob",
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "073c76667c61871838a46f335f176de1";

export default node;
