/**
 * @generated SignedSource<<b469bf1a11770fcfd2044609aeef3632>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentBody_comment$data = {
  readonly body: string | null;
  readonly mentions: ReadonlyArray<{
    readonly handle: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"CommentMention_userProfile">;
  } | null> | null;
  readonly " $fragmentType": "CommentBody_comment";
};
export type CommentBody_comment$key = {
  readonly " $data"?: CommentBody_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentBody_comment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentBody_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "mentions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommentMention_userProfile"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyImageComment",
  "abstractKey": null
};

(node as any).hash = "6f1f48f595fd20bd2447200fc5df9f12";

export default node;
