/**
 * @generated SignedSource<<c5f100f0e52f802f04c72a1fedf9ac5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EducationVideoListPage_query$data = {
  readonly lms: {
    readonly " $fragmentSpreads": FragmentRefs<"EducationVideoPageHeader_lms">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EducationWelcomeModal_query" | "VideoFilters_query">;
  readonly " $fragmentType": "EducationVideoListPage_query";
};
export type EducationVideoListPage_query$key = {
  readonly " $data"?: EducationVideoListPage_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationVideoListPage_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "practiceType",
    "variableName": "practiceType"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "practiceType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationVideoListPage_query",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "VideoFilters_query"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "EducationWelcomeModal_query"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lms",
      "kind": "LinkedField",
      "name": "lms",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EducationVideoPageHeader_lms"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "caa6ec59f32374bc0c77d535f507c083";

export default node;
