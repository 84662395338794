import { RedirectException } from 'found';
import { graphql } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import LaunchDarklyRoute, {
  type Prerender,
} from 'components/LaunchDarklyRoute';
import Route, {
  renderStaleWhileFetching,
  renderWithoutData,
} from 'components/Route';
import { prepareVariablesWithPagination } from 'hooks/usePagedConnection';
import { routes as orgRoutes } from 'routes/organization';
import { clearRedirectLocation } from 'utils/EagerRedirectUtils';
import { StudyListFilter } from 'utils/StudyConstants';
import { prepareNewOrganizationStudyQueryVariables } from 'utils/StudyFilters';
import { hasPermissionWithState } from 'utils/viewerContext';

import ExamGalleryPage from './components/ExamGalleryPage';
import ExamImagePage from './components/ExamImagePage';
import ExamNav from './components/ExamNav';
import ExamPage from './components/ExamPage';
import SearchPagedStudiesListPage from './components/SearchPagedStudiesListPage';
import StoragePagedStudiesListPage from './components/StoragePagedStudiesListPage';
import { checkOrganizationPlan, orgArchiveRoutes } from './routes/archive';
import measurementsRoutes, {
  examMeasurementRoutes,
} from './routes/measurements';
import searchroutes, { domainSearchRoutes } from './routes/search';
import sharedBundleRoutes from './routes/sharedBundles';
import {
  searchPaginationQuery,
  storagePaginationQuery,
} from './utils/examPageTopNavbar';

/* 
  This handles permission revoking/granting and adjusts redirect logic accordingly
  Can't be a child route because ':name' parametrized routes will not render children, since it will match everything
  */
const examListRedirectPrerender: Prerender = ({
  props,
  match,
  viewerContext,
}) => {
  const ldClient = match.context.launchDarkly.client;
  if (!props || !ldClient) return;

  const canUseQa = getVariation(ldClient, 'worksheets-review');
  const canUseDrafts = getVariation(ldClient, 'draft-studies');

  const qaRights =
    hasPermissionWithState(viewerContext, 'qa', 'BASIC', 'organization') &&
    canUseQa;

  const studiesStatus = match.params.status as StudyListFilter;

  // Edge cases when viewerCanQa permissions were revoked or flags disabled - reset redirect state
  if (
    (studiesStatus === StudyListFilter.MY_DRAFTS && !canUseDrafts) ||
    (studiesStatus === StudyListFilter.NEEDS_REVIEW && !qaRights)
  ) {
    clearRedirectLocation(match, viewerContext!.organization!.slug!);
    throw new RedirectException(orgRoutes.rootRoute());
  }
};

export const examRoutes = (
  <Route
    hideSidePanel
    path="exams/:studyHandle"
    Component={ExamPage}
    mobileFriendly
    query={graphql`
      query exams_ExamPageQuery(
        $studyHandle: String!
        $organizationSlug: String!
        $isPACEExam: Boolean!
      ) {
        study(handle: $studyHandle) {
          ...ExamPage_study @arguments(isPACEExam: $isPACEExam)
        }
        organization: organizationBySlug(slug: $organizationSlug) {
          ...ExamPage_organization
        }
      }
    `}
    prepareVariables={(variables, { context }) => ({
      ...variables,
      isPACEExam: getVariation(context.launchDarkly.client, 'bft-prototype'),
    })}
  >
    {{
      navigationControls: (
        <LaunchDarklyRoute
          defer
          path="(.*)?"
          render={renderWithoutData}
          getVariation={(ld, match) =>
            getVariation(ld, 'use-search-api') ||
            match.location.state?.studyListNavConfig?.kind ===
              'GLOBAL_SEARCH' ||
            match.location.state?.studyListNavConfig?.kind === 'DOMAIN_SEARCH' // If we're coming from global search or domain search, use search API regardless of the flag
              ? {
                  query: searchPaginationQuery,
                  Component: ExamNav,
                  prepareVariables: (variables, routeMatch) => {
                    const { studyListNavConfig, cursor } =
                      routeMatch.location.state || {};

                    return {
                      ...variables,
                      ...studyListNavConfig?.variables,
                      search: studyListNavConfig?.variables?.search,
                      cursor,
                    };
                  },
                }
              : {
                  query: storagePaginationQuery,
                  Component: ExamNav,
                  prepareVariables: (variables, routeMatch) => {
                    const { studyListNavConfig, cursor } =
                      routeMatch.location.state || {};
                    return {
                      ...variables,
                      ...studyListNavConfig?.variables,
                      cursor,
                      organizationSlug:
                        studyListNavConfig?.variables?.organizationSlug ||
                        variables.organizationSlug,
                    };
                  },
                }
          }
        />
      ),
      children: (
        <>
          <Route
            path="gallery"
            Component={ExamGalleryPage}
            query={graphql`
              query exams_ExamGalleryPage_Query($studyHandle: String!) {
                study(handle: $studyHandle) {
                  ...ExamGalleryPage_study
                }
              }
            `}
          />

          <Route
            defer
            Component={ExamImagePage}
            query={graphql`
              query exams_ExamImagePage_Query($studyHandle: String!) {
                viewer {
                  ...ExamImagePage_viewer
                }
                study(handle: $studyHandle) {
                  ...ExamImagePage_study
                }
              }
            `}
            prepareVariables={(variables) => ({
              ...variables,
              studyImageHandle: null,
            })}
          >
            <Route />
            <Route path=":studyImageHandle">
              <Route />
              {examMeasurementRoutes}
            </Route>
          </Route>
        </>
      ),
    }}
  </Route>
);

export const rootExamRoutes = (
  <>
    {sharedBundleRoutes}
    {domainSearchRoutes}
  </>
);

export const examListRoutes = (
  <>
    {searchroutes}
    {measurementsRoutes}
    {orgArchiveRoutes}
    <LaunchDarklyRoute
      path="exams/list/:status"
      getVariation={(ldClient) => {
        return getVariation(ldClient, 'use-search-api')
          ? {
              Component: SearchPagedStudiesListPage,
              prepareVariables: prepareVariablesWithPagination(
                (variables, match) =>
                  prepareNewOrganizationStudyQueryVariables(
                    variables,
                    match,
                    true,
                  ),
              ),
              query: graphql`
                query exams_search_PagedStudiesListPageQuery(
                  $first: Int
                  $after: String
                  $last: Int
                  $before: String
                  $organizationSlug: String!
                  $search: StudySearchInput
                  $sort: [StudySorting!]! # Not actually required.
                ) {
                  organization: organizationBySlug(slug: $organizationSlug) {
                    ...SearchPagedStudiesListPage_organization
                    subscription {
                      canAccessProFeatures
                      hasCompletedTrial
                    }
                  }
                  viewer {
                    ...SearchPagedStudiesListPage_viewer
                  }
                }
              `,
            }
          : {
              Component: StoragePagedStudiesListPage,
              prepareVariables: prepareVariablesWithPagination(
                prepareNewOrganizationStudyQueryVariables as any,
              ),
              query: graphql`
                query exams_PagedStudiesListPageQuery(
                  $first: Int
                  $after: String
                  $last: Int
                  $before: String
                  $organizationSlug: String!
                  $sort: [StudySorting!]! # Not actually required.
                  $archive: [ID!]
                  $author: [String!]
                  $primaryAuthor: [String!]
                  $examTypes: [String!]
                  $isDraft: [Boolean!]
                  $needsReview: [Boolean!]
                  $hasRequestedFinalization: [Boolean!]
                  $hasRequestedFinalizationFromViewer: [Boolean!]
                  $readyAtMax: DateTime
                  $readyAtMin: DateTime
                  $capturedAtMax: DateTime
                  $capturedAtMin: DateTime
                  $isViewerFavorite: Boolean
                  $excludedFromStudyLists: [Boolean!]
                  $workflowStatus: [StudySearchInputStatus!]
                  $isDeleted: [Boolean!]!
                ) {
                  organization: organizationBySlug(slug: $organizationSlug) {
                    ...StoragePagedStudiesListPage_organization
                    subscription {
                      canAccessProFeatures
                      hasCompletedTrial
                    }
                  }
                  viewer {
                    ...StoragePagedStudiesListPage_viewer
                  }
                }
              `,
            };
      }}
      prerender={(args) => {
        examListRedirectPrerender(args);
        checkOrganizationPlan(args);
      }}
      render={renderStaleWhileFetching(
        (lastProps, match) =>
          match.params.status !== lastProps.match.params.status,
      )}
    />

    {/* 
      This route takes care of people having an old version of to-do list redirects in their localStorage 
      TODO: Remove this once to-do lists have been on prod for a while
    */}
    <LaunchDarklyRoute
      path="studies"
      query={graphql`
        query examsLegacyRedirect_Query($organizationSlug: String!) {
          organization: organizationBySlug(slug: $organizationSlug) {
            slug
          }
        }
      `}
      prerender={({ props, match }) => {
        if (!props) return;
        const { slug } = props.organization;
        clearRedirectLocation(match, slug);
        throw new RedirectException(orgRoutes.rootRoute());
      }}
    />
  </>
);
