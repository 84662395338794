import React, { useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { RoutePageProps } from 'components/Route';

import EducationVideoPageHeader from './EducationVideoPageHeader';
import EducationWelcomeModal from './EducationWelcomeModal';
import SubscriberContentMessage from './SubscriberContentMessage';
import VideoListContainer from './VideoListContainer';
import type { EducationVideoListPage_query$data as Query } from './__generated__/EducationVideoListPage_query.graphql';
import type { EducationVideoListPage_viewer$data as Viewer } from './__generated__/EducationVideoListPage_viewer.graphql';

export const TotalEducationContentContext = React.createContext<{
  setTotalNumber: (count: number) => void;
}>(null as any);

interface Props extends RoutePageProps {
  query: Query;
  viewer: Viewer;
}

function EducationVideoListPage({ query, viewer, children }: Props) {
  const [totalNumber, setTotalNumber] = useState(0);
  const totalEducationContentContextValue = useMemo(
    () => ({ setTotalNumber }),
    [],
  );

  return (
    <>
      <EducationVideoPageHeader lms={query.lms!} totalNumber={totalNumber} />
      <VideoListContainer data-bni-id="EducationVideoListPage">
        <SubscriberContentMessage viewer={viewer} />
        <TotalEducationContentContext.Provider
          value={totalEducationContentContextValue}
        >
          {children}
        </TotalEducationContentContext.Provider>
      </VideoListContainer>
      <EducationWelcomeModal query={query} />
    </>
  );
}

export default createFragmentContainer(EducationVideoListPage, {
  viewer: graphql`
    fragment EducationVideoListPage_viewer on Viewer {
      email
      hasUnexpiredMembership
      ...SubscriberContentMessage_viewer
    }
  `,
  query: graphql`
    fragment EducationVideoListPage_query on Query
    @argumentDefinitions(
      practiceType: { type: "[EducationVideoPracticeType!]" }
    ) {
      ...VideoFilters_query @arguments(practiceType: $practiceType)
      ...EducationWelcomeModal_query @arguments(practiceType: $practiceType)
      lms {
        ...EducationVideoPageHeader_lms
      }
    }
  `,
});
