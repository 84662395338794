import { css } from 'astroturf';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PageTitle from 'components/PageTitle';
import { RoutePageProps } from 'components/Route';
import SearchBar from 'components/SearchBar';
import StudyListContent from 'components/StudyListContent';
import { StudyDataColumnUserPreferenceKey } from 'hooks/useStudyDataColumnsUserPreference';
import { useSearchStudyPagination } from 'utils/StudyPagination';

import { StudySearchPage_archive$data as Archive } from './__generated__/StudySearchPage_archive.graphql';
import { StudySearchPage_organization$data as Organization } from './__generated__/StudySearchPage_organization.graphql';
import { StudySearchPage_studyTags$data as StudyTags } from './__generated__/StudySearchPage_studyTags.graphql';
import { StudySearchPage_userProfile$data as UserProfile } from './__generated__/StudySearchPage_userProfile.graphql';
import { StudySearchPage_viewer$data as Viewer } from './__generated__/StudySearchPage_viewer.graphql';

const messages = defineMessages({
  title: {
    id: 'search.title',
    defaultMessage: 'Search Results',
  },
});

interface Props extends RoutePageProps {
  organization: Organization;
  archive: Archive | null;
  userProfile: UserProfile | null;
  studyTags: StudyTags | null;
  viewer: Viewer;
  loading: boolean;
}

function StudySearchPage({
  organization,
  archive,
  userProfile,
  studyTags,
  match,
  viewer,
  loading,
}: Props) {
  const { location } = match;
  const { search = null } = location.query;

  const { data, hasNext, loadNext } = useSearchStudyPagination(organization);

  const displayTitle = (
    <FormattedMessage
      id="search.text"
      defaultMessage={`Results{search, select,
        true { for "{searchText}"}
        other {}
      } in {archive, select,
        false {all archives}
        true {{label}}
        other {}
      }{hasTag, select,
        true { with {numTags, plural,
          =1 {tag}
          other {tags}
        } {tagNames}}
        other {}
      }{createdBy, select,
        true { created by {name}}
        other {}
      }`}
      values={{
        search: !!search,
        searchText: search,
        hasTag: !!studyTags?.length,
        numTags: studyTags?.length,
        tagNames: studyTags?.map((t) => t.name).join(', '),
        archive: !!archive,
        label: archive?.label,
        createdBy: !!userProfile,
        name: userProfile?.name,
      }}
    />
  );

  return (
    <StudyListContent
      allowMove
      allowDelete
      allowStatusFiltering
      viewer={viewer}
      archive={archive}
      loading={loading}
      navKind="SEARCH"
      showLocalFilters
      data-bni-id="StudySearchPage"
      domain={null}
      organization={organization!}
      studies={data.studyConnection?.edges as any}
      title={<PageTitle title={messages.title} displayTitle={displayTitle} />}
      relayPagination={{
        hasMore: () => hasNext,
        loadMore: (cnt, cb) => loadNext(cnt, { onComplete: cb }),
      }}
      columnsPreferenceKey={StudyDataColumnUserPreferenceKey.SEARCH_STUDY_LIST}
    >
      <SearchBar
        organization={organization}
        archive={archive}
        userProfile={userProfile}
        studyTags={studyTags}
        search={search}
        autoFocus
        css={css`
          flex: 1;
          min-width: 40rem;
        `}
      />
    </StudyListContent>
  );
}

export default createFragmentContainer(StudySearchPage, {
  organization: graphql`
    fragment StudySearchPage_organization on Organization {
      ...SearchBar_organization
      ...StudyPagination_search_organization
      ...StudyListContent_organization
    }
  `,
  archive: graphql`
    fragment StudySearchPage_archive on Archive {
      label
      ...SearchBar_archive
      ...StudyListContent_archive
    }
  `,
  userProfile: graphql`
    fragment StudySearchPage_userProfile on UserProfile {
      name
      ...SearchBar_userProfile
    }
  `,
  studyTags: graphql`
    fragment StudySearchPage_studyTags on StudyTag @relay(plural: true) {
      name
      ...SearchBar_studyTags
    }
  `,
  viewer: graphql`
    fragment StudySearchPage_viewer on Viewer {
      ...StudyListContent_viewer
    }
  `,
});
