const TELEMED_REDIRECT_URIS = ['/-/telemed', '/-/telemed/auth/callback']
  .map((path) => [
    path,
    ...globalThis.bflyConfig.TELEMED_EXTRA_REDIRECT_ORIGINS.map((origin) =>
      new URL(path, origin).toString(),
    ),
  ])
  .flat();

const BFLY_AT_HOME_REDIRECT_URIS = ['/-/home', '/-/home/auth/callback']
  .map((path) => [
    path,
    ...globalThis.bflyConfig.BFLY_AT_HOME_EXTRA_REDIRECT_ORIGINS.map(
      (origin) => new URL(path, origin).toString(),
    ),
  ])
  .flat();

const VALID_REDIRECT_URIS = {
  [globalThis.bflyConfig.STORE_CLIENT_ID]:
    globalThis.bflyConfig.STORE_REDIRECT_URIS,
  [globalThis.bflyConfig.TELEMED_CLIENT_ID]: TELEMED_REDIRECT_URIS,
  [globalThis.bflyConfig.BFLY_AT_HOME_CLIENT_ID]: BFLY_AT_HOME_REDIRECT_URIS,
};

export function isValidAuthorizeRedirect(
  redirectUri: string,
  clientId: string,
) {
  return !!VALID_REDIRECT_URIS[clientId]?.includes(redirectUri);
}

export function isValidLogoutRedirect(redirectUri: string) {
  return Object.values(VALID_REDIRECT_URIS).some((redirectUris) =>
    redirectUris.includes(redirectUri),
  );
}
