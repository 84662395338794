/**
 * @generated SignedSource<<db9e8c29541c33fc4797884119a3b9a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UndeleteArchiveControl_archive$data = {
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "UndeleteArchiveControl_archive";
};
export type UndeleteArchiveControl_archive$key = {
  readonly " $data"?: UndeleteArchiveControl_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"UndeleteArchiveControl_archive">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UndeleteArchiveControl_archive",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};
})();

(node as any).hash = "c7308cff4531a5af2bdd2fe10c579ca5";

export default node;
