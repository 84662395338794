import AgGrid from './AgGrid';

export type DemoElement<T> = {
  component: ({ settings }: { settings: T }) => JSX.Element;
  settings: T;
  path: string;
  title: string;
};

export default [AgGrid] as DemoElement<any>[];
