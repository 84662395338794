/**
 * @generated SignedSource<<62bac7658e04de49bf8c9a8c88760b86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type baseStudyInfo_selectedWorklistItemData$data = {
  readonly accessionNumber: string | null;
  readonly birthDate?: string | null;
  readonly dicomDocument: Record<string, any> | null;
  readonly id?: string;
  readonly internalId: string | null;
  readonly medicalRecordNumber?: string | null;
  readonly nameFirst?: string | null;
  readonly nameLast?: string | null;
  readonly nameMiddle?: string | null;
  readonly namePrefix?: string | null;
  readonly nameSuffix?: string | null;
  readonly sex?: PatientSex | null;
  readonly studyDescription: string | null;
  readonly " $fragmentType": "baseStudyInfo_selectedWorklistItemData";
};
export type baseStudyInfo_selectedWorklistItemData$key = {
  readonly " $data"?: baseStudyInfo_selectedWorklistItemData$data;
  readonly " $fragmentSpreads": FragmentRefs<"baseStudyInfo_selectedWorklistItemData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "baseStudyInfo_selectedWorklistItemData"
};

(node as any).hash = "b08e41289c809de06267489b52031a5a";

export default node;
