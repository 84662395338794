import { route } from '@4c/spa-routing';

const ssoZendesk = route('/-/sso/zendesk');

const routes = { ssoZendesk };

export default routes;

export const useAuthRoutes = () => {
  return routes;
};
