/**
 * @generated SignedSource<<42b412ae4fe8d996914b28002936a27d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EhrOruJobStatus = "CANCELED" | "COMPLETE" | "FAILED" | "PENDING" | "RETRYING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SendStudyToEhrButton_job$data = {
  readonly id: string;
  readonly status: EhrOruJobStatus | null;
  readonly " $fragmentType": "SendStudyToEhrButton_job";
};
export type SendStudyToEhrButton_job$key = {
  readonly " $data"?: SendStudyToEhrButton_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"SendStudyToEhrButton_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SendStudyToEhrButton_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "EhrOruJob",
  "abstractKey": null
};

(node as any).hash = "2f81eb925e17e5af1c107292d99e3c3f";

export default node;
