import IcoClose from '@bfly/icons/CloseSheet';
import Button from '@bfly/ui2/Button';
import Heading from '@bfly/ui2/Heading';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import localStore from 'store/dist/store.modern';

import AuthContext from 'components/AuthContext';
import { marketingWebsiteUrl } from 'config/app';

import useShareSource from '../utils/useShareSource';

const styles = stylesheet`
  .container {
    composes: flex flex-col justify-end absolute items-start w-full z-popover rounded from global;

    bottom: 6rem;
    right: 2rem;
    width: 100%;
    max-width: 35.5rem;
    height: 16rem;
    padding: 2rem 2rem 2rem 9.8rem;
    background: theme('colors.grey[85]') url('../assets/probe.png') no-repeat 1rem bottom;
  }

  .mobile {
    bottom: 4rem;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .close {
    composes: absolute top-4 right-4 text-body from global;

    & > svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;

const KEY = 'bfly:hasSeenMeetIqCallToAction';

function MeetIqAlert({ mobile }: { mobile?: boolean }) {
  const isImageShare = useShareSource() === 'image_share_sheet';

  const { isAuthenticated } = useContext(AuthContext);

  const [hide, setHide] = useState(
    () => localStore.get(KEY, false) || isAuthenticated(),
  );

  const markAsSeen = () => {
    localStore.set(KEY, true);
    setHide(true);
  };

  if (!isImageShare || hide) return null;

  return (
    <Section
      data-bni-id="MeetIqCallToAction"
      className={clsx(styles.container, mobile && styles.mobile)}
    >
      <Heading>
        <FormattedMessage
          id="meetIq.heading"
          defaultMessage="Meet Butterfly iQ+"
        />
      </Heading>
      <Text as="p" color="headline">
        <FormattedMessage
          id="meetIq.description"
          defaultMessage="A handheld, whole-body ultrasound solution."
        />
      </Text>
      <Button href={marketingWebsiteUrl} onClick={markAsSeen}>
        <FormattedMessage id="meetIq.learnMore" defaultMessage="Learn more" />
      </Button>
      <button type="button" className={styles.close} onClick={markAsSeen}>
        <IcoClose />
      </button>
    </Section>
  );
}

export default MeetIqAlert;
