import GridIcon from '@bfly/icons/Grid';
import Dropdown from '@bfly/ui2/Dropdown';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useModalState from 'hooks/useModalState';
import { useCanDeleteCaptures } from 'utils/StudyPermissions';

import DeleteCapturesModal from './DeleteCapturesModal';
import { DeleteCapturesDropdownItem_study$data as Study } from './__generated__/DeleteCapturesDropdownItem_study.graphql';

interface Props {
  study: Study;
}

function DeleteCapturesDropdownItem({ study }: Props) {
  const canDeleteCaptures = useCanDeleteCaptures(study);
  const [showModal, modalProps] = useModalState();

  if (!canDeleteCaptures) {
    return null;
  }

  return (
    <>
      <Dropdown.Item
        icon={<GridIcon className="w-full" />}
        onClick={showModal}
      >
        <FormattedMessage
          id="studyControls.deleteContent.button"
          defaultMessage="Delete captures"
        />
      </Dropdown.Item>
      <DeleteCapturesModal study={study} {...modalProps} />
    </>
  );
}

export default createFragmentContainer(DeleteCapturesDropdownItem, {
  study: graphql`
    fragment DeleteCapturesDropdownItem_study on Study {
      ...DeleteCapturesModal_study
      ...StudyPermissions_canDeleteCaptures
    }
  `,
});
