import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import React, { useRef } from 'react';
import { graphql, readInlineData } from 'react-relay';

import { Match } from 'components/Route';

import { AppPageRenderer_organization$key as OrganizationKey } from './__generated__/AppPageRenderer_organization.graphql';

interface AppPageProps {
  viewer: Record<string, unknown> | null;
  organization: OrganizationKey | null;
}

/**
 * Hack around unnecessary invalidation from archive fragment.
 */
function useLastProps<TProps extends AppPageProps>(
  props: TProps | undefined,
  match: Match,
) {
  const lastPropsRef = useRef<(TProps & { match?: Match }) | null>(null);

  // Take the newest props, or else the old props with the new match.
  lastPropsRef.current = props || { ...lastPropsRef.current!, match };
  const lastProps = lastPropsRef.current;

  if (!lastProps || !lastProps.viewer || !lastProps.organization) {
    return null;
  }

  const lastOrganization = readInlineData(
    graphql`
      fragment AppPageRenderer_organization on Organization @inline {
        slug
      }
    `,
    lastProps.organization,
  );

  if (
    match.params.organizationSlug.toLowerCase() !==
    lastOrganization.slug!.toLowerCase()
  ) {
    return null;
  }

  return lastProps;
}

interface Props<TProps extends AppPageProps> {
  Component: React.ComponentType<TProps>;
  props: TProps | undefined;
  match: Match;
  [other: PropertyKey]: any;
}

function AppPageRenderer<TProps extends AppPageProps>({
  Component,
  props,
  match,
  ...ownProps
}: Props<TProps>) {
  const lastProps = useLastProps(props, match);
  if (!lastProps) {
    return <LoadingIndicator />;
  }

  return <Component archive={null} {...lastProps} {...ownProps} />;
}

export default AppPageRenderer;
