import Card from '@bfly/ui2/Card';
import clsx from 'clsx';
import React from 'react';

function SkeletonCard({
  busy,
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'> & { busy?: boolean }) {
  return (
    <Card
      {...props}
      className={clsx(className, busy && `animate-pulse [&_*]:opacity-0`)}
    />
  );
}

export default SkeletonCard;
