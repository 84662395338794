import Layout from '@4c/layout';
import BackButton from '@bfly/ui2/BackButton';
import Form from '@bfly/ui2/Form';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import { Link, useRouter } from 'found';
import { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { graphql } from 'react-relay';

import PageTitle from 'components/PageTitle';
import RelayForm from 'components/RelayForm';
import { useAdminRoutes } from 'routes/admin';

import roleSchema, { serialize } from '../schemas/roles';
import { validateBucketsDependencies } from '../utils/validateBucketsDependencies';
import DomainRoleDetailFieldSet from './DomainRoleDetailsFieldSet';
import DomainRolePermissionsFieldSet from './DomainRolePermissionsFieldSet';
import { CreateDomainRolePage_AddNewRoleMutation as CreateRoleMutation } from './__generated__/CreateDomainRolePage_AddNewRoleMutation.graphql';

const messages = defineMessages({
  header: {
    id: 'domain.roles.addNewRole',
    defaultMessage: 'Add new Butterfly Access Roles',
  },
});

const defaultValue = roleSchema.getDefault();

const mutation = graphql`
  mutation CreateDomainRolePage_AddNewRoleMutation(
    $input: CreateMembershipRoleInput!
  ) {
    createMembershipRoleOrError(input: $input) {
      ... on CreateMembershipRolePayload {
        membershipRole {
          id
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

function CreateDomainRolePage() {
  const routes = useAdminRoutes();
  const { router } = useRouter();

  const [value, setValue] = useState(defaultValue);

  const handleChange = (nextValue, [updatedField]) => {
    const newValue = validateBucketsDependencies(nextValue, updatedField);
    setValue(newValue);
  };

  const onCompleted = ({ createMembershipRoleOrError }) => {
    const { membershipRole } = createMembershipRoleOrError;
    router.replace(
      routes.domainRoleUpdate({
        membershipRoleId: membershipRole!.id,
      }),
    );
  };

  return (
    <Page.Container>
      <MainContent>
        <Layout pad={4} className="items-center mb-10">
          <Link to={routes.domainRoles()}>
            <BackButton />
          </Link>
          <PageTitle title={messages.header} />
        </Layout>
        <RelayForm<CreateRoleMutation, typeof roleSchema>
          horizontal
          schema={roleSchema}
          defaultValue={defaultValue}
          value={value}
          mutation={mutation}
          getInput={(input) => {
            return serialize(input);
          }}
          onChange={handleChange}
          successMessage={
            <FormattedMessage
              id="createDomainRole.permission.success"
              defaultMessage="Butterfly Access Role created"
            />
          }
          onCompleted={onCompleted}
        >
          <>
            <DomainRoleDetailFieldSet />
            <DomainRolePermissionsFieldSet />
            <Layout reverse>
              <Form.Submit data-bni-id="AddNewRoleSubmitButton">
                <FormattedMessage
                  id="domainRoleAddNewRolePage.saveButton"
                  defaultMessage="Add Butterfly Access Role"
                />
              </Form.Submit>
            </Layout>
          </>
        </RelayForm>
      </MainContent>
    </Page.Container>
  );
}

export default CreateDomainRolePage;
