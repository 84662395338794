/**
 * @generated SignedSource<<b75990e8d37716e7f71aaa07db6a0da4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyEndpointsModalContent_study$data = {
  readonly archive: {
    readonly ehrAssociations: ReadonlyArray<{
      readonly ehr: {
        readonly id: string;
      } | null;
    } | null> | null;
    readonly pacsAssociations: ReadonlyArray<{
      readonly pacs: {
        readonly id: string;
      } | null;
    } | null> | null;
  } | null;
  readonly ehrOruJobs: ReadonlyArray<{
    readonly ehr: {
      readonly id: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"StudyEhrJobDetail_job">;
  } | null> | null;
  readonly id: string;
  readonly organization: {
    readonly ehrConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"StudyEhrJobDetail_ehr">;
        } | null;
      } | null> | null;
    } | null;
    readonly pacsConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"StudyPacsJobDetail_pacs">;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly pacsPushJobs: ReadonlyArray<{
    readonly pacs: {
      readonly id: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"StudyPacsJobDetail_job">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyEhrJobDetail_study" | "StudyPacsJobDetail_study">;
  readonly " $fragmentType": "StudyEndpointsModalContent_study";
};
export type StudyEndpointsModalContent_study$key = {
  readonly " $data"?: StudyEndpointsModalContent_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyEndpointsModalContent_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Pacs",
  "kind": "LinkedField",
  "name": "pacs",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Ehr",
  "kind": "LinkedField",
  "name": "ehr",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "organization",
          "pacsConnection"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "organization",
          "ehrConnection"
        ]
      }
    ]
  },
  "name": "StudyEndpointsModalContent_study",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyPacsJobDetail_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyEhrJobDetail_study"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PacsPushJob",
      "kind": "LinkedField",
      "name": "pacsPushJobs",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StudyPacsJobDetail_job"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EhrOruJob",
      "kind": "LinkedField",
      "name": "ehrOruJobs",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StudyEhrJobDetail_job"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": "pacsConnection",
          "args": null,
          "concreteType": "PacsConnection",
          "kind": "LinkedField",
          "name": "__Organization_pacsConnection_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PacsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Pacs",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StudyPacsJobDetail_pacs"
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "ehrConnection",
          "args": null,
          "concreteType": "EhrConnection",
          "kind": "LinkedField",
          "name": "__Organization_ehrConnection_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EhrEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Ehr",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StudyEhrJobDetail_ehr"
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PacsArchiveAssociation",
          "kind": "LinkedField",
          "name": "pacsAssociations",
          "plural": true,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EhrArchiveAssociation",
          "kind": "LinkedField",
          "name": "ehrAssociations",
          "plural": true,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "27bd1441d4d67a2a04501882cb339852";

export default node;
