/**
 * @generated SignedSource<<8cf78d58937fa0e5b28458a982292685>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarListSavedSearchOption_studySavedSearch$data = {
  readonly createdAt: string | null;
  readonly name: string | null;
  readonly " $fragmentType": "SearchBarListSavedSearchOption_studySavedSearch";
};
export type SearchBarListSavedSearchOption_studySavedSearch$key = {
  readonly " $data"?: SearchBarListSavedSearchOption_studySavedSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSavedSearchOption_studySavedSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBarListSavedSearchOption_studySavedSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "StudySavedSearch",
  "abstractKey": null
};

(node as any).hash = "31d705f9ba069aa296d96e8ed8bba3ab";

export default node;
