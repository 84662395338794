import BlankSlate from '@bfly/ui2/BlankSlate';
import Link from '@bfly/ui2/Link';
import useRouter from 'found/useRouter';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import { useAdminRoutes } from 'routes/admin';
import { useConfigRoutes } from 'routes/config';

import { isInApp } from '../../../utils/browserInfo';
import { LockedContentHero_viewer$data as Viewer } from './__generated__/LockedContentHero_viewer.graphql';

interface Props {
  viewer: Viewer;
}

function LockedContentHero({ viewer }: Props) {
  const configRoutes = useConfigRoutes();
  const adminRoutes = useAdminRoutes({
    organizationSlug: viewer
      ? viewer.memberships![0]?.organization?.slug
      : null,
  });
  const hasPremiumVideoAccess = useVariation('edu-premium-video-access');

  const {
    match: { location },
  } = useRouter();

  return (
    <BlankSlate className="w-full h-full justify-center bg-dark-grey">
      <BlankSlate.Title variant="display-lg">
        <FormattedMessage
          id="lockedEducationContent.title"
          defaultMessage="Member Content"
        />
      </BlankSlate.Title>

      <BlankSlate.Body>
        {!viewer ? (
          <FormattedMessage
            id="lockedEducationContent.noViewer.message"
            defaultMessage="A paid membership is required to gain full access to all Butterfly Education videos and content. If you already have a membership, please <a>log in</a> or purchase a Butterfly iQ."
            values={{
              a: (str: string) => (
                <Link
                  to={{
                    ...location,
                    pathname: configRoutes.rootRoute(),
                    state: {
                      ...location.state,
                      forceLogin: true,
                    },
                  }}
                >
                  {str}
                </Link>
              ),
            }}
          />
        ) : (
          <>
            {viewer && viewer.memberships?.length && !hasPremiumVideoAccess ? (
              <FormattedMessage
                id="lockedEducationContent.proCustom.message"
                defaultMessage="Upgrade your membership to get access to this video."
              />
            ) : (
              <FormattedMessage
                id="lockedEducationContent.viewer.message"
                defaultMessage="A paid membership is required to gain full access to all Butterfly Education videos and content. Please check the status of your membership."
              />
            )}
          </>
        )}
      </BlankSlate.Body>
      {!isInApp() && (
        <>
          {viewer && viewer.memberships?.length && !hasPremiumVideoAccess ? (
            <Link
              as={BlankSlate.Action}
              to={adminRoutes.organizationSubscription()}
              size="xl"
            >
              <FormattedMessage
                id="lockedEducationContent.upgrade"
                defaultMessage="Upgrade Now"
              />
            </Link>
          ) : (
            <BlankSlate.Action
              size="xl"
              href="https://store.butterflynetwork.com/"
            >
              <FormattedMessage
                id="lockedEducationContent.purchase"
                defaultMessage="Buy Now"
              />
            </BlankSlate.Action>
          )}
        </>
      )}
    </BlankSlate>
  );
}

export default createFragmentContainer(LockedContentHero, {
  viewer: graphql`
    fragment LockedContentHero_viewer on Viewer {
      __typename
      memberships {
        organization {
          slug
        }
      }
    }
  `,
});
