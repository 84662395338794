import Button from '@bfly/ui2/Button';
import FormCheck from '@bfly/ui2/FormCheck';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useMutation } from 'react-relay';

import { TDataBase } from 'components/AgGrid/types';
import withModal from 'utils/withModal';

import { bulkDomainUsersUpdateModalMessages as messages } from '../messages/DomainMembersMessages';
import {
  DicomLabelInfo,
  InterfaceCodeInfo,
  Row,
} from '../shared/DomainMembersGridTypes';
import {
  BulkDomainUsersUpdateModal_bulkUpdateMutation,
  BulkInputDomainUser,
  BulkInputDomainUserDicomField,
  BulkInputDomainUserIntegrationConfig,
} from './__generated__/BulkDomainUsersUpdateModal_bulkUpdateMutation.graphql';

const bulkUpdateMutation = graphql`
  mutation BulkDomainUsersUpdateModal_bulkUpdateMutation(
    $input: BulkUpdateDomainUsersInput!
  ) {
    bulkUpdateDomainUsers(input: $input) {
      users {
        edges {
          node {
            id
            email
            integrationDisplayNameFirst
            integrationDisplayNameMiddle
            integrationDisplayNameLast
            canQa
            canFinalize
            type
            dicomFields {
              dicomFieldTemplate {
                label
              }
              value
            }
            integrationConfigs {
              ehr {
                handle
              }
              interfaceCode
            }
            role {
              name
            }
            organizations {
              name
            }
            updatedAt
          }
        }
      }
      failures {
        email
        detail
        statusCode
        code
      }
    }
  }
`;

interface Props {
  changes: TDataBase[];
  domainId: string;
  onHide: () => void;
  onSuccess: (count: number) => void;
  onError: () => void;
  rowData: Row[];
  dicomLabels: DicomLabelInfo[];
  interfaceCodes: InterfaceCodeInfo[];
}

function BulkDomainUsersUpdateModal({
  changes,
  domainId,
  onHide,
  onSuccess,
  onError,
  rowData,
  dicomLabels,
  interfaceCodes,
}: Props) {
  const [mutate, saving] =
    useMutation<BulkDomainUsersUpdateModal_bulkUpdateMutation>(
      bulkUpdateMutation,
    );

  const [confirmedChanges, setConfirmedChanges] = useState<string[]>(
    changes.map(({ id }) => id),
  );

  const [failures, setFailures] = useState<Record<string, string[]>>({});

  const toggleUser = (id: string) => {
    setConfirmedChanges((prev) =>
      prev.includes(id)
        ? prev.filter((prevId) => prevId !== id)
        : [...prev, id],
    );
  };

  const handleUpdateUsers = useCallback(() => {
    const users: BulkInputDomainUser[] = [];

    for (const change of changes) {
      const row = rowData.find(({ id }) => id === change.id);

      if (!row || !confirmedChanges.includes(change.id)) continue;

      const dicomFields: BulkInputDomainUserDicomField[] = [];
      dicomLabels.forEach((item) => {
        const key = `dicom,${item.id}`;
        const hasValue = key in change || row[key] != null;
        const hasChanged = key in change && change[key] !== row[key];
        if (item.id && hasValue)
          dicomFields.push({
            templateId: item.id,
            value: hasChanged ? change[key] : row[key],
          });
      });

      const integrationConfigs: BulkInputDomainUserIntegrationConfig[] = [];
      interfaceCodes.forEach((item) => {
        const key = `interfaceCode,${item.id}`;
        const hasValue = key in change || row[key] != null;
        const hasChanged = key in change && change[key] !== row[key];
        if (item.id && hasValue) {
          integrationConfigs.push({
            integrationId: item.id,
            interfaceCode: hasChanged ? change[key] : row[key],
          });
        }
      });

      const nextUser: BulkInputDomainUser = {
        email: row.email!,
        dicomFields,
        integrationConfigs,
        integrationDisplayNameFirst:
          'integrationDisplayNameFirst' in change
            ? change.integrationDisplayNameFirst
            : row.integrationDisplayNameFirst,
        integrationDisplayNameMiddle:
          'integrationDisplayNameMiddle' in change
            ? change.integrationDisplayNameMiddle
            : row.integrationDisplayNameMiddle,
        integrationDisplayNameLast:
          'integrationDisplayNameLast' in change
            ? change.integrationDisplayNameLast
            : row.integrationDisplayNameLast,
      };

      users.push(nextUser);
    }

    mutate({
      variables: {
        input: {
          domainId,
          users,
        },
      },
      onError: () => {
        onError();
      },
      onCompleted({ bulkUpdateDomainUsers: response }) {
        if (!response?.failures?.length) {
          const successCount = response?.users?.edges?.length || 0;
          if (successCount) onSuccess(successCount);
        } else {
          const validFailures = (response?.failures || []).filter(
            ({ email, detail }) => email && detail,
          ) as { email: string; detail: string }[];

          if (validFailures.length)
            setFailures((prevFailures) => {
              // make a new object to trigger a re-render
              const nextFailures = { ...prevFailures };
              validFailures.forEach(({ email, detail }) => {
                nextFailures[email] = nextFailures[email] || [];
                nextFailures[email].push(detail);
              });
              return nextFailures;
            });
        }
      },
    });
  }, [
    mutate,
    domainId,
    changes,
    rowData,
    confirmedChanges,
    dicomLabels,
    interfaceCodes,
    onError,
    onSuccess,
  ]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...messages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextReview}
            values={{
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextUpdated}
            values={{
              numUpdated: confirmedChanges.length,
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>
        <div className="px-4">
          <table className="w-full">
            <tbody>
              {changes.map((change) => {
                const row = rowData.find(({ id }) => id === change.id)!;
                return (
                  <tr key={change.id} className="border-b border-b-grey-80">
                    <td className="w-8 h-12">
                      <FormCheck
                        className="m-0"
                        checked={confirmedChanges.includes(change.id)}
                        onChange={() => toggleUser(change.id)}
                      />
                    </td>
                    <td className="h-12">
                      {row.email}
                      {failures[row.email!] && (
                        <>
                          <br />
                          <Text color="danger">
                            {failures[row.email!].join(', ')}
                          </Text>
                        </>
                      )}
                    </td>
                    <td className="h-12 text-right">
                      {change.integrationDisplayNameFirst ||
                        row.integrationDisplayNameFirst}{' '}
                      {change.integrationDisplayNameLast ||
                        row.integrationDisplayNameLast}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            data-bni-id="BulkDomainUsersUpdateModalConfirm"
            size="lg"
            onClick={handleUpdateUsers}
            disabled={confirmedChanges.length === 0}
            busy={saving}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
          <Button
            data-bni-id="BulkDomainUsersUpdateModalCancel"
            variant="secondary"
            size="lg"
            onClick={onHide}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default withModal(BulkDomainUsersUpdateModal, {
  style: { maxWidth: '600px' },
  variant: 'dark',
});
