import React, { useMemo } from 'react';

import MultiSelectContext from 'components/MultiSelectContext';
import { SelectAllProvider } from 'components/SelectAllContext';

import {
  EnrollmentContextProvider,
  useEnrollmentContext,
} from './EnrollmentContext';

interface Props {
  children: React.ReactNode;
}

function UsersPageContent({ children }: Props) {
  const { selectedUsers, setSelectedUsers } = useEnrollmentContext();

  const multiSelectContextValue = useMemo(
    () => ({
      selectedItemsByKey: selectedUsers,
      setSelectedItemsByKey: setSelectedUsers,
    }),
    [selectedUsers, setSelectedUsers],
  );

  return (
    <MultiSelectContext.Provider value={multiSelectContextValue}>
      <SelectAllProvider>{children}</SelectAllProvider>
    </MultiSelectContext.Provider>
  );
}

export default function UsersPage({ children }: Props) {
  return (
    <EnrollmentContextProvider>
      <UsersPageContent>{children}</UsersPageContent>
    </EnrollmentContextProvider>
  );
}
