import React from 'react';
import { FormattedMessage } from 'react-intl';

import DeviceTooSmallSuccessPage from './DeviceTooSmallSuccessPage';

function AccountCreatedPage() {
  return (
    <DeviceTooSmallSuccessPage
      title={
        <FormattedMessage
          id="accountCreated.title"
          defaultMessage="Account Created"
        />
      }
    />
  );
}

export default AccountCreatedPage;
