/**
 * @generated SignedSource<<9add5f3abec6398f496cd0c1c5dfd855>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useStudyListLazyImagesQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type useStudyListLazyImagesQuery$data = {
  readonly nodes: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"StudyDataGrid_studiesWithImages" | "StudyList_studiesWithImages">;
  } | null>;
};
export type useStudyListLazyImagesQuery = {
  response: useStudyListLazyImagesQuery$data;
  variables: useStudyListLazyImagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useStudyListLazyImagesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StudyDataGrid_studiesWithImages"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StudyList_studiesWithImages"
              }
            ],
            "type": "Study",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useStudyListLazyImagesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 6
                  }
                ],
                "concreteType": "StudyImageConnection",
                "kind": "LinkedField",
                "name": "imageConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyImageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyImage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "handle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BurnedInFile",
                            "kind": "LinkedField",
                            "name": "burnedInFile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isVideo",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Thumbnail",
                                    "kind": "LinkedField",
                                    "name": "thumbnails",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "width",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "ThumbnailedInterface",
                                "abstractKey": "__isThumbnailedInterface"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "IqMetadata",
                            "kind": "LinkedField",
                            "name": "iqMetadata",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "probeSerial",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BurnedInFile",
                                "kind": "LinkedField",
                                "name": "burnedInFile",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isImageFileInterface"
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "numComments",
                                    "storageKey": null
                                  }
                                ],
                                "type": "StudyImage",
                                "abstractKey": null
                              }
                            ],
                            "type": "StudyImageInterface",
                            "abstractKey": "__isStudyImageInterface"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "imageConnection(first:6)"
              }
            ],
            "type": "Study",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6375e3499c43a271913fe138bd1fe4b",
    "id": null,
    "metadata": {},
    "name": "useStudyListLazyImagesQuery",
    "operationKind": "query",
    "text": "query useStudyListLazyImagesQuery(\n  $ids: [ID!]!\n) {\n  nodes(ids: $ids) {\n    __typename\n    id\n    ... on Study {\n      ...StudyDataGrid_studiesWithImages\n      ...StudyList_studiesWithImages\n    }\n  }\n}\n\nfragment ImagePreviewItem_image on StudyImageInterface {\n  __isStudyImageInterface: __typename\n  ... on StudyImage {\n    handle\n    numComments\n  }\n  burnedInFile {\n    ...StudyImageThumbnail_image\n  }\n}\n\nfragment NewStudyDataGridCaptures_studyImages on StudyImage {\n  handle\n  burnedInFile {\n    isVideo\n    ...Thumbnail_image\n  }\n  iqMetadata {\n    probeSerial\n  }\n}\n\nfragment StudyDataGrid_studiesWithImages on Study {\n  id\n  imageConnection(first: 6) {\n    edges {\n      node {\n        ...NewStudyDataGridCaptures_studyImages\n        id\n      }\n    }\n  }\n}\n\nfragment StudyImagePreviewList_studyImages on StudyImage {\n  handle\n  ...ImagePreviewItem_image\n}\n\nfragment StudyImageThumbnail_image on ImageFileInterface {\n  __isImageFileInterface: __typename\n  isVideo\n  ...Thumbnail_image\n}\n\nfragment StudyListItem_studyImages on StudyImage {\n  ...StudyImagePreviewList_studyImages\n}\n\nfragment StudyList_studiesWithImages on Study {\n  id\n  imageConnection(first: 6) {\n    edges {\n      node {\n        ...StudyListItem_studyImages\n        id\n      }\n    }\n  }\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n"
  }
};
})();

(node as any).hash = "69141739c8fe58690109f4d3ccbd0f1b";

export default node;
