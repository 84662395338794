import FeatureFlags from 'config/FeatureFlags';

import { useAuth } from 'components/AuthContext';
import {
  useVariation,
  useVariationAllowMissingLdContext,
} from 'components/LaunchDarklyContext';

export default function useButterflyAcademyAccess(): FeatureFlags['butterfly-academy-access'] {
  const butterflyAcademyAccess = useVariation('butterfly-academy-access');
  const { isAuthenticated } = useAuth();
  return isAuthenticated() ? butterflyAcademyAccess : 'locked';
}

export function useButterflyAcademyAccessWithoutLdContext(): FeatureFlags['butterfly-academy-access'] {
  const butterflyAcademyAccess = useVariationAllowMissingLdContext(
    'butterfly-academy-access',
  );
  const { isAuthenticated } = useAuth();
  return isAuthenticated() ? butterflyAcademyAccess : 'locked';
}
