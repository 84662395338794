/**
 * @generated SignedSource<<65efa95875b20ff802b561e9856d35c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShareStudyModal_study$data = {
  readonly id: string;
  readonly " $fragmentType": "ShareStudyModal_study";
};
export type ShareStudyModal_study$key = {
  readonly " $data"?: ShareStudyModal_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShareStudyModal_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShareStudyModal_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "603b0957c122c7882ef4b24ecb7fb93e";

export default node;
