import Button from '@bfly/ui2/Button';
import Listbox from '@bfly/ui2/Listbox';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useMoveStudies from 'hooks/useMoveStudies';
import actionMessages from 'messages/actions';
import withModal from 'utils/withModal';

import type { MoveStudyModal_Query as Query } from './__generated__/MoveStudyModal_Query.graphql';
import type { MoveStudyModal_domain$data as Domain } from './__generated__/MoveStudyModal_domain.graphql';
import type { MoveStudyModal_organization$data as Organization } from './__generated__/MoveStudyModal_organization.graphql';

type Archive = ConnectionNodeType<
  Query['response']['organization'],
  'archiveConnection'
>;

interface Props {
  organization: Organization;
  domain: Domain | null;
  studyAndArchiveIds: Array<{ studyId: string; archiveId: string }>;
  onHide: () => void;
}

function MoveStudyModal({
  organization,
  domain,
  studyAndArchiveIds,
  onHide,
}: Props) {
  const [selectedArchive, setSelectedArchive] = useState<null | string>(null);
  const [moveStudies, loading] = useMoveStudies();

  const sameArchives = useMemo(
    () =>
      studyAndArchiveIds.every(
        (object) => object.archiveId === studyAndArchiveIds[0].archiveId,
      ),
    [studyAndArchiveIds],
  );

  const { data } = useQuery<Query>(
    graphql`
      query MoveStudyModal_Query($organizationId: ID!) {
        organization: node(id: $organizationId) {
          ... on Organization {
            archiveConnection(first: 2147483647)
              @connection(key: "Organization_archiveConnection") {
              edges {
                node {
                  id
                  label
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: {
        organizationId: organization!.id,
      },
    },
  );

  const archives = data ? getNodes(data?.organization?.archiveConnection) : [];

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="study.move.modal.title"
            defaultMessage="Select Destination Archive"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body scrollable={false}>
        {data ? (
          <Listbox<Archive>
            dataKey="id"
            textField="label"
            className="h-full max-h-96"
            variant="secondary"
            data={archives}
            data-bni-id="MoveStudyArchiveListbox"
            onChange={({ id }) => {
              setSelectedArchive(id);
            }}
            disabled={
              sameArchives
                ? archives.filter(
                    (a) => a.id === studyAndArchiveIds[0].archiveId,
                  )
                : undefined
            }
          />
        ) : (
          <LoadingIndicator />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            disabled={!selectedArchive}
            busy={loading}
            onClick={async () => {
              if (!selectedArchive) return;

              await moveStudies(
                studyAndArchiveIds,
                selectedArchive,
                organization.id,
                organization.id,
                domain ? domain.id : '',
              );
              onHide();
            }}
          >
            <FormattedMessage
              id="moveStudyModal.moveConfirm"
              values={{ numStudies: studyAndArchiveIds.length }}
              defaultMessage="{numStudies, plural,
                one {Move study}
                other {Move studies}
              }"
            />
          </Button>
          <Button variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(
  withModal(MoveStudyModal, { backdrop: true, variant: 'dark', size: 'sm' }),
  {
    organization: graphql`
      fragment MoveStudyModal_organization on Organization {
        id
      }
    `,
    domain: graphql`
      fragment MoveStudyModal_domain on Domain {
        id
      }
    `,
  },
);
