import { defineMessages } from 'react-intl';

export default defineMessages({
  active: {
    id: 'associateWorklist.active',
    defaultMessage: 'Active',
  },
  discharged: {
    id: 'associateWorklist.past',
    defaultMessage: 'Past',
  },
  back: {
    id: 'associateWorklistModalContent.back',
    defaultMessage: 'Back',
  },
  cancel: {
    id: 'associateWorklistModalContent.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'associateWorklistModalContent.save',
    defaultMessage: 'Save',
  },
  editPatient: {
    id: 'editExamInfo.title',
    defaultMessage: 'Edit Patient',
  },
  manualEntry: {
    id: 'editExamInfo.manualEntry',
    defaultMessage: 'Manual entry',
    description:
      'manually type in patient details instead of copying from a worklist',
  },
  requiresSelection: {
    id: 'editExamInfo.requiresSelection',
    defaultMessage: 'This exam requires selection from the patient list.',
  },
  saveNote: {
    id: 'editExamInfo.saveNote',
    defaultMessage:
      'The Butterfly cloud will not automatically send the edited study to your downstream DICOM storage endpoints. Before manually re-sending the updated study from the cloud, please delete the current instance of your study in the downstream storage.',
  },
  clear: {
    id: 'exam.worklist.clear.button',
    defaultMessage: 'Clear',
  },
  accessionNumber: {
    id: 'studyInfo.accessionNumber',
    defaultMessage: 'Accession #',
  },
  accessionNumberEmpty: {
    id: 'studyInfo.accessionNumber.empty',
    defaultMessage: 'No Accession #',
  },
  changePatient: {
    id: 'editExamInfo.changePatient',
    defaultMessage: 'Change Patient',
  },
  chooseFromWorklist: {
    id: 'study.worklist.associate.chooseFromWorklist',
    defaultMessage: 'Choose from worklist',
  },
  fromPatientList: {
    id: 'study.worklist.associate.fromPatientList',
    defaultMessage: 'From Patient List',
  },
  associateAPatientName: {
    id: 'associateWorklist.title',
    defaultMessage: 'Associate a Patient from {worklistName}',
  },
  signAndNext: {
    id: 'exam.signAndNext',
    defaultMessage: 'Sign & Next',
  },
  signFinal: {
    id: 'exam.sign',
    defaultMessage: 'Sign Final',
  },
  sign: {
    id: 'exam.signFinal',
    defaultMessage: 'Sign',
  },
});
