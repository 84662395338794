import Button from '@bfly/ui2/Button';
import Heading from '@bfly/ui2/Heading';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import groupBy from 'lodash/groupBy';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import actionMessages from 'messages/actions';

import { BulkUploadResults_failures$data as Failures } from './__generated__/BulkUploadResults_failures.graphql';
import { BulkUploadResults_undeletedUsers$data as UndeletedUsers } from './__generated__/BulkUploadResults_undeletedUsers.graphql';

const EXISTING = 'invalid_data.existing_user';
const DELETED = 'invalid_data.deleted_user';
const DUPLICATE_DICOM_FIELD = 'invalid_data.dicom_field_exists';

interface Props {
  numAdded: number;
  failures: Failures;
  undeletedUsers: UndeletedUsers;
  onHide: () => void;
}

function BulkUploadResults({
  failures,
  undeletedUsers,
  numAdded,
  onHide,
}: Props) {
  const grouped = groupBy(failures, (f) => f.code);
  const inactiveUsersEnabled = useVariation('inactive-users');

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="bulkUploadResults.title"
            defaultMessage="Upload summary"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Heading ruled>
          <FormattedMessage
            id="bulkUploadResults.added"
            defaultMessage="{numAdded} {numAdded, plural, one {new user created.} other {new users created.}}"
            values={{ numAdded }}
          />
        </Heading>
        {!!undeletedUsers?.length && (
          <details className="mb-4">
            <summary>
              <Text color="headline" variant="body-bold" className="mb-4">
                <FormattedMessage
                  id="bulkUploadResults.undeletedSummary"
                  defaultMessage="{numUndeleted} {numUndeleted, plural, one {user has been reactivated.} other {users have been reactivated.}}"
                  values={{ numUndeleted: undeletedUsers.length }}
                />
              </Text>
            </summary>
            <ul
              className="list-unstyled pl-4 mt-3"
              data-bni-id="ReactivatedUserEmails"
            >
              {undeletedUsers.map((user) => (
                <li key={user.email!}>{user.email!}</li>
              ))}
            </ul>
          </details>
        )}
        {!!grouped[DELETED]?.length && (
          <details className="mb-4">
            <summary>
              {inactiveUsersEnabled ? (
                <Text color="danger" variant="body-bold" className="mb-4">
                  <FormattedMessage
                    id="bulkUploadResults.deletedSummaryInactiveUser"
                    defaultMessage="{numDeleted} {numDeleted, plural, one {user is deactivated, please reactivate the user first.} other {users are deactivated, please reactivate the users first.}}"
                    values={{ numDeleted: grouped[DELETED].length }}
                  />
                </Text>
              ) : (
                <Text color="danger" variant="body-bold" className="mb-4">
                  <FormattedMessage
                    id="bulkUploadResults.deletedSummary"
                    defaultMessage="{numDeleted} {numDeleted, plural, one {user is deleted and cannot be reinstated.} other {users are deleted and cannot be reinstated.}}"
                    values={{ numDeleted: grouped[DELETED].length }}
                  />
                </Text>
              )}
              {!inactiveUsersEnabled && (
                <Text as="div" color="subtitle" variant="sm" className="ml-4">
                  <FormattedMessage
                    id="bulkUploadResults.subtitle"
                    defaultMessage="Contact support for help"
                  />
                </Text>
              )}
            </summary>
            <ul
              className="list-unstyled pl-4 mt-3"
              data-bni-id="DeactivatedUserEmails"
            >
              {grouped[DELETED].map((deleted) => (
                <li key={deleted.email!}>{deleted.email!}</li>
              ))}
            </ul>
          </details>
        )}
        {!!grouped[EXISTING]?.length && (
          <details className="mb-4">
            <Text
              as="summary"
              color="headline"
              variant="body-bold"
              className="mb-4"
            >
              <FormattedMessage
                id="bulkUploadResults.existingSummary"
                defaultMessage="{numExisting} {numExisting, plural, one {user is already added to the enterprise.} other {users are already added to the enterprise.}}"
                values={{ numExisting: grouped[EXISTING].length }}
              />
            </Text>
            <ul className="list-unstyled pl-4  mt-3">
              {grouped[EXISTING].map((existing) => (
                <li key={existing.email}>{existing.email!}</li>
              ))}
            </ul>
          </details>
        )}
        {!!grouped[DUPLICATE_DICOM_FIELD]?.length && (
          <details className="mb-4">
            <Text
              as="summary"
              color="headline"
              variant="body-bold"
              className="mb-4"
            >
              <FormattedMessage
                id="bulkUploadResults.duplicateDicomFieldSummary"
                defaultMessage="{numUsers} {numUsers, plural, one {user has an existing DICOM field value.} other {users have existing DICOM field values.}}"
                values={{ numUsers: grouped[DUPLICATE_DICOM_FIELD].length }}
              />
            </Text>
            <ul className="list-unstyled pl-4  mt-3">
              {grouped[DUPLICATE_DICOM_FIELD].map((user) => (
                <li key={user.email}>{user.email!}</li>
              ))}
            </ul>
          </details>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>
          <FormattedMessage {...actionMessages.done} />
        </Button>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(BulkUploadResults, {
  failures: graphql`
    fragment BulkUploadResults_failures on BulkDomainUserCreateFailure
    @relay(plural: true) {
      code
      email
    }
  `,
  undeletedUsers: graphql`
    fragment BulkUploadResults_undeletedUsers on DomainUser
    @relay(plural: true) {
      id
      type
      email
      profile {
        name
      }
      ...domainUser_defaultValue
      ...DomainMemberDetailFieldSet_domainUser
      ...DomainMemberDeleteFieldSet_domainUser
    }
  `,
});
