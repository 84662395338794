/**
 * @generated SignedSource<<d72c64f9c3465641bfd725ce2cc856d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitStudyRefreshModalDestinationStudyQuery$variables = {
  id: string;
};
export type SplitStudyRefreshModalDestinationStudyQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SplitStudyConfirmDeleteStudy_destinationStudy" | "SplitStudyConfirmPatientDataConflictContent_destinationStudy">;
  } | null;
};
export type SplitStudyRefreshModalDestinationStudyQuery = {
  response: SplitStudyRefreshModalDestinationStudyQuery$data;
  variables: SplitStudyRefreshModalDestinationStudyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SplitStudyRefreshModalDestinationStudyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SplitStudyConfirmPatientDataConflictContent_destinationStudy"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SplitStudyConfirmDeleteStudy_destinationStudy"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SplitStudyRefreshModalDestinationStudyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessionNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Patient",
                "kind": "LinkedField",
                "name": "patient",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "birthDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "medicalRecordNumber",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nameFirst",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nameLast",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nameMiddle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "namePrefix",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nameSuffix",
                        "storageKey": null
                      }
                    ],
                    "type": "PatientInterface",
                    "abstractKey": "__isPatientInterface"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "practiceType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VetPatient",
                "kind": "LinkedField",
                "name": "vetPatient",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientNameLast",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientOrganizationName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Study",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7dcea473aad5ae605590209d4ecd6a8c",
    "id": null,
    "metadata": {},
    "name": "SplitStudyRefreshModalDestinationStudyQuery",
    "operationKind": "query",
    "text": "query SplitStudyRefreshModalDestinationStudyQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...SplitStudyConfirmPatientDataConflictContent_destinationStudy\n    ...SplitStudyConfirmDeleteStudy_destinationStudy\n    id\n  }\n}\n\nfragment PatientName_patient on PatientInterface {\n  __isPatientInterface: __typename\n  nameFirst\n  nameLast\n  nameMiddle\n  namePrefix\n  nameSuffix\n}\n\nfragment SplitStudyConfirmDeleteStudy_destinationStudy on Study {\n  ...StudyTitle_study\n}\n\nfragment SplitStudyConfirmPatientDataConflictContent_destinationStudy on Study {\n  accessionNumber\n  patient {\n    sex\n    birthDate\n    medicalRecordNumber\n    ...PatientName_patient\n    id\n  }\n}\n\nfragment StudyTitle_study on Study {\n  practiceType\n  patient {\n    ...PatientName_patient\n    id\n  }\n  vetPatient {\n    ...VetPatientName_vetPatient\n  }\n}\n\nfragment VetPatientName_vetPatient on VetPatient {\n  clientNameLast\n  clientOrganizationName\n  name\n}\n"
  }
};
})();

(node as any).hash = "1c885cdb39a081d501ec7388eef87940";

export default node;
