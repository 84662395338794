import Layout from '@4c/layout';
import { createFragmentContainer, graphql } from 'react-relay';

import SplitStudyRefreshSelectDraftItem from './SplitStudyRefreshSelectDraftItem';
import { SplitStudyRefreshSelectDraftStudyList_studies$data as Studies } from './__generated__/SplitStudyRefreshSelectDraftStudyList_studies.graphql';

interface Props {
  studies: Studies;
  currentStudyId: string;
  className?: string;
}

function SplitStudyRefreshSelectDraftStudyList({
  className,
  studies,
  currentStudyId,
}: Props) {
  const otherStudies = studies.filter((study) => study.id !== currentStudyId);

  return (
    <Layout direction="column" className={className}>
      {otherStudies.map((study) => (
        <SplitStudyRefreshSelectDraftItem study={study} />
      ))}
    </Layout>
  );
}

export default createFragmentContainer(SplitStudyRefreshSelectDraftStudyList, {
  studies: graphql`
    fragment SplitStudyRefreshSelectDraftStudyList_studies on Study
    @relay(plural: true) {
      id
      ...SplitStudyRefreshSelectDraftItem_study
      ...SplitStudyConfirmPatientDataConflictContent_destinationStudy
      ...SplitStudyConfirmDeleteStudy_destinationStudy
    }
  `,
});
