import { graphql } from 'react-relay';

import LaunchDarklyRoute from 'components/LaunchDarklyRoute';
import Route from 'components/Route';
import { checkRoutePermission, isFetched404 } from 'utils/RouteAccessControl';

import { domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query as DomainUpdateWorksheetTemplateFormQuery } from './__generated__/domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query.graphql';
import WorksheetsPageRenderer from './components/WorksheetsPageRenderer';

export const domainWorksheetsRoute = (
  <LaunchDarklyRoute
    path="worksheets"
    prerender={checkRoutePermission('studyDocumentation', 'BASIC', 'tenant')}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/DomainWorksheetSettingsContent'
        ).then((m) => m.default)
      }
      query={graphql`
        query domainWorksheetRoutes_DomainWorksheetsSettingsContent_Query(
          $sort: [WorksheetTemplateSorting!]
        ) {
          viewer {
            ...DomainWorksheetSettingsContent_viewer @arguments(sort: $sort)
          }
        }
      `}
      prepareVariables={(variables, { location: { query } }) => ({
        ...variables,
        sort: query.sort,
      })}
      render={({ Component, props, match }) => {
        return (
          <WorksheetsPageRenderer
            Component={Component}
            props={props}
            match={match}
          />
        );
      }}
    />
    <Route
      path="-/new"
      fullScreen
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/CreateWorksheetTemplateForm'
        ).then((m) => m.default)
      }
      query={graphql`
        query domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query {
          viewer {
            workflowConfig: domain {
              ...CreateWorksheetTemplateForm_workflowConfig
            }
            connectivityConfig: domain {
              ...CreateWorksheetTemplateForm_connectivityConfig
            }
          }
        }
      `}
      renderFetched={({ Component, props }) => (
        <Component {...props} {...props.viewer} />
      )}
    />
    <Route fullScreen path=":templateHandle">
      <Route<DomainUpdateWorksheetTemplateFormQuery>
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateWorksheetTemplateForm'
          ).then((m) => m.default)
        }
        query={graphql`
          query domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query(
            $templateHandle: String!
          ) {
            template: worksheetTemplate(handle: $templateHandle) {
              isPublished
              ...UpdateWorksheetTemplateForm_template
            }
            viewer {
              domain {
                ...UpdateWorksheetTemplateForm_connectivityConfig
              }
            }
          }
        `}
        prerender={isFetched404((preRenderProps) => {
          if (!preRenderProps?.props?.template?.isPublished) return;

          checkRoutePermission(
            'studyDocumentationManagement',
            'BASIC',
            'domain',
          )(preRenderProps);
        })}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer!.domain} />
        )}
      />
      <Route path="prefills">
        <Route
          path="-/new"
          getComponent={() =>
            import(
              /* webpackChunkName: "admin" */ './components/CreateWorksheetPrefillForm'
            ).then((m) => m.default)
          }
          query={graphql`
            query domainWorksheetRoutes_DomainCreateWorksheetPrefill_Query(
              $templateHandle: String!
            ) {
              template: worksheetTemplate(handle: $templateHandle) {
                ...CreateWorksheetPrefillForm_template
              }
            }
          `}
        />
        <Route
          path=":prefillHandle"
          getComponent={() =>
            import(
              /* webpackChunkName: "admin" */ './components/UpdateWorksheetPrefillForm'
            ).then((m) => m.default)
          }
          query={graphql`
            query domainWorksheetRoutes_DomainUpdateWorksheetPrefill_Query(
              $prefillHandle: String!
            ) {
              prefill: worksheetPrefill(handle: $prefillHandle) {
                ...UpdateWorksheetPrefillForm_prefill
              }
            }
          `}
        />
      </Route>
    </Route>
  </LaunchDarklyRoute>
);
