import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import { useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import Abbr from 'components/Abbr';
import accountMessages from 'messages/account';
import patientMessages, { sexMessages } from 'messages/patient';

import { Sex } from '../../schemas/humanStudyInfo';
import SplitStudyPatientDatePickerField from './SplitStudyPatientDatePickerField';
import { SplitStudyPatientHumanFields_study$data as Study } from './__generated__/SplitStudyPatientHumanFields_study.graphql';

interface Props {
  study: Study;
  disabled?: boolean;
}

function SplitStudyPatientHumanFields({ study, disabled }: Props) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  return (
    <>
      <Form.FieldGroup
        fluid
        name="nameFirst"
        autoComplete="off"
        variant="secondary"
        label={accountMessages.firstName}
        placeholder={accountMessages.firstName}
        disabled={disabled}
      />
      {study.patient?.nameMiddle && (
        <Form.FieldGroup
          fluid
          name="nameMiddle"
          variant="secondary"
          autoComplete="off"
          label={accountMessages.middleName}
          placeholder={accountMessages.middleName}
          disabled={disabled}
        />
      )}
      <Form.FieldGroup
        fluid
        name="nameLast"
        autoComplete="off"
        variant="secondary"
        label={accountMessages.lastName}
        placeholder={accountMessages.lastName}
        disabled={disabled}
      />
      {study.patient?.namePrefix && (
        <Form.FieldGroup
          fluid
          name="namePrefix"
          variant="secondary"
          autoComplete="off"
          label={accountMessages.prefix}
          placeholder={accountMessages.prefix}
          disabled={disabled}
        />
      )}
      {study.patient?.nameSuffix && (
        <Form.FieldGroup
          fluid
          name="nameSuffix"
          variant="secondary"
          autoComplete="off"
          label={accountMessages.suffix}
          placeholder={accountMessages.suffix}
          disabled={disabled}
        />
      )}
      <Form.FieldGroup
        fluid
        variant="secondary"
        name="medicalRecordNumber"
        label={
          <Abbr
            label={patientMessages.medicalRecordNumber}
            message={patientMessages.medicalRecordNumber}
          />
        }
        placeholder={patientMessages.medicalRecordNumber}
        disabled={disabled}
      />
      <SplitStudyPatientDatePickerField
        open={datePickerOpen}
        setOpen={setDatePickerOpen}
        disabled={disabled}
        label={
          <Abbr
            label={patientMessages.dateOfBirthLabel}
            message={patientMessages.birthDate}
          />
        }
      />
      <Form.FieldGroup
        fluid
        dropUp
        name="sex"
        variant="secondary"
        menuVariant="dark"
        as={DropdownList}
        label={patientMessages.sex}
        placeholder={patientMessages.sex}
        data={Object.values(Sex)}
        textField={(v) => v && sexMessages[v]}
        disabled={disabled}
      />
    </>
  );
}

export default createFragmentContainer(SplitStudyPatientHumanFields, {
  study: graphql`
    fragment SplitStudyPatientHumanFields_study on Study {
      id
      hasGeneratedAccessionNumber
      patient {
        namePrefix
        nameSuffix
        nameMiddle
      }
    }
  `,
});
