/**
 * @generated SignedSource<<4701785deb1f5b50d1846d58e1b1f31a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IndicatorRingChartCard_byExamType$data = {
  readonly byExamType: ReadonlyArray<{
    readonly examType: {
      readonly name: string | null;
      readonly systemId: string | null;
    } | null;
    readonly numReviewRequested: number;
    readonly numReviewed: number;
    readonly numUnsubmitted: number;
  }> | null;
  readonly " $fragmentType": "IndicatorRingChartCard_byExamType";
};
export type IndicatorRingChartCard_byExamType$key = {
  readonly " $data"?: IndicatorRingChartCard_byExamType$data;
  readonly " $fragmentSpreads": FragmentRefs<"IndicatorRingChartCard_byExamType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndicatorRingChartCard_byExamType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EduStudyExamTypeStatistics",
      "kind": "LinkedField",
      "name": "byExamType",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamType",
          "kind": "LinkedField",
          "name": "examType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "systemId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUnsubmitted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numReviewRequested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numReviewed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EduStudyStatistics",
  "abstractKey": null
};

(node as any).hash = "8f3011943b34274696d661697ee1fd7b";

export default node;
