import { OpenDialogOptions } from '@bfly/ui2/DialogContext';
import useDialog from '@bfly/ui2/useDialog';
import useMutationTrigger, {
  type UseMutationTriggerOptions,
} from '@bfly/ui2/useMutationTrigger';
import { MutationParametersWithError } from '@bfly/ui2/useMutationWithError';
import { ReactNode, useCallback } from 'react';
import { GraphQLTaggedNode } from 'relay-runtime';

export interface UseMutationTriggerWithConfirmOptions<
  T extends MutationParametersWithError,
> extends UseMutationTriggerOptions<T> {
  content: ReactNode;
  openDialogOptions: OpenDialogOptions<boolean>;
}

export default function useMutationTriggerWithConfirm<
  T extends MutationParametersWithError,
>(
  mutation: GraphQLTaggedNode,
  {
    content,
    openDialogOptions,
    ...mutationConfig
  }: UseMutationTriggerWithConfirmOptions<T>,
) {
  const dialog = useDialog();
  const [mutate, details] = useMutationTrigger<T>(mutation, mutationConfig);

  return [
    useCallback(async () => {
      const confirmed = await dialog.open(content, openDialogOptions);

      if (confirmed) {
        return mutate();
      }
      return undefined;
    }, [dialog, content, openDialogOptions, mutate]),
    details,
  ] as const;
}
