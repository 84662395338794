import { MessageDescriptor, defineMessages } from 'react-intl';

import type {
  InterpretationAccuracy,
  StandardQaFeedback,
} from 'schemas/standardQa';

export type { InterpretationAccuracy, StandardQaFeedback };

export const imageAccuracyLabels: Record<
  InterpretationAccuracy,
  MessageDescriptor
> = defineMessages({
  TRUE_POSITIVE: {
    id: 'standardQa.imageAccuracyLabel.TRUE_POSITIVE',
    defaultMessage: 'TP',
  },
  TRUE_NEGATIVE: {
    id: 'standardQa.imageAccuracyLabel.TRUE_NEGATIVE',
    defaultMessage: 'TN',
  },
  FALSE_POSITIVE: {
    id: 'standardQa.imageAccuracyLabel.FALSE_POSITIVE',
    defaultMessage: 'FP',
  },
  FALSE_NEGATIVE: {
    id: 'standardQa.imageAccuracyLabel.FALSE_NEGATIVE',
    defaultMessage: 'FN',
  },
});

export const imageAccuracyCopy: Record<
  InterpretationAccuracy,
  MessageDescriptor
> = defineMessages({
  TRUE_POSITIVE: {
    id: 'standardQa.imageAccuracyCopy.TRUE_POSITIVE',
    defaultMessage: 'True positive',
  },
  TRUE_NEGATIVE: {
    id: 'standardQa.imageAccuracyCopy.TRUE_NEGATIVE',
    defaultMessage: 'True negative',
  },
  FALSE_POSITIVE: {
    id: 'standardQa.imageAccuracyCopy.FALSE_POSITIVE',
    defaultMessage: 'False positive',
  },
  FALSE_NEGATIVE: {
    id: 'standardQa.imageAccuracyCopy.FALSE_NEGATIVE',
    defaultMessage: 'False negative',
  },
});

export const feedbackLabels: Record<StandardQaFeedback, MessageDescriptor> =
  defineMessages({
    ADJUST_GAIN: {
      id: 'standardQa.feedbackLabel.ADJUST_GAIN',
      defaultMessage: 'Adjust gain',
    },
    ADJUST_DEPTH: {
      id: 'standardQa.feedbackLabel.ADJUST_DEPTH',
      defaultMessage: 'Adjust depth',
    },
    INCORRECT_ORIENTATION: {
      id: 'standardQa.feedbackLabel.INCORRECT_ORIENTATION',
      defaultMessage: 'Incorrect orientation',
    },
    INCORRECT_TRANSDUCER: {
      id: 'standardQa.feedbackLabel.INCORRECT_TRANSDUCER',
      defaultMessage: 'Incorrect transducer',
    },
    INCORRECT_PRESET: {
      id: 'standardQa.feedbackLabel.INCORRECT_PRESET',
      defaultMessage: 'Incorrect preset',
    },
    INCOMPLETE_MEASUREMENTS: {
      id: 'standardQa.feedbackLabel.INCOMPLETE_MEASUREMENTS',
      defaultMessage: 'Incomplete measurements',
    },
    MISSING_STANDARD_VIEWS: {
      id: 'standardQa.feedbackLabel.MISSING_STANDARD_VIEWS',
      defaultMessage: 'Missing standard views',
    },
    NOT_CLINICALLY_INDICATED: {
      id: 'standardQa.feedbackLabel.NOT_CLINICALLY_INDICATED',
      defaultMessage: 'Not clinically indicated',
    },
  });

export type ImageQualityOption = 1 | 2 | 3 | 4 | 5;

export const imageQualityOptions: ImageQualityOption[] = [1, 2, 3, 4, 5];

export const imageQualityLabels: Record<
  ImageQualityOption,
  MessageDescriptor
> = defineMessages({
  1: {
    id: 'standardQaQuestions.imageQualityLabel.1',
    defaultMessage:
      'No recognizable structures, no objective data can be gathered',
  },
  2: {
    id: 'standardQaQuestions.imageQualityLabel.2',
    defaultMessage:
      'Minimally recognizable structures but insufficient for diagnosis.',
  },
  3: {
    id: 'standardQaQuestions.imageQualityLabel.3',
    defaultMessage:
      'Minimal criteria met for diagnosis, recognizable structures but with some technical or other flaws',
  },
  4: {
    id: 'standardQaQuestions.imageQualityLabel.4',
    defaultMessage:
      'Minimal criteria met for diagnosis, all structures imaged well and diagnosis easily supported',
  },
  5: {
    id: 'standardQaQuestions.imageQualityLabel.5',
    defaultMessage:
      'Minimal criteria met for diagnosis, all structures imaged with excellent image quality and diagnosis completely supported',
  },
});
