/**
 * @generated SignedSource<<c7ef553a0b95707fcb96cccb3d5377d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModalityWorklistItemPhysicianInformation_worklistItem$data = {
  readonly dicomDocument: Record<string, any> | null;
  readonly " $fragmentType": "ModalityWorklistItemPhysicianInformation_worklistItem";
};
export type ModalityWorklistItemPhysicianInformation_worklistItem$key = {
  readonly " $data"?: ModalityWorklistItemPhysicianInformation_worklistItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModalityWorklistItemPhysicianInformation_worklistItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModalityWorklistItemPhysicianInformation_worklistItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomDocument",
      "storageKey": null
    }
  ],
  "type": "ModalityWorklistItem",
  "abstractKey": null
};

(node as any).hash = "e7be71c61bfcd265fecff2b4542cc692";

export default node;
