/**
 * @generated SignedSource<<3a077ed1aee3a5993d0a3b560447bf82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type scanLab_EduStudy_Query$variables = {
  eduStudyId: string;
};
export type scanLab_EduStudy_Query$data = {
  readonly study: {
    readonly " $fragmentSpreads": FragmentRefs<"EduStudyPage_study">;
  } | null;
};
export type scanLab_EduStudy_Query = {
  response: scanLab_EduStudy_Query$data;
  variables: scanLab_EduStudy_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eduStudyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eduStudyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "scanLab_EduStudy_Query",
    "selections": [
      {
        "alias": "study",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EduStudyPage_study"
              }
            ],
            "type": "EduStudy",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "scanLab_EduStudy_Query",
    "selections": [
      {
        "alias": "study",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EduStandardQa",
                "kind": "LinkedField",
                "name": "standardQa",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageQuality",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "feedback",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "comments",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "createdBy",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Avatar",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Thumbnail",
                            "kind": "LinkedField",
                            "name": "thumbnails",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "width",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "ThumbnailedInterface",
                        "abstractKey": "__isThumbnailedInterface"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reviewRequestedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zonesCompleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalZones",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "readyAt",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "capturedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamType",
                "kind": "LinkedField",
                "name": "examTypes",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "EduStudy",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63609bd0dccc0e0fc67f60f9fb6735cf",
    "id": null,
    "metadata": {},
    "name": "scanLab_EduStudy_Query",
    "operationKind": "query",
    "text": "query scanLab_EduStudy_Query(\n  $eduStudyId: ID!\n) {\n  study: node(id: $eduStudyId) {\n    __typename\n    ... on EduStudy {\n      ...EduStudyPage_study\n    }\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment EduStudyPage_study on EduStudy {\n  ...EduStudySidebar_study\n  handle\n  createdBy {\n    id\n    handle\n    name\n    ...Avatar_userProfile\n  }\n  capturedAt\n  examTypes {\n    name\n    id\n  }\n  reviewRequestedAt\n  standardQa {\n    createdAt\n  }\n}\n\nfragment EduStudySidebar_study on EduStudy {\n  ...eduStandardQa_eduStudy\n  id\n  createdBy {\n    id\n  }\n  reviewRequestedAt\n  standardQa {\n    createdAt\n    createdBy {\n      name\n      id\n    }\n  }\n  zonesCompleted\n  totalZones\n  readyAt\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n\nfragment eduStandardQa_eduStudy on EduStudy {\n  standardQa {\n    imageQuality\n    feedback\n    comments\n  }\n}\n"
  }
};
})();

(node as any).hash = "1029c4013ea7f3717a4eae0343bb674b";

export default node;
