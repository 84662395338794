/**
 * @generated SignedSource<<1904f6edc5ee4efcf3180aa789243b40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LmsCourseSorting = "NAME_ASC" | "NAME_DESC" | "%future added value";
export type education_SelectCoursesGrid_Query$variables = {
  search?: string | null;
  sort?: ReadonlyArray<LmsCourseSorting> | null;
  tagHandles?: ReadonlyArray<string> | null;
};
export type education_SelectCoursesGrid_Query$data = {
  readonly lms: {
    readonly " $fragmentSpreads": FragmentRefs<"SelectCoursesGrid_lms">;
  } | null;
};
export type education_SelectCoursesGrid_Query = {
  response: education_SelectCoursesGrid_Query$data;
  variables: education_SelectCoursesGrid_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tagHandles"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v2 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v3 = {
  "kind": "Variable",
  "name": "tagHandles",
  "variableName": "tagHandles"
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "education_SelectCoursesGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "SelectCoursesGrid_lms"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "education_SelectCoursesGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LmsCourseConnection",
            "kind": "LinkedField",
            "name": "courseConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LmsCourseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LmsCourse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "search",
              "sort",
              "tagHandles"
            ],
            "handle": "connection",
            "key": "lms_courseConnection",
            "kind": "LinkedHandle",
            "name": "courseConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d14f33fd55e9d66baf94ef68f04bed1d",
    "id": null,
    "metadata": {},
    "name": "education_SelectCoursesGrid_Query",
    "operationKind": "query",
    "text": "query education_SelectCoursesGrid_Query(\n  $search: String\n  $sort: [LmsCourseSorting!]\n  $tagHandles: [String!]\n) {\n  lms {\n    ...SelectCoursesGrid_lms_253s6h\n  }\n}\n\nfragment SelectCoursesGrid_lms_253s6h on Lms {\n  courseConnection(first: 30, search: $search, sort: $sort, tagHandles: $tagHandles) {\n    edges {\n      node {\n        id\n        name\n        ...SelectCoursesRow_lmsCourse\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SelectCoursesRow_lmsCourse on LmsCourse {\n  id\n  name\n  description\n}\n"
  }
};
})();

(node as any).hash = "6a101eaae0e55f950c3f275456c29d28";

export default node;
