import HttpError from 'found/HttpError';
import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import type { Match, RelayRouteRenderArgs } from 'components/Route';
import accountRouteUrls from 'routes/account';
import { routes as archiveRoutes } from 'routes/archive';
import { routes } from 'routes/config';
import {
  getLastArchiveHandle,
  getLastOrganizationSlug,
  getRedirectLocation,
} from 'utils/EagerRedirectUtils';

import { rootRedirect_ViewerQuery as RootRedirectQuery } from './__generated__/rootRedirect_ViewerQuery.graphql';

export type { RootRedirectQuery };
/* 
  Redirect flow:
    / route: rootRedirect
      1. rootRedirect.getQuery execution - redirecting based on localStore data
      2. rootRedirect execution - few additional checks and redirect to rootOrganization(which triggers 3. and then 4.)
    /org-slug route: organizationRedirect
      3. organizationRedirect.getQuery
      4. organizationRedirect
*/

function getQuery(match: Match) {
  const { location } = match;

  const organizationSlug = getLastOrganizationSlug(match);

  if (organizationSlug) {
    const archiveHandle = getLastArchiveHandle(match, organizationSlug);

    const redirect = getRedirectLocation(match, organizationSlug);

    if (redirect) {
      throw new RedirectException(redirect);
    }

    if (archiveHandle) {
      // Redirect in the query aggregation phase. We don't need server data.
      throw new RedirectException({
        pathname: archiveRoutes.archive({ organizationSlug, archiveHandle }),
        state: {
          isRootRedirect: true,
          prevLocation: location,
        },
      });
    }

    throw new RedirectException({
      pathname: routes.rootOrganization({ organizationSlug }),
      state: {
        isRootRedirect: true,
        prevLocation: location,
      },
    });
  }

  // XXX: We can't fetch organization archives here, as we're not working in
  //  an organization context.
  return graphql`
    query rootRedirect_ViewerQuery {
      viewer {
        membershipRole {
          name
          permissions
        }
        memberships {
          organization {
            slug
          }
        }
      }
    }
  `;
}

function rootRedirect({
  props,
}: RelayRouteRenderArgs<RootRedirectQuery['response']>) {
  if (!props) {
    return;
  }

  if (!props.viewer) {
    // This shouldn't be possible; we shouldn't be here if we're not logged in.
    throw new HttpError(500);
  }

  if (!props.viewer.memberships!.length) {
    throw new RedirectException(
      props.viewer.membershipRole?.name === 'Enterprise Administrator'
        ? 'enterpriseSettings'
        : accountRouteUrls.inactiveAccount(),
    );
  }

  const { organization } = props.viewer.memberships![0]!;
  if (!organization) {
    // This shouldn't be possible; we should have already redirected the user
    // to the "inactive account" page if he or she has no organizations.
    throw new HttpError(500);
  }

  throw new RedirectException(
    routes.rootOrganization({ organizationSlug: organization.slug! }),
  );
}

rootRedirect.getQuery = getQuery;

export default rootRedirect;
