import clsx from 'clsx';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';

import { StudyStatus } from 'utils/StudyConstants';

import { useVariation } from './LaunchDarklyContext';

export const studyStatusMessages: Record<
  StudyStatus | 'ineligibleForQa',
  MessageDescriptor
> = defineMessages({
  ineligibleForQa: {
    defaultMessage: 'Ineligible for QA',
    id: 'studyStatus.ineligibleForQa',
  },
  [StudyStatus.PENDING_ATTESTATION]: {
    defaultMessage: 'Pending attestation',
    id: 'studyStatus.pendingAttestation',
  },
  [StudyStatus.DRAFT]: {
    defaultMessage: 'Draft',
    id: 'studyStatus.draft',
  },
  [StudyStatus.NEEDS_REVIEW]: {
    defaultMessage: 'Needs QA review',
    id: 'studyStatus.NEEDS_REVIEW',
  },
  [StudyStatus.REVIEWED]: {
    defaultMessage: 'QA reviewed',
    id: 'studyStatus.qaReviewed',
  },
  [StudyStatus.FINALIZED]: {
    defaultMessage: 'Finalized',
    id: 'studyStatus.finalized',
  },
});

export const StudyStatusColor = {
  [StudyStatus.PENDING_ATTESTATION]: 'bg-red-40',
  [StudyStatus.DRAFT]: 'bg-orange',
};

export interface StudyStatusProps {
  status: StudyStatus;
  colorIndicator?: 'always' | 'if-available' | 'hidden';
  hideFinalized?: boolean;
}

function StudyStatusRenderer({
  status,
  colorIndicator = 'if-available',
  hideFinalized = false,
}: StudyStatusProps) {
  const canQa = useVariation('worksheets-review');
  const color = StudyStatusColor[status];
  return (
    <div className="flex items-center">
      {(colorIndicator === 'always' ||
        (colorIndicator === 'if-available' && color)) && (
        <span
          className={clsx('rounded-full inline-block h-3 w-3 mr-2', color)}
        />
      )}
      {status === StudyStatus.FINALIZED && !hideFinalized ? (
        <FormattedMessage
          {...(canQa
            ? studyStatusMessages.ineligibleForQa
            : studyStatusMessages[StudyStatus.FINALIZED])}
        />
      ) : (
        studyStatusMessages[status] && (
          <FormattedMessage {...studyStatusMessages[status]} />
        )
      )}
    </div>
  );
}

export default StudyStatusRenderer;
