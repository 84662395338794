import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import isEqual from 'lodash/isEqual';
import { graphql, readInlineData } from 'react-relay';
import { RecordSourceSelectorProxy } from 'relay-runtime';
import { type QuickFilters } from 'schemas/examQuickFilters';
import { StudySortArgument } from 'utils/StudyPagination';
import { Maybe } from 'yup/lib/types';

import { Match } from 'components/Route';
import { StudySearchCriterionKey } from 'components/__generated__/SearchTags_studySearchCriteria.graphql';
import { PaginationVariables } from 'hooks/usePagedConnection';
import { STUDY_GRID_VIEW_PAGE_SIZE } from 'hooks/usePreferredStudyListView';
import { getSearchData } from 'hooks/useSearchState';
import {
  serializeToDateRange,
  serializeToDateTimeRange,
  serializeToDateTimes,
} from 'schemas/dateRange';
import { arrayFromString } from 'utils/LocationUtils';
import {
  STUDY_GRID_PAGE_SIZE,
  StudyListFilter,
  StudyStatus,
  isTodoFilter,
} from 'utils/StudyConstants';

import type { StudyFilters_study$key as StudyKey } from './__generated__/StudyFilters_study.graphql';

export function prepareStudySort(sort?: string): StudySortArgument[] {
  if (!sort) return ['READY_AT_DESC'];
  const arraySort = arrayFromString
    .cast(sort)
    ?.map((elem) => elem?.trim()) as StudySortArgument[];

  return (
    arraySort.length === 1 && arraySort[0].startsWith('READY_AT_')
      ? arraySort
      : [...arraySort, 'READY_AT_DESC']
  ) as any;
}

export function prepareNewStudySort(sort?: string): StudySortArgument[] {
  if (!sort) return ['CAPTURED_AT_DESC'];
  const arraySort = arrayFromString
    .cast(sort)
    ?.map((elem) => elem?.trim()) as StudySortArgument[];

  return (
    arraySort.length === 1 && arraySort[0].startsWith('CAPTURED_AT_')
      ? arraySort
      : [...arraySort, 'CAPTURED_AT_DESC']
  ) as any;
}

interface StudyStatusVariables {
  hasRequestedFinalizationFromViewer?: boolean[] | null | undefined;
  isViewerFavorite?: boolean | null | undefined;
  workflowStatus?: StudyStatus[] | null | undefined;
}

/** Returns variables for studyStatuses */
function studyListStatusToStudyVariables(
  status: StudyListFilter | StudyStatus = StudyListFilter.ALL,
): StudyStatusVariables | undefined {
  switch (status) {
    case StudyStatus.FINALIZED:
    case StudyListFilter.COMPLETED:
      return {
        workflowStatus: [
          StudyStatus.REVIEWED,
          StudyStatus.FINALIZED,
          StudyStatus.NEEDS_REVIEW,
        ],
      };

    case StudyStatus.DRAFT:
    case StudyListFilter.DRAFTS:
    case StudyListFilter.MY_DRAFTS:
      return { workflowStatus: [StudyStatus.DRAFT] };

    case StudyListFilter.NEEDS_REVIEW:
      return { workflowStatus: [StudyStatus.NEEDS_REVIEW] };

    case StudyStatus.REVIEWED:
      return { workflowStatus: [StudyStatus.REVIEWED] };

    case StudyStatus.PENDING_ATTESTATION:
    case StudyListFilter.PENDING_ATTESTATION:
      return { workflowStatus: [StudyStatus.PENDING_ATTESTATION] };

    case StudyListFilter.MY_SIGNATURE_REQUESTED:
      return {
        hasRequestedFinalizationFromViewer: [true],
      };
    case StudyListFilter.FAVORITES:
      return { isViewerFavorite: true };
    case StudyListFilter.UNASSIGNED:
    case StudyListFilter.ALL:
    default:
      return undefined;
  }
}

interface StudyStatusSearchVariables {
  hasRequestedFinalizationFromViewer?: boolean[] | null | undefined;
  author?: string[] | string | null | undefined;
  favoritedBy?: string[] | string | null | undefined;
  status?: StudyStatus[] | null | undefined;
  requestedFinalizer?: string[] | string | null | undefined;
}

/** Same as up, except it returns valeus for search API */
function studyListStatusToStudySearchVariables(
  status: StudyListFilter | StudyStatus = StudyListFilter.ALL,
  viewerId: string | null,
): StudyStatusSearchVariables | undefined {
  switch (status) {
    case StudyStatus.FINALIZED:
    case StudyListFilter.COMPLETED:
      return {
        status: [
          StudyStatus.REVIEWED,
          StudyStatus.FINALIZED,
          StudyStatus.NEEDS_REVIEW,
        ],
      };

    case StudyStatus.DRAFT:
    case StudyListFilter.DRAFTS:
    case StudyListFilter.MY_DRAFTS:
      return { status: [StudyStatus.DRAFT], author: viewerId };

    case StudyListFilter.NEEDS_REVIEW:
      return { status: [StudyStatus.NEEDS_REVIEW] };

    case StudyStatus.REVIEWED:
      return { status: [StudyStatus.REVIEWED] };

    case StudyStatus.PENDING_ATTESTATION:
    case StudyListFilter.PENDING_ATTESTATION:
      return { status: [StudyStatus.PENDING_ATTESTATION] };

    case StudyListFilter.MY_SIGNATURE_REQUESTED:
      return {
        status: [StudyStatus.PENDING_ATTESTATION],
        requestedFinalizer: viewerId,
      };
    case StudyListFilter.FAVORITES:
      return { favoritedBy: viewerId };
    case StudyListFilter.UNASSIGNED:
    case StudyListFilter.ALL:
    default:
      return undefined;
  }
}

const getStatusVariables = (
  status: StudyListFilter | StudyStatus = StudyListFilter.ALL,
  viewerId: string | null,
  isUsingSearchAPI = false,
) =>
  isUsingSearchAPI
    ? studyListStatusToStudySearchVariables(status, viewerId)
    : studyListStatusToStudyVariables(status);

interface StudyQueryVariables {
  primaryAuthor?: Maybe<string[]>;
  examTypes?: Maybe<string[]>;
  readyAtMax?: Maybe<string>;
  readyAtMin?: Maybe<string>;
  archiveHandle?: Maybe<string[]>;

  sort?: Maybe<StudySortArgument[]>;
}

/**
 * parses ExamQuickFilters into a common subset of variables
 * used by multiple queries
 */
function prepareStudyListQuickFilterVariables(
  location: Match['location'],
  isUsingSearchAPI = false,
) {
  if (!location.state?.studyFilters) {
    return undefined;
  }

  const value = location.state?.studyFilters as QuickFilters;

  const { dateRange, authors, archives, examTypes, status, organization } =
    value;

  const { startDate, endDate } = serializeToDateTimes(dateRange);

  const base = {
    archive: archives,
    author: authors?.map((a) => a.id),
  };

  if (isUsingSearchAPI) {
    return {
      ...base,
      status,
      examType: examTypes,
      organization: organization?.map((o) => o.id) || null,
      capturedAt: {
        startDate: startDate || null,
        endDate: endDate || null,
      },
    };
  }

  return {
    ...base,
    workflowStatus: status,
    examTypes,
    organization: organization?.map((o) => o.id) || null,
    capturedAtMin: startDate || null,
    capturedAtMax: endDate || null,
  };
}

interface ArchiveStudyQueryVariables
  extends StudyStatusVariables,
    Omit<StudyQueryVariables, 'archiveHandle'> {
  archiveHandle?: string;
}

/* Old archive view uses readyAtMin and readyAtMax while new one uses capturedAtMin and capturedAtMax for date filtering */
export function prepareNewArchiveStudyQueryVariables<
  T extends ArchiveStudyQueryVariables & PaginationVariables,
>(variables: T, { location, context }: Match, isUsingSearchAPI = false): T {
  const { query } = location;
  const status = query.status as StudyListFilter;
  const viewerId = context.viewerLocalId;

  const filters = prepareStudyListQuickFilterVariables(
    location,
    isUsingSearchAPI,
  );
  const statusVariables =
    !filters &&
    !!location.query.status &&
    getStatusVariables(
      location.query.status as StudyListFilter,
      viewerId,
      isUsingSearchAPI,
    );

  const base = {
    ...variables,
    first: variables.last ? null : STUDY_GRID_PAGE_SIZE,
    ...statusVariables,
    // override the quick filter `null`
    archiveHandle: variables.archiveHandle,
    sort: prepareNewStudySort(query?.sort),
  };

  if (isUsingSearchAPI) {
    return {
      ...base,
      search: {
        ...filters,
        ...getStatusVariables(status, viewerId, isUsingSearchAPI),
        ...statusVariables,
        archive: variables.archiveHandle,
      },
    };
  }

  return {
    ...base,
    ...filters,
    ...getStatusVariables(status, viewerId, isUsingSearchAPI),
  };
}

interface OrganizationStudyQueryVariables
  extends StudyStatusVariables,
    StudyQueryVariables {
  pageSize?: Maybe<number>;

  userHandle?: Maybe<string[]>;
  studyTagHandles?: Maybe<string[]>;
}

/**
 * A simpler version of the above where there is no
 * dynamic author filter (aside from MY_DRAFTS and UNASSIGNED) in the side
 * panel, instead list filters are powered by ExamQuickFilters
 * which constructs a form value that is similar to eventual filter values
 */
export function prepareNewOrganizationStudyQueryVariables<
  T extends OrganizationStudyQueryVariables & PaginationVariables,
>(
  variables: T,
  { location, params, context }: Match,
  isUsingSearchAPI = false,
): T {
  const { query } = location;
  const status = params.status as StudyListFilter;
  const filters = prepareStudyListQuickFilterVariables(
    location,
    isUsingSearchAPI,
  );
  const viewerId = context.viewerLocalId;
  const statusVariables = getStatusVariables(
    status,
    viewerId,
    isUsingSearchAPI,
  );

  /**
   * Base arguments, that are adjusted for search and storage APIs
   * For storage, they should be spread at the first level
   * For search, they should lay in an object under `search` key
   */
  const baseArguments = {
    ...filters,
    ...statusVariables,
    isDeleted: [false],
  };

  const commonVariables = {
    ...variables,
    first: variables.last ? null : STUDY_GRID_PAGE_SIZE,
    sort: prepareNewStudySort(query.sort),
  };

  if (status === StudyListFilter.MY_DRAFTS) {
    const authorsFilter = query.canSign
      ? { author: [viewerId!], primaryAuthor: null }
      : { primaryAuthor: [viewerId!], author: null };
    if (isUsingSearchAPI) {
      return {
        ...commonVariables,
        search: {
          ...baseArguments,
          ...authorsFilter,
        },
      };
    }
    return {
      ...commonVariables,
      ...baseArguments,
      ...authorsFilter,
      excludedFromStudyLists: false,
    };
  }
  if (isTodoFilter(status)) {
    if (isUsingSearchAPI) {
      return {
        ...commonVariables,
        search: {
          ...baseArguments,
          excludedFromStudyLists: false,
        },
      };
    }
    return {
      ...commonVariables,
      ...baseArguments,
      excludedFromStudyLists: false,
    };
  }

  if (status === StudyListFilter.UNASSIGNED) {
    const unassignedFilter = {
      author: ['@@UNASSIGNED'],
      primaryAuthor: null,
      excludedFromStudyLists: false,
    };
    if (isUsingSearchAPI) {
      return {
        ...commonVariables,
        search: {
          ...baseArguments,
          ...unassignedFilter,
        },
      };
    }
    return {
      ...commonVariables,
      ...baseArguments,
      ...unassignedFilter,
    };
  }

  if (status === StudyListFilter.DELETED) {
    if (isUsingSearchAPI) {
      return {
        ...commonVariables,
        search: {
          ...baseArguments,
          isDeleted: [true],
        },
      };
    }
    return {
      ...commonVariables,
      ...baseArguments,
      isDeleted: [true],
    };
  }

  if (isUsingSearchAPI) {
    return {
      ...commonVariables,
      search: {
        ...baseArguments,
      },
    };
  }

  return { ...commonVariables, ...baseArguments };
}

interface SearchStudyQueryVariables extends PaginationVariables {
  sort?: Maybe<StudySortArgument[]>;
  criteria: { key: StudySearchCriterionKey; value: unknown }[];
}

const filterObjectNulls = (obj) => {
  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value != null) result[key] = value;
  }
  return result;
};

/**
 * Variables for the older search experience.
 *
 * A user can:
 *
 * - search by tag, archive, author, or text
 * - filter by status and date range
 * - sort by grid columns or ready-at
 *
 */
export function prepareSearchStudyQueryVariables(variables, match) {
  const { context, location } = match;
  const { query } = location;
  const viewerId = context.viewerLocalId;
  const searchArchiveHandle = query.archive || null;
  const searchUserHandle = query.createdBy || null;
  const studyTagHandles = query.tag?.length ? query.tag : null;

  // status in the query string is from old list filters
  const statusVariables =
    query.status &&
    getStatusVariables(query.status as StudyListFilter, viewerId);

  return {
    ...variables,
    ...statusVariables,
    pageSize: STUDY_GRID_VIEW_PAGE_SIZE,

    userHandle: query.createdBy ? [query.createdBy] : null,

    sort: prepareNewStudySort(query.sort),
    readyAtMin: query.readyAtMin || null,
    readyAtMax: query.readyAtMax || null,

    search: query.search || null,

    searchArchiveHandle,
    hasArchive: !!searchArchiveHandle,

    searchUserHandle,
    hasUser: !!searchUserHandle,

    studyTagHandles,
    hasTag: !!studyTagHandles?.length,
  };
}

/**
 * Variables used by global search
 *
 * Most filter data lives in location state as a structured object.
 * We need to translate the author queries a bit but is otherwise
 * one to one with the variables for studySearchConnection
 */
export function prepareGlobalSearchStudyQueryVariables<
  T extends SearchStudyQueryVariables,
>(variables: T, { location }: Match): T {
  const { query } = location;
  const { freeText, primary, author, reviewer, ...rest } =
    getSearchData(location) || {};
  const authorKey = primary ? 'primaryAuthor' : 'author';

  const authors = author?.map((a) => a.value) || [];
  const reviewers = reviewer?.map((r) => r.value) || [];

  const restSearchParams = {
    ...rest,
    capturedAt: serializeToDateTimeRange(rest.capturedAt),
    patientBirthDate: serializeToDateRange(rest.patientBirthDate),
  };

  return {
    ...variables,
    first: variables.last ? null : STUDY_GRID_PAGE_SIZE,
    sort: prepareNewStudySort(query.sort),
    search: filterObjectNulls({
      [authorKey]: authors.length ? authors : null,
      reviewer: reviewers.length ? reviewers : null,
      freeText: freeText || null,
      ...restSearchParams,
      isDeleted: [false],
    }),
  };
}

export type { StudyKey };

export function studyRangeDeleteUpdater(
  store: RecordSourceSelectorProxy,
  studyRef: StudyKey,
  filters: StudyListFilter[],
  viewerId: string | null,
) {
  const study = readInlineData(
    graphql`
      fragment StudyFilters_study on Study @inline {
        id
        archive {
          id
        }
        organization {
          id
        }
      }
    `,
    studyRef,
  );

  rangeDeleteUpdater(store, {
    parentId: study.archive!.id,
    deletedId: study.id,
    connections: filters.map((filter) => ({
      connectionKey: 'Archive_studyConnection',
      connectionFilters: getStatusVariables(filter, viewerId),
    })),
  });
  rangeDeleteUpdater(store, {
    parentId: study.archive!.id,
    deletedId: study.id,
    connections: filters.map((filter) => ({
      connectionKey: 'Archive_studySearchConnection',
      connectionFilters: getStatusVariables(filter, viewerId, true),
    })),
  });

  [
    'Organization_studyConnection',
    'Organization_studySearchConnection',
  ].forEach((connKey) => {
    rangeDeleteUpdater(store, {
      parentId: study.organization!.id,
      deletedId: study.id,
      connections: filters.map((filter) => ({
        connectionKey: connKey,
        // This connection is slightly different in that it serves the study list views such as search and drafts.
        // These are usually also sorted and otherwise have additional args that make it hard to use the non-function
        // version of this.  Instead, we check that _at least_ these filters are present and correct and assume the
        // others aren't relevant
        connectionFilters: (variables) => {
          const args = getStatusVariables(filter, viewerId) || {};
          const isConnection = Object.entries(args).every(([key, value]) => {
            return key in variables && isEqual(value, variables[key]);
          });

          return isConnection;
        },
      })),
    });
  });
}
