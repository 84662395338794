/**
 * @generated SignedSource<<3e6c507db49e53cd2cbb886040241ce0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SplitStudyConfirmPatientDataConflictContent_sourceStudy$data = {
  readonly accessionNumber: string | null;
  readonly patient: {
    readonly birthDate: string | null;
    readonly medicalRecordNumber: string | null;
    readonly sex: PatientSex | null;
    readonly " $fragmentSpreads": FragmentRefs<"PatientName_patient">;
  } | null;
  readonly " $fragmentType": "SplitStudyConfirmPatientDataConflictContent_sourceStudy";
};
export type SplitStudyConfirmPatientDataConflictContent_sourceStudy$key = {
  readonly " $data"?: SplitStudyConfirmPatientDataConflictContent_sourceStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyConfirmPatientDataConflictContent_sourceStudy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyConfirmPatientDataConflictContent_sourceStudy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medicalRecordNumber",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PatientName_patient"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "675a132e2ca4e2e441ad309ab7bee417";

export default node;
