import HttpError from 'found/HttpError';
import { graphql } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import Route from 'components/Route';

import DemoBasePage from './components/DemoBasePage';
import ElementPage from './components/ElementPage';
import IntroPage from './components/IntroPage';
import elements from './elements';

export default (
  <Route
    path="demo"
    Component={DemoBasePage}
    query={graphql`
      query demo_Query {
        viewer {
          id
        }
      }
    `}
    getComponent={() =>
      import(/* webpackChunkName: "admin" */ './components/DemoBasePage').then(
        (m) => m.default,
      )
    }
    prerender={(renderArgs) => {
      const { props, ldClient } = renderArgs;

      if (!props || !ldClient) return;

      if (!getVariation(ldClient, 'demo-area')) {
        throw new HttpError(404);
      }
    }}
  >
    {elements.map((element) => (
      <Route
        key={element.path}
        path={element.path}
        Component={() => {
          return <ElementPage element={element} />;
        }}
      />
    ))}
    <Route path="(.*)?" Component={IntroPage} />
  </Route>
);
