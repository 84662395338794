import Layout from '@4c/layout';
import FailureOutline from '@bfly/icons/FailureOutline';
import Anchor from '@bfly/ui2/Anchor';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { supportEmail } from 'config/app';

export default function OutageMessage({
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <Layout
      className={`space-x-4 p-4 items-center border-red border border-opacity-70 rounded-lg text-white ${className}`}
    >
      <FailureOutline className="w-8 text-red opacity-70" />
      <div>
        <div>
          <FormattedMessage
            id="errorMessage.outage"
            defaultMessage="We are currently experiencing an outage with this service."
          />
        </div>
        <div>
          <FormattedMessage
            id="errorMessage.statusLink"
            defaultMessage="Please contact <a>Customer Support</a>  for more information."
            values={{
              a: (content) => (
                <Anchor target="_blank" href={`mailto:${supportEmail}`}>
                  {content}
                </Anchor>
              ),
            }}
          />
        </div>
      </div>
    </Layout>
  );
}
