import RingChart, { Data, RingChartProps } from '@bfly/ui2/RingChart';
import Text from '@bfly/ui2/Text';
import { colorVariables } from '@bfly/ui2/tailwind/colors';
import { ReactElement } from 'react';

interface PerformanceIndicatorRingChartProps {
  data: Data[];
  total: number;
  children: ReactElement;
  onHoverIndex?: RingChartProps['onHoverIndex'];
}

const PerformanceIndicatorRingChart = ({
  data,
  total,
  children,
  onHoverIndex,
}: PerformanceIndicatorRingChartProps) => {
  return (
    <RingChart
      spacerColor={colorVariables.grey[50]}
      spacerWidth={8}
      spacerGap={8}
      radius={40}
      axisColor={colorVariables.grey[80]}
      axisWidth={30}
      axisShadow={1}
      data={data.filter(({ value }) => !!value)}
      total={total}
      className="w-24"
      onHoverIndex={onHoverIndex}
    >
      <Text color="headline" variant="sm">
        <div className="mb-1 leading-[100%]">{children}</div>
      </Text>
    </RingChart>
  );
};

export default PerformanceIndicatorRingChart;
