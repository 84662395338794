/**
 * @generated SignedSource<<bbd83e07dd0cfe183b75ca6a579c55b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyListItem_studyImages$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"StudyImagePreviewList_studyImages">;
  readonly " $fragmentType": "StudyListItem_studyImages";
}>;
export type StudyListItem_studyImages$key = ReadonlyArray<{
  readonly " $data"?: StudyListItem_studyImages$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyListItem_studyImages">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudyListItem_studyImages",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyImagePreviewList_studyImages"
    }
  ],
  "type": "StudyImage",
  "abstractKey": null
};

(node as any).hash = "8c7b04e3d490a0fb36d39dda541d56e6";

export default node;
