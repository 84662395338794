/**
 * @generated SignedSource<<f86dd93f94b315877e9786cf798a1119>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationBillingProvider = "APPLE" | "SALESFORCE" | "STRIPE" | "%future added value";
export type subscription_SubscriptionContentQuery$variables = {
  organizationSlug: string;
};
export type subscription_SubscriptionContentQuery$data = {
  readonly organization: {
    readonly subscription: {
      readonly billingProvider: OrganizationBillingProvider | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SubscriptionContent_organization">;
  } | null;
};
export type subscription_SubscriptionContentQuery = {
  response: subscription_SubscriptionContentQuery$data;
  variables: subscription_SubscriptionContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingProvider",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "subscription_SubscriptionContentQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SubscriptionContent_organization"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "subscription_SubscriptionContentQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriptionEndsAtDisplay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canAccessProFeatures",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInGracePeriod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasCompletedTrial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxNumSeats",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasStartedActiveSubscription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessEndsAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "willAutorenew",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "savedCardBrand",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "savedCardLastFour",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "savedCardIsExpired",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "savedCardWillExpire",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d53dc0dfb63c5b0f709b00ab56c11329",
    "id": null,
    "metadata": {},
    "name": "subscription_SubscriptionContentQuery",
    "operationKind": "query",
    "text": "query subscription_SubscriptionContentQuery(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    ...SubscriptionContent_organization\n    subscription {\n      billingProvider\n    }\n    id\n  }\n}\n\nfragment BasicSubscriptionInfo_organization on Organization {\n  slug\n  subscriptionEndsAtDisplay\n  subscription {\n    maxNumSeats\n    billingProvider\n    hasCompletedTrial\n    hasStartedActiveSubscription\n  }\n  ...DaysRemainingBanner_organization\n}\n\nfragment DaysRemainingBanner_organization on Organization {\n  subscription {\n    isInGracePeriod\n    accessEndsAt\n  }\n}\n\nfragment ForeverSubscriptionInfo_organization on Organization {\n  slug\n  subscription {\n    maxNumSeats\n  }\n}\n\nfragment ProSubscriptionInfo_organization on Organization {\n  slug\n  willAutorenew\n  savedCardBrand\n  savedCardLastFour\n  savedCardIsExpired\n  savedCardWillExpire\n  subscription {\n    hasStartedActiveSubscription\n    planType\n    maxNumSeats\n    billingProvider\n  }\n  ...DaysRemainingBanner_organization\n}\n\nfragment SubscriptionContent_organization on Organization {\n  country\n  subscriptionEndsAtDisplay\n  subscription {\n    canAccessProFeatures\n    isInGracePeriod\n    hasCompletedTrial\n  }\n  ...useSubscriptionInfo_organization\n  ...BasicSubscriptionInfo_organization\n  ...ForeverSubscriptionInfo_organization\n  ...ProSubscriptionInfo_organization\n  ...DaysRemainingBanner_organization\n}\n\nfragment useSubscriptionInfo_organization on Organization {\n  subscription {\n    canAccessProFeatures\n    isInGracePeriod\n    planType\n  }\n}\n"
  }
};
})();

(node as any).hash = "a304a6230d3296f77326a0ed91b63be8";

export default node;
