import clsx from 'clsx';
import { createFragmentContainer, graphql } from 'react-relay';

import BlankAvatar from './BlankAvatar';
import Thumbnail from './Thumbnail';
import { Avatar_userProfile$data as UserProfile } from './__generated__/Avatar_userProfile.graphql';

interface Props {
  userProfile: UserProfile | null;
  width: number;
  className?: string;
}

function Avatar({ userProfile, width, className }: Props) {
  if (!userProfile || !userProfile.avatar) {
    return <BlankAvatar width={width} className={className} />;
  }

  const { avatar, name } = userProfile;
  if (!avatar) return null;

  return (
    <Thumbnail
      image={avatar!}
      alt={name!}
      className={clsx(className, 'rounded-full object-contain')}
      style={{ width, height: width }}
    />
  );
}

export default createFragmentContainer(Avatar, {
  userProfile: graphql`
    fragment Avatar_userProfile on UserProfile {
      avatar {
        ...Thumbnail_image
      }
      name
    }
  `,
});
