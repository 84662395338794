import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import React, { useRef } from 'react';

import type { Match } from 'components/Route';
import { getNullDataProps } from 'utils/RouteUtils';

export type ShouldInvalidate = (
  lastProps: Record<string, any> & { match: Match },
  match: Match,
) => boolean;

function useLastPropsShouldInvalidate(
  props: any | undefined,
  match: Match,
  shouldInvalidate?: ShouldInvalidate,
) {
  const lastPropsRef = useRef<any>(null);

  // Reset back to `null` if the route changes, this  guards
  // against the case where two Routes render StaleWhileFetching
  // and accidently pass data from one route to another
  if (lastPropsRef.current?.match.route !== (match as any).route) {
    lastPropsRef.current = null;
  }

  // Take the newest props, or else the old props with the new match.
  lastPropsRef.current = props || lastPropsRef.current;

  const lastProps = lastPropsRef.current;

  if (!lastProps || shouldInvalidate?.(lastProps, match)) {
    return null;
  }

  return lastProps;
}

interface StaleWhileFetchingProps {
  Component: React.ComponentType;
  props?: any;
  match: Match;
  shouldInvalidate?: ShouldInvalidate;
}

function StaleWhileFetching({
  Component,
  props,
  match,
  shouldInvalidate,
  ...ownProps
}: StaleWhileFetchingProps) {
  const nullDataProps = getNullDataProps(match);
  const lastProps = useLastPropsShouldInvalidate(
    props,
    match,
    shouldInvalidate,
  );

  if (!lastProps) {
    return <LoadingIndicator />;
  }

  return (
    <Component
      {...nullDataProps}
      {...lastProps}
      match={match}
      loading={!props}
      {...ownProps}
    />
  );
}

export default StaleWhileFetching;
