/**
 * @generated SignedSource<<741de04e000868b73f33b142cca34900>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyListContent_organization$data = {
  readonly id: string;
  readonly viewerCanQa: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"EmptyArchiveScreen_organization" | "StudyBatchActions_organization" | "StudyDataGrid_organization">;
  readonly " $fragmentType": "StudyListContent_organization";
};
export type StudyListContent_organization$key = {
  readonly " $data"?: StudyListContent_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyListContent_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanQa",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyBatchActions_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyDataGrid_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmptyArchiveScreen_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "4a634d688687ffde9183aa6b7bcdb96f";

export default node;
