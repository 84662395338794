import Layout from '@4c/layout';
import DateRangePicker from '@bfly/ui2/DateRangePicker';
import Text from '@bfly/ui2/Text';
import { useCallbackRef } from '@restart/hooks';
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import { ReactNode, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';

import { NO_EXAM_TYPE_OPTION } from 'components/ExamTypeSelectButton';
import { deserializeDateRange } from 'schemas/dateRange';
import {
  SearchData,
  UNASSIGNED_AUTHOR,
  mapSearchDataToCriteria,
} from 'utils/Search';
import { isValidDateRangeValue } from 'utils/dateRangePickerHelpers';

import Avatar from './Avatar';
import PopoverList from './PopoverList';
import StudyStatusRenderer from './StudyStatus';
import { SearchTags_nodes$data as Nodes } from './__generated__/SearchTags_nodes.graphql';
import {
  SearchTags_studySearchCriteria$data as StudySearchCriteria,
  StudySearchCriterionKey,
} from './__generated__/SearchTags_studySearchCriteria.graphql';

const messages = defineMessages({
  filters: {
    id: 'recentSearch.filters',
    defaultMessage: `+{hiddenCount} {hiddenCount, plural,
      =1 {filter}
      other {filters}
    }`,
  },
});

const getOuterWidth = (element: HTMLElement): number => {
  const style = getComputedStyle(element);

  return (
    element.offsetWidth +
    (parseInt(style.marginLeft, 10) || 0) +
    (parseInt(style.marginRight, 10) || 0)
  );
};

const getShowCount = (container: HTMLElement) => {
  const scrollElement = container.firstChild as HTMLElement;
  const childElements = Array.from(scrollElement.children) as HTMLElement[];
  const showCountElement = childElements.pop() as HTMLElement;
  const showCountElementWidth = getOuterWidth(showCountElement);

  if (
    scrollElement.scrollWidth - showCountElementWidth <=
    container.offsetWidth
  )
    return childElements.length;

  let currentWidth = 0;
  let showCount = 0;

  // adding each element width until it's it won't fit
  for (const childElement of childElements) {
    const childOuterWidth = getOuterWidth(childElement);
    const isLast = showCount + 1 === childElements.length;
    if (
      container.offsetWidth <=
      // includes the showCountElementWidth if not last
      currentWidth + childOuterWidth + (isLast ? 0 : showCountElementWidth)
    )
      return showCount;

    currentWidth += childOuterWidth;
    showCount++;
  }

  return showCount || 1;
};

type TagVariant = 'light' | 'dark' | 'text';

type TagContainerProps = {
  children: ReactNode;
  variant?: TagVariant;
};

function useHiddenOverflow(enabled = true) {
  const [container, setContainer] = useCallbackRef<HTMLElement>();
  const [showCount, setShowCount] = useState(0);

  useLayoutEffect(() => {
    if (!enabled || showCount) return undefined;

    const animationFrame = window.requestAnimationFrame(() => {
      if (container?.offsetWidth) setShowCount(getShowCount(container));
    });

    return () => window.cancelAnimationFrame(animationFrame);
  });

  return [showCount, setContainer] as const;
}

function TagContainer({ children, variant }: TagContainerProps) {
  return (
    <div
      className={clsx(
        'rounded flex px-2 mr-1 h-full whitespace-nowrap py-1',
        variant === 'dark' && 'bg-contrast/30',
        variant === 'light' && 'bg-white',
        variant !== 'text' && 'px-2',
      )}
    >
      {children}
    </div>
  );
}

interface Props {
  searchData?: SearchData | null;
  nodes: Nodes | null;
  studySearchCriteria: StudySearchCriteria | null;
  variant: TagVariant;
  wrap?: boolean;
}

export const keySortBy = (key: StudySearchCriterionKey) => {
  const idx = [
    'FREE_TEXT',
    'ARCHIVE',
    'AUTHOR',
    'PRIMARY_AUTHOR',
    'ORGANIZATION',
    'CAPTURED_AT',
    'EXAM_TYPE',
    'REVIEWER',
    'STATUS',
    'TAG',
    'WORKSHEET_TEMPLATE',
    'ALL_WORKSHEET_TEMPLATES',
    'PATIENT_NAME_FIRST',
    'PATIENT_NAME_MIDDLE',
    'PATIENT_NAME_MIDDLE',
  ].indexOf(key);
  return idx === -1 ? 100 : idx;
};

function renderContent({
  value,
  key,
  node,
}: Omit<StudySearchCriteria[0], ' $fragmentType'>): ReactNode {
  if (node) {
    if (node.type === 'UserProfile') {
      return (
        <>
          <Avatar userProfile={node} width={20} />
          <span className="ml-1">{node.name}</span>
        </>
      );
    }
    if (node.type === 'WorksheetTemplate') {
      return node.latestVersion?.title;
    }

    return node.name ?? null;
  }

  if (value === UNASSIGNED_AUTHOR) {
    return (
      <FormattedMessage
        id="searchTags.unassigned"
        defaultMessage="Unassigned authors"
      />
    );
  }

  if (value === NO_EXAM_TYPE_OPTION.id) {
    return (
      <FormattedMessage
        id="searchTags.noExamType"
        defaultMessage="No Exam Type"
      />
    );
  }

  if (key === 'STATUS') {
    return <StudyStatusRenderer status={value} />;
  }

  if (
    key === 'READY_AT' ||
    key === 'CAPTURED_AT' ||
    key === 'PATIENT_BIRTH_DATE'
  ) {
    const dateValue = value && deserializeDateRange(value);

    return isValidDateRangeValue(dateValue) ? (
      <DateRangePicker.FormattedValue value={dateValue} />
    ) : null;
  }
  if (typeof value === 'string') return value as string;
  return null;
}

function SearchTags({
  studySearchCriteria,
  nodes,
  searchData,
  variant,
  wrap = false,
}: Props) {
  const [showCount, ref] = useHiddenOverflow(!wrap);

  const contentToTag = (
    obj: { content: ReactNode; key: StudySearchCriterionKey },
    index: number,
  ) => {
    return (
      !!obj.content && (
        <TagContainer
          variant={(obj as any).key === 'FREE_TEXT' ? 'text' : variant}
          key={`${index}`}
        >
          {obj.content}
        </TagContainer>
      )
    );
  };

  const criteria = useMemo(() => {
    if (studySearchCriteria) {
      return sortBy(studySearchCriteria, (c) => keySortBy(c.key!))
        .map((c) => ({
          key: c.key,
          content: renderContent(c),
        }))
        .filter((c) => c.content != null);
    }

    return sortBy(mapSearchDataToCriteria(searchData!, nodes!), (c) =>
      keySortBy(c.key!),
    ).map((c) => ({
      key: c.key,
      content: renderContent(c),
    }));
  }, [searchData, nodes, studySearchCriteria]);

  if (!criteria.length) {
    return null;
  }

  const shownCriteria = criteria.slice(0, showCount);
  const hiddenCriteria = criteria.slice(showCount);

  return (
    <div className="overflow-hidden" ref={ref}>
      <Layout align="center" wrap={wrap} pad={1} className="whitespace-nowrap">
        {!showCount ? (
          <>
            {criteria.map(contentToTag)}
            {!wrap && (
              <Text className="ml-1">
                <FormattedMessage
                  {...messages.filters}
                  values={{ hiddenCount: criteria.length }}
                />
              </Text>
            )}
          </>
        ) : (
          <>
            {shownCriteria.map(contentToTag)}
            {!!hiddenCriteria.length && (
              <PopoverList
                listItems={hiddenCriteria.map(({ content }, idx) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return <li key={idx}>{content}</li>;
                })}
              >
                <span>
                  <Text className="ml-1 hover:border-white hover:border-b">
                    <FormattedMessage
                      {...messages.filters}
                      values={{ hiddenCount: criteria.length - showCount }}
                    />
                  </Text>
                </span>
              </PopoverList>
            )}
          </>
        )}
      </Layout>
    </div>
  );
}

export default createFragmentContainer(SearchTags, {
  nodes: graphql`
    fragment SearchTags_nodes on Node @relay(plural: true) {
      id
      type: __typename
      ... on UserProfile {
        name
        ...Avatar_userProfile
      }
      ... on Organization {
        name
      }
      ... on Archive {
        id
        name: label
      }
      ... on ExamType {
        id
        name
      }
      ... on WorksheetTemplate {
        latestVersion {
          title
        }
      }
      ... on StudyTag {
        name
      }
    }
  `,
  studySearchCriteria: graphql`
    fragment SearchTags_studySearchCriteria on StudySearchCriterion
    @relay(plural: true) {
      key
      value
      node {
        ...SearchTags_nodes @relay(mask: false)
      }
    }
  `,
});
