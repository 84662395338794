import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import PatientInfo from './PatientInfo';
import { StudyInfo_study$data as Study } from './__generated__/StudyInfo_study.graphql';

function StudyInfo({ study }: { study: Study }) {
  return (
    <PatientInfo
      patient={study.patient}
      accessionNumber={study.accessionNumber}
    />
  );
}

export default createFragmentContainer(StudyInfo, {
  study: graphql`
    fragment StudyInfo_study on Study {
      accessionNumber
      patient {
        ...PatientInfo_patient
      }
    }
  `,
});
