/**
 * @generated SignedSource<<fb6f2861648e3e800f702d845f6c67c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplatedFieldListItem_fieldDefinition$data = {
  readonly handle: string | null;
  readonly label: string | null;
  readonly options?: ReadonlyArray<{
    readonly handle: string | null;
    readonly label: string | null;
  } | null> | null;
  readonly typename: string;
  readonly " $fragmentType": "TemplatedFieldListItem_fieldDefinition";
};
export type TemplatedFieldListItem_fieldDefinition$key = {
  readonly " $data"?: TemplatedFieldListItem_fieldDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplatedFieldListItem_fieldDefinition">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FieldOption",
    "kind": "LinkedField",
    "name": "options",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplatedFieldListItem_fieldDefinition",
  "selections": [
    {
      "alias": "typename",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "MultiSelectFieldDefinition",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "SingleSelectFieldDefinition",
      "abstractKey": null
    }
  ],
  "type": "FormFieldDefinitionInterface",
  "abstractKey": "__isFormFieldDefinitionInterface"
};
})();

(node as any).hash = "f907eccb26cab1fc47efd16f81071b58";

export default node;
