/**
 * @generated SignedSource<<df0371f666cf81aedf49a57c3403237f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyListContent_archive$data = {
  readonly deletedAt: string | null;
  readonly viewerCanQa: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyFilterDropdown_archive" | "UndeleteArchiveControl_archive">;
  readonly " $fragmentType": "StudyListContent_archive";
};
export type StudyListContent_archive$key = {
  readonly " $data"?: StudyListContent_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyListContent_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UndeleteArchiveControl_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyFilterDropdown_archive"
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "dd662ca4e501b4d8ad14dee2dbffd3f9";

export default node;
