import FieldSet from '@bfly/ui2/FieldSet';
import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PermissionTooltip from 'components/PermissionTooltip';
import { usePermissions } from 'utils/viewerContext';

import { DomainMemberDicomFieldsFieldSet_dicomFieldTemplates$data as DomainDicomFieldTemplates } from './__generated__/DomainMemberDicomFieldsFieldSet_dicomFieldTemplates.graphql';

interface Props {
  fluid?: boolean;
  dicomFieldTemplates: DomainDicomFieldTemplates;
}

function DomainMemberDicomFieldsFieldSet({
  dicomFieldTemplates,
  fluid,
}: Props) {
  const { formatMessage } = useIntl();
  const { hasAdminPermission } = usePermissions();
  const hasPermission = hasAdminPermission('userManagement');
  return dicomFieldTemplates.length ? (
    <FieldSet
      className="my-6"
      legend={formatMessage(
        defineMessage({
          id: 'domainMemberDicomFieldsFieldSet.title',
          defaultMessage: 'DICOM field mappings (optional)',
        }),
      )}
    >
      <p>
        <FormattedMessage
          id="domainMemberDicomFieldsFieldSet.description"
          defaultMessage="Enter the user identifier exactly as it is seen in the DICOM field including caret delimiters, if applicable."
        />
      </p>
      {dicomFieldTemplates.map((dicomFieldTemplate, idx) => (
        <PermissionTooltip hide={hasPermission} key={dicomFieldTemplate!.id}>
          <Form.FieldGroup
            fluid={fluid}
            key={dicomFieldTemplate!.id}
            name={`dicomFields[${idx}].value`}
            label={dicomFieldTemplate!.label!}
            disabled={!hasPermission}
          />
        </PermissionTooltip>
      ))}
    </FieldSet>
  ) : null;
}

export default createFragmentContainer(DomainMemberDicomFieldsFieldSet, {
  dicomFieldTemplates: graphql`
    fragment DomainMemberDicomFieldsFieldSet_dicomFieldTemplates on DomainDicomFieldTemplate
    @relay(plural: true) {
      id
      label
      dicomDocumentPath
    }
  `,
});
