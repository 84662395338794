/**
 * @generated SignedSource<<175819073ca8aa385b9a8408c8f38d73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EduStudiesPortfolioNoQaIndicators_Query$variables = {
  author?: ReadonlyArray<string> | null;
};
export type EduStudiesPortfolioNoQaIndicators_Query$data = {
  readonly viewer: {
    readonly eduStudyStatistics: {
      readonly byExamType: ReadonlyArray<{
        readonly examType: {
          readonly name: string | null;
          readonly systemId: string | null;
        } | null;
        readonly numReviewRequested: number;
        readonly numReviewed: number;
        readonly numUnsubmitted: number;
      }> | null;
      readonly zones: {
        readonly percentageCompletelyCaptured: number;
      } | null;
    } | null;
    readonly last2Weeks: {
      readonly byExamType: ReadonlyArray<{
        readonly examType: {
          readonly name: string | null;
          readonly systemId: string | null;
        } | null;
        readonly numReviewRequested: number;
        readonly numReviewed: number;
        readonly numUnsubmitted: number;
      }> | null;
    } | null;
    readonly lastWeek: {
      readonly byExamType: ReadonlyArray<{
        readonly examType: {
          readonly name: string | null;
          readonly systemId: string | null;
        } | null;
        readonly numReviewRequested: number;
        readonly numReviewed: number;
        readonly numUnsubmitted: number;
      }> | null;
    } | null;
  } | null;
};
export type EduStudiesPortfolioNoQaIndicators_Query = {
  response: EduStudiesPortfolioNoQaIndicators_Query$data;
  variables: EduStudiesPortfolioNoQaIndicators_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "author"
  }
],
v1 = {
  "kind": "Variable",
  "name": "authors",
  "variableName": "author"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numUnsubmitted",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numReviewRequested",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numReviewed",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "EduStudyExamTypeStatistics",
  "kind": "LinkedField",
  "name": "byExamType",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examType",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "EduZonesStatistics",
  "kind": "LinkedField",
  "name": "zones",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percentageCompletelyCaptured",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "capturedAt",
    "value": {
      "days": 7
    }
  }
],
v11 = [
  (v8/*: any*/)
],
v12 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "capturedAt",
    "value": {
      "days": 14
    }
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "EduStudyExamTypeStatistics",
  "kind": "LinkedField",
  "name": "byExamType",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examType",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v13/*: any*/)
      ],
      "storageKey": null
    },
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v15 = [
  (v14/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EduStudiesPortfolioNoQaIndicators_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "EduStudyStatistics",
            "kind": "LinkedField",
            "name": "eduStudyStatistics",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "lastWeek",
            "args": (v10/*: any*/),
            "concreteType": "EduStudyStatistics",
            "kind": "LinkedField",
            "name": "eduStudyStatistics",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": "last2Weeks",
            "args": (v12/*: any*/),
            "concreteType": "EduStudyStatistics",
            "kind": "LinkedField",
            "name": "eduStudyStatistics",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EduStudiesPortfolioNoQaIndicators_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "EduStudyStatistics",
            "kind": "LinkedField",
            "name": "eduStudyStatistics",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "lastWeek",
            "args": (v10/*: any*/),
            "concreteType": "EduStudyStatistics",
            "kind": "LinkedField",
            "name": "eduStudyStatistics",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": "last2Weeks",
            "args": (v12/*: any*/),
            "concreteType": "EduStudyStatistics",
            "kind": "LinkedField",
            "name": "eduStudyStatistics",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81dbc676f83a0ded42358bda5cc3910d",
    "id": null,
    "metadata": {},
    "name": "EduStudiesPortfolioNoQaIndicators_Query",
    "operationKind": "query",
    "text": "query EduStudiesPortfolioNoQaIndicators_Query(\n  $author: [ID!]\n) {\n  viewer {\n    eduStudyStatistics(authors: $author) {\n      byExamType {\n        examType {\n          name\n          systemId\n          id\n        }\n        numUnsubmitted\n        numReviewRequested\n        numReviewed\n      }\n      zones {\n        percentageCompletelyCaptured\n      }\n    }\n    lastWeek: eduStudyStatistics(authors: $author, capturedAt: {days: 7}) {\n      byExamType {\n        examType {\n          name\n          systemId\n          id\n        }\n        numUnsubmitted\n        numReviewRequested\n        numReviewed\n      }\n    }\n    last2Weeks: eduStudyStatistics(authors: $author, capturedAt: {days: 14}) {\n      byExamType {\n        examType {\n          name\n          systemId\n          id\n        }\n        numUnsubmitted\n        numReviewRequested\n        numReviewed\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "199c736b56269a626f3b231edad49e12";

export default node;
