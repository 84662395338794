/**
 * @generated SignedSource<<be77483f090d9b86b0b4c3682fe236d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type routeConfig_ReferredPageQuery$variables = {
  token: string;
};
export type routeConfig_ReferredPageQuery$data = {
  readonly referrerInfo: {
    readonly " $fragmentSpreads": FragmentRefs<"ReferredPage_referrerInfo">;
  } | null;
};
export type routeConfig_ReferredPageQuery = {
  response: routeConfig_ReferredPageQuery$data;
  variables: routeConfig_ReferredPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_ReferredPageQuery",
    "selections": [
      {
        "alias": "referrerInfo",
        "args": (v1/*: any*/),
        "concreteType": "ReferrerInfo",
        "kind": "LinkedField",
        "name": "referrerInfoByToken",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferredPage_referrerInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeConfig_ReferredPageQuery",
    "selections": [
      {
        "alias": "referrerInfo",
        "args": (v1/*: any*/),
        "concreteType": "ReferrerInfo",
        "kind": "LinkedField",
        "name": "referrerInfoByToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shareLinkEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shareLinkMobile",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "53ed2d2a7367ae3f3bd049521a3acb02",
    "id": null,
    "metadata": {},
    "name": "routeConfig_ReferredPageQuery",
    "operationKind": "query",
    "text": "query routeConfig_ReferredPageQuery(\n  $token: String!\n) {\n  referrerInfo: referrerInfoByToken(token: $token) {\n    ...ReferredPage_referrerInfo\n  }\n}\n\nfragment ReferredPage_referrerInfo on ReferrerInfo {\n  shareLinkEmail\n  shareLinkMobile\n}\n"
  }
};
})();

(node as any).hash = "13da12e35d933d03cf0cd3c3401a7f8f";

export default node;
