/**
 * @generated SignedSource<<d1629f6e7ab1849c203fd6f8fde1230d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyImage_image$data = {
  readonly burnedInFile: {
    readonly dataUrl: string | null;
    readonly isVideo: boolean | null;
    readonly thumbnails: ReadonlyArray<{
      readonly url: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "StudyImage_image";
};
export type StudyImage_image$key = {
  readonly " $data"?: StudyImage_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImage_image">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyImage_image",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BurnedInFile",
      "kind": "LinkedField",
      "name": "burnedInFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVideo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Thumbnail",
          "kind": "LinkedField",
          "name": "thumbnails",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyImageInterface",
  "abstractKey": "__isStudyImageInterface"
};

(node as any).hash = "cbb337caedc7a8c487a330a970ed22d5";

export default node;
