import { graphql, readInlineData } from 'react-relay';
import { AnySchema, object } from 'yup';
import type { SchemaOf } from 'yup';

import removeKeysDeep from 'utils/removeKeysDeep';

import type { worksheet_templateVersion$key as WorksheetTemplateVersionKey } from './__generated__/worksheet_templateVersion.graphql';
import { fieldDefinitionToSchema } from './fieldDefinition';

export type TemplateVersionSchema = SchemaOf<{
  values: Record<string, any>;
}>;

/**
 * Generate a yup schema from a worksheet template version.
 */
export function templateVersionToSchema(
  worksheetTemplateVersionRef: WorksheetTemplateVersionKey,
  // Use partial: true to bypass required validation e.g. for prefills
  {
    partial = false,
    stripIncompatible = false,
  }: { partial?: boolean; stripIncompatible?: boolean } = {},
): TemplateVersionSchema {
  const worksheetTemplateVersion = readInlineData(
    graphql`
      fragment worksheet_templateVersion on WorksheetTemplateVersion @inline {
        sections {
          fieldDefinitions {
            handle
            ...fieldDefinition_fieldDefinition
          }
        }
      }
    `,
    worksheetTemplateVersionRef,
  );

  const valuesShape: Record<string, AnySchema> = {};

  worksheetTemplateVersion.sections!.forEach((section) => {
    section!.fieldDefinitions!.forEach((fieldDefinition) => {
      valuesShape[fieldDefinition!.handle!] = fieldDefinitionToSchema(
        fieldDefinition!,
        {
          partial,
          stripIncompatible,
        },
      );
    });
  });

  return object({
    values: object(valuesShape).noUnknown(),
  }) as any;
}

export function deserializeFromSchema(
  worksheet: { values: Record<string, unknown> | null } | null,
  schema: TemplateVersionSchema,
) {
  const value = worksheet ? schema.cast(worksheet) : schema.getDefault();

  // remove undefined / null values from incompatible prefills. The server will not accept undefined or nulls in the data
  // and there is no way for users to fix this. This modifies values in place.
  removeKeysDeep((v) => v === null || v === undefined, value.values);

  return value;
}

export function deserializeFromTemplate(
  worksheet: { values: Record<string, unknown> | null } | null,
  worksheetTemplateVersionRef: WorksheetTemplateVersionKey,
  options: { partial?: boolean; stripIncompatible?: boolean },
) {
  const schema = templateVersionToSchema(worksheetTemplateVersionRef, options);

  return deserializeFromSchema(worksheet, schema);
}
