import QuickBookmarkIcon from '@bfly/icons/QuickBookmark';
import useMutationTrigger from '@bfly/ui2/useMutationTrigger';
import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { ROOT_ID, generateClientID, readInlineData } from 'relay-runtime';

import { useAuth } from 'components/AuthContext';
import actionMessages from 'messages/actions';

import educationMessages from '../messages/educationMessages';
import type { useSaveContentToggleSaveContentMutation } from './__generated__/useSaveContentToggleSaveContentMutation.graphql';
import type { useSaveContentToggleUnsaveContentMutation } from './__generated__/useSaveContentToggleUnsaveContentMutation.graphql';
import type { useSaveContentToggle_educationContent$key as EducationContentKey } from './__generated__/useSaveContentToggle_educationContent.graphql';

interface Options {
  hideIcon?: boolean;
  hideMessage?: boolean;
}

const MESSAGE_TIMEOUT = 1500;

const UNSAVE_MUTATION = graphql`
  mutation useSaveContentToggleUnsaveContentMutation(
    $input: UnsaveEducationContentInput!
  ) {
    unsaveEducationContentOrError(input: $input) {
      ... on UnsaveEducationContentPayload {
        educationContent {
          viewerHasSaved
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

const SAVE_MUTATION = graphql`
  mutation useSaveContentToggleSaveContentMutation(
    $input: SaveEducationContentInput!
  ) {
    saveEducationContentOrError(input: $input) {
      ... on SaveEducationContentPayload {
        educationContent {
          viewerHasSaved
        }
        educationContentEdge {
          node {
            id
          }
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

type ToggleSaveVideoMutation =
  | useSaveContentToggleSaveContentMutation
  | useSaveContentToggleUnsaveContentMutation;

export default function useSaveContentToggle(
  contentRef: EducationContentKey,
  { hideMessage, hideIcon }: Options = {},
) {
  const auth = useAuth();
  const educationContent = readInlineData(
    graphql`
      fragment useSaveContentToggle_educationContent on EducationContent
      @inline {
        id
        viewerHasSaved
        viewerHasAccess
      }
    `,
    contentRef,
  );

  const [mutate, { loading, message }] =
    useMutationTrigger<ToggleSaveVideoMutation>(
      educationContent.viewerHasSaved ? UNSAVE_MUTATION : SAVE_MUTATION,
      {
        dismissMessageAfter: MESSAGE_TIMEOUT,
        input: { educationContentId: educationContent.id },
        successMessage: educationContent.viewerHasSaved ? (
          <FormattedMessage {...educationMessages.contentSaved} />
        ) : (
          <FormattedMessage {...educationMessages.contentUnsaved} />
        ),
        updater: (store) => {
          const connections = [
            {
              connectionKey: 'EducationContentBookmarksAssigned_saved',
              connectionFilters: () => true,
            },
            {
              connectionKey: 'SavedContentList_saved',
              connectionFilters: () => true,
            },
            {
              connectionKey: 'EducationVideoPageHeader_saved',
              connectionFilters: () => true,
            },
          ];
          if (educationContent.viewerHasSaved) {
            rangeDeleteUpdater(store, {
              parentId: generateClientID(ROOT_ID, 'lms'),
              connections,
              deletedId: educationContent.id!,
            });
          } else {
            rangeAddUpdater(store, {
              parentId: generateClientID(ROOT_ID, 'lms'),
              connections,
              rootFieldName: 'saveEducationContentOrError',
              edgeName: 'educationContentEdge',
            });
          }
        },
      },
    );

  const showToggle =
    auth.isAuthenticated() && educationContent.viewerHasAccess;

  return {
    showToggle,
    loading,
    mutate,
    message: message || (
      <>
        {!hideIcon && (
          <QuickBookmarkIcon
            className={clsx(
              'mr-2 w-6 h-6 rounded-full',
              educationContent.viewerHasSaved
                ? 'opacity-100 bg-primary'
                : 'bg-grey-55',
            )}
          />
        )}
        <span className={clsx(hideMessage && 'sr-only')}>
          <FormattedMessage {...actionMessages.save} />
        </span>
      </>
    ),
  };
}
