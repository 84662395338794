/**
 * @generated SignedSource<<ee22285c7bab71aca818ea0168a9bd4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudySource = "BUTTERFLY" | "THIRD_PARTY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyControls_study$data = {
  readonly id: string;
  readonly source: StudySource | null;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteCapturesDropdownItem_study" | "DeleteStudyControl_study" | "MoveStudyDropdownItem_study" | "PermanentlyDeleteStudyControl_study" | "ShareStudyDropdownItem_study" | "SplitStudyControl_study" | "StudyDownloadPdfDropdownItem_study" | "StudyEndpointsDropdownItem_study" | "UndeleteStudyControl_study">;
  readonly " $fragmentType": "StudyControls_study";
};
export type StudyControls_study$key = {
  readonly " $data"?: StudyControls_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyControls_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyControls_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShareStudyDropdownItem_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitStudyControl_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyEndpointsDropdownItem_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MoveStudyDropdownItem_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyDownloadPdfDropdownItem_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteStudyControl_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteCapturesDropdownItem_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UndeleteStudyControl_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PermanentlyDeleteStudyControl_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "0939d7a3ac6292391ac7fd5fe1a2fa7b";

export default node;
