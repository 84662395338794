import CopyToClipboardControl from '@bfly/ui2/CopyToClipboardControl';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import withModal from 'utils/withModal';

import { ShareStudyModalMutation } from './__generated__/ShareStudyModalMutation.graphql';
import { ShareStudyModal_study$data as Study } from './__generated__/ShareStudyModal_study.graphql';

interface Props {
  study: Study;
  // Required for withModal:
  // eslint-disable-next-line react/no-unused-prop-types
  onHide: () => void;
}

function ShareStudyModal({ study }: Props) {
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [shareStudy] = useMutationWithError<ShareStudyModalMutation>(
    graphql`
      mutation ShareStudyModalMutation($input: ShareStudyInput!) {
        shareStudyOrError(input: $input) {
          ... on ShareStudyPayload {
            sharedBundle {
              sharedUrl
            }
          }
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
  );

  useEffect(() => {
    shareStudy({
      input: { studyId: study.id },
    }).then((data) => {
      setShareUrl(data.shareStudyOrError!.sharedBundle!.sharedUrl);
    });
  }, [shareStudy, study.id]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="study.share.modal.title"
            defaultMessage="Share de-identified exam"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body data-bni-id="SharedLinkBlock">
        <p>
          <FormattedMessage
            id="study.share.modal.content"
            defaultMessage="Sharing this link will enable others to view a de-identified version of the current exam. Previously-shared versions of this study will not reflect future changes made to the study. All patient information will be removed. Users do not need Butterfly Cloud credentials to access the link."
          />
        </p>
        {shareUrl ? (
          <CopyToClipboardControl
            autoFocus
            variant="secondary"
            buttonLabel={
              <FormattedMessage
                id="study.share.modal.copyToClipboard.copyLink"
                defaultMessage="Copy Link"
              />
            }
            successMessage={
              <FormattedMessage
                id="study.share.modal.copyToClipboard.success"
                defaultMessage="URL copied to clipboard"
              />
            }
            value={shareUrl}
          />
        ) : (
          <LoadingIndicator />
        )}
      </Modal.Body>
    </>
  );
}

export default createFragmentContainer(
  withModal(ShareStudyModal, { backdrop: true, variant: 'dark' }),
  {
    study: graphql`
      fragment ShareStudyModal_study on Study {
        id
      }
    `,
  },
);
