import { Params, PartialRoute, RouteFactory, partial } from '@4c/spa-routing';
import mapValues from 'lodash/mapValues';

export default function partialRoutes<
  R extends Record<string, RouteFactory>,
  Keys extends string,
>(
  routes: R,
  params: Params<Keys>,
): { [K in keyof R]: PartialRoute<R[K], Keys> } {
  return mapValues(routes, (r) => partial(r, params)) as any;
}
