/**
 * @generated SignedSource<<cb05e54bbc7a9068b88448f4f4de8e87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestTokenBasedLmsLoginInput = {
  clientMutationId?: string | null;
  token: string;
};
export type LmsRedirect_RequestTokenBasedLmsLoginMutation$variables = {
  input: RequestTokenBasedLmsLoginInput;
};
export type LmsRedirect_RequestTokenBasedLmsLoginMutation$data = {
  readonly requestTokenBasedLmsLoginOrError: {
    readonly __typename?: string;
    readonly lmsLogin?: {
      readonly loginUrl: string;
      readonly origin: string | null;
      readonly samlResponse: string;
    } | null;
    readonly message?: string | null;
  } | null;
};
export type LmsRedirect_RequestTokenBasedLmsLoginMutation = {
  response: LmsRedirect_RequestTokenBasedLmsLoginMutation$data;
  variables: LmsRedirect_RequestTokenBasedLmsLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LmsLogin",
      "kind": "LinkedField",
      "name": "lmsLogin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "samlResponse",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loginUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "origin",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RequestTokenBasedLmsLoginPayload",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LmsRedirect_RequestTokenBasedLmsLoginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "requestTokenBasedLmsLoginOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LmsRedirect_RequestTokenBasedLmsLoginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "requestTokenBasedLmsLoginOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d98bd336987a6e93c42d6beb8e8e2225",
    "id": null,
    "metadata": {},
    "name": "LmsRedirect_RequestTokenBasedLmsLoginMutation",
    "operationKind": "mutation",
    "text": "mutation LmsRedirect_RequestTokenBasedLmsLoginMutation(\n  $input: RequestTokenBasedLmsLoginInput!\n) {\n  requestTokenBasedLmsLoginOrError(input: $input) {\n    __typename\n    ... on RequestTokenBasedLmsLoginPayload {\n      lmsLogin {\n        samlResponse\n        loginUrl\n        origin\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8955104b91ec6a3556891b794db2ae2c";

export default node;
