/**
 * @generated SignedSource<<8768a3cfc937e555a0aa1fee871090ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteDomainUserInput = {
  clientMutationId?: string | null;
  domainUserId: any;
};
export type DomainMemberDeleteFieldSetMutation$variables = {
  input: DeleteDomainUserInput;
};
export type DomainMemberDeleteFieldSetMutation$data = {
  readonly deleteDomainUserOrError: {
    readonly __typename?: string;
    readonly deletedId?: string;
    readonly message?: string | null;
  } | null;
};
export type DomainMemberDeleteFieldSetMutation = {
  response: DomainMemberDeleteFieldSetMutation$data;
  variables: DomainMemberDeleteFieldSetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedId",
      "storageKey": null
    }
  ],
  "type": "DeleteDomainUserPayload",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainMemberDeleteFieldSetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteDomainUserOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DomainMemberDeleteFieldSetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteDomainUserOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e79c3abaaabe4c11a3f474aa4c98b296",
    "id": null,
    "metadata": {},
    "name": "DomainMemberDeleteFieldSetMutation",
    "operationKind": "mutation",
    "text": "mutation DomainMemberDeleteFieldSetMutation(\n  $input: DeleteDomainUserInput!\n) {\n  deleteDomainUserOrError(input: $input) {\n    __typename\n    ... on DeleteDomainUserPayload {\n      deletedId\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8a290fb7f6c3ca92ab06e1092fb3c56c";

export default node;
