/**
 * @generated SignedSource<<d3b89ddb7f0dcba9fcbd9633aa21d8b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DomainMembersGrid_domainUser$data = {
  readonly canFinalize: boolean | null;
  readonly canQa: boolean | null;
  readonly dateLastActive: string | null;
  readonly dicomFields: ReadonlyArray<{
    readonly dicomFieldTemplate: {
      readonly id: string;
      readonly label: string | null;
    } | null;
    readonly value: string | null;
  }>;
  readonly email: string | null;
  readonly id: string;
  readonly integrationConfigs: ReadonlyArray<{
    readonly ehr: {
      readonly handle: string | null;
      readonly id: string;
    } | null;
    readonly interfaceCode: string | null;
  }>;
  readonly integrationDisplayNameFirst: string | null;
  readonly integrationDisplayNameLast: string | null;
  readonly integrationDisplayNameMiddle: string | null;
  readonly organizations: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly profile: {
    readonly id: string;
  } | null;
  readonly role: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly type: DomainUserType | null;
  readonly updatedAt: string | null;
  readonly " $fragmentType": "DomainMembersGrid_domainUser";
};
export type DomainMembersGrid_domainUser$key = {
  readonly " $data"?: DomainMembersGrid_domainUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMembersGrid_domainUser">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainMembersGrid_domainUser",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameFirst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameMiddle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameLast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainUserDicomField",
      "kind": "LinkedField",
      "name": "dicomFields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainDicomFieldTemplate",
          "kind": "LinkedField",
          "name": "dicomFieldTemplate",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainUserIntegrationConfig",
      "kind": "LinkedField",
      "name": "integrationConfigs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Ehr",
          "kind": "LinkedField",
          "name": "ehr",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "handle",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interfaceCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organizations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateLastActive",
      "storageKey": null
    }
  ],
  "type": "DomainUser",
  "abstractKey": null
};
})();

(node as any).hash = "7737d07b66600c6d091e573ac5a454a8";

export default node;
