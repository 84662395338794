/**
 * @generated SignedSource<<2ac762123b30a3ddd74116d876ba4ee5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PacsPushJobStatus = "CANCELED" | "COMPLETE" | "FAILED" | "PENDING" | "RETRYING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyPacsJobDetail_job$data = {
  readonly status: PacsPushJobStatus | null;
  readonly " $fragmentSpreads": FragmentRefs<"CancelPacsPushJobButton_job" | "PacsSyncStatus_job" | "SendStudyToPacsButton_job">;
  readonly " $fragmentType": "StudyPacsJobDetail_job";
};
export type StudyPacsJobDetail_job$key = {
  readonly " $data"?: StudyPacsJobDetail_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPacsJobDetail_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyPacsJobDetail_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PacsSyncStatus_job"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendStudyToPacsButton_job"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelPacsPushJobButton_job"
    }
  ],
  "type": "PacsPushJob",
  "abstractKey": null
};

(node as any).hash = "bc074caae642f2e502a54e8b1367370f";

export default node;
