import Text from '@bfly/ui2/Text';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Abbr from 'components/Abbr';
import Dl, { DescriptionListProps } from 'components/DescriptionList';
import PatientName from 'components/PatientName';
import messages, { sexMessages } from 'messages/patient';
import studyMessages from 'messages/study';

import { PatientDescriptionList_study$data as Study } from './__generated__/PatientDescriptionList_study.graphql';

interface Props extends DescriptionListProps {
  study: Study;
}

function PatientDescriptionList({ study, ...props }: Props) {
  if (study.patient == null) {
    return null;
  }

  const { accessionNumber, sourceWorklistItem } = study;
  const { birthDate, medicalRecordNumber, sex } = study.patient;

  return (
    <Dl {...props}>
      <Dl.Term>
        <FormattedMessage {...messages.name} />
      </Dl.Term>
      <Dl.Detail data-bni-id="PatientName">
        <PatientName patient={study.patient!} />
      </Dl.Detail>
      {sex && (
        <>
          <Dl.Term>
            <FormattedMessage {...messages.sex} />
          </Dl.Term>
          <Dl.Detail data-bni-id="PatientSex">
            <FormattedMessage {...sexMessages[sex]} />
          </Dl.Detail>
        </>
      )}
      <Dl.Term>
        <Abbr label={messages.dateOfBirthLabel} message={messages.birthDate} />
      </Dl.Term>
      <Dl.Detail data-bni-id="BirthDate">
        {
          // Explicitly specify UTC here, as birthDate is a date (which will
          // get interpreted as UTC), not a datetime.
        }
        {birthDate && <FormattedDate value={birthDate} timeZone="UTC" />}
      </Dl.Detail>
      <Dl.Term>
        <Abbr
          label={messages.medicalRecordNumber}
          message={messages.medicalRecordNumber}
        />
      </Dl.Term>
      <Dl.Detail data-bni-id="PatientId">
        {medicalRecordNumber || (
          <Text color="subtitle">
            <FormattedMessage {...messages.noMedicalRecordNumber} />
          </Text>
        )}
      </Dl.Detail>
      <Dl.Term>
        <Abbr label={messages.accessionNumberLabel}>
          <FormattedMessage
            id="PatientDescriptionList.accessionNumber"
            defaultMessage="Acc #"
          />
        </Abbr>
      </Dl.Term>
      <Dl.Detail data-bni-id="AccessionNumber">
        {accessionNumber || (
          <Text color="subtitle">
            <FormattedMessage
              id="PatientDescriptionList.noAccNo"
              defaultMessage="No accession number"
            />
          </Text>
        )}
      </Dl.Detail>
      <Dl.Term>
        <Abbr
          label={messages.encounterNumber}
          message={messages.encounterNumber}
        />
      </Dl.Term>
      <Dl.Detail data-bni-id="EncounterNumber">
        {sourceWorklistItem?.visitNumber ?? (
          <Text color="subtitle">
            <FormattedMessage
              id="PatientDescriptionList.noEncounterNumber"
              defaultMessage="No encounter number"
            />
          </Text>
        )}
      </Dl.Detail>

      <Dl.Term>
        <FormattedMessage {...studyMessages.studyDescription} />
      </Dl.Term>
      <Dl.Detail data-bni-id="StudyDescription">
        {study.studyDescription}
      </Dl.Detail>
    </Dl>
  );
}

export default createFragmentContainer(PatientDescriptionList, {
  study: graphql`
    fragment PatientDescriptionList_study on Study {
      readyAt
      studyDescription
      accessionNumber
      patient {
        sex
        birthDate
        medicalRecordNumber
        ...PatientName_patient
      }
      sourceWorklistItem {
        ... on EncounterBasedWorklistItem {
          visitNumber
        }

        ... on FhirEncounter {
          visitNumber
        }
      }
    }
  `,
});
