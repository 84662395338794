import notNullish from '@bfly/utils/notNullish';
import { InferType, array, object, string } from 'yup';

import { useLocationState } from 'utils/LocationUtils';

const emptyArrayToNull = <T>(arr: T[] | null) => (arr?.length ? arr : null);

const arrayOfString = array(string().required()).nullable();

export const analyticsCredentialingFilterSchema = object({
  examType: arrayOfString,
  credentialGroup: arrayOfString,
  user: array(
    object({
      id: string().required(),
      handle: string().required(),
      name: string().required(),
    }),
  )
    .nullable()
    .transform(emptyArrayToNull),
  // NOTE: yearRange out of scope for MVP - bring back in later iteration
  // yearRange: dateRangeSchema.nullable().default(null),
});

export type AnalyticsCredentialingSchema = InferType<
  typeof analyticsCredentialingFilterSchema
>;

export function serialize(filters?: AnalyticsCredentialingSchema) {
  return {
    ...filters,
    user: filters?.user?.filter(notNullish).map((a) => a.id),
  };
}

export function useAnalyticsCredentialingFilterState() {
  return useLocationState(analyticsCredentialingFilterSchema, 'filter');
}
