import { decodeHandle, isUuid } from '@bfly/utils/codecs';
import { MissingFieldHandler, ROOT_TYPE } from 'relay-runtime';

const missingIdHandler: MissingFieldHandler = {
  kind: 'linked',
  handle: (field, record, args) => {
    if (
      record != null &&
      // eslint-disable-next-line no-underscore-dangle
      record.__typename === ROOT_TYPE &&
      args &&
      args.handle
    ) {
      const { handle } = args;

      const uuid = !isUuid(handle) ? decodeHandle(handle) : handle;
      if (!uuid) {
        return undefined;
      }

      return btoa(`${field.concreteType}:${uuid}`);
    }

    return undefined;
  },
};

// this doesn't seem to working quite right yet, still making queries it shouldn't have too
const nodesQuery: MissingFieldHandler = {
  kind: 'pluralLinked',
  handle: (field, record, args) => {
    if (
      record != null &&
      // eslint-disable-next-line no-underscore-dangle
      record.__typename === ROOT_TYPE &&
      field.name === 'nodes' &&
      args.ids?.length
    ) {
      return args.ids;
    }

    return undefined;
  },
};

export default [missingIdHandler, nodesQuery];
