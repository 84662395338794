import CloseSmIcon from '@bfly/icons/CloseSm';
import Button from '@bfly/ui2/Button';
import { IFloatingFilterParams, TextFilterModel } from 'ag-grid-community';
import { css } from 'astroturf';
import clsx from 'clsx';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Column, ColumnComparator } from '../types';
import colDefType from './colDefType';

export interface Props {
  placeholder?: MessageDescriptor;
}

const floatingFilterComponent = forwardRef(
  (props: Props & IFloatingFilterParams, ref) => {
    const [currentValue, setCurrentValue] = useState<string | undefined>();
    const { formatMessage } = useIntl();

    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged: (parentModel: TextFilterModel) =>
          setCurrentValue(parentModel?.filter || undefined),
      };
    });

    const handleOnInput = (value: string) => {
      setCurrentValue(value);
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged('greaterThan', value);
      });
    };

    const [hasFocus, setFocus] = useState(false);

    return (
      <div
        className={clsx(
          'form-control mx-4 w-full flex bg-grey-85 rounded-[4px] h-8 p-0',
          hasFocus && 'ring',
        )}
      >
        <input
          type="text"
          className={clsx(
            'px-3 bg-transparent text-white w-full font-normal flex-grow outline-none',
            css`
              &::placeholder {
                @apply text-grey-50;
              }
            `,
          )}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={currentValue || ''}
          placeholder={
            props?.placeholder ? formatMessage(props?.placeholder) : ''
          }
          onInput={(event) =>
            handleOnInput((event.target as HTMLInputElement).value)
          }
          data-bni-id="FloatingFilter"
        />
        {!!currentValue && (
          <Button
            className="bg-grey-85 px-2 flex-shrink-0 h-full items-center flex"
            variant={null}
            onClick={() => handleOnInput('')}
          >
            <CloseSmIcon width={12} height={12} />
          </Button>
        )}
      </div>
    );
  },
);

const stringComparator: ColumnComparator = (
  valueA,
  valueB,
  _nodeA,
  _nodeB,
  _isDescending,
) => {
  const strA = valueA?.toString() || '';
  const strB = valueB?.toString() || '';
  if (!valueA && valueB) {
    return 1;
  }
  if (!valueB && valueA) {
    return -1;
  }
  return strA.toLocaleLowerCase().localeCompare(strB.toLocaleLowerCase());
};

export default function colDefText(colDef: Column) {
  return colDefType({
    comparator: stringComparator,
    ...colDef,
    filter: 'agTextColumnFilter',
    floatingFilterComponent,
  });
}
