/**
 * @generated SignedSource<<b4cac2a4dc7519c75fb62d98c2c035d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationMembershipType = "ADMIN" | "GUEST" | "REGULAR" | "%future added value";
export type BulkSetOrganizationMembershipInput = {
  clientMutationId?: string | null;
  domainId: string;
  organizationMemberships: ReadonlyArray<OrganizationIdUserIdPairs | null>;
  type: OrganizationMembershipType;
};
export type OrganizationIdUserIdPairs = {
  organizationId: string;
  userId: string;
};
export type BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation$variables = {
  input: BulkSetOrganizationMembershipInput;
};
export type BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation$data = {
  readonly bulkSetOrganizationMembership: {
    readonly failures: ReadonlyArray<{
      readonly code: string | null;
      readonly detail: string | null;
      readonly organizationId: string | null;
      readonly userId: string | null;
    }>;
    readonly users: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"DomainMembersGrid_domainUser">;
    } | null> | null;
  } | null;
};
export type BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation = {
  response: BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation$data;
  variables: BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "BulkSetOrganizationMembershipFailure",
  "kind": "LinkedField",
  "name": "failures",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detail",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkSetOrganizationMembershipPayload",
        "kind": "LinkedField",
        "name": "bulkSetOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUser",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DomainMembersGrid_domainUser"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkSetOrganizationMembershipPayload",
        "kind": "LinkedField",
        "name": "bulkSetOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUser",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrationDisplayNameFirst",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrationDisplayNameMiddle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrationDisplayNameLast",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canQa",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canFinalize",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserDicomField",
                "kind": "LinkedField",
                "name": "dicomFields",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainDicomFieldTemplate",
                    "kind": "LinkedField",
                    "name": "dicomFieldTemplate",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserIntegrationConfig",
                "kind": "LinkedField",
                "name": "integrationConfigs",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Ehr",
                    "kind": "LinkedField",
                    "name": "ehr",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "interfaceCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipRole",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organizations",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateLastActive",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0bb9b2c10ff03a09d92616ba37376925",
    "id": null,
    "metadata": {},
    "name": "BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation",
    "operationKind": "mutation",
    "text": "mutation BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation(\n  $input: BulkSetOrganizationMembershipInput!\n) {\n  bulkSetOrganizationMembership(input: $input) {\n    users {\n      ...DomainMembersGrid_domainUser\n      id\n    }\n    failures {\n      userId\n      organizationId\n      code\n      detail\n    }\n  }\n}\n\nfragment DomainMembersGrid_domainUser on DomainUser {\n  id\n  email\n  integrationDisplayNameFirst\n  integrationDisplayNameMiddle\n  integrationDisplayNameLast\n  canQa\n  canFinalize\n  type\n  dicomFields {\n    dicomFieldTemplate {\n      id\n      label\n    }\n    value\n  }\n  integrationConfigs {\n    ehr {\n      id\n      handle\n    }\n    interfaceCode\n  }\n  role {\n    name\n    id\n  }\n  organizations {\n    id\n    name\n  }\n  profile {\n    id\n  }\n  updatedAt\n  dateLastActive\n}\n"
  }
};
})();

(node as any).hash = "6810baacc7f2d70b602bcaa462a0aa3f";

export default node;
