/**
 * @generated SignedSource<<1622c6996b840f0740e40b85ba24fcfb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BulkUploadResults_failures$data = ReadonlyArray<{
  readonly code: string | null;
  readonly email: string | null;
  readonly " $fragmentType": "BulkUploadResults_failures";
}>;
export type BulkUploadResults_failures$key = ReadonlyArray<{
  readonly " $data"?: BulkUploadResults_failures$data;
  readonly " $fragmentSpreads": FragmentRefs<"BulkUploadResults_failures">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BulkUploadResults_failures",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "BulkDomainUserCreateFailure",
  "abstractKey": null
};

(node as any).hash = "09705746b7ef5aded3095ec35741286d";

export default node;
