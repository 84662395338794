import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import FormControlWithAddon from '@bfly/ui2/FormControlWithAddon';
import Heading from '@bfly/ui2/Heading';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import accountMessages from 'messages/account';
import vetPatientMessages, {
  neuterStatusMessages,
  speciesMessages,
  sexMessages as vetSexMessages,
} from 'messages/vetPatient';

import {
  NeuterStatus,
  Sex,
  Species,
  getCanUseSpeciesFreeForm,
} from '../schemas/vetStudyInfo';
import ExamInfoDatePickerField from './ExamInfoDatePickerField';
import { EditVetExamInfoFieldSet_study$data as Study } from './__generated__/EditVetExamInfoFieldSet_study.graphql';

interface Props {
  study: Study;
  disabled?: boolean;
}

function EditVetExamInfoFieldSet({ study, disabled }: Props) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  return (
    <>
      <Heading>
        <FormattedMessage {...vetPatientMessages.clientHeader} />
      </Heading>
      <Form.FieldGroup
        fluid
        name="clientNameFirst"
        autoComplete="off"
        disabled={disabled}
        label={accountMessages.firstName}
        placeholder={accountMessages.firstName}
      />
      {study.vetPatient?.clientNameMiddle && (
        <Form.FieldGroup
          fluid
          name="clientNameMiddle"
          autoComplete="off"
          label={accountMessages.middleName}
          placeholder={accountMessages.middleName}
          disabled={disabled}
        />
      )}
      <Form.FieldGroup
        fluid
        name="clientNameLast"
        autoComplete="off"
        disabled={disabled}
        label={accountMessages.lastName}
      />
      {study.vetPatient?.clientNamePrefix && (
        <Form.FieldGroup
          fluid
          name="clientNamePrefix"
          autoComplete="off"
          label={accountMessages.prefix}
          placeholder={accountMessages.prefix}
          disabled={disabled}
        />
      )}
      {study.vetPatient?.clientNameSuffix && (
        <Form.FieldGroup
          fluid
          name="clientNameSuffix"
          autoComplete="off"
          label={accountMessages.suffix}
          placeholder={accountMessages.suffix}
          disabled={disabled}
        />
      )}
      <Form.FieldGroup
        fluid
        name="clientOrganizationName"
        autoComplete="off"
        disabled={disabled}
        label={vetPatientMessages.clientOrganizationName}
        placeholder={vetPatientMessages.clientOrganizationName}
      />

      <Heading ruled>
        <FormattedMessage {...vetPatientMessages.patient} />
      </Heading>
      <Form.FieldGroup
        fluid
        name="name"
        autoComplete="off"
        disabled={disabled}
        label={vetPatientMessages.name}
        placeholder={vetPatientMessages.name}
      />
      <Form.FieldGroup
        fluid
        name="patientIdNumber"
        autoComplete="off"
        disabled={disabled}
        label={vetPatientMessages.patientIdNumber}
        placeholder={vetPatientMessages.patientIdNumber}
      />
      <Form.FieldGroup
        fluid
        name="microchipNumber"
        autoComplete="off"
        disabled={disabled}
        label={vetPatientMessages.microchipNumber}
        placeholder={vetPatientMessages.microchipNumber}
      />
      <Form.FieldGroup
        fluid
        dropUp
        name="species"
        as={DropdownList}
        disabled={disabled}
        label={vetPatientMessages.species}
        placeholder={vetPatientMessages.species}
        data={Object.values(Species)}
        textField={(v) => v && speciesMessages[v]}
      />
      <Form.Field name="species">
        {(_p, { value }) => (
          <>
            {getCanUseSpeciesFreeForm(value) && (
              <Form.FieldGroup
                fluid
                name="speciesFreeform"
                autoComplete="off"
                disabled={disabled}
                label={vetPatientMessages.speciesFreeform}
                placeholder={vetPatientMessages.speciesFreeformPlaceholder}
              />
            )}
          </>
        )}
      </Form.Field>
      <Form.FieldGroup
        fluid
        name="breed"
        autoComplete="off"
        disabled={disabled}
        label={vetPatientMessages.breed}
        placeholder={vetPatientMessages.breed}
      />
      <ExamInfoDatePickerField
        open={datePickerOpen}
        setOpen={setDatePickerOpen}
        disabled={disabled}
        label={vetPatientMessages.birthDate}
      />
      <Form.FieldGroup
        fluid
        dropUp
        name="sex"
        as={DropdownList}
        disabled={disabled}
        label={vetPatientMessages.sex}
        placeholder={vetPatientMessages.sex}
        data={Object.values(Sex)}
        textField={(v) => v && vetSexMessages[v]}
      />
      <Form.FieldGroup
        fluid
        dropUp
        name="neuterStatus"
        as={DropdownList}
        disabled={disabled}
        label={vetPatientMessages.neuterStatus}
        placeholder={vetPatientMessages.neuterStatus}
        data={Object.values(NeuterStatus)}
        textField={(v) => v && neuterStatusMessages[v]}
      />
      <Form.FieldGroup
        fluid
        name="weightKilograms"
        autoComplete="off"
        disabled={disabled}
        label={vetPatientMessages.weight}
        placeholder={vetPatientMessages.weight}
      >
        {(props) => <FormControlWithAddon {...props} addon="kg" />}
      </Form.FieldGroup>
    </>
  );
}

export default createFragmentContainer(EditVetExamInfoFieldSet, {
  study: graphql`
    fragment EditVetExamInfoFieldSet_study on Study {
      finalizedAt
      vetPatient {
        clientNamePrefix
        clientNameSuffix
        clientNameMiddle
      }
    }
  `,
});
