import { useRouter } from 'found';
import { useEffect } from 'react';

function LmsForceLoginRedirect() {
  const { router } = useRouter();

  useEffect(() => {
    const forceLogin = new URLSearchParams({
      lms: 'forceLogin',
    });

    router.replace(`/-/enterprise-login?${forceLogin}`);
  }, [router]);

  return null;
}

export default LmsForceLoginRedirect;
