import Layout from '@4c/layout';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import EhrSyncStatus from 'components/EhrSyncStatus';

import CancelEhrOruJobButton from './CancelEhrOruJobButton';
import SendStudyToEhrButton from './SendStudyToEhrButton';
import { StudyEhrJobDetail_ehr$data as Ehr } from './__generated__/StudyEhrJobDetail_ehr.graphql';
import { StudyEhrJobDetail_job$data as Job } from './__generated__/StudyEhrJobDetail_job.graphql';
import { StudyEhrJobDetail_study$data as Study } from './__generated__/StudyEhrJobDetail_study.graphql';

interface Props {
  job: Job | null;
  study: Study;
  ehr: Ehr;
  title: React.ReactNode;
  children?: React.ReactNode;
}

function StudyEhrJobDetail({ job, study, ehr, title, children }: Props) {
  return (
    <Layout className="py-3" align="center" pad={6}>
      <Layout direction="column" grow>
        <Text className="mb-1" variant="lg">
          {title}
        </Text>
        <Text variant="sm">
          {job ? (
            <EhrSyncStatus job={job} />
          ) : (
            <FormattedMessage
              id="study.ehr.notSynced"
              defaultMessage="Study Not Synced"
            />
          )}
        </Text>
      </Layout>
      {children}
      <SendStudyToEhrButton study={study} ehr={ehr} job={job} />
      {job &&
        (job.status === 'PENDING' ||
          job.status === 'FAILED' ||
          job.status === 'RETRYING') && <CancelEhrOruJobButton job={job} />}
    </Layout>
  );
}

export default createFragmentContainer(StudyEhrJobDetail, {
  job: graphql`
    fragment StudyEhrJobDetail_job on EhrOruJob {
      status
      ...EhrSyncStatus_job
      ...SendStudyToEhrButton_job
      ...CancelEhrOruJobButton_job
    }
  `,
  study: graphql`
    fragment StudyEhrJobDetail_study on Study {
      ...SendStudyToEhrButton_study
    }
  `,
  ehr: graphql`
    fragment StudyEhrJobDetail_ehr on Ehr {
      name
      ...SendStudyToEhrButton_ehr
    }
  `,
});
