import useQuery from '@bfly/ui2/useQuery';
import notNullish from '@bfly/utils/notNullish';
import { graphql } from 'relay-runtime';

import { useStudyListLazyImagesQuery as UseStudyListLazyImagesQuery } from './__generated__/useStudyListLazyImagesQuery.graphql';

export default function useStudyListLazyImages(
  studyIds: string[] | null | undefined,
  skip = false,
) {
  const { data } = useQuery<UseStudyListLazyImagesQuery>(
    graphql`
      query useStudyListLazyImagesQuery($ids: [ID!]!) {
        nodes(ids: $ids) {
          id
          ... on Study {
            ...StudyDataGrid_studiesWithImages
            ...StudyList_studiesWithImages
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-or-network',
      variables: { ids: studyIds || [] },
      skip: skip || !studyIds?.length,
    },
  );

  return data?.nodes.filter(notNullish) ?? null;
}
