import type { Placement } from '@bfly/ui2/Popover';
import { stylesheet } from 'astroturf';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { graphql, useMutation } from 'react-relay';

import Analytics, { AnalyticsEvent } from 'utils/Analytics';
import { useViewerAllowMissingContext } from 'utils/viewerContext';

import { isInApp } from '../../utils/browserInfo';
import ExperiencePopover from './ExperiencePopover';
import { ExperiencesProviderMarkExperiencedMutation } from './__generated__/ExperiencesProviderMarkExperiencedMutation.graphql';

export enum Experience {
  VIEW_STUDY_DETAIL = 'web.view_study_detail',
  INTERACT_COMMENT_BOX = 'web.interact_comment_box',
  VIEW_EDU_PAGE = 'web.view_edu_page',
  VIEW_SCANLAB_PAGE = 'web.view_scanlab_page',
}

export interface PopoverConfig {
  target: HTMLElement | null;
  placement?: Placement;
  fixed?: boolean;
  header?: React.ReactNode;
  message: React.ReactNode;
  className?: string;
}

const styles = stylesheet`
  .relative {
    position: relative;
    z-index: 0;
  }
`;

const ExperiencesContext = React.createContext<{
  tutorialStep: Experience | null;
  markCompleted: (key: Experience, analyticsEvent: string) => void;
  setPopoverConfig: (config: PopoverConfig) => void;
}>({} as any);

const mutation = graphql`
  mutation ExperiencesProviderMarkExperiencedMutation(
    $input: MarkExperiencedInput!
    $key: String!
  ) @raw_response_type {
    markExperienced(input: $input) {
      viewer {
        id
        didExperience(key: $key)
      }
    }
  }
`;

interface Props {
  children?: React.ReactNode;
}

// We encode most of the logic for when to show tooltips here so that views
// don't need to be aware of other experiences (and also to avoid having to
// thread `viewer` through all of the components).
export default function ExperiencesProvider({ children }: Props) {
  const viewer = useViewerAllowMissingContext();
  const inApp = isInApp();
  const [{ target, fixed, ...popoverProps }, setState] =
    useState<PopoverConfig>({
      target: null,
      message: '',
    });

  const tutorialStep = useMemo(() => {
    if (!viewer || !viewer.hasAcceptedLatestEula) {
      // Don't display tutorial steps for users who aren't logged in or who are
      // on the EULA click-wrap page.
      return null;
    }

    if (!viewer.didViewStudyDetail) {
      return Experience.VIEW_STUDY_DETAIL;
    }
    if (!viewer.didInteractCommentBox) {
      return Experience.INTERACT_COMMENT_BOX;
    }
    if (!viewer.didViewEduPage && !inApp) {
      return Experience.VIEW_EDU_PAGE;
    }

    // Nothing to show.
    return null;
  }, [viewer, inApp]);

  const [mutate] =
    useMutation<ExperiencesProviderMarkExperiencedMutation>(mutation);

  const markCompleted = useCallback(
    (key: Experience, analyticsEvent: AnalyticsEvent) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Analytics.track(analyticsEvent, { experience_key: key });

      if (!viewer) {
        return;
      }

      // optimistically clear the target, so that the UI can
      // respond to the new tutorial step
      setState({ target: null, message: '' });

      mutate({
        optimisticResponse: {
          markExperienced: {
            viewer: {
              // Viewer ID is needed so that optimistic response can correlate the viewer.
              id: viewer.id,
              didExperience: true,
            },
          },
        },
        variables: {
          input: { key },
          key,
        },
      });
    },
    [mutate, viewer],
  );

  const markKeyDismissed = useCallback(() => {
    if (!tutorialStep) return;

    markCompleted(tutorialStep, 'experienceTooltipDismissed');
  }, [tutorialStep, markCompleted]);

  const context = useMemo(
    () => ({
      tutorialStep,
      markCompleted,
      setPopoverConfig: setState,
    }),
    [tutorialStep, markCompleted],
  );

  return (
    <ExperiencesContext.Provider value={context}>
      {children}
      {target && (
        // The wrapper here naturally moves the popover _under_ the fixed navbar
        // for popovers in the navbar, use 'strategy: fixed'
        <div className={!fixed ? styles.relative : undefined}>
          <ExperiencePopover
            target={target}
            strategy={fixed ? 'fixed' : 'absolute'}
            onDismiss={markKeyDismissed}
            {...popoverProps}
          />
        </div>
      )}
    </ExperiencesContext.Provider>
  );
}

export function useExperiencesContext() {
  return useContext(ExperiencesContext);
}
