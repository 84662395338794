/**
 * @generated SignedSource<<e02df580cf3e27b29ae89e00b8d39309>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBar_studyTags$data = ReadonlyArray<{
  readonly handle: string | null;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarList_studyTag" | "SearchBarTags_studyTag">;
  readonly " $fragmentType": "SearchBar_studyTags";
}>;
export type SearchBar_studyTags$key = ReadonlyArray<{
  readonly " $data"?: SearchBar_studyTags$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBar_studyTags">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SearchBar_studyTags",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "SearchBarTags_studyTag",
      "selections": (v1/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "SearchBarList_studyTag",
      "selections": (v1/*: any*/),
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "StudyTag",
  "abstractKey": null
};
})();

(node as any).hash = "4965850fea0e3c973fe9e078fbbe4303";

export default node;
