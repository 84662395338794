/**
 * @generated SignedSource<<215dfb237727b389ac3b74e549d14bef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PacsPushJobStatus = "CANCELED" | "COMPLETE" | "FAILED" | "PENDING" | "RETRYING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SendStudyToPacsButton_job$data = {
  readonly id: string;
  readonly status: PacsPushJobStatus | null;
  readonly " $fragmentType": "SendStudyToPacsButton_job";
};
export type SendStudyToPacsButton_job$key = {
  readonly " $data"?: SendStudyToPacsButton_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"SendStudyToPacsButton_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SendStudyToPacsButton_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "PacsPushJob",
  "abstractKey": null
};

(node as any).hash = "494fc249c6a4f25327ba15627620dfcd";

export default node;
