/**
 * @generated SignedSource<<2b22d43c75fd465459f799b82444b7c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AcceptUserEmailInput = {
  clientMutationId?: string | null;
  token: string;
  userEmailId: any;
};
export type VerifyEmailChangePage_AcceptSecondaryEmailMutation$variables = {
  input: AcceptUserEmailInput;
};
export type VerifyEmailChangePage_AcceptSecondaryEmailMutation$data = {
  readonly acceptUserEmailOrError: {
    readonly __typename: string;
    readonly message?: string | null;
  } | null;
};
export type VerifyEmailChangePage_AcceptSecondaryEmailMutation = {
  response: VerifyEmailChangePage_AcceptSecondaryEmailMutation$data;
  variables: VerifyEmailChangePage_AcceptSecondaryEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "acceptUserEmailOrError",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "ErrorInterface",
        "abstractKey": "__isErrorInterface"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyEmailChangePage_AcceptSecondaryEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyEmailChangePage_AcceptSecondaryEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "80c3fbd0cd9c2bd9886ed8bd3186b16b",
    "id": null,
    "metadata": {},
    "name": "VerifyEmailChangePage_AcceptSecondaryEmailMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyEmailChangePage_AcceptSecondaryEmailMutation(\n  $input: AcceptUserEmailInput!\n) {\n  acceptUserEmailOrError(input: $input) {\n    __typename\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4618cda70373bd21f921848b7a842017";

export default node;
