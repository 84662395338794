/**
 * @generated SignedSource<<f537331118a298b362a2f3863f2c6a09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancelPacsPushJobButton_job$data = {
  readonly id: string;
  readonly " $fragmentType": "CancelPacsPushJobButton_job";
};
export type CancelPacsPushJobButton_job$key = {
  readonly " $data"?: CancelPacsPushJobButton_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancelPacsPushJobButton_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancelPacsPushJobButton_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "PacsPushJob",
  "abstractKey": null
};

(node as any).hash = "5fc9fabe6bb3b010e8af6448bb098ab3";

export default node;
