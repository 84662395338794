/**
 * @generated SignedSource<<5042c749de9529b12b092bb49893b08c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type routeConfig_OrganizationQuery$variables = {
  organizationSlug: string;
};
export type routeConfig_OrganizationQuery$data = {
  readonly organization: {
    readonly id: string;
    readonly setupAt: string | null;
    readonly slug: string | null;
  } | null;
};
export type routeConfig_OrganizationQuery = {
  response: routeConfig_OrganizationQuery$data;
  variables: routeConfig_OrganizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "alias": "organization",
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "organizationSlug"
      }
    ],
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "organizationBySlug",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "setupAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_OrganizationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeConfig_OrganizationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a57454a9381e1664633d2e656b5e674",
    "id": null,
    "metadata": {},
    "name": "routeConfig_OrganizationQuery",
    "operationKind": "query",
    "text": "query routeConfig_OrganizationQuery(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    id\n    slug\n    setupAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "022f3b26be6ba58eb1ac2ab14aca7a78";

export default node;
