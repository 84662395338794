import Layout from '@4c/layout';
import BackButton from '@bfly/ui2/BackButton';
import Form from '@bfly/ui2/Form';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import { Link } from 'found';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PageTitle from 'components/PageTitle';
import { useAdminRoutes } from 'routes/admin';

import { deserialize, updateRoleSchema } from '../schemas/roles';
import DomainRoleDetailFieldSet from './DomainRoleDetailsFieldSet';
import DomainRolePermissionsFieldSet from './DomainRolePermissionsFieldSet';
import { UpdateDomainRolePage_membershipRole$data as MembershipRole } from './__generated__/UpdateDomainRolePage_membershipRole.graphql';

const messages = defineMessages({
  header: {
    id: 'domain.roles.readDefinition',
    defaultMessage: 'Butterfly Access Roles',
  },
});

interface Props {
  membershipRole: MembershipRole;
}

function DomainRoleDefinitionPage({ membershipRole }: Props) {
  const routes = useAdminRoutes();

  const { name, description, permissions } = useMemo(
    () => deserialize(membershipRole),
    [membershipRole],
  );

  const membershipRoleData = {
    name,
    description,
    permissions,
  };

  return (
    <Page.Container>
      <MainContent>
        <Layout pad={4} className="items-center mb-10">
          <Link to={routes.domainRoles()}>
            <BackButton />
          </Link>
          <PageTitle title={messages.header} />
        </Layout>
        <Form
          horizontal
          as={null}
          schema={updateRoleSchema}
          defaultValue={membershipRoleData as any}
        >
          <>
            <DomainRoleDetailFieldSet readOnly />
            <DomainRolePermissionsFieldSet readOnly />
          </>
        </Form>
      </MainContent>
    </Page.Container>
  );
}

export default createFragmentContainer(DomainRoleDefinitionPage, {
  membershipRole: graphql`
    fragment DomainRoleDefinitionPage_membershipRole on MembershipRole {
      ...roles_defaultValue
    }
  `,
});
