import { Location, useRouter } from 'found';
import { useCallback } from 'react';
import { graphql, readInlineData } from 'relay-runtime';

import { routes } from 'routes/exam';
import { SetState, useLocationState } from 'utils/LocationUtils';
import { SearchData } from 'utils/Search';

import schema from '../schemas/searchData';
import { useSearchState_tenant$key as TenantKey } from './__generated__/useSearchState_tenant.graphql';

const NAMESPACE = 'searchData';

export function getSearchData(location: Location): SearchData {
  return { ...schema.getDefault(), ...location.state?.[NAMESPACE] };
}

export default function useSearchState(tenantRef: TenantKey) {
  const { match } = useRouter();

  const tenant = readInlineData(
    graphql`
      fragment useSearchState_tenant on TenantInterface @inline {
        ... on Organization {
          slug
        }
      }
    `,
    tenantRef,
  );

  const [value, setValue] = useLocationState(schema, NAMESPACE);

  const searchRoute = routes.search({ slug: tenant.slug || '-' });

  const isSearchRoute = match.location.pathname === searchRoute;

  return [
    value,
    useCallback(
      (searchData) => {
        setValue(
          searchData as any,
          !isSearchRoute
            ? {
                action: 'push',
                pathname: searchRoute,
              }
            : {},
        );
      },
      [setValue, isSearchRoute, searchRoute],
    ),
  ] as [SearchData, SetState<Partial<SearchData>>];
}
