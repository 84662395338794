/**
 * @generated SignedSource<<71af6bdcf68c44d70f9b49f06e0fafc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySearchPage_studyTags$data = ReadonlyArray<{
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBar_studyTags">;
  readonly " $fragmentType": "StudySearchPage_studyTags";
}>;
export type StudySearchPage_studyTags$key = ReadonlyArray<{
  readonly " $data"?: StudySearchPage_studyTags$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySearchPage_studyTags">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudySearchPage_studyTags",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBar_studyTags"
    }
  ],
  "type": "StudyTag",
  "abstractKey": null
};

(node as any).hash = "734b9834a4c71fe6194d3c36bd3d3f11";

export default node;
