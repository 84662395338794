/**
 * @generated SignedSource<<e7d38ec0bbb8dba52a83e29fb34ffd2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteStudyInput = {
  clientMutationId?: string | null;
  studyId: any;
};
export type useDeleteStudiesMutation$variables = {
  input: DeleteStudyInput;
};
export type useDeleteStudiesMutation$data = {
  readonly deleteStudyOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
  } | null;
};
export type useDeleteStudiesMutation = {
  response: useDeleteStudiesMutation$data;
  variables: useDeleteStudiesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteStudiesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteStudyOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteStudiesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteStudyOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0767a127d1f4d9773ad718d6381070a9",
    "id": null,
    "metadata": {},
    "name": "useDeleteStudiesMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteStudiesMutation(\n  $input: DeleteStudyInput!\n) {\n  deleteStudyOrError(input: $input) {\n    __typename\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9dbde720b68b5e076c70bb27dd97e24e";

export default node;
