/**
 * @generated SignedSource<<98ea3ab410dfc4df2b8f7db68bfc64eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TermsAndConditionsPage_latestTermsAndConditions$data = {
  readonly textHtml: string | null;
  readonly url: string | null;
  readonly " $fragmentType": "TermsAndConditionsPage_latestTermsAndConditions";
};
export type TermsAndConditionsPage_latestTermsAndConditions$key = {
  readonly " $data"?: TermsAndConditionsPage_latestTermsAndConditions$data;
  readonly " $fragmentSpreads": FragmentRefs<"TermsAndConditionsPage_latestTermsAndConditions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TermsAndConditionsPage_latestTermsAndConditions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textHtml",
      "storageKey": null
    }
  ],
  "type": "TermsAndConditions",
  "abstractKey": null
};

(node as any).hash = "d894ea0178ce8136b2bfb723e82324bc";

export default node;
