/* eslint-disable relay/must-colocate-fragment-spreads */
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Listbox from '@bfly/ui2/Listbox';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import Tooltip from '@bfly/ui2/Tooltip';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql } from 'react-relay';

import actionMessages from 'messages/actions';
import withModal from 'utils/withModal';

import {
  ExamQaSelectModal_ContentQuery as ContentQuery,
  ExamQaSelectModal_ContentQuery$data as ContentQueryResponse,
} from './__generated__/ExamQaSelectModal_ContentQuery.graphql';

type QaTemplate = ConnectionNodeType<
  ContentQueryResponse['organization'],
  'qaTemplateConnection'
>;

export type QaTemplateVersion = NonNullable<QaTemplate['latestVersion']>;

interface Props {
  organizationId: string;
  existingTemplateIds: string[] | null;
  onHide: () => void;
  onSelect: (selectedTemplateVersion: QaTemplateVersion) => any;
}

function ExamQaSelectModal({
  organizationId,
  onHide,
  onSelect,
  existingTemplateIds,
}: Props) {
  const intl = useIntl();
  const [selected, setSelected] = useState<string | null>(null);

  const { data } = useQuery<ContentQuery>(
    graphql`
      query ExamQaSelectModal_ContentQuery($organizationId: ID!) {
        organization: node(id: $organizationId) {
          ... on Organization {
            qaTemplateConnection {
              edges {
                node {
                  id
                  latestVersion {
                    id
                    title
                    ...qaEntry_templateVersion
                    # This is needed by the parent b/c Relay makes forms annoying
                    # eslint-disable-next-line relay/must-colocate-fragment-spreads
                    ...ExamQa_templateVersion
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: { organizationId },
    },
  );

  const qaTemplates: QaTemplate[] = data
    ? getNodes(data?.organization?.qaTemplateConnection)
    : [];

  const getDisabled = (templates: QaTemplate[]) =>
    templates.filter((qaTemplate) =>
      existingTemplateIds?.some((id) => id === qaTemplate.latestVersion!.id),
    );

  const handleSubmit = async () => {
    const template = qaTemplates.find((t) => t.id === selected)?.latestVersion;
    if (template) {
      await onSelect(template);
    }
    onHide();
  };

  return (
    <Form noValidate submitForm={handleSubmit}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="examQaSelectModal.add.title"
            defaultMessage="Add a Review Card"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
          <Listbox<QaTemplate>
            data={qaTemplates}
            disabled={getDisabled(qaTemplates)}
            value={selected}
            renderItem={({ disabled, text }) => {
              if (disabled) {
                return (
                  <Tooltip.Trigger
                    placement="left"
                    tooltip={intl.formatMessage({
                      id: 'examQaSelectModal.qaCardAlreadySelected',
                      defaultMessage:
                        'This review card has already been added to this exam. Please choose a different review card.',
                    })}
                  >
                    <div className="w-full">{text}</div>
                  </Tooltip.Trigger>
                );
              }
              return text;
            }}
            dataKey="id"
            textField={(d: QaTemplate) => d.latestVersion!.title!}
            onChange={(d) => setSelected(d.id!)}
            className="h-full"
          />
        ) : (
          <LoadingIndicator />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Form.Submit
            size="lg"
            disabled={!selected}
            data-bni-id="SelectQaTemplateSubmitButton"
          >
            <FormattedMessage {...actionMessages.select} />
          </Form.Submit>

          <Button size="lg" variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </Form>
  );
}

export default withModal(ExamQaSelectModal);
