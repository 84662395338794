/**
 * @generated SignedSource<<1b31c9c58df61639c71000252e66cbe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyDownloadPdfDropdownItem_study$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionInfo_organization">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyDownloadPdfModal_study" | "StudyPermissions_canGenerateStudyPdf">;
  readonly " $fragmentType": "StudyDownloadPdfDropdownItem_study";
};
export type StudyDownloadPdfDropdownItem_study$key = {
  readonly " $data"?: StudyDownloadPdfDropdownItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyDownloadPdfDropdownItem_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyDownloadPdfDropdownItem_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyDownloadPdfModal_study"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canGenerateStudyPdf",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "practiceType",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_studyOrArchiveIsDeleted",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useSubscriptionInfo_organization",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationSubscription",
              "kind": "LinkedField",
              "name": "subscription",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canAccessProFeatures",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isInGracePeriod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "planType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "e9d8244bcd99cea89166bbf321d58679";

export default node;
