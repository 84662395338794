import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';

import { TDataBase } from 'components/AgGrid/types';

import { Messages } from './messages';

export default function EditControls({
  changes,
  handleReset,
  handleSave,
  loading,
  messages,
}: {
  changes: TDataBase[] | undefined;
  handleReset: () => void;
  handleSave: () => void;
  loading: boolean;
  messages: Messages;
}) {
  const hasChanges = !!changes?.length;

  const disableButton = loading || !hasChanges;
  return (
    <Layout
      data-bni-id="agGridEditControls"
      justify="flex-end"
      className="m-4"
      pad={4}
    >
      {hasChanges && (
        <Text as="div" className="flex items-center">
          <FormattedMessage
            {...messages?.unsavedChanges}
            values={{ unsavedChangesCount: changes.length }}
          />
        </Text>
      )}
      <Button
        variant="secondary"
        onClick={() => handleReset()}
        disabled={disableButton}
      >
        <FormattedMessage {...messages.discardChanges} />
      </Button>
      <Button
        variant="primary"
        disabled={disableButton}
        busy={loading}
        onClick={() => handleSave()}
      >
        <FormattedMessage {...messages.saveChanges} />
      </Button>
    </Layout>
  );
}
