import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import DropdownForm from '@bfly/ui2/DropdownForm';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import actionMessages from 'messages/actions';

import educationMessages from '../routes/education/messages/educationMessages';

interface Props<T> {
  defaultValue?: T;
  sortedValue?: T;
  data: T[];
  onChange: (value: T | undefined) => void;
  onReset?: (value?: T | null) => void;
  className?: string;
}
function SortSelectButton<T extends { label: string; id: string }>({
  defaultValue,
  sortedValue,
  data,
  onReset,
  onChange,
  className,
}: Props<T>) {
  const { formatMessage } = useIntl();
  const [show, setShow] = useState(false);
  const [sortValue, setSortedValue] = useState(defaultValue);
  return (
    <DropdownForm
      show={show}
      label={`${formatMessage(
        educationMessages.sortBy,
      )} ${sortedValue?.label.toLowerCase()}`}
      className={className}
      value={sortedValue}
      onToggle={(nextShow) => {
        setSortedValue(sortedValue);
        setShow(nextShow);
      }}
      data-bni-id="EducationContentSortBy"
    >
      <div className="p-4 max-h-80 scrollable-y">
        <FormCheckGroup
          type="radio"
          name="sort"
          data={data}
          dataKey="id"
          className="border-b border-divider"
          value={sortValue}
          onChange={(value) => setSortedValue(value)}
          renderItem={(item) => item.label}
        />
      </div>
      <Layout className="justify-between px-4 pb-2">
        <Button
          onClick={() => {
            setShow(false);
            setSortedValue(defaultValue);
            if (onReset) {
              onReset();
            }
          }}
          variant="secondary"
        >
          <FormattedMessage {...actionMessages.reset} />
        </Button>
        <Button
          onClick={() => {
            setShow(false);
            onChange(sortValue);
          }}
        >
          <FormattedMessage {...actionMessages.done} />
        </Button>
      </Layout>
    </DropdownForm>
  );
}

export default SortSelectButton;
