/**
 * @generated SignedSource<<64d4dd80175b1046b40c9551272059af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyStatistics_userHistoricalProficiency$data = {
  readonly numCountsTowardsCredentialing: number | null;
  readonly userHistoricalProficiency: {
    readonly examTypeCounts: ReadonlyArray<{
      readonly count: number | null;
      readonly examType: {
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "StudyStatistics_userHistoricalProficiency";
};
export type StudyStatistics_userHistoricalProficiency$key = {
  readonly " $data"?: StudyStatistics_userHistoricalProficiency$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyStatistics_userHistoricalProficiency">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyStatistics_userHistoricalProficiency",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numCountsTowardsCredentialing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserHistoricalProficiency",
      "kind": "LinkedField",
      "name": "userHistoricalProficiency",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamTypeCount",
          "kind": "LinkedField",
          "name": "examTypeCounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamType",
              "kind": "LinkedField",
              "name": "examType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyStatisticsByAuthor",
  "abstractKey": null
};

(node as any).hash = "4974cd879a968d963f800788b0d04bcf";

export default node;
