/**
 * @generated SignedSource<<8b13b3b3f149314341a48ff6d6adb011>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VetPatientNeuterStatus = "INTACT" | "NEUTERED" | "UNKNOWN" | "%future added value";
export type VetPatientSex = "FEMALE" | "MALE" | "UNKNOWN" | "%future added value";
export type VetPatientSpecies = "AVIAN" | "CANINE" | "EQUINE" | "EXOTIC" | "FELINE" | "OTHER" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type VetPatientInfo_vetPatient$data = {
  readonly birthDate: string | null;
  readonly breed: string | null;
  readonly microchipNumber: string | null;
  readonly neuterStatus: VetPatientNeuterStatus | null;
  readonly patientIdNumber: string | null;
  readonly sex: VetPatientSex | null;
  readonly species: VetPatientSpecies | null;
  readonly speciesFreeform: string | null;
  readonly weightKilograms: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"VetPatientWeight_vetPatient" | "VetSpecies_vetPatient">;
  readonly " $fragmentType": "VetPatientInfo_vetPatient";
};
export type VetPatientInfo_vetPatient$key = {
  readonly " $data"?: VetPatientInfo_vetPatient$data;
  readonly " $fragmentSpreads": FragmentRefs<"VetPatientInfo_vetPatient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VetPatientInfo_vetPatient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "microchipNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "species",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "speciesFreeform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "breed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "neuterStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weightKilograms",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VetPatientWeight_vetPatient"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VetSpecies_vetPatient"
    }
  ],
  "type": "VetPatient",
  "abstractKey": null
};

(node as any).hash = "a140160d89b5049b4517e10688034686";

export default node;
