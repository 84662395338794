/**
 * @generated SignedSource<<d9d61b7247a0615c8ac57e5f8f5cf109>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type studyProtocols_CreateStudyIndicationProtocolPage_Query$variables = {
  organizationSlug: string;
};
export type studyProtocols_CreateStudyIndicationProtocolPage_Query$data = {
  readonly tenant: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CreateStudyProtocolPage_tenant">;
  } | null;
};
export type studyProtocols_CreateStudyIndicationProtocolPage_Query = {
  response: studyProtocols_CreateStudyIndicationProtocolPage_Query$data;
  variables: studyProtocols_CreateStudyIndicationProtocolPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "studyProtocols_CreateStudyIndicationProtocolPage_Query",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateStudyProtocolPage_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "studyProtocols_CreateStudyIndicationProtocolPage_Query",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "organizationId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "domainId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "TenantInterface",
            "abstractKey": "__isTenantInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c4ec2ee181b730741868422aafa78a4",
    "id": null,
    "metadata": {},
    "name": "studyProtocols_CreateStudyIndicationProtocolPage_Query",
    "operationKind": "query",
    "text": "query studyProtocols_CreateStudyIndicationProtocolPage_Query(\n  $organizationSlug: String!\n) {\n  tenant: organizationBySlug(slug: $organizationSlug) {\n    id\n    ...CreateStudyProtocolPage_tenant\n  }\n}\n\nfragment CreateStudyProtocolPage_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ...useTenantIdInput_tenant\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "65725f3457c47e63112fd178785bab55";

export default node;
