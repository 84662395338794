/**
 * @generated SignedSource<<4c4bc2be7b5a3d54217adb5bd7010d05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BulkDomainUsersDeactivateUsersResultsModal_failures$data = ReadonlyArray<{
  readonly code: string | null;
  readonly detail: string | null;
  readonly email: string | null;
  readonly " $fragmentType": "BulkDomainUsersDeactivateUsersResultsModal_failures";
}>;
export type BulkDomainUsersDeactivateUsersResultsModal_failures$key = ReadonlyArray<{
  readonly " $data"?: BulkDomainUsersDeactivateUsersResultsModal_failures$data;
  readonly " $fragmentSpreads": FragmentRefs<"BulkDomainUsersDeactivateUsersResultsModal_failures">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BulkDomainUsersDeactivateUsersResultsModal_failures",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "BulkDomainUserDeleteFailure",
  "abstractKey": null
};

(node as any).hash = "a4c3eb60b24df35b18d854ee7026e278";

export default node;
