import Redirect from 'found/Redirect';
import { graphql } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import Route from 'components/Route';
import routes from 'routes/admin';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';

const worklistItemRoute = (
  <Route
    path=":worklistHandle/items"
    getComponent={() =>
      import(/* webpackChunkName: "admin" */ './components/WorklistPage').then(
        (m) => m.default,
      )
    }
    query={graphql`
      query integrations_WorklistPage_Query($worklistHandle: String!) {
        worklist: worklist(handle: $worklistHandle) {
          ...WorklistPage_worklist
        }
      }
    `}
  />
);

export const organizationIntegrationsRoute = (
  <Route
    path="integrations"
    prerender={checkFlagsOr404(
      (ld) => !getVariation(ld, 'domain-connectivity-settings'),
    )}
  >
    <Route path="pacs">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.pacsNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      <Route
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreatePacsIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_CreatePacsConnectionPage_Query(
            $organizationSlug: String!
          ) {
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...CreatePacsIntegrationPage_connectivityConfig
            }
          }
        `}
      />
      <Route
        path=":pacsHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdatePacsIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_UpdatePacsConnectionPage_Query(
            $organizationSlug: String!
            $pacsHandle: String!
          ) {
            pacs(handle: $pacsHandle) {
              ...UpdatePacsIntegrationPage_pacs
            }
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...UpdatePacsIntegrationPage_connectivityConfig
            }
          }
        `}
      />
    </Route>

    <Route path="mwl">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.mwlNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      <Route
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateModalityWorklistIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_CreateModalityWorklistIntegrationPage_Query(
            $organizationSlug: String!
          ) {
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...CreateModalityWorklistIntegrationPage_connectivityConfig
            }
          }
        `}
      />
      <Route
        path=":modalityWorklistHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateModalityWorklistIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_UpdateModalityWorklistIntegrationPage_Query(
            $organizationSlug: String!
            $modalityWorklistHandle: String!
          ) {
            modalityWorklist(handle: $modalityWorklistHandle) {
              ...UpdateModalityWorklistIntegrationPage_modalityWorklist
            }
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...UpdateModalityWorklistIntegrationPage_connectivityConfig
            }
          }
        `}
      />

      {worklistItemRoute}
    </Route>

    <Route path="ehr">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.ehrNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      <Route
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateEhrIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_CreateEhrIntegrationPage_Query(
            $organizationSlug: String!
          ) {
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...CreateEhrIntegrationPage_connectivityConfig
            }
          }
        `}
        prerender={checkFlagsOr404('hl7-connector')}
      />
      <Route
        path=":ehrHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateEhrIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_UpdateEhrIntegrationPage_Query(
            $organizationSlug: String!
            $ehrHandle: String!
          ) {
            ehr(handle: $ehrHandle) {
              ...UpdateEhrIntegrationPage_ehr
            }
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...UpdateEhrIntegrationPage_connectivityConfig
            }
          }
        `}
      />
    </Route>

    <Route path="fhir">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.fhirNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      <Route
        path="-/new"
        prerender={checkFlagsOr404('fhir-integrations')}
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateFhirIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_CreateFhirIntegrationPage_Query(
            $organizationSlug: String!
          ) {
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...CreateFhirIntegrationPage_connectivityConfig
            }
          }
        `}
      />
      <Route
        path=":fhirIntegrationHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateFhirIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_UpdateFhirIntegrationPage_Query(
            $organizationSlug: String!
            $fhirIntegrationHandle: String!
          ) {
            fhirIntegration(handle: $fhirIntegrationHandle) {
              ...UpdateFhirIntegrationPage_fhirIntegration
            }
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...UpdateFhirIntegrationPage_connectivityConfig
            }
          }
        `}
      />
    </Route>

    <Route path="encounter-based-worklist">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.worklistNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      {worklistItemRoute}
      <Route
        path="-/new"
        prerender={checkFlagsOr404('encounter-based-worklists')}
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateEncounterBasedWorklistIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_CreateEncounterBasedWorklistIntegrationPage_Query(
            $organizationSlug: String!
          ) {
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...CreateEncounterBasedWorklistIntegrationPage_connectivityConfig
            }
          }
        `}
      />
      <Route
        path=":worklistHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateEncounterBasedWorklistIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_UpdateEncounterBasedWorklistIntegrationPage_Query(
            $organizationSlug: String!
            $worklistHandle: String!
          ) {
            encounterBasedWorklist(handle: $worklistHandle) {
              ...UpdateEncounterBasedWorklistIntegrationPage_encounterBasedWorklist
            }
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...UpdateEncounterBasedWorklistIntegrationPage_connectivityConfig
            }
          }
        `}
      />
    </Route>
  </Route>
);

export const domainIntegrationsRoute = (
  <Route
    path="integrations"
    prerender={checkFlagsOr404('domain-connectivity-settings')}
  >
    <Route path="pacs">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.pacsNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      <Route
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreatePacsIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainCreatePacsConnectionPage_Query {
            viewer {
              domain {
                ...CreatePacsIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
      <Route
        path=":pacsHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdatePacsIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainUpdatePacsConnectionPage_Query(
            $pacsHandle: String!
          ) {
            pacs(handle: $pacsHandle) {
              ...UpdatePacsIntegrationPage_pacs
            }
            viewer {
              domain {
                ...UpdatePacsIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
    </Route>

    <Route path="mwl">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.mwlNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      <Route
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateModalityWorklistIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainCreateModalityWorklistIntegrationPage_Query {
            viewer {
              domain {
                ...CreateModalityWorklistIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
      <Route
        path=":modalityWorklistHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateModalityWorklistIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainUpdateModalityWorklistIntegrationPage_Query(
            $modalityWorklistHandle: String!
          ) {
            modalityWorklist(handle: $modalityWorklistHandle) {
              ...UpdateModalityWorklistIntegrationPage_modalityWorklist
            }
            viewer {
              domain {
                ...UpdateModalityWorklistIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
      {worklistItemRoute}
    </Route>

    <Route path="ehr">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.ehrNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      <Route
        path="-/new"
        prerender={checkFlagsOr404('hl7-connector')}
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateEhrIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainCreateEhrIntegrationPage_Query {
            viewer {
              domain {
                ...CreateEhrIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
      <Route
        path=":ehrHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateEhrIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainUpdateEhrIntegrationPage_Query(
            $ehrHandle: String!
          ) {
            ehr(handle: $ehrHandle) {
              ...UpdateEhrIntegrationPage_ehr
            }
            viewer {
              domain {
                ...UpdateEhrIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
    </Route>

    <Route path="fhir">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.fhirNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      <Route
        path="-/new"
        prerender={checkFlagsOr404('fhir-integrations')}
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateFhirIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainCreateFhirIntegrationPage_Query {
            viewer {
              domain {
                ...CreateFhirIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
      <Route
        path=":fhirIntegrationHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateFhirIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainUpdateFhirIntegrationPage_Query(
            $fhirIntegrationHandle: String!
          ) {
            fhirIntegration(handle: $fhirIntegrationHandle) {
              ...UpdateFhirIntegrationPage_fhirIntegration
            }
            viewer {
              domain {
                ...UpdateFhirIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
    </Route>

    <Route path="encounter-based-worklist">
      <Redirect
        to={(match) => {
          const adminRoutes = routes(match);
          return {
            pathname: adminRoutes.worklistNew({
              organizationSlug: match.params.organizationSlug,
            }),
          };
        }}
      />
      <Route
        path="-/new"
        prerender={checkFlagsOr404('encounter-based-worklists')}
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateEncounterBasedWorklistIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainCreateEncounterBasedWorklistIntegrationPage_Query {
            viewer {
              domain {
                ...CreateEncounterBasedWorklistIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
      <Route
        path=":worklistHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateEncounterBasedWorklistIntegrationPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query integrations_DomainUpdateEncounterBasedWorklistIntegrationPage_Query(
            $worklistHandle: String!
          ) {
            encounterBasedWorklist(handle: $worklistHandle) {
              ...UpdateEncounterBasedWorklistIntegrationPage_encounterBasedWorklist
            }
            viewer {
              domain {
                ...UpdateEncounterBasedWorklistIntegrationPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component {...props} connectivityConfig={props.viewer.domain} />
        )}
      />
      {worklistItemRoute}
    </Route>
  </Route>
);
