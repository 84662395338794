import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { StudyTitle_study$data as Study } from 'components/__generated__/StudyTitle_study.graphql';

import PatientName from './PatientName';
import VetPatientName from './VetPatientName';

interface Props {
  study: Study;
  className?: string;
}

function StudyTitle({ study, className }: Props) {
  return study.practiceType === 'HUMAN' ? (
    <PatientName patient={study.patient} className={className} />
  ) : (
    <VetPatientName vetPatient={study.vetPatient!} className={className} />
  );
}

export default createFragmentContainer(StudyTitle, {
  study: graphql`
    fragment StudyTitle_study on Study {
      practiceType
      patient {
        ...PatientName_patient
      }
      vetPatient {
        ...VetPatientName_vetPatient
      }
    }
  `,
});
