/**
 * @generated SignedSource<<5516a5fbb47333cc726e5360228c2963>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MoveStudyDropdownItem_study$data = {
  readonly archive: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly organization: {
    readonly domain: {
      readonly " $fragmentSpreads": FragmentRefs<"MoveStudyModalRefresh_domain" | "MoveStudyModal_domain">;
    } | null;
    readonly handle: string | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"MoveStudyModalRefresh_organization" | "MoveStudyModal_organization">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canMoveStudy">;
  readonly " $fragmentType": "MoveStudyDropdownItem_study";
};
export type MoveStudyDropdownItem_study$key = {
  readonly " $data"?: MoveStudyDropdownItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"MoveStudyDropdownItem_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MoveStudyDropdownItem_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MoveStudyModal_organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MoveStudyModalRefresh_organization"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Domain",
          "kind": "LinkedField",
          "name": "domain",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MoveStudyModalRefresh_domain"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MoveStudyModal_domain"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canMoveStudy",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanMove",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizedAt",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_studyOrArchiveIsDeleted",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "ede04ec4026535f2826b18c7114438b8";

export default node;
