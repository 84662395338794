import { useEffect } from 'react';

/**
 * dispatch event with detail
 */
export function dispatchCustomEvent<TDetail>(name: string, detail?: TDetail) {
  document.dispatchEvent(new CustomEvent(name, { detail }));
}

/**
 * listen for event with detail
 * dispatch event response
 */
export function useCustomEventListener<TDetail, TResponse = void>(
  name: string,
  listener?: (detail: TDetail) => TResponse,
  sendResponse = false,
) {
  useEffect(() => {
    const listenerWrapper = ({ detail }: CustomEvent) => {
      const response = listener?.(detail);
      if (response && sendResponse)
        dispatchCustomEvent(`${name}-response`, response);
    };

    document.addEventListener(name, listenerWrapper);
    return () => {
      document.removeEventListener(name, listenerWrapper);
    };
  }, [listener, name, sendResponse]);
}
