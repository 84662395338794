import Heading from '@bfly/ui2/Heading';
import BaseProgressBar from '@bfly/ui2/Progress';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import clsx from 'clsx';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import SkeletonCard from 'components/SkeletonCard';

const dataDefault = {
  1: 0 as number,
  2: 0 as number,
  3: 0 as number,
  4: 0 as number,
  5: 0 as number,
} as const;

export type ImageQualityData = typeof dataDefault;

interface Props {
  data: Partial<ImageQualityData>;
  busy?: boolean;
  id?: string;
  className?: string;
}

export default function ImageQualityCard({
  id,
  data: dataProp,
  busy,
  className,
}: Props) {
  const data = { ...dataDefault, ...dataProp };

  let totalCount = 0;
  let totalValues = 0;
  for (const [key, value] of Object.entries(data)) {
    totalCount += value;
    totalValues += value * parseInt(key, 10);
  }

  const average = totalValues / totalCount || 0;

  const progressBar = (quality: number) => {
    const percentage =
      totalCount && data[quality] ? data[quality] / totalCount : 0;
    return (
      <>
        <Text data-bni-id="PerformanceIndicatorProgressBarLabel" align="right">
          {quality}
        </Text>
        <BaseProgressBar variant="green" value={percentage * 100} />
        <Text align="right" data-bni-id="PerformanceIndicatorProgressBarValue">
          <FormattedNumber style="percent" value={percentage} />
        </Text>
      </>
    );
  };

  return (
    <SkeletonCard
      busy={busy}
      className={clsx('flex-row space-x-2 px-4 pt-4 pb-5', className)}
    >
      <Section className="contents">
        <Heading flush>
          <Text as="div" variant="display-sm" data-bni-id={`${id}HeaderValue`}>
            <FormattedNumber
              style="decimal"
              value={average}
              minimumFractionDigits={1}
              maximumFractionDigits={1}
            />
          </Text>
          <div>
            <FormattedMessage
              id="ImageQualityCard.header"
              defaultMessage="Average image quality"
            />
          </div>
        </Heading>
        <div className="grid grid-cols-[auto,1fr,auto] ml-5 w-32 gap-x-2 self-center items-center">
          {progressBar(5)}
          {progressBar(4)}
          {progressBar(3)}
          {progressBar(2)}
          {progressBar(1)}
        </div>
      </Section>
    </SkeletonCard>
  );
}
