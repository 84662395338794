import useDialog from '@bfly/ui2/useDialog';
import useToast from '@bfly/ui2/useToast';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactRelayContext, graphql, readInlineData } from 'react-relay';
import { fetchQuery } from 'relay-runtime';

import { useRefreshStudyDialog_RefreshStudyQuery as RefreshStudyQuery } from './__generated__/useRefreshStudyDialog_RefreshStudyQuery.graphql';
import { useRefreshStudyDialog_study$key as StudyKey } from './__generated__/useRefreshStudyDialog_study.graphql';

export default function useRefreshStudyDialog(studyRef: StudyKey) {
  const toast = useToast();
  const dialog = useDialog();
  const relay = useContext(ReactRelayContext);

  const study = readInlineData(
    graphql`
      fragment useRefreshStudyDialog_study on Study @inline {
        handle
      }
    `,
    studyRef,
  );

  const handleRefresh = () => {
    return fetchQuery<RefreshStudyQuery>(
      relay!.environment,
      graphql`
        query useRefreshStudyDialog_RefreshStudyQuery($studyHandle: String!) {
          study(handle: $studyHandle) {
            ...ExamImagePage_study @relay(mask: false)
            latestStudyEvent {
              ... on Node {
                id
              }
            }
          }
        }
      `,
      { studyHandle: study.handle! },
    ).toPromise();
  };

  return async () => {
    const refreshedStudy = (await dialog.open(
      <FormattedMessage
        id="useRefreshStudyToast.content"
        defaultMessage="Images have been added to this study while it is open. Please refresh to review added content prior to signing the study."
      />,
      {
        title: (
          <FormattedMessage
            id="useRefreshStudyToast.title"
            defaultMessage="Study Content Changed"
          />
        ),
        confirmLabel: (
          <FormattedMessage
            id="refreshStudyModal.refresh"
            defaultMessage="Refresh"
          />
        ),
        runConfirm: handleRefresh,
      },
    )) as any;

    if (refreshedStudy) {
      toast.success(
        <FormattedMessage
          id="refreshStudyModal.refresh.success"
          defaultMessage="Study refreshed"
        />,
      );

      return refreshedStudy.study?.latestStudyEvent?.id;
    }

    return null;
  };
}
