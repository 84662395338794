import Layout from '@4c/layout';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from 'components/Avatar';

import { ExamReviewedBy_userProfile$data as UserProfile } from './__generated__/ExamReviewedBy_userProfile.graphql';

interface Props {
  userProfile: UserProfile;
  className?: string;
  readOnly: boolean;
}

function ExamReviewedBy({ userProfile, className, readOnly }: Props) {
  const messageValues = {
    avatar: (
      <Avatar
        userProfile={userProfile}
        width={readOnly ? 20 : 30}
        className="mr-2"
      />
    ),
    username: (
      <span className="font-bold text-white whitespace-nowrap">
        {userProfile.name!}
      </span>
    ),
    'no-wrap': (s: string) => (
      <span className="whitespace-nowrap font-normal">{s}&nbsp;</span>
    ),
  };

  return (
    <Layout align="center" className={clsx('text-sm', className)}>
      {readOnly ? (
        <FormattedMessage
          id="ExamReviewedBy.reviewedBy"
          defaultMessage="{avatar} <no-wrap>Reviewed by</no-wrap> {username}"
          values={messageValues}
        />
      ) : (
        <FormattedMessage
          id="ExamReviewedBy.previouslyReviewedBy"
          defaultMessage="{avatar} <no-wrap>Previously reviewed by</no-wrap> {username}"
          values={messageValues}
        />
      )}
    </Layout>
  );
}

export default createFragmentContainer(ExamReviewedBy, {
  userProfile: graphql`
    fragment ExamReviewedBy_userProfile on UserProfile {
      name
      ...Avatar_userProfile
    }
  `,
});
