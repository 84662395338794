import { join, route } from '@4c/spa-routing';

const education = route('/-/edu');

const educationContent = join(education, ':handle');

const lmsForceLogin = join(education, 'lms-login');
const lms = join(education, 'lms');

const savedContent = join(education, 'saved');
const assignedCourses = join(education, 'assigned');
const completedCourses = join(education, 'completed');
const dashboard = join(education, 'dashboard');
const dashboardCourseOverview = join(dashboard, 'course-overview');
const dashboardUserOverview = join(dashboard, 'user-overview');
const dashboardSelectCourses = join(dashboardUserOverview, 'select-courses');
const dashboardSelectDueDate = join(dashboardUserOverview, 'select-due-dates');

export const routes = {
  assignedCourses,
  completedCourses,
  dashboard,
  dashboardCourseOverview,
  dashboardSelectCourses,
  dashboardSelectDueDate,
  dashboardUserOverview,
  education,
  educationContent,
  lms,
  lmsForceLogin,
  savedContent,
};

export const useEducationRoutes = () => {
  return routes;
};
