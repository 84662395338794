/**
 * @generated SignedSource<<c574f2477bb4a850d77781c509989edb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StoragePagedArchivePage_archive$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PagedArchivePage_archive" | "StoragePagedArchivePage_studies">;
  readonly " $fragmentType": "StoragePagedArchivePage_archive";
};
export type StoragePagedArchivePage_archive$key = {
  readonly " $data"?: StoragePagedArchivePage_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"StoragePagedArchivePage_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoragePagedArchivePage_archive",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PagedArchivePage_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StoragePagedArchivePage_studies"
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "9f4ffc8696e4b9f03be95ccfb0ad7dc6";

export default node;
