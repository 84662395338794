/**
 * @generated SignedSource<<dc6cc24c66d298699c1d07bcacdf648b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainMembers_DomainMemberPage_Query$variables = {
  domainUserId: string;
};
export type domainMembers_DomainMemberPage_Query$data = {
  readonly domainUser: {
    readonly " $fragmentSpreads": FragmentRefs<"DomainMemberPage_domainUser">;
  } | null;
};
export type domainMembers_DomainMemberPage_Query = {
  response: domainMembers_DomainMemberPage_Query$data;
  variables: domainMembers_DomainMemberPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainUserId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "domainUserId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domainMembers_DomainMemberPage_Query",
    "selections": [
      {
        "alias": "domainUser",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainMemberPage_domainUser"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domainMembers_DomainMemberPage_Query",
    "selections": [
      {
        "alias": "domainUser",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrationDisplayNameFirst",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrationDisplayNameMiddle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrationDisplayNameLast",
                "storageKey": null
              }
            ],
            "type": "DomainUser",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f13e3a8e0288864832fea43d18bb45e8",
    "id": null,
    "metadata": {},
    "name": "domainMembers_DomainMemberPage_Query",
    "operationKind": "query",
    "text": "query domainMembers_DomainMemberPage_Query(\n  $domainUserId: ID!\n) {\n  domainUser: node(id: $domainUserId) {\n    __typename\n    ...DomainMemberPage_domainUser\n    id\n  }\n}\n\nfragment DomainMemberPage_domainUser on DomainUser {\n  id\n  profile {\n    name\n    id\n  }\n  ...formatDomainUserName_domainUser\n}\n\nfragment formatDomainUserName_domainUser on DomainUser {\n  integrationDisplayNameFirst\n  integrationDisplayNameMiddle\n  integrationDisplayNameLast\n  profile {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2d37a644f21eb8c4ed8f01030b5a3ec";

export default node;
