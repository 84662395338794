import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';

export const getCapturesUpdater =
  (imageIds: string[], studyId: string) => (store) =>
    imageIds.forEach((imageId) =>
      rangeDeleteUpdater(store, {
        parentId: studyId!,
        connections: [
          { connectionKey: 'StudyImagePreviewList_imageConnection' },
          { connectionKey: 'StudyImagePage_imageConnection' },
          { connectionKey: 'ExamImagePage_imageConnection' },
        ],
        deletedId: imageId,
      }),
    );
