/**
 * @generated SignedSource<<f151af130e0017f5bd628540f77f9ec9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CredentialingPerformanceIndicators_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CredentialGroupMembersPerformanceIndicator_tenant" | "EduCoursePerformanceIndicator_tenant" | "ExamTypeTagsPerformanceIndicator_tenant">;
  readonly " $fragmentType": "CredentialingPerformanceIndicators_tenant";
};
export type CredentialingPerformanceIndicators_tenant$key = {
  readonly " $data"?: CredentialingPerformanceIndicators_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CredentialingPerformanceIndicators_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CredentialingPerformanceIndicators_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CredentialGroupMembersPerformanceIndicator_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EduCoursePerformanceIndicator_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamTypeTagsPerformanceIndicator_tenant"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "abbcbb3622320741e37c6b32c3acb969";

export default node;
