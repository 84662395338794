/**
 * @generated SignedSource<<a2d39c214e865a86a4d572c399ccb9b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteArchiveControl_archive$data = {
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly slug: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"isLastPublicArchive_archive">;
  readonly " $fragmentType": "DeleteArchiveControl_archive";
};
export type DeleteArchiveControl_archive$key = {
  readonly " $data"?: DeleteArchiveControl_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteArchiveControl_archive">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteArchiveControl_archive",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "isLastPublicArchive_archive",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPrivate",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Archive",
  "abstractKey": null
};
})();

(node as any).hash = "faa7bf73bfd1bf89b2f49af3d542e87d";

export default node;
