/**
 * @generated SignedSource<<bd4f6928e9ef3eb60ad179f1b8ea2928>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MarkStudyAsDraftControl_useRevertToDraft_study$data = {
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canMarkAsDraft">;
  readonly " $fragmentType": "MarkStudyAsDraftControl_useRevertToDraft_study";
};
export type MarkStudyAsDraftControl_useRevertToDraft_study$key = {
  readonly " $data"?: MarkStudyAsDraftControl_useRevertToDraft_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"MarkStudyAsDraftControl_useRevertToDraft_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "MarkStudyAsDraftControl_useRevertToDraft_study"
};

(node as any).hash = "228c9a66cf03bad710d70757fa8e7022";

export default node;
