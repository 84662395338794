/**
 * @generated SignedSource<<a58ca3496f7b6b5eccdbfff489b33e08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestEduStudyReviewInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string>;
};
export type useRequestReview_Mutation$variables = {
  input: RequestEduStudyReviewInput;
};
export type useRequestReview_Mutation$data = {
  readonly requestEduStudyReviewOrError: {
    readonly clientMutationId?: string | null;
    readonly message?: string | null;
    readonly studies?: ReadonlyArray<{
      readonly id: string | null;
      readonly reviewRequestedAt: string | null;
    } | null> | null;
  } | null;
};
export type useRequestReview_Mutation = {
  response: useRequestReview_Mutation$data;
  variables: useRequestReview_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientMutationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EduStudyReviewRequested",
      "kind": "LinkedField",
      "name": "studies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reviewRequestedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RequestEduStudyReviewPayload",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "ErrorInterface",
  "abstractKey": "__isErrorInterface"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRequestReview_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "requestEduStudyReviewOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRequestReview_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "requestEduStudyReviewOrError",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da16321fd80b2d035cc5d4762791c464",
    "id": null,
    "metadata": {},
    "name": "useRequestReview_Mutation",
    "operationKind": "mutation",
    "text": "mutation useRequestReview_Mutation(\n  $input: RequestEduStudyReviewInput!\n) {\n  requestEduStudyReviewOrError(input: $input) {\n    __typename\n    ... on RequestEduStudyReviewPayload {\n      clientMutationId\n      studies {\n        id\n        reviewRequestedAt\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ee22e4b120b9e9ee560148dd153134c";

export default node;
