import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Text from '@bfly/ui2/Text';
import { useRouter } from 'found';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useNotificationsRoutes } from 'routes/notifications';

import { NotificationCenterViewAllControl_notificationCenter$data as NotificationCenter } from './__generated__/NotificationCenterViewAllControl_notificationCenter.graphql';

function NotificationCenterViewAllControl({
  notificationCenter,
}: {
  notificationCenter: NotificationCenter;
}) {
  const { router } = useRouter();
  const notificationsRoutes = useNotificationsRoutes();

  const hasAnyNotifications =
    !!notificationCenter?.allNotifications?.pageInfo.startCursor;

  return (
    <Layout
      align="center"
      justify="flex-end"
      className="text-sm border-t border-divider pb-1"
    >
      {hasAnyNotifications && (
        <Button
          className="pt-2 min-h-auto h-auto"
          variant="text-secondary"
          onClick={() => router.push(notificationsRoutes.dashboard())}
        >
          <Text variant="sm-bold" className="text-grey-50 underline">
            <FormattedMessage
              id="organization.notificationList.viewAllNotifications"
              defaultMessage="View all notifications"
            />
          </Text>
        </Button>
      )}
    </Layout>
  );
}

export default createFragmentContainer(NotificationCenterViewAllControl, {
  notificationCenter: graphql`
    fragment NotificationCenterViewAllControl_notificationCenter on NotificationCenter {
      allNotifications: notificationConnection(
        isCleared: [true, false]
        first: 1
      ) {
        pageInfo {
          startCursor
        }
      }
    }
  `,
});
