/**
 * @generated SignedSource<<7c59ea6d51ad4d880a4e77ad36c81ab1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThirdPartyUltrasoundLogModalLogListQuery$variables = {
  id: string;
};
export type ThirdPartyUltrasoundLogModalLogListQuery$data = {
  readonly archive: {
    readonly " $fragmentSpreads": FragmentRefs<"ThirdPartyUltrasoundLogList_archive">;
  } | null;
};
export type ThirdPartyUltrasoundLogModalLogListQuery = {
  response: ThirdPartyUltrasoundLogModalLogListQuery$data;
  variables: ThirdPartyUltrasoundLogModalLogListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 15
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ThirdPartyUltrasoundLogModalLogListQuery",
    "selections": [
      {
        "alias": "archive",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ThirdPartyUltrasoundLogList_archive"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ThirdPartyUltrasoundLogModalLogListQuery",
    "selections": [
      {
        "alias": "archive",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ThirdPartyUltrasoundListener",
                "kind": "LinkedField",
                "name": "thirdPartyUltrasoundListener",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "ThirdPartyUltrasoundLogConnection",
                    "kind": "LinkedField",
                    "name": "logConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ThirdPartyUltrasoundLogEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "type": "Node",
                                "abstractKey": "__isNode"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                  }
                                ],
                                "type": "ServerLogInterface",
                                "abstractKey": "__isServerLogInterface"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "logConnection(first:15)"
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "tpus_logConnection",
                    "kind": "LinkedHandle",
                    "name": "logConnection"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Archive",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ed595223ffefd8568a7e6cc0cd88b04",
    "id": null,
    "metadata": {},
    "name": "ThirdPartyUltrasoundLogModalLogListQuery",
    "operationKind": "query",
    "text": "query ThirdPartyUltrasoundLogModalLogListQuery(\n  $id: ID!\n) {\n  archive: node(id: $id) {\n    __typename\n    ...ThirdPartyUltrasoundLogList_archive\n    id\n  }\n}\n\nfragment ThirdPartyUltrasoundLogListItem_log on ServerLogInterface {\n  __isServerLogInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  status\n  createdAt\n}\n\nfragment ThirdPartyUltrasoundLogList_archive on Archive {\n  id\n  thirdPartyUltrasoundListener {\n    logConnection(first: 15) {\n      edges {\n        node {\n          __typename\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n          ...ThirdPartyUltrasoundLogListItem_log\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "72c023d6f75f4abf7c501b708cea56c1";

export default node;
