/**
 * @generated SignedSource<<14e829167cc4e3620083e4b7f451c211>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppSearchGlobal_searchNodes$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarGlobal_searchNodes">;
  readonly " $fragmentType": "AppSearchGlobal_searchNodes";
}>;
export type AppSearchGlobal_searchNodes$key = ReadonlyArray<{
  readonly " $data"?: AppSearchGlobal_searchNodes$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppSearchGlobal_searchNodes">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AppSearchGlobal_searchNodes",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBarGlobal_searchNodes"
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};

(node as any).hash = "1e79fa3e9f2c84d5555cc2e595767f98";

export default node;
