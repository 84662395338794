/**
 * @generated SignedSource<<bc5b620f33593c9c1765e2441e46a3d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AllTimeStatsUsers_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AllTimeStatsUsers_studyStatisticsByAuthor">;
  readonly " $fragmentType": "AllTimeStatsUsers_tenant";
};
export type AllTimeStatsUsers_tenant$key = {
  readonly " $data"?: AllTimeStatsUsers_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AllTimeStatsUsers_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AllTimeStatsUsers_tenant",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AllTimeStatsUsers_studyStatisticsByAuthor"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "72fe62f771d0f91915734f486fa64ec6";

export default node;
