import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import React, { useRef } from 'react';

const WorksheetsPageRenderer = ({ Component, props, match, ...rest }) => {
  const lastPropsRef = useRef<any | null>(null);
  lastPropsRef.current = props || { ...lastPropsRef.current, match };
  const nextProps = lastPropsRef.current;
  if (!props) return <LoadingIndicator />;
  return <Component {...nextProps} {...rest} />;
};

export default WorksheetsPageRenderer;
