import Button from '@bfly/ui2/Button';
import FormCheck from '@bfly/ui2/FormCheck';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useToast from '@bfly/ui2/useToast';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useMutation } from 'react-relay';

import withModal from 'utils/withModal';

import { bulkDomainUsersDeactivateUsersMessages as messages } from '../messages/DomainMembersMessages';
import { useMemberCountUpdate } from '../utils/MemberCounts';
import BulkDeactivateUsersResultsModal from './BulkDomainUsersDeactivateUsersResultsModal';
import { BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation } from './__generated__/BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation.graphql';
import { BulkInputDomainUser } from './__generated__/BulkDomainUsersUpdateRoleConfirmationModal_bulkUpdateMutation.graphql';

const bulkDeleteDomainUsersOrErrorMutation = graphql`
  mutation BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation(
    $input: BulkDeleteDomainUsersInput!
  ) {
    bulkDeleteDomainUsers(input: $input) {
      users {
        edges {
          cursor
          node {
            id
            email
            integrationDisplayNameFirst
            integrationDisplayNameLast
            integrationDisplayNameMiddle
          }
        }
      }
      failures {
        ...BulkDomainUsersDeactivateUsersResultsModal_failures
      }
    }
  }
`;

interface BulkDeactivateUsersModalProps {
  onHide: () => void;
  onSuccess: () => void;
  domainName: string;
  domainId: string;
  selectedUsers: {
    domainUserId: string;
    email: string;
    integrationDisplayNameFirst: string;
    integrationDisplayNameLast: string;
  }[];
}

const BulkDeactivateUsersModal = ({
  onSuccess,
  onHide,
  domainId,
  domainName,
  selectedUsers,
}: BulkDeactivateUsersModalProps) => {
  const toast = useToast();

  const memberCountUpdate = useMemberCountUpdate();

  const [bulkDeleteDomainUsersMutation] =
    useMutation<BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation>(
      bulkDeleteDomainUsersOrErrorMutation,
    );

  const [confirmedUsersEmails, setConfirmedUsersEmails] = useState(
    new Set(selectedUsers.map(({ email }) => email)),
  );
  const selectedUsersWithoutId = selectedUsers.map(
    ({ domainUserId: _, ...rest }) => rest,
  );
  const editedUsers = useMemo(
    () => [...selectedUsersWithoutId],
    [selectedUsersWithoutId],
  );
  const [result, setResult] = useState<any>(null);

  const toggleUser = useCallback(
    (email: string) => {
      setConfirmedUsersEmails((prev) => {
        const confirmedUsers = new Set(prev);
        if (prev.has(email)) {
          confirmedUsers.delete(email);
        } else {
          confirmedUsers.add(email);
        }
        return confirmedUsers;
      });
    },
    [setConfirmedUsersEmails],
  );
  const handleUpdateUsers = useCallback(() => {
    const users: BulkInputDomainUser[] = editedUsers.filter(({ email }) =>
      confirmedUsersEmails.has(email),
    );

    bulkDeleteDomainUsersMutation({
      variables: {
        input: {
          domainId,
          users,
        },
      },
      onError() {
        toast?.error(<FormattedMessage {...messages.toastError} />);
        onHide();
      },
      onCompleted({ bulkDeleteDomainUsers }) {
        if (!bulkDeleteDomainUsers!.failures!.length) {
          memberCountUpdate();
          onSuccess();
          toast?.success(
            <FormattedMessage
              {...messages.toastSuccess}
              values={{
                numUpdated: String(
                  bulkDeleteDomainUsers!.users!.edges!.length,
                ),
              }}
            />,
          );
        } else {
          setResult({
            failures: bulkDeleteDomainUsers!.failures!,
            numDeactivated: bulkDeleteDomainUsers!.users!.edges!.length,
          });
        }
      },
    });
  }, [
    onSuccess,
    onHide,
    memberCountUpdate,
    bulkDeleteDomainUsersMutation,
    toast,
    domainId,
    editedUsers,
    confirmedUsersEmails,
  ]);

  if (result) {
    return <BulkDeactivateUsersResultsModal {...result} onHide={onHide} />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...messages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextUpdated}
            values={{
              numUpdated: String(confirmedUsersEmails.size),
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextReview}
            values={{
              domainName,
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>

        <div className="px-5 w-full">
          <table
            className="w-full"
            data-bni-id="BulkDeactivateUsersConfirmationModalUserList"
          >
            <tbody>
              {editedUsers.map((user) => {
                return (
                  <tr key={user.email} className="border-b border-b-grey-70">
                    <td className="w-8 h-12">
                      <FormCheck
                        className="m-0"
                        checked={confirmedUsersEmails.has(user.email)}
                        onChange={() => toggleUser(user.email!)}
                      />
                    </td>
                    <td className="h-12">{user.email}</td>
                    <td className="h-12">
                      {user.integrationDisplayNameFirst}{' '}
                      {user.integrationDisplayNameLast}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            data-bni-id="BulkDeactivateUsersConfirmationModalConfirm"
            size="lg"
            onClick={handleUpdateUsers}
            disabled={confirmedUsersEmails.size === 0}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
          <Button
            data-bni-id="BulkDeactivateUsersConfirmationModalCancel"
            size="lg"
            variant="secondary"
            onClick={onHide}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
};

export default withModal(BulkDeactivateUsersModal, {
  style: { maxWidth: '600px' },
  variant: 'dark',
});
