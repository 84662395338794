/**
 * @generated SignedSource<<b1b13ab785bff6b85ca4fe6f6d9faa96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SushiRolls_tenant$data = {
  readonly credentialStatisticsByExamType: ReadonlyArray<{
    readonly examType: {
      readonly name: string | null;
      readonly systemId: string | null;
    } | null;
    readonly numUsers: number | null;
    readonly numUsersCredentialed: number | null;
    readonly numUsersPartiallyCredentialed: number | null;
  } | null> | null;
  readonly credentialStatisticsByMemberConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly examTypeProgress: ReadonlyArray<{
          readonly examType: {
            readonly id: string;
            readonly name: string | null;
          } | null;
          readonly numExamsCompleted: number | null;
          readonly numExamsRequired: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "SushiRolls_tenant";
};
export type SushiRolls_tenant$key = {
  readonly " $data"?: SushiRolls_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"SushiRolls_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SushiRolls_tenant",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "CredentialStatisticsByExamType",
      "kind": "LinkedField",
      "name": "credentialStatisticsByExamType",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamType",
          "kind": "LinkedField",
          "name": "examType",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "systemId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUsers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUsersPartiallyCredentialed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUsersCredentialed",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "CredentialStatisticsByMemberConnection",
      "kind": "LinkedField",
      "name": "credentialStatisticsByMemberConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CredentialStatisticsByMemberEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CredentialStatisticsByMember",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CredentialStatisticsByMemberExamTypeProgress",
                  "kind": "LinkedField",
                  "name": "examTypeProgress",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ExamType",
                      "kind": "LinkedField",
                      "name": "examType",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "numExamsCompleted",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "numExamsRequired",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};
})();

(node as any).hash = "b7d52a09ac6957e1a3af93285adc6597";

export default node;
