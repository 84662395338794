import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import getNodes from '@bfly/utils/getNodes';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import RelayForm from 'components/RelayForm';
import actions from 'messages/actions';
import { usePermissions } from 'utils/viewerContext';

import {
  deserialize,
  dicomFieldsSchema,
  domainUserDetails as domainUserDetailsSchema,
  integrationConfigs as integrationConfigsSchema,
} from '../schemas/domainUser';
import DomainMemberAccessRoleFieldSet from './DomainMemberAccessRoleFieldSet';
import DomainMemberDeleteFieldSet from './DomainMemberDeleteFieldSet';
import DomainMemberDetailFieldSet from './DomainMemberDetailFieldSet';
import DomainMemberDicomFieldsFieldSet from './DomainMemberDicomFieldsFieldSet';
import DomainMemberIntegrationConfigsFieldSet from './DomainMemberIntegrationConfigsFieldSet';
import { CreateDomainUserInputDomainUserDicomField } from './__generated__/AddDomainMemberModalMutation.graphql';
import {
  DomainMemberSettingsPageMutation as Mutation,
  UpdateDomainUserInputDomainUserIntegrationConfig,
} from './__generated__/DomainMemberSettingsPageMutation.graphql';
import { DomainMemberSettingsPage_domainUser$data as DomainUser } from './__generated__/DomainMemberSettingsPage_domainUser.graphql';
import { DomainMemberSettingsPage_viewer$data as Viewer } from './__generated__/DomainMemberSettingsPage_viewer.graphql';

const mutation = graphql`
  mutation DomainMemberSettingsPageMutation($input: UpdateDomainUserInput!) {
    updateDomainUserOrError(input: $input) {
      ... on UpdateDomainUserPayload {
        domainUser {
          ...DomainMemberSettingsPage_domainUser
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface Props {
  domainUser: DomainUser;
  viewer: Viewer;
}

function DomainMemberSettingsPage({ domainUser, viewer }: Props) {
  const { domain } = viewer;
  const ehrs = useMemo(() => getNodes(domain?.ehrConnection), [domain]);
  const dicomFieldTemplates = useMemo(
    () => getNodes(domain?.dicomFieldTemplateConnection),
    [domain],
  );

  const { integrationConfigs, dicomFields, ...domainUserDetails } = useMemo(
    () => deserialize(domainUser, ehrs, dicomFieldTemplates),
    [domainUser, ehrs, dicomFieldTemplates],
  );
  const { hasAdminPermission } = usePermissions();
  const hasPermission = hasAdminPermission('userManagement');
  return (
    <>
      <RelayForm<Mutation, typeof domainUserDetailsSchema>
        horizontal
        warnOnUnsavedNavigation
        schema={domainUserDetailsSchema}
        defaultValue={domainUserDetails as any}
        getInput={({ isAdmin, email: _, ...value }) => ({
          ...value,
          domainUserId: domainUser.id!,
          type: isAdmin ? 'ADMIN' : 'USER',
        })}
        mutation={mutation}
        successMessage={
          <FormattedMessage
            id="domainMemberSettingsPagei.profile.success"
            defaultMessage="Profile information updated"
          />
        }
      >
        <DomainMemberDetailFieldSet domain={domain!} domainUser={domainUser} />
        <DomainMemberAccessRoleFieldSet viewer={viewer} />
        <Layout reverse>
          <Form.Submit as={Button} disabled={!hasPermission}>
            <FormattedMessage {...actions.save} />
          </Form.Submit>
        </Layout>
      </RelayForm>
      {!!ehrs.length && (
        <RelayForm<Mutation, typeof integrationConfigsSchema>
          horizontal
          warnOnUnsavedNavigation
          mutation={mutation}
          schema={integrationConfigsSchema}
          defaultValue={{ integrationConfigs } as any}
          getInput={(value) => ({
            domainUserId: domainUser.id!,
            integrationConfigs: value.integrationConfigs.filter(
              (c): c is UpdateDomainUserInputDomainUserIntegrationConfig =>
                !!c.interfaceCode,
            ),
          })}
          successMessage={
            <FormattedMessage
              id="domainMemberSettingsPage.ehr.success"
              defaultMessage="Interface codes updated"
            />
          }
        >
          <DomainMemberIntegrationConfigsFieldSet ehrs={ehrs} />
          <Layout reverse>
            <Form.Submit as={Button} disabled={!hasPermission}>
              <FormattedMessage {...actions.save} />
            </Form.Submit>
          </Layout>
        </RelayForm>
      )}
      {!!dicomFieldTemplates.length && (
        <RelayForm<Mutation, typeof dicomFieldsSchema>
          horizontal
          warnOnUnsavedNavigation
          mutation={mutation}
          schema={dicomFieldsSchema}
          defaultValue={{ dicomFields } as any}
          getInput={(value) => ({
            domainUserId: domainUser.id!,
            dicomFields: value.dicomFields.filter(
              (d): d is CreateDomainUserInputDomainUserDicomField => !!d.value,
            ),
          })}
          successMessage={
            <FormattedMessage
              id="domainMemberSettingsPage.dicomFields.success"
              defaultMessage="DICOM fields updated"
            />
          }
        >
          <DomainMemberDicomFieldsFieldSet
            dicomFieldTemplates={dicomFieldTemplates}
          />
          <Layout reverse>
            <Form.Submit as={Button} disabled={!hasPermission}>
              <FormattedMessage {...actions.save} />
            </Form.Submit>
          </Layout>
        </RelayForm>
      )}
      <DomainMemberDeleteFieldSet domainUser={domainUser} domain={domain!} />
    </>
  );
}

export default createFragmentContainer(DomainMemberSettingsPage, {
  viewer: graphql`
    fragment DomainMemberSettingsPage_viewer on Viewer {
      domain {
        id
        ehrConnection(first: 2147483647)
          @connection(key: "Domain_ehrConnection") {
          edges {
            node {
              id
              ...DomainMemberIntegrationConfigsFieldSet_ehrs
            }
          }
        }
        dicomFieldTemplateConnection(first: 2147483647)
          @connection(key: "Domain_dicomFieldTemplateConnection") {
          edges {
            node {
              id
              ...DomainMemberDicomFieldsFieldSet_dicomFieldTemplates
            }
          }
        }
        ...DomainMemberDetailFieldSet_domain
        ...DomainMemberDeleteFieldSet_domain
      }
      ...DomainMemberAccessRoleFieldSet_viewer
    }
  `,

  domainUser: graphql`
    fragment DomainMemberSettingsPage_domainUser on DomainUser {
      id
      type
      email
      profile {
        name
      }
      ...domainUser_defaultValue
      ...DomainMemberDetailFieldSet_domainUser
      ...DomainMemberDeleteFieldSet_domainUser
    }
  `,
});
