/**
 * @generated SignedSource<<36cffb7d1d9a0cc685ec4ec18a5dcdc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type archive_render$data = {
  readonly handle: string | null;
  readonly " $fragmentType": "archive_render";
};
export type archive_render$key = {
  readonly " $data"?: archive_render$data;
  readonly " $fragmentSpreads": FragmentRefs<"archive_render">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "archive_render"
};

(node as any).hash = "f1af9c3eeca257e24d2911249ffe084c";

export default node;
