/**
 * @generated SignedSource<<35ad0f58e57c694abdda7b58ab510e7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canMarkAsDraft$data = {
  readonly viewerCanMarkAsDraft: boolean | null;
  readonly " $fragmentType": "StudyPermissions_canMarkAsDraft";
};
export type StudyPermissions_canMarkAsDraft$key = {
  readonly " $data"?: StudyPermissions_canMarkAsDraft$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canMarkAsDraft">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canMarkAsDraft"
};

(node as any).hash = "89aeac853d28bc92044bc836d4d10527";

export default node;
