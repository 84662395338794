/**
 * @generated SignedSource<<4d8e780462e0611a1cf5e40f27f9ff38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AllTimeStatsCards_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ClinicalInsightsCards_tenant" | "ExamStatusPerformanceIndicator_tenant" | "ImageQualityPerformanceIndicator_tenant" | "ReviewedExamsPerformanceIndicator_tenant">;
  readonly " $fragmentType": "AllTimeStatsCards_tenant";
};
export type AllTimeStatsCards_tenant$key = {
  readonly " $data"?: AllTimeStatsCards_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AllTimeStatsCards_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AllTimeStatsCards_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamStatusPerformanceIndicator_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewedExamsPerformanceIndicator_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ImageQualityPerformanceIndicator_tenant"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ClinicalInsightsCards_tenant"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "9f7fd393be845f67c218acd338a06f4e";

export default node;
