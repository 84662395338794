/**
 * @generated SignedSource<<596c809e7034d686b60938ff88e8ade9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RequestFinalizationModal_study$data = {
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"FinalizationCandidatesListbox_study">;
  readonly " $fragmentType": "RequestFinalizationModal_study";
};
export type RequestFinalizationModal_study$key = {
  readonly " $data"?: RequestFinalizationModal_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"RequestFinalizationModal_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequestFinalizationModal_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FinalizationCandidatesListbox_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "ac3fbefcd9bb8bd44d63188726673303";

export default node;
