import Button from '@bfly/ui2/Button';
import FormCheck from '@bfly/ui2/FormCheck';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useToast from '@bfly/ui2/useToast';
import { globalIdToLocalId } from '@bfly/utils/codecs';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useMutation } from 'react-relay';

import withModal from 'utils/withModal';

import { bulkDomainUsersUpdateModalMessages as messages } from '../messages/DomainMembersMessages';
import { Organization } from '../shared/DomainMembersGridTypes';
import {
  BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation,
  OrganizationMembershipType,
} from './__generated__/BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation.graphql';

const bulkSetOrganizationMembershipOrErrorMutation = graphql`
  mutation BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation(
    $input: BulkSetOrganizationMembershipInput!
  ) {
    bulkSetOrganizationMembership(input: $input) {
      users {
        ...DomainMembersGrid_domainUser
      }
      failures {
        userId
        organizationId
        code
        detail
      }
    }
  }
`;

interface Props {
  domainId: string;
  type: OrganizationMembershipType;
  members: any[];
  selectedOrganizations: Organization[];
  onHide: () => void;
  onSuccess: () => void;
}

function BulkAddOrganizationConfirmationModal({
  domainId,
  type,
  members,
  selectedOrganizations,
  onHide,
  onSuccess,
}: Props) {
  const toast = useToast();
  const [bulkAddOrganizationMembership] =
    useMutation<BulkAddOrganizationConfirmationModal_bulkSetOrganizationMembershipMutation>(
      bulkSetOrganizationMembershipOrErrorMutation,
    );

  const [confirmedUsersEmails, setConfirmedUsersEmails] = useState(
    new Set(members.map(({ email }) => email)),
  );
  const editedUsers = useMemo(
    () =>
      members.map((member) => ({
        ...member,
        userId: globalIdToLocalId(member.id).split('/')[1],
      })),
    [members],
  );
  const [failuresByUserId, setFailuresByUserId] = useState<
    Map<string, string[]>
  >(new Map());

  const toggleUser = useCallback(
    (email: string) => {
      setConfirmedUsersEmails((prev) => {
        const confirmedUsers = new Set(prev);
        if (prev.has(email)) {
          confirmedUsers.delete(email);
        } else {
          confirmedUsers.add(email);
        }
        return confirmedUsers;
      });
    },
    [setConfirmedUsersEmails],
  );

  const handleUpdateUsers = useCallback(() => {
    bulkAddOrganizationMembership({
      variables: {
        input: {
          domainId,
          type,
          organizationMemberships: [...confirmedUsersEmails.values()]
            .map((email) => {
              return selectedOrganizations.reduce((acc, org) => {
                const user = members.find((x) => x?.email === email);
                const organizationId = org.id || '';
                if (
                  user?.organizations
                    ?.map((x) => x?.id)
                    .includes(organizationId)
                ) {
                  return acc;
                }
                return [
                  ...acc,
                  {
                    organizationId,
                    userId: user?.profile?.id || '',
                  },
                ];
              }, []);
            })
            .flat(),
        },
      },
      onError() {
        toast?.error(<FormattedMessage {...messages.toastError} />);
        onHide();
      },
      onCompleted({ bulkSetOrganizationMembership }) {
        if (!bulkSetOrganizationMembership!.failures!.length) {
          onSuccess();
          toast?.success(
            <FormattedMessage
              {...messages.toastSuccess}
              values={{
                numUpdated: String(
                  bulkSetOrganizationMembership!.users!.length,
                ),
              }}
            />,
          );
          setFailuresByUserId(new Map());
        } else {
          const failures = new Map<string, string[]>();

          bulkSetOrganizationMembership!.failures.forEach((failure) => {
            toast?.error(failure.detail);
            if (failure.userId) {
              const prevValue = failures.get(failure.userId);
              const value = prevValue
                ? [...prevValue, failure.detail || '']
                : [failure.detail || ''];

              failures.set(failure.userId, value);
            }
          });

          setFailuresByUserId(failures);
        }
      },
    });
  }, [
    onHide,
    onSuccess,
    bulkAddOrganizationMembership,
    domainId,
    toast,
    members,
    selectedOrganizations,
    type,
    confirmedUsersEmails,
  ]);
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...messages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextReview}
            values={{
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextUpdated}
            values={{
              numUpdated: String(confirmedUsersEmails.size),
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>
        <div className="px-5 w-full">
          <table
            className="w-full"
            data-bni-id="BulkAddOrganizationModalUserList"
          >
            <tbody>
              {editedUsers.map((user) => {
                return (
                  <tr key={user.email} className="border-b border-b-grey-70">
                    <td className="w-8 h-12">
                      <FormCheck
                        className="m-0"
                        checked={confirmedUsersEmails.has(user.email)}
                        onChange={() => toggleUser(user.email)}
                      />
                    </td>
                    <td className="h-12">
                      {user.email}
                      {failuresByUserId.get(user.userId) && (
                        <>
                          <br />
                          <Text color="danger">
                            {failuresByUserId.get(user.userId)}
                          </Text>
                        </>
                      )}
                    </td>
                    <td className="h-12">
                      {user.integrationDisplayNameFirst}{' '}
                      {user.integrationDisplayNameLast}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            data-bni-id="BulkAddOrganizationConfirmationModalConfirm"
            size="lg"
            onClick={handleUpdateUsers}
            disabled={confirmedUsersEmails.size === 0}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
          <Button
            data-bni-id="BulkAddOrganizationConfirmationModalCancel"
            variant="secondary"
            size="lg"
            onClick={onHide}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default withModal(BulkAddOrganizationConfirmationModal, {
  style: { maxWidth: '600px' },
  variant: 'dark',
});
