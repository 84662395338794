import SyncingIcon from '@bfly/icons/Syncing';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import React from 'react';

const styles = stylesheet`
  .spinner {
    height: 1em;
  }

  .rotating {
    animation: rotation 3s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
`;

interface Props {
  paused?: boolean;
  className?: string;
}

// An animated spinner icon. While it's rotating, the tip of the arrow moves
//  outside its parent's bounding box, so make sure the parent has
//  "overflow: visible" to avoid clipping.
function SyncingSpinner({ paused, className }: Props) {
  return (
    <SyncingIcon
      className={clsx(className, styles.spinner, !paused && styles.rotating)}
    />
  );
}

export default SyncingSpinner;
