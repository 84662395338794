/**
 * @generated SignedSource<<c56553328b4939833ef57db60ebb6d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitStudyArchiveField_sourceStudy$data = {
  readonly archive: {
    readonly ehrAssociations: ReadonlyArray<{
      readonly id: string;
    } | null> | null;
    readonly id: string;
    readonly label: string | null;
    readonly pacsAssociations: ReadonlyArray<{
      readonly id: string;
    } | null> | null;
  } | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ArchiveDropdown_organization">;
  } | null;
  readonly " $fragmentType": "SplitStudyArchiveField_sourceStudy";
};
export type SplitStudyArchiveField_sourceStudy$key = {
  readonly " $data"?: SplitStudyArchiveField_sourceStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyArchiveField_sourceStudy">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyArchiveField_sourceStudy",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArchiveDropdown_organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PacsArchiveAssociation",
          "kind": "LinkedField",
          "name": "pacsAssociations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EhrArchiveAssociation",
          "kind": "LinkedField",
          "name": "ehrAssociations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "7d164f36f8a74c2c53fa423acdaef5de";

export default node;
