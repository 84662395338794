import { JSXElementConstructor, ReactElement } from 'react';
import { createPortal } from 'react-dom';

export default function ItemGridScrollObserver({
  scrollObserver,
}: {
  scrollObserver: ReactElement<any, string | JSXElementConstructor<any>>;
}) {
  return createPortal(
    <div className="w-full h-2 pointer-events-none">{scrollObserver}</div>,
    document.body,
  );
}
