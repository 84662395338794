import ArrowDown from '@bfly/icons/ArrowDown';
import clsx from 'clsx';
import { SVGAttributes } from 'react';

export default function SortIndicator({
  desc,
  height,
}: {
  desc?: boolean;
  height?: SVGAttributes<SVGElement>['height'];
}) {
  return (
    <ArrowDown
      height={height || 11}
      className={clsx(`ml-2`, desc ? 'rotate-180' : '')}
    />
  );
}
