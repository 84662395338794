import useQuery from '@bfly/ui2/useQuery';
import { useMemo, useRef } from 'react';
import { graphql } from 'relay-runtime';

import { Match } from 'components/Route';
import usePaginationResults, {
  getPaginationVariables,
} from 'hooks/usePaginationResults';

import { useStudiesData_eduStudiesQuery as StudiesQuery } from './__generated__/useStudiesData_eduStudiesQuery.graphql';
import { useScanLabContext } from './context';
import useStudyImages from './useStudyImages';

function usePersistentData<T>(data: T | undefined) {
  const dataRef = useRef<any | undefined>();

  dataRef.current = useMemo(
    () => (data !== undefined ? data : dataRef.current),
    [data],
  );

  return dataRef.current;
}

export default function useStudiesData(match: Match) {
  const { queryParams } = useScanLabContext();

  const { data, retry } = useQuery<StudiesQuery>(
    graphql`
      query useStudiesData_eduStudiesQuery(
        $first: Int
        $after: String
        $sort: [EduStudySorting!]
        $examTypeHandles: [String!]
        $conductedAtMin: DateTime
        $conductedAtMax: DateTime
        $author: [String!]
        $isReviewed: Boolean
        $isReviewRequested: Boolean
        $groupId: [ID!]
      ) {
        viewer {
          eduStudyConnection(
            first: $first
            after: $after
            includePageInfo: true
            sort: $sort
            examTypeHandles: $examTypeHandles
            conductedAtMin: $conductedAtMin
            conductedAtMax: $conductedAtMax
            author: $author
            isReviewed: $isReviewed
            isReviewRequested: $isReviewRequested
            groupId: $groupId
          ) @connection(key: "viewer_eduStudyConnection") {
            index
            numEdges
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              cursor
              node {
                ...types_EduStudy @relay(mask: false)
              }
            }
          }
        }
      }
    `,
    {
      skip: !queryParams,
      variables: {
        ...queryParams,
        ...getPaginationVariables(match.location, 'eduStudiesGrid'),
      },
    },
  );

  const { edges, paginationMeta } = usePaginationResults(
    usePersistentData(data?.viewer?.eduStudyConnection),
    'eduStudiesGrid',
  );

  const studies = useMemo(() => {
    return (
      edges?.filter(Boolean).map((edge) => ({
        ...edge!.node!,
        cursor: edge!.cursor,
      })) || []
    );
  }, [edges]);

  const studyImages = useStudyImages(
    studies.map(({ id }) => id),
    !studies.length,
  );

  return {
    ...paginationMeta,
    fetching: !data,
    studies,
    studyImages,
    reload: retry,
  };
}
