/**
 * @generated SignedSource<<cc08ebd9f86b5cc1d23d9f3df6a2391d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSavedSearchModal_studySavedSearch$data = {
  readonly criteria: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SavedSearchModal_studySearchCriteria">;
  } | null> | null;
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentType": "UpdateSavedSearchModal_studySavedSearch";
};
export type UpdateSavedSearchModal_studySavedSearch$key = {
  readonly " $data"?: UpdateSavedSearchModal_studySavedSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSavedSearchModal_studySavedSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSavedSearchModal_studySavedSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudySearchCriterion",
      "kind": "LinkedField",
      "name": "criteria",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SavedSearchModal_studySearchCriteria"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudySavedSearch",
  "abstractKey": null
};

(node as any).hash = "127657e603d34677e8101ce3360ebd9c";

export default node;
