import FarceActionTypes from 'farce/ActionTypes';
import FarceActions from 'farce/Actions';
import BrowserProtocol from 'farce/BrowserProtocol';
import createHistoryEnhancer from 'farce/createHistoryEnhancer';
import queryMiddleware from 'farce/queryMiddleware';
import { createNamedRoutesMiddleware } from 'found-named-routes';
import FoundActionTypes from 'found/ActionTypes';
import Matcher from 'found/Matcher';
import createMatchEnhancer from 'found/createMatchEnhancer';
import foundReducer from 'found/foundReducer';
import {
  Middleware,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';

import Analytics from 'utils/Analytics';
import { interactionIgnore } from 'utils/newRelic';

import routeConfig from './routeConfig';

const ignoreAuthInHashMiddleware: Middleware = () => (next) => (action) => {
  const { type, payload } = action;

  if (
    type === FarceActionTypes.UPDATE_LOCATION &&
    (payload.hash?.includes('access_token') ||
      payload.hash?.includes('id_token'))
  ) {
    interactionIgnore();
  }

  return next(action);
};

const segmentLogger: Middleware = () => (next) => (action) => {
  const { type } = action;

  if (type === FoundActionTypes.RESOLVE_MATCH) {
    Analytics.page({ virtual: true });
  }

  return next(action);
};

// Ignore this deprecation warning, it doesn't make sense to include @reduxjs/toolkit
const store = createStore(
  combineReducers({
    found: foundReducer,
  }),
  compose(
    createHistoryEnhancer({
      protocol: new BrowserProtocol(),
      middlewares: [createNamedRoutesMiddleware(routeConfig), queryMiddleware],
    }),
    applyMiddleware(ignoreAuthInHashMiddleware),
    createMatchEnhancer(new Matcher(routeConfig)),
    applyMiddleware(segmentLogger),
  ),
);

store.dispatch(FarceActions.init());

export default store;
