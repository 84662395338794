import Layout from '@4c/layout';
import CloseSm from '@bfly/icons/CloseSm';
import Dropdown from '@bfly/ui2/Dropdown';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useSubscription from '@bfly/ui2/useSubscription';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useNotificationCentertContext } from 'utils/notificationCenterContext';

import IconButton from './IconButton';
import { Notification_MarkNotificationClearedMutation as MarkNotificationClearedMutation } from './__generated__/Notification_MarkNotificationClearedMutation.graphql';
import { Notification_MarkReadMutation as MarkReadMutation } from './__generated__/Notification_MarkReadMutation.graphql';
import { Notification_Subscription as Subscription } from './__generated__/Notification_Subscription.graphql';
import { Notification_notification$data as NotificationType } from './__generated__/Notification_notification.graphql';

export const NotificationLoadingIndicator = () => (
  <LoadingIndicator className="p-0 border-t-1 border-divider m-0 justify-center" />
);

interface Props {
  notification: NotificationType;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  clearable: boolean;
}

function Notification({ notification, onClick, children, clearable }: Props) {
  const isUnread = notification.readAt === null;

  const [readNotificationMutate] = useMutationWithError<MarkReadMutation>(
    graphql`
      mutation Notification_MarkReadMutation(
        $input: MarkNotificationReadInput!
      ) {
        markNotificationReadOrError(input: $input) {
          ... on MarkNotificationReadPayload {
            notification {
              readAt
            }
          }
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
    {
      input: { notificationId: notification.id! },
    },
  );

  useSubscription<Subscription>(
    graphql`
      subscription Notification_Subscription(
        $input: NotificationUpdatedSubscriptionInput!
      ) {
        notificationUpdated(input: $input) {
          notification {
            readAt
          }
        }
      }
    `,
    {
      input: { notificationId: notification.id! },
    },
  );

  const notificationCenter = useNotificationCentertContext();

  const [removeNotificationMutate] =
    useMutationWithError<MarkNotificationClearedMutation>(
      graphql`
        mutation Notification_MarkNotificationClearedMutation(
          $input: MarkNotificationClearedInput!
        ) {
          markNotificationClearedOrError(input: $input) {
            ... on MarkNotificationClearedPayload {
              notification {
                readAt
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `,
      {
        input: { notificationId: notification.id! },
        updater: (store) => {
          rangeDeleteUpdater(store, {
            parentId: notificationCenter.id!,
            connectionKey: 'NotificationCenter_notificationConnection',
            connectionFilters: (filters) => {
              return filters.isCleared === undefined;
            },
            deletedId: notification.id!,
          });
        },
      },
    );

  return (
    <Layout
      className={clsx(
        'relative border-t border-divider',
        isUnread && clearable && 'bg-grey-5',
        'notification',
      )}
    >
      {isUnread && (
        <div className="absolute top-1 left-2 bg-primary h-2 w-2 rounded-full notification--unread-indicator" />
      )}
      <Dropdown.Item
        className="py-2 pr-8"
        onClick={(e: any) => {
          if (isUnread) {
            readNotificationMutate();
          }
          onClick?.(e);
        }}
      >
        {children}
      </Dropdown.Item>
      {clearable && (
        <IconButton
          variant={null}
          icon={<CloseSm width={12} />}
          onClick={() => {
            removeNotificationMutate();
          }}
          data-bni-id="RemoveNotificationButton"
          placement="left"
          titleSrOnly
          title={
            <FormattedMessage
              id="notification.removeNotification"
              defaultMessage="Remove Notification"
            />
          }
          className="absolute right-0 px-2 text-subtitle"
        />
      )}
    </Layout>
  );
}

export default createFragmentContainer(Notification, {
  notification: graphql`
    fragment Notification_notification on NotificationInterface {
      readAt
      ... on Node {
        id
      }
    }
  `,
});
