/**
 * @generated SignedSource<<1148b4d1eb0fb500e255ecf830d2baac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReviewedExamsPerformanceIndicator_tenant$data = {
  readonly reviewedStudyStatistics: {
    readonly numFinalizedStudies: number | null;
    readonly numReviewedStudies: number | null;
    readonly numReviewedStudiesWithWorksheets: number | null;
    readonly studyExamTypeStatistics: ReadonlyArray<{
      readonly examType: {
        readonly id: string;
        readonly name: string | null;
        readonly systemId: string | null;
      } | null;
      readonly numFinalizedStudies: number;
      readonly numReviewedStudies: number | null;
    } | null> | null;
    readonly studyStatusStatistics: ReadonlyArray<{
      readonly numStudies: number;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "ReviewedExamsPerformanceIndicator_tenant";
};
export type ReviewedExamsPerformanceIndicator_tenant$key = {
  readonly " $data"?: ReviewedExamsPerformanceIndicator_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewedExamsPerformanceIndicator_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numFinalizedStudies",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numReviewedStudies",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewedExamsPerformanceIndicator_tenant",
  "selections": [
    {
      "alias": "reviewedStudyStatistics",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "StudyStatistics",
      "kind": "LinkedField",
      "name": "studyStatistics",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numReviewedStudiesWithWorksheets",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyExamTypeStatistics",
          "kind": "LinkedField",
          "name": "studyExamTypeStatistics",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamType",
              "kind": "LinkedField",
              "name": "examType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "systemId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyStatusStatistics",
          "kind": "LinkedField",
          "name": "studyStatusStatistics",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numStudies",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};
})();

(node as any).hash = "330a7cf04515b109049a2607782bfa3d";

export default node;
