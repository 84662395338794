import type { FlexProps } from '@4c/layout';
import { useField } from '@bfly/ui2/Form';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import VideoTagCheck from './VideoTagCheck';
import { CategoriesFormField_query$data as Query } from './__generated__/CategoriesFormField_query.graphql';

interface Props extends React.ComponentPropsWithoutRef<any> {
  query: Query;
  justify?: FlexProps['justify'];
  className?: string;
  theme?: 'dark' | 'light';
}

type Category = DeepNonNullable<Query>['educationCategories'];
type EducationContentCategory =
  DeepNonNullable<Query>['lms']['educationContentCategories'][number];

function CategoriesFormField({
  query,
  className,
  justify = 'flex-start',
  theme = 'dark',
}: Props) {
  const educationCategories = query.lms!
    .educationContentCategories! as EducationContentCategory[];

  const categories = useMemo(() => {
    return orderBy(
      educationCategories,
      ({ ordering }: EducationContentCategory) => ordering,
    ) as EducationContentCategory[];
  }, [educationCategories]);

  const [fieldProps] = useField({
    name: 'category',
    mapFromValue: (val: Category) => val.map((d) => d.handle || d),
  });

  return (
    <FormCheckGroup<Category[0]>
      id="categories-form-field"
      wrap
      pad={2}
      direction="row"
      justify={justify}
      className={className}
      dataKey="handle"
      data={categories as any}
      renderItem={(category) => category.name}
      checkAs={({ children, ...props }) => (
        <VideoTagCheck theme={theme} {...props}>
          {children}
        </VideoTagCheck>
      )}
      {...fieldProps}
      type="checkbox"
    />
  );
}

export default createFragmentContainer(CategoriesFormField, {
  query: graphql`
    fragment CategoriesFormField_query on Query
    @argumentDefinitions(
      practiceType: { type: "[EducationVideoPracticeType!]" }
    ) {
      educationCategories(practiceType: $practiceType) {
        handle
        name
      }
      lms {
        educationContentCategories {
          handle
          name
          ordering
        }
      }
    }
  `,
});
