import Layout from '@4c/layout';
import styled from 'astroturf/react';

export const Grid = styled(Layout)`
  width: 100%;
  flex-wrap: wrap;

  @screen lg {
    flex-wrap: nowrap;
  }
`;

export const MainSection = styled(Layout).attrs({
  direction: 'column',
  grow: true,
})`
  flex-basis: 100%;

  @screen lg {
    flex-basis: 66.66%;
  }
`;

export const SecondarySection = styled(Layout).attrs({
  direction: 'column',
  grow: true,
})`
  flex-basis: 100%;

  @screen lg {
    flex-basis: 33.33%;
  }
`;
