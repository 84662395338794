import Layout, { LayoutProps } from '@4c/layout';
import { css } from 'astroturf';
import { ComponentPropsWithoutRef } from 'react';

function AnalyticsPageStickySection(
  props: LayoutProps & ComponentPropsWithoutRef<'div'>,
) {
  return (
    <Layout
      {...props}
      css={css`
        position: sticky;
        left: 0;
        width: 100vw;

        [data-obtrusive-scrollbars='true'] & {
          width: calc(100vw - 10px);
        }
      `}
    />
  );
}

export default AnalyticsPageStickySection;
