/**
 * @generated SignedSource<<6022f1b35c56a2c8d86fa362bcba9c1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarGlobal_tenant$data = {
  readonly slug?: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"getConcreteTenant_tenant" | "useSearchState_tenant">;
  readonly " $fragmentType": "SearchBarGlobal_tenant";
};
export type SearchBarGlobal_tenant$key = {
  readonly " $data"?: SearchBarGlobal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarGlobal_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
},
v1 = [
  {
    "alias": "type",
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBarGlobal_tenant",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useSearchState_tenant",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getConcreteTenant_tenant",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "Domain",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "Organization",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v0/*: any*/)
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};
})();

(node as any).hash = "d876a73984157622f81eb9122d1b56bb";

export default node;
