/**
 * @generated SignedSource<<93e7fe40c95721f5b5a6c476e87c0314>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canPermanentlyDeleteStudy$data = {
  readonly deletedAt: string | null;
  readonly " $fragmentType": "StudyPermissions_canPermanentlyDeleteStudy";
};
export type StudyPermissions_canPermanentlyDeleteStudy$key = {
  readonly " $data"?: StudyPermissions_canPermanentlyDeleteStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canPermanentlyDeleteStudy">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canPermanentlyDeleteStudy"
};

(node as any).hash = "f06f83c6a60eb53d5e0b3950b6e488d7";

export default node;
