/**
 * @generated SignedSource<<0cc28e8254de028b56c30919a7a61d64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsPage_organization$data = {
  readonly slug: string | null;
  readonly viewerNotificationCenter: {
    readonly id: string;
    readonly notificationConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly createdAt: string | null;
          readonly id?: string;
          readonly typename: string;
          readonly " $fragmentSpreads": FragmentRefs<"StudyFinalizationRequestedNotification_notification" | "StudyImageCommentNotification_notification" | "WorksheetReviewedNotification_notification">;
        } | null;
      } | null> | null;
    } | null;
    readonly numUnseenNotifications: number | null;
  } | null;
  readonly " $fragmentType": "NotificationsPage_organization";
};
export type NotificationsPage_organization$key = {
  readonly " $data"?: NotificationsPage_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsPage_organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 24,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isCleared"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "viewerNotificationCenter",
          "notificationConnection"
        ]
      }
    ]
  },
  "name": "NotificationsPage_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationCenter",
      "kind": "LinkedField",
      "name": "viewerNotificationCenter",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUnseenNotifications",
          "storageKey": null
        },
        {
          "alias": "notificationConnection",
          "args": [
            {
              "kind": "Variable",
              "name": "isCleared",
              "variableName": "isCleared"
            }
          ],
          "concreteType": "NotificationInterfaceConnection",
          "kind": "LinkedField",
          "name": "__NotificationCenter_notificationConnection_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NotificationInterfaceEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "type": "Node",
                      "abstractKey": "__isNode"
                    },
                    {
                      "alias": "typename",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StudyImageCommentNotification_notification"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "WorksheetReviewedNotification_notification"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StudyFinalizationRequestedNotification_notification"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "19e3c45628cb24accdff09d322ccc673";

export default node;
