import CineIcon from '@bfly/icons/Cine';
import Logo from '@bfly/icons/Logo';
import Popover from '@bfly/ui2/Popover';
import Text from '@bfly/ui2/Text';
import { UsePopperState } from '@bfly/ui2/usePopper';
import { css } from 'astroturf';
import { useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

// import AspectRatioBox from 'components/AspectRatioBox';
import Thumbnail from 'components/Thumbnail';

import AspectRatioBox from './AspectRatioBox';
import { useVariation } from './LaunchDarklyContext';
import { StudyImagePopoverThumbnail_studyImage$data as StudyImage } from './__generated__/StudyImagePopoverThumbnail_studyImage.graphql';

interface Props {
  studyImage: StudyImage | null;
  children?: React.ReactNode;
  maxWidth?: number;
  aspectRatioBoxClassName?: string;
}

/**
 * A component which renders a small study thumbnail and displays a larger image / cine video on mouseover
 */
function StudyImagePopoverThumbnail({
  studyImage,
  children,
  maxWidth = 240,
  aspectRatioBoxClassName = 'w-24 h-24',
}: Props) {
  const popperRef = useRef<UsePopperState | null>();
  const enableVideoOnSplitStudies = useVariation('split-studies');
  if (!studyImage) {
    return null;
  }
  const isNbd = !studyImage.iqMetadata?.probeSerial;
  const sizeClass = isNbd ? 'w-[42rem]' : 'w-[32rem]';
  const onResourceLoad = () => {
    popperRef.current?.update();
  };
  return (
    <Popover.Trigger
      placement="auto-start"
      contentClassName={sizeClass}
      variant="dark"
      onRenderOverlay={(_props, meta) => {
        const { popper } = meta;
        popperRef.current = popper;
      }}
      popover={
        <div className="z-50">
          {/* Caveat: dimensions must match the thumbnail maxWidth */}
          <div className="-mx-2  block rounded relative">
            {studyImage.burnedInFile?.isVideo && enableVideoOnSplitStudies ? (
              <video
                className="w-full h-full"
                src={studyImage.burnedInFile.dataUrl!}
                loop
                autoPlay
                onPlay={onResourceLoad}
              />
            ) : (
              <Thumbnail
                maxWidth={420}
                alt="Study Capture"
                className="w-full h-full object-cover rounded"
                key={studyImage.handle}
                image={studyImage.burnedInFile!}
                onLoad={onResourceLoad}
              />
            )}
          </div>
          {studyImage.iqMetadata?.probeSerial && (
            <Text className="flex items-center justify-center mt-1">
              <span>{studyImage.iqMetadata?.probeSerial}</span>
              <Logo
                className="h-3 w-3 ml-2"
                css={css`
                  path {
                    fill: currentColor !important;
                  }
                `}
              />
            </Text>
          )}
        </div>
      }
    >
      <div
        data-bni-id="StudyImagePopoverThumbnail"
        className="pointer-events-auto cursor-pointer border border-white/20 rounded block w-full h-full relative"
      >
        {children}
        <AspectRatioBox
          className={`inline-block relative ${aspectRatioBoxClassName}`}
        >
          <Thumbnail
            loading="lazy"
            maxWidth={maxWidth}
            alt="Study Capture"
            className="w-full h-full object-cover rounded"
            key={studyImage.handle}
            image={studyImage.burnedInFile!}
          />
          {studyImage.burnedInFile!.isVideo && (
            <div className="bg-grey-90 rounded-[2px] p-0.5 absolute flex items-center justify-center text-white w-[1rem] h-[1rem] bottom-0.5 right-0.5">
              <CineIcon width={6} height={3} />
            </div>
          )}
        </AspectRatioBox>
      </div>
    </Popover.Trigger>
  );
}

export default createFragmentContainer(StudyImagePopoverThumbnail, {
  studyImage: graphql`
    fragment StudyImagePopoverThumbnail_studyImage on StudyImageInterface {
      ... on StudyImage {
        handle
        burnedInFile {
          isVideo
          dataUrl
          ...Thumbnail_image
        }
        iqMetadata {
          probeSerial
        }
      }
      ... on EduStudyImage {
        handle
        burnedInFile {
          isVideo
          dataUrl
          ...Thumbnail_image
        }
        iqMetadata {
          probeSerial
        }
      }
    }
  `,
});
