import DropdownList, { DropdownListProps } from '@bfly/ui2/DropdownList';
import getNodes from '@bfly/utils/getNodes';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import { createFragmentContainer, graphql } from 'react-relay';

import EhrForwardingIcon from './EhrForwardingIcon';
import PacsForwardingIcon from './PacsForwardingIcon';
import { ArchiveDropdownItem$data as ArchiveItem } from './__generated__/ArchiveDropdownItem.graphql';
import { ArchiveDropdown_organization$data as Organization } from './__generated__/ArchiveDropdown_organization.graphql';

export type { ArchiveItem };

const styles = stylesheet`
  .input {
    composes: bg-grey-80 from global;

    --bni-form-control-height: 3rem;
    grid-template: 1fr / 1fr 3rem;

    & :global(.rw-dropdown-list-input) {
      padding: 0 theme('spacing.2');
    }
  }
`;

const ehrForwardingIcon = <EhrForwardingIcon className="flex-none ml-1" />;

const pacsForwardingIcon = <PacsForwardingIcon className="flex-none ml-1" />;

export function renderArchiveValue({ item }: { item: ArchiveItem }) {
  return (
    !!item && (
      <span className="w-full flex font-bold">
        <span
          className="truncate max-w-[max-content] w-full"
          title={item.label || ''}
        >
          {item.label}
        </span>
        {!!item.ehrAssociations?.length && ehrForwardingIcon}
        {!!item.pacsAssociations?.length && pacsForwardingIcon}
      </span>
    )
  );
}

export const ArchiveDropdownItem = graphql`
  fragment ArchiveDropdownItem on Archive @relay(mask: false) {
    id
    label
    pacsAssociations {
      id
    }
    ehrAssociations {
      id
    }
  }
`;

interface Props
  extends Omit<
    DropdownListProps<ArchiveItem>,
    | 'variant'
    | 'menuVariant'
    | 'filter'
    | 'data'
    | 'renderListItem'
    | 'dataKey'
    | 'textField'
    | 'value'
    | 'renderValue'
    | 'containerClassName'
    | 'disabled'
  > {
  /** Archive item or archive Id */
  value: ArchiveItem | null | string;
  organization: Organization | null;
  disabled?: (items: ArchiveItem[] | null) => ArchiveItem[];
}

function ArchiveDropdown({
  value,
  organization,
  className,
  busy,
  disabled,
  ...props
}: Props) {
  const items = organization?.archiveConnection
    ? getNodes(organization?.archiveConnection)
    : null;

  const valueId = typeof value === 'string' ? value : value?.id;
  return (
    <DropdownList<ArchiveItem>
      {...props}
      data-bni-id="ArchiveDropdown"
      variant="secondary"
      menuVariant="dark"
      filter={false}
      data={items || []}
      renderListItem={renderArchiveValue}
      dataKey="id"
      textField="label"
      value={valueId && items?.find((i) => i.id === valueId)}
      renderValue={renderArchiveValue}
      className={className}
      containerClassName={clsx(className, styles.input)}
      busy={busy || items === null}
      disabled={disabled?.(items) || []}
    />
  );
}

export default createFragmentContainer(ArchiveDropdown, {
  organization: graphql`
    fragment ArchiveDropdown_organization on Organization {
      archiveConnection(first: 2147483647)
        @connection(key: "Organization_archiveConnection") {
        edges {
          node {
            ...ArchiveDropdownItem @relay(mask: false)
          }
        }
      }
    }
  `,
});
