import Hl7 from '@bfly/icons/Hl7';
import Tooltip from '@bfly/ui2/Tooltip';
import { FormattedMessage } from 'react-intl';

import messages from 'messages/archive';

export default ({ className }: { className: string }) => (
  <Tooltip.Trigger tooltip={<FormattedMessage {...messages.ehrForwarding} />}>
    <Hl7 width="20" className={className} />
  </Tooltip.Trigger>
);
