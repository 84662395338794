/**
 * @generated SignedSource<<55b48237e11dc0f072693494b145ee26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudySource = "BUTTERFLY" | "THIRD_PARTY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExamPageActions_study$data = {
  readonly id: string;
  readonly source: StudySource | null;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteStudyControl_study" | "MarkStudyAsDraftControl_study" | "MoveStudyDropdownItem_study" | "PermanentlyDeleteStudyControl_study" | "ShareStudyDropdownItem_study" | "SplitStudyControl_study" | "StudyDownloadPdfDropdownItem_study" | "StudyEndpointsDropdownItem_study" | "StudyHistoryDropdownItem_study" | "ToggleStudyFavoriteButton_study" | "UndeleteStudyControl_study">;
  readonly " $fragmentType": "ExamPageActions_study";
};
export type ExamPageActions_study$key = {
  readonly " $data"?: ExamPageActions_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageActions_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPageActions_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShareStudyDropdownItem_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitStudyControl_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyEndpointsDropdownItem_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MoveStudyDropdownItem_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyDownloadPdfDropdownItem_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteStudyControl_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UndeleteStudyControl_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PermanentlyDeleteStudyControl_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ToggleStudyFavoriteButton_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MarkStudyAsDraftControl_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyHistoryDropdownItem_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "9c3ed718dde674b51020d324100c929c";

export default node;
