/**
 * @generated SignedSource<<1c58ec9944038d1b82c497010c719e2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canDeleteCaptures$data = {
  readonly finalizedAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "StudyPermissions_canDeleteCaptures";
};
export type StudyPermissions_canDeleteCaptures$key = {
  readonly " $data"?: StudyPermissions_canDeleteCaptures$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canDeleteCaptures">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canDeleteCaptures"
};

(node as any).hash = "030074384cbe04bea20d64d4bca2b038";

export default node;
