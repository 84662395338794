/**
 * @generated SignedSource<<26118108aa8fc2bef12574110447d46a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyFilters_study$data = {
  readonly archive: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "StudyFilters_study";
};
export type StudyFilters_study$key = {
  readonly " $data"?: StudyFilters_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyFilters_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyFilters_study"
};

(node as any).hash = "98297efea02ab09339ea524857fac04c";

export default node;
