/**
 * @generated SignedSource<<2d7e2dd090271cad881d29f0648ccd42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitStudyConfirmDeleteStudy_destinationStudy$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StudyTitle_study">;
  readonly " $fragmentType": "SplitStudyConfirmDeleteStudy_destinationStudy";
};
export type SplitStudyConfirmDeleteStudy_destinationStudy$key = {
  readonly " $data"?: SplitStudyConfirmDeleteStudy_destinationStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyConfirmDeleteStudy_destinationStudy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyConfirmDeleteStudy_destinationStudy",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyTitle_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "b4a1ae15642afcbc8d7407a3eddc09fc";

export default node;
