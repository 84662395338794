import BlankSlate from '@bfly/ui2/BlankSlate';
import MainContent from '@bfly/ui2/MainContent';
import useRouter from 'found/useRouter';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import AppStoreDownloadLinks from 'components/AppStoreDownloadLinks';
import BlankAppPage from 'components/BlankAppPage';
import useDeviceTooSmall from 'hooks/useIsTinyViewport';

function DeviceTooSmallPage() {
  const { router } = useRouter();
  const isTooSmall = useDeviceTooSmall();

  useEffect(() => {
    if (!isTooSmall) router.replace('/');
  }, [isTooSmall, router]);

  return (
    <BlankAppPage>
      <MainContent>
        <BlankSlate>
          <BlankSlate.Title>
            <FormattedMessage
              id="deviceTooSmall.title"
              defaultMessage="Screen Too Small"
            />
          </BlankSlate.Title>
          <BlankSlate.Body>
            <FormattedMessage
              id="deviceTooSmall.body1.iosOrAndroid"
              defaultMessage="Butterfly Cloud is optimized for larger screens. Increase your browser size or use a larger device. For the best experience on your mobile device, download Butterfly iQ for iOS or Android."
            />
            {/* TODO: Add a launch app button */}
          </BlankSlate.Body>
          <AppStoreDownloadLinks className="mt-4" />
        </BlankSlate>
      </MainContent>
    </BlankAppPage>
  );
}

export default DeviceTooSmallPage;
