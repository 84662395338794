/**
 * @generated SignedSource<<dcef0fdde516f1496ef7fe166c1305bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ImageCaptureMode = "BIPLANE" | "B_MODE" | "B_MODE_3D" | "COLOR_DOPPLER" | "M_MODE" | "POWER_DOPPLER" | "PULSED_WAVE_DOPPLER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EduStudyImage_study$data = {
  readonly deletedAt: string | null;
  readonly imageConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly burnedInFile: {
          readonly dataUrl: string | null;
          readonly isVideo: boolean | null;
        } | null;
        readonly captureMode: ImageCaptureMode | null;
        readonly capturedAt: string | null;
        readonly handle: string | null;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ExamImageRoll_images" | "StudyImage_image">;
      } | null;
    } | null> | null;
  } | null;
  readonly numImages: number;
  readonly " $fragmentType": "EduStudyImage_study";
};
export type EduStudyImage_study$key = {
  readonly " $data"?: EduStudyImage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"EduStudyImage_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "imageConnection"
        ]
      }
    ]
  },
  "name": "EduStudyImage_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": "imageConnection",
      "args": null,
      "concreteType": "EduStudyImageConnection",
      "kind": "LinkedField",
      "name": "__ExamImagePage_imageConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EduStudyImageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EduStudyImage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "handle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "capturedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "captureMode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BurnedInFile",
                  "kind": "LinkedField",
                  "name": "burnedInFile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isVideo",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dataUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyImage_image"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ExamImageRoll_images"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    }
  ],
  "type": "EduStudy",
  "abstractKey": null
};

(node as any).hash = "c952684eca093d767c477a57d4e8f90c";

export default node;
