import useMountEffect from '@restart/hooks/useMountEffect';
import { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { ReactRelayContext, graphql } from 'react-relay';

import { useLaunchDarklyClientAllowMissingContext } from 'components/LaunchDarklyContext';
import { Match } from 'components/Route';
import { routes } from 'routes/scanLab';
import { useRouterState } from 'utils/LocationUtils';
import { canAccessReview } from 'utils/canAccessScanLab';
import { dateRangePickerValueToDates } from 'utils/dateRangePickerHelpers';
import fetchQuery from 'utils/fetchQuery';
import { useViewerContext } from 'utils/viewerContext';

import {
  AVAILABLE_REVIEW_STATUSES,
  DEFAULT_SORT,
  QUERY_PARAMS_DEFAULT,
  REVIEW_STATUS_QUERY_PARAMS,
  STATUS_LIST,
} from '../constants';
import { scanLabContext } from '../context';
import {
  ReviewStatus,
  ScanLabContext,
  ScanLabListRoute,
  ScanLabRouterState,
  StatusCounts,
} from '../types';
import { ScanLabProvider_statusCounts_Query as StatusCountsQuery } from './__generated__/ScanLabProvider_statusCounts_Query.graphql';

const validateReviewStatus = (
  route: ScanLabListRoute | string,
  status?: any,
): ReviewStatus => {
  const statuses = AVAILABLE_REVIEW_STATUSES[route];

  return typeof status !== 'string' ||
    !statuses.includes(status as ReviewStatus)
    ? statuses[0]
    : (status as ReviewStatus);
};

function useCounts(
  listRoute: ScanLabListRoute | null,
  viewerId: string,
  canReview: boolean,
) {
  const { environment } = useContext(ReactRelayContext)!;

  const [statusCounts, setStatusCounts] = useState<Record<
    ScanLabListRoute,
    StatusCounts | null
  > | null>(null);

  const initializeStatusCounts = () =>
    fetchQuery<StatusCountsQuery>(
      graphql`
        query ScanLabProvider_statusCounts_Query(
          $viewerId: [ID!]
          $canNotReview: Boolean!
        ) {
          portfolio: viewer {
            unsubmitted: eduNumStudies(
              authors: $viewerId
              isReviewRequested: false
              isReviewed: false
            )
            reviewRequested: eduNumStudies(
              authors: $viewerId
              isReviewRequested: true
              isReviewed: false
            )
            reviewed: eduNumStudies(authors: $viewerId, isReviewed: true)
          }
          review: viewer @skip(if: $canNotReview) {
            reviewRequested: eduNumStudies(
              authors: null
              isReviewRequested: true
              isReviewed: false
            )
            reviewed: eduNumStudies(authors: null, isReviewed: true)
          }
        }
      `,
      {
        variables: {
          viewerId: [viewerId],
          canNotReview: !canReview,
        },
        environment,
      },
    ).then(({ data }) => {
      setStatusCounts({
        portfolio: {
          reviewed: data.portfolio?.reviewed || 0,
          reviewRequested: data.portfolio?.reviewRequested || 0,
          unsubmitted: data.portfolio?.unsubmitted || 0,
        },
        review: canReview
          ? {
              reviewed: data.review?.reviewed || 0,
              reviewRequested: data.review?.reviewRequested || 0,
              unsubmitted: 0,
            }
          : null,
      });
    });

  useMountEffect(() => {
    if (!statusCounts) initializeStatusCounts();
  });

  const updateStatusCounts: ScanLabContext['updateStatusCounts'] = (
    studies,
    nextStatus,
  ) => {
    const { portfolio, review } = statusCounts || {
      portfolio: null,
      review: null,
    };

    const userStudies = studies.filter((study) => study.authorId === viewerId);

    const prevStatus = STATUS_LIST[STATUS_LIST.indexOf(nextStatus) - 1];

    if (review) {
      review[prevStatus] -= studies.length;
      if (review[prevStatus] < 0) review[prevStatus] = 0;

      review[nextStatus] += studies.length;
    }

    if (portfolio && userStudies.length) {
      portfolio[prevStatus] -= userStudies.length;
      if (portfolio[prevStatus] < 0) portfolio[prevStatus] = 0;

      portfolio[nextStatus] += userStudies.length;
    }

    setStatusCounts({
      portfolio,
      review,
    });
  };

  return {
    statusCounts: (listRoute && statusCounts?.[listRoute]) || null,
    updateStatusCounts,
  };
}

export default function ScanLabProvider({
  children,
  match,
}: PropsWithChildren<{ match: Match }>) {
  const viewer = useViewerContext();
  const viewerProfileId = viewer.profile!.id;

  const { Provider } = scanLabContext;

  const { canRequestReview, canReview } = canAccessReview(
    viewer,
    useLaunchDarklyClientAllowMissingContext(),
  );

  const currentListRoute = useMemo(
    () =>
      match.params.listRoute === 'review' ||
      match.params.listRoute === 'portfolio'
        ? match.params.listRoute
        : null,
    [match.params.listRoute],
  );

  const reviewStatus = useMemo(() => {
    return !canRequestReview || !currentListRoute
      ? 'unsubmitted'
      : validateReviewStatus(currentListRoute, match.location.query?.status);
  }, [canRequestReview, currentListRoute, match.location.query?.status]);

  const { toLocation, routerState, setRouterState } =
    useRouterState<ScanLabRouterState>('scanlab', ({ location }) => {
      const next: ScanLabRouterState = {
        filters: {
          dates: null,
          examTypes: null,
          groupId: null,
          author: null,
        },
        lastListRoute: currentListRoute,
        queryParams: QUERY_PARAMS_DEFAULT,
        reviewStatus: 'unsubmitted',
      };

      if (!currentListRoute) {
        return next;
      }

      next.reviewStatus = !canRequestReview
        ? 'unsubmitted'
        : validateReviewStatus(currentListRoute, location.query?.status);

      next.queryParams = {
        ...next.queryParams,
        author: currentListRoute === 'portfolio' ? [viewerProfileId] : null,
      };

      return next;
    });

  const setFilters: ScanLabContext['setFilters'] = (nextFilters) => {
    if (!routerState?.filters || !routerState?.queryParams) return;

    const nextQueryParams = { ...routerState.queryParams };

    if ('dates' in nextFilters) {
      const { start, end } = dateRangePickerValueToDates(nextFilters.dates);
      nextQueryParams.conductedAtMax = end?.toISOString();
      nextQueryParams.conductedAtMin = start?.toISOString();
    }

    if ('examTypes' in nextFilters) {
      nextQueryParams.examTypeHandles = nextFilters.examTypes || undefined;
    }

    if ('author' in nextFilters) {
      const nextAuthorIds = nextFilters.author?.map((a) => a.id);
      nextQueryParams.author = nextAuthorIds?.length ? nextAuthorIds : null;
    }

    if ('groupId' in nextFilters) {
      nextQueryParams.groupId = nextFilters.groupId || null;
    }

    setRouterState({
      filters: { ...routerState.filters, ...nextFilters },
      queryParams: nextQueryParams,
    });
  };

  const setSort: ScanLabContext['setSort'] = (nextSort) =>
    routerState?.queryParams &&
    setRouterState({
      queryParams: {
        ...routerState.queryParams,
        sort: nextSort ? [nextSort] : [DEFAULT_SORT],
      },
    });

  const toListRoute: ScanLabContext['toListRoute'] = (
    status,
    newListRoute,
  ) => {
    const lastListRoute =
      newListRoute || routerState.lastListRoute || 'portfolio';
    return toLocation(
      {
        pathname: routes[lastListRoute](),
        query: { status },
      },
      { reviewStatus: status, lastListRoute },
    );
  };

  const toStudyLocation: ScanLabContext['toStudyLocation'] = ({
    studyHandle,
    studyImageHandle,
    cursor,
  }) =>
    toLocation(
      {
        pathname: studyImageHandle
          ? routes.examImage({ studyHandle, studyImageHandle })
          : routes.exam({ studyHandle }),
        query: {},
      },
      {
        ...routerState,
        queryParams: { ...routerState.queryParams, cursor },
      },
    );

  const { statusCounts, updateStatusCounts } = useCounts(
    currentListRoute,
    viewer.profile!.id,
    canReview,
  );

  const queryParams = routerState.queryParams && {
    ...routerState.queryParams,
    ...REVIEW_STATUS_QUERY_PARAMS[canRequestReview ? reviewStatus : 'none'],
  };

  return (
    <Provider
      value={{
        canReview,
        canRequestReview,
        setFilters,
        setSort,
        toListRoute,
        toStudyLocation,
        queryParams,
        filters: routerState.filters,
        reviewStatus: routerState.reviewStatus,
        lastListRoute: routerState.lastListRoute,
        statusCounts,
        updateStatusCounts,
      }}
    >
      {children}
    </Provider>
  );
}
