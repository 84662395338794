import PublicArchiveIcon from '@bfly/icons/Folder';
import PrivateArchiveIcon from '@bfly/icons/FolderPrivate';
import Link from '@bfly/ui2/Link';
import Text from '@bfly/ui2/Text';
import clsx from 'clsx';
import { createFragmentContainer, graphql } from 'react-relay';

import { useArchiveRoutes } from 'routes/archive';

import { StudyArchive_archive$data as Archive } from './__generated__/StudyArchive_archive.graphql';

interface Props {
  archive: Archive;
  variant?: 'pill' | 'default';
  className?: string;
  onClick?: () => void;
  linkToArchive?: boolean;
}

function StudyArchive({
  archive,
  variant,
  className,
  onClick,
  linkToArchive,
}: Props) {
  const archiveRoutes = useArchiveRoutes();
  const Icon = archive.isPrivate ? PrivateArchiveIcon : PublicArchiveIcon;
  const classes = clsx(
    className,
    'inline-flex items-center max-w-full text-body',
    variant === 'pill' && 'rounded bg-contrast/10 py-0.5 px-2',
  );
  const children = (
    <>
      <Text as={Icon} color="body" className="mr-1 flex-none" />
      <Text variant="sm-bold" color="headline" truncate>
        {archive.label}
      </Text>
    </>
  );

  return linkToArchive ? (
    <Link
      data-bni-id="studyArchive.icon"
      as="a"
      to={{
        pathname: archiveRoutes.studySearch({
          organizationSlug: archive!.organization!.slug!,
        }),
        query: {
          archive: archive!.handle,
        },
      }}
      className={classes}
    >
      {children}
    </Link>
  ) : (
    <button
      data-bni-id="studyArchive.icon"
      type="button"
      className={classes}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default createFragmentContainer(StudyArchive, {
  archive: graphql`
    fragment StudyArchive_archive on Archive {
      organization {
        slug
      }
      handle
      label
      isPrivate
    }
  `,
});
