/**
 * @generated SignedSource<<e548b185bde9cb2d72d5218c038aec17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateDomainRolePage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DomainRoleDeleteFieldSet_viewer">;
  readonly " $fragmentType": "UpdateDomainRolePage_viewer";
};
export type UpdateDomainRolePage_viewer$key = {
  readonly " $data"?: UpdateDomainRolePage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateDomainRolePage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateDomainRolePage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DomainRoleDeleteFieldSet_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "80c3d58c8f829f41066d604399fae8c4";

export default node;
