import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import React from 'react';
import { defineMessage } from 'react-intl';

import PageTitle from 'components/PageTitle';

export interface Props {
  children: React.ReactNode;
}

function DomainExamTypesPage({ children }) {
  return (
    <div data-bni-id="DomainExamTypesPage">
      <Page.Header>
        <PageTitle
          title={defineMessage({
            id: 'domain.settings.examTypes.pageTitle',
            defaultMessage: 'Exam Types',
          })}
        />
      </Page.Header>
      <MainContent>{children}</MainContent>
    </div>
  );
}

export default DomainExamTypesPage;
