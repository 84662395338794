import CheckMarkSmall from '@bfly/icons/CheckMarkSmall';
import ItemGrid from '@bfly/ui2/ItemGrid';
import MutationButton from '@bfly/ui2/MutationButton';
import Text from '@bfly/ui2/Text';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import { css } from 'astroturf';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import UserInfo from 'components/UserInfo';

import { ArchiveMemberItemGridRow_DeleteMutation as DeleteMutation } from './__generated__/ArchiveMemberItemGridRow_DeleteMutation.graphql';
import { ArchiveMemberItemGridRow_archive$data as Archive } from './__generated__/ArchiveMemberItemGridRow_archive.graphql';
import { ArchiveMemberItemGridRow_membership$data as ArchiveMembership } from './__generated__/ArchiveMemberItemGridRow_membership.graphql';

const ReadOnlyCheck = ({ checked }: { checked: boolean }) =>
  checked ? (
    <Text color="primary" className="px-1" data-bni-id="checkedCells">
      <CheckMarkSmall width="14px" height="12px" />
    </Text>
  ) : null;

interface Props {
  membership: ArchiveMembership;
  archive: Archive;
  span?: number;
}

function ArchiveMemberItemGridRow({ membership, archive, span }: Props) {
  const { id, userProfile, email, canFinalize, viewerCanDelete, canQa } =
    membership;

  const canUseQa = useVariation('worksheets-review');

  return (
    <ItemGrid.Row>
      <ItemGrid.Cell
        css={css`
          grid-column-end: span ${span};
        `}
      >
        <UserInfo
          userProfile={userProfile}
          email={email!}
          className="max-w-full"
        />
      </ItemGrid.Cell>

      {archive.hasRestrictedFinalization && (
        <ItemGrid.Cell justify="center">
          <ReadOnlyCheck checked={canFinalize!} />
        </ItemGrid.Cell>
      )}

      {canUseQa && (
        <ItemGrid.Cell justify="center">
          <ReadOnlyCheck checked={canQa!} />
        </ItemGrid.Cell>
      )}

      <ItemGrid.ActionsCell
        // Grumble: this is a design deviation from normal tables but it
        // looks bad with the checkboxes centered and the text not
        className="justify-center"
      >
        {viewerCanDelete && (
          <MutationButton<DeleteMutation>
            variant="text-secondary"
            size="flush"
            mutation={graphql`
              mutation ArchiveMemberItemGridRow_DeleteMutation(
                $input: DeleteArchiveMembershipInput!
              ) {
                deleteArchiveMembershipOrError(input: $input) {
                  ...mutationError_error @relay(mask: false)
                }
              }
            `}
            input={{ archiveMembershipId: id }}
            updater={(store) => {
              rangeDeleteUpdater(store, {
                parentId: archive.id,
                connectionKey: 'Archive_membershipConnection',
                deletedId: id,
              });
            }}
          >
            <FormattedMessage
              id="archiveMembersTable.remove"
              defaultMessage="Remove"
            />
          </MutationButton>
        )}
      </ItemGrid.ActionsCell>
    </ItemGrid.Row>
  );
}

export default createFragmentContainer(ArchiveMemberItemGridRow, {
  archive: graphql`
    fragment ArchiveMemberItemGridRow_archive on Archive {
      id
      hasRestrictedFinalization
    }
  `,
  membership: graphql`
    fragment ArchiveMemberItemGridRow_membership on ArchiveMembership {
      id
      userProfile {
        ...UserInfo_userProfile
      }
      email
      type
      viewerCanDelete
      canFinalize
      canQa
    }
  `,
});
