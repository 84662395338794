/**
 * @generated SignedSource<<acc761976e6a54f27dac40362deaae40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FileExportButton_fileExport$data = {
  readonly url: string | null;
  readonly " $fragmentType": "FileExportButton_fileExport";
};
export type FileExportButton_fileExport$key = {
  readonly " $data"?: FileExportButton_fileExport$data;
  readonly " $fragmentSpreads": FragmentRefs<"FileExportButton_fileExport">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "FileExportButton_fileExport"
};

(node as any).hash = "05b99afdaa2c33a72f01136367cbf268";

export default node;
