import Dropdown from '@bfly/ui2/Dropdown';
import useDialog from '@bfly/ui2/useDialog';
import React, { useCallback } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import actionMessages from 'messages/actions';

const messages = defineMessages({
  dialogMessage: {
    id: 'deleteControl.message',
    defaultMessage: 'This is a permanent action.',
  },
});

interface Props<As extends React.ElementType> {
  as: As;

  runCommit: () => Promise<unknown>;
  dialogTitle: React.ReactNode;
  dialogMessage?: React.ReactNode;
  buttonText?: React.ReactNode;
  modalVariant?: 'light' | 'dark';
  'data-bni-id'?: string;
}

export default function DeleteControl<As extends React.ElementType>({
  as: Component,
  runCommit: runConfirm,
  dialogTitle,
  dialogMessage,
  buttonText,
  ...props
}: Props<As> & Omit<React.ComponentPropsWithoutRef<As>, 'as'>) {
  const dialog = useDialog();
  const eventProp = Component === Dropdown.Item ? 'onSelect' : 'onClick';
  const handleEvent = useCallback(
    () =>
      dialog.open(
        dialogMessage || <FormattedMessage {...messages.dialogMessage} />,
        {
          runConfirm,
          confirmButtonProps: { variant: 'danger', size: 'lg' },
          modalVariant: 'dark',
          title: dialogTitle,
          confirmLabel: <FormattedMessage {...actionMessages.delete} />,
        },
      ),
    [dialog, dialogMessage, runConfirm, dialogTitle],
  );
  return (
    // @ts-expect-error variant prop doesn't necessarily exist on `as`
    <Component {...{ [eventProp]: handleEvent }} variant="danger" {...props}>
      {buttonText || <FormattedMessage {...actionMessages.delete} />}
    </Component>
  );
}
