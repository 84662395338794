/**
 * @generated SignedSource<<5b9053d85e05582ecba59dc309f84877>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type InterpretationAccuracy = "FALSE_NEGATIVE" | "FALSE_POSITIVE" | "TRUE_NEGATIVE" | "TRUE_POSITIVE" | "%future added value";
export type StandardQaFeedback = "ADJUST_DEPTH" | "ADJUST_GAIN" | "INCOMPLETE_MEASUREMENTS" | "INCORRECT_ORIENTATION" | "INCORRECT_PRESET" | "INCORRECT_TRANSDUCER" | "MISSING_STANDARD_VIEWS" | "NOT_CLINICALLY_INDICATED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type standardQa_study$data = {
  readonly organization: {
    readonly workflowSettings: {
      readonly defaultQaTemplate: {
        readonly latestVersion: {
          readonly id: string;
          readonly title: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"ExamQa_templateVersion" | "qaEntry_templateVersion">;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly qaEntries: ReadonlyArray<{
    readonly id: string;
    readonly templateVersion: {
      readonly id: string;
      readonly title: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"ExamQa_templateVersion" | "qaEntry_templateVersion">;
    } | null;
    readonly values: Record<string, any> | null;
  } | null> | null;
  readonly standardQa: {
    readonly accuracyAsPresented: InterpretationAccuracy | null;
    readonly accuracyComparedToGoldStandard: InterpretationAccuracy | null;
    readonly comments: string | null;
    readonly feedback: ReadonlyArray<StandardQaFeedback | null> | null;
    readonly imageQuality: number | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyReviewStatus" | "standardQa_examTypeProficiency">;
  readonly " $fragmentType": "standardQa_study";
};
export type standardQa_study$key = {
  readonly " $data"?: standardQa_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"standardQa_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "standardQa_study"
};

(node as any).hash = "bca4f44e8af31d310f9a6c947c26fcb5";

export default node;
