/**
 * @generated SignedSource<<34cfb6a6e7414818a13d90056a14a14b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySearchPage_organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SearchBar_organization" | "StudyListContent_organization" | "StudyPagination_search_organization">;
  readonly " $fragmentType": "StudySearchPage_organization";
};
export type StudySearchPage_organization$key = {
  readonly " $data"?: StudySearchPage_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySearchPage_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySearchPage_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBar_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyPagination_search_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3306bfa727834481c98515ce8c30fb42";

export default node;
