/**
 * @generated SignedSource<<fbaa96a2a9596ac797e2e22f0eff8427>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type education_EducationAssignedPage_InfoQuery$variables = {};
export type education_EducationAssignedPage_InfoQuery$data = {
  readonly lms: {
    readonly " $fragmentSpreads": FragmentRefs<"EducationContentBookmarks_lms">;
  } | null;
};
export type education_EducationAssignedPage_InfoQuery = {
  response: education_EducationAssignedPage_InfoQuery$data;
  variables: education_EducationAssignedPage_InfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 2147483647
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "viewerHasCompleted",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "viewerIsAssigned",
    "value": true
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "EducationContentEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContent",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "viewerHasCompleted",
    "value": true
  }
],
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v6 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "viewerHasSaved",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "education_EducationAssignedPage_InfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EducationContentBookmarks_lms"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "education_EducationAssignedPage_InfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": "assigned",
            "args": (v1/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContentConnection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "educationContentConnection(first:2147483647,viewerHasCompleted:false,viewerIsAssigned:true)"
          },
          {
            "alias": "assigned",
            "args": (v1/*: any*/),
            "filters": [
              "viewerIsAssigned",
              "viewerHasCompleted"
            ],
            "handle": "connection",
            "key": "EducationContentBookmarksAssigned_assigned",
            "kind": "LinkedHandle",
            "name": "educationContentConnection"
          },
          {
            "alias": "completed",
            "args": (v4/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContentConnection",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "educationContentConnection(first:2147483647,viewerHasCompleted:true)"
          },
          {
            "alias": "completed",
            "args": (v4/*: any*/),
            "filters": [
              "viewerHasCompleted"
            ],
            "handle": "connection",
            "key": "EducationContentBookmarksAssigned_completed",
            "kind": "LinkedHandle",
            "name": "educationContentConnection"
          },
          {
            "alias": "saved",
            "args": (v6/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContentConnection",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "educationContentConnection(first:2147483647,viewerHasSaved:true)"
          },
          {
            "alias": "saved",
            "args": (v6/*: any*/),
            "filters": [
              "viewerHasSaved"
            ],
            "handle": "connection",
            "key": "EducationContentBookmarksAssigned_saved",
            "kind": "LinkedHandle",
            "name": "educationContentConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4b80eb803eee1c9370ba3606520005d",
    "id": null,
    "metadata": {},
    "name": "education_EducationAssignedPage_InfoQuery",
    "operationKind": "query",
    "text": "query education_EducationAssignedPage_InfoQuery {\n  lms {\n    ...EducationContentBookmarks_lms\n  }\n}\n\nfragment EducationContentBookmarks_lms on Lms {\n  assigned: educationContentConnection(first: 2147483647, viewerIsAssigned: true, viewerHasCompleted: false) {\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  completed: educationContentConnection(first: 2147483647, viewerHasCompleted: true) {\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  saved: educationContentConnection(first: 2147483647, viewerHasSaved: true) {\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "83693c9e66a5000c9265348f3326f66d";

export default node;
