import Layout from '@4c/layout';
import CineIcon from '@bfly/icons/Cine';
import Logo from '@bfly/icons/Logo';
import Link from '@bfly/ui2/Link';
import Popover from '@bfly/ui2/Popover';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import AspectRatioBox from 'components/AspectRatioBox';
import DataGrid from 'components/DataGrid';
import FormattedShortDateTime from 'components/FormattedShortDateTime';
import Thumbnail from 'components/Thumbnail';
import { useExamRoutes } from 'routes/exam';
import { useStudyListNavConfig } from 'utils/StudyListNavUtils';

import { NewStudyDataGridCaptures_study$data as Study } from './__generated__/NewStudyDataGridCaptures_study.graphql';
import { NewStudyDataGridCaptures_studyImages$data as StudyImages } from './__generated__/NewStudyDataGridCaptures_studyImages.graphql';

interface Props {
  study: Study;
  studyImages: StudyImages | null;
  studyCursor?: string;
}

const overlayBox =
  'bg-grey-90 rounded-[2px] p-0.5 absolute flex items-center justify-center text-white';

function NewStudyDataGridCaptures({ study, studyImages, studyCursor }: Props) {
  const examRoutes = useExamRoutes();
  const intl = useIntl();
  const studyListNavConfig = useStudyListNavConfig();

  return (
    <DataGrid.Cell
      align="center"
      data-bni-id="NewStudyDataGridCapturesCell"
      className="relative min-w-min"
    >
      <DataGrid.RowLink
        className="inset-0 absolute"
        title={study.capturedAt ? intl.formatDate(study.capturedAt) : ''}
      />
      {study.capturedAt && (
        <span className="mr-3 lowercase min-w-[15rem]">
          <FormattedShortDateTime value={study.capturedAt!} />
        </span>
      )}
      <Layout pad={2} align="center" className="pointer-events-none w-full">
        {studyImages
          ? studyImages.slice(0, 3).map((studyImage, index) => (
              <Link
                key={`${index + 1}`}
                to={{
                  pathname: examRoutes.examImage({
                    studyHandle: study.handle!,
                    studyImageHandle: studyImage.handle!,
                  }),
                  state: {
                    studyListNavConfig,
                    cursor: studyCursor,
                  },
                }}
              >
                {({ active: _, ...linkProps }) => (
                  <AspectRatioBox className="inline-block w-8 relative">
                    <Popover.Trigger
                      placement="bottom"
                      popover={
                        <div>
                          {/* // XXX: dimensions must match the thumbnail maxWidth */}
                          <div className="-mx-2 w-[14rem] h-[14rem] block rounded relative">
                            <Thumbnail
                              maxWidth={140}
                              alt={`Study Capture ${index + 1}`}
                              className="w-full h-full object-cover rounded"
                              key={studyImage.handle}
                              image={studyImage.burnedInFile!}
                            />
                            {studyImage.burnedInFile!.isVideo && (
                              <div
                                className={`${overlayBox} h-5 w-5 bottom-1 right-1`}
                              >
                                <CineIcon width={14} height={8} />
                              </div>
                            )}
                          </div>
                          {studyImage.iqMetadata?.probeSerial && (
                            <Text className="flex items-center justify-center mt-1">
                              <span>{studyImage.iqMetadata?.probeSerial}</span>
                              <Logo
                                className="h-3 w-3 ml-2"
                                css={css`
                                  path {
                                    fill: currentColor !important;
                                  }
                                `}
                              />
                            </Text>
                          )}
                        </div>
                      }
                    >
                      <a
                        {...linkProps}
                        data-bni-id="NewStudyDataGridCapturesThumnail"
                        className="pointer-events-auto border border-white/20 rounded block w-full h-full relative"
                      >
                        <Thumbnail
                          loading="lazy"
                          // XXX: dimensions must match the thumbnail maxWidth
                          maxWidth={50}
                          alt={`Study Capture ${index + 1}`}
                          className="w-full h-full object-cover rounded"
                          key={studyImage.handle}
                          image={studyImage.burnedInFile!}
                        />
                        {studyImage.burnedInFile!.isVideo && (
                          <div
                            className={`${overlayBox} w-[1rem] h-[1rem] bottom-0.5 right-0.5`}
                          >
                            <CineIcon width={6} height={3} />
                          </div>
                        )}
                      </a>
                    </Popover.Trigger>
                  </AspectRatioBox>
                )}
              </Link>
            ))
          : Array.from({ length: Math.min(study.numImages!, 3) }, (_, i) => (
              <span
                key={i}
                className="bg-grey-85 inline-block w-8 h-8 animate-pulse rounded"
              />
            ))}
        {study.numImages! > 3 && <Text>+{study.numImages! - 3}</Text>}
      </Layout>
    </DataGrid.Cell>
  );
}

export const NewStudyDataGridCapturesLabel = () => (
  <FormattedMessage
    id="NewstudyDataGridCaptures.label"
    defaultMessage="Captured"
  />
);

export default createFragmentContainer(NewStudyDataGridCaptures, {
  studyImages: graphql`
    fragment NewStudyDataGridCaptures_studyImages on StudyImage
    @relay(plural: true) {
      handle
      burnedInFile {
        isVideo
        ...Thumbnail_image
      }
      iqMetadata {
        probeSerial
      }
    }
  `,
  study: graphql`
    fragment NewStudyDataGridCaptures_study on Study {
      handle
      numImages
      capturedAt
    }
  `,
});
