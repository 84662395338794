/**
 * @generated SignedSource<<7127768b96122ea5402dec7eebb5bf7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBar_archive$data = {
  readonly handle: string | null;
  readonly label: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarList_archive" | "SearchBarTags_archive">;
  readonly " $fragmentType": "SearchBar_archive";
};
export type SearchBar_archive$key = {
  readonly " $data"?: SearchBar_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBar_archive">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBar_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "SearchBarTags_archive",
      "selections": (v1/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "SearchBarList_archive",
      "selections": (v1/*: any*/),
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Archive",
  "abstractKey": null
};
})();

(node as any).hash = "179aa3c61a5422cef93e2de509487835";

export default node;
