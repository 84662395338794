import Layout from '@4c/layout';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from 'components/Avatar';

import { RelatedUsersPopoverListItems_users$data as Users } from './__generated__/RelatedUsersPopoverListItems_users.graphql';

const unassigned = (
  <FormattedMessage
    id="relatedUsersPopoverListItems.unassigned"
    defaultMessage="Unassigned"
  />
);

const noPrimaryAuthor = (
  <FormattedMessage
    id="relatedUsersPopoverListItems.noPrimaryAuthor"
    defaultMessage="No primary author"
  />
);

function renderSubtitle(
  user: Users[number],
  createdBy: string | undefined | null,
  transcribedBy: string | undefined | null,
) {
  // we pass in a null study in cases when we don't want to show subtitles (StudyRequestAttestationRecipients for example)
  if (!user) return null;

  if (!user) {
    return noPrimaryAuthor;
  }

  if (user.id === createdBy)
    return (
      <FormattedMessage
        id="relatedUsersPopoverListItems.primary"
        defaultMessage="Primary author"
      />
    );

  if (user.id === transcribedBy)
    return (
      <FormattedMessage
        id="relatedUsersPopoverListItems.scribe"
        defaultMessage="Scribe"
      />
    );

  return null;
}

interface Props {
  users: Users;
  createdBy: string | undefined | null;
  transcribedBy: string | undefined | null;
}

function RelatedUsersPopoverListItems({
  users,
  createdBy,
  transcribedBy,
}: Props) {
  const primaryAuthor = users.find((u) => u?.id === createdBy);
  const scribe = users.find((u) => u?.id === transcribedBy);
  const secondaryAuthors = users.filter(
    (u) => u?.id !== transcribedBy && u?.id !== createdBy,
  );

  return (
    <>
      {!primaryAuthor && (
        <Layout as="li" pad align="center">
          <Avatar width={20} userProfile={null} />
          <div>
            <div>{unassigned}</div>

            <Text variant="xs" color="subtitle">
              {noPrimaryAuthor}
            </Text>
          </div>
        </Layout>
      )}
      {[primaryAuthor, ...secondaryAuthors, scribe].map((user) => {
        if (!user) return null;

        const subtitle = renderSubtitle(user, createdBy, transcribedBy);

        return (
          <Layout as="li" pad key={user!.id} align="center">
            <Avatar width={20} userProfile={user!} />
            <div>
              <div>{user?.name || unassigned}</div>
              {subtitle && (
                <Text variant="xs" color="subtitle">
                  {subtitle}
                </Text>
              )}
            </div>
          </Layout>
        );
      })}
    </>
  );
}

export default createFragmentContainer(RelatedUsersPopoverListItems, {
  users: graphql`
    fragment RelatedUsersPopoverListItems_users on UserProfile
    @relay(plural: true) {
      id
      name
      ...AvatarCollage_userProfiles
      ...Avatar_userProfile
    }
  `,
});
