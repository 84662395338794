import useMountEffect from '@restart/hooks/useMountEffect';
import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { object, string } from 'yup';

import AuthContent from 'components/AuthContent';
import { useAuth } from 'components/AuthContext';
import AuthForm from 'components/AuthForm';
import accountMessages from 'messages/account';
import accountRouteUrls from 'routes/account';
import submitLogin from 'utils/login';

import { AuthType } from '../../../utils/auditLogs';
import passwordMessages from '../messages/password';
import AutofillHackInput from './AutofillHackInput';
import type { UserSignUpPageMutation } from './__generated__/UserSignUpPageMutation.graphql';
import type { UserSignUpPage_inviteInfo$data as InviteInfo } from './__generated__/UserSignUpPage_inviteInfo.graphql';

const messages: Record<string, any> = defineMessages({
  missingEmail: {
    id: 'userSignUpPage.missingEmail',
    defaultMessage: 'Please enter an email',
  },
  createPassword: {
    id: 'userSignUpPage.createPassword',
    defaultMessage: 'Create Password',
  },
});

const mutation = graphql`
  mutation UserSignUpPageMutation($input: RegisterDomainUserInput!) {
    registerDomainUserOrError(input: $input) {
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

const signupSchema = object({
  email: string().email().required(messages.missingEmail).default(''),
  password: string()
    .required(passwordMessages.required as any)
    .min(8, passwordMessages.length as any),
});

interface Props {
  inviteInfo: InviteInfo;
}

function UserSignUpPage({ inviteInfo }: Props) {
  const auth = useAuth();
  const { match, router } = useRouter();
  const { location } = match;

  useMountEffect(() => {
    if (auth.isAuthenticated()) {
      auth.clearAccessToken();
    }
  });

  const onCompleted = async ({ formValue }) => {
    let domainHandle: string | null = null;
    try {
      domainHandle = inviteInfo!.domainProfile!.handle!;
    } catch (e) {
      // simply ignore undefined error
    }

    // If signup is successful, try to log in.
    try {
      const { email, password } = formValue;
      const accessToken = await submitLogin({ email, password, domainHandle });
      await auth.setAccessToken(accessToken, {
        trackLogin: true,
        authType: AuthType.AUTH0,
      });
    } catch (e) {
      // The root redirect to user setup won't work, but we'll at least show
      // either the login page or the account created page.
    }
    router.replace(accountRouteUrls.accountCreated());
  };

  // let the hook run
  if (auth.isAuthenticated()) {
    return null;
  }

  return (
    <AuthContent>
      <AuthContent.Title>
        <FormattedMessage
          id="userSignUpPage.title"
          defaultMessage="Complete Sign Up"
        />
      </AuthContent.Title>
      <AuthForm<UserSignUpPageMutation, typeof signupSchema>
        schema={signupSchema}
        defaultValue={{
          email: inviteInfo.email!,
          password: '',
        }}
        getInput={(input) => {
          return {
            token: location.query.token,
            password: input.password,
          };
        }}
        mutation={mutation}
        onCompleted={onCompleted}
      >
        <AutofillHackInput />
        <AuthForm.FieldGroup
          readOnly
          name="email"
          type="email"
          label={accountMessages.email}
        />
        <AuthForm.FieldGroup
          name="password"
          type="password"
          label={messages.createPassword}
          help={passwordMessages.help}
          placeholder={messages.createPassword}
        />
        <AuthForm.Submit>
          <FormattedMessage
            id="userSignUpPage.submit"
            defaultMessage="Create an Account"
          />
        </AuthForm.Submit>
      </AuthForm>
    </AuthContent>
  );
}

export default createFragmentContainer(UserSignUpPage, {
  inviteInfo: graphql`
    fragment UserSignUpPage_inviteInfo on UserInviteInfo {
      email
      domainProfile {
        handle
        federatedIdentityProvider {
          __typename
        }
      }
    }
  `,
});
