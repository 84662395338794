import Link from '@bfly/ui2/Link';
import PreviewItem from '@bfly/ui2/PreviewItem';
import { createFragmentContainer, graphql } from 'react-relay';

import ImagePreviewItem from 'components/ImagePreviewItem';
import PreviewList from 'components/PreviewList';
import useExperiences, { Experience } from 'hooks/useExperiences';
import { useExamRoutes } from 'routes/exam';
import type { StudyListNavConfig } from 'utils/StudyListNavUtils';

import type { StudyImagePreviewList_study$data as Study } from './__generated__/StudyImagePreviewList_study.graphql';
import { StudyImagePreviewList_studyImages$data as StudyImages } from './__generated__/StudyImagePreviewList_studyImages.graphql';

const MAX_PREVIEWS = 6;

interface Props {
  study: Study;
  studyImages: StudyImages | null;
  studyListNavConfig: StudyListNavConfig;
  studyCursor?: string;
}

function StudyImagePreviewList({
  study,
  studyImages,
  studyListNavConfig,
  studyCursor,
}: Props) {
  const examRoutes = useExamRoutes();
  const { numImages } = study;

  const { markCompleted } = useExperiences(Experience.VIEW_STUDY_DETAIL);

  return (
    <PreviewList
      numItems={numImages!}
      maxPreviews={MAX_PREVIEWS}
      overflowProps={{
        as: Link,
        to: {
          pathname: examRoutes.examGallery({
            organizationSlug: study.organization!.slug!,
            studyHandle: study.handle!,
          }),
          state: { studyListNavConfig, cursor: studyCursor },
        },
      }}
    >
      {studyImages
        ? studyImages.map((image, index) => (
            <ImagePreviewItem
              key={image.handle!}
              study={study}
              studyCursor={studyCursor}
              image={image}
              index={index}
              studyListNavConfig={studyListNavConfig}
              onClick={markCompleted}
            />
          ))
        : Array.from({ length: numImages! }, (_, i) => (
            <PreviewItem.Placeholder key={i} className="animate-pulse" />
          ))}
    </PreviewList>
  );
}

export default createFragmentContainer(StudyImagePreviewList, {
  study: graphql`
    fragment StudyImagePreviewList_study on Study {
      handle
      organization {
        slug
      }

      numImages
      ...ImagePreviewItem_study
    }
  `,

  studyImages: graphql`
    fragment StudyImagePreviewList_studyImages on StudyImage
    @relay(plural: true) {
      handle
      ...ImagePreviewItem_image
    }
  `,
});
