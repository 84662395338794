/**
 * @generated SignedSource<<2d62c4149adc9e12dd89e95a0bb554b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PatientInfo_patient$data = {
  readonly birthDate: string | null;
  readonly medicalRecordNumber: string | null;
  readonly sex: PatientSex | null;
  readonly " $fragmentType": "PatientInfo_patient";
};
export type PatientInfo_patient$key = {
  readonly " $data"?: PatientInfo_patient$data;
  readonly " $fragmentSpreads": FragmentRefs<"PatientInfo_patient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PatientInfo_patient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "medicalRecordNumber",
      "storageKey": null
    }
  ],
  "type": "PatientInterface",
  "abstractKey": "__isPatientInterface"
};

(node as any).hash = "1da2b45718dc3e0e7986f78d068a5777";

export default node;
