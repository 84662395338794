import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import FormCheck from '@bfly/ui2/FormCheck';
import Modal from '@bfly/ui2/Modal';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  RelayProp,
  createFragmentContainer,
  fetchQuery,
  graphql,
} from 'react-relay';

import useToggler from 'hooks/useToggler';
import actionMessages from 'messages/actions';
import downloadFile from 'utils/downloadFile';
import withModal from 'utils/withModal';

import { StudyImageDownloadModal_DicomUrlQuery as DicomUrlQuery } from './__generated__/StudyImageDownloadModal_DicomUrlQuery.graphql';
import { StudyImageDownloadModal_image$data as Image } from './__generated__/StudyImageDownloadModal_image.graphql';

interface Props {
  image: Image;
  onHide: () => void;
  relay: RelayProp;
}

function StudyImageDownloadModal({ image, onHide, relay }: Props) {
  const { dataUrl, isVideo } = image.burnedInFile!;
  const [willFetchDicomFile, toggleWillFetchDicomFile] = useToggler();

  async function handleDownload() {
    let downloadUrl;

    if (!willFetchDicomFile) {
      downloadUrl = dataUrl!;
    } else {
      const result = await fetchQuery<DicomUrlQuery>(
        relay.environment,
        graphql`
          query StudyImageDownloadModal_DicomUrlQuery($imageId: ID!) {
            image: node(id: $imageId) {
              ... on StudyImage {
                dicomUrl
              }
            }
          }
        `,
        { imageId: image.id },
      ).toPromise();
      downloadUrl = result!.image!.dicomUrl!;
    }

    await downloadFile(downloadUrl);
    onHide();
  }

  return (
    <Form noValidate submitForm={handleDownload}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="studyImageDownloadModal.title"
            defaultMessage="Download {imageType}"
            values={{
              imageType: isVideo ? (
                <FormattedMessage
                  id="studyImageDownloadModal.video"
                  defaultMessage="Video"
                />
              ) : (
                <FormattedMessage
                  id="studyImageDownloadModal.image"
                  defaultMessage="Image"
                />
              ),
            }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <FormattedMessage
            id="studyImageDownloadModal.prompt"
            defaultMessage="Please select the download format:"
          />
        </p>
        <Layout pad={4}>
          <FormCheck
            checked={!willFetchDicomFile}
            inline
            name="source"
            onChange={() => toggleWillFetchDicomFile()}
            type="radio"
          >
            {isVideo ? (
              <FormattedMessage
                id="studyImageDownloadModal.mp4"
                defaultMessage="MP4"
              />
            ) : (
              // FIXME: Is this really always a PNG?
              <FormattedMessage
                id="studyImageDownloadModal.png"
                defaultMessage="PNG"
              />
            )}
          </FormCheck>
          <FormCheck
            checked={willFetchDicomFile}
            inline
            name="dicom"
            onChange={() => toggleWillFetchDicomFile()}
            type="radio"
          >
            <FormattedMessage
              id="studyImageDownloadModal.dicom"
              defaultMessage="DICOM"
            />
          </FormCheck>
        </Layout>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Form.Submit size="lg">
            <FormattedMessage {...actionMessages.download} />
          </Form.Submit>

          <Button size="lg" variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </Form>
  );
}

export default createFragmentContainer(withModal(StudyImageDownloadModal), {
  image: graphql`
    fragment StudyImageDownloadModal_image on StudyImage {
      id
      burnedInFile {
        dataUrl
        isVideo
      }
    }
  `,
});
