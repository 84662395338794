/**
 * @generated SignedSource<<cf99699e2dbfc62c2acd1f70cc7077f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalStudySearchList_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchList_studies" | "StudyListContent_domain" | "StudyListContent_organization" | "getConcreteTenant_tenant">;
  readonly " $fragmentType": "GlobalStudySearchList_tenant";
};
export type GlobalStudySearchList_tenant$key = {
  readonly " $data"?: GlobalStudySearchList_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchList_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": "type",
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalStudySearchList_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GlobalStudySearchList_studies"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_domain"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getConcreteTenant_tenant",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Domain",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Organization",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};
})();

(node as any).hash = "255fd64e5e065741b6c987a158208534";

export default node;
