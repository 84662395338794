/**
 * @generated SignedSource<<34c2f3de421f5e6617e37575dbc06c9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyHistoryModalContent_imageAddedStudyEvent$data = {
  readonly image: {
    readonly capturedAt: string | null;
  } | null;
  readonly " $fragmentType": "StudyHistoryModalContent_imageAddedStudyEvent";
};
export type StudyHistoryModalContent_imageAddedStudyEvent$key = {
  readonly " $data"?: StudyHistoryModalContent_imageAddedStudyEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyHistoryModalContent_imageAddedStudyEvent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyHistoryModalContent_imageAddedStudyEvent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyImage",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capturedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ImageAddedStudyEvent",
  "abstractKey": null
};

(node as any).hash = "c60df5b9f839047dbe0d3e3d6b9f424b";

export default node;
