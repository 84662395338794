/**
 * @generated SignedSource<<1874278293c4838be7036e1bf8c6c4f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImagePreviewItem_study$data = {
  readonly archive: {
    readonly handle: string | null;
  } | null;
  readonly handle: string | null;
  readonly organization: {
    readonly slug: string | null;
  } | null;
  readonly " $fragmentType": "ImagePreviewItem_study";
};
export type ImagePreviewItem_study$key = {
  readonly " $data"?: ImagePreviewItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImagePreviewItem_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImagePreviewItem_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "5eddb400ef4bd450a70e32ad62a72db6";

export default node;
