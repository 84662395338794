import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import useQuery from '@bfly/ui2/useQuery';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import useRouter from 'found/useRouter';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { array, object, string } from 'yup';

import RelayForm from 'components/RelayForm';
import { useExamRoutes } from 'routes/exam';
import withModal from 'utils/withModal';

import DeleteCapturesConfirmDelete from './DeleteCapturesConfirmDelete';
import DeleteCapturesModalContent from './DeleteCapturesModalContent';
import { DeleteCapturesModalContentQuery as ContentQuery } from './__generated__/DeleteCapturesModalContentQuery.graphql';
import { DeleteCapturesModal_Mutation as Mutation } from './__generated__/DeleteCapturesModal_Mutation.graphql';
import { DeleteCapturesModal_study$data as Study } from './__generated__/DeleteCapturesModal_study.graphql';

const mutation = graphql`
  mutation DeleteCapturesModal_Mutation($input: BulkDeleteCapturesInput!) {
    bulkDeleteCapturesOrError(input: $input) {
      ...RelayForm_error @relay(mask: false)
      ... on BulkDeleteCapturesPayload {
        study {
          numImages
          handle

          organization {
            slug
          }
          imageConnection(first: 2147483647) {
            edges {
              node {
                handle
              }
            }
          }
        }
      }
    }
  }
`;

const schema = object({
  imageIds: array()
    .of(string().required())
    .default(() => [])
    .test(
      'all-selected',
      defineMessage({
        id: 'deleteStudyCaptureSelector.warning',
        defaultMessage: 'You cannot remove all the captures within a study.',
      }),
      (images, testContext) => {
        return images.length !== testContext.options.context.numCaptures;
      },
    )
    .min(1),
});

interface Props {
  study: Study;
  onHide: () => void;
}

function DeleteCapturesModal({ study, onHide }: Props) {
  const examRoutes = useExamRoutes();
  const { router, match } = useRouter();

  const [value, setValue] = useState(schema.getDefault());

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const { data: studyData } = useQuery<ContentQuery>(
    graphql`
      query DeleteCapturesModalContentQuery($id: ID!) {
        node(id: $id) {
          ... on Study {
            numImages
            ...DeleteCapturesModalContent_study
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: { id: study.id },
    },
  );
  // May need to redirect if the image we are on is deleted
  const onCompleted = useCallback(
    (mutationData: Mutation['response']) => {
      const mutationStudy = mutationData!.bulkDeleteCapturesOrError!.study;
      const { organization } = mutationStudy!;

      if (match.params.studyHandle) {
        router.replace(
          examRoutes.examGallery({
            organizationSlug: organization!.slug!,
            studyHandle: mutationStudy!.handle!,
          }),
        );
      }

      onHide();
    },
    [match.params.studyHandle, onHide, router, examRoutes],
  );

  if (!studyData) {
    return (
      <span>
        <LoadingIndicator />
      </span>
    );
  }

  return (
    <RelayForm<Mutation, typeof schema>
      mutation={mutation}
      schema={schema}
      getInput={({ imageIds }) => {
        return {
          studyId: study.id,
          imageIds,
        };
      }}
      formContext={{ numCaptures: studyData!.node!.numImages }}
      value={value}
      onChange={setValue}
      onCompleted={onCompleted}
      updater={(store) =>
        value.imageIds.forEach((imageId) =>
          rangeDeleteUpdater(store, {
            parentId: study.id!,
            connections: [
              { connectionKey: 'StudyImagePreviewList_imageConnection' },
              { connectionKey: 'StudyImagePage_imageConnection' },
            ],
            deletedId: imageId,
          }),
        )
      }
      successMessage={
        <FormattedMessage
          id="editCapturesModal.success"
          defaultMessage="{numCaptures} {numCaptures, plural, =1 {capture} other {captures}} deleted"
          values={{ numCaptures: value.imageIds.length }}
        />
      }
    >
      {showConfirmDelete ? (
        // TODO: this should be a dialog - add functionality to make modals stackable
        <DeleteCapturesConfirmDelete
          setShowConfirmDelete={setShowConfirmDelete}
          formValue={value}
        />
      ) : (
        <DeleteCapturesModalContent
          onCancel={onHide}
          setShowConfirmDelete={setShowConfirmDelete}
          formValue={value}
          study={studyData.node!}
        />
      )}
    </RelayForm>
  );
}

export default createFragmentContainer(withModal(DeleteCapturesModal), {
  study: graphql`
    fragment DeleteCapturesModal_study on Study {
      id
    }
  `,
});
