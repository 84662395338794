import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import './style.scss';
import Layout from '@4c/layout';
import { useToast } from '@bfly/ui2/ToastContext';
import useDialog from '@bfly/ui2/useDialog';
import { arrayMove } from '@dnd-kit/sortable';
import {
  CellEditingStoppedEvent,
  ColDef,
  ColumnApi,
  ColumnResizedEvent,
  ModelUpdatedEvent,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import useRouter from 'found/useRouter';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUncontrolledProp } from 'uncontrollable';

import {
  Column,
  ColumnDef,
  ColumnState,
  SelectableColumnProps,
  TDataBase,
  isColumnGroup,
} from 'components/AgGrid/types';
import LoadingIndicatorLine from 'components/LoadingIndicatorLine';
import useMountMemo from 'hooks/useMountMemo';
import useObserver from 'hooks/useObserver';
import usePreferences from 'hooks/usePreferences';
import hasParentThatMatches from 'utils/hasParentThatMatches';

import ColumnPicker from './ColumnPicker';
import EditControls from './EditControls';
import defaultMessages, { Messages } from './messages';
import useGridEvents, { useAttachGridEvents } from './utils/gridEvents';
import setupColumns from './utils/setupColumns';
import useEditConfirmationModal from './utils/useEditConfirmationModal';

export { defaultMessages };
export type { Messages };

LicenseManager.setLicenseKey(globalThis.bflyConfig.AG_GRID_LICENSE);

const SELECTABLE_COLUMN_PROPS: SelectableColumnProps = {
  checkboxSelection: true,
  headerCheckboxSelection: true,
  showDisabledCheckboxes: true,
  headerCheckboxSelectionFilteredOnly: true,
  pinned: 'left',
} as const;

const UNSELECTABLE_COLUMN_PROPS: SelectableColumnProps = {
  checkboxSelection: undefined,
  headerCheckboxSelection: undefined,
  showDisabledCheckboxes: undefined,
  headerCheckboxSelectionFilteredOnly: undefined,
  pinned: undefined,
} as const;

const AG_GRID_DEFAULTS = {
  defaultColDef: (defaults?: Partial<ColDef>) => ({
    // defaults
    resizable: true,
    sortable: true,
    ...defaults,
    // overrides
    suppressMenu: true,
    suppressMovable: true,
  }),
  getContextMenuItems: () => ['autoSizeAll', 'copy', 'copyWithHeaders'],
} as const;

export type SaveState<TData = TDataBase> = {
  changes: TData[];
  success: (count: number) => void;
  error: () => void;
  cancelled: () => void;
};

export type SaveStateUpdate = (state: SaveState) => void;

function verifyNextState(
  nextState: ColumnState[],
  columns: ColumnDef[],
): ColumnState[] {
  const flatColumns = columns.flatMap((col) =>
    isColumnGroup(col) ? col.children : [col],
  );

  const hasValidNextState =
    Array.isArray(nextState) &&
    nextState?.every((nextCol) =>
      flatColumns.some((col) => col?.colId === nextCol?.colId),
    ) &&
    nextState.length <= flatColumns.length;

  return hasValidNextState
    ? nextState
    : // default columns state
      columns.flatMap((c): ColumnState[] =>
        (isColumnGroup(c) ? c.children : [c]).map(
          ({ colId, hide, width, pinned }) => ({ colId, hide, width, pinned }),
        ),
      );
}

export type Props = Pick<AgGridReactProps, 'rowData' | 'defaultColDef'> & {
  /** when readonly a confirmation dialog will appear if a column is editable  */
  messages?: Messages;
  columns: ColumnDef[];
  id: string;
  columnPicker?: boolean | { canHide: boolean; canOrder: boolean };
  loading?: boolean;
  showResetColumnState?: boolean;
  readOnly?: boolean;
  onSaveStateUpdate: SaveStateUpdate;
  selectable?: boolean;
  confirmDiscard?: boolean;
};

export default function AgGrid({
  messages = defaultMessages,
  rowData: rowDataOriginal,
  columns: columnsProp,
  id,
  columnPicker = true,
  loading: loadingProp,
  showResetColumnState,
  defaultColDef,
  readOnly: readOnlyProp,
  onSaveStateUpdate,
  selectable = false,
  confirmDiscard = false,
}: Props) {
  const { formatMessage } = useIntl();
  const { router } = useRouter();
  const dialog = useDialog();
  const [changes, setChanges] = useState<TDataBase[] | undefined>();
  const [loading, setLoading] = useUncontrolledProp(
    !!loadingProp,
    true,
    (nextLoading) => !loadingProp && !nextLoading,
  );
  const columnApi = useRef<ColumnApi>();
  const toast = useToast();

  const rowData = useMemo(
    () => rowDataOriginal?.map((row) => ({ ...row })),
    [rowDataOriginal],
  );

  const { handleSelectionChanged, handleReadOnlyChange, setGridApi } =
    useAttachGridEvents();

  const { closePopupMenu } = useGridEvents();

  const [readOnly, setReadOnly] = useUncontrolledProp(
    readOnlyProp,
    readOnlyProp,
    handleReadOnlyChange,
  );

  const editConfirmationModal = useEditConfirmationModal(messages);

  const launchEditConfirmationModal = () =>
    editConfirmationModal().then((confirmed) => {
      if (!confirmed) return;
      setReadOnly(false);
    });

  const [
    columnsState,
    setColumnsState,
    loadingColumnsState,
    clearColumnsState,
  ] = usePreferences<ColumnState[]>(`${id}ColumnState`, (next) =>
    verifyNextState(next, columnsProp),
  );

  const columnsOriginal = useMountMemo(() =>
    setupColumns(columnsProp, columnsState),
  );

  const isEditable = useMemo(
    () =>
      columnsOriginal.some((c) =>
        isColumnGroup(c) ? c.children.some((ch) => ch.editable) : c.editable,
      ),
    [columnsOriginal],
  );

  const columns = useMemo(() => {
    const firstColumnId = columnsState[0].colId;

    const updateColumn = (col: Column): Column => ({
      ...col,
      // add or remove selectable props
      ...(selectable && firstColumnId === col.colId
        ? SELECTABLE_COLUMN_PROPS
        : UNSELECTABLE_COLUMN_PROPS),
      editable: !loading && !readOnly && col.editable,
    });

    return columnsOriginal.map((col) =>
      isColumnGroup(col)
        ? {
            ...col,
            children: col.children.map(updateColumn),
          }
        : updateColumn(col),
    );
  }, [columnsOriginal, columnsState, loading, readOnly, selectable]);

  useEffect(() => {
    if (!columnsState || !columnApi.current) return;
    columnApi.current.applyColumnState({
      applyOrder: true,
      state: columnsState,
    });
    setLoading(false);
  }, [setLoading, columnsState]);

  const handleReset = () => {
    if (!confirmDiscard) {
      setChanges(undefined);
      return;
    }

    dialog
      .open(<FormattedMessage {...messages.confirmDiscardDialogContent} />, {
        title: <FormattedMessage {...messages.confirmDiscardDialogTitle} />,
        confirmLabel: (
          <FormattedMessage {...messages.confirmDiscardDialogConfirmLabel} />
        ),
        cancelLabel: (
          <FormattedMessage {...messages.confirmDiscardDialogCancelLabel} />
        ),
        confirmButtonProps: {
          variant: 'danger',
          size: 'lg',
        },
        modalVariant: 'dark',
        size: 'sm',
      })
      .then((confirmed) => {
        if (confirmed) setChanges(undefined);
      });
  };

  const handleSave = () => {
    if (!changes?.length) return;
    setLoading(true);
    onSaveStateUpdate({
      changes,
      success: (count) => {
        toast?.success(
          <FormattedMessage {...messages.saveSuccess} values={{ count }} />,
        );
        setChanges(undefined);
        setLoading(false);
      },
      error: () => {
        toast?.error(<FormattedMessage {...messages.saveError} />);
        setLoading(false);
      },
      cancelled: () => {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    const removeNavigationListener = router.addNavigationListener(
      (location) => {
        if (!changes) {
          return undefined;
        }
        if (!location) {
          return false;
        }

        return dialog
          .open(<FormattedMessage {...messages.confirmLeaveDialogContent} />, {
            title: <FormattedMessage {...messages.confirmLeaveDialogTitle} />,
            confirmLabel: (
              <FormattedMessage {...messages.confirmLeaveDialogConfirmLabel} />
            ),
            modalVariant: 'dark',
            size: 'sm',
          })
          .then((confirmed) => {
            if (confirmed) {
              removeNavigationListener();
            }
            return confirmed;
          });
      },
      { beforeUnload: true },
    );

    return removeNavigationListener;
  }, [dialog, changes, router, messages]);

  const handleCellEditingStopped = useCallback(
    (events: CellEditingStoppedEvent) => {
      const {
        newValue,
        oldValue,
        colDef,
        data: { id: rowId },
      } = events;
      const field = colDef.field!;
      const originalRow = rowDataOriginal!.find((r) => r.id === rowId)!;

      if (newValue === undefined || newValue === oldValue) return;

      setChanges((prev = []) => {
        const prevIndex = prev.findIndex((c) => c.id === rowId);

        const isOriginalValue = originalRow[field] === newValue;

        // an existing change row does not exist
        if (prevIndex === -1) {
          if (isOriginalValue) return [...prev];
          return [...prev, { id: rowId, [field]: newValue }];
        }

        const prevWithoutRowToEdit = prev.filter(
          (_, index) => index !== prevIndex,
        );
        const rowWithChanges = { ...prev[prevIndex], [field]: newValue };

        // check if we are setting back to original value
        if (isOriginalValue) {
          // has only one field change and it's this field
          if (
            prev[prevIndex][field] &&
            Object.keys(prev[prevIndex]).length > 2
          )
            return prevWithoutRowToEdit;

          delete rowWithChanges[field];
        }

        return [...prevWithoutRowToEdit, rowWithChanges];
      });
    },
    [rowDataOriginal],
  );

  const handleEditAttempt = (target: EventTarget) => {
    if (
      readOnly &&
      // we clicked on an editable cell
      hasParentThatMatches(
        target as Element,
        '.ag-cell-not-inline-editing',
        '.ag-cell-value',
      )
    )
      launchEditConfirmationModal();
  };

  const ref = useRef<HTMLDivElement | null>(null);

  const mountPopupObserver = useObserver(
    () => ref.current?.querySelector('.ag-root-wrapper'),
    (data) => {
      if ((data[0].removedNodes[0] as HTMLElement)?.matches('.ag-popup'))
        closePopupMenu();
    },
    { childList: true },
  );

  const handleColumnResized = (event: ColumnResizedEvent) => {
    if (!event.finished) return;

    if (event.source === 'autosizeColumns') {
      const columnWidths: Record<string, number> =
        event.columns?.reduce(
          (widths, { getColId, getActualWidth }) => ({
            ...widths,
            [getColId()]: getActualWidth(),
          }),
          {},
        ) || {};

      setColumnsState((prev) =>
        prev.map(
          ({ colId, hide, width }: ColumnState): ColumnState => ({
            colId,
            hide,
            width: columnWidths[colId] || width,
          }),
        ),
      );

      return;
    }

    const colId = event.column?.getColId();
    const width = event.column?.getActualWidth();

    setColumnsState((prev) =>
      prev.map(
        (c): ColumnState => ({
          colId: c.colId,
          hide: c.hide,
          width: c.colId === colId ? width : c.width,
        }),
      ),
    );
  };

  return (
    <Layout direction="column" className="flex-grow relative">
      {loading && <LoadingIndicatorLine className="z-10 -top-[2px]" />}
      <div
        ref={ref}
        id={id}
        data-bni-id={id}
        className="ag-theme-alpine-dark ag-theme-bfly relative flex-grow"
        onDoubleClick={(e) => handleEditAttempt(e.target)}
        onKeyUp={(e) => handleEditAttempt(e.target)}
      >
        <AgGridReact
          rowData={rowData}
          defaultColDef={AG_GRID_DEFAULTS.defaultColDef(defaultColDef)}
          onSelectionChanged={(event) => {
            if (!selectable) return;
            handleSelectionChanged(event.api.getSelectedRows());
          }}
          debounceVerticalScrollbar
          enableRangeSelection
          rowSelection="multiple"
          suppressRowClickSelection
          stopEditingWhenCellsLoseFocus
          overlayNoRowsTemplate={`<span class="text-lg">${formatMessage(
            messages.noResults,
          )}</span>`}
          onModelUpdated={(event: ModelUpdatedEvent) => {
            return event.api.getDisplayedRowCount() === 0
              ? event.api.showNoRowsOverlay()
              : event.api.hideOverlay();
          }}
          onCellEditingStopped={handleCellEditingStopped}
          onCellValueChanged={handleCellEditingStopped}
          getContextMenuItems={AG_GRID_DEFAULTS.getContextMenuItems}
          onGridReady={(e) => {
            columnApi.current = e.columnApi;
            setGridApi(e.api);
            mountPopupObserver();
          }}
          onColumnResized={handleColumnResized}
          onColumnMoved={(event) => {
            if (!event.toIndex) return;
            setColumnsState((prev) => {
              return arrayMove(
                prev,
                prev.findIndex((c) => c.colId === event.column?.getColId()),
                event.toIndex!,
              );
            });
          }}
          columnDefs={columns}
        />
      </div>
      {columnPicker && (
        <ColumnPicker
          loadingState={loadingColumnsState}
          columns={columns}
          state={columnsState}
          setState={setColumnsState}
          resetState={() => clearColumnsState()}
          canHide={columnPicker === true || columnPicker.canHide}
          canOrder={columnPicker === true || columnPicker.canOrder}
          showResetState={!!showResetColumnState}
        />
      )}
      {isEditable && !readOnly && (
        <EditControls
          changes={changes}
          handleReset={handleReset}
          handleSave={handleSave}
          loading={loading}
          messages={messages}
        />
      )}
    </Layout>
  );
}
