/**
 * @generated SignedSource<<404e26910e04ebb55edf9e5bb4650a0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VetClientDescriptionList_vetPatient$data = {
  readonly clientOrganizationName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"VetClientName_vetPatient">;
  readonly " $fragmentType": "VetClientDescriptionList_vetPatient";
};
export type VetClientDescriptionList_vetPatient$key = {
  readonly " $data"?: VetClientDescriptionList_vetPatient$data;
  readonly " $fragmentSpreads": FragmentRefs<"VetClientDescriptionList_vetPatient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VetClientDescriptionList_vetPatient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientOrganizationName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VetClientName_vetPatient"
    }
  ],
  "type": "VetPatient",
  "abstractKey": null
};

(node as any).hash = "354b7c9cda575dfd6a88dade772f28b6";

export default node;
