import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import React from 'react';
import {
  QueryRenderer,
  RelayProp,
  createFragmentContainer,
  graphql,
} from 'react-relay';

import ServerLogListItem, { JobType } from 'components/ServerLogListItem';

import { ThirdPartyUltrasoundLogListItemContentQuery as ContentQuery } from './__generated__/ThirdPartyUltrasoundLogListItemContentQuery.graphql';
import { ThirdPartyUltrasoundLogListItem_log$data as Log } from './__generated__/ThirdPartyUltrasoundLogListItem_log.graphql';

interface Props {
  log: Log;
  relay: RelayProp;
}

function ThirdPartyUltrasoundLogListItem({ log, relay }: Props) {
  return (
    <ServerLogListItem
      createdAt={log.createdAt!}
      status={log.status!}
      jobType={JobType.PUSH}
    >
      {(expanded) =>
        expanded && (
          <QueryRenderer<ContentQuery>
            environment={relay.environment}
            fetchPolicy="store-and-network"
            query={graphql`
              query ThirdPartyUltrasoundLogListItemContentQuery($logId: ID!) {
                log: node(id: $logId) {
                  ... on ServerLogInterface {
                    content
                  }
                }
              }
            `}
            variables={{ logId: log.id! }}
            render={({ props }) =>
              !props ? (
                <LoadingIndicator />
              ) : (
                <pre className="mb-3">{props.log!.content!}</pre>
              )
            }
          />
        )
      }
    </ServerLogListItem>
  );
}

export default createFragmentContainer(ThirdPartyUltrasoundLogListItem, {
  log: graphql`
    fragment ThirdPartyUltrasoundLogListItem_log on ServerLogInterface {
      ... on Node {
        id
      }
      status
      createdAt
    }
  `,
});
