/**
 * @generated SignedSource<<c24a5fd2ccef5395782e0c043ab310a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SplitStudyPatientField_sourceStudy$data = {
  readonly patient: {
    readonly id: string;
    readonly medicalRecordNumber: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"PatientName_patient">;
  } | null;
  readonly practiceType: StudyPracticeType | null;
  readonly vetPatient: {
    readonly " $fragmentSpreads": FragmentRefs<"VetPatientName_vetPatient">;
  } | null;
  readonly " $fragmentType": "SplitStudyPatientField_sourceStudy";
};
export type SplitStudyPatientField_sourceStudy$key = {
  readonly " $data"?: SplitStudyPatientField_sourceStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyPatientField_sourceStudy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyPatientField_sourceStudy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PatientName_patient"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medicalRecordNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VetPatientName_vetPatient"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "7a909c1c899c4c162bd7e36bd8631272";

export default node;
