import omitBy from 'lodash/omitBy';
import { array, bool, object, string } from 'yup';
import type { InferType } from 'yup';

import { arrayFromString, join, split } from './filterHelpers';

export enum Sort {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  RANKING_DESC = 'RANKING_ASC',
}

export const SORTS: Array<Sort> = Object.values(Sort);

export enum ContentType {
  VIDEO = 'VIDEO',
  COURSE = 'COURSE',
}

export const CONTENT_TYPES: Array<ContentType> = Object.values(ContentType);

export enum VideoType {
  ACQUISITION = 'ACQUISITION',
  INTERPRETATION = 'INTERPRETATION',
}

export const VIDEO_TYPES: Array<VideoType> = Object.values(VideoType);

export enum PracticeType {
  NONE = 'NONE',
  HUMAN = 'HUMAN',
  VETERINARY = 'VETERINARY',
}

export const PRACTICE_TYPES: Array<PracticeType> = Object.values(PracticeType);

export enum DurationType {
  LESS_THAN_1 = 'LESS_THAN_1',
  FROM_1_TO_5 = 'FROM_1_TO_5',
  FROM_5_TO_10 = 'FROM_5_TO_10',
  MORE_THAN_10 = 'MORE_THAN_10',
}

export const DURATION_TYPES: Array<DurationType> = Object.values(DurationType);

const schema = object({
  sort: string().enum(SORTS).default(undefined),
  search: string(),
  category: array().of(string()),
  contentType: array().of(string().enum(CONTENT_TYPES)),
  videoType: array().of(string().enum(VIDEO_TYPES)),
  practiceType: array().of(string().enum(PRACTICE_TYPES)),
  duration: array().of(string().enum(DURATION_TYPES)),
  viewerHasCompleted: bool(),
});

export type Filters = Partial<InferType<typeof schema>>;

const serializer = schema
  .shape({
    sort: string(),
    category: string().transform(join),
    contentType: string().transform(join),
    videoType: string().transform(join),
    practiceType: string().transform(join),
    duration: string().transform(join),
    viewerHasCompleted: string(),
  })
  .transform((values) => omitBy(values, (value) => !value || !value.length));

export function serialize(value: any) {
  return serializer.cast(value);
}

const deserializer = schema.shape({
  sort: string().enum(SORTS),
  category: arrayFromString,
  contentType: array().of(string().enum(CONTENT_TYPES)).transform(split),
  videoType: array().of(string().enum(VIDEO_TYPES)).transform(split),
  practiceType: array().of(string().enum(PRACTICE_TYPES)).transform(split),
  duration: array().of(string().enum(DURATION_TYPES)).transform(split),
});

export function deserialize(query: any): Filters {
  return deserializer.cast(query);
}

export default schema;
