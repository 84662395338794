/**
 * @generated SignedSource<<7fb455c380454bd6df0c8ed60049f115>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useInactivityTimeoutQuery$variables = {};
export type useInactivityTimeoutQuery$data = {
  readonly viewer: {
    readonly domain: {
      readonly inactivityTimeoutSeconds: number | null;
    } | null;
  } | null;
};
export type useInactivityTimeoutQuery = {
  response: useInactivityTimeoutQuery$data;
  variables: useInactivityTimeoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inactivityTimeoutSeconds",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useInactivityTimeoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useInactivityTimeoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7540d73f6142892392185e790ac2b69",
    "id": null,
    "metadata": {},
    "name": "useInactivityTimeoutQuery",
    "operationKind": "query",
    "text": "query useInactivityTimeoutQuery {\n  viewer {\n    domain {\n      inactivityTimeoutSeconds\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b47b5923c0b2129df75da7cd84b3919b";

export default node;
