import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import React, { useImperativeHandle, useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import CommentListItem from './CommentListItem';
import { CommentList_comments$data as Comments } from './__generated__/CommentList_comments.graphql';

const styles = stylesheet`
  .list {
    /* Tall enough that the item menu doesn't cause a scrollbar */
    min-height: 8rem;
    max-height: 30rem;
    overflow-y: auto;

    /* XXX: Workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=748518. */
    &::after {
      content: '';
      display: block;
      padding-bottom: theme('spacing.3');
    }
  }
`;

interface Props {
  comments: Comments;
  className?: string;
}

export interface CommentListHandle {
  scrollToBottom(): void;
}

const CommentList = React.forwardRef(
  ({ comments, className }: Props, ref: React.Ref<CommentListHandle>) => {
    const listRef = useRef<Element>();

    useImperativeHandle(
      ref,
      () => ({
        scrollToBottom: () => {
          listRef.current!.scrollTop = listRef.current!.scrollHeight;
        },
      }),
      [],
    );

    return (
      <div
        ref={listRef as any}
        className={clsx(className, styles.list, 'space-y-4')}
      >
        {!!comments.length &&
          [...comments]
            .reverse()
            .map((comment) => (
              <CommentListItem key={comment.id} comment={comment} />
            ))}
      </div>
    );
  },
);

export default createFragmentContainer(CommentList, {
  comments: graphql`
    fragment CommentList_comments on StudyImageComment @relay(plural: true) {
      id
      ...CommentListItem_comment
    }
  `,
});
