import Layout from '@4c/layout';
import Heading from '@bfly/ui2/Heading';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import RelayInfiniteList from '@bfly/ui2/RelayInfiniteList';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import useMountEffect from '@restart/hooks/useMountEffect';
import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from 'react-relay';

import { NotificationLoadingIndicator } from 'components/Notification';
import StudyFinalizationRequestedNotification from 'components/StudyFinalizationRequestedNotification';
import StudyImageCommentNotification from 'components/StudyImageCommentNotification';
import WorksheetReviewedNotification from 'components/WorksheetReviewedNotification';
import SetNotificationCenterLastSeenAt from 'mutations/SetNotificationCenterLastSeenAt';
import { useArchiveRoutes } from 'routes/archive';

import { NotificationsPage_organization$data as Organization } from './__generated__/NotificationsPage_organization.graphql';

const PAGE_SIZE = 24;

const NOTIFICATION_COMPONENTS = {
  StudyFinalizationRequestedNotification,
  StudyImageCommentNotification,
  WorksheetReviewedNotification,
};

interface Props {
  organization: Organization;
  relay: RelayPaginationProp;
}

type Notification = ConnectionNodeType<
  Organization['viewerNotificationCenter'],
  'notificationConnection'
> & {
  Component:
    | typeof StudyFinalizationRequestedNotification
    | typeof StudyImageCommentNotification
    | typeof WorksheetReviewedNotification;
};

function NotificationsPage({ organization, relay }: Props) {
  const { formatDate } = useIntl();

  const archiveRoutes = useArchiveRoutes();

  const notificationCenter = organization.viewerNotificationCenter;

  let notifications: Notification[] = [];
  let latestNotificationId: string | undefined = '';

  if (notificationCenter?.notificationConnection) {
    notifications = getNodes(notificationCenter?.notificationConnection).map(
      (n) => ({ ...n, Component: NOTIFICATION_COMPONENTS[n.typename] }),
    ) as Notification[];
    latestNotificationId = notifications?.[0]?.id;
  }

  useMountEffect(() => {
    if (notificationCenter?.numUnseenNotifications && latestNotificationId) {
      SetNotificationCenterLastSeenAt.commit(
        relay.environment,
        notificationCenter?.id,
        latestNotificationId,
      );
    }
  });

  const notificationGroups = groupBy(notifications, (notification) =>
    formatDate(new Date(notification.createdAt!), {
      year: 'numeric',
      month: 'long',
    }),
  );

  const groupHeaders = Object.keys(notificationGroups);

  return (
    <Page.Container>
      <Page.Header backTo={archiveRoutes.archive()}> </Page.Header>
      <MainContent>
        <Heading ruled className="mb-5">
          <FormattedMessage
            defaultMessage="All Notifications"
            id="notificationPage.title"
          />
        </Heading>
        <Layout direction="column">
          {notifications.length ? (
            <RelayInfiniteList
              relayPagination={relay}
              pageSize={PAGE_SIZE}
              loadingIndicator={<NotificationLoadingIndicator />}
            >
              {groupHeaders.map((header, groupIndex) => (
                <Fragment key={header}>
                  <Heading
                    ruled
                    variant="body-bold"
                    className={clsx(
                      'mb-1',
                      groupIndex && 'mt-8',
                      'border-b-0',
                    )}
                  >
                    {header}
                  </Heading>
                  {notificationGroups[header].map(
                    ({ Component, ...notification }) => (
                      <Component
                        notification={notification}
                        clearable={false}
                        key={notification.id}
                      />
                    ),
                  )}
                </Fragment>
              ))}
            </RelayInfiniteList>
          ) : (
            <Layout direction="column" justify="center" className="">
              <FormattedMessage
                id="notificationCenter.notificationPage.noNotifications.description"
                defaultMessage="You have no notifications yet."
              />
            </Layout>
          )}
        </Layout>
      </MainContent>
    </Page.Container>
  );
}

export default createPaginationContainer(
  NotificationsPage,
  {
    organization: graphql`
      fragment NotificationsPage_organization on Organization
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 24 } # PAGE_SIZE.
        cursor: { type: "String" }
        isCleared: { type: "[Boolean]" }
      ) {
        slug
        viewerNotificationCenter {
          id
          numUnseenNotifications
          notificationConnection(
            first: $count
            after: $cursor
            isCleared: $isCleared
          ) @connection(key: "NotificationCenter_notificationConnection") {
            edges {
              node {
                createdAt
                ... on Node {
                  id
                }
                typename: __typename
                ...StudyImageCommentNotification_notification
                ...WorksheetReviewedNotification_notification
                ...StudyFinalizationRequestedNotification_notification
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: ({ organization }) =>
      organization?.viewerNotificationCenter?.notificationConnection,
    getVariables: ({ organization }, { count, cursor }) => ({
      organizationSlug: organization.slug,
      id: organization?.viewerNotificationCenter?.id,
      count,
      cursor,
      isCleared: [true, false],
    }),
    query: graphql`
      query NotificationsPage_PaginationQuery(
        $organizationSlug: String!
        $count: Int!
        $cursor: String
        $isCleared: [Boolean]
      ) {
        organization: organizationBySlug(slug: $organizationSlug) {
          ...NotificationsPage_organization
            @arguments(count: $count, cursor: $cursor, isCleared: $isCleared)
        }
      }
    `,
  },
);
