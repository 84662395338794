/**
 * @generated SignedSource<<716c7a3dc694cf77633511ce987d4797>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedUsersPopoverListItems_users$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"AvatarCollage_userProfiles" | "Avatar_userProfile">;
  readonly " $fragmentType": "RelatedUsersPopoverListItems_users";
}>;
export type RelatedUsersPopoverListItems_users$key = ReadonlyArray<{
  readonly " $data"?: RelatedUsersPopoverListItems_users$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedUsersPopoverListItems_users">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RelatedUsersPopoverListItems_users",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarCollage_userProfiles"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_userProfile"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "2b15e59b706ab6416b7c69fc832408f3";

export default node;
