import CheckMedalIcon from '@bfly/icons/CheckMedal';
import CrayonIcon from '@bfly/icons/Crayon';
import Button from '@bfly/ui2/Button';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import notNullish from '@bfly/utils/notNullish';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from 'components/Avatar';
import { useVariation } from 'components/LaunchDarklyContext';
import PopoverList from 'components/PopoverList';
import RelatedUsersPopoverListItems from 'components/RelatedUsersPopoverListItems';
import StudyAuthorSearchControl from 'components/StudyAuthorSearchControl';
import StudyAuthorsList from 'components/StudyAuthorsList';
import useModalState from 'hooks/useModalState';
import actionMessages from 'messages/actions';

import { ExamAuthors_study$data as Study } from './__generated__/ExamAuthors_study.graphql';

interface Props {
  study: Study;
  id?: string;
}

function ExamAuthors({ study }: Props) {
  const canUseMultipleAuthors = useVariation('multiple-study-authors');

  const [openModal, modalProps] = useModalState();

  const { onHide } = modalProps;
  const { createdBy: primaryAuthor, transcribedBy } = study;
  const secondaryAuthors = study.secondaryAuthors || [];

  // close the author modal when the author changes (e.g one set/changed)
  // for single selection cases
  useEffect(() => {
    if (!canUseMultipleAuthors) {
      onHide();
    }
  }, [primaryAuthor, canUseMultipleAuthors, onHide]);

  return (
    <>
      <PopoverList
        variant="dark"
        // hide if there are no other authors
        show={
          canUseMultipleAuthors && secondaryAuthors?.length ? undefined : false
        }
        listItems={
          <RelatedUsersPopoverListItems
            users={[primaryAuthor, ...secondaryAuthors].filter(notNullish)}
            createdBy={primaryAuthor?.id}
            transcribedBy={transcribedBy?.id}
          />
        }
      >
        <Button
          variant="text-secondary"
          data-bni-id="ManageStudyAuthorsButton"
          className="rounded bg-grey-80 px-2 justify-start w-full max-w-[max-content] lg:max-w-sm"
          onClick={openModal}
        >
          {primaryAuthor ? (
            <>
              <Avatar
                width={20}
                className="mr-1"
                userProfile={primaryAuthor}
              />

              {canUseMultipleAuthors ? (
                <>
                  <span className="truncate">{primaryAuthor.name}</span>
                  <CheckMedalIcon className="ml-1 flex-none" height={14} />
                </>
              ) : (
                primaryAuthor.name
              )}
            </>
          ) : (
            <>
              <Text color="body" className="text-body">
                <FormattedMessage
                  id="examAuthors.none"
                  defaultMessage="None"
                />
              </Text>
              <CrayonIcon className="ml-2 text-headline" height={14} />
            </>
          )}
          {!!secondaryAuthors?.length && (
            <strong className="ml-2">
              <FormattedMessage
                id="examAuthors.secondaryAuthors"
                defaultMessage="+{numSecondaryAuthors}"
                values={{ numSecondaryAuthors: secondaryAuthors.length }}
              />
            </strong>
          )}
        </Button>
      </PopoverList>
      <Modal {...modalProps} variant="dark" size="sm">
        <Modal.Body
          scrollable={false}
          className="flex flex-col overflow-hidden"
        >
          <Modal.Title className="text-headline mb-3">
            <FormattedMessage
              id="examAuthors.authors"
              defaultMessage="{hasMultiple, plural, 
        true {Authors}
        other {Author}
      }"
              values={{ hasMultiple: !!canUseMultipleAuthors }}
            />
          </Modal.Title>
          {!study.finalizedAt && (
            <StudyAuthorSearchControl study={study} variant="secondary" />
          )}
          <StudyAuthorsList avatarSize={32} variant={null} study={study} />
        </Modal.Body>
        <Modal.Footer>
          <Modal.ButtonGroup>
            <Button size="lg" onClick={modalProps.onHide}>
              <FormattedMessage {...actionMessages.close} />
            </Button>
          </Modal.ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default createFragmentContainer(ExamAuthors, {
  study: graphql`
    fragment ExamAuthors_study on Study {
      finalizedAt
      createdBy {
        id
        name
        ...Avatar_userProfile
        ...RelatedUsersPopoverListItems_users
      }
      secondaryAuthors {
        id
        ...Avatar_userProfile
        ...RelatedUsersPopoverListItems_users
      }
      transcribedBy {
        id
      }
      ...StudyAuthorsList_study
      ...StudyAuthorSearchControl_study
    }
  `,
});
