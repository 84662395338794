/**
 * @generated SignedSource<<63d795750c96ec8a976273deb95733fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSaveContentToggle_educationContent$data = {
  readonly id: string;
  readonly viewerHasAccess: boolean | null;
  readonly viewerHasSaved: boolean | null;
  readonly " $fragmentType": "useSaveContentToggle_educationContent";
};
export type useSaveContentToggle_educationContent$key = {
  readonly " $data"?: useSaveContentToggle_educationContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSaveContentToggle_educationContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSaveContentToggle_educationContent"
};

(node as any).hash = "368d3aabf5b88bac0c598b601c8ee4f2";

export default node;
