import formatName from '@bfly/utils/formatName';
import useGlobalListener from '@restart/hooks/useGlobalListener';
import useImage from '@restart/hooks/useImage';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useVariation } from 'components/LaunchDarklyContext';
import {
  useFilteredSelectedItemsByKey,
  useMultiSelect,
} from 'components/MultiSelectContext';
import useKeyboardNavListener from 'hooks/useKeyboardNavListener';
import joinNonemptyStrings from 'utils/joinNonemptyStrings';
import { usePermissions } from 'utils/viewerContext';

import StudyMoveItem from '../components/StudyMoveItem';

const TRANSPARENT_IMAGE_URL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';

export interface SelectedStudyItem {
  studyId: string;
  archiveId: string;
  patientName: string;
  viewerCanMove: boolean;
  viewerCanDelete: boolean;
  viewerCanAssignExamTypes: boolean;
  finalizedAt: string;
}

interface Patient {
  nameFirst: string | null;
  nameLast: string | null;
  nameMiddle: string | null;
  namePrefix: string | null;
  nameSuffix: string | null;
}

interface VetPatient {
  clientNameLast: string | null;
  clientOrganizationName: string | null;
  name: string | null;
}

export type StudyRef = {
  id: string;
  archive: {
    id: string;
  } | null;
  createdBy: {
    id: string;
  } | null;
  patient: Patient | null;
  vetPatient: VetPatient | null;
  deletedAt: string | null;
  viewerCanMove: boolean | null;
  viewerCanDelete: boolean | null;
  finalizedAt: string | null;
  examTypes: readonly ({ readonly id: string } | null)[] | null;
};

function getPatientName(study: Omit<StudyRef, 'deletedAt'>): string {
  if (study.patient) {
    return study.patient ? formatName(study.patient) : '';
  }

  return study.vetPatient?.name
    ? joinNonemptyStrings(', ', [
        study.vetPatient.clientNameLast ||
          study.vetPatient.clientOrganizationName,
        study.vetPatient.name,
      ])
    : '';
}

export function useGetSelectedStudyItem() {
  const { hasBasicPermission } = usePermissions();
  return useCallback(
    (study: Omit<StudyRef, 'deletedAt'>): SelectedStudyItem => {
      return {
        studyId: study.id,
        archiveId: study.archive!.id,
        patientName: getPatientName(study),
        viewerCanMove: !!study.viewerCanMove,
        // GQL takes into account custom permissions
        viewerCanDelete: !!study.viewerCanDelete,
        finalizedAt: study.finalizedAt!,
        viewerCanAssignExamTypes: !!(
          hasBasicPermission('examTypeManagement') && !study.examTypes?.length
        ),
      };
    },
    [hasBasicPermission],
  );
}

export type SelectedStudiesContext = {
  selectedStudyItems: SelectedStudyItem[];
  viewerCanMoveSelected: boolean;
  viewerCanDeleteSelected: boolean;
  viewerCanAssignExamTypesToSelected: boolean;
};

const StudyListContext = createContext<SelectedStudiesContext>(null as any);

const canMove = (
  studyItem: StudyRef | SelectedStudyItem,
  onlyDrafts: boolean,
) => studyItem.viewerCanMove && (!onlyDrafts || !studyItem.finalizedAt);

export const { Provider } = StudyListContext;

type AllowedActions = {
  allowDelete: boolean;
  allowMove: boolean;
  allowAssignExamTypes: boolean;
};

export function useSelectionContextValue({
  allowDelete = false,
  allowMove = false,
  allowAssignExamTypes = false,
}: Partial<AllowedActions>) {
  const canUseDrafts = useVariation('draft-studies');

  const { selectedItemsByKey, setSelectedItemsByKey } =
    useMultiSelect<SelectedStudyItem>();

  const selectedStudyItems = useMemo(
    () => Array.from(selectedItemsByKey.values()),
    [selectedItemsByKey],
  );

  const viewerCanMoveSelected = useMemo(
    () =>
      allowMove &&
      selectedStudyItems.every((studyItem) =>
        canMove(studyItem, canUseDrafts),
      ),
    [allowMove, selectedStudyItems, canUseDrafts],
  );

  const viewerCanDeleteSelected = allowDelete;

  const viewerCanAssignExamTypesToSelected = useMemo(
    () =>
      allowAssignExamTypes &&
      selectedStudyItems.every(
        (studyItem) => studyItem.viewerCanAssignExamTypes,
      ),
    [allowAssignExamTypes, selectedStudyItems],
  );

  const contextValue = useMemo(
    () => ({
      viewerCanMoveSelected,
      viewerCanDeleteSelected,
      viewerCanAssignExamTypesToSelected,
      selectedStudyItems,
    }),
    [
      viewerCanMoveSelected,
      viewerCanDeleteSelected,
      viewerCanAssignExamTypesToSelected,
      selectedStudyItems,
    ],
  );

  return [contextValue, setSelectedItemsByKey] as const;
}

function useTransparentImage() {
  const { image } = useImage(TRANSPARENT_IMAGE_URL);
  return image;
}

type Position = {
  top: number;
  left: number;
};

export function useStudySelection({
  studies,
  allowDelete,
  allowAssignExamTypes,
  allowMove,
}: {
  studies: readonly StudyRef[];
} & AllowedActions) {
  const canUseDrafts = useVariation('draft-studies');

  const { hasBasicPermission } = usePermissions();
  const hasExamTypeManagementPermission = hasBasicPermission(
    'examTypeManagement',
  );

  const ctx = useContext(StudyListContext);
  const isNavigatingViaKeyboard = useKeyboardNavListener();
  const getSelectedStudyItem = useGetSelectedStudyItem();

  const image = useTransparentImage();
  const [draggingStudyTitle, setDraggingStudyTitle] = useState(null);
  const [numDraggingStudies, setNumDraggingStudies] = useState(0);

  // we use a ref to avoid high velocity react updates. Even throttled by RAF
  // it can cause too many child components to re-render in the list of studies (esp in the grid)
  const previewItemStyleRef = useRef<Position | null>(null);

  // Adding a global dragover listener is necessary here because
  // clientX & clientY are not available during a drag event in firefox.
  useGlobalListener('dragover', (e) => {
    e.preventDefault();
    if (!draggingStudyTitle) return;

    previewItemStyleRef.current = {
      top: e.clientY - 2,
      left: e.clientX - 2,
    };
  });

  const studyIds = useMemo(() => studies.map((study) => study.id), [studies]);
  const selectedItemsByKey =
    useFilteredSelectedItemsByKey<SelectedStudyItem>(studyIds);

  const handleDragStart = useCallback(
    (e: React.DragEvent, { studyId, studyTitle }) => {
      const draggedStudy = studies.find((study) => study.id === studyId);

      previewItemStyleRef.current = {
        top: e.clientY - 2,
        left: e.clientX - 2,
      };

      // If you start to drag an unselected study, we ignore the selected ones
      // and only drag the one.
      const studyItems = selectedItemsByKey.has(studyId)
        ? Array.from(selectedItemsByKey.values())
        : [getSelectedStudyItem(draggedStudy!)];

      setDraggingStudyTitle(studyTitle);
      setNumDraggingStudies(studyItems.length);

      e.dataTransfer.setData('study-items', JSON.stringify(studyItems));

      // We use different data keys so we determine, without introspecting the
      // data whether to show drop effects. The value is unimportant.
      if (studyItems.every((studyItem) => canMove(studyItem, canUseDrafts))) {
        e.dataTransfer.setData('study-items-can-move', 'true');
      }
      if (studyItems.every((studyItem) => studyItem.viewerCanDelete)) {
        e.dataTransfer.setData('study-items-can-delete', 'true');
      }

      e.dataTransfer.setDragImage(image!, 0, 0);
    },
    [studies, selectedItemsByKey, getSelectedStudyItem, image, canUseDrafts],
  );

  const handleDragEnd = useCallback(() => {
    previewItemStyleRef.current = null;
    setDraggingStudyTitle(null);
  }, []);

  const item = (
    <StudyMoveItem
      title={draggingStudyTitle}
      numDraggedStudies={numDraggingStudies}
      styleRef={previewItemStyleRef}
    />
  );

  const canSelect = (study: StudyRef) => {
    // Any movable, deletable, or assignable study can be selected. If the study is
    // selected, then by assumption all selected studies are movable
    // or draggable or assignable. Otherwise, the drag action will only target the
    // current study.

    const canDelete = allowDelete && !study.deletedAt;

    const canMoveStudy =
      allowMove && ctx.viewerCanMoveSelected && canMove(study, canUseDrafts);

    const canAssignExamTypes =
      allowAssignExamTypes &&
      hasExamTypeManagementPermission &&
      !study.examTypes?.length;

    return !!(canDelete || canMoveStudy || canAssignExamTypes);
  };

  const isStudyDraggable = useCallback(
    (study: StudyRef) => {
      // Any movable or deletable study can be dragged. If the study is
      // selected, then by assumption all selected studies are movable
      // or draggable. Otherwise, the drag action will only target the
      // current study.
      return (
        !study.deletedAt &&
        !!(canMove(study, canUseDrafts) || study.viewerCanDelete)
      );
    },
    [canUseDrafts],
  );

  return [
    item,
    {
      canSelect,
      isDraggable: isStudyDraggable,
      selectionVisible:
        !!ctx.selectedStudyItems.length || isNavigatingViaKeyboard,
    },
    useMemo(
      () => ({
        onDragEnd: handleDragEnd,
        onDragStart: handleDragStart,
      }),
      [handleDragEnd, handleDragStart],
    ),
  ] as const;
}
