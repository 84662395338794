import {
  ViewerContext,
  hasPermissionWithState,
  useViewerAllowMissingContext,
} from 'utils/viewerContext';

export default function canAccessEduDashboard(
  viewer: ViewerContext | null,
): boolean {
  if (!viewer) return false;

  // if public cloud org with CUP flag on, it won't have a domain, so need to check for permissions-enabled edu dash access
  if (!viewer.domain) {
    return !!viewer.canAccessEduDashboardWithEduManagementPermissions;
  }

  return (
    !!viewer.canAccessEduDashboardWithEduManagementPermissions &&
    hasPermissionWithState(viewer, 'educationManagement', 'BASIC')
  );
}

export function useCanAccessEduDashboard(): boolean {
  const viewer = useViewerAllowMissingContext();
  return canAccessEduDashboard(viewer);
}
