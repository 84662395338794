import { defineMessages } from 'react-intl';

export default defineMessages({
  dueDayAgo: {
    id: 'edu.dueDayAgo',
    defaultMessage:
      'Due {count, plural, =0 {Today}  one {# day ago} other {# days ago}}',
  },
  dueDays: {
    id: 'edu.dueDays',
    defaultMessage:
      'Due {count, plural, =0 {Today} one {in # day} other {in # days}}',
  },
  savedContent: {
    id: 'edu.savedContent',
    defaultMessage: 'Saved Content',
  },
  savedContentEmpty: {
    id: 'edu.savedContent.empty',
    defaultMessage: 'No Content Saved',
  },
  savedVideos: {
    id: 'edu.savedVideos',
    defaultMessage: 'Saved Videos',
  },
  savedVideosEmpty: {
    id: 'edu.saved.empty',
    defaultMessage: 'No Videos Saved',
  },
  educationVideos: {
    id: 'edu.educationVideos',
    defaultMessage: 'Education Videos',
  },
  educationDashboard: {
    id: 'edu.dashboard',
    defaultMessage: 'Dashboard',
  },
  educationalDashboard: {
    id: 'edu.dropdown.dashboard',
    defaultMessage: 'Educational Dashboard',
  },
  educationalContent: {
    id: 'edu.dropdown.content',
    defaultMessage: 'Educational Content',
  },
  courseOverview: {
    id: 'edu.courseOverview',
    defaultMessage: 'Course overview',
  },
  userOverview: {
    id: 'edu.userOverview',
    defaultMessage: 'User overview',
  },
  selectUser: {
    id: 'edu.selectUser',
    defaultMessage: 'Select current user',
  },
  name: {
    id: 'edu.messageName',
    defaultMessage: 'Name',
  },
  userProgress: {
    id: 'edu.userProgress',
    defaultMessage: 'User progress',
  },
  courseName: {
    id: 'edu.courseName',
    defaultMessage: 'Course Name',
  },
  progress: {
    id: 'edu.progress',
    defaultMessage: 'Progress',
  },
  selectCourse: {
    id: 'edu.selectCourse',
    defaultMessage: 'Select current course',
  },
  showCoursesProgress: {
    id: 'edu.showCoursesProgress',
    defaultMessage: 'Show courses progress',
  },
  sendReminder: {
    id: 'edu.sendReminder',
    defaultMessage: 'Send Reminder',
  },
  showCoursesDescription: {
    id: 'edu.showCoursesDescription',
    defaultMessage: 'Show courses description',
  },
  expandCoursesDescription: {
    id: 'edu.expandCoursesDescription',
    defaultMessage: 'Expand course description',
  },
  noCourseDescription: {
    id: 'edu.noCourseDescription',
    defaultMessage: 'No Course Description',
  },
  noCoursesFound: {
    id: 'edu.noCoursesFound',
    defaultMessage: 'No Courses Found',
  },
  noUsersFound: {
    id: 'edu.noUsers',
    defaultMessage: 'No Users Found',
  },
  noEnrollmentsFound: {
    id: 'edu.courseGrid.noEnrollments',
    defaultMessage: 'No Enrollments Found',
  },
  searchForCourseName: {
    id: 'edu.search',
    defaultMessage: 'Search for course name',
  },
  enroll: {
    id: 'edu.enroll',
    defaultMessage: 'Enroll',
  },
  unEnroll: {
    id: 'edu.unEnroll',
    defaultMessage: 'Unenroll',
  },
  courseEnrollment: {
    id: 'edu.courseEnrollment',
    defaultMessage: 'Course Enrollment',
  },
  courseEnrollmentDueDatesOverlapsTitle: {
    id: 'edu.courseEnrollmentDueDatesOverlapsTitle',
    defaultMessage:
      'The following {members, plural, one { member} other { members}} could not be enrolled in {courses, plural, one { this course} other { these courses}} because of conflicting due dates. To assign different due dates, unenroll {members, plural, one { this member} other { these members}} from {courses, plural, one { this course} other { these courses}} and start a new enrollment.',
  },
  courseEnrollmentDueDatesOverlapsItem: {
    id: 'edu.courseEnrollmentDueDatesOverlapsItem',
    defaultMessage: '{member} could not be enrolled in { course}',
  },
  courseEnrollmentToastMessage: {
    id: 'edu.courseEnrollmentToastMessage',
    defaultMessage:
      'Enrolling {members, plural, one {# member} other {# members}}',
  },
  courseUnenrolledToastMessage: {
    id: 'edu.courseUnenrolledToastMessage',
    defaultMessage:
      '{count, plural, one {# member} other {# members}} unenrolled',
  },
  courseEnrollmentFailureToastMessage: {
    id: 'edu.courseEnrollmentFailureToastMessage',
    defaultMessage:
      'Unable to create {count, plural, one {# enrollment} other {# enrollments}}: {details}',
  },
  courseUnenrollmentFailureToastMessage: {
    id: 'edu.courseUnenrollmentFailureToastMessage',
    defaultMessage:
      'Unable to unenroll {count, plural, one {# enrollment} other {# enrollments}}: {details}',
  },
  courseEnrollmentConfirmation: {
    id: 'edu.courseEnrollmentConfirmation',
    defaultMessage:
      'You are enrolling {members, plural, one {# member} other {# members}} in {courses, plural, one {# course} other {# courses}}',
  },
  failedToEnrol: {
    id: 'edu.failedToEnrol',
    defaultMessage: 'Failed to enroll',
  },
  membersCount: {
    id: 'edu.membersCount',
    defaultMessage: '{members, plural, one {# member} other {# members}}',
  },
  coursesCount: {
    id: 'edu.coursesCount',
    defaultMessage: '{courses, plural, one {# course} other {# courses}}',
  },
  coursesSelected: {
    id: 'edu.coursesSelected',
    defaultMessage:
      '{count, plural, one {# course selected} other {# courses selected}}',
  },
  selectCourses: {
    id: 'edu.selectCourses',
    defaultMessage: 'Select Courses',
  },
  expandDescription: {
    id: 'edu.expandDescription',
    defaultMessage: 'Expand course Description',
  },
  expandCoursesInfo: {
    id: 'edu.expandCoursesInfo',
    defaultMessage: 'Expand courses info',
  },
  expandCourseDetails: {
    id: 'edu.expandCell',
    defaultMessage: 'Expand course details',
  },
  expandCoursesInfoCell: {
    id: 'edu.expandCoursesInfoCell',
    defaultMessage: 'Expand courses info cell',
  },
  numberOfCourseCompletions: {
    id: 'edu.numberOfCourseCompletions',
    defaultMessage: "% of Learners Who've Completed Course",
  },
  showCourseProgress: {
    id: 'edu.showCourseProgress',
    defaultMessage: 'Show course progress',
  },
  numberOfEnrollments: {
    id: 'edu.numberOfEnrollments',
    defaultMessage: 'Number of Enrollments',
  },
  courseUnenrollmentConfirmation: {
    id: 'edu.courseUnenrollmentConfirmation',
    defaultMessage:
      'You are unenrolling {members, plural, one {# member} other {# members}}',
  },
  courseUnEnrollment: {
    id: 'edu.courseUnEnrollment',
    defaultMessage: 'Course Unenrollment',
  },
  membersSelected: {
    id: 'edu.membersSelected',
    defaultMessage:
      '{count, plural, one {# member selected} other {# members selected}}',
  },
  searchForMemberName: {
    id: 'edu.searchForMemberName',
    defaultMessage: 'Search for member name',
  },
  enrolledCourses: {
    id: 'edu.enrolledCourses',
    defaultMessage: 'Enrolled Courses',
  },
  coursesProgress: {
    id: 'edu.coursesProgress',
    defaultMessage: 'Courses Progress',
  },
  startEnrollment: {
    id: 'edu.startEnrollment',
    defaultMessage: 'Start Course Enrollment',
  },
  courseCompletionProgress: {
    id: 'edu.courseCompletionProgress',
    defaultMessage: 'Course completion progress',
  },
  categoriesSelected: {
    id: 'edu.categoriesSelected',
    defaultMessage:
      '{count, plural, one {# category selected} other {# categories selected}}',
  },
  organizationsSelected: {
    id: 'edu.organizationsSelected',
    defaultMessage:
      '{count, plural, one {# organization selected} other {# organizations selected}}',
  },
  selectCategories: {
    id: 'edu.selectCategories',
    defaultMessage: 'Categories',
  },
  selectOrganizations: {
    id: 'edu.selectOrganizations',
    defaultMessage: 'Organizations',
  },
  failedToUnenrol: {
    id: 'edu.failedToUnenrol',
    defaultMessage: 'Failed to unenroll',
  },
  selectAllUsers: {
    id: 'edu.selectAllUsers',
    defaultMessage: 'Select All Users',
  },
  selectAllCourses: {
    id: 'edu.selectAllCourses',
    defaultMessage: 'Select All Courses',
  },
  exportButton: {
    id: 'edu.dashboard.export',
    defaultMessage: 'Export Dashboard',
  },
  exportButtonShort: {
    id: 'edu.dashboard.export.short',
    defaultMessage: 'Export',
  },
  nonPaidBanner: {
    id: 'edu.nonPaid',
    defaultMessage: 'Butterfly Academy is available on select plans',
  },
  proCustomWithoutEduBanner: {
    id: 'edu.proCustomWithoutEduBanner',
    defaultMessage:
      'Upgrade your membership to get access to courses and premium videos',
  },
  membershipsExpiredBanner: {
    id: 'edu.membershipsExpiredBanner',
    defaultMessage: 'Membership expired',
  },
  dueDateBanner: {
    id: 'edu.dueDateBanner',
    defaultMessage:
      'You have  {count, plural, one {# course} other {# courses}} due soon.',
  },
  viewCourse: {
    id: 'edu.viewCourse',
    defaultMessage: 'View {count, plural, one {course} other {courses}}',
  },
  educationVideoSearch: {
    id: 'edu.topNav.search',
    defaultMessage: 'Search for anything you’d like to learn',
  },
  assigned: {
    id: 'edu.assigned',
    defaultMessage: 'Assigned to me ({count})',
  },
  enrollments: {
    id: 'edu.enrollments',
    defaultMessage: 'My Enrollments ({count})',
  },
  saved: {
    id: 'edu.saved',
    defaultMessage: 'Saved ({count})',
  },
  completedCount: {
    id: 'edu.completedCount',
    defaultMessage: 'Completed ({count})',
  },
  items: {
    id: 'edu.items',
    defaultMessage: '{count, plural, one {# item} other {# items}}',
  },
  newEduTitle: {
    id: 'edu.header.title',
    defaultMessage: 'Ultrasound Educational Content',
  },
  startMembership: {
    id: 'edu.startMembership',
    defaultMessage: 'Start a membership',
  },
  membershipMessage: {
    id: 'edu.premiumContent.membershipMessage',
    defaultMessage:
      'Content with lock icon is available via Butterfly Cloud Membership.',
  },
  addOnMessage: {
    id: 'edu.premiumContent.addOnMessage',
    defaultMessage:
      'Content with lock icon is available with the education add-on.',
  },
  lessThanMinute: {
    id: 'edu.lessThanMinute',
    defaultMessage: '< 1 min',
  },
  minutes: {
    id: 'edu.minutes',
    defaultMessage: '{count} min',
  },
  butterflyAcademy: {
    id: 'edu.butterflyAcademy',
    defaultMessage: 'Butterfly Academy',
  },
  completed: {
    id: 'edu.completed',
    defaultMessage: 'Completed',
  },
  contentSaved: {
    id: 'edu.contentSaved',
    defaultMessage: 'Saved',
  },
  contentUnsaved: {
    id: 'edu.videoSaved',
    defaultMessage: 'Removed from saved list',
  },
  share: {
    id: 'edu.share.button',
    defaultMessage: 'Share',
  },
  shareTitle: {
    id: 'edu.share.title',
    defaultMessage: 'Share Video',
  },
  subscribersOnlyContent: {
    id: 'edu.subscribersOnlyContent',
    defaultMessage:
      'This content is only available to Butterfly Cloud subscribers. Login is required to access this URL.',
  },
  myContent: {
    id: 'edu.myContent',
    defaultMessage: 'My Content',
  },
  assignments: {
    id: 'edu.assignments',
    defaultMessage: 'Assignments',
  },
  noAssignedCourses: {
    id: 'edu.noAssignedCourses',
    defaultMessage: 'You have no assigned courses.',
  },
  selfEnrollments: {
    id: 'edu.selfEnrollments',
    defaultMessage: 'Self-Enrollments',
  },
  noSelfEnrollments: {
    id: 'edu.noSelfEnrollments',
    defaultMessage: 'You have no self-enrolled courses.',
  },
  noCompletedCourses: {
    id: 'edu.noCompletedCourses',
    defaultMessage: 'You have no completed courses.',
  },
  noResults: {
    id: 'edu.noResults',
    defaultMessage: 'Your search returned no results.',
  },
  suggestedContent: {
    id: 'edu.suggestedContent',
    defaultMessage: 'Consider these options for continued learning.',
  },
  newestContent: {
    id: 'edu.newestContent',
    defaultMessage: 'Newest Content',
  },
  bodySystem: {
    id: 'edu.bodySystem',
    defaultMessage: 'Category',
  },
  duration: {
    id: 'edu.duration',
    defaultMessage: 'Duration',
  },
  lessThanMin: {
    id: 'edu.duration.lessThanMin',
    defaultMessage: '< 1 min',
  },
  from1To5: {
    id: 'edu.duration.durationFrom1To5',
    defaultMessage: '1 - 5 min',
  },
  from5To10: {
    id: 'edu.duration.durationFrom5To10',
    defaultMessage: '5 - 10 min',
  },
  moreThan10Min: {
    id: 'edu.duration.moreThan10Min',
    defaultMessage: '> 10 min',
  },
  contentType: {
    id: 'edu.contentType',
    defaultMessage: 'Content Type',
  },
  videos: {
    id: 'edu.videos',
    defaultMessage: 'Videos',
  },
  courses: {
    id: 'edu.courses',
    defaultMessage: 'Courses',
  },
  imageAcquisition: {
    id: 'edu.imageAcquisition',
    defaultMessage: 'Image acquisition',
  },
  imageInterpretation: {
    id: 'edu.imageInterpretation',
    defaultMessage: 'Image interpretation',
  },
  sortBy: {
    id: 'edu.sortBy',
    defaultMessage: 'Sort by',
  },
  CREATED_AT_DESC: {
    id: 'edu.newest',
    defaultMessage: 'Newest',
  },
  CREATED_AT_ASC: {
    id: 'edu.oldest',
    defaultMessage: 'Oldest',
  },
  NAME_ASC: {
    id: 'edu.nameAsc',
    defaultMessage: 'A to Z',
  },
  NAME_DESC: {
    id: 'edu.nameDesc',
    defaultMessage: 'Z to A',
  },
  RANKING_ASC: {
    id: 'edu.rankingAsc',
    defaultMessage: 'Most popular',
  },
  clearFilters: {
    id: 'edu.clearFilters',
    defaultMessage: 'Clear filters',
  },
  next: {
    id: 'edu.next',
    defaultMessage: 'Next',
  },
  dueDate: {
    id: 'edu.dueDate',
    defaultMessage: 'Due Date',
  },
  dueDateValue: {
    id: 'edu.dueDateValue',
    defaultMessage: 'Due Date: {dueDate}',
  },
  assignDueDate: {
    id: 'edu.assignDueDate',
    defaultMessage: 'Assign a Due Date',
  },
  addDueDate: {
    id: 'edu.addDueDate',
    defaultMessage: 'Add due date',
  },
  removeDueDate: {
    id: 'edu.removeDueDate',
    defaultMessage: 'Remove due date',
  },
  dueDatesAreOptional: {
    id: 'edu.dueDatesAreOptional',
    defaultMessage: 'Due dates are optional.',
  },
  editDueDate: {
    id: 'edu.editDueDate',
    defaultMessage: 'Edit due date for selected courses',
  },
  daysForCompletion: {
    id: 'edu.daysForCompletion',
    defaultMessage:
      '{days, plural, one {# day} other {# days}} for completion',
  },
});
