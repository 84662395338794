/**
 * @generated SignedSource<<3483ff855aaa630099ed1406028be759>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type NotificationCenterUpdatedSubscriptionInput = {
  clientSubscriptionId?: string | null;
  notificationCenterId: string;
};
export type NotificationCenter_Subscription$variables = {
  input: NotificationCenterUpdatedSubscriptionInput;
};
export type NotificationCenter_Subscription$data = {
  readonly notificationCenterUpdated: {
    readonly notificationCenter: {
      readonly numUnreadNotifications: number | null;
      readonly numUnseenNotifications: number | null;
    } | null;
  } | null;
};
export type NotificationCenter_Subscription = {
  response: NotificationCenter_Subscription$data;
  variables: NotificationCenter_Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numUnreadNotifications",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numUnseenNotifications",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationCenter_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationCenterUpdatedSubscriptionPayload",
        "kind": "LinkedField",
        "name": "notificationCenterUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationCenter",
            "kind": "LinkedField",
            "name": "notificationCenter",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationCenter_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationCenterUpdatedSubscriptionPayload",
        "kind": "LinkedField",
        "name": "notificationCenterUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationCenter",
            "kind": "LinkedField",
            "name": "notificationCenter",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cad6dd069175ab678c30e974725fd812",
    "id": null,
    "metadata": {},
    "name": "NotificationCenter_Subscription",
    "operationKind": "subscription",
    "text": "subscription NotificationCenter_Subscription(\n  $input: NotificationCenterUpdatedSubscriptionInput!\n) {\n  notificationCenterUpdated(input: $input) {\n    notificationCenter {\n      numUnreadNotifications\n      numUnseenNotifications\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "abcbbea4624b88dabb386d741ca78257";

export default node;
