import Link from '@bfly/ui2/Link';
import SideMenu from '@bfly/ui2/SideMenu';
import clsx from 'clsx';
import { useRouter } from 'found';
import { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { useExamRoutes } from 'routes/exam';
import { StudyListFilter } from 'utils/StudyConstants';
import { StudiesQuery, TodoQuery } from 'utils/studiesSelection';

interface Props {
  id?: string;
  message: MessageDescriptor;
  nextQuery?: StudiesQuery | TodoQuery;
  badge?: number | null;
  icon?: ReactNode;
  routeName: StudyListFilter;
  className?: string;
}

const SectionFilterItem = ({
  id,
  nextQuery,
  message,
  badge,
  routeName,
  icon,
  className,
}: Props) => {
  const {
    match: { params },
  } = useRouter();
  const { status } = params;
  const examRoutes = useExamRoutes();

  return (
    <Link
      to={{
        pathname: examRoutes.examLists({
          organizationSlug: params.organizationSlug ?? '-',
          status: routeName,
        }),
        // query parameter should accept array as an argument but it doesn't
        query: nextQuery as any,
      }}
    >
      {(linkProps) => (
        <SideMenu.ListItem id={id}>
          <SideMenu.Link
            className={clsx('tabular-nums', className)}
            badge={badge || undefined}
            icon={icon}
            {...linkProps}
            active={status === routeName}
          >
            <FormattedMessage {...message} />
          </SideMenu.Link>
        </SideMenu.ListItem>
      )}
    </Link>
  );
};

export default SectionFilterItem;
