/**
 * @generated SignedSource<<572d21012b3f5172df3b06bc37080db0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MoveStudyModal_organization$data = {
  readonly id: string;
  readonly " $fragmentType": "MoveStudyModal_organization";
};
export type MoveStudyModal_organization$key = {
  readonly " $data"?: MoveStudyModal_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MoveStudyModal_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MoveStudyModal_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "cc04ef4e77447b230f4c21ccdeb58121";

export default node;
