/**
 * @generated SignedSource<<1b97e6ec91933a02b1985eb6fe43b266>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type viewerContext_viewer$data = {
  readonly acceptedTermsAndConditionsUrl: string | null;
  readonly canAccessEduDashboard: boolean | null;
  readonly canAccessEduDashboardWithEduManagementPermissions: boolean | null;
  readonly canAccessLms: boolean | null;
  readonly didInteractCommentBox: boolean | null;
  readonly didViewEduPage: boolean | null;
  readonly didViewStudyDetail: boolean | null;
  readonly domain: {
    readonly id: string;
    readonly name: string | null;
    readonly organizationConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly handle: string | null;
          readonly id: string;
          readonly name: string | null;
          readonly setupAt: string | null;
          readonly slug: string | null;
          readonly subscription: {
            readonly planType: string | null;
          } | null;
          readonly viewerCanQa: boolean | null;
          readonly viewerIsMember: boolean | null;
          readonly viewerLaunchDarklyConfig: {
            readonly state: Record<string, any> | null;
          } | null;
          readonly viewerPermissions: {
            readonly accessAllOrganizations: PermissionLevel | null;
            readonly archiveManagement: PermissionLevel | null;
            readonly bulkDataExport: PermissionLevel | null;
            readonly connectivityManagement: PermissionLevel | null;
            readonly credentialManagement: PermissionLevel | null;
            readonly dataDeletion: PermissionLevel | null;
            readonly dataExport: PermissionLevel | null;
            readonly educationManagement: PermissionLevel | null;
            readonly examTypeManagement: PermissionLevel | null;
            readonly fleetManagement: PermissionLevel | null;
            readonly iqCareRestrictedExperience: PermissionLevel | null;
            readonly loginAndSecurityManagement: PermissionLevel | null;
            readonly organizationManagement: PermissionLevel | null;
            readonly qa: PermissionLevel | null;
            readonly qaManagement: PermissionLevel | null;
            readonly signing: PermissionLevel | null;
            readonly studyDocumentation: PermissionLevel | null;
            readonly studyDocumentationManagement: PermissionLevel | null;
            readonly studyReversion: PermissionLevel | null;
            readonly userManagement: PermissionLevel | null;
            readonly userPermissions: PermissionLevel | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly viewerCanQa: boolean | null;
    readonly viewerPermissions: {
      readonly accessAllOrganizations: PermissionLevel | null;
      readonly archiveManagement: PermissionLevel | null;
      readonly bulkDataExport: PermissionLevel | null;
      readonly connectivityManagement: PermissionLevel | null;
      readonly credentialManagement: PermissionLevel | null;
      readonly dataDeletion: PermissionLevel | null;
      readonly dataExport: PermissionLevel | null;
      readonly educationManagement: PermissionLevel | null;
      readonly examTypeManagement: PermissionLevel | null;
      readonly fleetManagement: PermissionLevel | null;
      readonly iqCareRestrictedExperience: PermissionLevel | null;
      readonly loginAndSecurityManagement: PermissionLevel | null;
      readonly organizationManagement: PermissionLevel | null;
      readonly qa: PermissionLevel | null;
      readonly qaManagement: PermissionLevel | null;
      readonly signing: PermissionLevel | null;
      readonly studyDocumentation: PermissionLevel | null;
      readonly studyDocumentationManagement: PermissionLevel | null;
      readonly studyReversion: PermissionLevel | null;
      readonly userManagement: PermissionLevel | null;
      readonly userPermissions: PermissionLevel | null;
    } | null;
  } | null;
  readonly eligibleForTermsAndConditions: boolean | null;
  readonly email: string | null;
  readonly hasAcceptedLatestEula: boolean | null;
  readonly hasUnexpiredMembership: boolean | null;
  readonly id: string;
  readonly memberships: ReadonlyArray<{
    readonly organization: {
      readonly handle: string | null;
      readonly id: string;
      readonly name: string | null;
      readonly setupAt: string | null;
      readonly slug: string | null;
      readonly subscription: {
        readonly planType: string | null;
      } | null;
      readonly viewerCanQa: boolean | null;
      readonly viewerIsMember: boolean | null;
      readonly viewerLaunchDarklyConfig: {
        readonly state: Record<string, any> | null;
      } | null;
      readonly viewerPermissions: {
        readonly accessAllOrganizations: PermissionLevel | null;
        readonly archiveManagement: PermissionLevel | null;
        readonly bulkDataExport: PermissionLevel | null;
        readonly connectivityManagement: PermissionLevel | null;
        readonly credentialManagement: PermissionLevel | null;
        readonly dataDeletion: PermissionLevel | null;
        readonly dataExport: PermissionLevel | null;
        readonly educationManagement: PermissionLevel | null;
        readonly examTypeManagement: PermissionLevel | null;
        readonly fleetManagement: PermissionLevel | null;
        readonly iqCareRestrictedExperience: PermissionLevel | null;
        readonly loginAndSecurityManagement: PermissionLevel | null;
        readonly organizationManagement: PermissionLevel | null;
        readonly qa: PermissionLevel | null;
        readonly qaManagement: PermissionLevel | null;
        readonly signing: PermissionLevel | null;
        readonly studyDocumentation: PermissionLevel | null;
        readonly studyDocumentationManagement: PermissionLevel | null;
        readonly studyReversion: PermissionLevel | null;
        readonly userManagement: PermissionLevel | null;
        readonly userPermissions: PermissionLevel | null;
      } | null;
    } | null;
  } | null> | null;
  readonly placeOfWork: {
    readonly id: string;
  } | null;
  readonly placeOfWorkFreeform: string | null;
  readonly profile: {
    readonly handle: string | null;
    readonly id: string;
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  } | null;
  readonly setupAt: string | null;
  readonly shouldSeeMembershipsExpiredBanner: boolean | null;
  readonly shouldSeeNonPaidBanner: boolean | null;
  readonly shouldSeeProCustomWithoutEduBanner: boolean | null;
  readonly specialty: {
    readonly key: string | null;
  } | null;
  readonly userPreferences: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly preferenceKey: string | null;
        readonly value: any | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "viewerContext_viewer";
};
export type viewerContext_viewer$key = {
  readonly " $data"?: viewerContext_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"viewerContext_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "setupAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanQa",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessAllOrganizations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archiveManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bulkDataExport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connectivityManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "credentialManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataDeletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataExport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "educationManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examTypeManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fleetManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iqCareRestrictedExperience",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loginAndSecurityManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qaManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyDocumentation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyDocumentationManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyReversion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userManagement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userPermissions",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  (v3/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  (v1/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerIsMember",
    "storageKey": null
  },
  (v5/*: any*/),
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "platform",
        "value": "CLOUD"
      }
    ],
    "concreteType": "LaunchDarklyConfig",
    "kind": "LinkedField",
    "name": "viewerLaunchDarklyConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      }
    ],
    "storageKey": "viewerLaunchDarklyConfig(platform:\"CLOUD\")"
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationSubscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "planType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "viewerContext_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptedTermsAndConditionsUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessEduDashboard",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessEduDashboardWithEduManagementPermissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessLms",
      "storageKey": null
    },
    {
      "alias": "didInteractCommentBox",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "web.interact_comment_box"
        }
      ],
      "kind": "ScalarField",
      "name": "didExperience",
      "storageKey": "didExperience(key:\"web.interact_comment_box\")"
    },
    {
      "alias": "didViewEduPage",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "web.view_edu_page"
        }
      ],
      "kind": "ScalarField",
      "name": "didExperience",
      "storageKey": "didExperience(key:\"web.view_edu_page\")"
    },
    {
      "alias": "didViewStudyDetail",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "web.view_study_detail"
        }
      ],
      "kind": "ScalarField",
      "name": "didExperience",
      "storageKey": "didExperience(key:\"web.view_study_detail\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eligibleForTermsAndConditions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAcceptedLatestEula",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasUnexpiredMembership",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PlaceOfWork",
      "kind": "LinkedField",
      "name": "placeOfWork",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeOfWorkFreeform",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldSeeMembershipsExpiredBanner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldSeeNonPaidBanner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldSeeProCustomWithoutEduBanner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Specialty",
      "kind": "LinkedField",
      "name": "specialty",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_userProfile"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerMembership",
      "kind": "LinkedField",
      "name": "memberships",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        (v2/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationConnection",
          "kind": "LinkedField",
          "name": "organizationConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPreferencesConnection",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserPreferencesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserPreferences",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "preferenceKey",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "1fbcdfe3c55ae780dface6f6303a41c9";

export default node;
