import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import type { SelectedStudyItem } from 'utils/StudySelection';

export type SelectedItemsByKey<T = any> = Map<string, T>;

type MultiSelect<T> = {
  selectedItemsByKey: SelectedItemsByKey<T>;
  setSelectedItemsByKey: Dispatch<SetStateAction<SelectedItemsByKey<T>>>;
};

const MultiSelectContext = React.createContext<MultiSelect<any>>(null as any);

export default MultiSelectContext;

export function MultiSelectProvider({ children }) {
  const [selectedItemsByKey, setSelectedItemsByKey] =
    useState<SelectedItemsByKey>(new Map());

  const contextValue = useMemo(
    () => ({
      selectedItemsByKey,
      setSelectedItemsByKey,
    }),
    [selectedItemsByKey],
  );

  return (
    <MultiSelectContext.Provider value={contextValue}>
      {children}
    </MultiSelectContext.Provider>
  );
}

export function useMultiSelect<T = any>() {
  const context = useContext(MultiSelectContext) as MultiSelect<T>;

  if (!context) {
    throw new Error(
      'useMultiSelect must be called within MultiSelectContext provider',
    );
  }

  return context;
}

export function useFilteredSelectedItemsByKey<T>(dataKeys: string[]) {
  const { selectedItemsByKey, setSelectedItemsByKey } = useMultiSelect<T>();

  // TODO: If a person selects studies through StudySearchList and then
  //  navigates to an archive, the studies will still be selected. We should
  //  probably clear selectedItemsByKey whenever the search term or route
  //  changes.

  const dataKeySet = useMemo(() => new Set(dataKeys), [dataKeys]);

  const validSelectedItemsByKey = useMemo(
    () =>
      new Map(
        Array.from(selectedItemsByKey.entries()).filter(([key]) =>
          dataKeySet.has(key),
        ),
      ),
    [selectedItemsByKey, dataKeySet],
  );

  if (validSelectedItemsByKey.size < selectedItemsByKey.size) {
    setSelectedItemsByKey(validSelectedItemsByKey);
  }

  return selectedItemsByKey;
}

export function useMultiSelectable(key: string) {
  const { selectedItemsByKey, setSelectedItemsByKey } =
    useMultiSelect<SelectedStudyItem>();

  const selected = selectedItemsByKey.has(key);

  const handleChange = useCallback(
    (nextSelected: boolean, value) => {
      setSelectedItemsByKey((prevSelectedItemsByKey) => {
        const nextSelectedItemsByKey = new Map(prevSelectedItemsByKey);

        if (nextSelected) {
          nextSelectedItemsByKey.set(key, value);
        } else {
          nextSelectedItemsByKey.delete(key);
        }

        return nextSelectedItemsByKey;
      });
    },
    [key, setSelectedItemsByKey],
  );

  const handleShiftClick = useCallback(
    (allSelected: Array<SelectedStudyItem>) => {
      setSelectedItemsByKey((prevSelectedItemsByKey) => {
        const nextSelectedItemsByKey = new Map(prevSelectedItemsByKey);
        const prevSelected = Array.from(
          prevSelectedItemsByKey,
          ([studyId, studyValue]) => ({
            // @ts-ignore
            studyId,
            ...studyValue,
          }),
        );
        // handleShiftClick is being called on shift+click so on the second time, in that case our "first" is the last selected from the row context
        const firstSelected = prevSelected[prevSelected.length - 1];
        const firstSelectedIdx = allSelected.findIndex(
          ({ studyId }) => studyId === firstSelected?.studyId,
        );
        const lastSelectedIdx = allSelected.findIndex(
          ({ studyId }) => studyId === key,
        );
        const studiesBetween = allSelected.slice(
          firstSelectedIdx + 1,
          lastSelectedIdx,
        );

        for (const study of studiesBetween) {
          nextSelectedItemsByKey.set(study.studyId, study);
        }

        return nextSelectedItemsByKey;
      });
    },
    [key, setSelectedItemsByKey],
  );

  return {
    selected,
    onChange: handleChange,
    onShiftClick: handleShiftClick,
  };
}
