import { InferType, array, object, string } from 'yup';

import dateRange from 'schemas/dateRange';
import { StudyStatus } from 'utils/StudyConstants';

const arrayOfStrings = array(string().defined().required()).nullable();

const arrayOfStatuses = array(
  string().enum(Object.values(StudyStatus)).defined(),
).nullable();

const emptyArrayToNull = <T>(arr: T[] | null) => (arr?.length ? arr : null);

export const filterSchema = object({
  dateRange: dateRange.nullable().default(null),
  authors: array(
    object({
      id: string().required(),
      handle: string().required(),
      name: string().required(),
    }).required(),
  ).nullable(),
  archives: arrayOfStrings,
  examTypes: arrayOfStrings,
  status: arrayOfStatuses,
  organization: array(
    object({
      id: string().required(),
      handle: string().required(),
      name: string().required(),
      slug: string().required(),
    }),
  )
    .nullable()
    .transform(emptyArrayToNull),
});

export type QuickFilters = InferType<typeof filterSchema>;
