/**
 * @generated SignedSource<<f11e4b0e01b4b477a4e9363fb605b60b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermanentlyDeleteStudyControl_study$data = {
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly slug: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canPermanentlyDeleteStudy">;
  readonly " $fragmentType": "PermanentlyDeleteStudyControl_study";
};
export type PermanentlyDeleteStudyControl_study$key = {
  readonly " $data"?: PermanentlyDeleteStudyControl_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"PermanentlyDeleteStudyControl_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PermanentlyDeleteStudyControl_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canPermanentlyDeleteStudy",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "f423845a43cbf85549aa797618476d34";

export default node;
