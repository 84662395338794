import Button from '@bfly/ui2/Button';
import { useField } from '@bfly/ui2/Form';
import Text, { TextProps } from '@bfly/ui2/Text';
import formatName, {
  HumanName,
  joinNonemptyStrings,
} from '@bfly/utils/formatName';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PromptButton from 'components/PromptButton';

import { SplitStudyPatientField_sourceStudy$data as SourceStudy } from './__generated__/SplitStudyPatientField_sourceStudy.graphql';

interface Props {
  sourceStudy: SourceStudy;
  onEditPatient: () => void;
}

interface PatientNameProps extends TextProps {
  patient: HumanName | null;
  patientId: string | null;
}

function PatientName({ patient, patientId, ...props }: PatientNameProps) {
  const patientName = patient && formatName(patient);

  const children: (JSX.Element | string)[] = patientName
    ? [<Text key="patientName">{patientName}</Text>]
    : [
        <FormattedMessage
          key="noPatientName"
          id="SplitStudyPatientField.patientName.empty"
          defaultMessage="No Patient Name"
        />,
      ];

  const color = patientName ? 'inherit' : 'subtitle';
  if (patientId) {
    children.push(<Text key="patientId"> ({patientId})</Text>);
  }
  return (
    <Text variant="inherit" {...props} color={color}>
      {children}
    </Text>
  );
}

interface VetPatient {
  clientNameLast: string;
  clientOrganizationName: string;
  name: string;
}

export const getVetPatientName = (vetPatient: VetPatient) =>
  joinNonemptyStrings(', ', [
    vetPatient.clientNameLast || vetPatient.clientOrganizationName,
    vetPatient.name,
  ]);

interface VetPatientNameProps extends TextProps {
  vetPatient: VetPatient | null;
}

function VetPatientName({ vetPatient, ...props }: VetPatientNameProps) {
  if (!vetPatient || !vetPatient.name) {
    return (
      <Text variant="inherit" {...props} color="subtitle">
        <FormattedMessage
          id="SplitStudyPatientField.vetPatientName.empty"
          defaultMessage="No Patient Name"
        />
      </Text>
    );
  }

  return (
    <Text variant="inherit" {...props}>
      {getVetPatientName(vetPatient)}
    </Text>
  );
}

function SplitStudyPatientField({ sourceStudy: study, onEditPatient }: Props) {
  // This must be rendered inside a Form context with a schema
  // key of 'worksheets' to function properly
  const [{ value: patient }] = useField('patient');
  const [{ value: vetPatient }] = useField('vetPatient');

  const patientName = patient
    ? {
        nameFirst: patient.nameFirst,
        nameLast: patient.nameLast,
        nameSuffix: patient.nameSuffix,
        namePrefix: patient.namePrefix,
        nameMiddle: patient.nameMiddle,
      }
    : null;

  const patientId = patient ? patient.medicalRecordNumber || patient.id : null;

  const vetPatientName = vetPatient
    ? {
        clientNameLast: vetPatient.clientNameLast,
        clientOrganizationName: vetPatient.clientOrganizationName,
        name: vetPatient.name,
      }
    : null;

  const hasPatientIdentity =
    (patientName &&
      (patientName.nameFirst || patientName.nameLast || patientId)) ||
    (vetPatientName && vetPatientName.name);

  return (
    <>
      {!hasPatientIdentity ? (
        <PromptButton
          data-bni-id="SplitStudyAddPatient"
          variant="primary"
          className="p-2 rounded min-w-[20rem]"
          onClick={() => onEditPatient()}
        >
          Add patient
        </PromptButton>
      ) : (
        <Button
          variant="text-secondary"
          className="p-2 rounded bg-grey-80 drop-shadow-md"
          data-bni-id="SplitStudyEditPatient"
          onClick={() => onEditPatient()}
        >
          {study.practiceType === 'HUMAN' ? (
            <PatientName
              variant="body"
              truncate
              className="w-full block"
              color="headline"
              patient={patientName}
              patientId={patientId}
            />
          ) : (
            <VetPatientName
              truncate
              variant="body"
              className="w-full block"
              vetPatient={vetPatientName}
              color="headline"
            />
          )}
        </Button>
      )}
    </>
  );
}

export default createFragmentContainer(SplitStudyPatientField, {
  sourceStudy: graphql`
    fragment SplitStudyPatientField_sourceStudy on Study {
      practiceType
      patient {
        ...PatientName_patient
        medicalRecordNumber
        id
      }
      vetPatient {
        ...VetPatientName_vetPatient
      }
    }
  `,
});
