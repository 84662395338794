/**
 * @generated SignedSource<<d80172e14ecf10fcec0a6233b797cd09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyImagePopoverThumbnail_studyImage$data = {
  readonly burnedInFile?: {
    readonly dataUrl: string | null;
    readonly isVideo: boolean | null;
    readonly " $fragmentSpreads": FragmentRefs<"Thumbnail_image">;
  } | null;
  readonly handle?: string | null;
  readonly iqMetadata?: {
    readonly probeSerial: string | null;
  } | null;
  readonly " $fragmentType": "StudyImagePopoverThumbnail_studyImage";
};
export type StudyImagePopoverThumbnail_studyImage$key = {
  readonly " $data"?: StudyImagePopoverThumbnail_studyImage$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImagePopoverThumbnail_studyImage">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "handle",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "BurnedInFile",
    "kind": "LinkedField",
    "name": "burnedInFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isVideo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataUrl",
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Thumbnail_image"
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "IqMetadata",
    "kind": "LinkedField",
    "name": "iqMetadata",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "probeSerial",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyImagePopoverThumbnail_studyImage",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "StudyImage",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "EduStudyImage",
      "abstractKey": null
    }
  ],
  "type": "StudyImageInterface",
  "abstractKey": "__isStudyImageInterface"
};
})();

(node as any).hash = "efedea9dc71b7c8c0eb103cc6deec098";

export default node;
