import notNullish from '@bfly/utils/notNullish';
import { LocationDescriptorObject } from 'farce';
import { InferType, array, object, string } from 'yup';

import { type Match } from 'components/Route';
import dateRangeSchema, { serializeToDateTimeRange } from 'schemas/dateRange';
import { useLocationState } from 'utils/LocationUtils';
import { StudyStatus } from 'utils/StudyConstants';

const emptyArrayToNull = <T>(arr: T[] | null) => (arr?.length ? arr : null);

const arrayOfString = array(string().required()).nullable();

export const statsFilterSchema = object({
  capturedAt: dateRangeSchema.nullable().default(null),
  examType: arrayOfString,
  worksheetTemplate: arrayOfString,
  organization: array(
    object({
      id: string().required(),
      handle: string().required(),
      name: string().required(),
    }),
  )
    .nullable()
    .transform(emptyArrayToNull),
  archive: arrayOfString,
  credentialGroup: arrayOfString,
  author: array(
    object({
      id: string().required(),
      handle: string().required(),
      name: string().required(),
    }),
  )
    .nullable()
    .transform(emptyArrayToNull),
  status: array()
    .of(string().enum(Object.values(StudyStatus)))
    .nullable(),
});

export type StatsFilters = InferType<typeof statsFilterSchema>;

export function useStatsFilters() {
  const [filters] = useLocationState(statsFilterSchema, 'filter');
  return filters;
}

export function getStatsFilters(location: LocationDescriptorObject) {
  return location.state?.filter as StatsFilters | undefined;
}

export function serialize(filters?: StatsFilters) {
  return {
    ...filters,
    capturedAt: serializeToDateTimeRange(filters?.capturedAt ?? null),
    author: filters?.author?.filter(notNullish).map((a) => a.id),
    organization: filters?.organization
      ?.filter(notNullish)
      .map((o) => o.handle),
  };
}

export function prepareStudyStatisticsVariables(
  variables: any,
  { location }: Match,
) {
  const filter = serialize(getStatsFilters(location));
  return { ...variables, filter };
}

export function prepareAllTimeStatsVariables(
  variables: any,
  { location }: Match,
) {
  const filter = serialize(getStatsFilters(location));
  return { ...variables, filter, showWorksheetStats: false };
}

export function useFilterState() {
  return useLocationState(statsFilterSchema, 'filter');
}
