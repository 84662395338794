import { createContext, useContext } from 'react';
import { graphql } from 'react-relay';

import OmitFragType from './OmitFragType';
import { viewerContext_organization$data } from './__generated__/viewerContext_organization.graphql';
import { viewerContext_viewer$data } from './__generated__/viewerContext_viewer.graphql';
import {
  Permission,
  PermissionLevel,
  ViewerPermissions,
  hasPermissionLevel,
} from './viewerPermissions';

export type Viewer = OmitFragType<viewerContext_viewer$data>;
export type Organization = OmitFragType<viewerContext_organization$data>;

export interface ViewerContext extends Omit<Viewer, 'userPreferences'> {
  // these are all the organizations the user has access to
  organizations: Organization[];
  // we convert the userPreferences data to a cleaner Record<string, any>
  userPreferences: Record<string, any>;
  organization: Organization | null;
  tenant: Viewer['domain'] | Organization | null;
  permissions: ViewerPermissions;
  permissionsAllOrganizations: ViewerPermissions;
}

export const hasPermissionWithState = (
  viewerContext: ViewerContext | null | undefined,
  permission: Permission,
  level?: PermissionLevel,
  permissionsOn: 'domain' | 'organization' | 'tenant' = 'tenant',
) => {
  if (!viewerContext) return false;
  return hasPermissionLevel(
    viewerContext[permissionsOn]?.viewerPermissions,
    permission,
    level,
  );
};

export function canAccessEnterpriseSettings(
  viewer: ViewerContext | null,
  permission?: Permission | null,
) {
  if (!viewer?.domain) return false;

  const hasPermission = (
    permission2?: Permission | null,
    level?: PermissionLevel,
  ) =>
    !!permission2 &&
    hasPermissionWithState(viewer, permission2, level, 'domain');

  if (permission) {
    return hasPermission(permission);
  }

  return (
    hasPermission('credentialManagement') ||
    hasPermission('connectivityManagement') ||
    hasPermission('examTypeManagement') ||
    hasPermission('fleetManagement') ||
    hasPermission('loginAndSecurityManagement') ||
    hasPermission('qaManagement') ||
    hasPermission('studyDocumentation') ||
    hasPermission('studyDocumentationManagement') ||
    hasPermission('userManagement') ||
    hasPermission('userPermissions')
  );
}

export function canAccessOrganizationSettings(
  viewer: ViewerContext | null,
  permission?: Permission | null,
) {
  if (!viewer?.organization) return false;

  const hasPermission = (
    permission2?: Permission | null,
    level?: PermissionLevel,
  ) =>
    !!permission2 &&
    hasPermissionWithState(viewer, permission2, level, 'organization');

  if (permission) {
    return hasPermission(permission);
  }

  return (
    hasPermission('credentialManagement') ||
    hasPermission('connectivityManagement') ||
    hasPermission('examTypeManagement') ||
    hasPermission('fleetManagement') ||
    hasPermission('loginAndSecurityManagement') ||
    hasPermission('qaManagement') ||
    hasPermission('studyDocumentation') ||
    hasPermission('studyDocumentationManagement') ||
    hasPermission('userManagement') ||
    hasPermission('userPermissions')
  );
}

const viewerContext = createContext<ViewerContext | null>(null);

export const ViewerContextProvider = viewerContext.Provider;

export function useViewerContext() {
  const context = useContext(viewerContext);
  if (!context) {
    throw new Error('useViewer must be called within viewerContext');
  }
  return context;
}

/**
 * Same as useViewer but won't throw an error when viewer context is unavailable.
 * Use this on pages that are outside of an organization, e.g. account settings.
 */
export function useViewerAllowMissingContext() {
  return useContext(viewerContext);
}

export function usePermissions() {
  const viewer = useViewerAllowMissingContext();

  const hasPermission = (
    permission2?: Permission | null,
    level?: PermissionLevel,
  ) => !!permission2 && hasPermissionWithState(viewer, permission2, level);

  return {
    hasPermission,

    hasBasicPermission: (permission: Permission) =>
      hasPermission(permission, 'BASIC'),

    hasAdminPermission: (permission: Permission) =>
      hasPermission(permission, 'ADMIN'),

    canAccessEnterpriseSettings: (permission?: Permission | null) =>
      canAccessEnterpriseSettings(viewer, permission),

    canAccessOrganizationSettings: () => canAccessOrganizationSettings(viewer),

    viewerProfileId: viewer?.profile?.id,

    permissionLevel: (permission: Permission): PermissionLevel | null =>
      viewer?.permissions[permission] || null,
  };
}

export const viewerPermissions = graphql`
  fragment viewerContext_viewerPermissions on ViewerPermissions {
    accessAllOrganizations
    archiveManagement
    bulkDataExport
    connectivityManagement
    credentialManagement
    dataDeletion
    dataExport
    educationManagement
    examTypeManagement
    fleetManagement
    iqCareRestrictedExperience
    loginAndSecurityManagement
    organizationManagement
    qa
    qaManagement
    signing
    studyDocumentation
    studyDocumentationManagement
    studyReversion
    userManagement
    userPermissions
  }
`;

export const organizationFragment = graphql`
  fragment viewerContext_organization on Organization {
    id
    handle
    name
    slug
    setupAt
    viewerCanQa
    viewerIsMember
    viewerPermissions {
      ...viewerContext_viewerPermissions @relay(mask: false)
    }
    viewerLaunchDarklyConfig(platform: CLOUD) {
      state
    }
    subscription {
      planType
    }
  }
`;

export const viewerFragment = graphql`
  fragment viewerContext_viewer on Viewer {
    acceptedTermsAndConditionsUrl
    canAccessEduDashboard
    canAccessEduDashboardWithEduManagementPermissions
    canAccessLms
    didInteractCommentBox: didExperience(key: "web.interact_comment_box")
    didViewEduPage: didExperience(key: "web.view_edu_page")
    didViewStudyDetail: didExperience(key: "web.view_study_detail")
    eligibleForTermsAndConditions
    email
    hasAcceptedLatestEula
    hasUnexpiredMembership
    id
    placeOfWork {
      id
    }
    placeOfWorkFreeform
    setupAt
    shouldSeeMembershipsExpiredBanner
    shouldSeeNonPaidBanner
    shouldSeeProCustomWithoutEduBanner
    specialty {
      key
    }
    profile {
      name
      id
      handle
      ...Avatar_userProfile
    }
    memberships {
      organization {
        ...viewerContext_organization @relay(mask: false)
      }
    }
    domain {
      id
      viewerCanQa
      name
      viewerPermissions {
        ...viewerContext_viewerPermissions @relay(mask: false)
      }
      organizationConnection {
        edges {
          node {
            ...viewerContext_organization @relay(mask: false)
          }
        }
      }
    }
    userPreferences {
      edges {
        node {
          id
          value
          preferenceKey
        }
      }
    }
  }
`;
