import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Abbr from 'components/Abbr';
import VetPatientWeight from 'components/VetPatientWeight';
import VetSpecies from 'components/VetSpecies';
import messages, {
  neuterStatusMessages,
  sexMessages,
} from 'messages/vetPatient';
import joinNonemptyNodes from 'utils/joinNonemptyNodes';

import { VetPatientInfo_vetPatient$data as VetPatient } from './__generated__/VetPatientInfo_vetPatient.graphql';

interface Props {
  vetPatient: VetPatient;
  accessionNumber: string | null;
  verbose?: boolean;
}

function VetPatientInfo({ vetPatient, accessionNumber, verbose }: Props) {
  const {
    patientIdNumber,
    microchipNumber,
    species,
    speciesFreeform,
    breed,
    birthDate,
    sex,
    neuterStatus,
    weightKilograms,
  } = vetPatient;

  return (
    <>
      {joinNonemptyNodes(', ', [
        patientIdNumber && (
          <React.Fragment key="patientIdNumber">
            <FormattedMessage {...messages.patientIdNumber} />:{' '}
            {patientIdNumber}
          </React.Fragment>
        ),
        verbose && microchipNumber && (
          <React.Fragment key="microchipNumber">
            <FormattedMessage {...messages.microchipNumber} />:{' '}
            {microchipNumber}
          </React.Fragment>
        ),
        accessionNumber && (
          <React.Fragment key="accessionNumber">
            <Abbr label={messages.accessionNumberLabel}>
              <FormattedMessage
                id="study.vet.accessionNumber"
                defaultMessage="Acc #"
              />
            </Abbr>
            : {accessionNumber}
          </React.Fragment>
        ),
        species && (species !== 'UNKNOWN' || speciesFreeform) && (
          <React.Fragment key="species">
            <FormattedMessage {...messages.species} />:{' '}
            <VetSpecies vetPatient={vetPatient} />
          </React.Fragment>
        ),
        breed && (
          <React.Fragment key="breed">
            <FormattedMessage {...messages.breed} />: {breed}
          </React.Fragment>
        ),
        verbose && birthDate && (
          <React.Fragment key="birthDate">
            <FormattedMessage {...messages.birthDate} />:{' '}
            <FormattedDate value={birthDate} timeZone="UTC" />
          </React.Fragment>
        ),
        sex && sex !== 'UNKNOWN' && (
          <React.Fragment key="sex">
            <FormattedMessage {...messages.sex} />:{' '}
            <FormattedMessage {...sexMessages[sex]} />
          </React.Fragment>
        ),
        verbose && neuterStatus && (
          <React.Fragment key="neuterStatus">
            <FormattedMessage {...messages.neuterStatus} />:{' '}
            <FormattedMessage {...neuterStatusMessages[neuterStatus]} />
          </React.Fragment>
        ),
        verbose && weightKilograms && (
          <React.Fragment key="weightKilograms">
            <FormattedMessage {...messages.weight} />:{' '}
            <VetPatientWeight vetPatient={vetPatient} />
          </React.Fragment>
        ),
      ])}
    </>
  );
}

export default createFragmentContainer(VetPatientInfo, {
  vetPatient: graphql`
    fragment VetPatientInfo_vetPatient on VetPatient {
      patientIdNumber
      microchipNumber
      species
      speciesFreeform
      breed
      birthDate
      sex
      neuterStatus
      weightKilograms
      ...VetPatientWeight_vetPatient
      ...VetSpecies_vetPatient
    }
  `,
});
