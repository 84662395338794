/**
 * @generated SignedSource<<074fcc891cf5759423391884460a3626>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyImageThumbnail_image$data = {
  readonly isVideo: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"Thumbnail_image">;
  readonly " $fragmentType": "StudyImageThumbnail_image";
};
export type StudyImageThumbnail_image$key = {
  readonly " $data"?: StudyImageThumbnail_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImageThumbnail_image">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyImageThumbnail_image",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVideo",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Thumbnail_image"
    }
  ],
  "type": "ImageFileInterface",
  "abstractKey": "__isImageFileInterface"
};

(node as any).hash = "2d2cf3c36e49b480104fcffedd72eeca";

export default node;
