/**
 * @generated SignedSource<<7f452e21511b43ad00f5f4930ce1a1e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type UpdateDomainUserInput = {
  canFinalize?: boolean | null;
  canQa?: boolean | null;
  clientMutationId?: string | null;
  dicomFields?: ReadonlyArray<UpdateDomainUserInputDomainUserDicomField> | null;
  domainUserId: string;
  integrationConfigs?: ReadonlyArray<UpdateDomainUserInputDomainUserIntegrationConfig> | null;
  integrationDisplayNameFirst?: string | null;
  integrationDisplayNameLast?: string | null;
  integrationDisplayNameMiddle?: string | null;
  isNurse?: boolean | null;
  roleId?: string | null;
  type?: DomainUserType | null;
};
export type UpdateDomainUserInputDomainUserIntegrationConfig = {
  integrationId: string;
  interfaceCode: string;
};
export type UpdateDomainUserInputDomainUserDicomField = {
  templateId: string;
  value: string;
};
export type DomainMemberSettingsPageMutation$variables = {
  input: UpdateDomainUserInput;
};
export type DomainMemberSettingsPageMutation$data = {
  readonly updateDomainUserOrError: {
    readonly __typename?: string;
    readonly domainUser?: {
      readonly " $fragmentSpreads": FragmentRefs<"DomainMemberSettingsPage_domainUser">;
    } | null;
    readonly fields?: ReadonlyArray<{
      readonly message: string;
      readonly path: ReadonlyArray<string>;
    }>;
    readonly message?: string | null;
  } | null;
};
export type DomainMemberSettingsPageMutation = {
  response: DomainMemberSettingsPageMutation$data;
  variables: DomainMemberSettingsPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvalidInputErrorField",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvalidInputError",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainMemberSettingsPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateDomainUserOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUser",
                "kind": "LinkedField",
                "name": "domainUser",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DomainMemberSettingsPage_domainUser"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateDomainUserPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DomainMemberSettingsPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateDomainUserOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUser",
                "kind": "LinkedField",
                "name": "domainUser",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canQa",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canFinalize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isNurse",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembershipRole",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUserIntegrationConfig",
                    "kind": "LinkedField",
                    "name": "integrationConfigs",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Ehr",
                        "kind": "LinkedField",
                        "name": "ehr",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "interfaceCode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUserDicomField",
                    "kind": "LinkedField",
                    "name": "dicomFields",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainDicomFieldTemplate",
                        "kind": "LinkedField",
                        "name": "dicomFieldTemplate",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "integrationDisplayNameFirst",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "integrationDisplayNameMiddle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "integrationDisplayNameLast",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateDomainUserPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cfb6e9eb69571664b6a910daaf34eb9c",
    "id": null,
    "metadata": {},
    "name": "DomainMemberSettingsPageMutation",
    "operationKind": "mutation",
    "text": "mutation DomainMemberSettingsPageMutation(\n  $input: UpdateDomainUserInput!\n) {\n  updateDomainUserOrError(input: $input) {\n    __typename\n    ... on UpdateDomainUserPayload {\n      domainUser {\n        ...DomainMemberSettingsPage_domainUser\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      ... on InvalidInputError {\n        fields {\n          message\n          path\n        }\n      }\n      ... on MaxSeatsExceededError {\n        message\n      }\n      __typename\n      message\n    }\n  }\n}\n\nfragment DomainMemberDeleteFieldSet_domainUser on DomainUser {\n  id\n  profile {\n    name\n    id\n  }\n}\n\nfragment DomainMemberDetailFieldSet_domainUser on DomainUser {\n  id\n  email\n  type\n  canQa\n  canFinalize\n  isNurse\n  integrationDisplayNameFirst\n  integrationDisplayNameMiddle\n  integrationDisplayNameLast\n}\n\nfragment DomainMemberSettingsPage_domainUser on DomainUser {\n  id\n  type\n  email\n  profile {\n    name\n    id\n  }\n  ...domainUser_defaultValue\n  ...DomainMemberDetailFieldSet_domainUser\n  ...DomainMemberDeleteFieldSet_domainUser\n}\n\nfragment domainUser_defaultValue on DomainUser {\n  type\n  email\n  canQa\n  canFinalize\n  isNurse\n  role {\n    id\n  }\n  integrationConfigs {\n    ehr {\n      id\n    }\n    interfaceCode\n  }\n  dicomFields {\n    dicomFieldTemplate {\n      id\n    }\n    value\n  }\n  integrationDisplayNameFirst\n  integrationDisplayNameMiddle\n  integrationDisplayNameLast\n}\n"
  }
};
})();

(node as any).hash = "03d57968cb8b071ac00e72b10bf711f8";

export default node;
