/**
 * @generated SignedSource<<d74b77df08ec5b5497ca97efd0e5e285>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyCaptureSelector_study$data = {
  readonly id: string;
  readonly images: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly capturedAt: string | null;
        readonly handle: string | null;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"StudyCaptureSelectorImagePreviewItem_image">;
      } | null;
    } | null> | null;
  } | null;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "StudyCaptureSelector_study";
};
export type StudyCaptureSelector_study$key = {
  readonly " $data"?: StudyCaptureSelector_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyCaptureSelector_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyCaptureSelector_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "images",
      "args": null,
      "concreteType": "StudyImageConnection",
      "kind": "LinkedField",
      "name": "imageConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyImageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyImage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "capturedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "handle",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyCaptureSelectorImagePreviewItem_image"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "1a0b944875ce73e5c19f68c3d79e48ea";

export default node;
