import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { LoginSubtitle_inviteInfo$data as InviteInfo } from './__generated__/LoginSubtitle_inviteInfo.graphql';

interface Props {
  inviteInfo: InviteInfo | null;
}

function LoginSubtitle({ inviteInfo }: Props) {
  if (!inviteInfo) {
    return null;
  }

  if (inviteInfo.usedAt) {
    return (
      <FormattedMessage
        id="login.subtitle.inviteUsed"
        defaultMessage="This invitation has already been accepted. Log in with your Butterfly account to view this organization."
      />
    );
  }

  const strong = (msg: string) => <strong>{msg}</strong>;

  if (inviteInfo.userHasButterflyLogin) {
    return (
      <FormattedMessage
        id="login.subtitle.existingUser"
        defaultMessage="You have been invited to join <strong>{organization}</strong>. Please log in to your account associated with the email address <strong>{email}</strong> to join."
        values={{
          strong,
          organization: inviteInfo.organizationInfo!.name,
          email: inviteInfo.email,
        }}
      />
    );
  }

  // TODO: Unwind logic here; this is hit the way things are set up in the userRegistration test;
  // is that realistic?
  return (
    <FormattedMessage
      id="login.subtitle.newUser"
      defaultMessage="You have been invited to join <strong>{organization}</strong>. Please sign up for a Butterfly account to join."
      values={{
        strong,
        organization: inviteInfo.organizationInfo!.name,
      }}
    />
  );
}

export default createFragmentContainer(LoginSubtitle, {
  inviteInfo: graphql`
    fragment LoginSubtitle_inviteInfo on OrganizationInviteInfo {
      email
      usedAt
      organizationInfo {
        name
      }
      userHasButterflyLogin
    }
  `,
});
