import Button from '@bfly/ui2/Button';
import FormCheck from '@bfly/ui2/FormCheck';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useToast from '@bfly/ui2/useToast';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useMutation } from 'react-relay';

import withModal from 'utils/withModal';

import { bulkDomainUsersReactivateUsersConfirmationModalMessages as messages } from '../messages/DomainMembersMessages';
import { useMemberCountUpdate } from '../utils/MemberCounts';
import BulkReactivateUsersResultsModal from './BulkDomainUsersReactivateUsersResultsModal';
import {
  BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation,
  BulkInputDomainUser,
} from './__generated__/BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation.graphql';

const bulkReactivateMutation = graphql`
  mutation BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation(
    $input: BulkUndeleteDomainUsersInput!
  ) {
    bulkUndeleteDomainUsers(input: $input) {
      users {
        edges {
          node {
            email
            integrationDisplayNameFirst
            integrationDisplayNameMiddle
            integrationDisplayNameLast
          }
        }
      }
      failures {
        email
        detail
        statusCode
        code
      }
    }
  }
`;

interface Props {
  onHide: () => void;
  onSuccess: () => void;
  domainId: string;
  selectedUsers: {
    email: string;
    integrationDisplayNameFirst: string;
    integrationDisplayNameLast: string;
  }[];
  numAvailableSeats: number | null | undefined;
}

function BulkReactivateUsersModal({
  selectedUsers,
  domainId,
  onHide,
  onSuccess,
  numAvailableSeats,
}: Props) {
  const toast = useToast();

  const memberCountUpdate = useMemberCountUpdate();

  const [mutate] =
    useMutation<BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation>(
      bulkReactivateMutation,
    );
  const [confirmedUsersEmails, setConfirmedUsersEmails] = useState(
    new Set(selectedUsers.map(({ email }) => email)),
  );
  const editedUsers = useMemo(() => [...selectedUsers], [selectedUsers]);
  const [result, setResult] = useState<any>(null);

  const toggleUser = useCallback(
    (email: string) => {
      setConfirmedUsersEmails((prev) => {
        const confirmedUsers = new Set(prev);
        if (prev.has(email)) {
          confirmedUsers.delete(email);
        } else {
          confirmedUsers.add(email);
        }
        return confirmedUsers;
      });
    },
    [setConfirmedUsersEmails],
  );

  const handleReactivateUsers = useCallback(() => {
    const users: BulkInputDomainUser[] = selectedUsers.filter(({ email }) =>
      confirmedUsersEmails.has(email),
    );

    mutate({
      variables: {
        input: {
          domainId,
          users,
        },
      },
      onError() {
        toast?.error(<FormattedMessage {...messages.toastError} />);
      },
      onCompleted({ bulkUndeleteDomainUsers }) {
        if (!bulkUndeleteDomainUsers!.failures!.length) {
          onSuccess();
          memberCountUpdate();
          toast?.success(
            <FormattedMessage
              {...messages.toastSuccess}
              values={{
                numUpdated: String(
                  bulkUndeleteDomainUsers!.users!.edges!.length,
                ),
              }}
            />,
          );
        } else {
          setResult({
            failures: bulkUndeleteDomainUsers!.failures!,
            numReactivated: bulkUndeleteDomainUsers!.users!.edges!.length,
          });
        }
      },
    });
  }, [
    onSuccess,
    mutate,
    memberCountUpdate,
    selectedUsers,
    confirmedUsersEmails,
    domainId,
    toast,
  ]);

  const maxSeatsExceeded =
    numAvailableSeats != null && confirmedUsersEmails.size > numAvailableSeats;

  if (result) {
    return <BulkReactivateUsersResultsModal {...result} onHide={onHide} />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...messages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Text as="p" className="px-5">
          <FormattedMessage {...messages.subtitle} />
        </Text>
        {maxSeatsExceeded ? (
          <Text as="p" className="px-5 p-0 mb-4 text-sm text-danger">
            <FormattedMessage
              {...messages.maxSeatsExceededError}
              values={{
                confirmedUsers: String(confirmedUsersEmails.size),
                numAvailableSeats,
                strong: (msg: string) => <strong>{msg}</strong>,
              }}
            />
          </Text>
        ) : (
          <Text as="p" className="px-5">
            <FormattedMessage
              {...messages.bodyTextReactivated}
              values={{
                numUpdated: String(confirmedUsersEmails.size),
                strong: (msg: string) => <strong>{msg}</strong>,
              }}
            />
          </Text>
        )}
        <div className="px-5 w-full">
          <table
            className="w-full"
            data-bni-id="BulkReactivateUsersConfirmationModalUserList"
          >
            <tbody>
              {editedUsers.map((user) => {
                return (
                  <tr key={user.email} className="border-b border-b-grey-70">
                    <td className="w-8 h-12">
                      <FormCheck
                        className="m-0"
                        checked={confirmedUsersEmails.has(user.email)}
                        onChange={() => toggleUser(user.email!)}
                      />
                    </td>
                    <td className="h-12">{user.email}</td>
                    <td className="h-12">
                      {user.integrationDisplayNameFirst}{' '}
                      {user.integrationDisplayNameLast}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          {!maxSeatsExceeded && (
            <Button
              data-bni-id="BulkReactivateUsersConfirmationModalConfirm"
              size="lg"
              onClick={handleReactivateUsers}
              disabled={confirmedUsersEmails.size === 0}
            >
              <FormattedMessage {...messages.confirm} />
            </Button>
          )}
          <Button
            data-bni-id="BulkReactivateUsersConfirmationModalCancel"
            variant="secondary"
            size="lg"
            onClick={onHide}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default withModal(BulkReactivateUsersModal, {
  style: { maxWidth: '600px' },
  variant: 'dark',
});
