import Button from '@bfly/ui2/Button';
import Modal from '@bfly/ui2/Modal';
import Multiselect from '@bfly/ui2/Multiselect';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import withModal from 'utils/withModal';

import {
  bulkAddOrganizationsMessages,
  bulkDomainUsersUpdateModalMessages,
} from '../messages/DomainMembersMessages';
import { Organization } from '../shared/DomainMembersGridTypes';

interface BulkAddOrganizationModalProps {
  organizations: Organization[];
  onHide: () => void;
  onSuccess: (organizations: Organization[]) => void;
}

const BulkAddOrganizationModal = ({
  organizations,
  onHide,
  onSuccess,
}: BulkAddOrganizationModalProps) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Organization[]
  >([]);

  const handleSetOrganizationMembership = useCallback(() => {
    if (selectedOrganizations) {
      onSuccess(selectedOrganizations);
    }
  }, [selectedOrganizations, onSuccess]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...bulkAddOrganizationsMessages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5 h-64">
        <Multiselect
          variant="secondary"
          menuVariant="dark"
          // inline
          placeholder={bulkAddOrganizationsMessages.placeholder}
          data-bni-id="AddOrganizationsDropdown"
          data={organizations}
          textField="name"
          className="w-full mt-0 mb-10"
          dataKey="id"
          value={selectedOrganizations}
          onChange={setSelectedOrganizations}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            data-bni-id="BulkAddOrganizationModalConfirm"
            size="lg"
            onClick={handleSetOrganizationMembership}
            disabled={selectedOrganizations.length === 0}
          >
            <FormattedMessage
              {...bulkDomainUsersUpdateModalMessages.confirm}
            />
          </Button>
          <Button
            data-bni-id="BulkAddOrganizationModalCancel"
            variant="secondary"
            size="lg"
            onClick={onHide}
          >
            <FormattedMessage {...bulkDomainUsersUpdateModalMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
};

export default withModal(BulkAddOrganizationModal, {
  variant: 'dark',
});
