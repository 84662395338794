import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import { usePageLayout } from '@bfly/ui2/Page';
import getNodes from '@bfly/utils/getNodes';
import useEventCallback from '@restart/hooks/useEventCallback';
import useRouter from 'found/useRouter';
import { useEffect, useState } from 'react';
import { createFragmentContainer, fetchQuery, graphql } from 'react-relay';
import { Environment } from 'relay-runtime';

import { AuthContextValue, useAuth } from 'components/AuthContext';
import useIsTinyViewport from 'hooks/useIsTinyViewport';

import useShareSource from '../utils/useShareSource';
import DesktopSharedImageList from './DesktopSharedImageList';
import MobileSharedImageDetail from './MobileSharedImageDetail';
import MobileSharedImageList from './MobileSharedImageList';
import SharedBundleExpiredPage from './SharedBundleExpiredPage';
import { SharedBundlePageQuery } from './__generated__/SharedBundlePageQuery.graphql';
import { SharedBundlePage_sharedBundleOrError$data as SharedBundleOrError } from './__generated__/SharedBundlePage_sharedBundleOrError.graphql';

const regionCache = new Map<string, Environment>();

async function fetchUpstreamByRegion(region: string, auth: AuthContextValue) {
  if (regionCache.has(region)) return regionCache.get(region);

  const resp = await fetch(
    // FIXME: The new relic snippet barfs on a URL object, we need to update it
    new URL(
      `/regions/${region}`,
      globalThis.bflyConfig.ROUTER_UPSTREAM,
    ).toString(),
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (!resp.ok) return null;
  const { apiUrl } = await resp.json();
  const env = auth.createEnvironment(apiUrl);
  regionCache.set(region, env);
  return env;
}

function useCrossRegionBundle(usData: SharedBundleOrError | null) {
  const auth = useAuth();
  const [data, setData] = useState<
    SharedBundlePageQuery['response']['sharedBundleOrError'] | null
  >(null);
  const {
    router,
    match: { params, location },
  } = useRouter();
  const region = location.query?.region?.toLowerCase();
  const hasUsData = !!usData;

  const redirect404 = useEventCallback(() => {
    router.replace({
      ...location,
      state: { foundHttpError: 404 },
    });
  });

  useEffect(() => {
    if (!region || region === 'us') {
      if (!hasUsData) {
        redirect404();
      }
      return;
    }

    fetchUpstreamByRegion(region, auth).then(async (env) => {
      if (!env) return;

      const response = await fetchQuery<SharedBundlePageQuery>(
        env,
        graphql`
          query SharedBundlePageQuery($sharedBundleHandle: String!) {
            sharedBundleOrError(handle: $sharedBundleHandle) {
              ...SharedBundlePage_sharedBundleOrError @relay(mask: false)
            }
          }
        `,
        { sharedBundleHandle: params.sharedBundleHandle },
      ).toPromise();

      if (!response?.sharedBundleOrError) {
        redirect404();
        return;
      }

      setData(response.sharedBundleOrError || null);
    });
  }, [region, auth, params.sharedBundleHandle, redirect404, hasUsData]);

  return usData || data;
}

interface Props {
  sharedBundleOrError: SharedBundleOrError | null;
}

function SharedBundlePage({ sharedBundleOrError }: Props) {
  const isTinyViewport = useIsTinyViewport();
  const { params } = useRouter().match;

  usePageLayout(isTinyViewport ? 'overflow' : 'fill');

  const isImageShare = useShareSource() === 'image_share_sheet';

  const data = useCrossRegionBundle(sharedBundleOrError);

  if (!data) {
    return <LoadingIndicator />;
  }
  if (data.type !== 'SharedBundle') {
    return <SharedBundleExpiredPage />;
  }

  const images = getNodes(data?.sharedImageConnection);

  if (!isTinyViewport) {
    return <DesktopSharedImageList images={images} />;
  }

  return params.sharedBundleImageHandle || isImageShare ? (
    <MobileSharedImageDetail images={images} hideClose={isImageShare} />
  ) : (
    <MobileSharedImageList images={images} />
  );
}

export default createFragmentContainer(SharedBundlePage, {
  sharedBundleOrError: graphql`
    fragment SharedBundlePage_sharedBundleOrError on SharedBundleOrError {
      type: __typename
      ... on SharedBundle {
        sharedImageConnection(first: 2147483647) {
          edges {
            node {
              ...DesktopSharedImageList_images
              ...MobileSharedImageDetail_images
              ...MobileSharedImageList_images
            }
          }
        }
      }
    }
  `,
});
