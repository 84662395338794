import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import MainContent from '@bfly/ui2/MainContent';
import useMountEffect from '@restart/hooks/useMountEffect';
import useRouter from 'found/useRouter';
import React from 'react';

import { useAuth } from 'components/AuthContext';
import ExternalRedirect from 'utils/ExternalRedirect';

import { isValidLogoutRedirect } from '../utils/RedirectUtils';

// XXX: logout will not occur with federated SSO
export default function OAuthLogoutPage() {
  const {
    match: { location, context },
    router,
  } = useRouter();

  const auth = useAuth();
  const redirectUri = location.query.post_logout_redirect_uri;

  useMountEffect(() => {
    const logoutAndRedirect = async () => {
      if (
        context.domainSubdomainLabel ||
        !isValidLogoutRedirect(redirectUri)
      ) {
        router.replace({
          ...location,
          state: { foundHttpError: 403 },
        });

        return;
      }

      await auth.clearAccessToken();
      ExternalRedirect.redirect(redirectUri);
    };

    logoutAndRedirect();
  });

  return (
    <MainContent>
      <LoadingIndicator />
    </MainContent>
  );
}
