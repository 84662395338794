import Layout from '@4c/layout';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import ListItem from '@bfly/ui2/ListItem';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PatientName from 'components/PatientName';

import { WorklistItemHeader_worklistItem$data as WorklistItem } from './__generated__/WorklistItemHeader_worklistItem.graphql';

interface Props {
  worklistItem: WorklistItem;
  titleTextVariant?: 'body-bold' | 'lg';
  controls?: React.ReactNode;
}

function WorklistItemHeader({
  worklistItem,
  titleTextVariant,
  controls,
}: Props) {
  return (
    <ListItem.Header>
      <ListItem.Title>
        <Text variant={titleTextVariant}>
          <PatientName patient={worklistItem} />
        </Text>
      </ListItem.Title>
      <Layout align="center">
        {worklistItem.scheduledProcedureStartTime && (
          <span className="text-body">
            <FormattedMessage
              id="worklistItem.procedureStartAt"
              defaultMessage="Procedure Start: {value}"
              values={{
                value: (
                  <FormattedDateTime
                    value={worklistItem.scheduledProcedureStartTime}
                  />
                ),
              }}
            />
          </span>
        )}
        {controls}
      </Layout>
    </ListItem.Header>
  );
}

export default createFragmentContainer(WorklistItemHeader, {
  worklistItem: graphql`
    fragment WorklistItemHeader_worklistItem on WorklistItemInterface {
      scheduledProcedureStartTime
      ...PatientName_patient
    }
  `,
});
