/**
 * @generated SignedSource<<0f6db921f1030575f098ec180f825182>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThirdPartyUltrasoundLogModal_archive$data = {
  readonly id: string;
  readonly label: string | null;
  readonly " $fragmentType": "ThirdPartyUltrasoundLogModal_archive";
};
export type ThirdPartyUltrasoundLogModal_archive$key = {
  readonly " $data"?: ThirdPartyUltrasoundLogModal_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThirdPartyUltrasoundLogModal_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThirdPartyUltrasoundLogModal_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "633bdcc474fcb732fe19674fc21106c8";

export default node;
