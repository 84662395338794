import { graphql, readInlineData } from 'react-relay';
import { SchemaOf, object } from 'yup';

import type { qaEntry_templateVersion$key as QaTemplateVersionKey } from './__generated__/qaEntry_templateVersion.graphql';
import { fieldDefinitionToSchema } from './fieldDefinition';

export type TemplateVersionSchema = SchemaOf<{
  values: Record<string, unknown>;
}>;

/**
 * Generate a yup schema from a QA template version.
 */
export function templateVersionToSchema(
  qaTemplateVersionRef: QaTemplateVersionKey,
): TemplateVersionSchema {
  const qaTemplateVersion = readInlineData(
    graphql`
      fragment qaEntry_templateVersion on QaTemplateVersion @inline {
        fieldDefinitions {
          handle
          ...fieldDefinition_fieldDefinition
        }
      }
    `,
    qaTemplateVersionRef,
  );

  const valuesShape: Record<string, any> = {};

  if (qaTemplateVersion) {
    qaTemplateVersion!.fieldDefinitions!.forEach((fieldDefinition) => {
      valuesShape[fieldDefinition!.handle!] = fieldDefinitionToSchema(
        fieldDefinition!,
      );
    });
  }

  return object({
    values: object(valuesShape).noUnknown(),
  });
}
