import Button from '@bfly/ui2/Button';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import { FormattedMessage } from 'react-intl';
import {
  QueryRenderer,
  RelayProp,
  createFragmentContainer,
  graphql,
} from 'react-relay';

import actionMessages from 'messages/actions';
import withModal from 'utils/withModal';

import StudyHistoryModalContent from './StudyHistoryModalContent';
import { StudyHistoryModalQuery } from './__generated__/StudyHistoryModalQuery.graphql';
import { StudyHistoryModal_study$data as Study } from './__generated__/StudyHistoryModal_study.graphql';

function StudyHistoryModal({
  study,
  relay,
  onHide,
}: {
  study: Study;
  relay: RelayProp;
  onHide: () => void;
}) {
  return (
    <>
      <Modal.Header data-bni-id="StudyHistoryTitle">
        <Modal.Title>
          <FormattedMessage
            id="study.history.title"
            defaultMessage="History"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <QueryRenderer<StudyHistoryModalQuery>
          environment={relay.environment}
          fetchPolicy="store-and-network"
          query={graphql`
            query StudyHistoryModalQuery($studyId: ID!) {
              study: node(id: $studyId) {
                ...StudyHistoryModalContent_study
              }
            }
          `}
          variables={{ studyId: study.id }}
          render={({ props }) =>
            props ? (
              <StudyHistoryModalContent study={props.study!} />
            ) : (
              <LoadingIndicator />
            )
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button size="lg" onClick={onHide}>
            <FormattedMessage {...actionMessages.close} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(withModal(StudyHistoryModal), {
  study: graphql`
    fragment StudyHistoryModal_study on Study {
      id
    }
  `,
});
