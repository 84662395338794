import Layout from '@4c/layout';
import CloseIcon from '@bfly/icons/CloseSheet';
import SearchIcon from '@bfly/icons/Search';
import Fade from '@bfly/ui2/Fade';
import Navbar from '@bfly/ui2/Navbar';
import SrOnly from '@bfly/ui2/SrOnly';
import useFocusManager from '@restart/hooks/useFocusManager';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import React, { useReducer, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import SwitchTransition from 'react-transition-group/SwitchTransition';
import Transition from 'react-transition-group/Transition';

import SearchBar, { SearchBarHandle } from './SearchBar';
import { AppSearch_archive$data as Archive } from './__generated__/AppSearch_archive.graphql';
import { AppSearch_organization$data as Organization } from './__generated__/AppSearch_organization.graphql';

const FADE_DURATION = 300;
const ENTER_DURATION = 500;
const EXIT_DURATION = 600;

const MAX_WIDTH = 620;

const styles = stylesheet`

  // so bad, this is the width of three buttons, padding
  // trying to show my work here ends up being more confusing than just hardcoding
  // the right offset minus the panel padding
  $offset-actions: 20.1rem;

  .wrapper {
    top: 0;
    left: theme('padding.app-panel');
    right: calc(#{$offset-actions} + theme('padding.app-panel'));
    display: flex;
    position: fixed;
    justify-content: flex-end;
    pointer-events: none;
    height: theme('height.navbar');

    @screen sm-max {
      left: theme('padding.app-panel-sm');
      right: calc(#{$offset-actions} + theme('padding.app-panel-sm'));
    }
    

    & > * {
      pointer-events: all;
    }
  }

  .panel {
    max-width: ${MAX_WIDTH}px;
    background-color: transparent;
    transition: background-color 0.3s;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  .entered {
    width: 100%;
    background-color: theme('colors.grey[85]');
  }

  .entering {
    background-color: theme('colors.grey[85]');
  }

  .exiting {
    transition-delay: ${EXIT_DURATION - FADE_DURATION}ms;
    background-color: transparent;
  }

  .collapse {
    width: 100%;

    .exited > & {
      display: none;
    }

    .entering > &,
    .exiting > & {
      position: relative;
      width: 0;
      height: auto;
      overflow: hidden;
      transition: width ${ENTER_DURATION}ms;

      @media (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }

    .entering > & {
      transition-timing-function: ease-out;
    }

    .exiting > & {
      transition-timing-function: ease-in;
    }
  }
`;

interface Props {
  organization: Organization;
  archive: Archive | null;
}

function AppSearch({ organization, archive }: Props) {
  const [searchRevision, incrementSearchRevision] = useReducer(
    (state: number, _action: void) => state + 1,
    0,
  );

  const searchRef = useRef<SearchBarHandle>(null);
  const [showSearch, setShowSearch] = useState(false);

  const getWidth = (panel: HTMLDivElement) => {
    const parent = panel.parentElement as HTMLElement;
    return `${Math.min(parent.offsetWidth, MAX_WIDTH)}px`;
  };

  const handleSearch = () => {
    setShowSearch(false);
    incrementSearchRevision();
  };

  const handleFocus = () => {
    if (!searchRef.current) return;

    searchRef.current.focus();
  };

  const focusHandlers = useFocusManager({
    isDisabled: () => false,
    onChange: (nextFocused) => {
      if (!nextFocused) setShowSearch(false);
    },
  });

  const handleEnter = (elem: HTMLDivElement) => {
    const panel = elem;
    const collapse = elem.firstElementChild as HTMLElement;

    panel.style.width = getWidth(panel);
    collapse.style.width = '0';
  };

  const handleEntering = (elem: HTMLDivElement) => {
    const collapse = elem.firstElementChild as HTMLElement;

    collapse.style.width = getWidth(elem);
  };

  const handleEntered = (elem: HTMLDivElement) => {
    const collapse = elem.firstElementChild as HTMLElement;
    collapse.style.width = '';
    handleFocus();
  };

  const handleExit = (elem: HTMLDivElement) => {
    const collapse = elem.firstElementChild as HTMLElement;
    collapse.style.width = `${collapse.offsetWidth}px`;
    // reflow
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    elem.offsetWidth;
  };

  const handleExiting = (elem: HTMLDivElement) => {
    const collapse = elem.firstElementChild as HTMLElement;
    collapse.style.width = '';
  };

  const handleExited = (elem: HTMLDivElement) => {
    const panel = elem;
    panel.style.width = '';
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape' && showSearch) {
      e.preventDefault();
      setShowSearch(false);
    }
  };

  return (
    <div {...focusHandlers} tabIndex={-1}>
      <div className={styles.wrapper}>
        <Transition
          timeout={{
            enter: ENTER_DURATION,
            exit: EXIT_DURATION,
          }}
          in={showSearch}
          onEnter={handleEnter}
          onEntering={handleEntering}
          onEntered={handleEntered}
          onExit={handleExit}
          onExiting={handleExiting}
          onExited={handleExited}
        >
          {(state) => (
            <Layout
              align="center"
              justify="flex-end"
              className={clsx(styles.panel, styles[state])}
              onKeyDown={handleKeyDown}
            >
              <div className={styles.collapse}>
                <SearchBar
                  key={searchRevision}
                  ref={searchRef}
                  organization={organization}
                  archive={archive}
                  userProfile={null}
                  studyTags={null}
                  onSearch={handleSearch}
                />
              </div>
            </Layout>
          )}
        </Transition>
      </div>
      <Navbar.Button
        data-bni-id="SearchButton"
        css="height: 3rem; width: 4.2rem;"
        onClick={() => {
          setShowSearch((s) => !s);
        }}
      >
        <SwitchTransition mode="out-in">
          {showSearch ? (
            <Fade key="close">
              <CloseIcon css="height: 1.6rem; width: 1.6rem;" />
            </Fade>
          ) : (
            <Fade key="open">
              <SearchIcon css="height: 2rem; width: 2rem;" />
            </Fade>
          )}
        </SwitchTransition>
        <SrOnly>
          {showSearch ? (
            <FormattedMessage
              id="appHeader.search.close"
              defaultMessage="Close Search"
            />
          ) : (
            <FormattedMessage
              id="appHeader.search.open"
              defaultMessage="Open Search"
            />
          )}
        </SrOnly>
      </Navbar.Button>
    </div>
  );
}

export default createFragmentContainer(AppSearch, {
  organization: graphql`
    fragment AppSearch_organization on Organization {
      ...SearchBar_organization
    }
  `,
  archive: graphql`
    fragment AppSearch_archive on Archive {
      ...SearchBar_archive
    }
  `,
});
