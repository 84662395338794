import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import MultiselectSelectButton, {
  type MultiselectSelectButtonProps,
} from 'components/MultiselectSelectButton';
import UserSuggestion from 'components/UserSuggestion';
import useSearchQuery from 'hooks/useSearchQuery';

import {
  EduStudiesAuthorSuggestionButton_MembersSearch_Query as Query,
  EduStudiesAuthorSuggestionButton_MembersSearch_Query$data as QueryData,
} from './__generated__/EduStudiesAuthorSuggestionButton_MembersSearch_Query.graphql';

export type SerializedAuthor =
  DeepNonNullable<QueryData>['tenant']['eduStudySearchSuggestions'][0]['node'];

interface Props
  extends Omit<MultiselectSelectButtonProps<SerializedAuthor>, 'data'> {
  tenantId: string;
  value: SerializedAuthor[];
  onChange: (item: SerializedAuthor[]) => void;
}

function MemberSelectButton({
  tenantId,
  value,
  onChange,
  placeholder,
  searchPlaceholder,
  ...props
}: Props) {
  const [searchTerm, setSearchTerm] = useState('');

  const { data } = useSearchQuery<Query>(
    graphql`
      query EduStudiesAuthorSuggestionButton_MembersSearch_Query(
        $search: String!
        $tenantId: ID!
      ) {
        tenant: node(id: $tenantId) {
          ... on TenantInterface {
            eduStudySearchSuggestions(search: $search) {
              node {
                ... on UserProfile {
                  id
                  name
                  ...UserSuggestion_userProfile
                }
              }
            }
          }
        }
      }
    `,
    searchTerm,
    useMemo(
      () => ({
        tenantId,
      }),
      [tenantId],
    ),
  );

  const userProfiles = data?.tenant?.eduStudySearchSuggestions
    ? data?.tenant.eduStudySearchSuggestions.map(
        (m) => m!.node! as SerializedAuthor,
      )
    : [];

  return (
    <MultiselectSelectButton<SerializedAuthor>
      {...props}
      filter={false}
      updateOnClose
      value={value}
      dataKey="id"
      data={userProfiles}
      onChange={onChange}
      searchTerm={searchTerm}
      onSearch={setSearchTerm}
      busy={!!searchTerm && !data}
      textField={(item: SerializedAuthor) => {
        return item.name ?? '';
      }}
      renderListItem={({ item }) => (
        <UserSuggestion
          userProfile={item}
          searchTerm={searchTerm}
          highlightedClassName="text-base font-extrabold"
        />
      )}
      searchPlaceholder={
        searchPlaceholder ||
        defineMessage({
          id: 'eduStudiesAuthorSuggestionButton.searchPlaceholder',
          defaultMessage: 'Search by name…',
        })
      }
      placeholder={
        placeholder || (
          <FormattedMessage
            id="eduStudiesAuthorSuggestionButton.placeholder"
            defaultMessage="All authors"
          />
        )
      }
    />
  );
}

export default MemberSelectButton;
