import FormCheck from '@bfly/ui2/FormCheck';
import useQuery from '@bfly/ui2/useQuery';
import { usePrevious } from '@restart/hooks';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import type { GraphQLTaggedNode, OperationType } from 'relay-runtime';
import { readInlineData } from 'relay-runtime';

import InterpretationRenderSwitch from 'components/InterpretationRenderSwitch';
import { useVariation } from 'components/LaunchDarklyContext';
import useToggler from 'hooks/useToggler';

import { InterpretationType } from '../../components/__generated__/ExamImagePage_InterpretationsQuery.graphql';
import StudyImageVideo from '../components/StudyImageVideo';
import { useInterpretationImage_interpretation$key as InterpretationKey } from './__generated__/useInterpretationImage_interpretation.graphql';

const DEFAULT_ATTACHMENT_URLS = [null, null, null, null];

function readInterpretation(interpretationRef: InterpretationKey) {
  return readInlineData(
    graphql`
      fragment useInterpretationImage_interpretation on InterpretationInterface
      @inline {
        attachment(attachmentKey: "BURNED_IN_IMAGE_FILE") {
          dataUrl
        }
        volumeRender: attachment(attachmentKey: "VOLUME_RENDER_FILE") {
          dataUrl
        }
        staticRender: attachment(attachmentKey: "STATIC_REPORT_FILE") {
          dataUrl
        }
        stillBurnIn: attachment(attachmentKey: "DL_STILL_IMAGE_FILE") {
          dataUrl
        }
      }
    `,
    interpretationRef,
  );
}

// XXX: matches the generated graphql type and cruftiness around shared interpretations
type Interpretations =
  | ReadonlyArray<InterpretationKey | null>
  | null
  | undefined;

type InterpretationTypes =
  | ReadonlyArray<InterpretationType | null>
  | null
  | undefined;

type UseInterpretationImageParams<T extends OperationType> = {
  query: GraphQLTaggedNode;
  variables: T['variables'];
  getInterpretations: (result: T['response']) => Interpretations;
  getInterpretationTypes: (result: T['response']) => InterpretationTypes;
  hideVideoControls?: boolean;
};

export default function useInterpretationImage<T extends OperationType>({
  query,
  variables,
  getInterpretations,
  getInterpretationTypes,
  hideVideoControls,
}: UseInterpretationImageParams<T>) {
  const { data } = useQuery<T>(query, {
    fetchPolicy: 'store-and-network',
    variables,
  });

  const canShowCaptionInterpretationReport = useVariation(
    'caption-interpretation-report',
  );

  const [showInterpretation, toggleInterpretation] = useToggler();
  const [showRender, toggleRender] = useToggler();

  const usesLabels = data
    ? getInterpretationTypes(data)?.includes('ANATOMICAL_LABEL')
    : false;

  const [
    interpretationAttachmentUrl,
    captionReportRenderAttachmentUrl,
    volumeRenderAttachmentUrl,
    interpretationStillAttachmentUrl,
  ] = useMemo(() => {
    // This method can't be written with a combination of optional chaining and
    //  non-null assertions because they interact in a buggy way.
    if (!data) {
      return DEFAULT_ATTACHMENT_URLS;
    }
    const interpretationRef = getInterpretations(data);

    if (!interpretationRef?.length) {
      return DEFAULT_ATTACHMENT_URLS;
    }

    const interpretations = interpretationRef.map(readInterpretation);

    const cineInterpretation = interpretations!.find(
      (interpretation) => interpretation!.attachment?.dataUrl,
    );

    const volumeRenderInterpretation = interpretations!.find(
      (interpretation) => interpretation!.volumeRender?.dataUrl,
    );

    const captionReportInterpretation = interpretations!.find(
      (interpretation) => interpretation!.staticRender?.dataUrl,
    );

    const stillInterpretation = interpretations!.find(
      (interpretation) => interpretation!.stillBurnIn?.dataUrl,
    );

    // If the flag is off captionInterpretationUrl will be null and caption logic will be skipped
    let captionInterpretationUrl: null | string = null;
    if (canShowCaptionInterpretationReport) {
      captionInterpretationUrl =
        captionReportInterpretation?.staticRender?.dataUrl || null;
    }

    return [
      cineInterpretation?.attachment?.dataUrl || null,
      captionInterpretationUrl,
      // This shouldn't be possible in theory but we should still guard against it.
      // We should not have two both a captionInterpretationUrl and a volumeInterpretationUrl as they have the same controls to toggle between interpretations.
      captionInterpretationUrl
        ? null
        : volumeRenderInterpretation?.volumeRender?.dataUrl || null,
      stillInterpretation?.stillBurnIn?.dataUrl || null,
    ];
  }, [data, getInterpretations, canShowCaptionInterpretationReport]);

  const previousInterpretationAttachmentUrl = usePrevious(
    interpretationAttachmentUrl,
  );

  // Toggle the interpretation video on load or change
  if (
    interpretationAttachmentUrl !== previousInterpretationAttachmentUrl &&
    !showInterpretation
  ) {
    toggleInterpretation();

    // if interpretation attachment url has changed we should also ensure that volume toggle is off
    if (showRender) {
      toggleRender();
    }
  }

  let interpretationImageUrl: string | null = null;

  if (showRender && captionReportRenderAttachmentUrl) {
    interpretationImageUrl = captionReportRenderAttachmentUrl;
  } else if (showRender && volumeRenderAttachmentUrl) {
    interpretationImageUrl = volumeRenderAttachmentUrl;
  } else if (showInterpretation && interpretationAttachmentUrl) {
    interpretationImageUrl = interpretationAttachmentUrl;
  } else if (showInterpretation && interpretationStillAttachmentUrl) {
    interpretationImageUrl = interpretationStillAttachmentUrl;
  }

  let interpretationImage: JSX.Element | null = null;
  if (interpretationImageUrl) {
    if (interpretationImageUrl === interpretationStillAttachmentUrl) {
      interpretationImage = (
        <img
          src={interpretationImageUrl}
          alt="labels-still"
          className="block w-full h-full object-contain"
        />
      );
    } else if (interpretationImageUrl === captionReportRenderAttachmentUrl) {
      interpretationImage = (
        <img
          src={interpretationImageUrl}
          alt="report"
          className="block w-full h-full object-contain"
        />
      );
    } else {
      interpretationImage = (
        <StudyImageVideo
          src={interpretationImageUrl}
          hideVideoControls={hideVideoControls}
        />
      );
    }
  }

  let showRenderControl: JSX.Element | null = null;
  if (captionReportRenderAttachmentUrl || volumeRenderAttachmentUrl) {
    showRenderControl = captionReportRenderAttachmentUrl ? (
      <InterpretationRenderSwitch
        onToggle={toggleRender}
        checked={showRender}
        interpretationSwitchItemMessage={
          <FormattedMessage
            id="interpretationSwitch.captionReport"
            defaultMessage="Report"
          />
        }
        interpretationDescription={
          <FormattedMessage
            id="interpretationSwitch.label.captionShowReport"
            defaultMessage="Show Report"
          />
        }
      />
    ) : (
      <InterpretationRenderSwitch
        onToggle={toggleRender}
        checked={showRender}
        interpretationSwitchItemMessage={
          <FormattedMessage id="interpretationSwitch.3d" defaultMessage="3D" />
        }
        interpretationDescription={
          <FormattedMessage
            id="interpretationSwitch.label.bladder"
            defaultMessage="Show 3D volume calculation"
          />
        }
      />
    );
  }

  const showInterpretationControlRequired =
    interpretationAttachmentUrl || interpretationStillAttachmentUrl;
  const interpretationControlText = usesLabels ? (
    <FormattedMessage
      defaultMessage="Anatomical Labels"
      id="useInterpretationImage.showInterpretation.labels"
    />
  ) : (
    <FormattedMessage
      defaultMessage="Calculation"
      id="useInterpretationImage.showInterpretation"
    />
  );
  const showInterpretationControl = showInterpretationControlRequired ? (
    <FormCheck
      type="checkbox"
      checked={showInterpretation || showRender}
      disabled={showRender}
      onChange={() => toggleInterpretation()}
      className="m-0"
    >
      {interpretationControlText}
    </FormCheck>
  ) : null;

  return {
    interpretationImageUrl,
    interpretationImage,
    showRenderControl,
    showInterpretationControl,
  };
}
