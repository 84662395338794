import AirwayIcon from '@bfly/icons/Airway';
import AortaIcon from '@bfly/icons/Aorta';
import AppendixIcon from '@bfly/icons/Appendix';
import ArmIcon from '@bfly/icons/Arm';
import BabyIcon from '@bfly/icons/Baby';
import BabyCircleIcon from '@bfly/icons/BabyCircle';
import BabyFrontIcon from '@bfly/icons/BabyFront';
import BladderIcon from '@bfly/icons/Bladder';
import BowelIcon from '@bfly/icons/Bowel';
import BreastIcon from '@bfly/icons/Breast';
import CardiacIcon from '@bfly/icons/Cardiac';
import DripIcon from '@bfly/icons/Drip';
import EyeIcon from '@bfly/icons/Eve';
import FastIcon from '@bfly/icons/Fast';
import KidneysIcon from '@bfly/icons/Kidneys';
import LegIcon from '@bfly/icons/Leg';
import LiverIcon from '@bfly/icons/Liver';
import LungsIcon from '@bfly/icons/Lungs';
import NerveIcon from '@bfly/icons/Nerve';
import ProbeWavesIcon from '@bfly/icons/ProbeWaves';
import SoftTissueIcon from '@bfly/icons/SoftTissue';
import StomachIcon from '@bfly/icons/Stomach';
import SyringeIcon from '@bfly/icons/Syringe';
import TesticlesIcon from '@bfly/icons/Testicles';
import ThyroidIcon from '@bfly/icons/Thyroid';
import UterusIcon from '@bfly/icons/Uterus';

interface Props {
  examTypeSystemId?: string | null;
  width?: number;
  height?: number;
  className?: string;
}

function ExamTypeIcon({ examTypeSystemId, className, height, width }: Props) {
  const iconProps = { width, height, className };
  switch (examTypeSystemId) {
    case 'abdomen':
      return <StomachIcon {...iconProps} />;
    case 'airway':
      return <AirwayIcon {...iconProps} />;
    case 'aorta':
      return <AortaIcon {...iconProps} />;
    case 'appendix':
      return <AppendixIcon {...iconProps} />;
    case 'biliary':
      return <LiverIcon {...iconProps} />;
    case 'bladder':
      return <BladderIcon {...iconProps} />;
    case 'bowel':
      return <BowelIcon {...iconProps} />;
    case 'breast':
      return <BreastIcon {...iconProps} />;
    case 'cardiac':
      return <CardiacIcon {...iconProps} />;
    case 'dvt':
      return <LegIcon {...iconProps} />;
    case 'efast':
    case 'fast':
      return <FastIcon {...iconProps} />;
    case 'gyn':
      return <UterusIcon {...iconProps} />;
    case 'iup':
      return <BabyCircleIcon {...iconProps} />;
    case 'lung':
      return <LungsIcon {...iconProps} />;
    case 'ob':
      return <BabyIcon {...iconProps} />;
    case 'ocular':
      return <EyeIcon {...iconProps} />;
    case 'pediatric-abdomen':
      return <BabyFrontIcon {...iconProps} />;
    case 'procedural-guidance':
      return <SyringeIcon {...iconProps} />;
    case 'renal':
      return <KidneysIcon {...iconProps} />;
    case 'soft-tissue':
      return <SoftTissueIcon {...iconProps} />;
    case 'testicular':
      return <TesticlesIcon {...iconProps} />;
    case 'thyroid':
      return <ThyroidIcon {...iconProps} />;
    case 'msk':
      return <ArmIcon {...iconProps} />;
    case 'nerve':
      return <NerveIcon {...iconProps} />;
    case 'us-guided-iv':
      return <DripIcon {...iconProps} />;
    default:
      return <ProbeWavesIcon {...iconProps} />;
  }
}

export default ExamTypeIcon;
