import Layout from '@4c/layout';
import DragHandlesIcon from '@bfly/icons/DragHandles';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import Popover from '@bfly/ui2/Popover';
import { RelayPagination } from '@bfly/ui2/RelayInfiniteList';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import notNullish from '@bfly/utils/notNullish';
import { ConnectionNodeType } from '@bfly/utils/types';
import { css } from 'astroturf';
import useParams from 'found/useParams';
import useRouter from 'found/useRouter';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Abbr from 'components/Abbr';
import DataGrid, { ColumnSpec } from 'components/DataGrid';
import DicomName from 'components/DicomName';
import FormattedAge from 'components/FormattedAge';
import FormattedShortDateTime from 'components/FormattedShortDateTime';
import {
  LaunchDarklyClient,
  getVariation,
  useLaunchDarklyClient,
} from 'components/LaunchDarklyContext';
import PatientName from 'components/PatientName';
import PopoverList from 'components/PopoverList';
import QuickCopyText from 'components/QuickCopyText';
import StudySyncStatus from 'components/StudySyncStatus';
import StudyTitle from 'components/StudyTitle';
import VetPatientName from 'components/VetPatientName';
import messages from 'messages/patient';
import { useExamRoutes } from 'routes/exam';
import { useLastViewedStudy } from 'utils/LastViewedStudy';
import { StudyListFilter } from 'utils/StudyConstants';
import { useStudyListNavConfig } from 'utils/StudyListNavUtils';
import { studyReviewStatus } from 'utils/StudyPermissions';
import {
  useGetSelectedStudyItem,
  useStudySelection,
} from 'utils/StudySelection';
import * as StudySelection from 'utils/StudySelection';

import useStudyDataColumnsUserPreference, {
  StudyDataColumnUserPreferenceKey,
} from '../hooks/useStudyDataColumnsUserPreference';
import NewStudyDataGridActions from './NewStudyDataGridActions';
import NewStudyDataGridCaptures, {
  NewStudyDataGridCapturesLabel,
} from './NewStudyDataGridCaptures';
import StudyDataGridTags from './StudyDataGridTags';
import ToggleStudyFavoriteButton from './ToggleStudyFavoriteButton';
import { StudyDataGrid_domain$data as Domain } from './__generated__/StudyDataGrid_domain.graphql';
import { StudyDataGrid_organization$data as Organization } from './__generated__/StudyDataGrid_organization.graphql';
import { StudyDataGrid_studies$data as Studies } from './__generated__/StudyDataGrid_studies.graphql';
import { StudyDataGrid_studiesWithImages$data as StudiesWithImages } from './__generated__/StudyDataGrid_studiesWithImages.graphql';
import { StudyDataGrid_studiesWithSync$data as StudiesWithSync } from './__generated__/StudyDataGrid_studiesWithSync.graphql';
import { StudyDataGrid_viewer$data as Viewer } from './__generated__/StudyDataGrid_viewer.graphql';

type StudyImages = ConnectionNodeType<
  StudiesWithImages[0],
  'imageConnection'
>[];

type StudySync = StudiesWithSync[0];

interface Props {
  loading?: boolean;
  studies: Studies;
  studiesWithImages: StudiesWithImages | null;
  studiesWithSync: StudiesWithSync | null;
  organization: Organization;
  domain: Domain | null;
  relayPagination?: RelayPagination;
  pageSize: number;
  canQa: boolean;
  // FIXME: remove this when disparities between search and the other pages are fixed
  isSearchPage: boolean;
  viewer: Viewer;
  showArchive: boolean;
  allowDelete: boolean;
  allowMove: boolean;
  allowAssignExamTypes: boolean;
  showOrganization?: boolean;
  columnsPreferenceKey: StudyDataColumnUserPreferenceKey;
  onColumnChange: (columnPrefernces: { columnOrder: string[] }) => void;
  idsToCursors?: Map<string, string>;
  isAllOrgs?: boolean;
}

const sortAlphaAsc = (
  <FormattedMessage
    id="studyDataGrid.sortAlphaAsc"
    defaultMessage="Sort alphabetically A-Z"
  />
);

const sortAlphaDesc = (
  <FormattedMessage
    id="studyDataGrid.sortAlphaDesc"
    defaultMessage="Sort alphabetically Z-A"
  />
);

const unassignedMessage = (
  <FormattedMessage
    id="studyDataGrid.unassigned"
    defaultMessage="Unassigned"
  />
);

function FormattedOverflowList({ name, numOverflowing }) {
  if (!numOverflowing) {
    return <Text truncate>{name}</Text>;
  }

  return (
    <span className="flex overflow-hidden">
      <FormattedMessage
        id="studyDataGrid.formattedOverflow"
        description="Displays the first of a list of items followed by the number of additional items in the list: 'My worksheet, +3'"
        defaultMessage="<first>{name}</first><overflow>, +{numAdditionalItems}</overflow>"
        values={{
          name,
          numAdditionalItems: numOverflowing ?? 0,
          first: (c) => (
            <Text truncate title={name}>
              {c}
            </Text>
          ),
          overflow: (c) => <span>{c}</span>,
        }}
      />
    </span>
  );
}

interface StudyColumnContext {
  studies: Studies;
  getStudyImages: (id: string) => StudyImages | null;
  getStudySync: (id: string) => StudySync | null;
  ldClient: LaunchDarklyClient;
  organization: Organization;
  lastViewedStudyHandle?: string;
  showArchive: boolean;
  viewerCanQa: boolean;
  isDraggable: (study: Studies[0]) => boolean;
  canSelect: (study: Studies[0]) => boolean;
  dragHandlers: any;
  allowBulkActions: boolean;
  showOrganization: boolean;
  status: string;
  isAllOrgs: boolean;
  idsToCursors?: Map<string, string>;
}
export interface StudyColumnSpec
  extends ColumnSpec<StudyColumnContext, Studies[0]> {
  defaultHidden?: boolean;
  shownInPicker?: boolean;
  frozenOffset?: number;
}

const rowActions: StudyColumnSpec = {
  key: 'actions',
  width: (ctx) => (ctx.allowBulkActions ? '7rem' : '4rem'),
  frozen: true,
  picker: { show: false },
  label: (ctx) =>
    ctx.allowBulkActions ? (
      <DataGrid.SelectAll
        data-bni-id="StudyDataGridSelectAll"
        numSelectableItems={ctx.studies.filter(ctx.canSelect).length}
      />
    ) : (
      <span />
    ),
  headerClassName: 'border-r-0',
  renderCell: ({ cellProps, item: study, context }) => {
    return (
      <DataGrid.Cell {...cellProps} to={undefined}>
        {context.allowBulkActions && (
          <DataGrid.RowSelect
            disabled={!context.canSelect(study)}
            className="mr-3"
          />
        )}
        <ToggleStudyFavoriteButton study={study} />
      </DataGrid.Cell>
    );
  },
};

/**
 * DEFAULT ORDER AND VISIBILITY
 * --------------
 * Patient Name
 * Patient ID
 * Age
 * Exam Type
 * Study Author
 * Tags
 * Captured
 * Worksheet
 * Finalized Date
 * QA Reviewed
 * Sync
 * Operator
 * (all other columns should have defaultHidden: true)
 */

const columnSpecs: StudyColumnSpec[] = [
  // --------- DEFAULT ORDER AND VISIBILITY START ---------
  {
    key: 'patient',
    width: '20rem',
    frozen: true,
    picker: { show: true },
    label: (
      <FormattedMessage
        id="studyDataGrid.patientHeader"
        defaultMessage="Patient"
      />
    ),
    sortable: [
      {
        key: 'PATIENT_FULL_NAME_ASC',
        label: sortAlphaAsc,
      },
      {
        key: 'PATIENT_FULL_NAME_DESC',
        label: sortAlphaDesc,
      },
    ],
    renderCell: ({ item: study, context, cellProps }) => {
      const className = 'flex-grow font-bold';
      return (
        <DataGrid.Cell {...cellProps} flush to={undefined}>
          <DataGrid.RowLink fillCell>
            {study.patient ? (
              <PatientName
                patient={study.patient}
                className={className}
                truncate
              />
            ) : (
              <VetPatientName
                vetPatient={study.vetPatient!}
                className={className}
                truncate
              />
            )}
          </DataGrid.RowLink>
          {context.isDraggable(study) && (
            <button
              type="button"
              draggable
              className="absolute h-full w-2 right-1 top-0 items-center text-headline cursor-grab"
              css={css`
                visibility: hidden;
                opacity: 0;
                transition: opacity 100ms linear;

                &.visible,
                :global(.__datagrid_row_hook):hover & {
                  // Setting visibility instead of just opacity makes the drag handles fade
                  // in but not out. This is intentional.
                  visibility: visible;
                  opacity: 1;
                }

                @media (pointer: coarse) {
                  visibility: visible;
                }
              `}
              onDragStart={(e) => {
                context.dragHandlers.onDragStart(e, {
                  studyId: study.id,
                  studyTitle: <StudyTitle study={study} />,
                });
              }}
              onDragEnd={context.dragHandlers.onDragEnd}
            >
              <DragHandlesIcon />
            </button>
          )}
        </DataGrid.Cell>
      );
    },
  },
  {
    key: 'mrn',
    width: 'minmax(auto, 20rem)',
    label: (
      <Abbr
        label={messages.medicalRecordNumber}
        message={messages.medicalRecordNumber}
      />
    ),
    render: (study, ctx) => {
      const patientId = study.patient
        ? study.patient?.medicalRecordNumber
        : study.vetPatient?.patientIdNumber;
      if (!patientId) return null;

      const canCopyPatientId = getVariation(ctx.ldClient, 'copy-patient-id');

      if (canCopyPatientId) {
        return (
          <Layout flex justify="space-between">
            <Text truncate>{patientId}</Text>
            <QuickCopyText
              data-bni-id="StudyDataGridCopyPatientID"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              textToCopy={patientId}
              css={css`
                opacity: 0;
                transition: opacity 100ms linear;
                :global(.__datagrid_row_hook):hover & {
                  opacity: 1;
                }
              `}
            />
          </Layout>
        );
      }

      return <span>{patientId}</span>;
    },
  },
  {
    key: 'age',
    label: <Abbr label={messages.age} message={messages.age} />,
    defaultHidden: false,
    render: (study) => {
      const dob = study.patient
        ? study.patient?.birthDate
        : study.vetPatient?.birthDate;
      if (!dob) return '';

      return (
        <Popover.Trigger
          placement="bottom"
          popover={<FormattedDate value={dob} />}
        >
          <span>
            <FormattedAge value={dob} />
          </span>
        </Popover.Trigger>
      );
    },
  },
  {
    key: 'examTypes',
    width: 'minmax(auto, 24rem)',
    label: (
      <FormattedMessage
        id="studyDataGrid.examTypes"
        defaultMessage="Exam Type"
      />
    ),
    renderCell: ({ cellProps, spec, item: study }) => {
      return (
        <PopoverList
          key={spec.key}
          show={(study.examTypes?.length || 0) > 1 ? undefined : false}
          id={`study-exam-types-popover-${study!.id}`}
          listItems={study.examTypes?.map((examType) => (
            <li key={examType!.id}>{examType!.name}</li>
          ))}
        >
          <DataGrid.Cell {...cellProps}>
            {study.examTypes?.length ? (
              <FormattedOverflowList
                name={study.examTypes[0]!.name}
                numOverflowing={study.examTypes.length - 1}
              />
            ) : null}
          </DataGrid.Cell>
        </PopoverList>
      );
    },
  },
  {
    key: 'author',
    width: 'minmax(auto, 24rem)',
    label: (
      <FormattedMessage
        id="studyDataGrid.author"
        defaultMessage="Study Author"
      />
    ),
    renderCell: ({ spec, item: study, rowLocation }) => {
      return (
        <PopoverList
          key={spec.key}
          show={study.secondaryAuthors?.length ? undefined : false}
          id={`study-authors-popover-${study!.id}`}
          listItems={
            <>
              {study.createdBy ? (
                <li>
                  <div>{study.createdBy.name}</div>
                  <Text variant="xs" color="subtitle">
                    <FormattedMessage
                      id="studyDataGrid.primaryAuthor"
                      defaultMessage="Primary"
                    />
                  </Text>
                </li>
              ) : (
                <li>
                  <div>{unassignedMessage}</div>
                  <Text variant="xs" color="subtitle">
                    <FormattedMessage
                      id="studyDataGrid.noPrimaryAuthor"
                      defaultMessage="No primary author"
                    />
                  </Text>
                </li>
              )}
              {study.secondaryAuthors!.map((author) => (
                <li key={author!.id}>{author!.name}</li>
              ))}
            </>
          }
        >
          <DataGrid.Cell key={spec.key} to={rowLocation}>
            <FormattedOverflowList
              name={study.createdBy?.name || unassignedMessage}
              numOverflowing={study.secondaryAuthors?.length}
            />
          </DataGrid.Cell>
        </PopoverList>
      );
    },
  },
  {
    key: 'tags',
    disabled: ({ ldClient }) => !getVariation(ldClient, 'study-tags'),

    label: <FormattedMessage id="studyDataGrid.tags" defaultMessage="Tags" />,
    renderCell: ({ cellProps, item: study }) => {
      return <StudyDataGridTags study={study} {...cellProps} />;
    },
  },
  {
    // capture dates with thumbnails
    key: 'newCapturedAt',
    label: <NewStudyDataGridCapturesLabel />,
    sortable: [
      {
        key: 'CAPTURED_AT_DESC',
        label: (
          <FormattedMessage
            id="studyDataGrid.capturedAtSort"
            defaultMessage="Sort most recently captured first"
          />
        ),
      },
    ],
    renderCell: ({ spec, item: study, context }) => (
      <NewStudyDataGridCaptures
        key={spec.key}
        study={study}
        studyCursor={context.idsToCursors?.get(study.id)}
        studyImages={context.getStudyImages(study.id)}
      />
    ),
  },
  {
    key: 'worksheets',
    disabled: ({ ldClient }) => !getVariation(ldClient, 'worksheets'),
    label: (
      <FormattedMessage
        id="studyDataGrid.worksheets"
        defaultMessage="Worksheets"
      />
    ),
    sortable: [
      {
        key: 'WORKSHEET_TEMPLATES_NAME_ASC',
        label: sortAlphaAsc,
      },
      {
        key: 'WORKSHEET_TEMPLATES_NAME_DESC',
        label: sortAlphaDesc,
      },
    ],
    render: (study) =>
      study.worksheets!.length ? (
        <FormattedOverflowList
          name={study.worksheets![0]!.templateVersion!.title}
          numOverflowing={study.worksheets!.length! - 1}
        />
      ) : null,
  },
  {
    key: 'finalizedAt',
    label: (
      <FormattedMessage
        id="studyDataGrid.finalizedAt"
        defaultMessage="Finalized Date"
      />
    ),
    disabled: ({ status }) =>
      status === StudyListFilter.MY_DRAFTS ||
      status === StudyListFilter.MY_SIGNATURE_REQUESTED,
    render: (study) =>
      study.finalizedAt ? (
        <span key={study.id} className="lowercase">
          <FormattedDateTime value={new Date(study.finalizedAt)} />
        </span>
      ) : null,
  },
  {
    key: 'qaReview',
    disabled: ({ ldClient, status, viewerCanQa }) =>
      !viewerCanQa ||
      !getVariation(ldClient, 'worksheets-review') ||
      status === StudyListFilter.MY_DRAFTS ||
      status === StudyListFilter.MY_SIGNATURE_REQUESTED,
    label: (
      <FormattedMessage
        id="studyDataGrid.qaReview"
        defaultMessage="QA Review"
      />
    ),
    render: (study) => {
      switch (studyReviewStatus(study)) {
        case 'NEEDS_REVIEW':
          return (
            <FormattedMessage
              id="studyDataGrid.qaReview.needsReview"
              defaultMessage="Not reviewed"
            />
          );
        case 'REVIEWED':
          return (
            <FormattedMessage
              id="studyDataGrid.qaReview.reviewed"
              defaultMessage="Reviewed"
            />
          );
        case 'INELIGIBLE_FOR_REVIEW':
        default:
          return null;
      }
    },
  },
  {
    key: 'syncStatus',
    disabled: ({ status }) =>
      status === StudyListFilter.MY_DRAFTS ||
      status === StudyListFilter.MY_SIGNATURE_REQUESTED,
    label: (
      <FormattedMessage id="studyDataGrid.syncStatus" defaultMessage="Sync" />
    ),
    renderCell: ({ item: study, context, cellProps }) => {
      return (
        <DataGrid.Cell {...cellProps}>
          <Layout>
            <StudySyncStatus study={context.getStudySync(study.id)} />
          </Layout>
        </DataGrid.Cell>
      );
    },
  },
  {
    key: 'operatorsName',
    label: (
      <FormattedMessage
        id="studyDataGrid.operatorsName"
        defaultMessage="Operator"
      />
    ),
    sortable: [
      {
        key: 'DICOM_OPERATORS_NAME_ASC',
        label: sortAlphaAsc,
      },
      {
        key: 'DICOM_OPERATORS_NAME_DESC',
        label: sortAlphaDesc,
      },
    ],
    render: (study) =>
      study.dicomOperatorsName && (
        <DicomName value={study.dicomOperatorsName} />
      ),
  },
  // --------- DEFAULT ORDER AND VISIBILITY STOP ---------
  {
    key: 'uploadedAt',
    defaultHidden: true,
    sortable: [
      {
        key: 'READY_AT_DESC',
        label: (
          <FormattedMessage
            id="studyDataGrid.uploadedAtSort"
            defaultMessage="Sort most recently uploaded first"
          />
        ),
      },
    ],
    label: (
      <FormattedMessage
        id="studyDataGrid.uploadedAt"
        defaultMessage="Uploaded"
      />
    ),
    render: (study) => (
      <span className="lowercase">
        <FormattedShortDateTime value={new Date(study.readyAt!)} />
      </span>
    ),
  },
  {
    key: 'dob',
    label: (
      <Abbr label={messages.dateOfBirthLabel} message={messages.birthDate} />
    ),
    defaultHidden: true,
    render: (study) => {
      const dob = study.patient
        ? study.patient?.birthDate
        : study.vetPatient?.birthDate;

      return dob ? <FormattedDate value={dob} timeZone="UTC" /> : '-';
    },
  },
  {
    key: 'requestingPhysician',
    defaultHidden: true,
    sortable: [
      {
        key: 'DICOM_REQUESTING_PHYSICIAN_ASC',
        label: sortAlphaAsc,
      },
      {
        key: 'DICOM_REQUESTING_PHYSICIAN_DESC',
        label: sortAlphaDesc,
      },
    ],
    label: (
      <FormattedMessage
        id="studyDataGrid.requestingPhysician"
        defaultMessage="Requesting Physician"
      />
    ),
    render: (study) =>
      study.dicomRequestingPhysician && (
        <DicomName value={study.dicomRequestingPhysician} />
      ),
  },
  {
    key: 'referringPhysician',
    defaultHidden: true,
    label: (
      <FormattedMessage
        id="studyDataGrid.referringPhysician"
        defaultMessage="Referring Physician"
      />
    ),
    sortable: [
      {
        key: 'DICOM_REFERRING_PHYSICIAN_ASC',
        label: sortAlphaAsc,
      },
      {
        key: 'DICOM_REFERRING_PHYSICIAN_DESC',
        label: sortAlphaDesc,
      },
    ],
    render: (study) =>
      study.dicomReferringPhysician && (
        <DicomName value={study.dicomReferringPhysician} />
      ),
  },
  {
    key: 'newStatus',
    defaultHidden: true,
    label: (
      <FormattedMessage id="newStudyDataGrid.status" defaultMessage="Status" />
    ),
    disabled: ({ status }) =>
      status === StudyListFilter.MY_DRAFTS ||
      status === StudyListFilter.MY_SIGNATURE_REQUESTED,
    render: (study) =>
      // eslint-disable-next-line no-nested-ternary
      study.finalizedAt ? (
        <FormattedMessage
          id="newStudyDataGrid.studyStatus.finalized"
          defaultMessage="Finalized"
        />
      ) : study.isPendingFinalization ? (
        <Text className="text-red-40">
          <FormattedMessage
            defaultMessage="Pending attestation"
            id="newStudyDataGrid.studyStatus.pendingAttestation"
          />
        </Text>
      ) : (
        <Text color="draft">
          <FormattedMessage
            id="newStudyDataGrid.studyStatus.draft"
            defaultMessage="Draft"
          />
        </Text>
      ),
  },
  {
    key: 'finalizedBy',
    defaultHidden: true,
    label: (
      <FormattedMessage
        id="studyDataGrid.signedBy"
        defaultMessage="Signed by"
      />
    ),
    render: (study) =>
      study.finalizedBy ? (
        <Text truncate>{study.finalizedBy.name}</Text>
      ) : null,
  },
  {
    key: 'accessionNumber',
    defaultHidden: true,
    label: (
      <FormattedMessage
        id="studyDataGrid.accessionNumber"
        defaultMessage="Accession #"
      />
    ),
    render: (study) => study.accessionNumber,
  },
  {
    key: 'organization',
    defaultHidden: true,
    disabled: ({ showOrganization }) => !showOrganization,
    label: (
      <FormattedMessage
        id="studyDataGrid.organization"
        defaultMessage="Organization"
      />
    ),
    render: (study) => study.organization!.name,
  },
  {
    key: 'archive',
    defaultHidden: true,
    label: (
      <FormattedMessage id="studyDataGrid.archive" defaultMessage="Archive" />
    ),
    disabled: ({ showArchive, isAllOrgs }) => {
      if (isAllOrgs) return false;
      return !showArchive;
    },
    render: (study) => study.archive!.label,
  },
  {
    key: 'requestedAttester',
    defaultHidden: true,
    disabled: ({ status }) => status === StudyListFilter.MY_DRAFTS,
    label: (
      <FormattedMessage
        id="studyDataGrid.requestedAttesterCell"
        defaultMessage="Requested Attester"
      />
    ),
    renderCell: ({ cellProps, spec, item: study }) => {
      return (
        <PopoverList
          key={spec.key}
          show={study.finalizationRequests!.length > 1 ? undefined : false}
          id={`requested-attesters-popover-${study!.id}`}
          listItems={study.finalizationRequests!.map((req) => (
            <li key={req!.recipient!.id}>{req!.recipient!.name}</li>
          ))}
        >
          <DataGrid.Cell {...cellProps}>
            {study.finalizationRequests!.length ? (
              <Text truncate>
                <FormattedMessage
                  id="studyDataGrid.requestedAttester"
                  description="Requested attester and 0 or more requested attesters: 'Jane Doe, +4'"
                  defaultMessage="
                    {numRequestedAttesters, plural,
                      =0 {{firstAttester}}
                      other {{firstAttester}, +{numRequestedAttesters}}
                    }
                  "
                  values={{
                    firstAttester:
                      study.finalizationRequests![0]?.recipient!.name,
                    numRequestedAttesters:
                      (study.finalizationRequests!.length || 1) - 1,
                  }}
                />
              </Text>
            ) : null}
          </DataGrid.Cell>
        </PopoverList>
      );
    },
  },
  {
    key: 'studyDescription',
    defaultHidden: true,
    label: (
      <FormattedMessage
        id="studyDataGrid.studyDescription"
        defaultMessage="Study Description"
        css="max-width: 20rem;"
      />
    ),
    render: (study) =>
      study.studyDescription ? (
        <Popover.Trigger
          placement="bottom"
          popover={<>{study.studyDescription}</>}
        >
          <span css="max-width: 20rem;" className="truncate">
            {study.studyDescription}
          </span>
        </Popover.Trigger>
      ) : null,
  },
];

interface StudyDataGridRowProps {
  item: Studies[0];
  context: StudyColumnContext;
  selected?: boolean;
}

function StudyDataGridRow({ context, item, ...props }: StudyDataGridRowProps) {
  const { lastViewedStudyHandle } = context;

  return (
    <DataGrid.Row
      {...props}
      highlighted={lastViewedStudyHandle === item.handle}
      indicator={
        // eslint-disable-next-line no-nested-ternary
        item.isPendingFinalization
          ? 'danger'
          : !item.finalizedAt
          ? 'draft'
          : undefined
      }
    />
  );
}

function StudyDataGrid({
  loading,
  studies: studiesProp,
  organization,
  domain,
  relayPagination,
  studiesWithImages: studiesWithImagesProp,
  studiesWithSync: studiesWithSyncProp,
  allowDelete,
  allowMove,
  allowAssignExamTypes,
  pageSize,
  canQa,
  showArchive = false,
  isSearchPage = false,
  showOrganization = false,
  isAllOrgs = false,
  viewer,
  columnsPreferenceKey,
  onColumnChange,
  idsToCursors,
}: Props) {
  // stop gap measure to prevent studies with bad data breaking the list [CBUG-1124]
  const studies = studiesProp.filter((s) => s.organization?.slug);
  const studiesWithImages = studiesWithImagesProp?.filter((s) =>
    studies.find((s2) => s2.id === s.id),
  );
  const studiesWithSync = studiesWithSyncProp?.filter((s) =>
    studies.find((s2) => s2.id === s.id),
  );

  const ldClient = useLaunchDarklyClient();
  const [selectedItems, setSelectedItemsByKey] =
    StudySelection.useSelectionContextValue({});

  const getSelectedStudyItem = useGetSelectedStudyItem();

  const studyListNavConfig = useStudyListNavConfig();
  const lastViewedStudyHandle = useLastViewedStudy(viewer.profile!.id);
  const examRoutes = useExamRoutes();
  const { router, match } = useRouter();
  const { status } = useParams();
  const [userColumns, setUserColumns] = useStudyDataColumnsUserPreference(
    columnsPreferenceKey,
  );

  const StudiesById = useMemo(() => {
    const result = new Map<string, Studies[0]>();
    for (const study of studies) {
      result.set(study.id, study);
    }
    return result;
  }, [studies]);

  const ImagesByStudyId = useMemo(() => {
    const result: Record<string, StudyImages> = {};

    if (!studiesWithImages) return result;
    for (const node of studiesWithImages) {
      result[node!.id] = getNodes(node?.imageConnection);
    }

    return result;
  }, [studiesWithImages]);

  const SyncByStudyId = useMemo(() => {
    const result: Record<string, StudySync> = {};

    if (!studiesWithSync) return result;
    for (const node of studiesWithSync) {
      result[node!.id] = node;
    }

    return result;
  }, [studiesWithSync]);

  const [preview, { isDraggable, canSelect }, dragHandlers] =
    useStudySelection({
      studies,
      allowAssignExamTypes,
      allowDelete,
      allowMove,
    });

  const columnContext: StudyColumnContext = useMemo(
    () => ({
      studies,
      getStudyImages: (id: string): StudyImages | null =>
        ImagesByStudyId[id] || null,
      getStudySync: (id: string): StudySync | null =>
        SyncByStudyId[id] || null,
      organization,
      viewerCanQa: canQa,
      showArchive,
      ldClient,
      lastViewedStudyHandle,
      isDraggable,
      canSelect,
      dragHandlers,
      allowBulkActions: allowDelete || allowMove || allowAssignExamTypes,
      showOrganization,
      status,
      isAllOrgs,
      idsToCursors,
    }),
    [
      studies,
      organization,
      canQa,
      showArchive,
      ldClient,
      lastViewedStudyHandle,
      isDraggable,
      canSelect,
      dragHandlers,
      allowDelete,
      allowMove,
      allowAssignExamTypes,
      showOrganization,
      status,
      isAllOrgs,
      ImagesByStudyId,
      SyncByStudyId,
      idsToCursors,
    ],
  );

  const colSpec = useMemo(() => {
    const result = columnSpecs
      .map((spec) => {
        if (isSearchPage && spec.key === 'requestedAttester')
          return { ...spec, sortable: undefined };
        if (!isSearchPage && spec.key === 'worksheets')
          return { ...spec, sortable: undefined };
        if (spec.key === 'organization')
          return { ...spec, defaultHidden: !showOrganization };
        if (spec.key === 'archive') {
          return {
            ...spec,
            defaultHidden: !isAllOrgs,
          };
        }
        return spec;
      })
      .filter((col) => !col.disabled?.(columnContext));
    result.unshift(rowActions);
    return result;
  }, [columnContext, isAllOrgs, isSearchPage, showOrganization]);

  const [columnOrder, columnVisibility] = useMemo(() => {
    const order: string[] =
      userColumns[columnsPreferenceKey]?.map((s) => Object.keys(s)[0]) || [];

    const visibility = Object.fromEntries(
      userColumns[columnsPreferenceKey]?.map((s) => Object.entries(s)[0]) ||
        [],
    );

    for (const [idx, { key, ...spec }] of colSpec.entries()) {
      if (!(key in visibility)) {
        visibility[key] = !spec.defaultHidden;
        // if the key is missing then insert it at its default position.
        order.splice(idx, 0, key);
        // if this column can't be toggled in the picker then force it to be visible
      } else if (spec.picker?.show === false) {
        visibility[key] = true;
      }
    }

    return [order, visibility] as const;
  }, [userColumns, columnsPreferenceKey, colSpec]);

  function handleColumnChange(
    nextOrder: string[],
    nextVisibility: Record<string, boolean>,
  ) {
    // duplicates are somehow possible here, this should be a set?
    const nextColumns = [...new Set(nextOrder)].map((key) => ({
      [key]: nextVisibility[key] ?? true,
    }));
    setUserColumns({
      ...userColumns,
      [columnsPreferenceKey]: nextColumns,
    });
  }

  useEffect(() => {
    onColumnChange({ columnOrder });
  }, [columnOrder, onColumnChange]);

  function handleRowSelect(nextStudies: Studies) {
    const mappedRows = new Map();
    // filter out studies we can't select
    nextStudies.forEach((study) => {
      if (canSelect(study)) {
        mappedRows.set(study.id, getSelectedStudyItem(study));
      }
    });
    setSelectedItemsByKey(mappedRows);
  }

  const selectedStudies = useMemo(() => {
    return selectedItems.selectedStudyItems
      .map((si) => StudiesById.get(si.studyId))
      .filter(notNullish);
  }, [StudiesById, selectedItems.selectedStudyItems]);

  const getRowLocation = useCallback(
    (study: Studies[0]) => ({
      pathname: examRoutes.exam({
        organizationSlug: study.organization!.slug!,
        studyHandle: study.handle!,
      }),
      state: {
        studyListNavConfig,
        cursor: idsToCursors?.get(study.id),
      },
    }),
    [examRoutes, studyListNavConfig, idsToCursors],
  );

  // infer from the set of `data.id` the unique collection of items currently displayed
  // in the `DataGridTable`, if this set changes (and we're done loading and new data),
  // scroll the `DataGridTable` back to the top [SHARK-990]
  const scrollRef = useRef<HTMLElement>(null);
  const pageIdentifier = studies
    .map((it) => it.id)
    .sort()
    .join(',');
  useEffect(() => {
    if (!loading && !relayPagination) {
      scrollRef.current!.scrollTop = 0;
    }
  }, [loading, studies, relayPagination, pageIdentifier]);

  return (
    <>
      <NewStudyDataGridActions
        allowDelete={allowDelete}
        allowMove={allowMove}
        allowAssignExamTypes={allowAssignExamTypes}
        show={!!selectedItems.selectedStudyItems.length}
        organization={organization}
        domain={domain}
      />
      <DataGrid<Studies[0], StudyColumnContext>
        data={studies}
        loading={loading}
        key="data-grid"
        scrollKey="study-data-grid"
        scrollRef={scrollRef}
        data-bni-id="StudyDataGridTable"
        columns={colSpec}
        columnOrder={columnOrder}
        columnVisibility={columnVisibility}
        columnContext={columnContext}
        pageSize={pageSize}
        rowComponent={StudyDataGridRow}
        relayPagination={relayPagination}
        selectedItems={selectedStudies}
        getRowLocation={getRowLocation}
        sort={match.location.query.sort}
        onSort={(nextSort) =>
          router.replace({
            ...match.location,
            query: {
              ...match.location.query,
              sort: nextSort,
            },
          })
        }
        onRowSelect={handleRowSelect}
        onColumnVisibilityChange={(nextVisiblity) =>
          handleColumnChange(columnOrder, nextVisiblity)
        }
        onColumnOrderChange={(nextOrder) => {
          if (nextOrder[0] !== 'patient') return;
          handleColumnChange(nextOrder, columnVisibility);
        }}
      />
      {preview}
    </>
  );
}

export default createFragmentContainer(StudyDataGrid, {
  organization: graphql`
    fragment StudyDataGrid_organization on Organization {
      ...NewStudyDataGridActions_organization
    }
  `,
  domain: graphql`
    fragment StudyDataGrid_domain on Domain {
      ...NewStudyDataGridActions_domain
    }
  `,
  studies: graphql`
    fragment StudyDataGrid_studies on Study @relay(plural: true) {
      id
      handle
      organization {
        name
        slug
      }
      accessionNumber
      archive {
        id
        handle
        label
      }
      readyAt
      capturedAt
      examTypes {
        id
        name
      }
      finalizedBy {
        name
      }
      createdBy {
        id
        name
      }
      secondaryAuthors {
        id
        name
      }
      source
      dicomOperatorsName
      dicomReferringPhysician
      dicomRequestingPhysician
      finalizedAt
      isPendingFinalization
      numImages
      patient {
        birthDate
        medicalRecordNumber
        nameFirst
        nameLast
        nameMiddle
        namePrefix
        nameSuffix
        ...PatientName_patient
      }
      vetPatient {
        birthDate
        clientNameLast
        clientOrganizationName
        name
        patientIdNumber
        ...VetPatientName_vetPatient
      }
      worksheets {
        templateVersion {
          title
        }
      }
      tags {
        id
        name
      }
      deletedAt
      viewerCanDelete
      viewerCanMove
      finalizationRequests {
        recipient {
          id
          name
        }
      }
      studyDescription
      ...StudyTitle_study
      ...NewStudyDataGridCaptures_study
      ...StudyDataGridTags_study
      ...ToggleStudyFavoriteButton_study
      ...StudyPermissions_studyReviewStatus
    }
  `,
  studiesWithImages: graphql`
    fragment StudyDataGrid_studiesWithImages on Study @relay(plural: true) {
      id
      imageConnection(
        first: 6 # Match MAX_PREVIEWS in <StudyImagePreviewList>.
      ) {
        edges {
          node {
            ...NewStudyDataGridCaptures_studyImages
          }
        }
      }
    }
  `,
  studiesWithSync: graphql`
    fragment StudyDataGrid_studiesWithSync on Study @relay(plural: true) {
      id
      ...StudySyncStatus_study
    }
  `,
  viewer: graphql`
    fragment StudyDataGrid_viewer on Viewer {
      profile {
        id
      }
    }
  `,
});
