import DropdownList, { DropdownListProps } from '@bfly/ui2/DropdownList';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';

import { Organization, useViewerContext } from 'utils/viewerContext';

export type OrganizationItem = Pick<Organization, 'id' | 'name' | 'handle'>;

const styles = stylesheet`
  .input {
    composes: bg-grey-80 from global;

    --bni-form-control-height: 3rem;
    grid-template: 1fr / 1fr 3rem;

    & :global(.rw-dropdown-list-input) {
      padding: 0 theme('spacing.2');
    }
  }
`;

function renderValue({ item }: { item: OrganizationItem }) {
  return (
    item && (
      <span className="w-full flex font-bold">
        <span className="truncate max-w-[max-content] w-full">
          {item.name}
        </span>
      </span>
    )
  );
}

interface Props
  extends Omit<
    DropdownListProps<OrganizationItem>,
    | 'variant'
    | 'menuVariant'
    | 'filter'
    | 'data'
    | 'renderListItem'
    | 'dataKey'
    | 'textField'
    | 'value'
    | 'renderValue'
    | 'containerClassName'
  > {
  /** OrganizationItem or Organization Id */
  value: OrganizationItem | string | null;

  // Only include organizations to which the viewer has an explicit membership
  explicitMemberships?: boolean;
}

function OrganizationDropdown({
  value,
  className,
  busy,
  explicitMemberships,
  ...props
}: Props) {
  const viewer = useViewerContext();
  const items = explicitMemberships
    ? viewer.organizations.filter(
        (organization) => organization.viewerIsMember,
      )
    : viewer.organizations;
  const valueId = typeof value === 'string' ? value : value?.id;

  return (
    <DropdownList<OrganizationItem>
      {...props}
      data-bni-id="OrganizationDropdown"
      variant="secondary"
      menuVariant="dark"
      filter={false}
      data={items || []}
      renderListItem={renderValue}
      dataKey="id"
      textField="name"
      value={valueId && items?.find((i) => i.id === valueId)}
      renderValue={renderValue}
      className={className}
      containerClassName={clsx(className, styles.input)}
      busy={busy || items === null}
    />
  );
}

export default OrganizationDropdown;
