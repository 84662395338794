import BookmarkIcon from '@bfly/icons/Bookmark';
import CineIcon from '@bfly/icons/Cine';
import CommentIcon from '@bfly/icons/CommentBubble';
import Link from '@bfly/ui2/Link';
import Text from '@bfly/ui2/Text';
import clsx from 'clsx';
import useMatch from 'found/useMatch';
import { createFragmentContainer, graphql } from 'react-relay';

import AspectRatioBox from 'components/AspectRatioBox';
import Thumbnail from 'components/Thumbnail';
import { routes } from 'routes/exam';
import { routes as scanLabRoutes } from 'routes/scanLab';

import { ExamImageThumbnail_image$data as Image } from './__generated__/ExamImageThumbnail_image.graphql';

interface Props {
  image: Image;
  index: number;
  selected?: boolean;
  className?: string;
}

const box =
  'bg-grey-90 rounded-[2px] p-0.5 absolute h-5 w-5 flex items-center justify-center';

function ExamImageThumbnail({ image, index, selected, className }: Props) {
  const { location, params } = useMatch();
  const { studyHandle, organizationSlug } = params;

  // eslint-disable-next-line
  const isEduImage = image.__typename === 'EduStudyImage';
  // eslint-disable-next-line
  const isStudyImage = image.__typename === 'StudyImage';
  const isImageType = isEduImage || isStudyImage;

  const imageThumbnailRoute = (thumbnailImage): string => {
    let pathname = '';
    if (isEduImage) {
      pathname = scanLabRoutes.examImage({
        studyHandle,
        studyImageHandle: thumbnailImage!.handle!,
      });
    }
    if (isStudyImage) {
      pathname = routes.examImage({
        studyHandle,
        organizationSlug,
        studyImageHandle: thumbnailImage!.handle!,
      });
    }
    return pathname;
  };

  return (
    <AspectRatioBox
      aspectRatio={1}
      // XXX: Dimensions should smaller than Thumbnail maxWidth
      className={clsx(className, 'relative rounded group w-[100px] h-[100px]')}
      aria-selected={selected}
    >
      <Link
        as="a"
        to={{
          ...location,
          pathname: imageThumbnailRoute(image),
        }}
      >
        {isImageType && (
          <Thumbnail
            maxWidth={80}
            image={image.burnedInFile}
            alt={`Preview ${index + 1}`}
            className="display w-full h-full object-cover"
          />
        )}
        <div
          className={clsx(
            'pointer-events-none rounded border-4 group-hover:border-blue inset-0 absolute',
            selected ? 'border-blue' : 'border-transparent',
          )}
        />
        <Text color="headline" className={`${box} top-1 left-1`}>
          {index + 1}
        </Text>

        {!!('numComments' in image! && image.numComments) && (
          <Text color="headline" className={`${box} bottom-1 left-1`}>
            <CommentIcon width={14} />
          </Text>
        )}
        {'isImportant' in image! && image.isImportant && (
          <Text color="headline" className={`${box} top-1 right-1`}>
            <BookmarkIcon height={14} data-bni-id="examImageBookmarkIcon" />
          </Text>
        )}
        {'burnedInFile' in image! && image.burnedInFile!.isVideo && (
          <Text color="headline" className={`${box} bottom-1 right-1`}>
            <CineIcon width={14} height={8} />
          </Text>
        )}
      </Link>
    </AspectRatioBox>
  );
}

export default createFragmentContainer(ExamImageThumbnail, {
  image: graphql`
    fragment ExamImageThumbnail_image on StudyImageInterface {
      ... on StudyImage {
        __typename
        handle
        numComments
        isImportant
        burnedInFile {
          isVideo
          ...Thumbnail_image
        }
      }
      ... on EduStudyImage {
        __typename
        handle
        burnedInFile {
          isVideo
          ...Thumbnail_image
        }
      }
    }
  `,
});
