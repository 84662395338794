/**
 * @generated SignedSource<<27e9e09f6e967a35597eeb1ca5312243>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type examsLegacyRedirect_Query$variables = {
  organizationSlug: string;
};
export type examsLegacyRedirect_Query$data = {
  readonly organization: {
    readonly slug: string | null;
  } | null;
};
export type examsLegacyRedirect_Query = {
  response: examsLegacyRedirect_Query$data;
  variables: examsLegacyRedirect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "examsLegacyRedirect_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "examsLegacyRedirect_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab95223fbb6dc4a53da5ae45af76e9fa",
    "id": null,
    "metadata": {},
    "name": "examsLegacyRedirect_Query",
    "operationKind": "query",
    "text": "query examsLegacyRedirect_Query(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    slug\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f7e2c744c568f53f39efd0efd7127e9";

export default node;
