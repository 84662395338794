/**
 * @generated SignedSource<<13a4b77f99a0509876282cfc0e3ee237>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EducationContentInfo_educationContent$data = {
  readonly createdAt: string | null;
  readonly description: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly video: {
    readonly authoredBy: {
      readonly name: string | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ShareEducationContentModal_educationContent" | "useSaveContentToggle_educationContent">;
  readonly " $fragmentType": "EducationContentInfo_educationContent";
};
export type EducationContentInfo_educationContent$key = {
  readonly " $data"?: EducationContentInfo_educationContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationContentInfo_educationContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationContentInfo_educationContent",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContentVideo",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "authoredBy",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useSaveContentToggle_educationContent",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerHasSaved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerHasAccess",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShareEducationContentModal_educationContent"
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};
})();

(node as any).hash = "adbb1772de7f9ce10ef749d4fa0871f4";

export default node;
