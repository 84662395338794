import { graphql } from 'relay-hooks';

import { examPageTopNavbar_next_prev_search_Query$data as SearchStyleQuery } from './__generated__/examPageTopNavbar_next_prev_search_Query.graphql';
import { examPageTopNavbar_next_prev_storage_Query$data as StorageStyleQuery } from './__generated__/examPageTopNavbar_next_prev_storage_Query.graphql';

export const storagePaginationQuery = graphql`
  query examPageTopNavbar_next_prev_storage_Query(
    $studyHandle: String!
    $cursor: String
    $organizationSlug: String!
    $sort: [StudySorting!] = [CAPTURED_AT_DESC]
    $archiveId: [ID!]
    $archiveHandle: [String!]
    $author: [String!]
    $primaryAuthor: [String!]
    $examTypes: [String!]
    $isDraft: [Boolean!]
    $needsReview: [Boolean!]
    $hasRequestedFinalization: [Boolean!]
    $hasRequestedFinalizationFromViewer: [Boolean!]
    $readyAtMax: DateTime
    $readyAtMin: DateTime
    $capturedAtMax: DateTime
    $capturedAtMin: DateTime
    $isViewerFavorite: Boolean
    $excludedFromStudyLists: [Boolean!]
    $workflowStatus: [StudySearchInputStatus!]
    $isDeleted: [Boolean!] = [false]
  ) {
    study(handle: $studyHandle) {
      ...ExamNav_study
    }
    tenant: organizationBySlug(slug: $organizationSlug) {
      prev: studyConnection(
        last: 1
        before: $cursor

        includePageInfo: true
        sort: $sort
        archiveId: $archiveId
        archiveHandle: $archiveHandle
        examTypeHandles: $examTypes
        author: $author
        primaryAuthor: $primaryAuthor
        isDraft: $isDraft
        needsReview: $needsReview
        hasRequestedFinalization: $hasRequestedFinalization
        hasRequestedFinalizationFromViewer: $hasRequestedFinalizationFromViewer
        readyAtMin: $readyAtMin
        readyAtMax: $readyAtMax
        capturedAtMin: $capturedAtMin
        capturedAtMax: $capturedAtMax
        isViewerFavorite: $isViewerFavorite
        excludedFromStudyLists: $excludedFromStudyLists
        workflowStatus: $workflowStatus
        isDeleted: $isDeleted
      ) {
        edges {
          cursor
          node {
            id
            handle
            organization {
              slug
            }
          }
        }
      }
      next: studyConnection(
        first: 1
        after: $cursor

        includePageInfo: true
        sort: $sort
        archiveId: $archiveId
        archiveHandle: $archiveHandle
        examTypeHandles: $examTypes
        author: $author
        primaryAuthor: $primaryAuthor
        isDraft: $isDraft
        needsReview: $needsReview
        hasRequestedFinalization: $hasRequestedFinalization
        hasRequestedFinalizationFromViewer: $hasRequestedFinalizationFromViewer
        readyAtMin: $readyAtMin
        readyAtMax: $readyAtMax
        capturedAtMin: $capturedAtMin
        capturedAtMax: $capturedAtMax
        isViewerFavorite: $isViewerFavorite
        excludedFromStudyLists: $excludedFromStudyLists
        workflowStatus: $workflowStatus
        isDeleted: $isDeleted
      ) {
        edges {
          cursor
          node {
            id
            handle
            organization {
              slug
            }
          }
        }
      }
    }
  }
`;

export const searchPaginationQuery = graphql`
  query examPageTopNavbar_next_prev_search_Query(
    $studyHandle: String!
    $search: StudySearchInput
    $cursor: String
    $sort: [StudySorting!] = [CAPTURED_AT_DESC]
  ) {
    study(handle: $studyHandle) {
      ...ExamNav_study
    }
    tenant {
      prev: studySearchConnection(
        search: $search
        last: 1
        before: $cursor
        sort: $sort
      ) {
        edges {
          cursor
          node {
            id
            handle
            organization {
              slug
            }
          }
        }
      }
      next: studySearchConnection(
        search: $search
        first: 1
        after: $cursor
        sort: $sort
      ) {
        edges {
          cursor
          node {
            id
            handle
            organization {
              slug
            }
          }
        }
      }
    }
  }
`;

export interface NavbarMeta {
  loading: boolean;
  prevExamHandle: string | null;
  nextExamHandle: string | null;
  prevExamCursor: string | null;
  nextExamCursor: string | null;
  prevExamOrgSlug: string | null;
  nextExamOrgSlug: string | null;
}

export function getMetaForTopNavbarQuery(
  connection?:
    | SearchStyleQuery['tenant']
    | StorageStyleQuery['tenant']
    | undefined,
): NavbarMeta {
  const loading = !connection;
  const prevExamHandle = connection?.prev?.edges?.[0]?.node?.handle || null;
  const nextExamHandle = connection?.next?.edges?.[0]?.node?.handle || null;
  const prevExamCursor = connection?.prev?.edges?.[0]?.cursor || null;
  const nextExamCursor = connection?.next?.edges?.[0]?.cursor || null;
  const prevExamOrgSlug =
    connection?.prev?.edges?.[0]?.node?.organization?.slug || null;
  const nextExamOrgSlug =
    connection?.next?.edges?.[0]?.node?.organization?.slug || null;

  return {
    loading,
    prevExamHandle,
    nextExamHandle,
    prevExamCursor,
    nextExamCursor,
    prevExamOrgSlug,
    nextExamOrgSlug,
  };
}
