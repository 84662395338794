/* it's hard to conditionally use this polyfill so we always include it */
import 'focus-visible';

/**
 * Lazy load polyfills that aren't covered during compilation, generally things
 * not covered by babel and core-js
 */
import loadPolyfillIo from './loadPolyfillIo';

const polyfills = [] as Promise<unknown>[];

polyfills.push(loadPolyfillIo());

if (typeof Intl !== 'undefined' && !Intl.PluralRules) {
  polyfills.push(
    // @ts-ignore
    import(/* webpackChunkName: "polyfills" */ 'intl-pluralrules'),
  );
}

/**
 * Safari doesn't allow setting an IntersectionObserver root to `document` or doesn't work if you do
 * In cases where it is set to the document, we default to nothing, which is the viewport
 */
function fixSafariObserver() {
  const ua = (window.navigator?.userAgent || '').toLowerCase();

  if (
    // @ts-ignore
    !!window.safari || // desktop mac
    ua.toLowerCase().match(/(ipad|iphone).+?os (\d+)/) !== null // ios
  ) {
    window.IntersectionObserver = class FixedIntersectionObserve extends (
      IntersectionObserver
    ) {
      constructor(
        h: IntersectionObserverCallback,
        options: IntersectionObserverInit,
      ) {
        const opts = options && { ...options };
        if (
          opts &&
          opts.root &&
          opts.root.nodeType === document.DOCUMENT_NODE
        ) {
          opts.root = undefined;
        }
        super(h, opts);
      }
    };
  }
}

if (typeof IntersectionObserver !== 'undefined') {
  fixSafariObserver();
}

export default Promise.all(polyfills);
