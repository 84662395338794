import { useEffect, useState } from 'react';
import { TokenResponse, TokenStorage } from 'relay-network-layer';
import store from 'store/dist/store.modern';

export type SessionInfo = {
  localId: string;
  email: string | null;
  expiresAt: number;
  federatedIdentityProvider: {
    clientId: string;
    logoutEndpointBaseUrl: string;
  } | null;
  domainId: string | null;
  userId: string;
};

export type TokenState = { accessToken: string } & SessionInfo;

type BaseType = TokenState & TokenResponse;

type Listener<T extends BaseType> = (data: T | null) => void;

type Subscription<T extends BaseType> = {
  subscribe: (callback: Listener<T>) => void;
  unsubscribe: (callback: Listener<T>) => void;
  useState: () => T | null;
};

const tokenStorage = (function tokenStore<T extends BaseType>(): TokenStorage &
  Subscription<T> {
  const key = 'bfly:token';

  let subscriptions: Listener<T>[] = [];

  const subscribe = (callback: Listener<T>) => {
    subscriptions.push(callback);
  };

  const unsubscribe = (callback: Listener<T>) => {
    subscriptions = subscriptions.filter((s) => s !== callback);
  };

  return {
    load(): T {
      const data = store.get(key);
      subscriptions.forEach((callback) => callback(data));
      return data;
    },
    save(data: T) {
      store.set(key, data);
      subscriptions.forEach((callback) => callback(data));
    },
    clear() {
      store.remove(key);
    },

    subscribe,

    unsubscribe,

    useState(): T | null {
      const [state, setState] = useState<T | null>(store.get(key));

      useEffect(() => {
        subscribe(setState);
        return () => unsubscribe(setState);
      }, []);

      return state;
    },
  };
})();

export default tokenStorage;
