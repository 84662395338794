import CrayonIcon from '@bfly/icons/Crayon';
import { ColDef as BaseColDef, ICellRendererParams } from 'ag-grid-community';

import {
  Column,
  ColumnArray,
  ColumnComparator,
  ColumnDate,
  ColumnDef,
  ColumnGroup,
  ColumnState,
  isColumnGroup,
} from '../types';
import applyStateToColumns from './applyStateToColumns';
import colDefArray from './colDefArray';
import colDefDate from './colDefDate';
import colDefText from './colDefText';

const defaultComparator: ColumnComparator = (
  valueA: string | number,
  valueB: string | number,
  _nodeA: unknown,
  _nodeB: unknown,
  isDescending?: boolean,
) => {
  if (valueA === valueB) {
    return 0;
  }
  if (!valueA) {
    return isDescending ? 1 : -1;
  }
  if (!valueB) {
    return isDescending ? -1 : 1;
  }
  return valueA > valueB ? 1 : -1;
};

const getColumnComparator = (
  colProp: Column,
  secondarySortColumn?: Column,
): ColumnComparator => {
  const { secondarySort: _, ...col } = colProp;

  if (isColumnGroup(colProp)) return undefined;

  if (!secondarySortColumn || col.colId === secondarySortColumn.colId)
    return col.comparator;

  const comparator = col.comparator || defaultComparator;
  return (valueA, valueB, nodeA, nodeB, isDescending) => {
    const order = comparator(valueA, valueB, nodeA, nodeB, isDescending);
    return order !== 0
      ? order
      : (secondarySortColumn.comparator || defaultComparator)(
          nodeA.data?.[secondarySortColumn.colId],
          nodeB.data?.[secondarySortColumn.colId],
          nodeA,
          nodeB,
          isDescending,
        );
  };
};

export default function setupColumns(
  columns: ColumnDef[],
  columnsState: ColumnState[],
): ColumnDef[] {
  const secondarySortColumn = columns
    .flatMap((col) => (isColumnGroup(col) ? col.children : [col]))
    .find((c) => 'secondarySort' in c && !!c.secondarySort);

  const updateColumn = (colProp: Column, parent?: ColumnGroup): Column => {
    let col: Column & BaseColDef = { ...colProp };

    if (
      col.type === 'array' &&
      Object.prototype.hasOwnProperty.call(col, 'options')
    ) {
      col = colDefArray(col as ColumnArray);
    } else if (col.type === 'date') {
      col = colDefDate(col as ColumnDate);
    } else {
      col = colDefText(col);
    }

    return {
      ...col,

      pinned: parent?.pinned || col.pinned,

      field: col.field || col.colId,
      hide: !!col.hide,
      width: col.width || undefined,

      comparator: getColumnComparator(col, secondarySortColumn),

      cellRenderer: (props: ICellRendererParams) => (
        <>
          {col.cellRenderer?.(props) || props.value}
          {col.editable && (
            <span className="editable-icon">
              <CrayonIcon />
            </span>
          )}
        </>
      ),
    };
  };

  const columnsReady = columns
    // remove column groups without children
    .filter((col) => !isColumnGroup(col) || col.children.length)
    .map((col) =>
      isColumnGroup(col)
        ? {
            ...col,
            children: col.children.map((c) => updateColumn(c, col)),
          }
        : updateColumn(col),
    );

  return applyStateToColumns(columnsReady, columnsState);
}
