/**
 * @generated SignedSource<<01636dac9746d10c752a51ce33d42d90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchPagedArchivePage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PagedArchivePage_viewer" | "StudyListContent_viewer">;
  readonly " $fragmentType": "SearchPagedArchivePage_viewer";
};
export type SearchPagedArchivePage_viewer$key = {
  readonly " $data"?: SearchPagedArchivePage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchPagedArchivePage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchPagedArchivePage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PagedArchivePage_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "1650dd08172aecbc324abdbab733d360";

export default node;
