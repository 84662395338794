import BackButton from '@bfly/ui2/BackButton';
import Header from '@bfly/ui2/Header';
import Link from '@bfly/ui2/Link';
import React from 'react';
import { Helmet } from 'react-helmet';

import { useEducationRoutes } from 'routes/education';

interface Props {
  title: string;
}

function EducationPageHeader({ title }: Props) {
  const educationRoutes = useEducationRoutes();
  return (
    <Header
      className="hidden lg:flex"
      data-bni-id="EducationPageHeaderGoBackLink"
    >
      <Link to={educationRoutes.education()}>
        <BackButton />
      </Link>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header.Title>{title}</Header.Title>
    </Header>
  );
}

export default EducationPageHeader;
