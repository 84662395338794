/**
 * @generated SignedSource<<8dc2a258aa7790cfd14877e6ccca3da7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyDataGrid_studiesWithImages$data = ReadonlyArray<{
  readonly id: string;
  readonly imageConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"NewStudyDataGridCaptures_studyImages">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "StudyDataGrid_studiesWithImages";
}>;
export type StudyDataGrid_studiesWithImages$key = ReadonlyArray<{
  readonly " $data"?: StudyDataGrid_studiesWithImages$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyDataGrid_studiesWithImages">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudyDataGrid_studiesWithImages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 6
        }
      ],
      "concreteType": "StudyImageConnection",
      "kind": "LinkedField",
      "name": "imageConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyImageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyImage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NewStudyDataGridCaptures_studyImages"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "imageConnection(first:6)"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "62a560d0cb889cf2e74e82ae6803c3a3";

export default node;
