/**
 * @generated SignedSource<<0740d409f167e6212783b41baf9bdf1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyListContent_studies$data = ReadonlyArray<{
  readonly cursor: string;
  readonly node: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"StudyDataGrid_studies" | "StudyDataGrid_studiesWithImages" | "StudyList_studies" | "StudyList_studiesWithImages">;
  } | null;
  readonly " $fragmentType": "StudyListContent_studies";
}>;
export type StudyListContent_studies$key = ReadonlyArray<{
  readonly " $data"?: StudyListContent_studies$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_studies">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "lazyLoadImages"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "listEnabled"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showArchive"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudyListContent_studies",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Study",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "condition": "listEnabled",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "showArchive",
                  "variableName": "showArchive"
                }
              ],
              "kind": "FragmentSpread",
              "name": "StudyList_studies"
            },
            {
              "condition": "lazyLoadImages",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyList_studiesWithImages"
                }
              ]
            }
          ]
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StudyDataGrid_studies"
        },
        {
          "condition": "lazyLoadImages",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StudyDataGrid_studiesWithImages"
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyEdge",
  "abstractKey": null
};

(node as any).hash = "667e4912de92e6b394c2dcfabd8ea5a5";

export default node;
