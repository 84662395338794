import useToast from '@bfly/ui2/useToast';
import { useContext, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import {
  GraphQLTaggedNode,
  ReactRelayContext,
  fetchQuery,
  graphql,
  readInlineData,
} from 'react-relay';
import { OperationType } from 'relay-runtime';

import downloadFile from 'utils/downloadFile';

import { useFileExport_fileExport$key as FileExportKey } from './__generated__/useFileExport_fileExport.graphql';

const failedExportMessage = defineMessage({
  id: 'FileExportAction.failed',
  defaultMessage: 'Failed to export file',
});
interface Props<T extends OperationType> {
  query: GraphQLTaggedNode;
  // Set variables as partial to account for disabled states
  //  that yet to have the full information
  variables?: Partial<T['variables']>;
  // FIXME: why is this nullable?
  getFile: (response: T['response']) => FileExportKey | null | undefined;
}

export default function useFileExport<T extends OperationType>({
  query,
  variables = {},
  getFile,
}: Props<T>): [
  (variables?: Partial<T['variables']>) => Promise<void>,
  boolean,
] {
  const { formatMessage } = useIntl();

  const relay = useContext(ReactRelayContext);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const requestDownloadFile = async (
    additionalVariables?: Partial<T['variables']>,
  ) => {
    if (!relay || isLoading) {
      return;
    }

    setIsLoading(true);

    let response: T['response'] | undefined;

    try {
      response = await fetchQuery<T>(relay.environment, query, {
        ...variables,
        ...additionalVariables,
      })
        .toPromise()
        .then((res) => {
          setIsLoading(false);
          return res;
        });
    } catch (e) {
      setIsLoading(false);
      toast.error(formatMessage(failedExportMessage));
      return;
    }

    const file = readInlineData(
      graphql`
        fragment useFileExport_fileExport on FileExport @inline {
          url
        }
      `,
      getFile(response)!,
    );

    if (!file || !file.url) {
      toast.error(formatMessage(failedExportMessage));
      return;
    }

    setIsLoading(false);

    downloadFile(file.url, file.url);
  };

  return [requestDownloadFile, isLoading];
}
