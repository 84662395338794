/**
 * @generated SignedSource<<c618ead99649801ae1a03ad3ec9a5193>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StudySearchCriterionKey = "ACCESSION_NUMBER" | "ALL_WORKSHEET_TEMPLATES" | "ARCHIVE" | "AUTHOR" | "CAPTURED_AT" | "CREDENTIAL_GROUP" | "EXAM_TYPE" | "EXCLUDED_FROM_STUDY_LISTS" | "FAVORITED_BY" | "FREE_TEXT" | "IS_DELETED" | "NUM_WORKSHEETS" | "ORGANIZATION" | "PATIENT_BIRTH_DATE" | "PATIENT_MEDICAL_RECORD_NUMBER" | "PATIENT_NAME_FIRST" | "PATIENT_NAME_LAST" | "PATIENT_NAME_MIDDLE" | "PRIMARY_AUTHOR" | "READY_AT" | "REQUESTED_FINALIZER" | "REVIEWER" | "STATUS" | "TAG" | "VET_PATIENT_CLIENT_NAME_FIRST" | "VET_PATIENT_CLIENT_NAME_LAST" | "VET_PATIENT_ID_NUMBER" | "VET_PATIENT_NAME" | "WORKSHEET_TEMPLATE" | "%future added value";
export type UpdateStudySavedSearchInput = {
  clientMutationId?: string | null;
  criteria?: ReadonlyArray<UpdateStudySavedSearchInputStudySearchCriterion> | null;
  name?: string | null;
  studySavedSearchId: any;
};
export type UpdateStudySavedSearchInputStudySearchCriterion = {
  key?: StudySearchCriterionKey | null;
  value?: any | null;
};
export type UpdateSavedSearchModalMutation$variables = {
  input: UpdateStudySavedSearchInput;
};
export type UpdateSavedSearchModalMutation$data = {
  readonly updateStudySavedSearchOrError: {
    readonly __typename?: string;
    readonly fields?: ReadonlyArray<{
      readonly message: string;
      readonly path: ReadonlyArray<string>;
    }>;
    readonly message?: string | null;
    readonly studySavedSearch?: {
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
};
export type UpdateSavedSearchModalMutation = {
  response: UpdateSavedSearchModalMutation$data;
  variables: UpdateSavedSearchModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudySavedSearch",
      "kind": "LinkedField",
      "name": "studySavedSearch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UpdateStudySavedSearchPayload",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvalidInputErrorField",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvalidInputError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSavedSearchModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateStudySavedSearchOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSavedSearchModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateStudySavedSearchOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "372271ab377c5e7aa833981bca6cd65c",
    "id": null,
    "metadata": {},
    "name": "UpdateSavedSearchModalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSavedSearchModalMutation(\n  $input: UpdateStudySavedSearchInput!\n) {\n  updateStudySavedSearchOrError(input: $input) {\n    __typename\n    ... on UpdateStudySavedSearchPayload {\n      studySavedSearch {\n        name\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      ... on InvalidInputError {\n        fields {\n          message\n          path\n        }\n      }\n      ... on MaxSeatsExceededError {\n        message\n      }\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e20ec06202d8a47df2b538e84ff3f6d";

export default node;
