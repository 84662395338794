/**
 * @generated SignedSource<<bc155a709892fc84215976b2b0b72ceb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainMembersGridWrapper_viewer$data = {
  readonly domain: {
    readonly dicomFieldTemplateConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly label: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly ehrConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly handle: string | null;
          readonly id: string;
          readonly name: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly id: string;
    readonly organizationConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly membershipRoles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isDisabled: boolean | null;
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMembersGrid_viewer">;
  readonly " $fragmentType": "DomainMembersGridWrapper_viewer";
};
export type DomainMembersGridWrapper_viewer$key = {
  readonly " $data"?: DomainMembersGridWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMembersGridWrapper_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 2147483647
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDeleted"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainMembersGridWrapper_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDeleted",
          "variableName": "isDeleted"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "kind": "FragmentSpread",
      "name": "DomainMembersGrid_viewer"
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "roleType",
          "value": [
            "SYSTEM_DEFINED",
            "USER_DEFINED"
          ]
        },
        {
          "kind": "Literal",
          "name": "sort",
          "value": "NAME_ASC"
        }
      ],
      "concreteType": "MembershipRoleConnection",
      "kind": "LinkedField",
      "name": "membershipRoles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipRoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MembershipRole",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isDisabled",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "membershipRoles(first:2147483647,roleType:[\"SYSTEM_DEFINED\",\"USER_DEFINED\"],sort:\"NAME_ASC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "OrganizationConnection",
          "kind": "LinkedField",
          "name": "organizationConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "organizationConnection(first:2147483647)"
        },
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "DomainDicomFieldTemplateConnection",
          "kind": "LinkedField",
          "name": "dicomFieldTemplateConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DomainDicomFieldTemplateEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DomainDicomFieldTemplate",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "dicomFieldTemplateConnection(first:2147483647)"
        },
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "EhrConnection",
          "kind": "LinkedField",
          "name": "ehrConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EhrEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Ehr",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "handle",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "ehrConnection(first:2147483647)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "5aa3a3548b98b0d65af48b5b66af3fa4";

export default node;
