/**
 * @generated SignedSource<<cac157462bbc678ac444902af2a904fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type studyAuthors_study$data = {
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly secondaryAuthors: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly " $fragmentType": "studyAuthors_study";
};
export type studyAuthors_study$key = {
  readonly " $data"?: studyAuthors_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"studyAuthors_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "studyAuthors_study"
};

(node as any).hash = "141c698f6a9ef66b5ef004093bc38397";

export default node;
