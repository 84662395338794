import ButtonToggleGroup from '@bfly/ui2/ButtonToggleGroup';
import Form, { useFormValues } from '@bfly/ui2/Form';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import Text from '@bfly/ui2/Text';
import Textarea from '@bfly/ui2/Textarea';
import Tooltip from '@bfly/ui2/Tooltip';
import { useEffect, useState } from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';

import {
  feedbackLabels,
  imageAccuracyCopy,
  imageAccuracyLabels,
  imageQualityLabels,
  imageQualityOptions,
} from 'messages/examStandardQa';
import {
  type StandardQaFeedback,
  interpretationAccuracyOptions,
  standardQaFeedbackOptions,
} from 'schemas/standardQa';

function useImageQualityValue() {
  const imageQualityFormValue = useFormValues('imageQuality');

  return imageQualityFormValue && imageQualityLabels[imageQualityFormValue] ? (
    <div>
      {imageQualityFormValue}
      {' - '}
      <FormattedMessage {...imageQualityLabels[imageQualityFormValue]} />
    </div>
  ) : null;
}

function useFeedbackCommentsValue() {
  const { formatMessage } = useIntl();

  const feedbackValue = useFormValues('feedback') || [];
  const commentsValue = useFormValues('comments');

  return (
    <>
      {feedbackValue
        .map((option: StandardQaFeedback) =>
          feedbackLabels[option] ? formatMessage(feedbackLabels[option]) : '',
        )
        .filter((f?: string | null) => Boolean(f?.trim()))
        .join(', ')}
      {commentsValue
        ?.split('\n')
        .filter((f?: string | null) => Boolean(f?.trim()))
        .map((line: string) => (
          <>
            {line}
            <br />
          </>
        ))}
    </>
  );
}

function FeedbackTextarea() {
  const [fieldRef, attachRef] = useState<HTMLTextAreaElement>();

  useEffect(() => {
    if (!fieldRef) return;
    fieldRef.style.resize = 'none';
    fieldRef.rows = 1;
  }, [fieldRef]);

  const setRows = () => {
    if (!fieldRef) return;
    const hasFocus = !!document.activeElement?.isEqualNode(fieldRef);

    if (!hasFocus) {
      fieldRef.value = fieldRef.value.trim();
    }

    fieldRef.rows = Math.max(
      hasFocus ? 2 : 1,
      fieldRef.value.split('\n').length,
    );
    // handles multilines
    while (fieldRef.scrollHeight > fieldRef.clientHeight) fieldRef.rows += 1;
  };

  return (
    <Form.Field
      placeholder={defineMessage({
        id: 'standardQa.commentPlaceholder',
        defaultMessage: 'Click to add feedback',
      })}
      variant="secondary"
      as={Textarea}
      name="comments"
      className="w-full"
      ref={attachRef}
      onBlur={() => setRows()}
      onFocus={() => setRows()}
      onChange={() => setRows()}
    />
  );
}

export default function ExamQAStandard({
  readOnly,
  showImageQualityTooltips,
  hideImageInterpretationAccuracyFields = false,
  feedbackOptions = standardQaFeedbackOptions,
  imageQualityRequired = false,
}: {
  readOnly: boolean;
  showImageQualityTooltips: boolean;
  hideImageInterpretationAccuracyFields?: boolean;
  feedbackOptions?: StandardQaFeedback[];
  imageQualityRequired?: boolean;
}) {
  const imageQualityValue = useImageQualityValue();

  const accuracyAsPresentedValue = useFormValues('accuracyAsPresented');

  const accuracyComparedToGoldStandardValue = useFormValues(
    'accuracyComparedToGoldStandard',
  );

  const feedbackAndCommentValue = useFeedbackCommentsValue();

  const showField = (...value: any[]): boolean =>
    // can't simply do a truthy check - we allow zero or false
    !readOnly || value.some((v) => v !== null && v !== undefined && v !== '');

  return (
    <>
      {!showField(
        imageQualityValue,
        accuracyAsPresentedValue,
        accuracyComparedToGoldStandardValue,
        feedbackAndCommentValue,
      ) && (
        <Text>
          <FormattedMessage
            id="standardQa.noData"
            defaultMessage="Not completed"
          />
        </Text>
      )}

      {showField(imageQualityValue) && (
        <div>
          <Form.Field
            className="m-0"
            name="imageQuality"
            invalid={imageQualityRequired && !imageQualityValue}
            required={imageQualityRequired}
            validateOn={null}
          >
            {(props, meta) => (
              <>
                <label
                  // used to scroll to invalid questions
                  data-invalid={meta.invalid ? '' : undefined}
                  htmlFor={props.name}
                  className={meta.invalid ? 'text-red-40' : ''}
                >
                  <Text variant="body-bold">
                    <FormattedMessage
                      id="standardQa.imageQuality"
                      defaultMessage="Image quality"
                    />
                  </Text>
                </label>

                <>
                  {readOnly ? (
                    imageQualityValue
                  ) : (
                    <ButtonToggleGroup
                      {...props}
                      type="radio"
                      allowUnselectingRadios
                      data-bni-id="QAStandard-Field-imageQuality"
                    >
                      {imageQualityOptions.map((value) => (
                        <Tooltip.Trigger
                          key={value}
                          placement="bottom"
                          className="font-normal text-center"
                          show={showImageQualityTooltips ? undefined : false}
                          tooltip={
                            <FormattedMessage {...imageQualityLabels[value]} />
                          }
                        >
                          <ButtonToggleGroup.Item
                            className="h-[2.8rem]"
                            key={value}
                            value={value}
                          />
                        </Tooltip.Trigger>
                      ))}
                    </ButtonToggleGroup>
                  )}
                </>
              </>
            )}
          </Form.Field>
        </div>
      )}

      {!hideImageInterpretationAccuracyFields &&
        showField(
          accuracyAsPresentedValue,
          accuracyComparedToGoldStandardValue,
        ) && (
          <div>
            <Text as="div" variant="body-bold" color="headline">
              <FormattedMessage
                id="standardQa.accuracyOfInterpretation"
                defaultMessage="Image interpretation accuracy"
              />
            </Text>
            {showField(accuracyAsPresentedValue) && (
              <Form.FieldGroup
                className="m-0"
                name="accuracyAsPresented"
                label={
                  <FormattedMessage
                    id="standardQa.accuracyAsPresented"
                    defaultMessage="As presented"
                  />
                }
              >
                {(props) => (
                  <>
                    {readOnly ? (
                      <div>
                        {imageAccuracyLabels[props.value] && (
                          <div>
                            <FormattedMessage
                              {...imageAccuracyLabels[props.value]}
                            />
                            {' - '}
                            <FormattedMessage
                              {...imageAccuracyCopy[props.value]}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <ButtonToggleGroup
                        {...props}
                        type="radio"
                        allowUnselectingRadios
                        data-bni-id="QAStandard-Field-accuracyAsPresented"
                      >
                        {interpretationAccuracyOptions.map((value) => (
                          <Tooltip.Trigger
                            placement="bottom"
                            key={value}
                            className="font-normal text-center"
                            tooltip={
                              <FormattedMessage
                                id="standardQa.imageAccuracyCopy.{value}"
                                defaultMessage="{value}"
                                values={{
                                  value:
                                    imageAccuracyCopy[value].defaultMessage,
                                }}
                              />
                            }
                          >
                            <ButtonToggleGroup.Item
                              className="h-[2.8rem]"
                              key={value}
                              value={value}
                            >
                              {imageAccuracyLabels[value] && (
                                <FormattedMessage
                                  {...imageAccuracyLabels[value]}
                                />
                              )}
                            </ButtonToggleGroup.Item>
                          </Tooltip.Trigger>
                        ))}
                      </ButtonToggleGroup>
                    )}
                  </>
                )}
              </Form.FieldGroup>
            )}
            {showField(accuracyComparedToGoldStandardValue) && (
              <Form.FieldGroup
                className="m-0 mt-4"
                name="accuracyComparedToGoldStandard"
                label={
                  <FormattedMessage
                    id="standardQa.accuracyComparedToGoldStandard"
                    defaultMessage="Compared to gold standard (i.e., CT, operative report)"
                  />
                }
              >
                {(props) => (
                  <>
                    {readOnly ? (
                      <div>
                        {imageAccuracyLabels[props.value] && (
                          <div>
                            <FormattedMessage
                              {...imageAccuracyLabels[props.value]}
                            />
                            {' - '}
                            <FormattedMessage
                              {...imageAccuracyCopy[props.value]}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <ButtonToggleGroup
                        {...props}
                        type="radio"
                        allowUnselectingRadios
                        data-bni-id="QAStandard-Field-accuracyComparedToGoldStandard"
                      >
                        {interpretationAccuracyOptions.map((value) => (
                          <Tooltip.Trigger
                            placement="bottom"
                            key={value}
                            className="font-normal text-center"
                            tooltip={
                              <FormattedMessage
                                id="standardQa.imageAccuracyCopy.{value}"
                                defaultMessage="{value}"
                                values={{
                                  value:
                                    imageAccuracyCopy[value].defaultMessage,
                                }}
                              />
                            }
                          >
                            <ButtonToggleGroup.Item
                              className="h-[2.8rem]"
                              key={value}
                              value={value}
                            >
                              {imageAccuracyLabels[value] && (
                                <FormattedMessage
                                  {...imageAccuracyLabels[value]}
                                />
                              )}
                            </ButtonToggleGroup.Item>
                          </Tooltip.Trigger>
                        ))}
                      </ButtonToggleGroup>
                    )}
                  </>
                )}
              </Form.FieldGroup>
            )}
          </div>
        )}

      {showField(feedbackAndCommentValue) && (
        <div>
          <Text
            as="label"
            variant="body-bold"
            color="headline"
            htmlFor="feedback"
            className="mb-2"
          >
            <FormattedMessage
              id="standardQa.feedback"
              defaultMessage="Feedback"
            />
          </Text>
          {!readOnly ? (
            <>
              <Form.Field
                as={FormCheckGroup}
                name="feedback"
                type="checkbox"
                data={feedbackOptions}
                renderItem={(option) => (
                  <FormattedMessage {...feedbackLabels[option]} />
                )}
                pad={0}
                data-bni-id="QAStandard-Field-feedback"
              />
              <FeedbackTextarea />
            </>
          ) : (
            <div>{feedbackAndCommentValue}</div>
          )}
        </div>
      )}
    </>
  );
}
