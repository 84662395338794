import { graphql } from 'react-relay';

import AppPage from 'components/AppPage';
import RenameRedirect from 'components/RenameRedirect';
import Route from 'components/Route';
import { routes } from 'routes/archive';

import SharedBundlePage from './components/SharedBundlePage';

export default (
  <Route
    allowPublic
    mobileFriendly
    path="shared"
    Component={AppPage}
    query={AppPage.query}
  >
    <Route
      path=":sharedBundleHandle"
      Component={SharedBundlePage}
      getQuery={({ location }) =>
        !location.query.region || location.query.region.toLowerCase() === 'us'
          ? graphql`
              query sharedBundles_SharedBundlePage_Query(
                $sharedBundleHandle: String!
              ) {
                sharedBundleOrError(handle: $sharedBundleHandle) {
                  ...SharedBundlePage_sharedBundleOrError
                }
              }
            `
          : null
      }
      renderFetched={({ Component, props: { sharedBundleOrError } }) => {
        return <Component sharedBundleOrError={sharedBundleOrError || null} />;
      }}
    >
      <Route />
      <Route path=":sharedBundleImageHandle" />
      <RenameRedirect
        from="shared-images/:sharedBundleImageHandle"
        to={(match) =>
          routes.sharedBundleImage({
            sharedBundleHandle: match.params.sharedBundleHandle,
            sharedBundleImageHandle: match.params.sharedBundleImageHandle,
          })
        }
      />
    </Route>
  </Route>
);
