/**
 * @generated SignedSource<<75d40a213a971e652aedfb804ca1a7c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type baseStudyInfo_patient$data = {
  readonly birthDate: string | null;
  readonly medicalRecordNumber: string | null;
  readonly nameFirst: string | null;
  readonly nameLast: string | null;
  readonly nameMiddle: string | null;
  readonly namePrefix: string | null;
  readonly nameSuffix: string | null;
  readonly sex: PatientSex | null;
  readonly " $fragmentType": "baseStudyInfo_patient";
};
export type baseStudyInfo_patient$key = {
  readonly " $data"?: baseStudyInfo_patient$data;
  readonly " $fragmentSpreads": FragmentRefs<"baseStudyInfo_patient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "baseStudyInfo_patient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameFirst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameLast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameMiddle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namePrefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameSuffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "medicalRecordNumber",
      "storageKey": null
    }
  ],
  "type": "PatientInterface",
  "abstractKey": "__isPatientInterface"
};

(node as any).hash = "34cc20325b205a492763a5631f0952ee";

export default node;
