/**
 * @generated SignedSource<<6e55f2dc938b9428ce4eb47b432c5b72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainMemberSettingsPage_viewer$data = {
  readonly domain: {
    readonly dicomFieldTemplateConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"DomainMemberDicomFieldsFieldSet_dicomFieldTemplates">;
        } | null;
      } | null> | null;
    } | null;
    readonly ehrConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"DomainMemberIntegrationConfigsFieldSet_ehrs">;
        } | null;
      } | null> | null;
    } | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"DomainMemberDeleteFieldSet_domain" | "DomainMemberDetailFieldSet_domain">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMemberAccessRoleFieldSet_viewer">;
  readonly " $fragmentType": "DomainMemberSettingsPage_viewer";
};
export type DomainMemberSettingsPage_viewer$key = {
  readonly " $data"?: DomainMemberSettingsPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMemberSettingsPage_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "domain",
          "ehrConnection"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "domain",
          "dicomFieldTemplateConnection"
        ]
      }
    ]
  },
  "name": "DomainMemberSettingsPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "ehrConnection",
          "args": null,
          "concreteType": "EhrConnection",
          "kind": "LinkedField",
          "name": "__Domain_ehrConnection_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EhrEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Ehr",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DomainMemberIntegrationConfigsFieldSet_ehrs"
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "dicomFieldTemplateConnection",
          "args": null,
          "concreteType": "DomainDicomFieldTemplateConnection",
          "kind": "LinkedField",
          "name": "__Domain_dicomFieldTemplateConnection_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DomainDicomFieldTemplateEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DomainDicomFieldTemplate",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DomainMemberDicomFieldsFieldSet_dicomFieldTemplates"
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DomainMemberDetailFieldSet_domain"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DomainMemberDeleteFieldSet_domain"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DomainMemberAccessRoleFieldSet_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "220b7f59c124f0619e826e71de4dff94";

export default node;
