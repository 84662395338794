/**
 * @generated SignedSource<<d6b1f35ffe769b16e230b52c23a31479>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentListItem_isCommentAuthor$data = {
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "CommentListItem_isCommentAuthor";
};
export type CommentListItem_isCommentAuthor$key = {
  readonly " $data"?: CommentListItem_isCommentAuthor$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentListItem_isCommentAuthor">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "CommentListItem_isCommentAuthor"
};

(node as any).hash = "74f28a4e5592935798e1cb0354accda8";

export default node;
