/**
 * @generated SignedSource<<d80f9e2265d4aed17c9699c0ce9ac7b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserCredentialingProgressGridUserEnrollmentsQuery$variables = {
  userId: string;
};
export type UserCredentialingProgressGridUserEnrollmentsQuery$data = {
  readonly lms: {
    readonly enrollmentConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly courseCompletedAt: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type UserCredentialingProgressGridUserEnrollmentsQuery = {
  response: UserCredentialingProgressGridUserEnrollmentsQuery$data;
  variables: UserCredentialingProgressGridUserEnrollmentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  },
  {
    "items": [
      {
        "kind": "Variable",
        "name": "userId.0",
        "variableName": "userId"
      }
    ],
    "kind": "ListValue",
    "name": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "courseCompletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserCredentialingProgressGridUserEnrollmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LmsEnrollmentConnection",
            "kind": "LinkedField",
            "name": "enrollmentConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LmsEnrollmentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LmsEnrollment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserCredentialingProgressGridUserEnrollmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LmsEnrollmentConnection",
            "kind": "LinkedField",
            "name": "enrollmentConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LmsEnrollmentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LmsEnrollment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37d07942c5eaa3c68bb390fe55deabab",
    "id": null,
    "metadata": {},
    "name": "UserCredentialingProgressGridUserEnrollmentsQuery",
    "operationKind": "query",
    "text": "query UserCredentialingProgressGridUserEnrollmentsQuery(\n  $userId: String!\n) {\n  lms {\n    enrollmentConnection(userId: [$userId], first: 500) {\n      edges {\n        node {\n          courseCompletedAt\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d735e1470e452bdd027260b7ce12b18";

export default node;
