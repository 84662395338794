/**
 * @generated SignedSource<<ca6e0b99536878616c7c5dc6ca9db075>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedBundlePage_sharedBundleOrError$data = {
  readonly sharedImageConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"DesktopSharedImageList_images" | "MobileSharedImageDetail_images" | "MobileSharedImageList_images">;
      } | null;
    } | null> | null;
  } | null;
  readonly type: "SharedBundle";
  readonly " $fragmentType": "SharedBundlePage_sharedBundleOrError";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly type: "%other";
  readonly " $fragmentType": "SharedBundlePage_sharedBundleOrError";
};
export type SharedBundlePage_sharedBundleOrError$key = {
  readonly " $data"?: SharedBundlePage_sharedBundleOrError$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedBundlePage_sharedBundleOrError">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedBundlePage_sharedBundleOrError",
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 2147483647
            }
          ],
          "concreteType": "SharedBundleImageConnection",
          "kind": "LinkedField",
          "name": "sharedImageConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SharedBundleImageEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SharedBundleImage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DesktopSharedImageList_images"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "MobileSharedImageDetail_images"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "MobileSharedImageList_images"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "sharedImageConnection(first:2147483647)"
        }
      ],
      "type": "SharedBundle",
      "abstractKey": null
    }
  ],
  "type": "SharedBundleOrError",
  "abstractKey": "__isSharedBundleOrError"
};

(node as any).hash = "84b0bff3e6d07b1a450235cc9583f56f";

export default node;
