import useCallbackRef from '@restart/hooks/useCallbackRef';
import useIntersectionObserver from '@restart/hooks/useIntersectionObserver';
import { css } from 'astroturf';
import clsx from 'clsx';
import isDocument from 'dom-helpers/isDocument';
import scrollParent from 'dom-helpers/scrollParent';
import React, { useMemo } from 'react';

function isStuck(entry) {
  if (!entry || !entry.rootBounds) return false;

  const targetInfo = entry.boundingClientRect;
  const rootBoundsInfo = entry.rootBounds;
  return targetInfo.bottom < rootBoundsInfo.top;
}

interface Props {
  as?: React.ElementType;
  top?: number;
  className?: string;
  stuckClassName?: string;
  children?: React.ReactNode;
}

function StickyContainer({
  as: Tag = 'div',
  top = 0,
  className,
  stuckClassName = 'is-stuck',
  children,
}: Props) {
  const [ref, attachRef] = useCallbackRef<HTMLSpanElement>();

  const root = useMemo(() => {
    const parent = ref && scrollParent(ref, true);
    return !parent || isDocument(parent) ? null : parent;
  }, [ref]);

  const [entry] = useIntersectionObserver(
    ref,
    useMemo(
      () => ({ threshold: [0], root, rootMargin: `${-top}px` }),
      [root, top],
    ),
  );

  const stuck = isStuck(entry);

  return (
    <>
      <span
        ref={attachRef}
        css={css`
          flex: 0;
          width: 100%;
          pointer-events: none;
        `}
      />
      <Tag
        data-stuck={stuck}
        className={clsx('sticky', className, stuck && stuckClassName)}
        style={{ top }}
      >
        {children}
      </Tag>
    </>
  );
}

export default StickyContainer;
