/**
 * @generated SignedSource<<ae37b144ba99d8d3b3cda28f779dea97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarListSuggestionOption_organization$data = {
  readonly name: string | null;
  readonly " $fragmentType": "SearchBarListSuggestionOption_organization";
};
export type SearchBarListSuggestionOption_organization$key = {
  readonly " $data"?: SearchBarListSuggestionOption_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_organization">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarListSuggestionOption_organization"
};

(node as any).hash = "a949371d5dacf0132d3fe03e44fcbf49";

export default node;
