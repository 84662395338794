import { FormVariant } from '@bfly/ui2/Form';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import AvatarList from './AvatarList';
import StudyAuthorsListItem from './StudyAuthorsListItem';
import { StudyAuthorsList_study$data as Study } from './__generated__/StudyAuthorsList_study.graphql';

interface Props {
  study: Study;
  variant?: FormVariant | null;
  className?: string;
  avatarSize?: number;
}

function StudyAuthorsList({ study, variant, className, avatarSize }: Props) {
  const { createdBy, transcribedBy, secondaryAuthors } = study;

  const authors = useMemo(
    () =>
      sortBy(
        [createdBy, ...secondaryAuthors!].filter(Boolean),
        (s) => s!.name,
      ),
    [createdBy, secondaryAuthors],
  );

  return (
    <AvatarList
      data-bni-id="StudyAuthorsList"
      variant={variant}
      className={className}
    >
      {authors.map((author) => (
        <StudyAuthorsListItem
          key={author!.id}
          study={study}
          author={author!}
          avatarSize={avatarSize}
          isPrimary={author === createdBy}
          isScribe={author!.id === transcribedBy?.id}
          authorsCount={authors.length}
        />
      ))}
    </AvatarList>
  );
}

export default createFragmentContainer(StudyAuthorsList, {
  study: graphql`
    fragment StudyAuthorsList_study on Study {
      id
      createdBy {
        id
        name
        ...StudyAuthorsListItem_author
      }
      transcribedBy {
        id
      }
      secondaryAuthors {
        id
        name
        ...StudyAuthorsListItem_author
      }
      ...StudyAuthorsListItem_study
    }
  `,
});
