import BlankSlate from '@bfly/ui2/BlankSlate';
import Link from '@bfly/ui2/Link';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import accountMessages from 'messages/account';
import { useConfigRoutes } from 'routes/config';

function InviteEmailMismatchPage() {
  const configRoutes = useConfigRoutes();
  return (
    <Page.Container center>
      <MainContent centerText>
        <BlankSlate>
          <BlankSlate.Title>
            <FormattedMessage
              id="acceptInvite.emailMismatch.title"
              defaultMessage="The email on this invitation does not match this user's current email."
            />
          </BlankSlate.Title>

          <BlankSlate.Body>
            <FormattedMessage
              id="acceptInvite.emailMismatch.body.desktop"
              defaultMessage="This invitation is no longer valid. If you are already a member of this 
              organization and have recently changed your email, then please log in with your updated 
              email. If you are not already a member of this organization, then please request a new 
              invitation from your administrator to your preferred email."
            />
          </BlankSlate.Body>
          <Link as={BlankSlate.Action} to={configRoutes.rootRoute()} size="lg">
            <FormattedMessage {...accountMessages.logIn} />
          </Link>
        </BlankSlate>
      </MainContent>
    </Page.Container>
  );
}

export default InviteEmailMismatchPage;
