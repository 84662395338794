import HttpError from 'found/HttpError';
import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import type { Match, RelayRouteRenderArgs } from 'components/Route';
import { routes as archiveRoutes } from 'routes/archive';
import {
  getLastArchiveHandle,
  getRedirectLocation,
} from 'utils/EagerRedirectUtils';

import { organizationRedirect_OrganizationQuery as OrganizationRedirectQuery } from './__generated__/organizationRedirect_OrganizationQuery.graphql';

export type { OrganizationRedirectQuery };

function getQuery(match: Match) {
  const {
    params: { organizationSlug },
    location,
  } = match;

  const archiveHandle = getLastArchiveHandle(match, organizationSlug);
  const redirect = getRedirectLocation(match, organizationSlug);

  if (redirect) {
    throw new RedirectException(redirect);
  }

  if (archiveHandle) {
    // Redirect in the query aggregation phase. We don't need server data.
    throw new RedirectException({
      pathname: archiveRoutes.archive({ organizationSlug, archiveHandle }),
      state: {
        isOrganizationRedirect: true,
        prevLocation: location,
      },
    });
  }

  return graphql`
    query organizationRedirect_OrganizationQuery($organizationSlug: String!) {
      organization: organizationBySlug(slug: $organizationSlug) {
        slug
        viewerCanQa
        # Setting first here matches other uses of this connection.
        archiveConnection(first: 2147483647)
          @connection(key: "Organization_archiveConnection") {
          edges {
            node {
              handle
            }
          }
        }
      }
    }
  `;
}

function organizationRedirect({
  props,
}: RelayRouteRenderArgs<OrganizationRedirectQuery['response']>) {
  if (!props) {
    return;
  }

  const { organization } = props;
  if (!organization) {
    // This shouldn't be possible; we shouldn't be here if we can't read the
    // organization.
    throw new HttpError(500);
  }

  const { slug, archiveConnection } = organization;

  const archive = archiveConnection!.edges![0]?.node;
  if (!archive) {
    // This shouldn't be possible. The back end maintains an invariant that all
    // organizations have at least one archive.
    throw new HttpError(500);
  }

  throw new RedirectException(
    archiveRoutes.archive({
      organizationSlug: slug!,
      archiveHandle: archive.handle!,
    }),
  );
}

organizationRedirect.getQuery = getQuery;

export default organizationRedirect;
