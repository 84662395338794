import Dropdown from '@bfly/ui2/Dropdown';
import React from 'react';

type Props = Omit<React.ComponentProps<typeof Dropdown.Item>, 'onClick'> & {
  onClick?: (file: File) => void;
};

/**
 * A DropdownItem that is actually a label and file input for uploading files
 * from a menu
 */
function DropdownFileItem({ onClick, children, ...props }: Props) {
  return (
    <Dropdown.Item
      {...props}
      as="label"
      tabIndex={0}
      css="cursor: pointer;"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.click();
        }
      }}
    >
      <input
        type="file"
        onChange={(e) => onClick?.(e.currentTarget.files![0]!)}
        value=""
        accept=".csv"
        className="sr-only"
      />
      {children}
    </Dropdown.Item>
  );
}

export default DropdownFileItem;
