import Layout from '@4c/layout';
import Anchor from '@bfly/ui2/Anchor';
import React from 'react';
import { useIntl } from 'react-intl';

import { isAndroid, isIos } from '../../utils/browserInfo';
import androidBadge from '../assets/download-android.svg';
import iosBadge from '../assets/download-ios.svg';

interface Props {
  className?: string;
}

function AppStoreDownloadLinks({ className }: Props) {
  const intl = useIntl();

  let showIos = true;
  let showAndroid = true;

  if (isIos()) {
    showAndroid = false;
  } else if (isAndroid()) {
    showIos = false;
  }

  return (
    <Layout
      pad
      direction="column"
      align="center"
      justify="center"
      className={className}
    >
      {showIos && (
        <Anchor
          href={globalThis.bflyConfig.APP_LINK_IOS}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width={120}
            src={iosBadge}
            alt={intl.formatMessage({
              id: 'appStoreDownloadLink.ios.alt',
              defaultMessage: 'Download the Butterfly iQ app on the App Store',
            })}
          />
        </Anchor>
      )}
      {showAndroid && (
        <Anchor
          href={globalThis.bflyConfig.APP_LINK_ANDROID}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width={120}
            src={androidBadge}
            alt={intl.formatMessage({
              id: 'appStoreDownloadLink.android.alt',
              defaultMessage: 'Get the Butterfly iQ app on Google Play',
            })}
          />
        </Anchor>
      )}
    </Layout>
  );
}

export default AppStoreDownloadLinks;
