/**
 * @generated SignedSource<<81125ff32fe86a8b8ea9454702abd266>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSearchState_tenant$data = {
  readonly slug?: string | null;
  readonly " $fragmentType": "useSearchState_tenant";
};
export type useSearchState_tenant$key = {
  readonly " $data"?: useSearchState_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSearchState_tenant">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSearchState_tenant"
};

(node as any).hash = "0211a53079083003e1dd5b2479c0e994";

export default node;
