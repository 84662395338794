/**
 * @generated SignedSource<<6d5dfd0674295e14bba668c1fb4124fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EducationContentType = "COURSE" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EducationContentTile_educationContent$data = {
  readonly category: {
    readonly name: string | null;
  } | null;
  readonly contentType: EducationContentType | null;
  readonly course: {
    readonly absorbId: string | null;
    readonly dueDate: string | null;
    readonly viewerHasCompleted: boolean | null;
  } | null;
  readonly duration: number | null;
  readonly handle: string | null;
  readonly id: string;
  readonly isPremium: boolean | null;
  readonly name: string | null;
  readonly thumbnails: ReadonlyArray<{
    readonly url: string | null;
  } | null> | null;
  readonly video: {
    readonly vimeoId: string | null;
  } | null;
  readonly viewerHasAccess: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"EducationContentTileMobileActions_educationContent" | "Thumbnail_image" | "useSaveContentToggle_educationContent">;
  readonly " $fragmentType": "EducationContentTile_educationContent";
};
export type EducationContentTile_educationContent$key = {
  readonly " $data"?: EducationContentTile_educationContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationContentTile_educationContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasAccess",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationContentTile_educationContent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPremium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContentCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContentVideo",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vimeoId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContentCourse",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerHasCompleted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "absorbId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnails",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Thumbnail_image"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useSaveContentToggle_educationContent",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerHasSaved",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EducationContentTileMobileActions_educationContent"
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};
})();

(node as any).hash = "e8662b837fcd34eb0b2722d6113879f2";

export default node;
