import DropdownIcon from '@bfly/icons/Dropdown';
import styled from 'astroturf/react';

const ExpandIcon = styled(DropdownIcon)<{ expanded?: boolean }>`
  transition: transform 300ms ease-in-out;

  &.expanded {
    transform: rotate(180deg);
  }
`;

export default ExpandIcon;
