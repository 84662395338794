import Help from '@bfly/icons/Help';
import Dropdown from '@bfly/ui2/Dropdown';
import Link from '@bfly/ui2/Link';
import Navbar from '@bfly/ui2/Navbar';
import SrOnly from '@bfly/ui2/SrOnly';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import AboutControl from 'components/AboutControl';
import { useVariationAllowMissingLdContext } from 'components/LaunchDarklyContext';
import ZendeskTicketLink, { TicketType } from 'components/ZendeskTicketLink';
import { useAuthRoutes } from 'routes/auth';
import getClosestLocale from 'utils/getClosestLocale';

const DEFAULT_USER_MANUAL_URL = 'https://www.butterflynetwork.com/manual';

export default function HelpDropdownButton() {
  const authRoutes = useAuthRoutes();
  const userManualUrls = useVariationAllowMissingLdContext('user-manual-urls');

  const userManualUrl = useMemo(() => {
    const closestLocale = getClosestLocale(Object.keys(userManualUrls));
    return userManualUrls[closestLocale] || DEFAULT_USER_MANUAL_URL;
  }, [userManualUrls]);

  return (
    <Dropdown placement="bottom-end">
      <Dropdown.Toggle id="help-dropdown" as={Navbar.Button}>
        <Help />
        <SrOnly>
          <FormattedMessage id="helpMenu" defaultMessage="Help" />
        </SrOnly>
      </Dropdown.Toggle>
      <Dropdown.Menu data-bni-id="HelpDropdownMenu">
        <AboutControl />
        <Link to={authRoutes.ssoZendesk()}>
          {({ active: _, ...rest }) => (
            <Dropdown.Item
              data-bni-id="HelpSupportCenterItem"
              {...rest}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="helpMenu.selfService"
                defaultMessage="Support Center"
              />
            </Dropdown.Item>
          )}
        </Link>
        <Dropdown.Item
          data-bni-id="HelpUserManualItem"
          href={userManualUrl}
          target="_blank"
        >
          <FormattedMessage
            id="helpMenu.userManual"
            defaultMessage="User Manual"
          />
        </Dropdown.Item>
        <Dropdown.Divider />
        <ZendeskTicketLink
          data-bni-id="HelpSubmitFeedbackItem"
          as={Dropdown.Item}
          ticketType={TicketType.FEEDBACK}
        >
          <FormattedMessage
            id="helpMenu.submitFeedback"
            defaultMessage="Submit Feedback"
          />
        </ZendeskTicketLink>
        <ZendeskTicketLink
          data-bni-id="HelpReportBugItem"
          as={Dropdown.Item}
          ticketType={TicketType.BUG}
        >
          <FormattedMessage
            id="helpMenu.reportBug"
            defaultMessage="Report a Bug"
          />
        </ZendeskTicketLink>
        <ZendeskTicketLink
          data-bni-id="HelpContactSupportItem"
          as={Dropdown.Item}
          ticketType={TicketType.QUESTION}
        >
          <FormattedMessage
            id="helpMenu.contactSupport"
            defaultMessage="Contact Support"
          />
        </ZendeskTicketLink>
      </Dropdown.Menu>
    </Dropdown>
  );
}
