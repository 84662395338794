import { graphql } from 'react-relay';

import Route from 'components/Route';

import NotificationsPage from './components/NotificationsPage';

export default (
  <Route
    hideSidePanel
    path="notifications"
    Component={NotificationsPage}
    query={graphql`
      query notifications_NotificationPageQuery(
        $organizationSlug: String!
        $count: Int!
        $cursor: String
        $isCleared: [Boolean]
      ) {
        organization: organizationBySlug(slug: $organizationSlug) {
          ...NotificationsPage_organization
            @arguments(count: $count, cursor: $cursor, isCleared: $isCleared)
        }
      }
    `}
    prepareVariables={(variables, { params }) => ({
      ...variables,
      organizationSlug: params.organizationSlug!,
      isCleared: [true, false],
      count: 24,
    })}
  />
);
