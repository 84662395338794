import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType, DeepNonNullable } from '@bfly/utils/types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { dataItem } from 'react-widgets/Accessors';

import CheckGroupSelectButton, {
  CheckGroupSelectButtonHOCProps,
} from 'components/CheckGroupSelectButton';

import {
  ExamTypeSelectButtonTenantQuery as ExamTypeQuery,
  ExamTypeSelectButtonTenantQuery$data as TenantQuery,
} from './__generated__/ExamTypeSelectButtonTenantQuery.graphql';

export const NO_EXAM_TYPE_OPTION: ExamType = {
  id: '@@NO_EXAM_TYPE',
  handle: '@@NO_EXAM_TYPE',
  name: 'No Exam Type',
  hiddenAt: '',
  isAssociatedWithStudies: true,
  systemId: '',
};

type ExamType = DeepNonNullable<
  ConnectionNodeType<TenantQuery['tenant'], 'examTypeConnection'>
>;

interface Props extends CheckGroupSelectButtonHOCProps<ExamType> {
  tenantId: string;
  showTypesWithNoStudies?: boolean;
  includeNoExamTypeOption?: boolean;
  hideNonSystemTypes?: boolean;
  filter?: (examType: ExamType) => boolean;
  hideFilter?: boolean;
}

function ExamTypeSelectButton({
  tenantId,
  showTypesWithNoStudies = false,
  includeNoExamTypeOption = false,
  placeholder,
  dataKey,
  hideNonSystemTypes,
  filter,
  ...props
}: Props) {
  const { data } = useQuery<ExamTypeQuery>(
    graphql`
      query ExamTypeSelectButtonTenantQuery($tenantId: ID!) {
        tenant: node(id: $tenantId) {
          ... on TenantInterface {
            id
            examTypeConnection(first: 2147483647) {
              edges {
                node {
                  id
                  handle
                  name
                  hiddenAt
                  isAssociatedWithStudies
                  systemId
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      skip: !tenantId,
      variables: { tenantId },
    },
  );

  const examTypes = useMemo(() => {
    const items = data?.tenant?.examTypeConnection
      ? getNodes(data?.tenant.examTypeConnection).filter((e) => {
          if (hideNonSystemTypes) {
            return e.systemId;
          }

          return e.hiddenAt
            ? e.isAssociatedWithStudies
            : showTypesWithNoStudies || e.isAssociatedWithStudies;
        })
      : [];

    if (data && includeNoExamTypeOption) {
      items.unshift(NO_EXAM_TYPE_OPTION);
    }

    return filter ? items.filter(filter) : items;
  }, [
    data,
    includeNoExamTypeOption,
    hideNonSystemTypes,
    showTypesWithNoStudies,
    filter,
  ]);

  // FIXME: this should handled by CheckGroupSelectButton
  // It covers a case where dropdownForm uses the raw form value instead of the data items
  const valueExamTypes =
    props.value?.map((item) => dataItem(examTypes, item, dataKey)) ?? null;

  return (
    <CheckGroupSelectButton
      {...props}
      value={valueExamTypes}
      dataKey={dataKey}
      loading={!data}
      placeholder={
        placeholder || (
          <FormattedMessage
            id="examTypeSelectButton.placeholder"
            defaultMessage="All exam types"
          />
        )
      }
      data={examTypes}
      textKey="name"
    />
  );
}

export default ExamTypeSelectButton;
