/**
 * @generated SignedSource<<16375ed3b7ab36c6dc542d8e4ec51ea7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyList_studies$data = ReadonlyArray<{
  readonly archive: {
    readonly id: string;
  } | null;
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly deletedAt: string | null;
  readonly examTypes: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly finalizedAt: string | null;
  readonly id: string;
  readonly patient: {
    readonly nameFirst: string | null;
    readonly nameLast: string | null;
    readonly nameMiddle: string | null;
    readonly namePrefix: string | null;
    readonly nameSuffix: string | null;
  } | null;
  readonly practiceType: StudyPracticeType | null;
  readonly vetPatient: {
    readonly clientNameLast: string | null;
    readonly clientOrganizationName: string | null;
    readonly name: string | null;
  } | null;
  readonly viewerCanDelete: boolean | null;
  readonly viewerCanMove: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyListItem_study">;
  readonly " $fragmentType": "StudyList_studies";
}>;
export type StudyList_studies$key = ReadonlyArray<{
  readonly " $data"?: StudyList_studies$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyList_studies">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showArchive"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudyList_studies",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanDelete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanMove",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameFirst",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameLast",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameMiddle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "namePrefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameSuffix",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientNameLast",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientOrganizationName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examTypes",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "showArchive",
          "variableName": "showArchive"
        }
      ],
      "kind": "FragmentSpread",
      "name": "StudyListItem_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "20c5ed157269b98b114706c70b70cf12";

export default node;
