/**
 * @generated SignedSource<<94f992f46c6c3d3c5bd366abec405d12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationList_notificationCenter$data = {
  readonly id: string;
  readonly notificationConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id?: string;
        readonly typename: string;
        readonly " $fragmentSpreads": FragmentRefs<"StudyFinalizationRequestedNotification_notification" | "StudyImageCommentNotification_notification" | "WorksheetReviewedNotification_notification">;
      } | null;
    } | null> | null;
  } | null;
  readonly numUnseenNotifications: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationCenterMarkAllReadControl_notificationCenter" | "NotificationCenterViewAllControl_notificationCenter">;
  readonly " $fragmentType": "NotificationList_notificationCenter";
};
export type NotificationList_notificationCenter$key = {
  readonly " $data"?: NotificationList_notificationCenter$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationList_notificationCenter">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 24,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "notificationConnection"
        ]
      }
    ]
  },
  "name": "NotificationList_notificationCenter",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numUnseenNotifications",
      "storageKey": null
    },
    {
      "alias": "notificationConnection",
      "args": null,
      "concreteType": "NotificationInterfaceConnection",
      "kind": "LinkedField",
      "name": "__NotificationCenter_notificationConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationInterfaceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "type": "Node",
                  "abstractKey": "__isNode"
                },
                {
                  "alias": "typename",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StudyImageCommentNotification_notification"
                    }
                  ],
                  "type": "StudyImageCommentNotification",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "WorksheetReviewedNotification_notification"
                    }
                  ],
                  "type": "WorksheetReviewedNotification",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StudyFinalizationRequestedNotification_notification"
                    }
                  ],
                  "type": "StudyFinalizationRequestedNotification",
                  "abstractKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationCenterViewAllControl_notificationCenter"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationCenterMarkAllReadControl_notificationCenter"
    }
  ],
  "type": "NotificationCenter",
  "abstractKey": null
};
})();

(node as any).hash = "376af80a7c3c7d2599ccb373085b73df";

export default node;
