import { join, partial, route } from '@4c/spa-routing';
import { useRouter } from 'found';
import { useMemo } from 'react';

import { useViewerAllowMissingContext } from 'utils/viewerContext';

import partialRoutes from '../utils/partialRoutes';

const analytics = route('/:organizationSlug/analytics');

const proficiency = join(analytics, 'proficiency');
const stats = join(analytics, 'stats');

const qaDashboard = route('/:organizationSlug/analytics/qa');

export const routes = {
  analytics,
  proficiency,
  qaDashboard,
  stats,
};

/**
 * Yields routes that scoped to the viewer's Tenant, such that Domain-scoped
 * Viewers will return routes swapping `organizationSlug` with `-`
 * where appropriate.
 *
 * @param tenantRef
 */
export function useAnalyticsRoutes() {
  const { match } = useRouter();
  const viewer = useViewerAllowMissingContext();

  const isDomainTenant = !!viewer?.domain;

  // If params are passed use them, otherwise use existing ones
  const organizationSlug = match.params?.organizationSlug;

  return useMemo(() => {
    return {
      ...partialRoutes(routes, {
        organizationSlug: isDomainTenant ? '-' : organizationSlug,
      }),
      qaDashboard: partial(qaDashboard, { organizationSlug }),
    };
  }, [organizationSlug, isDomainTenant]);
}
