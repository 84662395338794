import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

interface Props {
  value: string | number | Date;
  hideYear?: boolean;
}

/**
 * Displays a JS Date as date and time, using localized formats. The year
 * will be omitted if `hideYear` is true.
 */
function FormattedShortDateTime({ value, hideYear }: Props) {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <FormattedMessage
      id="formattedShortDateTime"
      defaultMessage="{date}, {time}"
      values={{
        date: hideYear ? (
          <FormattedDate value={value} month="numeric" day="numeric" />
        ) : (
          <FormattedDate value={value} />
        ),
        time: <FormattedTime value={value} />,
      }}
    />
  );
}

export default FormattedShortDateTime;
