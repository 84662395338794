import useQuery from '@bfly/ui2/useQuery';
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { graphql } from 'relay-runtime';

import {
  MemberCounts_Query$data as CountsData,
  MemberCounts_Query as CountsQuery,
} from './__generated__/MemberCounts_Query.graphql';

const memberCountsContext = createContext<{
  onCountUpdate: () => void;
  numUsers: number;
  maxNumSeats: number;
  numAvailableSeats: number;
  version: string;
} | null>(null);

export function useMemberCounts() {
  const context = useContext(memberCountsContext);

  if (!context) {
    throw new Error(
      'useMemberCounts must be called within MemberCountsContext provider',
    );
  }
  return context;
}

/**
 *
 * @returns a function that can be called to trigger a refetch of the member counts
 */
export function useMemberCountUpdate() {
  const { onCountUpdate } = useMemberCounts();
  return onCountUpdate;
}

export function MemberCountsContextProvider({
  children,
  domain,
}: PropsWithChildren<{
  domain: CountsData['domain'];
}>) {
  const { data, retry } = useQuery<CountsQuery>(
    graphql`
      query MemberCounts_Query($id: ID!) {
        domain: node(id: $id) {
          id
          ... on Domain {
            settings {
              numUsers
              maxNumSeats
              numAvailableSeats
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: { id: domain?.id || '' },
      skip: !domain?.id,
    },
  );

  const { Provider } = memberCountsContext;

  const settings = data?.domain?.settings || domain?.settings;

  const [version, setVersion] = useState(0);

  return (
    <Provider
      value={{
        version: `v${version}`,
        onCountUpdate: () => {
          setVersion((p) => p + 1);
          retry?.();
        },
        numUsers: settings?.numUsers || 0,
        maxNumSeats: settings?.maxNumSeats || 0,
        numAvailableSeats: settings?.numAvailableSeats || 0,
      }}
    >
      {children}
    </Provider>
  );
}
