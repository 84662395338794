import Dropdown from '@bfly/ui2/Dropdown';
import { PickerCaret } from '@bfly/ui2/Pickers';
import Text from '@bfly/ui2/Text';
import useFocusManager from '@restart/hooks/useFocusManager';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { CheckGroupSelectButtonLabel } from 'components/CheckGroupSelectButton';
import { DropdownMultiselect } from 'components/MultiselectSelectButton';
import useHandleExamTypeChange from 'hooks/useHandleExamTypeChange';
import useSuggestedExamTypesGroups from 'hooks/useSuggestedExamTypesGroups';

import { ExamExamType_study$data as Study } from './__generated__/ExamExamType_study.graphql';

const suggestedGroupTitle = (
  <FormattedMessage id="examExamType.suggested" defaultMessage="Suggested" />
);
const remainingGroupTitle = (
  <FormattedMessage id="examExamType.othertypes" defaultMessage="Exam types" />
);

interface Props {
  study: Study;
  id?: string;
  className?: string;
}

function ExamExamType({ study, className, id }: Props) {
  const [show, setShow] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const { groupBy, examTypes } = useSuggestedExamTypesGroups(
    study,
    suggestedGroupTitle,
    remainingGroupTitle,
  );

  const focusHandlers = useFocusManager({
    isDisabled: () => false,
    onChange: (focused) => {
      if (!focused && show) setShow(false);
    },
  });
  const handleChange = useHandleExamTypeChange(study.id);
  const hasValue = !!study.examTypes?.length;

  useEffect(() => {
    if (show) {
      // the first open the dropdown doesn't exist so give it a tick to render
      requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }
  }, [show]);

  const fixOverflows = css`
    :global(.rw-multiselect-taglist) {
      @apply max-w-full truncate;
    }
    :global(.rw-multiselect-tag) {
      @apply max-w-full truncate;
    }
    :global(.rw-multiselect-tag-label) {
      @apply block leading-[3.2rem] max-w-full truncate;
    }
    :global(.rw-list-option) {
      @apply max-w-full truncate;
    }
  `;

  return (
    <Dropdown
      show={show}
      onToggle={setShow}
      className="group"
      {...focusHandlers}
    >
      <Dropdown.Toggle
        data-bni-id="ExamExamTypeDropdown"
        className={clsx(
          className,
          'bg-grey-80 hover:filter-none focus:ring text-left',
          show && 'ring',
        )}
        id={id}
        variant={{ type: 'text', primary: 'var(--bni-white)' }}
      >
        {hasValue ? (
          <CheckGroupSelectButtonLabel
            show={show}
            dataItems={study.examTypes!}
            renderValue={(i) => i.item!.name}
            renderOverflowItem={(i) => i.item!.name}
          />
        ) : (
          <Text
            color="subtitle"
            css="text-overflow: ellipsis; overflow: auto;"
          >
            <FormattedMessage
              id="examExamType.placeholder"
              defaultMessage="Add exam types…"
            />
          </Text>
        )}
        <PickerCaret className="ml-auto" />
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark" placement="bottom" className="w-full">
        <DropdownMultiselect
          data={examTypes}
          ref={inputRef}
          dataKey="id"
          textField="name"
          variant="secondary"
          menuVariant="dark"
          placeholder={defineMessage({
            id: 'examExamType.searchPlaceholder',
            defaultMessage: 'Search…',
          })}
          groupBy={groupBy}
          value={(study.examTypes as any) ?? []}
          onChange={(values, meta) => handleChange(values || [], meta)}
          className={fixOverflows}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default createFragmentContainer(ExamExamType, {
  study: graphql`
    fragment ExamExamType_study on Study {
      ...useSuggestedExamTypesGroups_study
      id
      organization {
        slug
      }
      examTypes {
        id
        name
      }
    }
  `,
});
