import formatName from '@bfly/utils/formatName';
import React from 'react';

interface Props {
  value: string;
}

export const getDicomName = (value: string) => {
  const [nameLast, nameFirst, nameMiddle, namePrefix, nameSuffix] =
    value.split('^');
  return formatName({
    nameLast,
    nameFirst,
    nameMiddle,
    namePrefix,
    nameSuffix,
  });
};

function DicomName({ value }: Props) {
  return <>{getDicomName(value)}</>;
}

export default DicomName;
