import Form from '@bfly/ui2/Form';
import Textarea from '@bfly/ui2/Textarea';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  detailFieldSetTitle: {
    id: 'domainRoleDetailFieldSet.legend',
    defaultMessage: 'Details',
  },
  roleNameField: {
    id: 'domainRoleDetailFieldSet.roleName',
    defaultMessage: 'Butterfly Access Role name',
  },
  roleDescriptionField: {
    id: 'domainRoleDetailFieldSet.roleDescription',
    defaultMessage: 'Butterfly Access Role description (optional)',
  },
});

interface Props {
  readOnly?: boolean;
}

function DomainRoleDetailFieldSet({ readOnly = false }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Form.FieldSet legend={formatMessage(messages.detailFieldSetTitle)}>
      <Form.FieldGroup
        name="name"
        label={messages.roleNameField}
        readOnly={readOnly}
      />
      <Form.FieldGroup
        fluid
        as={Textarea}
        rows={2}
        name="description"
        label={messages.roleDescriptionField}
        readOnly={readOnly}
      />
    </Form.FieldSet>
  );
}

export default DomainRoleDetailFieldSet;
