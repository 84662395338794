import PlusIcon from '@bfly/icons/Plus';

import IconButton, { IconButtonProps } from './IconButton';

export default function PlusIconButton(
  props: Omit<IconButtonProps, 'icon' | 'variant' | 'className'>,
) {
  return (
    <IconButton
      icon={<PlusIcon height={9} />}
      {...props}
      className="m-0 ml-auto h-auto flex bg-grey-55 p-[5px] rounded-full text-grey-90"
      variant={null}
    />
  );
}
