import FailureIcon from '@bfly/icons/Failure';
import Caret from '@bfly/ui2/Caret';
import Popover from '@bfly/ui2/Popover';
import SrOnly from '@bfly/ui2/SrOnly';
import Text from '@bfly/ui2/Text';
import formatName from '@bfly/utils/formatName';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RelayProp, createFragmentContainer, graphql } from 'react-relay';

import FormattedShortDateTime from 'components/FormattedShortDateTime';
import PatientName from 'components/PatientName';
import PromptButton from 'components/PromptButton';
import QuickCopyText from 'components/QuickCopyText';
import VetPatientName from 'components/VetPatientName';
import patientMessages from 'messages/patient';
import vetPatientMessages from 'messages/vetPatient';
import Analytics from 'utils/Analytics';

import { clearPatient as clearHumanPatient } from '../schemas/humanStudyInfo';
import { clearPatient as clearVetPatient } from '../schemas/vetStudyInfo';
import { StudyFinalizationRestriction } from '../utils/useStudyFinalization';
import EditExamInfoModal from './EditExamInfoModal';
import { useExamContext } from './ExamContext';
import PatientPopoverContent from './PatientPopoverContent';
import { HeaderPatientInfo_study$data as Study } from './__generated__/HeaderPatientInfo_study.graphql';

interface Props {
  study: Study;
  readOnly?: boolean;
  relay: RelayProp;
}

function HeaderPatientInfo({ study, readOnly, relay }: Props) {
  const { finalizationErrors } = useExamContext();
  const [editInfoTarget, setEditInfoTarget] = useState<
    'WORKLIST' | 'EDIT' | null
  >(null);

  const missingWorklistError =
    !!finalizationErrors?.[StudyFinalizationRestriction.WORKLIST_REQUIRED];

  if (!study) {
    return <div className="animate-pulse bg-grey-90 min-w-48 rounded h-10" />;
  }
  const noPatientData = !!(
    study.patient &&
    !formatName(study.patient) &&
    !study.patient.medicalRecordNumber
  );
  const showWorklistRequired = !!(
    !study.finalizedAt &&
    study.practiceType === 'HUMAN' &&
    study.archive!.worklistRequiredToFinalize &&
    !study.hasAssociatedWorklistItem
  );

  const patientId =
    study.practiceType === 'HUMAN'
      ? study.patient?.medicalRecordNumber
      : study.vetPatient?.patientIdNumber;

  async function handleClearAndEditPatient() {
    if (study.practiceType === 'HUMAN') {
      await clearHumanPatient(study.id, relay.environment);
    }
    if (study.practiceType === 'VETERINARY') {
      await clearVetPatient(study.id, relay.environment);
    }

    setEditInfoTarget('WORKLIST');
  }

  return (
    <>
      {noPatientData && !study.finalizedAt ? (
        <PromptButton
          as="div"
          data-bni-id="PatientPromptButton"
          onClick={() => {
            setEditInfoTarget('WORKLIST');
            Analytics.track('addPatientOpened', {
              studyId: study.id,
              organizationId: study.organization?.id,
            });
          }}
          className="min-w-48 flex-col"
          invalid={missingWorklistError}
        >
          <FormattedMessage {...patientMessages.addPatient} />
        </PromptButton>
      ) : (
        /*
         Why is the markup so odd here? The design calls for what looks like 
         nested buttons. The patient details popover and quick-copy buttons. 
         It's not valid HTML to nest buttons and not accesible, so the more 
         correct thing is to model this as a button on top of another button (quick copy over popover trigger).
         To do this while keeping the layout correct we put the trigger button behind the content 
         positioned absolutely. Then make the content click-through via `pointer-events: none` 
         and for any interactive element in the top set `pointer-events: auto`. This causes 
         all clicks to non-interactive elements to "fall through" to trigger button behind it
        */
        <div
          className={clsx(
            'relative rounded group transition-colors min-w-0 flex',
            !readOnly && 'cursor-pointer hover:bg-grey-85',
            missingWorklistError && 'border-2 border-red-40 bg-grey-85',
          )}
        >
          {!readOnly && (
            <Popover.Trigger
              trigger="click"
              placement="bottom"
              className="text-base max-w-[48rem] w-full"
              variant="dark"
              dismissOnOutsideInteraction
              // hide the popover if the modal is open otherwise undefined so hover will show it
              show={editInfoTarget ? false : undefined}
              onToggle={() => {
                if (showWorklistRequired) {
                  setEditInfoTarget('EDIT');
                }
              }}
              popover={
                <PatientPopoverContent
                  study={study}
                  onEdit={setEditInfoTarget}
                  onClear={handleClearAndEditPatient}
                />
              }
            >
              {/* buttons need the width to fill space for some reason, unique to button element. */}
              <button
                type="button"
                className="absolute inset-0 w-full"
                data-bni-id="PatientInfoPopoverTrigger"
              >
                <SrOnly>
                  <FormattedMessage
                    id="headerPatientInfo.disclosure"
                    defaultMessage="Show Patient details"
                  />
                </SrOnly>
              </button>
            </Popover.Trigger>
          )}
          <div
            className="px-5 lg:px-10 py-2 flex flex-col items-center min-w-0"
            css={css`
              position: relative;
              pointer-events: none;

              & button {
                pointer-events: auto;
              }
            `}
          >
            <FailureIcon
              className={clsx(
                'absolute top-1/2 -translate-y-1/2 left-2 text-red-40 transition-opacity lg-max:hidden',
                !showWorklistRequired && 'invisible opacity-0',
              )}
              css={`
                :global(.foreground) {
                  fill: transparent;
                }
              `}
            />
            {study.practiceType === 'HUMAN' ? (
              <PatientName
                truncate
                variant="lg"
                className="w-full block"
                patient={study.patient}
                color="headline"
              />
            ) : (
              <VetPatientName
                truncate
                variant="lg"
                className="w-full block"
                vetPatient={study.vetPatient!}
                color="headline"
              />
            )}
            <div>
              {patientId ? (
                <>
                  <Text className="mr-1">
                    {study.practiceType === 'HUMAN' ? (
                      <FormattedMessage
                        {...patientMessages.medicalRecordNumber}
                      />
                    ) : (
                      <FormattedMessage
                        {...vetPatientMessages.patientIdNumber}
                      />
                    )}
                  </Text>

                  <QuickCopyText
                    onClick={(e) => e.stopPropagation()}
                    color="headline"
                    variant="body-bold"
                    truncate
                    data-bni-id="CopyPatientIdButton"
                  >
                    {patientId}
                  </QuickCopyText>
                </>
              ) : (
                <Text color="subtitle">
                  <FormattedMessage
                    {...patientMessages.noMedicalRecordNumber}
                  />
                </Text>
              )}
              <div className="md:hidden">
                <Text className="mr-1">
                  <FormattedMessage
                    id="headerPatientInfo.captured"
                    defaultMessage="Captured"
                  />
                </Text>
                <Text
                  variant="body-bold"
                  color="headline"
                  transform="lowercase"
                  truncate
                >
                  <FormattedShortDateTime value={study.capturedAt!} />
                </Text>
              </div>
              {!readOnly && (
                <div className="justify-center pt-1 invisible group-hover:visible flex text-headline">
                  <Caret />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <EditExamInfoModal
        show={!!editInfoTarget}
        study={study}
        onHide={() => setEditInfoTarget(null)}
        target={
          typeof editInfoTarget === 'string' ? editInfoTarget : undefined
        }
      />
    </>
  );
}

export default createFragmentContainer(HeaderPatientInfo, {
  study: graphql`
    fragment HeaderPatientInfo_study on Study {
      id
      capturedAt
      practiceType
      finalizedAt
      hasAssociatedWorklistItem
      patient {
        medicalRecordNumber
        nameFirst
        nameLast
        nameMiddle
        namePrefix
        nameSuffix
        ...PatientName_patient
      }
      vetPatient {
        patientIdNumber
        ...VetPatientName_vetPatient
      }
      archive {
        worklistRequiredToFinalize
      }
      organization {
        id
      }
      ...EditExamInfoModal_study
      ...PatientPopoverContent_study
    }
  `,
});
