/**
 * @generated SignedSource<<ec400a91c9fdce8ff2e0ad7a9bc0b142>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyTitle_study$data = {
  readonly patient: {
    readonly " $fragmentSpreads": FragmentRefs<"PatientName_patient">;
  } | null;
  readonly practiceType: StudyPracticeType | null;
  readonly vetPatient: {
    readonly " $fragmentSpreads": FragmentRefs<"VetPatientName_vetPatient">;
  } | null;
  readonly " $fragmentType": "StudyTitle_study";
};
export type StudyTitle_study$key = {
  readonly " $data"?: StudyTitle_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyTitle_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyTitle_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PatientName_patient"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VetPatientName_vetPatient"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "9dc4b72fab497949ab3cd7ad6574586f";

export default node;
