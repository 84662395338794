import Spinner from '@bfly/ui2/Spinner';
import {
  QueryRenderer,
  RelayProp,
  createFragmentContainer,
  graphql,
} from 'react-relay';

import NotificationList from './NotificationList';
import NotificationNone from './NotificationNone';
import { NotificationListRendererQuery as Query } from './__generated__/NotificationListRendererQuery.graphql';
import { NotificationListRenderer_notificationCenter$data as NotificationCenter } from './__generated__/NotificationListRenderer_notificationCenter.graphql';

interface Props {
  notificationCenter: NotificationCenter | null;
  relay: RelayProp;
}

function NotificationListRenderer({ notificationCenter, relay }: Props) {
  return !notificationCenter ? (
    <NotificationNone />
  ) : (
    <QueryRenderer<Query>
      environment={relay.environment}
      fetchPolicy="network-only"
      query={graphql`
        query NotificationListRendererQuery($notificationCenterId: ID!) {
          notificationCenter: node(id: $notificationCenterId) {
            ... on NotificationCenter {
              numUnseenNotifications
              numUnreadNotifications
            }
            ...NotificationList_notificationCenter
          }
        }
      `}
      variables={{
        notificationCenterId: notificationCenter.id,
      }}
      render={({ props }) => {
        if (!props)
          return (
            <div className="text-center">
              <Spinner size="md" className="m-3" />
            </div>
          );

        if (!props.notificationCenter) return <NotificationNone />;

        return (
          <NotificationList notificationCenter={props.notificationCenter!} />
        );
      }}
    />
  );
}

export default createFragmentContainer(NotificationListRenderer, {
  notificationCenter: graphql`
    fragment NotificationListRenderer_notificationCenter on NotificationCenter {
      id
    }
  `,
});
