import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import messages from 'messages/vetPatient';

import { VetPatientWeight_vetPatient$data as VetPatient } from './__generated__/VetPatientWeight_vetPatient.graphql';

interface Props {
  vetPatient: VetPatient;
}

function VetPatientWeight({ vetPatient }: Props) {
  return (
    <FormattedMessage
      {...messages.weightTemplate}
      values={{
        weightKilograms: (
          <FormattedNumber
            maximumFractionDigits={2}
            value={vetPatient.weightKilograms!}
          />
        ),
      }}
    />
  );
}

export default createFragmentContainer(VetPatientWeight, {
  vetPatient: graphql`
    fragment VetPatientWeight_vetPatient on VetPatient {
      weightKilograms
    }
  `,
});
