import useMountEffect from '@restart/hooks/useMountEffect';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import LoadingIndicatorPage from 'components/LoadingIndicatorPage';
import ExternalRedirect from 'utils/ExternalRedirect';

import type { ReferralsPage_organization$data as Organization } from './__generated__/ReferralsPage_organization.graphql';

interface Props {
  organization: Organization;
}

function ReferralsPage({ organization }: Props) {
  const { referrerToken } = organization.viewerReferrerInfo!;

  useMountEffect(() => {
    ExternalRedirect.redirect(
      `${globalThis.bflyConfig!.STORE_URL}/refer/${referrerToken}`,
    );
  });

  return <LoadingIndicatorPage />;
}

export default createFragmentContainer(ReferralsPage, {
  organization: graphql`
    fragment ReferralsPage_organization on Organization {
      viewerReferrerInfo {
        referrerToken
      }
    }
  `,
});
