import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import useToast from '@bfly/ui2/useToast';
import useRouter from 'found/useRouter';
import { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import RelayForm from 'components/RelayForm';
import useMoveStudies, { StudyItem } from 'hooks/useMoveStudies';
import actionMessages from 'messages/actions';
import { useArchiveRoutes } from 'routes/archive';
import archiveSchema, { makeArchiveSchemaContext } from 'schemas/archive';
import withModal from 'utils/withModal';

import type { CreateArchiveModal_CreateArchiveMutation as CreateArchiveMutation } from './__generated__/CreateArchiveModal_CreateArchiveMutation.graphql';
import type { CreateArchiveModal_organization$data as Organization } from './__generated__/CreateArchiveModal_organization.graphql';

const messages = defineMessages({
  label: {
    id: 'createArchive.label',
    defaultMessage: 'Archive Title',
  },
  labelPlaceholder: {
    id: 'createArchive.label.placeholder',
    defaultMessage: 'Title',
  },
});

const defaultValue = archiveSchema.getDefault();

// TODO: Insert the new edge instead of refetching archives.
const mutation = graphql`
  mutation CreateArchiveModal_CreateArchiveMutation(
    $input: CreateArchiveInput!
  ) {
    createArchiveOrError(input: $input) {
      ... on CreateArchivePayload {
        archive {
          id
          handle
        }
        organization {
          archiveConnection(first: 2147483647)
            @connection(key: "Organization_archiveConnection") {
            edges {
              node {
                ...ArchiveListItem_archive
              }
            }
          }
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface Props {
  organization: Organization;
  onHide: () => void;
  initialStudies: StudyItem[] | null;
}

function CreateArchiveModal({ organization, onHide, initialStudies }: Props) {
  const archiveRoutes = useArchiveRoutes();
  const [finished, setFinished] = useState(false);
  const toast = useToast();
  const { router } = useRouter();
  const [moveStudies] = useMoveStudies();

  const onCompleted = async (data: CreateArchiveMutation['response']) => {
    setFinished(true);

    const { archive } = data!.createArchiveOrError!;

    if (initialStudies) {
      await moveStudies(
        initialStudies,
        archive!.id,
        organization.id,
        organization.id,
      );
    }

    router.replace(
      archiveRoutes.archive({
        organizationSlug: organization.slug!,
        archiveHandle: archive!.handle!,
      }),
    );

    onHide();

    toast.success(
      <FormattedMessage
        id="organization.createArchive.success"
        defaultMessage="Archive created"
      />,
    );
  };

  return (
    <RelayForm<CreateArchiveMutation, typeof archiveSchema>
      as="div"
      schema={archiveSchema}
      defaultValue={defaultValue}
      mutation={mutation}
      getInput={(input) => ({
        organizationId: organization.id,
        label: input.label,
      })}
      formContext={makeArchiveSchemaContext(organization)}
      onCompleted={onCompleted}
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="createArchive.title"
            defaultMessage="Create New Archive"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <FormattedMessage
            id="createArchive.content"
            defaultMessage="Archives are like folders for your exams. Archives appear as upload destinations when saving exams on the Butterfly iQ app."
          />
        </p>
        {organization.subscription!.isTeam && (
          <p>
            <FormattedMessage
              id="createArchive.teamAccess"
              defaultMessage="All members of your team will have access to this archive."
            />
          </p>
        )}
        <form noValidate className="mt-8">
          <Form.FieldGroup
            name="label"
            label={messages.label}
            placeholder={messages.labelPlaceholder}
            autoFocus
            variant="secondary"
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Form.Submit size="lg" variant="primary" finished={finished}>
            <FormattedMessage {...actionMessages.create} />
          </Form.Submit>
          <Button size="lg" variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </RelayForm>
  );
}

export default createFragmentContainer(
  withModal(CreateArchiveModal, { variant: 'dark' }),
  {
    organization: graphql`
      fragment CreateArchiveModal_organization on Organization {
        id
        slug
        subscription {
          isTeam
        }
        ...archiveSchema_organization
      }
    `,
  },
);
