import StarIcon from '@bfly/icons/Star';
import StarOutlineIcon from '@bfly/icons/StarOutline';
import clsx from 'clsx';
import { useRouter } from 'found';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql, useMutation } from 'react-relay';

import IconButton from 'components/IconButton';

import { ToggleStudyFavoriteButtonFavoriteStudyMutation as FavoriteStudyMutation } from './__generated__/ToggleStudyFavoriteButtonFavoriteStudyMutation.graphql';
import { ToggleStudyFavoriteButtonUnfavoriteStudyMutation as UnfavoriteStudyMutation } from './__generated__/ToggleStudyFavoriteButtonUnfavoriteStudyMutation.graphql';
import { ToggleStudyFavoriteButton_study$data as Study } from './__generated__/ToggleStudyFavoriteButton_study.graphql';

const iconClassName = 'shrink-0 w-5';

export function InvisibleToggleStudyFavoriteButton() {
  return <StarIcon className={clsx(iconClassName, 'invisible')} />;
}

const DEBOUNCE_TIME = 200;

const updateFavoriteStudyCount =
  (organizationId: string, type: 'increase' | 'decrease', viewerId: string) =>
  (store) => {
    const org = store.get(organizationId);
    // To get the value, relay requires that you provide the exact args that the gql record was called with
    const favoriteVars = {
      search: { favoritedBy: viewerId },
    };
    const numFavStudies = org?.getValue('numStudies', favoriteVars);
    if (type === 'increase')
      org?.setValue(Number(numFavStudies) + 1, 'numStudies', favoriteVars);
    else org?.setValue(Number(numFavStudies) - 1, 'numStudies', favoriteVars);
  };

interface Props {
  study: Study;
  className?: string;
}

function ToggleStudyFavoriteButton({ study, className }: Props) {
  // keep Icon responsive as quick as possible on click, do mutation after timeout
  const [selected, setSelected] = useState(!!study.isViewerFavorite);
  const Icon = selected ? StarIcon : StarOutlineIcon;
  const { match } = useRouter();

  const [favoriteStudy] = useMutation<FavoriteStudyMutation>(
    graphql`
      mutation ToggleStudyFavoriteButtonFavoriteStudyMutation(
        $input: FavoriteStudyInput!
      ) @raw_response_type {
        favoriteStudy(input: $input) {
          study {
            isViewerFavorite
          }
        }
      }
    `,
  );

  const [unfavoriteStudy] = useMutation<UnfavoriteStudyMutation>(
    graphql`
      mutation ToggleStudyFavoriteButtonUnfavoriteStudyMutation(
        $input: UnfavoriteStudyInput!
      ) @raw_response_type {
        unfavoriteStudy(input: $input) {
          study {
            isViewerFavorite
          }
        }
      }
    `,
  );

  const toggleFavorite = useCallback(
    (nextSelected: boolean) => {
      if (nextSelected) {
        favoriteStudy({
          variables: {
            input: {
              studyId: study.id,
            },
          },
          updater: updateFavoriteStudyCount(
            study!.organization!.id,
            'increase',
            match.context.viewerLocalId,
          ),
          optimisticResponse: {
            favoriteStudy: {
              study: {
                id: study.id,
                isViewerFavorite: true,
              },
            },
          },
        });
      } else {
        unfavoriteStudy({
          variables: {
            input: {
              studyId: study.id,
            },
          },
          updater: updateFavoriteStudyCount(
            study!.organization!.id,
            'decrease',
            match.context.viewerLocalId,
          ),
          optimisticResponse: {
            unfavoriteStudy: {
              study: {
                id: study.id,
                isViewerFavorite: false,
              },
            },
          },
        });
      }
    },
    [favoriteStudy, match.context.viewerLocalId, study, unfavoriteStudy],
  );

  const debouncedToggleFavorite = useMemo(
    () => debounce(toggleFavorite, DEBOUNCE_TIME),
    [toggleFavorite],
  );

  const handleClick = useCallback(() => {
    setSelected((prev) => {
      debouncedToggleFavorite(!prev);
      return !prev;
    });
  }, [debouncedToggleFavorite]);

  return (
    <IconButton
      data-bni-id="ToggleStudyFavoriteButton"
      aria-pressed={selected}
      icon={<Icon className={iconClassName} />}
      onClick={handleClick}
      className={clsx(className, 'p-0')}
      title={
        <FormattedMessage
          id="ToggleStudyFavoriteButton"
          defaultMessage="{isViewerFavorite, select,
            true {Favorited}
            other {Not favorited}
          }"
          values={{ isViewerFavorite: selected }}
        />
      }
    />
  );
}

export default createFragmentContainer(ToggleStudyFavoriteButton, {
  study: graphql`
    fragment ToggleStudyFavoriteButton_study on Study {
      id
      isViewerFavorite
      organization {
        id
      }
    }
  `,
});
