import { useRouter } from 'found';
import { useCallback, useMemo } from 'react';

import {
  DashboardFilters,
  SortingDirection,
  deserialize,
  serialize,
} from '../../schemas/educationDashboardFilters';

export default function useEducationDashboardFilter() {
  const { router, match } = useRouter();
  const { location } = match;

  const filters = useMemo(
    () => deserialize({ ...location.query }),
    [location.query],
  );

  const updateFilters = useCallback(
    (nextFilters: Partial<DashboardFilters>) => {
      router.replace({
        ...location,
        query: serialize({ ...filters, ...nextFilters }),
      });
    },
    [filters, location, router],
  );

  const updateSort = useCallback(
    (value: string) => {
      const field = value.toUpperCase();
      const direction =
        filters.sort?.field === field &&
        filters.sort?.direction === SortingDirection.ASC
          ? SortingDirection.DESC
          : SortingDirection.ASC;

      updateFilters({ sort: { field, direction } });
    },
    [filters, updateFilters],
  );

  const updateSearch = useCallback(
    (value: string) => {
      updateFilters({ search: value });
    },
    [updateFilters],
  );

  const updateTags = useCallback(
    (value: string[]) => updateFilters({ tags: value }),
    [updateFilters],
  );

  const updateOrganizationIds = useCallback(
    (value: string[]) => updateFilters({ organizationIds: value }),
    [updateFilters],
  );

  const updateCredentialGroup = useCallback(
    (value: string[]) => updateFilters({ groupId: value }),
    [updateFilters],
  );

  return {
    filters,
    updateSearch,
    updateSort,
    updateTags,
    updateOrganizationIds,
    updateCredentialGroup,
  };
}
