import { useCallback, useState } from 'react';

export default function useModalState(
  defaultShow = false,
): [open: () => void, props: { onHide(): void; show: boolean }] {
  const [show, setShow] = useState(defaultShow);

  const onHide = useCallback(() => setShow(false), []);
  const open = useCallback(() => setShow(true), []);

  return [
    open,
    {
      show,
      onHide,
    },
  ];
}
