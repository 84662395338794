import Banner from '@bfly/ui2/Banner';
import Button from '@bfly/ui2/Button';
import Text from '@bfly/ui2/Text';
import { white } from '@bfly/ui2/tailwind/colors';
import { stylesheet } from 'astroturf';
import { Fragment, ReactNode, useLayoutEffect, useRef } from 'react';
import type { Errors } from 'react-formal/types';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import UndeleteArchiveControl from 'components/UndeleteArchiveControl';
import UndeleteStudyControl from 'components/UndeleteStudyControl';
import actionMessages from 'messages/actions';
import joinNonemptyNodes from 'utils/joinNonemptyNodes';

import { StudyFinalizationRestriction } from '../utils/useStudyFinalization';
import { useExamContext } from './ExamContext';
import { ExamPageBanner_study$data as Study } from './__generated__/ExamPageBanner_study.graphql';

const _ = stylesheet`
  [data-worksheet-field][data-invalid] {
    @apply transition-colors duration-300;

    // We use a data attribute instead of a class because React tightly controls
    // the className attribute, reseting it to it's value on render
    // we want this to stick around through renders to get the last
    // index of the focused element
    &[data-focused] {
      @apply bg-blue-80 px-2 -mx-2 rounded;
    }
  }
`;

function getRequiredErrors(errors?: Errors) {
  return (
    Object.values(errors || {})
      .flatMap((e) => Object.values(e || {}).flat())
      // 'min' in case of multiselect (select min 1)
      .filter(
        (e: any) =>
          e?.type === 'required' || e?.type === 'defined' || e?.type === 'min',
      )
  );
}
/**
 * Find the next invalid worksheet field and scroll to it.
 * This is done via DOM API's instead of something more React-y
 * because its _much_ simpler. To do this, worksheet field group
 * adds a data-worksheet-field and optional data-invalid attributes
 * that we query into an array to loop through. It's important to loop
 * through all fieldsn not just invalid ones, to maintain our position
 * in the list even after a user fixes the current error.
 */
function scrollToError(delta: number) {
  const fields: HTMLElement[] = Array.from(
    document.querySelectorAll('[data-worksheet-field]'),
  );

  const hasErrors = fields.some((f) => f.dataset.invalid != null);
  if (!hasErrors) return;

  let nextIdx = fields.findIndex((el) => el.dataset.focused != null);
  let element: HTMLElement | null = null;

  // loop through all the worksheet fields in either direction
  // flipping back at the start/end. The constant while loop is safe
  // because we check hasErrors above ensuring there is at least one "next"
  // error we can find (even if it's the current one)
  //
  // eslint-disable-next-line no-constant-condition
  while (true) {
    nextIdx += delta;

    if (nextIdx >= fields.length) nextIdx = 0;
    if (nextIdx < 0) nextIdx = fields.length - 1;
    element = fields[nextIdx];

    if (element?.dataset.invalid != null) break;
  }
  if (!element) return;

  fields.forEach((el) => delete el.dataset.focused);
  element.dataset.focused = '';
  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

interface Props {
  study: Study;
}

function PrevNextScrollToError() {
  return (
    <>
      <Button
        variant={{
          type: 'outline',
          primary: white,
          secondary: 'var(--bni-banner-bg-color)',
        }}
        onClick={() => scrollToError(-1)}
        className="ml-4"
      >
        <FormattedMessage
          id="examPageBanner.prevError"
          defaultMessage="Prev"
        />
      </Button>
      <Button
        variant={{
          type: 'outline',
          primary: white,
          secondary: 'var(--bni-banner-bg-color)',
        }}
        onClick={() => scrollToError(+1)}
        className="ml-4"
      >
        <FormattedMessage {...actionMessages.next} />
      </Button>
    </>
  );
}

function ExamPageBanner({ study }: Props) {
  const { finalizationErrors, qaErrors } = useExamContext();
  const currentErrorIndexRef = useRef(-1);

  useLayoutEffect(() => {
    currentErrorIndexRef.current = -1;
  }, [finalizationErrors?.worksheetsErrors]);

  if (study.deletedAt || study.archive!.deletedAt) {
    return (
      <Banner variant="danger" className="flex justify-center space-x-3">
        {study.archive!.deletedAt ? (
          <>
            <Text>
              <FormattedMessage
                id="examPage.archiveDeleted"
                defaultMessage="This archive was deleted"
              />
            </Text>
            <UndeleteArchiveControl
              as={Banner.Button}
              archive={study.archive!}
            >
              <FormattedMessage
                id="examPage.undeleteArchive"
                defaultMessage="Restore Archive"
              />
            </UndeleteArchiveControl>
          </>
        ) : (
          <>
            <Text>
              <FormattedMessage
                id="examPage.studyDeleted"
                defaultMessage="This exam was deleted."
              />
            </Text>
            <UndeleteStudyControl as={Banner.Button} study={study}>
              <FormattedMessage
                id="examPage.undeleteStudy"
                defaultMessage="Restore exam"
              />
            </UndeleteStudyControl>
          </>
        )}
      </Banner>
    );
  }

  let numErrors = 0;
  const errors: ReactNode[] = [];
  if (finalizationErrors && !study.finalizedAt) {
    if (finalizationErrors[StudyFinalizationRestriction.AUTHOR_REQUIRED]) {
      numErrors++;
      errors.push(
        <FormattedMessage
          id="examPageBanner.authorRequired"
          defaultMessage="Author required"
        />,
      );
    }
    if (
      finalizationErrors[StudyFinalizationRestriction.PRIMARY_AUTHOR_MUST_SIGN]
    ) {
      numErrors++;
      errors.push(
        <FormattedMessage
          id="examPageBanner.primaryMustSign"
          defaultMessage="The primary author must sign"
        />,
      );
    }
    if (finalizationErrors[StudyFinalizationRestriction.SCRIBE_CANNOT_SIGN]) {
      numErrors++;
      errors.push(
        <FormattedMessage
          id="examPageBanner.scribeCannotSign"
          defaultMessage="Scribes can not sign studies."
        />,
      );
    }
    if (finalizationErrors[StudyFinalizationRestriction.EXAM_TYPE_REQUIRED]) {
      numErrors++;
      errors.push(
        <FormattedMessage
          id="examPageBanner.examTypeRequired"
          defaultMessage="Exam type required"
        />,
      );
    }
    if (finalizationErrors[StudyFinalizationRestriction.WORKLIST_REQUIRED]) {
      numErrors++;
      errors.push(
        <FormattedMessage
          id="examPageBanner.worklistRequired"
          defaultMessage="Patient association required"
        />,
      );
    }
    if (finalizationErrors[StudyFinalizationRestriction.PATIENT_ID_REQUIRED]) {
      numErrors++;
      errors.push(
        <FormattedMessage
          id="examPageBanner.patientIdRequired"
          defaultMessage="Patient ID required"
        />,
      );
    }
    if (finalizationErrors[StudyFinalizationRestriction.WORKSHEET_REQUIRED]) {
      numErrors++;
      errors.push(
        <FormattedMessage
          id="examPageBanner.worksheetRequired"
          defaultMessage="Worksheet required"
        />,
      );
    } else if (finalizationErrors.worksheetsErrors) {
      const requiredErrors = getRequiredErrors(
        finalizationErrors.worksheetsErrors,
      );

      numErrors += requiredErrors.length;
      errors.push(
        <>
          <FormattedMessage
            id="examPageBanner.requiredQuestions"
            values={{ numRequired: requiredErrors.length }}
            defaultMessage="{numRequired, plural,
              =1 {1 required worksheet question is blank}
              other {{numRequired} required worksheet questions are blank}
            }"
          />
          <PrevNextScrollToError />
        </>,
      );
    }
  } else if (qaErrors && study.finalizedAt) {
    const requiredErrors = getRequiredErrors(qaErrors);

    numErrors += requiredErrors.length;
    errors.push(
      <>
        <FormattedMessage
          id="examPageBanner.requiredQaQuestions"
          values={{ numRequired: requiredErrors.length }}
          defaultMessage="{numRequired, plural,
            =1 {1 required QA question is blank}
            other {{numRequired} required QA questions are blank}
          }"
        />
        <PrevNextScrollToError />
      </>,
    );
  }

  if (!numErrors) {
    return null;
  }

  return (
    <Banner variant="danger" data-bni-id="ExamErrorBanner">
      {!qaErrors && (
        <FormattedMessage
          id="examPageBanner.numQuestions"
          defaultMessage="{numErrors, plural,
          =1 {1 error stopping your signature: }
          other {{numErrors} errors stopping your signature: }
        }"
          values={{ numErrors }}
        />
      )}
      {joinNonemptyNodes(', ', errors).map((error, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={idx}>{error}</Fragment>
      ))}
    </Banner>
  );
}

export default createFragmentContainer(ExamPageBanner, {
  study: graphql`
    fragment ExamPageBanner_study on Study {
      deletedAt
      finalizedAt
      archive {
        deletedAt
        ...UndeleteArchiveControl_archive
      }
      ...UndeleteStudyControl_study
    }
  `,
});
