/**
 * @generated SignedSource<<3f2e56650fa91db9b1be8612f128bf7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamPage_study$data = {
  readonly handle: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageBanner_study" | "ExamPageSidebar_study" | "ExamTopNavbar_study" | "HeaderPatientInfo_study" | "examPageSidebar_useShowQa_study">;
  readonly " $fragmentType": "ExamPage_study";
};
export type ExamPage_study$key = {
  readonly " $data"?: ExamPage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPage_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numWorksheets",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Archive",
  "kind": "LinkedField",
  "name": "archive",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheetRequiredForQa",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isPACEExam"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPage_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "examPageSidebar_useShowQa_study",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanQa",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizedAt",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_studyOrArchiveIsDeleted",
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_studyReviewStatus",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numQaEntries",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "StandardQa",
              "kind": "LinkedField",
              "name": "standardQa",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "createdBy",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamTopNavbar_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamPageBanner_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderPatientInfo_study"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isPACEExam",
          "variableName": "isPACEExam"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ExamPageSidebar_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "4f7692f7926e2631b59cfb41ad055c9a";

export default node;
