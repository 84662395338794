/**
 * Heads up! This file makes up the bulk of the browserCheck.js entry file
 * Which, is compiled to very old Javascript to ensure that the code testing if
 * the browser is too old isn't too new for bad browsers. Practically this means
 * this file should:
 *
 * - NOT import other code in src (except the support regex)
 * - NOT use methods that would require polyfills in <= ES5 browsers
 */
// @ts-ignore
// eslint-disable-next-line import/extensions
import supportedBrowsers from './supportedBrowsers';

// Code based on https://github.com/arasatasaygin/is.js/blob/master/is.js.
function getUserAgent() {
  return window.navigator?.userAgent || '';
}

export function isIos() {
  const userAgent = getUserAgent();
  return userAgent.toLowerCase().match(/(ipad|iphone).+?os (\d+)/) !== null;
}

export function isAndroid() {
  const userAgent = getUserAgent();
  return userAgent.toLowerCase().match(/(android)/) !== null;
}

// Whitelist valid user agents that can login by directly passing
// an access_token hash to the URL.
export const APP_USER_AGENT_REGEX = /ButterflyIQ/i;

/**
 * The mobile app opens the education portal within an WebView and
 * applies custom user agent. Design differs slightly between a regular
 * mobile user and a user within the app so we need to differentiate.
 */
export function isInApp() {
  const userAgent = getUserAgent();
  return APP_USER_AGENT_REGEX.test(userAgent);
}

export function isSupported() {
  const userAgent = getUserAgent();
  return supportedBrowsers.test(userAgent) || isInApp();
}
