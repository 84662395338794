import Layout from '@4c/layout';
import Help from '@bfly/icons/Help';
import Anchor from '@bfly/ui2/Anchor';
import ButtonToggleGroup from '@bfly/ui2/ButtonToggleGroup';
import Form, { useFormValues } from '@bfly/ui2/Form';
import Heading from '@bfly/ui2/Heading';
import Popover from '@bfly/ui2/Popover';
import Text from '@bfly/ui2/Text';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import { useAnalyticsRoutes } from 'routes/analytics';
import { type ExamTypeProficiencyField } from 'schemas/standardQa';

const yesMessage = (
  <FormattedMessage id="examTypeProficiency.yes" defaultMessage="Yes" />
);
const noMessage = (
  <FormattedMessage id="examTypeProficiency.no" defaultMessage="No" />
);
const notCompletedMessage = (
  <FormattedMessage
    id="examTypeProficiency.notCompleted"
    defaultMessage="Not completed"
  />
);
const deletedExamType = (
  <FormattedMessage
    id="examQaProficiency.deletedExamType"
    defaultMessage="[deleted]"
  />
);

function ExamTypeControl({
  readOnly,
  examType,
  onChange,
  index,
}: {
  readOnly: boolean;
  examType: ExamTypeProficiencyField;
  onChange: (examType: ExamTypeProficiencyField) => void;
  index: number;
}) {
  if (readOnly)
    return (
      <>
        <Text>{examType.name || deletedExamType}</Text>
        <div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {examType.countsTowardCredentialing == null
            ? notCompletedMessage
            : examType.countsTowardCredentialing
            ? yesMessage
            : noMessage}
        </div>
      </>
    );

  return (
    <Form.Field
      name={`examTypeProficiency[${index}].countsTowardCredentialing`}
      className="flex flex-row items-center m-0"
      onChange={() => onChange(examType)}
    >
      {({ value: _2, ...props }, { value, ...meta }) => (
        <>
          <label
            // used to scroll to invalid questions
            data-worksheet-field=""
            data-invalid={meta.invalid ? '' : undefined}
            htmlFor={props.name}
            className={clsx('truncate', meta.invalid && 'text-red-40')}
          >
            <Text className="truncate">
              {examType.name ?? deletedExamType}
            </Text>
          </label>

          <ButtonToggleGroup
            {...props}
            value={value}
            type="radio"
            allowUnselectingRadios
            id={props.name}
            data-bni-id={`examTypeProficiency-[${index}]`}
          >
            <ButtonToggleGroup.Item className="h-[2.8rem]" value>
              {yesMessage}
            </ButtonToggleGroup.Item>
            <ButtonToggleGroup.Item className="h-[2.8rem]" value={false}>
              {noMessage}
            </ButtonToggleGroup.Item>
          </ButtonToggleGroup>
        </>
      )}
    </Form.Field>
  );
}

interface Props {
  readOnly: boolean;
  passSuggestion?: boolean;
  onChange: (examType: ExamTypeProficiencyField) => void;
}

export default function ExamQaProficiency({
  readOnly,
  passSuggestion,
  onChange,
}: Props) {
  const analyticRoutes = useAnalyticsRoutes();

  const values = useFormValues(
    'examTypeProficiency',
  ) as ExamTypeProficiencyField[];

  const notCompleted = values.every(
    (v) => v.countsTowardCredentialing == null,
  );

  const hasValidExamTypes = values.some((v) => !v.deletedAt);

  let noValuesMessage: JSX.Element | null = null;

  if (readOnly && notCompleted) {
    noValuesMessage = <Text>{notCompletedMessage}</Text>;
  } else if (!readOnly && !hasValidExamTypes) {
    noValuesMessage = (
      <Text>
        <FormattedMessage
          id="examTypeProficiency.noExamTypes"
          defaultMessage="Add a valid exam type to the exam to count it towards proficiency"
        />
      </Text>
    );
  }

  return (
    <>
      <Heading>
        <FormattedMessage
          id="examTypeProficiency.title"
          defaultMessage="Proficiency"
        />
        <Popover.Trigger
          id="examTypeProficiency-proficiency"
          trigger="click"
          dismissOnOutsideInteraction
          placement="top"
          popover={
            <FormattedMessage
              id="examTypeProficiency.tooltip"
              defaultMessage="Exam types marked “Yes” will be counted towards all study author's proficiency requirements in the <a>Analytics tab</a>"
              values={{
                a: (content) => (
                  <Anchor target="_blank" href={analyticRoutes.stats()}>
                    {content}
                  </Anchor>
                ),
              }}
            />
          }
        >
          <Help className="h-3 w-3 text-secondary ml-1" />
        </Popover.Trigger>
      </Heading>
      <label htmlFor="examTypeProficiency" className="mb-2 transition-colors">
        <Text variant="body-bold" color="headline">
          <FormattedMessage
            id="examTypeProficiency.description"
            defaultMessage="Count towards POCUS Proficiency Dashboard?"
          />
        </Text>
      </label>
      <Layout direction="column" pad={2} data-bni-id="ExamTypeProficiencies">
        {noValuesMessage ||
          values
            // if readonly, show all, otherwise show only undeleted
            .filter((examType) => readOnly || !examType.deletedAt)
            .map((examType, index) => (
              <Layout key={examType.id} justify="space-between" align="center">
                <ExamTypeControl
                  examType={examType}
                  readOnly={readOnly}
                  onChange={onChange}
                  index={index}
                />
              </Layout>
            ))}
      </Layout>
      {!readOnly && typeof passSuggestion === 'boolean' && hasValidExamTypes && (
        <Text
          as="div"
          className="mt-2"
          data-bni-id="examTypeProficiency-suggestion"
        >
          <FormattedMessage
            id="standardQa.examTypeCredentialInfoSuggestion"
            defaultMessage={`Recommendation: <b>{passSuggestion, select,
            true {Yes}
            other {No}
          }</b>, based on answers from Butterfly Standard QA card.`}
            values={{
              passSuggestion,
              b: (msg: any) => <Text variant="body-bold">{msg}</Text>,
            }}
          />
        </Text>
      )}
    </>
  );
}
