/**
 * @generated SignedSource<<fa2062efcf5af15f6f191f2d6be0bd63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewStudyDataGridActions_organization$data = {
  readonly handle: string | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssignExamTypesToStudiesModal_organization" | "MoveStudyModalRefresh_organization" | "MoveStudyModal_organization">;
  readonly " $fragmentType": "NewStudyDataGridActions_organization";
};
export type NewStudyDataGridActions_organization$key = {
  readonly " $data"?: NewStudyDataGridActions_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewStudyDataGridActions_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewStudyDataGridActions_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MoveStudyModal_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MoveStudyModalRefresh_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignExamTypesToStudiesModal_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "20306bce3ff4da6d25b27573d5d73a54";

export default node;
