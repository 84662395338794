import Layout from '@4c/layout';
import { stylesheet } from 'astroturf/react';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

const styles = stylesheet`
.slider-wrapper {
  @apply relative flex;

  & > span:first-of-type {
    @apply cursor-pointer relative inset-0 bg-grey-50;
    transition: 0.4s;
    border-radius: 34px;
    width: 3.75rem;
    height: 2rem;
  }

 & > span:first-of-type span {
    @apply absolute bg-grey-80;
    content: '';
    width: 45%;
    height: 70%;
    left: 6%;
    border-radius: 50%;
    bottom: 15%;
    transition: 0.4s;
  }

  input {
    @apply sr-only;
  }

  input:checked + span {
    @apply bg-primary bg-opacity-100;
  }

  input:checked + span span {
    @apply bg-white;
    transform: translateX(100%);  
  }

  span + span {
    @apply flex;
  }
}
`;

export default function FormCheckSwitch({
  checked,
  className,
  onChange,
  id,
  children,
}: PropsWithChildren<{
  className?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  id?: string;
}>) {
  return (
    <Layout
      as="label"
      pad={3}
      className={clsx(styles.sliderWrapper, className)}
      justify="center"
      alignContent="center"
    >
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          onChange?.(e.target.checked);
        }}
      />
      <span className="slider">
        <span />
      </span>
      {children && <span className="label-content">{children}</span>}
    </Layout>
  );
}
