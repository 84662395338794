/**
 * @generated SignedSource<<2358f006837b0520368dd27f9e3da7c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarTags_userProfile$data = {
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  readonly " $fragmentType": "SearchBarTags_userProfile";
};
export type SearchBarTags_userProfile$key = {
  readonly " $data"?: SearchBarTags_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarTags_userProfile">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarTags_userProfile"
};

(node as any).hash = "d95837563a5dfc12fc1466d1cecd05cb";

export default node;
