/**
 * @generated SignedSource<<fac38fef9353e714587dfd796e7505a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImagePreviewItem_image$data = {
  readonly burnedInFile: {
    readonly " $fragmentSpreads": FragmentRefs<"StudyImageThumbnail_image">;
  } | null;
  readonly handle?: string | null;
  readonly numComments?: number | null;
  readonly " $fragmentType": "ImagePreviewItem_image";
};
export type ImagePreviewItem_image$key = {
  readonly " $data"?: ImagePreviewItem_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImagePreviewItem_image">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImagePreviewItem_image",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numComments",
          "storageKey": null
        }
      ],
      "type": "StudyImage",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BurnedInFile",
      "kind": "LinkedField",
      "name": "burnedInFile",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StudyImageThumbnail_image"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyImageInterface",
  "abstractKey": "__isStudyImageInterface"
};

(node as any).hash = "f92407436d7f7f45cc6cae40af87f96e";

export default node;
