/**
 * @generated SignedSource<<f60970dc01cbf90945552c8394725156>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type inactivity_InactivityTimeoutPageQuery$variables = {};
export type inactivity_InactivityTimeoutPageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_tenant">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"InactivityTimeoutPage_viewer">;
  } | null;
};
export type inactivity_InactivityTimeoutPageQuery = {
  response: inactivity_InactivityTimeoutPageQuery$data;
  variables: inactivity_InactivityTimeoutPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdomainLabel",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "inactivity_InactivityTimeoutPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InactivityTimeoutPage_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "RouteAccessControl_tenant",
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "inactivity_InactivityTimeoutPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inactivityTimeoutSeconds",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8fa0f68b74adf0b7d49191e78ec97294",
    "id": null,
    "metadata": {},
    "name": "inactivity_InactivityTimeoutPageQuery",
    "operationKind": "query",
    "text": "query inactivity_InactivityTimeoutPageQuery {\n  viewer {\n    ...InactivityTimeoutPage_viewer\n    id\n  }\n  tenant {\n    __typename\n    ...RouteAccessControl_tenant\n    id\n  }\n}\n\nfragment InactivityTimeoutPage_viewer on Viewer {\n  domain {\n    id\n    inactivityTimeoutSeconds\n  }\n}\n\nfragment RouteAccessControl_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    subdomainLabel\n  }\n  ... on Organization {\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "51c726718c712eca7d0cc3fe893cb898";

export default node;
