/**
 * @generated SignedSource<<b3350720fb7f933fc21d2154dfff8161>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoginPage_inviteInfo$data = {
  readonly email: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"LoginSubtitle_inviteInfo">;
  readonly " $fragmentType": "LoginPage_inviteInfo";
};
export type LoginPage_inviteInfo$key = {
  readonly " $data"?: LoginPage_inviteInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoginPage_inviteInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoginPage_inviteInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoginSubtitle_inviteInfo"
    }
  ],
  "type": "OrganizationInviteInfo",
  "abstractKey": null
};

(node as any).hash = "2938a8226f5492c56a46ca4612b643ca";

export default node;
