import Spinner from '@bfly/ui2/Spinner';
import getNodes from '@bfly/utils/getNodes';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  BaseEditor,
  Descendant,
  Editor,
  ExtendedRenderElementProps,
  Node,
  Range,
  Transforms,
  createEditor,
} from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  Slate,
  useFocused,
  useSelected,
  withReact,
} from 'slate-react';

import useSearchQuery from 'hooks/useSearchQuery';

import { isEditorStateEmpty } from '../utils/mentionHelpers';
import CommentSuggestionEntry from './CommentSuggestionEntry';
import { CommentEditor_Query as CommentEditorQuery } from './__generated__/CommentEditor_Query.graphql';
import { CommentEditor_studyImage$data as StudyImage } from './__generated__/CommentEditor_studyImage.graphql';

export const LOADING = '@@loading';

type CustomElement = {
  type: 'paragraph' | 'mention';
  children: CustomText[];
  user: { userProfile: { name: string; handle: string } };
};
type CustomText = { text: string };

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }

  interface ExtendedRenderElementProps extends RenderElementProps {
    element: CustomElement;
  }
}

const messages = defineMessages({
  placeholderIndividual: {
    id: 'comment.placeholder.individual',
    defaultMessage: 'Add a comment.',
  },
  placeholderTeam: {
    id: 'comment.placeholder.team',
    defaultMessage: 'Add a comment. Type “@“ to tag a teammate.',
  },
});

const styles = stylesheet`
  .form p {
    margin-bottom: 0;
    height: 2.2rem;
  }

  .singleline {
    max-height: 2.2rem;
  }

  .multiline {
    max-height: calc(2.2em * 4);
    max-height: min(calc(2.2em * 10), 100vh);
  }
`;
const insertMention = (editor, user) => {
  const mention = {
    type: 'mention',
    user,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, mention as Node);
  Transforms.move(editor);
};
const Mention = ({
  attributes,
  children,
  element,
}: ExtendedRenderElementProps) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <span
      {...attributes}
      contentEditable={false}
      className={clsx(
        'font-bold inline-block align-baseline text-white border-2 border-transparent',
        selected && focused && 'ring',
      )}
      data-handle={element.user.userProfile.handle}
    >
      @{element.user.userProfile.name}
      {children}
    </span>
  );
};
const CommentElement = (props: ExtendedRenderElementProps) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'mention':
      return <Mention {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const withMentions = (editor) => {
  const { isInline, isVoid } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  return editor;
};

interface Props {
  multiline: boolean;
  editorState: Descendant[];
  onChange: (newEditorState: Descendant[]) => void;
  studyImage: StudyImage;
  onSubmit: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  className?: string;

  search?: string;
}

export interface CommentEditorHandle {
  focus: () => void;
  element: HTMLElement;
}

const CommentEditor = React.forwardRef<CommentEditorHandle, Props>(
  (
    {
      editorState,
      onChange,
      studyImage,
      onSubmit,
      onFocus,
      disabled,
      className,
      multiline,
    }: Props,
    outerRef,
  ) => {
    const { formatMessage } = useIntl();

    const suggestionsContainer = useRef<HTMLDivElement>(null);
    const [target, setTarget] = useState<Range | undefined>();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loadMemberships, setLoadMemberships] = useState<boolean>(false);

    const [search, setSearch] = useState('');

    const { data: mentionsData, loading } = useSearchQuery<CommentEditorQuery>(
      graphql`
        query CommentEditor_Query($studyId: ID!, $search: String) {
          study: node(id: $studyId) {
            ... on Study {
              mentionableConnection(first: 15, search: $search) {
                edges {
                  node {
                    ...CommentSuggestionEntry_mentionable
                    email
                    userProfile {
                      handle
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      search,
      { studyId: studyImage.study!.id },
    );

    const mentionables = useMemo(() => {
      if (!mentionsData) return [];

      return getNodes(mentionsData.study?.mentionableConnection);
    }, [mentionsData]);

    const renderElement = useCallback(
      (props: ExtendedRenderElementProps) => <CommentElement {...props} />,
      [],
    );
    const editor = useMemo(
      () => withMentions(withReact(withHistory(createEditor()))),
      [],
    );

    const handleIndexChange = useCallback((newIndex: number) => {
      setSelectedIndex(newIndex);
      if (suggestionsContainer.current) {
        (
          Array.from(suggestionsContainer.current.childNodes).find(
            (e: HTMLElement) =>
              e.attributes['index-data'].value === String(newIndex),
          ) as any
        ).scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }
    }, []);

    const selectSuggestion = useCallback(
      (event: any) => {
        event.preventDefault();
        Transforms.select(editor, target!);
        insertMention(editor, mentionables[selectedIndex]);
        setTarget(undefined);
      },
      [editor, mentionables, selectedIndex, target],
    );

    const handleSubmit = useCallback(() => {
      if (!isEditorStateEmpty(editorState)) {
        // We need to reset selection when resetting state, otherwise slate will bug out
        const point = { path: [0, 0], offset: 0 };
        editor.selection = { anchor: point, focus: point };
        editor.history = { redos: [], undos: [] };
        onSubmit();
      }
    }, [editor, editorState, onSubmit]);

    useImperativeHandle(
      outerRef,
      () => ({
        focus: () => ReactEditor.focus(editor),
        element: ReactEditor.toDOMNode(editor, editor),
      }),
      [editor],
    );

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (target) {
          switch (event.key) {
            case 'ArrowDown':
              event.preventDefault();
              handleIndexChange(
                selectedIndex >= mentionables.length - 1
                  ? 0
                  : selectedIndex + 1,
              );
              break;
            case 'ArrowUp':
              event.preventDefault();
              handleIndexChange(
                selectedIndex <= 0
                  ? mentionables.length - 1
                  : selectedIndex - 1,
              );
              break;
            case 'Tab':
            case 'Enter':
              if (mentionables.length === 0) {
                // Rare case when @somebody who doesn't exist
                handleSubmit();
              } else if (!event.shiftKey && !loading) {
                selectSuggestion(event);
              }
              break;
            case 'Escape':
              event.preventDefault();
              setTarget(undefined);
              break;
            default:
              break;
          }
        } else if (event.key === 'Enter' && !event.shiftKey) {
          handleSubmit();
        }
      },
      [
        target,
        handleIndexChange,
        selectedIndex,
        mentionables,
        loading,
        selectSuggestion,
        handleSubmit,
      ],
    );

    return (
      <>
        <div
          data-bni-id="CommentMentionBlock"
          className="surface surface-light rounded absolute top-0 left-0 w-full max-w-xl -translate-y-full max-h-56 scrollable-y"
          ref={suggestionsContainer}
        >
          {target &&
            (!loading ? (
              mentionables.map((node, i) => {
                return (
                  <CommentSuggestionEntry
                    key={node.userProfile!.handle!}
                    index-data={i}
                    mentionable={node}
                    searchValue={search || ''}
                    isFocused={selectedIndex === i}
                    onMouseEnter={() => setSelectedIndex(i)}
                    onMouseDown={selectSuggestion}
                  />
                );
              })
            ) : (
              <div className="text-center py-[1rem]">
                <Spinner size="sm" />
              </div>
            ))}
        </div>
        <div className={className}>
          <Slate
            editor={editor}
            value={editorState}
            onChange={(value) => {
              onChange(value);
              const { selection } = editor;

              // Check if current selection exists and that it's NOT a range of characters
              if (selection && Range.isCollapsed(selection)) {
                const [start] = Range.edges(selection); // Return start and end points of the range
                // Get a word before the start of selected range(or if its the first word then the current word)
                const wordBefore = Editor.before(editor, start, {
                  unit: 'word',
                });
                // If the word exists, get the point before it
                const before = wordBefore && Editor.before(editor, wordBefore);
                // Get range before the word
                const beforeRange =
                  before && Editor.range(editor, before, start);
                // Get text BEFORE the caret but AFTER the last word
                const beforeText =
                  beforeRange && Editor.string(editor, beforeRange);
                // Get the tuplet of [@<mention>, <mention>]
                const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
                // Get point after the caret(start)
                const after = Editor.after(editor, start);
                // Get the range after the caret
                const afterRange = Editor.range(editor, start, after);
                // Get text after the caret
                const afterText = Editor.string(editor, afterRange);
                // Get the whitespace after the caret when mentioning
                // e.g. '@som ething'
                // Basically ensures there's a whitespace after the string we're searching for
                const afterMatch = afterText.match(/^(\s|$)/);

                if (beforeMatch && afterMatch) {
                  if (!loadMemberships) setLoadMemberships(true);
                  setTarget(beforeRange);
                  setSearch(beforeMatch[1]);
                  setSelectedIndex(0);
                  return;
                }
              }

              setTarget(undefined);
            }}
          >
            <Editable
              renderElement={renderElement}
              className={clsx(
                styles.form,
                'w-full overflow-x-hidden scrollable-y',
                multiline ? styles.multiline : styles.singleline,
              )}
              placeholder={
                /* https://github.com/ianstormtaylor/slate/issues/3459 */
                (
                  <span className="text-white">
                    {formatMessage(
                      studyImage.organization!.subscription!.isTeam
                        ? messages.placeholderTeam
                        : messages.placeholderIndividual,
                    )}
                  </span>
                ) as any
              }
              onKeyDown={onKeyDown}
              onFocus={onFocus}
              readOnly={disabled}
              data-bni-id="CommentsEditor"
            />
          </Slate>
        </div>
      </>
    );
  },
);

export default createFragmentContainer(CommentEditor, {
  studyImage: graphql`
    fragment CommentEditor_studyImage on StudyImage {
      study {
        id
      }
      organization {
        subscription {
          isTeam
        }
      }
    }
  `,
});
