import omitBy from 'lodash/omitBy';
import { InferType, array, object, string } from 'yup';

import { arrayFromString, join } from './filterHelpers';

export enum SortingDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

export const SORT_DIRECTIONS: Array<SortingDirection> =
  Object.values(SortingDirection);

const sortSchema = object({
  field: string().default('NAME'),
  direction: string().oneOf(SORT_DIRECTIONS).default(SortingDirection.ASC),
});

const schema = object({
  sort: sortSchema,
  search: string(),
  tags: array().of(string()),
  organizationIds: array().of(string()),
  groupId: array().of(string()),
});

type SortType = InferType<typeof sortSchema>;

export type DashboardFilters = Partial<InferType<typeof schema>>;

export function serializeSort(sort?: SortType) {
  const { field = 'name', direction = SortingDirection.ASC } = sort || {};
  return `${field}_${direction}`.toUpperCase();
}

function joinSort(_schema: any, sort: any): string {
  return serializeSort(sort);
}

function splitSort(_schema: any, value: string): SortType {
  const parts = value.split('_');
  const [direction] = parts.splice(-1);
  return {
    field: parts?.join('_')?.toUpperCase(),
    direction: direction?.toUpperCase(),
  };
}

const serializer = schema
  .shape({
    tags: string().transform(join),
    organizationIds: string().transform(join),
    groupId: string().transform(join),
    sort: string().transform(joinSort),
  })
  .transform((values) =>
    omitBy(
      values,
      (value, key) => key !== 'sort' && (!value || !value.length),
    ),
  );

export function serialize(value: any) {
  return serializer.cast(value);
}

const deserializer = schema.shape({
  tags: arrayFromString,
  organizationIds: arrayFromString,
  groupId: arrayFromString,
  sort: sortSchema.transform(splitSort),
});

export function deserialize(query: any): DashboardFilters {
  return deserializer.cast(query);
}

export default schema;
