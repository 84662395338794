/**
 * @generated SignedSource<<2742d320f0d1e7bc79c3aa89eab26455>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EducationVideoListPage_viewer$data = {
  readonly email: string | null;
  readonly hasUnexpiredMembership: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriberContentMessage_viewer">;
  readonly " $fragmentType": "EducationVideoListPage_viewer";
};
export type EducationVideoListPage_viewer$key = {
  readonly " $data"?: EducationVideoListPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationVideoListPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationVideoListPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasUnexpiredMembership",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubscriberContentMessage_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "8206ec13457f54e912c9ce39e448593f";

export default node;
