/**
 * @generated SignedSource<<0c5dc7c0f1db583f8f57da646d075e03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ImageCaptureMode = "BIPLANE" | "B_MODE" | "B_MODE_3D" | "COLOR_DOPPLER" | "M_MODE" | "POWER_DOPPLER" | "PULSED_WAVE_DOPPLER" | "%future added value";
export type StudySource = "BUTTERFLY" | "THIRD_PARTY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExamImagePageFooter_studyImage$data = {
  readonly burnedInFile: {
    readonly isVideo: boolean | null;
  } | null;
  readonly captureMode: ImageCaptureMode | null;
  readonly handle: string | null;
  readonly id: string;
  readonly numComments: number | null;
  readonly source: StudySource | null;
  readonly study: {
    readonly handle: string | null;
    readonly id: string;
    readonly numImages: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canDeleteCaptures">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ImageToggleBookmarkButton_studyImage" | "StudyImageDownloadControl_image">;
  readonly " $fragmentType": "ExamImagePageFooter_studyImage";
};
export type ExamImagePageFooter_studyImage$key = {
  readonly " $data"?: ExamImagePageFooter_studyImage$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamImagePageFooter_studyImage">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamImagePageFooter_studyImage",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numComments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "captureMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BurnedInFile",
      "kind": "LinkedField",
      "name": "burnedInFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVideo",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Study",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numImages",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_canDeleteCaptures",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "finalizedAt",
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "StudyPermissions_studyOrArchiveIsDeleted",
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Archive",
                  "kind": "LinkedField",
                  "name": "archive",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ImageToggleBookmarkButton_studyImage"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyImageDownloadControl_image"
    }
  ],
  "type": "StudyImage",
  "abstractKey": null
};
})();

(node as any).hash = "61293effcd68466723f1d9c560024603";

export default node;
