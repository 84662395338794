import Layout from '@4c/layout';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import Text from '@bfly/ui2/Text';
import useRouter from 'found/useRouter';
import { FormattedMessage } from 'react-intl';
import type { MessageDescriptor } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PatientName from 'components/PatientName';
import { useExamRoutes } from 'routes/exam';

import Avatar from './Avatar';
import Notification from './Notification';
import type { StudyNotification_image$data as Image } from './__generated__/StudyNotification_image.graphql';
import type { StudyNotification_notification$data as NotificationType } from './__generated__/StudyNotification_notification.graphql';
import type { StudyNotification_study$data as Study } from './__generated__/StudyNotification_study.graphql';
import type { StudyNotification_userProfile$data as UserProfile } from './__generated__/StudyNotification_userProfile.graphql';

interface Props {
  notification: NotificationType;
  userProfile: UserProfile | null;
  study: Study;
  image: Image | null;
  message: MessageDescriptor;
  query?: Record<string, any>;
  clearable: boolean;
}

function StudyNotification({
  notification,
  userProfile,
  study,
  image,
  message,
  query,
  clearable,
}: Props) {
  const examRoutes = useExamRoutes();
  const { router } = useRouter();

  const imageHandle = image?.handle;

  const handleClick = () => {
    router.push({
      query,
      pathname: imageHandle
        ? examRoutes.examImage({
            organizationSlug: study!.organization!.slug!,
            studyHandle: study!.handle!,
            studyImageHandle: imageHandle,
          })
        : examRoutes.exam({
            organizationSlug: study!.organization!.slug!,
            studyHandle: study!.handle!,
          }),
    });
  };

  const notificationBody = (
    <Layout pad align="center">
      <Avatar userProfile={userProfile} width={24} />
      <Layout grow direction="column" css="min-width: 0;">
        <Text truncate color="headline">
          <FormattedMessage
            {...message}
            values={{
              username: (
                <Text color="headline" variant="body-bold">
                  {userProfile ? (
                    userProfile.name
                  ) : (
                    <FormattedMessage
                      id="notificationCenter.studyNotification.createdBy.missing"
                      defaultMessage="Unassigned"
                    />
                  )}
                </Text>
              ),
              studyName: (
                <Text color="headline" variant="body-bold">
                  <PatientName patient={study.patient} />
                </Text>
              ),
            }}
          />
        </Text>

        <Text truncate variant="xs" as="div" color="subtitle" className="mt-1">
          <FormattedMessage
            id="notificationCenter.studyNotification.createdAt"
            defaultMessage="{createdAt} - {archiveLabel}"
            values={{
              archiveLabel: study.archive!.label,
              createdAt: <FormattedDateTime value={notification.createdAt!} />,
            }}
          />
        </Text>
      </Layout>
    </Layout>
  );

  return (
    <Notification
      notification={notification}
      onClick={handleClick}
      clearable={clearable}
    >
      {notificationBody}
    </Notification>
  );
}

export default createFragmentContainer(StudyNotification, {
  notification: graphql`
    fragment StudyNotification_notification on NotificationInterface {
      createdAt
      ...Notification_notification
    }
  `,
  userProfile: graphql`
    fragment StudyNotification_userProfile on UserProfile {
      name
      ...Avatar_userProfile
    }
  `,
  study: graphql`
    fragment StudyNotification_study on Study {
      handle
      organization {
        slug
      }
      archive {
        label
        handle
      }
      patient {
        ...PatientName_patient
      }
    }
  `,
  image: graphql`
    fragment StudyNotification_image on StudyImage {
      handle
    }
  `,
});
