import Layout from '@4c/layout';
import Carousel from '@bfly/ui2/Carousel';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import getNodes from '@bfly/utils/getNodes';
import { css } from 'astroturf';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ExamImageRoll from 'components/ExamImageRoll';
import { RoutePageProps } from 'components/Route';
import StudyImage from 'components/StudyImage';
import useElementsNavigation from 'hooks/useElementsNavigation';
import useInterpretationImage from 'hooks/useInterpretationImage';
import { useExamRoutes } from 'routes/exam';

import ExamImagePageFooter from './ExamImagePageFooter';
import { ExamImagePage_InterpretationsQuery as InterpretationsQuery } from './__generated__/ExamImagePage_InterpretationsQuery.graphql';
import type { ExamImagePage_study$data as Study } from './__generated__/ExamImagePage_study.graphql';
import { ExamImagePage_viewer$data as Viewer } from './__generated__/ExamImagePage_viewer.graphql';

interface Props extends RoutePageProps {
  study: Study;
  viewer: Viewer;
  // studyListNav: React.ReactNode;
  // organization: Organization;
}

function ExamImagePage({ study, match, viewer, children }: Props) {
  const { params, location } = match;
  const routes = useExamRoutes();
  const [showComments, setShowComments] = useState(false);

  const images = getNodes(study.imageConnection);

  const [activeImage, handler] = useElementsNavigation(images, {
    activeHandle: params.studyImageHandle,
    to: (handle) => ({
      pathname: routes.examImage({
        studyHandle: params.studyHandle,
        studyImageHandle: handle,
      }),
      query: location.query,
      state: {
        ...location.state,
        studyListNavConfig: location.state?.studyListNavConfig,
      },
    }),
  });

  const {
    interpretationImage,
    interpretationImageUrl,
    showRenderControl,
    showInterpretationControl,
  } = useInterpretationImage<InterpretationsQuery>({
    query: graphql`
      query ExamImagePage_InterpretationsQuery($handle: String!) {
        studyImage(handle: $handle) {
          interpretations(
            interpretationType: [
              APICAL_EF
              PLAX_EF
              BLADDER_VOLUME
              BLINE_COUNT
              CAPTION_ECHO
              ANATOMICAL_LABEL
            ]
          ) {
            ...useInterpretationImage_interpretation
            interpretationType
          }
        }
      }
    `,
    variables: {
      handle: params.studyImageHandle || images[0]?.handle || '',
    },
    getInterpretations: (data) => data.studyImage?.interpretations,
    getInterpretationTypes: (data) => {
      return data.studyImage?.interpretations?.map(
        (i) => i!.interpretationType,
      );
    },
    hideVideoControls: showComments,
  });

  if (!activeImage) {
    return null;
  }

  return (
    <div
      className="relative h-full"
      css={css`
        display: grid;
        grid-template:
          'image' minmax(0, 1fr)
          'preview-roll' minmax(0, auto)
          'toolbar' auto;

        @screen md {
          grid-template-rows: minmax(0, 1fr) auto;
          grid-template-columns: minmax(0, auto) minmax(0, 1fr);
          grid-template-areas:
            'preview-roll image'
            'preview-roll toolbar';
        }
      `}
    >
      <div
        style={{ gridArea: 'preview-roll' }}
        className="border-t md:border-r md:border-t-0 border-grey-85 overflow-hidden z-10"
      >
        <ExamImageRoll
          images={images}
          className="md:flex-col space-x-1 md:space-y-1 md:space-x-0 p-1 h-full"
        />
      </div>
      {children || (
        <>
          <Layout direction="column" style={{ gridArea: 'image' }}>
            <>
              {showRenderControl && (
                <Layout justify="center" className="mt-2">
                  {showRenderControl}
                </Layout>
              )}
              <Carousel
                className="h-full min-h-0 px-10 m-4"
                isFirst={handler.isFirst}
                isLast={handler.isLast}
                onPrev={handler.prev}
                onNext={handler.next}
              >
                {interpretationImage || (
                  <StudyImage
                    image={activeImage}
                    index={handler.activeIndex}
                    hideVideoControls={showComments}
                  />
                )}
              </Carousel>
            </>
            <div className="px-4 pb-4 flex">
              <span>
                <FormattedMessage
                  id="ExamImagePage.imageNumber"
                  defaultMessage="{imageNumber} of {numberOfImages}"
                  values={{
                    imageNumber: handler.activeIndex + 1,
                    numberOfImages: study.numImages,
                  }}
                />
                {' - '}
                <FormattedDateTime value={activeImage.capturedAt!} />
              </span>
              <span className="ml-auto" />
              {showInterpretationControl}
            </div>
          </Layout>
          <ExamImagePageFooter
            viewer={viewer}
            studyImage={activeImage}
            interpretationImageUrl={interpretationImageUrl}
            gridArea="toolbar"
            showComments={showComments}
            setShowComments={setShowComments}
          />
        </>
      )}
    </div>
  );
}

export default createFragmentContainer(ExamImagePage, {
  viewer: graphql`
    fragment ExamImagePage_viewer on Viewer {
      ...ExamImagePageFooter_viewer
    }
  `,
  study: graphql`
    fragment ExamImagePage_study on Study {
      deletedAt
      finalizedAt
      archive {
        label
        deletedAt
      }
      organization {
        viewerCanComment: viewerHasEntitlement(
          key: "cloud.commenting.study_image"
        ) {
          ... on BooleanEntitlement {
            value
          }
        }
      }
      imageConnection(first: 2147483647)
        @connection(key: "ExamImagePage_imageConnection") {
        edges {
          node {
            id
            handle
            capturedAt
            captureMode
            source
            # XXX: this is used by the top level follow control
            # adding it here prevents an awkward loading spinner there
            # we should find a nicer way to do this.
            viewerIsFollowing
            burnedInFile {
              isVideo
              dataUrl
            }
            ...StudyImage_image
            ...ExamImageRoll_images
            ...ExamImagePageFooter_studyImage
          }
        }
      }
      numImages
    }
  `,
});
