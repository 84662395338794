import getNodes from '@bfly/utils/getNodes';
import { graphql, readInlineData } from 'react-relay';
import { object, string } from 'yup';

import type { archiveSchema_organization$key as ArchiveOrganizationKey } from 'schemas/__generated__/archiveSchema_organization.graphql';

interface Archive {
  readonly id: string;
  readonly label: string | null;
}

interface SchemaContext {
  otherArchives: Archive[];
  otherDeletedArchives: Archive[];
}

const schema = object({
  label: string()
    .required('Please provide an archive title')
    .trim()
    .test(
      'is-unique',
      'Archive with this name already exists',
      function validateArchiveLabelIsUnique(value) {
        if (!value) {
          return true;
        }

        // Consider moving the toLowercase to the Form since this runs on every
        // keystroke.
        return !(this.options.context as SchemaContext).otherArchives.some(
          ({ label }) => label!.toLowerCase() === value.toLowerCase(),
        );
      },
    )
    .test(
      'is-unique-deleted',
      'Deleted archive with this name already exists',
      function validateArchiveLabelIsUniqueDeleted(value) {
        if (!value) {
          return true;
        }

        // Consider moving the toLowercase to the Form since this runs on every
        // keystroke.
        return !(
          this.options.context as SchemaContext
        ).otherDeletedArchives.some(
          ({ label }) => label!.toLowerCase() === value.toLowerCase(),
        );
      },
    ),
});

export default schema;

export function makeArchiveSchemaContext(
  organizationRef: ArchiveOrganizationKey,
  currentArchiveId: string | null = null,
): SchemaContext {
  const organization = readInlineData(
    graphql`
      fragment archiveSchema_organization on Organization @inline {
        archiveConnection(first: 2147483647)
          @connection(key: "Organization_archiveConnection") {
          edges {
            node {
              id
              label
            }
          }
        }
        deletedArchiveConnection: archiveConnection(
          first: 2147483647
          isDeleted: true
        )
          @connection(
            key: "Organization_deletedArchiveConnection"
            filters: []
          ) {
          edges {
            node {
              id
              label
            }
          }
        }
      }
    `,
    organizationRef,
  );

  const { archiveConnection, deletedArchiveConnection } = organization;

  return {
    otherArchives: getNodes(archiveConnection).filter(
      ({ id }) => id !== currentArchiveId,
    ),
    otherDeletedArchives: getNodes(deletedArchiveConnection).filter(
      ({ id }) => id !== currentArchiveId,
    ),
  };
}

export const serialize = (value) =>
  schema.cast(value, { stripUnknown: true, context: { serialize: true } });

export const deserialize = (value) =>
  schema.cast(value, { stripUnknown: true });
