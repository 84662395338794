import React, { useContext } from 'react';
import { type Environment } from 'relay-runtime';

import { AuthType } from '../../utils/auditLogs';

export interface SetAccessOptions {
  trackLogin?: boolean;
  authType: AuthType;
}

export interface AuthContextValue {
  setAccessToken: (
    accessToken: string,
    options?: SetAccessOptions,
  ) => Promise<void>;
  clearAccessToken: (redirectToRoot?: boolean) => Promise<void>;
  isAuthenticated: () => boolean;
  getTokenInfo: () => { accessToken: string; expiresAt: number } | null;
  createEnvironment(apiOrigin?: string): Environment;
}

const AuthContext = React.createContext<AuthContextValue>(null as any);
export default AuthContext;

export function useAuth(): AuthContextValue {
  return useContext(AuthContext);
}

export function withAuth<TProps extends { auth?: AuthContextValue }>(
  Component: React.ComponentType<TProps>,
) {
  return React.forwardRef((props: Omit<TProps, 'auth'>, ref: any) => (
    <Component ref={ref} auth={useContext(AuthContext)} {...(props as any)} />
  ));
}
