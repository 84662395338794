/**
 * @generated SignedSource<<57382559db85d145f98807e57119f8b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ServerLogStatus = "COMPLETE" | "FAILED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ThirdPartyUltrasoundLogListItem_log$data = {
  readonly createdAt: string | null;
  readonly id?: string;
  readonly status: ServerLogStatus | null;
  readonly " $fragmentType": "ThirdPartyUltrasoundLogListItem_log";
};
export type ThirdPartyUltrasoundLogListItem_log$key = {
  readonly " $data"?: ThirdPartyUltrasoundLogListItem_log$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThirdPartyUltrasoundLogListItem_log">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThirdPartyUltrasoundLogListItem_log",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "ServerLogInterface",
  "abstractKey": "__isServerLogInterface"
};

(node as any).hash = "906ada5c563fc22b0e83835ed9e357f9";

export default node;
