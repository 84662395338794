/**
 * @generated SignedSource<<262c40ccb38e6c2455f6a65d109569a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteStudyControl_study$data = {
  readonly archive: {
    readonly handle: string | null;
    readonly id: string;
  } | null;
  readonly finalizedAt: string | null;
  readonly id: string;
  readonly numImages: number | null;
  readonly organization: {
    readonly id: string;
    readonly slug: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canDeleteStudy" | "studyAuthors_study">;
  readonly " $fragmentType": "DeleteStudyControl_study";
};
export type DeleteStudyControl_study$key = {
  readonly " $data"?: DeleteStudyControl_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteStudyControl_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalizedAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteStudyControl_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canDeleteStudy",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_studyOrArchiveIsDeleted",
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanDelete",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "studyAuthors_study",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "secondaryAuthors",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "e64a46ec3d4d623769a45b75be480572";

export default node;
