import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import usePagedConnection from 'hooks/usePagedConnection';
import { STUDY_GRID_PAGE_SIZE } from 'utils/StudyConstants';

import PagedArchivePage from './PagedArchivePage';
import { PagedArchivePageQuery } from './__generated__/PagedArchivePageQuery.graphql';
import { SearchPagedArchivePage_archive$data as Archive } from './__generated__/SearchPagedArchivePage_archive.graphql';
import { SearchPagedArchivePage_studies_search$key as ArchiveSearchKey } from './__generated__/SearchPagedArchivePage_studies_search.graphql';
import { SearchPagedArchivePage_viewer$data as Viewer } from './__generated__/SearchPagedArchivePage_viewer.graphql';

interface Props {
  archive: Archive;
  loading?: boolean;
  viewer: Viewer;
}

const SearchPagedArchivePage = ({ archive, loading, viewer }: Props) => {
  const [studies, paginationMeta] = usePagedConnection<
    PagedArchivePageQuery,
    ArchiveSearchKey,
    'studySearchConnection'
  >(
    graphql`
      fragment SearchPagedArchivePage_studies_search on Organization
      @refetchable(queryName: "PagedArchivePageSearchQuery") {
        studySearchConnection(
          first: $first
          after: $after
          last: $last
          before: $before
          sort: $sort
          search: $search
        ) @connection(key: "Archive_studySearchConnection") {
          index
          numEdges
          edges {
            ...StudyListContent_studies
              @arguments(listEnabled: true, lazyLoadImages: true)
            node {
              id
            }
          }
        }
      }
    `,
    archive.organization!,
    {
      pageSize: STUDY_GRID_PAGE_SIZE,
      overfetchNumPages: 0,
    },
  );
  return (
    <PagedArchivePage
      archive={archive}
      viewer={viewer}
      loading={loading}
      paginationMeta={paginationMeta}
      studies={studies}
    />
  );
};

export default createFragmentContainer(SearchPagedArchivePage, {
  archive: graphql`
    fragment SearchPagedArchivePage_archive on Archive {
      ...PagedArchivePage_archive
      ...StudyListContent_archive
      organization {
        ...SearchPagedArchivePage_studies_search
      }
    }
  `,
  viewer: graphql`
    fragment SearchPagedArchivePage_viewer on Viewer {
      ...PagedArchivePage_viewer
      ...StudyListContent_viewer
    }
  `,
});
