/**
 * @generated SignedSource<<a9e60baabcd2c65a11300d33381a95e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotFoundNotification_notification$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Notification_notification">;
  readonly " $fragmentType": "NotFoundNotification_notification";
};
export type NotFoundNotification_notification$key = {
  readonly " $data"?: NotFoundNotification_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotFoundNotification_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotFoundNotification_notification",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Notification_notification"
    }
  ],
  "type": "NotificationInterface",
  "abstractKey": "__isNotificationInterface"
};

(node as any).hash = "27db18ca7694702c151dbc67e0df433f";

export default node;
