/**
 * @generated SignedSource<<c72fd68f00f9b7f3c992f699b2fec3c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_studyReviewStatus$data = {
  readonly archive: {
    readonly worksheetRequiredForQa: boolean | null;
  } | null;
  readonly numQaEntries: number | null;
  readonly numWorksheets: number | null;
  readonly standardQa: {
    readonly createdBy: {
      readonly name: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "StudyPermissions_studyReviewStatus";
};
export type StudyPermissions_studyReviewStatus$key = {
  readonly " $data"?: StudyPermissions_studyReviewStatus$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyReviewStatus">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_studyReviewStatus"
};

(node as any).hash = "e46b96826cefdd117c8c8f86e8908389";

export default node;
