import useQuery from '@bfly/ui2/useQuery';
import notNullish from '@bfly/utils/notNullish';
import { graphql } from 'relay-runtime';

import { useStudyListLazySyncQuery as UseStudyListLazySyncQuery } from './__generated__/useStudyListLazySyncQuery.graphql';

export default function useStudyListLazySync(
  studyIds: string[] | null | undefined,
) {
  const { data } = useQuery<UseStudyListLazySyncQuery>(
    graphql`
      query useStudyListLazySyncQuery($ids: [ID!]!) {
        nodes(ids: $ids) {
          ...StudyDataGrid_studiesWithSync
        }
      }
    `,
    {
      fetchPolicy: 'store-or-network',
      variables: { ids: studyIds || [] },
      skip: !studyIds?.length,
    },
  );

  return data?.nodes.filter(notNullish) ?? null;
}
