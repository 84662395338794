/**
 * @generated SignedSource<<16258ea7c984d08a39924c87124d747e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySearchInputStatus = "DRAFT" | "FINALIZED" | "NEEDS_REVIEW" | "PENDING_ATTESTATION" | "REVIEWED" | "%future added value";
export type StudyStatisticsExportPivotType = "EXAM_TYPE" | "%future added value";
export type StudyStatisticsExportType = "DETAILED" | "SIMPLE" | "%future added value";
export type StudyStatisticsFilterInput = {
  archive?: ReadonlyArray<string> | null;
  author?: ReadonlyArray<string> | null;
  authorDeleted?: ReadonlyArray<boolean> | null;
  capturedAt?: DateTimeRangeInput | null;
  credentialGroup?: ReadonlyArray<string> | null;
  domain?: ReadonlyArray<string> | null;
  examType?: ReadonlyArray<string> | null;
  isDeleted?: ReadonlyArray<boolean> | null;
  isRelevantForCredentialing?: ReadonlyArray<boolean> | null;
  organization?: ReadonlyArray<string> | null;
  reviewer?: ReadonlyArray<string> | null;
  status?: ReadonlyArray<StudySearchInputStatus> | null;
  worksheetTemplate?: ReadonlyArray<string> | null;
};
export type DateTimeRangeInput = {
  days?: number | null;
  endDate?: string | null;
  startDate?: string | null;
};
export type useStudyStatisticsExportQuery$variables = {
  exportType: StudyStatisticsExportType;
  filter: StudyStatisticsFilterInput;
  includePivot: boolean;
  pivot?: StudyStatisticsExportPivotType | null;
  tenantId: string;
  timezone?: string | null;
};
export type useStudyStatisticsExportQuery$data = {
  readonly tenant: {
    readonly studyStatistics?: {
      readonly exportWithPivot?: {
        readonly " $fragmentSpreads": FragmentRefs<"useFileExport_fileExport">;
      } | null;
      readonly exportWithoutPivot?: {
        readonly " $fragmentSpreads": FragmentRefs<"useFileExport_fileExport">;
      } | null;
    } | null;
  } | null;
};
export type useStudyStatisticsExportQuery = {
  response: useStudyStatisticsExportQuery$data;
  variables: useStudyStatisticsExportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "exportType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includePivot"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pivot"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timezone"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v8 = {
  "kind": "Variable",
  "name": "exportType",
  "variableName": "exportType"
},
v9 = {
  "kind": "Variable",
  "name": "timezone",
  "variableName": "timezone"
},
v10 = [
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "pivot",
    "variableName": "pivot"
  },
  (v9/*: any*/)
],
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v12 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "useFileExport_fileExport",
    "selections": (v11/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
],
v13 = [
  (v8/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useStudyStatisticsExportQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "StudyStatistics",
                "kind": "LinkedField",
                "name": "studyStatistics",
                "plural": false,
                "selections": [
                  {
                    "condition": "includePivot",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": "exportWithPivot",
                        "args": (v10/*: any*/),
                        "concreteType": "FileExport",
                        "kind": "LinkedField",
                        "name": "export",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "condition": "includePivot",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "alias": "exportWithoutPivot",
                        "args": (v13/*: any*/),
                        "concreteType": "FileExport",
                        "kind": "LinkedField",
                        "name": "export",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "TenantInterface",
            "abstractKey": "__isTenantInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "useStudyStatisticsExportQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "StudyStatistics",
                "kind": "LinkedField",
                "name": "studyStatistics",
                "plural": false,
                "selections": [
                  {
                    "condition": "includePivot",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": "exportWithPivot",
                        "args": (v10/*: any*/),
                        "concreteType": "FileExport",
                        "kind": "LinkedField",
                        "name": "export",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "condition": "includePivot",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "alias": "exportWithoutPivot",
                        "args": (v13/*: any*/),
                        "concreteType": "FileExport",
                        "kind": "LinkedField",
                        "name": "export",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "TenantInterface",
            "abstractKey": "__isTenantInterface"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea622e15c8ff89caea9dd4e1b14756d7",
    "id": null,
    "metadata": {},
    "name": "useStudyStatisticsExportQuery",
    "operationKind": "query",
    "text": "query useStudyStatisticsExportQuery(\n  $tenantId: ID!\n  $filter: StudyStatisticsFilterInput!\n  $exportType: StudyStatisticsExportType!\n  $pivot: StudyStatisticsExportPivotType\n  $includePivot: Boolean!\n  $timezone: String\n) {\n  tenant: node(id: $tenantId) {\n    __typename\n    ... on TenantInterface {\n      __isTenantInterface: __typename\n      studyStatistics(filter: $filter) {\n        exportWithPivot: export(exportType: $exportType, pivot: $pivot, timezone: $timezone) @include(if: $includePivot) {\n          ...useFileExport_fileExport\n        }\n        exportWithoutPivot: export(exportType: $exportType, timezone: $timezone) @skip(if: $includePivot) {\n          ...useFileExport_fileExport\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment useFileExport_fileExport on FileExport {\n  url\n}\n"
  }
};
})();

(node as any).hash = "5176e70e2894426fa58587cfb71ae660";

export default node;
