import { useCallback } from 'react';
import { graphql, readInlineData } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import { usePermissions } from 'utils/viewerContext';

import { StudyPermissions_canDeleteCaptures$key as CanDeleteCaptures } from './__generated__/StudyPermissions_canDeleteCaptures.graphql';
import { StudyPermissions_canDeleteFinalizedStudy$key as CanDeleteFinalizedStudy } from './__generated__/StudyPermissions_canDeleteFinalizedStudy.graphql';
import { StudyPermissions_canDeleteStudy$key as CanDeleteStudy } from './__generated__/StudyPermissions_canDeleteStudy.graphql';
import { StudyPermissions_canEditStudy$key as CanEditStudy } from './__generated__/StudyPermissions_canEditStudy.graphql';
import { StudyPermissions_canEditStudyAuthor$key as CanEditStudyAuthor } from './__generated__/StudyPermissions_canEditStudyAuthor.graphql';
import { StudyPermissions_canFinalizeStudy$key as CanFinalizeStudy } from './__generated__/StudyPermissions_canFinalizeStudy.graphql';
import { StudyPermissions_canGenerateStudyPdf$key as CanGenerateStudyPdf } from './__generated__/StudyPermissions_canGenerateStudyPdf.graphql';
import { StudyPermissions_canManageEndpoints$key as CanManageEndpoints } from './__generated__/StudyPermissions_canManageEndpoints.graphql';
import { StudyPermissions_canMarkAsDraft$key as CanMarkAsDraft } from './__generated__/StudyPermissions_canMarkAsDraft.graphql';
import { StudyPermissions_canMoveStudy$key as CanMoveStudy } from './__generated__/StudyPermissions_canMoveStudy.graphql';
import { StudyPermissions_canPermanentlyDeleteStudy$key as CanPermanentlyDeleteStudy } from './__generated__/StudyPermissions_canPermanentlyDeleteStudy.graphql';
import { StudyPermissions_canSaveMeasurements$key as CanSaveMeasurements } from './__generated__/StudyPermissions_canSaveMeasurements.graphql';
import { StudyPermissions_canSplitStudies$key as CanSplitStudies } from './__generated__/StudyPermissions_canSplitStudies.graphql';
import { StudyPermissions_canTagStudy$key as CanTagStudy } from './__generated__/StudyPermissions_canTagStudy.graphql';
import { StudyPermissions_canUndeleteStudy$key as CanUndeleteStudy } from './__generated__/StudyPermissions_canUndeleteStudy.graphql';
import { StudyPermissions_studyOrArchiveIsDeleted$key as StudyOrArchiveIsDeleted } from './__generated__/StudyPermissions_studyOrArchiveIsDeleted.graphql';
import { StudyPermissions_studyReviewStatus$key as StudyReviewStatus } from './__generated__/StudyPermissions_studyReviewStatus.graphql';

const _ = graphql`
  fragment StudyPermissions_allStudyPermissions on Study {
    ...StudyPermissions_studyOrArchiveIsDeleted
    ...StudyPermissions_canDeleteStudy
    ...StudyPermissions_canUndeleteStudy
    ...StudyPermissions_canPermanentlyDeleteStudy
    ...StudyPermissions_canMoveStudy
    ...StudyPermissions_canManageEndpoints
    ...StudyPermissions_canMarkAsDraft
    ...StudyPermissions_canEditStudy
    ...StudyPermissions_canSplitStudies
    ...StudyPermissions_canDeleteCaptures
    ...StudyPermissions_canSaveMeasurements
    ...StudyPermissions_canGenerateStudyPdf
    ...StudyPermissions_canFinalizeStudy
    ...StudyPermissions_canTagStudy
    ...StudyPermissions_canEditStudyAuthor
    ...useStudyFinalization_study
  }
`;

export function studyOrArchiveIsDeleted(studyRef: StudyOrArchiveIsDeleted) {
  const { deletedAt, archive } = readInlineData(
    graphql`
      fragment StudyPermissions_studyOrArchiveIsDeleted on Study @inline {
        deletedAt
        archive {
          deletedAt
        }
      }
    `,
    studyRef,
  );

  return !!(deletedAt || archive!.deletedAt);
}

export function studyReviewStatus(studyRef: StudyReviewStatus) {
  const { numWorksheets, numQaEntries, archive, standardQa } = readInlineData(
    graphql`
      fragment StudyPermissions_studyReviewStatus on Study @inline {
        numWorksheets
        numQaEntries
        archive {
          worksheetRequiredForQa
        }
        standardQa {
          createdBy {
            name
          }
        }
      }
    `,
    studyRef,
  );

  if (standardQa || numQaEntries) {
    return 'REVIEWED';
  }

  if (numWorksheets || archive!.worksheetRequiredForQa === false) {
    return numQaEntries ? 'REVIEWED' : 'NEEDS_REVIEW';
  }
  return 'INELIGIBLE_FOR_REVIEW';
}

export function useCanDeleteStudy() {
  const { hasBasicPermission } = usePermissions();

  return useCallback(
    (studyRef: CanDeleteStudy) => {
      const study = readInlineData(
        graphql`
          fragment StudyPermissions_canDeleteStudy on Study @inline {
            ...StudyPermissions_studyOrArchiveIsDeleted
            viewerCanDelete
            finalizedAt
          }
        `,
        studyRef,
      );

      const viewerCanDelete =
        hasBasicPermission('dataDeletion') || !study?.finalizedAt;
      return viewerCanDelete && !studyOrArchiveIsDeleted(study);
    },
    [hasBasicPermission],
  );
}

export function useCanUndeleteStudy(studyRef: CanUndeleteStudy) {
  const { hasBasicPermission } = usePermissions();

  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canUndeleteStudy on Study @inline {
        deletedAt
        archive {
          deletedAt
        }
      }
    `,
    studyRef,
  );

  const viewerCanUndelete = hasBasicPermission('dataDeletion');

  return viewerCanUndelete && !!study.deletedAt && !study.archive!.deletedAt;
}

export function useCanPermanentlyDeleteStudy(
  studyRef: CanPermanentlyDeleteStudy,
) {
  const { hasBasicPermission } = usePermissions();

  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canPermanentlyDeleteStudy on Study @inline {
        deletedAt
      }
    `,
    studyRef,
  );

  const canPermanentlyDelete = hasBasicPermission('dataDeletion');

  const canPermanentlyDeleteFromVariation = useVariation(
    'permanently-delete-studies',
  );

  return (
    canPermanentlyDelete &&
    !!study.deletedAt &&
    canPermanentlyDeleteFromVariation
  );
}

export function canDeleteFinalizedStudy(
  viewerProfileId?: string,
  studyRef?: CanDeleteFinalizedStudy,
): boolean {
  const study =
    studyRef &&
    readInlineData(
      graphql`
        fragment StudyPermissions_canDeleteFinalizedStudy on Study @inline {
          id
          finalizedAt
          createdBy {
            id
          }
          # viewerCanDelete // TODO do we need to check this?
        }
      `,
      studyRef,
    );

  return (
    !study?.finalizedAt ||
    (!!viewerProfileId && study?.createdBy?.id === viewerProfileId)
  );
}

export function canMoveStudy(studyRef: CanMoveStudy) {
  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canMoveStudy on Study @inline {
        viewerCanMove
        finalizedAt
        ...StudyPermissions_studyOrArchiveIsDeleted
      }
    `,
    studyRef,
  );

  return (
    study.viewerCanMove! &&
    !study.finalizedAt &&
    !studyOrArchiveIsDeleted(study)
  );
}

export function canFinalizeStudy(studyRef: CanFinalizeStudy) {
  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canFinalizeStudy on Study @inline {
        finalizedAt
        ...StudyPermissions_studyOrArchiveIsDeleted
      }
    `,
    studyRef,
  );

  return !study.finalizedAt && !studyOrArchiveIsDeleted(study);
}

export function useCanManageEndpoints(studyRef: CanManageEndpoints) {
  const canUseDicomConnector = useVariation('dicom-connector');
  const canUseHl7Connector = useVariation('hl7-connector');

  return (
    (canUseDicomConnector || canUseHl7Connector) &&
    !studyOrArchiveIsDeleted(
      readInlineData(
        graphql`
          fragment StudyPermissions_canManageEndpoints on Study @inline {
            ...StudyPermissions_studyOrArchiveIsDeleted
          }
        `,
        studyRef,
      ),
    )
  );
}

export function useCanEditStudyAuthor(studyRef: CanEditStudyAuthor) {
  const canEditStudyAuthor = useVariation('editable-study-author');
  if (!canEditStudyAuthor) return false;

  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canEditStudyAuthor on Study @inline {
        finalizedAt
        ...StudyPermissions_studyOrArchiveIsDeleted
      }
    `,
    studyRef,
  );

  return !study.finalizedAt && !studyOrArchiveIsDeleted(study);
}

export function useCanTagStudy(studyRef: CanTagStudy) {
  const canTagStudy = useVariation('study-tags');
  if (!canTagStudy) return false;

  return !studyOrArchiveIsDeleted(
    readInlineData(
      graphql`
        fragment StudyPermissions_canTagStudy on Study @inline {
          ...StudyPermissions_studyOrArchiveIsDeleted
        }
      `,
      studyRef,
    ),
  );
}

export function useCanMarkAsDraft(studyRef: CanMarkAsDraft) {
  const canUseDrafts = useVariation('draft-studies');
  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canMarkAsDraft on Study @inline {
        viewerCanMarkAsDraft
      }
    `,
    studyRef,
  );
  return canUseDrafts && !!study.viewerCanMarkAsDraft;
}

export function useCanEditStudy(studyRef: CanEditStudy) {
  const canUseDrafts = useVariation('draft-studies');

  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canEditStudy on Study @inline {
        viewerCanEdit
        finalizedAt
        ...StudyPermissions_studyOrArchiveIsDeleted
      }
    `,
    studyRef,
  );

  return (
    study.viewerCanEdit! &&
    // can only edit drafts if drafts are enabled
    (!canUseDrafts || !study.finalizedAt) &&
    !studyOrArchiveIsDeleted(study)
  );
}

export function useCanSplitStudies(studyRef: CanSplitStudies) {
  // For split studies and delete captures
  const showSplitStudies = useVariation('split-studies');
  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canSplitStudies on Study @inline {
        finalizedAt

        ...StudyPermissions_studyOrArchiveIsDeleted
      }
    `,
    studyRef,
  );

  return (
    !study.finalizedAt && !studyOrArchiveIsDeleted(study) && showSplitStudies
  );
}

export function useCanDeleteCaptures(studyRef: CanDeleteCaptures) {
  // For split studies and delete captures
  const showDeleteCaptures = useVariation('delete-study-captures');
  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canDeleteCaptures on Study @inline {
        finalizedAt

        ...StudyPermissions_studyOrArchiveIsDeleted
      }
    `,
    studyRef,
  );

  return (
    !study.finalizedAt && !studyOrArchiveIsDeleted(study) && showDeleteCaptures
  );
}

export function useCanGenerateStudyPdf(studyRef: CanGenerateStudyPdf) {
  const showPdf = useVariation('study-pdf');
  const study = readInlineData(
    graphql`
      fragment StudyPermissions_canGenerateStudyPdf on Study @inline {
        finalizedAt
        practiceType
        ...StudyPermissions_studyOrArchiveIsDeleted
      }
    `,
    studyRef,
  );

  return study.finalizedAt && !studyOrArchiveIsDeleted(study) && showPdf;
}

export function useCanSaveMeasurements(studyRef: CanSaveMeasurements) {
  const canUseDrafts = useVariation('draft-studies');
  const hasCloudMeasurementsSaving = useVariation('cloud-measurements-saving');

  if (!hasCloudMeasurementsSaving) {
    return {
      canSaveMeasurements: false,
      canSaveMeasurementsOnDrafts: false,
    };
  }

  const { finalizedAt } = readInlineData(
    graphql`
      fragment StudyPermissions_canSaveMeasurements on Study @inline {
        finalizedAt
      }
    `,
    studyRef,
  );

  return {
    canSaveMeasurements: !canUseDrafts || !finalizedAt,
    canSaveMeasurementsOnDrafts: canUseDrafts,
  };
}
