import { createFragmentContainer, graphql } from 'react-relay';

import ExamTypeIcon from 'components/ExamTypeIcon';
import {
  getExamTypeColor,
  sortByExamType,
} from 'utils/ExamTypeProficiencyUtils';

import SushiRoll from './SushiRoll';
import { SushiRolls_tenant$data as Tenant } from './__generated__/SushiRolls_tenant.graphql';

interface SushiRollsProps {
  tenant: Tenant | null;
}

function SushiRolls({ tenant }: SushiRollsProps) {
  const stats =
    tenant?.credentialStatisticsByExamType?.filter(
      (stat) => stat?.examType?.name,
    ) ?? [];
  const numUsers = (
    tenant?.credentialStatisticsByMemberConnection?.edges || []
  ).length;
  const singleUser = numUsers === 1;

  const examTypeProgress = {};
  const memberExamStats = (
    tenant?.credentialStatisticsByMemberConnection?.edges || []
  )
    .map((edge) => edge?.node?.examTypeProgress)
    .filter((v) => v);

  if (singleUser) {
    const examStats = memberExamStats[0] ?? [];
    for (const entry of examStats) {
      examTypeProgress[entry?.examType?.name ?? ''] = entry;
    }
  }

  return (
    <>
      {stats
        .sort((a, b) =>
          sortByExamType(a?.examType ?? null, b?.examType ?? null),
        )
        .map((stat) => {
          if (stat?.examType?.name) {
            const { name, systemId } = stat.examType;
            const examProgress = examTypeProgress[name];
            let nComplete = stat.numUsersCredentialed ?? 0;
            let nPartial = stat.numUsersPartiallyCredentialed ?? 0;
            let total = stat.numUsers ?? 0;
            if (singleUser) {
              nPartial = 0;
              nComplete = examProgress?.numExamsCompleted ?? 0;
              total = examProgress?.numExamsRequired ?? 0;
            }
            return (
              <SushiRoll
                data-bni-id="ExamTypeSushiRoll"
                key={name}
                color={getExamTypeColor(systemId)}
                nComplete={nComplete}
                nPartial={nPartial}
                singleUser={singleUser}
                total={total}
                icon={
                  <ExamTypeIcon
                    className="text-black h-10"
                    examTypeSystemId={systemId}
                  />
                }
                sushiRollTitle={name}
                className="mr-4"
              />
            );
          }
          return null;
        })}
    </>
  );
}

export default createFragmentContainer(SushiRolls, {
  tenant: graphql`
    fragment SushiRolls_tenant on TenantInterface {
      credentialStatisticsByExamType(filter: $filter) {
        examType {
          name
          systemId
        }
        numUsers
        numUsersPartiallyCredentialed
        numUsersCredentialed
      }
      credentialStatisticsByMemberConnection(filter: $filter) {
        edges {
          node {
            examTypeProgress {
              examType {
                id
                name
              }
              numExamsCompleted
              numExamsRequired
            }
          }
        }
      }
    }
  `,
});
