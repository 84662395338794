import ususeMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { RecordSourceSelectorProxy } from 'relay-runtime';

import allSettled from '../utils/allSettled';
import type { useDeleteStudiesMutation as UseDeleteStudiesMutation } from './__generated__/useDeleteStudiesMutation.graphql';

const mutation = graphql`
  mutation useDeleteStudiesMutation($input: DeleteStudyInput!) {
    deleteStudyOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
    }
  }
`;

export default function useDeleteStudies() {
  const toast = useToast();
  const [mutate, loading] =
    ususeMutationWithError<UseDeleteStudiesMutation>(mutation);

  const deleteStudies = async (
    studyAndArchiveIds: Array<{ studyId: string; archiveId: string }>,
    organizationId: string,
    domainId: string,
    updater?: (store: RecordSourceSelectorProxy, payloadData?: any) => void,
  ) => {
    const numStudies = studyAndArchiveIds.length;
    const results = await allSettled(
      studyAndArchiveIds.map(({ studyId, archiveId }) =>
        mutate({
          input: {
            studyId,
          },

          updater: (store, payloadData) => {
            [
              'Archive_studySearchConnection',
              'Archive_studyConnection',
            ].forEach((connKey) => {
              rangeDeleteUpdater(store, {
                parentId: archiveId,
                connectionKey: connKey,
                connectionFilters: () => true,
                deletedId: studyId,
              });
            });

            [
              'Organization_studyConnection',
              'Organization_studySearchConnection',
            ].forEach((connKey) => {
              rangeDeleteUpdater(store, {
                parentId: organizationId,
                connectionKey: connKey,
                connectionFilters: () => true,
                deletedId: studyId,
              });
            });
            if (domainId) {
              [
                'Tenant_studyConnection',
                'Tenant_studySearchConnection',
              ].forEach((connKey) => {
                rangeDeleteUpdater(store, {
                  parentId: domainId,
                  connectionKey: connKey,
                  connectionFilters: () => true,
                  deletedId: studyId,
                });
              });
            }
            updater?.(store, payloadData);
          },
        }),
      ),
    );

    const numErrors = results.filter(
      (result) => result.status === 'rejected',
    ).length;

    if (numErrors > 0) {
      toast.error(
        <FormattedMessage
          id="study.delete.failure"
          defaultMessage="Failed to delete {numStudies} {numStudies, plural,
            one {study}
            other {studies}
          }"
          values={{ numStudies: numErrors }}
        />,
      );
    }

    toast.success(
      <FormattedMessage
        id="study.delete.success"
        defaultMessage="{numStudies} {numStudies, plural,
          one {study}
          other {studies}
        } deleted"
        values={{ numStudies: numStudies - numErrors }}
      />,
    );

    return true;
  };

  return [deleteStudies, loading] as const;
}
