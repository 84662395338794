import { graphql, readInlineData } from 'react-relay';
import { array, boolean, object, string } from 'yup';

import { domainUser_defaultValue$key as DomainUserKey } from './__generated__/domainUser_defaultValue.graphql';

const integrationConfig = object({
  integrationId: string().defined(),
  // not required because we manually filter out empty ones when submitting
  interfaceCode: string(),
});

const dicomField = object({
  templateId: string().defined(),
  // not required because we manually filter out empty ones when submitting
  value: string(),
});

export const domainUserDetails = object({
  email: string().email().required().default(''),
  integrationDisplayNameFirst: string().nullable().default(''),
  integrationDisplayNameMiddle: string().nullable().default(''),
  integrationDisplayNameLast: string().nullable().default(''),
  isAdmin: boolean().default(false).required(),
  roleId: string().nullable().default(undefined),
  // Both `transform` and `default` must be defined below because transform
  //  will not execute when the value is undefined
  canQa: boolean()
    .nullable()
    .required()
    .transform((v) => (v === null ? false : v))
    .default(false),
  canFinalize: boolean()
    .nullable()
    .required()
    .transform((v) => (v === null ? false : v))
    .default(false),
  isNurse: boolean()
    .nullable()
    .required()
    .transform((v) => (v === null ? false : v))
    .default(false),
});

export const integrationConfigs = object({
  integrationConfigs: array(integrationConfig).ensure(),
});

export const dicomFieldsSchema = object({
  dicomFields: array(dicomField).ensure(),
});

const domainUserSchema = domainUserDetails
  .concat(integrationConfigs)
  .concat(dicomFieldsSchema);

export default domainUserSchema;

export function deserialize(
  domainUser: DomainUserKey | null,
  ehrs: Array<{ id: string }>,
  dicomFieldTemplates: Array<{ id: string }>,
) {
  if (!domainUser) {
    return {
      ...domainUserSchema.getDefault(),
      integrationConfigs: ehrs.map((ehr) => ({ integrationId: ehr!.id })),
      dicomFields: dicomFieldTemplates.map((template) => ({
        templateId: template!.id,
      })),
    };
  }

  const data = readInlineData(
    graphql`
      fragment domainUser_defaultValue on DomainUser @inline {
        type
        email
        canQa
        canFinalize
        isNurse
        role {
          id
        }
        integrationConfigs {
          ehr {
            id
          }
          interfaceCode
        }
        dicomFields {
          dicomFieldTemplate {
            id
          }
          value
        }
        integrationDisplayNameFirst
        integrationDisplayNameMiddle
        integrationDisplayNameLast
      }
    `,
    domainUser,
  );
  return domainUserSchema
    .transform(
      ({
        integrationConfigs: configs,
        dicomFields,
        type,
        role,
        ...value
      }) => ({
        ...value,
        roleId: role?.id,
        isAdmin: type === 'ADMIN',
        integrationConfigs: ehrs.map(({ id }) => {
          const { interfaceCode } =
            configs.find((ic) => ic.ehr.id === id) ?? {};

          return { integrationId: id, interfaceCode };
        }),
        dicomFields: dicomFieldTemplates.map(({ id }) => {
          const field =
            dicomFields.find((df) => df.dicomFieldTemplate.id === id) ?? {};

          return { templateId: id, value: field.value };
        }),
      }),
    )
    .cast(data);
}
