/**
 * @generated SignedSource<<9b9fe01c47e4f3a5912738429ec2d1d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SplitStudyRefreshModal_study$data = {
  readonly accessionNumber: string | null;
  readonly archive: {
    readonly id: string;
  } | null;
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly dicomDocument: Record<string, any> | null;
  readonly id: string;
  readonly practiceType: StudyPracticeType | null;
  readonly secondaryAuthors: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly sourceWorklistItem: {
    readonly id?: string;
  } | null;
  readonly transcribedBy: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyPatientField_sourceStudy">;
  readonly " $fragmentType": "SplitStudyRefreshModal_study";
};
export type SplitStudyRefreshModal_study$key = {
  readonly " $data"?: SplitStudyRefreshModal_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyRefreshModal_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyRefreshModal_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomDocument",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sourceWorklistItem",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "Node",
          "abstractKey": "__isNode"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitStudyPatientField_sourceStudy"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "secondaryAuthors",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "transcribedBy",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "2bbba9368e95ec496d76737bf90a241d";

export default node;
