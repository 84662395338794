/**
 * @generated SignedSource<<60e2746808a8d97bd042b0373466342e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VetPatientNeuterStatus = "INTACT" | "NEUTERED" | "UNKNOWN" | "%future added value";
export type VetPatientSex = "FEMALE" | "MALE" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type VetPatientDescriptionList_vetPatient$data = {
  readonly birthDate: string | null;
  readonly breed: string | null;
  readonly clientOrganizationName: string | null;
  readonly microchipNumber: string | null;
  readonly neuterStatus: VetPatientNeuterStatus | null;
  readonly patientIdNumber: string | null;
  readonly sex: VetPatientSex | null;
  readonly weightKilograms: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"VetPatientName_vetPatient" | "VetPatientWeight_vetPatient" | "VetSpecies_vetPatient">;
  readonly " $fragmentType": "VetPatientDescriptionList_vetPatient";
};
export type VetPatientDescriptionList_vetPatient$key = {
  readonly " $data"?: VetPatientDescriptionList_vetPatient$data;
  readonly " $fragmentSpreads": FragmentRefs<"VetPatientDescriptionList_vetPatient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VetPatientDescriptionList_vetPatient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientOrganizationName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "neuterStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weightKilograms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "microchipNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "breed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VetPatientName_vetPatient"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VetPatientWeight_vetPatient"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VetSpecies_vetPatient"
    }
  ],
  "type": "VetPatient",
  "abstractKey": null
};

(node as any).hash = "cda383a35289c753a86da32efc5782b6";

export default node;
