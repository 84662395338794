/**
 * @generated SignedSource<<8679e7b7f20bd205fd7b0700a8cbd4bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FinalizationCandidatesListbox_Query$variables = {
  search?: string | null;
  studyId: string;
};
export type FinalizationCandidatesListbox_Query$data = {
  readonly study: {
    readonly finalizationRequestRecipientCandidateConnection?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly email: string | null;
          readonly userProfile: {
            readonly id: string;
            readonly name: string | null;
            readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
          } | null;
          readonly " $fragmentSpreads": FragmentRefs<"FinalizationCandidatesSearchControl_candidates">;
        } | null;
      } | null> | null;
    } | null;
    readonly id?: string;
  } | null;
};
export type FinalizationCandidatesListbox_Query = {
  response: FinalizationCandidatesListbox_Query$data;
  variables: FinalizationCandidatesListbox_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studyId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "studyId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FinalizationCandidatesListbox_Query",
    "selections": [
      {
        "alias": "study",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "MentionableConnection",
                "kind": "LinkedField",
                "name": "finalizationRequestRecipientCandidateConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MentionableEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Mentionable",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "userProfile",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "Avatar_userProfile"
                              }
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "FinalizationCandidatesSearchControl_candidates"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Study",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FinalizationCandidatesListbox_Query",
    "selections": [
      {
        "alias": "study",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "MentionableConnection",
                "kind": "LinkedField",
                "name": "finalizationRequestRecipientCandidateConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MentionableEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Mentionable",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "userProfile",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Avatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Thumbnail",
                                        "kind": "LinkedField",
                                        "name": "thumbnails",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "url",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "width",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "ThumbnailedInterface",
                                    "abstractKey": "__isThumbnailedInterface"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isUserInfoInterface"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Study",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3aff734b0c620645107ca63d3b0a3ce6",
    "id": null,
    "metadata": {},
    "name": "FinalizationCandidatesListbox_Query",
    "operationKind": "query",
    "text": "query FinalizationCandidatesListbox_Query(\n  $studyId: ID!\n  $search: String\n) {\n  study: node(id: $studyId) {\n    __typename\n    ... on Study {\n      id\n      finalizationRequestRecipientCandidateConnection(first: 30, search: $search) {\n        edges {\n          node {\n            userProfile {\n              id\n              name\n              ...Avatar_userProfile\n            }\n            email\n            ...FinalizationCandidatesSearchControl_candidates\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment FinalizationCandidatesSearchControl_candidates on Mentionable {\n  userProfile {\n    id\n    name\n  }\n  email\n  ...UserSuggestionListItem_userInfo\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n\nfragment UserSuggestionListItem_userInfo on UserInfoInterface {\n  __isUserInfoInterface: __typename\n  userProfile {\n    name\n    ...UserSuggestion_userProfile\n    id\n  }\n  email\n}\n\nfragment UserSuggestion_userProfile on UserProfile {\n  name\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "2d1fa89924a0a0800775516fac189999";

export default node;
