import Text, { TextProps } from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RelayProp, createFragmentContainer, graphql } from 'react-relay';

import joinNonemptyStrings from '../utils/joinNonemptyStrings';
import { VetPatientName_vetPatient$data as VetPatient } from './__generated__/VetPatientName_vetPatient.graphql';

interface Props extends TextProps {
  vetPatient: VetPatient;
  className?: string;
  relay: RelayProp;
}

export const getVetPatientName = (vetPatient: VetPatient) =>
  joinNonemptyStrings(', ', [
    vetPatient.clientNameLast || vetPatient.clientOrganizationName,
    vetPatient.name,
  ]);

function VetPatientName({ vetPatient, relay: _, ...props }: Props) {
  if (!vetPatient?.name) {
    return (
      <Text variant="inherit" {...props} color="subtitle">
        <FormattedMessage
          id="vetPatientName.empty"
          defaultMessage="No Patient Name"
        />
      </Text>
    );
  }

  return (
    <Text variant="inherit" {...props}>
      {getVetPatientName(vetPatient)}
    </Text>
  );
}

export default createFragmentContainer(VetPatientName, {
  vetPatient: graphql`
    fragment VetPatientName_vetPatient on VetPatient {
      clientNameLast
      clientOrganizationName
      name
    }
  `,
});
