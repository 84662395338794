import Dropdown from '@bfly/ui2/Dropdown';
import MeatballDropdownButton from '@bfly/ui2/MeatballDropdownButton';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DeleteStudyControl from 'components/DeleteStudyControl';
import IconButton from 'components/IconButton';
import MoveStudyDropdownItem from 'components/MoveStudyDropdownItem';
import PermanentlyDeleteStudyControl from 'components/PermanentlyDeleteStudyControl';
import ShareStudyDropdownItem from 'components/ShareStudyDropdownItem';
import SplitStudyControl from 'components/SplitStudyControl';
import StudyDownloadPdfDropdownItem from 'components/StudyDownloadPdfDropdownItem';
import StudyEndpointsDropdownItem from 'components/StudyEndpointsDropdownItem';
import StudyHistoryDropdownItem from 'components/StudyHistoryDropdownItem';
import ToggleStudyFavoriteButton from 'components/ToggleStudyFavoriteButton';
import UndeleteStudyControl from 'components/UndeleteStudyControl';

import FollowImageDropdownItem from './FollowImageDropdownItem';
import MarkStudyAsDraftControl from './MarkStudyAsDraftControl';
import { ExamPageActions_study$data as Study } from './__generated__/ExamPageActions_study.graphql';

interface Props {
  study: Study;
  showUndelete?: boolean;
  className?: string;
}

function ExamPageActions({ study, showUndelete = true, className }: Props) {
  const intl = useIntl();

  return (
    <>
      <SplitStudyControl as={IconButton} study={study} />
      <ToggleStudyFavoriteButton study={study} />
      <MeatballDropdownButton
        id={`${study.id}-controls`}
        label={intl.formatMessage(
          defineMessage({
            id: 'examPageActions.label',
            defaultMessage: 'Exam screen actions',
          }),
        )}
        variant="dark"
        className={className}
        data-bni-id="ExamPageActions"
      >
        {study.source === 'BUTTERFLY' && (
          <ShareStudyDropdownItem study={study}>
            <FormattedMessage
              id="examPageActions.share"
              defaultMessage="Share de-identified exam"
            />
          </ShareStudyDropdownItem>
        )}
        <StudyEndpointsDropdownItem study={study} />
        <MoveStudyDropdownItem study={study} />
        <StudyDownloadPdfDropdownItem study={study} />
        <FollowImageDropdownItem />
        <StudyHistoryDropdownItem study={study} />

        <Dropdown.Divider />

        <MarkStudyAsDraftControl as={Dropdown.Item} study={study} />
        <Dropdown.Divider />
        <DeleteStudyControl as={Dropdown.Item} study={study} />
        {showUndelete && (
          <UndeleteStudyControl as={Dropdown.Item} study={study}>
            <FormattedMessage
              id="examPageActions.undelete"
              defaultMessage="Restore deleted exam"
            />
          </UndeleteStudyControl>
        )}
        <PermanentlyDeleteStudyControl as={Dropdown.Item} study={study} />
      </MeatballDropdownButton>
    </>
  );
}

export default createFragmentContainer(ExamPageActions, {
  study: graphql`
    fragment ExamPageActions_study on Study {
      id
      source
      ...ShareStudyDropdownItem_study
      ...SplitStudyControl_study
      ...StudyEndpointsDropdownItem_study
      ...MoveStudyDropdownItem_study
      ...StudyDownloadPdfDropdownItem_study
      ...DeleteStudyControl_study
      ...UndeleteStudyControl_study
      ...PermanentlyDeleteStudyControl_study
      ...ToggleStudyFavoriteButton_study
      ...MarkStudyAsDraftControl_study
      ...StudyHistoryDropdownItem_study
    }
  `,
});
