export default function getUrlSearchParam(
  name: string,
  init?:
    | string
    | Record<string, string>
    | string[][]
    | URLSearchParams
    | undefined,
): string | null {
  const urlParams = new URLSearchParams(init || globalThis.location.search);
  return urlParams.get(name);
}
