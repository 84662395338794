/**
 * @generated SignedSource<<b766731ad1593476b4f1c66eea255c4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BulkUndeleteDomainUsersInput = {
  clientMutationId?: string | null;
  domainId: string;
  users: ReadonlyArray<BulkInputDomainUser | null>;
};
export type BulkInputDomainUser = {
  canFinalize?: boolean | null;
  canQa?: boolean | null;
  dicomFields?: ReadonlyArray<BulkInputDomainUserDicomField> | null;
  email: string;
  integrationConfigs?: ReadonlyArray<BulkInputDomainUserIntegrationConfig> | null;
  integrationDisplayNameFirst?: string | null;
  integrationDisplayNameLast?: string | null;
  integrationDisplayNameMiddle?: string | null;
  isNurse?: boolean | null;
  roleId?: string | null;
};
export type BulkInputDomainUserIntegrationConfig = {
  integrationId: string;
  interfaceCode: string;
};
export type BulkInputDomainUserDicomField = {
  templateId: string;
  value: string;
};
export type BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation$variables = {
  input: BulkUndeleteDomainUsersInput;
};
export type BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation$data = {
  readonly bulkUndeleteDomainUsers: {
    readonly failures: ReadonlyArray<{
      readonly code: string | null;
      readonly detail: string | null;
      readonly email: string | null;
      readonly statusCode: number | null;
    }>;
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly email: string | null;
          readonly integrationDisplayNameFirst: string | null;
          readonly integrationDisplayNameLast: string | null;
          readonly integrationDisplayNameMiddle: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation = {
  response: BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation$data;
  variables: BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrationDisplayNameFirst",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrationDisplayNameMiddle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrationDisplayNameLast",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "BulkDomainUserDeleteFailure",
  "kind": "LinkedField",
  "name": "failures",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUndeleteDomainUsersPayload",
        "kind": "LinkedField",
        "name": "bulkUndeleteDomainUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUndeleteDomainUsersPayload",
        "kind": "LinkedField",
        "name": "bulkUndeleteDomainUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b472cd72cf214499d82c537e08d19ff4",
    "id": null,
    "metadata": {},
    "name": "BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation",
    "operationKind": "mutation",
    "text": "mutation BulkDomainUsersReactivateUsersConfirmationModal_bulkUndeleteDomainUsersMutation(\n  $input: BulkUndeleteDomainUsersInput!\n) {\n  bulkUndeleteDomainUsers(input: $input) {\n    users {\n      edges {\n        node {\n          email\n          integrationDisplayNameFirst\n          integrationDisplayNameMiddle\n          integrationDisplayNameLast\n          id\n        }\n      }\n    }\n    failures {\n      email\n      detail\n      statusCode\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b24cd43f9f8201411f44f81782b06e98";

export default node;
