import useDialog from '@bfly/ui2/useDialog';
import useMountEffect from '@restart/hooks/useMountEffect';
import useRouter from 'found/useRouter';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuth } from 'components/AuthContext';
import { routes } from 'routes/config';

interface Props {
  children: React.ReactNode;
  redirectToRoot?: boolean;
}

export default function ConfirmLogoutDialog({
  children,
  redirectToRoot,
}: Props) {
  const auth = useAuth();

  const { router } = useRouter();
  const dialog = useDialog();

  useMountEffect(() => {
    if (!auth!.isAuthenticated()) {
      return;
    }

    dialog!
      .open(children, {
        title: (
          <FormattedMessage
            id="confirmLogout.dialog.title"
            defaultMessage="Already Logged In"
          />
        ),
        confirmLabel: (
          <FormattedMessage
            id="confirmLogout.dialog.confirm"
            defaultMessage="Log Out"
          />
        ),
      })
      .then((logout) => {
        if (logout) {
          auth!.clearAccessToken(redirectToRoot);
        } else {
          router.replace(routes.rootRoute());
        }
      });
  });

  return null;
}
