/**
 * @generated SignedSource<<98ffd78d74998bad047340adaca3558a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AuthorsUpdatedStudyEventAction = "ADDED" | "PRIMARY_SET" | "REMOVED" | "SET_SCRIBE" | "UNSET_SCRIBE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyHistoryModalContent_authorsUpdatedStudyEvent$data = {
  readonly author: {
    readonly name: string | null;
  } | null;
  readonly authorAction: AuthorsUpdatedStudyEventAction | null;
  readonly " $fragmentType": "StudyHistoryModalContent_authorsUpdatedStudyEvent";
};
export type StudyHistoryModalContent_authorsUpdatedStudyEvent$key = {
  readonly " $data"?: StudyHistoryModalContent_authorsUpdatedStudyEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyHistoryModalContent_authorsUpdatedStudyEvent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyHistoryModalContent_authorsUpdatedStudyEvent",
  "selections": [
    {
      "alias": "authorAction",
      "args": null,
      "kind": "ScalarField",
      "name": "action",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuthorsUpdatedStudyEvent",
  "abstractKey": null
};

(node as any).hash = "43e111651b24cdd49a6bac85cdb75321";

export default node;
