/**
 * @generated SignedSource<<405bce9b2da83e5c3509153f8511d0fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImageQualityPerformanceIndicator_tenant$data = {
  readonly imageStudyStatistics: {
    readonly averageImageQuality: number | null;
    readonly imageQualityStatistics: ReadonlyArray<{
      readonly key: number;
      readonly numFinalizedStudies: number;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "ImageQualityPerformanceIndicator_tenant";
};
export type ImageQualityPerformanceIndicator_tenant$key = {
  readonly " $data"?: ImageQualityPerformanceIndicator_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImageQualityPerformanceIndicator_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageQualityPerformanceIndicator_tenant",
  "selections": [
    {
      "alias": "imageStudyStatistics",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "StudyStatistics",
      "kind": "LinkedField",
      "name": "studyStatistics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "averageImageQuality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ImageQualityStatistics",
          "kind": "LinkedField",
          "name": "imageQualityStatistics",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numFinalizedStudies",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "d9bded7d17f6e03c2f99b66fdfd4fa68";

export default node;
