/**
 * @generated SignedSource<<ffe1d3f581d8327657db510cd40fbc1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InterpretationType = "ANATOMICAL_LABEL" | "APICAL_EF" | "APICAL_EF_MEASURABILITY" | "BLADDER_VOLUME" | "BLINE_COUNT" | "CAPTION_ECHO" | "GESTATIONAL_AGE" | "MODEL_FEEDBACK" | "PACE_BLINE" | "PLAX_EF" | "PLAX_EF_MEASURABILITY" | "SDK_BATCH" | "SDK_LIVE" | "%future added value";
export type ExamImagePage_InterpretationsQuery$variables = {
  handle: string;
};
export type ExamImagePage_InterpretationsQuery$data = {
  readonly studyImage: {
    readonly interpretations: ReadonlyArray<{
      readonly interpretationType: InterpretationType | null;
      readonly " $fragmentSpreads": FragmentRefs<"useInterpretationImage_interpretation">;
    } | null> | null;
  } | null;
};
export type ExamImagePage_InterpretationsQuery = {
  response: ExamImagePage_InterpretationsQuery$data;
  variables: ExamImagePage_InterpretationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "handle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "handle"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "interpretationType",
    "value": [
      "APICAL_EF",
      "PLAX_EF",
      "BLADDER_VOLUME",
      "BLINE_COUNT",
      "CAPTION_ECHO",
      "ANATOMICAL_LABEL"
    ]
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "attachmentKey",
    "value": "BURNED_IN_IMAGE_FILE"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataUrl",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  {
    "kind": "Literal",
    "name": "attachmentKey",
    "value": "VOLUME_RENDER_FILE"
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "attachmentKey",
    "value": "STATIC_REPORT_FILE"
  }
],
v8 = [
  {
    "kind": "Literal",
    "name": "attachmentKey",
    "value": "DL_STILL_IMAGE_FILE"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interpretationType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v10/*: any*/)
    ],
    "type": "Node",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamImagePage_InterpretationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyImage",
        "kind": "LinkedField",
        "name": "studyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Interpretation",
            "kind": "LinkedField",
            "name": "interpretations",
            "plural": true,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "useInterpretationImage_interpretation",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": (v3/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachment",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": "attachment(attachmentKey:\"BURNED_IN_IMAGE_FILE\")"
                      },
                      {
                        "alias": "volumeRender",
                        "args": (v6/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachment",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": "attachment(attachmentKey:\"VOLUME_RENDER_FILE\")"
                      },
                      {
                        "alias": "staticRender",
                        "args": (v7/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachment",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": "attachment(attachmentKey:\"STATIC_REPORT_FILE\")"
                      },
                      {
                        "alias": "stillBurnIn",
                        "args": (v8/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachment",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": "attachment(attachmentKey:\"DL_STILL_IMAGE_FILE\")"
                      }
                    ],
                    "type": "InterpretationInterface",
                    "abstractKey": "__isInterpretationInterface"
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              },
              (v9/*: any*/)
            ],
            "storageKey": "interpretations(interpretationType:[\"APICAL_EF\",\"PLAX_EF\",\"BLADDER_VOLUME\",\"BLINE_COUNT\",\"CAPTION_ECHO\",\"ANATOMICAL_LABEL\"])"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamImagePage_InterpretationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyImage",
        "kind": "LinkedField",
        "name": "studyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Interpretation",
            "kind": "LinkedField",
            "name": "interpretations",
            "plural": true,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "attachment",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "attachment(attachmentKey:\"BURNED_IN_IMAGE_FILE\")"
                  },
                  {
                    "alias": "volumeRender",
                    "args": (v6/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "attachment",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "attachment(attachmentKey:\"VOLUME_RENDER_FILE\")"
                  },
                  {
                    "alias": "staticRender",
                    "args": (v7/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "attachment",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "attachment(attachmentKey:\"STATIC_REPORT_FILE\")"
                  },
                  {
                    "alias": "stillBurnIn",
                    "args": (v8/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "attachment",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "attachment(attachmentKey:\"DL_STILL_IMAGE_FILE\")"
                  }
                ],
                "type": "InterpretationInterface",
                "abstractKey": "__isInterpretationInterface"
              },
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": "interpretations(interpretationType:[\"APICAL_EF\",\"PLAX_EF\",\"BLADDER_VOLUME\",\"BLINE_COUNT\",\"CAPTION_ECHO\",\"ANATOMICAL_LABEL\"])"
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "53b2dbca95626d79bf33ee807b212a79",
    "id": null,
    "metadata": {},
    "name": "ExamImagePage_InterpretationsQuery",
    "operationKind": "query",
    "text": "query ExamImagePage_InterpretationsQuery(\n  $handle: String!\n) {\n  studyImage(handle: $handle) {\n    interpretations(interpretationType: [APICAL_EF, PLAX_EF, BLADDER_VOLUME, BLINE_COUNT, CAPTION_ECHO, ANATOMICAL_LABEL]) {\n      ...useInterpretationImage_interpretation\n      interpretationType\n      id\n    }\n    id\n  }\n}\n\nfragment useInterpretationImage_interpretation on InterpretationInterface {\n  __isInterpretationInterface: __typename\n  attachment(attachmentKey: \"BURNED_IN_IMAGE_FILE\") {\n    __typename\n    dataUrl\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  volumeRender: attachment(attachmentKey: \"VOLUME_RENDER_FILE\") {\n    __typename\n    dataUrl\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  staticRender: attachment(attachmentKey: \"STATIC_REPORT_FILE\") {\n    __typename\n    dataUrl\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  stillBurnIn: attachment(attachmentKey: \"DL_STILL_IMAGE_FILE\") {\n    __typename\n    dataUrl\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75d5364097015a139bdcd13a57e7edb0";

export default node;
