import Layout from '@4c/layout';
import Carousel from '@bfly/ui2/Carousel';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import getNodes from '@bfly/utils/getNodes';
import { css } from 'astroturf';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { RoutePageProps } from 'components/Route';
import useElementsNavigation from 'hooks/useElementsNavigation';
import { routes as scanLabRoutes } from 'routes/scanLab';

import ExamImageRoll from '../../../shared/components/ExamImageRoll';
import StudyImage from '../../exams/shared/components/StudyImage';
import type { EduStudyImage_study$data as EduStudy } from './__generated__/EduStudyImage_study.graphql';

interface Props extends RoutePageProps {
  study: EduStudy;
}

function EduStudyImage({ study, match, children }: Props) {
  const { params, location } = match;

  const images = getNodes(study.imageConnection);

  const [activeImage, handler] = useElementsNavigation(images, {
    activeHandle: params.studyImageHandle,
    to: (handle) => ({
      pathname: scanLabRoutes.examImage({
        studyHandle: params.studyHandle,
        studyImageHandle: handle,
      }),
      query: location.query,
      state: {
        ...location.state,
        studyListNavConfig: location.state?.studyListNavConfig,
      },
    }),
  });

  if (!activeImage) {
    return null;
  }

  return (
    <div
      className="relative h-full"
      css={css`
        display: grid;
        grid-template:
          'image' minmax(0, 1fr)
          'preview-roll' minmax(0, auto)
          'toolbar' auto;

        @screen md {
          grid-template-rows: minmax(0, 1fr) auto;
          grid-template-columns: minmax(0, auto) minmax(0, 1fr);
          grid-template-areas:
            'preview-roll image'
            'preview-roll toolbar';
        }
      `}
    >
      <div
        style={{ gridArea: 'preview-roll' }}
        className="border-t md:border-r md:border-t-0 border-grey-85 overflow-hidden z-10"
      >
        <ExamImageRoll
          images={images}
          className="md:flex-col space-x-1 md:space-y-1 md:space-x-0 p-1 h-full"
        />
      </div>
      {children || (
        <Layout direction="column" style={{ gridArea: 'image' }}>
          <Carousel
            className="h-full min-h-0 px-10 m-4"
            isFirst={handler.isFirst}
            isLast={handler.isLast}
            onPrev={handler.prev}
            onNext={handler.next}
          >
            <StudyImage
              image={activeImage}
              index={handler.activeIndex}
              hideVideoControls={false}
            />
          </Carousel>
          <div className="px-4 pb-4 flex">
            <span>
              <FormattedMessage
                id="ScanLabExamImagePage.imageNumber"
                defaultMessage="{imageNumber} of {numberOfImages}"
                values={{
                  imageNumber: handler.activeIndex + 1,
                  numberOfImages: study.numImages,
                }}
              />
              {' - '}
              <FormattedDateTime value={activeImage.capturedAt!} />
            </span>
            <span className="ml-auto" />
          </div>
        </Layout>
      )}
    </div>
  );
}

export default createFragmentContainer(EduStudyImage, {
  study: graphql`
    fragment EduStudyImage_study on EduStudy {
      deletedAt
      imageConnection(first: 2147483647)
        @connection(key: "ExamImagePage_imageConnection") {
        edges {
          node {
            id
            handle
            capturedAt
            captureMode
            burnedInFile {
              isVideo
              dataUrl
            }
            ...StudyImage_image
            ...ExamImageRoll_images
          }
        }
      }
      numImages
    }
  `,
});
