import Layout from '@4c/layout';
import Popover, { PopoverProps, PopoverTriggerProps } from '@bfly/ui2/Popover';
import React from 'react';

interface Props {
  id?: string;
  show?: boolean;
  children: PopoverTriggerProps['children'];
  listItems: React.ReactNode;
  variant?: PopoverProps['variant'];
}

const PopoverList = ({ id, show, children, variant, listItems }: Props) => {
  return (
    <Popover.Trigger
      placement="bottom"
      show={show}
      id={id}
      variant={variant}
      popover={
        <Layout
          pad
          as="ul"
          direction="column"
          // TODO: check if needed since tailwind default already does this
          className="p-0 m-0 list-none"
        >
          {listItems}
        </Layout>
      }
    >
      {children}
    </Popover.Trigger>
  );
};

export default PopoverList;
