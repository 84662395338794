/**
 * @generated SignedSource<<db0cd61482fde65627cc3d93cbf9bc1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamNav_study$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HeaderPatientInfo_study">;
  readonly " $fragmentType": "ExamNav_study";
};
export type ExamNav_study$key = {
  readonly " $data"?: ExamNav_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamNav_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamNav_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderPatientInfo_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "e571fd2130c87ed1f57fb27ef295fa00";

export default node;
