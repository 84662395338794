/**
 * @generated SignedSource<<a8ba1f8818016e8f2490212757d0a1bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberSelectButton_tenant$data = {
  readonly id: string;
  readonly " $fragmentType": "MemberSelectButton_tenant";
};
export type MemberSelectButton_tenant$key = {
  readonly " $data"?: MemberSelectButton_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberSelectButton_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberSelectButton_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "d075e7b3fd667834f9288222a8b9d361";

export default node;
