import {
  Maybe,
  PermissionLevel as PermissionLevelBase,
  ViewerPermissions as ViewerPermissionsBase,
} from '@bfly/olympus-graphql-schema';

import { LocalStore } from './localStore';

export type PermissionLevel = `${PermissionLevelBase}`;

export type Permission = Exclude<keyof ViewerPermissionsBase, '__typename'>;

export type ViewerPermissions = Record<Permission, PermissionLevel>;

export type ViewerPermissionsUnknown = Record<
  Permission,
  Maybe<PermissionLevel> | undefined | null | FutureAddedValue
>;

export const viewerPermissionsOverrides = LocalStore<
  Partial<ViewerPermissions>
>('bfly:permissionsOverrides');

export const PERMISSIONS_LEVEL_VALUE = {
  NONE: 0,
  BASIC: 50,
  ADMIN: 100,
};

export const NONE_PERMISSIONS: ViewerPermissions = {
  accessAllOrganizations: 'NONE',
  archiveManagement: 'NONE',
  bulkDataExport: 'NONE',
  connectivityManagement: 'NONE',
  credentialManagement: 'NONE',
  dataDeletion: 'NONE',
  dataExport: 'NONE',
  educationManagement: 'NONE',
  examTypeManagement: 'NONE',
  fleetManagement: 'NONE',
  iqCareRestrictedExperience: 'NONE',
  loginAndSecurityManagement: 'NONE',
  organizationManagement: 'NONE',
  qa: 'NONE',
  qaManagement: 'NONE',
  signing: 'NONE',
  studyDocumentation: 'NONE',
  studyDocumentationManagement: 'NONE',
  studyReversion: 'NONE',
  userManagement: 'NONE',
  userPermissions: 'NONE',
};

/**
 * Every permission function eventually calls this function to check if the user has the requested permission level
 * This is the only place we look for permission overrides
 */
export const hasPermissionLevel = (
  permissions: null | undefined | Record<string, any>,
  permission: Permission,
  requestedLevel: PermissionLevel = 'BASIC',
) => {
  const permissionsOverrides = viewerPermissionsOverrides.get()[permission];

  const userPermissionLevel =
    permissionsOverrides || permissions?.[permission];

  return !!(
    userPermissionLevel &&
    PERMISSIONS_LEVEL_VALUE[userPermissionLevel] >=
      PERMISSIONS_LEVEL_VALUE[requestedLevel]
  );
};

export function constructPermissions(
  permissionsBase: ViewerPermissionsUnknown,
): ViewerPermissions {
  return Object.entries(permissionsBase).reduce(
    (agg, [key, value]) => {
      if (
        Object.keys(NONE_PERMISSIONS).includes(key) &&
        value &&
        !value.startsWith('%')
      ) {
        agg[key] = value;
      }
      return agg;
    },
    { ...NONE_PERMISSIONS },
  );
}
