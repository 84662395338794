/**
 * @generated SignedSource<<684dadde7339b951e0cdc9c0f1ea6869>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyHistoryModalContent_qaSubmittedStudyEvent$data = {
  readonly createdAt: string | null;
  readonly createdBy: {
    readonly name: string | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "StudyHistoryModalContent_qaSubmittedStudyEvent";
};
export type StudyHistoryModalContent_qaSubmittedStudyEvent$key = {
  readonly " $data"?: StudyHistoryModalContent_qaSubmittedStudyEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyHistoryModalContent_qaSubmittedStudyEvent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyHistoryModalContent_qaSubmittedStudyEvent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QaSubmittedStudyEvent",
  "abstractKey": null
};

(node as any).hash = "ea7cfdcd1eacbc565366d07cbd9f1df5";

export default node;
