/**
 * @generated SignedSource<<af93d32b73bbfd774b1f5c971468a310>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainRoles_UpdateDomainRolePage_Query$variables = {
  membershipRoleId: string;
};
export type domainRoles_UpdateDomainRolePage_Query$data = {
  readonly membershipRole: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdateDomainRolePage_membershipRole">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdateDomainRolePage_viewer">;
  } | null;
};
export type domainRoles_UpdateDomainRolePage_Query = {
  response: domainRoles_UpdateDomainRolePage_Query$data;
  variables: domainRoles_UpdateDomainRolePage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "membershipRoleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "membershipRoleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domainRoles_UpdateDomainRolePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateDomainRolePage_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "membershipRole",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateDomainRolePage_membershipRole"
              }
            ],
            "type": "MembershipRole",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domainRoles_UpdateDomainRolePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "membershipRole",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permissions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roleType",
                "storageKey": null
              }
            ],
            "type": "MembershipRole",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50f25b3090242e2dfd5afce7f78a71c1",
    "id": null,
    "metadata": {},
    "name": "domainRoles_UpdateDomainRolePage_Query",
    "operationKind": "query",
    "text": "query domainRoles_UpdateDomainRolePage_Query(\n  $membershipRoleId: ID!\n) {\n  viewer {\n    ...UpdateDomainRolePage_viewer\n    id\n  }\n  membershipRole: node(id: $membershipRoleId) {\n    __typename\n    ... on MembershipRole {\n      ...UpdateDomainRolePage_membershipRole\n    }\n    id\n  }\n}\n\nfragment DomainRoleDeleteFieldSet_membershipRole on MembershipRole {\n  id\n  name\n}\n\nfragment DomainRoleDeleteFieldSet_viewer on Viewer {\n  domain {\n    id\n  }\n}\n\nfragment UpdateDomainRolePage_membershipRole on MembershipRole {\n  ...roles_defaultValue\n  ...DomainRoleDeleteFieldSet_membershipRole\n  roleType\n}\n\nfragment UpdateDomainRolePage_viewer on Viewer {\n  ...DomainRoleDeleteFieldSet_viewer\n}\n\nfragment roles_defaultValue on MembershipRole {\n  id\n  name\n  description\n  permissions\n}\n"
  }
};
})();

(node as any).hash = "aba59bd24fc65e568f3224438197efca";

export default node;
