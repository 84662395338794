import { useRouter } from 'found';
import { useEffect } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import BaseExamNav from 'components/ExamNav';
import { useExamRoutes } from 'routes/exam';

import { examPageTopNavbar_next_prev_search_Query$data as SearchStyleQuery } from '../utils/__generated__/examPageTopNavbar_next_prev_search_Query.graphql';
import { examPageTopNavbar_next_prev_storage_Query$data as StorageStyleQuery } from '../utils/__generated__/examPageTopNavbar_next_prev_storage_Query.graphql';
import { getMetaForTopNavbarQuery } from '../utils/examPageTopNavbar';
import { useExamSetterContext } from './ExamContext';
import HeaderPatientInfo from './HeaderPatientInfo';
import { ExamNav_study$data as Study } from './__generated__/ExamNav_study.graphql';

interface Props {
  tenant: SearchStyleQuery['tenant'] | StorageStyleQuery['tenant'];
  study: Study;
}

const ExamNav = ({ tenant, study }: Props) => {
  const { match } = useRouter();
  const { location } = match;
  const examRoutes = useExamRoutes();
  const { studyListNavConfig, cursor } = location.state || {};
  const { setNavbarMeta } = useExamSetterContext();

  const navbarMeta = getMetaForTopNavbarQuery(tenant);

  const {
    loading,
    prevExamHandle,
    nextExamHandle,
    prevExamCursor,
    nextExamCursor,
    nextExamOrgSlug,
    prevExamOrgSlug,
  } = navbarMeta;

  const hasPrev =
    cursor && prevExamHandle && prevExamCursor && prevExamOrgSlug;
  const hasNext =
    cursor && nextExamHandle && nextExamCursor && nextExamOrgSlug;

  useEffect(() => {
    setNavbarMeta(navbarMeta);
  }, [navbarMeta, setNavbarMeta]);

  return (
    <BaseExamNav
      loading={loading}
      prev={
        hasPrev
          ? {
              pathname: examRoutes.exam({
                organizationSlug: prevExamOrgSlug!,
                studyHandle: prevExamHandle!,
              }),
              state: {
                studyListNavConfig,
                cursor: prevExamCursor,
              },
            }
          : undefined
      }
      next={
        hasNext
          ? {
              pathname: examRoutes.exam({
                organizationSlug: nextExamOrgSlug!,
                studyHandle: nextExamHandle!,
              }),
              state: {
                studyListNavConfig,
                cursor: nextExamCursor,
              },
            }
          : undefined
      }
    >
      <HeaderPatientInfo study={study} />
    </BaseExamNav>
  );
};

export default createFragmentContainer(ExamNav, {
  study: graphql`
    fragment ExamNav_study on Study {
      ...HeaderPatientInfo_study
    }
  `,
});
