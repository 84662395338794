import { UserPermission } from '@bfly/olympus-protobuf';

const { Bucket } = UserPermission;

export function validateBucketsDependencies(nextValue, updatedBucket) {
  let { permissions } = nextValue;
  const isStudyDocumentationUpdated =
    updatedBucket === `permissions.${Bucket.STUDY_DOCUMENTATION}`;
  const isStudyDocumentationManagmentUpdated =
    updatedBucket === `permissions.${Bucket.STUDY_DOCUMENTATION_MANAGEMENT}`;
  if (
    isStudyDocumentationUpdated &&
    permissions[Bucket.STUDY_DOCUMENTATION] === 0
  ) {
    permissions = {
      ...permissions,
      [Bucket.STUDY_DOCUMENTATION_MANAGEMENT]: 0,
    };
  }

  if (
    isStudyDocumentationManagmentUpdated &&
    permissions[Bucket.STUDY_DOCUMENTATION_MANAGEMENT] !== 0
  ) {
    permissions = {
      ...permissions,
      [UserPermission.Bucket.STUDY_DOCUMENTATION]: 50,
    };
  }

  return {
    ...nextValue,
    permissions: { ...permissions },
  };
}
