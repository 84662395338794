/**
 * @generated SignedSource<<b5bc1117adfa17031c7b3bc5c08268b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type auditLogs_checkIsDomainAdmin_Query$variables = {};
export type auditLogs_checkIsDomainAdmin_Query$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_tenant">;
  } | null;
};
export type auditLogs_checkIsDomainAdmin_Query = {
  response: auditLogs_checkIsDomainAdmin_Query$data;
  variables: auditLogs_checkIsDomainAdmin_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdomainLabel",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "auditLogs_checkIsDomainAdmin_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "RouteAccessControl_tenant",
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "auditLogs_checkIsDomainAdmin_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c416a3733c494cb9de5fbdc40197a73",
    "id": null,
    "metadata": {},
    "name": "auditLogs_checkIsDomainAdmin_Query",
    "operationKind": "query",
    "text": "query auditLogs_checkIsDomainAdmin_Query {\n  tenant {\n    __typename\n    ...RouteAccessControl_tenant\n    id\n  }\n}\n\nfragment RouteAccessControl_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    subdomainLabel\n  }\n  ... on Organization {\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a20bcb4943ac04a9adfe172c21362af";

export default node;
