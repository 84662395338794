import Learn from '@bfly/icons/Learn';
import Members from '@bfly/icons/Members';
import ScanLabIcon from '@bfly/icons/ScanLab';
import ScanLabReviewIcon from '@bfly/icons/ScanlabReview';
import Caret from '@bfly/ui2/Caret';
import Dropdown from '@bfly/ui2/Dropdown';
import Link from '@bfly/ui2/Link';
import Navbar from '@bfly/ui2/Navbar';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import useExperiences, { Experience } from 'hooks/useExperiences';
import { useAnalyticsRoutes } from 'routes/analytics';
import { routes as educationRoutes } from 'routes/education';
import { routes as scanlabRoutes } from 'routes/scanLab';
import { useCanAccessEduDashboard } from 'utils/canAccessEduDashboard';
import { useCanAccessScanLab } from 'utils/canAccessScanLab';
import { useViewerAllowMissingContext } from 'utils/viewerContext';

import { useAdvancedAcademyFeaturesWithoutLdContext } from '../../routes/education/components/utils/useAdvancedAcademyFeatures';
import { useButterflyAcademyAccessWithoutLdContext } from '../../routes/education/components/utils/useButterflyAcademyAccess';
import educationMessages from '../../routes/education/messages/educationMessages';
import { STUDIES_MESSAGES } from '../../routes/scanLab/messages';
import { useVariationAllowMissingLdContext } from './LaunchDarklyContext';
import { useActiveRoute } from './isRouteActive';

function AnalyticsNavItem() {
  const isRouteActive = useActiveRoute();

  const analyticRoutes = useAnalyticsRoutes();
  const isAnalyticsActive = isRouteActive(analyticRoutes.analytics(), {
    exact: false,
  });

  const canUseProficiency = useVariationAllowMissingLdContext(
    'credentialing-analytics-page',
  );
  const DropdownNavMessage = (
    <>
      <FormattedMessage
        id="appProductNav.analytics"
        defaultMessage="Analytics"
      />
      <Caret />
    </>
  );

  if (canUseProficiency)
    return (
      <Dropdown data-bni-id="MultipleAnalyticsNav">
        <Dropdown.Toggle as={Navbar.NavItem} active={isAnalyticsActive}>
          {DropdownNavMessage}
        </Dropdown.Toggle>

        <Dropdown.Menu variant="dark">
          <Link to={analyticRoutes.stats()}>
            {(linkProps) => (
              <Dropdown.Item {...linkProps} data-bni-id="AnalyticsStats">
                <FormattedMessage
                  id="appProductNavAnalytics.stats"
                  defaultMessage="Stats"
                />
              </Dropdown.Item>
            )}
          </Link>
          <Link to={analyticRoutes.proficiency()}>
            {(linkProps) => (
              <Dropdown.Item {...linkProps} data-bni-id="AnalyticsProficiency">
                <FormattedMessage
                  id="appProductNavAnalytics.proficiency"
                  defaultMessage="Proficiency dashboard"
                />
              </Dropdown.Item>
            )}
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    );

  return (
    <Link to={analyticRoutes.analytics()}>
      {(linkProps) => (
        <Navbar.NavItem data-bni-id="AnalyticsNav" {...linkProps}>
          <FormattedMessage
            id="appProductNav.analytics"
            defaultMessage="Analytics"
          />
        </Navbar.NavItem>
      )}
    </Link>
  );
}

function EducationNavItem({
  active,
  label,
}: {
  active: boolean;
  label: ReactNode;
}) {
  const viewer = useViewerAllowMissingContext();
  const canAccessEduDashboard = useCanAccessEduDashboard();
  const {
    portfolio: canAccessScanLabPortfolio,
    review: canAccessScanLabReview,
  } = useCanAccessScanLab();
  const canAccessScanLab = canAccessScanLabPortfolio || canAccessScanLabReview;
  const butterflyAcademyAccess = useButterflyAcademyAccessWithoutLdContext();
  const advancedAcademyFeatures = useAdvancedAcademyFeaturesWithoutLdContext();

  const { markCompleted, targetRef } = useExperiences(
    Experience.VIEW_EDU_PAGE,
    {
      fixed: true,
      header: (
        <FormattedMessage
          id="educationPage.tooltip.header"
          defaultMessage="Master your Butterfly iQ"
        />
      ),
      message: (
        <FormattedMessage
          id="educationPage.tooltip.body"
          defaultMessage="Browse our collection of instructional videos"
        />
      ),
    },
  );

  if (!canAccessScanLab || !viewer)
    return (
      <div ref={targetRef}>
        <Link
          onClick={markCompleted}
          to={educationRoutes.education()}
          as={Navbar.NavItem}
          data-bni-id="Education"
          active={active}
        >
          {label}
        </Link>
      </div>
    );

  return (
    <div ref={targetRef}>
      <Dropdown placement="bottom-end" data-bni-id="Education">
        <Dropdown.Toggle
          id="education-dropdown"
          onClick={markCompleted}
          as={Navbar.NavItem}
          active={active}
        >
          {label}
          <Caret />
        </Dropdown.Toggle>
        <Dropdown.Menu
          variant="dark"
          data-bni-id="EducationDropdownMenu"
          className="min-w-auto"
          placement="bottom-start"
        >
          {canAccessEduDashboard &&
            butterflyAcademyAccess === 'enabled' &&
            advancedAcademyFeatures && (
              <Link to={educationRoutes.dashboardCourseOverview()}>
                {({ active: _, ...rest }) => (
                  <Dropdown.Item
                    {...rest}
                    data-bni-id="EducationalDashboard"
                    className="flex items-center "
                  >
                    <Members width={18} className="mr-4" />
                    <FormattedMessage
                      {...educationMessages.educationalDashboard}
                    />
                  </Dropdown.Item>
                )}
              </Link>
            )}
          {canAccessScanLabPortfolio && (
            <Link to={scanlabRoutes.portfolio()}>
              {({ active: _, ...rest }) => (
                <Dropdown.Item
                  {...rest}
                  data-bni-id="ScanLabPortfolio"
                  className="flex items-center "
                >
                  <ScanLabIcon width={18} className="mr-4" />
                  <FormattedMessage {...STUDIES_MESSAGES.portfolio.title} />
                </Dropdown.Item>
              )}
            </Link>
          )}
          {canAccessScanLabReview && (
            <Link to={scanlabRoutes.review()}>
              {({ active: _, ...rest }) => (
                <Dropdown.Item
                  {...rest}
                  data-bni-id="ScanLabReview"
                  className="flex items-center "
                >
                  <ScanLabReviewIcon
                    key="AppProductNav-ScanLabReviewIcon"
                    width={18}
                    className="mr-4"
                  />
                  <FormattedMessage {...STUDIES_MESSAGES.review.title} />
                </Dropdown.Item>
              )}
            </Link>
          )}
          <Link to={educationRoutes.education()}>
            {({ active: _, ...rest }) => (
              <Dropdown.Item
                {...rest}
                data-bni-id="EducationalContent"
                className="flex items-center "
              >
                <Learn width={18} className="mr-4" />
                <FormattedMessage {...educationMessages.educationalContent} />
              </Dropdown.Item>
            )}
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

/**
 * Displays "Cloud," "Education," and "TeleGuidance" links
 * on the top navbar of the web app if the user is accessing
 * the app on a large enough browser and not in a web view of
 * the mobile app. TeleGuidance access requires an unexpired membership.
 *
 * If the user is logged out, all links are still displayed when on
 * the Education page. The Education page can be viewed without
 * logging in. Visiting "Cloud" or "TeleGuidance" redirects to the
 * login page.
 */
export default function AppProductNav() {
  const viewer = useViewerAllowMissingContext();

  const isRouteActive = useActiveRoute();
  const canUseTelemed = !!viewer?.hasUnexpiredMembership || !viewer;

  const isEduActive =
    isRouteActive(educationRoutes.education(), { exact: false }) ||
    isRouteActive(scanlabRoutes.portfolio(), { exact: false }) ||
    isRouteActive(scanlabRoutes.review(), { exact: false });

  const canUseReviews = useVariationAllowMissingLdContext('worksheets-review');

  const showAnalytics = canUseReviews && !!viewer && !isEduActive;

  return (
    <>
      {showAnalytics ? (
        <AnalyticsNavItem />
      ) : (
        <Link
          to="/"
          as={Navbar.NavItem}
          active={!isEduActive}
          data-bni-id="Cloud"
        >
          <FormattedMessage id="appProductNav.cloud" defaultMessage="Cloud" />
        </Link>
      )}
      <EducationNavItem
        label={
          <FormattedMessage
            id="appProductNav.education"
            defaultMessage="Education"
          />
        }
        active={isEduActive}
      />
      {canUseTelemed && (
        <Navbar.NavItem href="/-/telemed">
          <FormattedMessage
            id="appProductNav.teleguidance"
            defaultMessage="TeleGuidance"
          />
        </Navbar.NavItem>
      )}
    </>
  );
}
