import loadScript from './utils/loadScript';

/**
 * Wrap polyfill.io script in a promise so that it runs prior to rendering the app.
 * This also does some client-side detection before making the script request.
 *
 * We are leveraging https://github.com/Financial-Times/polyfill-library's detect scripts
 * for the client-side detection.
 * See https://github.com/Financial-Times/polyfill-library/blob/master/polyfills/IntersectionObserver/detect.js
 * for an example of the detect scripts that we've copied here.
 *
 * TODO: move this whole check into a webpack-loader that uses polyfill-library under the hood
 * Can leverage https://github.com/garygreen/polyfill-io-loader or https://github.com/SebastianS90/webpack-polyfill-injector
 */

const SCRIPT = 'https://polyfill.io/v3/polyfill.min.js';

const FEATURES = {
  IntersectionObserver: () =>
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    // @ts-ignore
    'intersectionRatio' in window.IntersectionObserverEntry.prototype,

  IntersectionObserverEntry: () =>
    'IntersectionObserverEntry' in window &&
    // @ts-ignore
    'isIntersecting' in window.IntersectionObserverEntry.prototype,

  'HTMLCanvasElement.prototype.toBlob': () =>
    'toBlob' in HTMLCanvasElement.prototype,

  ResizeObserver: () => 'ResizeObserver' in window,
};

export default async function loadPolyfillIo() {
  const missingFeatures = Object.entries(FEATURES)
    .filter(([_, detect]) => !detect())
    .map(([feature]) => feature);

  // No features so don't make the request.
  if (!missingFeatures.length) {
    return;
  }

  // Intentionally not using URLSearchParams to maintain the same level of bad
  // Edge support as before, where it only breaks when you get deeper in the
  // app.
  await loadScript(
    `${SCRIPT}?features=${encodeURIComponent(missingFeatures.join(','))}`,
  );
}
