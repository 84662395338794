import Button, { ButtonProps } from '@bfly/ui2/Button';
import type { Placement } from '@bfly/ui2/Popover';
import SrOnly from '@bfly/ui2/SrOnly';
import Tooltip, { TooltipProps } from '@bfly/ui2/Tooltip';
import {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  ReactElement,
  ReactNode,
  RefAttributes,
  SVGProps,
  isValidElement,
} from 'react';

type SVG = ForwardRefExoticComponent<
  PropsWithoutRef<SVGProps<SVGSVGElement>> & RefAttributes<SVGSVGElement>
>;

export interface IconButtonProps extends Omit<ButtonProps, 'title'> {
  icon: SVG | ReactElement;
  title: ReactNode;
  children?: ReactNode;
  placement?: Placement;
  titleSrOnly?: boolean;
  tooltipVariant?: TooltipProps['variant'];
}

function IconButton({
  title,
  titleSrOnly,
  icon: Icon,
  children,
  tooltipVariant,
  placement = 'bottom',
  ...props
}: IconButtonProps) {
  const tooltip = title || children;

  return (
    <Tooltip.Trigger
      placement={placement}
      variant={tooltipVariant}
      tooltip={tooltip}
      show={titleSrOnly ? false : undefined}
    >
      <Button variant="text-secondary" className="px-2" {...props}>
        {isValidElement(Icon) ? Icon : <Icon />}
        {titleSrOnly && <SrOnly>{tooltip}</SrOnly>}
      </Button>
    </Tooltip.Trigger>
  );
}

export default IconButton;
