/**
 * @generated SignedSource<<55da25b9b47f927f2062d523ecbbce55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalStudySearchSubNav_tenant$data = {
  readonly handle?: string | null;
  readonly id: string;
  readonly name?: string | null;
  readonly slug?: string | null;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreateSavedSearchModal_tenant" | "GlobalStudySearchAdvanced_tenant" | "useSearchState_tenant">;
  readonly " $fragmentType": "GlobalStudySearchSubNav_tenant";
};
export type GlobalStudySearchSubNav_tenant$key = {
  readonly " $data"?: GlobalStudySearchSubNav_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchSubNav_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalStudySearchSubNav_tenant",
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "type": "Organization",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GlobalStudySearchAdvanced_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateSavedSearchModal_tenant"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useSearchState_tenant",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "Organization",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};
})();

(node as any).hash = "27537c84f31549017d49d13f42292b83";

export default node;
