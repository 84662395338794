/**
 * @generated SignedSource<<9f710fd306b4ba7a3e95165c49c5e679>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dicomFieldTemplates_checkIsDomainAdmin_Query$variables = {};
export type dicomFieldTemplates_checkIsDomainAdmin_Query$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_tenant">;
  } | null;
};
export type dicomFieldTemplates_checkIsDomainAdmin_Query = {
  response: dicomFieldTemplates_checkIsDomainAdmin_Query$data;
  variables: dicomFieldTemplates_checkIsDomainAdmin_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdomainLabel",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dicomFieldTemplates_checkIsDomainAdmin_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "RouteAccessControl_tenant",
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dicomFieldTemplates_checkIsDomainAdmin_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c224576474e55b0c46fe83bf4882f8b",
    "id": null,
    "metadata": {},
    "name": "dicomFieldTemplates_checkIsDomainAdmin_Query",
    "operationKind": "query",
    "text": "query dicomFieldTemplates_checkIsDomainAdmin_Query {\n  tenant {\n    __typename\n    ...RouteAccessControl_tenant\n    id\n  }\n}\n\nfragment RouteAccessControl_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    subdomainLabel\n  }\n  ... on Organization {\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "bfda9c32dff35313404a7e17d49b4a08";

export default node;
