import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import StudyTitle from 'components/StudyTitle';
import actionMessages from 'messages/actions';

import { SplitStudyConfirmDeleteStudy_destinationStudy$data as DestinationStudy } from './__generated__/SplitStudyConfirmDeleteStudy_destinationStudy.graphql';
import { SplitStudyConfirmDeleteStudy_sourceStudy$data as SourceStudy } from './__generated__/SplitStudyConfirmDeleteStudy_sourceStudy.graphql';

interface Props {
  sourceStudy: SourceStudy;
  destinationStudy?: DestinationStudy;
  destinationIsNewDraft: boolean;
  onBack: () => void;
}

function SplitStudyConfirmDeleteStudy({
  sourceStudy,
  destinationStudy,
  destinationIsNewDraft,
  onBack,
}: Props) {
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="splitStudy.splitStudyConfirmDeleteStudy.title"
            defaultMessage="Move content and delete current exam?"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text>
          <FormattedMessage
            id="splitStudy.splitStudyConfirmDeleteStudy.body"
            defaultMessage="Are you sure you want to move all captures and worksheets to another exam? This will permanently delete the original exam and any associated patient information. "
          />
          {!destinationIsNewDraft && destinationStudy ? (
            <FormattedMessage
              id="splitStudy.splitStudyConfirmDeleteStudy.body.existing"
              defaultMessage="<b>{numImages} {numImages, plural,
              one {capture}
              other {captures}
            }</b> and <b>{numWorksheets} {numWorksheets, plural,
              one {worksheet}
              other {worksheets}
            }</b> will be moved to <b>{destinationStudy}</b> and current exam <b>{sourceStudy}</b> will be deleted."
              values={{
                numImages: sourceStudy.numImages,
                numWorksheets: sourceStudy.worksheets?.length,
                destinationStudy: <StudyTitle study={destinationStudy} />,
                sourceStudy: <StudyTitle study={sourceStudy} />,
                b: (msg) => <Text variant="body-bold">{msg}</Text>,
              }}
            />
          ) : (
            <FormattedMessage
              id="splitStudy.splitStudyConfirmDeleteStudy.body.new"
              defaultMessage="<b>{numImages} {numImages, plural,
              one {capture}
              other {captures}
            }</b> and <b>{numWorksheets} {numWorksheets, plural,
              one {worksheet}
              other {worksheets}
            }</b> will be moved to a new draft in this archive and current exam <b>{sourceStudy}</b> will be deleted."
              values={{
                numImages: sourceStudy.numImages,
                numWorksheets: sourceStudy.worksheets?.length,
                sourceStudy: <StudyTitle study={sourceStudy} />,
                b: (msg) => <Text variant="body-bold">{msg}</Text>,
              }}
            />
          )}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Form.Submit variant="primary" size="lg">
            <FormattedMessage {...actionMessages.confirm} />
          </Form.Submit>
          <Button size="lg" variant="secondary" onClick={onBack}>
            <FormattedMessage {...actionMessages.back} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(SplitStudyConfirmDeleteStudy, {
  sourceStudy: graphql`
    fragment SplitStudyConfirmDeleteStudy_sourceStudy on Study {
      ...StudyTitle_study
      numImages
      worksheets {
        handle
      }
    }
  `,
  destinationStudy: graphql`
    fragment SplitStudyConfirmDeleteStudy_destinationStudy on Study {
      ...StudyTitle_study
    }
  `,
});
