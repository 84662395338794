/**
 * @generated SignedSource<<e86bab50b80bddea5ff7d6561ce518b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssociateExamWorklistItemModalContent_primaryWorklist$data = {
  readonly id?: string;
  readonly name: string | null;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly refreshJob?: {
    readonly completedAt: string | null;
  } | null;
  readonly type: string;
  readonly worklistItemConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accessionNumber: string | null;
        readonly id?: string;
        readonly visitEndDate?: string | null;
        readonly visitLocation?: string | null;
        readonly visitNumber?: string | null;
        readonly visitStartDate?: string | null;
        readonly visitTypes?: ReadonlyArray<string | null> | null;
        readonly " $fragmentSpreads": FragmentRefs<"PatientInfo_patient" | "WorklistItemDicomDocument_worklistItem" | "WorklistItemHeader_worklistItem" | "baseStudyInfo_selectedWorklistItemData">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RefreshModalityWorklistControl_modalityWorklist" | "WorklistSearchBar_worklist" | "WorklistSyncStatus_modalityWorklist">;
  readonly " $fragmentType": "AssociateExamWorklistItemModalContent_primaryWorklist";
};
export type AssociateExamWorklistItemModalContent_primaryWorklist$key = {
  readonly " $data"?: AssociateExamWorklistItemModalContent_primaryWorklist$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssociateExamWorklistItemModalContent_primaryWorklist">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "InlineFragment",
  "selections": (v1/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessionNumber",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 34,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isDischarged"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "worklistItemConnection"
        ]
      }
    ]
  },
  "name": "AssociateExamWorklistItemModalContent_primaryWorklist",
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorklistSearchBar_worklist"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ModalityWorklistRefreshJob",
          "kind": "LinkedField",
          "name": "refreshJob",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RefreshModalityWorklistControl_modalityWorklist"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorklistSyncStatus_modalityWorklist"
        }
      ],
      "type": "ModalityWorklist",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "worklistItemConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "isDischarged",
          "variableName": "isDischarged"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "WorklistItemInterfaceConnection",
      "kind": "LinkedField",
      "name": "__Worklist_worklistItemConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorklistItemInterfaceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visitTypes",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visitStartDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visitEndDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visitLocation",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visitNumber",
                      "storageKey": null
                    }
                  ],
                  "type": "FhirEncounter",
                  "abstractKey": null
                },
                (v3/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WorklistItemHeader_worklistItem"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WorklistItemDicomDocument_worklistItem"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PatientInfo_patient"
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "baseStudyInfo_selectedWorklistItemData",
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "studyDescription",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "internalId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dicomDocument",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nameFirst",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nameLast",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nameMiddle",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "namePrefix",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nameSuffix",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sex",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "birthDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "medicalRecordNumber",
                          "storageKey": null
                        }
                      ],
                      "type": "PatientInterface",
                      "abstractKey": "__isPatientInterface"
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorklistInterface",
  "abstractKey": "__isWorklistInterface"
};
})();

(node as any).hash = "cd9f6180f6a3cf5474649270ccbbb09b";

export default node;
