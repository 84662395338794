import Link from '@bfly/ui2/Link';
import PreviewItem from '@bfly/ui2/PreviewItem';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { useExamRoutes } from 'routes/exam';
import type { StudyListNavConfig } from 'utils/StudyListNavUtils';

import StudyImageThumbnail from './StudyImageThumbnail';
import type { ImagePreviewItem_image$data as Image } from './__generated__/ImagePreviewItem_image.graphql';
import type { ImagePreviewItem_study$data as Study } from './__generated__/ImagePreviewItem_study.graphql';

interface Props {
  study: Study | null;
  image: Image;
  index: number;
  studyListNavConfig?: StudyListNavConfig;
  active?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  studyCursor?: string;
}

function ImagePreviewItem({
  study,
  image,
  index,
  studyListNavConfig,
  active,
  onClick,
  studyCursor,
}: Props) {
  const examRoutes = useExamRoutes();
  // TODO: Linkify the preview item for shared bundle images as well.
  const asProps = study && {
    as: Link,
    to: {
      pathname: examRoutes.examImage({
        organizationSlug: study.organization!.slug!,
        studyHandle: study!.handle!,
        studyImageHandle: image!.handle!,
      }),
      state: { studyListNavConfig, cursor: studyCursor },
    },
  };

  return (
    <PreviewItem
      {...asProps}
      active={active}
      onClick={onClick}
      data-bni-id="ImagePreviewItem"
    >
      <StudyImageThumbnail
        index={index}
        image={image.burnedInFile!}
        numComments={image.numComments || 0}
      />
    </PreviewItem>
  );
}

export default createFragmentContainer(ImagePreviewItem, {
  study: graphql`
    fragment ImagePreviewItem_study on Study {
      organization {
        slug
      }
      handle
      archive {
        handle
      }
    }
  `,
  image: graphql`
    fragment ImagePreviewItem_image on StudyImageInterface {
      ... on StudyImage {
        handle
        numComments
      }
      burnedInFile {
        ...StudyImageThumbnail_image
      }
    }
  `,
});
