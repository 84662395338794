/**
 * @generated SignedSource<<03d3f4261118efc7b67631ad30911f23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedUserAvatars_displayUser$data = {
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile" | "RelatedUsersPopoverListItems_users">;
  readonly " $fragmentType": "RelatedUserAvatars_displayUser";
};
export type RelatedUserAvatars_displayUser$key = {
  readonly " $data"?: RelatedUserAvatars_displayUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedUserAvatars_displayUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RelatedUserAvatars_displayUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_userProfile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RelatedUsersPopoverListItems_users"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "f46d79b4db24ec6dbbebd00af5ed0b76";

export default node;
