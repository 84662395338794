import { graphql, readInlineData } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';

import { useSubscriptionInfo_organization$key as OrganizationKey } from './__generated__/useSubscriptionInfo_organization.graphql';

export type OrganizationRef = OrganizationKey;

export default function useSubscriptionInfo(organizationRef: OrganizationRef) {
  const organization = readInlineData<OrganizationKey>(
    graphql`
      fragment useSubscriptionInfo_organization on Organization @inline {
        subscription {
          canAccessProFeatures
          isInGracePeriod
          planType
        }
      }
    `,
    organizationRef,
  );

  const { subscription } = organization!;
  const { isInGracePeriod = false, canAccessProFeatures = false } =
    subscription || {};

  const isForever = subscription?.planType === 'INDIVIDUAL_FOREVER';
  const isPro = !isForever && canAccessProFeatures && !isInGracePeriod;
  const isBasic = !(isForever || isPro);
  const hasStudySharing = useVariation('study-sharing');

  return {
    isForever,
    isPro,
    isBasic,
    hasStudySharing,
  };
}
