/**
 * @generated SignedSource<<a1af8aed5df29ec768d74cc120598f97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PatientPopoverContent_study$data = {
  readonly dicomReferringPhysician: string | null;
  readonly dicomRequestingPhysician: string | null;
  readonly finalizedAt: string | null;
  readonly hasAssociatedWorklistItem: boolean | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly primaryWorklist: {
      readonly id?: string;
      readonly type: string;
    } | null;
  } | null;
  readonly practiceType: StudyPracticeType | null;
  readonly vetPatient: {
    readonly " $fragmentSpreads": FragmentRefs<"VetClientDescriptionList_vetPatient" | "VetPatientDescriptionList_vetPatient">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PatientDescriptionList_study">;
  readonly " $fragmentType": "PatientPopoverContent_study";
};
export type PatientPopoverContent_study$key = {
  readonly " $data"?: PatientPopoverContent_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"PatientPopoverContent_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PatientPopoverContent_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomReferringPhysician",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomRequestingPhysician",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAssociatedWorklistItem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "primaryWorklist",
          "plural": false,
          "selections": [
            {
              "alias": "type",
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/)
              ],
              "type": "Node",
              "abstractKey": "__isNode"
            }
          ],
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VetClientDescriptionList_vetPatient"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VetPatientDescriptionList_vetPatient"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PatientDescriptionList_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "cbaffb62eaa54de1604e2b79d3db1655";

export default node;
