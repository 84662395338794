import Layout from '@4c/layout';
import BackButton from '@bfly/ui2/BackButton';
import Form from '@bfly/ui2/Form';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import { Link } from 'found';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PageTitle from 'components/PageTitle';
import RelayForm from 'components/RelayForm';
import { useAdminRoutes } from 'routes/admin';

import roleSchema, {
  deserialize,
  serialize,
  updateRoleSchema,
} from '../schemas/roles';
import { validateBucketsDependencies } from '../utils/validateBucketsDependencies';
import DomainRoleDeleteFieldSet from './DomainRoleDeleteFieldSet';
import DomainRoleDetailFieldSet from './DomainRoleDetailsFieldSet';
import DomainRolePermissionsFieldSet from './DomainRolePermissionsFieldSet';
import { UpdateDomainRolePage_UpdateRoleMutation as UpdateRoleMutation } from './__generated__/UpdateDomainRolePage_UpdateRoleMutation.graphql';
import { UpdateDomainRolePage_membershipRole$data as MembershipRole } from './__generated__/UpdateDomainRolePage_membershipRole.graphql';
import { UpdateDomainRolePage_viewer$data as Viewer } from './__generated__/UpdateDomainRolePage_viewer.graphql';

const messages = defineMessages({
  header: {
    id: 'domain.roles.updateRole',
    defaultMessage: 'Edit Butterfly Access Role',
  },
});

const mutation = graphql`
  mutation UpdateDomainRolePage_UpdateRoleMutation(
    $input: UpdateMembershipRoleInput!
  ) {
    updateMembershipRoleOrError(input: $input) {
      ... on UpdateMembershipRolePayload {
        membershipRole {
          ...UpdateDomainRolePage_membershipRole
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface Props {
  membershipRole: MembershipRole;
  viewer: Viewer;
}

function UpdateDomainRolePage({ membershipRole, viewer }: Props) {
  const routes = useAdminRoutes();

  const { membershipRoleId, ...membershipRoleData } = useMemo(
    () => deserialize(membershipRole),
    [membershipRole],
  );
  const isUserDefinedRole = membershipRole.roleType === 'USER_DEFINED';

  const [value, setValue] = useState(membershipRoleData);
  const handleChange = (nextValue, [updatedField]) => {
    const newValue = validateBucketsDependencies(nextValue, updatedField);
    setValue(newValue);
  };

  return (
    <Page.Container>
      <MainContent>
        <Layout pad={4} className="items-center mb-10">
          <Link to={routes.domainRoles()}>
            <BackButton />
          </Link>
          <PageTitle title={messages.header} />
        </Layout>
        <RelayForm<UpdateRoleMutation, typeof roleSchema>
          horizontal
          as={null}
          schema={updateRoleSchema}
          defaultValue={membershipRoleData as any}
          value={value}
          mutation={mutation}
          getInput={(input) => {
            return {
              membershipRoleId,
              ...serialize(input),
            };
          }}
          onChange={handleChange}
          successMessage={
            <FormattedMessage
              id="UpdateDomainRole.permission.success"
              defaultMessage="Butterfly Access Role updated"
            />
          }
        >
          <>
            <DomainRoleDetailFieldSet />
            <DomainRolePermissionsFieldSet />
            <Layout reverse>
              <Form.Submit data-bni-id="UpdateRoleSubmitButton">
                <FormattedMessage
                  id="domainRoleUpdateRolePage.saveButton"
                  defaultMessage="Save"
                />
              </Form.Submit>
            </Layout>
          </>
        </RelayForm>
        {isUserDefinedRole && (
          <DomainRoleDeleteFieldSet
            membershipRole={membershipRole}
            viewer={viewer}
          />
        )}
      </MainContent>
    </Page.Container>
  );
}

export default createFragmentContainer(UpdateDomainRolePage, {
  membershipRole: graphql`
    fragment UpdateDomainRolePage_membershipRole on MembershipRole {
      ...roles_defaultValue
      ...DomainRoleDeleteFieldSet_membershipRole
      roleType
    }
  `,
  viewer: graphql`
    fragment UpdateDomainRolePage_viewer on Viewer {
      ...DomainRoleDeleteFieldSet_viewer
    }
  `,
});
