/**
 * @generated SignedSource<<eaebaba6b653d3cdb6916bc70ab91c3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CredentialingPageFilterInput = {
  credentialGroup?: ReadonlyArray<string> | null;
  domain?: ReadonlyArray<string> | null;
  examType?: ReadonlyArray<string> | null;
  organization?: ReadonlyArray<string> | null;
  user?: ReadonlyArray<string> | null;
};
export type analyticsRoutes_SushiRollsQuery$variables = {
  filter: CredentialingPageFilterInput;
  organizationSlug?: string | null;
};
export type analyticsRoutes_SushiRollsQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"SushiRolls_tenant">;
  } | null;
};
export type analyticsRoutes_SushiRollsQuery = {
  response: analyticsRoutes_SushiRollsQuery$data;
  variables: analyticsRoutes_SushiRollsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "analyticsRoutes_SushiRollsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SushiRolls_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "analyticsRoutes_SushiRollsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "CredentialStatisticsByExamType",
            "kind": "LinkedField",
            "name": "credentialStatisticsByExamType",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamType",
                "kind": "LinkedField",
                "name": "examType",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "systemId",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numUsers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numUsersPartiallyCredentialed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numUsersCredentialed",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "CredentialStatisticsByMemberConnection",
            "kind": "LinkedField",
            "name": "credentialStatisticsByMemberConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CredentialStatisticsByMemberEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CredentialStatisticsByMember",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CredentialStatisticsByMemberExamTypeProgress",
                        "kind": "LinkedField",
                        "name": "examTypeProgress",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExamType",
                            "kind": "LinkedField",
                            "name": "examType",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numExamsCompleted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numExamsRequired",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e840440ba5736f6af9b2a4463d0d7b14",
    "id": null,
    "metadata": {},
    "name": "analyticsRoutes_SushiRollsQuery",
    "operationKind": "query",
    "text": "query analyticsRoutes_SushiRollsQuery(\n  $organizationSlug: String\n  $filter: CredentialingPageFilterInput!\n) {\n  tenant(slug: $organizationSlug) {\n    __typename\n    ...SushiRolls_tenant\n    id\n  }\n}\n\nfragment SushiRolls_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  credentialStatisticsByExamType(filter: $filter) {\n    examType {\n      name\n      systemId\n      id\n    }\n    numUsers\n    numUsersPartiallyCredentialed\n    numUsersCredentialed\n    id\n  }\n  credentialStatisticsByMemberConnection(filter: $filter) {\n    edges {\n      node {\n        examTypeProgress {\n          examType {\n            id\n            name\n          }\n          numExamsCompleted\n          numExamsRequired\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6bd59267d20a38bfdb54ee12748a1c25";

export default node;
