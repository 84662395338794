import Popover from '@bfly/ui2/Popover';
import { css } from 'astroturf';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import permission from 'messages/permission';

const disabledTarget = css`
  & *:disabled {
    pointer-events: none;
  }
`;

export default function PermissionTooltip({
  children,
  wrap = false,
  hide = false,
}: {
  hide?: boolean | null;
  /** includes a warpping `<span>` around the trigger for targeting disabled elements */
  wrap?: boolean;
  children: React.ReactElement | React.ReactElement[];
}) {
  return (
    <Popover.Trigger
      trigger="passive"
      placement="right"
      show={hide ? false : undefined}
      popover={
        <span data-bni-id="permissionsDisabledTooltip">
          <FormattedMessage {...permission.permissionMessage} />
        </span>
      }
    >
      {wrap ? (
        <span className={disabledTarget}>{children}</span>
      ) : (
        (children as React.ReactElement)
      )}
    </Popover.Trigger>
  );
}
