import CheckMarkSmall from '@bfly/icons/CheckMarkSmall';
import Button from '@bfly/ui2/Button';
import Heading from '@bfly/ui2/Heading';
import SrOnly from '@bfly/ui2/SrOnly';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Dl from 'components/DescriptionList';
import DicomName from 'components/DicomName';
import { useVariation } from 'components/LaunchDarklyContext';
import PatientDescriptionList from 'components/PatientDescriptionList';
import VetClientDescriptionList from 'components/VetClientDescriptionList';
import VetPatientDescriptionList from 'components/VetPatientDescriptionList';
import actionMessages from 'messages/actions';
import examMessages from 'messages/examMessages';
import patientMessages from 'messages/patient';
import vetPatientMessages from 'messages/vetPatient';
import Analytics from 'utils/Analytics';

import { PatientPopoverContent_study$data as Study } from './__generated__/PatientPopoverContent_study.graphql';

interface Props {
  study: Study;
  onEdit?: (target: 'WORKLIST' | 'EDIT') => void;
  onClear?: () => void;
}

function renderHeading(message: MessageDescriptor, children) {
  return (
    <Heading className="flex justify-between items-center btn-toolbar">
      <FormattedMessage {...message} />

      {children}
    </Heading>
  );
}

function PatientPopoverContent({ study, onEdit, onClear }: Props) {
  const canEditPhi = !useVariation('disable-phi-entry');

  const worklistSubHeading = !!study.hasAssociatedWorklistItem && (
    <Dl variant="table" ruled={false} className="mt-0 -mb-2.5">
      <Dl.Term>
        <FormattedMessage {...examMessages.fromPatientList} />
      </Dl.Term>
      <Dl.Detail>
        <CheckMarkSmall width="20px" />
        <SrOnly>
          <FormattedMessage id="CheckMarkId" defaultMessage="checked" />
        </SrOnly>
      </Dl.Detail>
    </Dl>
  );

  const actions = !study.finalizedAt && onEdit && onClear && (
    <div className="btn-toolbar">
      {study.hasAssociatedWorklistItem ? (
        <>
          <Button
            size="flush"
            variant="text-primary"
            onClick={() => onEdit('WORKLIST')}
            data-bni-id="ChangeWorklistPatientButton"
          >
            <FormattedMessage
              id="patientPopoverContent.changePatient"
              defaultMessage="Change patient"
            />
          </Button>

          <Button
            size="flush"
            variant="text-danger"
            onClick={() => onClear()}
            data-bni-id="ClearPatientButton"
          >
            <FormattedMessage
              id="patientPopoverContent.clearPatient"
              defaultMessage="Clear"
            />
          </Button>
        </>
      ) : (
        <>
          {!!study.organization!.primaryWorklist && (
            <Button
              size="flush"
              variant="text-primary"
              data-bni-id="ChoseFromWorklistPatientButton"
              onClick={() => {
                onEdit('WORKLIST');
                Analytics.track('chooseFromWorklistClicked', {
                  studyId: study.id,
                  worklistId: study.organization!.primaryWorklist!.id,
                  worklistType: study.organization!.primaryWorklist!.type,
                });
              }}
            >
              <FormattedMessage
                id="patientPopoverContent.chooseFromWorklist"
                defaultMessage="Choose from worklist"
              />
            </Button>
          )}
          {canEditPhi && (
            <Button
              size="flush"
              variant="text-secondary"
              data-bni-id="EditPatientButton"
              onClick={() => {
                onEdit('EDIT');
                Analytics.track('editPatientClicked', {
                  studyId: study.id,
                  organizationId: study.organization?.id,
                });
              }}
            >
              <FormattedMessage {...actionMessages.edit} />
            </Button>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className="p-2">
      {study.practiceType === 'HUMAN' && (
        <>
          {renderHeading(patientMessages.patient, actions)}
          {worklistSubHeading}
          <PatientDescriptionList
            study={study}
            variant="table"
            ruled={false}
          />
          <hr className="my-4" />
          <Dl variant="table" ruled={false}>
            <Dl.Term>
              <FormattedMessage
                id="patientPopoverContent.referringDoc"
                defaultMessage="Referring Physician"
              />
            </Dl.Term>
            <Dl.Detail data-bni-id="DicomRerringPhysician">
              {study.dicomReferringPhysician && (
                <DicomName value={study.dicomReferringPhysician} />
              )}
            </Dl.Detail>
            <Dl.Term>
              <FormattedMessage
                id="patientPopoverContent.requestingDoc"
                defaultMessage="Requesting Physician"
              />
            </Dl.Term>
            <Dl.Detail data-bni-id="DicomRequestingPhysician">
              {study.dicomRequestingPhysician && (
                <DicomName value={study.dicomRequestingPhysician} />
              )}
            </Dl.Detail>
          </Dl>
        </>
      )}
      {study.practiceType === 'VETERINARY' && (
        <>
          {renderHeading(vetPatientMessages.clientHeader, actions)}

          {worklistSubHeading}
          <VetClientDescriptionList
            vetPatient={study.vetPatient!}
            variant="table"
            className="mb-8"
            ruled={false}
          />

          <Heading>
            <FormattedMessage {...vetPatientMessages.patient} />
          </Heading>
          <VetPatientDescriptionList
            vetPatient={study.vetPatient!}
            variant="table"
            ruled={false}
          />
        </>
      )}
    </div>
  );
}

export default createFragmentContainer(PatientPopoverContent, {
  study: graphql`
    fragment PatientPopoverContent_study on Study {
      id
      dicomReferringPhysician
      dicomRequestingPhysician
      hasAssociatedWorklistItem
      practiceType
      finalizedAt
      organization {
        primaryWorklist {
          type: __typename
          ... on Node {
            id
          }
        }
      }
      vetPatient {
        ...VetClientDescriptionList_vetPatient
        ...VetPatientDescriptionList_vetPatient
      }
      organization {
        id
      }
      ...PatientDescriptionList_study
    }
  `,
});
