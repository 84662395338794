/**
 * @generated SignedSource<<167edbdf5ec703f47b7e252c95141fc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyBatchActions_domain$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MoveStudyModalRefresh_domain" | "MoveStudyModal_domain">;
  readonly " $fragmentType": "StudyBatchActions_domain";
};
export type StudyBatchActions_domain$key = {
  readonly " $data"?: StudyBatchActions_domain$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyBatchActions_domain">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyBatchActions_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MoveStudyModal_domain"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MoveStudyModalRefresh_domain"
    }
  ],
  "type": "Domain",
  "abstractKey": null
};

(node as any).hash = "f78bc7e749e01264d9fa337c60df937f";

export default node;
