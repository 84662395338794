import getNodes from '@bfly/utils/getNodes';

import type {
  Organization,
  Viewer,
  ViewerContext,
} from '../shared/utils/viewerContext';
import {
  NONE_PERMISSIONS,
  ViewerPermissions,
  constructPermissions,
} from '../shared/utils/viewerPermissions';

const getMergedPermissions = (
  organizations?: Organization[] | null,
): ViewerPermissions =>
  (organizations || []).reduce(
    (set, { viewerPermissions }) => {
      Object.entries(viewerPermissions || {}).forEach(([key, value]) => {
        // key is valid and value is not NONE
        if (set[key] && set[key] !== 'ADMIN' && value && value !== 'NONE')
          set[key] = value;
      });
      return set;
    },
    { ...NONE_PERMISSIONS },
  );

const getOrganizations = (viewer: Viewer) => {
  let organizations: Organization[] = [];

  if (
    viewer.domain &&
    viewer.domain?.viewerPermissions?.accessAllOrganizations !== 'NONE'
  ) {
    organizations = viewer.domain.organizationConnection
      ? (getNodes(viewer.domain.organizationConnection) as Organization[])
      : [];
  } else {
    organizations =
      viewer?.memberships?.map(
        (membership) => membership!.organization! as Organization,
      ) || [];
  }

  organizations.sort((o1, o2) => (o1.name || '').localeCompare(o2.name || ''));

  return organizations;
};

/**
 * This class is used to manage the viewer state and provide a single source of truth for viewer information.
 */
export default class ViewerContextManager {
  state: ViewerContext | null = null;

  public static instance: ViewerContextManager | null = null;

  public static getInstance(): ViewerContextManager {
    if (!ViewerContextManager.instance) {
      ViewerContextManager.instance = new ViewerContextManager();
    }
    return ViewerContextManager.instance;
  }

  static clear() {
    ViewerContextManager.instance = new ViewerContextManager();
  }

  /**
   * This function initializes the viewer state
   * @param {Viewer | null} viewer - The viewer object
   * @param {string | null} organizationSlug - The organization slug
   * @returns {ViewerContext | null}
   */
  init(
    viewer: Viewer | null,
    organizationSlug?: string | null,
  ): ViewerContext | null {
    if (!viewer) {
      this.state = null;
      return this.state;
    }
    const organizations = getOrganizations(viewer);

    const organization =
      organizations.find((o) => o.slug === organizationSlug) || null;

    const userPreferences =
      viewer?.userPreferences?.edges?.reduce((preferences, edge) => {
        if (edge?.node?.preferenceKey)
          preferences[edge?.node?.preferenceKey] = edge?.node?.value;
        return preferences;
      }, {}) || {};

    const tenant = organization || viewer.domain || null;

    const permissionsAllOrganizations = getMergedPermissions(organizations);
    const permissions = constructPermissions(
      tenant?.viewerPermissions ||
        // if we don't have a tenant, we need to merge all the permissions
        permissionsAllOrganizations,
    );

    this.state = {
      ...viewer,
      organizations,
      organization,
      permissions,
      permissionsAllOrganizations,
      userPreferences,
      tenant,
    };

    return this.state;
  }
}
