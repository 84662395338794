/**
 * @generated SignedSource<<291632c41a619cfa7f608a71c51e10bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type humanStudyInfo_study$data = {
  readonly accessionNumber: string | null;
  readonly dicomDocument: Record<string, any> | null;
  readonly hasAssociatedWorklistItem: boolean | null;
  readonly notes: string | null;
  readonly patient: {
    readonly birthDate?: string | null;
    readonly medicalRecordNumber?: string | null;
    readonly nameFirst?: string | null;
    readonly nameLast?: string | null;
    readonly nameMiddle?: string | null;
    readonly namePrefix?: string | null;
    readonly nameSuffix?: string | null;
    readonly sex?: PatientSex | null;
  } | null;
  readonly sourceWorklistItem: {
    readonly id?: string;
  } | null;
  readonly studyDescription: string | null;
  readonly " $fragmentType": "humanStudyInfo_study";
};
export type humanStudyInfo_study$key = {
  readonly " $data"?: humanStudyInfo_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"humanStudyInfo_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "humanStudyInfo_study"
};

(node as any).hash = "9a2928dece31d0a822e97f13069a7f44";

export default node;
