import { createFragmentContainer, graphql } from 'react-relay';

import ImageQualityCard, {
  ImageQualityData,
} from 'components/ImageQualityCard';

import { ImageQualityPerformanceIndicator_tenant$data as Tenant } from './__generated__/ImageQualityPerformanceIndicator_tenant.graphql';

interface Props {
  tenant: Tenant | null;
}

function ImageQualityPerformanceIndicator({ tenant }: Props) {
  const imageQualityStats =
    tenant?.imageStudyStatistics?.imageQualityStatistics ?? [];

  const data: Partial<ImageQualityData> = {};

  for (const stat of imageQualityStats) {
    if (stat?.key) data[stat?.key] = stat.numFinalizedStudies;
  }

  return (
    <ImageQualityCard
      data={data}
      busy={!tenant}
      id="ImageQualityPerformance"
    />
  );
}

export default createFragmentContainer(ImageQualityPerformanceIndicator, {
  tenant: graphql`
    fragment ImageQualityPerformanceIndicator_tenant on TenantInterface {
      imageStudyStatistics: studyStatistics(filter: $filter) {
        averageImageQuality
        imageQualityStatistics {
          key
          numFinalizedStudies
        }
      }
    }
  `,
});
