export function interactionIgnore() {
  return globalThis?.newrelic?.interaction().ignore();
}

export function noticeError(
  error: Error,
  customAttributes?: Record<string | number, any>,
) {
  return globalThis?.newrelic?.noticeError(error, customAttributes);
}
