/**
 * @generated SignedSource<<58a249911cb87fc6122ceb98092b8a79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteCapturesModalContent_study$data = {
  readonly deleteCapturesImages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyCaptureSelector_study">;
  readonly " $fragmentType": "DeleteCapturesModalContent_study";
};
export type DeleteCapturesModalContent_study$key = {
  readonly " $data"?: DeleteCapturesModalContent_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteCapturesModalContent_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteCapturesModalContent_study",
  "selections": [
    {
      "alias": "deleteCapturesImages",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2147483647
        }
      ],
      "concreteType": "StudyImageConnection",
      "kind": "LinkedField",
      "name": "imageConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyImageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyImage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "imageConnection(first:2147483647)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyCaptureSelector_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "08cacaf767cf6c3878136604ef99f376";

export default node;
