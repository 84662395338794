/**
 * @generated SignedSource<<083c2c21f7613be75760df66c7ece95e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyCaptureSelectorImagePreviewItem_image$data = {
  readonly burnedInFile?: {
    readonly isVideo: boolean | null;
    readonly " $fragmentSpreads": FragmentRefs<"StudyImageThumbnail_image" | "Thumbnail_image">;
  } | null;
  readonly createdAt?: string | null;
  readonly handle?: string | null;
  readonly id?: string;
  readonly isImportant?: boolean | null;
  readonly numComments?: number | null;
  readonly study?: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImagePopoverThumbnail_studyImage">;
  readonly " $fragmentType": "StudyCaptureSelectorImagePreviewItem_image";
};
export type StudyCaptureSelectorImagePreviewItem_image$key = {
  readonly " $data"?: StudyCaptureSelectorImagePreviewItem_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyCaptureSelectorImagePreviewItem_image">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BurnedInFile",
  "kind": "LinkedField",
  "name": "burnedInFile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVideo",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyImageThumbnail_image"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Thumbnail_image"
    }
  ],
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "StudyImagePopoverThumbnail_studyImage"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyCaptureSelectorImagePreviewItem_image",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numComments",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isImportant",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Study",
          "kind": "LinkedField",
          "name": "study",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "type": "StudyImage",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EduStudy",
          "kind": "LinkedField",
          "name": "study",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "type": "EduStudyImage",
      "abstractKey": null
    }
  ],
  "type": "StudyImageInterface",
  "abstractKey": "__isStudyImageInterface"
};
})();

(node as any).hash = "be0b12615db4ca2006da3bc5782cffc4";

export default node;
