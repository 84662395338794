import Layout from '@4c/layout';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

function VideoListContainer({ children, ...props }: Props) {
  return (
    <Layout
      className={clsx('px-app-panel-sm md:px-app-panel pb-5')}
      direction="column"
      {...props}
    >
      <div className="my-6 border-t border-t-grey-80" />
      {children}
    </Layout>
  );
}

export default VideoListContainer;
