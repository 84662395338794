import { graphql } from 'react-relay';

import ImageQualityCardBase from 'components/ImageQualityCard';

import { IndicatorImageQualityCard_imageQuality$data as Data } from './__generated__/IndicatorImageQualityCard_imageQuality.graphql';

export const imageQualityFragment = graphql`
  fragment IndicatorImageQualityCard_imageQuality on EduStudyStatistics {
    imageQuality {
      byQuality {
        quality
        numStudies
      }
    }
  }
`;

export default function IndicatorImageQualityCard({
  className,
  byQualityData,
  id,
}: {
  className: string;
  byQualityData?: NonNullable<Data['imageQuality']>['byQuality'];
  id: string;
}) {
  const data = byQualityData?.reduce(
    (agg, { numStudies, quality }) => ({ ...agg, [quality]: numStudies }),
    { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
  );

  return (
    <ImageQualityCardBase
      busy={!data}
      id={id}
      data={data || {}}
      className={className}
    />
  );
}
