/**
 * @generated SignedSource<<281682ec63ace14b88e808bde93d5406>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrganizationPracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EducationContentGrid_viewer$data = {
  readonly memberships: ReadonlyArray<{
    readonly organization: {
      readonly practiceType: OrganizationPracticeType | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "EducationContentGrid_viewer";
};
export type EducationContentGrid_viewer$key = {
  readonly " $data"?: EducationContentGrid_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationContentGrid_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationContentGrid_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerMembership",
      "kind": "LinkedField",
      "name": "memberships",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "practiceType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "5e0a6ce3e589ff028ba87c7821af1b15";

export default node;
