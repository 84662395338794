import Dropdown from '@bfly/ui2/Dropdown';
import FormCheck from '@bfly/ui2/FormCheck';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import RelayInfiniteList, {
  RelayPagination,
} from '@bfly/ui2/RelayInfiniteList';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import { LocationDescriptor } from 'found';
// eslint-disable-next-line no-restricted-imports
import Link, { LinkPropsCommon, LinkPropsWithFunctionChild } from 'found/Link';
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useUncontrolledProp } from 'uncontrollable';

import DataGridColumnPicker, { PickerColumn } from './DataGridColumnPicker';
import DataGridTable, { DataTableCellProps } from './DataGridTable';
import IndeterminateFormCheck from './IndeterminateFormCheck';
import SortIndicator from './SortIndicator';

function maybeInvoke<T extends unknown | ((...p: any[]) => any)>(
  value: T,
  ...args: T extends (...p: any[]) => any ? Parameters<T> : any[]
): T extends (...p: any[]) => any ? ReturnType<T> : T {
  if (typeof value === 'function') return value(...args);
  return value as any;
}

const gridStyles = stylesheet`
  .loading::after {
    content: '';
    z-index: 10; // above columns and headers
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.2;
  }
`;

const toOffsetCssValue = (values: string[] | undefined) => {
  if (!values?.length) return '0';
  return values.length === 1 ? values[0] : `calc(${values.join(' + ')})`;
};
/*
  This is an extended version of https://github.com/ButterflyNetwork/olympus-web/blob/master/libraries/ui2/src/ScrollObserver.tsx#L27,
  which allows scroll observer to flex, so that if we're scrolled horizontally, scroll observer is still in user's view.
*/
const ScrollObserverElement = React.forwardRef((_, ref: any) => (
  <span ref={ref} style={{ fontSize: 0, display: 'flex' }} />
));

type ColumnLabel<TContext> = ReactNode | ((ctx: TContext) => ReactNode);

export interface SortSpec {
  key: string;
  label: ReactNode;
}

export interface ColumnSpecPickerConfig<TContext> {
  label?: ColumnLabel<TContext>;
  /**
   * Whether or not to show the column in the picker.
   */
  show?: boolean;
}
export interface ColumnGroupPickerConfig<TContext> {
  label?: ColumnLabel<TContext>;
  /**
   * Whether or not to only show the group label or each column in the picker
   */
  show?: boolean | 'collapsed';
}

export interface ColumnSpec<TContext, TData> {
  disabled?: (ctx: TContext) => boolean;
  frozen?: boolean;
  headerClassName?: string;
  key: string;
  label: ColumnLabel<TContext>;
  picker?: ColumnSpecPickerConfig<TContext>;
  render?: (item: TData, ctx: TContext) => ReactNode;
  renderCell?: (options: {
    cellProps: DataTableCellProps<'td'>;
    spec: ColumnSpec<TContext, TData>;
    item: TData;
    context: TContext;
    style: Record<string, any> | undefined;
    rowLocation: LocationDescriptor | undefined;
    rowIndex: number;
  }) => ReactNode;
  sortable?: SortSpec[];
  width?: string | ((ctx: TContext) => string);
}

export interface ColumnGroupSpec<TContext, TData>
  extends Omit<
    ColumnSpec<TContext, TData>,
    'picker' | 'render' | 'renderCell'
  > {
  columns: ColumnSpec<TContext, TData>[];
  picker?: ColumnGroupPickerConfig<TContext>;
}

export type ColumnProp<TContext, TData> =
  | ColumnSpec<TContext, TData>
  | ColumnGroupSpec<TContext, TData>;

interface ColumnPropFlat<TContext, TData> extends ColumnSpec<TContext, TData> {
  group?: Omit<ColumnGroupSpec<TContext, TData>, 'columns'>;
}

interface DataGridContext<TContext, TData> {
  data: readonly TData[];
  columnContext: TContext;
  columns: ColumnProp<TContext, TData>[];
  selectedItems: readonly TData[];
  getRowLocation?: (item: TData) => LocationDescriptor;
  onRowSelect: (items: readonly TData[]) => void;
  onRowSingleSelect: (checked: boolean, item: TData) => void;
  onRowRangeSelect: (item: TData) => void;
}

const DataGridContext = React.createContext<DataGridContext<
  unknown,
  unknown
> | null>(null);

export interface DataGridProps<TContext, TData> {
  className?: string;
  showColPicker?: boolean;
  loading?: boolean;
  data: readonly TData[];
  selectedItems?: readonly TData[];
  selectedItemsDefault?: readonly TData[]; // use only when the element is uncontrolled
  sort?: string | null;
  columns: ColumnProp<TContext, TData>[];
  columnVisibility?: Record<string, boolean>;
  defaultVisibility?: Record<string, boolean>;
  columnOrder?: string[];
  onColumnVisibilityChange?: (nextColumnKeys: Record<string, boolean>) => void;
  onColumnOrderChange?: (nextColumnKeys: string[]) => void;
  onRowSelect?: (nextSelected: TData[]) => void;
  onSort?: (nextSort: string | null) => void;
  pageSize?: number;
  relayPagination?: RelayPagination;
  columnContext: TContext;
  scrollKey: string;
  scrollRef?: React.Ref<HTMLElement>;

  /** Defers to the window for grid scrolling. */
  viewportScrolling?: boolean;

  getRowLocation?: (item: TData) => LocationDescriptor;
  rowComponent?: React.ComponentType<{
    item: TData;
    context: TContext;
    selected?: boolean;
    highlighted?: boolean;
    children: ReactNode;
  }>;
  allowScrollToLastColumn?: boolean;
}

interface DataGridRowContext<TContext, TData> {
  context: TContext;
  item: TData;
  rowLocation?: LocationDescriptor;
  selected: boolean;
  onSelect?: (checked: boolean) => void;
}

const DataGridRowContext = React.createContext<DataGridRowContext<
  unknown,
  unknown
> | null>(null);

interface DataGridRowProps<TContext, TData> {
  columns: ColumnPropFlat<TContext, TData>[];
  columnContext: TContext;
  item: TData;
  selected: boolean;
  frozenCellOffsets: Record<string, string[]>;
  onSelect?: (checked: boolean) => void;
  getRowLocation?: (item: TData) => LocationDescriptor;
  rowComponent?: React.ComponentType<{
    item: TData;
    context: TContext;
    selected?: boolean;
    highlighted?: boolean;
    children: ReactNode;
  }>;
  rowIndex: number;
}

function DataGridRow<TContext, TData>({
  columns,
  columnContext,
  item,
  selected,
  onSelect,
  getRowLocation,
  rowComponent: Row = DataGridTable.Row,
  rowIndex,
  frozenCellOffsets,
}: DataGridRowProps<TContext, TData>) {
  const rowLocation = getRowLocation?.(item);

  const context = useMemo(
    () => ({
      item,
      rowLocation,
      context: columnContext,
      onSelect,
      selected,
    }),
    [columnContext, item, onSelect, rowLocation, selected],
  );

  return (
    <DataGridRowContext.Provider value={context}>
      <Row context={columnContext} item={item} selected={selected}>
        {columns.map((colSpec, idx) => {
          const isLastFrozen = colSpec.frozen && !columns[idx + 1]?.frozen;
          const style = colSpec.frozen
            ? { left: toOffsetCssValue(frozenCellOffsets[colSpec.key]) }
            : undefined;

          const cellProps = {
            key: colSpec.key,
            style,
            frozen: colSpec.frozen,
            lastFrozen: isLastFrozen,
            to: rowLocation,
          };
          if (colSpec.renderCell)
            return colSpec.renderCell({
              cellProps,
              spec: colSpec,
              item,
              style,
              context: columnContext,
              rowLocation,
              rowIndex,
            });

          return (
            <DataGridTable.Cell {...cellProps}>
              {colSpec.render!(item, columnContext)}
            </DataGridTable.Cell>
          );
        })}
      </Row>
    </DataGridRowContext.Provider>
  );
}

function DataGridRowCheck(props: { disabled?: boolean; className?: string }) {
  const isShiftRef = useRef(false);
  const rowContext = useContext(DataGridRowContext);
  const gridContext = useContext(DataGridContext);

  return (
    <FormCheck
      {...props}
      inline
      type="checkbox"
      checked={rowContext?.selected}
      onClick={(e) => {
        isShiftRef.current = e.shiftKey;
      }}
      onChange={(e) => {
        if (isShiftRef.current) {
          gridContext?.onRowRangeSelect(rowContext!.item);
        } else {
          gridContext?.onRowSingleSelect(
            e.currentTarget.checked,
            rowContext!.item,
          );
        }
        isShiftRef.current = false;
      }}
    />
  );
}

function DataGridSelectAll({
  numSelectableItems,
  ...props
}: React.ComponentPropsWithoutRef<'input'> & { numSelectableItems?: number }) {
  const gridContext = useContext(DataGridContext);

  const total = numSelectableItems ?? gridContext!.data.length;

  const noneChecked = !gridContext!.selectedItems.length;
  const allChecked =
    !!gridContext!.selectedItems.length &&
    gridContext!.selectedItems.length === total;

  return (
    <IndeterminateFormCheck
      // if there are no selectable items then be disabled
      disabled={total === 0}
      {...props}
      inline
      type="checkbox"
      indeterminate={!allChecked && !noneChecked}
      checked={allChecked}
      onChange={(e) => {
        gridContext?.onRowSelect(
          e.currentTarget.checked ? gridContext.data : [],
        );
      }}
    />
  );
}

function DataGridRowLink(
  props: {
    to?: LinkPropsCommon['to'];
    fillCell?: boolean;
    children?: LinkPropsWithFunctionChild['children'] | ReactNode;
  } & Omit<React.ComponentPropsWithoutRef<'a'>, 'children'>,
) {
  const rowContext = useContext(DataGridRowContext);

  return (
    <Link
      as={DataGridTable.Anchor}
      data-bni-id="DataGridRowLink"
      {...(props as any)}
      to={rowContext?.rowLocation}
    />
  );
}

interface DataGridHeaderProps<TContext, TData> {
  spec: ColumnProp<TContext, TData>;
  children?: ReactNode;
  sort?: string | null;
  style?: CSSProperties;
  onSort: (nextSort: string | null) => void;
  lastFrozen?: boolean;
  colSpan?: number;
  frozen?: boolean;
}

function DataGridHeader<TContext, TData>({
  spec,
  sort,
  onSort,
  children,
  ...props
}: DataGridHeaderProps<TContext, TData>) {
  const sorts = spec.sortable;
  const hasActiveSort = sort && sorts?.find((s) => s.key === sort);
  const isAscending = sort?.endsWith('ASC');

  return (
    <DataGridTable.Header
      {...props}
      frozen={spec.frozen}
      className={spec.headerClassName}
      data-bni-id={`DataGridColumn:${spec.key}`}
      data-last-frozen={props.lastFrozen}
      menuItems={
        sorts ? (
          <>
            {sorts.map(({ key, label }) => (
              <Dropdown.Item key={key} onSelect={() => onSort(key)}>
                {label}
              </Dropdown.Item>
            ))}
            {hasActiveSort && (
              <Dropdown.Item variant="danger" onSelect={() => onSort(null)}>
                <FormattedMessage
                  id="dataGrid.removeSort"
                  defaultMessage="Remove sort"
                />
              </Dropdown.Item>
            )}
          </>
        ) : undefined
      }
    >
      {children}
      {hasActiveSort && <SortIndicator desc={isAscending} />}
    </DataGridTable.Header>
  );
}

export default function DataGrid<TData extends { id: any }, TContext>({
  loading,
  data = [],
  columns: columnsProp,
  columnContext,
  columnVisibility: propscolumnVisibility,
  defaultVisibility,
  columnOrder: propsColumnOrder,
  onColumnVisibilityChange,
  onColumnOrderChange,
  onRowSelect,
  selectedItems: propsSelectedItems,
  selectedItemsDefault,
  sort: propsSort,
  onSort,
  rowComponent,
  getRowLocation,
  relayPagination,
  pageSize,
  showColPicker = true,
  className,
  allowScrollToLastColumn,
  ...props
}: DataGridProps<TContext, TData>) {
  const [sort, handleSort] = useUncontrolledProp(propsSort, null, onSort);

  // remove disabled columns and disabled group columns
  const columns: ColumnProp<TContext, TData>[] = useMemo(() => {
    const nextColumns: ColumnProp<TContext, TData>[] = [];

    columnsProp.forEach((column) => {
      if ('columns' in column) {
        const groupColumns = column.columns.filter(
          ({ disabled }) => !disabled?.(columnContext),
        );
        // only keep a group column if there are non-disabled child columns
        if (groupColumns.length)
          nextColumns.push({
            ...column,
            columns: groupColumns,
          });
      } else if (!column.disabled?.(columnContext)) {
        nextColumns.push(column);
      }
    });
    return nextColumns;
  }, [columnsProp, columnContext]);

  const [selectedItems, handleRowSelect] = useUncontrolledProp(
    propsSelectedItems,
    selectedItemsDefault || [],
    onRowSelect,
  );

  const [columnOrder, handleColumnOrderChange] = useUncontrolledProp(
    propsColumnOrder,
    // default columnOrder
    columns.map(({ key }) => key),
    onColumnOrderChange,
  );

  const [columnVisibility, handleColumnVisibilityChange] = useUncontrolledProp(
    propscolumnVisibility,
    // default columnVisibility
    Object.fromEntries(
      columns
        .flatMap((column) =>
          'columns' in column ? [column, ...column.columns] : [column],
        )
        .map((c) => {
          const def =
            defaultVisibility && c.key in defaultVisibility
              ? defaultVisibility[c.key]
              : true;
          return [c.key, def];
        }),
    ) as Record<string, boolean>,
    onColumnVisibilityChange,
  );

  const sortedColumns = useMemo(() => {
    const sorted = [...columns];
    sorted.sort(
      (a, b) => columnOrder.indexOf(a.key) - columnOrder.indexOf(b.key),
    );
    return sorted;
  }, [columns, columnOrder]);

  const [sortedVisibleColumns, sortedVisibleColumnsFlat] = useMemo(() => {
    const visibleColumns: ColumnProp<TContext, TData>[] = [];

    sortedColumns.forEach((column) => {
      if (!columnVisibility[column.key]) return;

      if ('columns' in column) {
        const visibleGroupColumns = column.columns.filter(
          ({ key }) => columnVisibility[key],
        );

        if (visibleGroupColumns.length)
          visibleColumns.push({
            ...column,
            columns: visibleGroupColumns,
          });
      } else {
        visibleColumns.push(column);
      }
    });

    const flat: ColumnPropFlat<TContext, TData>[] = visibleColumns.flatMap(
      (column) =>
        'columns' in column
          ? column.columns.map((childColumn) => ({
              ...childColumn,
              group: column,
            }))
          : [column],
    );

    return [visibleColumns, flat];
  }, [sortedColumns, columnVisibility]);

  const offsets = useMemo(() => {
    const result: Record<string, string[]> = {};
    let current = [] as string[];

    for (const { key, width, frozen } of sortedVisibleColumnsFlat) {
      if (!frozen) return result;

      result[key] = current;
      const offset = maybeInvoke(width, columnContext);
      if (offset) current = [...current, offset];
    }
    return result;
  }, [sortedVisibleColumnsFlat, columnContext]);

  const handleRowSingleSelect = useCallback(
    (checked: boolean, item: TData) => {
      if (checked) {
        handleRowSelect([...selectedItems, item]);
      } else {
        handleRowSelect(selectedItems.filter((i) => i !== item));
      }
    },
    [handleRowSelect, selectedItems],
  );

  const handleRowRangeSelect = useCallback(
    (item: TData) => {
      const itemIndex = data.indexOf(item);
      const lastItemIndex = data.indexOf(
        selectedItems[selectedItems.length - 1],
      );

      const range = data.slice(
        Math.min(itemIndex, lastItemIndex),
        Math.max(itemIndex, lastItemIndex) + 1,
      );

      const nextSelected = new Set(selectedItems);
      range.forEach((i) => nextSelected.add(i));

      handleRowSelect(Array.from(nextSelected));
    },
    [data, handleRowSelect, selectedItems],
  );

  const gridContext = useMemo(() => {
    return {
      selectedItems,
      data,
      columnContext,
      columns,
      onRowSelect: handleRowSelect,
      onRowSingleSelect: handleRowSingleSelect,
      onRowRangeSelect: handleRowRangeSelect,
      getRowLocation,
    };
  }, [
    columnContext,
    columns,
    data,
    handleRowSelect,
    handleRowSingleSelect,
    handleRowRangeSelect,
    selectedItems,
    getRowLocation,
  ]);

  const rows = useMemo(
    () =>
      data.map((item, index) => (
        <DataGridRow
          key={item.id}
          item={item}
          frozenCellOffsets={offsets}
          columnContext={columnContext}
          columns={sortedVisibleColumnsFlat}
          getRowLocation={getRowLocation}
          rowComponent={rowComponent}
          rowIndex={index}
          selected={selectedItems.includes(item)}
        />
      )),
    [
      data,
      offsets,
      columnContext,
      sortedVisibleColumnsFlat,
      getRowLocation,
      rowComponent,
      selectedItems,
    ],
  );

  const templateColumns = sortedVisibleColumnsFlat
    .map(
      (col) =>
        `[${col.key}] ${
          maybeInvoke(col.width, columnContext) || 'minmax(auto, 30rem)'
        }`,
    )
    .join(' ');

  const getColumnLabel = (label: ColumnLabel<typeof columnContext>) =>
    typeof label === 'function' ? label(columnContext) : label;

  const toPickerColumn = (
    column: ColumnProp<typeof columnContext, any>,
  ): PickerColumn => ({
    key: column.key,
    fixed: column.frozen,
    label: getColumnLabel(column.picker?.label || column.label),
  });

  const columnPicker = showColPicker && (
    <DataGridColumnPicker
      columns={sortedColumns
        .filter(({ picker }) => picker?.show !== false)
        .map((column) => {
          const pickerColumn = toPickerColumn(column);
          if ('columns' in column && column.picker?.show !== 'collapsed') {
            pickerColumn.columns = column.columns?.map(toPickerColumn);
          }
          return pickerColumn;
        })}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={handleColumnVisibilityChange}
      onColumnOrderChange={handleColumnOrderChange}
    />
  );

  const needsGroupColumnHeaders = sortedVisibleColumns.some(
    (column) => 'columns' in column,
  );

  return (
    <DataGridContext.Provider
      value={gridContext as DataGridContext<unknown, unknown>}
    >
      <div
        className={clsx(
          'relative min-h-0 flex-1',
          loading && gridStyles.loading,
          className,
        )}
      >
        <DataGridTable
          {...props}
          templateColumns={templateColumns}
          allowScrollToLastColumn={allowScrollToLastColumn}
          showColPicker={showColPicker}
        >
          <thead>
            {needsGroupColumnHeaders && (
              // top row of columns if we hasGroupedColumns
              <DataGridTable.HeaderRow>
                {sortedVisibleColumns.map((column, idx) => {
                  const colSpan =
                    'columns' in column ? column.columns.length : 1;

                  return 'columns' in column ? (
                    <DataGridTable.Header
                      key={column.key}
                      colSpan={colSpan}
                      style={{ gridColumn: `span ${colSpan}` }}
                    >
                      {getColumnLabel(column.label)}
                    </DataGridTable.Header>
                  ) : (
                    <DataGridTable.Header
                      // eslint-disable-next-line react/no-array-index-key
                      key={idx}
                      placeholder
                      colSpan={colSpan}
                      frozen={column.frozen}
                      lastFrozen={column.frozen}
                      style={{ gridColumn: `span ${colSpan}` }}
                    />
                  );
                })}
              </DataGridTable.HeaderRow>
            )}
            <DataGridTable.HeaderRow
              columnPicker={columnPicker}
              utilityHeader={allowScrollToLastColumn}
            >
              {sortedVisibleColumnsFlat.map((column, idx, list) => {
                const isLastFrozen = column.frozen && !list[idx + 1]?.frozen;
                const style = column.frozen
                  ? { left: toOffsetCssValue(offsets[column.key]) }
                  : undefined;

                return (
                  <DataGridHeader
                    key={column.key}
                    style={style}
                    spec={column}
                    sort={sort}
                    lastFrozen={isLastFrozen}
                    onSort={handleSort}
                  >
                    {getColumnLabel(column.label)}
                  </DataGridHeader>
                );
              })}
            </DataGridTable.HeaderRow>
          </thead>
          <tbody>
            {relayPagination ? (
              <RelayInfiniteList
                pageSize={pageSize!}
                relayPagination={relayPagination}
                loadingIndicator={
                  <tr>
                    <td css="grid-column: 1 / -1">
                      <LoadingIndicator />
                    </td>
                  </tr>
                }
                as={ScrollObserverElement}
                renderScrollObserver={(scrollObserserver) => (
                  <tr>
                    <td css="grid-column: 1 / -1">{scrollObserserver}</td>
                  </tr>
                )}
              >
                {rows}
              </RelayInfiniteList>
            ) : (
              rows
            )}
          </tbody>
        </DataGridTable>
      </div>
    </DataGridContext.Provider>
  );
}

DataGrid.Cell = DataGridTable.Cell;
DataGrid.Header = DataGridTable.Header;
DataGrid.Row = DataGridTable.Row;
DataGrid.Anchor = DataGridTable.Anchor;
DataGrid.RowLink = DataGridRowLink;
DataGrid.RowSelect = DataGridRowCheck;
DataGrid.SelectAll = DataGridSelectAll;
