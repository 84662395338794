/**
 * @generated SignedSource<<4b11bef3725569efe041de9da8f4e293>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LmsSsoBanner_ExportQuery$variables = {
  timezoneOffset?: number | null;
};
export type LmsSsoBanner_ExportQuery$data = {
  readonly lms: {
    readonly dashboardExport: {
      readonly " $fragmentSpreads": FragmentRefs<"FileExportButton_fileExport">;
    } | null;
  } | null;
};
export type LmsSsoBanner_ExportQuery = {
  response: LmsSsoBanner_ExportQuery$data;
  variables: LmsSsoBanner_ExportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "timezoneOffset"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "timezoneOffset",
    "variableName": "timezoneOffset"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LmsSsoBanner_ExportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "FileExport",
            "kind": "LinkedField",
            "name": "dashboardExport",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "FileExportButton_fileExport",
                "selections": (v2/*: any*/),
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LmsSsoBanner_ExportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "FileExport",
            "kind": "LinkedField",
            "name": "dashboardExport",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dc3a9ac82f9bb65a5a5d3f9fb288af9",
    "id": null,
    "metadata": {},
    "name": "LmsSsoBanner_ExportQuery",
    "operationKind": "query",
    "text": "query LmsSsoBanner_ExportQuery(\n  $timezoneOffset: Int\n) {\n  lms {\n    dashboardExport(timezoneOffset: $timezoneOffset) {\n      ...FileExportButton_fileExport\n    }\n  }\n}\n\nfragment FileExportButton_fileExport on FileExport {\n  url\n}\n"
  }
};
})();

(node as any).hash = "8468e6ded927df17fde0ecef05b3148b";

export default node;
