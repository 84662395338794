/**
 * @generated SignedSource<<6a99f8e1f5489e6d8e07a42e799be81b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPacsJobDetail_pacs$data = {
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SendStudyToPacsButton_pacs">;
  readonly " $fragmentType": "StudyPacsJobDetail_pacs";
};
export type StudyPacsJobDetail_pacs$key = {
  readonly " $data"?: StudyPacsJobDetail_pacs$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPacsJobDetail_pacs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyPacsJobDetail_pacs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendStudyToPacsButton_pacs"
    }
  ],
  "type": "Pacs",
  "abstractKey": null
};

(node as any).hash = "dc634f16b50a58a25ee9951287f42aa9";

export default node;
