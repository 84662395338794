import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import { graphql } from 'react-relay';

import Route, { Match } from 'components/Route';

import EcommerceExternalRedirect from './components/EcommerceExternalRedirect';

function getQuery(match: Match) {
  if (match?.params?.organizationSlug) {
    return graphql`
      query ecommerceExternalRedirect_OrganizationQuery(
        $organizationSlug: String!
      ) {
        organization: organizationBySlug(slug: $organizationSlug) {
          ...EcommerceExternalRedirect_organization
        }
      }
    `;
  }

  return graphql`
    query ecommerceExternalRedirect_ViewerQuery {
      viewer {
        memberships {
          organization {
            ...EcommerceExternalRedirect_organization
          }
        }
      }
    }
  `;
}

export default (
  <Route
    allowLoginFromHash
    mobileFriendly
    path="subscription/activate"
    getQuery={getQuery}
    Component={EcommerceExternalRedirect}
    render={({ Component, props, match }) => {
      match.location.state = {
        ...match?.location?.state,
        comingFromSubActivate: true,
      };
      if (props) {
        let organization;

        if (props.organization) {
          organization = props.organization;
        } else {
          const { memberships } = props.viewer!;
          organization =
            memberships!.length === 1 ? memberships![0].organization : null;
        }

        return <Component organization={organization} />;
      }
      return <LoadingIndicator />;
    }}
  />
);
