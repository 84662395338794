import { GridApi } from 'ag-grid-community';
import { useState } from 'react';

import {
  dispatchCustomEvent,
  useCustomEventListener,
} from 'hooks/useCustomEvents';

const EVENTS = {
  ON_SELECT: 'ON_SELECT',
  DESELECT_ALL: 'DESELECT_ALL',
  ON_READ_ONLY_CHANGE: 'ON_READ_ONLY_CHANGE',
  CLOSE_POPUP_MENU: 'CLOSE_POPUP_MENU',
  CLEAR_FILTER: 'CLEAR_FILTER',
} as const;

export default function useGridEvents<TData>(callbacks?: {
  onSelect?: (rows: TData[]) => void;
  onReadOnlyChange?: (next: boolean) => void;
  onClosePopupMenu?: () => void;
  onClearFilter?: (colId: string) => void;
}) {
  const { onSelect, onReadOnlyChange, onClosePopupMenu, onClearFilter } =
    callbacks || {};

  useCustomEventListener<TData[], void>(EVENTS.ON_SELECT, onSelect);

  useCustomEventListener<boolean, void>(
    EVENTS.ON_READ_ONLY_CHANGE,
    onReadOnlyChange,
  );

  useCustomEventListener<boolean, void>(
    EVENTS.CLOSE_POPUP_MENU,
    onClosePopupMenu,
  );

  useCustomEventListener<string, void>(EVENTS.CLEAR_FILTER, onClearFilter);

  return {
    deselectAll: () => {
      dispatchCustomEvent(EVENTS.DESELECT_ALL);
    },
    clearFilter: (colId: string) => {
      dispatchCustomEvent(EVENTS.CLEAR_FILTER, colId);
    },
    closePopupMenu: () => {
      dispatchCustomEvent(EVENTS.CLOSE_POPUP_MENU);
    },
  };
}

export function useAttachGridEvents<TData>() {
  const [gridApi, setGridApi] = useState<GridApi>();

  useCustomEventListener(EVENTS.DESELECT_ALL, () => {
    gridApi?.deselectAll();
  });

  useCustomEventListener(EVENTS.CLOSE_POPUP_MENU, () => {
    gridApi?.hidePopupMenu();
  });

  return {
    handleSelectionChanged: (rows: TData[]) => {
      dispatchCustomEvent(EVENTS.ON_SELECT, rows);
    },
    handleReadOnlyChange: (next: boolean) => {
      dispatchCustomEvent(EVENTS.ON_READ_ONLY_CHANGE, next);
    },
    setGridApi,
  };
}
