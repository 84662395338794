import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import MainContent from '@bfly/ui2/MainContent';
import useMutationWithError, {
  isMutationError,
} from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import useMountEffect from '@restart/hooks/useMountEffect';
import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';

import { useAccountRoutes } from 'routes/account';
import { useConfigRoutes } from 'routes/config';
import sessionStore from 'utils/sessionStore';

import { SHOW_SIGN_IN_CONFIRMATION_STATE_KEY } from '../../../components/AuthProvider';
import { VerifyEmailChangePage_AcceptSecondaryEmailMutation as AcceptMutation } from './__generated__/VerifyEmailChangePage_AcceptSecondaryEmailMutation.graphql';
import { VerifyEmailChangePage_ConfirmMutation as ConfirmMutation } from './__generated__/VerifyEmailChangePage_ConfirmMutation.graphql';

function VerifyEmailChangePage() {
  const { match, router } = useRouter();
  const configRoutes = useConfigRoutes();
  const accountRoutes = useAccountRoutes();
  const toast = useToast();

  const [confirmPrimaryEmail] = useMutationWithError<ConfirmMutation>(
    graphql`
      mutation VerifyEmailChangePage_ConfirmMutation(
        $input: ConfirmEmailInput!
      ) {
        confirmEmailOrError(input: $input) {
          __typename
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
  );

  const [confirmSecondaryEmail] = useMutationWithError<AcceptMutation>(
    graphql`
      mutation VerifyEmailChangePage_AcceptSecondaryEmailMutation(
        $input: AcceptUserEmailInput!
      ) {
        acceptUserEmailOrError(input: $input) {
          __typename
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
  );

  async function handleEmailChange() {
    const { location } = match;

    if (!location.query) {
      router.replace(accountRoutes.settings());
    }
    sessionStore.set(SHOW_SIGN_IN_CONFIRMATION_STATE_KEY, false);

    const { change_request_token: token, user_email_id: userEmailId } =
      location.query;

    try {
      if (userEmailId) {
        await confirmSecondaryEmail({
          input: { token, userEmailId },
        });
      } else {
        await confirmPrimaryEmail({ input: { token } });
      }
    } catch (networkError: any) {
      if (
        isMutationError(networkError) &&
        (networkError.errorType === 'NotFoundError' ||
          networkError.errorType === 'MissingEmailChangeRequestError')
      ) {
        // The user may have already accepted or canceled the email change
        // request. Just indicate that no email change happened.
        toast.error(
          <FormattedMessage
            id="account.verifyEmailChange.failure"
            defaultMessage="Email address change failed"
          />,
        );
        router.replace(configRoutes.rootRoute());
      } else {
        router.replace({
          ...location,
          state: { foundHttpError: 500 },
        });
      }
      return;
    }

    toast.success(
      <FormattedMessage
        id="account.verifyEmailChange.success"
        defaultMessage="Email address has been updated"
      />,
    );

    router.replace(accountRoutes.settings());
  }

  useMountEffect(() => {
    handleEmailChange();
  });

  return (
    <MainContent>
      <LoadingIndicator />
    </MainContent>
  );
}

export default VerifyEmailChangePage;
