import Layout from '@4c/layout';
import TabNav from '@bfly/ui2/TabNav';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import type { RoutePageProps } from 'components/Route';
import { useEducationRoutes } from 'routes/education';
import { useIsEnterpriseOrEducation } from 'utils/isEnterpriseOrEducation';

import educationMessages from '../messages/educationMessages';
import EducationPageHeader from './EducationPageHeader';
import type { EducationContentBookmarks_lms$data as Lms } from './__generated__/EducationContentBookmarks_lms.graphql';
import useAdvancedAcademyFeatures from './utils/useAdvancedAcademyFeatures';
import useButterflyAcademyAccess from './utils/useButterflyAcademyAccess';

interface Props extends RoutePageProps {
  lms: Lms;
}

function EducationContentBookmarks({ children, lms }: Props) {
  const intl = useIntl();
  const educationRoutes = useEducationRoutes();
  const butterflyAcademyAccess = useButterflyAcademyAccess();
  const isViewerEnterpriseOrEducation = useIsEnterpriseOrEducation();
  const advancedAcademyFeatures = useAdvancedAcademyFeatures();
  const canSeeCourses = butterflyAcademyAccess !== 'hidden';

  return (
    <>
      <EducationPageHeader
        title={intl.formatMessage(educationMessages.myContent)}
      />
      <TabNav className="px-app-panel-sm md:px-app-panel">
        {canSeeCourses && (
          <TabNav.LinkItem
            to={educationRoutes.assignedCourses()}
            data-bni-id="EducationContentBookmarksAssignedTab"
          >
            <FormattedMessage
              {...(isViewerEnterpriseOrEducation && advancedAcademyFeatures
                ? educationMessages.assigned
                : educationMessages.enrollments)}
              values={{ count: lms?.assigned?.edges?.length || 0 }}
            />
          </TabNav.LinkItem>
        )}
        <TabNav.LinkItem
          to={educationRoutes.savedContent()}
          data-bni-id="EducationContentBookmarksSavedTab"
        >
          <FormattedMessage
            {...educationMessages.saved}
            values={{ count: lms?.saved?.edges?.length || 0 }}
          />
        </TabNav.LinkItem>
        {canSeeCourses && (
          <TabNav.LinkItem
            to={educationRoutes.completedCourses()}
            data-bni-id="EducationContentBookmarksCompletedTab"
          >
            <FormattedMessage
              {...educationMessages.completedCount}
              values={{ count: lms?.completed?.edges?.length || 0 }}
            />
          </TabNav.LinkItem>
        )}
      </TabNav>
      <Layout
        className="py-5 px-app-panel-sm md:px-app-panel"
        direction="column"
      >
        {children}
      </Layout>
    </>
  );
}

export default createFragmentContainer(EducationContentBookmarks, {
  lms: graphql`
    fragment EducationContentBookmarks_lms on Lms {
      assigned: educationContentConnection(
        first: 2147483647
        viewerIsAssigned: true
        viewerHasCompleted: false
      ) @connection(key: "EducationContentBookmarksAssigned_assigned") {
        edges {
          node {
            id
          }
        }
      }
      completed: educationContentConnection(
        first: 2147483647
        viewerHasCompleted: true
      ) @connection(key: "EducationContentBookmarksAssigned_completed") {
        __id
        edges {
          node {
            id
          }
        }
      }
      saved: educationContentConnection(
        first: 2147483647
        viewerHasSaved: true
      ) @connection(key: "EducationContentBookmarksAssigned_saved") {
        __id
        edges {
          node {
            id
          }
        }
      }
    }
  `,
});
