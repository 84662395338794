import Layout from '@4c/layout';
import GearIcon from '@bfly/icons/Gear';
import MembersIcon from '@bfly/icons/Members';
import ReferAFriendIcon from '@bfly/icons/ReferAFriend';
import Dropdown from '@bfly/ui2/Dropdown';
import Link from '@bfly/ui2/Link';
import Navbar from '@bfly/ui2/Navbar';
import SrOnly from '@bfly/ui2/SrOnly';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import useRouter from 'found/useRouter';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useAuth } from 'components/AuthContext';
import Avatar from 'components/Avatar';
import useIsTinyViewport from 'hooks/useIsTinyViewport';
import accountMessages from 'messages/account';
import { useAccountRoutes } from 'routes/account';
import { useAdminRoutes } from 'routes/admin';
import { useConfigRoutes } from 'routes/config';
import {
  usePermissions,
  useViewerAllowMissingContext,
} from 'utils/viewerContext';

import { useVariationAllowMissingLdContext } from './LaunchDarklyContext';
import { AppHeaderUserDropdownButton_organization$data as Organization } from './__generated__/AppHeaderUserDropdownButton_organization.graphql';

interface Props {
  organization: Organization | null;
}

const referAFriend = (
  <FormattedMessage
    id="headerMenu.orgSwitcher.referrals"
    defaultMessage="Refer a friend"
  />
);

function AppHeaderUserDropdownButton({ organization }: Props) {
  const { router } = useRouter();
  const configRoutes = useConfigRoutes();
  const accountRoutes = useAccountRoutes();
  const adminRoutes = useAdminRoutes();

  const { canAccessEnterpriseSettings, canAccessOrganizationSettings } =
    usePermissions();
  const auth = useAuth();
  const canUseReferrals = useVariationAllowMissingLdContext('referral-web');
  const viewer = useViewerAllowMissingContext();

  const [show, setShow] = useState(false);

  const isTinyViewport = useIsTinyViewport();

  const handleClickLogIn = useCallback(() => {
    router.push(configRoutes.rootRoute());
  }, [configRoutes, router]);

  const handleClickLogOut = useCallback(() => {
    auth.clearAccessToken(true);
  }, [auth]);

  // Mobile devices don't have a need to login or view any user dropdown
  //  button.
  if (isTinyViewport) {
    return null;
  }

  if (!viewer) {
    return (
      <Navbar.Button data-bni-id="LoginButton" onClick={handleClickLogIn}>
        <FormattedMessage {...accountMessages.logIn} />
      </Navbar.Button>
    );
  }

  const canSeeEnterpriseSettingsLink = canAccessEnterpriseSettings();

  const canAccessOrganizationSettingsLink = canAccessOrganizationSettings();

  let routeTo = 'organizations';
  if (viewer.domain && canSeeEnterpriseSettingsLink) {
    const canSeeOrgList = canAccessEnterpriseSettings(
      'organizationManagement',
    );

    const hasUserManagementPermission =
      canAccessEnterpriseSettings('userManagement');

    if (hasUserManagementPermission) {
      routeTo = 'domainMembers';
    }
    if (canSeeOrgList) {
      routeTo = 'organizations';
    }
  }

  return (
    <Dropdown show={show} onToggle={setShow}>
      <Dropdown.Toggle
        data-bni-id="UserDropdown"
        id="user-dropdown"
        as={Navbar.Button}
      >
        <Avatar userProfile={viewer.profile!} width={30} />
        <SrOnly>{viewer.profile!.name}</SrOnly>
      </Dropdown.Toggle>
      <Dropdown.Menu
        placement="bottom-end"
        data-bni-id="AppHeaderUserDropdownMenu"
        css="width: 25rem;"
      >
        <>
          <Dropdown.Header>
            <Layout align="center">
              <Avatar
                userProfile={viewer.profile!}
                width={48}
                className="mr-4 mt-1 mb-2"
              />
              <Layout
                direction="column"
                justify="space-between"
                className="overflow-hidden"
              >
                <Text truncate variant="body" color="body" className="mb-1">
                  {viewer.profile!.name}
                </Text>
                <Link
                  to={accountRoutes.settings()}
                  as={Text}
                  variant="body"
                  color="primary"
                  css={css`
                    cursor: pointer;
                  `}
                >
                  <FormattedMessage
                    defaultMessage="Profile settings"
                    id="headerMenu.profileSettings"
                  />
                </Link>
              </Layout>
            </Layout>
          </Dropdown.Header>
          {canSeeEnterpriseSettingsLink && (
            <Link as={Dropdown.Item} to={adminRoutes[routeTo]()}>
              <Layout align="center" data-bni-id="EnterpriseSettingsLink">
                <GearIcon width={20} height={20} className="mr-2" />
                <FormattedMessage
                  tagName="span"
                  id="headerMenu.enterpriseSettings"
                  defaultMessage="Enterprise settings"
                />
              </Layout>
            </Link>
          )}
          {canAccessOrganizationSettingsLink && !canSeeEnterpriseSettingsLink && (
            <Link
              as={Dropdown.Item}
              to={adminRoutes.organizationGeneral()}
              data-bni-id="HeaderMenuOrganizationSettings"
            >
              <Layout align="center">
                <GearIcon width={20} height={20} className="mr-2" />
                <FormattedMessage
                  tagName="span"
                  id="headerMenu.organizationSettings"
                  defaultMessage="Organization settings"
                />
              </Layout>
            </Link>
          )}
          {organization &&
            !canAccessOrganizationSettingsLink &&
            !canSeeEnterpriseSettingsLink && (
              <Link
                to={adminRoutes.organizationMembers()}
                as={Dropdown.Item}
                data-bni-id="HeaderMenuOrganizationMembers"
              >
                <Layout align="center">
                  <MembersIcon width={20} height={20} className="mr-2" />
                  <FormattedMessage
                    id="headerMenu.orgSwitcher.members"
                    defaultMessage="Organization members"
                  />
                </Layout>
              </Link>
            )}
          {canUseReferrals && organization && (
            <Link
              as={Dropdown.Item}
              to={configRoutes.referrals({
                organizationSlug: organization.slug!,
              })}
            >
              <Layout align="center">
                <ReferAFriendIcon width={20} height={20} className="mr-2" />
                {referAFriend}
              </Layout>
            </Link>
          )}
        </>

        <Dropdown.Divider />
        <Dropdown.Item onSelect={handleClickLogOut}>
          <FormattedMessage id="headerMenu.logOut" defaultMessage="Log Out" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default createFragmentContainer(AppHeaderUserDropdownButton, {
  organization: graphql`
    fragment AppHeaderUserDropdownButton_organization on Organization {
      slug
      name
    }
  `,
});
