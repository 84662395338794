/**
 * @generated SignedSource<<335707a8195ee6c1f64a6a5655a2b295>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type analyticsRoutes_AnalyticsBasePage_Query$variables = {};
export type analyticsRoutes_AnalyticsBasePage_Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"AnalyticsBasePage_viewer">;
  } | null;
};
export type analyticsRoutes_AnalyticsBasePage_Query = {
  response: analyticsRoutes_AnalyticsBasePage_Query$data;
  variables: analyticsRoutes_AnalyticsBasePage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "analyticsRoutes_AnalyticsBasePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnalyticsBasePage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "analyticsRoutes_AnalyticsBasePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  }
                ],
                "type": "TenantInterface",
                "abstractKey": "__isTenantInterface"
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ed27087e68c2bd7e42e6a204c384413",
    "id": null,
    "metadata": {},
    "name": "analyticsRoutes_AnalyticsBasePage_Query",
    "operationKind": "query",
    "text": "query analyticsRoutes_AnalyticsBasePage_Query {\n  viewer {\n    ...AnalyticsBasePage_viewer\n    id\n  }\n}\n\nfragment AnalyticsBasePage_viewer on Viewer {\n  ...BaseAppPage_viewer\n}\n\nfragment AppLeadingNav_viewer on Viewer {\n  domain {\n    ...AppSearchGlobal_tenant\n    id\n  }\n}\n\nfragment AppSearchGlobal_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ...SearchBarGlobal_tenant\n}\n\nfragment BaseAppPage_viewer on Viewer {\n  ...AppLeadingNav_viewer\n}\n\nfragment SearchBarGlobal_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ...useSearchState_tenant\n  ...getConcreteTenant_tenant\n  ... on Organization {\n    slug\n  }\n}\n\nfragment getConcreteTenant_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    type: __typename\n  }\n  ... on Organization {\n    type: __typename\n  }\n}\n\nfragment useSearchState_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Organization {\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "7442850b8ed299c95b34b39624738842";

export default node;
