import Progress from '@bfly/ui2/Progress';
import { colors } from '@bfly/ui2/tailwind/colors';
import { ComponentProps } from 'react';

type ProgressVariant = NonNullable<ComponentProps<typeof Progress>['variant']>;

export type ColoredSystemExamType =
  | 'abdomen'
  | 'aorta'
  | 'biliary'
  | 'cardiac'
  | 'dvt'
  | 'efast'
  | 'lung'
  | 'ob'
  | 'procedural-guidance'
  | 'renal'
  | 'us-guided-iv';

export type SystemExamTypes =
  | ColoredSystemExamType
  | 'appendix'
  | 'airway'
  | 'bladder'
  | 'bowel'
  | 'breast'
  | 'fast'
  | 'gyn'
  | 'iup'
  | 'msk'
  | 'nerve'
  | 'ocular'
  | 'pediatric-abdomen'
  | 'soft-tissue'
  | 'testicular'
  | 'thyroid';

export const SYSTEM_EXAM_TYPE_TO_COLOR: Record<ColoredSystemExamType, string> =
  {
    abdomen: colors.blue.DEFAULT,
    aorta: colors.purple,
    biliary: colors.pink,
    cardiac: colors.red.DEFAULT,
    dvt: colors.orange,
    efast: colors.yellow,
    lung: colors.limegreen,
    ob: colors.green,
    'procedural-guidance': colors.aquamarine,
    renal: colors.cyan,
    'us-guided-iv': colors.blue[40],
  };

const DEFAULT_EXAM_TYPE_COLOR = colors.grey[25];
const CUSTOM_EXAM_TYPE_COLOR = colors.grey[50];

export const getExamTypeColor = (
  examTypeSystemId?: string | ColoredSystemExamType | null,
): string =>
  !examTypeSystemId
    ? CUSTOM_EXAM_TYPE_COLOR
    : SYSTEM_EXAM_TYPE_TO_COLOR[examTypeSystemId] || DEFAULT_EXAM_TYPE_COLOR;

const examTypeToProgressVariant: Record<
  ColoredSystemExamType,
  ProgressVariant
> = {
  abdomen: 'blue',
  aorta: 'purple',
  biliary: 'pink',
  cardiac: 'red',
  dvt: 'orange',
  efast: 'yellow',
  lung: 'limegreen',
  ob: 'green',
  'procedural-guidance': 'aquamarine',
  renal: 'cyan',
  'us-guided-iv': 'blue40',
};

export const getExamTypeProgressVariant = (
  examTypeSystemId: string | null,
): ProgressVariant => {
  return examTypeToProgressVariant[examTypeSystemId!] || 'secondary';
};

export interface ExamTypeSortable {
  id?: string;
  name?: string | null;
  systemId?: string | null;
}

// We want to bucket a group of exam type stats into three parts:
//   1. highlighted system exam types sorted alphabetically
//   2. non-highlighted system exam types sorted alphabetically
//   3. custom exam types sorted alphabetically
export const sortByExamType = (
  a: ExamTypeSortable | null,
  b: ExamTypeSortable | null,
) => {
  if (!a || !b) return 0;
  if (a.systemId && b.systemId) {
    const aSystemId = a.systemId ?? '';
    const bSystemId = b.systemId ?? '';
    if (
      SYSTEM_EXAM_TYPE_TO_COLOR[aSystemId] &&
      SYSTEM_EXAM_TYPE_TO_COLOR[bSystemId]
    ) {
      if (aSystemId === bSystemId) return 0;
      return aSystemId > bSystemId ? 1 : -1;
    }
    if (
      SYSTEM_EXAM_TYPE_TO_COLOR[aSystemId] ||
      SYSTEM_EXAM_TYPE_TO_COLOR[bSystemId]
    ) {
      if (SYSTEM_EXAM_TYPE_TO_COLOR[aSystemId]) {
        return -1;
      }
      return 1;
    }
    if (aSystemId === bSystemId) return 0;
    return aSystemId > bSystemId ? 1 : -1;
  }
  if (a.systemId || b.systemId) {
    if (a.systemId) {
      return -1;
    }
    return 1;
  }
  return (a.name || '').localeCompare(b.name || '');
};
