/* eslint-disable dot-notation */
import Layout from '@4c/layout';
import Caret from '@bfly/ui2/Caret';
import Dropdown from '@bfly/ui2/Dropdown';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import { Link } from 'found';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from 'react-relay';

import AgGrid, { SaveState, defaultMessages } from 'components/AgGrid';
import { Column, ColumnArrayOption, ColumnDef } from 'components/AgGrid/types';
import ColumnArrayOptions from 'components/AgGrid/utils/ColumnArrayOptions';
import useGridEvents from 'components/AgGrid/utils/gridEvents';
import { useVariation } from 'components/LaunchDarklyContext';
import { useAdminRoutes } from 'routes/admin';

import MembershipType from '../../organizations/routes/members/config/MembershipType';
import {
  bulkActionsMessages,
  domainMembersGridColumnHeaders as columnHeaders,
  domainMembersGridMessages as messages,
} from '../messages/DomainMembersMessages';
import {
  DicomLabelInfo,
  InterfaceCodeInfo,
  Organization,
  Role,
  Row,
  User,
} from '../shared/DomainMembersGridTypes';
import BulkAddOrganizationConfirmationModal from './BulkAddOrganizationConfirmationModal';
import BulkAddOrganizationModal from './BulkAddOrganizationModal';
import BulkDomainUsersDeactivateUsersConfirmationModal from './BulkDomainUsersDeactivateUsersConfirmationModal';
import BulkDomainUsersReactivateUsersConfirmationModal from './BulkDomainUsersReactivateUsersConfirmationModal';
import BulkDomainUsersUpdateModal from './BulkDomainUsersUpdateModal';
import BulkDomainUsersUpdateRoleConfirmationModal from './BulkDomainUsersUpdateRoleConfirmationModal';
import BulkDomainUsersUpdateRoleModal from './BulkDomainUsersUpdateRoleModal';
import BulkRemoveOrganizationConfirmationModal from './BulkRemoveOrganizationConfirmationModal';
import BulkRemoveOrganizationModal from './BulkRemoveOrganizationModal';
import { DomainMembersGrid_viewer$data as Viewer } from './__generated__/DomainMembersGrid_viewer.graphql';

interface Props {
  domainId: string;
  viewer: Viewer;
  relay: RelayPaginationProp;
  dicomLabels: DicomLabelInfo[];
  interfaceCodes: InterfaceCodeInfo[];
  membershipRoles: Role[];
  domainOrganizations: Organization[];
  editable: boolean;
  isInactive: boolean;
}

const DomainMembersGrid = ({
  domainId,
  viewer,
  relay,
  dicomLabels,
  interfaceCodes,
  membershipRoles,
  domainOrganizations,
  editable,
  isInactive,
}: Props) => {
  const hasNext = relay.hasMore();

  if (hasNext && !relay.isLoading()) {
    relay.loadMore(5000);
  }

  const { formatMessage } = useIntl();

  const inactiveUsersEnabled = useVariation('inactive-users');

  const [rowData, setRowData] = useState<Row[]>([]);
  const [dataOptions, setDataOptions] = useState({
    roles: [] as ColumnArrayOption[],
    organizations: [] as ColumnArrayOption[],
  });

  const setRowDataFromUsers = useCallback(() => {
    relay.refetchConnection(2147483647);

    const users: User[] = viewer?.domain?.users
      ? getNodes(viewer.domain.users)
      : [];

    const rows = users.map((user): Row => {
      const dicomData: Record<string, string | null> = {};
      dicomLabels.forEach((item) => {
        if (item.id)
          dicomData[`dicom,${item.id}`] =
            user?.dicomFields.find((d) => d.dicomFieldTemplate?.id === item.id)
              ?.value || null;
      });

      const interfaceCodesData: Record<string, string | null> = {};
      interfaceCodes.forEach((item) => {
        if (item.id)
          interfaceCodesData[`interfaceCode,${item.id}`] =
            user?.integrationConfigs.find((d) => d.ehr?.id === item.id)
              ?.interfaceCode || null;
      });

      const organizations =
        user.organizations?.filter((o) => o?.id).map((o) => o!.id) || [];

      return {
        ...user,
        organizations,
        role: user.role?.id ? [user.role.id] : [],
        dateLastActive: user.dateLastActive
          ? new Date(user.dateLastActive).toLocaleDateString(undefined, {
              weekday: undefined,
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })
          : null,
        ...interfaceCodesData,
        ...dicomData,
      };
    });

    const roleOptions = ColumnArrayOptions();
    const organizationOptions = ColumnArrayOptions();

    membershipRoles.forEach((role) => {
      roleOptions.add(role.id, role.name);
    });

    domainOrganizations.forEach((org) => {
      organizationOptions.add(org.id, org.name);
    });

    setDataOptions({
      roles: roleOptions.get(),
      organizations: organizationOptions.get(),
    });

    setRowData(rows);
  }, [
    dicomLabels,
    interfaceCodes,
    membershipRoles,
    domainOrganizations,
    viewer.domain?.users,
    relay,
  ]);

  useEffect(() => setRowDataFromUsers(), [setRowDataFromUsers]);

  const interfaceCodesColumns = useMemo(
    () =>
      interfaceCodes?.map(
        ({ name, id }, index): Column => ({
          colId: `interfaceCode,${id}`,
          headerName: name,
          sortable: true,
          editable,
          columnGroupShow: index > 0 ? 'open' : undefined,
        }),
      ) || [],
    [editable, interfaceCodes],
  );

  const dicomColumns = useMemo(
    () =>
      dicomLabels?.map(({ label, id }, index) => ({
        colId: `dicom,${id}`,
        headerName: label,
        sortable: true,
        editable,
        columnGroupShow: index > 0 ? 'open' : undefined,
      })) || [],
    [dicomLabels, editable],
  );

  const columns = useMemo((): ColumnDef[] => {
    const permissionColumns: ColumnDef[] = [
      {
        colId: 'role',
        headerName: formatMessage(columnHeaders.butterflyAccessRole),
        sortable: true,
        type: 'array',
        multiple: 'filter',
        options: dataOptions.roles,
      },
    ];

    return [
      {
        colId: 'email',
        headerName: formatMessage(columnHeaders.emailAddress),
        pinned: 'left',
        cellRenderer: function EmailRenderer(props: ICellRendererParams) {
          const adminRoutes = useAdminRoutes();
          if (!isInactive) {
            return (
              <Link
                to={adminRoutes.domainMember({
                  domainUserId: props.data.id,
                })}
              >
                {props.value}
              </Link>
            );
          }
        },
        sortable: true,
      },
      {
        colId: 'integrationDisplayNameFirst',
        headerName: formatMessage(columnHeaders.firstName),
        sortable: true,
        editable,
      },
      {
        colId: 'integrationDisplayNameMiddle',
        headerName: formatMessage(columnHeaders.middleInitial),
        sortable: true,
        editable,
      },
      {
        colId: 'integrationDisplayNameLast',
        headerName: formatMessage(columnHeaders.lastName),
        sortable: true,
        editable,
      },
      {
        colId: 'organizations',
        headerName: formatMessage(columnHeaders.organizations),
        multiple: 'filter',
        options: dataOptions.organizations,
        type: 'array',
      },
      ...permissionColumns,
      {
        groupId: 'interfaceCodes',
        headerName: formatMessage(columnHeaders.interfaceCodes),
        children: interfaceCodesColumns,
      },
      {
        groupId: 'dicom',
        headerName: formatMessage(columnHeaders.dicomFieldMappings),
        children: dicomColumns,
      },
      {
        colId: 'updatedAt',
        headerName: formatMessage(columnHeaders.dateEdited),
        type: 'date',
        sortable: true,
      },
      {
        colId: 'dateLastActive',
        headerName: formatMessage(columnHeaders.dateLastActive),
        sortable: true,
      },
    ];
  }, [
    formatMessage,
    dataOptions.roles,
    dataOptions.organizations,
    editable,
    interfaceCodesColumns,
    dicomColumns,
    isInactive,
  ]);

  const [showDomainUsersUpdateModal, setShowDomainUsersUpdateModal] =
    useState(false);

  const [selectedRows, setSelectedRows] = useState<Row[]>([]);

  const [showDomainUsersUpdateRoleModal, setShowDomainUsersUpdateRoleModal] =
    useState(false);
  const [
    showDomainUsersUpdateRoleConfirmationModal,
    setShowDomainUsersUpdateRoleConfirmationModal,
  ] = useState(false);
  const [
    showAddOrganizationConfirmationModal,
    setShowAddOrganizationConfirmationModal,
  ] = useState(false);
  const [
    showRemoveOrganizationConfirmationModal,
    setShowRemoveOrganizationConfirmationModal,
  ] = useState(false);
  const [
    showBulkDomainUsersDeactivateUsersConfirmationModal,
    setBulkDomainUsersDeactivateUsersConfirmationModal,
  ] = useState(false);
  const [
    showBulkDomainUsersReactivateUsersConfirmationModal,
    setShowBulkDomainUsersReactivateUsersConfirmationModal,
  ] = useState(false);
  const [hasAddOrganizationModalOpen, setHasAddOrganizationModalOpen] =
    useState(false);
  const [hasRemoveOrganizationModalOpen, setHasRemoveOrganizationModalOpen] =
    useState(false);
  const [isDeletingAction, setIsDeletingAction] = useState(false);
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Organization[]
  >([]);

  // FIXME: This needs to be ripped out eventually, since it no longer does anything.
  const selectedType = MembershipType.REGULAR;

  const [selectedRole, setSelectedRole] = useState<Role>();

  const { deselectAll } = useGridEvents<Row>({
    onSelect: (rows) => {
      setSelectedRows(rows);
    },
  });

  const [saveState, setSaveState] = useState<SaveState>();

  useEffect(() => {
    setSelectedRows([]);
  }, [isInactive]);

  return (
    <>
      <Layout
        data-bni-id="BulkActionsControl"
        align="center"
        className={clsx(
          'pl-8',
          'mt-4',
          'mb-5',
          selectedRows.length > 0
            ? 'opacity-1'
            : 'opacity-0 pointer-events-none',
        )}
      >
        <Text variant="body-bold">
          <FormattedMessage
            {...messages.membersSelected}
            values={{
              numMembers: selectedRows.length,
            }}
          />
        </Text>
        <Dropdown className="ml-5">
          <Dropdown.Toggle data-bni-id="BulkActionsBtn">
            <FormattedMessage {...messages.bulkActions} />
            <Caret />
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark">
            {isInactive ? (
              <Dropdown.Item
                data-bni-id="BulkReactivateUsers"
                onClick={() =>
                  setShowBulkDomainUsersReactivateUsersConfirmationModal(true)
                }
              >
                <FormattedMessage
                  {...bulkActionsMessages.bulkReactivateUsers}
                />
              </Dropdown.Item>
            ) : (
              <>
                <Dropdown.Item
                  data-bni-id="BulkActionsAddOrganizations"
                  onClick={() => setHasAddOrganizationModalOpen(true)}
                >
                  <FormattedMessage
                    {...bulkActionsMessages.addOrganizations}
                  />
                </Dropdown.Item>
                <Dropdown.Item
                  data-bni-id="BulkActionsRemoveOrganizations"
                  onClick={() => setHasRemoveOrganizationModalOpen(true)}
                >
                  <FormattedMessage
                    {...bulkActionsMessages.removeOrganizations}
                  />
                </Dropdown.Item>
                <Dropdown.Item
                  data-bni-id="BulkActionsUpdateRole"
                  onClick={() => setShowDomainUsersUpdateRoleModal(true)}
                >
                  <FormattedMessage {...messages.bulkUpdateRoles} />
                </Dropdown.Item>
                {inactiveUsersEnabled && (
                  <Dropdown.Item
                    data-bni-id="BulkDeactivateUsers"
                    onClick={() =>
                      setBulkDomainUsersDeactivateUsersConfirmationModal(true)
                    }
                    variant="danger"
                  >
                    <FormattedMessage {...messages.bulkDeactivateUsers} />
                  </Dropdown.Item>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Layout>
      {!!rowData.length && (
        <AgGrid
          showResetColumnState
          id="BulkUserUpdateGrid"
          onSaveStateUpdate={(state) => {
            setShowDomainUsersUpdateModal(true);
            setSaveState(state);
          }}
          rowData={rowData}
          columns={columns}
          loading={hasNext || !dicomLabels || !interfaceCodes}
          selectable
          confirmDiscard
          messages={{
            ...defaultMessages,
            saveError: {
              id: 'bulkDomainUsersUpdateModal.toastError',
              defaultMessage: `An unexpected error occurred.`,
            },
            saveSuccess: {
              id: 'bulkDomainUsersUpdateModal.toastSuccess',
              defaultMessage: `{count} {count, plural,
                one {user}
                other {users}
              } updated.`,
            },
          }}
        />
      )}
      {saveState && (
        <BulkDomainUsersUpdateModal
          domainId={domainId}
          show={showDomainUsersUpdateModal}
          changes={saveState.changes}
          rowData={rowData}
          onHide={() => {
            saveState.cancelled();
            setShowDomainUsersUpdateModal(false);
          }}
          onSuccess={(count: number) => {
            setShowDomainUsersUpdateModal(false);
            saveState.success(count);
          }}
          onError={saveState.error}
          dicomLabels={dicomLabels}
          interfaceCodes={interfaceCodes}
        />
      )}
      <BulkAddOrganizationModal
        organizations={domainOrganizations || []}
        show={hasAddOrganizationModalOpen}
        onHide={() => {
          setHasAddOrganizationModalOpen(false);
        }}
        onSuccess={(organizationsList) => {
          setSelectedOrganizations(organizationsList);
          setHasAddOrganizationModalOpen(false);
          setShowAddOrganizationConfirmationModal(true);
        }}
      />
      {selectedOrganizations.length > 0 && selectedRows.length > 0 && (
        <BulkAddOrganizationConfirmationModal
          domainId={domainId}
          type={selectedType}
          members={selectedRows}
          selectedOrganizations={selectedOrganizations}
          show={showAddOrganizationConfirmationModal}
          onHide={() => {
            setShowAddOrganizationConfirmationModal(false);
          }}
          onSuccess={() => {
            deselectAll();
            setSelectedRows([]);
            setShowAddOrganizationConfirmationModal(false);
          }}
        />
      )}
      <BulkRemoveOrganizationModal
        organizations={domainOrganizations || []}
        show={hasRemoveOrganizationModalOpen}
        onHide={() => {
          setIsDeletingAction(false);
          setHasRemoveOrganizationModalOpen(false);
        }}
        onSuccess={(organizationsList) => {
          setSelectedOrganizations(organizationsList);
          setHasRemoveOrganizationModalOpen(false);
          setIsDeletingAction(true);
          setShowRemoveOrganizationConfirmationModal(true);
        }}
      />
      {selectedOrganizations.length > 0 &&
        selectedRows.length > 0 &&
        isDeletingAction && (
          <BulkRemoveOrganizationConfirmationModal
            domainId={domainId}
            members={selectedRows}
            selectedOrganizations={selectedOrganizations}
            show={showRemoveOrganizationConfirmationModal}
            onHide={() => {
              setShowRemoveOrganizationConfirmationModal(false);
            }}
            onSuccess={() => {
              deselectAll();
              setIsDeletingAction(false);
              setSelectedRows([]);
              setShowRemoveOrganizationConfirmationModal(false);
            }}
          />
        )}
      <BulkDomainUsersUpdateRoleModal
        roles={membershipRoles}
        show={showDomainUsersUpdateRoleModal}
        onHide={() => setShowDomainUsersUpdateRoleModal(false)}
        onSuccess={(role) => {
          setSelectedRole(role);
          setShowDomainUsersUpdateRoleModal(false);
          setShowDomainUsersUpdateRoleConfirmationModal(true);
        }}
      />
      {selectedRole && selectedRows.length > 0 && (
        <BulkDomainUsersUpdateRoleConfirmationModal
          domainId={domainId}
          selectedUsers={selectedRows!.map(
            ({
              email,
              integrationDisplayNameFirst,
              integrationDisplayNameLast,
            }) => ({
              email: email!,
              roleId: selectedRole!.id,
              integrationDisplayNameFirst: integrationDisplayNameFirst!,
              integrationDisplayNameLast: integrationDisplayNameLast!,
            }),
          )}
          show={showDomainUsersUpdateRoleConfirmationModal}
          onHide={() => {
            setShowDomainUsersUpdateRoleConfirmationModal(false);
          }}
          onSuccess={() => {
            deselectAll();
            setSelectedRows([]);
            setShowDomainUsersUpdateRoleConfirmationModal(false);
          }}
        />
      )}
      {selectedRows.length > 0 && (
        <BulkDomainUsersReactivateUsersConfirmationModal
          domainId={domainId}
          show={showBulkDomainUsersReactivateUsersConfirmationModal}
          onHide={() => {
            setShowBulkDomainUsersReactivateUsersConfirmationModal(false);
          }}
          onSuccess={() => {
            deselectAll();
            setSelectedRows([]);
            setShowBulkDomainUsersReactivateUsersConfirmationModal(false);
          }}
          selectedUsers={selectedRows!.map(
            ({
              email,
              integrationDisplayNameFirst,
              integrationDisplayNameLast,
            }) => ({
              email: email!,
              integrationDisplayNameFirst: integrationDisplayNameFirst!,
              integrationDisplayNameLast: integrationDisplayNameLast!,
            }),
          )}
          numAvailableSeats={viewer.domain?.numAvailableSeats}
        />
      )}
      {inactiveUsersEnabled && selectedRows.length > 0 && (
        <BulkDomainUsersDeactivateUsersConfirmationModal
          domainName={viewer.domain!.name!}
          domainId={domainId}
          selectedUsers={selectedRows!.map(
            ({
              id,
              email,
              integrationDisplayNameFirst,
              integrationDisplayNameLast,
            }) => ({
              domainUserId: id,
              email: email!,
              integrationDisplayNameFirst: integrationDisplayNameFirst!,
              integrationDisplayNameLast: integrationDisplayNameLast!,
            }),
          )}
          show={showBulkDomainUsersDeactivateUsersConfirmationModal}
          onHide={() => {
            setBulkDomainUsersDeactivateUsersConfirmationModal(false);
          }}
          onSuccess={() => {
            deselectAll();
            setSelectedRows([]);
            setBulkDomainUsersDeactivateUsersConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

const _ = graphql`
  fragment DomainMembersGrid_domainUser on DomainUser {
    id
    email
    integrationDisplayNameFirst
    integrationDisplayNameMiddle
    integrationDisplayNameLast
    canQa
    canFinalize
    type
    dicomFields {
      dicomFieldTemplate {
        id
        label
      }
      value
    }
    integrationConfigs {
      ehr {
        id
        handle
      }
      interfaceCode
    }
    role {
      name
      id
    }
    organizations {
      id
      name
    }
    profile {
      id
    }
    updatedAt
    dateLastActive
  }
`;

export default createPaginationContainer(
  DomainMembersGrid,
  {
    viewer: graphql`
      fragment DomainMembersGrid_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 500 } # PAGE_SIZE.
        cursor: { type: "String" }
        search: { type: "String", defaultValue: null }
        isDeleted: { type: "Boolean" }
      ) {
        domain {
          name
          numAvailableSeats
          users(
            first: $count
            after: $cursor
            search: $search
            sort: NAME_ASC
            isDeleted: $isDeleted
          ) @connection(key: "domain_grid_users") {
            edges {
              node {
                ...DomainMembersGrid_domainUser @relay(mask: false)
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: ({ viewer }) => viewer.domain!.users,
    getVariables: (_props, { count, cursor }, variables) => ({
      ...variables,
      count,
      cursor,
    }),
    query: graphql`
      query DomainMembersGridQuery(
        $count: Int!
        $cursor: String
        $search: String
        $isDeleted: Boolean
      ) {
        viewer {
          ...DomainMembersGrid_viewer
            @arguments(
              count: $count
              cursor: $cursor
              search: $search
              isDeleted: $isDeleted
            )
        }
      }
    `,
  },
);
