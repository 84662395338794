import BlankSlate from '@bfly/ui2/BlankSlate';
import Link from '@bfly/ui2/Link';
import MainContent from '@bfly/ui2/MainContent';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useEducationRoutes } from 'routes/education';

function EducationVideoNotFoundPage() {
  const educationRoutes = useEducationRoutes();

  return (
    <MainContent centerText data-bni-id="VideoNotFoundPage">
      <BlankSlate>
        <BlankSlate.Title>
          <FormattedMessage
            id="videoNotFoundPage.title"
            defaultMessage="Video Not Found"
          />
        </BlankSlate.Title>
        <BlankSlate.Body>
          <FormattedMessage
            id="videoNotFoundPage.body"
            defaultMessage="The video you are looking for cannot be found."
          />
        </BlankSlate.Body>
        <Link
          as={BlankSlate.Action}
          to={educationRoutes.education()}
          size="lg"
        >
          <FormattedMessage
            id="videoNotFoundPage.returnHome"
            defaultMessage="Return to Video List"
          />
        </Link>
      </BlankSlate>
    </MainContent>
  );
}

export default EducationVideoNotFoundPage;
