import { InferType, array, boolean, object, string } from 'yup';

import { dateRangeWithValidation } from 'schemas/dateRange';
import { StudyStatus } from 'utils/StudyConstants';

const arrayOfIds = array(string().defined())
  .nullable()
  .defined()
  .default(null);

const nullableString = string().defined().nullable().default(null);

const dateRange = dateRangeWithValidation.nullable().default(null);

const labeledValue = object({
  label: string().required(),
  value: string().required(),
}).required();

const schema = object({
  organization: arrayOfIds,
  archive: arrayOfIds,
  author: array(labeledValue).nullable(),
  primary: boolean().nullable().defined().default(false),

  capturedAt: dateRange,
  examType: arrayOfIds,
  freeText: string().defined().default(''),
  tag: arrayOfIds,
  worksheetTemplate: arrayOfIds,
  allWorksheetTemplates: arrayOfIds,
  accessionNumber: nullableString,
  patientBirthDate: dateRange,
  patientNameFirst: nullableString,
  patientMedicalRecordNumber: nullableString,
  patientNameLast: nullableString,
  patientNameMiddle: nullableString,
  reviewer: array(labeledValue).nullable(),
  status: array(
    string()
      .defined()
      .enum([
        StudyStatus.DRAFT,
        StudyStatus.PENDING_ATTESTATION,
        StudyStatus.FINALIZED,
        StudyStatus.NEEDS_REVIEW,
        StudyStatus.REVIEWED,
      ]),
  )
    .defined()
    .nullable()
    .default(null),
}).defined();

export type SearchDataSchema = InferType<typeof schema>;
export default schema;
