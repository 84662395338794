import { ButtonVariant } from '@bfly/ui2/Button';
import useDialog from '@bfly/ui2/useDialog';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import useRouter from 'found/useRouter';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useExamRoutes } from 'routes/exam';
import { useCanUndeleteStudy } from 'utils/StudyPermissions';

import type { UndeleteStudyControlMutation } from './__generated__/UndeleteStudyControlMutation.graphql';
import type { UndeleteStudyControl_study$data as Study } from './__generated__/UndeleteStudyControl_study.graphql';

const mutation = graphql`
  mutation UndeleteStudyControlMutation($input: UndeleteStudyInput!) {
    undeleteStudyOrError(input: $input) {
      ... on UndeleteStudyPayload {
        study {
          deletedAt
          # Fetch this here to avoid double-fetching study images in control.
          imageConnection(first: 1) {
            edges {
              node {
                handle
              }
            }
          }
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  study: Study;
  as: React.ElementType;
  children: React.ReactNode;
  variant?: ButtonVariant;
}

function UndeleteStudyControl({
  as: Component,
  study,
  variant,
  children,
}: Props) {
  const examRoutes = useExamRoutes();
  const dialog = useDialog();
  const toast = useToast();
  const { router, match } = useRouter();

  const [mutate] = useMutationWithError<UndeleteStudyControlMutation>(
    mutation,
    {
      input: {
        studyId: study.id,
      },
      updater: (store) => {
        rangeDeleteUpdater(store, {
          parentId: study.organization!.id,
          connectionKey: 'Organization_studyConnection',
          connectionFilters: (vars) => {
            const deletedFilter = vars?.isDeleted || [];
            return Array.isArray(deletedFilter)
              ? deletedFilter.includes(true) && !deletedFilter.includes(false)
              : !!deletedFilter;
          },
          deletedId: study.id,
        });
        rangeDeleteUpdater(store, {
          parentId: study.organization!.id,
          connectionKey: 'Organization_studySearchConnection',
          connectionFilters: (vars) => {
            const deletedFilter = vars?.search?.isDeleted || [];
            return Array.isArray(deletedFilter)
              ? deletedFilter.includes(true) && !deletedFilter.includes(false)
              : !!deletedFilter;
          },
          deletedId: study.id,
        });
      },
    },
  );

  const handleClick = async () => {
    const result = await dialog.open(
      <FormattedMessage
        id="study.undelete.dialog.content"
        defaultMessage="This study will be restored to {archiveLabel}."
        values={{ archiveLabel: study.archive!.label }}
      />,
      {
        modalVariant: 'dark',
        size: 'sm',
        runConfirm: () => mutate(),
        title: (
          <FormattedMessage
            id="study.undelete.dialog.title"
            defaultMessage="Restore exam?"
          />
        ),
        confirmLabel: (
          <FormattedMessage
            id="study.undelete.dialog.confirm"
            defaultMessage="Restore"
          />
        ),
      },
    );

    if (!result) {
      return;
    }

    // After undeleting from the deleted studies page, navigate to the study.
    if (match.params.studyHandle !== study.handle) {
      const imageEdges =
        result.undeleteStudyOrError!.study!.imageConnection!.edges;

      // TODO: Add a study detail page so we have somewhere to which to
      // redirect if the study has no images.
      if (imageEdges) {
        router.push(
          examRoutes.examImage({
            organizationSlug: study.organization!.slug!,
            studyHandle: study.handle!,
            studyImageHandle: imageEdges[0]!.node!.handle!,
          }),
        );
      }
    }

    toast.success(
      <FormattedMessage
        id="study.undelete.success"
        defaultMessage="Exam restored successfully"
      />,
    );
  };

  const canUndeleteStudy = useCanUndeleteStudy(study);

  if (!canUndeleteStudy) {
    return null;
  }

  return (
    <Component onClick={handleClick} variant={variant}>
      {children}
    </Component>
  );
}

export default createFragmentContainer(UndeleteStudyControl, {
  study: graphql`
    fragment UndeleteStudyControl_study on Study {
      id
      organization {
        id
        slug
      }
      handle
      archive {
        label
      }
      ...StudyPermissions_canUndeleteStudy
    }
  `,
});
