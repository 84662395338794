import useGlobalKeyDownListener from '@bfly/ui2/useGlobalKeyDownListener';
import type { LocationDescriptor } from 'found';
import useRouter from 'found/useRouter';
import { useCallback } from 'react';

import { Match } from 'components/Route';

interface ElementBase {
  handle: string | null;
}

interface Options {
  activeHandle?: string | null;
  to: (handle: string, match: Match) => LocationDescriptor;
  onSelect?: (index: number) => void;
}

export default function useElementsNavigation<TElement extends ElementBase>(
  elements: ReadonlyArray<TElement>,
  { to, activeHandle = elements[0]?.handle, onSelect }: Options,
) {
  const { match, router } = useRouter();

  const setActiveIndex = useCallback(
    (nextActiveIndex: number) => {
      const nextElement = elements[nextActiveIndex];

      if (!nextElement) {
        return;
      }

      router.replace(to(nextElement.handle!, match));
    },
    [elements, router, to, match],
  );

  const activeIndex = elements.findIndex(
    ({ handle }) => handle === activeHandle,
  );

  const notFound = activeIndex === -1;

  const prev = useCallback(() => {
    setActiveIndex(activeIndex - 1);
  }, [setActiveIndex, activeIndex]);

  const next = useCallback(() => {
    setActiveIndex(activeIndex + 1);
  }, [setActiveIndex, activeIndex]);

  useGlobalKeyDownListener(({ key }) => {
    if (key === 'ArrowLeft' || key === 'Left') {
      prev();
    } else if (key === 'ArrowRight' || key === 'Right') {
      next();
    } else if (key === 'Enter' && onSelect) {
      onSelect(activeIndex);
    }
  });

  return [
    elements[activeIndex] as TElement | undefined,
    {
      activeIndex,

      isFirst: notFound || activeIndex === 0,
      isLast: notFound || activeIndex === elements.length - 1,

      setActiveIndex,
      prev,
      next,
    },
  ] as const;
}
