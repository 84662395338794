/**
 * @generated SignedSource<<5b6dad98d0e2f0621113c03d26e0dc6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileSharedImageDetail_images$data = ReadonlyArray<{
  readonly handle: string | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MobileSharedStudyImage_image" | "StudyImage_image">;
  readonly " $fragmentType": "MobileSharedImageDetail_images";
}>;
export type MobileSharedImageDetail_images$key = ReadonlyArray<{
  readonly " $data"?: MobileSharedImageDetail_images$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileSharedImageDetail_images">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MobileSharedImageDetail_images",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyImage_image"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileSharedStudyImage_image"
    }
  ],
  "type": "SharedBundleImage",
  "abstractKey": null
};

(node as any).hash = "56d05e22e9a7a5ad4766186f108448ba";

export default node;
