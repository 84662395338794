/**
 * @generated SignedSource<<fd36b0f6f2bc289a4c21db3a840dece1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type fieldDefinition_fieldDefinition$data = {
  readonly allowOther?: boolean | null;
  readonly handle: string | null;
  readonly options?: ReadonlyArray<{
    readonly handle: string | null;
    readonly label: string | null;
  } | null> | null;
  readonly required: boolean | null;
  readonly typename: string;
  readonly " $fragmentType": "fieldDefinition_fieldDefinition";
};
export type fieldDefinition_fieldDefinition$key = {
  readonly " $data"?: fieldDefinition_fieldDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"fieldDefinition_fieldDefinition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "fieldDefinition_fieldDefinition"
};

(node as any).hash = "77a809d6b1239b9a0943f7ad900173a3";

export default node;
