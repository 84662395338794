import Layout from '@4c/layout';
import CloseSmIcon from '@bfly/icons/CloseSm';
import Crayon from '@bfly/icons/Crayon';
import SearchIcon from '@bfly/icons/Search';
import Button from '@bfly/ui2/Button';
import Dropdown from '@bfly/ui2/Dropdown';
import FormCheck from '@bfly/ui2/FormCheck';
import FormControlWithAddon from '@bfly/ui2/FormControlWithAddon';
import ItemGrid from '@bfly/ui2/ItemGrid';
import Toast from '@bfly/ui2/Toast';
import { useToast } from '@bfly/ui2/ToastContext';
import clsx from 'clsx';
import FeatureFlags from 'config/FeatureFlags';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import featureFlagDefaults from 'config/featureFlagDefaults';

import useAutoSaveState from '../../shared/hooks/useAutoSave';
import { featureFlagOverridesStore } from '../../utils/LaunchDarklyManager';
import { useDevContext } from './devContext';

const flagOptions: Partial<Record<keyof FeatureFlags, string[]>> = {
  'butterfly-academy-access': ['hidden', 'locked', 'enabled'],
  'study-sharing': ['enabled', 'disabled', 'hidden'],
  'custom-protocols': ['enabled', 'disabled', 'hidden'],
};

const exists = (key: string, obj?: Record<string, any>) =>
  obj && Object.prototype.hasOwnProperty.call(obj, key);

function DropdownField({
  overrides,
  flagKey,
  setOverride,
  options,
}: {
  overrides: Partial<FeatureFlags>;
  flagKey: keyof FeatureFlags;
  setOverride: (flagKey: keyof FeatureFlags, value: boolean) => void;
  options: any[];
}) {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="text-secondary" className="p-0">
        {exists(flagKey, overrides) ? (
          <span className="font-mono text-sm whitespace-nowrap overflow-hidden text-primary">
            {JSON.stringify(overrides[flagKey])}
          </span>
        ) : (
          <Crayon className="w-3" />
        )}
      </Dropdown.Toggle>
      {createPortal(
        <Dropdown.Menu variant="dark" className="min-w-20 z-tooltip">
          {options.map((value) => (
            <Dropdown.Item
              key={value.toString()}
              active={overrides[flagKey] === value}
              onClick={() => setOverride(flagKey, value)}
            >
              {value.toString()}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>,
        document.body,
      )}
    </Dropdown>
  );
}

export default function LaunchDarklyFlags({
  featureFlagOverrides,
  featureFlags,
}: {
  featureFlagOverrides: Partial<FeatureFlags>;
  featureFlags: FeatureFlags;
}) {
  const { search, setDevState } = useDevContext();

  const toast = useToast();

  const successToast = () => {
    const props = {
      key: 1234,
      message: <>Feature flag override saved</>,
      callToAction: (
        <Toast.Action onClick={() => window.location.reload()}>
          Refresh Page
        </Toast.Action>
      ),
    };

    toast.removeToast(props);

    toast.success(props);
  };

  const [overrides, setOverrides, AutoSave] = useAutoSaveState(
    featureFlagOverrides || {},
    () => {
      successToast();
      return Promise.resolve();
    },
  );

  const currentValues = {
    ...featureFlagDefaults,
    ...featureFlags,
  };

  const setOverride = (key: keyof FeatureFlags, value: any) => {
    const next = { ...overrides };
    next[key] = value;
    if (value === currentValues[key] || value === undefined || value === null)
      delete next[key];

    setOverrides(featureFlagOverridesStore.set(next));
  };

  const clearOverrides = () => {
    setOverrides(featureFlagOverridesStore.set({}), true);
  };

  const overridesCount = Object.keys(overrides).length;

  const [showOverrideOnly, toggleOverride] = useState(false);

  useEffect(() => {
    if (search) toggleOverride(false);
  }, [search]);

  const setSearch = (next: string) => {
    setDevState((p) => ({ ...p, search: next }));
  };

  return (
    <>
      <Layout className="items-center mb-4" pad={4}>
        <FormControlWithAddon
          variant="secondary"
          className="min-w-2/5 grow"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          addon={
            <>
              <SearchIcon
                className="order-first w-5 flex-shrink-0 ml-3 text-grey-25"
                onChange={(e) =>
                  setSearch((e.target as HTMLInputElement).value)
                }
              />
              {search && (
                <Button variant="text-secondary" onClick={() => setSearch('')}>
                  <CloseSmIcon className="w-3 flex-shrink-0" />
                </Button>
              )}
            </>
          }
        />
        <div className="font-bold">
          {overridesCount ? (
            <FormCheck
              className="m-0"
              onClick={() => {
                toggleOverride((t) => {
                  if (!t) setSearch('');
                  return !t;
                });
              }}
            >
              <FormattedMessage
                id="developerConsole.launchDarklyFlags.overridesLabel"
                defaultMessage="{overridesCount} {overridesCount, plural, one {override} other {overrides}}"
                values={{ overridesCount }}
              />
            </FormCheck>
          ) : (
            <FormattedMessage
              id="developerConsole.launchDarklyFlags.overrides"
              defaultMessage="{overridesCount} {overridesCount, plural, one {override} other {overrides}}"
              values={{ overridesCount }}
            />
          )}
        </div>
        <Layout justify="flex-end" className="w-10">
          <AutoSave>
            <Button
              variant="text-danger"
              className="px-0"
              onClick={() => clearOverrides()}
              disabled={!overridesCount}
            >
              Clear
            </Button>
          </AutoSave>
        </Layout>
      </Layout>
      <Layout
        direction="column"
        className="min-w-0 scrollable-y text-md text-white"
      >
        <ItemGrid templateColumns="2fr 168px 168px 168px">
          <thead>
            <ItemGrid.Row>
              <ItemGrid.Header className="sticky top-0 bg-grey-90 z-sticky pl-4">
                Key
              </ItemGrid.Header>
              <ItemGrid.Header className="sticky top-0 bg-grey-90 z-sticky">
                Default Value
              </ItemGrid.Header>
              <ItemGrid.Header className="sticky top-0 bg-grey-90 z-sticky">
                User&apos;s Value
              </ItemGrid.Header>
              <ItemGrid.Header className="sticky top-0 bg-grey-90 z-sticky pr-6">
                Local Value
              </ItemGrid.Header>
            </ItemGrid.Row>
          </thead>
          <tbody>
            {Object.entries(featureFlagDefaults)
              .sort(([key1], [key2]) => (key1 > key2 ? 1 : -1))
              .filter(([key]) => {
                if (search)
                  return key.toLowerCase().includes(search.toLowerCase());

                if (overridesCount && showOverrideOnly)
                  return exists(key, overrides);

                return true;
              })
              .map(([key, defaultValue]: [keyof FeatureFlags, any], index) => {
                const fieldOptions =
                  typeof defaultValue === 'boolean'
                    ? [true, false]
                    : flagOptions[key];

                return (
                  <ItemGrid.Row key={key}>
                    <ItemGrid.Cell
                      className={clsx('pl-4', index % 2 && 'bg-grey-85')}
                    >
                      <a
                        className="hover:text-primary"
                        href={`https://app.launchdarkly.com/default/dev/features/${key}/targeting`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {key}
                      </a>
                    </ItemGrid.Cell>
                    <ItemGrid.Cell
                      className={clsx(
                        'font-mono text-sm whitespace-nowrap overflow-hidden',
                        !exists(key, featureFlags) &&
                          !exists(key, overrides) &&
                          'text-primary',
                        index % 2 && 'bg-grey-85',
                      )}
                    >
                      <div>{JSON.stringify(defaultValue)}</div>
                    </ItemGrid.Cell>
                    <ItemGrid.Cell
                      className={clsx(
                        'font-mono text-sm whitespace-nowrap overflow-hidden',
                        !exists(key, overrides) && 'text-primary',
                        index % 2 && 'bg-grey-85',
                      )}
                    >
                      <div
                        title={JSON.stringify(featureFlags[key], null, '\t')}
                      >
                        {JSON.stringify(featureFlags[key])}
                      </div>
                    </ItemGrid.Cell>
                    <ItemGrid.Cell
                      className={clsx('pr-6', index % 2 && 'bg-grey-85')}
                    >
                      <Layout className="items-center" pad={2}>
                        {fieldOptions && (
                          <DropdownField
                            flagKey={key}
                            overrides={overrides}
                            setOverride={setOverride}
                            options={fieldOptions}
                          />
                        )}
                      </Layout>
                    </ItemGrid.Cell>
                  </ItemGrid.Row>
                );
              })}
          </tbody>
        </ItemGrid>
      </Layout>
    </>
  );
}
