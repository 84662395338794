import Layout from '@4c/layout';
import CheckMarkSmall from '@bfly/icons/CheckMarkSmall';
import DropdownIcon from '@bfly/icons/Dropdown';
import Information from '@bfly/icons/Information';
import { UserPermission } from '@bfly/olympus-protobuf';
import Button from '@bfly/ui2/Button';
import Card from '@bfly/ui2/Card';
import Collapse from '@bfly/ui2/Collapse';
import DropdownList from '@bfly/ui2/DropdownList';
import { useField } from '@bfly/ui2/Form';
import Heading from '@bfly/ui2/Heading';
import ItemGrid from '@bfly/ui2/ItemGrid';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import { css } from 'astroturf';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useToggler from 'hooks/useToggler';

import permissionBucketsMessages from '../messages/DomainRolesMessages';
import FormCheckSwitch from './FormCheckSwitch';
import { PermissionBucket } from './permissionBuckets';

const renderDropdownValue = ({ item, allowReadWrite }) => {
  if (item === 50) {
    return allowReadWrite ? (
      <FormattedMessage {...permissionBucketsMessages.read} />
    ) : (
      <FormattedMessage {...permissionBucketsMessages.included} />
    );
  }
  if (item === 100) {
    return <FormattedMessage {...permissionBucketsMessages.readWrite} />;
  }
  return <FormattedMessage {...permissionBucketsMessages.notIncluded} />;
};

interface Props {
  header: React.ReactElement;
  children: React.ReactNode;
  customTooltip?: React.ReactElement;
  permissionId: number;
  allowReadWrite: boolean;
  readOnly?: boolean;
}

const data = [UserPermission.Level.ADMIN, UserPermission.Level.BASIC];

function PermissionBucketCard({
  header,
  children,
  customTooltip,
  permissionId,
  allowReadWrite,
  readOnly,
}: Props) {
  const [props] = useField(`permissions.${permissionId}`);

  const [expanded, toggleExpanded] = useToggler(false);
  const enabled = !!props.value;

  return (
    <Card className="my-3" data-bni-id="permissionCard">
      <Card.Body className="flex flex-row justify-between items-center">
        {header}
        <Layout
          className="flex gap-[2rem] items-center"
          data-bni-id="permissionsInputFields"
        >
          <div />
          {readOnly && (
            <span className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-grey rounded-full text-grey-25 bg-grey-70">
              {renderDropdownValue({ item: props.value, allowReadWrite })}
            </span>
          )}
          {!readOnly && (
            <>
              {customTooltip}
              <FormCheckSwitch
                className="w-8 h-5"
                checked={enabled}
                onChange={(e) => {
                  const onLevel = allowReadWrite ? 100 : 50;
                  props.onChange(e.target.checked ? onLevel : 0);
                }}
              />
            </>
          )}
          {!readOnly && allowReadWrite && enabled && (
            <DropdownList
              name="permissions"
              variant="secondary"
              menuVariant="dark"
              data={data}
              dataKey="level"
              textField="label"
              renderValue={(obj) =>
                renderDropdownValue({ item: obj.item, allowReadWrite })
              }
              renderListItem={(obj) =>
                renderDropdownValue({ item: obj.value, allowReadWrite })
              }
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
              containerClassName="bg-grey-85"
              css={css`
                border-color: theme('colors.grey[50]') !important;
                border-radius: 0.25rem;
                border-width: 1px;
                color: theme('colors.grey[25]') !important;
                cursor: pointer;
                input {
                  caret-color: transparent;
                }
              `}
            />
          )}

          <Button
            aria-hidden="true"
            className="btn--content"
            onClick={() => toggleExpanded()}
          >
            <DropdownIcon
              className={clsx('h-3 w-3', expanded ? 'rotate-180' : undefined)}
              css={css`
                color: var(--theme-button-color-secondary);
              `}
            />
          </Button>
        </Layout>
      </Card.Body>
      <Collapse
        in={expanded}
        className="px-6 py-3 col-span-full bg-grey-85"
        timeout={200}
        unmountOnExit
      >
        <>
          <Heading
            ruled
            css={css`
              border-bottom-color: theme('colors.grey[50]') !important;
              margin: 0 1rem;
            `}
          />
          <ItemGrid
            role="list"
            templateColumns="1fr 1fr"
            className="px-5 pb-5 gap-y-3 gap-x-20"
            data-bni-id="bucketPrivilegeList"
          >
            <ItemGrid.Row>{children}</ItemGrid.Row>
          </ItemGrid>
        </>
      </Collapse>
    </Card>
  );
}

interface PermissionBucketCardListItemProps {
  children: React.ReactElement;
}

function PermissionBucketCardListItem({
  children,
}: PermissionBucketCardListItemProps) {
  return (
    <Text variant="body" color="body">
      <Layout align="flex-start">
        <CheckMarkSmall width="13px" height="11px" className="mr-2 mt-1" />
        {children}
      </Layout>
    </Text>
  );
}

function ParentPermissionTooltip({
  bucket,
  buckets,
}: {
  bucket: PermissionBucket;
  buckets: PermissionBucket[];
}) {
  return (
    <Tooltip.Trigger
      placement="left"
      className="max-w-lg"
      tooltip={
        <FormattedMessage
          id="PermissionBucketCard.ParentPermissionTooltip"
          defaultMessage="To enable {childPermission}, {parentPermission} permissions must also be enabled."
          values={{
            childPermission: <FormattedMessage {...bucket.label} />,
            parentPermission: (
              <FormattedMessage
                {...buckets.find(
                  (b) => b.permissionId === bucket.parentPermission,
                )!.label}
              />
            ),
          }}
        />
      }
    >
      <Information width="25" className="text-white" />
    </Tooltip.Trigger>
  );
}
export default Object.assign(PermissionBucketCard, {
  ListItem: PermissionBucketCardListItem,
  ParentPermissionTooltip,
});
