/**
 * @generated SignedSource<<8a856ac905d287448ead4cd0197f97b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EduStandardQaFeedback = "ADJUST_DEPTH" | "ADJUST_GAIN" | "INCOMPLETE_MEASUREMENTS" | "INCORRECT_ORIENTATION" | "INCORRECT_PRESET" | "INCORRECT_TRANSDUCER" | "MISSING_STANDARD_VIEWS" | "%future added value";
export type EduStudySorting = "CAPTURED_AT_ASC" | "CAPTURED_AT_DESC" | "CONDUCTED_AT_ASC" | "CONDUCTED_AT_DESC" | "READY_AT_ASC" | "READY_AT_DESC" | "REVIEWED_AT_ASC" | "REVIEWED_AT_DESC" | "REVIEWED_IMAGE_QUALITY_ASC" | "REVIEWED_IMAGE_QUALITY_DESC" | "REVIEW_REQUESTED_AT_ASC" | "REVIEW_REQUESTED_AT_DESC" | "ZONES_CAPTURED_ASC" | "ZONES_CAPTURED_DESC" | "%future added value";
export type EduStudyStatus = "FAILED" | "PENDING" | "READY" | "%future added value";
export type useStudiesData_eduStudiesQuery$variables = {
  after?: string | null;
  author?: ReadonlyArray<string> | null;
  conductedAtMax?: string | null;
  conductedAtMin?: string | null;
  examTypeHandles?: ReadonlyArray<string> | null;
  first?: number | null;
  groupId?: ReadonlyArray<string> | null;
  isReviewRequested?: boolean | null;
  isReviewed?: boolean | null;
  sort?: ReadonlyArray<EduStudySorting> | null;
};
export type useStudiesData_eduStudiesQuery$data = {
  readonly viewer: {
    readonly eduStudyConnection: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly conductedAt: string;
          readonly createdAt: string | null;
          readonly createdBy: {
            readonly id: string;
            readonly name: string | null;
          } | null;
          readonly examTypes: ReadonlyArray<{
            readonly handle: string | null;
            readonly name: string | null;
            readonly systemId: string | null;
          } | null> | null;
          readonly handle: string | null;
          readonly id: string;
          readonly numImages: number;
          readonly readyAt: string | null;
          readonly reviewRequestedAt: string | null;
          readonly standardQa: {
            readonly comments: string | null;
            readonly createdAt: string | null;
            readonly createdBy: {
              readonly name: string | null;
            } | null;
            readonly feedback: ReadonlyArray<EduStandardQaFeedback> | null;
            readonly imageQuality: number;
          } | null;
          readonly status: EduStudyStatus | null;
          readonly totalZones: number;
          readonly zonesCompleted: number;
          readonly " $fragmentSpreads": FragmentRefs<"EduStudyCaptures_study">;
        } | null;
      } | null> | null;
      readonly index: number | null;
      readonly numEdges: number | null;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
    } | null;
  } | null;
};
export type useStudiesData_eduStudiesQuery = {
  response: useStudiesData_eduStudiesQuery$data;
  variables: useStudiesData_eduStudiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "author"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conductedAtMax"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conductedAtMin"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "examTypeHandles"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isReviewRequested"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isReviewed"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v10 = {
  "kind": "Variable",
  "name": "author",
  "variableName": "author"
},
v11 = {
  "kind": "Variable",
  "name": "conductedAtMax",
  "variableName": "conductedAtMax"
},
v12 = {
  "kind": "Variable",
  "name": "conductedAtMin",
  "variableName": "conductedAtMin"
},
v13 = {
  "kind": "Variable",
  "name": "examTypeHandles",
  "variableName": "examTypeHandles"
},
v14 = {
  "kind": "Variable",
  "name": "groupId",
  "variableName": "groupId"
},
v15 = {
  "kind": "Literal",
  "name": "includePageInfo",
  "value": true
},
v16 = {
  "kind": "Variable",
  "name": "isReviewRequested",
  "variableName": "isReviewRequested"
},
v17 = {
  "kind": "Variable",
  "name": "isReviewed",
  "variableName": "isReviewed"
},
v18 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numEdges",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conductedAt",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readyAt",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zonesCompleted",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reviewRequestedAt",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalZones",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemId",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageQuality",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feedback",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comments",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    (v23/*: any*/),
    (v32/*: any*/)
  ],
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numImages",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v40 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useStudiesData_eduStudiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "eduStudyConnection",
            "args": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "concreteType": "EduStudyConnection",
            "kind": "LinkedField",
            "name": "__viewer_eduStudyConnection_connection",
            "plural": false,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EduStudyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EduStudy",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EduStudyCaptures_study"
                      },
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v30/*: any*/),
                      (v31/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExamType",
                        "kind": "LinkedField",
                        "name": "examTypes",
                        "plural": true,
                        "selections": [
                          (v32/*: any*/),
                          (v25/*: any*/),
                          (v33/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EduStandardQa",
                        "kind": "LinkedField",
                        "name": "standardQa",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              (v32/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v26/*: any*/),
                          (v34/*: any*/),
                          (v35/*: any*/),
                          (v36/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v37/*: any*/),
                      (v38/*: any*/),
                      (v39/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v9/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "useStudiesData_eduStudiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v40/*: any*/),
            "concreteType": "EduStudyConnection",
            "kind": "LinkedField",
            "name": "eduStudyConnection",
            "plural": false,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EduStudyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EduStudy",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/),
                      (v25/*: any*/),
                      (v38/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "capturedAt",
                        "storageKey": null
                      },
                      (v24/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v30/*: any*/),
                      (v31/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExamType",
                        "kind": "LinkedField",
                        "name": "examTypes",
                        "plural": true,
                        "selections": [
                          (v32/*: any*/),
                          (v25/*: any*/),
                          (v33/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EduStandardQa",
                        "kind": "LinkedField",
                        "name": "standardQa",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              (v32/*: any*/),
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v26/*: any*/),
                          (v34/*: any*/),
                          (v35/*: any*/),
                          (v36/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v37/*: any*/),
                      (v39/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v40/*: any*/),
            "filters": [
              "includePageInfo",
              "sort",
              "examTypeHandles",
              "conductedAtMin",
              "conductedAtMax",
              "author",
              "isReviewed",
              "isReviewRequested",
              "groupId"
            ],
            "handle": "connection",
            "key": "viewer_eduStudyConnection",
            "kind": "LinkedHandle",
            "name": "eduStudyConnection"
          },
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6179d190fff03173163bdb4562a356a8",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "viewer",
            "eduStudyConnection"
          ]
        }
      ]
    },
    "name": "useStudiesData_eduStudiesQuery",
    "operationKind": "query",
    "text": "query useStudiesData_eduStudiesQuery(\n  $first: Int\n  $after: String\n  $sort: [EduStudySorting!]\n  $examTypeHandles: [String!]\n  $conductedAtMin: DateTime\n  $conductedAtMax: DateTime\n  $author: [String!]\n  $isReviewed: Boolean\n  $isReviewRequested: Boolean\n  $groupId: [ID!]\n) {\n  viewer {\n    eduStudyConnection(first: $first, after: $after, includePageInfo: true, sort: $sort, examTypeHandles: $examTypeHandles, conductedAtMin: $conductedAtMin, conductedAtMax: $conductedAtMax, author: $author, isReviewed: $isReviewed, isReviewRequested: $isReviewRequested, groupId: $groupId) {\n      index\n      numEdges\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        cursor\n        node {\n          id\n          ...EduStudyCaptures_study\n          status\n          handle\n          createdAt\n          conductedAt\n          readyAt\n          zonesCompleted\n          reviewRequestedAt\n          totalZones\n          examTypes {\n            name\n            handle\n            systemId\n            id\n          }\n          standardQa {\n            createdBy {\n              name\n              id\n            }\n            createdAt\n            imageQuality\n            feedback\n            comments\n          }\n          createdBy {\n            id\n            name\n          }\n          numImages\n          __typename\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment EduStudyCaptures_study on EduStudy {\n  handle\n  numImages\n  capturedAt\n}\n"
  }
};
})();

(node as any).hash = "74c5ddeb612301799eae34bc2588e688";

export default node;
