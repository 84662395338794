/**
 * @generated SignedSource<<6787ca67ea360577d9cec0fde6f71637>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type connections_UpdateDicomConnectionPage_Query$variables = {
  hospitalConnectionHandle: string;
  organizationSlug: string;
};
export type connections_UpdateDicomConnectionPage_Query$data = {
  readonly dicomConnection: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdateDicomConnectionPage_dicomConnection">;
  } | null;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdateDicomConnectionPage_organization">;
  } | null;
};
export type connections_UpdateDicomConnectionPage_Query = {
  response: connections_UpdateDicomConnectionPage_Query$data;
  variables: connections_UpdateDicomConnectionPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hospitalConnectionHandle"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "hospitalConnectionHandle"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "connections_UpdateDicomConnectionPage_Query",
    "selections": [
      {
        "alias": "dicomConnection",
        "args": (v2/*: any*/),
        "concreteType": "DicomTlsConnection",
        "kind": "LinkedField",
        "name": "dicomTlsConnection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateDicomConnectionPage_dicomConnection"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateDicomConnectionPage_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "connections_UpdateDicomConnectionPage_Query",
    "selections": [
      {
        "alias": "dicomConnection",
        "args": (v2/*: any*/),
        "concreteType": "DicomTlsConnection",
        "kind": "LinkedField",
        "name": "dicomTlsConnection",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAnonymous",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSecured",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "qpathClassicSecurityMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scpPublicKey",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scpHost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scuPublicKey",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "connectivityConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "organizationId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Organization",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "domainId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Domain",
                        "abstractKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isNode"
              }
            ],
            "type": "HospitalConnectionInterface",
            "abstractKey": "__isHospitalConnectionInterface"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "practiceType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6cfddaa4bd264f88bf0d53720ab3284d",
    "id": null,
    "metadata": {},
    "name": "connections_UpdateDicomConnectionPage_Query",
    "operationKind": "query",
    "text": "query connections_UpdateDicomConnectionPage_Query(\n  $organizationSlug: String!\n  $hospitalConnectionHandle: String!\n) {\n  dicomConnection: dicomTlsConnection(handle: $hospitalConnectionHandle) {\n    ...UpdateDicomConnectionPage_dicomConnection\n    id\n  }\n  organization: organizationBySlug(slug: $organizationSlug) {\n    ...UpdateDicomConnectionPage_organization\n    id\n  }\n}\n\nfragment DeleteHospitalConnectionSection_hospitalConnection on HospitalConnectionInterface {\n  __isHospitalConnectionInterface: __typename\n  name\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  connectivityConfig {\n    __typename\n    ...useTenantIdInput_tenant\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment DicomConnectionForm_dicomConnection on DicomTlsConnection {\n  id\n  name\n  isAnonymous\n  isSecured\n  qpathClassicSecurityMode\n  scpPublicKey\n  scpHost\n  scuPublicKey\n}\n\nfragment DicomConnectionForm_organization on Organization {\n  subscription {\n    practiceType\n  }\n  ...SecurityModeInput_organization\n}\n\nfragment SecurityModeInput_organization on Organization {\n  subscription {\n    practiceType\n  }\n}\n\nfragment UpdateDicomConnectionPage_dicomConnection on DicomTlsConnection {\n  id\n  name\n  ...DicomConnectionForm_dicomConnection\n  ...DeleteHospitalConnectionSection_hospitalConnection\n}\n\nfragment UpdateDicomConnectionPage_organization on Organization {\n  ...DicomConnectionForm_organization\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d3329b6e6242df6f523a55da7941361";

export default node;
