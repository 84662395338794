import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import getNodes from '@bfly/utils/getNodes';
import { defineMessage, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PermissionTooltip from 'components/PermissionTooltip';
import { usePermissions } from 'utils/viewerContext';

import { DomainMemberAccessRoleFieldSet_viewer$data as Viewer } from './__generated__/DomainMemberAccessRoleFieldSet_viewer.graphql';

const messages = defineMessages({
  placeholder: {
    id: 'domainMembersPage.AccessRole.placeholder',
    defaultMessage: 'Select Butterfly Access Role',
  },
});

interface Props {
  viewer: Viewer;
}

function DomainMemberAccessRoleFieldSet({ viewer }: Props) {
  const rolesList = getNodes(viewer.membershipRoles).filter(
    ({ isDisabled }) => !isDisabled,
  );
  const { formatMessage } = useIntl();

  const { hasAdminPermission } = usePermissions();
  const hasPermission = hasAdminPermission('userManagement');
  return (
    <Form.FieldSet
      className="my-6"
      legend={formatMessage(
        defineMessage({
          id: 'domainMemberAccessRoleFieldSet.legend',
          defaultMessage: 'Butterfly Access Role',
        }),
      )}
    >
      <Form.FieldGroup
        name="roleId"
        label={defineMessage({
          id: 'domainRoleTypeFieldset.roleList.field',
          defaultMessage: 'Butterfly Access Role',
        })}
        className="items-center pb-8"
        mapFromValue={{
          roleId: 'id',
        }}
        required
      >
        {(props) => (
          <PermissionTooltip wrap hide={hasPermission}>
            <DropdownList
              {...props}
              data={rolesList}
              dataKey="id"
              textField="name"
              disabled={!hasPermission}
              placeholder={messages.placeholder}
            />
          </PermissionTooltip>
        )}
      </Form.FieldGroup>
    </Form.FieldSet>
  );
}

export default createFragmentContainer(DomainMemberAccessRoleFieldSet, {
  viewer: graphql`
    fragment DomainMemberAccessRoleFieldSet_viewer on Viewer {
      membershipRoles(
        sort: NAME_ASC
        roleType: [SYSTEM_DEFINED, USER_DEFINED]
      ) {
        edges {
          node {
            id
            name
            isDisabled
          }
        }
      }
    }
  `,
});
