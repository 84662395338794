import Pagination from '@bfly/ui2/Pagination';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import PageTitle from 'components/PageTitle';
import { RoutePageProps } from 'components/Route';
import StudyListContent from 'components/StudyListContent';
import usePagedConnection from 'hooks/usePagedConnection';
import { StudyDataColumnUserPreferenceKey } from 'hooks/useStudyDataColumnsUserPreference';
import { STUDY_GRID_PAGE_SIZE } from 'utils/StudyConstants';
import getConcreteTenant from 'utils/getConcreteTenant';

import { GlobalSearchRefetchQuery } from './__generated__/GlobalSearchRefetchQuery.graphql';
import { GlobalStudySearchList_studies$key as TenantKey } from './__generated__/GlobalStudySearchList_studies.graphql';
import { GlobalStudySearchList_tenant$data as Tenant } from './__generated__/GlobalStudySearchList_tenant.graphql';
import { GlobalStudySearchList_viewer$data as Viewer } from './__generated__/GlobalStudySearchList_viewer.graphql';

const messages = defineMessages({
  title: {
    id: 'globalStudySearchList.title',
    defaultMessage: 'Search results ({numResults} studies)',
  },
});

function useStudyConnection(tenant: TenantKey) {
  return usePagedConnection<
    GlobalSearchRefetchQuery,
    TenantKey,
    'studySearchConnection'
  >(
    graphql`
      fragment GlobalStudySearchList_studies on TenantInterface
      @refetchable(queryName: "GlobalSearchRefetchQuery") {
        studySearchConnection(
          first: $first
          after: $after
          last: $last
          before: $before

          sort: $sort
          search: $search
        ) @connection(key: "Tenant_studySearchConnection") {
          index
          numEdges
          edges {
            ...StudyListContent_studies
              @arguments(listEnabled: false, lazyLoadImages: true)
            node {
              id
            }
          }
        }
      }
    `,
    tenant,
    {
      pageSize: STUDY_GRID_PAGE_SIZE,
      overfetchNumPages: 0,
    },
  );
}
interface Props extends RoutePageProps {
  tenant: Tenant;
  viewer: Viewer;
  loading?: boolean;
}

function GlobalStudySearchList({ tenant, viewer, loading }: Props) {
  const canUseBulkActions = useVariation('global-search-bulk-actions');
  const canUseBulkAssignExamTypes = useVariation('bulk-assign-exam-types');
  const { domain, organization } = getConcreteTenant(tenant);

  const [studies, { index, numEdges, loadNext, loadPrevious, fetching }] =
    useStudyConnection(tenant);

  const isDomain = !!viewer.domain;

  return (
    <StudyListContent
      navKind="GLOBAL_SEARCH"
      lazyLoadImages
      loading={fetching || loading}
      allowDelete={canUseBulkActions}
      allowMove={canUseBulkActions}
      allowAssignExamTypes={canUseBulkAssignExamTypes}
      allowList={false}
      archive={null}
      studies={studies.edges as any}
      domain={domain}
      organization={organization}
      viewer={viewer}
      showOrganization={isDomain}
      title={
        <PageTitle title={messages.title} values={{ numResults: numEdges }} />
      }
      headerActions={
        <Pagination
          index={index}
          onNextPage={loadNext}
          onPreviousPage={loadPrevious}
          pageSize={STUDY_GRID_PAGE_SIZE}
          numItems={numEdges}
        />
      }
      columnsPreferenceKey={StudyDataColumnUserPreferenceKey.SEARCH_STUDY_LIST}
    />
  );
}

export default createFragmentContainer(GlobalStudySearchList, {
  tenant: graphql`
    fragment GlobalStudySearchList_tenant on TenantInterface {
      ...GlobalStudySearchList_studies
      ...StudyListContent_organization
      ...StudyListContent_domain
      ...getConcreteTenant_tenant
    }
  `,
  viewer: graphql`
    fragment GlobalStudySearchList_viewer on Viewer {
      domain {
        __typename
      }
      ...StudyListContent_viewer
    }
  `,
});
