/**
 * @generated SignedSource<<eb8c0d9694a781701b64c9926f1581ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainMembersPage_ExportQuery$variables = {
  id: string;
};
export type DomainMembersPage_ExportQuery$data = {
  readonly domain: {
    readonly bulkUsersExport?: {
      readonly " $fragmentSpreads": FragmentRefs<"FileExportButton_fileExport">;
    } | null;
  } | null;
};
export type DomainMembersPage_ExportQuery = {
  response: DomainMembersPage_ExportQuery$data;
  variables: DomainMembersPage_ExportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainMembersPage_ExportQuery",
    "selections": [
      {
        "alias": "domain",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileExport",
                "kind": "LinkedField",
                "name": "bulkUsersExport",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "FileExportButton_fileExport",
                    "selections": (v2/*: any*/),
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Domain",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DomainMembersPage_ExportQuery",
    "selections": [
      {
        "alias": "domain",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileExport",
                "kind": "LinkedField",
                "name": "bulkUsersExport",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Domain",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "36959b568191f16c76def286be4028f7",
    "id": null,
    "metadata": {},
    "name": "DomainMembersPage_ExportQuery",
    "operationKind": "query",
    "text": "query DomainMembersPage_ExportQuery(\n  $id: ID!\n) {\n  domain: node(id: $id) {\n    __typename\n    ... on Domain {\n      bulkUsersExport {\n        ...FileExportButton_fileExport\n      }\n    }\n    id\n  }\n}\n\nfragment FileExportButton_fileExport on FileExport {\n  url\n}\n"
  }
};
})();

(node as any).hash = "41c1870d921ab7eba6474f1f6cde2a8c";

export default node;
