/**
 * @generated SignedSource<<aef7552fa94374ad8997229c630aee2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorklistSearchBar_worklist$data = {
  readonly type: string;
  readonly " $fragmentType": "WorklistSearchBar_worklist";
};
export type WorklistSearchBar_worklist$key = {
  readonly " $data"?: WorklistSearchBar_worklist$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorklistSearchBar_worklist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorklistSearchBar_worklist",
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "WorklistInterface",
  "abstractKey": "__isWorklistInterface"
};

(node as any).hash = "5f36582592891195c0eba4d83be7b78a";

export default node;
