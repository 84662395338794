import ArrowRightIcon from '@bfly/icons/ArrowRight';
import DeselectAllIcon from '@bfly/icons/DeselectAll';
import TrashCanIcon from '@bfly/icons/TrashCan';
import Header from '@bfly/ui2/Header';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import useDeleteStudiesHandler from 'hooks/useDeleteStudiesHandler';
import useModalState from 'hooks/useModalState';
import actionMessages from 'messages/actions';
import { SelectedStudyItem } from 'utils/StudySelection';

import MoveStudyModal from './MoveStudyModal';
import MoveStudyModalRefresh from './MoveStudyModalRefresh';
import { StudyBatchActions_domain$data as Domain } from './__generated__/StudyBatchActions_domain.graphql';
import { StudyBatchActions_organization$data as Organization } from './__generated__/StudyBatchActions_organization.graphql';

interface Props {
  viewerCanMoveSelected: boolean;
  viewerCanDeleteSelected: boolean;
  organization: Organization;
  domain: Domain | null;
  onDeselectAll: () => void;
  selectedStudyItems: SelectedStudyItem[];
}

function StudyBatchActions({
  viewerCanDeleteSelected,
  viewerCanMoveSelected,
  selectedStudyItems,
  onDeselectAll,
  organization,
  domain,
}: Props) {
  const [showMoveModal, moveModalProps] = useModalState();
  const handleClick = useDeleteStudiesHandler(
    organization.id,
    domain ? domain.id : '',
    selectedStudyItems,
  );
  const canUseMoveStudyOrg = useVariation('move-study-org');

  return (
    <>
      <Header.Action onClick={onDeselectAll}>
        <DeselectAllIcon width={16} height={16} />
        <FormattedMessage {...actionMessages.deselectAll} />
      </Header.Action>
      {viewerCanMoveSelected && (
        <Header.Action onClick={showMoveModal}>
          <ArrowRightIcon
            // TODO: Sizing required for Safari and Firefox; remove
            //  after upstream upgrade.
            width={17}
            height={14}
          />
          <FormattedMessage {...actionMessages.move} />
        </Header.Action>
      )}
      {viewerCanDeleteSelected && (
        <Header.Action
          onClick={handleClick}
          data-bni-id="DeleteSelectedButton"
        >
          <TrashCanIcon />
          <FormattedMessage {...actionMessages.delete} />
        </Header.Action>
      )}
      {canUseMoveStudyOrg ? (
        <MoveStudyModalRefresh
          {...moveModalProps}
          organization={organization}
          domain={domain}
          studyAndArchiveIds={selectedStudyItems}
        />
      ) : (
        <MoveStudyModal
          {...moveModalProps}
          organization={organization}
          domain={domain}
          studyAndArchiveIds={selectedStudyItems}
        />
      )}
    </>
  );
}

export default createFragmentContainer(StudyBatchActions, {
  organization: graphql`
    fragment StudyBatchActions_organization on Organization {
      id
      handle
      ...MoveStudyModal_organization
      ...MoveStudyModalRefresh_organization
    }
  `,
  domain: graphql`
    fragment StudyBatchActions_domain on Domain {
      id
      ...MoveStudyModal_domain
      ...MoveStudyModalRefresh_domain
    }
  `,
});
