import { FormVariant } from '@bfly/ui2/Form';
import FormControlSearch, {
  FormControlSearchProps,
} from '@bfly/ui2/FormControlSearch';
import useDebouncedValue from '@restart/hooks/useDebouncedValue';
import useEventCallback from '@restart/hooks/useEventCallback';
import { useEffect, useState } from 'react';

interface Props extends Omit<FormControlSearchProps, 'value'> {
  value?: string;
  autoFocus?: boolean;
  defaultValue?: string | undefined;
  onChange: (search: string) => void;
  debounceTimeout?: number;
  variant?: FormVariant;
}

export default function DebouncedSearchControl({
  onChange,
  defaultValue,
  value,
  debounceTimeout = 200,
  ...props
}: Props) {
  const handleChange = useEventCallback(onChange);

  const [searchTerm, setSearchTerm] = useState(value ?? defaultValue ?? '');
  const debouncedSearch = useDebouncedValue(searchTerm, debounceTimeout);

  useEffect(() => {
    handleChange(debouncedSearch);
  }, [debouncedSearch, handleChange]);

  return (
    <FormControlSearch
      {...props}
      value={searchTerm}
      onChange={(nextValue) => {
        setSearchTerm(nextValue ?? '');
      }}
    />
  );
}
