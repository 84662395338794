import Pagination from '@bfly/ui2/Pagination';
import { defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ExamQuickFilters from 'components/ExamQuickFilters';
import { useVariation } from 'components/LaunchDarklyContext';
import PageTitle from 'components/PageTitle';
import StudyListContent from 'components/StudyListContent';
import usePagedConnection from 'hooks/usePagedConnection';
import { StudyDataColumnUserPreferenceKey } from 'hooks/useStudyDataColumnsUserPreference';
import { STUDY_GRID_PAGE_SIZE } from 'utils/StudyConstants';

import { AllOrganizationsStudyList_studies$key as OrganizationSearchKey } from './__generated__/AllOrganizationsStudyList_studies.graphql';
import { AllOrganizationsStudyList_tenant$data as Tenant } from './__generated__/AllOrganizationsStudyList_tenant.graphql';
import { AllOrganizationsStudyList_viewer$data as Viewer } from './__generated__/AllOrganizationsStudyList_viewer.graphql';
import { GlobalStudiesListPageWithSearchQuery } from './__generated__/GlobalStudiesListPageWithSearchQuery.graphql';

interface Props {
  viewer: Viewer;
  tenant: Tenant;
  loading?: boolean;
}

const AllOrganizationsStudyList = ({ viewer, tenant, loading }: Props) => {
  const [studies, paginationMeta] = usePagedConnection<
    GlobalStudiesListPageWithSearchQuery,
    OrganizationSearchKey,
    'studySearchConnection'
  >(
    graphql`
      fragment AllOrganizationsStudyList_studies on Domain
      @refetchable(queryName: "GlobalStudiesListPageWithSearchQuery") {
        studySearchConnection(
          first: $first
          after: $after
          last: $last
          before: $before
          sort: $sort
          search: $search
        ) @connection(key: "Global_studySearchConnection") {
          index
          numEdges
          edges {
            ...StudyListContent_studies
              @arguments(listEnabled: false, lazyLoadImages: true)
            node {
              id
            }
          }
        }
      }
    `,
    viewer!.domain!,
    {
      pageSize: STUDY_GRID_PAGE_SIZE,
      overfetchNumPages: 0,
    },
  );
  const { index, loadNext, loadPrevious, numEdges, fetching } = paginationMeta;
  const canUseBulkActions = useVariation('global-search-bulk-actions');
  const canUseBulkAssignExamTypes = useVariation('bulk-assign-exam-types');

  return (
    <StudyListContent
      navKind="DOMAIN_SEARCH"
      lazyLoadImages
      loading={fetching || loading}
      allowDelete={canUseBulkActions}
      allowMove={canUseBulkActions}
      allowAssignExamTypes={canUseBulkAssignExamTypes}
      allowList={false}
      archive={null}
      isAllOrgs
      studies={studies.edges as any}
      domain={viewer!.domain}
      organization={null}
      viewer={viewer}
      showOrganization
      title={
        <PageTitle
          title={defineMessage({
            id: 'domain.all.orgs.exams',
            defaultMessage: 'All Exams in your Domain',
          })}
        />
      }
      headerActions={
        <>
          <ExamQuickFilters
            tenant={tenant}
            includeAuthors
            includeStatuses
            includeOrganizations
          />
          <Pagination
            index={index}
            onNextPage={loadNext}
            onPreviousPage={loadPrevious}
            pageSize={STUDY_GRID_PAGE_SIZE}
            numItems={numEdges}
          />
        </>
      }
      columnsPreferenceKey={StudyDataColumnUserPreferenceKey.SEARCH_STUDY_LIST}
    />
  );
};

export default createFragmentContainer(AllOrganizationsStudyList, {
  viewer: graphql`
    fragment AllOrganizationsStudyList_viewer on Viewer {
      id
      domain {
        ...AllOrganizationsStudyList_studies
        ...StudyListContent_domain
      }
      ...StudyListContent_viewer
    }
  `,
  tenant: graphql`
    fragment AllOrganizationsStudyList_tenant on TenantInterface {
      id
      ...ExamQuickFilters_tenant @arguments(includeAuthors: true)
    }
  `,
});
