/**
 * @generated SignedSource<<cccc55a19e909a36d5d8595aece9e602>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type NotificationUpdatedSubscriptionInput = {
  clientSubscriptionId?: string | null;
  notificationId: string;
};
export type Notification_Subscription$variables = {
  input: NotificationUpdatedSubscriptionInput;
};
export type Notification_Subscription$data = {
  readonly notificationUpdated: {
    readonly notification: {
      readonly readAt: string | null;
    } | null;
  } | null;
};
export type Notification_Subscription = {
  response: Notification_Subscription$data;
  variables: Notification_Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Notification_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationUpdatedSubscriptionPayload",
        "kind": "LinkedField",
        "name": "notificationUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Notification_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationUpdatedSubscriptionPayload",
        "kind": "LinkedField",
        "name": "notificationUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d23adf157ab32daac60cde5dad5fbe6",
    "id": null,
    "metadata": {},
    "name": "Notification_Subscription",
    "operationKind": "subscription",
    "text": "subscription Notification_Subscription(\n  $input: NotificationUpdatedSubscriptionInput!\n) {\n  notificationUpdated(input: $input) {\n    notification {\n      __typename\n      readAt\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee392b7be0e2cf57a5e78dfbe3916579";

export default node;
