/**
 * @generated SignedSource<<1ab5c9fe7562d3b9ae3cf0ef5761b430>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveSettingsPage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ThirdPartyUltrasoundListenerConfiguration_viewer">;
  readonly " $fragmentType": "ArchiveSettingsPage_viewer";
};
export type ArchiveSettingsPage_viewer$key = {
  readonly " $data"?: ArchiveSettingsPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveSettingsPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveSettingsPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ThirdPartyUltrasoundListenerConfiguration_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "ff011eb61b2de597abffe4d4bc0aa0d1";

export default node;
