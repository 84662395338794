/**
 * @generated SignedSource<<141278b596b2570d956a0375e2b50975>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPagination_search_organization$data = {
  readonly id: string;
  readonly studyConnection: {
    readonly edges: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_studies">;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "StudyPagination_search_organization";
};
export type StudyPagination_search_organization$key = {
  readonly " $data"?: StudyPagination_search_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPagination_search_organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "studyConnection"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "archiveHandle"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "examTypes"
    },
    {
      "kind": "RootArgument",
      "name": "hasRequestedFinalization"
    },
    {
      "kind": "RootArgument",
      "name": "hasRequestedFinalizationFromViewer"
    },
    {
      "defaultValue": [
        false
      ],
      "kind": "LocalArgument",
      "name": "isDeleted"
    },
    {
      "kind": "RootArgument",
      "name": "isDraft"
    },
    {
      "kind": "RootArgument",
      "name": "needsReview"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    },
    {
      "kind": "RootArgument",
      "name": "primaryAuthor"
    },
    {
      "kind": "RootArgument",
      "name": "readyAtMax"
    },
    {
      "kind": "RootArgument",
      "name": "readyAtMin"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "kind": "RootArgument",
      "name": "studyTagHandles"
    },
    {
      "kind": "RootArgument",
      "name": "userHandle"
    },
    {
      "kind": "RootArgument",
      "name": "workflowStatus"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "pageSize",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SearchStudyPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "StudyPagination_search_organization",
  "selections": [
    {
      "alias": "studyConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "archiveHandle",
          "variableName": "archiveHandle"
        },
        {
          "kind": "Variable",
          "name": "createdByHandle",
          "variableName": "userHandle"
        },
        {
          "kind": "Variable",
          "name": "examTypeHandles",
          "variableName": "examTypes"
        },
        {
          "kind": "Variable",
          "name": "hasRequestedFinalization",
          "variableName": "hasRequestedFinalization"
        },
        {
          "kind": "Variable",
          "name": "hasRequestedFinalizationFromViewer",
          "variableName": "hasRequestedFinalizationFromViewer"
        },
        {
          "kind": "Variable",
          "name": "isDeleted",
          "variableName": "isDeleted"
        },
        {
          "kind": "Variable",
          "name": "isDraft",
          "variableName": "isDraft"
        },
        {
          "kind": "Variable",
          "name": "needsReview",
          "variableName": "needsReview"
        },
        {
          "kind": "Variable",
          "name": "primaryAuthor",
          "variableName": "primaryAuthor"
        },
        {
          "kind": "Variable",
          "name": "readyAtMax",
          "variableName": "readyAtMax"
        },
        {
          "kind": "Variable",
          "name": "readyAtMin",
          "variableName": "readyAtMin"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "tagHandles",
          "variableName": "studyTagHandles"
        },
        {
          "kind": "Variable",
          "name": "workflowStatus",
          "variableName": "workflowStatus"
        }
      ],
      "concreteType": "StudyConnection",
      "kind": "LinkedField",
      "name": "__Organization_studyConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "showArchive",
                  "value": true
                }
              ],
              "kind": "FragmentSpread",
              "name": "StudyListContent_studies"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Study",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "6495480b041a9056a53f094affa9cfb3";

export default node;
