/**
 * @generated SignedSource<<d8e1fb19014ad9aa7ff094ab5ef5faff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThirdPartyUltrasoundLogListPaginationQuery$variables = {
  archiveId: string;
  count: number;
  cursor?: string | null;
};
export type ThirdPartyUltrasoundLogListPaginationQuery$data = {
  readonly archive: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ThirdPartyUltrasoundLogList_archive">;
  } | null;
};
export type ThirdPartyUltrasoundLogListPaginationQuery = {
  response: ThirdPartyUltrasoundLogListPaginationQuery$data;
  variables: ThirdPartyUltrasoundLogListPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "archiveId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "archiveId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ThirdPartyUltrasoundLogListPaginationQuery",
    "selections": [
      {
        "alias": "archive",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ThirdPartyUltrasoundLogList_archive"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ThirdPartyUltrasoundLogListPaginationQuery",
    "selections": [
      {
        "alias": "archive",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ThirdPartyUltrasoundListener",
                "kind": "LinkedField",
                "name": "thirdPartyUltrasoundListener",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "ThirdPartyUltrasoundLogConnection",
                    "kind": "LinkedField",
                    "name": "logConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ThirdPartyUltrasoundLogEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v2/*: any*/)
                                ],
                                "type": "Node",
                                "abstractKey": "__isNode"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                  }
                                ],
                                "type": "ServerLogInterface",
                                "abstractKey": "__isServerLogInterface"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "tpus_logConnection",
                    "kind": "LinkedHandle",
                    "name": "logConnection"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Archive",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d843d6878ef4503080af4f94dcb22597",
    "id": null,
    "metadata": {},
    "name": "ThirdPartyUltrasoundLogListPaginationQuery",
    "operationKind": "query",
    "text": "query ThirdPartyUltrasoundLogListPaginationQuery(\n  $archiveId: ID!\n  $count: Int!\n  $cursor: String\n) {\n  archive: node(id: $archiveId) {\n    __typename\n    id\n    ...ThirdPartyUltrasoundLogList_archive_1G22uz\n  }\n}\n\nfragment ThirdPartyUltrasoundLogListItem_log on ServerLogInterface {\n  __isServerLogInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  status\n  createdAt\n}\n\nfragment ThirdPartyUltrasoundLogList_archive_1G22uz on Archive {\n  id\n  thirdPartyUltrasoundListener {\n    logConnection(first: $count, after: $cursor) {\n      edges {\n        node {\n          __typename\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n          ...ThirdPartyUltrasoundLogListItem_log\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d2a7ef857d74f4401862a6c9c30a039";

export default node;
