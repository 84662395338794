/**
 * @generated SignedSource<<5b4ee20cf19c49fb89486b6e3b779b82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSuggestion_userProfile$data = {
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  readonly " $fragmentType": "UserSuggestion_userProfile";
};
export type UserSuggestion_userProfile$key = {
  readonly " $data"?: UserSuggestion_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSuggestion_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSuggestion_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_userProfile"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "bfe08df8a92b6a8615ef15bc6c3c8c4e";

export default node;
