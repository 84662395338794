import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import JobSyncStatus from './JobSyncStatus';
import type { EhrSyncStatus_job$data as Job } from './__generated__/EhrSyncStatus_job.graphql';

interface Props {
  job: Job;
}

function EhrSyncStatus({ job }: Props) {
  return <JobSyncStatus job={job} server={job.ehr!} />;
}

export default createFragmentContainer(EhrSyncStatus, {
  job: graphql`
    fragment EhrSyncStatus_job on EhrOruJob {
      completedAt
      ehr {
        name
        paused
      }
      status
      isOutdated
    }
  `,
});
