import Layout from '@4c/layout';
import Anchor from '@bfly/ui2/Anchor';
import Link from '@bfly/ui2/Link';
import clsx from 'clsx';
import { createFragmentContainer, graphql } from 'react-relay';

import { useArchiveRoutes } from 'routes/archive';

import StudyAuthors from './StudyAuthors';
import { StudyAttribution_study$data as Study } from './__generated__/StudyAttribution_study.graphql';

interface Props {
  study: Study;
  variant?: 'pill' | 'default';
  className?: string;
  displayAsLink?: boolean;
}

function StudyAttribution({
  variant,
  className,
  study,
  displayAsLink = true,
}: Props) {
  const archiveRoutes = useArchiveRoutes();
  const { organization, createdBy } = study;
  const classes = clsx(
    className,
    variant === 'pill' && 'bg-contrast/10 rounded py-0.5 px-2',
  );

  return createdBy && displayAsLink ? (
    <Link
      to={{
        pathname: archiveRoutes.studySearch({
          organizationSlug: organization!.slug!,
        }),
        query: {
          createdBy: createdBy.handle,
        },
      }}
    >
      {({ active: _, ...linkProps }) => {
        return (
          <Layout
            inline
            as={Anchor}
            align="center"
            {...linkProps}
            className={classes}
          >
            <StudyAuthors study={study} />
          </Layout>
        );
      }}
    </Link>
  ) : (
    // Since this is not a link like above, we need to add tabindex 1 so we can select for it and make it hoverable and focusable.
    // Popover in RelatedUserAvatars won't work without it.
    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
    <Layout tabIndex={-1} inline align="center" className={classes}>
      <StudyAuthors study={study} />
    </Layout>
  );
}

export default createFragmentContainer(StudyAttribution, {
  study: graphql`
    fragment StudyAttribution_study on Study {
      organization {
        slug
      }
      createdBy {
        id
        handle
      }
      finalizedAt
      ...StudyAuthors_study
    }
  `,
});
