/**
 * @generated SignedSource<<d9d90043eb29b2a3d7eda36b811a47a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateQaNotificationRecipientsInput = {
  clientMutationId?: string | null;
  qaNotificationRecipients?: ReadonlyArray<QaNotificationRecipientInput> | null;
  studyId: string;
};
export type QaNotificationRecipientInput = {
  recipientId: string;
};
export type ExamQaNotificationControl_Mutation$variables = {
  input: UpdateQaNotificationRecipientsInput;
};
export type ExamQaNotificationControl_Mutation$data = {
  readonly updateQaNotificationRecipients: {
    readonly study: {
      readonly qaNotificationRecipients: {
        readonly recipients: ReadonlyArray<{
          readonly id: string;
          readonly name: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"AvatarList_user">;
        } | null> | null;
        readonly submittedAt: string | null;
      } | null;
    } | null;
  } | null;
};
export type ExamQaNotificationControl_Mutation = {
  response: ExamQaNotificationControl_Mutation$data;
  variables: ExamQaNotificationControl_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submittedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamQaNotificationControl_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateQaNotificationRecipientsPayload",
        "kind": "LinkedField",
        "name": "updateQaNotificationRecipients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QaNotificationRecipients",
                "kind": "LinkedField",
                "name": "qaNotificationRecipients",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "recipients",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AvatarList_user"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamQaNotificationControl_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateQaNotificationRecipientsPayload",
        "kind": "LinkedField",
        "name": "updateQaNotificationRecipients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QaNotificationRecipients",
                "kind": "LinkedField",
                "name": "qaNotificationRecipients",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "recipients",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Avatar",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Thumbnail",
                                "kind": "LinkedField",
                                "name": "thumbnails",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "width",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ThumbnailedInterface",
                            "abstractKey": "__isThumbnailedInterface"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04082d82c1e1d6739412465e7a8dba96",
    "id": null,
    "metadata": {},
    "name": "ExamQaNotificationControl_Mutation",
    "operationKind": "mutation",
    "text": "mutation ExamQaNotificationControl_Mutation(\n  $input: UpdateQaNotificationRecipientsInput!\n) {\n  updateQaNotificationRecipients(input: $input) {\n    study {\n      qaNotificationRecipients {\n        submittedAt\n        recipients {\n          name\n          id\n          ...AvatarList_user\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment AvatarList_user on UserProfile {\n  id\n  name\n  ...Avatar_userProfile\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n"
  }
};
})();

(node as any).hash = "306a3f6361e3a1bb59da075ae74c4cfd";

export default node;
