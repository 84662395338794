import { Redirect } from 'found';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import { checkTenantPermission } from 'utils/RouteAccessControl';

import { domainMembers_DomainMemberSettings_Query as DomainMemberSettings_Query } from './__generated__/domainMembers_DomainMemberSettings_Query.graphql';
import { domainMembers_Query } from './__generated__/domainMembers_Query.graphql';
import DomainMemberPage from './components/DomainMemberPage';
import DomainMemberSettingsPage from './components/DomainMemberSettingsPage';
import DomainMembersGridWrapper from './components/DomainMembersGridWrapper';
import DomainMembersPage from './components/DomainMembersPage';

export default (
  <Route<domainMembers_Query>
    pageLayout
    path="members"
    query={graphql`
      query domainMembers_Query {
        tenant {
          ...RouteAccessControl_tenant
        }
      }
    `}
    prerender={checkTenantPermission('userManagement', 'BASIC')}
  >
    <Redirect to="/-/enterprise/settings/members/active/" />
    <Route
      Component={DomainMembersPage}
      query={graphql`
        query domainMembers_DomainMembersPage_Query {
          viewer {
            ...DomainMembersPage_viewer
          }
        }
      `}
    >
      <Route
        Component={DomainMembersGridWrapper}
        query={graphql`
          query domainMembers_DomainMembersGrid_Query {
            viewer {
              ...DomainMembersGridWrapper_viewer
            }
          }
        `}
        prepareVariables={(variables, { location: { query } }) => ({
          ...variables,
          search: query.search || null,
        })}
      />
      <Route
        path="active"
        Component={DomainMembersGridWrapper}
        query={graphql`
          query domainMembers_DomainMembersGridActive_Query(
            $isDeleted: Boolean
          ) {
            viewer {
              ...DomainMembersGridWrapper_viewer
                @arguments(isDeleted: $isDeleted)
            }
          }
        `}
        prepareVariables={(variables) => {
          return {
            ...variables,
            isDeleted: false,
          };
        }}
      />
      <Route
        path="inactive"
        Component={DomainMembersGridWrapper}
        query={graphql`
          query domainMembers_DomainMembersGridInactive_Query(
            $isDeleted: Boolean
          ) {
            viewer {
              ...DomainMembersGridWrapper_viewer
                @arguments(isDeleted: $isDeleted)
            }
          }
        `}
        prepareVariables={(variables) => {
          return {
            ...variables,
            isDeleted: true,
          };
        }}
      />
    </Route>
    <Route
      path=":domainUserId"
      Component={DomainMemberPage}
      query={graphql`
        query domainMembers_DomainMemberPage_Query($domainUserId: ID!) {
          domainUser: node(id: $domainUserId) {
            ...DomainMemberPage_domainUser
          }
        }
      `}
    >
      <Route<DomainMemberSettings_Query>
        Component={DomainMemberSettingsPage}
        query={graphql`
          query domainMembers_DomainMemberSettings_Query($domainUserId: ID!) {
            viewer {
              ...DomainMemberSettingsPage_viewer
            }
            domainUser: node(id: $domainUserId) {
              ...DomainMemberSettingsPage_domainUser
            }
          }
        `}
      />
    </Route>
  </Route>
);
