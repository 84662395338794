/**
 * @generated SignedSource<<df5b73285aaa728d4283c3c504a45d0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type education_CompletedCoursesList_Query$variables = {};
export type education_CompletedCoursesList_Query$data = {
  readonly lms: {
    readonly " $fragmentSpreads": FragmentRefs<"CompletedCoursesList_lms">;
  } | null;
};
export type education_CompletedCoursesList_Query = {
  response: education_CompletedCoursesList_Query$data;
  variables: education_CompletedCoursesList_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": [
      "CREATED_AT_DESC"
    ]
  },
  {
    "kind": "Literal",
    "name": "viewerHasCompleted",
    "value": true
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "education_CompletedCoursesList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompletedCoursesList_lms"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "education_CompletedCoursesList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": "completed",
            "args": (v0/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContentConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EducationContent",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerHasAccess",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPremium",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EducationContentCategory",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EducationContentVideo",
                        "kind": "LinkedField",
                        "name": "video",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "vimeoId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EducationContentCourse",
                        "kind": "LinkedField",
                        "name": "course",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerHasCompleted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "absorbId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dueDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Thumbnail",
                        "kind": "LinkedField",
                        "name": "thumbnails",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerHasSaved",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "localId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Thumbnail",
                            "kind": "LinkedField",
                            "name": "thumbnails",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "width",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "ThumbnailedInterface",
                        "abstractKey": "__isThumbnailedInterface"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "educationContentConnection(first:2147483647,sort:[\"CREATED_AT_DESC\"],viewerHasCompleted:true)"
          },
          {
            "alias": "completed",
            "args": (v0/*: any*/),
            "filters": [
              "sort",
              "viewerHasCompleted"
            ],
            "handle": "connection",
            "key": "CompletedCoursesList_completed",
            "kind": "LinkedHandle",
            "name": "educationContentConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "133dd25f962ea612c9f52b0ea9c6aa98",
    "id": null,
    "metadata": {},
    "name": "education_CompletedCoursesList_Query",
    "operationKind": "query",
    "text": "query education_CompletedCoursesList_Query {\n  lms {\n    ...CompletedCoursesList_lms\n  }\n}\n\nfragment CompletedCoursesList_lms on Lms {\n  completed: educationContentConnection(first: 2147483647, sort: [CREATED_AT_DESC], viewerHasCompleted: true) {\n    edges {\n      node {\n        id\n        ...EducationContentTile_educationContent\n        ...ShareEducationContentModal_educationContent\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EducationContentTileMobileActions_educationContent on EducationContent {\n  id\n  contentType\n  ...useSaveContentToggle_educationContent\n}\n\nfragment EducationContentTile_educationContent on EducationContent {\n  id\n  handle\n  name\n  viewerHasAccess\n  duration\n  isPremium\n  contentType\n  category {\n    name\n    id\n  }\n  video {\n    vimeoId\n  }\n  course {\n    viewerHasCompleted\n    absorbId\n    dueDate\n  }\n  thumbnails {\n    url\n  }\n  ...Thumbnail_image\n  ...useSaveContentToggle_educationContent\n  ...EducationContentTileMobileActions_educationContent\n}\n\nfragment ShareEducationContentModal_educationContent on EducationContent {\n  localId\n  handle\n  isPremium\n  contentType\n  viewerHasAccess\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n\nfragment useSaveContentToggle_educationContent on EducationContent {\n  id\n  viewerHasSaved\n  viewerHasAccess\n}\n"
  }
};
})();

(node as any).hash = "d5cdce768e8c159a98709363b2669b28";

export default node;
