import Dropdown from '@bfly/ui2/Dropdown';
import { Link, useRouter } from 'found';
import React from 'react';

import StudyListFilterMessage from 'components/StudyListFilterMessage';
import { StudyListFilter } from 'utils/StudyConstants';

interface FilterItemProps {
  status: StudyListFilter;
  as?: React.ElementType;
}

export default function StudyFilterItem({
  status,
  as = Dropdown.Item,
}: FilterItemProps) {
  const {
    match: { location },
  } = useRouter();

  const { status: currentStatus = StudyListFilter.ALL, ...query } =
    location.query;

  if (status === currentStatus) {
    return null;
  }

  const nextQuery =
    status === StudyListFilter.ALL ? query : { ...query, status };

  return (
    <Link
      as={as}
      to={{
        ...location,
        query: nextQuery,
      }}
    >
      <StudyListFilterMessage status={status} />
    </Link>
  );
}
