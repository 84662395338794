import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import useDialog from '@bfly/ui2/useDialog';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import clsx from 'clsx';
import useRouter from 'found/useRouter';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PermissionTooltip from 'components/PermissionTooltip';
import actionMessages from 'messages/actions';
import { useConfigRoutes } from 'routes/config';
import { clearLastArchiveHandle } from 'utils/EagerRedirectUtils';
import { usePermissions } from 'utils/viewerContext';

import isLastPublicArchive from '../utils/isLastPublicArchive';
import { DeleteArchiveControl_archive$data as Archive } from './__generated__/DeleteArchiveControl_archive.graphql';
import { DeleteArchiveControl_organization$data as Organization } from './__generated__/DeleteArchiveControl_organization.graphql';

// TODO: Insert the new edge instead of refetching archives.
const mutation = graphql`
  mutation DeleteArchiveControlMutation($input: DeleteArchiveInput!) {
    deleteArchiveOrError(input: $input) {
      ... on DeleteArchivePayload {
        organization {
          deletedArchiveConnection: archiveConnection(
            first: 2147483647
            isDeleted: true
          )
            @connection(
              key: "Organization_deletedArchiveConnection"
              filters: []
            ) {
            edges {
              node {
                ...ArchiveListItem_archive
              }
            }
          }
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  archive: Archive;
  organization: Organization;
}

function DeleteArchiveControl({ archive, organization }: Props) {
  const configRoutes = useConfigRoutes();
  const dialog = useDialog();
  const toast = useToast();
  const { hasBasicPermission } = usePermissions();
  const { router, match } = useRouter();

  const hasPermissionToDeleteArchive = hasBasicPermission('archiveManagement');

  const [mutate] = useMutationWithError(mutation, {
    input: {
      organizationId: archive.organization!.id,
      archiveId: archive.id,
    },
    updater: (store) => {
      rangeDeleteUpdater(store, {
        parentId: archive.organization!.id,
        connectionKey: 'Organization_archiveConnection',
        deletedId: archive.id,
      });
    },
  });

  const handleClick = async () => {
    const deleted = await dialog.open(
      <FormattedMessage
        id="archive.delete.dialog.content"
        defaultMessage="You will lose access to all images in the archive."
      />,
      {
        runConfirm: mutate,
        confirmButtonProps: { variant: 'danger', size: 'lg' },
        modalVariant: 'dark',
        title: (
          <FormattedMessage
            id="archive.delete.dialog.title"
            defaultMessage="Are you sure you want to delete this archive?"
          />
        ),
        confirmLabel: <FormattedMessage {...actionMessages.delete} />,
      },
    );

    if (!deleted) {
      return;
    }

    // Don't try to redirect back to the archive we just deleted.
    const organizationSlug = archive.organization!.slug!;
    clearLastArchiveHandle(match, organizationSlug);

    router.replace(configRoutes.rootOrganization({ organizationSlug }));

    toast.success(
      <FormattedMessage
        id="archive.delete.success"
        defaultMessage="Archive deleted successfully."
      />,
    );
  };

  const isOnlyPublicArchive = isLastPublicArchive(archive, organization);
  return (
    <Form.FieldSet
      legend={
        <FormattedMessage
          id="archive.delete.header"
          defaultMessage="Delete Archive"
        />
      }
    >
      <Layout justify="space-between" align="center">
        {isOnlyPublicArchive ? (
          <FormattedMessage
            tagName="span"
            id="archive.delete.lastPublicArchive"
            defaultMessage="Your organization must have at least one public archive."
          />
        ) : (
          <FormattedMessage
            tagName="span"
            id="archive.delete.help"
            defaultMessage="This action will delete the archive and all of its contents."
          />
        )}
        <PermissionTooltip hide={hasPermissionToDeleteArchive}>
          <span
            data-bni-id="deleteArchiveDisabled"
            className={clsx(
              !hasPermissionToDeleteArchive && 'cursor-not-allowed',
            )}
          >
            <Button
              className={clsx(
                !hasPermissionToDeleteArchive && 'pointer-events-none',
              )}
              variant="danger"
              onClick={handleClick}
              disabled={!hasBasicPermission('archiveManagement')}
            >
              <FormattedMessage
                id="archive.delete.control"
                defaultMessage="Delete Archive"
              />
            </Button>
          </span>
        </PermissionTooltip>
      </Layout>
    </Form.FieldSet>
  );
}

export default createFragmentContainer(DeleteArchiveControl, {
  organization: graphql`
    fragment DeleteArchiveControl_organization on Organization {
      ...isLastPublicArchive_organization
    }
  `,
  archive: graphql`
    fragment DeleteArchiveControl_archive on Archive {
      id
      organization {
        id
        slug
      }
      ...isLastPublicArchive_archive
    }
  `,
});
