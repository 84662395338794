/**
 * @generated SignedSource<<6bdbba33d7131633d277f7d64116744f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignExamTypesToStudiesModal_organization$data = {
  readonly examTypeConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "AssignExamTypesToStudiesModal_organization";
};
export type AssignExamTypesToStudiesModal_organization$key = {
  readonly " $data"?: AssignExamTypesToStudiesModal_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignExamTypesToStudiesModal_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignExamTypesToStudiesModal_organization",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2147483647
        }
      ],
      "concreteType": "ExamTypeConnection",
      "kind": "LinkedField",
      "name": "examTypeConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "examTypeConnection(first:2147483647)"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2593a451cc54ace2fae35dd9d743c4ad";

export default node;
