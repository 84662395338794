/**
 * @generated SignedSource<<3ea8007e6f54a8e226e30adcb5ecee13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySearchInputStatus = "DRAFT" | "FINALIZED" | "NEEDS_REVIEW" | "PENDING_ATTESTATION" | "REVIEWED" | "%future added value";
export type CredentialingPageFilterInput = {
  credentialGroup?: ReadonlyArray<string> | null;
  domain?: ReadonlyArray<string> | null;
  examType?: ReadonlyArray<string> | null;
  organization?: ReadonlyArray<string> | null;
  user?: ReadonlyArray<string> | null;
};
export type StudyStatisticsFilterInput = {
  archive?: ReadonlyArray<string> | null;
  author?: ReadonlyArray<string> | null;
  authorDeleted?: ReadonlyArray<boolean> | null;
  capturedAt?: DateTimeRangeInput | null;
  credentialGroup?: ReadonlyArray<string> | null;
  domain?: ReadonlyArray<string> | null;
  examType?: ReadonlyArray<string> | null;
  isDeleted?: ReadonlyArray<boolean> | null;
  isRelevantForCredentialing?: ReadonlyArray<boolean> | null;
  organization?: ReadonlyArray<string> | null;
  reviewer?: ReadonlyArray<string> | null;
  status?: ReadonlyArray<StudySearchInputStatus> | null;
  worksheetTemplate?: ReadonlyArray<string> | null;
};
export type DateTimeRangeInput = {
  days?: number | null;
  endDate?: string | null;
  startDate?: string | null;
};
export type LmsStatisticsFilterInput = {
  groupId?: ReadonlyArray<string> | null;
  userId?: ReadonlyArray<string> | null;
};
export type analyticsRoutes_CredentialingPerformanceIndicatorsQuery$variables = {
  filter: CredentialingPageFilterInput;
  lmsStatisticsFilterInputValue: LmsStatisticsFilterInput;
  organizationSlug?: string | null;
  studyStatsFilter: StudyStatisticsFilterInput;
};
export type analyticsRoutes_CredentialingPerformanceIndicatorsQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"CredentialingPerformanceIndicators_tenant">;
  } | null;
};
export type analyticsRoutes_CredentialingPerformanceIndicatorsQuery = {
  response: analyticsRoutes_CredentialingPerformanceIndicatorsQuery$data;
  variables: analyticsRoutes_CredentialingPerformanceIndicatorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lmsStatisticsFilterInputValue"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studyStatsFilter"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "analyticsRoutes_CredentialingPerformanceIndicatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CredentialingPerformanceIndicators_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "analyticsRoutes_CredentialingPerformanceIndicatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          {
            "alias": "type",
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              }
            ],
            "concreteType": "CredentialStatistics",
            "kind": "LinkedField",
            "name": "credentialStatistics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numUsers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numUsersPartiallyCredentialed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numUsersCredentialed",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "lmsStatisticsFilterInputValue"
              }
            ],
            "concreteType": "LmsStatistics",
            "kind": "LinkedField",
            "name": "lmsStatistics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalUsersEnrolled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalUsersCompletedAllEnrollments",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "studyStatsFilter"
              }
            ],
            "concreteType": "StudyStatistics",
            "kind": "LinkedField",
            "name": "studyStatistics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numExamTypeAssociations",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numExamTypesCountingForCredentialing",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ef8b79c516772f3c1852e82eda3b186",
    "id": null,
    "metadata": {},
    "name": "analyticsRoutes_CredentialingPerformanceIndicatorsQuery",
    "operationKind": "query",
    "text": "query analyticsRoutes_CredentialingPerformanceIndicatorsQuery(\n  $organizationSlug: String\n  $filter: CredentialingPageFilterInput!\n  $studyStatsFilter: StudyStatisticsFilterInput!\n  $lmsStatisticsFilterInputValue: LmsStatisticsFilterInput!\n) {\n  tenant(slug: $organizationSlug) {\n    __typename\n    ...CredentialingPerformanceIndicators_tenant\n    id\n  }\n}\n\nfragment CredentialGroupMembersPerformanceIndicator_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  type: __typename\n  credentialStatistics(filter: $filter) {\n    numUsers\n    numUsersPartiallyCredentialed\n    numUsersCredentialed\n  }\n}\n\nfragment CredentialingPerformanceIndicators_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ...CredentialGroupMembersPerformanceIndicator_tenant\n  ...EduCoursePerformanceIndicator_tenant\n  ...ExamTypeTagsPerformanceIndicator_tenant\n}\n\nfragment EduCoursePerformanceIndicator_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  lmsStatistics(filter: $lmsStatisticsFilterInputValue) {\n    totalUsersEnrolled\n    totalUsersCompletedAllEnrollments\n  }\n}\n\nfragment ExamTypeTagsPerformanceIndicator_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  type: __typename\n  studyStatistics(filter: $studyStatsFilter) {\n    numExamTypeAssociations\n    numExamTypesCountingForCredentialing\n  }\n}\n"
  }
};
})();

(node as any).hash = "fecbef6b5663723b46c93ff1134c7467";

export default node;
