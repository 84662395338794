/**
 * @generated SignedSource<<e5b2265c07eed7c7dbf57d2ef4a99877>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddDomainRoleModal_viewer$data = {
  readonly membershipRoles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "AddDomainRoleModal_viewer";
};
export type AddDomainRoleModal_viewer$key = {
  readonly " $data"?: AddDomainRoleModal_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddDomainRoleModal_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddDomainRoleModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "roleType",
          "value": [
            "SYSTEM_DEFINED",
            "USER_DEFINED"
          ]
        },
        {
          "kind": "Literal",
          "name": "sort",
          "value": "NAME_ASC"
        }
      ],
      "concreteType": "MembershipRoleConnection",
      "kind": "LinkedField",
      "name": "membershipRoles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipRoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MembershipRole",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "membershipRoles(roleType:[\"SYSTEM_DEFINED\",\"USER_DEFINED\"],sort:\"NAME_ASC\")"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e1d5b53e30a51abd52389fd4264f872c";

export default node;
