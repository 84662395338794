/**
 * @generated SignedSource<<71272a866d9e6b2cf43aff2d66f039b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudySource = "BUTTERFLY" | "THIRD_PARTY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyDataGrid_studies$data = ReadonlyArray<{
  readonly accessionNumber: string | null;
  readonly archive: {
    readonly handle: string | null;
    readonly id: string;
    readonly label: string | null;
  } | null;
  readonly capturedAt: string | null;
  readonly createdBy: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly deletedAt: string | null;
  readonly dicomOperatorsName: string | null;
  readonly dicomReferringPhysician: string | null;
  readonly dicomRequestingPhysician: string | null;
  readonly examTypes: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly finalizationRequests: ReadonlyArray<{
    readonly recipient: {
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null> | null;
  readonly finalizedAt: string | null;
  readonly finalizedBy: {
    readonly name: string | null;
  } | null;
  readonly handle: string | null;
  readonly id: string;
  readonly isPendingFinalization: boolean | null;
  readonly numImages: number | null;
  readonly organization: {
    readonly name: string | null;
    readonly slug: string | null;
  } | null;
  readonly patient: {
    readonly birthDate: string | null;
    readonly medicalRecordNumber: string | null;
    readonly nameFirst: string | null;
    readonly nameLast: string | null;
    readonly nameMiddle: string | null;
    readonly namePrefix: string | null;
    readonly nameSuffix: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"PatientName_patient">;
  } | null;
  readonly readyAt: string | null;
  readonly secondaryAuthors: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly source: StudySource | null;
  readonly studyDescription: string | null;
  readonly tags: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly vetPatient: {
    readonly birthDate: string | null;
    readonly clientNameLast: string | null;
    readonly clientOrganizationName: string | null;
    readonly name: string | null;
    readonly patientIdNumber: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"VetPatientName_vetPatient">;
  } | null;
  readonly viewerCanDelete: boolean | null;
  readonly viewerCanMove: boolean | null;
  readonly worksheets: ReadonlyArray<{
    readonly templateVersion: {
      readonly title: string | null;
    } | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"NewStudyDataGridCaptures_study" | "StudyDataGridTags_study" | "StudyPermissions_studyReviewStatus" | "StudyTitle_study" | "ToggleStudyFavoriteButton_study">;
  readonly " $fragmentType": "StudyDataGrid_studies";
}>;
export type StudyDataGrid_studies$key = ReadonlyArray<{
  readonly " $data"?: StudyDataGrid_studies$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyDataGrid_studies">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthDate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudyDataGrid_studies",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readyAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capturedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examTypes",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "finalizedBy",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "secondaryAuthors",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomOperatorsName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomReferringPhysician",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomRequestingPhysician",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPendingFinalization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medicalRecordNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameFirst",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameLast",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameMiddle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "namePrefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameSuffix",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PatientName_patient"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientNameLast",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientOrganizationName",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "patientIdNumber",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VetPatientName_vetPatient"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksheet",
      "kind": "LinkedField",
      "name": "worksheets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksheetTemplateVersion",
          "kind": "LinkedField",
          "name": "templateVersion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyTag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanDelete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanMove",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyFinalizationRequest",
      "kind": "LinkedField",
      "name": "finalizationRequests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "recipient",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyDescription",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyTitle_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewStudyDataGridCaptures_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyDataGridTags_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ToggleStudyFavoriteButton_study"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_studyReviewStatus",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numWorksheets",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numQaEntries",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Archive",
          "kind": "LinkedField",
          "name": "archive",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "worksheetRequiredForQa",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StandardQa",
          "kind": "LinkedField",
          "name": "standardQa",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "3a712ae948122d296fc7e10063f30e7e";

export default node;
