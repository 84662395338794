/**
 * @generated SignedSource<<62dd7c600a204ed3e616bb7128c5ef7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type roles_defaultValue$data = {
  readonly description: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly permissions: any | null;
  readonly " $fragmentType": "roles_defaultValue";
};
export type roles_defaultValue$key = {
  readonly " $data"?: roles_defaultValue$data;
  readonly " $fragmentSpreads": FragmentRefs<"roles_defaultValue">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "roles_defaultValue"
};

(node as any).hash = "db7105619d61811072e87d5da31903e6";

export default node;
