import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { WorklistSyncStatus_modalityWorklist$data as ModalityWorklist } from './__generated__/WorklistSyncStatus_modalityWorklist.graphql';

interface Props {
  modalityWorklist: ModalityWorklist;
}

function WorklistSyncStatus({ modalityWorklist }: Props) {
  const { refreshJob } = modalityWorklist;
  return refreshJob!.error ? (
    <FormattedMessage
      id="WorklistSyncStatus.failedLabel"
      defaultMessage="(Failed to sync {completedAt, date, short} at {completedAt, time, short})"
      values={{
        completedAt: new Date(refreshJob!.completedAt!),
      }}
    />
  ) : (
    <FormattedMessage
      id="WorklistSyncStatus.completedLabel"
      defaultMessage="(Synced {completedAt, date, short} at {completedAt, time, short})"
      values={{
        completedAt: new Date(refreshJob!.completedAt!),
      }}
    />
  );
}
export default createFragmentContainer(WorklistSyncStatus, {
  modalityWorklist: graphql`
    fragment WorklistSyncStatus_modalityWorklist on ModalityWorklist {
      refreshJob {
        completedAt
        error
      }
    }
  `,
});
