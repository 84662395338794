/**
 * @generated SignedSource<<8e6119a418fb8a9ba4ecf751e8324964>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentListItem_comment$data = {
  readonly createdAt: string | null;
  readonly createdBy: {
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  } | null;
  readonly id: string;
  readonly viewerCanDelete: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"CommentAuthor_comment" | "CommentBody_comment" | "CommentListItem_isCommentAuthor" | "DeleteStudyImageCommentControl_comment">;
  readonly " $fragmentType": "CommentListItem_comment";
};
export type CommentListItem_comment$key = {
  readonly " $data"?: CommentListItem_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentListItem_comment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentListItem_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_userProfile"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanDelete",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentAuthor_comment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentBody_comment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteStudyImageCommentControl_comment"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "CommentListItem_isCommentAuthor",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "StudyImageComment",
  "abstractKey": null
};
})();

(node as any).hash = "faa4e9470817ebc050ed134f6969c4fa";

export default node;
