/**
 * @generated SignedSource<<da3f35733debc7f770d47f9cd9d4e70a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EulaPage_viewer$data = {
  readonly hasAcceptedLatestEula: boolean | null;
  readonly " $fragmentType": "EulaPage_viewer";
};
export type EulaPage_viewer$key = {
  readonly " $data"?: EulaPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EulaPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EulaPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAcceptedLatestEula",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "ecf3fd3c35bf5ffef6a180a3a5abca3b";

export default node;
