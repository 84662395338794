import formatName from '@bfly/utils/formatName';
import mapKeys from 'lodash/mapKeys';
import { defineMessages } from 'react-intl';
import { graphql, readInlineData } from 'react-relay';
import { Environment } from 'relay-runtime';
import { InferType, SchemaOf, date, mixed, number, object, string } from 'yup';

import asyncCommitMutation from 'utils/asyncCommitMutation';

import { baseStudyInfo_selectedWorklistItemData$key as WorklistItemKey } from './__generated__/baseStudyInfo_selectedWorklistItemData.graphql';
import { vetStudyInfoUpdateMutation as VetStudyInfoUpdateMutation } from './__generated__/vetStudyInfoUpdateMutation.graphql';
import { vetStudyInfo_study$key as StudyKey } from './__generated__/vetStudyInfo_study.graphql';
import studyInfoSchema, {
  formatWorklistItem,
  nameField,
} from './baseStudyInfo';

export const formatWorklistVetStudy = (worklistItemRef: WorklistItemKey) => {
  const worklistItem = formatWorklistItem(worklistItemRef);
  const name = formatName(worklistItem);
  return mapKeys({ name, ...worklistItem }, (_, key) =>
    key.replace('medicalRecordNumber', 'patientIdNumber'),
  );
};

const messages = defineMessages({
  invalidWeight: {
    id: 'study.vetPatient.invalidWeight',
    defaultMessage: 'Must be a positive number',
  },
});

const flattenPatient = (value) => {
  if (!value) return value;
  const { vetPatient, ...rest } = value;
  return Object.assign(rest, vetPatient);
};

const unflattenPatient = ({
  clientNameFirst,
  clientNameMiddle,
  clientNameLast,
  clientNamePrefix,
  clientNameSuffix,
  clientOrganizationName,
  name,
  patientIdNumber,
  microchipNumber,
  species,
  speciesFreeform,
  breed,
  birthDate,
  sex,
  neuterStatus,
  weightKilograms,
  ...rest
}) =>
  Object.assign(rest, {
    vetPatient: rest.vetPatient || {
      clientNameFirst,
      clientNameLast,
      clientOrganizationName,
      name,
      patientIdNumber,
      microchipNumber,
      species,
      speciesFreeform,
      breed,
      birthDate,
      sex,
      neuterStatus,
      weightKilograms,
      clientNameMiddle,
      clientNamePrefix,
      clientNameSuffix,
    },
  });

export const Species = {
  AVIAN: 'AVIAN',
  CANINE: 'CANINE',
  EQUINE: 'EQUINE',
  EXOTIC: 'EXOTIC',
  FELINE: 'FELINE',
  OTHER: 'OTHER',
  UNKNOWN: 'UNKNOWN',
} as const;

export const Sex = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  UNKNOWN: 'UNKNOWN',
} as const;

export const NeuterStatus = {
  NEUTERED: 'NEUTERED',
  INTACT: 'INTACT',
  UNKNOWN: 'UNKNOWN',
} as const;

const patientFields = {
  clientNameFirst: nameField(64).nullable(),
  clientNameMiddle: nameField(64).nullable(),
  clientNamePrefix: nameField(64).nullable(),
  clientNameSuffix: nameField(64).nullable(),
  clientNameLast: nameField(64).nullable(),
  clientOrganizationName: nameField(64).nullable(),

  name: nameField(64).nullable(),
  patientIdNumber: string().nullable().trim(),
  microchipNumber: string().nullable().trim(),
  birthDate: date().nullable(),
  species: string().enum<keyof typeof Species>(
    Object.values(Species) as any,
    Species.UNKNOWN,
  ),
  speciesFreeform: string()
    .nullable()
    .transform((v) => v || null)
    .trim(),
  breed: string()
    .nullable()
    .transform((v) => v || null)
    .trim(),
  sex: string()
    .enum<keyof typeof Sex | null>([...Object.values(Sex), null] as any, null)
    .nullable(),
  neuterStatus: string()
    .enum<keyof typeof NeuterStatus>(
      Object.values(NeuterStatus) as any,
      NeuterStatus.UNKNOWN,
    )
    .nullable(),
  weightKilograms: number()
    .positive(messages.invalidWeight as any)
    .nullable(),
};

export interface VetPatientFields {
  clientNameFirst: string | null | undefined;
  clientNameMiddle: string | null | undefined;
  clientNamePrefix: string | null | undefined;
  clientNameSuffix: string | null | undefined;
  clientNameLast: string | null | undefined;
  clientOrganizationName: string | null | undefined;

  name: string | null | undefined;
  patientIdNumber: string | null | undefined;
  microchipNumber: string | null | undefined;
  birthDate: Date | null | undefined;
  species: keyof typeof Species;
  speciesFreeform: string | null | undefined;
  breed: string | null | undefined;
  sex: keyof typeof Sex | null;
  neuterStatus: string | null;
  weightKilograms: number | null | undefined;
}

export const vetPatientSchema: SchemaOf<VetPatientFields> =
  object(patientFields);

export const schema = studyInfoSchema.shape(patientFields);

const vetStudyInfoInput = studyInfoSchema
  .shape({
    vetPatient: object({
      ...patientFields,
      birthDate: string().nullable().isoDate(),
    }),
    dicomDocument: mixed().nullable(),
  })
  .transform(unflattenPatient);

const fragment = graphql`
  fragment vetStudyInfo_study on Study @inline {
    vetPatient {
      clientNamePrefix
      clientNameSuffix
      clientNameFirst
      clientNameMiddle
      clientNameLast
      clientOrganizationName
      name
      patientIdNumber
      microchipNumber
      species
      speciesFreeform
      breed
      birthDate
      sex
      neuterStatus
      weightKilograms
    }
    dicomDocument
    accessionNumber
    hasAssociatedWorklistItem
    sourceWorklistItem {
      ... on Node {
        id
      }
    }
    ...baseStudyInfo_study @relay(mask: false)
  }
`;

export function hasPatientInfo(studyRef: StudyKey) {
  const { accessionNumber, studyDescription, vetPatient } = readInlineData(
    fragment,
    studyRef,
  );

  return (
    !!accessionNumber ||
    !!studyDescription ||
    Object.values(vetPatient!).every(Boolean)
  );
}

export const deserialize = (studyRef: StudyKey) => {
  const study = readInlineData(fragment, studyRef);

  const { vetPatient: _p, ...result } = (
    study
      ? schema
          .transform(flattenPatient)
          .from('sourceWorklistItem.id', 'sourceWorklistItemId')
          .cast({ ...study })
      : schema.getDefault()
  ) as InferType<typeof schema> & {
    vetPatient?: unknown;
  };

  return result;
};

export const getCanUseSpeciesFreeForm = (species: string) =>
  species === 'EXOTIC' || species === 'OTHER' || species === 'AVIAN';

export const serialize = (value) =>
  vetStudyInfoInput.cast(
    getCanUseSpeciesFreeForm(value)
      ? value
      : { ...value, speciesFreeform: null },
    { stripUnknown: true },
  );

const updateStudyMutation = graphql`
  mutation vetStudyInfoUpdateMutation($input: UpdateStudyInfoInput!) {
    updateStudyInfo(input: $input) {
      study {
        ...vetStudyInfo_study
      }
    }
  }
`;

export const clearPatient = (studyId: string, relay: Environment) => {
  return asyncCommitMutation<VetStudyInfoUpdateMutation>(relay, {
    mutation: updateStudyMutation,
    variables: {
      input: {
        studyId,
        vetPatient: {
          clientNamePrefix: '',
          clientNameFirst: '',
          clientNameMiddle: '',
          clientNameLast: '',
          clientOrganizationName: '',
          name: '',
          patientIdNumber: '',
          microchipNumber: '',
          species: 'UNKNOWN',
          speciesFreeform: '',
          breed: '',
          birthDate: null,
          sex: null,
          neuterStatus: 'UNKNOWN',
          weightKilograms: null,
        },
        accessionNumber: '',
        sourceWorklistItemId: null,
        dicomDocument: {},
        studyDescription: '',
      },
    },
  });
};

export function associateWithWorklistItem(
  studyId: string,
  worklistItem: WorklistItemKey,
  relay: Environment,
) {
  return asyncCommitMutation<VetStudyInfoUpdateMutation>(relay, {
    mutation: updateStudyMutation,
    variables: {
      input: {
        studyId,
        ...serialize(formatWorklistVetStudy(worklistItem)),
      },
    },
  });
}
