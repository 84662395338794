import RelayInfiniteList, {
  RelayPagination,
} from '@bfly/ui2/RelayInfiniteList';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import { useMemo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { useStudySelection } from 'utils/StudySelection';

import StudyListItem from './StudyListItem';
import type { StudyList_studies$data as Studies } from './__generated__/StudyList_studies.graphql';
import { StudyList_studiesWithImages$data as StudiesWithImages } from './__generated__/StudyList_studiesWithImages.graphql';

type StudyImages = ConnectionNodeType<
  StudiesWithImages[0],
  'imageConnection'
>[];

interface Props {
  pageSize: number;
  studies: Studies;
  studiesWithImages: StudiesWithImages | null;
  relayPagination?: RelayPagination;
  allowAssignExamTypes?: boolean;
  allowDelete?: boolean;
  allowMove?: boolean;
  idsToCursors?: Map<string, string>;
}

function StudyList({
  studies,
  studiesWithImages,
  pageSize,
  relayPagination,
  idsToCursors,
  allowAssignExamTypes = false,
  allowDelete = false,
  allowMove = false,
}: Props) {
  const [preview, { isDraggable, canSelect, selectionVisible }, dragHandlers] =
    useStudySelection({
      studies,
      allowAssignExamTypes,
      allowDelete,
      allowMove,
    });

  const imagesByStudyId = useMemo(() => {
    const result: Record<string, StudyImages> = {};

    if (!studiesWithImages) return result;
    for (const node of studiesWithImages) {
      result[node!.id] = getNodes(node?.imageConnection);
    }

    return result;
  }, [studiesWithImages]);

  const listItems = (
    <div data-bni-id="StudyList">
      {studies.map((study, i) => (
        <StudyListItem
          index={i}
          key={study.id}
          cursor={idsToCursors?.get(study.id)}
          study={study}
          studyImages={imagesByStudyId[study.id] || null}
          allowSelect={canSelect(study)}
          draggable={isDraggable(study)}
          {...dragHandlers}
          isSelectionVisible={selectionVisible}
        />
      ))}
      {preview}
    </div>
  );

  return relayPagination ? (
    <RelayInfiniteList relayPagination={relayPagination} pageSize={pageSize}>
      {listItems}
    </RelayInfiniteList>
  ) : (
    listItems
  );
}

export default createFragmentContainer(StudyList, {
  studies: graphql`
    fragment StudyList_studies on Study
    @relay(plural: true)
    @argumentDefinitions(
      showArchive: { type: "Boolean", defaultValue: false }
    ) {
      id
      archive {
        id
      }
      deletedAt
      viewerCanDelete
      viewerCanMove
      finalizedAt
      createdBy {
        id
      }
      practiceType
      patient {
        nameFirst
        nameLast
        nameMiddle
        namePrefix
        nameSuffix
      }
      vetPatient {
        clientNameLast
        clientOrganizationName
        name
      }
      examTypes {
        id
      }
      ...StudyListItem_study @arguments(showArchive: $showArchive)
    }
  `,
  studiesWithImages: graphql`
    fragment StudyList_studiesWithImages on Study @relay(plural: true) {
      id
      imageConnection(first: 6) {
        edges {
          node {
            ...StudyListItem_studyImages
          }
        }
      }
    }
  `,
});
