import Button from '@bfly/ui2/Button';
import useToast from '@bfly/ui2/useToast';
import formatName from '@bfly/utils/formatName';
import getNodes from '@bfly/utils/getNodes';
import useRouter from 'found/useRouter';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import {
  Environment,
  ReactRelayContext,
  fetchQuery,
  graphql,
} from 'react-relay';

import { getDicomName } from 'components/DicomName';
import { formattedAge } from 'components/FormattedAge';
import { useVariation } from 'components/LaunchDarklyContext';
import { Match } from 'components/Route';
import { studyStatusMessages } from 'components/StudyStatus';
import { getVetPatientName } from 'components/VetPatientName';
import useOrganizationSlug from 'hooks/useOrganizationSlug';
import { useExamRoutes } from 'routes/exam';
import { StudyStatus } from 'utils/StudyConstants';
import {
  prepareGlobalSearchStudyQueryVariables,
  prepareNewStudySort,
} from 'utils/StudyFilters';
import { studyReviewStatus } from 'utils/StudyPermissions';
import downloadFile from 'utils/downloadFile';

import { getSyncStatus } from './StudySyncStatus';
import { ExportSearchProvider_SearchListQuery } from './__generated__/ExportSearchProvider_SearchListQuery.graphql';

const papaparse = import('papaparse');

const messages = defineMessages({
  noPatient: {
    id: 'searchExport.emptyPatient',
    defaultMessage: 'No Patient Name',
  },
  patient: {
    id: 'searchExport.patient',
    defaultMessage: 'Patient',
  },
  patientID: {
    id: 'searchExport.patientID',
    defaultMessage: 'Patient ID',
  },
  org: {
    id: 'searchExport.organization',
    defaultMessage: 'Organization',
  },
  dob: {
    id: 'searchExport.DOB',
    defaultMessage: 'DOB',
  },
  capturedDate: {
    id: 'searchExport.capturedDate',
    defaultMessage: 'Capture Date',
  },
  requestingPhysician: {
    id: 'searchExport.requestingPhysician',
    defaultMessage: 'Requesting Physician',
  },
  referringPhysician: {
    id: 'searchExport.referringPhysician',
    defaultMessage: 'Referring Physician',
  },
  operator: {
    id: 'searchExport.operator',
    defaultMessage: 'Operator',
  },
  age: {
    id: 'searchExport.age',
    defaultMessage: 'Age',
  },
  uploaded: {
    id: 'searchExport.uploaded',
    defaultMessage: 'Uploaded',
  },
  worksheets: {
    id: 'searchExport.worksheets',
    defaultMessage: 'Worksheets',
  },
  tags: {
    id: 'searchExport.tags',
    defaultMessage: 'Tags',
  },
  examType: {
    id: 'searchExport.examType',
    defaultMessage: 'Exam Type',
  },
  accessionNumberColummLabel: {
    id: 'searchExport.accession',
    defaultMessage: 'Accession #',
  },
  archive: {
    id: 'searchExport.archive',
    defaultMessage: 'Archive',
  },
  sync: {
    id: 'searchExport.sync',
    defaultMessage: 'Sync',
  },
  primaryStudyAuthor: {
    id: 'searchExport.primaryStudyAuthor',
    defaultMessage: 'Primary Study Author',
  },
  secondaryStudyAuthor: {
    id: 'searchExport.secondaryStudyAuthor',
    defaultMessage: 'Secondary Study Authors',
  },
  status: {
    id: 'searchExport.status',
    defaultMessage: 'Status',
  },
  notReviewed: {
    id: 'searchExport.notReviewed',
    defaultMessage: 'Not reviewed',
  },
  reviewed: {
    id: 'searchExport.reviewed',
    defaultMessage: 'Reviewed',
  },
  qaReview: {
    id: 'searchExport.qaReview',
    defaultMessage: 'QA Review',
  },
  requestedAttester: {
    id: 'searchExport.requestedAttester',
    defaultMessage: 'Requested Attester',
  },
  finalizedBy: {
    id: 'searchExport.finalizedBy',
    defaultMessage: 'Signed by',
  },
  studyUrl: {
    id: 'searchExport.studyURL',
    defaultMessage: 'Study URL',
  },
  finalizedDate: {
    id: 'searchExport.finalizedDate',
    defaultMessage: 'Finalized Date',
  },
  studyDescription: {
    id: 'searchExport.studyDescription',
    defaultMessage: 'Study Description',
  },
  captures: {
    id: 'searchExport.captures',
    defaultMessage: 'Captures',
  },
  numOfCaptures: {
    id: 'searchExport.numOfCaptures',
    defaultMessage: '{numImages} Captures',
  },
});

const ExportSearchContext = React.createContext<{
  exportFile: (columnOrder: string[]) => void;
  exportingInProgress: boolean;
  setIsDomain: (isDomain: boolean) => void;
}>({} as any);

const searchExportQuery = graphql`
  query ExportSearchProvider_SearchListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: [StudySorting!]!
    $search: StudySearchInput
    $organizationSlug: String
  ) {
    tenant(slug: $organizationSlug) {
      studySearchConnection(
        first: $first
        after: $after
        last: $last
        before: $before
        sort: $sort
        search: $search
      ) {
        edges {
          node {
            handle
            accessionNumber
            practiceType
            organization {
              name
              slug
            }
            archive {
              label
              handle
            }
            createdBy {
              name
            }
            secondaryAuthors {
              name
            }
            patient {
              nameFirst
              nameLast
              nameMiddle
              namePrefix
              nameSuffix
              birthDate
              medicalRecordNumber
            }
            vetPatient {
              clientNameLast
              clientOrganizationName
              name
              birthDate
            }
            worksheets {
              templateVersion {
                title
              }
            }
            tags {
              name
            }
            examTypes {
              name
            }
            readyAt
            capturedAt
            finalizedBy {
              name
            }
            finalizedAt
            isPendingFinalization
            dicomOperatorsName
            dicomReferringPhysician
            dicomRequestingPhysician
            finalizationRequests {
              recipient {
                name
              }
            }
            pacsPushStatus
            pacsPushHasPausedJob
            ehrOruStatus
            ehrOruHasPausedJob
            numImages
            ...StudyPermissions_studyReviewStatus
            studyDescription
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
async function fetchStudyListQuery(
  environment: Environment,
  filters: [],
  match: Match,
  organizationSlug: string | null,
  after?: string,
) {
  const result = await fetchQuery<ExportSearchProvider_SearchListQuery>(
    environment,
    searchExportQuery,
    {
      ...filters,
      sort: prepareNewStudySort(match.location.query.sort),
      first: 100,
      after,
      organizationSlug,
    },
  ).toPromise();
  const studySearchConnection = result!.tenant!.studySearchConnection!;
  const { hasNextPage, endCursor } = studySearchConnection.pageInfo;
  const studies = getNodes(studySearchConnection);

  return hasNextPage
    ? [
        ...studies,
        ...(await fetchStudyListQuery(
          environment,
          filters,
          match,
          organizationSlug,
          endCursor!,
        )),
      ]
    : studies;
}

const getFileNameWithTimeStamp = () => {
  const date = new Date();
  const dateTimestamp = [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0'),
    String(date.getHours()).padStart(2, '0'),
    String(date.getMinutes()).padStart(2, '0'),
    String(date.getSeconds()).padStart(2, '0'),
  ].join('_');

  return `Search_Export_${dateTimestamp}.csv`;
};

interface Props {
  children?: React.ReactNode;
}

export default function ExportSearchProvider({ children }: Props) {
  const intl = useIntl();
  const examRoutes = useExamRoutes();
  const [exportingInProgress, setExportingInProgress] = useState(false);
  const [isToastManuallyDismissed, setIsToastManuallyDismissed] =
    useState(false);
  const canUseTags = useVariation('study-tags');
  const canUseWorksheets = useVariation('worksheets');
  const canUseQAReview = useVariation('worksheets-review');
  const organizationSlug = useOrganizationSlug();
  const [isDomain, setIsDomain] = useState(true);

  const exportColumnSpecs = useMemo(
    () => [
      {
        key: 'patient',
        getValue: (study) => {
          const noPatientMessage = intl.formatMessage(messages.noPatient);
          if (!study.patient && !study.vetPatient?.name) {
            return noPatientMessage;
          }

          return study.practiceType === 'HUMAN'
            ? (study.patient && formatName(study.patient)) || noPatientMessage
            : getVetPatientName(study.vetPatient);
        },
        label: intl.formatMessage(messages.patient),
      },
      {
        key: 'mrn',
        getValue: (study) => study.patient?.medicalRecordNumber,
        label: intl.formatMessage(messages.patientID),
      },
      {
        key: 'organization',
        getValue: (study) => study.organization.name,
        label: intl.formatMessage(messages.org),
        disabled: !isDomain,
      },
      {
        key: 'dob',
        getValue: (study) => {
          if (!study.patient?.birthDate && !study.vetPatient?.birthDate)
            return '-';
          return study.practiceType === 'HUMAN'
            ? intl.formatDate(study.patient?.birthDate, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            : intl.formatDate(study.vetPatient?.birthDate, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              });
        },
        label: intl.formatMessage(messages.dob),
      },
      {
        key: 'newCapturedAt',
        getValue: (study) => {
          if (!study.capturedAt) return '';
          return intl.formatDate(study.capturedAt, {
            timeZoneName: 'short',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
        },
        label: intl.formatMessage(messages.capturedDate),
      },
      {
        key: 'requestingPhysician',
        getValue: (study) =>
          study.dicomRequestingPhysician
            ? getDicomName(study.dicomRequestingPhysician)
            : '',
        label: intl.formatMessage(messages.requestingPhysician),
      },
      {
        key: 'referringPhysician',
        getValue: (study) =>
          study.dicomReferringPhysician
            ? getDicomName(study.dicomReferringPhysician)
            : '',
        label: intl.formatMessage(messages.referringPhysician),
      },
      {
        key: 'operatorsName',
        getValue: (study) =>
          study.dicomOperatorsName
            ? getDicomName(study.dicomOperatorsName)
            : '',
        label: intl.formatMessage(messages.operator),
      },
      {
        key: 'age',
        getValue: (study) => {
          if (!study.patient?.birthDate && !study.vetPatient?.birthDate)
            return '';
          return study.practiceType === 'HUMAN'
            ? formattedAge(study.patient?.birthDate, intl)
            : formattedAge(study.vetPatient?.birthDate, intl);
        },
        label: intl.formatMessage(messages.age),
      },
      {
        key: 'uploadedAt',
        getValue: (study) => {
          if (!study.readyAt) return '';
          return intl.formatDate(study.readyAt, {
            timeZoneName: 'short',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
        },
        label: intl.formatMessage(messages.uploaded),
      },
      {
        key: 'worksheets',
        getValue: (study) =>
          study.worksheets
            ? study.worksheets
                .map((worksheet) => worksheet!.templateVersion!.title)
                .join(',')
            : '',
        disabled: !canUseWorksheets,
        label: intl.formatMessage(messages.worksheets),
      },
      {
        key: 'tags',
        getValue: (study) =>
          study.tags ? study.tags.map((tag) => tag!.name).join(',') : '',
        disabled: !canUseTags,
        label: intl.formatMessage(messages.tags),
      },
      {
        key: 'examTypes',
        getValue: (study) =>
          study.examTypes
            ? study.examTypes.map((examType) => examType!.name).join(',')
            : '',
        label: intl.formatMessage(messages.examType),
      },
      {
        key: 'accessionNumber',
        getValue: (study) => study.accessionNumber,
        label: intl.formatMessage(messages.accessionNumberColummLabel),
      },
      {
        key: 'archive',
        getValue: (study) => study.archive.label,
        label: intl.formatMessage(messages.archive),
      },
      {
        key: 'syncStatus',
        getValue: ({
          pacsPushStatus,
          ehrOruStatus,
          pacsPushHasPausedJob,
          ehrOruHasPausedJob,
        }) => {
          const status = getSyncStatus(
            pacsPushStatus!,
            ehrOruStatus!,
            pacsPushHasPausedJob!,
            ehrOruHasPausedJob!,
            intl,
          );

          if (status === null) return '';
          const { studyStatus, text } = status;

          return `${text}:${studyStatus}`;
        },
        label: intl.formatMessage(messages.sync),
      },
      {
        key: 'author',
        getValue: (study) => study.createdBy?.name || 'Unassigned',
        label: intl.formatMessage(messages.primaryStudyAuthor),
      },
      {
        key: 'author',
        getValue: (study) =>
          study.secondaryAuthors
            ? study.secondaryAuthors.map((author) => author!.name).join(',')
            : '',
        label: intl.formatMessage(messages.secondaryStudyAuthor),
      },
      {
        key: 'newStatus',
        getValue: (study) => {
          if (study.finalizedAt)
            return intl.formatMessage(
              studyStatusMessages[StudyStatus.FINALIZED],
            );
          if (study.isPendingFinalization)
            return intl.formatMessage(
              studyStatusMessages[StudyStatus.PENDING_ATTESTATION],
            );
          return intl.formatMessage(studyStatusMessages[StudyStatus.DRAFT]);
        },
        label: intl.formatMessage(messages.status),
      },
      {
        key: 'qaReview',
        getValue: (study) => {
          switch (studyReviewStatus(study)) {
            case 'NEEDS_REVIEW':
              return intl.formatMessage(messages.notReviewed);
            case 'REVIEWED':
              return intl.formatMessage(messages.reviewed);
            default:
              return '';
          }
        },
        disabled: !canUseQAReview,

        label: intl.formatMessage(messages.qaReview),
      },
      {
        key: 'requestedAttester',
        getValue: (study) =>
          study.finalizationRequests
            ? study.finalizationRequests
                .map((fr) => fr.recipient.name)
                .join(',')
            : '',
        label: intl.formatMessage(messages.requestedAttester),
      },
      {
        key: 'finalizedAt',
        getValue: (study) => {
          if (!study.finalizedAt) return '';
          return intl.formatDate(study.finalizedAt, {
            timeZoneName: 'short',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
        },
        label: intl.formatMessage(messages.finalizedDate),
      },
      {
        key: 'finalizedBy',
        getValue: (study) => (study.finalizedBy ? study.finalizedBy.name : ''),
        label: intl.formatMessage(messages.finalizedBy),
      },
      {
        key: 'newCapturedAt',
        getValue: (study) => {
          return `${window.location.origin}${examRoutes.exam({
            organizationSlug: study.organization!.slug!,
            studyHandle: study.handle!,
          })}`;
        },
        label: intl.formatMessage(messages.studyUrl),
      },
      {
        key: 'studyDescription',
        getValue: (study) => study.studyDescription,
        label: intl.formatMessage(messages.studyDescription),
      },
    ],
    [intl, canUseWorksheets, canUseTags, canUseQAReview, examRoutes, isDomain],
  );

  const dismiss = useCallback(() => setIsToastManuallyDismissed(true), []);

  const { match } = useRouter();
  const relay = useContext(ReactRelayContext);
  const toast = useToast();

  const exportFile = useCallback(
    async (columnOrder: string[]) => {
      const filters = prepareGlobalSearchStudyQueryVariables({} as any, match);
      setExportingInProgress(true);
      try {
        const studies = await fetchStudyListQuery(
          relay!.environment,
          filters,
          match,
          organizationSlug,
        );
        const rows: string[][] = [];

        const filteredExportColumnSpecs = [
          ...exportColumnSpecs.filter((col) => !col.disabled),
        ];

        filteredExportColumnSpecs.sort(
          (a, b) => columnOrder.indexOf(a.key) - columnOrder.indexOf(b.key),
        );
        studies.forEach((study) => {
          const row: string[] = [];
          filteredExportColumnSpecs.forEach((column) => {
            row.push(column.getValue(study));
          });
          rows.push(row);
        });

        const { unparse } = await papaparse;

        const csvStudies = unparse({
          fields: filteredExportColumnSpecs.map((col) => col.label),
          data: rows,
        });

        await downloadFile(
          `data:text/csv;charset=utf-8,${encodeURIComponent(csvStudies)}`,
          getFileNameWithTimeStamp(),
        );
        setExportingInProgress(false);
        setIsToastManuallyDismissed(false);
      } catch {
        setExportingInProgress(false);
        toast.error(
          <FormattedMessage
            id="export.error"
            defaultMessage="An error has occurred, please try again"
          />,
        );
      }
    },
    [exportColumnSpecs, match, organizationSlug, relay, toast],
  );

  const contextValue = useMemo(
    () => ({
      exportFile,
      exportingInProgress,
      setIsDomain,
    }),
    [exportFile, exportingInProgress, setIsDomain],
  );

  return (
    <ExportSearchContext.Provider value={contextValue}>
      <div className="flex flex-col left-1/2 -translate-x-1/2 bottom-3 z-popover fixed">
        {exportingInProgress && !isToastManuallyDismissed && (
          <div className="__toast items-center w-72 p-3 rounded-lg mb-1.5 cursor-pointer">
            <div className="flex flex-grow justify-between" role="status">
              <FormattedMessage
                id="searchExport.toastText"
                defaultMessage="Export in progress"
              />
              <Button size="flush" variant="text-primary" onClick={dismiss}>
                <FormattedMessage
                  id="searchExport.toastDismiss"
                  defaultMessage="Dismiss"
                />
              </Button>
            </div>
          </div>
        )}
      </div>
      {children}
    </ExportSearchContext.Provider>
  );
}

export const useExportSearchContext = () => useContext(ExportSearchContext);
