import Layout from '@4c/layout';
import LockIcon from '@bfly/icons/Lock';
import Text from '@bfly/ui2/Text';
import { Link } from 'found';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import { useAdminRoutes } from 'routes/admin';

import educationMessages from '../messages/educationMessages';
import { SubscriberContentMessage_viewer$data as Viewer } from './__generated__/SubscriberContentMessage_viewer.graphql';

interface Props {
  viewer: Viewer | null;
}

function SubscriberContentMessage({ viewer }: Props) {
  const hasPremiumVideoAccess = useVariation('edu-premium-video-access');

  const adminRoutes = useAdminRoutes({
    organizationSlug: viewer?.memberships![0]?.organization?.slug,
  });

  if (viewer?.hasUnexpiredMembership && hasPremiumVideoAccess) {
    return null;
  }

  return (
    <Layout className="mb-6" data-bni-id="SubscriberContentMessage">
      <LockIcon className="mr-2" width={18} height={18} />
      {viewer && viewer.memberships?.length && !hasPremiumVideoAccess ? (
        <FormattedMessage {...educationMessages.addOnMessage} />
      ) : (
        <>
          <FormattedMessage {...educationMessages.membershipMessage} />
          {viewer && !!viewer.memberships?.length && (
            <Link to={adminRoutes.organizationSubscription()}>
              <Text className="font-bold ml-1" color="primary">
                <FormattedMessage {...educationMessages.startMembership} />
              </Text>
            </Link>
          )}
        </>
      )}
    </Layout>
  );
}

export default createFragmentContainer(SubscriberContentMessage, {
  viewer: graphql`
    fragment SubscriberContentMessage_viewer on Viewer {
      hasUnexpiredMembership
      memberships {
        organization {
          name
          slug
        }
      }
    }
  `,
});
