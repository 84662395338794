import Layout from '@4c/layout';
import Pagination from '@bfly/ui2/Pagination';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import NProgress from 'components/NProgress';
import usePagedConnection from 'hooks/usePagedConnection';
import { serialize } from 'schemas/analyticsFilters';

import AnalyticsPageStickySection from './AnalyticsPageStickySection';
import UserCredentialingProgressGrid from './UserCredentialingProgressGrid';
import { CredentialProgressSectionPaginationQuery } from './__generated__/CredentialProgressSectionPaginationQuery.graphql';
import { CredentialingProgressSection_credentialStatisticsByMemberConnection$key as CredentialStatisticsByMemberConnectionKey } from './__generated__/CredentialingProgressSection_credentialStatisticsByMemberConnection.graphql';
import { CredentialingProgressSection_tenant$data as Tenant } from './__generated__/CredentialingProgressSection_tenant.graphql';

interface CredentialingProgressSectionProps {
  tenant: Tenant;
}

export const serializeCredentialingFilters = serialize;

function CredentialingProgressSection({
  tenant,
}: CredentialingProgressSectionProps) {
  const [statsConnection, { index, numEdges, loadNext, loadPrevious }] =
    usePagedConnection<
      CredentialProgressSectionPaginationQuery,
      CredentialStatisticsByMemberConnectionKey,
      'credentialStatisticsByMemberConnection'
    >(
      graphql`
        fragment CredentialingProgressSection_credentialStatisticsByMemberConnection on TenantInterface
        @refetchable(queryName: "CredentialProgressSectionPaginationQuery")
        @argumentDefinitions(
          after: { type: "String" }
          first: { type: "Int", defaultValue: 50 }
          filter: { type: "CredentialingPageFilterInput!" }
          userProgressSort: { type: "[CredentialGroupMemberProgressSorting!]" }
          studyStatsFilter: { type: "StudyStatisticsFilterInput" }
        ) {
          credentialStatisticsByMemberConnection(
            first: $first
            after: $after
            filter: $filter
            sort: $userProgressSort
          ) @connection(key: "Tenant_credentialStatisticsByMemberConnection") {
            index
            numEdges
            edges {
              node {
                id
                ...UserCredentialingProgressGrid_credentialStatisticsByMembers
                  @arguments(studyStatsFilter: $studyStatsFilter)
              }
            }
          }
        }
      `,
      tenant,
      { pageSize: 50 },
    );

  return (
    <>
      <AnalyticsPageStickySection>
        <Layout pad={8} align="center" className="">
          <Text variant="lg" className="text-headline ml-16 pl-2">
            <FormattedMessage
              id="CredentialingPage.progressGrid.Headline"
              defaultMessage="People"
            />
            <Pagination
              data-bni-id="CredentialingProgressPaginationControls"
              index={index}
              onNextPage={loadNext}
              onPreviousPage={loadPrevious}
              pageSize={50}
              numItems={numEdges}
              className="mr-3 ml-3"
            />
          </Text>
        </Layout>
        <NProgress loading={false} />
      </AnalyticsPageStickySection>
      <UserCredentialingProgressGrid
        credentialStatisticsByMembers={getNodes(statsConnection)}
      />
    </>
  );
}

export default createFragmentContainer(CredentialingProgressSection, {
  tenant: graphql`
    fragment CredentialingProgressSection_tenant on TenantInterface
    @argumentDefinitions(
      after: { type: "String" }
      first: { type: "Int", defaultValue: 50 }
      filter: { type: "CredentialingPageFilterInput!" }
      userProgressSort: { type: "[CredentialGroupMemberProgressSorting!]" }
      studyStatsFilter: { type: "StudyStatisticsFilterInput" }
    ) {
      ...CredentialingProgressSection_credentialStatisticsByMemberConnection
        @arguments(
          after: $after
          first: $first
          filter: $filter
          userProgressSort: $userProgressSort
          studyStatsFilter: $studyStatsFilter
        )
    }
  `,
});
