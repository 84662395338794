import ConnectionHandler from 'relay-connection-handler-plus';

export default function handlerProvider(handle: string) {
  switch (handle) {
    case 'connection':
      return ConnectionHandler;
    default:
      throw new Error(`no handler configured for ${handle}`);
  }
}
