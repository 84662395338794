import Layout from '@4c/layout';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import { useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ConfigSectionTitle from 'components/ConfigSectionTitle';
import FormCheckSwitch from 'components/FormCheckSwitch';
import PageTitle from 'components/PageTitle';
import useChangesDialog from 'hooks/useChangesDialog';

import WorkflowDigestsExcludedArchives from './WorkflowDigestsExcludedArchives';
import WorkflowDigestsForm from './WorkflowDigestsForm';
import { WorkflowDigestsPage_viewer$data as WorkflowDigestsViewer } from './__generated__/WorkflowDigestsPage_viewer.graphql';

interface WorkflowDigestsPageProps {
  viewer: WorkflowDigestsViewer;
}

const WorkflowDigestsPage = ({ viewer }: WorkflowDigestsPageProps) => {
  const [showWorkflowDigestsInfo, setShowWorkflowDigestsInfo] = useState(
    viewer.domain?.workflowDigestSchedule !== null,
  );

  const [deleteWorkflowDigest] = useMutationWithError(
    graphql`
      mutation WorkflowDigestsPage_DeleteMutation(
        $input: DeleteWorkflowDigestScheduleInput!
      ) {
        deleteWorkflowDigestSchedule(input: $input) {
          deletedId
        }
      }
    `,
    {
      input: { domainId: viewer.domain?.id },
      updater: (store) => {
        store.get(viewer.domain!.id)!.setValue(null, 'workflowDigestSchedule');
      },
    },
  );

  const { setUnsavedChanges } = useChangesDialog({
    modalVariant: 'dark',
  });

  const toggleWorkflowDigestsInfo = (checked: boolean) => {
    setShowWorkflowDigestsInfo(checked);

    // if checked is false, we immediately delete the workflow digest - no unsaved changes
    // if checked is true, we set unsaved changes to true because they need to click save for the changes to take effect
    setUnsavedChanges(checked);

    if (!checked && viewer.domain?.workflowDigestSchedule) {
      deleteWorkflowDigest();
    }
  };

  return (
    <Layout direction="column" className="h-full pb-2">
      <Page.Header>
        <PageTitle
          title={defineMessage({
            id: 'workflowDigestsPage.WorkflowEmailDigests',
            defaultMessage: 'Workflow Email Digests',
          })}
        />
      </Page.Header>
      <Layout.Spacer />

      <MainContent>
        <ConfigSectionTitle>
          <Layout align="center" alignContent="flex-start">
            <FormattedMessage
              id="workflowDigestsPage.enable"
              defaultMessage="Enable workflow email digests"
            />
            <FormCheckSwitch
              className="w-[4.3rem]"
              onChange={toggleWorkflowDigestsInfo}
              checked={showWorkflowDigestsInfo}
            />
          </Layout>
        </ConfigSectionTitle>
        <div className="my-2 mb-12">
          {showWorkflowDigestsInfo && viewer.domain ? (
            <>
              <WorkflowDigestsForm
                schedule={viewer.domain.workflowDigestSchedule}
                setUnsavedChanges={setUnsavedChanges}
              />
              <WorkflowDigestsExcludedArchives
                archiveConnection={viewer.domain.archiveConnection}
              />
            </>
          ) : (
            <FormattedMessage
              id="workflowDigestsPage.enableDescription"
              defaultMessage="When enabled, members of this domain
            will receive regular workflow email digests regarding any draft
            studies or pending attestation studies."
            />
          )}
        </div>
      </MainContent>
    </Layout>
  );
};

export default createFragmentContainer(WorkflowDigestsPage, {
  viewer: graphql`
    fragment WorkflowDigestsPage_viewer on Viewer {
      domain {
        id
        workflowDigestSchedule {
          ...WorkflowDigestsForm_schedule
        }
        archiveConnection(isExcludedFromWorklist: true) {
          ...WorkflowDigestsExcludedArchives_archiveConnection
        }
      }
    }
  `,
});
