/**
 * @generated SignedSource<<f6b8f4123949762c16bbbd8661e48722>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type qaEntry_templateVersion$data = {
  readonly fieldDefinitions: ReadonlyArray<{
    readonly handle: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"fieldDefinition_fieldDefinition">;
  } | null> | null;
  readonly " $fragmentType": "qaEntry_templateVersion";
};
export type qaEntry_templateVersion$key = {
  readonly " $data"?: qaEntry_templateVersion$data;
  readonly " $fragmentSpreads": FragmentRefs<"qaEntry_templateVersion">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "qaEntry_templateVersion"
};

(node as any).hash = "cf1fc5c0a11b3bda77db12780ab9ea76";

export default node;
