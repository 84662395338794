import Button from '@bfly/ui2/Button';
import Heading from '@bfly/ui2/Heading';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import groupBy from 'lodash/groupBy';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';

import { bulkDomainUsersReactivateUsersResultsMessages as messages } from '../messages/DomainMembersMessages';
import { BulkDomainUsersReactivateUsersResultsModal_failures$data as Failures } from './__generated__/BulkDomainUsersReactivateUsersResultsModal_failures.graphql';

const NONEXISTING = 'invalid_data.non_existing_user';
const ACTIVE = 'invalid_data.active_user';
const DELETED = 'invalid_data.deleted_domain_user';

interface Props {
  numReactivated: number;
  failures: Failures;
  onHide: () => void;
}

function BulkReactivateUsersResultsModal({
  failures,
  numReactivated,
  onHide,
}: Props) {
  const grouped = groupBy(failures, (f) => f.code);

  return (
    <>
      <Modal.Header>
        <Modal.Title data-bni-id="BulkReactivateUsersResultsModalTitle">
          <FormattedMessage {...messages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Heading ruled data-bni-id="BulkReactivateUsersResultsModalHeading">
          <FormattedMessage
            {...messages.reactivated}
            values={{ numReactivated }}
          />
        </Heading>
        {!!grouped[NONEXISTING]?.length && (
          <details className="mb-4">
            <summary>
              <Text color="danger" variant="body-bold" className="mb-4">
                <FormattedMessage
                  {...messages.nonexistingSummary}
                  values={{ numFailed: grouped[NONEXISTING].length }}
                />
              </Text>
              <Text as="div" color="subtitle" variant="sm" className="ml-4">
                <FormattedMessage {...messages.subtitle} />
              </Text>
            </summary>
            <ul
              className="list-unstyled pl-4 mt-3"
              data-bni-id="BulkReactivateUsersResultsModalNonexistingFailures"
            >
              {grouped[NONEXISTING].map((failed) => (
                <li key={failed.email!}>{failed.email!}</li>
              ))}
            </ul>
          </details>
        )}
        {!!grouped[ACTIVE]?.length && (
          <details className="mb-4">
            <summary>
              <Text color="danger" variant="body-bold" className="mb-4">
                <FormattedMessage
                  {...messages.activeSummary}
                  values={{ numFailed: grouped[ACTIVE].length }}
                />
              </Text>
            </summary>
            <ul
              className="list-unstyled pl-4 mt-3"
              data-bni-id="BulkReactivateUsersResultsModalActiveFailures"
            >
              {grouped[ACTIVE].map((failed) => (
                <li key={failed.email!}>{failed.email!}</li>
              ))}
            </ul>
          </details>
        )}
        {!!grouped[DELETED]?.length && (
          <details className="mb-4">
            <summary>
              <Text color="danger" variant="body-bold" className="mb-4">
                <FormattedMessage
                  {...messages.deletedSummary}
                  values={{ numFailed: grouped[DELETED].length }}
                />
              </Text>
              <Text as="div" color="subtitle" variant="sm" className="ml-4">
                <FormattedMessage {...messages.subtitle} />
              </Text>
            </summary>
            <ul
              className="list-unstyled pl-4 mt-3"
              data-bni-id="BulkReactivateUsersResultsModalDeletedFailures"
            >
              {grouped[DELETED].map((failed) => (
                <li key={failed.email!}>{failed.email!}</li>
              ))}
            </ul>
          </details>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>
          <FormattedMessage {...actionMessages.done} />
        </Button>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(BulkReactivateUsersResultsModal, {
  failures: graphql`
    fragment BulkDomainUsersReactivateUsersResultsModal_failures on BulkDomainUserDeleteFailure
    @relay(plural: true) {
      detail
      code
      email
    }
  `,
});
