import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DicomName from './DicomName';
import WorklistItemDicomDocumentListItem from './WorklistItemDicomDocumentListItem';
import { ModalityWorklistItemPhysicianInformation_worklistItem$data as WorklistItem } from './__generated__/ModalityWorklistItemPhysicianInformation_worklistItem.graphql';

interface Props {
  worklistItem: WorklistItem;
}

function WorklistItemDicomDocument({ worklistItem }: Props) {
  const { dicomDocument } = worklistItem;
  if (!dicomDocument) {
    return null;
  }
  return (
    <>
      {dicomDocument.RequestingPhysician && (
        <WorklistItemDicomDocumentListItem>
          <FormattedMessage
            id="worklistItem.requestingPhysician"
            defaultMessage="Requesting Physician: {value}"
            values={{
              value: <DicomName value={dicomDocument!.RequestingPhysician} />,
            }}
          />
        </WorklistItemDicomDocumentListItem>
      )}
      {dicomDocument.ReferringPhysicianName && (
        <WorklistItemDicomDocumentListItem>
          <FormattedMessage
            id="worklistItem.referringPhysician"
            defaultMessage="Referring Physician: {value}"
            values={{
              value: (
                <DicomName value={dicomDocument!.ReferringPhysicianName} />
              ),
            }}
          />
        </WorklistItemDicomDocumentListItem>
      )}
      {dicomDocument.ScheduledProcedureStepSequence
        ?.ScheduledPerformingPhysicianName && (
        <WorklistItemDicomDocumentListItem>
          <FormattedMessage
            id="worklistItem.scheduledPerformingPhysician"
            defaultMessage="Scheduled Performing Physician: {value}"
            values={{
              value: (
                <DicomName
                  value={
                    dicomDocument!.ScheduledProcedureStepSequence
                      ?.ScheduledPerformingPhysicianName
                  }
                />
              ),
            }}
          />
        </WorklistItemDicomDocumentListItem>
      )}
    </>
  );
}

export default createFragmentContainer(WorklistItemDicomDocument, {
  worklistItem: graphql`
    fragment ModalityWorklistItemPhysicianInformation_worklistItem on ModalityWorklistItem {
      dicomDocument
    }
  `,
});
