/**
 * @generated SignedSource<<6796fea4a7c2197da4655e5c822df6f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query$variables = {
  organizationSlug: string;
};
export type organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query$data = {
  readonly connectivityConfig: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateWorksheetTemplateForm_connectivityConfig">;
  } | null;
  readonly workflowConfig: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateWorksheetTemplateForm_workflowConfig">;
  } | null;
};
export type organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query = {
  response: organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query$data;
  variables: organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  },
  {
    "kind": "Literal",
    "name": "isHidden",
    "value": false
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query",
    "selections": [
      {
        "alias": "connectivityConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateWorksheetTemplateForm_connectivityConfig"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "workflowConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateWorksheetTemplateForm_workflowConfig"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query",
    "selections": [
      {
        "alias": "connectivityConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "ExamTypeConnection",
                "kind": "LinkedField",
                "name": "examTypeConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExamType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "examTypeConnection(first:2147483647,isHidden:false)"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [
                  "isHidden"
                ],
                "handle": "connection",
                "key": "CreateWorksheetTemplateForm_examTypeConnection",
                "kind": "LinkedHandle",
                "name": "examTypeConnection"
              }
            ],
            "type": "ConnectivityConfigInterface",
            "abstractKey": "__isConnectivityConfigInterface"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "workflowConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "organizationId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "domainId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "WorkflowConfigInterface",
            "abstractKey": "__isWorkflowConfigInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9994dcc9db02ccef3a9cdc45229d5181",
    "id": null,
    "metadata": {},
    "name": "organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query",
    "operationKind": "query",
    "text": "query organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query(\n  $organizationSlug: String!\n) {\n  connectivityConfig: organizationBySlug(slug: $organizationSlug) {\n    ...CreateWorksheetTemplateForm_connectivityConfig\n    id\n  }\n  workflowConfig: organizationBySlug(slug: $organizationSlug) {\n    ...CreateWorksheetTemplateForm_workflowConfig\n    id\n  }\n}\n\nfragment CreateWorksheetTemplateForm_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  examTypeConnection(first: 2147483647, isHidden: false) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CreateWorksheetTemplateForm_workflowConfig on WorkflowConfigInterface {\n  __isWorkflowConfigInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ...useTenantIdInput_tenant\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f404241bb0486155e403f42dd128e24";

export default node;
