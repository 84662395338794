import useUpdatedRef from '@restart/hooks/useUpdatedRef';
import { useCallback, useEffect } from 'react';

import {
  Experience,
  PopoverConfig,
  useExperiencesContext,
} from 'components/ExperiencesProvider';

export { Experience };
export type { PopoverConfig };

function useExperiences(experienceKey: Experience): {
  stepIsActive: boolean;
  markCompleted: () => void;
};
function useExperiences(
  experienceKey: Experience,
  popoverConfig: Omit<PopoverConfig, 'target'>,
): {
  stepIsActive: boolean;
  markCompleted: () => void;
  targetRef: React.RefCallback<HTMLElement>;
};
function useExperiences(
  experienceKey: Experience,
  popoverConfig?: Omit<PopoverConfig, 'target'>,
) {
  // b/c this is used inside of a ref callback, the config will be stale (even in an event callback)
  // so we store it in a ref
  const configRef = useUpdatedRef(popoverConfig);
  const { tutorialStep, markCompleted, setPopoverConfig } =
    useExperiencesContext();

  const markKeyCompleted = useCallback(() => {
    if (experienceKey !== tutorialStep) {
      return;
    }

    markCompleted(experienceKey, 'experienceTooltipCompleted');
  }, [experienceKey, tutorialStep, markCompleted]);

  const result: any = {
    stepIsActive: tutorialStep === experienceKey,
    markCompleted: markKeyCompleted,
  };

  const targetRef = useCallback(
    (target: HTMLElement | null) => {
      if (tutorialStep !== experienceKey) return;
      setPopoverConfig({
        target,
        ...configRef.current!,
      });
    },
    [experienceKey, tutorialStep, setPopoverConfig, configRef],
  );

  if (popoverConfig) {
    result.targetRef = targetRef;
  }

  return result;
}

export default useExperiences;

export function useMarkExperienceCompleted(experienceKey: Experience) {
  const { tutorialStep, markCompleted } = useExperiencesContext();

  useEffect(() => {
    if (experienceKey !== tutorialStep) return;

    markCompleted(experienceKey, 'experienceTooltipAutoDismissed');
  }, [markCompleted, experienceKey, tutorialStep]);
}
