/**
 * @generated SignedSource<<54a243540fd684d4fa9045ff037b58d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FhirPatientSearchModalContentQuery$variables = {
  birthDate?: string | null;
  medicalRecordNumber?: string | null;
  name?: string | null;
  organizationSlug?: string | null;
  skip: boolean;
};
export type FhirPatientSearchModalContentQuery$data = {
  readonly organization?: {
    readonly fhirPatients: ReadonlyArray<{
      readonly birthDate: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly medicalRecordNumber: string | null;
      readonly mrnType: string | null;
      readonly name: string | null;
      readonly patientFhirId: string | null;
      readonly sex: string | null;
    } | null> | null;
  } | null;
};
export type FhirPatientSearchModalContentQuery = {
  response: FhirPatientSearchModalContentQuery$data;
  variables: FhirPatientSearchModalContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "birthDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "medicalRecordNumber"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v5 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "birthdate",
      "variableName": "birthDate"
    },
    {
      "kind": "Variable",
      "name": "mrn",
      "variableName": "medicalRecordNumber"
    },
    {
      "kind": "Variable",
      "name": "name",
      "variableName": "name"
    }
  ],
  "concreteType": "FhirPatient",
  "kind": "LinkedField",
  "name": "fhirPatients",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "medicalRecordNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mrnType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientFhirId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FhirPatientSearchModalContentQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "organization",
            "args": (v5/*: any*/),
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organizationBySlug",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "FhirPatientSearchModalContentQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "organization",
            "args": (v5/*: any*/),
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organizationBySlug",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "592fb77087837e3a6d1e52f5023de5c1",
    "id": null,
    "metadata": {},
    "name": "FhirPatientSearchModalContentQuery",
    "operationKind": "query",
    "text": "query FhirPatientSearchModalContentQuery(\n  $name: String\n  $birthDate: Date\n  $medicalRecordNumber: String\n  $organizationSlug: String = \"\"\n  $skip: Boolean!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) @skip(if: $skip) {\n    fhirPatients(name: $name, birthdate: $birthDate, mrn: $medicalRecordNumber) {\n      name\n      firstName\n      lastName\n      birthDate\n      medicalRecordNumber\n      mrnType\n      sex\n      patientFhirId\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab6f3daf9e83d3aaed9a46908079282b";

export default node;
