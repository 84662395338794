/**
 * @generated SignedSource<<5d22e27e09c8caf9dacddf4d6f7c9784>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PagedArchivePage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_viewer">;
  readonly " $fragmentType": "PagedArchivePage_viewer";
};
export type PagedArchivePage_viewer$key = {
  readonly " $data"?: PagedArchivePage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PagedArchivePage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PagedArchivePage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "5c3a650ce763a3ebc36a3aaf1ae9856e";

export default node;
