import { DateRangePickerValue } from '@bfly/ui2/DateRangePicker';
import { defineMessage } from 'react-intl';
import dates from 'react-widgets/dates';
import { InferType, date, number, object, string } from 'yup';

import { isValidDate, toIsoDateString } from 'utils/DateSerialization';

const dateRangeSchema = object({
  days: number().optional(),
  startDate: date().nullable(),
  endDate: date().nullable(),
});

export type DateRange = InferType<typeof dateRangeSchema>;

export const dateRangeWithValidation = dateRangeSchema.test(
  'valid-range',
  defineMessage({
    id: 'dateRange.validRange',
    defaultMessage: 'End date must be after the start date',
  }),
  (value: DateRange) => {
    if (!value || 'days' in value || (value as DateRange).endDate == null)
      return true;
    if (!(value as DateRange).startDate) return false;
    return dates.gte(
      (value as DateRange).endDate!,
      (value as DateRange).startDate!,
      'day',
    );
  },
);

export default dateRangeSchema;

const dateTimeRangeInput = object({
  days: number().optional(),
  startDate: string()
    .nullable()
    .transform((_, value) => {
      if (!isValidDate(value)) return null;
      return dates.startOf(value, 'day').toISOString();
    }),
  endDate: string()
    .nullable()
    .transform((_, value) => {
      if (!isValidDate(value)) return null;
      return dates.endOf(value, 'day').toISOString();
    }),
});

export function serializeToDateTimeRange(
  value: DateRange | DateRangePickerValue | null,
): InferType<typeof dateTimeRangeInput> | null {
  return dateTimeRangeInput.nullable().cast(value, { stripUnknown: true });
}

const dateRangeInput = object({
  days: number().optional(),
  startDate: string()
    .nullable()
    .transform((_, value) =>
      isValidDate(value) ? toIsoDateString(value) : null,
    ),
  endDate: string()
    .nullable()
    .transform((_, value) =>
      isValidDate(value) ? toIsoDateString(value) : null,
    ),
});

export function serializeToDateRange(
  value: DateRange | DateRangePickerValue | null,
): InferType<typeof dateRangeInput> | null {
  return dateRangeInput.nullable().cast(value, { stripUnknown: true });
}

/**
 * Takes a DateRangePickerValue and returns it in terms of a start and end date,
 * with the dates normalized to the start and end of their day respectively
 */
export function serializeToDateTimes(
  dateRange: DateRange | null | undefined,
): {
  startDate: string | null;
  endDate: string | null;
} {
  if (dateRange?.days != null)
    return {
      startDate: dates
        .startOf(dates.add(new Date(), -dateRange.days, 'day'), 'day')
        .toISOString(),
      endDate: null,
    };

  const { startDate = null, endDate = null } = dateRange || {};
  return {
    startDate: startDate
      ? dates.startOf(startDate, 'day').toISOString()
      : null,
    endDate: endDate ? dates.endOf(endDate, 'day').toISOString() : null,
  };
}

export function deserializeDateRange(value: any) {
  return dateRangeSchema.cast(value, { stripUnknown: true });
}
