/**
 * @generated SignedSource<<612f1095453098f005239a9f82ee6cb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainLoginPage_Query$variables = {
  domainSubdomainLabel: string;
};
export type DomainLoginPage_Query$data = {
  readonly domainProfile: {
    readonly " $fragmentSpreads": FragmentRefs<"DomainLoginPage_domainProfile">;
  } | null;
};
export type DomainLoginPage_Query = {
  response: DomainLoginPage_Query$data;
  variables: DomainLoginPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainSubdomainLabel"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "subdomainLabel",
    "variableName": "domainSubdomainLabel"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainLoginPage_Query",
    "selections": [
      {
        "alias": "domainProfile",
        "args": (v1/*: any*/),
        "concreteType": "DomainProfile",
        "kind": "LinkedField",
        "name": "domainProfileBySubdomainLabel",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainLoginPage_domainProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DomainLoginPage_Query",
    "selections": [
      {
        "alias": "domainProfile",
        "args": (v1/*: any*/),
        "concreteType": "DomainProfile",
        "kind": "LinkedField",
        "name": "domainProfileBySubdomainLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ButterflyIdentityProvider",
            "kind": "LinkedField",
            "name": "butterflyIdentityProvider",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FederatedIdentityProvider",
            "kind": "LinkedField",
            "name": "federatedIdentityProvider",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "authorizationEndpointBaseUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6a40b83ee8cac29ec3ecd199a671c091",
    "id": null,
    "metadata": {},
    "name": "DomainLoginPage_Query",
    "operationKind": "query",
    "text": "query DomainLoginPage_Query(\n  $domainSubdomainLabel: String!\n) {\n  domainProfile: domainProfileBySubdomainLabel(subdomainLabel: $domainSubdomainLabel) {\n    ...DomainLoginPage_domainProfile\n  }\n}\n\nfragment DomainLoginPage_domainProfile on DomainProfile {\n  name\n  handle\n  butterflyIdentityProvider {\n    __typename\n  }\n  federatedIdentityProvider {\n    authorizationEndpointBaseUrl\n    clientId\n  }\n}\n"
  }
};
})();

(node as any).hash = "27ade7c8452af7b8cc41e197a1668b6f";

export default node;
