import DropdownList, { type DropdownListProps } from '@bfly/ui2/DropdownList';
import Multiselect, { type MultiselectProps } from '@bfly/ui2/Multiselect';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';

import { renderUserSuggestion } from 'components/UserSuggestionListItem';
import useSearchQuery from 'hooks/useSearchQuery';

import { TenantMembershipAutoCompleteQuery } from './__generated__/TenantMembershipAutoCompleteQuery.graphql';
import { TenantMembershipAutoComplete_tenant$data as Tenant } from './__generated__/TenantMembershipAutoComplete_tenant.graphql';

export interface TenantMembershipOption {
  label: string;
  value: string;
  membership?: ConnectionNodeType<
    TenantMembershipAutoCompleteQuery['response']['tenant'],
    'membershipConnection'
  >;
}

function useTenantMemberships(tenant: Tenant, searchTerm: string) {
  const { data } = useSearchQuery<TenantMembershipAutoCompleteQuery>(
    graphql`
      query TenantMembershipAutoCompleteQuery(
        $organizationSlug: String
        $search: String
      ) {
        tenant(slug: $organizationSlug) {
          ... on Organization {
            membershipConnection(first: 30, search: $search) {
              edges {
                node {
                  userProfile {
                    id
                    name
                    handle
                  }
                  email
                  ...UserSuggestionListItem_userInfo
                }
              }
            }
          }
          ... on Domain {
            membershipConnection(first: 30, search: $search) {
              edges {
                node {
                  userProfile {
                    id
                    name
                    handle
                  }
                  email
                  ...UserSuggestionListItem_userInfo
                }
              }
            }
          }
        }
      }
    `,
    searchTerm,
    { organizationSlug: tenant.slug },
  );

  const membershipOptions = useMemo(() => {
    return getNodes(data?.tenant?.membershipConnection)
      .filter((m) => m.userProfile)
      .map((m) => ({
        label: m.userProfile!.name || '',
        value: m.userProfile!.id,
        membership: m!,
      }));
  }, [data]);

  const busy = !!searchTerm && !data;

  return [membershipOptions, { busy }] as const;
}

const returnsTrue = () => true;

const renderListItem: MultiselectProps<TenantMembershipOption>['renderListItem'] =
  ({ item, ...args }) =>
    renderUserSuggestion({ item: item.membership!, ...args });

type Props =
  | (MultiselectProps<TenantMembershipOption> & {
      multiple: true;
      tenant: Tenant;
    })
  | (DropdownListProps<TenantMembershipOption> & {
      multiple?: false;
      tenant: Tenant;
    });

function TenantMembershipAutoComplete({ tenant, multiple, ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [membershipOptions, { busy }] = useTenantMemberships(
    tenant,
    searchTerm,
  );

  const baseProps = {
    textField: 'label',
    dataKey: 'value',
    data: membershipOptions,
    renderListItem,
    onSearch: setSearchTerm,
    busy,
    messages: {
      emptyList:
        !searchTerm || busy ? (
          <FormattedMessage
            id="tenantMembershipAutocomplete.noSearch"
            defaultMessage="Type to search by name or email…"
          />
        ) : (
          <FormattedMessage
            id="tenantMembershipAutocomplete.emptySearch"
            defaultMessage="No one matching this search found"
          />
        ),
    },
  };

  return multiple ? (
    <Multiselect<TenantMembershipOption>
      {...(props as MultiselectProps<TenantMembershipOption>)}
      filter={false}
      {...baseProps}
    />
  ) : (
    <DropdownList<TenantMembershipOption>
      {...(props as DropdownListProps<TenantMembershipOption>)}
      filter={returnsTrue}
      {...baseProps}
    />
  );
}

export default createFragmentContainer(TenantMembershipAutoComplete, {
  tenant: graphql`
    fragment TenantMembershipAutoComplete_tenant on TenantInterface {
      ... on Organization {
        slug
      }
    }
  `,
});
