/**
 * @generated SignedSource<<c230589435e9ad3978b2a8d362405e1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorksheetAndPrefillSelect_TemplatePrefillQuery$variables = {
  hasPrefill: boolean;
  prefillId: string;
  templateVersionId: string;
};
export type WorksheetAndPrefillSelect_TemplatePrefillQuery$data = {
  readonly prefill?: {
    readonly values?: Record<string, any> | null;
  };
  readonly templateVersion: {
    readonly " $fragmentSpreads": FragmentRefs<"worksheet_templateVersion">;
  };
};
export type WorksheetAndPrefillSelect_TemplatePrefillQuery = {
  response: WorksheetAndPrefillSelect_TemplatePrefillQuery$data;
  variables: WorksheetAndPrefillSelect_TemplatePrefillQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasPrefill"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "prefillId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "templateVersionId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "templateVersionId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": "typename",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "required",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FieldOption",
    "kind": "LinkedField",
    "name": "options",
    "plural": true,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowOther",
    "storageKey": null
  }
],
v8 = {
  "kind": "InlineFragment",
  "selections": (v7/*: any*/),
  "type": "SingleSelectFieldDefinition",
  "abstractKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": (v7/*: any*/),
  "type": "MultiSelectFieldDefinition",
  "abstractKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "prefillId"
  }
],
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "values",
      "storageKey": null
    }
  ],
  "type": "WorksheetPrefill",
  "abstractKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorksheetAndPrefillSelect_TemplatePrefillQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "templateVersion",
          "args": (v3/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "worksheet_templateVersion",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WorksheetSectionDefinition",
                      "kind": "LinkedField",
                      "name": "sections",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "fieldDefinitions",
                          "plural": true,
                          "selections": [
                            (v4/*: any*/),
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "fieldDefinition_fieldDefinition",
                              "selections": [
                                (v5/*: any*/),
                                (v4/*: any*/),
                                (v6/*: any*/),
                                (v8/*: any*/),
                                (v9/*: any*/)
                              ],
                              "args": null,
                              "argumentDefinitions": []
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "WorksheetTemplateVersion",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "templateVersion"
      },
      {
        "condition": "hasPrefill",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": "prefill",
              "args": (v10/*: any*/),
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v11/*: any*/)
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "prefill"
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorksheetAndPrefillSelect_TemplatePrefillQuery",
    "selections": [
      {
        "alias": "templateVersion",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorksheetSectionDefinition",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "fieldDefinitions",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isFormFieldDefinitionInterface"
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "WorksheetTemplateVersion",
            "abstractKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "hasPrefill",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "prefill",
            "args": (v10/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "f7e2d92c2fef11a953d52817eed642cd",
    "id": null,
    "metadata": {},
    "name": "WorksheetAndPrefillSelect_TemplatePrefillQuery",
    "operationKind": "query",
    "text": "query WorksheetAndPrefillSelect_TemplatePrefillQuery(\n  $templateVersionId: ID!\n  $prefillId: ID!\n  $hasPrefill: Boolean!\n) {\n  templateVersion: node(id: $templateVersionId) {\n    __typename\n    ... on WorksheetTemplateVersion {\n      ...worksheet_templateVersion\n    }\n    id\n  }\n  prefill: node(id: $prefillId) @include(if: $hasPrefill) {\n    __typename\n    ... on WorksheetPrefill {\n      values\n    }\n    id\n  }\n}\n\nfragment fieldDefinition_fieldDefinition on FormFieldDefinitionInterface {\n  __isFormFieldDefinitionInterface: __typename\n  typename: __typename\n  handle\n  required\n  ... on SingleSelectFieldDefinition {\n    options {\n      handle\n      label\n    }\n    allowOther\n  }\n  ... on MultiSelectFieldDefinition {\n    options {\n      handle\n      label\n    }\n    allowOther\n  }\n}\n\nfragment worksheet_templateVersion on WorksheetTemplateVersion {\n  sections {\n    fieldDefinitions {\n      __typename\n      handle\n      ...fieldDefinition_fieldDefinition\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "89f949241e739e8f4aa24209d0298fc1";

export default node;
