/**
 * @generated SignedSource<<af8499a71e126c94d1ecde9cc5be3386>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferredPage_referrerInfo$data = {
  readonly shareLinkEmail: string | null;
  readonly shareLinkMobile: string | null;
  readonly " $fragmentType": "ReferredPage_referrerInfo";
};
export type ReferredPage_referrerInfo$key = {
  readonly " $data"?: ReferredPage_referrerInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferredPage_referrerInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferredPage_referrerInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareLinkEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareLinkMobile",
      "storageKey": null
    }
  ],
  "type": "ReferrerInfo",
  "abstractKey": null
};

(node as any).hash = "80dc8322c3d1c96fc59af4872dad9076";

export default node;
