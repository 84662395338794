/**
 * @generated SignedSource<<13029cb95a33ca89986967ef66270791>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
export type VetPatientNeuterStatus = "INTACT" | "NEUTERED" | "UNKNOWN" | "%future added value";
export type VetPatientSex = "FEMALE" | "MALE" | "UNKNOWN" | "%future added value";
export type VetPatientSpecies = "AVIAN" | "CANINE" | "EQUINE" | "EXOTIC" | "FELINE" | "OTHER" | "UNKNOWN" | "%future added value";
export type UpdateStudyInfoInput = {
  accessionNumber?: string | null;
  clientMutationId?: string | null;
  createdBy?: string | null;
  createdById?: string | null;
  dicomDocument?: Record<string, any> | null;
  internalId?: string | null;
  notes?: string | null;
  patient?: UpdateStudyInfoInputPatient | null;
  sourceWorklistItemId?: string | null;
  studyDescription?: string | null;
  studyId: string;
  vetPatient?: UpdateStudyInfoInputVetPatient | null;
};
export type UpdateStudyInfoInputPatient = {
  birthDate?: string | null;
  internalId?: string | null;
  medicalRecordNumber?: string | null;
  nameFirst?: string | null;
  nameLast?: string | null;
  nameMiddle?: string | null;
  namePrefix?: string | null;
  nameSuffix?: string | null;
  sex?: PatientSex | null;
};
export type UpdateStudyInfoInputVetPatient = {
  birthDate?: string | null;
  breed?: string | null;
  clientNameFirst?: string | null;
  clientNameLast?: string | null;
  clientNameMiddle?: string | null;
  clientNamePrefix?: string | null;
  clientNameSuffix?: string | null;
  clientOrganizationName?: string | null;
  microchipNumber?: string | null;
  name?: string | null;
  neuterStatus?: VetPatientNeuterStatus | null;
  patientIdNumber?: string | null;
  sex?: VetPatientSex | null;
  species?: VetPatientSpecies | null;
  speciesFreeform?: string | null;
  weightKilograms?: number | null;
};
export type ExamPageSidebarNotes_UpdateStudyInfo_Mutation$variables = {
  input: UpdateStudyInfoInput;
};
export type ExamPageSidebarNotes_UpdateStudyInfo_Mutation$data = {
  readonly updateStudyInfoOrError: {
    readonly __typename?: string;
    readonly fields?: ReadonlyArray<{
      readonly message: string;
      readonly path: ReadonlyArray<string>;
    }>;
    readonly message?: string | null;
    readonly study?: {
      readonly notes: string | null;
    } | null;
  } | null;
};
export type ExamPageSidebarNotes_UpdateStudyInfo_Mutation = {
  response: ExamPageSidebarNotes_UpdateStudyInfo_Mutation$data;
  variables: ExamPageSidebarNotes_UpdateStudyInfo_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvalidInputErrorField",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvalidInputError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamPageSidebarNotes_UpdateStudyInfo_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateStudyInfoOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateStudyInfoPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamPageSidebarNotes_UpdateStudyInfo_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateStudyInfoOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateStudyInfoPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c598f0423b3201a596a1cc2795f5e6c",
    "id": null,
    "metadata": {},
    "name": "ExamPageSidebarNotes_UpdateStudyInfo_Mutation",
    "operationKind": "mutation",
    "text": "mutation ExamPageSidebarNotes_UpdateStudyInfo_Mutation(\n  $input: UpdateStudyInfoInput!\n) {\n  updateStudyInfoOrError(input: $input) {\n    __typename\n    ... on UpdateStudyInfoPayload {\n      study {\n        notes\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      ... on InvalidInputError {\n        fields {\n          message\n          path\n        }\n      }\n      ... on MaxSeatsExceededError {\n        message\n      }\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f130c5928de7730bb2593d60c66c5937";

export default node;
