import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import { useField } from '@bfly/ui2/Form';
import FormMessage from '@bfly/ui2/FormMessage';
import PreviewGrid from '@bfly/ui2/PreviewGrid';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';
import Analytics from 'utils/Analytics';

import StudyCaptureSelectorImagePreviewItem from './StudyCaptureSelectorImagePreviewItem';
import { StudyCaptureSelector_study$data as Study } from './__generated__/StudyCaptureSelector_study.graphql';

interface Props {
  study: Study;
  heading: React.ReactNode;
}

function StudyCaptureSelector({ study, heading }: Props) {
  const [fieldProps] = useField('imageIds');

  const { onChange: setSelectedImages, value: selectedImages } = fieldProps;

  const images = getNodes(study.images);

  const roundToNearestMinute = (image) => {
    // ms in one minute
    const msInterval = 60 * 1000;
    return new Date(
      Math.floor(new Date(image.capturedAt).getTime() / msInterval) *
        msInterval,
    );
  };

  const imagesByCapturedAt = Object.entries(
    groupBy(
      sortBy(images, (i) => i.capturedAt),
      (i) => new Date(i.capturedAt!).toLocaleDateString(),
    ),
  ).map(([day, imagesFromDay]) => ({
    date: new Date(day),
    imagesFromDate: groupBy(imagesFromDay, roundToNearestMinute),
  }));

  const handleSelectGroup = (imageGroup) => {
    const imageGroupIds = imageGroup
      .filter((image) => !selectedImages.includes(image.id))
      .map((image) => image.id);
    setSelectedImages(selectedImages.concat(imageGroupIds));
    Analytics.track('studyCaptureSelectorImageGroupSelected', {
      studyId: study.id,
      organizationId: study.organization?.id,
      imageGroupIds,
    });
  };

  const handleDeselectGroup = (imageGroup) => {
    const imageGroupIds = imageGroup.map((image) => image.id);
    setSelectedImages(
      selectedImages.filter((imageId) => !imageGroupIds.includes(imageId)),
    );
    Analytics.track('studyCaptureSelectorImageGroupDeselected', {
      studyId: study.id,
      organizationId: study.organization?.id,
      imageGroupIds,
    });
  };

  return (
    <>
      <Layout justify="space-between" align="center">
        <Text>{heading}</Text>
        {images.length === selectedImages.length ? (
          <Button
            variant="text-primary"
            onClick={() => {
              setSelectedImages([]);
              Analytics.track('studyCaptureSelectorAllImagesDeselected', {
                studyId: study.id,
              });
            }}
          >
            <FormattedMessage {...actionMessages.deselectAll} />
          </Button>
        ) : (
          <Button
            variant="text-primary"
            onClick={() => {
              setSelectedImages(images.map((image) => image.id));
              Analytics.track('studyCaptureSelectorAllImagesSelected', {
                studyId: study.id,
                organizationId: study.organization?.id,
              });
            }}
          >
            <FormattedMessage {...actionMessages.selectAll} />
          </Button>
        )}
      </Layout>
      <FormMessage for="imageIds" />
      {imagesByCapturedAt.map(({ date, imagesFromDate }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Layout direction="column" key={idx}>
          <Text>
            <FormattedDate
              value={date}
              weekday="short"
              month="short"
              day="numeric"
              year="numeric"
            />
          </Text>
          {Object.entries(imagesFromDate).map(([capturedAt, imageGroup]) => (
            <Layout direction="column" key={capturedAt}>
              <Layout justify="space-between" align="center">
                <Text color="subtitle">
                  <FormattedTime value={capturedAt} />
                </Text>
                {imageGroup.every((image) =>
                  selectedImages.includes(image.id),
                ) ? (
                  <Button
                    variant="text-primary"
                    onClick={() => handleDeselectGroup(imageGroup)}
                  >
                    <FormattedMessage {...actionMessages.deselect} />
                  </Button>
                ) : (
                  <Button
                    variant="text-primary"
                    id="select-group"
                    onClick={() => handleSelectGroup(imageGroup)}
                  >
                    <FormattedMessage {...actionMessages.select} />
                  </Button>
                )}
              </Layout>
              <PreviewGrid>
                {imageGroup.map((image, index) => (
                  <StudyCaptureSelectorImagePreviewItem
                    key={image.handle}
                    image={image}
                    index={index}
                  />
                ))}
              </PreviewGrid>
            </Layout>
          ))}
        </Layout>
      ))}
    </>
  );
}

export default createFragmentContainer(StudyCaptureSelector, {
  study: graphql`
    fragment StudyCaptureSelector_study on Study {
      id
      images: imageConnection {
        edges {
          node {
            id
            capturedAt
            handle
            ...StudyCaptureSelectorImagePreviewItem_image
          }
        }
      }
      organization {
        id
      }
    }
  `,
});
