/**
 * @generated SignedSource<<5936b04cb7c13f1a5594a69e3023b91e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canEditStudy$data = {
  readonly finalizedAt: string | null;
  readonly viewerCanEdit: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "StudyPermissions_canEditStudy";
};
export type StudyPermissions_canEditStudy$key = {
  readonly " $data"?: StudyPermissions_canEditStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canEditStudy">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canEditStudy"
};

(node as any).hash = "d6743f75a59b9bbc8a711797edf24958";

export default node;
