/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/no-interactive-element-to-noninteractive-role */
import Caret from '@bfly/ui2/Caret';
import Dropdown from '@bfly/ui2/Dropdown';
import { useEventListener } from '@restart/hooks';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import clsx from 'clsx';
// eslint-disable-next-line no-restricted-imports
import Link, { LinkPropsCommon } from 'found/Link';
import React, { ReactElement, useRef, useState } from 'react';

import ScrollView from './ScrollView';

import styles from './DataGridTable.module.scss';

export { styles };

/**
 *  When the table is scrolled all the way to the right the utility
 *  column width should be wide enough to allow the last data column
 *  to be flush with the frozen column.
 *
 *  |                 parent               |
 *  |--------|----------|---------|--------|
 *  | frozen | lastData | utility | picker |
 *  |--------|----------|---------|--------|
 *
 * utility = parent - (lastData + frozen + picker);
 */
const calculateUtilityColumnWidth = (
  table: HTMLTableElement,
  viewportScrolling: boolean,
): number => {
  const lastFrozenElement = table.querySelector('th[data-last-frozen]')!;

  if (!lastFrozenElement) return 0;

  const utilityElement = table.querySelector('[data-utility-header]')!;

  const lastDataColumn = utilityElement.previousElementSibling;

  if (lastDataColumn === lastFrozenElement) return 0;

  const parentWidth = viewportScrolling
    ? document.documentElement.clientWidth
    : table.parentElement!.offsetWidth;

  const pickerElement = utilityElement.nextElementSibling;

  const pickerWidth = pickerElement?.clientWidth || 0;

  const lastDataWidth = lastDataColumn!.clientWidth;

  const frozenWidth = lastFrozenElement.getBoundingClientRect().right;

  const utilityWidth =
    parentWidth - (frozenWidth + lastDataWidth + pickerWidth);

  return Math.max(0, utilityWidth);
};

interface Props extends React.ComponentPropsWithoutRef<'table'> {
  className?: string;
  children: React.ReactNode;
  scrollKey: string | null;
  viewportScrolling?: boolean;
  templateColumns: string;
  scrollRef?: React.Ref<HTMLElement>;
  allowScrollToLastColumn?: boolean;
  showColPicker?: boolean;
}

export default function DataGridTable({
  children,
  className,
  scrollKey,
  templateColumns,
  scrollRef,
  style,
  showColPicker,
  allowScrollToLastColumn,
  viewportScrolling,
  ...props
}: Props) {
  const ref = useRef<HTMLTableElement | null>(null);
  const mergedScrollRef = useMergedRefs(ref, scrollRef);

  const [utilityColumnWidth, setUtilityColumnWidth] = useState(0);

  useEventListener(
    () => (viewportScrolling ? window : ref.current!),
    'scroll',
    (e) => {
      const table = ref.current!;
      const scrollParent =
        'scrollX' in e.currentTarget!
          ? document.documentElement
          : (e.currentTarget! as HTMLTableElement);

      const isScrolledLeft = scrollParent.scrollLeft === 0;

      const isScrolledRight =
        scrollParent.scrollWidth - scrollParent.scrollLeft ===
        scrollParent.clientWidth;

      table.classList.toggle(styles.stuckStart, !isScrolledLeft);
      table.classList.toggle(styles.stuckEnd, !isScrolledRight);

      if (allowScrollToLastColumn)
        setUtilityColumnWidth(
          calculateUtilityColumnWidth(ref.current!, !!viewportScrolling),
        );
    },
  );

  const tableStyle = {
    ...style,
    gridTemplateColumns: `${templateColumns} 
    [utility] minmax(${utilityColumnWidth}px, 1fr) 
    [picker] ${showColPicker ? 40 : 0}px`,
  };

  return !viewportScrolling ? (
    <ScrollView
      {...props}
      ref={mergedScrollRef}
      as="table"
      scrollKey={scrollKey}
      style={tableStyle}
      className={clsx(className, styles.table)}
    >
      {children}
    </ScrollView>
  ) : (
    <table
      style={tableStyle}
      ref={mergedScrollRef}
      className={clsx(className, styles.viewportScrolling, styles.table)}
    >
      {children}
    </table>
  );
}

export type DataTableCellProps<T extends 'th' | 'td'> =
  React.ComponentPropsWithoutRef<T> & {
    frozen?: boolean;
    lastFrozen?: boolean;
  };

const DataGridTableHeader = ({
  frozen,
  placeholder,
  className,
  lastFrozen,
  menuItems,
  children,
  onSelect: _,
  style,
  ...props
}: Omit<DataTableCellProps<'th'>, 'placeholder'> & {
  menuItems?: React.ReactNode;
  placeholder?: boolean;
}) => {
  return (
    <th
      {...props}
      style={style}
      data-grid-header={!placeholder ? '' : undefined}
      className={clsx(
        className,
        styles.header,
        placeholder && 'bg-body',
        frozen && styles.frozen,
        !menuItems && styles.content,
        lastFrozen && styles.lastFrozen,
      )}
    >
      {menuItems ? (
        <Dropdown className="contents">
          <Dropdown.Toggle
            as="button"
            type="button"
            className={clsx(styles.content, 'w-full h-full')}
          >
            <span className="flex-grow">{children}</span>
            <Caret />
          </Dropdown.Toggle>
          <Dropdown.Menu
            role="menu"
            placement="bottom-end"
            flip={false}
            popperConfig={{
              modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
            }}
          >
            {menuItems}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        children
      )}
    </th>
  );
};

DataGridTable.SortMenuButton = ({ toggle, children, id, ...props }: any) => {
  return (
    <Dropdown
      {...props}
      className={clsx(props.className, 'inline-block -mr-4')}
    >
      <Dropdown.Toggle id={id} variant="text-secondary" css="height: auto;">
        {toggle}
        <Caret />
      </Dropdown.Toggle>
      <Dropdown.Menu
        role="menu"
        placement="bottom-end"
        flip={false}
        popperConfig={{
          modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
        }}
      >
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DataGridTable.Anchor = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<'a'> & { fillCell?: boolean }
>(({ children, fillCell, ...props }, ref) => {
  return (
    <a
      {...props}
      className={clsx(
        props.className,
        fillCell && 'w-full h-full',
        fillCell && styles.content,
      )}
      ref={ref}
      draggable={false}
    >
      {children}
    </a>
  );
});
DataGridTable.Anchor.displayName = 'DataGridTableAnchor';

const DataGridTableCell = React.forwardRef(
  (
    {
      frozen,
      to,
      children,
      lastFrozen,
      flush = !!to,
      ...props
    }: DataTableCellProps<'td'> & {
      to?: LinkPropsCommon['to'];
      flush?: boolean;
    },
    ref: React.Ref<HTMLTableCellElement>,
  ) => {
    return (
      <td
        {...props}
        ref={ref}
        className={clsx(
          props.className,
          styles.cell,
          frozen && styles.frozen,
          lastFrozen && styles.lastFrozen,
          !flush && styles.content,
        )}
      >
        {to ? (
          <Link to={to}>
            {({ active: _, ...linkProps }) => (
              <DataGridTable.Anchor {...linkProps} fillCell>
                {children}
              </DataGridTable.Anchor>
            )}
          </Link>
        ) : (
          children
        )}
      </td>
    );
  },
);

const DataGridTableHeaderRow = ({
  children,
  columnPicker,
  utilityHeader,
  ...props
}: React.ComponentPropsWithRef<'tr'> & {
  columnPicker?: ReactElement | false;
  utilityHeader?: boolean;
}) => {
  return (
    <tr {...props}>
      {children}
      <td
        data-utility-header={utilityHeader}
        role="presentation"
        className={clsx(
          utilityHeader && styles.utilityHeader,
          !columnPicker && 'col-span-2',
        )}
      />

      {columnPicker && (
        <td role="presentation" className={styles.columnPicker}>
          {columnPicker}
        </td>
      )}
    </tr>
  );
};

const DataGridTableRow = ({
  selected,
  highlighted,
  indicator,
  context: _c,
  item: _i,
  ...props
}: React.ComponentPropsWithoutRef<'tr'> & {
  context?: unknown;
  item?: unknown;
  selected?: boolean;
  highlighted?: boolean;
  indicator?: 'success' | 'draft' | 'danger';
}) => {
  return (
    <tr
      data-bni-id="DataGridTableRow"
      {...props}
      aria-selected={!!selected}
      className={clsx(
        props.className,
        styles.row,
        indicator && styles[indicator],
        highlighted && styles.highlighted,
        selected && styles.selected,
      )}
    >
      {props.children}
      <td role="presentation" className={clsx(styles.cell, 'col-span-2')} />
    </tr>
  );
};

DataGridTable.Cell = DataGridTableCell;
DataGridTable.Header = DataGridTableHeader;
DataGridTable.Row = DataGridTableRow;
DataGridTable.HeaderRow = DataGridTableHeaderRow;
