import type { IntrinsicElementsKeys } from '@4c/layout';
import clsx from 'clsx';
import React, {
  ComponentPropsWithRef,
  ComponentType,
  forwardRef,
} from 'react';

type PickRef<P extends object> = 'ref' extends keyof P ? P['ref'] : never;

type ProvidedProps<P, A extends Partial<P>> = ((props: P) => A) | A;

export default function withProps<
  C extends ComponentType<any> | IntrinsicElementsKeys,
  CP extends { className?: string } = ComponentPropsWithRef<C>,
  P extends Partial<CP> = CP,
>(Component: C, defaultedProps: ProvidedProps<CP, P>) {
  const getProps =
    typeof defaultedProps === 'function'
      ? defaultedProps
      : (p: CP) => ({
          ...p,
          ...defaultedProps,
          className: clsx(defaultedProps.className, p.className),
        });

  const wrapped = forwardRef<PickRef<CP>, CP>((props, ref) => (
    <Component {...(getProps(props) as any)} ref={ref} />
  ));
  wrapped.displayName = `WithProps(${
    typeof Component === 'string'
      ? Component
      : Component.displayName || 'Component'
  }`;

  return wrapped;
}

// export default function composed<
//   C extends ComponentType<any> | IntrinsicElementsKeys,
//   CP extends { className?: string } = ComponentPropsWithRef<C>,
//   P extends Partial<CP> = CP,
// >(Component: C, render: (props: CP) => React.ReactElement) {
//   const getProps =
//     typeof defaultedProps === 'function'
//       ? defaultedProps
//       : (p: CP) => ({
//           ...p,
//           ...defaultedProps,
//           className: clsx(defaultedProps.className, p.className),
//         });

//   const wrapped = forwardRef<PickRef<CP>, CP>((props, ref) => (
//     <Component {...(getProps(props) as any)} ref={ref} />
//   ));
//   wrapped.displayName = `WithProps(${
//     typeof Component === 'string'
//       ? Component
//       : Component.displayName || 'Component'
//   }`;

//   return wrapped;
// }
