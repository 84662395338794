import useSafeState from '@restart/hooks/useSafeState';
import { useState } from 'react';

type StateReturn<TState> = [
  TState,
  React.Dispatch<React.SetStateAction<TState>>,
];

function useSafeUseState<TState = undefined>(
  initialState: TState | (() => TState),
): StateReturn<TState> {
  return useSafeState<TState>(useState<TState>(initialState));
}

export default useSafeUseState;
