/**
 * @generated SignedSource<<5334578d9b1a11df6e2da1d18dd9fb70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedUserAvatars_study$data = {
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly transcribedBy: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "RelatedUserAvatars_study";
};
export type RelatedUserAvatars_study$key = {
  readonly " $data"?: RelatedUserAvatars_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedUserAvatars_study">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RelatedUserAvatars_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "transcribedBy",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "0b0d3a2783c4d43f4b54383f4896e1bb";

export default node;
