import Layout from '@4c/layout';
import { createFragmentContainer, graphql } from 'react-relay';

import StudyImage from 'components/StudyImage';

import useSharedInterpretationImage from '../utils/useSharedInterpretationImage';
import { MobileSharedStudyImage_image$data as Image } from './__generated__/MobileSharedStudyImage_image.graphql';

interface Props {
  image: Image;
  index: number;
}

function MobileSharedStudyImage({ image, index }: Props) {
  const { interpretationImage, showRenderControl, showInterpretationControl } =
    useSharedInterpretationImage(image.id);

  return (
    <>
      {showRenderControl && (
        <div className="px-5 py-2">{showRenderControl}</div>
      )}

      {interpretationImage || (
        <StudyImage
          image={image}
          index={index}
          fullscreenEnabled={false}
          autoPlay
        />
      )}

      {showInterpretationControl && (
        <Layout justify="center" align="center" className="p-3 bg-grey-85">
          {showInterpretationControl}
        </Layout>
      )}
    </>
  );
}

export default createFragmentContainer(MobileSharedStudyImage, {
  image: graphql`
    fragment MobileSharedStudyImage_image on SharedBundleImage {
      id
      ...StudyImage_image
    }
  `,
});
