/**
 * @generated SignedSource<<5448f06b07940b963df383a47f5a6208>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type studyProtocols_StudyProtocolsPage_Query$variables = {
  organizationSlug: string;
};
export type studyProtocols_StudyProtocolsPage_Query$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"StudyProtocolsPage_organization" | "StudyProtocolsPage_tenant">;
  } | null;
};
export type studyProtocols_StudyProtocolsPage_Query = {
  response: studyProtocols_StudyProtocolsPage_Query$data;
  variables: studyProtocols_StudyProtocolsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 30
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "type",
    "value": "WORKFLOW"
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StudyProtocolEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StudyProtocol",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "systemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = [
  "type"
],
v7 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "type",
    "value": "INDICATION"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "studyProtocols_StudyProtocolsPage_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StudyProtocolsPage_tenant"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StudyProtocolsPage_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "studyProtocols_StudyProtocolsPage_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyProtocol",
            "kind": "LinkedField",
            "name": "defaultStudyProtocol",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "workflowProtocols",
                "args": (v4/*: any*/),
                "concreteType": "StudyProtocolConnection",
                "kind": "LinkedField",
                "name": "studyProtocolConnection",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "studyProtocolConnection(first:30,type:\"WORKFLOW\")"
              },
              {
                "alias": "workflowProtocols",
                "args": (v4/*: any*/),
                "filters": (v6/*: any*/),
                "handle": "connection",
                "key": "tenant_workflowProtocols",
                "kind": "LinkedHandle",
                "name": "studyProtocolConnection"
              },
              {
                "alias": "indicationProtocols",
                "args": (v7/*: any*/),
                "concreteType": "StudyProtocolConnection",
                "kind": "LinkedField",
                "name": "studyProtocolConnection",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "studyProtocolConnection(first:30,type:\"INDICATION\")"
              },
              {
                "alias": "indicationProtocols",
                "args": (v7/*: any*/),
                "filters": (v6/*: any*/),
                "handle": "connection",
                "key": "Tenant_indicationProtocols",
                "kind": "LinkedHandle",
                "name": "studyProtocolConnection"
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isNode"
              }
            ],
            "type": "TenantInterface",
            "abstractKey": "__isTenantInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b7dc4f0285071fff31d10b0309c9598",
    "id": null,
    "metadata": {},
    "name": "studyProtocols_StudyProtocolsPage_Query",
    "operationKind": "query",
    "text": "query studyProtocols_StudyProtocolsPage_Query(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    ...StudyProtocolsPage_tenant\n    ...StudyProtocolsPage_organization\n    id\n  }\n}\n\nfragment DeleteProtocolModal_studyProtocol on StudyProtocol {\n  id\n  name\n}\n\nfragment StudyProtocolsIndicationsSection_tenant_2WQKlb on TenantInterface {\n  __isTenantInterface: __typename\n  indicationProtocols: studyProtocolConnection(first: 30, type: \"INDICATION\") {\n    edges {\n      node {\n        id\n        handle\n        name\n        systemId\n        type\n        ...DeleteProtocolModal_studyProtocol\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n\nfragment StudyProtocolsPage_organization on Organization {\n  ...StudyProtocolsWorkflowsSection_organization\n}\n\nfragment StudyProtocolsPage_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ...StudyProtocolsWorkflowsSection_tenant_10BDOG\n  ...StudyProtocolsIndicationsSection_tenant_2WQKlb\n}\n\nfragment StudyProtocolsWorkflowsSection_organization on Organization {\n  id\n  defaultStudyProtocol {\n    id\n  }\n}\n\nfragment StudyProtocolsWorkflowsSection_tenant_10BDOG on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  workflowProtocols: studyProtocolConnection(first: 30, type: \"WORKFLOW\") {\n    edges {\n      node {\n        id\n        handle\n        name\n        systemId\n        type\n        ...DeleteProtocolModal_studyProtocol\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "295e54b60fc8035d517e4299b725b1b4";

export default node;
