import Card from '@bfly/ui2/Card';
import Text from '@bfly/ui2/Text';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import SkeletonCard from 'components/SkeletonCard';

import { ClinicalInsightsCards_tenant$data as Tenant } from './__generated__/ClinicalInsightsCards_tenant.graphql';

interface ClinicalInsightsCardsProps {
  tenant: Tenant | null;
  className?: string;
}

const ClinicalInsightsCards = ({
  tenant,
  className,
}: ClinicalInsightsCardsProps) => {
  const insights = {
    numTruePositives: 0,
    numTrueNegatives: 0,
    numFalsePositives: 0,
    numFalseNegatives: 0,
  };
  for (const stats of tenant?.studyStatistics?.accuracyAsPresentedStatistics ||
    []) {
    if (!stats) {
      continue;
    }
    const { key, numFinalizedStudies } = stats;
    switch (key) {
      case 'TRUE_POSITIVE':
        insights.numTruePositives = numFinalizedStudies;
        break;
      case 'TRUE_NEGATIVE':
        insights.numTrueNegatives = numFinalizedStudies;
        break;
      case 'FALSE_POSITIVE':
        insights.numFalsePositives = numFinalizedStudies;
        break;
      case 'FALSE_NEGATIVE':
        insights.numFalseNegatives = numFinalizedStudies;
        break;
      default:
        break;
    }
  }
  const {
    numTrueNegatives,
    numTruePositives,
    numFalseNegatives,
    numFalsePositives,
  } = insights;

  return (
    <div
      className={clsx(className, 'grid grid-cols-2 gap-2')}
      data-bni-id="ClinicalInsightsCardsLayout"
    >
      <SkeletonCard busy={!tenant}>
        <Card.Body
          className="m-3"
          data-bni-id="ClinicalInsightsCards-TrueNegatives"
        >
          <Text variant="body-bold" color="headline">
            <FormattedMessage
              id="ClinicalInsightsCards.trueNegatives"
              defaultMessage="{numTrueNegatives} true {numTrueNegatives, plural, one {negative} other {negatives}}"
              values={{ numTrueNegatives }}
            />
          </Text>
        </Card.Body>
      </SkeletonCard>
      <SkeletonCard busy={!tenant}>
        <Card.Body
          className="m-3"
          data-bni-id="ClinicalInsightsCards-TruePositives"
        >
          <Text variant="body-bold" color="headline">
            <FormattedMessage
              id="ClinicalInsightsCards.truePositives"
              defaultMessage="{numTruePositives} true {numTruePositives, plural, one {positive} other {positives}}"
              values={{ numTruePositives }}
            />
          </Text>
        </Card.Body>
      </SkeletonCard>

      <SkeletonCard busy={!tenant}>
        <Card.Body
          className="m-3"
          data-bni-id="ClinicalInsightsCards-FalseNegatives"
        >
          <Text variant="body-bold" color="headline">
            <FormattedMessage
              id="ClinicalInsightsCards.falseNegatives"
              defaultMessage="{numFalseNegatives} false {numFalseNegatives, plural, one {negative} other {negatives}}"
              values={{ numFalseNegatives }}
            />
          </Text>
        </Card.Body>
      </SkeletonCard>
      <SkeletonCard busy={!tenant}>
        <Card.Body
          className="m-3"
          data-bni-id="ClinicalInsightsCards-FalsePositives"
        >
          <Text variant="body-bold" color="headline">
            <FormattedMessage
              id="ClinicalInsightsCards.falsePositives"
              defaultMessage="{numFalsePositives} false {numFalsePositives, plural, one {positive} other {positives}}"
              values={{ numFalsePositives }}
            />
          </Text>
        </Card.Body>
      </SkeletonCard>
    </div>
  );
};

export default createFragmentContainer(ClinicalInsightsCards, {
  tenant: graphql`
    fragment ClinicalInsightsCards_tenant on TenantInterface
    @argumentDefinitions(filter: { type: "StudyStatisticsFilterInput!" }) {
      type: __typename
      studyStatistics(filter: $filter) {
        accuracyAsPresentedStatistics {
          key
          numFinalizedStudies
        }
      }
    }
  `,
});
