/**
 * @generated SignedSource<<8545e4d290744d5e3470031cf7b71dcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyHistoryDropdownItem_study$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StudyHistoryModal_study">;
  readonly " $fragmentType": "StudyHistoryDropdownItem_study";
};
export type StudyHistoryDropdownItem_study$key = {
  readonly " $data"?: StudyHistoryDropdownItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyHistoryDropdownItem_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyHistoryDropdownItem_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyHistoryModal_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "6c3ee737fef829c7a0eea960749c7bcb";

export default node;
