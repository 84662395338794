/**
 * @generated SignedSource<<424d5d95879c23a27accc8951b3ca0cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type education_SelectCoursesPage_Query$variables = {};
export type education_SelectCoursesPage_Query$data = {
  readonly lms: {
    readonly " $fragmentSpreads": FragmentRefs<"SelectCoursesPage_lms">;
  } | null;
};
export type education_SelectCoursesPage_Query = {
  response: education_SelectCoursesPage_Query$data;
  variables: education_SelectCoursesPage_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "education_SelectCoursesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelectCoursesPage_lms"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "education_SelectCoursesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LmsTag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "handle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e94b508fd5aaf60ad75ac9f25dcf56c8",
    "id": null,
    "metadata": {},
    "name": "education_SelectCoursesPage_Query",
    "operationKind": "query",
    "text": "query education_SelectCoursesPage_Query {\n  lms {\n    ...SelectCoursesPage_lms\n  }\n}\n\nfragment CourseTagsField_lmsTags on LmsTag {\n  handle\n  name\n}\n\nfragment SelectCoursesPage_lms on Lms {\n  tags {\n    ...CourseTagsField_lmsTags\n    id\n  }\n}\n"
  }
};

(node as any).hash = "1da57f985b03fe5fea583b1b0fb864f3";

export default node;
