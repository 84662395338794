/**
 * @generated SignedSource<<d7d1815b218e7500dcccc9c913d2acf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TextFieldStyle = "LONG_TEXT" | "SHORT_TEXT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FieldDefinitionFormFieldGroup_fieldDefinition$data = {
  readonly allowOther?: boolean | null;
  readonly handle: string | null;
  readonly label: string | null;
  readonly options?: ReadonlyArray<{
    readonly handle: string | null;
    readonly label: string | null;
  } | null> | null;
  readonly required: boolean | null;
  readonly textStyle?: TextFieldStyle | null;
  readonly typename: string;
  readonly " $fragmentType": "FieldDefinitionFormFieldGroup_fieldDefinition";
};
export type FieldDefinitionFormFieldGroup_fieldDefinition$key = {
  readonly " $data"?: FieldDefinitionFormFieldGroup_fieldDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"FieldDefinitionFormFieldGroup_fieldDefinition">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FieldOption",
    "kind": "LinkedField",
    "name": "options",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowOther",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FieldDefinitionFormFieldGroup_fieldDefinition",
  "selections": [
    {
      "alias": "typename",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "required",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "textStyle",
          "args": null,
          "kind": "ScalarField",
          "name": "style",
          "storageKey": null
        }
      ],
      "type": "TextFieldDefinition",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "SingleSelectFieldDefinition",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "MultiSelectFieldDefinition",
      "abstractKey": null
    }
  ],
  "type": "FormFieldDefinitionInterface",
  "abstractKey": "__isFormFieldDefinitionInterface"
};
})();

(node as any).hash = "55c080fa57f3b37c6217b9a16ef8cd52";

export default node;
