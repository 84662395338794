import { defineMessages } from 'react-intl';

export default defineMessages({
  signDialogTitle: {
    id: 'useStudyFinalization.sign.dialog.title',
    defaultMessage: 'Sign this draft?',
  },
  signEhrDialogBody: {
    id: 'useStudyFinalization.signEhr.dialog.body',
    defaultMessage:
      'Signing a draft in this archive will publish the interpretation to the electronic health record. Studies are only editable by admins of this organization.',
  },
  signEhrDialogBodyForCustomPermissions: {
    id: 'useStudyFinalization.signEhr.dialog.body.with.permissions',
    defaultMessage:
      'Signing a draft in this archive will publish the interpretation to the electronic health record. Studies are only editable by users with reversion permissions.',
  },
  signDialogBody: {
    id: 'useStudyFinalization.sign.dialog.body',
    defaultMessage:
      'Signing a draft in this archive will publish the interpretation to the archive. Studies are only editable by admins of this organization.',
  },
  signDialogBodyForCustomPermissions: {
    id: 'useStudyFinalization.sign.dialog.body.with.permissions',
    defaultMessage:
      'Signing a draft in this archive will publish the interpretation to the archive. Studies are only editable by users with reversion permissions.',
  },
  signSuccess: {
    id: 'useStudyFinalization.success.sign',
    defaultMessage: 'Your draft has been signed as final.',
  },
  attestDialogTitle: {
    id: 'useStudyFinalization.attest.dialog.title',
    defaultMessage: 'Attestation Required',
  },
  attestDialogConfirm: {
    id: 'useStudyFinalization.attest.dialog.confirm.sign',
    defaultMessage: 'Sign',
  },
  attestDialogConfirmAsPrimaryAuthor: {
    id: 'useStudyFinalization.attest.dialog.confirmAsPrimaryAuthor',
    defaultMessage: 'Sign as Primary Author',
  },
  signDialogNoPrimaryAuthor: {
    id: 'useStudyFinalization.sign.dialog.noPrimaryAuthor',
    defaultMessage:
      'This study does not currently have a primary author assigned. You will be added as the default primary author.',
  },
});
