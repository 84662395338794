import {
  type MutationParametersWithError,
  type RelayMutationError,
  isMutationError,
} from '@bfly/ui2/useMutationWithError';
import { graphql } from 'react-relay';

import { isUnseenChangesError_error$data as UnseenStudyChangesError } from './__generated__/isUnseenChangesError_error.graphql';

const _ = graphql`
  fragment isUnseenChangesError_error on UnseenStudyChangesError {
    message
  }
`;

export default function isUnseenChangesError(
  e: Error,
): e is RelayMutationError<
  MutationParametersWithError<UnseenStudyChangesError>
> {
  return isMutationError(e) && e.errorType === 'UnseenStudyChangesError';
}
