import { graphql, readInlineData } from 'react-relay';

import UserSuggestion from './UserSuggestion';
import { UserSuggestionListItem_userInfo$key as UserInfoKey } from './__generated__/UserSuggestionListItem_userInfo.graphql';

const fragment = graphql`
  fragment UserSuggestionListItem_userInfo on UserInfoInterface @inline {
    userProfile {
      name
      ...UserSuggestion_userProfile
    }
    email
  }
`;

interface Props {
  item: UserInfoKey;
  searchTerm?: string;
}

export function renderUserSuggestion({ item, searchTerm }: Props) {
  const { userProfile, email } = readInlineData(fragment, item);

  return (
    <UserSuggestion
      userProfile={userProfile!}
      email={email || ''}
      searchTerm={searchTerm}
      highlightedClassName="text-base font-extrabold"
    />
  );
}
