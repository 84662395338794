/**
 * @generated SignedSource<<19d85536240a8d622ae4f0384232fea4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyImagePreviewList_studyImages$data = ReadonlyArray<{
  readonly handle: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ImagePreviewItem_image">;
  readonly " $fragmentType": "StudyImagePreviewList_studyImages";
}>;
export type StudyImagePreviewList_studyImages$key = ReadonlyArray<{
  readonly " $data"?: StudyImagePreviewList_studyImages$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImagePreviewList_studyImages">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudyImagePreviewList_studyImages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ImagePreviewItem_image"
    }
  ],
  "type": "StudyImage",
  "abstractKey": null
};

(node as any).hash = "ab20426fd296c3c659584504678dc4f3";

export default node;
