import Layout from '@4c/layout';
import useCallbackRef from '@restart/hooks/useCallbackRef';
import useEventCallback from '@restart/hooks/useEventCallback';
import { css } from 'astroturf';
import clsx from 'clsx';
import {
  ComponentPropsWithoutRef,
  ReactNode,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

type ScrollSpyContextState = {
  activeSection?: string;
  setActiveSection: (id: string) => void;
  setRoot: React.RefCallback<HTMLElement>;
  observeElement: (el: HTMLElement) => () => void;
};

const ScrollSpyContext = createContext<ScrollSpyContextState | null>(null);

function ScrollSpySection({
  id,
  children,
  ...props
}: Omit<ComponentPropsWithoutRef<'section'>, 'title'> & {
  id: string;
  children: ReactNode;
}) {
  const [ref, attachRef] = useCallbackRef<HTMLElement>();
  const { observeElement } = useContext(ScrollSpyContext)!;

  useEffect(() => {
    if (!ref) return undefined;
    return observeElement(ref);
  }, [ref, observeElement]);

  return (
    // we add sections through the ref callback as some sections are loaded after initial root load
    <section data-scroll-spy-section {...props} id={id} ref={attachRef}>
      {children}
    </section>
  );
}

interface ScrollSpyProps {
  children: ReactNode;
  defaultActiveSection?: string;
}

function ScrollSpyTabs({
  children,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <Layout
      {...props}
      pad={2}
      align="center"
      direction="row"
      data-scroll-spy-tabs
      className="min-w-0 w-full px-5 pb-2 -mt-2"
    >
      {children}
    </Layout>
  );
}

interface ScrollSpyTabProps extends React.ComponentPropsWithoutRef<'a'> {
  children?: React.ReactNode;
  sectionId: string;
}

function ScrollSpyTab({ children, sectionId, ...props }: ScrollSpyTabProps) {
  const { activeSection, setActiveSection } = useContext(ScrollSpyContext)!;
  return (
    <a
      {...props}
      href={`#${sectionId}`}
      onClick={(e) => {
        e.preventDefault();
        setActiveSection(sectionId);
        props.onClick?.(e);
      }}
      data-active={activeSection === sectionId}
      className={clsx(
        props.className,
        'truncate border-b-2 py-2 flex-1 transition-colors',
        activeSection === sectionId
          ? 'text-headline border-b-primary'
          : 'text-subtitle border-b-grey-80',
      )}
    >
      {children}
    </a>
  );
}

function ScrollSpyScrollView({ children }: { children?: ReactNode }) {
  const { setRoot } = useContext(ScrollSpyContext)!;

  return (
    <Layout
      grow
      ref={setRoot}
      direction="column"
      className="relative scrollable-y overflow-x-hidden px-5"
      css={css`
        & > [data-scroll-spy-section] {
          @apply py-4 border-b border-divider;

          &:last-child {
            @apply pb-0 border-0;
          }

          // Setting the last section to full height ensures
          // that the scroll view has enough scroll area scroll
          // to shorter last sections. Without it, the trailing
          // section might be much shorter than the root element
          // which would only scroll it to the bottom or middle
          // of the screen
          [data-scroll-spy-tabs] ~ &:last-of-type {
            @apply min-h-full;
          }
        }
      `}
    >
      {children}
    </Layout>
  );
}

const thresholds = Array.from({ length: 50 }, (_, idx) => (idx + 1) / 50);

export interface ScrollSpyHandle {
  scrollIntoView: (nextSectionId: string) => void;
}

/**
 * Create a ScrollSpy context. This element
 * is INTENTIONALLY not controllable, because there is no
 * good (without excessive code) way to syncronize state updates
 * between what is in view and what tab is active. It's more
 * reliable to simple scroll to the section you want and let the
 * internal logic active the tab.
 */
const ScrollSpy = forwardRef<ScrollSpyHandle, ScrollSpyProps>(
  ({ children, defaultActiveSection }: ScrollSpyProps, ref) => {
    const [root, setRoot] = useCallbackRef<HTMLElement>();

    const [observer, setObserver] = useState<IntersectionObserver>();
    const [activeSection, setActiveSection] = useState(defaultActiveSection);

    const setSectionAndScrollIntoView = useEventCallback(
      (nextSectionId: string, behavior: ScrollBehavior = 'smooth') => {
        const element = root?.querySelector(`#${CSS.escape(nextSectionId)}`);
        if (!element) return;

        const height = (element as HTMLElement).offsetTop;

        root?.scrollTo({ top: height, behavior });
        setActiveSection(activeSection);
      },
    );

    useImperativeHandle(
      ref,
      () => ({
        scrollIntoView: (id: string) => {
          // run in an animation frame to give any pending state changes that might
          // render this id a chance to flush
          requestAnimationFrame(() => {
            setSectionAndScrollIntoView(id);
          });
        },
      }),
      [setSectionAndScrollIntoView],
    );

    // Without a defaultActiveSection the IntersectionObserver will
    // automatically highlight the most correct tab when it mounts.
    // When we want to default to our own thing we can simply scroll to
    // the section id on mount. However, the IO is really noisy on mount
    // and fires a number of times as the UI settles. To avoid thrashing
    // or firing to early we run the scroll with a slight delay to give the
    // IO time to settle
    useLayoutEffect(() => {
      if (defaultActiveSection) {
        requestAnimationFrame(() => {
          setSectionAndScrollIntoView(defaultActiveSection, 'auto');
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
      if (!root || observer) return undefined;

      /*
      The challenge with scroll spy behavior is 
      that there is no clear approach to chosing 
      the "correct" section when sections are shorter
      than the root element, and so multiple sections can
      be on screen at once. In particular, IO's report changes 
      when an element's "intersection ratio" actually changes.

      This means that if more than one section is fully in view, 
      at a time you won't get updates for those elements until 
      they start to go off screen. This makes it easy to 
      "miss" short elements, jumping over them or not 
      highlighting them even when clicked.
      
      To help avoid this, the IO uses a particular root margin 
      to create an "alert area" at starts at the top of the 
      root element and extends 75% of the way down. This helps 
      limit the size of the element to avoid missing smaller sections
      while also been large enough to track elements moving in and out 
      of view effectively. Once in view, we collect the MOST in view elements
      and activate the first one (furthest up) in case of a tie. We make 
      and exception for when the element is not scrolled at all, 
      always activating the first element in that case.

      This tends to produce the least weird results in testing
      */
      const newObserver = new IntersectionObserver(
        (entries) => {
          const rootScrollTop = root.scrollTop;

          let lastRatio = 0;
          let nextActiveSectionId: string | null = null;
          for (const entry of entries) {
            if (!entry.isIntersecting) continue;

            // If we aren't scrolled at all always use the first item
            if (!rootScrollTop) {
              nextActiveSectionId = entry.target.id;
              break;
            }

            // we want to use the first most interesting item hence < instead of <=
            if (lastRatio < entry.intersectionRatio) {
              nextActiveSectionId = entry.target.id;
              lastRatio = entry.intersectionRatio;
            }
          }

          if (nextActiveSectionId) {
            setActiveSection(nextActiveSectionId);
          }
        },
        {
          root,
          rootMargin: '0px 0px -25%',
          threshold: thresholds,
        },
      );

      setObserver(newObserver);

      return () => {
        newObserver?.disconnect();
      };
    }, [root, observer, setActiveSection]);

    const contextValue: ScrollSpyContextState = useMemo(
      () => ({
        activeSection,
        setActiveSection: setSectionAndScrollIntoView,
        setRoot,
        observeElement: (element: HTMLElement) => {
          observer?.observe(element);
          return () => observer?.unobserve(element);
        },
      }),
      [activeSection, observer, setSectionAndScrollIntoView, setRoot],
    );

    return (
      <ScrollSpyContext.Provider value={contextValue}>
        <>{children}</>
      </ScrollSpyContext.Provider>
    );
  },
);

ScrollSpy.displayName = 'ScrollSpy';

export default Object.assign(ScrollSpy, {
  ScrollView: ScrollSpyScrollView,
  Section: ScrollSpySection,
  Tabs: ScrollSpyTabs,
  Tab: ScrollSpyTab,
});
