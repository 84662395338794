import Dicom from '@bfly/icons/Dicom';
import Tooltip from '@bfly/ui2/Tooltip';
import { FormattedMessage } from 'react-intl';

import messages from 'messages/archive';

export default ({ className }: { className: string }) => (
  <Tooltip.Trigger tooltip={<FormattedMessage {...messages.pacsForwarding} />}>
    <Dicom width="20" className={className} />
  </Tooltip.Trigger>
);
