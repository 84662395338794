/**
 * @generated SignedSource<<1c4bb6f981326c9fde8fe0bef1f05421>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HeaderPatientInfo_study$data = {
  readonly archive: {
    readonly worklistRequiredToFinalize: boolean | null;
  } | null;
  readonly capturedAt: string | null;
  readonly finalizedAt: string | null;
  readonly hasAssociatedWorklistItem: boolean | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly patient: {
    readonly medicalRecordNumber: string | null;
    readonly nameFirst: string | null;
    readonly nameLast: string | null;
    readonly nameMiddle: string | null;
    readonly namePrefix: string | null;
    readonly nameSuffix: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"PatientName_patient">;
  } | null;
  readonly practiceType: StudyPracticeType | null;
  readonly vetPatient: {
    readonly patientIdNumber: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"VetPatientName_vetPatient">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EditExamInfoModal_study" | "PatientPopoverContent_study">;
  readonly " $fragmentType": "HeaderPatientInfo_study";
};
export type HeaderPatientInfo_study$key = {
  readonly " $data"?: HeaderPatientInfo_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderPatientInfo_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderPatientInfo_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capturedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAssociatedWorklistItem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medicalRecordNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameFirst",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameLast",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameMiddle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "namePrefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameSuffix",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PatientName_patient"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "patientIdNumber",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VetPatientName_vetPatient"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "worklistRequiredToFinalize",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditExamInfoModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PatientPopoverContent_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "a96be00c49d7229c4c86a419c0ae9bd3";

export default node;
