/**
 * @generated SignedSource<<3a6e2a3350a35fb2c4c28ccca27ffffb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type VetPatientNeuterStatus = "INTACT" | "NEUTERED" | "UNKNOWN" | "%future added value";
export type VetPatientSex = "FEMALE" | "MALE" | "UNKNOWN" | "%future added value";
export type VetPatientSpecies = "AVIAN" | "CANINE" | "EQUINE" | "EXOTIC" | "FELINE" | "OTHER" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type vetStudyInfo_study$data = {
  readonly accessionNumber: string | null;
  readonly dicomDocument: Record<string, any> | null;
  readonly hasAssociatedWorklistItem: boolean | null;
  readonly notes: string | null;
  readonly sourceWorklistItem: {
    readonly id?: string;
  } | null;
  readonly studyDescription: string | null;
  readonly vetPatient: {
    readonly birthDate: string | null;
    readonly breed: string | null;
    readonly clientNameFirst: string | null;
    readonly clientNameLast: string | null;
    readonly clientNameMiddle: string | null;
    readonly clientNamePrefix: string | null;
    readonly clientNameSuffix: string | null;
    readonly clientOrganizationName: string | null;
    readonly microchipNumber: string | null;
    readonly name: string | null;
    readonly neuterStatus: VetPatientNeuterStatus | null;
    readonly patientIdNumber: string | null;
    readonly sex: VetPatientSex | null;
    readonly species: VetPatientSpecies | null;
    readonly speciesFreeform: string | null;
    readonly weightKilograms: number | null;
  } | null;
  readonly " $fragmentType": "vetStudyInfo_study";
};
export type vetStudyInfo_study$key = {
  readonly " $data"?: vetStudyInfo_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"vetStudyInfo_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "vetStudyInfo_study"
};

(node as any).hash = "68e90b8709e9c3126182356f4d1bf00e";

export default node;
