/**
 * @generated SignedSource<<24386bb0fcac75e63bac2db1ef5c24ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EulaPage_latestEula$data = {
  readonly textHtml: string | null;
  readonly version: string | null;
  readonly " $fragmentType": "EulaPage_latestEula";
};
export type EulaPage_latestEula$key = {
  readonly " $data"?: EulaPage_latestEula$data;
  readonly " $fragmentSpreads": FragmentRefs<"EulaPage_latestEula">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EulaPage_latestEula",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textHtml",
      "storageKey": null
    }
  ],
  "type": "Eula",
  "abstractKey": null
};

(node as any).hash = "54f41f4460eb81525e6c43c2de7cbdb3";

export default node;
