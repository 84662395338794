/**
 * @generated SignedSource<<187ded6e586704bd5807eac3238c4896>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type archive_ArchiveMembersPage_Query$variables = {
  archiveHandle: string;
};
export type archive_ArchiveMembersPage_Query$data = {
  readonly archive: {
    readonly " $fragmentSpreads": FragmentRefs<"ArchiveMembersPage_archive">;
  } | null;
};
export type archive_ArchiveMembersPage_Query = {
  response: archive_ArchiveMembersPage_Query$data;
  variables: archive_ArchiveMembersPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "archiveHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "archiveHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "archive_ArchiveMembersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Archive",
        "kind": "LinkedField",
        "name": "archive",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArchiveMembersPage_archive"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "archive_ArchiveMembersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Archive",
        "kind": "LinkedField",
        "name": "archive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPrivate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numMemberships",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasRestrictedFinalization",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ArchiveMembershipConnection",
            "kind": "LinkedField",
            "name": "membershipConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArchiveMembershipEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArchiveMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "userProfile",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Avatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Thumbnail",
                                    "kind": "LinkedField",
                                    "name": "thumbnails",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "width",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "ThumbnailedInterface",
                                "abstractKey": "__isThumbnailedInterface"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerCanDelete",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canFinalize",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canQa",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "membershipConnection(first:30)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Archive_membershipConnection",
            "kind": "LinkedHandle",
            "name": "membershipConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f43fe2262872b0bf38841986a0e009a4",
    "id": null,
    "metadata": {},
    "name": "archive_ArchiveMembersPage_Query",
    "operationKind": "query",
    "text": "query archive_ArchiveMembersPage_Query(\n  $archiveHandle: String!\n) {\n  archive(handle: $archiveHandle) {\n    ...ArchiveMembersPage_archive\n    id\n  }\n}\n\nfragment ArchiveInviteForm_archive on Archive {\n  id\n  isPrivate\n  organization {\n    id\n    slug\n  }\n}\n\nfragment ArchiveMemberItemGridRow_archive on Archive {\n  id\n  hasRestrictedFinalization\n}\n\nfragment ArchiveMemberItemGridRow_membership on ArchiveMembership {\n  id\n  userProfile {\n    ...UserInfo_userProfile\n    id\n  }\n  email\n  type\n  viewerCanDelete\n  canFinalize\n  canQa\n}\n\nfragment ArchiveMemberItemGrid_archive on Archive {\n  id\n  hasRestrictedFinalization\n  membershipConnection(first: 30) {\n    edges {\n      node {\n        id\n        type\n        userProfile {\n          name\n          id\n        }\n        ...ArchiveMemberItemGridRow_membership\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ...ArchiveMemberItemGridRow_archive\n}\n\nfragment ArchiveMembersPage_archive on Archive {\n  label\n  isPrivate\n  organization {\n    ...ArchivePrivacyDescription_organization\n    id\n  }\n  numMemberships\n  ...ArchiveMemberItemGrid_archive\n  ...ArchivePageTitle_archive\n  ...ArchiveInviteForm_archive\n}\n\nfragment ArchivePageTitle_archive on Archive {\n  label\n}\n\nfragment ArchivePrivacyDescription_organization on Organization {\n  name\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n\nfragment UserInfo_userProfile on UserProfile {\n  name\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "0065296abb49b1483fc3c1d8bbd57bbf";

export default node;
