/**
 * @generated SignedSource<<586ac376d8be628a4294ef8654ca71cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveSettingsPage_tenant$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetsExportControl_tenant">;
  readonly " $fragmentType": "ArchiveSettingsPage_tenant";
};
export type ArchiveSettingsPage_tenant$key = {
  readonly " $data"?: ArchiveSettingsPage_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveSettingsPage_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveSettingsPage_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorksheetsExportControl_tenant"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "65fb50e39fe42c0ffd2f6257b93f33ed";

export default node;
