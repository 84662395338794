import Button from '@bfly/ui2/Button';
import { createFragmentContainer, graphql } from 'react-relay';

import ArchiveSelectButton from 'components/ArchiveSelectButton';
import DateRangeSelectButton from 'components/DateRangeSelectButton';
import ExamTypeSelectButton from 'components/ExamTypeSelectButton';
import { useVariation } from 'components/LaunchDarklyContext';
import MemberSelectButton from 'components/MemberSelectButton';
import OrganizationSelectButton from 'components/OrganizationSelectButton';
import StudyStatusSelectButton from 'components/StudyStatusSelectButton';
import { useLocationState } from 'utils/LocationUtils';

import { QuickFilters, filterSchema } from '../schemas/examQuickFilters';
import { ExamQuickFilters_tenant$data as Tenant } from './__generated__/ExamQuickFilters_tenant.graphql';

interface Props {
  tenant: Tenant;
  includeArchives?: boolean;
  includeStatuses?: boolean;
  includeAuthors?: boolean;
  includeOrganizations?: boolean;
}

function ExamQuickFilters({
  tenant,
  includeArchives = false,
  includeStatuses = false,
  includeAuthors = false,
  includeOrganizations = false,
}: Props) {
  const [quickFilters, setFilters] = useLocationState(
    filterSchema,
    'studyFilters',
  );

  const hasQaReview = useVariation('worksheets-review');
  const hasDraftsDrafts = useVariation('draft-studies');

  const statusAreRelevant = hasQaReview || hasDraftsDrafts;

  function areFiltersActive() {
    return Object.values(quickFilters).some((filter) => filter);
  }

  function handleChange(partialNextValue: Partial<typeof quickFilters>) {
    setFilters({ ...quickFilters, ...partialNextValue });
  }

  return (
    <div className="self-center space-x-3 mr-4 ml-3">
      {includeOrganizations && (
        <OrganizationSelectButton
          data-bni-id="OrganizationSelectButton"
          onChange={(orgs) =>
            handleChange({
              organization: orgs as QuickFilters['organization'],
            })
          }
          value={quickFilters.organization?.map((o) => o.id) ?? []}
        />
      )}
      {includeAuthors && (
        <MemberSelectButton
          value={quickFilters.authors || []}
          tenant={tenant}
          placement="bottom-end"
          onChange={(authors) => handleChange({ authors })}
        />
      )}

      <DateRangeSelectButton
        value={quickFilters.dateRange}
        // FIXME
        onChange={(dateRange: any) => handleChange({ dateRange })}
        placement="bottom-end"
      />

      <ExamTypeSelectButton
        tenantId={tenant.id}
        placement="bottom-end"
        includeNoExamTypeOption
        data-bni-id="ExamTypeSelectButton"
        value={quickFilters.examTypes || []}
        onChange={(nextExamTypes) => {
          handleChange({ examTypes: nextExamTypes?.map((a) => a.handle!) });
        }}
        dataKey="handle"
      />

      {statusAreRelevant && includeStatuses && (
        <StudyStatusSelectButton
          value={quickFilters.status || []}
          onChange={(status) => {
            handleChange({ status });
          }}
          placement="bottom-end"
        />
      )}

      {includeArchives && (
        <ArchiveSelectButton
          tenant={tenant}
          value={quickFilters.archives || []}
          onChange={(v) => {
            handleChange({ archives: v?.map((a) => a.id!) });
          }}
          placement="bottom-end"
        />
      )}

      <Button
        variant="text-secondary"
        data-bni-id="ExamQuickFilterResetButton"
        disabled={!areFiltersActive()}
        onClick={() => handleChange(filterSchema.getDefault() as any)}
      >
        Reset
      </Button>
    </div>
  );
}

export default createFragmentContainer(ExamQuickFilters, {
  tenant: graphql`
    fragment ExamQuickFilters_tenant on TenantInterface
    @argumentDefinitions(
      includeArchives: { type: "Boolean", defaultValue: false }
      includeAuthors: { type: "Boolean", defaultValue: false }
    ) {
      id
      ...MemberSelectButton_tenant @include(if: $includeAuthors)
      ...ArchiveSelectButton_tenant @include(if: $includeArchives)
    }
  `,
});
