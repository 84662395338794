/**
 * @generated SignedSource<<2378a6a1cf300bb205131096235055b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppSearch_organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SearchBar_organization">;
  readonly " $fragmentType": "AppSearch_organization";
};
export type AppSearch_organization$key = {
  readonly " $data"?: AppSearch_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppSearch_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppSearch_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBar_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "5541f1a8cc1e5406e7048fc17f4259da";

export default node;
