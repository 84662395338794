/**
 * @generated SignedSource<<f6ac0c4645e88d83e39de806bce424cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamPageSidebarQa_study$data = {
  readonly createdBy: {
    readonly handle: string | null;
  } | null;
  readonly examTypes: ReadonlyArray<{
    readonly deletedAt: string | null;
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly id: string;
  readonly numQaEntries: number | null;
  readonly organization: {
    readonly id: string;
    readonly workflowSettings: {
      readonly showStandardQaImageQualityTooltips: boolean | null;
      readonly skipStandardQa: boolean | null;
    } | null;
  } | null;
  readonly qaEntries: ReadonlyArray<{
    readonly templateVersion: {
      readonly " $fragmentSpreads": FragmentRefs<"ExamQa_templateVersion">;
    } | null;
  } | null> | null;
  readonly secondaryAuthors: ReadonlyArray<{
    readonly handle: string | null;
  } | null> | null;
  readonly standardQa: {
    readonly createdAt: string | null;
    readonly createdBy: {
      readonly " $fragmentSpreads": FragmentRefs<"ExamReviewedBy_userProfile">;
    } | null;
  } | null;
  readonly viewerCanQa: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"standardQa_examTypeProficiency" | "standardQa_study">;
  readonly " $fragmentType": "ExamPageSidebarQa_study";
};
export type ExamPageSidebarQa_study$key = {
  readonly " $data"?: ExamPageSidebarQa_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageSidebarQa_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numQaEntries",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deletedAt",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ExamType",
  "kind": "LinkedField",
  "name": "examTypes",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ExamQa_templateVersion"
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FieldOption",
    "kind": "LinkedField",
    "name": "options",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowOther",
    "storageKey": null
  }
],
v9 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "kind": "InlineDataFragmentSpread",
    "name": "qaEntry_templateVersion",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "fieldDefinitions",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineDataFragmentSpread",
            "name": "fieldDefinition_fieldDefinition",
            "selections": [
              {
                "alias": "typename",
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "required",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "SingleSelectFieldDefinition",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "MultiSelectFieldDefinition",
                "abstractKey": null
              }
            ],
            "args": null,
            "argumentDefinitions": ([]/*: any*/)
          }
        ],
        "storageKey": null
      }
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  },
  (v7/*: any*/)
],
v10 = {
  "kind": "InlineDataFragmentSpread",
  "name": "standardQa_examTypeProficiency",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StandardQa",
      "kind": "LinkedField",
      "name": "standardQa",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamTypeCredentialInfo",
          "kind": "LinkedField",
          "name": "examTypeCredentialInfo",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamType",
              "kind": "LinkedField",
              "name": "examType",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "countsTowardCredentialing",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPageSidebarQa_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanQa",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "secondaryAuthors",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkflowSettings",
          "kind": "LinkedField",
          "name": "workflowSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showStandardQaImageQualityTooltips",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "skipStandardQa",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QaEntry",
      "kind": "LinkedField",
      "name": "qaEntries",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QaTemplateVersion",
          "kind": "LinkedField",
          "name": "templateVersion",
          "plural": false,
          "selections": [
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StandardQa",
      "kind": "LinkedField",
      "name": "standardQa",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ExamReviewedBy_userProfile"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "standardQa_study",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StandardQa",
          "kind": "LinkedField",
          "name": "standardQa",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageQuality",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accuracyAsPresented",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accuracyComparedToGoldStandard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feedback",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "comments",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "QaEntry",
          "kind": "LinkedField",
          "name": "qaEntries",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "values",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "QaTemplateVersion",
              "kind": "LinkedField",
              "name": "templateVersion",
              "plural": false,
              "selections": (v9/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkflowSettings",
              "kind": "LinkedField",
              "name": "workflowSettings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QaTemplate",
                  "kind": "LinkedField",
                  "name": "defaultQaTemplate",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "QaTemplateVersion",
                      "kind": "LinkedField",
                      "name": "latestVersion",
                      "plural": false,
                      "selections": (v9/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v10/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_studyReviewStatus",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numWorksheets",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "worksheetRequiredForQa",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StandardQa",
              "kind": "LinkedField",
              "name": "standardQa",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "createdBy",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v10/*: any*/)
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "c5831ca5d9f7f1b50d6294c65512f07d";

export default node;
