import Button from '@bfly/ui2/Button';
import Link from '@bfly/ui2/Link';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { object, string } from 'yup';

import AuthContent from 'components/AuthContent';
import AuthForm from 'components/AuthForm';
import MessageBox from 'components/MessageBox';
import MultiTextInputGroup from 'components/MultiTextInputGroup';
import type { RoutePageProps } from 'components/Route';
import accountMessages from 'messages/account';
import actionMessages from 'messages/actions';
import { useConfigRoutes } from 'routes/config';

import { ForgotPasswordPage_RequestPasswordResetMutation as RequestPasswordResetMutation } from './__generated__/ForgotPasswordPage_RequestPasswordResetMutation.graphql';

const schema = object({
  email: string()
    .email('Email is invalid')
    .required('Please enter an email')
    .default('')
    .trim(),
});

function ForgotPasswordPage({ match }: RoutePageProps) {
  const configRoutes = useConfigRoutes();
  const redirectUri = match.location.query.redirect_uri;
  const userEmail = match.location.state?.email;

  const [finished, setFinished] = useState(false);

  const defaultValue = useMemo(() => {
    const defaultValueWithEmail = schema.getDefault();
    if (userEmail) defaultValueWithEmail.email = userEmail;

    return defaultValueWithEmail;
  }, [userEmail]);

  const getInput = useCallback(
    ({ email }) => ({ email, redirectUri }),
    [redirectUri],
  );

  const handleChange = useCallback(() => {
    setFinished(false);
  }, []);

  const handleCompleted = useCallback(() => {
    setFinished(true);
  }, []);

  return (
    <AuthContent>
      <AuthContent.Title>
        <FormattedMessage
          id="forgotPassword.title"
          defaultMessage="Trouble Logging In?"
        />
      </AuthContent.Title>
      <AuthContent.Description>
        <FormattedMessage
          id="forgotPassword.subtitle"
          defaultMessage="To resend your invitation link or reset your password, enter your email below."
        />
      </AuthContent.Description>
      <AuthForm<RequestPasswordResetMutation, typeof schema>
        schema={schema}
        mutation={graphql`
          mutation ForgotPasswordPage_RequestPasswordResetMutation(
            $input: RequestPasswordResetInput!
          ) {
            requestPasswordResetOrError(input: $input) {
              ... on RequestPasswordResetPayload {
                __typename
              }
              ...RelayForm_error @relay(mask: false)
            }
          }
        `}
        getInput={getInput}
        defaultValue={defaultValue}
        onChange={handleChange}
        onCompleted={handleCompleted}
      >
        {finished && (
          <MessageBox variant="success">
            <FormattedMessage
              id="resetPassword.submit.success"
              defaultMessage="Invitation email sent if account exists"
            />
          </MessageBox>
        )}
        <MultiTextInputGroup>
          <AuthForm.FieldGroup
            name="email"
            autoComplete="email"
            labelSrOnly
            placeholder={accountMessages.email}
            label={accountMessages.email}
          />
        </MultiTextInputGroup>
        <AuthForm.Submit finished={finished}>
          <FormattedMessage {...actionMessages.submit} />
        </AuthForm.Submit>
      </AuthForm>
      <Link
        as={Button}
        variant="text-primary"
        to={match.location.state?.prevLocation || configRoutes.rootRoute()}
      >
        <FormattedMessage {...accountMessages.logIn} />
      </Link>
    </AuthContent>
  );
}

export default ForgotPasswordPage;
