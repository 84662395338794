import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { speciesMessages } from 'messages/vetPatient';

import { VetSpecies_vetPatient$data as VetPatient } from './__generated__/VetSpecies_vetPatient.graphql';

interface Props {
  vetPatient: VetPatient;
}

function VetSpecies({ vetPatient }: Props) {
  return (
    vetPatient.species && (
      <>
        <FormattedMessage {...speciesMessages[vetPatient.species]} />
        {vetPatient.speciesFreeform && ` (${vetPatient.speciesFreeform})`}
      </>
    )
  );
}

export default createFragmentContainer(VetSpecies, {
  vetPatient: graphql`
    fragment VetSpecies_vetPatient on VetPatient {
      species
      speciesFreeform
    }
  `,
});
