import { useState } from 'react';
import useField from 'react-formal/useField';
import { defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ArchiveDropdown, { ArchiveItem } from 'components/ArchiveDropdown';
import Analytics from 'utils/Analytics';

import { SplitStudyArchiveField_sourceStudy$data as Study } from './__generated__/SplitStudyArchiveField_sourceStudy.graphql';

interface Props {
  sourceStudy: Study;
}

function SplitStudyArchiveField({ sourceStudy }: Props) {
  const [fieldProps] = useField('archiveId');
  const [value, setValue] = useState<ArchiveItem | null>(sourceStudy.archive);

  if (!sourceStudy) return null;

  return (
    <ArchiveDropdown
      placeholder={defineMessage({
        id: 'ExamArchiveField.placeholder',
        defaultMessage: 'Select archive',
      })}
      organization={sourceStudy.organization}
      value={value}
      onChange={(archive) => {
        fieldProps.onChange(archive?.id || undefined);
        setValue(archive);
        Analytics.track('splitStudyArchiveSelected', {
          studyId: sourceStudy.id,
          archiveId: archive.id,
          organizationId: sourceStudy.organization?.id,
        });
      }}
      className="min-w-48"
    />
  );
}

export default createFragmentContainer(SplitStudyArchiveField, {
  sourceStudy: graphql`
    fragment SplitStudyArchiveField_sourceStudy on Study {
      id
      organization {
        id
        ...ArchiveDropdown_organization
      }
      archive {
        id
        ...ArchiveDropdownItem @relay(mask: false)
      }
    }
  `,
});
