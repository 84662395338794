import BellIcon from '@bfly/icons/Bell';
import Dropdown from '@bfly/ui2/Dropdown';
import Navbar from '@bfly/ui2/Navbar';
import SrOnly from '@bfly/ui2/SrOnly';
import Text from '@bfly/ui2/Text';
import useSubscription from '@bfly/ui2/useSubscription';
import styled from 'astroturf/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import NotificationListRenderer from './NotificationListRenderer';
import { NotificationCenter_Subscription as Subscription } from './__generated__/NotificationCenter_Subscription.graphql';
import { NotificationCenter_organization$data as Organization } from './__generated__/NotificationCenter_organization.graphql';

const NotificationCenterBubble = styled(Text).attrs({
  variant: 'xs',
  color: 'headline',
})`
  position: absolute;
  top: 1.3rem;
  left: theme('spacing.6');
  padding: 0 0.3rem;
  border-radius: 0.1rem;
  background: theme('colors.primary');
  z-index: 1;
`;

interface Props {
  organization: Organization;
}

function NotificationCenter({ organization }: Props) {
  const notificationCenter = organization.viewerNotificationCenter;

  const [show, setShow] = useState(false);

  useSubscription<Subscription>(
    graphql`
      subscription NotificationCenter_Subscription(
        $input: NotificationCenterUpdatedSubscriptionInput!
      ) {
        notificationCenterUpdated(input: $input) {
          notificationCenter {
            numUnreadNotifications
            numUnseenNotifications
          }
        }
      }
    `,
    {
      input: {
        notificationCenterId: notificationCenter?.id || '',
      },
    },
  );

  return (
    <Dropdown placement="bottom-end" show={show} onToggle={setShow}>
      <Dropdown.Toggle id="notification-center" as={Navbar.Button}>
        <BellIcon />
        <SrOnly>
          <FormattedMessage
            id="notificationCenter.title"
            defaultMessage="Notifications"
          />
        </SrOnly>
        {!!notificationCenter?.numUnseenNotifications && (
          <NotificationCenterBubble data-bni-id="NotificationCenterBubble">
            {notificationCenter?.numUnseenNotifications}
          </NotificationCenterBubble>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        data-bni-id="NotificationCenterDropdown"
        css="width: 43rem;"
      >
        {show && (
          <NotificationListRenderer notificationCenter={notificationCenter} />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default createFragmentContainer(NotificationCenter, {
  organization: graphql`
    fragment NotificationCenter_organization on Organization {
      viewerNotificationCenter {
        id
        numUnseenNotifications
        ...NotificationListRenderer_notificationCenter
      }
    }
  `,
});
