import { UserPermission } from '@bfly/olympus-protobuf';
import { graphql, readInlineData } from 'react-relay';
import { InferType, number, object, string } from 'yup';

import { roles_defaultValue$key as MembershipRoleKey } from './__generated__/roles_defaultValue.graphql';

const { Bucket, Level } = UserPermission;

const level = number()
  .required()
  .oneOf([Level.NONE, Level.BASIC, Level.ADMIN])
  .default(Level.NONE);

const schema = object({
  name: string().required().max(60).trim(),
  description: string().max(150).default(''),
  permissions: object({
    [Bucket.ACCESS_ALL_ORGANIZATIONS]: level,
    [Bucket.ARCHIVE_MANAGEMENT]: level,
    [Bucket.BULK_DATA_EXPORT]: level,
    [Bucket.CONNECTIVITY_MANAGEMENT]: level,
    [Bucket.CREDENTIAL_MANAGEMENT]: level,
    [Bucket.DATA_DELETION]: level,
    [Bucket.DATA_EXPORT]: level,
    [Bucket.EDUCATION_MANAGEMENT]: level,
    [Bucket.EXAM_TYPE_MANAGEMENT]: level,
    [Bucket.SIGNING]: level,
    [Bucket.FLEET_MANAGEMENT]: level,
    [Bucket.LOGIN_AND_SECURITY_MANAGEMENT]: level,
    [Bucket.ORGANIZATION_MANAGEMENT]: level,
    [Bucket.QA_MANAGEMENT]: level,
    [Bucket.QA]: level,
    [Bucket.STUDY_DOCUMENTATION]: level,
    [Bucket.STUDY_DOCUMENTATION_MANAGEMENT]: level,
    [Bucket.USER_MANAGEMENT]: level,
    [Bucket.USER_PERMISSIONS]: level,
    [Bucket.STUDY_REVERSION]: level,
  }),
});

export const updateRoleFields = object({
  membershipRoleId: string(),
});

export const updateRoleSchema = schema.concat(updateRoleFields);

export default schema;

export function serialize(data: InferType<typeof schema>) {
  return {
    ...data,
    permissions: Object.entries(data.permissions).map(([key, value]) => [
      parseInt(key, 10),
      value,
    ]),
  };
}

export function deserialize(membershipRole: MembershipRoleKey) {
  const {
    id: membershipRoleId,
    name,
    description,
    permissions,
  } = readInlineData(
    graphql`
      fragment roles_defaultValue on MembershipRole @inline {
        id
        name
        description
        permissions
      }
    `,
    membershipRole,
  );
  return {
    membershipRoleId,
    name,
    description,
    permissions:
      Object.keys(permissions).length === 0
        ? {}
        : Object.fromEntries(permissions),
  };
}
