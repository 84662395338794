/**
 * @generated SignedSource<<debae5ebedd8c04e3707ddc8a74f86b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type archive_CheckArchive_Query$variables = {
  archiveHandle: string;
  organizationSlug: string;
};
export type archive_CheckArchive_Query$data = {
  readonly archive: {
    readonly id: string;
  } | null;
  readonly organization: {
    readonly viewerIsMember: boolean | null;
  } | null;
};
export type archive_CheckArchive_Query = {
  response: archive_CheckArchive_Query$data;
  variables: archive_CheckArchive_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "archiveHandle"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsMember",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "handle",
      "variableName": "archiveHandle"
    }
  ],
  "concreteType": "Archive",
  "kind": "LinkedField",
  "name": "archive",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "archive_CheckArchive_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "archive_CheckArchive_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a32a0b1b4d01d06e752d069fb8de62f4",
    "id": null,
    "metadata": {},
    "name": "archive_CheckArchive_Query",
    "operationKind": "query",
    "text": "query archive_CheckArchive_Query(\n  $archiveHandle: String!\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    viewerIsMember\n    id\n  }\n  archive(handle: $archiveHandle) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c71e0d0d5a5516dff165b4f5b3067bdb";

export default node;
