/**
 * @generated SignedSource<<b4b4581c33349514ef04be3573c7c8e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorksheetReviewedNotification_notification$data = {
  readonly reviewer?: {
    readonly " $fragmentSpreads": FragmentRefs<"StudyNotification_userProfile">;
  } | null;
  readonly study?: {
    readonly deletedAt: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"StudyNotification_study">;
  } | null;
  readonly worksheet?: {
    readonly handle: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"NotFoundNotification_notification" | "StudyNotification_notification">;
  readonly " $fragmentType": "WorksheetReviewedNotification_notification";
};
export type WorksheetReviewedNotification_notification$key = {
  readonly " $data"?: WorksheetReviewedNotification_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetReviewedNotification_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorksheetReviewedNotification_notification",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "reviewer",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StudyNotification_userProfile"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Study",
          "kind": "LinkedField",
          "name": "study",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deletedAt",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StudyNotification_study"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Worksheet",
          "kind": "LinkedField",
          "name": "worksheet",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "handle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "WorksheetReviewedNotification",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotFoundNotification_notification"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyNotification_notification"
    }
  ],
  "type": "NotificationInterface",
  "abstractKey": "__isNotificationInterface"
};

(node as any).hash = "dcf0fe3cef502e0380bacd2a11cbb611";

export default node;
