import Heading from '@bfly/ui2/Heading';
import Text from '@bfly/ui2/Text';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { array, number, object, string } from 'yup';

import { BLineSummaryResults_interpretations$data as Interpretations } from './__generated__/BLineSummaryResults_interpretations.graphql';

interface Props {
  interpretations: Interpretations;
}

type BLineData = {
  id: string;
  zone: string;
  count: number;
};

const bLineDataSchema = object({
  zone: string().required(),
  count: number().required(),
});

const bLineInterpretationSchema = object({
  blines: array().of(bLineDataSchema),
});

async function validateBlineData(
  interpretations: Interpretations,
): Promise<BLineData[]> {
  const blineDataArray = await Promise.all(
    interpretations.map(async (interpretation) => {
      if (!interpretation.output) {
        return null;
      }
      // output is an opaque JSONObject
      // so we need to validate that this is the b-line
      // data that we expect for the interpretation
      const output = await bLineInterpretationSchema.validate(
        interpretation.output,
      );
      const blineData = output.blines;
      if (!(blineData && blineData.length > 0)) {
        return null;
      }
      return blineData.map((data) => ({
        id: uuidv4(),
        zone: data.zone,
        count: data.count,
      }));
    }),
  );
  return blineDataArray
    .filter((data): data is BLineData[] => data !== null)
    .flatMap((data) => data);
}

export function useValidatedBlineData(
  interpretations: Interpretations,
): BLineData[] {
  const [bLineData, setBLineData] = useState<BLineData[]>([]);

  useEffect(() => {
    validateBlineData(interpretations)
      .then((blineData) => setBLineData(blineData))
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error));
  }, [interpretations]);

  return bLineData;
}

function BLineSummaryResults({ interpretations }: Props) {
  const bLineData = useValidatedBlineData(interpretations);

  if (bLineData && bLineData.length > 0) {
    return (
      <div
        className="flex flex-col items-start text-white px-5"
        data-bni-id="BLineSummaryResults"
      >
        <Heading
          className="mt-5 text-grey-50"
          variant="body-bold"
          transform="uppercase"
        >
          <FormattedMessage
            id="pace.examPageSidebar.results"
            defaultMessage="Results"
          />
        </Heading>
        <Text className="font-bold">
          <FormattedMessage
            id="pace.examPageSidebar.primaryAdvancedClinicalExam"
            defaultMessage="PACE (Primary Advanced Clinical Exam)"
          />
        </Text>
        <Text className="font-bold mt-3">
          <FormattedMessage
            id="pace.examPageSidebar.bLineCount"
            defaultMessage="B-Line Count for Lung Zones"
          />
        </Text>
        <div className="mt-2 space-y-5">
          {bLineData.map((data) => (
            <div className="flex flex-col" key={data.id}>
              <Text className="mt-0">
                <FormattedMessage
                  id="pace.examPageSidebar.zone"
                  defaultMessage="{annotation} View"
                  values={{ annotation: data.zone }}
                />
              </Text>
              <Text className="mt-2">
                <FormattedMessage
                  id="pace.examPageSidebar.bLinesFound"
                  defaultMessage="{bLineCount} {bLineCount, plural, one {B-Line} other {B-Lines}} Found"
                  values={{ bLineCount: data.count }}
                />
              </Text>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <Heading className="mt-5 text-grey-50 px-5" variant="body-bold">
      <FormattedMessage
        id="pace.examPageSidebar.noResultsFound"
        defaultMessage="No results found"
      />
    </Heading>
  );
}

export default createFragmentContainer(BLineSummaryResults, {
  interpretations: graphql`
    fragment BLineSummaryResults_interpretations on Interpretation
    @relay(plural: true) {
      output
      interpretationType
    }
  `,
});
