/**
 * @generated SignedSource<<b3b4dc699bbfbd26cec1cd40c07ee613>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type education_EducationContentPage_Query$variables = {
  handle: string;
};
export type education_EducationContentPage_Query$data = {
  readonly lms: {
    readonly educationContent: {
      readonly " $fragmentSpreads": FragmentRefs<"EducationContentPage_educationContent">;
    } | null;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"EducationContentPage_viewer">;
  } | null;
};
export type education_EducationContentPage_Query = {
  response: education_EducationContentPage_Query$data;
  variables: education_EducationContentPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "handle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "handle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnails",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ThumbnailedInterface",
  "abstractKey": "__isThumbnailedInterface"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "education_EducationContentPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EducationContent",
            "kind": "LinkedField",
            "name": "educationContent",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EducationContentPage_educationContent"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EducationContentPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "education_EducationContentPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EducationContent",
            "kind": "LinkedField",
            "name": "educationContent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "localId",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPremium",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContent",
                "kind": "LinkedField",
                "name": "prevRecord",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContent",
                "kind": "LinkedField",
                "name": "nextRecord",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerHasAccess",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContentVideo",
                "kind": "LinkedField",
                "name": "video",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vimeoId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "authoredBy",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerHasSaved",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerMembership",
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a737cadbf9aa365d985384493f0be04",
    "id": null,
    "metadata": {},
    "name": "education_EducationContentPage_Query",
    "operationKind": "query",
    "text": "query education_EducationContentPage_Query(\n  $handle: String!\n) {\n  lms {\n    educationContent(handle: $handle) {\n      ...EducationContentPage_educationContent\n      id\n    }\n  }\n  viewer {\n    ...EducationContentPage_viewer\n    id\n  }\n}\n\nfragment EducationContentInfo_educationContent on EducationContent {\n  id\n  name\n  description\n  createdAt\n  video {\n    authoredBy {\n      name\n      id\n    }\n  }\n  ...useSaveContentToggle_educationContent\n  ...ShareEducationContentModal_educationContent\n}\n\nfragment EducationContentPage_educationContent on EducationContent {\n  localId\n  name\n  isPremium\n  prevRecord {\n    handle\n    id\n  }\n  nextRecord {\n    handle\n    ...NextContentPopout_educationContent\n    id\n  }\n  viewerHasAccess\n  ...Thumbnail_image\n  ...EducationContentPlayer_educationContent\n  ...EducationContentInfo_educationContent\n}\n\nfragment EducationContentPage_viewer on Viewer {\n  ...LockedContentHero_viewer\n}\n\nfragment EducationContentPlayer_educationContent on EducationContent {\n  name\n  video {\n    vimeoId\n  }\n}\n\nfragment LockedContentHero_viewer on Viewer {\n  __typename\n  memberships {\n    organization {\n      slug\n      id\n    }\n    id\n  }\n}\n\nfragment NextContentPopout_educationContent on EducationContent {\n  handle\n  name\n  ...Thumbnail_image\n}\n\nfragment ShareEducationContentModal_educationContent on EducationContent {\n  localId\n  handle\n  isPremium\n  contentType\n  viewerHasAccess\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n\nfragment useSaveContentToggle_educationContent on EducationContent {\n  id\n  viewerHasSaved\n  viewerHasAccess\n}\n"
  }
};
})();

(node as any).hash = "95b1319b7cdde90943136c729060f5db";

export default node;
