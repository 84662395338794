/**
 * @generated SignedSource<<bc54718ff17d928bfba13f3acd78b8c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalStudySearchDomainPage_viewer$data = {
  readonly domain: {
    readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchSubNav_tenant">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_viewer" | "GlobalStudySearchSubNav_viewer">;
  readonly " $fragmentType": "GlobalStudySearchDomainPage_viewer";
};
export type GlobalStudySearchDomainPage_viewer$key = {
  readonly " $data"?: GlobalStudySearchDomainPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchDomainPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalStudySearchDomainPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GlobalStudySearchSubNav_tenant"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GlobalStudySearchSubNav_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BaseAppPage_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "39d1d4f4deebee30b7c011dfac41d5a7";

export default node;
