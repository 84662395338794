import { IntlShape, defineMessages, useIntl } from 'react-intl';

const MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;

interface Props {
  value: string | number | Date;
}

const messages = defineMessages({
  yearsOld: {
    id: 'formattedAge.yearsOld',
    defaultMessage: '{age} yrs',
    description: 'An age measured in whole years',
  },
  monthsOld: {
    id: 'formattedAge.monthsOld',
    defaultMessage: '{age, plural, =0 {<1 mo} other {{age} mo}}',
    description: 'An age measured in whole months',
  },
});

export const formattedAge = (
  birthDate: string | number | Date,
  intl: IntlShape,
) => {
  const date = new Date(birthDate);
  const now = new Date();
  const diffInYears = (now.getTime() - date.getTime()) / MS_PER_YEAR;
  const diffInMonths = Math.floor(diffInYears * 12);

  return diffInMonths > 23
    ? intl.formatMessage(messages.yearsOld, {
        age: Math.max(Math.floor(diffInYears), 0),
      })
    : intl.formatMessage(messages.monthsOld, {
        age: Math.floor(diffInMonths),
      });
};

/**
 * Displays a JS Date as an age, displaying months when the range is less than 2 years
 */
function FormattedAge({ value }: Props) {
  const intl = useIntl();

  return <>{formattedAge(value, intl)}</>;
}

export default FormattedAge;
