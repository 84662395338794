import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';

import useMutationTriggerWithConfirm from 'hooks/useMutationTriggerWithConfirm';
import actionMessages from 'messages/actions';

import { useDeleteWorksheetMutation } from './__generated__/useDeleteWorksheetMutation.graphql';

const mutation = graphql`
  mutation useDeleteWorksheetMutation($input: RemoveWorksheetInput!) {
    removeWorksheetOrError(input: $input) {
      ... on RemoveWorksheetPayload {
        study {
          worksheets {
            ...ExamPageSidebarWorksheets
          }
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

export interface DeleteWorksheetControlOptions {
  worksheetId: string;
  studyId: string;
}

export default function useDeleteWorksheet({
  worksheetId,
  studyId,
}: DeleteWorksheetControlOptions) {
  return useMutationTriggerWithConfirm<useDeleteWorksheetMutation>(mutation, {
    content: (
      <FormattedMessage
        id="deleteWorksheet.dialog.message"
        defaultMessage="Deleting this worksheet will remove all the content it contains at the time of deletion."
      />
    ),
    openDialogOptions: {
      title: (
        <FormattedMessage
          id="deleteWorksheet.dialog.title"
          defaultMessage="Delete this worksheet?"
        />
      ),
      confirmButtonProps: { variant: 'danger', size: 'lg' },
      modalVariant: 'dark',
      confirmLabel: <FormattedMessage {...actionMessages.delete} />,
    },
    input: {
      worksheetId,
      studyId,
    },
  });
}
