/**
 * @generated SignedSource<<b6ad4c5df5d00608433c7d6cf1c2a4ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type fleet_FleetManagementPageQuery$variables = {};
export type fleet_FleetManagementPageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"FleetManagementPage_domain" | "RouteAccessControl_tenant">;
  } | null;
};
export type fleet_FleetManagementPageQuery = {
  response: fleet_FleetManagementPageQuery$data;
  variables: fleet_FleetManagementPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subdomainLabel",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 2147483647
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "status",
    "value": "LOST"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FleetDeviceEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FleetDevice",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registeredAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "status",
    "value": "STOLEN"
  }
],
v8 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "status",
    "value": "ACTIVE"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "fleet_FleetManagementPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FleetManagementPage_domain"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "RouteAccessControl_tenant",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v0/*: any*/)
                ],
                "type": "Domain",
                "abstractKey": null
              },
              (v1/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "fleet_FleetManagementPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "lostFleetDevices",
                "args": (v4/*: any*/),
                "concreteType": "FleetDeviceConnection",
                "kind": "LinkedField",
                "name": "fleetDeviceConnection",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "fleetDeviceConnection(first:2147483647,status:\"LOST\")"
              },
              {
                "alias": "lostFleetDevices",
                "args": (v4/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "FleetDevice_lostFleetDevices",
                "kind": "LinkedHandle",
                "name": "fleetDeviceConnection"
              },
              {
                "alias": "stolenFleetDevices",
                "args": (v7/*: any*/),
                "concreteType": "FleetDeviceConnection",
                "kind": "LinkedField",
                "name": "fleetDeviceConnection",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "fleetDeviceConnection(first:2147483647,status:\"STOLEN\")"
              },
              {
                "alias": "stolenFleetDevices",
                "args": (v7/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "FleetDevice_stolenFleetDevices",
                "kind": "LinkedHandle",
                "name": "fleetDeviceConnection"
              },
              {
                "alias": "activeFleetDevices",
                "args": (v8/*: any*/),
                "concreteType": "FleetDeviceConnection",
                "kind": "LinkedField",
                "name": "fleetDeviceConnection",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "fleetDeviceConnection(first:2147483647,status:\"ACTIVE\")"
              },
              {
                "alias": "activeFleetDevices",
                "args": (v8/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "FleetDevice_activeFleetDevices",
                "kind": "LinkedHandle",
                "name": "fleetDeviceConnection"
              },
              (v0/*: any*/)
            ],
            "type": "Domain",
            "abstractKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          (v1/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1a2b9fa3f735cce1fe8e6be18bd708e",
    "id": null,
    "metadata": {},
    "name": "fleet_FleetManagementPageQuery",
    "operationKind": "query",
    "text": "query fleet_FleetManagementPageQuery {\n  tenant {\n    __typename\n    ...FleetManagementPage_domain\n    ...RouteAccessControl_tenant\n    id\n  }\n}\n\nfragment FleetDeviceListItem_device on FleetDevice {\n  productionId\n  registeredAt\n  ...FleetDeviceStatusDropdown_device\n}\n\nfragment FleetDeviceSection_devices on FleetDevice {\n  id\n  registeredAt\n  ...FleetDeviceListItem_device\n}\n\nfragment FleetDeviceStatusDropdown_device on FleetDevice {\n  id\n  domain {\n    id\n  }\n  status\n}\n\nfragment FleetManagementPage_domain on Domain {\n  lostFleetDevices: fleetDeviceConnection(status: LOST, first: 2147483647) {\n    edges {\n      node {\n        ...FleetDeviceSection_devices\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  stolenFleetDevices: fleetDeviceConnection(status: STOLEN, first: 2147483647) {\n    edges {\n      node {\n        ...FleetDeviceSection_devices\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  activeFleetDevices: fleetDeviceConnection(status: ACTIVE, first: 2147483647) {\n    edges {\n      node {\n        ...FleetDeviceSection_devices\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment RouteAccessControl_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    subdomainLabel\n  }\n  ... on Organization {\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0ca6fbe83e9a07242c2a9849f269f23";

export default node;
