import { FormVariant } from '@bfly/ui2/Form';
import { css } from 'astroturf';
import clsx from 'clsx';
import { forwardRef } from 'react';

export interface ListCardContainerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  className?: string;
  variant?: FormVariant | null;
  /** remove the top and side padding */
  flush?: boolean;
}

const ListCardContainer = forwardRef(
  (
    { className, variant = 'light', flush, ...props }: ListCardContainerProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        {...props}
        className={clsx(
          className,
          variant != null && 'form-control',
          variant != null && (!flush ? 'py-3 px-4' : 'px-0'),
          variant === 'light' && 'form-control-light',
          variant === 'secondary' && 'form-control-secondary',
          'scrollable-y relative min-w-0 flex bg-clip-border flex-col break-words',
        )}
        css={css`
          overflow-y: auto;
          min-height: 29rem;
          max-height: 35rem;
          height: auto;
        `}
      />
    );
  },
);

export default ListCardContainer;
