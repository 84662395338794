/**
 * @generated SignedSource<<909719a49df0b032d4b4fc9bf75a5c57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPacsPushStatus = "CANCELED" | "COMPLETE" | "FAILED" | "NOT_PUSHING" | "PENDING" | "RETRYING" | "%future added value";
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditExamInfoModal_study$data = {
  readonly accessionNumber: string | null;
  readonly archive: {
    readonly worklistRequiredToFinalize: boolean | null;
  } | null;
  readonly finalizedAt: string | null;
  readonly hasAssociatedWorklistItem: boolean | null;
  readonly hasGeneratedAccessionNumber: boolean | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly primaryWorklist: {
      readonly id?: string;
      readonly type: string;
      readonly " $fragmentSpreads": FragmentRefs<"AssociateExamWorklistItemModalContent_primaryWorklist">;
    } | null;
  } | null;
  readonly pacsPushStatus: StudyPacsPushStatus | null;
  readonly patient: {
    readonly medicalRecordNumber: string | null;
    readonly nameFirst: string | null;
    readonly nameLast: string | null;
    readonly nameMiddle: string | null;
    readonly namePrefix: string | null;
    readonly nameSuffix: string | null;
  } | null;
  readonly practiceType: StudyPracticeType | null;
  readonly studyDescription: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"EditHumanExamInfoFieldSet_study" | "EditVetExamInfoFieldSet_study" | "humanStudyInfo_study" | "vetStudyInfo_study">;
  readonly " $fragmentType": "EditExamInfoModal_study";
};
export type EditExamInfoModal_study$key = {
  readonly " $data"?: EditExamInfoModal_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditExamInfoModal_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "studyDescription",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessionNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAssociatedWorklistItem",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v0/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "medicalRecordNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameFirst",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameLast",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameMiddle",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namePrefix",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameSuffix",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dicomDocument",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "sourceWorklistItem",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditExamInfoModal_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pacsPushStatus",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasGeneratedAccessionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "primaryWorklist",
          "plural": false,
          "selections": [
            {
              "alias": "type",
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AssociateExamWorklistItemModalContent_primaryWorklist"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "worklistRequiredToFinalize",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditHumanExamInfoFieldSet_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditVetExamInfoFieldSet_study"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "humanStudyInfo_study",
      "selections": [
        (v11/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Patient",
          "kind": "LinkedField",
          "name": "patient",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v5/*: any*/)
              ],
              "type": "PatientInterface",
              "abstractKey": "__isPatientInterface"
            }
          ],
          "storageKey": null
        },
        (v15/*: any*/),
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "vetStudyInfo_study",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VetPatient",
          "kind": "LinkedField",
          "name": "vetPatient",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientNamePrefix",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientNameSuffix",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientNameFirst",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientNameMiddle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientNameLast",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientOrganizationName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "patientIdNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "microchipNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "species",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "speciesFreeform",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "breed",
              "storageKey": null
            },
            (v14/*: any*/),
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "neuterStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "weightKilograms",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v11/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v12/*: any*/),
        (v15/*: any*/),
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "25c79aa232a96304280920fe3e45291f";

export default node;
