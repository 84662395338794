import RelayInfiniteList from '@bfly/ui2/RelayInfiniteList';
import getNodes from '@bfly/utils/getNodes';
import { useContext, useEffect, useState } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import type { RelayPaginationProp } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import { RoutePageProps } from 'components/Route';

import EducationContentTile from './EducationContentTile';
import { TotalEducationContentContext } from './EducationVideoListPage';
import EmptyContentListScreen from './EmptyContentListScreen';
import ShareEducationContentModal from './ShareEducationContentModal';
import type { EducationContentGrid_lms$data as Lms } from './__generated__/EducationContentGrid_lms.graphql';

const PAGE_SIZE = 25;

export type EducationContent =
  DeepNonNullable<Lms>['educationContentConnection']['edges'][0]['node'];

interface Props extends RoutePageProps {
  lms: Lms;
  relay: RelayPaginationProp;
}

function EducationContentGrid({ lms, relay }: Props) {
  const { educationContentConnection } = lms;
  const [sharedRecord, setSharedRecord] = useState<EducationContent | null>(
    null,
  );
  const { setTotalNumber } = useContext(TotalEducationContentContext);
  useEffect(() => {
    setTotalNumber(educationContentConnection?.numEdges || 0);
  }, [educationContentConnection?.numEdges, setTotalNumber]);

  if (!educationContentConnection?.edges?.length) {
    return <EmptyContentListScreen />;
  }

  return (
    <>
      <ShareEducationContentModal
        show={!!sharedRecord}
        educationContent={sharedRecord}
        onHide={() => setSharedRecord(null)}
      />
      <RelayInfiniteList relayPagination={relay} pageSize={PAGE_SIZE}>
        <div
          data-bni-id="EducationContentGrid"
          className="grid gap-3 auto-rows-fr grid-cols-[repeat(auto-fill,_minmax(27rem,_1fr))]"
        >
          {getNodes(educationContentConnection).map(
            (educationContent: EducationContent) => (
              <EducationContentTile
                key={educationContent.id}
                educationContent={educationContent}
                onShare={() => setSharedRecord(educationContent)}
              />
            ),
          )}
        </div>
      </RelayInfiniteList>
    </>
  );
}

export default createPaginationContainer(
  EducationContentGrid,
  {
    viewer: graphql`
      fragment EducationContentGrid_viewer on Viewer {
        memberships {
          organization {
            practiceType
          }
        }
      }
    `,
    lms: graphql`
      fragment EducationContentGrid_lms on Lms
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 25 }
        cursor: { type: "String" }
        sort: { type: "[EducationContentSorting!]" }
        search: { type: "String" }
        categoryHandle: { type: "[String!]" }
        contentType: { type: "[EducationContentType!]" }
        videoType: { type: "[EducationVideoType!]" }
        duration: { type: "[EducationContentDuration!]" }
        viewerHasCompleted: { type: "[Boolean!]" }
        courseLanguage: { type: "LmsCourseLanguageEnum" }
      ) {
        educationContentConnection(
          first: $count
          after: $cursor
          sort: $sort
          search: $search
          categoryHandle: $categoryHandle
          contentType: $contentType
          videoType: $videoType
          duration: $duration
          viewerHasCompleted: $viewerHasCompleted
          includePageInfo: true
          courseLanguage: $courseLanguage
        ) @connection(key: "query_educationContentConnection") {
          numEdges
          edges {
            node {
              id
              practiceType
              ...EducationContentTile_educationContent
              ...ShareEducationContentModal_educationContent
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getVariables: (
      _,
      { count, cursor },
      {
        sort,
        search,
        categoryHandle,
        videoType,
        contentType,
        duration,
        viewerHasCompleted,
        courseLanguage,
      },
    ) => {
      return {
        count,
        cursor,
        sort,
        search,
        categoryHandle,
        contentType,
        videoType,
        duration,
        viewerHasCompleted,
        courseLanguage,
      };
    },
    query: graphql`
      query EducationContentGridQuery(
        $count: Int!
        $cursor: String
        $sort: [EducationContentSorting!]
        $search: String
        $categoryHandle: [String!]
        $contentType: [EducationContentType!]
        $videoType: [EducationVideoType!]
        $duration: [EducationContentDuration!]
        $viewerHasCompleted: [Boolean!]
        $courseLanguage: LmsCourseLanguageEnum!
      ) {
        lms {
          ...EducationContentGrid_lms
            @arguments(
              count: $count
              cursor: $cursor
              sort: $sort
              search: $search
              categoryHandle: $categoryHandle
              contentType: $contentType
              videoType: $videoType
              duration: $duration
              viewerHasCompleted: $viewerHasCompleted
              courseLanguage: $courseLanguage
            )
        }
      }
    `,
  },
);
