/**
 * @generated SignedSource<<1c5eb546ab358f48eae0cb43eb5d6afb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewStudyDataGridCaptures_studyImages$data = ReadonlyArray<{
  readonly burnedInFile: {
    readonly isVideo: boolean | null;
    readonly " $fragmentSpreads": FragmentRefs<"Thumbnail_image">;
  } | null;
  readonly handle: string | null;
  readonly iqMetadata: {
    readonly probeSerial: string | null;
  } | null;
  readonly " $fragmentType": "NewStudyDataGridCaptures_studyImages";
}>;
export type NewStudyDataGridCaptures_studyImages$key = ReadonlyArray<{
  readonly " $data"?: NewStudyDataGridCaptures_studyImages$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewStudyDataGridCaptures_studyImages">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "NewStudyDataGridCaptures_studyImages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BurnedInFile",
      "kind": "LinkedField",
      "name": "burnedInFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVideo",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Thumbnail_image"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IqMetadata",
      "kind": "LinkedField",
      "name": "iqMetadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "probeSerial",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyImage",
  "abstractKey": null
};

(node as any).hash = "2be21874a1025eb45bf32446521e8030";

export default node;
