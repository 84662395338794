/**
 * @generated SignedSource<<c82c50a042e5b1b614d5188965a8d092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type routeConfig_OrganizationSetupPageQuery$variables = {
  organizationSlug: string;
};
export type routeConfig_OrganizationSetupPageQuery$data = {
  readonly organization: {
    readonly setupAt: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSetupPage_organization">;
  } | null;
};
export type routeConfig_OrganizationSetupPageQuery = {
  response: routeConfig_OrganizationSetupPageQuery$data;
  variables: routeConfig_OrganizationSetupPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "setupAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_OrganizationSetupPageQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSetupPage_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeConfig_OrganizationSetupPageQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3bbd41138c162104c6d957f2c0403258",
    "id": null,
    "metadata": {},
    "name": "routeConfig_OrganizationSetupPageQuery",
    "operationKind": "query",
    "text": "query routeConfig_OrganizationSetupPageQuery(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    setupAt\n    ...OrganizationSetupPage_organization\n    id\n  }\n}\n\nfragment OrganizationSetupPage_organization on Organization {\n  id\n}\n"
  }
};
})();

(node as any).hash = "0f7d00e309d791f54b08bd3395d4757e";

export default node;
