import { Params, join, route } from '@4c/spa-routing';
import { useRouter } from 'found';
import { useMemo } from 'react';

import partialRoutes from 'utils/partialRoutes';

const rootRoute = route('');
const organizationRoot = join(rootRoute, ':organizationSlug');
const archivesRoot = join(organizationRoot, 'archives');
const archive = join(archivesRoot, ':archiveHandle');

const archiveSettings = join(archive, 'settings');
const archiveMembers = join(archive, '-/members');

const studySearch = join(organizationRoot, 'search');

const sharedBundle = join(rootRoute, '-/shared/:sharedBundleHandle');
const sharedBundleImage = join(sharedBundle, ':sharedBundleImageHandle');

const measurements = join(organizationRoot, ':studyImageHandle/measurements');

const orgRoutes = {
  archive,
  archiveSettings,
  archiveMembers,
  studySearch,
  measurements,
};

export const routes = {
  ...orgRoutes,
  sharedBundle,
  sharedBundleImage,
};

export function useArchiveRoutes(
  newOrgSlug?: string,
  newArchiveSlug?: string,
) {
  const { match } = useRouter();
  // If params are passed use them, otherwise use existing ones
  const organizationSlug = newOrgSlug || match.params.organizationSlug;
  const archiveHandle = newArchiveSlug || match.params.archiveHandle;

  return useMemo(() => {
    const params: Params<'organizationSlug' | 'archiveHandle'> = {
      organizationSlug,
      archiveHandle,
    };
    return {
      ...partialRoutes(orgRoutes, params),
      sharedBundle,
      sharedBundleImage,
    };
  }, [archiveHandle, organizationSlug]);
}
