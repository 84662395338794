import Heading from '@bfly/ui2/Heading';
import styled from 'astroturf/react';

const ExamPageSidebarHeading = styled(Heading).attrs({
  color: 'subtitle',
  variant: 'sm-bold',
  transform: 'uppercase',
})<{ invalid?: boolean }>`
  composes: mb-2 from global;

  display: flex;
  flex: none;

  &.invalid {
    /* Ported from FormControlErrorWrapper with minor tweaks. */
    position: relative;
    padding-left: theme('padding.2');

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      width: 2px;
      border-radius: 2px;
      background-color: theme('colors.danger');
    }
  }
`;

export default ExamPageSidebarHeading;
