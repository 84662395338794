import Text from '@bfly/ui2/Text';
import clsx from 'clsx';
import React from 'react';

interface Props {
  variant: 'success' | 'danger';
  className?: string;
  children: React.ReactNode;
}

function MessageBox({ variant, className, children }: Props) {
  return (
    <div
      className={clsx(
        className,
        'p-3 mb-2 rounded',
        variant === 'success' && 'bg-success/10',
        variant === 'danger' && 'bg-danger/10',
      )}
    >
      <Text variant="body" color={variant}>
        {children}
      </Text>
    </div>
  );
}

export default MessageBox;
