/**
 * @generated SignedSource<<e3b4c54b2ffb1a6e6b44cff7fa2f7ed4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveSettingsPage_archive$data = {
  readonly id: string;
  readonly label: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ArchivePageTitle_archive" | "ArchivePrivacyForm_archive" | "ArchiveQaNotificationsForm_archive" | "ArchiveStudyRestrictionsForm_archive" | "DeleteArchiveControl_archive" | "EhrArchiveAssociationForm_archive" | "PacsArchiveAssociationForm_archive" | "ThirdPartyUltrasoundListenerConfiguration_archive" | "WorksheetsExportControl_archive">;
  readonly " $fragmentType": "ArchiveSettingsPage_archive";
};
export type ArchiveSettingsPage_archive$key = {
  readonly " $data"?: ArchiveSettingsPage_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveSettingsPage_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveSettingsPage_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchivePageTitle_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PacsArchiveAssociationForm_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteArchiveControl_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchivePrivacyForm_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorksheetsExportControl_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ThirdPartyUltrasoundListenerConfiguration_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EhrArchiveAssociationForm_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchiveStudyRestrictionsForm_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchiveQaNotificationsForm_archive"
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "98c234eccca4d06bcb24d84ad1db9cc4";

export default node;
