import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Dl, { DescriptionListProps } from 'components/DescriptionList';
import VetClientName from 'components/VetClientName';
import messages from 'messages/vetPatient';

import { VetClientDescriptionList_vetPatient$data as VetPatient } from './__generated__/VetClientDescriptionList_vetPatient.graphql';

interface Props extends DescriptionListProps {
  vetPatient: VetPatient;
}

function VetClientDescriptionList({ vetPatient, ...props }: Props) {
  if (!vetPatient) {
    return null;
  }

  return (
    <Dl {...props}>
      <Dl.Term>
        <FormattedMessage {...messages.clientName} />
      </Dl.Term>
      <Dl.Detail>
        <VetClientName vetPatient={vetPatient} />
      </Dl.Detail>
      <Dl.Term>
        <FormattedMessage {...messages.clientOrganizationName} />
      </Dl.Term>
      <Dl.Detail>{vetPatient.clientOrganizationName}</Dl.Detail>
    </Dl>
  );
}

export default createFragmentContainer(VetClientDescriptionList, {
  vetPatient: graphql`
    fragment VetClientDescriptionList_vetPatient on VetPatient {
      clientOrganizationName
      ...VetClientName_vetPatient
    }
  `,
});
