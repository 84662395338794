import { colors } from '@bfly/ui2/tailwind/colors';
import { MessageDescriptor } from 'react-intl';
import { graphql } from 'react-relay';

import RingChartCard from 'components/RingChartCard';
import { getExamTypeColor } from 'utils/ExamTypeProficiencyUtils';

import { INDICATORS as messages } from '../messages';
import { IndicatorRingChartCard_byExamType$data as Stats } from './__generated__/IndicatorRingChartCard_byExamType.graphql';

export const byExamTypeFragment = graphql`
  fragment IndicatorRingChartCard_byExamType on EduStudyStatistics {
    byExamType {
      examType {
        name
        systemId
      }
      numUnsubmitted
      numReviewRequested
      numReviewed
    }
  }
`;

export function IndicatorRingChartCardByExamType({
  className,
  byExamTypeData,
  getValue,
  id,
  headerMessage,
}: {
  className: string;
  byExamTypeData?: Stats['byExamType'];
  getValue: (row: NonNullable<Stats['byExamType']>[number]) => number;
  id: string;
  headerMessage?: MessageDescriptor | undefined;
}) {
  const data =
    byExamTypeData
      ?.filter((row) => row.examType?.name && row.examType?.systemId)
      .map((row) => ({
        title: row.examType!.name!,
        value: getValue(row),
        color: getExamTypeColor(row.examType!.systemId!),
      })) || [];

  const total = data?.reduce((acc, { value }) => acc + value, 0) || 0;

  const largestSet = total && data?.sort((a, b) => b.value - a.value)?.[0];

  const percentages = largestSet && [
    { ...largestSet, value: largestSet.value / total },
  ];

  return (
    <RingChartCard
      loading={!byExamTypeData}
      className={className}
      id={id}
      data={data || []}
      total={total}
      chartInside={{
        message: messages.examTypes,
      }}
      header={{
        value: total,
        message: headerMessage,
      }}
      percentages={percentages || []}
    />
  );
}

export function IndicatorRingChartCardByExamStatus({
  className,
  byExamTypeData,
  id,
  headerMessage,
}: {
  className: string;
  byExamTypeData?: Stats['byExamType'];
  id: string;
  headerMessage?: MessageDescriptor | undefined;
}) {
  const totals = (byExamTypeData || []).reduce(
    (acc, { numReviewed, numReviewRequested }) => ({
      numReviewed: acc.numReviewed + numReviewed,
      numReviewRequested: acc.numReviewRequested + numReviewRequested,
    }),
    {
      numReviewed: 0,
      numReviewRequested: 0,
    },
  );

  const data = [
    {
      title: 'Reviewed',
      value: totals?.numReviewed || 0,
      color: colors.pink,
    },
  ];

  const total = totals?.numReviewRequested || 0;

  const percentages =
    (total && [{ ...data[0], value: data[0].value / total }]) || 0;

  return (
    <RingChartCard
      loading={!byExamTypeData}
      className={className}
      id={id}
      data={data || []}
      total={total}
      chartInside={{
        message: messages.examStatus,
      }}
      header={{
        value: total,
        message: headerMessage,
      }}
      percentages={percentages || []}
    />
  );
}
