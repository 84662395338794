/**
 * @generated SignedSource<<ce2dfa746db41ae22703e2bbab92da8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveMemberItemGridRow_archive$data = {
  readonly hasRestrictedFinalization: boolean | null;
  readonly id: string;
  readonly " $fragmentType": "ArchiveMemberItemGridRow_archive";
};
export type ArchiveMemberItemGridRow_archive$key = {
  readonly " $data"?: ArchiveMemberItemGridRow_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveMemberItemGridRow_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveMemberItemGridRow_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasRestrictedFinalization",
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "7bbd95a24750aa8421abf863726a88af";

export default node;
