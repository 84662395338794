/**
 * @generated SignedSource<<6d5fc8fe0ba0aaba3053686d1bc9bdd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useRefreshStudyDialog_study$data = {
  readonly handle: string | null;
  readonly " $fragmentType": "useRefreshStudyDialog_study";
};
export type useRefreshStudyDialog_study$key = {
  readonly " $data"?: useRefreshStudyDialog_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"useRefreshStudyDialog_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useRefreshStudyDialog_study"
};

(node as any).hash = "7b412cfbb0ff4d1ae6f9be16786b4019";

export default node;
