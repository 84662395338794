import useDialog from '@bfly/ui2/useDialog';
import useQuery from '@bfly/ui2/useQuery';
import useEventCallback from '@restart/hooks/useEventCallback';
import useTimeout from '@restart/hooks/useTimeout';
import { listen } from 'dom-helpers';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Environment, graphql } from 'react-relay';
import store from 'store/dist/store.modern';

import { useInactivityTimeoutQuery as Query } from './__generated__/useInactivityTimeoutQuery.graphql';

const STORE_KEY = 'bfly:lastActivityTime';

const ACTIVITY_EVENTS = [
  'click',
  'keypress',
  'mousemove',
  'touchmove',
  'touchstart',
  'wheel',
] as const;

// Duration between checks for activity
const SESSION_ACTIVITY_INTERVAL = 1000 * 10; // 10 sec

// Time to display warning dialog before logging out
// We can hardcode this because we know the minimum timeout will be >= 15 mins
const DIALOG_TIMEOUT_MS = 1000 * 60; // 1 min

async function openTimeoutDialog(dialog: ReturnType<typeof useDialog>) {
  const dialogHandle = setTimeout(() => {
    dialog.close();
  }, DIALOG_TIMEOUT_MS);

  const result = await dialog.open(
    <FormattedMessage
      id="inactivity.timeout.dialog.body"
      defaultMessage="Due to inactivity, your current session will expire."
    />,
    {
      cancelLabel: (
        <FormattedMessage
          id="inactivity.timeout.dialog.cancel"
          defaultMessage="Log Out"
        />
      ),
      confirmLabel: (
        <FormattedMessage
          id="inactivity.timeout.dialog.confirm"
          defaultMessage="Continue Session"
        />
      ),
      title: (
        <FormattedMessage
          id="inactivity.timeout.dialog.title"
          defaultMessage="Your session will expire soon."
        />
      ),
    },
  );

  globalThis.clearTimeout(dialogHandle);

  return result;
}

/**
 * Create an activity authentication timeout that prompts a user to
 * "continue" if they are still active after a user configured amount of time.
 * Enforcement across browser sessions is handled by storing the deadline
 * in local storage, and checking "on load" if the value exists and is the past.
 *
 * This hook checks if a deadline every ~10 seconds, and updates
 * the users 'inactiveAt' value. If the current deadline is close,
 * show a user a dialog to allow reseting the deadline. If 60 seconds
 * pass without user feedback, they are signed out automatically
 */
function useInactivityTimeout({
  enabled,
  environment,
  onTimeout,
}: {
  enabled: boolean;
  environment: Environment;
  onTimeout(): void | Promise<void>;
}) {
  const dialog = useDialog();

  const lastActiveMsRef = useRef<number>(Date.now());
  const timeout = useTimeout();

  // We store the inactivity deadline in local storage
  // in order to catch cases where the user closes tabs and then
  // returns later (but before their token expires)
  // In these cases we "timeout" immediately clearing any auth info
  const initialInactiveAt = store.get(STORE_KEY, null) as number | null;
  if (initialInactiveAt && Date.now() > initialInactiveAt) {
    store.remove(STORE_KEY);
    onTimeout();
  }

  // This should be batched with the other page queries avoiding an
  // extra request
  const { data } = useQuery<Query>(
    graphql`
      query useInactivityTimeoutQuery {
        viewer {
          domain {
            inactivityTimeoutSeconds
          }
        }
      }
    `,
    {
      environment,
      fetchPolicy: 'store-or-network',
      variables: {},
      skip: !enabled,
    },
  );

  const inactivitySeconds = data?.viewer?.domain?.inactivityTimeoutSeconds;

  const isActivityTimeoutActive = enabled && inactivitySeconds != null;

  useEffect(() => {
    if (!isActivityTimeoutActive) return undefined;

    store.set(STORE_KEY, lastActiveMsRef.current! + inactivitySeconds! * 1000);

    // For a number of interaction update the users "last active" time
    const handlers = ACTIVITY_EVENTS.map((event) =>
      listen(
        window as any,
        event,
        () => {
          lastActiveMsRef.current = Date.now();
        },
        { passive: true },
      ),
    );

    return () => {
      handlers.forEach((fn) => fn());
    };
  }, [isActivityTimeoutActive, inactivitySeconds]);

  const scheduleActivityCheckInterval = useEventCallback(() => {
    timeout.set(async () => {
      if (!isActivityTimeoutActive) {
        return;
      }

      const inactiveAtMs =
        lastActiveMsRef.current! + inactivitySeconds! * 1000;

      store.set(STORE_KEY, inactiveAtMs);

      // Subtract the timeout after warning to make the timeout
      // accurate for the user.
      if (Date.now() > inactiveAtMs - DIALOG_TIMEOUT_MS) {
        const continueSession = await openTimeoutDialog(dialog);

        if (!continueSession) {
          await onTimeout();
          return;
        }
      }

      scheduleActivityCheckInterval();
    }, SESSION_ACTIVITY_INTERVAL);
  });

  useEffect(() => {
    if (!isActivityTimeoutActive) return;

    scheduleActivityCheckInterval();
  }, [isActivityTimeoutActive, scheduleActivityCheckInterval]);
}

export default useInactivityTimeout;
