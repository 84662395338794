/**
 * @generated SignedSource<<84948468839000fadf6e049bcdc32808>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DefaultQaNotificationRecipients = "EVERYONE" | "NO_ONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ArchiveQaNotificationsForm_archive$data = {
  readonly defaultQaNotificationRecipients: DefaultQaNotificationRecipients | null;
  readonly id: string;
  readonly " $fragmentType": "ArchiveQaNotificationsForm_archive";
};
export type ArchiveQaNotificationsForm_archive$key = {
  readonly " $data"?: ArchiveQaNotificationsForm_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveQaNotificationsForm_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveQaNotificationsForm_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultQaNotificationRecipients",
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "ff502e18a56b704c1c640fee4d9c4f2b";

export default node;
