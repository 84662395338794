import Layout from '@4c/layout';
import Link from '@bfly/ui2/Link';
import Navbar from '@bfly/ui2/Navbar';
import Page from '@bfly/ui2/Page';
import useResponsive from '@bfly/ui2/useResponsive';
import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, useIntl } from 'react-intl';

import AppProductNav from 'components/AppProductNav';
import ErrorBoundary from 'components/ErrorBoundary';
import HelpDropdownButton from 'components/HelpDropdownButton';
import OutageMessage from 'components/OutageMessage';
import { RelayRouteRenderArgs } from 'components/Route';
import UserDropdownButton from 'components/UserDropdownButton';
import { useViewerAllowMissingContext } from 'utils/viewerContext';

import { isInApp } from '../../../utils/browserInfo';
import SidePanel from './ScanLabAppPageSidePanel';
import ScanLabProvider from './ScanLabProvider';

const messages = defineMessages({
  title: {
    id: 'scanlab.appHeaderTitle',
    defaultMessage: 'Butterfly Education',
  },
});

function Header() {
  const isSmall = useResponsive('sm', 'down');

  const inApp = isInApp();

  const { formatMessage } = useIntl();
  const butterflyEducation = formatMessage(messages.title);

  return (
    <Navbar>
      <Helmet
        defaultTitle={butterflyEducation}
        titleTemplate={`%s - ${butterflyEducation}`}
      />
      <Navbar.Brand as={Link} to="/-/scanlab" />
      <Layout.Spacer />
      {useViewerAllowMissingContext() && !inApp && !isSmall && (
        <>
          <AppProductNav />
          <Navbar.Divider />
        </>
      )}
      {!inApp && (
        <>
          <HelpDropdownButton />
          <UserDropdownButton />
        </>
      )}
    </Navbar>
  );
}

interface Props extends RelayRouteRenderArgs {
  canGoBack: boolean;
  children: React.ReactNode;
  center?: boolean;
}

export default function ScanLabAppPage({
  canGoBack: _,
  children,
  center,
  ...others
}: Props) {
  return (
    <ScanLabProvider {...others}>
      <Page layout="fill">
        <Header />
        <Page.Container center={center}>
          <SidePanel {...others} />
          <Page.Main>
            <ErrorBoundary
              fallbackComponent={<OutageMessage className="m-4" />}
            >
              {children}
            </ErrorBoundary>
          </Page.Main>
        </Page.Container>
      </Page>
    </ScanLabProvider>
  );
}
