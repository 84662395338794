import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Link from '@bfly/ui2/Link';
import MainContent from '@bfly/ui2/MainContent';
import { css } from 'astroturf';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { object, string } from 'yup';

import AuthContent from 'components/AuthContent';
import { useAuth } from 'components/AuthContext';
import AuthForm from 'components/AuthForm';
import useRedirectToPrevLocation from 'hooks/useRedirectToPrevLocation';
import { useConfigRoutes } from 'routes/config';

import { EulaPageMutation } from './__generated__/EulaPageMutation.graphql';
import { EulaPage_latestEula$data as Eula } from './__generated__/EulaPage_latestEula.graphql';
import { EulaPage_viewer$data as Viewer } from './__generated__/EulaPage_viewer.graphql';

const mutation = graphql`
  mutation EulaPageMutation($input: AcceptEulaInput!) {
    acceptEulaOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
      ... on AcceptEulaPayload {
        viewer {
          hasAcceptedLatestEula
        }
      }
    }
  }
`;

const eulaSchema = object({
  version: string(),
});

interface Props {
  viewer: Viewer;
  latestEula: Eula;
}

function EulaPage({ viewer, latestEula }: Props) {
  const configRoutes = useConfigRoutes();
  const auth = useAuth();

  const { hasAcceptedLatestEula } = viewer;
  const redirectToPrevLocation = useRedirectToPrevLocation();

  return (
    <MainContent centerText size="sm">
      <AuthContent.Title>
        <FormattedMessage
          defaultMessage="End-user license agreement"
          id="eula.title"
        />
      </AuthContent.Title>
      <div
        className="mb-3 scrollable-y text-left flex-1"
        css={css`
          max-height: 55vh;

          ul {
            list-style-type: disc;
            padding-left: 40px;
            margin-bottom: 8px;
          }

          ol {
            list-style-type: decimal;
            padding-left: 40px;
            margin-bottom: 8px;
          }
        `}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: latestEula.textHtml!,
        }}
      />
      <AuthForm<EulaPageMutation, typeof eulaSchema>
        mutation={mutation}
        schema={eulaSchema}
        getInput={() => ({ version: latestEula.version! })}
        onCompleted={redirectToPrevLocation}
      >
        {!hasAcceptedLatestEula && (
          <Form.Submit size="lg" className="mt-4">
            <FormattedMessage defaultMessage="Accept" id="eula.accept" />
          </Form.Submit>
        )}
      </AuthForm>
      {!hasAcceptedLatestEula && (
        <Link
          as={Button}
          variant="text-primary"
          onClick={() => auth.clearAccessToken(true)}
          to={configRoutes.rootRoute()}
        >
          <FormattedMessage
            defaultMessage="Decline and log out"
            id="eula.decline"
          />
        </Link>
      )}
    </MainContent>
  );
}

export default createFragmentContainer(EulaPage, {
  viewer: graphql`
    fragment EulaPage_viewer on Viewer {
      hasAcceptedLatestEula
    }
  `,
  latestEula: graphql`
    fragment EulaPage_latestEula on Eula {
      version
      textHtml
    }
  `,
});
