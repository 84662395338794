import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import MultiselectSelectButton, {
  type MultiselectSelectButtonProps,
} from 'components/MultiselectSelectButton';
import { renderUserSuggestion } from 'components/UserSuggestionListItem';
import useSearchQuery from 'hooks/useSearchQuery';

import {
  MemberSelectButton_MembersSearch_Query as Query,
  MemberSelectButton_MembersSearch_Query$data as QueryResponse,
} from './__generated__/MemberSelectButton_MembersSearch_Query.graphql';
import { MemberSelectButton_tenant$data as Tenant } from './__generated__/MemberSelectButton_tenant.graphql';

const SearchQuery = graphql`
  query MemberSelectButton_MembersSearch_Query(
    $tenantId: ID!
    $search: String
    $isDeleted: [Boolean!]!
  ) {
    tenant: node(id: $tenantId) {
      ... on Domain {
        membershipConnection(first: 30, search: $search)
          @connection(key: "MemberSelectButtonDomain_membershipConnection") {
          edges {
            node {
              userProfile {
                id
                name
                handle
              }
              email
              ...UserSuggestionListItem_userInfo
            }
          }
        }
      }
      ... on Organization {
        membershipConnection(
          first: 30
          search: $search
          isDeleted: $isDeleted
        )
          @connection(
            key: "MemberSelectButtonOrganization_membershipConnection"
          ) {
          edges {
            node {
              userProfile {
                id
                name
                handle
              }
              email
              ...UserSuggestionListItem_userInfo
            }
          }
        }
      }
    }
  }
`;

type Membership = ConnectionNodeType<
  NonNullable<QueryResponse['tenant']>,
  'membershipConnection'
>;

type SerializedAuthor = {
  id: string;
  name: string;
  handle: string;
  membership?: Membership;
};

interface Props
  extends Omit<MultiselectSelectButtonProps<SerializedAuthor>, 'data'> {
  tenant: Tenant & { handle: string };
  value: SerializedAuthor[];
  onChange: (item: SerializedAuthor[]) => void;
  includeDeleted?: boolean;
}

const authorTextField = (item: SerializedAuthor | null) => {
  return item?.name ?? '';
};

const renderAuthorListItem: MultiselectSelectButtonProps<SerializedAuthor>['renderListItem'] =
  ({ item, ...args }) =>
    renderUserSuggestion({ item: item.membership!, ...args });

function MemberSelectButton({
  tenant,
  value,
  onChange,
  placeholder,
  searchPlaceholder,
  includeDeleted,
  ...props
}: Props) {
  const [searchTerm, setSearchTerm] = useState('');

  const { data } = useSearchQuery<Query>(
    SearchQuery,
    searchTerm,
    useMemo(
      () => ({
        tenantId: tenant.id!,
        isDeleted: includeDeleted ? [true, false] : [false],
      }),
      [tenant.id, includeDeleted],
    ),
  );

  const userProfiles = data?.tenant?.membershipConnection
    ? getNodes(data?.tenant.membershipConnection).map((m) => ({
        id: m.userProfile!.id!,
        name: m.userProfile!.name!,
        handle: m.userProfile!.handle!,
        membership: m,
      }))
    : [];

  return (
    <MultiselectSelectButton<any>
      {...props}
      filter={false}
      updateOnClose
      value={value as any[]}
      dataKey="id"
      data={userProfiles}
      onChange={onChange}
      searchTerm={searchTerm}
      onSearch={setSearchTerm}
      busy={!!searchTerm && !data}
      textField={authorTextField}
      renderListItem={renderAuthorListItem}
      searchPlaceholder={
        searchPlaceholder ||
        defineMessage({
          id: 'memberSelectButton.searchPlaceholder',
          defaultMessage: 'Search by name or email…',
        })
      }
      placeholder={
        placeholder || (
          <FormattedMessage
            id="memberSelectButton.placeholder"
            defaultMessage="All authors"
          />
        )
      }
    />
  );
}

export default createFragmentContainer(MemberSelectButton, {
  tenant: graphql`
    fragment MemberSelectButton_tenant on TenantInterface {
      id
    }
  `,
});
