/**
 * @generated SignedSource<<a1b3ba3db4604bab89ab0a944edccb52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DefaultQaNotificationRecipients = "EVERYONE" | "NO_ONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExamQaNotificationControl_study$data = {
  readonly archive: {
    readonly defaultQaNotificationRecipients: DefaultQaNotificationRecipients | null;
    readonly id: string;
  } | null;
  readonly createdBy: {
    readonly id: string;
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"AvatarList_user">;
  } | null;
  readonly id: string;
  readonly qaNotificationRecipients: {
    readonly recipients: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"AvatarList_user">;
    } | null> | null;
    readonly submittedAt: string | null;
  } | null;
  readonly secondaryAuthors: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"AvatarList_user">;
  } | null> | null;
  readonly transcribedBy: {
    readonly id: string;
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"AvatarList_user">;
  } | null;
  readonly " $fragmentType": "ExamQaNotificationControl_study";
};
export type ExamQaNotificationControl_study$key = {
  readonly " $data"?: ExamQaNotificationControl_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamQaNotificationControl_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AvatarList_user"
},
v3 = [
  (v1/*: any*/),
  (v0/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamQaNotificationControl_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultQaNotificationRecipients",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QaNotificationRecipients",
      "kind": "LinkedField",
      "name": "qaNotificationRecipients",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "submittedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "recipients",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "secondaryAuthors",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "transcribedBy",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "21b0d9210a7c94bf3795988761f3b2e0";

export default node;
