/**
 * @generated SignedSource<<59c2f51a3c5232badcc7d72370664c10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseAppPage_organization$data = {
  readonly name: string | null;
  readonly subscription: {
    readonly hasCompletedTrial: boolean | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AppHeaderUserDropdownButton_organization" | "AppLeadingNav_organization" | "AppSearch_organization" | "NotificationCenter_organization" | "SubscriptionExpiredBanner_organization">;
  readonly " $fragmentType": "BaseAppPage_organization";
};
export type BaseAppPage_organization$key = {
  readonly " $data"?: BaseAppPage_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BaseAppPage_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasCompletedTrial",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppSearch_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppLeadingNav_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppHeaderUserDropdownButton_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationCenter_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubscriptionExpiredBanner_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "8ec1d610a4b403c01d0f2524b5cfe38d";

export default node;
