/**
 * @generated SignedSource<<fd8cbbf9e6fc715115b3c8898c13b7fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySearchPage_archive$data = {
  readonly label: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBar_archive" | "StudyListContent_archive">;
  readonly " $fragmentType": "StudySearchPage_archive";
};
export type StudySearchPage_archive$key = {
  readonly " $data"?: StudySearchPage_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySearchPage_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySearchPage_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBar_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_archive"
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "d9f2db90b09dab3b5cf209f9ba90498c";

export default node;
