/**
 * @generated SignedSource<<fc0aecccea0628f91ba0772e837b1330>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamStatusPerformanceIndicator_tenant$data = {
  readonly examStatusStudyStatistics: {
    readonly studyStatusStatistics: ReadonlyArray<{
      readonly key: string;
      readonly numStudies: number;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "ExamStatusPerformanceIndicator_tenant";
};
export type ExamStatusPerformanceIndicator_tenant$key = {
  readonly " $data"?: ExamStatusPerformanceIndicator_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamStatusPerformanceIndicator_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamStatusPerformanceIndicator_tenant",
  "selections": [
    {
      "alias": "examStatusStudyStatistics",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "StudyStatistics",
      "kind": "LinkedField",
      "name": "studyStatistics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyStatusStatistics",
          "kind": "LinkedField",
          "name": "studyStatusStatistics",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numStudies",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "c9272f2af74274fe5ac46282f4d8dec8";

export default node;
