import Text from '@bfly/ui2/Text';
import { FormattedDate, FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import ListOption, { ListOptionProps } from 'react-widgets/ListOption';

import { SavedSearchIcon } from 'utils/Search';

import { SearchBarListSavedSearchOption_studySavedSearch$data as StudySavedSearch } from './__generated__/SearchBarListSavedSearchOption_studySavedSearch.graphql';

const messages = defineMessages({
  createdOn: {
    defaultMessage: 'Created on {date}',
    description: 'Created on Date',
    id: 'createdOn',
  },
});

type Props = ListOptionProps<any> & {
  studySavedSearch: StudySavedSearch;
};

function SearchBarListSavedSearchOption({
  studySavedSearch,
  ...props
}: Props) {
  return (
    <ListOption
      {...props}
      className="flex items-center justify-center btn-toolbar h-10"
    >
      <SavedSearchIcon className="text-subtitle mr-1" />
      <span>{studySavedSearch.name}</span>
      <Text color="subtitle" className="ml-auto">
        <FormattedMessage
          {...messages.createdOn}
          values={{
            date: <FormattedDate value={studySavedSearch.createdAt!} />,
          }}
        />
      </Text>
    </ListOption>
  );
}

export default createFragmentContainer(SearchBarListSavedSearchOption, {
  studySavedSearch: graphql`
    fragment SearchBarListSavedSearchOption_studySavedSearch on StudySavedSearch {
      name
      createdAt
    }
  `,
});
