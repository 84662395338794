/**
 * @generated SignedSource<<0d405547f2b49b1b4ddffed36e3923ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarListSuggestionOption_worksheetTemplate$data = {
  readonly latestVersion: {
    readonly title: string | null;
  } | null;
  readonly " $fragmentType": "SearchBarListSuggestionOption_worksheetTemplate";
};
export type SearchBarListSuggestionOption_worksheetTemplate$key = {
  readonly " $data"?: SearchBarListSuggestionOption_worksheetTemplate$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_worksheetTemplate">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarListSuggestionOption_worksheetTemplate"
};

(node as any).hash = "ff5d7da74d2cebceb0c6ba7c9b5e67d1";

export default node;
