import { useCallback, useMemo } from 'react';

import usePreferences from 'hooks/usePreferences';

/**
 * version: 0.0
 * Columns are being saved under a preferenceKey without any distinction between statuses:
 * [ { patientName: true }, { dob: false }, { mrn: true } ]
 */
export type StudyDataColumnUserPreferenceV0 = Array<Record<string, boolean>>;

export enum StudyDataColumnUserPreferenceKey {
  /** Used for user preference on any study list. */
  STUDY_LIST = 'studyList',
  /** Used for user preference on search study list. */
  SEARCH_STUDY_LIST = 'searchStudyList',
}

/**
 * version: 1.0
 * Differentiation between study lists and search study list:
 * {
 *   studyList: [ { patientName: true }, { dob: false }, { mrn: true } ],
 *   searchStudyList: [ { dob: true }, { patientName: false }, { mrn: false } ],
 * }
 */
export interface StudyDataColumnUserPreferenceV1 {
  [StudyDataColumnUserPreferenceKey.STUDY_LIST]?: Array<
    Record<string, boolean>
  >;
  [StudyDataColumnUserPreferenceKey.SEARCH_STUDY_LIST]?: Array<
    Record<string, boolean>
  >;
  version?: string;
}

export type StudyDataColumnUserPreference = StudyDataColumnUserPreferenceV1;

export const STUDY_DATA_COLUMNS_CURRENT_VERSION = '1.0';

const useStudyDataColumnsUserPreference = (
  columnsKey: StudyDataColumnUserPreferenceKey,
) => {
  const [preferredColumns, setPreferredColumns] =
    usePreferences<StudyDataColumnUserPreference>('studyDataColumns');

  const setColumns = useCallback(
    (next: StudyDataColumnUserPreference) => {
      setPreferredColumns({
        ...next,
        version: STUDY_DATA_COLUMNS_CURRENT_VERSION,
      });
    },
    [setPreferredColumns],
  );

  const columns = useMemo(() => {
    return !preferredColumns || Array.isArray(preferredColumns)
      ? // new or invalid
        { version: STUDY_DATA_COLUMNS_CURRENT_VERSION, [columnsKey]: [] }
      : // existing
        {
          ...preferredColumns,
          version: STUDY_DATA_COLUMNS_CURRENT_VERSION,
          [columnsKey]: Array.isArray(preferredColumns?.[columnsKey])
            ? preferredColumns?.[columnsKey]
            : [],
        };
  }, [columnsKey, preferredColumns]);

  return [columns, setColumns] as const;
};

export default useStudyDataColumnsUserPreference;
