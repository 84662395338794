import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'tnc.title',
    defaultMessage: 'Terms And Conditions',
  },
  accept: {
    id: 'tnc.accept',
    defaultMessage: 'Accept',
  },
  decline: {
    id: 'tnc.decline',
    defaultMessage: 'Decline and log out',
  },
});
