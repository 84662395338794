/**
 * @generated SignedSource<<2f2e00ce94fd2d15de717cf2b50ce2ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type account_InactiveAccountPage_Query$variables = {};
export type account_InactiveAccountPage_Query$data = {
  readonly viewer: {
    readonly domain: {
      readonly __typename: "Domain";
    } | null;
    readonly memberships: ReadonlyArray<{
      readonly id: string;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"InactiveAccountPage_viewer">;
  } | null;
};
export type account_InactiveAccountPage_Query = {
  response: account_InactiveAccountPage_Query$data;
  variables: account_InactiveAccountPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerMembership",
  "kind": "LinkedField",
  "name": "memberships",
  "plural": true,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "account_InactiveAccountPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InactiveAccountPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "account_InactiveAccountPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9040b06fce49f4053f0db615e39159ad",
    "id": null,
    "metadata": {},
    "name": "account_InactiveAccountPage_Query",
    "operationKind": "query",
    "text": "query account_InactiveAccountPage_Query {\n  viewer {\n    domain {\n      __typename\n      id\n    }\n    memberships {\n      id\n    }\n    ...InactiveAccountPage_viewer\n    id\n  }\n}\n\nfragment InactiveAccountPage_viewer on Viewer {\n  domain {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7a75ebd10a9c8b4dc541bb4d3bcdc77";

export default node;
