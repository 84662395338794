/**
 * @generated SignedSource<<c46c773c12e08ca5de4bfc6cab28abbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateMembershipRoleInput = {
  clientMutationId?: string | null;
  description?: string | null;
  membershipRoleId: any;
  name?: string | null;
  permissions?: any | null;
};
export type UpdateDomainRolePage_UpdateRoleMutation$variables = {
  input: UpdateMembershipRoleInput;
};
export type UpdateDomainRolePage_UpdateRoleMutation$data = {
  readonly updateMembershipRoleOrError: {
    readonly __typename?: string;
    readonly fields?: ReadonlyArray<{
      readonly message: string;
      readonly path: ReadonlyArray<string>;
    }>;
    readonly membershipRole?: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateDomainRolePage_membershipRole">;
    } | null;
    readonly message?: string | null;
  } | null;
};
export type UpdateDomainRolePage_UpdateRoleMutation = {
  response: UpdateDomainRolePage_UpdateRoleMutation$data;
  variables: UpdateDomainRolePage_UpdateRoleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvalidInputErrorField",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvalidInputError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDomainRolePage_UpdateRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateMembershipRoleOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipRole",
                "kind": "LinkedField",
                "name": "membershipRole",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UpdateDomainRolePage_membershipRole"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateMembershipRolePayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDomainRolePage_UpdateRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateMembershipRoleOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipRole",
                "kind": "LinkedField",
                "name": "membershipRole",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "permissions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateMembershipRolePayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "294d96370a0936dc78d79630bac44812",
    "id": null,
    "metadata": {},
    "name": "UpdateDomainRolePage_UpdateRoleMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDomainRolePage_UpdateRoleMutation(\n  $input: UpdateMembershipRoleInput!\n) {\n  updateMembershipRoleOrError(input: $input) {\n    __typename\n    ... on UpdateMembershipRolePayload {\n      membershipRole {\n        ...UpdateDomainRolePage_membershipRole\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      ... on InvalidInputError {\n        fields {\n          message\n          path\n        }\n      }\n      ... on MaxSeatsExceededError {\n        message\n      }\n      __typename\n      message\n    }\n  }\n}\n\nfragment DomainRoleDeleteFieldSet_membershipRole on MembershipRole {\n  id\n  name\n}\n\nfragment UpdateDomainRolePage_membershipRole on MembershipRole {\n  ...roles_defaultValue\n  ...DomainRoleDeleteFieldSet_membershipRole\n  roleType\n}\n\nfragment roles_defaultValue on MembershipRole {\n  id\n  name\n  description\n  permissions\n}\n"
  }
};
})();

(node as any).hash = "a681c86a0cd38c403c253d048f96d0a2";

export default node;
