/**
 * @generated SignedSource<<4b301ffa0af60746b4d8533b16a8e767>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSuggestionListItem_userInfo$data = {
  readonly email: string | null;
  readonly userProfile: {
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"UserSuggestion_userProfile">;
  } | null;
  readonly " $fragmentType": "UserSuggestionListItem_userInfo";
};
export type UserSuggestionListItem_userInfo$key = {
  readonly " $data"?: UserSuggestionListItem_userInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSuggestionListItem_userInfo">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "UserSuggestionListItem_userInfo"
};

(node as any).hash = "64032095a57706f8e5f8fdc9586b05d2";

export default node;
