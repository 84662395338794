import { useState } from 'react';

export default function useToggler(
  defaultValue = false,
): [boolean, (newValue?: boolean) => void] {
  const [value, setValue] = useState(defaultValue);

  return [
    value,
    (newValue?: boolean) => {
      setValue((prevShow) =>
        typeof newValue === 'undefined' ? !prevShow : newValue,
      );
    },
  ];
}
