/**
 * @generated SignedSource<<e6dab0be30807dc29b247358a04e50c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorksheetSection_section$data = {
  readonly fieldDefinitions: ReadonlyArray<{
    readonly __typename: string;
  } | null> | null;
  readonly title: string | null;
  readonly " $fragmentType": "WorksheetSection_section";
};
export type WorksheetSection_section$key = {
  readonly " $data"?: WorksheetSection_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetSection_section">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorksheetSection_section",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "fieldDefinitions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorksheetSectionDefinition",
  "abstractKey": null
};

(node as any).hash = "8ccae67425196767268f0af07cb89848";

export default node;
