import TrashCanIcon from '@bfly/icons/TrashCan';
import Link from '@bfly/ui2/Link';
import { SideMenuLink } from '@bfly/ui2/SideMenu';
import { LocationDescriptor } from 'farce';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useDeleteStudies from 'hooks/useDeleteStudies';

import StudiesDropTarget from './StudiesDropTarget';
import { DeletedStudySection_tenant$data as Tenant } from './__generated__/DeletedStudySection_tenant.graphql';

interface Props {
  tenant: Tenant;
  className?: string;
  to: string | LocationDescriptor;
}

function DeletedStudySection({ tenant, className, to }: Props) {
  const [deleteStudies] = useDeleteStudies();

  const onStudiesDropped = async (
    studyAndArchiveIds: Array<{ studyId: string; archiveId: string }>,
  ) => {
    await deleteStudies(studyAndArchiveIds, tenant.id, '');
  };

  return (
    <StudiesDropTarget
      data-bni-id="DeletedStudySection"
      onStudiesDropped={onStudiesDropped}
      dataKey="study-items-can-delete"
    >
      <div className={className}>
        <Link to={to}>
          {(linkProps) => (
            <SideMenuLink
              data-bni-id="DeletedStudies"
              {...linkProps}
              icon={<TrashCanIcon />}
              className="py-4 h-auto border-t border-grey-85"
            >
              <FormattedMessage
                id="organizationSidePanelItem.deletedStudies.new"
                defaultMessage="Deleted"
              />
            </SideMenuLink>
          )}
        </Link>
      </div>
    </StudiesDropTarget>
  );
}

export default createFragmentContainer(DeletedStudySection, {
  tenant: graphql`
    fragment DeletedStudySection_tenant on TenantInterface {
      id
    }
  `,
});
