import GearIcon from '@bfly/icons/Gear';
import Button from '@bfly/ui2/Button';
import { createPortal } from 'react-dom';

import useHotKey from 'hooks/useHotKey';
import useModalState from 'hooks/useModalState';
import podTargetingHeaders from 'utils/podTargetingHeaders';

import {
  featureFlagOverridesStore,
  featureFlagsStore,
} from '../../utils/LaunchDarklyManager';
import tokenStorage from '../../utils/tokenStorage';
import DeveloperControlsModal from './Modal';
import { DevContextProvider } from './devContext';

const useFeatureFlagOverridesState = featureFlagOverridesStore.useState;
const usePodTargetingHeadersState = podTargetingHeaders.useState;
const useFeatureFlagsState = featureFlagsStore.useState;
const useTokenStorageState = tokenStorage.useState;

function ActiveOverridesShortcut({
  show,
  showModal,
}: {
  show: boolean;
  showModal: () => void;
}) {
  if (!show) return null;

  return (
    <>
      {createPortal(
        <Button
          variant={null}
          onClick={showModal}
          className="z-popover fixed rounded-full bg-grey-75 bottom-5 right-5 p-2"
        >
          <GearIcon />
        </Button>,
        document.body,
      )}
    </>
  );
}

function DeveloperControls() {
  const [showModal, modalProps] = useModalState(false);

  useHotKey(showModal, '`', 'ctrlKey');

  const featureFlagOverrides = useFeatureFlagOverridesState();
  const headerOverrides = usePodTargetingHeadersState();
  const featureFlags = useFeatureFlagsState();
  const accessToken = useTokenStorageState()?.accessToken || null;

  const showShortcut = Boolean(
    (featureFlagOverrides && Object.keys(featureFlagOverrides).length) ||
      (headerOverrides && Object.keys(headerOverrides).length),
  );

  return (
    <DevContextProvider>
      <ActiveOverridesShortcut show={showShortcut} showModal={showModal} />
      <DeveloperControlsModal
        {...modalProps}
        featureFlags={featureFlags}
        featureFlagOverrides={featureFlagOverrides}
        headerOverrides={headerOverrides}
        accessToken={accessToken}
      />
    </DevContextProvider>
  );
}

export default DeveloperControls;
