import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useConfigRoutes } from 'routes/config';

import AlternativeLoginSection from './AlternativeLoginSection';
import AuthContent from './AuthContent';
import BlankAppPage from './BlankAppPage';
import EnterpriseLoginForm from './EnterpriseLoginForm';

function EnterpriseLoginPage() {
  const configRoutes = useConfigRoutes();
  const { match } = useRouter();
  const { formatMessage } = useIntl();
  const lmsForceLogin = match.location.query?.lms === 'forceLogin';
  const showAlternativeLoginSection = !lmsForceLogin;

  return (
    <BlankAppPage>
      <AuthContent>
        <AuthContent.Title>
          <FormattedMessage
            id="enterpriseLoginPage.title"
            defaultMessage="Log In to Butterfly Network"
          />
        </AuthContent.Title>
        <EnterpriseLoginForm />
        {showAlternativeLoginSection && (
          <AlternativeLoginSection
            buttonText={formatMessage({
              id: 'enterpriseLoginPage.backButton',
              defaultMessage: 'Log In as Non-Enterprise User',
            })}
            to={{
              pathname:
                match.location.state?.prevLocation?.pathname ||
                configRoutes.rootRoute(),
              state: {
                prevLocation: match.location,
              },
            }}
          />
        )}
      </AuthContent>
    </BlankAppPage>
  );
}

export default EnterpriseLoginPage;
