import { defineMessages } from 'react-intl';
import { fetchQuery } from 'relay-runtime';
import * as yup from 'yup';
import type { RemoteArgs, RemoteContext } from 'yup';
import { ValidateOptions } from 'yup/lib/types';

import { isValidDate, toIsoDateString } from 'utils/DateSerialization';

const emailErrors = defineMessages({
  missingAt: {
    id: 'validation.string.missingAt',
    defaultMessage:
      'Email is invalid. Please include an "@" sign in the email address.',
  },
  default: {
    id: 'validation.string.email',
    defaultMessage: 'Email is invalid',
  },
});

yup.addMethod(yup.string, 'email', function $email(msg = emailErrors.default) {
  return this.test('email', msg, function checkEmail(value) {
    if (!value) return true;
    if (!value.includes('@'))
      return this.createError({ message: emailErrors.missingAt as any });

    // This is a minimal test to ensure something is probably an email.
    // Since we verify the email with the user later this is prefered.
    return (
      !value.startsWith('@') &&
      !value.endsWith('@') &&
      !!value.match(/@((?!,).)*$/)
    );
  });
});

yup.addMethod(yup.string, 'isoDate', function $isoDate() {
  return this.transform((value, originalValue) =>
    isValidDate(originalValue) ? toIsoDateString(originalValue) : value,
  );
});

yup.addMethod(yup.string, 'isoDateTime', function $isoDate() {
  return this.transform((value, originalValue) =>
    isValidDate(originalValue) ? originalValue.toISOString() : value,
  );
});

yup.addMethod(
  yup.mixed,
  'enum',
  function $enum(values, defaultValue = values[0]) {
    return this.oneOf(values).default(defaultValue);
  },
);

yup.addMethod(
  yup.mixed,
  'remote',
  function remote({
    query,
    getVariables,
    getResult,
    localValidate,
    message,
  }: RemoteArgs<any>) {
    return this.test({
      message,
      name: 'remote',
      exclusive: false,
      async test(value: unknown) {
        const { context } = this.options as ValidateOptions<RemoteContext>;
        if (localValidate) {
          const result = localValidate.call(this, value, this);
          if (result != null) return result;
        }

        const variables = getVariables(value, this.parent, context);
        const data = await fetchQuery(
          context!.relay.environment,
          query,
          variables,
        ).toPromise();

        return getResult.call(this, data!, this);
      },
    });
  },
);

yup.addMethod(yup.array, 'split', function $split(splitOnChar = ',') {
  return this.transform((value, inputValue, ctx) => {
    if (value && ctx.isType(value)) return value;
    return typeof inputValue === 'string'
      ? inputValue.split(splitOnChar)
      : value;
  });
});

const mixed = defineMessages({
  required: {
    id: 'validation.required',
    defaultMessage: 'Required',
  },
  // In 99% of cases the type error is due to a null value in a non-nullable
  // field.
  notType: {
    id: 'validation.notType',
    defaultMessage: 'Required',
  },
  defined: {
    id: 'validation.defined',
    defaultMessage: 'Required',
  },
});

const string = defineMessages({
  min: {
    id: 'validation.string.min',
    defaultMessage:
      'This must be at least {min} {min, plural, one {character} other {characters}} long',
  },
  max: {
    id: 'validation.string.max',
    defaultMessage:
      'This must be at most {max} {max, plural, one {character} other {characters}} long',
  },
});

const array = defineMessages({
  min: {
    id: 'validation.array.min',
    defaultMessage:
      'This must have at least {min} {min, plural, one {item} other {items}}',
  },
  max: {
    id: 'validation.array.max',
    defaultMessage:
      'This must have at most {max} {max, plural, one {item} other {items}}',
  },
});

const number = defineMessages({
  min: {
    id: 'validation.number.min',
    defaultMessage: 'This must be at least {min}',
  },
  max: {
    id: 'validation.number.max',
    defaultMessage: 'This must be at most {max}',
  },
  positive: {
    id: 'validation.number.positive',
    defaultMessage: 'This must be a positive number',
  },
  negative: {
    id: 'validation.number.negative',
    defaultMessage: 'This must be a negative number',
  },
  integer: {
    id: 'validation.number.integer',
    defaultMessage: 'Please enter an integer',
  },
});

yup.setLocale({
  number: number as any,
  string: string as any,
  array: array as any,
  mixed: mixed as any,
});
