/**
 * @generated SignedSource<<f8db91a8e359cab8a92ef892480da4fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canDeleteFinalizedStudy$data = {
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly finalizedAt: string | null;
  readonly id: string;
  readonly " $fragmentType": "StudyPermissions_canDeleteFinalizedStudy";
};
export type StudyPermissions_canDeleteFinalizedStudy$key = {
  readonly " $data"?: StudyPermissions_canDeleteFinalizedStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canDeleteFinalizedStudy">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canDeleteFinalizedStudy"
};

(node as any).hash = "8d37e7eab225f19e268012777a214345";

export default node;
