export default function hasParentThatMatches(
  element: Element,
  ...selector: string[]
) {
  return (
    element &&
    (selector.some((s) => element?.matches?.(s)) ||
      hasParentThatMatches(element.parentNode as HTMLElement, ...selector))
  );
}
