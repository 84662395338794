import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import React from 'react';
import { defineMessage } from 'react-intl';

import PageTitle from 'components/PageTitle';

export interface Props {
  children?: React.ReactNode;
}

export default function DomainStudyTagPage({ children }: Props) {
  return (
    <>
      <Page.Header>
        <PageTitle
          title={defineMessage({
            id: 'domain.settings.studyTags.pageTitle',
            defaultMessage: 'Tags Management',
          })}
        />
      </Page.Header>
      <MainContent>{children}</MainContent>
    </>
  );
}
