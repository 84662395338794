import ViewLogIcon from '@bfly/icons/ViewLog';
import Dropdown from '@bfly/ui2/Dropdown';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useModalState from 'hooks/useModalState';

import StudyHistoryModal from './StudyHistoryModal';
import { StudyHistoryDropdownItem_study$data as Study } from './__generated__/StudyHistoryDropdownItem_study.graphql';

function StudyHistoryDropdownItem({ study }: { study: Study }) {
  const [showModal, modalProps] = useModalState();

  return (
    <>
      <Dropdown.Item
        icon={<ViewLogIcon />}
        onClick={showModal}
        data-bni-id="StudyHistoryDropdownItem"
      >
        <FormattedMessage id="study.history.button" defaultMessage="History" />
      </Dropdown.Item>
      <StudyHistoryModal study={study} {...modalProps} />
    </>
  );
}

export default createFragmentContainer(StudyHistoryDropdownItem, {
  study: graphql`
    fragment StudyHistoryDropdownItem_study on Study {
      ...StudyHistoryModal_study
    }
  `,
});
