/**
 * @generated SignedSource<<5d03bae311fae69a46c7ff0b3e3fdf27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MemberCounts_Query$variables = {
  id: string;
};
export type MemberCounts_Query$data = {
  readonly domain: {
    readonly id: string;
    readonly settings?: {
      readonly maxNumSeats: number | null;
      readonly numAvailableSeats: number | null;
      readonly numUsers: number | null;
    } | null;
  } | null;
};
export type MemberCounts_Query = {
  response: MemberCounts_Query$data;
  variables: MemberCounts_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainSettings",
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUsers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxNumSeats",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numAvailableSeats",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberCounts_Query",
    "selections": [
      {
        "alias": "domain",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MemberCounts_Query",
    "selections": [
      {
        "alias": "domain",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f04a7a5e25e8c77905f5dc225771ad7f",
    "id": null,
    "metadata": {},
    "name": "MemberCounts_Query",
    "operationKind": "query",
    "text": "query MemberCounts_Query(\n  $id: ID!\n) {\n  domain: node(id: $id) {\n    __typename\n    id\n    ... on Domain {\n      settings {\n        numUsers\n        maxNumSeats\n        numAvailableSeats\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b4a63c715c4fd2c3ea771f3c2e446230";

export default node;
