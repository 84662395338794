/**
 * @generated SignedSource<<999f878986c5565d613526f472491c9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EducationContentType = "COURSE" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShareEducationContentModal_educationContent$data = {
  readonly contentType: EducationContentType | null;
  readonly handle: string | null;
  readonly isPremium: boolean | null;
  readonly localId: string | null;
  readonly viewerHasAccess: boolean | null;
  readonly " $fragmentType": "ShareEducationContentModal_educationContent";
};
export type ShareEducationContentModal_educationContent$key = {
  readonly " $data"?: ShareEducationContentModal_educationContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShareEducationContentModal_educationContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShareEducationContentModal_educationContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPremium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasAccess",
      "storageKey": null
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};

(node as any).hash = "f1400b170c62aeda86c0a0f34baade97";

export default node;
