import { defineMessages } from 'react-intl';

export default defineMessages({
  pacsForwarding: {
    id: 'archiveMessages.pacsForwarding',
    defaultMessage: 'PACS forwarding',
  },
  ehrForwarding: {
    id: 'archiveMessages.ehrForwarding',
    defaultMessage: 'EHR forwarding',
  },
});
