import captureException from '@bfly/utils/captureException';
import HttpError from 'found/HttpError';
import RedirectException from 'found/RedirectException';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import { marketingWebsiteUrl } from 'config/app';
import routes from 'routes/auth';
import ExternalRedirect from 'utils/ExternalRedirect';

import ecommerceExternalRedirectRoute from '../ecommerceExternalRedirect';
import FederatedAuthCallbackPage from './components/FederatedAuthCallbackPage';
import FederatedAuthLogoutCallbackPage from './components/FederatedAuthLogoutCallbackPage';
import OAuthAuthorizePage from './components/OAuthAuthorizePage';
import OAuthLogoutPage from './components/OAuthLogoutPage';
import ZendeskSsoPage from './components/ZendeskSsoPage';

export default (
  <Route mobileFriendly>
    <Route
      allowLoginFromHash
      path="sso/zendesk"
      Component={ZendeskSsoPage}
      query={graphql`
        query auth_ZendeskSsoPage_Query {
          viewer {
            ...ZendeskSsoPage_viewer
          }
        }
      `}
    />
    <Route
      allowPublic
      path="sso/zendesk-logout"
      getQuery={({ location }) => {
        switch (location.query.kind) {
          case 'info':
            ExternalRedirect.redirect(marketingWebsiteUrl);
            throw new HttpError('loading' as any);
          case 'error':
            captureException(
              new Error('failed to log into Zendesk'),
              location.query,
            );
            // Leave the error page, as it's an event we want to log, rather
            //  than a state.
            throw new RedirectException({
              pathname: routes.ssoZendesk(),
              state: { foundHttpError: 400 },
            });
          default:
            captureException(
              new Error('unrecognized Zendesk logout'),
              location.query,
            );
            // I dunno, might as well stay on the cloud for now.
            throw new HttpError(400);
        }
      }}
    />
    <Route
      allowPublic
      path="federated-auth/callback"
      Component={FederatedAuthCallbackPage}
    />
    <Route
      allowPublic
      path="federated-auth/logout"
      Component={FederatedAuthLogoutCallbackPage}
    />
    <Route path="authorize" Component={OAuthAuthorizePage} />
    <Route allowPublic path="logout" Component={OAuthLogoutPage} />
    {ecommerceExternalRedirectRoute}
  </Route>
);
