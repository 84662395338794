import Layout from '@4c/layout';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from 'components/Avatar';

import { CommentAuthor_comment$data as Comment } from './__generated__/CommentAuthor_comment.graphql';

interface Props {
  comment: Comment;
}

function CommentAuthor({ comment }: Props) {
  return (
    <Layout pad align="center">
      <Avatar userProfile={comment.createdBy!} width={24} />
      <Text variant="body-bold" color="headline">
        {comment.createdBy!.name}
      </Text>
    </Layout>
  );
}

export default createFragmentContainer(CommentAuthor, {
  comment: graphql`
    fragment CommentAuthor_comment on StudyImageComment {
      createdBy {
        ...Avatar_userProfile
        name
      }
    }
  `,
});
