/**
 * @generated SignedSource<<5914413401817aad1b41a4ce1c0c3583>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BulkUnEnrollUsersFromCoursesInput = {
  clientMutationId?: string | null;
  enrollments: ReadonlyArray<EnrollmentInput | null>;
  organizationId?: ReadonlyArray<string> | null;
};
export type EnrollmentInput = {
  courseId: string;
  userId: string;
};
export type CourseOverviewPageContentMutation$variables = {
  input: BulkUnEnrollUsersFromCoursesInput;
};
export type CourseOverviewPageContentMutation$data = {
  readonly bulkUnEnrollUsersFromCoursesOrError: {
    readonly __typename?: string;
    readonly courseStatistics?: ReadonlyArray<{
      readonly averageProgress: number | null;
      readonly count: number | null;
      readonly id: string;
    }>;
    readonly message?: string | null;
    readonly unEnrollFailures?: ReadonlyArray<{
      readonly code: string | null;
      readonly courseId: string | null;
      readonly detail: string | null;
      readonly userId: string | null;
    }>;
  } | null;
};
export type CourseOverviewPageContentMutation = {
  response: CourseOverviewPageContentMutation$data;
  variables: CourseOverviewPageContentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BulkEnrollUsersFailure",
      "kind": "LinkedField",
      "name": "unEnrollFailures",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "detail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LmsCourseStatistic",
      "kind": "LinkedField",
      "name": "courseStatistics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "averageProgress",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BulkUnEnrollUsersFromCoursesPayload",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseOverviewPageContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "bulkUnEnrollUsersFromCoursesOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseOverviewPageContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "bulkUnEnrollUsersFromCoursesOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9dcc2e4943e9d3e1d9b86e56a20c22c1",
    "id": null,
    "metadata": {},
    "name": "CourseOverviewPageContentMutation",
    "operationKind": "mutation",
    "text": "mutation CourseOverviewPageContentMutation(\n  $input: BulkUnEnrollUsersFromCoursesInput!\n) {\n  bulkUnEnrollUsersFromCoursesOrError(input: $input) {\n    __typename\n    ... on BulkUnEnrollUsersFromCoursesPayload {\n      unEnrollFailures {\n        code\n        detail\n        userId\n        courseId\n      }\n      courseStatistics {\n        id\n        count\n        averageProgress\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "88163839a4512fc57b90976ee91a8ee0";

export default node;
