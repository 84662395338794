import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';

import usePagedConnection from 'hooks/usePagedConnection';
import { STUDY_GRID_PAGE_SIZE } from 'utils/StudyConstants';

import PagedArchivePage from './PagedArchivePage';
import { PagedArchivePageQuery } from './__generated__/PagedArchivePageQuery.graphql';
import { StoragePagedArchivePage_archive$data as Archive } from './__generated__/StoragePagedArchivePage_archive.graphql';
import { StoragePagedArchivePage_studies$key as ArchiveKey } from './__generated__/StoragePagedArchivePage_studies.graphql';
import { StoragePagedArchivePage_viewer$data as Viewer } from './__generated__/StoragePagedArchivePage_viewer.graphql';

interface Props {
  archive: Archive;
  loading?: boolean;
  viewer: Viewer;
}

const StoragePagedArchivePage = ({ archive, loading, viewer }: Props) => {
  const [studies, paginationMeta] = usePagedConnection<
    PagedArchivePageQuery,
    ArchiveKey,
    'studyConnection'
  >(
    graphql`
      fragment StoragePagedArchivePage_studies on Archive
      @refetchable(queryName: "PagedArchivePageQuery") {
        studyConnection(
          includePageInfo: true
          first: $first
          after: $after
          last: $last
          before: $before

          sort: $sort
          examTypeHandles: $examTypes
          author: $author
          isDraft: $isDraft
          needsReview: $needsReview
          hasRequestedFinalization: $hasRequestedFinalization
          hasRequestedFinalizationFromViewer: $hasRequestedFinalizationFromViewer
          readyAtMin: $readyAtMin
          readyAtMax: $readyAtMax
          capturedAtMin: $capturedAtMin
          capturedAtMax: $capturedAtMax
          workflowStatus: $workflowStatus
        ) @connection(key: "Archive_studyConnection") {
          index
          numEdges
          edges {
            ...StudyListContent_studies
              @arguments(listEnabled: true, lazyLoadImages: true)
            node {
              id
            }
          }
        }
      }
    `,
    archive,
    {
      pageSize: STUDY_GRID_PAGE_SIZE,
      overfetchNumPages: 0,
    },
  );
  return (
    <PagedArchivePage
      archive={archive}
      viewer={viewer}
      loading={loading}
      paginationMeta={paginationMeta}
      studies={studies}
    />
  );
};

export default createFragmentContainer(StoragePagedArchivePage, {
  archive: graphql`
    fragment StoragePagedArchivePage_archive on Archive {
      ...PagedArchivePage_archive
      ...StoragePagedArchivePage_studies
    }
  `,
  viewer: graphql`
    fragment StoragePagedArchivePage_viewer on Viewer {
      ...PagedArchivePage_viewer
    }
  `,
});
