import useGlobalListener from '@restart/hooks/useGlobalListener';
import { useState } from 'react';

export default function useKeyboardNavListener() {
  const [isNavigatingViaKeyboard, setIsNavigatingViaKeyboard] =
    useState(false);
  useGlobalListener('keydown', (e) => {
    if (e.key !== 'Tab') return;
    setIsNavigatingViaKeyboard(true);
  });

  // TODO: use pointerdown once we polyfill it
  useGlobalListener('mousedown', () => {
    setIsNavigatingViaKeyboard(false);
  });

  return isNavigatingViaKeyboard;
}
