/**
 * @generated SignedSource<<dc0ea39add513ddfdb48bd19b6d7df5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyAttribution_study$data = {
  readonly createdBy: {
    readonly handle: string | null;
    readonly id: string;
  } | null;
  readonly finalizedAt: string | null;
  readonly organization: {
    readonly slug: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyAuthors_study">;
  readonly " $fragmentType": "StudyAttribution_study";
};
export type StudyAttribution_study$key = {
  readonly " $data"?: StudyAttribution_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyAttribution_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyAttribution_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyAuthors_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "058b26bf26c389de1b855543f9d779fd";

export default node;
