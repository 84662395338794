import Dropdown from '@bfly/ui2/Dropdown';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useModalState from 'hooks/useModalState';

import AboutModal from './AboutModal';

function AboutControl() {
  const [showModal, modalProps] = useModalState();

  return (
    <>
      <Dropdown.Item onClick={showModal} data-bni-id="HelpAboutItem">
        <FormattedMessage id="about" defaultMessage="About" />
      </Dropdown.Item>
      <AboutModal {...modalProps} />
    </>
  );
}

export default AboutControl;
