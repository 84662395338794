/**
 * @generated SignedSource<<533cee85e5422f1649d0c708cd559931>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DomainMemberDetailFieldSet_domainUser$data = {
  readonly canFinalize: boolean | null;
  readonly canQa: boolean | null;
  readonly email: string | null;
  readonly id: string;
  readonly integrationDisplayNameFirst: string | null;
  readonly integrationDisplayNameLast: string | null;
  readonly integrationDisplayNameMiddle: string | null;
  readonly isNurse: boolean | null;
  readonly type: DomainUserType | null;
  readonly " $fragmentType": "DomainMemberDetailFieldSet_domainUser";
};
export type DomainMemberDetailFieldSet_domainUser$key = {
  readonly " $data"?: DomainMemberDetailFieldSet_domainUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMemberDetailFieldSet_domainUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainMemberDetailFieldSet_domainUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNurse",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameFirst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameMiddle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameLast",
      "storageKey": null
    }
  ],
  "type": "DomainUser",
  "abstractKey": null
};

(node as any).hash = "bae07a9f4db724a1b06347075c13823c";

export default node;
