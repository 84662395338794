/**
 * @generated SignedSource<<8083c7d50a59b2ea4313d93016795391>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainOrganizationsPage_viewer$data = {
  readonly domain: {
    readonly organizationConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: "Organization";
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "DomainOrganizationsPage_viewer";
};
export type DomainOrganizationsPage_viewer$key = {
  readonly " $data"?: DomainOrganizationsPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainOrganizationsPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainOrganizationsPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationConnection",
          "kind": "LinkedField",
          "name": "organizationConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "d4e185bbbe3a378f2700c84063a0b162";

export default node;
