/**
 * @generated SignedSource<<ee6103f563c22ffb68f1e5c192594d61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamPageSidebarWorksheets_study$data = {
  readonly archive: {
    readonly deletedAt: string | null;
    readonly multipleWorksheetsEnabled: boolean | null;
    readonly worksheetRequiredForQa: boolean | null;
  } | null;
  readonly deletedAt: string | null;
  readonly finalizedAt: string | null;
  readonly id: string;
  readonly imageConnection?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly interpretations: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"BLineSummaryResults_interpretations">;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  readonly notes: string | null;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly worksheets: ReadonlyArray<{
    readonly handle: string | null;
    readonly id: string;
    readonly templateVersion: {
      readonly title: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"WorksheetAutosaveForm_worksheet" | "WorksheetDisplay_worksheet">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetAndPrefillSelect_study" | "examPageSidebar_useSections_study">;
  readonly " $fragmentType": "ExamPageSidebarWorksheets_study";
};
export type ExamPageSidebarWorksheets_study$key = {
  readonly " $data"?: ExamPageSidebarWorksheets_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageSidebarWorksheets_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalizedAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worksheetRequiredForQa",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numWorksheets",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Archive",
  "kind": "LinkedField",
  "name": "archive",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isPACEExam"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPageSidebarWorksheets_study",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "multipleWorksheetsEnabled",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksheet",
      "kind": "LinkedField",
      "name": "worksheets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksheetTemplateVersion",
          "kind": "LinkedField",
          "name": "templateVersion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorksheetAutosaveForm_worksheet"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorksheetDisplay_worksheet"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "condition": "isPACEExam",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyImageConnection",
          "kind": "LinkedField",
          "name": "imageConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyImageEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StudyImage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "interpretationType",
                          "value": "PACE_BLINE"
                        }
                      ],
                      "concreteType": "Interpretation",
                      "kind": "LinkedField",
                      "name": "interpretations",
                      "plural": true,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "BLineSummaryResults_interpretations"
                        }
                      ],
                      "storageKey": "interpretations(interpretationType:\"PACE_BLINE\")"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorksheetAndPrefillSelect_study"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "examPageSidebar_useSections_study",
      "selections": [
        (v1/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "examPageSidebar_useShowQa_study",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "viewerCanQa",
              "storageKey": null
            },
            (v1/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "StudyPermissions_studyOrArchiveIsDeleted",
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Archive",
                  "kind": "LinkedField",
                  "name": "archive",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "StudyPermissions_studyReviewStatus",
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "numQaEntries",
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StandardQa",
                  "kind": "LinkedField",
                  "name": "standardQa",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserProfile",
                      "kind": "LinkedField",
                      "name": "createdBy",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "f2593359e46a6490c741f3ef2259f23d";

export default node;
