import BlankSlate from '@bfly/ui2/BlankSlate';
import getNodes from '@bfly/utils/getNodes';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import { RoutePageProps } from 'components/Route';

import educationMessages from '../messages/educationMessages';
import EducationContentTile from './EducationContentTile';
import ShareEducationContentModal from './ShareEducationContentModal';
import type { SavedContentList_lms$data as Lms } from './__generated__/SavedContentList_lms.graphql';

interface Props extends RoutePageProps {
  lms: Lms;
}

type EducationContent = DeepNonNullable<Lms>['saved']['edges'][0]['node'];

function SavedContentList({ lms }: Props) {
  const [sharedRecord, setSharedRecord] = useState<EducationContent | null>(
    null,
  );

  const saved = lms?.saved ? getNodes(lms.saved) : [];

  return (
    <div data-bni-id="SavedContentList">
      <ShareEducationContentModal
        show={!!sharedRecord}
        educationContent={sharedRecord}
        onHide={() => setSharedRecord(null)}
      />
      {saved.length ? (
        <div className="grid gap-3 mb-6 auto-rows-[1fr] grid-cols-[repeat(auto-fill,minmax(27rem,_1fr))]">
          {saved.map((educationContent: EducationContent) => (
            <EducationContentTile
              key={educationContent.id}
              educationContent={educationContent}
              onShare={() => setSharedRecord(educationContent)}
            />
          ))}
        </div>
      ) : (
        <BlankSlate>
          <BlankSlate.Title>
            <FormattedMessage {...educationMessages.savedContentEmpty} />
          </BlankSlate.Title>
        </BlankSlate>
      )}
    </div>
  );
}

export default createFragmentContainer(SavedContentList, {
  lms: graphql`
    fragment SavedContentList_lms on Lms {
      saved: educationContentConnection(
        first: 2147483647
        sort: [CREATED_AT_DESC]
        viewerHasSaved: true
      ) @connection(key: "SavedContentList_saved") {
        edges {
          node {
            id
            ...EducationContentTile_educationContent
            ...ShareEducationContentModal_educationContent
          }
        }
      }
    }
  `,
});
