import ArrowDownIcon from '@bfly/icons/ArrowDown';
import CommentIcon from '@bfly/icons/CommentBubble';
import RulerIcon from '@bfly/icons/Ruler';
import TrashCanIcon from '@bfly/icons/TrashCan';
import ButtonToolbar from '@bfly/ui2/ButtonToolbar';
import useDialog from '@bfly/ui2/useDialog';
import useMutationTrigger from '@bfly/ui2/useMutationTrigger';
import useQuery from '@bfly/ui2/useQuery';
import useSubscription from '@bfly/ui2/useSubscription';
import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import clsx from 'clsx';
// eslint-disable-next-line no-restricted-imports
import Link from 'found/Link';
import useRouter from 'found/useRouter';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { Experience } from 'components/ExperiencesProvider';
import IconButton from 'components/IconButton';
import { useVariation } from 'components/LaunchDarklyContext';
import useExperiences from 'hooks/useExperiences';
import actionMessages from 'messages/actions';
import { useExamRoutes } from 'routes/exam';
import { useCanDeleteCaptures } from 'utils/StudyPermissions';

import { usePreloadMeasurements } from '../routes/measurements';
import { getCapturesUpdater } from '../utils/examPageFooter';
import ExamImageComments from './ExamImageComments';
import ImageToggleBookmarkButton from './ImageToggleBookmarkButton';
import StudyImageDownloadControl from './StudyImageDownloadControl';
import { ExamImagePageFooterDeleteCaptureMutation } from './__generated__/ExamImagePageFooterDeleteCaptureMutation.graphql';
import { ExamImagePageFooter_CommentPostedOnImageSubscription as CommentPostedOnImageSubscription } from './__generated__/ExamImagePageFooter_CommentPostedOnImageSubscription.graphql';
import { ExamImagePageFooter_CommentsQuery as CommentsQuery } from './__generated__/ExamImagePageFooter_CommentsQuery.graphql';
import {
  ImageCaptureMode,
  ExamImagePageFooter_studyImage$data as StudyImage,
} from './__generated__/ExamImagePageFooter_studyImage.graphql';
import { ExamImagePageFooter_viewer$data as Viewer } from './__generated__/ExamImagePageFooter_viewer.graphql';

export const INVALID_MEASUREMENT_MODES: ImageCaptureMode[] = [
  'M_MODE',
  'POWER_DOPPLER',
  'BIPLANE',
  'PULSED_WAVE_DOPPLER',
];

interface Props {
  viewer: Viewer;
  studyImage: StudyImage;
  interpretationImageUrl: string | null;
  gridArea?: string;
  showComments: boolean;
  setShowComments: (prevShowComments: boolean) => void;
}

function ExamImagePageFooter({
  viewer,
  studyImage,
  interpretationImageUrl,
  gridArea,
  showComments,
  setShowComments,
}: Props) {
  const { formatMessage } = useIntl();
  const examRoutes = useExamRoutes();
  const { router, match } = useRouter();
  const dialog = useDialog();

  const {
    location,
    params: { studyHandle },
  } = match;

  const canDeleteCaptures = useCanDeleteCaptures(studyImage.study!);
  const canUseMeasurements = useVariation('cloud-measurements');

  const canMeasure =
    canUseMeasurements &&
    !studyImage.burnedInFile!.isVideo &&
    !INVALID_MEASUREMENT_MODES.includes(studyImage.captureMode!) &&
    studyImage.source === 'BUTTERFLY';

  // preload the measurements data to reduce the chance of an awkward
  // set of spinners
  usePreloadMeasurements(studyImage.handle!, !canMeasure);

  const { data: commentsData } = useQuery<CommentsQuery>(
    graphql`
      query ExamImagePageFooter_CommentsQuery($studyImageId: ID!) {
        studyImage: node(id: $studyImageId) {
          ... on StudyImage {
            ...ExamImageComments_studyImage
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      skip: !showComments,
      variables: {
        studyImageId: studyImage.id,
      },
    },
  );
  const commentsVisible = !!(showComments && commentsData);

  const { targetRef } = useExperiences(Experience.INTERACT_COMMENT_BOX, {
    placement: 'top-start',
    header: (
      <FormattedMessage
        id="examImagePageFooter.post_comment.title"
        defaultMessage="Post a comment"
      />
    ),
    message: (
      <FormattedMessage
        id="examImagePageFooter.post_comment.initial"
        defaultMessage="Click to leave a comment on this image"
      />
    ),
  });

  useSubscription<CommentPostedOnImageSubscription>(
    // XXX: numImageComments gets around an edge case with data consistency. See
    //  https://app.asana.com/0/381397475033997/667123260760187.
    graphql`
      subscription ExamImagePageFooter_CommentPostedOnImageSubscription(
        $input: StudyImageCommentPostedOnImageSubscriptionInput!
      ) {
        studyImageCommentPostedOnImage(input: $input) {
          studyImageCommentEdge {
            node {
              image {
                study {
                  numImageComments
                }
                numComments
              }
              ...CommentPreview_comments
              ...CommentListItem_comment
            }
          }
        }
      }
    `,
    {
      input: { imageId: studyImage.id },
      updater: (store) => {
        rangeAddUpdater(store, {
          parentId: studyImage.id,
          connectionKey: 'StudyImage_commentConnection',
          rootFieldName: 'studyImageCommentPostedOnImage',
          edgeName: 'studyImageCommentEdge',
        });
      },
    },
  );

  const [deleteCapture, deleteCaptureDetails] =
    useMutationTrigger<ExamImagePageFooterDeleteCaptureMutation>(
      graphql`
        mutation ExamImagePageFooterDeleteCaptureMutation(
          $input: BulkDeleteCapturesInput!
        ) {
          bulkDeleteCapturesOrError(input: $input) {
            ...RelayForm_error @relay(mask: false)
            ... on BulkDeleteCapturesPayload {
              study {
                numImages
                handle
                archive {
                  handle
                }
                organization {
                  slug
                }
                imageConnection(first: 2147483647) {
                  edges {
                    node {
                      handle
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        input: { studyId: studyImage.study!.id, imageIds: [studyImage.id] },

        updater: getCapturesUpdater([studyImage.id], studyImage.study!.id),
      },
    );

  const arrowDownIcon = <ArrowDownIcon width={20} height={20} />;
  const isLastCapture = studyImage.study!.numImages === 1;

  return (
    <>
      {commentsVisible && (
        <ExamImageComments
          viewer={viewer}
          // place the comments in the same "cell" as the toolbar so it's possitioned correctly
          gridArea={gridArea}
          studyImage={commentsData.studyImage!}
          onClose={() => setShowComments(false)}
        />
      )}

      <ButtonToolbar
        pad={8}
        style={{ gridArea: 'toolbar' }}
        className={clsx(
          'bg-black border-t border-grey-80 py-4 h-16',
          commentsVisible && 'invisible',
        )}
        justify="center"
      >
        <span className="relative" ref={commentsVisible ? null : targetRef}>
          <IconButton
            title={formatMessage({
              id: 'examImagePageFooter.commentsButton',
              defaultMessage: 'Comments',
            })}
            busy={showComments && !commentsData}
            onClick={() => setShowComments(true)}
            icon={<CommentIcon width={20} height={20} />}
            data-bni-id="OpenComments"
          />
          {!!studyImage.numComments && (
            <div
              style={{ lineHeight: 1, fontFamily: 'sans-serif' }} // override the text-sm line height, font for tabular nums
              className="
                absolute text-white -top-1.5 -right-1 bg-primary 
                rounded-full w-5 h-5 text-sm font-extrabold 
                text-center flex items-center justify-center tabular-nums pointer-events-none
              "
            >
              {studyImage.numComments}
            </div>
          )}
        </span>
        {canMeasure && (
          <Link
            to={{
              ...location,
              pathname: examRoutes.measurements({
                studyImageHandle: studyImage.handle!,
                studyHandle,
              }),
            }}
            as={IconButton}
            data-bni-id="MeasureButton"
            title={formatMessage({
              id: 'examImagePageFooter.measurementButton',
              defaultMessage: 'Measure',
            })}
            icon={<RulerIcon width={40} />}
          />
        )}
        <ImageToggleBookmarkButton studyImage={studyImage} />
        <StudyImageDownloadControl
          image={studyImage}
          interpretationImageUrl={interpretationImageUrl}
          icon={arrowDownIcon}
          title={formatMessage({
            id: 'examImagePageFooter.downloadButton',
            defaultMessage: 'Download Image',
          })}
        />
        {canDeleteCaptures && (
          <IconButton
            data-bni-id="DeleteCaptureButton"
            onClick={async () => {
              const confirmed = await dialog.open(
                <FormattedMessage
                  id="examImagePageFooter.delete"
                  defaultMessage="Are you sure you want to delete this capture? This action cannot be undone."
                />,
                {
                  confirmButtonProps: { variant: 'danger', size: 'lg' },
                  modalVariant: 'dark',
                  title: (
                    <FormattedMessage
                      id="examImagePageFooter.deleteTitle"
                      defaultMessage="Are you sure you want to delete this?"
                    />
                  ),
                  confirmLabel: (
                    <FormattedMessage {...actionMessages.delete} />
                  ),
                },
              );

              if (confirmed) {
                await deleteCapture();
                router.replace(examRoutes.exam({ studyHandle }));
              }
            }}
            title={formatMessage({
              id: 'examImagePageFooter.deleteButton',
              defaultMessage: 'Delete capture',
            })}
            disabled={deleteCaptureDetails.disabled || isLastCapture}
            busy={deleteCaptureDetails.loading}
            icon={<TrashCanIcon width={20} height={20} />}
          />
        )}
      </ButtonToolbar>
    </>
  );
}

export default createFragmentContainer(ExamImagePageFooter, {
  viewer: graphql`
    fragment ExamImagePageFooter_viewer on Viewer {
      ...ExamImageComments_viewer
    }
  `,
  studyImage: graphql`
    fragment ExamImagePageFooter_studyImage on StudyImage {
      id
      handle
      numComments
      captureMode
      source
      burnedInFile {
        isVideo
      }
      study {
        handle
        id
        numImages
        ...StudyPermissions_canDeleteCaptures
      }
      ...ImageToggleBookmarkButton_studyImage
      ...StudyImageDownloadControl_image
    }
  `,
});
