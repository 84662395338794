/**
 * @generated SignedSource<<953d84541317893f8c6785599b192e31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudySearchCriterionKey = "ACCESSION_NUMBER" | "ALL_WORKSHEET_TEMPLATES" | "ARCHIVE" | "AUTHOR" | "CAPTURED_AT" | "CREDENTIAL_GROUP" | "EXAM_TYPE" | "EXCLUDED_FROM_STUDY_LISTS" | "FAVORITED_BY" | "FREE_TEXT" | "IS_DELETED" | "NUM_WORKSHEETS" | "ORGANIZATION" | "PATIENT_BIRTH_DATE" | "PATIENT_MEDICAL_RECORD_NUMBER" | "PATIENT_NAME_FIRST" | "PATIENT_NAME_LAST" | "PATIENT_NAME_MIDDLE" | "PRIMARY_AUTHOR" | "READY_AT" | "REQUESTED_FINALIZER" | "REVIEWER" | "STATUS" | "TAG" | "VET_PATIENT_CLIENT_NAME_FIRST" | "VET_PATIENT_CLIENT_NAME_LAST" | "VET_PATIENT_ID_NUMBER" | "VET_PATIENT_NAME" | "WORKSHEET_TEMPLATE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SearchTags_studySearchCriteria$data = ReadonlyArray<{
  readonly key: StudySearchCriterionKey | null;
  readonly node: {
    readonly id: string;
    readonly latestVersion?: {
      readonly title: string | null;
    } | null;
    readonly name?: string | null;
    readonly type: string;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  } | null;
  readonly value: any | null;
  readonly " $fragmentType": "SearchTags_studySearchCriteria";
}>;
export type SearchTags_studySearchCriteria$key = ReadonlyArray<{
  readonly " $data"?: SearchTags_studySearchCriteria$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchTags_studySearchCriteria">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SearchTags_studySearchCriteria",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": "type",
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Avatar_userProfile"
            }
          ],
          "type": "UserProfile",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "Organization",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "name",
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "type": "Archive",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "ExamType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorksheetTemplateVersion",
              "kind": "LinkedField",
              "name": "latestVersion",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "WorksheetTemplate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "StudyTag",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudySearchCriterion",
  "abstractKey": null
};
})();

(node as any).hash = "eea0e3ad0c001526a1283188c8ca2e4c";

export default node;
