import Layout from '@4c/layout';
import { css } from 'astroturf';
import clsx from 'clsx';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from './Avatar';
import ListCardContainer, {
  type ListCardContainerProps,
} from './ListCardContainer';
import { AvatarList_user$data as User } from './__generated__/AvatarList_user.graphql';

const AvatarList = (props: ListCardContainerProps) => (
  <ListCardContainer {...props} className={clsx(props.className, 'mt-2')} />
);
interface AvatarListItemProps {
  children: React.ReactNode;
  user: User;
  avatarSize?: number;
}

const AvatarListItem = ({
  children,
  user: author,
  avatarSize = 40,
}: AvatarListItemProps) => {
  return (
    <Layout
      align="center"
      pad
      css={css`
        &:not(:last-child) > *:last-child {
          border-bottom: solid 1px theme('borderColor.divider');
        }
      `}
    >
      <Avatar width={avatarSize} userProfile={author} />
      <Layout align="center" justify="space-between" pad grow className="py-4">
        {children}
      </Layout>
    </Layout>
  );
};

AvatarList.Item = createFragmentContainer(AvatarListItem, {
  user: graphql`
    fragment AvatarList_user on UserProfile {
      id
      name
      ...Avatar_userProfile
    }
  `,
});

export default AvatarList;
