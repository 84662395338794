import { useToast } from '@bfly/ui2/ToastContext';
import { FormattedMessage } from 'react-intl';
import { UseMutationConfig, graphql, useMutation } from 'react-relay';

import { useRequestReview_Mutation as RequestReviewMutation } from './__generated__/useRequestReview_Mutation.graphql';
import { useScanLabContext } from './context';

export default function useRequestReview(
  config?: Omit<UseMutationConfig<RequestReviewMutation>, 'variables'>,
) {
  const { updateStatusCounts } = useScanLabContext();
  const toast = useToast();

  const [mutate, working] = useMutation<RequestReviewMutation>(
    graphql`
      mutation useRequestReview_Mutation($input: RequestEduStudyReviewInput!) {
        requestEduStudyReviewOrError(input: $input) {
          ... on RequestEduStudyReviewPayload {
            clientMutationId
            studies {
              id
              reviewRequestedAt
            }
          }
          ... on ErrorInterface {
            message
          }
        }
      }
    `,
  );

  return {
    requestReview: (studies: { id: string; authorId: string }[]) => {
      const ids = studies.map(({ id }) => id);
      mutate({
        variables: {
          input: { ids },
        },
        ...config,
        onCompleted: (...props) => {
          toast.success(
            <FormattedMessage
              id="scanLab.requestReviewSuccess"
              defaultMessage="{count, plural, one {Study} other {# Studies}} successfully submitted for review"
              values={{ count: ids.length }}
            />,
          );
          config?.onCompleted?.(...props);
          updateStatusCounts(studies, 'reviewRequested');
        },
      });
    },
    requestReviewWorking: working,
  };
}
