import DropdownForm from '@bfly/ui2/DropdownForm';
import Form from '@bfly/ui2/Form';
import FormField from '@bfly/ui2/FormField';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { array, object, string } from 'yup';

import { CheckGroupSelectButtonLabel } from 'components/CheckGroupSelectButton';
import IndeterminateFormCheck from 'components/IndeterminateFormCheck';

import educationMessages from '../messages/educationMessages';
import { ContentType, VideoType } from '../schemas/educationContentFilters';
import useButterflyAcademyAccess from './utils/useButterflyAcademyAccess';

interface ContentTypeFilterValue {
  contentType?: ContentType[] | null;
  videoType?: VideoType[] | null;
}

interface ContentTypeSelectButtonProps {
  value: ContentTypeFilterValue;
  onChange: (nextValue: ContentTypeFilterValue) => void;
  disabledContentTypes: ContentType[];
  disabledVideoTypes: VideoType[];
}

const schema = object({
  contentType: array(
    string().enum(Object.values(ContentType)).defined(),
  ).nullable(),
  videoType: array(
    string().enum(Object.values(VideoType)).defined(),
  ).nullable(),
}).nullable();

const CONTENT_TYPE_LABELS = [
  {
    id: ContentType.VIDEO,
    title: educationMessages.videos,
  },
  {
    id: ContentType.COURSE,
    title: educationMessages.courses,
  },
  {
    id: VideoType.ACQUISITION,
    title: educationMessages.imageAcquisition,
  },
  {
    id: VideoType.INTERPRETATION,
    title: educationMessages.imageInterpretation,
  },
];

export default function ContentTypeSelectButton({
  value,
  onChange,
  disabledContentTypes,
  disabledVideoTypes,
  ...props
}: ContentTypeSelectButtonProps) {
  const butterflyAcademyAccess = useButterflyAcademyAccess();

  const [show, setShow] = useState(false);
  const handleChange = (formValue: ContentTypeFilterValue | null) => {
    const videoType = formValue?.videoType ?? [];
    const contentType = formValue?.contentType ?? [];

    if (butterflyAcademyAccess !== 'hidden') {
      if (videoType.length > 0 && !contentType.includes(ContentType.VIDEO)) {
        contentType.push(ContentType.VIDEO);
      }

      if (videoType.length === 0 && contentType.includes(ContentType.VIDEO)) {
        contentType.splice(contentType.indexOf(ContentType.VIDEO), 1);
      }
    }

    onChange({ contentType, videoType });
  };

  const formValue = useMemo(() => {
    return {
      contentType: value?.contentType ?? [],
      videoType: value?.videoType ?? [],
    };
  }, [value]);

  const selectedItems = CONTENT_TYPE_LABELS.filter(
    ({ id }) =>
      formValue.videoType.includes(id as VideoType) ||
      formValue.contentType.includes(id as ContentType),
  );

  const label = !selectedItems.length ? (
    <FormattedMessage {...educationMessages.contentType} />
  ) : (
    <CheckGroupSelectButtonLabel
      show={show}
      dataItems={selectedItems}
      renderValue={({ item }) => <FormattedMessage {...item.title} />}
      renderOverflowItem={({ item }) => <FormattedMessage {...item.title} />}
    />
  );

  const videoTypeCheckboxes = (
    <>
      <Form.Field
        name="videoType"
        type="checkbox"
        value={VideoType.ACQUISITION}
        disabled={disabledVideoTypes.includes(VideoType.ACQUISITION)}
      >
        <FormattedMessage {...educationMessages.imageAcquisition} />
      </Form.Field>
      <Form.Field
        name="videoType"
        type="checkbox"
        value={VideoType.INTERPRETATION}
        disabled={disabledVideoTypes.includes(VideoType.INTERPRETATION)}
      >
        <FormattedMessage {...educationMessages.imageInterpretation} />
      </Form.Field>
    </>
  );

  return (
    <DropdownForm
      {...props}
      show={show}
      label={label}
      schema={schema}
      value={formValue}
      onToggle={setShow}
      onChange={handleChange}
      isActive={!!selectedItems.length}
      data-bni-id="EducationContentTypeFilter"
    >
      <div className="p-4">
        {butterflyAcademyAccess !== 'hidden' ? (
          <>
            <FormField name="videoType" type="checkbox">
              {(_, { value: inputValue, update }) => {
                const selectableItems = [
                  VideoType.ACQUISITION,
                  VideoType.INTERPRETATION,
                ].filter(
                  (videoType) => !disabledVideoTypes.includes(videoType),
                );

                const noneChecked = !inputValue?.length;
                const allChecked =
                  inputValue?.length === selectableItems.length;

                return (
                  <IndeterminateFormCheck
                    name="all-checked"
                    checked={allChecked}
                    indeterminate={!noneChecked && !allChecked}
                    disabled={disabledContentTypes.includes(ContentType.VIDEO)}
                    onChange={(e) => {
                      update(e.currentTarget.checked ? selectableItems : []);
                    }}
                  >
                    <FormattedMessage {...educationMessages.videos} />
                  </IndeterminateFormCheck>
                );
              }}
            </FormField>
            <div className="ml-6">{videoTypeCheckboxes}</div>
            <Form.Field
              name="contentType"
              type="checkbox"
              value={ContentType.COURSE}
              disabled={disabledContentTypes.includes(ContentType.COURSE)}
            >
              <FormattedMessage {...educationMessages.courses} />
            </Form.Field>
          </>
        ) : (
          <>{videoTypeCheckboxes}</>
        )}
      </div>
      <DropdownForm.Footer />
    </DropdownForm>
  );
}
