/**
 * @generated SignedSource<<2ec317620638eb70db2eef6689312ff1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitStudyTagsField_sourceStudy$data = {
  readonly id: string;
  readonly organization: {
    readonly handle: string | null;
  } | null;
  readonly " $fragmentType": "SplitStudyTagsField_sourceStudy";
};
export type SplitStudyTagsField_sourceStudy$key = {
  readonly " $data"?: SplitStudyTagsField_sourceStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyTagsField_sourceStudy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyTagsField_sourceStudy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "1d47fc069f75400cb18ab5c7d9cae2cd";

export default node;
