import Layout from '@4c/layout';
import CheckMarkSmallIcon from '@bfly/icons/CheckMarkSmall';
import Button from '@bfly/ui2/Button';
import Caret from '@bfly/ui2/Caret';
import Dropdown from '@bfly/ui2/Dropdown';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import SearchFilter from '@bfly/ui2/SearchFilter';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import {
  ICellEditorParams,
  ICellRendererParams,
  IFloatingFilterParams,
} from 'ag-grid-community';
import { css } from 'astroturf';
import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import {
  ColumnArray,
  ColumnComparator,
  ColumnArrayOption as Option,
} from '../types';
import colDefType, {
  DoesFilterPass,
  FloatingFilterWrapper,
  filterWrapper,
} from './colDefType';
import useGridEvents from './gridEvents';

const messages = defineMessages({
  noMatches: {
    id: 'colDefArray.noMatches',
    defaultMessage: 'No matches.',
  },
  selectAll: {
    id: 'colDefArray.selectAll',
    defaultMessage: 'Select all',
  },
  deselectAll: {
    id: 'colDefArray.deselectAll',
    defaultMessage: 'Deselect all',
  },
  selected: {
    id: 'colDefArray.selected',
    defaultMessage: '{selected} selected',
  },
  search: {
    id: 'colDefArray.search',
    defaultMessage: 'Search...',
  },
  all: {
    id: 'colDefArray.all',
    defaultMessage: 'All',
  },
  allSelected: {
    id: 'colDefArray.allSelected',
    defaultMessage: 'All selected',
  },
  none: {
    id: 'colDefArray.none',
    defaultMessage: 'None',
  },
});

const getLabels = (options: Option[], values?: string[]) =>
  options.reduce((agg, { value, label, render }) => {
    if (!values || values.includes(value)) return [...agg, { label, render }];
    return agg;
  }, []);

export const arrayRender = (
  options: Option[],
  values?: string[],
  showAll = false,
  showToolTip = false,
) => {
  const labels = getLabels(options, values);

  if (labels.length === 0)
    return (
      <Text as="div" color="subtitle" className="italic truncate max-w-full">
        <FormattedMessage {...messages.none} />
      </Text>
    );

  let content: JSX.Element | undefined;

  if (labels.length === options.length && showAll) {
    content = (
      <Text as="div" className="truncate max-w-full">
        <FormattedMessage {...messages.all} />
      </Text>
    );
  } else {
    content = (
      <>
        <Text as="div" className="truncate max-w-full">
          {labels[0].render || labels[0].label}
        </Text>
        {labels.length > 1 && (
          <div className="ml-1 flex-shrink-0 flex items-center">
            <div className="bg-opacity-20 bg-white rounded flex-shrink-0 m-auto p-1 leading-[1] font-medium">
              {` +${labels.length - 1}`}
            </div>
          </div>
        )}
      </>
    );
  }

  if (showToolTip && labels.length > 1)
    return (
      <Tooltip.Trigger
        variant="dark"
        tooltip={labels.map((l) => l.render || l.label).join(', ')}
      >
        <Layout className="items-center">{content}</Layout>
      </Tooltip.Trigger>
    );

  return <Layout className="items-center">{content}</Layout>;
};

function MultiSelect({
  options,
  values,
  onChange,
}: {
  options: Option[];
  values: string[] | undefined;
  onChange: (next: string[] | undefined) => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const [allSelected, setAllSelected] = useState(
    values?.length === options.length,
  );

  return (
    <SearchFilter
      className="m-2"
      css={css`
        & > * {
          &:global(.ring) {
            box-shadow: none !important;
          }
          & > svg {
            display: none;
          }
          & > button {
            @apply px-2;
            svg {
              width: 1rem !important;
            }
          }
        }
      `}
      containerClassName="min-h-8"
      variant="secondary"
      placeholder={messages.search}
      value={searchTerm}
      onChange={setSearchTerm}
      filter={(searchTermValue, option) =>
        !!option.label &&
        option.label.toLocaleLowerCase().includes(searchTermValue)
      }
      items={options}
    >
      {({ filteredItems, filteredCount }) => {
        const allHidden = !filteredCount;
        return (
          <>
            {allHidden ? (
              <Text as="div" className="p-2">
                <FormattedMessage {...messages.noMatches} />
              </Text>
            ) : (
              <Layout
                direction="column"
                className="max-h-[20rem] scrollable-y"
              >
                <Button
                  className="mr-auto pb-1 font-semibold"
                  variant="text-primary"
                  onClick={() => {
                    onChange(
                      !allSelected
                        ? options.map(({ value }) => value)
                        : undefined,
                    );
                    setAllSelected((prev) => !prev);
                  }}
                >
                  <FormattedMessage
                    {...(allSelected
                      ? messages.deselectAll
                      : messages.selectAll)}
                  />
                </Button>
                <FormCheckGroup<Option>
                  pad={0}
                  type="checkbox"
                  className="px-2 pt-2 m-0 w-full"
                  name="items"
                  data={filteredItems}
                  value={options.filter(
                    (o) => allSelected || values?.includes(o.value),
                  )}
                  onChange={(selectedOptions) => {
                    const nextValue = !selectedOptions.length
                      ? undefined
                      : selectedOptions.map((o) => o.value as string);
                    setAllSelected(nextValue?.length === options.length);
                    onChange(nextValue);
                  }}
                  renderItem={({ label }) => (
                    <div className="truncate w-[18rem]">{label}</div>
                  )}
                />
              </Layout>
            )}
            <Layout className="m-2">
              <Text color="primary" className="ml-auto">
                <FormattedMessage
                  {...messages.selected}
                  values={{
                    selected: allSelected
                      ? options.length
                      : values?.length || 0,
                  }}
                />
              </Text>
            </Layout>
          </>
        );
      }}
    </SearchFilter>
  );
}

function SingleSelect({
  options: optionsProp,
  currentValue,
  toggleValue,
  isFilter,
}: {
  options: Option[];
  currentValue: string[] | undefined;
  toggleValue: (next: string | undefined) => void;
  isFilter?: boolean;
}) {
  const { formatMessage } = useIntl();

  const options = isFilter
    ? [
        // adding all option only to the filter not edit
        {
          render: <FormattedMessage {...messages.all} />,
          label: formatMessage(messages.all),
          value: undefined as any,
        },
        ...optionsProp,
      ]
    : optionsProp;

  return (
    <Dropdown.Menu
      variant="dark"
      className="relative w-12 max-h-56 scrollable-y"
      show
    >
      {options.map(({ render, label, value }, index) => {
        const isActive =
          value === undefined ? !currentValue : currentValue?.includes(value);
        return (
          <Dropdown.Item
            key={value || `item-${index}`}
            active={isActive}
            onClick={() => toggleValue(value)}
            className={clsx(
              'py-1 px-2 hover:bg-grey-85 flex items-center',
              isActive && 'bg-grey-85',
            )}
          >
            <CheckMarkSmallIcon
              width={14}
              height={10}
              className={clsx(
                'mr-3 fill-grey-50 flex-shrink-0',
                isActive ? 'opacity-100' : 'opacity-0',
              )}
            />
            <span className="truncate">{render || label}</span>
          </Dropdown.Item>
        );
      })}
    </Dropdown.Menu>
  );
}

const cellEditor = (options: Option[], multiple?: ColumnArray['multiple']) =>
  forwardRef((params: ICellEditorParams, ref) => {
    const [currentValue, setCurrentValue] = useState(params.value);
    const [done, setDone] = useState(false);

    useImperativeHandle(ref, () => ({
      getValue: () => currentValue,
    }));

    useEffect(() => {
      if (done) params.stopEditing();
    }, [done, params]);

    return multiple === true ? (
      <MultiSelect
        options={options}
        values={currentValue}
        onChange={(values) => {
          if (!values || values?.some((v) => v === undefined))
            setCurrentValue(undefined);
          else setCurrentValue(values);
        }}
      />
    ) : (
      <SingleSelect
        options={options}
        toggleValue={(next) => {
          setCurrentValue(next);
          setDone(true);
        }}
        currentValue={currentValue}
      />
    );
  });

const arrayComparator =
  (options: Option[]): ColumnComparator =>
  (valueA: string[], valueB: string[], _nodeA, _nodeB, _isDescending) => {
    const strA = getLabels(options, valueA)
      .map(({ label }) => label)
      .sort()
      .join(',')
      .toLocaleLowerCase();
    const strB = getLabels(options, valueB)
      .map(({ label }) => label)
      .sort()
      .join(',')
      .toLocaleLowerCase();
    if (!valueA && valueB) {
      return 1;
    }
    if (!valueB && valueA) {
      return -1;
    }
    return strA.localeCompare(strB);
  };

export default function colDefArray(column: ColumnArray) {
  const {
    options,
    multiple,
    editable,
    type: _,
    colId,
    showAllLabel,
    ...colDef
  } = column;

  const doesFilterPass: DoesFilterPass<string[] | undefined> = (
    data,
    filterValues,
  ) => {
    return (
      !filterValues ||
      [data[colId]].flat().some((value) => filterValues?.includes(value))
    );
  };

  return colDefType({
    colId,
    ...colDef,
    editable: !!editable,
    filter: filterWrapper<string[] | undefined>(
      ({ setFilterValue, filterValue, hidePopupMenu }) =>
        multiple ? (
          <MultiSelect
            options={options}
            values={filterValue}
            onChange={(values) => {
              if (!values) setFilterValue(undefined);
              else setFilterValue(values);
            }}
          />
        ) : (
          <SingleSelect
            isFilter
            options={options}
            toggleValue={(next) => {
              setFilterValue(
                next === undefined ||
                  (filterValue?.length && filterValue[0] === next)
                  ? undefined
                  : [next],
              );
              hidePopupMenu();
            }}
            currentValue={filterValue}
          />
        ),
      doesFilterPass,
    ),
    floatingFilterComponent: forwardRef(
      ({ showParentFilter }: IFloatingFilterParams, ref) => {
        const [currentValue, setCurrentValue] = useState<string[]>();

        useImperativeHandle(ref, () => ({
          onParentModelChanged: setCurrentValue,
        }));

        const [hasFocus, setHasFocus] = useState(false);

        const { clearFilter } = useGridEvents({
          onClosePopupMenu: () => {
            setHasFocus(false);
          },
        });

        const showFilter = () => {
          showParentFilter();
          setHasFocus(true);
        };

        return (
          <FloatingFilterWrapper
            currentValue={currentValue}
            hasFocus={hasFocus}
            onClear={() => {
              setCurrentValue(undefined);
              clearFilter((column.colId || column.field)!);
            }}
            onClick={showFilter}
          >
            <div className="flex-grow pl-3 pr-0 overflow-hidden">
              {arrayRender(options, currentValue, true, false)}
            </div>
            <Button
              variant={null}
              className={clsx(
                'pl-1 flex flex-shrink-1',
                currentValue ? 'pr-1' : 'pr-3',
              )}
            >
              <Caret />
            </Button>
          </FloatingFilterWrapper>
        );
      },
    ),
    comparator: arrayComparator(options),
    cellRenderer: (params: ICellRendererParams) =>
      arrayRender(options, [params.value].flat(), showAllLabel, true),
    cellEditor: cellEditor(options, multiple),
    cellEditorPopup: true,
    cellEditorPopupPosition: 'over',
  });
}
