import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import TabNav from '@bfly/ui2/TabNav';
import { css } from 'astroturf';
import React from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PageTitle from 'components/PageTitle';
import { useAdminRoutes } from 'routes/admin';

import formatDomainUserName from '../utils/formatDomainUserName';
import { DomainMemberPage_domainUser$data as DomainUser } from './__generated__/DomainMemberPage_domainUser.graphql';

interface Props {
  children?: React.ReactNode;
  domainUser: DomainUser;
}

function DomainMemberPage({ children, domainUser }: Props) {
  const routes = useAdminRoutes();
  return (
    <Page.Container data-bni-id="DomainMemberPage" className="flex-col">
      <Page.Header bordered={false}>
        <PageTitle
          title={defineMessage({
            id: 'domainMemberPage.pageTitle',
            defaultMessage: '{name}',
          })}
          values={{ name: formatDomainUserName(domainUser!) }}
        />
      </Page.Header>
      <TabNav
        // FIXME: sass
        css={css`
          @apply mb-10;

          padding: 0 calc(theme('padding.app-panel-sm') - theme('spacing.4'));

          @screen md {
            padding: 0 calc(theme('padding.app-panel') - theme('spacing.4'));
          }
        `}
      >
        <TabNav.LinkItem
          to={routes.domainMember({ domainUserId: domainUser.id })}
        >
          <FormattedMessage
            id="domainUser.settings.menu.general"
            defaultMessage="General"
          />
        </TabNav.LinkItem>
      </TabNav>
      <MainContent>{children}</MainContent>
    </Page.Container>
  );
}

export default createFragmentContainer(DomainMemberPage, {
  domainUser: graphql`
    fragment DomainMemberPage_domainUser on DomainUser {
      id
      profile {
        name
      }
      ...formatDomainUserName_domainUser
    }
  `,
});
