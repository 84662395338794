import getNodes from '@bfly/utils/getNodes';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import HospitalServerArchiveAssociationForm from './HospitalServerArchiveAssociationForm';
import { EhrArchiveAssociationForm_Mutation as Mutation } from './__generated__/EhrArchiveAssociationForm_Mutation.graphql';
import { EhrArchiveAssociationForm_archive$data as Archive } from './__generated__/EhrArchiveAssociationForm_archive.graphql';
import { EhrArchiveAssociationForm_organization$data as Organization } from './__generated__/EhrArchiveAssociationForm_organization.graphql';

export const MAX_EHR_ASSOCIATIONS = 3;

const messages = defineMessages({
  fieldLabel: {
    id: 'archive.settings.ehrAssociations.label',
    defaultMessage: 'EHR {index}',
  },
  emptyDropdownPlaceholder: {
    id: 'archive.settings.ehrAssociations.emptyDropdownPlaceholder',
    defaultMessage: 'No EHR Selected',
  },
  emptyDropdownMenu: {
    id: 'archive.settings.ehrAssociations.emptyDropdownMenu',
    defaultMessage:
      'No EHRs available.{br}You can create an EHR from the Connectivity page.',
  },
});

type Ehr = NonNullable<
  NonNullable<NonNullable<Archive>['ehrAssociations']>[0]
>['ehr'];

const mutation = graphql`
  mutation EhrArchiveAssociationForm_Mutation(
    $input: SetArchiveEhrAssociationsInput!
  ) {
    setArchiveEhrAssociationsOrError(input: $input) {
      ... on SetArchiveEhrAssociationsPayload {
        archive {
          ...EhrArchiveAssociationForm_archive
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  archive: Archive;
  organization: Organization;
}

function EhrArchiveAssociationForm({ archive, organization }: Props) {
  const currentEhrs = useMemo(
    () => archive.ehrAssociations!.map((a) => a!.ehr),
    [archive],
  );

  const availableEhrs = useMemo(
    () =>
      getNodes(organization.ehrConnection).filter(
        ({ id }) => !currentEhrs.find((p) => p!.id === id),
      ),
    [currentEhrs, organization],
  );

  return (
    <HospitalServerArchiveAssociationForm<Mutation, Ehr>
      currentAssociations={currentEhrs}
      formMessages={messages}
      mutation={mutation}
      data={availableEhrs}
      maxAssociations={MAX_EHR_ASSOCIATIONS}
      getInput={(associations) => ({
        archiveId: archive.id,
        ehrIds: associations
          .filter(Boolean)
          .map((association) => association!.id),
      })}
    />
  );
}

export default createFragmentContainer(EhrArchiveAssociationForm, {
  archive: graphql`
    fragment EhrArchiveAssociationForm_archive on Archive {
      id
      ehrAssociations {
        ehr {
          id
          name
        }
      }
    }
  `,
  organization: graphql`
    fragment EhrArchiveAssociationForm_organization on Organization {
      ehrConnection(first: 2147483647)
        @connection(key: "Organization_ehrConnection") {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
