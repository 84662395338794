/**
 * @generated SignedSource<<4c82e30ea9e27138282cee44273c30a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainRoleDeleteFieldSet_viewer$data = {
  readonly domain: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "DomainRoleDeleteFieldSet_viewer";
};
export type DomainRoleDeleteFieldSet_viewer$key = {
  readonly " $data"?: DomainRoleDeleteFieldSet_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainRoleDeleteFieldSet_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainRoleDeleteFieldSet_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "051601b9047fd8d6d20fdea0f53e3b76";

export default node;
