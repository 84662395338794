import Logo from '@bfly/icons/Logo';
import Heading from '@bfly/ui2/Heading';
import MainContent from '@bfly/ui2/MainContent';
import Text, { TextProps } from '@bfly/ui2/Text';
import React from 'react';

interface Props {
  children: React.ReactNode;
  'data-bni-id'?: string;
}

const AuthContentTitle = Heading;

const AuthContentDescription = (
  props: TextProps & React.ComponentPropsWithoutRef<'p'>,
) => <Text {...props} as="p" variant="body" className="mb-4" />;

function AuthContent({ children, ...props }: Props) {
  return (
    <MainContent size="xs" centerText {...props}>
      <Logo className="mb-5 w-10" />
      {children}
    </MainContent>
  );
}

AuthContent.Title = AuthContentTitle;
AuthContent.Description = AuthContentDescription;

export default AuthContent;
