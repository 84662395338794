/**
 * @generated SignedSource<<905e0468663baac30cb57446ca08ad49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type StudySearchCriterionKey = "ACCESSION_NUMBER" | "ALL_WORKSHEET_TEMPLATES" | "ARCHIVE" | "AUTHOR" | "CAPTURED_AT" | "CREDENTIAL_GROUP" | "EXAM_TYPE" | "EXCLUDED_FROM_STUDY_LISTS" | "FAVORITED_BY" | "FREE_TEXT" | "IS_DELETED" | "NUM_WORKSHEETS" | "ORGANIZATION" | "PATIENT_BIRTH_DATE" | "PATIENT_MEDICAL_RECORD_NUMBER" | "PATIENT_NAME_FIRST" | "PATIENT_NAME_LAST" | "PATIENT_NAME_MIDDLE" | "PRIMARY_AUTHOR" | "READY_AT" | "REQUESTED_FINALIZER" | "REVIEWER" | "STATUS" | "TAG" | "VET_PATIENT_CLIENT_NAME_FIRST" | "VET_PATIENT_CLIENT_NAME_LAST" | "VET_PATIENT_ID_NUMBER" | "VET_PATIENT_NAME" | "WORKSHEET_TEMPLATE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Search_searchToData$data = {
  readonly criteria: ReadonlyArray<{
    readonly key: StudySearchCriterionKey | null;
    readonly node: {
      readonly id: string;
      readonly latestVersion?: {
        readonly title: string | null;
      } | null;
      readonly name?: string | null;
      readonly type: string;
    } | null;
    readonly value: any | null;
  } | null> | null;
  readonly " $fragmentType": "Search_searchToData";
};
export type Search_searchToData$key = {
  readonly " $data"?: Search_searchToData$data;
  readonly " $fragmentSpreads": FragmentRefs<"Search_searchToData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "Search_searchToData"
};

(node as any).hash = "783197daf08e72e5a4b611b9add3cb42";

export default node;
