import Layout from '@4c/layout';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Notification from './Notification';
import { NotFoundNotification_notification$data as NotificationType } from './__generated__/NotFoundNotification_notification.graphql';

interface Props {
  notification: NotificationType;
  clearable: boolean;
}

function NotFoundNotification({ notification, clearable }: Props) {
  return (
    <Notification notification={notification} clearable={clearable}>
      <Layout.Block grow css="text-align: center;">
        <FormattedMessage
          id="notFoundNotification.description"
          defaultMessage="The content of this notification can no longer be found."
        />
      </Layout.Block>
    </Notification>
  );
}

export default createFragmentContainer(NotFoundNotification, {
  notification: graphql`
    fragment NotFoundNotification_notification on NotificationInterface {
      ...Notification_notification
    }
  `,
});
