import useDialog from '@bfly/ui2/useDialog';
import countBy from 'lodash/countBy';
import map from 'lodash/map';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import useDeleteStudies from 'hooks/useDeleteStudies';
import actionMessages from 'messages/actions';
import { SelectedStudyItem } from 'utils/StudySelection';

export default function useDeleteStudiesHandler(
  organizationId: string,
  domainId: string,
  selectedStudyItems: SelectedStudyItem[],
) {
  const dialog = useDialog();
  const [deleteStudies] = useDeleteStudies();
  const numSelectedStudies = selectedStudyItems.length;

  const nonRemovableStudies = useMemo(
    () => selectedStudyItems.filter((study) => !study.viewerCanDelete),
    [selectedStudyItems],
  );

  const numNonRemovableStudies = nonRemovableStudies.length;

  const runConfirm = useCallback(() => {
    const nonRemovableStudiesIds = nonRemovableStudies.map(
      (value) => value.studyId,
    );

    const studiesToRemove = selectedStudyItems.filter(
      (item) => !nonRemovableStudiesIds.includes(item.studyId),
    );

    return deleteStudies(studiesToRemove, organizationId, domainId);
  }, [
    deleteStudies,
    nonRemovableStudies,
    organizationId,
    selectedStudyItems,
    domainId,
  ]);

  const nonRemovablePatientNames = useMemo(
    () =>
      Object.entries(countBy(map(nonRemovableStudies, 'patientName'))).sort(
        (a, b) => a[0].localeCompare(b[0]),
      ),
    [nonRemovableStudies],
  );

  return useCallback(() => {
    dialog.open(
      <>
        <span className="block">
          <FormattedMessage
            id="studies.delete.dialog.content"
            defaultMessage="You are about to delete {numStudies} {numStudies, plural, one {exam} other {exams}}."
            values={{ numStudies: numSelectedStudies }}
          />
        </span>
        {!!numNonRemovableStudies && (
          <>
            <span className="block pt-4">
              <FormattedMessage
                tagName="span"
                id="studies.delete.dialog.noPermissionsToDeleteStudies"
                defaultMessage="You do not have admin permissions to delete the following {numStudies} {numStudies, plural, one {exam} other {exams}}:"
                values={{ numStudies: numNonRemovableStudies }}
              />
            </span>
            <span className="block overflow-y-auto">
              {nonRemovablePatientNames.map(([patientName, count]) => (
                <span className="list-item list-inside" key={patientName}>
                  ({count}){' '}
                  {patientName || (
                    <FormattedMessage
                      id="studies.delete.dialog.patientName.empty"
                      defaultMessage="No Patient Name"
                    />
                  )}
                </span>
              ))}
            </span>
          </>
        )}
      </>,
      {
        runConfirm,
        confirmButtonProps: { variant: 'danger', size: 'lg' },
        modalVariant: 'dark',
        title: (
          <FormattedMessage
            id="studies.delete.dialog.title"
            defaultMessage="Delete {numStudies} {numStudies, plural, one {exam} other {exams}}?"
            values={{ numStudies: numSelectedStudies }}
          />
        ),
        confirmLabel: <FormattedMessage {...actionMessages.delete} />,
      },
    );
  }, [
    dialog,
    numSelectedStudies,
    numNonRemovableStudies,
    nonRemovablePatientNames,
    runConfirm,
  ]);
}
