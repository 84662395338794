import Layout from '@4c/layout';
import Bookmark from '@bfly/icons/Bookmark';
import CineIcon from '@bfly/icons/Cine';
import { useField } from '@bfly/ui2/Form';
import FormCheck from '@bfly/ui2/FormCheck';
import PreviewGrid from '@bfly/ui2/PreviewGrid';
import clsx from 'clsx';
import { createFragmentContainer, graphql } from 'react-relay';

import AspectRatioBox from 'components/AspectRatioBox';
import { useVariation } from 'components/LaunchDarklyContext';
import StudyImagePopoverThumbnail from 'components/StudyImagePopoverThumbnail';
import Thumbnail from 'components/Thumbnail';
import Analytics, { AnalyticsEvent } from 'utils/Analytics';

import { StudyCaptureSelectorImagePreviewItem_image$data as StudyImage } from './__generated__/StudyCaptureSelectorImagePreviewItem_image.graphql';

interface Props {
  image: StudyImage;
  index: number;
  shape?: 'round' | 'square';
}

function StudyCaptureSelectorImagePreviewItem({
  image,
  shape = 'square',
}: Props) {
  const [fieldProps] = useField({
    name: 'imageIds',
    type: 'checkbox',
    value: image.id,
  });
  const { onChange: handleChange } = fieldProps;

  const controls = (
    <>
      {!!image.isImportant && (
        <div
          className="relative"
          data-bni-id="StudyCaptureSelectorImagePreviewItem_bookmark"
        >
          <Bookmark className="h-4 z-50 text-white absolute top-2 left-2" />
        </div>
      )}
      <Layout className="relative">
        <Layout
          justify="flex-end"
          align="flex-start"
          className="absolute top-0 inset-0 p-2"
        >
          <FormCheck.Input
            {...fieldProps}
            onChange={(event) => {
              handleChange(event);
              const eventName = event.target.checked
                ? 'studyCaptureSelectorImageSelected'
                : 'studyCaptureSelectorImageDeselected';
              Analytics.track(eventName as AnalyticsEvent, {
                studyId: image.study!.id,
                imageId: image.id,
              });
            }}
            type="checkbox"
            className={clsx(shape && 'rounded-full z-50')}
          />
        </Layout>
      </Layout>
    </>
  );

  const usePopoverThumbnail = useVariation('split-studies');

  if (!usePopoverThumbnail) {
    return (
      <PreviewGrid.Item>
        <label className="m-0" css="display: block; cursor: pointer">
          {controls}
          <AspectRatioBox className="inline-block relative w-24 h-24">
            <Thumbnail
              loading="lazy"
              maxWidth={240}
              alt="Study Capture"
              className="w-full h-full object-cover rounded"
              key={image.handle}
              image={image.burnedInFile!}
            />
            {image.burnedInFile!.isVideo && (
              <div className="bg-grey-90 rounded-[2px] p-0.5 absolute flex items-center justify-center text-white w-[1rem] h-[1rem] bottom-0.5 right-0.5">
                <CineIcon width={6} height={3} />
              </div>
            )}
          </AspectRatioBox>
        </label>
      </PreviewGrid.Item>
    );
  }

  return (
    <PreviewGrid.Item>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="m-0" css="display: block;">
        <StudyImagePopoverThumbnail studyImage={image}>
          {controls}
        </StudyImagePopoverThumbnail>
      </label>
    </PreviewGrid.Item>
  );
}

export default createFragmentContainer(StudyCaptureSelectorImagePreviewItem, {
  image: graphql`
    fragment StudyCaptureSelectorImagePreviewItem_image on StudyImageInterface {
      ... on StudyImage {
        id
        handle
        createdAt
        numComments
        isImportant
        study {
          id
        }
        burnedInFile {
          isVideo
          ...StudyImageThumbnail_image
          ...Thumbnail_image
        }
        ...StudyImagePopoverThumbnail_studyImage
      }
      ... on EduStudyImage {
        id
        handle
        createdAt
        study {
          id
        }
        burnedInFile {
          isVideo
          ...StudyImageThumbnail_image
          ...Thumbnail_image
        }
        ...StudyImagePopoverThumbnail_studyImage
      }
    }
  `,
});
