/**
 * @generated SignedSource<<f0e8d749d3cc92d66334396308154cb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type examTypes_ExamTypesPageQuery$variables = {
  organizationSlug: string;
};
export type examTypes_ExamTypesPageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"ExamTypesPage_tenant" | "RouteAccessControl_tenant">;
  } | null;
};
export type examTypes_ExamTypesPageQuery = {
  response: examTypes_ExamTypesPageQuery$data;
  variables: examTypes_ExamTypesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdomainLabel",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "examTypes_ExamTypesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExamTypesPage_tenant"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "RouteAccessControl_tenant",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "examTypes_ExamTypesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          {
            "alias": "numNoExamTypes",
            "args": [
              {
                "kind": "Literal",
                "name": "search",
                "value": {
                  "examType": "@@NO_EXAM_TYPE"
                }
              }
            ],
            "kind": "ScalarField",
            "name": "numStudies",
            "storageKey": "numStudies(search:{\"examType\":\"@@NO_EXAM_TYPE\"})"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ExamTypeConnection",
            "kind": "LinkedField",
            "name": "examTypeConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numStudies",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hiddenAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "systemId",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "examTypeConnection(first:50)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Tenant_examTypeConnection",
            "kind": "LinkedHandle",
            "name": "examTypeConnection"
          },
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "organizationId",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Organization",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "domainId",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Domain",
                "abstractKey": null
              }
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "739d945170bfd8877ef4a1dd651e0ba2",
    "id": null,
    "metadata": {},
    "name": "examTypes_ExamTypesPageQuery",
    "operationKind": "query",
    "text": "query examTypes_ExamTypesPageQuery(\n  $organizationSlug: String!\n) {\n  tenant(slug: $organizationSlug) {\n    __typename\n    ...ExamTypesPage_tenant\n    ...RouteAccessControl_tenant\n    id\n  }\n}\n\nfragment ExamTypesMissingBanner_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Organization {\n    slug\n  }\n}\n\nfragment ExamTypesPage_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ... on Organization {\n    slug\n  }\n  numNoExamTypes: numStudies(search: {examType: \"@@NO_EXAM_TYPE\"})\n  examTypeConnection(first: 50) {\n    edges {\n      node {\n        id\n        name\n        numStudies\n        hiddenAt\n        systemId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ...useTenantIdInput_tenant\n  ...ExamTypesMissingBanner_tenant\n}\n\nfragment RouteAccessControl_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    subdomainLabel\n  }\n  ... on Organization {\n    slug\n  }\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5acedc4022fb0e49a46b309c71f01c1";

export default node;
