import Layout from '@4c/layout';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PacsSyncStatus from 'components/PacsSyncStatus';

import CancelPacsPushJobButton from './CancelPacsPushJobButton';
import SendStudyToPacsButton from './SendStudyToPacsButton';
import { StudyPacsJobDetail_job$data as Job } from './__generated__/StudyPacsJobDetail_job.graphql';
import { StudyPacsJobDetail_pacs$data as Pacs } from './__generated__/StudyPacsJobDetail_pacs.graphql';
import { StudyPacsJobDetail_study$data as Study } from './__generated__/StudyPacsJobDetail_study.graphql';

interface Props {
  job: Job | null;
  study: Study;
  pacs: Pacs;
  title: React.ReactNode;
  children?: React.ReactNode;
}

function StudyPacsJobDetail({ job, study, pacs, title, children }: Props) {
  return (
    <Layout className="py-3" align="center">
      <Layout direction="column" grow>
        <Text className="mb-1" variant="lg">
          {title}
        </Text>
        <Text variant="sm">
          {job ? (
            <PacsSyncStatus job={job} />
          ) : (
            <FormattedMessage
              id="study.pacs.notSynced"
              defaultMessage="Study Not Synced"
            />
          )}
        </Text>
      </Layout>
      {children}
      <SendStudyToPacsButton study={study} pacs={pacs} job={job} />
      {job &&
        (job.status === 'PENDING' ||
          job.status === 'FAILED' ||
          job.status === 'RETRYING') && <CancelPacsPushJobButton job={job} />}
    </Layout>
  );
}

export default createFragmentContainer(StudyPacsJobDetail, {
  job: graphql`
    fragment StudyPacsJobDetail_job on PacsPushJob {
      status
      ...PacsSyncStatus_job
      ...SendStudyToPacsButton_job
      ...CancelPacsPushJobButton_job
    }
  `,
  study: graphql`
    fragment StudyPacsJobDetail_study on Study {
      ...SendStudyToPacsButton_study
    }
  `,
  pacs: graphql`
    fragment StudyPacsJobDetail_pacs on Pacs {
      name
      ...SendStudyToPacsButton_pacs
    }
  `,
});
