/**
 * @generated SignedSource<<c4ae8ec4f5d6159fb31b165b9d27caf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
export type VetPatientNeuterStatus = "INTACT" | "NEUTERED" | "UNKNOWN" | "%future added value";
export type VetPatientSex = "FEMALE" | "MALE" | "UNKNOWN" | "%future added value";
export type VetPatientSpecies = "AVIAN" | "CANINE" | "EQUINE" | "EXOTIC" | "FELINE" | "OTHER" | "UNKNOWN" | "%future added value";
export type UpdateStudyInfoInput = {
  accessionNumber?: string | null;
  clientMutationId?: string | null;
  createdBy?: string | null;
  createdById?: string | null;
  dicomDocument?: Record<string, any> | null;
  internalId?: string | null;
  notes?: string | null;
  patient?: UpdateStudyInfoInputPatient | null;
  sourceWorklistItemId?: string | null;
  studyDescription?: string | null;
  studyId: string;
  vetPatient?: UpdateStudyInfoInputVetPatient | null;
};
export type UpdateStudyInfoInputPatient = {
  birthDate?: string | null;
  internalId?: string | null;
  medicalRecordNumber?: string | null;
  nameFirst?: string | null;
  nameLast?: string | null;
  nameMiddle?: string | null;
  namePrefix?: string | null;
  nameSuffix?: string | null;
  sex?: PatientSex | null;
};
export type UpdateStudyInfoInputVetPatient = {
  birthDate?: string | null;
  breed?: string | null;
  clientNameFirst?: string | null;
  clientNameLast?: string | null;
  clientNameMiddle?: string | null;
  clientNamePrefix?: string | null;
  clientNameSuffix?: string | null;
  clientOrganizationName?: string | null;
  microchipNumber?: string | null;
  name?: string | null;
  neuterStatus?: VetPatientNeuterStatus | null;
  patientIdNumber?: string | null;
  sex?: VetPatientSex | null;
  species?: VetPatientSpecies | null;
  speciesFreeform?: string | null;
  weightKilograms?: number | null;
};
export type humanStudyInfoUpdateMutation$variables = {
  input: UpdateStudyInfoInput;
};
export type humanStudyInfoUpdateMutation$data = {
  readonly updateStudyInfo: {
    readonly study: {
      readonly " $fragmentSpreads": FragmentRefs<"humanStudyInfo_study">;
    } | null;
  } | null;
};
export type humanStudyInfoUpdateMutation = {
  response: humanStudyInfoUpdateMutation$data;
  variables: humanStudyInfoUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dicomDocument",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessionNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAssociatedWorklistItem",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameFirst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameLast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameMiddle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namePrefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameSuffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "medicalRecordNumber",
      "storageKey": null
    }
  ],
  "type": "PatientInterface",
  "abstractKey": "__isPatientInterface"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "studyDescription",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "humanStudyInfoUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStudyInfoPayload",
        "kind": "LinkedField",
        "name": "updateStudyInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "humanStudyInfo_study",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "sourceWorklistItem",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Patient",
                    "kind": "LinkedField",
                    "name": "patient",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "humanStudyInfoUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStudyInfoPayload",
        "kind": "LinkedField",
        "name": "updateStudyInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "sourceWorklistItem",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Patient",
                "kind": "LinkedField",
                "name": "patient",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94cfe7ef616b5b522476f30ac8690f16",
    "id": null,
    "metadata": {},
    "name": "humanStudyInfoUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation humanStudyInfoUpdateMutation(\n  $input: UpdateStudyInfoInput!\n) {\n  updateStudyInfo(input: $input) {\n    study {\n      ...humanStudyInfo_study\n      id\n    }\n  }\n}\n\nfragment humanStudyInfo_study on Study {\n  dicomDocument\n  accessionNumber\n  hasAssociatedWorklistItem\n  sourceWorklistItem {\n    __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  patient {\n    ... on PatientInterface {\n      __isPatientInterface: __typename\n      nameFirst\n      nameLast\n      nameMiddle\n      namePrefix\n      nameSuffix\n      sex\n      birthDate\n      medicalRecordNumber\n    }\n    id\n  }\n  notes\n  studyDescription\n}\n"
  }
};
})();

(node as any).hash = "9a0d372cb70cec0756af905ee905a1e4";

export default node;
