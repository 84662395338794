import Button, { ButtonVariant } from '@bfly/ui2/Button';
import useToast from '@bfly/ui2/useToast';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  GraphQLTaggedNode,
  ReactRelayContext,
  fetchQuery,
  graphql,
  readInlineData,
} from 'react-relay';
import { OperationType } from 'relay-runtime';

import actionMessages from 'messages/actions';
import downloadFile from 'utils/downloadFile';

import { FileExportButton_fileExport$key as FileExportKey } from './__generated__/FileExportButton_fileExport.graphql';

interface Props<T extends OperationType> {
  query: GraphQLTaggedNode;
  disabled?: boolean;
  // Set variables as partial to account for disabled states
  // that yet to have the full information
  variables?: Partial<T['variables']>;
  getFile: (response: T['response']) => FileExportKey | null | undefined;
  theme?: 'secondary';
  className?: string;
  message?: React.ReactNode;
  variant?: ButtonVariant;
  /** name of the file to be downloaded, defaults to file URL */
  filename?: string;
}

function FileExportButton<T extends OperationType>({
  query,
  variables = {},
  getFile,
  disabled,
  className,
  message = <FormattedMessage {...actionMessages.export} />,
  variant = 'primary',
  filename,
  ...props
}: Props<T>) {
  const relay = useContext(ReactRelayContext);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const requestDownloadUrl = async () => {
    if (!relay) {
      return;
    }

    setIsLoading(true);

    let fileUrl = '';
    try {
      const response = await fetchQuery<T>(
        relay.environment,
        query,
        variables,
      ).toPromise();

      const file = readInlineData(
        graphql`
          fragment FileExportButton_fileExport on FileExport @inline {
            url
          }
        `,
        getFile(response)!,
      );

      if (!file || !file.url) {
        throw Error('no FileUrl provided from export fetch');
      }

      fileUrl = file.url;
    } catch (e) {
      setIsLoading(false);

      toast.error(
        <FormattedMessage
          id="fileExportButton.failed"
          defaultMessage="Failed to export file"
        />,
      );

      return;
    }

    setIsLoading(false);
    downloadFile(fileUrl, filename || fileUrl);
  };

  return (
    <Button
      {...props}
      busy={isLoading}
      disabled={disabled ?? isLoading}
      onClick={requestDownloadUrl}
      className={className}
      variant={variant}
    >
      {message}
    </Button>
  );
}

export default FileExportButton;
