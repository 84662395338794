import HttpError from 'found/HttpError';
import Redirect from 'found/Redirect';
import RedirectException from 'found/RedirectException';
import { graphql, readInlineData } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import LaunchDarklyRoute from 'components/LaunchDarklyRoute';
import Route, { Match, renderStaleWhileFetching } from 'components/Route';
import { prepareVariablesWithPagination } from 'hooks/usePagedConnection';
import { routes as examRoutes } from 'routes/exam';
import {
  clearLastArchiveHandle,
  clearLastOrganizationSlug,
  setLastOrganization,
  setLastOrganizationAndArchive,
} from 'utils/EagerRedirectUtils';
import { StudyListFilter } from 'utils/StudyConstants';
import {
  prepareNewArchiveStudyQueryVariables,
  prepareNewOrganizationStudyQueryVariables,
} from 'utils/StudyFilters';

import type { archive_ArchiveRoot_Query as ArchiveRootQuery } from './__generated__/archive_ArchiveRoot_Query.graphql';
import { archive_ArchiveSettingsPage_Query as ArchiveSettingsPageQuery } from './__generated__/archive_ArchiveSettingsPage_Query.graphql';
import ArchiveMembersPage from './components/ArchiveMembersPage';
import ArchiveSettingsPage from './components/ArchiveSettingsPage';
import SearchPagedArchivePage from './components/SearchPagedArchivePage';
import StoragePagedArchivePage from './components/StoragePagedArchivePage';

interface CheckOrganizationProps {
  match: Match;
  props?: ArchiveRootQuery['response'];
}

export function checkOrganizationPlan({
  props,
  match,
}: CheckOrganizationProps) {
  if (!props) return;
  const { canAccessProFeatures, hasCompletedTrial } =
    props.organization!.subscription!;

  if (!(canAccessProFeatures || hasCompletedTrial)) {
    setLastOrganization(match);
  }
}

const renderArchivePage = renderStaleWhileFetching((lastProps, match) => {
  const lastArchive = readInlineData<any>(
    graphql`
      fragment archive_render on Archive @inline {
        handle
      }
    `,
    lastProps.archive,
  );

  return lastArchive.handle !== match.params.archiveHandle;
});

const archiveRoute = (
  <Route<ArchiveRootQuery>
    path="archives"
    prerender={checkOrganizationPlan}
    query={graphql`
      query archive_ArchiveRoot_Query($organizationSlug: String!) {
        organization: organizationBySlug(slug: $organizationSlug) {
          slug

          subscription {
            canAccessProFeatures
            hasCompletedTrial
          }
        }
      }
    `}
  >
    <Route
      path=":archiveHandle"
      query={graphql`
        query archive_CheckArchive_Query(
          $archiveHandle: String!
          $organizationSlug: String!
        ) {
          organization: organizationBySlug(slug: $organizationSlug) {
            viewerIsMember
          }
          archive(handle: $archiveHandle) {
            id
          }
        }
      `}
      prerender={({ props, match }) => {
        if (!props) {
          return;
        }

        const {
          location: { state },
          params: { organizationSlug },
        } = match;
        const isHomeRedirect =
          state?.isRootRedirect || state?.isOrganizationRedirect;

        if (!props.archive) {
          // Root redirects don't check for valid targets to avoid an extra round
          // trip. If it turns out we redirected to somewhere invalid, clear the
          // cache and actually ask the server where to go.

          if (isHomeRedirect) {
            clearLastArchiveHandle(match, organizationSlug);
            throw new RedirectException(state.prevLocation);
          }

          throw new HttpError(404);
        }

        // Domain admins can access orgs and archives they aren't members of
        // However it's weird to automatically direct to those orgs. In cases where
        // the user is not a member we don't cache the org, so that the root redirect
        // can send them to the correct homepage. The clearing logic below is for users
        // that may have already cached an org before this fix.
        if (!props.organization!.viewerIsMember) {
          if (isHomeRedirect) {
            clearLastOrganizationSlug(match);
            clearLastArchiveHandle(match, organizationSlug);
            throw new RedirectException(state.prevLocation);
          }
          return;
        }

        // Don't save the archive handle until navigation completes, in case
        // something fails.
        setLastOrganizationAndArchive(match);
      }}
    >
      <LaunchDarklyRoute
        render={renderArchivePage}
        getVariation={(ldClient) =>
          getVariation(ldClient, 'use-search-api')
            ? {
                Component: SearchPagedArchivePage,
                prepareVariables: prepareVariablesWithPagination(
                  (variables, match) =>
                    prepareNewArchiveStudyQueryVariables(
                      variables,
                      match,
                      true,
                    ) as any,
                ),
                query: graphql`
                  query archive_studies_search_PagedArchivePageQuery(
                    $first: Int
                    $after: String
                    $last: Int
                    $before: String
                    $sort: [StudySorting!]
                    $search: StudySearchInput
                    $archiveHandle: String!
                  ) {
                    archive(handle: $archiveHandle) {
                      ...SearchPagedArchivePage_archive
                      ...archive_render
                    }
                    viewer {
                      ...SearchPagedArchivePage_viewer
                    }
                  }
                `,
              }
            : {
                Component: StoragePagedArchivePage,
                prepareVariables: prepareVariablesWithPagination(
                  (variables, match) =>
                    prepareNewArchiveStudyQueryVariables(
                      variables,
                      match,
                      false,
                    ) as any,
                ),
                query: graphql`
                  query archive_studies_PagedArchivePageQuery(
                    $first: Int
                    $after: String
                    $last: Int
                    $before: String
                    $archiveHandle: String!
                    $examTypes: [String!]
                    $author: [String!]
                    $sort: [StudySorting!]! # Not actually required.
                    $isDraft: [Boolean!]
                    $needsReview: [Boolean!]
                    $hasRequestedFinalization: [Boolean!]
                    $hasRequestedFinalizationFromViewer: [Boolean!]
                    $readyAtMax: DateTime
                    $readyAtMin: DateTime
                    $capturedAtMax: DateTime
                    $capturedAtMin: DateTime
                    $workflowStatus: [StudySearchInputStatus!]
                  ) {
                    archive(handle: $archiveHandle) {
                      ...StoragePagedArchivePage_archive
                      ...archive_render
                    }
                    viewer {
                      ...StoragePagedArchivePage_viewer
                    }
                  }
                `,
              }
        }
      />
      <Route<ArchiveSettingsPageQuery>
        // TODO: Make this "-/settings" after we move studies and images.
        path="settings"
        Component={ArchiveSettingsPage}
        query={graphql`
          query archive_ArchiveSettingsPage_Query(
            $archiveHandle: String!
            $organizationSlug: String!
          ) {
            archive(handle: $archiveHandle) {
              ...ArchiveSettingsPage_archive
            }
            organization: organizationBySlug(slug: $organizationSlug) {
              ...ArchiveSettingsPage_organization
            }
            viewer {
              ...ArchiveSettingsPage_viewer
            }
            tenant {
              ...ArchiveSettingsPage_tenant
            }
          }
        `}
        prerender={({ props, viewerContext }) => {
          if (!props) return;

          const viewerPermissions =
            viewerContext?.organization?.viewerPermissions;
          if (
            !viewerPermissions ||
            (viewerPermissions.userManagement === 'NONE' &&
              viewerPermissions.bulkDataExport === 'NONE' &&
              viewerPermissions.archiveManagement === 'NONE' &&
              viewerPermissions.connectivityManagement === 'NONE' &&
              viewerPermissions.studyDocumentationManagement === 'NONE')
          ) {
            throw new HttpError(404);
          }
        }}
      />
      <Route
        path="-/members"
        Component={ArchiveMembersPage}
        query={graphql`
          query archive_ArchiveMembersPage_Query($archiveHandle: String!) {
            archive(handle: $archiveHandle) {
              ...ArchiveMembersPage_archive
            }
          }
        `}
      />

      <Redirect
        from="studies/:studyHandle/images/:studyImageHandle?"
        to={(match) => ({
          ...match.location,
          pathname: match.params.studyImageHandle
            ? examRoutes.examImage(match.params as any)
            : examRoutes.exam(match.params as any),
        })}
      />
      {/* Universal link for iOS that as of now redirects to the study's first image. */}
      <Redirect
        from="studies/:studyHandle/worksheets/:worksheetHandle/reviews/:qaEntryHandle?"
        to={(match) => ({
          ...match.location,
          pathname: examRoutes.exam(match.params as any),
        })}
      />
      <Redirect
        from="studies/:studyHandle"
        to={(match) => ({
          ...match.location,
          query: { ...match.location.query, studyImageHandle: undefined },
          // The "accept finalization request" email adds studyImageHandle as query param
          pathname: match.location.query?.studyImageHandle
            ? examRoutes.examImage({
                ...match.params,
                studyImageHandle: match.location.query?.studyImageHandle,
              } as any)
            : examRoutes.exam(match.params as any),
        })}
      />
    </Route>
  </Route>
);

export const orgAppHeaderRoutes = <>{}</>;

export const orgArchiveRoutes = (
  <>
    {archiveRoute}

    {/** This is only responsible for redirecting from :org/-/deleted(old route for deleted studies) */}
    <Route
      path="-/deleted"
      prepareVariables={prepareNewOrganizationStudyQueryVariables as any}
      prerender={({ variables }) => {
        throw new RedirectException(
          examRoutes.examLists({
            status: StudyListFilter.DELETED,
            organizationSlug: variables.organizationSlug,
          }),
        );
      }}
      query={graphql`
        query archive_DeletedStudiesPage_Query($organizationSlug: String!) {
          organization: organizationBySlug(slug: $organizationSlug) {
            id
          }
        }
      `}
    />
  </>
);
