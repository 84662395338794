import Dropdown from '@bfly/ui2/Dropdown';
import MeatballDropdownButton from '@bfly/ui2/MeatballDropdownButton';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DeleteCapturesDropdownItem from './DeleteCapturesDropdownItem';
import DeleteStudyControl from './DeleteStudyControl';
import MoveStudyDropdownItem from './MoveStudyDropdownItem';
import PermanentlyDeleteStudyControl from './PermanentlyDeleteStudyControl';
import ShareStudyControl from './ShareStudyDropdownItem';
import SplitStudyControl from './SplitStudyControl';
import StudyDownloadPdfDropdownItem from './StudyDownloadPdfDropdownItem';
import StudyEndpointsDropdownItem from './StudyEndpointsDropdownItem';
import UndeleteStudyControl from './UndeleteStudyControl';
import { StudyControls_study$data as Study } from './__generated__/StudyControls_study.graphql';

interface Props {
  study: Study;
  showUndelete?: boolean;
  className?: string;
  children?: React.ReactNode;
}

function StudyControls({
  study,
  showUndelete = true,
  className,
  children,
}: Props) {
  const intl = useIntl();

  return (
    <MeatballDropdownButton
      id={`${study.id}-controls`}
      label={intl.formatMessage({
        id: 'studyControls.label',
        defaultMessage: 'Study actions',
      })}
      className={className}
      data-bni-id="StudyControls"
    >
      {study.source === 'BUTTERFLY' && (
        <ShareStudyControl study={study}>
          <FormattedMessage
            id="study.share"
            defaultMessage="Share de-identified exam"
          />
        </ShareStudyControl>
      )}
      <StudyEndpointsDropdownItem study={study} />
      <MoveStudyDropdownItem study={study} />
      <DeleteCapturesDropdownItem study={study} />
      <SplitStudyControl as={Dropdown.Item} study={study} />
      <StudyDownloadPdfDropdownItem study={study} />
      {children && (
        <>
          {children}
          <Dropdown.Divider />
        </>
      )}
      <DeleteStudyControl as={Dropdown.Item} study={study} />
      {showUndelete && (
        <UndeleteStudyControl as={Dropdown.Item} study={study}>
          <FormattedMessage
            id="study.undelete"
            defaultMessage="Restore deleted exam"
          />
        </UndeleteStudyControl>
      )}
      <PermanentlyDeleteStudyControl as={Dropdown.Item} study={study} />
    </MeatballDropdownButton>
  );
}

export default createFragmentContainer(StudyControls, {
  study: graphql`
    fragment StudyControls_study on Study {
      id
      source
      ...ShareStudyDropdownItem_study
      ...SplitStudyControl_study
      ...StudyEndpointsDropdownItem_study
      ...MoveStudyDropdownItem_study
      ...StudyDownloadPdfDropdownItem_study
      ...DeleteStudyControl_study
      ...DeleteCapturesDropdownItem_study
      ...UndeleteStudyControl_study
      ...PermanentlyDeleteStudyControl_study
    }
  `,
});
