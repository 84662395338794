import useMutationWithError from '@bfly/ui2/useMutationWithError';
import { graphql } from 'react-relay';

import Analytics from 'utils/Analytics';

import { useHandleExamTypeChange_AddMutation as AddMutation } from './__generated__/useHandleExamTypeChange_AddMutation.graphql';
import { useHandleExamTypeChange_RemoveMutation as RemoveMutation } from './__generated__/useHandleExamTypeChange_RemoveMutation.graphql';

export default function useHandleExamTypeChange(studyId: string) {
  const addExamTypeMutation = graphql`
    mutation useHandleExamTypeChange_AddMutation($input: AddExamTypeInput!)
    @raw_response_type {
      addExamTypeOrError(input: $input) {
        ... on AddExamTypePayload {
          study {
            id
            examTypes {
              id
              name
            }
          }
        }
        ...mutationError_error @relay(mask: false)
      }
    }
  `;

  const removeExamTypeMutation = graphql`
    mutation useHandleExamTypeChange_RemoveMutation(
      $input: RemoveExamTypeInput!
    ) @raw_response_type {
      removeExamTypeOrError(input: $input) {
        ... on RemoveExamTypePayload {
          study {
            examTypes {
              name
            }
          }
        }
        ...mutationError_error @relay(mask: false)
      }
    }
  `;

  const [addExamType] = useMutationWithError<AddMutation>(addExamTypeMutation);
  const [removeExamType] = useMutationWithError<RemoveMutation>(
    removeExamTypeMutation,
  );

  return (values: { id: string; name: string | null }[], meta) => {
    const { dataItem, action } = meta;

    const examTypeId = dataItem.id;

    if (action === 'insert') {
      // values has the latest item at the end but the backend sticks them in front, reorder
      values = [values.pop()!, ...values];

      addExamType({
        input: { studyId, examTypeId },
        optimisticResponse: {
          addExamTypeOrError: {
            /* eslint-disable @typescript-eslint/naming-convention */
            __typename: 'AddExamTypePayload',
            __isErrorInterface: 'AddExamTypePayload',
            /* eslint-enable @typescript-eslint/naming-convention */
            message: null,
            study: {
              id: studyId,
              examTypes: values,
            },
          },
        },
      });
      Analytics.track('examTypeAdded', { studyId, examTypeId });
    }

    if (action === 'remove') {
      removeExamType({
        input: { studyId, examTypeId },
        optimisticResponse: {
          removeExamTypeOrError: {
            /* eslint-disable @typescript-eslint/naming-convention */
            __isErrorInterface: 'RemoveExamTypePayload',
            __typename: 'RemoveExamTypePayload',
            /* eslint-enable @typescript-eslint/naming-convention */
            message: null,
            study: {
              id: studyId,
              examTypes: values,
            },
          },
        },
      });
      Analytics.track('examTypeRemoved', { studyId, examTypeId });
    }
  };
}
