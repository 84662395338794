import Button, { ButtonProps } from '@bfly/ui2/Button';
import clsx from 'clsx';
import React from 'react';

const NavbarSolidButton = React.forwardRef(
  (props: Omit<ButtonProps, 'variant'>, ref: any) => {
    return (
      <Button
        {...props}
        ref={ref}
        size="flush"
        variant={null}
        className={clsx(
          props.className,
          'bg-grey-80 text-white active:filter-none hover:filter-none hover:opacity-[0.85] active:opacity-75 px-[1rem] h-10',
        )}
      />
    );
  },
);

export default NavbarSolidButton;
