import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import DropdownList from '@bfly/ui2/DropdownList';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import withModal from 'utils/withModal';

import { bulkDomainUsersUpdateRoleModalMessages as messages } from '../messages/DomainMembersMessages';
import { Role } from '../shared/DomainMembersGridTypes';

interface Props {
  roles: Role[];
  onHide: () => void;
  onSuccess: (selectedRole: Role) => void;
}

function BulkDomainUsersUpdateRoleModal({ roles, onHide, onSuccess }: Props) {
  const [selectedRole, setSelectedRole] = useState<Role>();
  const handleSetRole = useCallback(() => {
    if (selectedRole) {
      onSuccess(selectedRole);
    }
  }, [selectedRole, onSuccess]);

  const handleChangeRole = useCallback((role) => {
    setSelectedRole(role);
  }, []);
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...messages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0 h-64">
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextNote}
            values={{
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>
        <Layout className="px-5" align="center">
          <Text className="flex-none pr-5">
            <FormattedMessage {...messages.selectRole} />
          </Text>

          <DropdownList
            variant="secondary"
            menuVariant="dark"
            data-bni-id="RoleSelect"
            id="role-select"
            css={css`
              :global(#role-select_input .rw-popup .rw-list) {
                max-height: 14rem;
              }
            `}
            className="w-full"
            data={roles}
            textField={(item: { name: string; id: string }) => item.name}
            onChange={handleChangeRole}
          />
        </Layout>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            data-bni-id="SelectRoleModalConfirm"
            size="lg"
            onClick={handleSetRole}
            disabled={!selectedRole}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
          <Button
            data-bni-id="SelectRoleModalCancel"
            variant="secondary"
            size="lg"
            onClick={onHide}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default withModal(BulkDomainUsersUpdateRoleModal, {
  variant: 'dark',
});
