import omitBy from 'lodash/omitBy';
import { array, bool, object, string } from 'yup';
import type { InferType } from 'yup';

import { arrayFromString, join, split } from './filterHelpers';

export enum Sort {
  NAME_ASC = 'NAME_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}

export enum VideoType {
  ACQUISITION = 'ACQUISITION',
  INTERPRETATION = 'INTERPRETATION',
}

enum ImageCaptureMode {
  B_MODE = 'B_MODE',
  M_MODE = 'M_MODE',
  COLOR_DOPPLER = 'COLOR_DOPPLER',
  POWER_DOPPLER = 'POWER_DOPPLER',
  B_MODE_3D = 'B_MODE_3D',
  PULSED_WAVE_DOPPLER = 'PULSED_WAVE_DOPPLER',
}

export const SORTS: Array<Sort> = Object.values(Sort);
export const VIDEO_TYPES: Array<VideoType> = Object.values(VideoType);

const CAPTURE_MODES: Array<ImageCaptureMode> = Object.values(ImageCaptureMode);

const schema = object({
  sort: string().enum(SORTS).default(undefined),
  search: string(),
  viewerHasSaved: bool(),
  category: array().of(string()),
  type: array().of(string().enum(VIDEO_TYPES)),
  captureMode: array().of(string().enum(CAPTURE_MODES)),
  toolLocalId: array().of(string()),
  presetLocalId: array().of(string()),
});

export type Filters = Partial<InferType<typeof schema>>;

const serializer = schema
  .shape({
    category: string().transform(join),
    type: string().transform(join),
    viewerHasSaved: string(),
    captureMode: string().transform(join),
    toolLocalId: string().transform(join),
    presetLocalId: string().transform(join),
  })
  .transform((values) => omitBy(values, (value) => !value || !value.length));

export function serialize(value: any) {
  return serializer.cast(value);
}

const deserializer = schema.shape({
  category: arrayFromString,
  type: array().of(string().enum(VIDEO_TYPES)).transform(split),
  captureMode: array().of(string().enum(CAPTURE_MODES)).transform(split),
  toolLocalId: arrayFromString,
  presetLocalId: arrayFromString,
});

export function deserialize(query: any): Filters {
  return deserializer.cast(query);
}

export default schema;
