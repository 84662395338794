import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import getNodes from '@bfly/utils/getNodes';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { RelayProp, createFragmentContainer, graphql } from 'react-relay';
import * as yup from 'yup';

import { useVariation } from 'components/LaunchDarklyContext';
import RelayForm from 'components/RelayForm';
import actionMessages from 'messages/actions';
import withModal from 'utils/withModal';

import domainUser, { deserialize } from '../schemas/domainUser';
import { useMemberCountUpdate } from '../utils/MemberCounts';
import DomainMemberAccessRoleFieldSet from './DomainMemberAccessRoleFieldSet';
import DomainMemberDetailFieldSet from './DomainMemberDetailFieldSet';
import DomainMemberDicomFieldsFieldSet from './DomainMemberDicomFieldsFieldSet';
import DomainMemberIntegrationConfigsFieldSet from './DomainMemberIntegrationConfigsFieldSet';
import { AddDomainMemberModalEmailCheckQuery } from './__generated__/AddDomainMemberModalEmailCheckQuery.graphql';
import {
  CreateDomainUserInputDomainUserDicomField,
  CreateDomainUserInputDomainUserIntegrationConfig,
  AddDomainMemberModalMutation as Mutation,
} from './__generated__/AddDomainMemberModalMutation.graphql';
import { AddDomainMemberModal_domain$data as Domain } from './__generated__/AddDomainMemberModal_domain.graphql';
import { AddDomainMemberModal_viewer$data as Viewer } from './__generated__/AddDomainMemberModal_viewer.graphql';

interface Props {
  onHide: () => void;
  show: boolean;
  viewer: Viewer;
  domain: Domain;
  relay: RelayProp;
}
const emailClaimedMessage = defineMessage({
  id: 'addDomainMemberModal.alreadyMember',
  defaultMessage: 'This user is already a member of the domain',
});

const reactivateMessage = defineMessage({
  id: 'addDomainMemberModal.alreadyMember.reactivate',
  defaultMessage:
    'An existing profile is associated with this email address. If this user is inactive, you can reactivate them from our “inactive members” list.',
});

function AddDomainMemberModalContent({
  viewer,
  domain,
  relay,
  ...props
}: Props) {
  const memberCountUpdate = useMemberCountUpdate();
  const [disabled, setDisabled] = useState(false);
  const createDomainUser = domainUser.shape({
    createButterflyLogin: yup.boolean(),
    email: yup
      .string()
      .email()
      .required()
      .default('')
      .remote<AddDomainMemberModalEmailCheckQuery>({
        message: useVariation('inactive-users')
          ? reactivateMessage
          : emailClaimedMessage,
        query: graphql`
          query AddDomainMemberModalEmailCheckQuery(
            $domainId: ID!
            $email: String!
          ) {
            domain: node(id: $domainId) {
              ... on Domain {
                isUserEmailAvailable(email: $email) {
                  answer
                }
              }
            }
          }
        `,
        getVariables: (email: string, _, { domainId }) => ({
          email: (email || '').trim(),
          domainId,
        }),
        getResult(data) {
          const isEmailAvailable = data.domain!.isUserEmailAvailable!.answer;
          setDisabled(!isEmailAvailable);
          return isEmailAvailable;
        },
        localValidate: (value) => {
          if (!(value || '').trim()) return true;
          return null;
        },
      }),
    roleId: yup.string().required().default(undefined),
  });

  const includeLoginOption =
    domain.enableSamlLogin && domain.enableButterflyLogin;

  const [ehrs, dicomFieldTemplates, defaultValue] = useMemo(() => {
    const nodes = getNodes(domain.ehrConnection);
    const dicomFields = getNodes(domain.dicomFieldTemplateConnection);
    const value = deserialize(null, nodes, dicomFields);

    if (includeLoginOption) {
      (value as any).createButterflyLogin = false;
    }

    return [nodes, dicomFields, value] as const;
  }, [
    domain.ehrConnection,
    domain.dicomFieldTemplateConnection,
    includeLoginOption,
  ]);

  const formContext = useMemo(
    () => ({
      relay,
      domainId: domain.id,
    }),
    [relay, domain],
  );

  return (
    <RelayForm<Mutation, typeof createDomainUser>
      as={null}
      horizontal
      schema={createDomainUser}
      defaultValue={defaultValue as any}
      onCompleted={() => {
        memberCountUpdate();
        props.onHide();
      }}
      formContext={formContext}
      getInput={({
        isAdmin,
        integrationConfigs,
        dicomFields,
        email,
        ...value
      }) => ({
        ...value,
        email: email.trim(),
        domainId: domain.id!,
        type: isAdmin ? 'ADMIN' : 'USER',
        integrationConfigs: integrationConfigs.filter(
          (c): c is CreateDomainUserInputDomainUserIntegrationConfig =>
            !!c.interfaceCode,
        ),
        dicomFields: dicomFields.filter(
          (d): d is CreateDomainUserInputDomainUserDicomField => !!d.value,
        ),
      })}
      mutation={graphql`
        mutation AddDomainMemberModalMutation($input: CreateDomainUserInput!) {
          createDomainUserOrError(input: $input) {
            ... on CreateDomainUserPayload {
              domainUserEdge {
                node {
                  ...DomainMembersGrid_domainUser
                }
              }
            }
            ...RelayForm_error @relay(mask: false)
          }
        }
      `}
    >
      <>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              id="addDomainMemberModal.title"
              defaultMessage="Add user"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DomainMemberDetailFieldSet
            fluid
            domain={domain}
            domainUser={null}
          />
          <DomainMemberAccessRoleFieldSet viewer={viewer} />
          <DomainMemberIntegrationConfigsFieldSet fluid ehrs={ehrs} />
          <DomainMemberDicomFieldsFieldSet
            fluid
            dicomFieldTemplates={dicomFieldTemplates}
          />
        </Modal.Body>

        <Modal.Footer>
          <Modal.ButtonGroup>
            <Form.Submit disabled={disabled}>
              <FormattedMessage {...actionMessages.save} />
            </Form.Submit>
            <Button variant="secondary" onClick={props.onHide}>
              <FormattedMessage {...actionMessages.cancel} />
            </Button>
          </Modal.ButtonGroup>
        </Modal.Footer>
      </>
    </RelayForm>
  );
}

export default createFragmentContainer(
  withModal(AddDomainMemberModalContent),
  {
    viewer: graphql`
      fragment AddDomainMemberModal_viewer on Viewer {
        ...DomainMemberAccessRoleFieldSet_viewer
      }
    `,
    domain: graphql`
      fragment AddDomainMemberModal_domain on Domain {
        id
        enableSamlLogin
        enableButterflyLogin
        ehrConnection(first: 2147483647)
          @connection(key: "Domain_ehrConnection") {
          edges {
            node {
              id
              ...DomainMemberIntegrationConfigsFieldSet_ehrs
            }
          }
        }
        dicomFieldTemplateConnection(first: 2147483647)
          @connection(key: "Domain_dicomFieldTemplateConnection") {
          edges {
            node {
              id
              ...DomainMemberDicomFieldsFieldSet_dicomFieldTemplates
            }
          }
        }
        ...DomainMemberDetailFieldSet_domain
      }
    `,
  },
);
