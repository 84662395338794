import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import { createFragmentContainer, graphql } from 'react-relay';

import BaseAppPage from 'components/BaseAppPage';
import { RoutePageProps } from 'components/Route';

import GlobalStudySearchSubNav from './GlobalStudySearchSubNav';
import { GlobalStudySearchDomainPage_tenant$data as Tenant } from './__generated__/GlobalStudySearchDomainPage_tenant.graphql';
import { GlobalStudySearchDomainPage_viewer$data as Viewer } from './__generated__/GlobalStudySearchDomainPage_viewer.graphql';

interface Props extends RoutePageProps {
  viewer: Viewer;
  tenant: Tenant;
  loading?: boolean;
  sidePanel?: React.ReactElement | null;
}

function GlobalStudySearchDomainPage({
  viewer,
  tenant,
  children,
  loading,
  sidePanel,
}: Props) {
  // XXX: this route should not use staleWhileFetching but needs too until we remove the old search route
  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <BaseAppPage
      tenant={tenant}
      organization={null}
      viewer={viewer}
      pageLayout="fill"
      archive={null}
      searchNodes={null}
      sidePanel={sidePanel}
    >
      <GlobalStudySearchSubNav tenant={viewer.domain!} viewer={viewer} />
      {children}
    </BaseAppPage>
  );
}

export default createFragmentContainer(GlobalStudySearchDomainPage, {
  viewer: graphql`
    fragment GlobalStudySearchDomainPage_viewer on Viewer {
      domain {
        ...GlobalStudySearchSubNav_tenant
      }
      ...GlobalStudySearchSubNav_viewer
      ...BaseAppPage_viewer
    }
  `,
  tenant: graphql`
    fragment GlobalStudySearchDomainPage_tenant on TenantInterface {
      ...BaseAppPage_tenant
    }
  `,
});
