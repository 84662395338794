import Button from '@bfly/ui2/Button';
import FormCheck from '@bfly/ui2/FormCheck';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useToast from '@bfly/ui2/useToast';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useMutation } from 'react-relay';

import withModal from 'utils/withModal';

import {
  bulkRemoveOrganizationsMessages,
  bulkDomainUsersUpdateModalMessages as messages,
} from '../messages/DomainMembersMessages';
import { Organization, Row } from '../shared/DomainMembersGridTypes';
import { BulkRemoveOrganizationConfirmationModal_bulkDeleteOrganizationMembershipMutation } from './__generated__/BulkRemoveOrganizationConfirmationModal_bulkDeleteOrganizationMembershipMutation.graphql';

const bulkDeleteOrganizationMembershipOrErrorMutation = graphql`
  mutation BulkRemoveOrganizationConfirmationModal_bulkDeleteOrganizationMembershipMutation(
    $input: BulkDeleteOrganizationMembershipInput!
  ) {
    bulkDeleteOrganizationMembership(input: $input) {
      deletedOrganizationMembershipIds
      users {
        ...DomainMembersGrid_domainUser
      }
      failures {
        detail
        code
      }
    }
  }
`;

interface BulkRemoveOrganizationModalProps {
  domainId: string;
  members: Row[];
  selectedOrganizations: Organization[];
  onHide: () => void;
  onSuccess: () => void;
}

const BulkRemoveOrganizationModal = ({
  members,
  domainId,
  selectedOrganizations,
  onHide,
  onSuccess,
}: BulkRemoveOrganizationModalProps) => {
  const toast = useToast();

  const [bulkDeleteOrganizationMembershipMutation] =
    useMutation<BulkRemoveOrganizationConfirmationModal_bulkDeleteOrganizationMembershipMutation>(
      bulkDeleteOrganizationMembershipOrErrorMutation,
    );

  const [confirmedUsersEmails, setConfirmedUsersEmails] = useState(
    new Set(members.map(({ email }) => email)),
  );
  const editedUsers = useMemo(() => [...members], [members]);

  const toggleUser = useCallback(
    (email: string) => {
      setConfirmedUsersEmails((prev) => {
        const confirmedUsers = new Set(prev);
        if (prev.has(email)) {
          confirmedUsers.delete(email);
        } else {
          confirmedUsers.add(email);
        }
        return confirmedUsers;
      });
    },
    [setConfirmedUsersEmails],
  );

  const handleUpdateUsers = useCallback(() => {
    bulkDeleteOrganizationMembershipMutation({
      variables: {
        input: {
          domainId,
          organizationMemberships: [...confirmedUsersEmails.values()]
            .map((email) => {
              return selectedOrganizations.reduce((acc, org) => {
                const user = members.find((m) => m?.email === email);

                if (!user?.organizations.includes(org.id)) {
                  return acc;
                }

                return [
                  ...acc,
                  {
                    organizationId: org.id,
                    userId: user.profile!.id,
                  },
                ];
              }, []);
            })
            .flat(),
        },
      },
      onError() {
        toast?.error(<FormattedMessage {...messages.toastError} />);
        onHide();
      },
      onCompleted({ bulkDeleteOrganizationMembership }) {
        if (!bulkDeleteOrganizationMembership!.failures!.length) {
          onSuccess();
          toast?.success(
            <FormattedMessage
              {...messages.toastSuccess}
              values={{
                numUpdated: String(
                  bulkDeleteOrganizationMembership!.users!.length,
                ),
              }}
            />,
          );
        } else {
          toast?.error(<FormattedMessage {...messages.toastError} />);
        }
        onHide();
      },
    });
  }, [
    onSuccess,
    onHide,
    bulkDeleteOrganizationMembershipMutation,
    domainId,
    toast,
    members,
    selectedOrganizations,
    confirmedUsersEmails,
  ]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...bulkRemoveOrganizationsMessages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextReview}
            values={{
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextUpdated}
            values={{
              numUpdated: String(confirmedUsersEmails.size),
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>

        <div className="px-5 w-full">
          <table
            className="w-full"
            data-bni-id="BulkRemoveOrganizationModalUserList"
          >
            <tbody>
              {editedUsers.map((user) => {
                return (
                  <tr key={user.email} className="border-b border-b-grey-70">
                    <td className="w-8 h-12">
                      <FormCheck
                        className="m-0"
                        checked={confirmedUsersEmails.has(user.email)}
                        onChange={() => toggleUser(user.email!)}
                      />
                    </td>
                    <td className="h-12">{user.email}</td>
                    <td className="h-12">
                      {user.integrationDisplayNameFirst}{' '}
                      {user.integrationDisplayNameLast}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            data-bni-id="BulkRemoveOrganizationConfirmationModalConfirm"
            size="lg"
            onClick={handleUpdateUsers}
            disabled={confirmedUsersEmails.size === 0}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
          <Button
            data-bni-id="BulkRemoveOrganizationConfirmationModalCancel"
            size="lg"
            variant="secondary"
            onClick={onHide}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
};

export default withModal(BulkRemoveOrganizationModal, {
  style: { maxWidth: '600px' },
  variant: 'dark',
});
