/**
 * @generated SignedSource<<8c6e796e579fe6684053fe385af4e6fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamPageSidebarQaFooter_study$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExamQaNotificationControl_study">;
  readonly " $fragmentType": "ExamPageSidebarQaFooter_study";
};
export type ExamPageSidebarQaFooter_study$key = {
  readonly " $data"?: ExamPageSidebarQaFooter_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageSidebarQaFooter_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPageSidebarQaFooter_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamQaNotificationControl_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "a472611557c2f7ab144bd9eed2905273";

export default node;
