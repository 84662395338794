/**
 * @generated SignedSource<<73e2d1bd1d92035b223728e9709fa42c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query$variables = {};
export type domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query$data = {
  readonly viewer: {
    readonly connectivityConfig: {
      readonly " $fragmentSpreads": FragmentRefs<"CreateWorksheetTemplateForm_connectivityConfig">;
    } | null;
    readonly workflowConfig: {
      readonly " $fragmentSpreads": FragmentRefs<"CreateWorksheetTemplateForm_workflowConfig">;
    } | null;
  } | null;
};
export type domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query = {
  response: domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query$data;
  variables: domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  },
  {
    "kind": "Literal",
    "name": "isHidden",
    "value": false
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "workflowConfig",
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreateWorksheetTemplateForm_workflowConfig"
              }
            ],
            "storageKey": null
          },
          {
            "alias": "connectivityConfig",
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreateWorksheetTemplateForm_connectivityConfig"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "workflowConfig",
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "organizationId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Organization",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "domainId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Domain",
                        "abstractKey": null
                      }
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "type": "WorkflowConfigInterface",
                "abstractKey": "__isWorkflowConfigInterface"
              }
            ],
            "storageKey": null
          },
          {
            "alias": "connectivityConfig",
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "ExamTypeConnection",
                    "kind": "LinkedField",
                    "name": "examTypeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExamTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExamType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "examTypeConnection(first:2147483647,isHidden:false)"
                  },
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "filters": [
                      "isHidden"
                    ],
                    "handle": "connection",
                    "key": "CreateWorksheetTemplateForm_examTypeConnection",
                    "kind": "LinkedHandle",
                    "name": "examTypeConnection"
                  }
                ],
                "type": "ConnectivityConfigInterface",
                "abstractKey": "__isConnectivityConfigInterface"
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70e6cbaa60844a806818a5b6648dcde9",
    "id": null,
    "metadata": {},
    "name": "domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query",
    "operationKind": "query",
    "text": "query domainWorksheetRoutes_DomainCreateWorksheetTemplateForm_Query {\n  viewer {\n    workflowConfig: domain {\n      ...CreateWorksheetTemplateForm_workflowConfig\n      id\n    }\n    connectivityConfig: domain {\n      ...CreateWorksheetTemplateForm_connectivityConfig\n      id\n    }\n    id\n  }\n}\n\nfragment CreateWorksheetTemplateForm_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  examTypeConnection(first: 2147483647, isHidden: false) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CreateWorksheetTemplateForm_workflowConfig on WorkflowConfigInterface {\n  __isWorkflowConfigInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ...useTenantIdInput_tenant\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "14dc01caf1bd697a2d0e5e3bf3db7c36";

export default node;
