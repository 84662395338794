import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';

const styles = stylesheet`
  .term {
    font-weight: inherit;
  }

  .detail {
    /* ! */
  }

  .list {
    margin: 1rem 0 0 0;

    &.stacked {
      & .term {
        @apply text-sm text-subtitle;

        &:not(:first-child) {
          margin-top: 2rem;
        }
      }

      & .detail {
        margin: 1rem 0 0;
      }
    }

    &.table {
      display: grid;
      grid-template-columns: auto 1fr;

      &.ruled {
        border-top: 1px solid theme('borderColor.divider');

        & > dt,  
        & > dd {
          border-bottom: 1px solid theme('borderColor.divider');
        }
      }

      & .term {
        @apply text-body;

        padding: theme('padding.2') 2rem theme('padding.2') 0;
      }

      & .detail {
        @apply text-headline;

        padding: theme('padding.2') 0;
        margin: 0;
      }
    }
  }
`;

const DescriptionListTerm = (props: ComponentPropsWithoutRef<'dt'>) => (
  <dt {...props} className={clsx(styles.term, props.className)} />
);

const DescriptionListDetail = (props: ComponentPropsWithoutRef<'dd'>) => (
  <dd {...props} className={clsx(styles.detail, props.className)} />
);

export interface DescriptionListProps extends ComponentPropsWithoutRef<'dl'> {
  variant?: 'stacked' | 'table';
  className?: string;
  children?: ReactNode;
  ruled?: boolean;
}

const DescriptionList = ({
  variant = 'stacked',
  ruled = true,
  ...props
}: DescriptionListProps) => (
  <dl
    {...props}
    data-bni-id="studyHistoryDescriptionList"
    className={clsx(
      props.className,
      styles.list,
      variant && styles[variant],
      ruled && styles.ruled,
    )}
  />
);

export default Object.assign(DescriptionList, {
  Term: DescriptionListTerm,
  Detail: DescriptionListDetail,
  Divider: () => <hr className="w-full my-0 col-span-full" />,
});
