import Layout from '@4c/layout';
import Form from '@bfly/ui2/Form';
import FormControlWithAddon from '@bfly/ui2/FormControlWithAddon';
import Text from '@bfly/ui2/Text';
import { stylesheet } from 'astroturf';
import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { object, string } from 'yup';

import actionMessages from 'messages/actions';

import ExternalRedirect from '../utils/ExternalRedirect';
import AuthForm from './AuthForm';

const styles = stylesheet`
  .addon {
    width: 100%;
    height: 65%;
    align-items: center;
    justify-content: center;
    border-left: 1px solid theme('colors.grey[50]');
  }
`;

function EnterpriseLoginForm() {
  const [_, ...hostnameParts] = window.location.hostname.split('.');
  const subdomain = hostnameParts.join('.');
  const schema = object({
    domainUrl: string().required('Please provide your domain url').default(''),
  });
  const { formatMessage } = useIntl();
  const { match } = useRouter();
  const lmsForceLogin = new URLSearchParams({
    lms: 'forceLogin',
  });
  const lms =
    match.location.query?.lms === 'forceLogin' ? `?${lmsForceLogin}` : '';

  const redirectToDomain = ({ domainUrl }: { domainUrl: string }) => {
    const normalizedDomainUrl = domainUrl.trim();

    if (normalizedDomainUrl.endsWith(subdomain)) {
      ExternalRedirect.redirect(`https://${normalizedDomainUrl}${lms}`);
      return;
    }

    const [_swapMe, ...domainParts] = window.location.hostname.split('.');
    const domain = [normalizedDomainUrl, ...domainParts].join('.');

    ExternalRedirect.redirect(`https://${domain}${lms}`);
  };

  return (
    <AuthForm<typeof schema>
      schema={schema}
      defaultValue={schema.getDefault()}
      submitForm={redirectToDomain}
    >
      <AuthForm.FieldGroup
        name="domainUrl"
        placeholder={formatMessage({
          id: 'login.enterprise.placeholder',
          defaultMessage: 'your-enterprise-url',
        })}
      >
        {(inputProps) => (
          <FormControlWithAddon
            {...inputProps}
            addon={
              <Layout className={styles.addon}>
                <Text>.{subdomain}</Text>
              </Layout>
            }
          />
        )}
      </AuthForm.FieldGroup>
      <Form.Submit size="lg" className="w-full mt-6">
        <FormattedMessage {...actionMessages.continue} />
      </Form.Submit>
    </AuthForm>
  );
}

export default EnterpriseLoginForm;
