/**
 * @generated SignedSource<<015b346658f7408f97a937496b45e01d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchivePrivacyDescription_organization$data = {
  readonly name: string | null;
  readonly " $fragmentType": "ArchivePrivacyDescription_organization";
};
export type ArchivePrivacyDescription_organization$key = {
  readonly " $data"?: ArchivePrivacyDescription_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchivePrivacyDescription_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchivePrivacyDescription_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "de32b5f37d365f1ad647474d7e338f21";

export default node;
