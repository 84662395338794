import React from 'react';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import NotFoundNotification from './NotFoundNotification';
import StudyNotification from './StudyNotification';
import { StudyImageCommentNotification_notification$data as Notification } from './__generated__/StudyImageCommentNotification_notification.graphql';

const messages = defineMessages({
  notification: {
    id: 'notificationCenter.notification.studyImageComment.action',
    defaultMessage: '{username} commented on the study {studyName}',
  },
});

interface Props {
  notification: Notification;
  clearable: boolean;
}

function StudyImageCommentNotification({ notification, clearable }: Props) {
  const { studyImageComment } = notification;
  const study = studyImageComment?.image?.study;

  if (!study || study?.deletedAt) {
    return (
      <NotFoundNotification
        notification={notification}
        clearable={clearable}
      />
    );
  }

  const { createdBy, image } = studyImageComment;

  return (
    <StudyNotification
      notification={notification}
      userProfile={createdBy!}
      study={study!}
      image={image}
      message={messages.notification}
      clearable={clearable}
    />
  );
}

export default createFragmentContainer(StudyImageCommentNotification, {
  notification: graphql`
    fragment StudyImageCommentNotification_notification on NotificationInterface {
      ... on StudyImageCommentNotification {
        studyImageComment {
          createdBy {
            ...StudyNotification_userProfile
          }
          image {
            study {
              deletedAt
              ...StudyNotification_study
            }
            ...StudyNotification_image
          }
        }
      }
      ...NotFoundNotification_notification
      ...StudyNotification_notification
    }
  `,
});
