import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import AspectRatioBox from './AspectRatioBox';
import Thumbnail from './Thumbnail';
import { AvatarCollage_userProfiles$data as UserProfiles } from './__generated__/AvatarCollage_userProfiles.graphql';

interface Props {
  userProfiles: UserProfiles;
  width: number;
  className?: string;
}

const AvatarCollage = React.forwardRef<HTMLDivElement, Props>(
  ({ userProfiles, width, className }, ref) => {
    return (
      <div
        ref={ref}
        className={className}
        css={css`
          position: relative;
          height: ${width}px;
          width: ${width}px;
          border-radius: 50%;
          overflow: hidden;
          display: inline-flex;
          flex: none;
          flex-flow: column wrap-reverse;

          & > * {
            min-width: 50%;
            min-height: 50%;
            flex: 1;
          }
        `}
      >
        {userProfiles.slice(0, 4).map((profile) => (
          <AspectRatioBox aspectRatio={1} key={profile.id}>
            <Thumbnail
              css="width: 100%; height: 100%; display: block;"
              image={profile.avatar!}
              alt={profile.name!}
            />
          </AspectRatioBox>
        ))}
        {userProfiles.length > 1 && (
          <Text
            variant="xs"
            color="headline"
            className="absolute inset-0 flex items-center justify-center leading-[1] bg-black/20"
          >
            <span>
              {userProfiles.length > 10 ? '9+' : userProfiles.length}
            </span>
          </Text>
        )}
      </div>
    );
  },
);

AvatarCollage.displayName = 'AvatarCollage';

export default createFragmentContainer(AvatarCollage, {
  userProfiles: graphql`
    fragment AvatarCollage_userProfiles on UserProfile @relay(plural: true) {
      id
      avatar {
        ...Thumbnail_image
      }
      name
    }
  `,
});
