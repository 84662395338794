import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import sortBy from 'lodash/sortBy';
import { ReactNode, useMemo } from 'react';
import { graphql, readInlineData } from 'react-relay';
import { GroupBy } from 'react-widgets/List';

import {
  useSuggestedExamTypesGroups_study$data as Study,
  useSuggestedExamTypesGroups_study$key as StudyKey,
} from './__generated__/useSuggestedExamTypesGroups_study.graphql';

type ExamType = ConnectionNodeType<
  Study['organization'],
  'examTypeConnection'
>;

export default function useSuggestedExamTypesGroups(
  studyKey: StudyKey,
  suggestedTitle: ReactNode,
  remainingTitle: ReactNode,
  suggestedIds: string[] = [],
  hideGroupsIfAllSuggestedSelected = false,
) {
  const study = readInlineData<StudyKey>(
    graphql`
      fragment useSuggestedExamTypesGroups_study on Study @inline {
        examTypes {
          id
        }
        organization {
          slug
          examTypeConnection(first: 2147483647) {
            edges {
              node {
                id
                name
                hiddenAt
              }
            }
          }
        }
        worksheets {
          templateVersion {
            examTypes {
              id
            }
          }
        }
      }
    `,
    studyKey,
  );

  return useMemo(() => {
    let examTypes: ExamType[] = getNodes(
      study.organization!.examTypeConnection || { edges: [] },
    ).filter((e: ExamType) => !e.hiddenAt);

    let groupBy: GroupBy<ExamType> | undefined;

    if (
      study &&
      study.examTypes &&
      study.organization?.examTypeConnection &&
      study.worksheets
    ) {
      const suggestedExamTypeIds = new Set<string>(
        (study?.worksheets || [])
          .map((w) => w!.templateVersion!.examTypes?.map((e) => e!.id) || [])
          .flat()
          .concat(suggestedIds),
      );

      if (suggestedExamTypeIds.size)
        examTypes = sortBy(
          examTypes,
          (item) => !suggestedExamTypeIds.has(item.id),
          'name',
        );

      const allSuggestedAreSelected =
        suggestedExamTypeIds.size ===
        study.examTypes.filter(
          (item) => item?.id && suggestedExamTypeIds.has(item?.id),
        ).length;

      groupBy =
        !suggestedExamTypeIds.size ||
        (hideGroupsIfAllSuggestedSelected && allSuggestedAreSelected)
          ? undefined
          : (item) =>
              suggestedExamTypeIds.has(item.id)
                ? suggestedTitle
                : remainingTitle;
    }

    return {
      groupBy,
      examTypes,
    };
  }, [
    study,
    suggestedIds,
    hideGroupsIfAllSuggestedSelected,
    suggestedTitle,
    remainingTitle,
  ]);
}
