import { createFragmentContainer, graphql } from 'react-relay';

import BaseAppPage from 'components/BaseAppPage';
import { RoutePageProps } from 'components/Route';

import { AnalyticsBasePage_viewer$data as Viewer } from './__generated__/AnalyticsBasePage_viewer.graphql';

interface Props extends RoutePageProps {
  viewer: Viewer;
}

function AnalyticsBasePage({ viewer, children }: Props) {
  return (
    <BaseAppPage
      tenant={null}
      organization={null}
      viewer={viewer}
      pageLayout="overflow"
      archive={null}
      searchNodes={null}
    >
      {children}
    </BaseAppPage>
  );
}

export default createFragmentContainer(AnalyticsBasePage, {
  viewer: graphql`
    fragment AnalyticsBasePage_viewer on Viewer {
      ...BaseAppPage_viewer
    }
  `,
});
