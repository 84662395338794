/**
 * @generated SignedSource<<44d9eb97d5c77a50bc771dd85f5a1574>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkflowDigestsExcludedArchives_archiveConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly handle: string | null;
      readonly label: string | null;
      readonly organization: {
        readonly name: string | null;
        readonly slug: string | null;
      } | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "WorkflowDigestsExcludedArchives_archiveConnection";
};
export type WorkflowDigestsExcludedArchives_archiveConnection$key = {
  readonly " $data"?: WorkflowDigestsExcludedArchives_archiveConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkflowDigestsExcludedArchives_archiveConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkflowDigestsExcludedArchives_archiveConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ArchiveEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Archive",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "handle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ArchiveConnection",
  "abstractKey": null
};

(node as any).hash = "ccd2bc1cbe1ab248cce6b40cce7b36f1";

export default node;
