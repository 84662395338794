/**
 * @generated SignedSource<<f2949d58d751372040f9df9c5e37ca6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EducationAppPageAssignedCoursesListQuery$variables = {
  dueBefore?: string | null;
  skipAssignments: boolean;
};
export type EducationAppPageAssignedCoursesListQuery$data = {
  readonly assignmentsSortedByDueDate?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly course: {
          readonly dueDate: string | null;
        } | null;
        readonly id: string;
      } | null;
    } | null> | null;
  } | null;
};
export type EducationAppPageAssignedCoursesListQuery = {
  response: EducationAppPageAssignedCoursesListQuery$data;
  variables: EducationAppPageAssignedCoursesListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dueBefore"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipAssignments"
},
v2 = {
  "kind": "Variable",
  "name": "dueBefore",
  "variableName": "dueBefore"
},
v3 = {
  "kind": "Literal",
  "name": "sort",
  "value": [
    "DUE_DATE_DESC"
  ]
},
v4 = {
  "kind": "Literal",
  "name": "viewerHasCompleted",
  "value": false
},
v5 = {
  "kind": "Literal",
  "name": "viewerIsAssigned",
  "value": true
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EducationContentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EducationContent",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EducationContentCourse",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  },
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EducationAppPageAssignedCoursesListQuery",
    "selections": [
      {
        "condition": "skipAssignments",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "assignmentsSortedByDueDate",
            "args": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "__EducationAppPage_assignmentsSortedByDueDate_connection",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EducationAppPageAssignedCoursesListQuery",
    "selections": [
      {
        "condition": "skipAssignments",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "assignmentsSortedByDueDate",
            "args": (v7/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContentConnection",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": "assignmentsSortedByDueDate",
            "args": (v7/*: any*/),
            "filters": [
              "sort",
              "viewerIsAssigned",
              "viewerHasCompleted",
              "dueBefore"
            ],
            "handle": "connection",
            "key": "EducationAppPage_assignmentsSortedByDueDate",
            "kind": "LinkedHandle",
            "name": "educationContentConnection"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "0244288223a1aed7d13e78ea8b290533",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "assignmentsSortedByDueDate"
          ]
        }
      ]
    },
    "name": "EducationAppPageAssignedCoursesListQuery",
    "operationKind": "query",
    "text": "query EducationAppPageAssignedCoursesListQuery(\n  $skipAssignments: Boolean!\n  $dueBefore: DateTime\n) {\n  assignmentsSortedByDueDate: educationContentConnection(first: 2147483647, sort: [DUE_DATE_DESC], viewerIsAssigned: true, viewerHasCompleted: false, dueBefore: $dueBefore) @skip(if: $skipAssignments) {\n    edges {\n      node {\n        id\n        course {\n          dueDate\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "961ed82c409bd155b53c66f31e90e2d5";

export default node;
