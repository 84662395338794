import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';

import usePagedConnection from 'hooks/usePagedConnection';
import { STUDY_GRID_PAGE_SIZE } from 'utils/StudyConstants';

import PagedStudiesListPage from './PagedStudiesListPage';
import { PagedStudiesListPageQuery } from './__generated__/PagedStudiesListPageQuery.graphql';
import { StoragePagedStudiesListPage_organization$data as Organization } from './__generated__/StoragePagedStudiesListPage_organization.graphql';
import { StoragePagedStudiesListPage_studies$key as OrganizationKey } from './__generated__/StoragePagedStudiesListPage_studies.graphql';
import { StoragePagedStudiesListPage_viewer$data as Viewer } from './__generated__/StoragePagedStudiesListPage_viewer.graphql';

interface Props {
  viewer: Viewer;
  organization: Organization;
  loading?: boolean;
  shouldClearStatus?: boolean;
}

const StoragePagedStudiesListPage = ({
  organization,
  viewer,
  loading,
  shouldClearStatus,
}: Props) => {
  const [studies, paginationMeta] = usePagedConnection<
    PagedStudiesListPageQuery,
    OrganizationKey,
    'studyConnection'
  >(
    graphql`
      fragment StoragePagedStudiesListPage_studies on Organization
      @refetchable(queryName: "PagedStudiesListPageQuery") {
        studyConnection(
          includePageInfo: true
          first: $first
          after: $after

          last: $last
          before: $before

          sort: $sort
          archiveId: $archive
          examTypeHandles: $examTypes
          author: $author
          primaryAuthor: $primaryAuthor
          isDraft: $isDraft
          needsReview: $needsReview
          hasRequestedFinalization: $hasRequestedFinalization
          hasRequestedFinalizationFromViewer: $hasRequestedFinalizationFromViewer
          readyAtMin: $readyAtMin
          readyAtMax: $readyAtMax
          capturedAtMin: $capturedAtMin
          capturedAtMax: $capturedAtMax
          isViewerFavorite: $isViewerFavorite
          excludedFromStudyLists: $excludedFromStudyLists
          workflowStatus: $workflowStatus
          isDeleted: $isDeleted
        ) @connection(key: "Organization_studyConnection") {
          index
          numEdges
          edges {
            ...StudyListContent_studies @arguments(lazyLoadImages: true)
            node {
              id
            }
          }
        }
      }
    `,
    organization,
    {
      pageSize: STUDY_GRID_PAGE_SIZE,
      overfetchNumPages: 0,
    },
  );
  return (
    <PagedStudiesListPage
      organization={organization}
      viewer={viewer}
      loading={loading}
      shouldClearStatus={shouldClearStatus}
      paginationMeta={paginationMeta}
      studies={studies}
    />
  );
};

export default createFragmentContainer(StoragePagedStudiesListPage, {
  organization: graphql`
    fragment StoragePagedStudiesListPage_organization on Organization {
      ...PagedStudiesListPage_organization
      ...StoragePagedStudiesListPage_studies
      ...StudyListContent_organization
    }
  `,
  viewer: graphql`
    fragment StoragePagedStudiesListPage_viewer on Viewer {
      ...PagedStudiesListPage_viewer
      ...StudyListContent_viewer
    }
  `,
});
