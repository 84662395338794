import { FormattedDate, FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Dl, { DescriptionListProps } from 'components/DescriptionList';
import VetPatientName from 'components/VetPatientName';
import VetPatientWeight from 'components/VetPatientWeight';
import VetSpecies from 'components/VetSpecies';
import messages, {
  neuterStatusMessages,
  sexMessages,
} from 'messages/vetPatient';

import { VetPatientDescriptionList_vetPatient$data as VetPatient } from './__generated__/VetPatientDescriptionList_vetPatient.graphql';

interface Props extends DescriptionListProps {
  vetPatient: VetPatient;
}
function VetPatientDescriptionList({ vetPatient, ...props }: Props) {
  if (!vetPatient) {
    return null;
  }

  const { sex } = vetPatient;

  return (
    <Dl variant="table" {...props}>
      <Dl.Term>
        <FormattedMessage {...messages.name} />
      </Dl.Term>
      <Dl.Detail>
        <VetPatientName vetPatient={vetPatient} />
      </Dl.Detail>
      <Dl.Term>
        <FormattedMessage {...messages.patientIdNumber} />
      </Dl.Term>
      <Dl.Detail>{vetPatient.patientIdNumber}</Dl.Detail>
      <Dl.Term>
        <FormattedMessage {...messages.microchipNumber} />
      </Dl.Term>
      <Dl.Detail>{vetPatient.microchipNumber}</Dl.Detail>
      <Dl.Term>
        <FormattedMessage {...messages.species} />
      </Dl.Term>
      <Dl.Detail>
        <VetSpecies vetPatient={vetPatient} />
      </Dl.Detail>
      <Dl.Term>
        <FormattedMessage {...messages.breed} />
      </Dl.Term>
      <Dl.Detail>{vetPatient.breed}</Dl.Detail>
      <Dl.Term>
        <FormattedMessage {...messages.birthDate} />
      </Dl.Term>
      <Dl.Detail>
        {vetPatient.birthDate && (
          <FormattedDate value={vetPatient.birthDate} timeZone="UTC" />
        )}
      </Dl.Detail>
      {sex && (
        <>
          <Dl.Term>
            <FormattedMessage {...messages.sex} />
          </Dl.Term>
          <Dl.Detail>
            <FormattedMessage {...sexMessages[sex]} />
          </Dl.Detail>
        </>
      )}
      <Dl.Term>
        <FormattedMessage {...messages.neuterStatus} />
      </Dl.Term>
      <Dl.Detail>
        <FormattedMessage
          {...neuterStatusMessages[vetPatient.neuterStatus!]}
        />
      </Dl.Detail>
      <Dl.Term>
        <FormattedMessage {...messages.weight} />
      </Dl.Term>
      <Dl.Detail>
        {vetPatient.weightKilograms != null && (
          <VetPatientWeight vetPatient={vetPatient} />
        )}
      </Dl.Detail>
    </Dl>
  );
}

export default createFragmentContainer(VetPatientDescriptionList, {
  vetPatient: graphql`
    fragment VetPatientDescriptionList_vetPatient on VetPatient {
      clientOrganizationName
      neuterStatus
      weightKilograms
      birthDate
      patientIdNumber
      microchipNumber
      breed
      sex
      ...VetPatientName_vetPatient
      ...VetPatientWeight_vetPatient
      ...VetSpecies_vetPatient
    }
  `,
});
