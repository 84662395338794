import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { SearchData, mapSearchDataToCriteria } from 'utils/Search';
import withModal from 'utils/withModal';

import SavedSearchModal from './SavedSearchModal';
import { CreateSavedSearchModalMutation } from './__generated__/CreateSavedSearchModalMutation.graphql';
import { CreateSavedSearchModal_tenant$data as Tenant } from './__generated__/CreateSavedSearchModal_tenant.graphql';
import { CreateSavedSearchModal_viewer$data as Viewer } from './__generated__/CreateSavedSearchModal_viewer.graphql';

interface Props {
  onHide: () => void;
  searchData: SearchData;
  viewer: Viewer;
  tenant: Tenant;
}

function CreateSavedSearchModal({
  searchData,
  tenant,
  viewer,
  onHide,
}: Props) {
  return (
    <SavedSearchModal<CreateSavedSearchModalMutation>
      onHide={onHide}
      successMessage={
        <FormattedMessage
          id="createSavedSearchModal.success"
          defaultMessage="Search created"
        />
      }
      failureMessage={
        <FormattedMessage
          id="createSavedSearchModal.failedUpdate"
          defaultMessage="Search not created"
        />
      }
      searchData={searchData}
      studySearchCriteria={null}
      getInput={(data) => ({
        name: data.name,
        userId: viewer.id,
        organizationId: tenant.id,
        criteria: mapSearchDataToCriteria(searchData).map((n) => ({
          key: n.key!,
          value: n.value,
        })),
      })}
      organizationSlug={tenant.slug || null}
      mutation={graphql`
        mutation CreateSavedSearchModalMutation(
          $input: CreateStudySavedSearchInput!
        ) {
          createStudySavedSearchOrError(input: $input) {
            ... on CreateStudySavedSearchPayload {
              studySavedSearchEdge {
                node {
                  ...Search_searchToData
                  ...UpdateSavedSearchModal_studySavedSearch
                }
              }
            }
            ...RelayForm_error @relay(mask: false)
          }
        }
      `}
      updater={(store) => {
        rangeAddUpdater(store, {
          parentId: tenant.id!,
          connectionKey: 'Organization_viewerSavedSearchConnection',
          rootFieldName: 'createStudySavedSearchOrError',
          edgeName: 'studySavedSearchEdge',
          rangeBehavior: 'prepend',
        });
      }}
    />
  );
}

export default createFragmentContainer(withModal(CreateSavedSearchModal), {
  viewer: graphql`
    fragment CreateSavedSearchModal_viewer on Viewer {
      id
    }
  `,

  tenant: graphql`
    fragment CreateSavedSearchModal_tenant on Node {
      id
      ... on Organization {
        slug
      }
    }
  `,
});
