/* eslint-disable relay/must-colocate-fragment-spreads */
import Combobox, { ComboboxProps } from '@bfly/ui2/Combobox';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import { useMemo } from 'react';
import { defineMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { useUncontrolledProp } from 'uncontrollable';

import { renderUserSuggestion } from 'components/UserSuggestionListItem';
import useSearchQuery from 'hooks/useSearchQuery';

import { ExamQaNotificationSearchControl_Query as Query } from './__generated__/ExamQaNotificationSearchControl_Query.graphql';

export type ArchiveMembership = ConnectionNodeType<
  NonNullable<Query['response']['archive']>,
  'membershipConnection'
>;

interface Props
  extends Omit<
    ComboboxProps<ArchiveMembership>,
    | 'data'
    | 'filter'
    | 'focusFirstItem'
    | 'renderListItem'
    | 'textField'
    | 'value'
  > {
  archiveId: string;
  search?: string;
  setSearch?: (nextSearch: string) => void;
}

export default function ExamQaNotificationSearchControl({
  archiveId,
  search: searchProp,
  setSearch: setSearchProp,
  ...props
}: Props) {
  const [search, setSearch] = useUncontrolledProp(
    searchProp,
    '',
    setSearchProp,
  );

  const { data, loading } = useSearchQuery<Query>(
    graphql`
      query ExamQaNotificationSearchControl_Query(
        $archiveId: ID!
        $search: String
      ) {
        archive: node(id: $archiveId) {
          ... on Archive {
            membershipConnection(first: 30, search: $search)
              @connection(key: "Archive_membershipConnection") {
              edges {
                node {
                  ...UserSuggestionListItem_userInfo
                  userProfile {
                    id
                    name
                    ...AvatarList_user
                  }
                }
              }
            }
          }
        }
      }
    `,
    search,
    { archiveId },
  );

  const archiveMemberships: ArchiveMembership[] = useMemo(() => {
    if (!data) return [];

    return getNodes(data?.archive?.membershipConnection);
  }, [data]);

  const handleChange =
    props.onChange ||
    ((value: string) => {
      if (typeof value === 'string') {
        setSearch(value);
      }
    });

  const placeholder =
    props.placeholder ||
    defineMessage({
      id: 'ExamQaNotificationSearchControl.placeholder',
      defaultMessage: 'Search user by name',
    });

  return (
    <Combobox
      {...props}
      busy={props.busy || loading}
      data={archiveMemberships}
      filter={false}
      focusFirstItem
      onChange={handleChange}
      placeholder={placeholder}
      renderListItem={renderUserSuggestion}
      textField="email"
      value={search}
    />
  );
}
