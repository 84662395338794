/**
 * @generated SignedSource<<b528ab42728a9fb53674f960e60db51e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShareStudyDropdownItem_study$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionInfo_organization">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ShareStudyModal_study" | "StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "ShareStudyDropdownItem_study";
};
export type ShareStudyDropdownItem_study$key = {
  readonly " $data"?: ShareStudyDropdownItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShareStudyDropdownItem_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShareStudyDropdownItem_study",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_studyOrArchiveIsDeleted",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Archive",
          "kind": "LinkedField",
          "name": "archive",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShareStudyModal_study"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useSubscriptionInfo_organization",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationSubscription",
              "kind": "LinkedField",
              "name": "subscription",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canAccessProFeatures",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isInGracePeriod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "planType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "ece53df17dbf88615054480ceda117d7";

export default node;
