import MainContent from '@bfly/ui2/MainContent';
import TabNav from '@bfly/ui2/TabNav';
import { FormattedMessage } from 'react-intl';

import { useEducationRoutes } from 'routes/education';

import educationMessages from '../messages/educationMessages';

export default function EducationDashboardTabNav() {
  const educationRoutes = useEducationRoutes();
  return (
    <div className="w-full border-b border-grey-80">
      <MainContent size="lg" className="py-0">
        <TabNav className="-mb-px">
          <TabNav.LinkItem
            to={educationRoutes.dashboardCourseOverview()}
            data-bni-id="EducationDashboard-CourseOverview"
          >
            <FormattedMessage {...educationMessages.courseOverview} />
          </TabNav.LinkItem>
          <TabNav.LinkItem
            to={educationRoutes.dashboardUserOverview()}
            data-bni-id="EducationDashboard-UserOverview"
          >
            <FormattedMessage {...educationMessages.userOverview} />
          </TabNav.LinkItem>
        </TabNav>
      </MainContent>
    </div>
  );
}
