/**
 * @generated SignedSource<<b6f4175878af9abac753a34598ad48f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type account_TermsAndConditions_Query$variables = {};
export type account_TermsAndConditions_Query$data = {
  readonly latestTermsAndConditions: {
    readonly " $fragmentSpreads": FragmentRefs<"TermsAndConditionsPage_latestTermsAndConditions">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"TermsAndConditionsPage_viewer">;
  } | null;
};
export type account_TermsAndConditions_Query = {
  response: account_TermsAndConditions_Query$data;
  variables: account_TermsAndConditions_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "account_TermsAndConditions_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TermsAndConditionsPage_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TermsAndConditions",
        "kind": "LinkedField",
        "name": "latestTermsAndConditions",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TermsAndConditionsPage_latestTermsAndConditions"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "account_TermsAndConditions_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "acceptedTermsAndConditionsUrl",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TermsAndConditions",
        "kind": "LinkedField",
        "name": "latestTermsAndConditions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "textHtml",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d265b00a0e1d34f6639d346f6835207",
    "id": null,
    "metadata": {},
    "name": "account_TermsAndConditions_Query",
    "operationKind": "query",
    "text": "query account_TermsAndConditions_Query {\n  viewer {\n    ...TermsAndConditionsPage_viewer\n    id\n  }\n  latestTermsAndConditions {\n    ...TermsAndConditionsPage_latestTermsAndConditions\n    id\n  }\n}\n\nfragment TermsAndConditionsPage_latestTermsAndConditions on TermsAndConditions {\n  url\n  textHtml\n}\n\nfragment TermsAndConditionsPage_viewer on Viewer {\n  acceptedTermsAndConditionsUrl\n}\n"
  }
};
})();

(node as any).hash = "044d3f94ea7a3c69aaf311152dc93baa";

export default node;
