import Layout from '@4c/layout';
import Heading from '@bfly/ui2/Heading';
import Section from '@bfly/ui2/Section';
import { LocationDescriptorObject } from 'farce';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { RoutePageProps } from 'components/Route';
import {
  AnalyticsCredentialingSchema,
  serialize,
} from 'schemas/analyticsFilters';

import CredentialGroupMembersPerformanceIndicator from './CredentialGroupMembersPerformanceIndicator';
import EduCoursePerformanceIndicator from './EduCoursePerformanceIndicator';
import ExamTypeTagsPerformanceIndicator from './ExamTypeTagsPerformanceIndicator';
import { CredentialingPerformanceIndicators_tenant$data as Tenant } from './__generated__/CredentialingPerformanceIndicators_tenant.graphql';

interface CredentialingPerformanceIndicatorsProps extends RoutePageProps {
  tenant: Tenant | null;
}

export function getCredentialingFilters(location: LocationDescriptorObject) {
  return location.state?.filter as AnalyticsCredentialingSchema | undefined;
}
export const serializeCredentialingFilters = serialize;

function CredentialingPerformanceIndicators({
  tenant,
}: CredentialingPerformanceIndicatorsProps) {
  return (
    <Section>
      <Heading>
        <FormattedMessage
          id="CredentialingPerformanceIndicators.performanceIndicators.header"
          defaultMessage="Performance indicators"
        />
      </Heading>
      <Layout pad={2}>
        <CredentialGroupMembersPerformanceIndicator tenant={tenant} />
        <EduCoursePerformanceIndicator tenant={tenant} />
        <ExamTypeTagsPerformanceIndicator tenant={tenant} />
      </Layout>
    </Section>
  );
}

export default createFragmentContainer(CredentialingPerformanceIndicators, {
  tenant: graphql`
    fragment CredentialingPerformanceIndicators_tenant on TenantInterface {
      ...CredentialGroupMembersPerformanceIndicator_tenant
      ...EduCoursePerformanceIndicator_tenant
      ...ExamTypeTagsPerformanceIndicator_tenant
    }
  `,
});
