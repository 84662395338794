// eslint-disable-next-line max-classes-per-file
import { encodeHandle } from '@bfly/utils/codecs';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import canUseDOM from 'dom-helpers/canUseDOM';
import type { Location, Params, RouteObject } from 'found';
import { QuerySubscription, Resolver as ResolverBase } from 'found-relay';
import type { QuerySubscriptionOptions } from 'found-relay/lib/QuerySubscription';
import HttpError from 'found/HttpError';
import RedirectException from 'found/RedirectException';
import { Environment } from 'relay-runtime';

import { AuthContextValue } from 'components/AuthContext';
import { NEEDS_LAUNCH_DARKLY } from 'components/LaunchDarklyRoute';
import { Match } from 'components/Route';
import { isTinyViewPortMediaQuery as deviceTooSmallMediaQuery } from 'hooks/useIsTinyViewport';
import sessionStore from 'utils/sessionStore';
import someRouteHasProperty from 'utils/someRouteHasProperty';

import LaunchDarklyManager from './LaunchDarklyManager';
import { APP_USER_AGENT_REGEX } from './browserInfo';

function defer<T>() {
  interface Deferred {
    promise: Promise<T>;
    resolve: (v: T) => void;
    reject: (v: any) => void;
  }
  const deferred: Deferred = {} as any;
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred;
}
// XXX: The below utilities are exposed for testing. Do not use them in normal
//  code;

// These need to live outside of the Resolver instance, because we replace the
//  resolver on login state changes.
let deferred = defer<void>();

let resolved = false;

function resetDeferred() {
  deferred = defer<void>();
  resolved = false;
}

function resolveDeferred() {
  deferred.resolve();
  resolved = true;
}

export const EXPOSED_FOR_TESTS_DO_NOT_TOUCH_OR_YOU_WILL_BE_FIRED = {
  async consumeReady(): Promise<void> {
    await deferred.promise;
    resetDeferred();
  },
};
// @ts-ignore
if (window.cypressIntegrationTest) {
  // @ts-ignore
  window.resolverInternals =
    EXPOSED_FOR_TESTS_DO_NOT_TOUCH_OR_YOU_WILL_BE_FIRED;
}

const PARAM_HANDLE_KEYS = [
  'archiveHandle',
  'studyHandle',
  'studyImageHandle',
  'sharedBundleHandle',
  'sharedBundleImageHandle',
  'pacsHandle',
  'modalityWorklistHandle',
];

const QUERY_HANDLE_KEYS = ['archive'];

/**
 * Functions exactly the same as the upstream QuerySubscription,
 * except that when `defer` is set, `fetch` will return a resolved
 * promise to the resolver, instead of the actual fetch promise.
 * The Resolver will not wait for the data to be fetched and will
 * render as soon as it can. Loading states are still handled correctly
 */
class DeferringQuerySubscription extends QuerySubscription {
  defer: boolean;

  constructor(config: any) {
    super(config);

    this.defer = this.cacheConfig?.metadata?.defer === true;
  }

  fetch() {
    const promise = super.fetch();

    return this.defer ? Promise.resolve() : promise;
  }
}

export default class Resolver extends ResolverBase {
  private lastOrganizationSlugLower: string | null = null;

  private mediaMatcher: null | MediaQueryList = null;

  private pendingMatch: Match | null = null;

  constructor(
    environment: Environment,
    private readonly auth: AuthContextValue,
  ) {
    super(environment);
  }

  protected createQuerySubscription(options: QuerySubscriptionOptions) {
    return new DeferringQuerySubscription(options);
  }

  checkDeviceSize(routes: RouteObject[]) {
    if (!canUseDOM) return;

    const mobileFriendly = routes.some((r) => r.mobileFriendly);

    if (!this.mediaMatcher) {
      this.mediaMatcher = window.matchMedia(deviceTooSmallMediaQuery);
    }
    if (!mobileFriendly && this.mediaMatcher.matches) {
      // @ts-ignore
      throw new HttpError('device_too_small');
    }
  }

  checkError(location: Location) {
    if (location.state && location.state.foundHttpError) {
      throw new HttpError(location.state.foundHttpError);
    }
  }

  checkSsoLogoutRedirect() {
    Sentry.addBreadcrumb({
      level: Severity.Info,
      message: 'sso logout sessionStore',
      data: {
        storeUsed: (sessionStore as any).storage.name || 'unknown',
        version: sessionStore.version,
      },
    });
    const ssoLogoutRedirectPath = sessionStore.get('ssoLogoutRedirectPath');
    if (ssoLogoutRedirectPath) {
      sessionStore.remove('ssoLogoutRedirectPath');
      throw new RedirectException(ssoLogoutRedirectPath);
    }
  }

  getAccessTokenFromHash(match: Match) {
    if (!someRouteHasProperty(match, 'allowLoginFromHash')) {
      return null;
    }

    const hash = new URLSearchParams(match.location.hash.substring(1));
    return hash.get('access_token');
  }

  async loginFromHashToken(location: Location, accessToken: string) {
    // Do not run login if we're not on a valid user agent.
    if (APP_USER_AGENT_REGEX.test(window.navigator.userAgent)) {
      try {
        // XXX: This will cause match resolution to run again because it will
        //  replace the resolver, but the second call will be a no-op, as it
        //  will use the same access token.
        await this.auth.setAccessToken(accessToken);
      } catch (e) {
        // accessToken is invalid. Skip to redirect to remove it.
      }
    }

    throw new RedirectException({ ...location, hash: '' });
  }

  canonicalizeHandles(location: Location, params: Params) {
    let nextLocation = location;

    for (const paramHandleKey of PARAM_HANDLE_KEYS) {
      const uuid = params[paramHandleKey];
      const canonicalHandle = encodeHandle(uuid);

      if (canonicalHandle) {
        nextLocation = {
          ...nextLocation,
          pathname: nextLocation.pathname.replace(uuid, canonicalHandle),
        };
      }
    }

    for (const queryHandleKey of QUERY_HANDLE_KEYS) {
      const uuid = nextLocation.query[queryHandleKey];
      const canonicalHandle = encodeHandle(uuid);

      if (canonicalHandle) {
        nextLocation = {
          ...nextLocation,
          // @ts-ignore Fixme upstream
          query: {
            ...nextLocation.query,
            [queryHandleKey]: canonicalHandle,
          },
        };
      }
    }

    if (nextLocation !== location) {
      throw new RedirectException(nextLocation);
    }
  }

  // eslint-disable-next-line require-await
  async *resolveElements(match: Match) {
    const { location, routes, params, context } = match;

    if (resolved) {
      resetDeferred();
    }

    this.pendingMatch = match;

    try {
      this.checkError(location);
      this.checkDeviceSize(routes);
      this.checkSsoLogoutRedirect();

      const token = this.getAccessTokenFromHash(match);
      if (token) {
        await this.loginFromHashToken(location, token);
      }

      // Check for authenticated or allowPublic.
      if (
        !this.auth.isAuthenticated() &&
        ((location.state && location.state.forceLogin) ||
          !someRouteHasProperty(match, 'allowPublic'))
      ) {
        throw new HttpError(401);
      }

      if (location.state && location.state.forceLogin) {
        throw new RedirectException({
          ...location,
          state: {
            ...location.state,
            forceLogin: undefined,
          },
        });
      }

      const organizationSlug = params.organizationSlug || null;
      (context.environment.getNetwork() as any).setOrganizationSlug(
        organizationSlug,
      );

      const organizationSlugLower =
        organizationSlug && organizationSlug.toLowerCase();

      if (organizationSlugLower !== this.lastOrganizationSlugLower) {
        LaunchDarklyManager.clear();
      }

      this.lastOrganizationSlugLower = organizationSlugLower;

      this.canonicalizeHandles(location, params);

      // super() leads to a recursive call stack overflow due to compilation bug
      yield* ResolverBase.prototype.resolveElements.call(this, match);

      if (location[NEEDS_LAUNCH_DARKLY]) {
        location[NEEDS_LAUNCH_DARKLY] = false;
        throw new RedirectException(location);
      }
    } catch (e) {
      if (e instanceof HttpError) {
        // We can't resolve the deferred until after the error processing
        //  finishes.
        setTimeout(resolveDeferred);
      }
      throw e;
    }

    if (this.pendingMatch === match) {
      resolveDeferred();
    }
  }
}
