import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { SendStudyToPacsButton_job$data as Job } from 'components/__generated__/SendStudyToPacsButton_job.graphql';
import { SendStudyToPacsButton_pacs$data as Pacs } from 'components/__generated__/SendStudyToPacsButton_pacs.graphql';
import { SendStudyToPacsButton_study$data as Study } from 'components/__generated__/SendStudyToPacsButton_study.graphql';

import SendStudyToEndpointButton from './SendStudyToEndpointButton';
import { SendStudyToPacsButtonMutation } from './__generated__/SendStudyToPacsButtonMutation.graphql';

interface Props {
  study: Study;
  pacs: Pacs;
  job: Job | null;
}

function SendStudyToPacsButton({ study, pacs, job }: Props) {
  return (
    <SendStudyToEndpointButton<SendStudyToPacsButtonMutation>
      job={job}
      mutation={graphql`
        mutation SendStudyToPacsButtonMutation(
          $input: CreatePacsPushJobInput!
        ) {
          createPacsPushJobOrError(input: $input) {
            ... on CreatePacsPushJobPayload {
              study {
                ...StudyEndpointsModalContent_study
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `}
      input={{
        studyId: study.id,
        pacsId: pacs.id,
      }}
    />
  );
}

export default createFragmentContainer(SendStudyToPacsButton, {
  study: graphql`
    fragment SendStudyToPacsButton_study on Study {
      id
    }
  `,
  pacs: graphql`
    fragment SendStudyToPacsButton_pacs on Pacs {
      id
    }
  `,
  job: graphql`
    fragment SendStudyToPacsButton_job on PacsPushJob {
      id
      status
    }
  `,
});
