import { useRouter } from 'found';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import BlankAppPage from 'components/BlankAppPage';
import LoginSubtitle from 'components/LoginSubtitle';
import { LoginPage_inviteInfo$data as InviteInfo } from 'components/__generated__/LoginPage_inviteInfo.graphql';
import { useAccountRoutes } from 'routes/account';

import AlternativeLoginSection from './AlternativeLoginSection';
import AuthContent from './AuthContent';
import ButterflyLoginForm from './ButterflyLoginForm';
import UserRegionSelectForm, {
  Props as RegionSelectProps,
} from './UserRegionSelectForm';

interface Props {
  inviteInfo: InviteInfo | null;
}

function LoginPage({ inviteInfo }: Props) {
  const accountRoutes = useAccountRoutes();
  const { formatMessage } = useIntl();
  const { match } = useRouter();

  const [regionSelect, setRegionSelect] = useState<RegionSelectProps>();

  return (
    <BlankAppPage data-bni-id="LoginPage">
      {regionSelect ? (
        <UserRegionSelectForm {...regionSelect} />
      ) : (
        <AuthContent>
          {!regionSelect && (
            <AuthContent.Title>
              <FormattedMessage
                id="loginPage.title"
                defaultMessage="Log In to Butterfly Network"
              />
            </AuthContent.Title>
          )}
          {inviteInfo && (
            <AuthContent.Description>
              <LoginSubtitle inviteInfo={inviteInfo} />
            </AuthContent.Description>
          )}
          <ButterflyLoginForm
            email={inviteInfo && inviteInfo.email}
            setRegionSelect={setRegionSelect}
          />
          {!inviteInfo && (
            <AlternativeLoginSection
              buttonText={formatMessage({
                id: 'login.alternative.section.button',
                defaultMessage: 'Log In as Enterprise User',
              })}
              to={{
                pathname: accountRoutes.enterpriseLogin(),
                name: 'enterprise-login',
                state: {
                  prevLocation: match.location,
                },
              }}
            />
          )}
        </AuthContent>
      )}
    </BlankAppPage>
  );
}

export default createFragmentContainer(LoginPage, {
  inviteInfo: graphql`
    fragment LoginPage_inviteInfo on OrganizationInviteInfo {
      email
      ...LoginSubtitle_inviteInfo
    }
  `,
});
