import useDebouncedValue from '@restart/hooks/useDebouncedValue';
import { useContext, useEffect, useLayoutEffect } from 'react';
import { ReactRelayContext } from 'react-relay';
import { GraphQLTaggedNode, OperationType, fetchQuery } from 'relay-runtime';

import useSafeUseState from './useSafeUseState';

export default function useSearchQuery<T extends OperationType>(
  query: GraphQLTaggedNode,
  search: string | null,
  variables?: Omit<T['variables'], 'search'>,
) {
  const { environment } = useContext(ReactRelayContext)!;
  const debouncedSearch = useDebouncedValue(search, 500);
  const [state, setState] = useSafeUseState<{
    error: any;
    data: T['response'];
    loading: boolean;
  }>({
    data: null,
    loading: false,
    error: null,
  });

  useLayoutEffect(() => {
    setState((s) => {
      if (s.loading === !!search) return s;
      return { ...s, loading: !!search?.trim() };
    });
  }, [search, setState]);

  useEffect(() => {
    if (!debouncedSearch?.trim()) {
      setState({ error: null, data: null, loading: false });
      return undefined;
    }

    let stale = false;
    fetchQuery<T>(
      environment,
      query,
      {
        ...variables,
        search: debouncedSearch || null,
      },
      { fetchPolicy: 'store-or-network' },
    )
      .toPromise()
      .then(
        (data) => {
          if (stale) return;
          setState((s) => ({ ...s, data, loading: false }));
        },
        (error) => {
          if (stale) return;
          setState((s) => ({ ...s, error, loading: false }));
        },
      );

    return () => {
      stale = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    environment,
    debouncedSearch,
    setState,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...Object.values(variables || {}),
  ]);

  return state;
}
