/**
 * @generated SignedSource<<f97bf7d407865b4f85d021890945d088>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImagePreviewRoll_images$data = ReadonlyArray<{
  readonly id?: string;
  readonly " $fragmentSpreads": FragmentRefs<"ImagePreviewItem_image">;
  readonly " $fragmentType": "ImagePreviewRoll_images";
}>;
export type ImagePreviewRoll_images$key = ReadonlyArray<{
  readonly " $data"?: ImagePreviewRoll_images$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImagePreviewRoll_images">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ImagePreviewRoll_images",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ImagePreviewItem_image"
    }
  ],
  "type": "StudyImageInterface",
  "abstractKey": "__isStudyImageInterface"
};

(node as any).hash = "2c5c5d3289a098de1e6d04c1295d06c2";

export default node;
