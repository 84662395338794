/**
 * @generated SignedSource<<cd12ab918731affe873bf961707e50c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type worksheet_templateVersion$data = {
  readonly sections: ReadonlyArray<{
    readonly fieldDefinitions: ReadonlyArray<{
      readonly handle: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"fieldDefinition_fieldDefinition">;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "worksheet_templateVersion";
};
export type worksheet_templateVersion$key = {
  readonly " $data"?: worksheet_templateVersion$data;
  readonly " $fragmentSpreads": FragmentRefs<"worksheet_templateVersion">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "worksheet_templateVersion"
};

(node as any).hash = "0b28c75bb9286edd5e3586aee5c9a56f";

export default node;
