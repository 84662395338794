import React from 'react';

// This component is a weird hack to prevent autocomplete per
// https://stackoverflow.com/a/19174309/251162.
//
// we use sr-only to avoid a clear signal to the browser that this is not visible
//
// aria-hidden prevents SRs from reading it as if it was display: none;
function AutofillHackInput(props: React.ComponentProps<'input'>) {
  return (
    <input
      className="sr-only"
      aria-hidden
      type="password"
      name="password"
      {...props}
    />
  );
}

export default AutofillHackInput;
