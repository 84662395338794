import Layout from '@4c/layout';
import HamburgerMenuIcon from '@bfly/icons/HamburgerMenu';
import PlusIcon from '@bfly/icons/Plus';
import FormCheck from '@bfly/ui2//FormCheck';
import Button from '@bfly/ui2/Button';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import { DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useState } from 'react';

import useModalState from 'hooks/useModalState';

import SortableList, {
  SortableDndContext,
  SortableDragOverlay,
  arrayMove,
} from './SortableList';

export interface PickerColumn {
  key: string;
  label: React.ReactNode;
  fixed?: boolean;
  columns?: Omit<PickerColumn, 'columns'>[];
}

interface Props {
  className?: string;
  reorderable?: boolean;
  columns: PickerColumn[];
  columnVisibility: Record<string, boolean>;
  onColumnVisibilityChange: (
    nextColumnVisibility: Record<string, boolean>,
  ) => void;
  onColumnOrderChange: (nextColumnKeys: string[]) => void;
}

function DataGridColumnPicker({
  className,
  reorderable = true,
  columns,
  columnVisibility,
  onColumnVisibilityChange,
  onColumnOrderChange,
}: Props) {
  const columnOrder = columns.map(({ key }) => key);

  const [dragging, setDragging] = useState(false);

  const handleDragEnd = (result: DragEndEvent) => {
    const { active, over } = result;

    if (over && active.id !== over.id) {
      const orderedColumns = arrayMove(
        columnOrder,
        columnOrder.indexOf(active.id),
        columnOrder.indexOf(over.id),
      );
      onColumnOrderChange(orderedColumns);
    }

    setDragging(false);
  };

  const handleChecked = (key: string) => {
    onColumnVisibilityChange({
      ...columnVisibility,
      [key]: !columnVisibility[key],
    });
  };

  const [open, { onHide, show }] = useModalState(false);

  return (
    <>
      <Button
        data-bni-id="DataGridColumnPicker"
        id="study-grid-column-spec"
        variant="text-secondary"
        css={css`
          box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
        `}
        className={clsx(
          'rounded-full p-0 w-6 h-6 bg-grey-50 text-grey-85',
          className,
        )}
        onClick={() => (show ? onHide() : open())}
      >
        <PlusIcon width={16} height={16} />
      </Button>
      <Modal size="sm" show={show} onHide={onHide} variant="dark">
        <Modal.Body scrollable={false}>
          <SortableDndContext
            onDragStart={() => setDragging(true)}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableList
              items={columnOrder}
              data-bni-id="DataGridColumnPickerContent"
              className="max-h-full scrollable-y"
            >
              {columns.map((column) => (
                <SortableList.Item
                  key={column.key}
                  id={column.key}
                  as={Layout}
                  className={clsx(
                    column.columns && 'border-t border-b border-divider',
                    dragging && 'max-h-12 overflow-hidden',
                  )}
                  direction={column.columns ? 'column' : 'row'}
                  justify="space-between"
                  draggingClassName="bg-grey-80"
                >
                  {column.columns && (
                    <Layout className="px-4 py-3" justify="space-between">
                      <Text color="subtitle">{column.label}</Text>
                      {!column.fixed && reorderable && (
                        <SortableList.DragHandle>
                          <HamburgerMenuIcon width={14} />
                        </SortableList.DragHandle>
                      )}
                    </Layout>
                  )}

                  {(column.columns ? column.columns : [column]).map(
                    ({ key, fixed, label }) => (
                      <FormCheck
                        key={key}
                        data-rr-ui-dropdown-item
                        data-bni-id={`DataGridColumnPicker:${key}`}
                        className="flex mb-0 px-4 py-3 flex-grow"
                        checked={!!columnVisibility[key]}
                        onChange={() => handleChecked(key)}
                        disabled={fixed}
                      >
                        {label}
                      </FormCheck>
                    ),
                  )}

                  {!column.columns && !column.fixed && reorderable && (
                    // sort handle for non-grouped columns
                    <SortableList.DragHandle>
                      <HamburgerMenuIcon width={14} className="mr-4" />
                    </SortableList.DragHandle>
                  )}
                </SortableList.Item>
              ))}
              <SortableDragOverlay>
                <div className="h-8" />
              </SortableDragOverlay>
            </SortableList>
          </SortableDndContext>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DataGridColumnPicker;
