/**
 * @generated SignedSource<<0e8c28d99f94dd88ccb25625d4467bf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendStudyToPacsButton_study$data = {
  readonly id: string;
  readonly " $fragmentType": "SendStudyToPacsButton_study";
};
export type SendStudyToPacsButton_study$key = {
  readonly " $data"?: SendStudyToPacsButton_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"SendStudyToPacsButton_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SendStudyToPacsButton_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "2448a59c360b170ed39765be80d78ae2";

export default node;
