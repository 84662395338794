import { RouteMatch } from 'found';
import useRouter from 'found/useRouter';
import { ReadonlyDeep } from 'type-fest';

type PageState = {
  after?: string | null;
};

export interface PaginationMeta {
  index: number;
  numEdges: number;
  loadNext: () => void;
  loadPrevious: () => void;
}

export type ConnectionPagination = ReadonlyDeep<{
  index: number | null;
  numEdges: number | null;
  pageInfo: {
    endCursor: string | null;
  } | null;
  edges:
    | ({
        cursor: string;
      } | null)[]
    | null;
}>;

export function getPaginationVariables(
  location: RouteMatch['location'],
  namespace: string,
): PageState {
  return location.state?.paginationVariables?.[namespace] || {};
}

export default function usePaginationResults<T extends ConnectionPagination>(
  data: T | null | undefined,
  namespace: string,
  pageSize = 25,
) {
  const { match, router } = useRouter();

  if (!data) {
    return { edges: null };
  }

  const setPageState = (pageState: PageState) => {
    router.replace({
      ...match.location,
      state: {
        ...match.location.state,
        paginationVariables: {
          ...match.location.state?.paginationVariables,
          [namespace]: pageState,
        },
      },
    });
  };

  const pageState: PageState = getPaginationVariables(
    match.location,
    namespace,
  );

  const allEdges = (data?.edges || []) as NonNullable<
    NonNullable<T['edges']>[number]
  >[];

  let edges = allEdges;

  // we have more edges than the page size so we need to reduce them
  if (edges.length > pageSize) {
    const afterIndex = pageState.after
      ? edges.findIndex(({ cursor }) => cursor === pageState.after)
      : 0;
    const beforeIndex = afterIndex + pageSize;
    edges = edges.filter((_, i) => i > afterIndex && i <= beforeIndex);
  }

  const dataIndex = data?.index || 0;

  return {
    edges,
    paginationMeta: {
      loadNext: () =>
        setPageState({ after: allEdges[dataIndex + pageSize - 1]?.cursor }),
      loadPrevious: () =>
        setPageState({ after: allEdges[dataIndex - pageSize - 1]?.cursor }),
      numEdges: data?.numEdges || 0,
      index: data?.index || 0,
    },
  };
}
