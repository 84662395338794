import Layout from '@4c/layout';
import { FormattedMessage } from 'react-intl';

export default function NotificationNone() {
  return (
    <Layout direction="column" justify="center" className="text-center py-3">
      <FormattedMessage
        id="notificationCenter.notificationList.noNotifications.description"
        defaultMessage="You have no notifications."
      />
    </Layout>
  );
}
