/**
 * @generated SignedSource<<4b1b625eead13c49e3ef0c3dee7bef08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThirdPartyUltrasoundListenerConfigurationContent_viewer$data = {
  readonly domain: {
    readonly dicomFieldTemplateConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly label: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "ThirdPartyUltrasoundListenerConfigurationContent_viewer";
};
export type ThirdPartyUltrasoundListenerConfigurationContent_viewer$key = {
  readonly " $data"?: ThirdPartyUltrasoundListenerConfigurationContent_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThirdPartyUltrasoundListenerConfigurationContent_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "domain",
          "dicomFieldTemplateConnection"
        ]
      }
    ]
  },
  "name": "ThirdPartyUltrasoundListenerConfigurationContent_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": "dicomFieldTemplateConnection",
          "args": null,
          "concreteType": "DomainDicomFieldTemplateConnection",
          "kind": "LinkedField",
          "name": "__Domain_dicomFieldTemplateConnection_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DomainDicomFieldTemplateEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DomainDicomFieldTemplate",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "d3f52c539fe690e1a108b87b7ddd6007";

export default node;
