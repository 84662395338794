/**
 * @generated SignedSource<<08a472853bfea52d2944d4bb96d8b1f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteArchiveMembershipInput = {
  archiveMembershipId: any;
  clientMutationId?: string | null;
};
export type ArchiveMemberItemGridRow_DeleteMutation$variables = {
  input: DeleteArchiveMembershipInput;
};
export type ArchiveMemberItemGridRow_DeleteMutation$data = {
  readonly deleteArchiveMembershipOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
  } | null;
};
export type ArchiveMemberItemGridRow_DeleteMutation = {
  response: ArchiveMemberItemGridRow_DeleteMutation$data;
  variables: ArchiveMemberItemGridRow_DeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchiveMemberItemGridRow_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteArchiveMembershipOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArchiveMemberItemGridRow_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteArchiveMembershipOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb97d272d75a876ce4dc575af15e2b66",
    "id": null,
    "metadata": {},
    "name": "ArchiveMemberItemGridRow_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ArchiveMemberItemGridRow_DeleteMutation(\n  $input: DeleteArchiveMembershipInput!\n) {\n  deleteArchiveMembershipOrError(input: $input) {\n    __typename\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "243dd79a9ae5daf933e4cc934c040ea6";

export default node;
