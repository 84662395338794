/**
 * @generated SignedSource<<a706987c12ed025f0aa27475100a108e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EhrOruJobStatus = "CANCELED" | "COMPLETE" | "FAILED" | "PENDING" | "RETRYING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyEhrJobDetail_job$data = {
  readonly status: EhrOruJobStatus | null;
  readonly " $fragmentSpreads": FragmentRefs<"CancelEhrOruJobButton_job" | "EhrSyncStatus_job" | "SendStudyToEhrButton_job">;
  readonly " $fragmentType": "StudyEhrJobDetail_job";
};
export type StudyEhrJobDetail_job$key = {
  readonly " $data"?: StudyEhrJobDetail_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyEhrJobDetail_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyEhrJobDetail_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EhrSyncStatus_job"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendStudyToEhrButton_job"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelEhrOruJobButton_job"
    }
  ],
  "type": "EhrOruJob",
  "abstractKey": null
};

(node as any).hash = "db18dc98aed3929c135f979f607213fb";

export default node;
