/**
 * @generated SignedSource<<32c7706dfe56f87b3c5976b63bb616f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetArchivePacsAssociationsInput = {
  archiveId: string;
  clientMutationId?: string | null;
  pacsIds: ReadonlyArray<string>;
};
export type PacsArchiveAssociationForm_Mutation$variables = {
  input: SetArchivePacsAssociationsInput;
};
export type PacsArchiveAssociationForm_Mutation$data = {
  readonly setArchivePacsAssociationsOrError: {
    readonly __typename?: string;
    readonly archive?: {
      readonly pacsAssociations: ReadonlyArray<{
        readonly id: string;
      } | null> | null;
      readonly " $fragmentSpreads": FragmentRefs<"PacsArchiveAssociationForm_archive">;
    } | null;
    readonly message?: string | null;
  } | null;
};
export type PacsArchiveAssociationForm_Mutation = {
  response: PacsArchiveAssociationForm_Mutation$data;
  variables: PacsArchiveAssociationForm_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PacsArchiveAssociationForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setArchivePacsAssociationsOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Archive",
                "kind": "LinkedField",
                "name": "archive",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PacsArchiveAssociation",
                    "kind": "LinkedField",
                    "name": "pacsAssociations",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PacsArchiveAssociationForm_archive"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SetArchivePacsAssociationsPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PacsArchiveAssociationForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setArchivePacsAssociationsOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Archive",
                "kind": "LinkedField",
                "name": "archive",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PacsArchiveAssociation",
                    "kind": "LinkedField",
                    "name": "pacsAssociations",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Pacs",
                        "kind": "LinkedField",
                        "name": "pacs",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "SetArchivePacsAssociationsPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc6c03e8838f608520a1c4392b599d8d",
    "id": null,
    "metadata": {},
    "name": "PacsArchiveAssociationForm_Mutation",
    "operationKind": "mutation",
    "text": "mutation PacsArchiveAssociationForm_Mutation(\n  $input: SetArchivePacsAssociationsInput!\n) {\n  setArchivePacsAssociationsOrError(input: $input) {\n    __typename\n    ... on SetArchivePacsAssociationsPayload {\n      archive {\n        pacsAssociations {\n          id\n        }\n        ...PacsArchiveAssociationForm_archive\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n\nfragment PacsArchiveAssociationForm_archive on Archive {\n  id\n  pacsAssociations {\n    pacs {\n      id\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "af8e28964c7aec883bc049fecf4dc820";

export default node;
