import Layout from '@4c/layout';
import DropdownList from '@bfly/ui2/DropdownList';
import Form, { useField } from '@bfly/ui2/Form';
import MutationButton from '@bfly/ui2/MutationButton';
import getNodes from '@bfly/utils/getNodes';
import React, { useMemo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import { useVariation } from 'components/LaunchDarklyContext';
import PermissionTooltip from 'components/PermissionTooltip';
import actionMessages from 'messages/actions';
import connectionMessages from 'messages/connections';
import ALLOWED_TIMEZONES, { Timezone } from 'schemas/allowedTimezones';
import { usePermissions } from 'utils/viewerContext';

import type { ThirdPartyUltrasoundListenerConfigurationContent_ClearMutation as ClearMutation } from './__generated__/ThirdPartyUltrasoundListenerConfigurationContent_ClearMutation.graphql';
import type { ThirdPartyUltrasoundListenerConfigurationContent_archive$data as Archive } from './__generated__/ThirdPartyUltrasoundListenerConfigurationContent_archive.graphql';
import type { ThirdPartyUltrasoundListenerConfigurationContent_viewer$data as Viewer } from './__generated__/ThirdPartyUltrasoundListenerConfigurationContent_viewer.graphql';

const messages = defineMessages({
  autoAssignDicomFieldTemplateLabel: {
    id: 'archive.settings.tpus.connection.dicomField.label',
    defaultMessage: 'Primary Author DICOM Field',
  },
  autoAssignSecondaryDicomFieldTemplateLabel: {
    id: 'archive.settings.tpus.connection.dicomField.secondary.label',
    defaultMessage: 'Secondary Author DICOM Field',
  },
  autoAssignDicomFieldTemplateDescription: {
    id: 'archive.settings.tpus.connection.dicomField.description',
    defaultMessage:
      'Auto assign primary and secondary authors based upon the selected author DICOM field template.',
  },
});

const clearMutation = graphql`
  mutation ThirdPartyUltrasoundListenerConfigurationContent_ClearMutation(
    $input: ClearArchiveThirdPartyUltrasoundListenerInput!
  ) {
    clearArchiveThirdPartyUltrasoundListenerOrError(input: $input) {
      ... on ClearArchiveThirdPartyUltrasoundListenerPayload {
        archive {
          ...ThirdPartyUltrasoundListenerConfiguration_archive
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

type DicomFieldTemplateConnection =
  DeepNonNullable<Viewer>['domain']['dicomFieldTemplateConnection'];
const useDicomFieldTemplates = (connection?: DicomFieldTemplateConnection) => {
  const [primaryField, _] = useField('autoAssignDicomFieldTemplateId');
  const [secondaryField, _2] = useField(
    'autoAssignSecondaryDicomFieldTemplateId',
  );
  const dicomFieldTemplates = useMemo(
    () => (connection ? getNodes(connection) : []),
    [connection],
  );

  const primaryDicomFields = dicomFieldTemplates.filter(
    (fieldTemplate) => fieldTemplate.id !== secondaryField.value,
  );

  const secondaryDicomFields = dicomFieldTemplates.filter(
    (fieldTemplate) => fieldTemplate.id !== primaryField.value,
  );
  return [
    primaryDicomFields,
    secondaryDicomFields,
    dicomFieldTemplates,
  ] as const;
};

export type TpusArchiveRouting = 'unknown' | 'scu_aet' | 'port';

interface Props {
  showClearButton: boolean;
  clearForm(data): void;
  viewer: Viewer;
  archive: Archive;
  editable: boolean;
  routing: TpusArchiveRouting;
}

function TpusArchiveRouting({
  routing,
  editable,
}: {
  routing: TpusArchiveRouting;
  editable: boolean;
}) {
  switch (routing) {
    case 'port':
      return (
        <Form.FieldGroup
          name="clientPort"
          label={connectionMessages.port}
          placeholder={connectionMessages.portPlaceholder}
          disabled={!editable}
        />
      );
    case 'scu_aet':
      return (
        <Form.FieldGroup
          name="scuAet"
          label={connectionMessages.scuAet}
          placeholder={connectionMessages.scuAetPlaceholder}
          disabled={!editable}
        />
      );
    case 'unknown':
    default:
      return null;
  }
}

const ThirdPartyUltrasoundListenerConfigurationContent = ({
  showClearButton,
  clearForm,
  viewer,
  archive,
  editable,
  routing,
}: Props) => {
  const canUseSecondaryDicomField = useVariation('secondary-dicom-field');
  const { hasAdminPermission } = usePermissions();

  const hasPermissions = hasAdminPermission('connectivityManagement');
  const connection = viewer.domain?.dicomFieldTemplateConnection;
  const [primaryDicomFields, secondaryDicomFields, dicomFieldTemplates] =
    useDicomFieldTemplates(connection as DicomFieldTemplateConnection);
  return (
    <>
      <TpusArchiveRouting routing={routing} editable={editable} />

      <Form.FieldGroup
        name="timezone"
        as={DropdownList}
        data={ALLOWED_TIMEZONES}
        textField="name"
        dataKey="value"
        mapFromValue={(v: Timezone) => v.value}
        label={connectionMessages.timezone}
        placeholder={connectionMessages.timezone}
        disabled={!editable || !hasPermissions}
      />

      {!!dicomFieldTemplates.length && (
        <>
          <Form.FieldGroup
            name="autoAssignDicomFieldTemplateId"
            as={DropdownList}
            data={primaryDicomFields}
            textField="label"
            dataKey="id"
            mapFromValue={(v: typeof primaryDicomFields[number]) => v.id}
            label={messages.autoAssignDicomFieldTemplateLabel}
            placeholder={messages.autoAssignDicomFieldTemplateLabel}
            data-bni-id="autoAssignDicomFieldTemplateId"
            disabled={!editable || !hasPermissions}
          />
          {canUseSecondaryDicomField && (
            <Form.FieldGroup
              name="autoAssignSecondaryDicomFieldTemplateId"
              as={DropdownList}
              data={secondaryDicomFields}
              textField="label"
              dataKey="id"
              mapFromValue={(v: typeof secondaryDicomFields[number]) => v.id}
              label={messages.autoAssignSecondaryDicomFieldTemplateLabel}
              placeholder={messages.autoAssignSecondaryDicomFieldTemplateLabel}
              data-bni-id="autoAssignSecondaryDicomFieldTemplateId"
              disabled={!editable || !hasPermissions}
            />
          )}
          <Form.Description>
            <FormattedMessage
              {...messages.autoAssignDicomFieldTemplateDescription}
            />
          </Form.Description>
        </>
      )}

      <Layout justify="flex-end" align="center" pad>
        {!editable && (
          <FormattedMessage
            id="archive.settings.tpus.editingDisabled"
            defaultMessage="Editing a third-party integration is no longer supported. Please delete the integration and create a new one."
          />
        )}
      </Layout>

      <Layout justify="flex-end" align="center" pad>
        {showClearButton && (
          <PermissionTooltip wrap hide={hasPermissions}>
            <MutationButton<ClearMutation>
              disabled={!hasPermissions}
              variant="text-danger"
              className={`${!hasPermissions && 'pointer-events-none'}`}
              mutation={clearMutation}
              onCompleted={clearForm}
              input={{
                archiveId: archive.id,
              }}
              successToastMessage={
                <FormattedMessage
                  id="archive.settings.tpus.delete.success"
                  defaultMessage="Third-Party Ultrasound Listener cleared"
                />
              }
            >
              <FormattedMessage {...actionMessages.delete} />
            </MutationButton>
          </PermissionTooltip>
        )}
        <PermissionTooltip wrap hide={hasPermissions}>
          <Form.Submit
            variant="primary"
            data-bni-id="SaveThirdPartyConfigurationButton"
            disabled={!editable || !hasPermissions}
          >
            <FormattedMessage {...actionMessages.save} />
          </Form.Submit>
        </PermissionTooltip>
      </Layout>
    </>
  );
};

export default createFragmentContainer(
  ThirdPartyUltrasoundListenerConfigurationContent,
  {
    archive: graphql`
      fragment ThirdPartyUltrasoundListenerConfigurationContent_archive on Archive {
        id
      }
    `,
    viewer: graphql`
      fragment ThirdPartyUltrasoundListenerConfigurationContent_viewer on Viewer {
        domain {
          dicomFieldTemplateConnection(first: 2147483647)
            @connection(key: "Domain_dicomFieldTemplateConnection") {
            edges {
              node {
                id
                label
              }
            }
          }
        }
      }
    `,
  },
);
