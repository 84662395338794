import TrashCanIcon from '@bfly/icons/TrashCan';
import useDialog from '@bfly/ui2/useDialog';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import useRouter from 'found/useRouter';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';
import { useExamRoutes } from 'routes/exam';
import { StudyListFilter } from 'utils/StudyConstants';
import { useCanPermanentlyDeleteStudy } from 'utils/StudyPermissions';

import type { PermanentlyDeleteStudyControlMutation } from './__generated__/PermanentlyDeleteStudyControlMutation.graphql';
import type { PermanentlyDeleteStudyControl_study$data as Study } from './__generated__/PermanentlyDeleteStudyControl_study.graphql';

const mutation = graphql`
  mutation PermanentlyDeleteStudyControlMutation(
    $input: PermanentlyDeleteStudyInput!
  ) {
    permanentlyDeleteStudyOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  study: Study;
  as: React.ElementType<{
    icon?: React.ReactElement;
    onClick: any;
    variant?: 'danger' | null;
  }>;
}

function PermanentlyDeleteStudyControl({ as: Component, study }: Props) {
  const examRoutes = useExamRoutes();
  const dialog = useDialog();
  const toast = useToast();
  const { match, router } = useRouter();

  const [mutate] = useMutationWithError<PermanentlyDeleteStudyControlMutation>(
    mutation,
    {
      input: {
        studyId: study.id,
      },
      onError: () => {
        toast.error(
          <FormattedMessage
            id="study.permanentlyDelete.failure"
            defaultMessage="Unknown error prevented permanent deletion."
          />,
        );
      },
      updater: (store) => {
        [
          'Organization_studyConnection',
          'Organization_studySearchConnection',
        ].forEach((connKey) => {
          rangeDeleteUpdater(store, {
            parentId: study.organization!.id,
            connectionKey: connKey,
            connectionFilters: () => true,
            deletedId: study.id,
          });
        });
      },
    },
  );

  const canPermanentlyDeleteStudy = useCanPermanentlyDeleteStudy(study);

  if (!canPermanentlyDeleteStudy) {
    return null;
  }

  const handleClick = async () => {
    const permanentlyDeleted = await dialog.open(
      <FormattedMessage
        id="study.permanentlyDelete.dialog.content"
        defaultMessage="This exam will be permanently deleted. You will not be able to restore this exam."
      />,
      {
        runConfirm: mutate,
        confirmButtonProps: { variant: 'danger', size: 'lg' },
        modalVariant: 'dark',
        title: (
          <FormattedMessage
            id="study.permanentlyDelete.dialog.title"
            defaultMessage="Permanently delete exam?"
          />
        ),
        confirmLabel: <FormattedMessage {...actionMessages.delete} />,
      },
    );

    if (!permanentlyDeleted) {
      return;
    }

    toast.success(
      <FormattedMessage
        id="study.permanentlyDelete.success"
        defaultMessage="Exam permanently deleted"
      />,
    );

    // If we're on a study detail view, navigate away after deleting the study.
    if (match.params.studyHandle) {
      router.replace(
        examRoutes.examLists({
          organizationSlug: study.organization!.slug!,
          status: StudyListFilter.DELETED,
        }),
      );
    }
  };

  return (
    <Component icon={<TrashCanIcon />} onClick={handleClick} variant="danger">
      <FormattedMessage
        id="study.permanentlyDelete"
        defaultMessage="Permanently delete"
      />
    </Component>
  );
}

export default createFragmentContainer(PermanentlyDeleteStudyControl, {
  study: graphql`
    fragment PermanentlyDeleteStudyControl_study on Study {
      id
      organization {
        id
        slug
      }
      ...StudyPermissions_canPermanentlyDeleteStudy
    }
  `,
});
