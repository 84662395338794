import PlayCircleIcon from '@bfly/icons/PlayCircle';
import Link from '@bfly/ui2/Link';
import useRouter from 'found/useRouter';
import { createFragmentContainer, graphql } from 'react-relay';

import Thumbnail from 'components/Thumbnail';
import { useArchiveRoutes } from 'routes/archive';

import { MobileSharedImageList_images$data as Images } from './__generated__/MobileSharedImageList_images.graphql';

const ICON_SIZE = 80;

interface Props {
  images: Images;
}

function MobileSharedImageList({ images }: Props) {
  const archiveRoutes = useArchiveRoutes();
  const { sharedBundleHandle } = useRouter().match.params;

  return (
    <div data-bni-id="MobileSharedImageList">
      {images.map((image, index) => {
        const { isVideo } = image!.burnedInFile!;

        return (
          <Link
            key={image.id}
            to={archiveRoutes.sharedBundleImage({
              sharedBundleHandle,
              sharedBundleImageHandle: image.handle!,
            } as any)}
            className="relative block overflow-hidden mb-5 active:opacity-50"
          >
            <Thumbnail
              image={image.burnedInFile!}
              alt={`${isVideo ? 'Cine' : 'Still'} ${index + 1}`}
              className="w-full"
            />
            {isVideo && (
              <PlayCircleIcon
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                height={ICON_SIZE}
                width={ICON_SIZE}
              />
            )}
          </Link>
        );
      })}
    </div>
  );
}

export default createFragmentContainer(MobileSharedImageList, {
  images: graphql`
    fragment MobileSharedImageList_images on SharedBundleImage
    @relay(plural: true) {
      id
      handle
      burnedInFile {
        isVideo
        ...Thumbnail_image
      }
    }
  `,
});
