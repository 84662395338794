import BlankSlate from '@bfly/ui2/BlankSlate';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import { useVariation } from 'components/LaunchDarklyContext';

import educationMessages from '../messages/educationMessages';
import EducationContentTile from './EducationContentTile';
import ShareEducationContentModal from './ShareEducationContentModal';
import { EmptyContentListScreen_Query as Query } from './__generated__/EmptyContentListScreen_Query.graphql';

type EducationContent =
  DeepNonNullable<Query>['response']['lms']['educationContentConnection']['edges'][0]['node'];

export default function EmptyContentListScreen() {
  const butterflyAcademyAccess = useVariation('butterfly-academy-access');
  const [sharedRecord, setSharedRecord] = useState<EducationContent | null>(
    null,
  );

  const { data } = useQuery<Query>(
    graphql`
      query EmptyContentListScreen_Query(
        $contentType: [EducationContentType!]
      ) {
        lms {
          educationContentConnection(
            first: 8
            contentType: $contentType
            sort: [RANKING_ASC]
          ) {
            edges {
              node {
                id
                ...EducationContentTile_educationContent
                ...ShareEducationContentModal_educationContent
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-or-network',
      variables: {
        contentType: [
          butterflyAcademyAccess !== 'hidden' ? 'COURSE' : 'VIDEO',
        ],
      },
    },
  );

  const educationContentList = data?.lms?.educationContentConnection
    ? getNodes(data?.lms?.educationContentConnection)
    : [];

  return (
    <>
      <BlankSlate className="py-12" data-bni-id="EducationContentBlankSlate">
        <BlankSlate.Title>
          <FormattedMessage {...educationMessages.noResults} />
          <span className="block">
            <FormattedMessage {...educationMessages.suggestedContent} />
          </span>
        </BlankSlate.Title>
      </BlankSlate>
      {data ? (
        educationContentList.length > 0 && (
          <>
            <ShareEducationContentModal
              show={!!sharedRecord}
              educationContent={sharedRecord}
              onHide={() => setSharedRecord(null)}
            />
            <Text variant="sm-bold" transform="uppercase" className="py-4">
              <FormattedMessage {...educationMessages.newestContent} />
            </Text>
            <div
              data-bni-id="EducationContentSuggestedContent"
              className="grid gap-3 auto-rows-[0] grid-rows-[auto] grid-cols-[repeat(auto-fill,_minmax(27rem,_1fr))] overflow-hidden"
            >
              {educationContentList.map(
                (educationContent: EducationContent) => (
                  <EducationContentTile
                    key={educationContent.id}
                    educationContent={educationContent}
                    onShare={() => setSharedRecord(educationContent)}
                  />
                ),
              )}
            </div>
          </>
        )
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
}
