import FormCheck, { FormCheckProps } from '@bfly/ui2/FormCheck';
import React, { useEffect, useImperativeHandle, useRef } from 'react';

interface IndeterminateFormCheckProps extends FormCheckProps {
  indeterminate?: boolean;
}

const IndeterminateFormCheck = React.forwardRef(
  (
    { indeterminate, ...props }: IndeterminateFormCheckProps,
    forwardRef: React.Ref<HTMLInputElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(forwardRef, () => inputRef.current!);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate]);

    return <FormCheck {...props} ref={inputRef} />;
  },
);

export default IndeterminateFormCheck;
