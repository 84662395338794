import BookmarkIcon from '@bfly/icons/Bookmark';
import BookmarkOutlineIcon from '@bfly/icons/BookmarkOutline';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import IconButton from 'components/IconButton';

import { ImageToggleBookmarkButton_UpdateIsImportantStudyImageMutation as UpdateIsImportantStudyImageMutation } from './__generated__/ImageToggleBookmarkButton_UpdateIsImportantStudyImageMutation.graphql';
import { ImageToggleBookmarkButton_studyImage$data as StudyImage } from './__generated__/ImageToggleBookmarkButton_studyImage.graphql';

interface Props {
  studyImage: StudyImage;
}

function ImageToggleBookmarkButton({ studyImage }: Props) {
  const { id, isImportant } = studyImage;
  const Icon = isImportant ? BookmarkIcon : BookmarkOutlineIcon;

  const [updateIsImportantStudyImage] =
    useMutationWithError<UpdateIsImportantStudyImageMutation>(
      graphql`
        mutation ImageToggleBookmarkButton_UpdateIsImportantStudyImageMutation(
          $input: UpdateIsImportantStudyImageInput!
        ) @raw_response_type {
          updateIsImportantStudyImageOrError(input: $input) {
            ... on UpdateIsImportantStudyImagePayload {
              studyImage {
                isImportant
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `,
    );

  return (
    <IconButton
      data-bni-id="imageToggleBookmarkButton"
      icon={<Icon width={20} height={20} />}
      onClick={() =>
        updateIsImportantStudyImage({
          input: {
            imageId: id,
            isImportant: !isImportant,
          },
          optimisticResponse: {
            // @ts-ignore
            updateIsImportantStudyImageOrError: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              __typename: 'UpdateIsImportantStudyImagePayload',
              message: null,
              studyImage: {
                id,
                isImportant: !isImportant,
              },
            },
          },
        })
      }
      title={
        isImportant ? (
          <FormattedMessage
            id="imageToggleBookmarkButton.removeBookmark"
            defaultMessage="Remove Bookmark"
          />
        ) : (
          <FormattedMessage
            id="imageToggleBookmarkButton.bookmark"
            defaultMessage="Bookmark"
          />
        )
      }
    />
  );
}

export default createFragmentContainer(ImageToggleBookmarkButton, {
  studyImage: graphql`
    fragment ImageToggleBookmarkButton_studyImage on StudyImage {
      id
      isImportant
    }
  `,
});
