import Header from '@bfly/ui2/Header';
import React from 'react';
import { Helmet } from 'react-helmet';
import { MessageDescriptor, useIntl } from 'react-intl';

interface Props {
  title: MessageDescriptor | string;
  displayTitle?: React.ReactNode;
  className?: string;
  values?: Record<string, any>;
  variant?: React.ComponentProps<typeof Header.Title>['variant'];
}

function PageTitle({
  title,
  displayTitle,
  className,
  values,
  variant = 'lg',
}: Props) {
  const { formatMessage } = useIntl();

  const formattedTitle =
    typeof title === 'string' ? title : formatMessage(title, values);

  return (
    <>
      <Header.Title className={className} variant={variant}>
        {displayTitle || formattedTitle}
      </Header.Title>
      <Helmet>
        <title>{formattedTitle}</title>
      </Helmet>
    </>
  );
}

export default PageTitle;
