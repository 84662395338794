/**
 * @generated SignedSource<<3020328307f1ee3fe8db27e9a7c08dea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy$data = {
  readonly archive: {
    readonly handle: string | null;
    readonly id: string;
    readonly label: string | null;
    readonly organization: {
      readonly archiveConnection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly label: string | null;
          } | null;
        } | null> | null;
      } | null;
      readonly id: string;
    } | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy";
};
export type SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy$key = {
  readonly " $data"?: SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 2147483647
                }
              ],
              "concreteType": "ArchiveConnection",
              "kind": "LinkedField",
              "name": "archiveConnection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArchiveEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Archive",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "archiveConnection(first:2147483647)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "1ee8823b9964d0210d1f8d4d28502076";

export default node;
