/**
 * @generated SignedSource<<52b7ad358570661de7e16dc0d7145cc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DefaultQaNotificationRecipients = "EVERYONE" | "NO_ONE" | "%future added value";
export type UpdateArchiveInput = {
  archiveId: any;
  attestationMessage?: string | null;
  autoFinalizeNbdExams?: boolean | null;
  clientMutationId?: string | null;
  defaultQaNotificationRecipients?: DefaultQaNotificationRecipients | null;
  examTypeRequiredToFinalize?: boolean | null;
  excludedStudies?: boolean | null;
  hasRestrictedFinalization?: boolean | null;
  label?: string | null;
  multipleWorksheetsEnabled?: boolean | null;
  patientIdRequiredToFinalize?: boolean | null;
  studyAuthorRequiredToRequestFinalization?: boolean | null;
  worklistRequiredToFinalize?: boolean | null;
  worksheetRequiredForQa?: boolean | null;
  worksheetRequiredToFinalize?: boolean | null;
};
export type UpdateArchiveMutation$variables = {
  input: UpdateArchiveInput;
};
export type UpdateArchiveMutation$data = {
  readonly updateArchive: {
    readonly archive: {
      readonly label: string | null;
    } | null;
  } | null;
};
export type UpdateArchiveMutation = {
  response: UpdateArchiveMutation$data;
  variables: UpdateArchiveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateArchiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateArchivePayload",
        "kind": "LinkedField",
        "name": "updateArchive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Archive",
            "kind": "LinkedField",
            "name": "archive",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateArchiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateArchivePayload",
        "kind": "LinkedField",
        "name": "updateArchive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Archive",
            "kind": "LinkedField",
            "name": "archive",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dce50d46121931e3cfe428c519698558",
    "id": null,
    "metadata": {},
    "name": "UpdateArchiveMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateArchiveMutation(\n  $input: UpdateArchiveInput!\n) {\n  updateArchive(input: $input) {\n    archive {\n      label\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee4c4c60d3c9772521eb86fcd2a2eb16";

export default node;
