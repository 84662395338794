/**
 * @generated SignedSource<<59da2c59528e0db14829a8d2eb258647>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamAuthorsFieldUsers_Query$variables = {
  ids: ReadonlyArray<string>;
};
export type ExamAuthorsFieldUsers_Query$data = {
  readonly users: ReadonlyArray<{
    readonly id?: string;
    readonly name?: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile" | "RelatedUsersPopoverListItems_users">;
  } | null>;
};
export type ExamAuthorsFieldUsers_Query = {
  response: ExamAuthorsFieldUsers_Query$data;
  variables: ExamAuthorsFieldUsers_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamAuthorsFieldUsers_Query",
    "selections": [
      {
        "alias": "users",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Avatar_userProfile"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RelatedUsersPopoverListItems_users"
              }
            ],
            "type": "UserProfile",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamAuthorsFieldUsers_Query",
    "selections": [
      {
        "alias": "users",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Avatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Thumbnail",
                        "kind": "LinkedField",
                        "name": "thumbnails",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "width",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "ThumbnailedInterface",
                    "abstractKey": "__isThumbnailedInterface"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UserProfile",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "62f08698e933ac20e7b2b7b0a4b80e2a",
    "id": null,
    "metadata": {},
    "name": "ExamAuthorsFieldUsers_Query",
    "operationKind": "query",
    "text": "query ExamAuthorsFieldUsers_Query(\n  $ids: [ID!]!\n) {\n  users: nodes(ids: $ids) {\n    __typename\n    ... on UserProfile {\n      id\n      name\n      ...Avatar_userProfile\n      ...RelatedUsersPopoverListItems_users\n    }\n    id\n  }\n}\n\nfragment AvatarCollage_userProfiles on UserProfile {\n  id\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment RelatedUsersPopoverListItems_users on UserProfile {\n  id\n  name\n  ...AvatarCollage_userProfiles\n  ...Avatar_userProfile\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e71bcc99f76daea9a3a93af0640c973";

export default node;
