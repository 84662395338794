/**
 * @generated SignedSource<<ecc8b06a0c08c812729a4e612ac03416>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddDomainMemberModal_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DomainMemberAccessRoleFieldSet_viewer">;
  readonly " $fragmentType": "AddDomainMemberModal_viewer";
};
export type AddDomainMemberModal_viewer$key = {
  readonly " $data"?: AddDomainMemberModal_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddDomainMemberModal_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddDomainMemberModal_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DomainMemberAccessRoleFieldSet_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "cb4c60d470809b7a244719f00d64d8d5";

export default node;
