import { Param, join, route } from '@4c/spa-routing';
import { useRouter } from 'found';
import mapValues from 'lodash/mapValues';
import { useMemo } from 'react';

import { Match } from 'components/Route';

import { MakeParamOptional } from '../utils/RouteUtils';

type OrgParam = Param<'organizationSlug', string>;

const rootRoute = route('');

//
// Enterprise only routes
// -----------------------------------

const enterpriseRoot = join(rootRoute, '-');
const enterpriseSettings = join(enterpriseRoot, 'enterprise/settings');

const organizations = join(enterpriseSettings, 'organizations');
const domainMembers = join(enterpriseSettings, 'members');
const domainMembersActive = join(domainMembers, 'active');
const domainMembersInactive = join(domainMembers, 'inactive');
const domainRoles = join(enterpriseSettings, 'roles');

const domainMember = join(domainMembers, ':domainUserId');
const domainMemberRoles = join(domainMember, 'roles');
const doaminRoleDefintion = join(domainRoles, ':membershipRoleId/definition');
const domainRoleNew = join(domainRoles, 'new');
const domainRoleDuplicate = join(domainRoles, ':membershipRoleId/duplicate');
const domainRoleUpdate = join(domainRoles, ':membershipRoleId');

const sso = join(enterpriseSettings, 'sso');
const sessionTimeout = join(enterpriseSettings, 'inactivity');
const fleet = join(enterpriseSettings, 'fleet');
const mdm = join(enterpriseSettings, 'mdm');

const dicomFieldTemplates = join(enterpriseSettings, 'dicom-field-templates');
const auditLogs = join(enterpriseSettings, 'audit-logs');
const dicomFieldTemplate = join(dicomFieldTemplates, ':templateHandle');
const dicomFieldTemplateNew = join(dicomFieldTemplates, '-/new');

//
// Organization only routes
// -----------------------------------

const organizationAdmin = route('/:organizationSlug/admin');

const organizationGeneral = join(organizationAdmin, 'settings');
const organizationMembers = join(organizationAdmin, 'members');
const organizationSubscription = join(organizationAdmin, 'membership');

const annotationExtensions = join(organizationAdmin, 'annotation-extensions');
const annotationExtension = join(annotationExtensions, ':extensionHandle');
const annotationExtensionNew = join(annotationExtensions, '-/new');

//
// Common routes
//
// These routes have no root, because it's context dependent. they apply
// to domain config and org config
// -----------------------------------

const tags = route('study-tags');
const examTypes = route('exam-types');

const proficiencyGroups = route('proficiency-groups');
const historicalProficiency = route('historical-proficiency');

const worksheets = route('worksheets');
const worksheet = join(worksheets, ':templateHandle');
const worksheetNew = join(worksheets, '-/new');

const worksheetPrefills = join(worksheet, 'prefills');
const worksheetPrefill = join(worksheetPrefills, ':prefillHandle');
const worksheetPrefillNew = join(worksheetPrefills, '-/new');

const qa = route('qa');
const qaUpdate = join(qa, ':templateHandle');
const qaNew = join(qa, '-/new');

const connectivity = route('connectivity');

const connections = join(connectivity, 'connections');
const butterflyLinks = join(connections, 'butterfly-link');
const butterflyLink = join(butterflyLinks, ':hospitalConnectionHandle');
const butterflyLinkNew = join(
  join(butterflyLinks, '-/new'),
  ':hospitalConnectionHandle',
);

const dicoms = join(connections, 'dicom');
const dicom = join(dicoms, ':hospitalConnectionHandle');
const dicomNew = join(dicoms, '-/new');

const integrations = join(connectivity, 'integrations');

function createIntegration<T extends string>(path: string, handle: T) {
  const integrationRoot = join(integrations, path);
  return [
    join(integrationRoot, '-/new'),
    join(integrationRoot, `:${handle}`),
  ] as const;
}

const [pacsNew, pacs] = createIntegration('pacs', 'pacsHandle');
const [mwlNew, mwl] = createIntegration('mwl', 'worklistHandle');
const mwlItems = join(mwl, 'items');
const [ehrNew, ehr] = createIntegration('ehr', 'ehrHandle');
const [worklistNew, worklist] = createIntegration(
  'encounter-based-worklist',
  'worklistHandle',
);
const ebwItems = join(worklist, 'items');
const [fhirNew, fhir] = createIntegration('fhir', 'fhirIntegrationHandle');

const studyProtocols = route('study-protocols');
const studyProtocol = join(studyProtocols, ':studyProtocolHandle');
const studyProtocolNewWorkflow = join(studyProtocols, '-/workflow/new');
const studyProtocolNewIndication = join(studyProtocols, '-/indication/new');

const workflowDigests = route('workflow-email-digests');

const contextualAdminRoutes = {
  tags,
  examTypes,
  proficiencyGroups,
  historicalProficiency,
  worksheets,
  worksheet,
  worksheetNew,
  worksheetPrefill,
  worksheetPrefillNew,
  qa,
  qaUpdate,
  qaNew,
  mwlItems,
  ebwItems,

  studyProtocols,
  studyProtocol,
  studyProtocolNewWorkflow,
  studyProtocolNewIndication,

  connectivity,
  butterflyLink,
  butterflyLinkNew,
  dicom,
  dicomNew,
  fhir,
  fhirNew,
  pacs,
  pacsNew,
  mwl,
  mwlNew,
  ehr,
  ehrNew,
  worklist,
  worklistNew,

  workflowDigests,
} as const;

type ContextualRoutes = typeof contextualAdminRoutes;

// -----------------------------------

const adminRoutes = {
  domainMembers,
  domainMembersActive,
  domainMembersInactive,
  domainMember,
  domainMemberRoles,

  organizations,
  organizationAdmin,
  organizationGeneral,
  organizationMembers,
  organizationSubscription,
  domainRoles,
  doaminRoleDefintion,
  domainRoleNew,
  domainRoleDuplicate,
  domainRoleUpdate,

  annotationExtensions,
  annotationExtension,
  annotationExtensionNew,

  sso,
  sessionTimeout,
  fleet,
  mdm,

  dicomFieldTemplates,
  dicomFieldTemplate,
  dicomFieldTemplateNew,
  auditLogs,
};

// -----------------------------------

type ContextualRoutesWithOrgParam = {
  [idx in keyof ContextualRoutes]: ContextualRoutes[idx] extends () => string
    ? (params?: Partial<OrgParam>) => string
    : (
        params: Parameters<ContextualRoutes[idx]>[0] & Partial<OrgParam>,
      ) => string;
};

type OrgRoutes = ContextualRoutesWithOrgParam &
  MakeParamOptional<typeof adminRoutes, OrgParam>;

type ResolveAdminRoutesOptions = {
  organizationSlug?: string | null;
};

/**
 * Return a set of functions that generate urls for the "admin" routes, e.g.
 * all the settings routes (less user profile). In order to reuse components for these
 * routes we need links within the routes to be contextually aware of whether
 * they are in a domain or org settings context.
 *
 * SO unless the route is specific, shared route factories will:
 *  - return `/-/enterprise/settings/foo` when there is no org slug
 *  - return `/:slug/admin/foo` when there is an org slug
 */
function routes(
  match: Match,
  opts: ResolveAdminRoutesOptions = {},
): OrgRoutes {
  let { organizationSlug } = opts;

  let isInOrgContext: boolean;

  // unless explicitly null infer the org context from params
  if (organizationSlug === undefined)
    isInOrgContext = !!match.params?.organizationSlug;
  else {
    isInOrgContext = !!organizationSlug;
  }

  const contextualRoot = isInOrgContext
    ? organizationAdmin
    : enterpriseSettings;

  // still try and provide something to routes
  // so `null` doesn't end up in the url
  organizationSlug ||= match.params?.organizationSlug;

  return {
    ...mapValues(contextualAdminRoutes, (value: any) => {
      return (params?: any) => {
        const joined = join(contextualRoot, value) as any;
        return joined({ organizationSlug, ...params });
      };
    }),
    ...mapValues(
      adminRoutes,
      (value: any) => (params: any) => value({ organizationSlug, ...params }),
    ),
  } as any;
}

export default routes;

export function useAdminRoutes({
  organizationSlug,
}: ResolveAdminRoutesOptions = {}) {
  const { match } = useRouter();

  return useMemo(
    () => routes(match, { organizationSlug }),
    [match, organizationSlug],
  );
}
