import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Form, { useField } from '@bfly/ui2/Form';
import FormCheck from '@bfly/ui2/FormCheck';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import FormControl from '@bfly/ui2/FormControl';
import Text from '@bfly/ui2/Text';
import Textarea from '@bfly/ui2/Textarea';
import useId from '@bfly/ui2/useId';
import styled from 'astroturf/react';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import FormFieldType from 'config/FormFieldType';

import { FieldDefinitionFormFieldGroup_fieldDefinition$data as FieldDefinition } from './__generated__/FieldDefinitionFormFieldGroup_fieldDefinition.graphql';

interface Props {
  fieldDefinition: FieldDefinition;
}

function ClearSelectionControl({ name }: { name: string }) {
  return (
    <Form.Field name={name}>
      {(fieldProps) =>
        !isEmpty(fieldProps.value) && (
          <Layout justify="flex-end">
            <Button
              variant="text-primary"
              onClick={() => fieldProps.onChange(undefined)}
            >
              <FormattedMessage
                id="worksheets.optional.clearSingleSelect"
                defaultMessage="Clear selection"
              />
            </Button>
          </Layout>
        )
      }
    </Form.Field>
  );
}

const Check = styled(FormCheck)`
  margin: 0;

  & + & {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
`;

function FieldDefinitionFormFieldGroup({ fieldDefinition }: Props) {
  const name = `values.${fieldDefinition.handle}`;
  const [fieldProps, meta] = useField(name);
  const id = useId();
  const type = fieldDefinition.typename;

  (fieldProps as any).id = id;

  let field;
  switch (type) {
    case FormFieldType.TEXT: {
      field =
        fieldDefinition.textStyle === 'LONG_TEXT' ? (
          <Textarea {...fieldProps} rows={3} />
        ) : (
          <FormControl {...fieldProps} />
        );
      break;
    }
    case FormFieldType.NUMBER: {
      return <FormControl {...fieldProps} type="text" inputMode="decimal" />;
    }
    case FormFieldType.SINGLE_SELECT:
    case FormFieldType.MULTI_SELECT: {
      const optionsData = fieldDefinition!.options!.map((option) => ({
        id: option!.handle!,
        label: option!.label!,
      }));
      // TODO: respect allowOther
      field = (
        <>
          <FormCheckGroup<{ id: string; label: string }>
            {...fieldProps}
            id={id}
            checkAs={Check}
            data={optionsData}
            value={meta.value}
            dataKey="id"
            renderItem={(item) => item.label}
            type={
              fieldDefinition.typename === FormFieldType.SINGLE_SELECT
                ? 'radio'
                : 'checkbox'
            }
          />
          {!fieldDefinition.required && <ClearSelectionControl name={name} />}
        </>
      );
      break;
    }
    default: {
      field = <FormControl {...fieldProps} />;
    }
  }

  return (
    <div className="mb-4">
      <label
        htmlFor={id}
        // used to scroll to invalid questions
        data-worksheet-field=""
        data-invalid={meta.invalid ? '' : undefined}
        className={clsx(
          'mb-2 transition-colors',
          meta.invalid ? 'text-red-40' : 'text-body',
        )}
      >
        {fieldDefinition.label}{' '}
        {!fieldDefinition.required && (
          <Text color="subtitle">
            <FormattedMessage
              id="worksheets.optional"
              defaultMessage="(Optional)"
            />
          </Text>
        )}
      </label>
      {field}
      <Form.Message for={name} className="text-red-40" />
    </div>
  );
}

export default createFragmentContainer(FieldDefinitionFormFieldGroup, {
  fieldDefinition: graphql`
    fragment FieldDefinitionFormFieldGroup_fieldDefinition on FormFieldDefinitionInterface {
      typename: __typename
      handle
      required
      label
      ... on TextFieldDefinition {
        textStyle: style
      }
      ... on SingleSelectFieldDefinition {
        options {
          handle
          label
        }
        allowOther
      }
      ... on MultiSelectFieldDefinition {
        options {
          handle
          label
        }
        allowOther
      }
    }
  `,
});
