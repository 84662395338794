/**
 * @generated SignedSource<<d01131149670469abebe9ef4295a0291>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalStudySearchSubNav_viewer$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreateSavedSearchModal_viewer">;
  readonly " $fragmentType": "GlobalStudySearchSubNav_viewer";
};
export type GlobalStudySearchSubNav_viewer$key = {
  readonly " $data"?: GlobalStudySearchSubNav_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchSubNav_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalStudySearchSubNav_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateSavedSearchModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "26cd717910d52dfb49ca80a9d787d28c";

export default node;
