import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { CommentMention_userProfile$data as UserProfile } from './__generated__/CommentMention_userProfile.graphql';

interface Props {
  userProfile: UserProfile | null | undefined;
}

function CommentMention({ userProfile }: Props) {
  // FIXME: This is sort of messed up. The GraphQL API actually generates stub
  //  user profile objects for nonexistent users here because the iOS code
  //  depends on it. In those cases, instead of userProfile being missing, we
  //  just have no name.
  const name = userProfile && userProfile.name;

  if (!name) {
    return (
      <Text variant="body-bold" color="subtitle">
        [
        <FormattedMessage
          id="commentMention.missing"
          defaultMessage="Unknown User"
        />
        ]
      </Text>
    );
  }

  return (
    <Text variant="body-bold" color="headline">
      @{name}
    </Text>
  );
}

export default createFragmentContainer(CommentMention, {
  userProfile: graphql`
    fragment CommentMention_userProfile on UserProfile {
      name
    }
  `,
});
