/**
 * @generated SignedSource<<1bb0fbdbf9a6a44927fd6abc8b5fbc97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPageWithSidePanel_organization$data = {
  readonly slug: string | null;
  readonly worklists: ReadonlyArray<{
    readonly __typename: string;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_organization" | "BaseAppPage_tenant" | "DeletedStudySection_tenant" | "NewExamSection_organization" | "NewExamSection_tenant" | "NewTodoSection_tenant">;
  readonly " $fragmentType": "AppPageWithSidePanel_organization";
};
export type AppPageWithSidePanel_organization$key = {
  readonly " $data"?: AppPageWithSidePanel_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppPageWithSidePanel_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPageWithSidePanel_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "worklists",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BaseAppPage_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BaseAppPage_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeletedStudySection_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewExamSection_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewTodoSection_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewExamSection_tenant"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c67814e58e245e8ca6dc8095a6ed5553";

export default node;
