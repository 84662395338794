import { MessageDescriptor, defineMessages } from 'react-intl';

const defaultMessages = defineMessages({
  unsavedChanges: {
    id: 'agGrid.unsavedChanges',
    defaultMessage: `Changes will be made to {unsavedChangesCount} {unsavedChangesCount, plural,
        one {row}
        other {rows}
      }`,
  },
  discardChanges: {
    id: 'agGrid.discardChanges',
    defaultMessage: 'Discard changes',
  },
  saveChanges: {
    id: 'agGrid.saveChanges',
    defaultMessage: 'Save',
  },
  confirmLeaveDialogTitle: {
    id: 'agGrid.confirmLeaveDialog.title',
    defaultMessage: 'Leave this page',
  },
  confirmLeaveDialogContent: {
    id: 'agGrid.confirmLeaveDialog.content',
    defaultMessage:
      'Do you want to leave this page? Your unsaved changes will be discarded.',
  },
  confirmLeaveDialogConfirmLabel: {
    id: 'agGrid.confirmLeaveDialog.confirmLabel',
    defaultMessage: 'Leave',
  },

  confirmEditDialogTitle: {
    id: 'agGrid.confirmEditDialog.title',
    defaultMessage: 'Edit Confirmation',
  },
  confirmEditDialogContent: {
    id: 'agGrid.confirmEditDialog.content',
    defaultMessage: 'Are you sure you want to edit this data?',
  },
  confirmEditDialogConfirmLabel: {
    id: 'agGrid.confirmEditDialog.confirmLabel',
    defaultMessage: 'Continue',
  },

  confirmSaveDialogTitle: {
    id: 'agGrid.confirmSaveDialog.title',
    defaultMessage: 'Confirm Changes',
  },
  confirmSaveDialogContent: {
    id: 'agGrid.confirmSaveDialog.content',
    defaultMessage: `<p>Please review and confirm the changes. This action cannot be undone.</p><p> Information will be updated for {count, plural, one {# row} other {# rows}}.</p>`,
  },
  confirmSaveDialogDiscardLabel: {
    id: 'agGrid.confirmSaveDialog.discardLabel',
    defaultMessage: 'Back to bulk edit',
  },
  confirmSaveDialogConfirmLabel: {
    id: 'agGrid.confirmSaveDialog.confirmLabel',
    defaultMessage: 'Confirm Changes',
  },

  confirmDiscardDialogTitle: {
    id: 'agGrid.confirmDiscardDialog.title',
    defaultMessage: 'Discard changes',
  },
  confirmDiscardDialogContent: {
    id: 'agGrid.confirmDiscardDialog.content',
    defaultMessage:
      "Are you sure you want to discard all your local changes? This action can't be undone.",
  },
  confirmDiscardDialogCancelLabel: {
    id: 'agGrid.confirmDiscardDialog.cancelLabel',
    defaultMessage: 'Cancel',
  },
  confirmDiscardDialogConfirmLabel: {
    id: 'agGrid.confirmDiscardDialog.confirmLabel',
    defaultMessage: 'Discard changes',
  },

  saveSuccess: {
    id: 'agGrid.saveSuccess',
    defaultMessage: '{count, plural, one {# row} other {# rows}} updated.',
  },
  saveError: {
    id: 'agGrid.saveError',
    defaultMessage: 'Error updating data.',
  },
  noResults: {
    id: 'agGrid.noResults',
    defaultMessage: 'Your search returned no results.',
  },
});

export default defaultMessages;

export type Messages = {
  [key in keyof typeof defaultMessages]: MessageDescriptor;
};
