/**
 * @generated SignedSource<<42ba3b95a450d529e2c2e2354cb92d25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoriesFormField_query$data = {
  readonly educationCategories: ReadonlyArray<{
    readonly handle: string | null;
    readonly name: string | null;
  } | null> | null;
  readonly lms: {
    readonly educationContentCategories: ReadonlyArray<{
      readonly handle: string | null;
      readonly name: string | null;
      readonly ordering: number | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "CategoriesFormField_query";
};
export type CategoriesFormField_query$key = {
  readonly " $data"?: CategoriesFormField_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoriesFormField_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "practiceType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoriesFormField_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "practiceType",
          "variableName": "practiceType"
        }
      ],
      "concreteType": "EducationCategory",
      "kind": "LinkedField",
      "name": "educationCategories",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lms",
      "kind": "LinkedField",
      "name": "lms",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EducationContentCategory",
          "kind": "LinkedField",
          "name": "educationContentCategories",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ordering",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2be2ae196a2efc6d34298af35cee409f";

export default node;
