import Layout from '@4c/layout';
import Page from '@bfly/ui2/Page';
import { useEffect, useState } from 'react';

import FormCheckSwitch from 'components/FormCheckSwitch';
import PageTitle from 'components/PageTitle';

import { DemoElement } from '../elements';

export default function ElementPage<T extends Record<string, any>>({
  element,
}: {
  element: DemoElement<T>;
}) {
  const [settings, setSettings] = useState<T>(element.settings);

  const [reloadKey, setReload] = useState(1);

  useEffect(() => setReload((p) => p + 1), [settings]);

  const Component = element.component;
  return (
    <>
      <Page.Header>
        <PageTitle title={element.title} />
        <Layout.Spacer />
        <Layout pad={6}>
          {Object.entries(settings).map(([key]) => (
            <FormCheckSwitch
              key={key}
              id={`${element.path}-settings-${key}`}
              checked={settings[key]}
              onChange={(value) =>
                setSettings((prev) => ({ ...prev, [key]: value }))
              }
              className="cursor-pointer"
            >
              {key}
            </FormCheckSwitch>
          ))}
        </Layout>
      </Page.Header>
      <Layout direction="column" pad={5} className="px-6 flex-grow">
        <Component key={reloadKey} settings={settings} />
      </Layout>
    </>
  );
}
