import Button from '@bfly/ui2/Button';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import actionMessages from 'messages/actions';

import ExamQaNotificationControl from './ExamQaNotificationControl';
import { ExamPageSidebarQaFooter_study$data as Study } from './__generated__/ExamPageSidebarQaFooter_study.graphql';

function ExamPageSidebarQaFooter({
  study,
  onSubmit,
  readonly,
}: {
  study: Study;
  onSubmit: () => Promise<boolean>;
  readonly: boolean;
}) {
  const canUseGranularQaNotifications = useVariation(
    'granular-qa-notifications',
  );

  const [qaSubmitting, setQaSubmitting] = useState(false);

  return (
    <>
      {!readonly && (
        <Button
          size="lg"
          variant="primary"
          data-bni-id="SubmitQAButton"
          onClick={async () => {
            try {
              setQaSubmitting(true);
              await onSubmit();
            } finally {
              setQaSubmitting(false);
            }
          }}
          busy={qaSubmitting}
          className="min-w-[15rem]"
        >
          <FormattedMessage {...actionMessages.submit} />
        </Button>
      )}
      {canUseGranularQaNotifications &&
        // we don't have the ability yet to tell who was previously notified
        !readonly && (
          <ExamQaNotificationControl study={study} className="mr-auto" />
        )}
    </>
  );
}

export default createFragmentContainer(ExamPageSidebarQaFooter, {
  study: graphql`
    fragment ExamPageSidebarQaFooter_study on Study {
      ...ExamQaNotificationControl_study
    }
  `,
});
