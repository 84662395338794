import { graphql } from 'relay-runtime';

import { StudyStatistics_examType$data as ExamTypeStatistic } from './__generated__/StudyStatistics_examType.graphql';
import { StudyStatistics_studyAuthor$data as StudyAuthorStatistic } from './__generated__/StudyStatistics_studyAuthor.graphql';
import { StudyStatistics_userHistoricalProficiency$data as UserHistoricalProficiency } from './__generated__/StudyStatistics_userHistoricalProficiency.graphql';

const _ = {
  userHistoricalProficiency: graphql`
    fragment StudyStatistics_userHistoricalProficiency on StudyStatisticsByAuthor {
      numCountsTowardsCredentialing
      userHistoricalProficiency {
        examTypeCounts {
          examType {
            name
          }
          count
        }
      }
    }
  `,
  studyAuthor: graphql`
    fragment StudyStatistics_studyAuthor on StudyStatisticsByAuthor {
      numFinalizedStudies
      numReviewedStudies
      numExamTypeAssociations
      averageImageQuality
      studyStatusStatistics {
        key
        numStudies
      }
      accuracyAsPresentedStatistics {
        key
        numFinalizedStudies
      }
      accuracyComparedToGoldStandardStatistics {
        key
        numFinalizedStudies
      }
      feedbackStatistics {
        key
        numFinalizedStudies
      }
    }
  `,
  examType: graphql`
    fragment StudyStatistics_examType on ExamTypeStudyStatistics {
      numFinalizedStudies
      numReviewedStudies
      numCountsTowardsCredentialing
      averageImageQuality
      studyStatusStatistics {
        key
        numStudies
      }
      accuracyAsPresentedStatistics {
        key
        numFinalizedStudies
      }
      accuracyComparedToGoldStandardStatistics {
        key
        numFinalizedStudies
      }
      feedbackStatistics {
        key
        numFinalizedStudies
      }
    }
  `,
};

export const STUDY_STATUS_STATISTICS_KEYS = {
  DRAFT: 'DRAFT',
  PENDING_ATTESTATION: 'PENDING_ATTESTATION',
  REVIEWED: 'REVIEWED',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  FINALIZED: 'FINALIZED',
};

export const ACCURACY_STATISTICS_KEYS = {
  TRUE_NEGATIVE: 'TRUE_NEGATIVE',
  TRUE_POSITIVE: 'TRUE_POSITIVE',
  FALSE_NEGATIVE: 'FALSE_NEGATIVE',
  FALSE_POSITIVE: 'FALSE_POSITIVE',
};

export const FEEDBACK_STATISTICS_KEYS = {
  ADJUST_GAIN: 'ADJUST_GAIN',
  ADJUST_DEPTH: 'ADJUST_DEPTH',
  INCORRECT_ORIENTATION: 'INCORRECT_ORIENTATION',
  INCORRECT_TRANSDUCER: 'INCORRECT_TRANSDUCER',
  INCORRECT_PRESET: 'INCORRECT_PRESET',
  INCOMPLETE_MEASUREMENTS: 'INCOMPLETE_MEASUREMENTS',
  MISSING_STANDARD_VIEWS: 'MISSING_STANDARD_VIEWS',
  NOT_CLINICALLY_INDICATED: 'NOT_CLINICALLY_INDICATED',
};

/**
 * Creates a function that allows looking up a value via key. The provided function returns 0 if the key isn't found.
 *
 * @param statistics a list of key/value statistics
 * @returns a function that allows looking up a potential value by key
 */
export function getStatisticByKey<
  T extends
    | { key: string; numFinalizedStudies: number }
    | { key: string; numStudies: number }
    | null,
>(statistics: readonly T[]) {
  const map = new Map<string, number>();
  for (const statistic of statistics) {
    if (!statistic) {
      continue;
    }

    map.set(
      statistic.key,
      'numFinalizedStudies' in statistic
        ? statistic.numFinalizedStudies
        : statistic.numStudies,
    );
  }

  return (key: string) => {
    return map.get(key) ?? 0;
  };
}

export interface ExpandedStudyStatistics {
  studyStatusStatisticsDrafts: number;
  studyStatusStatisticsTotal: number | null;
  studyExamTypeTotal: number;
  studyStatusStatisticsPendingAttestation: number;
  studyStatusStatisticsNeedsReview: number;
  studyStatusStatisticsFinalized: number;

  accuracyAsPresentedStatisticsTP: number;
  accuracyAsPresentedStatisticsFP: number;
  accuracyAsPresentedStatisticsTN: number;
  accuracyAsPresentedStatisticsFN: number;

  accuracyComparedToGoldStandardStatisticsTP: number;
  accuracyComparedToGoldStandardStatisticsFP: number;
  accuracyComparedToGoldStandardStatisticsTN: number;
  accuracyComparedToGoldStandardStatisticsFN: number;

  feedbackStatisticsAdjustGain: number;
  feedbackStatisticsAdjustDepth: number;
  feedbackStatisticsIncompleteMeasurement: number;
  feedbackStatisticsIncorrectPreset: number;
  feedbackStatisticsIncorrectOrientation: number;
  feedbackStatisticsIncorrectTransducer: number;
  feedbackStatisticsMissingStandardViews: number;
  feedbackStatisticsNotClinicallyIndicated: number;
}

/**
 * Flattens the [{key, numStudies}] statistics into named properties such as studyStatusStatisticsDrafts. Modifies the passed
 * stat in place to avoid extra spreading.
 *
 * @param stat the statistic to expand
 * @returns an object with flattened statistics
 */
export function expandStudyStatistics<
  T extends Partial<
    Omit<StudyAuthorStatistic | ExamTypeStatistic, ' $fragmentType'>
  >,
>(stat: T, columnsToHide?: 'studyStatisticsTotal'[]): ExpandedStudyStatistics {
  const getStudyStatusStatistics = getStatisticByKey(
    stat.studyStatusStatistics ?? [],
  );

  const getAccuracyAsPresentedStatistics = getStatisticByKey(
    stat.accuracyAsPresentedStatistics ?? [],
  );

  const getAccuracyComparedToGoldStandardStatistics = getStatisticByKey(
    stat.accuracyComparedToGoldStandardStatistics ?? [],
  );

  const getFeedbackStatistics = getStatisticByKey(
    stat.feedbackStatistics ?? [],
  );

  return {
    studyStatusStatisticsDrafts: getStudyStatusStatistics(
      STUDY_STATUS_STATISTICS_KEYS.DRAFT,
    ),
    studyStatusStatisticsPendingAttestation: getStudyStatusStatistics(
      STUDY_STATUS_STATISTICS_KEYS.PENDING_ATTESTATION,
    ),
    studyStatusStatisticsFinalized: getStudyStatusStatistics(
      STUDY_STATUS_STATISTICS_KEYS.FINALIZED,
    ),
    studyStatusStatisticsNeedsReview: getStudyStatusStatistics(
      STUDY_STATUS_STATISTICS_KEYS.NEEDS_REVIEW,
    ),
    studyStatusStatisticsTotal: columnsToHide?.includes('studyStatisticsTotal')
      ? null
      : stat.studyStatusStatistics?.reduce(
          (sum, status) => sum + (status?.numStudies ?? 0),
          0,
        ) ?? 0,
    studyExamTypeTotal:
      stat.studyStatusStatistics?.reduce(
        (sum, status) => sum + (status?.numStudies ?? 0),
        0,
      ) ?? 0,
    accuracyAsPresentedStatisticsFN: getAccuracyAsPresentedStatistics(
      ACCURACY_STATISTICS_KEYS.FALSE_NEGATIVE,
    ),
    accuracyAsPresentedStatisticsFP: getAccuracyAsPresentedStatistics(
      ACCURACY_STATISTICS_KEYS.FALSE_POSITIVE,
    ),
    accuracyAsPresentedStatisticsTN: getAccuracyAsPresentedStatistics(
      ACCURACY_STATISTICS_KEYS.TRUE_NEGATIVE,
    ),
    accuracyAsPresentedStatisticsTP: getAccuracyAsPresentedStatistics(
      ACCURACY_STATISTICS_KEYS.TRUE_POSITIVE,
    ),

    accuracyComparedToGoldStandardStatisticsFN:
      getAccuracyComparedToGoldStandardStatistics(
        ACCURACY_STATISTICS_KEYS.FALSE_NEGATIVE,
      ),
    accuracyComparedToGoldStandardStatisticsFP:
      getAccuracyComparedToGoldStandardStatistics(
        ACCURACY_STATISTICS_KEYS.FALSE_POSITIVE,
      ),
    accuracyComparedToGoldStandardStatisticsTN:
      getAccuracyComparedToGoldStandardStatistics(
        ACCURACY_STATISTICS_KEYS.TRUE_NEGATIVE,
      ),
    accuracyComparedToGoldStandardStatisticsTP:
      getAccuracyComparedToGoldStandardStatistics(
        ACCURACY_STATISTICS_KEYS.TRUE_POSITIVE,
      ),

    feedbackStatisticsAdjustDepth: getFeedbackStatistics(
      FEEDBACK_STATISTICS_KEYS.ADJUST_DEPTH,
    ),
    feedbackStatisticsAdjustGain: getFeedbackStatistics(
      FEEDBACK_STATISTICS_KEYS.ADJUST_GAIN,
    ),
    feedbackStatisticsIncompleteMeasurement: getFeedbackStatistics(
      FEEDBACK_STATISTICS_KEYS.INCOMPLETE_MEASUREMENTS,
    ),
    feedbackStatisticsIncorrectOrientation: getFeedbackStatistics(
      FEEDBACK_STATISTICS_KEYS.INCORRECT_ORIENTATION,
    ),
    feedbackStatisticsIncorrectPreset: getFeedbackStatistics(
      FEEDBACK_STATISTICS_KEYS.INCORRECT_PRESET,
    ),
    feedbackStatisticsMissingStandardViews: getFeedbackStatistics(
      FEEDBACK_STATISTICS_KEYS.MISSING_STANDARD_VIEWS,
    ),
    feedbackStatisticsNotClinicallyIndicated: getFeedbackStatistics(
      FEEDBACK_STATISTICS_KEYS.NOT_CLINICALLY_INDICATED,
    ),
    feedbackStatisticsIncorrectTransducer: getFeedbackStatistics(
      FEEDBACK_STATISTICS_KEYS.INCORRECT_TRANSDUCER,
    ),
  };
}

export interface ExpandedHistoricalProficiency {
  numCountsTowardCredentialingWithHistorical: number;
  numCountsTowardCredentialingButterfly: number;
  numCountsTowardCredentialingHistorical: number;
}

export function expandHistoricalProficiency<
  T extends Partial<Omit<UserHistoricalProficiency, ' $fragmentType'>>,
>(item: T, examTypeName?: string): ExpandedHistoricalProficiency {
  const butterflyCount = item.numCountsTowardsCredentialing || 0;
  const counts = item.userHistoricalProficiency?.examTypeCounts || [];
  const filtered = counts.filter(
    (etc) => !examTypeName || etc?.examType?.name === examTypeName,
  );
  const historicalCount =
    examTypeName === ''
      ? 0
      : filtered.reduce((a, n) => a + (n?.count || 0), 0);
  return {
    numCountsTowardCredentialingButterfly: butterflyCount,
    numCountsTowardCredentialingHistorical: historicalCount,
    numCountsTowardCredentialingWithHistorical:
      butterflyCount + historicalCount,
  };
}
