import { NamedLocationDescriptorObject } from 'farce';
import 'found';

declare module 'found' {
  export { NamedLocationDescriptorObject };

  export interface RouteObjectBase {
    allowPublic?: boolean;
    center?: boolean;
    mobileFriendly?: boolean;
    name?: string;
  }
}
