import { createContext, useContext } from 'react';

import FeatureFlags, { LDClient } from 'config/FeatureFlags.d';
import featureFlagDefaults from 'config/featureFlagDefaults';

export type LaunchDarklyClient = LDClient;

const LaunchDarklyContext = createContext<LDClient | null>(null);
export default LaunchDarklyContext;

/**
 * Same as useLaunchDarklyClient but won't throw an error when LD context is unavailable.
 * Use this on pages that are outside of an organization, e.g. account settings.
 */
export function useLaunchDarklyClientAllowMissingContext() {
  return useContext(LaunchDarklyContext);
}

export function useLaunchDarklyClient() {
  const ldClient = useContext(LaunchDarklyContext);
  if (!ldClient) {
    throw new Error('LaunchDarkly client not available.');
  }

  return ldClient;
}

export function getVariation<TKey extends keyof FeatureFlags>(
  ldClient: LDClient | null | undefined,
  key: TKey,
): FeatureFlags[TKey] {
  return ldClient ? ldClient.variation(key) : featureFlagDefaults[key];
}

export function useVariation<TKey extends keyof FeatureFlags>(
  key: TKey,
): FeatureFlags[TKey] {
  const ldClient = useContext(LaunchDarklyContext);
  if (!ldClient) {
    throw new Error('LaunchDarkly client not available.');
  }
  return getVariation(ldClient, key);
}

/**
 * Same as useVariation but won't throw an error when LD context is unavailable.
 * Use this on pages that are outside of an organization, e.g. account settings.
 */
export function useVariationAllowMissingLdContext<
  TKey extends keyof FeatureFlags,
>(key: TKey): FeatureFlags[TKey] {
  const ldClient = useContext(LaunchDarklyContext);
  return ldClient ? getVariation(ldClient, key) : featureFlagDefaults[key];
}
