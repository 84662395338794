import DatePicker from '@bfly/ui2/DatePicker';
import Form from '@bfly/ui2/Form';
import { LabelValue } from '@bfly/ui2/FormattedLabel';
import { stylesheet } from 'astroturf';

const styles = stylesheet`
  .date-picker-field {
    :global(.rw-picker-btn) {
      border-left: 0px;
  }
}`;

interface Props {
  open: boolean;
  setOpen: (newState: boolean) => any;
  disabled?: boolean;
  label: string | LabelValue;
}

const SplitStudyPatientDatePickerField = ({
  open,
  setOpen,
  disabled,
  label,
}: Props) => {
  return (
    <Form.FieldGroup
      fluid
      variant="secondary"
      name="birthDate"
      label={label}
      disabled={disabled}
    >
      {(props, meta) => {
        return (
          <DatePicker
            variant="secondary"
            menuVariant="dark"
            dropUp
            {...props}
            {...meta}
            className={styles.datePickerField}
            open={open}
            inputProps={{
              onClick: () => {
                setOpen(true);
              },
              readOnly: disabled,
            }}
            onToggle={setOpen}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === ' ') {
                setOpen(true);
              }
            }}
          />
        );
      }}
    </Form.FieldGroup>
  );
};

export default SplitStudyPatientDatePickerField;
