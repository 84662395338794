/**
 * @generated SignedSource<<5474a8331ce96741086cc2124e312900>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTenantIdInput_tenant$data = {
  readonly domainId?: string;
  readonly organizationId?: string;
  readonly " $fragmentType": "useTenantIdInput_tenant";
};
export type useTenantIdInput_tenant$key = {
  readonly " $data"?: useTenantIdInput_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTenantIdInput_tenant">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTenantIdInput_tenant"
};

(node as any).hash = "30bd28c5756191ea3d039ef297e2a188";

export default node;
