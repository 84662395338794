import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import Heading from '@bfly/ui2/Heading';
import Text from '@bfly/ui2/Text';
import { useToast } from '@bfly/ui2/ToastContext';
import clsx from 'clsx';
import { PropsWithChildren, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ConditionalContainer from 'components/ConditionalWrapper';
import ExamQAStandard from 'components/ExamQAStandard';
import RelayForm, { FormHandle } from 'components/RelayForm';
import {
  deserialize,
  schema,
  serialize,
  standardQaFeedbackOptions,
} from 'schemas/eduStandardQa';

import { useScanLabContext } from '../context';
import { ReviewStatus } from '../types';
import useRequestReview from '../useRequestReview';
import { EduStudySidebar_ReviewMutation as ReviewMutation } from './__generated__/EduStudySidebar_ReviewMutation.graphql';
import { EduStudySidebar_study$data as EduStudy } from './__generated__/EduStudySidebar_study.graphql';

const reviewMutation = graphql`
  mutation EduStudySidebar_ReviewMutation($input: QaEduStudyInput!) {
    qaEduStudyOrError(input: $input) {
      ... on QaEduStudyPayload {
        standardQa {
          study {
            id
          }
          imageQuality
          feedback
          comments
          createdBy {
            ...ExamReviewedBy_userProfile
          }
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

function EventItem({ children }: PropsWithChildren) {
  return (
    <Text as="div" variant="body" color="inherit" className="flex mx-5">
      {children}
    </Text>
  );
}

function ReviewStatusEventItem({ study }: { study: EduStudy }) {
  if (study.standardQa?.createdAt)
    return (
      <EventItem>
        <FormattedMessage
          id="scanLab.ExamPageSidebar.reviewedBy"
          defaultMessage="Reviewed by {name} on"
          values={{ name: study.standardQa?.createdBy?.name || '' }}
        />{' '}
        <FormattedDateTime value={study.standardQa.createdAt} />
      </EventItem>
    );

  if (study.reviewRequestedAt)
    return (
      <EventItem>
        <FormattedMessage
          id="scanLab.ExamPageSidebar.reviewRequestedAt"
          defaultMessage="Review requested on"
        />{' '}
        <FormattedDateTime value={study.reviewRequestedAt!} />
      </EventItem>
    );

  return null;
}

interface Props {
  study: EduStudy;
  status: ReviewStatus;
  setStatus: (status: ReviewStatus) => void;
}

function EduStudySidebar({ study, status, setStatus }: Props) {
  const toast = useToast();

  const { canReview, canRequestReview, updateStatusCounts } =
    useScanLabContext();

  const statusStudy = { id: study.id, authorId: study.createdBy!.id! };

  const qaFormRef = useRef<null | FormHandle>(null);

  const [qaSubmitting, setQaSubmitting] = useState(false);

  const readOnly = status === 'reviewed' || !canReview;

  const [formValue, setFormValue] = useState(() => deserialize(study));

  const { requestReview, requestReviewWorking } = useRequestReview({
    onCompleted: () => {
      setStatus('reviewRequested');
    },
  });

  return (
    <div
      className={clsx(
        'border-l border-grey-80',
        'flex flex-col w-full bg-grey-90 overflow-hidden',
      )}
    >
      {/* above footer */}
      <div className="flex-1 flex flex-col max-h-full overflow-hidden">
        <Heading
          color="subtitle"
          variant="sm-bold"
          transform="uppercase"
          className="flex items-center mt-4 mb-4 px-5"
          flush
        >
          <div className="mr-auto">
            <FormattedMessage
              tagName="span"
              id="scanLab.ExamPageSidebar.scanDetails"
              defaultMessage="Scan Details"
            />
          </div>
        </Heading>
        <Heading className="flex ml-5">
          <FormattedMessage
            tagName="span"
            id="scanLab.ExamPageSidebar.views"
            defaultMessage="Views {zonesCompleted}/{totalZones}"
            values={{
              zonesCompleted: study.zonesCompleted,
              totalZones: study.totalZones,
            }}
          />
        </Heading>
        <Layout
          direction="column"
          align="baseline"
          justify="space-between"
          wrap={false}
          className="space-y-3"
        >
          <ConditionalContainer
            description="show only when readyAt is set"
            condition={study.readyAt}
          >
            <EventItem>
              <FormattedMessage
                id="scanLab.ExamPageSidebar.uploadedAt"
                defaultMessage="Uploaded on"
              />{' '}
              <FormattedDateTime value={study.readyAt!} />
            </EventItem>
          </ConditionalContainer>
          <ReviewStatusEventItem study={study} />
        </Layout>
        <ConditionalContainer
          id="request-review-button"
          description="when unsubmitted, show the request review button"
          condition={status === 'unsubmitted' && canRequestReview}
        >
          <Button
            size="lg"
            variant="primary"
            data-bni-id="RequestReviewButton"
            className="min-w-[15rem] mt-6 mx-5"
            busy={requestReviewWorking}
            onClick={() =>
              requestReview([{ id: study.id, authorId: study.createdBy!.id! }])
            }
          >
            <FormattedMessage
              id="scanLab.ExamPageSidebar.requestReview"
              defaultMessage="Submit for Review"
            />
          </Button>
        </ConditionalContainer>
        <ConditionalContainer
          description="when review requested and not qa, show the review requested button"
          condition={status === 'reviewRequested' && !canReview}
        >
          <Button
            size="lg"
            variant={null}
            disabled
            data-bni-id="RequestedReviewButton"
            className="min-w-[15rem] mt-6 mx-5 bg-grey-80"
          >
            <FormattedMessage
              id="scanLab.ExamPageSidebar.requestedReview"
              defaultMessage="Review Requested"
            />
          </Button>
        </ConditionalContainer>
        <ConditionalContainer
          description={[
            'when review requested and qa, show the review requested button',
            'when reviewed, show the qa form (readonly)',
          ]}
          condition={[
            status === 'reviewRequested' && canReview,
            status === 'reviewed',
          ]}
        >
          <Layout
            direction="column"
            className="mt-8 border-t border-t-grey-70 pt-4 overflow-hidden space-y-2"
          >
            <RelayForm<ReviewMutation, typeof schema>
              schema={schema}
              defaultValue={schema.getDefault()}
              value={formValue}
              formRef={qaFormRef}
              onChange={setFormValue}
              getInput={(nextValue) => serialize(nextValue, study.id)}
              mutation={reviewMutation}
              onSubmitFinished={(validationError) => {
                if (validationError) return;
                toast.success(
                  <FormattedMessage
                    id="scanLab.reviewSuccess"
                    defaultMessage="Review successfully submitted"
                  />,
                );
                setStatus('reviewed');
                updateStatusCounts([statusStudy], `reviewed`);
              }}
              className="contents"
            >
              <div className="overflow-y-auto min-h-full space-y-4 px-5 pb-5">
                <Heading flush>
                  <FormattedMessage
                    id="scanLab.ExamPageSidebar.qaTitle"
                    defaultMessage="Butterfly Standard Review"
                  />
                </Heading>
                <ExamQAStandard
                  readOnly={readOnly}
                  showImageQualityTooltips={!readOnly}
                  hideImageInterpretationAccuracyFields
                  feedbackOptions={standardQaFeedbackOptions}
                  imageQualityRequired
                />
              </div>
            </RelayForm>
          </Layout>
        </ConditionalContainer>
      </div>
      {/* footer */}
      <ConditionalContainer
        description="when review requested and qa, show the submit qa button"
        condition={status === 'reviewRequested' && canReview}
      >
        <div className="flex-0 shrink-0 z-10 w-full px-5 h-16 flex-none bg-body flex flex-row-reverse items-center border-t border-grey-80">
          <Button
            size="lg"
            variant="primary"
            data-bni-id="SubmitQAButton"
            onClick={async () => {
              try {
                setQaSubmitting(true);
                await qaFormRef.current!.submit();
              } finally {
                setQaSubmitting(false);
              }
            }}
            busy={qaSubmitting}
            className="min-w-[15rem]"
          >
            <FormattedMessage
              id="scanLab.ExamPageSidebar.submitReview"
              defaultMessage="Submit Review"
            />
          </Button>
        </div>
      </ConditionalContainer>
    </div>
  );
}

export default createFragmentContainer(EduStudySidebar, {
  study: graphql`
    fragment EduStudySidebar_study on EduStudy {
      ...eduStandardQa_eduStudy
      id
      createdBy {
        id
      }
      reviewRequestedAt
      standardQa {
        createdAt
        createdBy {
          name
        }
      }
      zonesCompleted
      totalZones
      readyAt
    }
  `,
});
