import type { ButtonProps } from '@bfly/ui2/Button';
import Caret from '@bfly/ui2/Caret';
import Dropdown, { DropdownToggleProps } from '@bfly/ui2/Dropdown';
import useRouter from 'found/useRouter';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import StudyListFilterMessage from 'components/StudyListFilterMessage';
import { StudyListFilter } from 'utils/StudyConstants';
import { usePermissions } from 'utils/viewerContext';

import StudyFilterItem from './StudyFilterItem';
import { StudyFilterDropdown_archive$data as Archive } from './__generated__/StudyFilterDropdown_archive.graphql';

interface Props extends Omit<ButtonProps, 'as'>, DropdownToggleProps {
  archive: Archive | null;
}

function StudyFilterDropdown({ archive, ...props }: Props) {
  const {
    match: { location },
  } = useRouter();

  const canUseReviews = useVariation('worksheets-review');
  const canUseRestrictStudyFinalization = useVariation(
    'restricted-study-finalization',
  );
  const { hasBasicPermission } = usePermissions();

  const current =
    (location.query.status as StudyListFilter) || StudyListFilter.ALL;

  return (
    <Dropdown placement="bottom-end">
      <Dropdown.Toggle {...props} variant="text-secondary" id="study-filter">
        <StudyListFilterMessage status={current} />
        <Caret />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <StudyFilterItem status={StudyListFilter.ALL} />
        <StudyFilterItem status={StudyListFilter.COMPLETED} />
        <StudyFilterItem status={StudyListFilter.DRAFTS} />
        {canUseRestrictStudyFinalization &&
          (!archive ||
            (archive.hasRestrictedFinalization &&
              !archive.viewerMustRequestFinalization)) && (
            <StudyFilterItem status={StudyListFilter.MY_SIGNATURE_REQUESTED} />
          )}
        {canUseReviews &&
          (archive ? archive.viewerCanQa : hasBasicPermission('qa')) && (
            <StudyFilterItem status={StudyListFilter.NEEDS_REVIEW} />
          )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default createFragmentContainer(StudyFilterDropdown, {
  archive: graphql`
    fragment StudyFilterDropdown_archive on Archive {
      viewerMustRequestFinalization
      viewerCanQa
      hasRestrictedFinalization
    }
  `,
});
