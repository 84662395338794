/**
 * @generated SignedSource<<3105063b8821caaeab9c46496003fbc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InterpretationType = "ANATOMICAL_LABEL" | "APICAL_EF" | "APICAL_EF_MEASURABILITY" | "BLADDER_VOLUME" | "BLINE_COUNT" | "CAPTION_ECHO" | "GESTATIONAL_AGE" | "MODEL_FEEDBACK" | "PACE_BLINE" | "PLAX_EF" | "PLAX_EF_MEASURABILITY" | "SDK_BATCH" | "SDK_LIVE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BLineSummaryResults_interpretations$data = ReadonlyArray<{
  readonly interpretationType: InterpretationType | null;
  readonly output: Record<string, any> | null;
  readonly " $fragmentType": "BLineSummaryResults_interpretations";
}>;
export type BLineSummaryResults_interpretations$key = ReadonlyArray<{
  readonly " $data"?: BLineSummaryResults_interpretations$data;
  readonly " $fragmentSpreads": FragmentRefs<"BLineSummaryResults_interpretations">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BLineSummaryResults_interpretations",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "output",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interpretationType",
      "storageKey": null
    }
  ],
  "type": "Interpretation",
  "abstractKey": null
};

(node as any).hash = "c8575d2971745d294e748f858e83b43d";

export default node;
