import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkRoutePermission,
} from 'utils/RouteAccessControl';

import { organizationConnectionsRoute } from './routes/connections';
import { organizationIntegrationsRoute } from './routes/integrations';

export const organizationConnectivityRoute = (
  <Route
    path="connectivity"
    prerender={chainPrerenders(
      checkFlagsOr404('dicom-connector'),
      checkRoutePermission('connectivityManagement', 'BASIC'),
    )}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/ConnectivityPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query organizationConnectivityRoutes_ConnectivityPage_Query(
          $organizationSlug: String!
        ) {
          connectivityConfig: organizationBySlug(slug: $organizationSlug) {
            ...ConnectivityPage_connectivityConfig
          }
        }
      `}
    />
    {organizationConnectionsRoute}
    {organizationIntegrationsRoute}
  </Route>
);
