import Layout from '@4c/layout';
import ChevronLeft from '@bfly/icons/ChevronLeft';
import ChevronRight from '@bfly/icons/ChevronRight';
import Button from '@bfly/ui2/Button';
import Link from '@bfly/ui2/Link';
import Tooltip from '@bfly/ui2/Tooltip';
import { LocationDescriptor } from 'found';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

export default function ExamNav({
  children,
  loading,
  prev: navPrev,
  next: navNext,
}: PropsWithChildren<{
  loading?: boolean;
  prev?: LocationDescriptor | null;
  next?: LocationDescriptor | null;
}>) {
  return (
    <>
      <Tooltip.Trigger
        placement="left"
        tooltip={
          <FormattedMessage
            id="exams.examNav.previous"
            defaultMessage="Previous exam"
          />
        }
      >
        <Layout className="pr-1" align="center">
          <Link
            as={Button}
            variant="text-secondary"
            data-bni-id="ExamNavPrevExamButton"
            className="bg-grey-80 p-0 w-[2.2rem] h-[2.2rem] stroke-white"
            disabled={loading || !navPrev}
            to={navPrev || ''}
          >
            <ChevronLeft width={6} />
          </Link>
        </Layout>
      </Tooltip.Trigger>
      {children}
      <Tooltip.Trigger
        placement="right"
        tooltip={
          <FormattedMessage
            id="exams.examNav.next"
            defaultMessage="Next exam"
          />
        }
      >
        <Layout className="pl-1" align="center">
          <Link
            as={Button}
            className="bg-grey-80 p-0 w-[2.2rem] h-[2.2rem] stroke-white"
            variant="text-secondary"
            data-bni-id="ExamNavNextExamButton"
            disabled={loading || !navNext}
            to={navNext || ''}
          >
            <ChevronRight width={6} />
          </Link>
        </Layout>
      </Tooltip.Trigger>
    </>
  );
}
