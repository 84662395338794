import Page from '@bfly/ui2/Page';
import React from 'react';

interface Props {
  children: React.ReactNode;
  'data-bni-id'?: string;
}

export default function BlankAppPage({ children, ...props }: Props) {
  return (
    <Page>
      <Page.Container center {...props}>
        {children}
      </Page.Container>
    </Page>
  );
}
