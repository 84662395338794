/**
 * @generated SignedSource<<54a066b7e21660117ca19271d691ba32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MoveStudyModalRefresh_Organization_Query$variables = {
  handle: string;
};
export type MoveStudyModalRefresh_Organization_Query$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"ArchiveDropdown_organization">;
  } | null;
};
export type MoveStudyModalRefresh_Organization_Query = {
  response: MoveStudyModalRefresh_Organization_Query$data;
  variables: MoveStudyModalRefresh_Organization_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "handle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "handle"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MoveStudyModalRefresh_Organization_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArchiveDropdown_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MoveStudyModalRefresh_Organization_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "ArchiveConnection",
            "kind": "LinkedField",
            "name": "archiveConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArchiveEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Archive",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PacsArchiveAssociation",
                        "kind": "LinkedField",
                        "name": "pacsAssociations",
                        "plural": true,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EhrArchiveAssociation",
                        "kind": "LinkedField",
                        "name": "ehrAssociations",
                        "plural": true,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "archiveConnection(first:2147483647)"
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Organization_archiveConnection",
            "kind": "LinkedHandle",
            "name": "archiveConnection"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d0d68364123a63a5e7c926752e04e5e",
    "id": null,
    "metadata": {},
    "name": "MoveStudyModalRefresh_Organization_Query",
    "operationKind": "query",
    "text": "query MoveStudyModalRefresh_Organization_Query(\n  $handle: String!\n) {\n  organization(handle: $handle) {\n    ...ArchiveDropdown_organization\n    id\n  }\n}\n\nfragment ArchiveDropdown_organization on Organization {\n  archiveConnection(first: 2147483647) {\n    edges {\n      node {\n        id\n        label\n        pacsAssociations {\n          id\n        }\n        ehrAssociations {\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2fd426e9df8857cda482af577de99c89";

export default node;
