import Layout from '@4c/layout';
import HamburgerMenuIcon from '@bfly/icons/HamburgerMenu';
import PlusIcon from '@bfly/icons/Plus';
import FormCheck from '@bfly/ui2//FormCheck';
import Button from '@bfly/ui2/Button';
import Modal from '@bfly/ui2/Modal';
import { DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useState } from 'react';

import LoadingIndicatorLine from 'components/LoadingIndicatorLine';
import useModalState from 'hooks/useModalState';

import SortableList, {
  SortableDndContext,
  SortableDragOverlay,
  arrayMove,
} from '../SortableList';
import {
  Column,
  ColumnDef,
  ColumnGroup,
  ColumnState,
  isColumnGroup,
} from './types';
import applyStateToColumns from './utils/applyStateToColumns';

export default function ColumnPicker({
  canHide,
  canOrder,
  columns,
  state,
  loadingState,
  setState,
  resetState,
  showResetState,
}: {
  canOrder: boolean;
  canHide: boolean;
  columns: ColumnDef[];
  state: ColumnState[];
  setState: (columnsState: ColumnState[]) => void;
  loadingState: boolean;
  resetState: () => void;
  showResetState: boolean;
}) {
  const [dragging, setDragging] = useState(false);

  const columnsSortable: (Column & {
    parent?: ColumnGroup;
    index?: number;
  })[] = applyStateToColumns(columns, state).flatMap((col) =>
    isColumnGroup(col)
      ? col.children.map((childCol, index) => ({
          ...childCol,
          parent: col,
          index,
        }))
      : [col],
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const activeColumn = columnsSortable.find((c) => c.colId === active.id)!;
      const overColumn = columnsSortable.find((c) => c.colId === over.id)!;

      if (
        activeColumn.pinned ||
        activeColumn.parent?.pinned ||
        overColumn.pinned ||
        overColumn.parent?.pinned
      )
        return;

      const colIds = columnsSortable.map(({ colId }) => colId);

      let nextStateIds = arrayMove(
        colIds,
        colIds.indexOf(active.id),
        colIds.indexOf(over.id),
      );

      if (activeColumn.parent) {
        const [firstChildId, ...childIds] = activeColumn.parent.children.map(
          ({ colId }) => colId,
        );

        nextStateIds = nextStateIds
          .filter((colId) => !childIds.includes(colId))
          .flatMap((colId) =>
            colId === firstChildId ? [firstChildId, ...childIds] : colId,
          );
      }

      const nextState = nextStateIds
        .map((colId) => state.find((c) => c.colId === colId)!)
        .filter(Boolean);

      setState(nextState);
    }
    setDragging(false);
  };

  const handleCheckClick = (key: string[]) => {
    setState(
      state.map((col: ColumnState) => ({
        ...col,
        hide: key.includes(col.colId) ? !col.hide : col.hide,
      })),
    );
  };

  const [open, { onHide, show }] = useModalState(false);

  return (
    <>
      <Button
        data-bni-id="DataGridColumnPicker"
        id="study-grid-column-spec"
        variant="text-secondary"
        css={css`
          box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
        `}
        className="rounded-full p-0 w-6 h-6 bg-grey-50 text-grey-85 absolute right-4 z-10 top-[1rem]"
        onClick={() => (show ? onHide() : open())}
      >
        <PlusIcon width={16} height={16} />
      </Button>
      <Modal size="sm" show={show} onHide={onHide} variant="dark">
        <Modal.Body scrollable={false}>
          <div className="relative">
            {loadingState && <LoadingIndicatorLine />}
            <SortableDndContext
              onDragStart={() => setDragging(true)}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableList
                items={columnsSortable.map((c) => c.colId)}
                data-bni-id="DataGridColumnPickerContent"
                className="max-h-full scrollable-y"
              >
                {columnsSortable.map(({ colId, parent, index, ...column }) => {
                  if (index) return null;
                  const label = parent?.headerName || column.headerName;
                  const pinned = column.pinned || parent?.pinned;
                  return (
                    <SortableList.Item
                      key={colId}
                      id={colId}
                      as={Layout}
                      className={clsx(
                        'border-t border-b border-grey-85',
                        dragging && 'max-h-12 overflow-hidden',
                      )}
                      direction="row"
                      justify="space-between"
                      draggingClassName="bg-grey-80"
                    >
                      {!canHide ? (
                        <span className="flex mb-0 px-4 py-3 flex-grow">
                          {label}
                        </span>
                      ) : (
                        <FormCheck
                          key={colId}
                          data-rr-ui-dropdown-item
                          data-bni-id={`DataGridColumnPicker:${colId}`}
                          className="flex mb-0 px-4 py-3 flex-grow"
                          checked={!column.hide}
                          onChange={() =>
                            handleCheckClick(
                              parent?.children.map((c) => c.colId) || [colId],
                            )
                          }
                          disabled={!!pinned}
                        >
                          {label}
                        </FormCheck>
                      )}
                      {canOrder && (
                        <>
                          {pinned ? (
                            <HamburgerMenuIcon
                              width={14}
                              className="mr-4 opacity-50 cursor-not-allowed"
                            />
                          ) : (
                            // sort handle for non-grouped columns
                            <SortableList.DragHandle>
                              <HamburgerMenuIcon width={14} className="mr-4" />
                            </SortableList.DragHandle>
                          )}
                        </>
                      )}
                    </SortableList.Item>
                  );
                })}
                <SortableDragOverlay>
                  <div className="h-8" />
                </SortableDragOverlay>
              </SortableList>
            </SortableDndContext>
          </div>
          {showResetState && (
            <div className="flex items-center justify-between px-4 pt-2 pb-3">
              <Button
                variant="secondary"
                className="px-2 py-1 border-grey-50 text-grey-20"
                onClick={() => resetState()}
              >
                Reset
              </Button>
              <Button
                variant="secondary"
                className="px-2 py-1 border-grey-50 text-grey-0"
                onClick={() => onHide()}
              >
                Close
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
