/**
 * @generated SignedSource<<b844d15e3dfd07f12e403f90d3eb306c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
export type VetPatientNeuterStatus = "INTACT" | "NEUTERED" | "UNKNOWN" | "%future added value";
export type VetPatientSex = "FEMALE" | "MALE" | "UNKNOWN" | "%future added value";
export type VetPatientSpecies = "AVIAN" | "CANINE" | "EQUINE" | "EXOTIC" | "FELINE" | "OTHER" | "UNKNOWN" | "%future added value";
export type UpdateStudyInfoInput = {
  accessionNumber?: string | null;
  clientMutationId?: string | null;
  createdBy?: string | null;
  createdById?: string | null;
  dicomDocument?: Record<string, any> | null;
  internalId?: string | null;
  notes?: string | null;
  patient?: UpdateStudyInfoInputPatient | null;
  sourceWorklistItemId?: string | null;
  studyDescription?: string | null;
  studyId: string;
  vetPatient?: UpdateStudyInfoInputVetPatient | null;
};
export type UpdateStudyInfoInputPatient = {
  birthDate?: string | null;
  internalId?: string | null;
  medicalRecordNumber?: string | null;
  nameFirst?: string | null;
  nameLast?: string | null;
  nameMiddle?: string | null;
  namePrefix?: string | null;
  nameSuffix?: string | null;
  sex?: PatientSex | null;
};
export type UpdateStudyInfoInputVetPatient = {
  birthDate?: string | null;
  breed?: string | null;
  clientNameFirst?: string | null;
  clientNameLast?: string | null;
  clientNameMiddle?: string | null;
  clientNamePrefix?: string | null;
  clientNameSuffix?: string | null;
  clientOrganizationName?: string | null;
  microchipNumber?: string | null;
  name?: string | null;
  neuterStatus?: VetPatientNeuterStatus | null;
  patientIdNumber?: string | null;
  sex?: VetPatientSex | null;
  species?: VetPatientSpecies | null;
  speciesFreeform?: string | null;
  weightKilograms?: number | null;
};
export type vetStudyInfoUpdateMutation$variables = {
  input: UpdateStudyInfoInput;
};
export type vetStudyInfoUpdateMutation$data = {
  readonly updateStudyInfo: {
    readonly study: {
      readonly " $fragmentSpreads": FragmentRefs<"vetStudyInfo_study">;
    } | null;
  } | null;
};
export type vetStudyInfoUpdateMutation = {
  response: vetStudyInfoUpdateMutation$data;
  variables: vetStudyInfoUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "VetPatient",
  "kind": "LinkedField",
  "name": "vetPatient",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNamePrefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNameSuffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNameFirst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNameMiddle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNameLast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientOrganizationName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "microchipNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "species",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "speciesFreeform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "breed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "neuterStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weightKilograms",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dicomDocument",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessionNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAssociatedWorklistItem",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "studyDescription",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "vetStudyInfoUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStudyInfoPayload",
        "kind": "LinkedField",
        "name": "updateStudyInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "vetStudyInfo_study",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "sourceWorklistItem",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "vetStudyInfoUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStudyInfoPayload",
        "kind": "LinkedField",
        "name": "updateStudyInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "sourceWorklistItem",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd249a02cd2def92253767a233236e3c",
    "id": null,
    "metadata": {},
    "name": "vetStudyInfoUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation vetStudyInfoUpdateMutation(\n  $input: UpdateStudyInfoInput!\n) {\n  updateStudyInfo(input: $input) {\n    study {\n      ...vetStudyInfo_study\n      id\n    }\n  }\n}\n\nfragment vetStudyInfo_study on Study {\n  vetPatient {\n    clientNamePrefix\n    clientNameSuffix\n    clientNameFirst\n    clientNameMiddle\n    clientNameLast\n    clientOrganizationName\n    name\n    patientIdNumber\n    microchipNumber\n    species\n    speciesFreeform\n    breed\n    birthDate\n    sex\n    neuterStatus\n    weightKilograms\n  }\n  dicomDocument\n  accessionNumber\n  hasAssociatedWorklistItem\n  sourceWorklistItem {\n    __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  notes\n  studyDescription\n}\n"
  }
};
})();

(node as any).hash = "312ef55dc0b373d31432ccc695c74ce0";

export default node;
