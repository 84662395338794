/**
 * @generated SignedSource<<de61c4f0bd5d774485788a4161bb1bea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CredentialingPage_credentialGroup$data = {
  readonly dueAt: string | null;
  readonly " $fragmentType": "CredentialingPage_credentialGroup";
};
export type CredentialingPage_credentialGroup$key = {
  readonly " $data"?: CredentialingPage_credentialGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"CredentialingPage_credentialGroup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CredentialingPage_credentialGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueAt",
      "storageKey": null
    }
  ],
  "type": "CredentialGroup",
  "abstractKey": null
};

(node as any).hash = "62dae182233f9e55efc7ef4050d1657d";

export default node;
