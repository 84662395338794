/**
 * @generated SignedSource<<6eccec4c48e6ac636930f9feda8b8d31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyListContent_domain$data = {
  readonly id: string;
  readonly organizationConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly viewerCanQa: boolean | null;
        readonly " $fragmentSpreads": FragmentRefs<"EmptyArchiveScreen_organization" | "StudyBatchActions_organization" | "StudyDataGrid_organization">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyBatchActions_domain" | "StudyDataGrid_domain">;
  readonly " $fragmentType": "StudyListContent_domain";
};
export type StudyListContent_domain$key = {
  readonly " $data"?: StudyListContent_domain$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_domain">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyListContent_domain",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationConnection",
      "kind": "LinkedField",
      "name": "organizationConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "viewerCanQa",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyBatchActions_organization"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyDataGrid_organization"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EmptyArchiveScreen_organization"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyDataGrid_domain"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyBatchActions_domain"
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
})();

(node as any).hash = "0b301f0677876b3a0fdc008306104a6b";

export default node;
