import Button from '@bfly/ui2/Button';
import Modal from '@bfly/ui2/Modal';
import Multiselect from '@bfly/ui2/Multiselect';
import Text from '@bfly/ui2/Text';
import useMutationTrigger from '@bfly/ui2/useMutationTrigger';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import { KeyboardEvent, useState } from 'react';
import { FormattedList, FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';
import withModal from 'utils/withModal';

import { AssignExamTypesToStudiesModal_Mutation as Mutation } from './__generated__/AssignExamTypesToStudiesModal_Mutation.graphql';
import type { AssignExamTypesToStudiesModal_organization$data as Organization } from './__generated__/AssignExamTypesToStudiesModal_organization.graphql';

interface Props {
  organization: Organization;
  studyItems: { studyId: string }[];
  onHide: () => void;
}

function AssignExamTypesToStudiesModal({
  organization,
  studyItems,
  onHide,
}: Props) {
  const [selectedExamTypes, setSelectedExamTypes] = useState<
    ConnectionNodeType<Organization, 'examTypeConnection'>[]
  >([]);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [assignExamTypes, { loading }] = useMutationTrigger<Mutation>(
    graphql`
      mutation AssignExamTypesToStudiesModal_Mutation(
        $input: BulkAddExamTypesToStudiesInput!
      ) {
        bulkAddExamTypesToStudiesOrError(input: $input) {
          ... on BulkAddExamTypesToStudiesPayload {
            studies {
              id
              examTypes {
                name
              }
            }
          }
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
    {
      input: {
        studyIds: studyItems.map(({ studyId }) => studyId),
        examTypeIds: selectedExamTypes.map(({ id }) => id),
      },
      errorToastMessage: (
        <FormattedMessage
          id="studies.assignExamTypes.failure"
          defaultMessage="Failed to assign {numExamTypes} {numExamTypes, plural,
      one {exam type}
      other {exam types}
    } to {numStudies} {numStudies, plural,
      one {exam}
      other {exams}
    }"
          values={{
            numStudies: studyItems.length,
            numExamTypes: selectedExamTypes.length,
          }}
        />
      ),
      successToastMessage: (
        <FormattedMessage
          id="studies.assignExamTypes.success"
          defaultMessage="{numExamTypes} {numExamTypes, plural,
  one {exam type}
  other {exam types}
} assigned to {numStudies} {numStudies, plural,
  one {exam}
  other {exams}
}"
          values={{
            numStudies: studyItems.length,
            numExamTypes: selectedExamTypes.length,
          }}
        />
      ),
    },
  );

  const examTypes = getNodes(organization.examTypeConnection);

  const selectPlaceholder = defineMessage({
    id: 'assignExamTypesToStudiesModal.selectPlaceholder',
    defaultMessage: 'Select exam type',
  });

  const handleSave = async () => {
    if (!selectedExamTypes.length) return;

    if (!isConfirmed) {
      setIsConfirmed(true);
      return;
    }

    await assignExamTypes();
    onHide();
  };

  const preventClosingModal = (e: KeyboardEvent<HTMLDivElement>) =>
    e.key === 'Escape' && e.stopPropagation();

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="assignExamTypesToStudiesModal.title"
            defaultMessage="Assign Exam Types"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body scrollable={false} className="flex flex-col">
        <Text>
          {isConfirmed ? (
            <FormattedMessage
              id="assignExamTypesToStudiesModal.moveConfirm"
              values={{
                numStudies: studyItems.length,
                examTypeNames: (
                  <FormattedList
                    value={selectedExamTypes.map(({ name }) => name)}
                    type="conjunction"
                  />
                ),
                b: (str: string) => <Text variant="body-bold">{str}</Text>,
              }}
              defaultMessage="Are you sure you want to assign <b>{examTypeNames}</b> to {numStudies, plural,
                =1 {the selected exam}
                other {<b>{numStudies}</b> selected exams}
              }?"
            />
          ) : (
            <FormattedMessage
              id="assignExamTypesToStudiesModal.instructions"
              values={{
                numStudies: studyItems.length,
                b: (str: string) => <Text variant="body-bold">{str}</Text>,
              }}
              defaultMessage="Choose exam types to assign to the {numStudies, plural,
                =1 {selected exam}
                other {<b>{numStudies}</b> selected exams}
              }."
            />
          )}
        </Text>
        <Text>
          <FormattedMessage
            id="assignExamTypesToStudiesModal.disclaimer"
            defaultMessage="Note: Once saved this can only be changed manually."
          />
        </Text>
        {!isConfirmed && (
          <Multiselect
            placeholder={selectPlaceholder}
            data={examTypes}
            textField="name"
            variant="secondary"
            className="w-full mt-2 mb-[25.6rem]"
            menuVariant="dark"
            dataKey="id"
            onChange={setSelectedExamTypes}
            focusFirstItem
            onKeyDown={preventClosingModal}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            disabled={!selectedExamTypes.length}
            busy={loading}
            onClick={handleSave}
          >
            <FormattedMessage {...actionMessages.save} />
          </Button>
          <Button variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(
  withModal(AssignExamTypesToStudiesModal, {
    backdrop: true,
    variant: 'dark',
    size: 'sm',
  }),
  {
    organization: graphql`
      fragment AssignExamTypesToStudiesModal_organization on Organization {
        examTypeConnection(first: 2147483647) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
);
