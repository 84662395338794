/**
 * @generated SignedSource<<bccdae8208ecfb2fafecbb706d07145e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchivePrivacyForm_archive$data = {
  readonly id: string;
  readonly isPrivate: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"ArchivePrivacyControl_archive" | "isLastPublicArchive_archive">;
  readonly " $fragmentType": "ArchivePrivacyForm_archive";
};
export type ArchivePrivacyForm_archive$key = {
  readonly " $data"?: ArchivePrivacyForm_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchivePrivacyForm_archive">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPrivate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchivePrivacyForm_archive",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchivePrivacyControl_archive"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "isLastPublicArchive_archive",
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Archive",
  "abstractKey": null
};
})();

(node as any).hash = "ec5859983896cb90875fe16e6959a459";

export default node;
