import Layout from '@4c/layout';
import Card from '@bfly/ui2/Card';
import Text from '@bfly/ui2/Text';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import SkeletonCard from 'components/SkeletonCard';

import { ExamTypeTagsPerformanceIndicator_tenant$data as Tenant } from './__generated__/ExamTypeTagsPerformanceIndicator_tenant.graphql';

interface ExamTypeTagsPerformanceIndicatorProps {
  tenant: Tenant | null;
}

function ExamTypeTagsPerformanceIndicator({
  tenant,
}: ExamTypeTagsPerformanceIndicatorProps) {
  const numExamTypeAssociations =
    tenant?.studyStatistics?.numExamTypeAssociations ?? 0;
  const numExamTypesCountingForCredentialing =
    tenant?.studyStatistics?.numExamTypesCountingForCredentialing ?? 0;

  return (
    <SkeletonCard busy={!tenant}>
      <Layout direction="column" className="ml-2">
        <Layout pad={2}>
          <div>
            <Card.Header>
              <Text variant="display-sm">
                <FormattedNumber value={numExamTypeAssociations} />
              </Text>
            </Card.Header>
            <Card.Body className="-mt-5">
              <Text variant="lg" color="headline">
                <FormattedMessage
                  id="ExamTypeTagsPerformanceIndicator.header"
                  defaultMessage="Total Exam Type Tags"
                />
              </Text>
              <div>
                <Text>
                  <FormattedMessage
                    id="ExamTypeTagsPerformanceIndicator.body"
                    defaultMessage="{countForCredentialing} Counting for Proficiency"
                    values={{
                      countForCredentialing:
                        numExamTypesCountingForCredentialing,
                    }}
                  />
                </Text>
              </div>
            </Card.Body>
          </div>
        </Layout>
      </Layout>
    </SkeletonCard>
  );
}
export default createFragmentContainer(ExamTypeTagsPerformanceIndicator, {
  tenant: graphql`
    fragment ExamTypeTagsPerformanceIndicator_tenant on TenantInterface {
      type: __typename
      studyStatistics(filter: $studyStatsFilter) {
        numExamTypeAssociations
        numExamTypesCountingForCredentialing
      }
    }
  `,
});
