import auth0 from '../config/auth0';

class LoginError extends Error {
  constructor(public code: string) {
    super('Login error');
  }
}

export default async function submitLogin({
  email,
  password,
  domainHandle,
}: {
  email: string;
  password: string;
  domainHandle: string | null;
}) {
  const resp = await fetch(`https://${auth0.domain}/oauth/token`, {
    method: 'POST',
    body: JSON.stringify({
      /* eslint-disable @typescript-eslint/naming-convention */
      grant_type: 'password',
      username: email,
      password,
      scope: `${auth0.scope}${
        domainHandle ? ` bfly-domain:${domainHandle}` : ''
      }`,
      client_id: auth0.clientId,
      sso: true,
      /* eslint-enable @typescript-eslint/naming-convention */
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const payload = await resp.json();

  if (!resp.ok) {
    throw new LoginError(payload.error);
  }

  return payload.access_token as string;
}
