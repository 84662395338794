import Layout from '@4c/layout';
import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import { defineMessages } from 'react-intl';

import {
  ALLOWED_OPTIONAL_TIMEZONES,
  Timezone,
} from 'schemas/allowedTimezones';

const ALLOWED_TIMES: Timezone[] = [];

// manual time dropdown options in 30 min increments
for (let hour = 0; hour < 24; hour++) {
  for (let minute = 0; minute < 60; minute += 30) {
    const timeValue = `${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}:00`;
    const period = hour < 12 ? 'am' : 'pm';
    const displayHour = hour % 12 === 0 ? 12 : hour % 12;
    const timeName = `${displayHour}:${minute
      .toString()
      .padStart(2, '0')} ${period}`;

    ALLOWED_TIMES.push({
      value: timeValue,
      name: timeName,
    });
  }
}

const messages = defineMessages({
  sourceTimezone: {
    id: 'workflowDigestsTimeForm.sourceTimezone',
    defaultMessage: 'Timezone',
  },

  sourceTimePlaceholder: {
    id: 'workflowDigestsTimeForm.sourceTimePlaceholder',
    defaultMessage: 'Time',
  },

  sourceTimeLabel: {
    id: 'workflowDigestsTimeForm.sourceTimeLabel',
    defaultMessage: 'Specify time of send:',
  },
});

const TimeDropdowns = () => {
  return (
    <Layout justify="space-between" className="mt-4">
      <Form.FieldGroup
        name="time"
        className="min-w-1/2 pr-8"
        variant="secondary"
        menuVariant="dark"
        as={DropdownList}
        data={ALLOWED_TIMES}
        textField="name"
        dataKey="value"
        mapFromValue={(v: Timezone) => v.value}
        placeholder={messages.sourceTimePlaceholder}
        label={messages.sourceTimeLabel}
      />
      <Form.FieldGroup
        name="timezone"
        className="min-w-1/2"
        variant="secondary"
        menuVariant="dark"
        as={DropdownList}
        data={ALLOWED_OPTIONAL_TIMEZONES}
        textField="name"
        dataKey="value"
        mapFromValue={(v: Timezone) => v.value}
        placeholder={messages.sourceTimezone}
        label="&nbsp;"
      />
    </Layout>
  );
};

export default TimeDropdowns;
