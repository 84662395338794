/**
 * @generated SignedSource<<1083c57b1c62738ec2e3213941cd32d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTopNavbar_study$data = {
  readonly capturedAt: string | null;
  readonly createdBy: {
    readonly __typename: "UserProfile";
  } | null;
  readonly finalizedAt: string | null;
  readonly handle: string | null;
  readonly isPendingFinalization: boolean | null;
  readonly secondaryAuthors: ReadonlyArray<{
    readonly __typename: "UserProfile";
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExamArchive_study" | "ExamAuthorsRefresh_study" | "ExamAuthors_study" | "ExamExamType_study" | "ExamNav_study" | "ExamPageActions_study" | "ExamStatus_study" | "ExamTags_study" | "HeaderPatientInfo_study" | "examPageSidebar_useSections_study" | "examPageSidebar_useShowQa_study">;
  readonly " $fragmentType": "ExamTopNavbar_study";
};
export type ExamTopNavbar_study$key = {
  readonly " $data"?: ExamTopNavbar_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTopNavbar_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalizedAt",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numWorksheets",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Archive",
  "kind": "LinkedField",
  "name": "archive",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheetRequiredForQa",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v5 = {
  "kind": "InlineDataFragmentSpread",
  "name": "examPageSidebar_useShowQa_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanQa",
      "storageKey": null
    },
    (v0/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_studyOrArchiveIsDeleted",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Archive",
          "kind": "LinkedField",
          "name": "archive",
          "plural": false,
          "selections": [
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_studyReviewStatus",
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numQaEntries",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "StandardQa",
          "kind": "LinkedField",
          "name": "standardQa",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamTopNavbar_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capturedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPendingFinalization",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "secondaryAuthors",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamStatus_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamPageActions_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamAuthors_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamAuthorsRefresh_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderPatientInfo_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamTags_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamArchive_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamExamType_study"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "examPageSidebar_useSections_study",
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v5/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v5/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamNav_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "841eebc8efd20df34df57d61bdc4eec2";

export default node;
