/**
 * @generated SignedSource<<ad4e33da85f13743b4330986a85fff1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamImagePageFooter_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExamImageComments_viewer">;
  readonly " $fragmentType": "ExamImagePageFooter_viewer";
};
export type ExamImagePageFooter_viewer$key = {
  readonly " $data"?: ExamImagePageFooter_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamImagePageFooter_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamImagePageFooter_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamImageComments_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "59e1e9c62b5b0d2a87c1cf7a5e68c14b";

export default node;
