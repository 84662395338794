import Button from '@bfly/ui2/Button';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';
import {
  QueryRenderer,
  RelayPaginationProp,
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import actionMessages from 'messages/actions';
import withModal from 'utils/withModal';

import StudyEndpointsModalContent from './StudyEndpointsModalContent';
import { StudyEndpointsModalQuery } from './__generated__/StudyEndpointsModalQuery.graphql';
import { StudyEndpointsModal_study$data as Study } from './__generated__/StudyEndpointsModal_study.graphql';

function StudyEndpointsModal({
  relay,
  study,
  onHide,
}: {
  relay: RelayPaginationProp;
  study: Study;
  onHide: () => void;
}) {
  const canUseHl7Connector = useVariation('hl7-connector');

  let studyEndpointsModalTitle;
  if (!study.finalizedAt) {
    studyEndpointsModalTitle = (
      <FormattedMessage
        id="study.finalization.required"
        defaultMessage="Study Finalization Required"
      />
    );
  } else if (canUseHl7Connector) {
    studyEndpointsModalTitle = (
      <FormattedMessage
        id="study.endpoints.modal.title"
        defaultMessage="Endpoints"
      />
    );
  } else {
    studyEndpointsModalTitle = (
      <FormattedMessage
        id="study.pacsDetails.modal.title"
        defaultMessage="DICOM Management"
      />
    );
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{studyEndpointsModalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          {study.finalizedAt ? (
            <QueryRenderer<StudyEndpointsModalQuery>
              environment={relay.environment}
              fetchPolicy="store-and-network"
              query={graphql`
                query StudyEndpointsModalQuery($studyId: ID!) {
                  study: node(id: $studyId) {
                    ... on Study {
                      ...StudyEndpointsModalContent_study
                    }
                  }
                }
              `}
              variables={{ studyId: study.id }}
              render={({ props }) =>
                props?.study ? (
                  <StudyEndpointsModalContent study={props.study} />
                ) : (
                  <LoadingIndicator />
                )
              }
            />
          ) : (
            <Text>
              <FormattedMessage
                id="study.not.finalized"
                defaultMessage="Study must be finalized before syncing"
              />
            </Text>
          )}
        </div>
      </Modal.Body>
      {!study.finalizedAt && (
        <Modal.Footer>
          <Modal.ButtonGroup>
            <Button size="lg" onClick={onHide}>
              <FormattedMessage {...actionMessages.close} />
            </Button>
          </Modal.ButtonGroup>
        </Modal.Footer>
      )}
    </>
  );
}

export default createFragmentContainer(
  withModal(StudyEndpointsModal, { backdrop: true, variant: 'dark' }),
  {
    study: graphql`
      fragment StudyEndpointsModal_study on Study {
        id
        finalizedAt
      }
    `,
  },
);
