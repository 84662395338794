/**
 * @generated SignedSource<<a4343daa4ecb9543c7a2067f1de6b710>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarList_userProfile$data = {
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  readonly " $fragmentType": "SearchBarList_userProfile";
};
export type SearchBarList_userProfile$key = {
  readonly " $data"?: SearchBarList_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarList_userProfile">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarList_userProfile"
};

(node as any).hash = "4c246474f4d3fff76290485d07a79d6a";

export default node;
