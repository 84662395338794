import Layout from '@4c/layout';
import Card from '@bfly/ui2/Card';
import Popover from '@bfly/ui2/Popover';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';

import { useMemberCounts } from '../utils/MemberCounts';
import DomainUserSeatsRingChart from './DomainUserSeatsRingChart';

export default function DomainUserSeatsIndicator() {
  const { numUsers, maxNumSeats, numAvailableSeats } = useMemberCounts();

  return (
    <Card
      key="DomainUserSeatsIndicator"
      className="flex-row space-x-2 pr-5 pt-3 pb-1 ml-3 mt-3 items-center  justify-between"
    >
      <Layout direction="column" className="ml-2">
        <Layout>
          {maxNumSeats ? (
            <>
              <div>
                <Card.Body>
                  <div className="pb-1 mb-2 border-b-2 border-grey-50">
                    <Text variant="lg" color="headline">
                      <FormattedMessage
                        id="DomainUserSeatsIndicator.header"
                        defaultMessage="Seats"
                      />
                    </Text>
                  </div>
                  <div>
                    <Text color="primary" variant="body-bold">
                      <FormattedMessage
                        key={numUsers}
                        id="DomainUserSeatsIndicator.numActiveUsers"
                        defaultMessage="{numUsers}"
                        values={{ numUsers }}
                      />
                    </Text>
                    <Text>
                      <FormattedMessage
                        id="DomainUserSeatsIndicator.activeUsersText"
                        defaultMessage="{numUsers, plural,
                      =1 { Active User}
                      other { Active Users}}"
                        values={{ numUsers }}
                      />
                    </Text>
                  </div>
                  <div>
                    <Text>
                      <FormattedMessage
                        id="DomainUserSeatsIndicator.totalSeats"
                        defaultMessage="{maxNumSeats, plural,
                      =1 {{maxNumSeats} Total Seat}
                      other {{maxNumSeats} Total Seats}
                    }"
                        values={{ maxNumSeats }}
                      />
                    </Text>
                  </div>
                </Card.Body>
              </div>
              <Popover.Trigger
                data-bni-id="UserSeatsPopover"
                trigger="passive"
                placement="right"
                variant="dark"
                popover={
                  <div className="mt-1">
                    <Text as="div" variant="sm-bold" className="-mb-1">
                      <FormattedMessage
                        key={numAvailableSeats}
                        id="DomainUserSeatsIndicator.numAvailableSeats"
                        defaultMessage="{numAvailableSeats, plural,
                    =1 {{numAvailableSeats} Seat Available}
                    other {{numAvailableSeats} Seats Available}}"
                        values={{ numAvailableSeats }}
                      />
                    </Text>
                  </div>
                }
              >
                <div className="ml-5" data-bni-id="UserSeatsRingChart">
                  <DomainUserSeatsRingChart
                    activeUsers={numUsers}
                    maxNumSeats={maxNumSeats}
                    numAvailableSeats={numAvailableSeats}
                  />
                </div>
              </Popover.Trigger>
            </>
          ) : (
            <div>
              <Card.Body className="text-headline">
                <Text as="div" variant="display-sm">
                  {numUsers}
                </Text>
                <Text as="div" variant="body-bold">
                  <FormattedMessage
                    id="DomainUserSeatsIndicator.activeUsersText"
                    defaultMessage="{numUsers, plural,
                  =1 { Active User}
                  other { Active Users}}"
                    values={{ numUsers }}
                  />
                </Text>
              </Card.Body>
            </div>
          )}
        </Layout>
      </Layout>
    </Card>
  );
}
