/**
 * @generated SignedSource<<55a666894f1c4c76c89864830301e201>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SplitStudyRefreshSelectDraftItem_study$data = {
  readonly archive: {
    readonly label: string | null;
  } | null;
  readonly capturedAt: string | null;
  readonly createdBy: {
    readonly name: string | null;
  } | null;
  readonly handle: string | null;
  readonly id: string;
  readonly imageConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly burnedInFile: {
          readonly " $fragmentSpreads": FragmentRefs<"Thumbnail_image">;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"StudyImagePopoverThumbnail_studyImage">;
      } | null;
    } | null> | null;
  } | null;
  readonly numImages: number | null;
  readonly patient: {
    readonly birthDate: string | null;
    readonly medicalRecordNumber: string | null;
  } | null;
  readonly practiceType: StudyPracticeType | null;
  readonly readyAt: string | null;
  readonly vetPatient: {
    readonly birthDate: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyTitle_study">;
  readonly " $fragmentType": "SplitStudyRefreshSelectDraftItem_study";
};
export type SplitStudyRefreshSelectDraftItem_study$key = {
  readonly " $data"?: SplitStudyRefreshSelectDraftItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyRefreshSelectDraftItem_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthDate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyRefreshSelectDraftItem_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readyAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capturedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medicalRecordNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        }
      ],
      "concreteType": "StudyImageConnection",
      "kind": "LinkedField",
      "name": "imageConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyImageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyImage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyImagePopoverThumbnail_studyImage"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BurnedInFile",
                  "kind": "LinkedField",
                  "name": "burnedInFile",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Thumbnail_image"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "imageConnection(first:3)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyTitle_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "efc95727e843c1b849fb7d3ef33a6b04";

export default node;
