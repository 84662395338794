/**
 * @generated SignedSource<<66bf10b08df37119a28d852ac4aa7f99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarListRecentSearchOption_studyRecentSearch$data = {
  readonly criteria: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SearchTags_studySearchCriteria">;
  } | null> | null;
  readonly " $fragmentType": "SearchBarListRecentSearchOption_studyRecentSearch";
};
export type SearchBarListRecentSearchOption_studyRecentSearch$key = {
  readonly " $data"?: SearchBarListRecentSearchOption_studyRecentSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarListRecentSearchOption_studyRecentSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBarListRecentSearchOption_studyRecentSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudySearchCriterion",
      "kind": "LinkedField",
      "name": "criteria",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SearchTags_studySearchCriteria"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyRecentSearch",
  "abstractKey": null
};

(node as any).hash = "598682bb81b1d6b923616b6434567238";

export default node;
