import Header from '@bfly/ui2/Header';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import IconButton from 'components/IconButton';
import useModalState from 'hooks/useModalState';
import actionMessages from 'messages/actions';

import StudyImageDownloadModal from './StudyImageDownloadModal';
import { StudyImageDownloadControl_image$data as Image } from './__generated__/StudyImageDownloadControl_image.graphql';

interface Props {
  image: Image;
  interpretationImageUrl?: string | null;
  icon?: ReactElement;
  title?: ReactNode;
}

function StudyImageDownloadControl({
  image,
  interpretationImageUrl,
  icon,
  title,
}: Props) {
  const [showModal, modalProps] = useModalState();

  const canUseDicomExport = !!image.study!.finalizedAt;
  const noDicomAvailable = interpretationImageUrl || !canUseDicomExport;

  if (noDicomAvailable && icon) {
    return (
      <IconButton
        download
        data-bni-id="DownloadButton"
        href={interpretationImageUrl || image!.burnedInFile!.dataUrl!}
        title={title}
        icon={icon}
      />
    );
  }

  return (
    <>
      <Header.Action onClick={showModal}>
        {icon ? (
          <IconButton
            as="div"
            title={title}
            icon={icon}
            data-bni-id="DownloadButton"
          />
        ) : (
          <FormattedMessage {...actionMessages.download} />
        )}
      </Header.Action>
      <StudyImageDownloadModal image={image} {...modalProps} />
    </>
  );
}

export default createFragmentContainer(StudyImageDownloadControl, {
  image: graphql`
    fragment StudyImageDownloadControl_image on StudyImage {
      ...StudyImageDownloadModal_image
      burnedInFile {
        dataUrl
      }
      study {
        finalizedAt
      }
    }
  `,
});
