import { Data as BaseData } from '@bfly/ui2/RingChart';
import Text from '@bfly/ui2/Text';
import { colors } from '@bfly/ui2/tailwind/colors';
import {
  FormattedMessage,
  defineMessage,
  defineMessages,
  useIntl,
} from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import RingChartCard from 'components/RingChartCard';
import {
  getExamTypeColor,
  sortByExamType,
} from 'utils/ExamTypeProficiencyUtils';

import { ReviewedExamsPerformanceIndicator_tenant$data as Tenant } from './__generated__/ReviewedExamsPerformanceIndicator_tenant.graphql';

interface Props {
  tenant: Tenant | null;
}

const axisWidth = 30;

const messages = defineMessages({
  totalQAedExams: {
    id: 'examStatusPerformanceIndicator.totalQAedExams',
    defaultMessage: "Total QA'ed exams",
  },
  partOfWhole: {
    id: 'examStatusPerformanceIndicator.partOfWhole',
    defaultMessage: '{part} of {whole}',
  },
  numQAedExams: {
    id: 'examStatusPerformanceIndicator.numQAedExams',
    defaultMessage: "{num} QA'ed {num, plural, =1 {Exam} other {Exams}}",
  },
});

type Data = BaseData & { title: string };

function popoverContent({
  data,
  hoverIndex,
  stats,
}: {
  data: Data[];
  hoverIndex: number | null;
  stats: Tenant['reviewedStudyStatistics'];
}) {
  const totalStudies = stats?.studyStatusStatistics?.reduce(
    (sum, stat) => sum + stat!.numStudies!,
    0,
  );

  return hoverIndex === null ? (
    <>
      <Text as="div" variant="sm-bold" color="headline" className="-mb-1">
        <FormattedMessage {...messages.totalQAedExams} />
      </Text>
      <Text as="div">
        <FormattedMessage
          {...messages.partOfWhole}
          values={{
            part: stats?.numReviewedStudies || 0,
            whole: totalStudies || 0,
          }}
        />
      </Text>
    </>
  ) : (
    <>
      {data
        .filter((_, index) => index === hoverIndex)
        .map((row) => (
          <div key={row.title}>
            <Text
              as="div"
              style={{ color: row.color }}
              variant="sm-bold"
              className="-mb-1"
            >
              {row.title}
            </Text>
            <Text as="div">
              <FormattedMessage
                {...messages.numQAedExams}
                values={{ num: row.value }}
              />
            </Text>
          </div>
        ))}
    </>
  );
}

function ReviewedExamsPerformanceIndicator({ tenant }: Props) {
  const { formatMessage } = useIntl();

  const totalReviewedStudies =
    tenant?.reviewedStudyStatistics?.numReviewedStudies ?? 0;
  const totalReviewedStudiesWithWorksheet =
    tenant?.reviewedStudyStatistics?.numReviewedStudiesWithWorksheets ?? 0;
  const percentReviewedWithWorksheet = !totalReviewedStudies
    ? 0
    : totalReviewedStudiesWithWorksheet / totalReviewedStudies;

  const studyExamTypeStatistics =
    tenant?.reviewedStudyStatistics?.studyExamTypeStatistics ?? [];
  const totalFinalizedStudies =
    tenant?.reviewedStudyStatistics?.numFinalizedStudies ?? 1;

  const data: Data[] = studyExamTypeStatistics
    .filter(
      (examTypeStat) =>
        examTypeStat?.examType && !!examTypeStat?.numReviewedStudies,
    )
    .sort((a, b) => sortByExamType(a!.examType, b!.examType))
    .map((examTypeStat) => {
      return {
        title: examTypeStat?.examType?.name ?? '',
        value: examTypeStat?.numReviewedStudies ?? 0,
        width: axisWidth,
        color: getExamTypeColor(examTypeStat?.examType?.systemId ?? ''),
      };
    });

  return (
    <RingChartCard
      popoverContent={({ hoverIndex }) =>
        popoverContent({
          data,
          hoverIndex,
          stats: tenant?.reviewedStudyStatistics || null,
        })
      }
      chartInside={{
        message: defineMessage({
          id: 'ReviewedExamsPerformanceIndicator.ringChart.innerText',
          defaultMessage: 'Exam{br}types',
          values: { br: <br /> },
        }),
      }}
      header={{
        value: totalReviewedStudies,
        label: <FormattedMessage {...messages.totalQAedExams} />,
      }}
      loading={!tenant}
      data={data}
      id="ReviewedExamsPerformance"
      total={totalFinalizedStudies}
      percentages={[
        {
          value: percentReviewedWithWorksheet,
          title: formatMessage({
            id: 'ReviewedExamsPerformanceIndicator.body.bottom',
            defaultMessage: 'Have a worksheet',
          }),
          color: colors.white,
        },
      ]}
    />
  );
}

export default createFragmentContainer(ReviewedExamsPerformanceIndicator, {
  tenant: graphql`
    fragment ReviewedExamsPerformanceIndicator_tenant on TenantInterface {
      reviewedStudyStatistics: studyStatistics(filter: $filter) {
        numFinalizedStudies
        numReviewedStudies
        numReviewedStudiesWithWorksheets
        studyExamTypeStatistics {
          examType {
            id
            name
            systemId
          }
          numFinalizedStudies
          numReviewedStudies
        }
        studyStatusStatistics {
          numStudies
        }
      }
    }
  `,
});
