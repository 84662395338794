/**
 * @generated SignedSource<<485351c5647fcdb62678d2777c81d17f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EduStudySidebar_study$data = {
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly readyAt: string | null;
  readonly reviewRequestedAt: string | null;
  readonly standardQa: {
    readonly createdAt: string | null;
    readonly createdBy: {
      readonly name: string | null;
    } | null;
  } | null;
  readonly totalZones: number;
  readonly zonesCompleted: number;
  readonly " $fragmentSpreads": FragmentRefs<"eduStandardQa_eduStudy">;
  readonly " $fragmentType": "EduStudySidebar_study";
};
export type EduStudySidebar_study$key = {
  readonly " $data"?: EduStudySidebar_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"EduStudySidebar_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EduStudySidebar_study",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "eduStandardQa_eduStudy",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EduStandardQa",
          "kind": "LinkedField",
          "name": "standardQa",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageQuality",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feedback",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "comments",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewRequestedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EduStandardQa",
      "kind": "LinkedField",
      "name": "standardQa",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zonesCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalZones",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readyAt",
      "storageKey": null
    }
  ],
  "type": "EduStudy",
  "abstractKey": null
};
})();

(node as any).hash = "a8e1d576bfdf6d2c26a3654d13f1adce";

export default node;
