export default function loadScript(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const js = document.createElement('script');
    js.src = url;
    js.async = true;

    document.body.appendChild(js);

    js.addEventListener('load', () => resolve());
    js.addEventListener('error', reject);
  });
}
