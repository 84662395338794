import Combobox from '@bfly/ui2/Combobox';
import DropdownInfiniteList from '@bfly/ui2/DropdownInfiniteList';
import { css } from 'astroturf';
import React from 'react';
import { defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { renderUserSuggestion } from 'components/UserSuggestionListItem';

import { FinalizationCandidatesSearchControl_candidates$data as Candidates } from './__generated__/FinalizationCandidatesSearchControl_candidates.graphql';

type Candidate = Candidates[0];

interface Props {
  candidates: Candidates;
  search: string;
  onSearch: (search: string) => void;
  onSelect: (candidate: Candidate) => void;
  loading?: boolean;
  loadMore?: () => void;
}

function FinalizationCandidatesSearchControl({
  candidates,
  onSelect,
  search,
  onSearch,
  loading,
  loadMore,
}: Props) {
  // XXX: wrapping in async/await so that clear search is triggered after onChange
  const handleSelect = async (candidate) => {
    await onSelect(candidate);
    onSearch('');
  };

  return (
    <Combobox
      focusFirstItem
      data={candidates}
      textField="email"
      filter={false}
      data-bni-id="FinalizationCandidatesSearchControl"
      renderListItem={renderUserSuggestion}
      placeholder={defineMessage({
        id: 'finalizationCandidatesSearchControl.placeholder',
        defaultMessage: 'Search attester by name',
      })}
      busy={loading}
      value={search}
      onChange={(value) =>
        onSearch(typeof value === 'string' ? value : value.email!)
      }
      onSelect={handleSelect}
      listProps={{ loadMore }}
      listComponent={DropdownInfiniteList}
      // FIXME: sass
      css={css`
        --form-control-border-color: theme('formControlLight.borderColor');
      `}
    />
  );
}

export default createFragmentContainer(FinalizationCandidatesSearchControl, {
  candidates: graphql`
    fragment FinalizationCandidatesSearchControl_candidates on Mentionable
    @relay(plural: true) {
      userProfile {
        id
        name
      }
      email
      ...UserSuggestionListItem_userInfo
    }
  `,
});
