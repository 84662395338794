import PreviewRoll from '@bfly/ui2/PreviewRoll';
import React, { useMemo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import ImagePreviewItem from 'components/ImagePreviewItem';

import { ImagePreviewRoll_images$data as Images } from './__generated__/ImagePreviewRoll_images.graphql';
import { ImagePreviewRoll_study$data as Study } from './__generated__/ImagePreviewRoll_study.graphql';

interface Props {
  study: Study | null;
  images: Images;
  activeIndex: number;
  onSelect: (idx: number) => void;
}

function ImagePreviewRoll({ study, images, activeIndex, onSelect }: Props) {
  const handleClicks = useMemo(
    () =>
      Array.from({ length: images.length }, (_, index) => (e) => {
        e.preventDefault();
        onSelect(index);
      }),
    [images.length, onSelect],
  );

  return (
    <PreviewRoll activeIndex={activeIndex}>
      {images.map((image, index) => (
        <ImagePreviewItem
          key={image.id}
          study={study}
          image={image}
          index={index}
          active={activeIndex === index}
          onClick={handleClicks[index]}
        />
      ))}
    </PreviewRoll>
  );
}

export default createFragmentContainer(ImagePreviewRoll, {
  study: graphql`
    fragment ImagePreviewRoll_study on Study {
      ...ImagePreviewItem_study
    }
  `,
  images: graphql`
    fragment ImagePreviewRoll_images on StudyImageInterface
    @relay(plural: true) {
      ... on Node {
        id
      }
      ...ImagePreviewItem_image
    }
  `,
});
