/**
 * @generated SignedSource<<47487807e309be41b6d55c61b5d143d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyListItem_study$data = {
  readonly archive: {
    readonly deletedAt: string | null;
    readonly handle: string | null;
    readonly id: string;
    readonly organization: {
      readonly slug: string | null;
    } | null;
  } | null;
  readonly archiveAttribution?: {
    readonly " $fragmentSpreads": FragmentRefs<"StudyArchive_archive">;
  } | null;
  readonly capturedAt: string | null;
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly deletedAt: string | null;
  readonly examTypes: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly finalizedAt: string | null;
  readonly handle: string | null;
  readonly id: string;
  readonly isPendingFinalization: boolean | null;
  readonly numImages: number | null;
  readonly organization: {
    readonly slug: string | null;
  } | null;
  readonly patient: {
    readonly nameFirst: string | null;
    readonly nameLast: string | null;
    readonly nameMiddle: string | null;
    readonly namePrefix: string | null;
    readonly nameSuffix: string | null;
  } | null;
  readonly practiceType: StudyPracticeType | null;
  readonly readyAt: string | null;
  readonly vetPatient: {
    readonly clientNameLast: string | null;
    readonly clientOrganizationName: string | null;
    readonly name: string | null;
  } | null;
  readonly viewerCanDelete: boolean | null;
  readonly viewerCanMove: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyAttribution_study" | "StudyControls_study" | "StudyHistoryDropdownItem_study" | "StudyImagePreviewList_study" | "StudySubtitle_study" | "StudySyncStatus_study" | "StudyTitle_study">;
  readonly " $fragmentType": "StudyListItem_study";
};
export type StudyListItem_study$key = {
  readonly " $data"?: StudyListItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyListItem_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v4 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showArchive"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyListItem_study",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capturedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readyAt",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameFirst",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameLast",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameMiddle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "namePrefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameSuffix",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientNameLast",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientOrganizationName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "showArchive",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "archiveAttribution",
          "args": null,
          "concreteType": "Archive",
          "kind": "LinkedField",
          "name": "archive",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StudyArchive_archive"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPendingFinalization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanDelete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanMove",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examTypes",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyTitle_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudySubtitle_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyControls_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyImagePreviewList_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyAttribution_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudySyncStatus_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyHistoryDropdownItem_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "6669b3fa489a8885e5f7b8842d85a985";

export default node;
