/**
 * @generated SignedSource<<e103a32198702a28fe7e9d75db338501>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type integrations_CreateEhrIntegrationPage_Query$variables = {
  organizationSlug: string;
};
export type integrations_CreateEhrIntegrationPage_Query$data = {
  readonly connectivityConfig: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateEhrIntegrationPage_connectivityConfig">;
  } | null;
};
export type integrations_CreateEhrIntegrationPage_Query = {
  response: integrations_CreateEhrIntegrationPage_Query$data;
  variables: integrations_CreateEhrIntegrationPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "integrations_CreateEhrIntegrationPage_Query",
    "selections": [
      {
        "alias": "connectivityConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateEhrIntegrationPage_connectivityConfig"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "integrations_CreateEhrIntegrationPage_Query",
    "selections": [
      {
        "alias": "connectivityConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "hospitalConnections",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": "type",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isHospitalConnectionInterface"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isSecured",
                        "storageKey": null
                      }
                    ],
                    "type": "DicomTlsConnection",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "organizationId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "domainId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "ConnectivityConfigInterface",
            "abstractKey": "__isConnectivityConfigInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9a06900f474cfecbf1a091633053a3f",
    "id": null,
    "metadata": {},
    "name": "integrations_CreateEhrIntegrationPage_Query",
    "operationKind": "query",
    "text": "query integrations_CreateEhrIntegrationPage_Query(\n  $organizationSlug: String!\n) {\n  connectivityConfig: organizationBySlug(slug: $organizationSlug) {\n    ...CreateEhrIntegrationPage_connectivityConfig\n    id\n  }\n}\n\nfragment CreateButterflyLinkControl_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  hospitalConnections {\n    __typename\n    type: __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  ...useTenantIdInput_tenant\n}\n\nfragment CreateEhrIntegrationPage_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ...EhrForm_connectivityConfig\n  ...useTenantIdInput_tenant\n}\n\nfragment EhrForm_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  hospitalConnections {\n    __typename\n    type: __typename\n    ...HospitalServerForm_hospitalConnections\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  ...CreateButterflyLinkControl_connectivityConfig\n}\n\nfragment HospitalServerForm_hospitalConnections on HospitalConnectionInterface {\n  __isHospitalConnectionInterface: __typename\n  name\n  type: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ... on DicomTlsConnection {\n    isSecured\n  }\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "926206587e76e86c4308e1a2ef88d3a4";

export default node;
