/**
 * @generated SignedSource<<dbcb3f37e352252c8117b8d1dd368233>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateUserAndAcceptInvitePage_inviteInfo$data = {
  readonly email: string | null;
  readonly organizationInfo: {
    readonly domainProfile: {
      readonly federatedIdentityProvider: {
        readonly __typename: "FederatedIdentityProvider";
      } | null;
      readonly handle: string | null;
    } | null;
  } | null;
  readonly showMarketingOptOutToggle: boolean | null;
  readonly " $fragmentType": "CreateUserAndAcceptInvitePage_inviteInfo";
};
export type CreateUserAndAcceptInvitePage_inviteInfo$key = {
  readonly " $data"?: CreateUserAndAcceptInvitePage_inviteInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateUserAndAcceptInvitePage_inviteInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateUserAndAcceptInvitePage_inviteInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showMarketingOptOutToggle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationInfo",
      "kind": "LinkedField",
      "name": "organizationInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainProfile",
          "kind": "LinkedField",
          "name": "domainProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "handle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FederatedIdentityProvider",
              "kind": "LinkedField",
              "name": "federatedIdentityProvider",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationInviteInfo",
  "abstractKey": null
};

(node as any).hash = "4891317181a14c0b82ce572b80738e73";

export default node;
