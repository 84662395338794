/**
 * @generated SignedSource<<3e79bac82cb8cabdbddffa84cd3058a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PacsPushJobStatus = "CANCELED" | "COMPLETE" | "FAILED" | "PENDING" | "RETRYING" | "%future added value";
export type StudyPacsPushStatusUpdatedSubscriptionInput = {
  clientSubscriptionId?: string | null;
  studyId: string;
};
export type StudyEndpointsModalContentPacsSubscription$variables = {
  input: StudyPacsPushStatusUpdatedSubscriptionInput;
};
export type StudyEndpointsModalContentPacsSubscription$data = {
  readonly studyPacsPushStatusUpdated: {
    readonly study: {
      readonly pacsPushJobs: ReadonlyArray<{
        readonly pacs: {
          readonly id: string;
        } | null;
        readonly status: PacsPushJobStatus | null;
        readonly " $fragmentSpreads": FragmentRefs<"PacsSyncStatus_job" | "SendStudyToPacsButton_job">;
      } | null> | null;
      readonly " $fragmentSpreads": FragmentRefs<"StudySyncStatus_study">;
    } | null;
  } | null;
};
export type StudyEndpointsModalContentPacsSubscription = {
  response: StudyEndpointsModalContentPacsSubscription$data;
  variables: StudyEndpointsModalContentPacsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StudyEndpointsModalContentPacsSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyPacsPushStatusUpdatedSubscriptionPayload",
        "kind": "LinkedField",
        "name": "studyPacsPushStatusUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StudySyncStatus_study"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PacsPushJob",
                "kind": "LinkedField",
                "name": "pacsPushJobs",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PacsSyncStatus_job"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SendStudyToPacsButton_job"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pacs",
                    "kind": "LinkedField",
                    "name": "pacs",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StudyEndpointsModalContentPacsSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyPacsPushStatusUpdatedSubscriptionPayload",
        "kind": "LinkedField",
        "name": "studyPacsPushStatusUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pacsPushStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pacsPushHasPausedJob",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ehrOruStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ehrOruHasPausedJob",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PacsPushJob",
                "kind": "LinkedField",
                "name": "pacsPushJobs",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pacs",
                    "kind": "LinkedField",
                    "name": "pacs",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paused",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOutdated",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "86103f7040e13bbe89287314ecae886f",
    "id": null,
    "metadata": {},
    "name": "StudyEndpointsModalContentPacsSubscription",
    "operationKind": "subscription",
    "text": "subscription StudyEndpointsModalContentPacsSubscription(\n  $input: StudyPacsPushStatusUpdatedSubscriptionInput!\n) {\n  studyPacsPushStatusUpdated(input: $input) {\n    study {\n      ...StudySyncStatus_study\n      pacsPushJobs {\n        ...PacsSyncStatus_job\n        ...SendStudyToPacsButton_job\n        pacs {\n          id\n        }\n        status\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment PacsSyncStatus_job on PacsPushJob {\n  completedAt\n  pacs {\n    name\n    paused\n    id\n  }\n  status\n  isOutdated\n}\n\nfragment SendStudyToPacsButton_job on PacsPushJob {\n  id\n  status\n}\n\nfragment StudySyncStatus_study on Study {\n  pacsPushStatus\n  pacsPushHasPausedJob\n  ehrOruStatus\n  ehrOruHasPausedJob\n}\n"
  }
};
})();

(node as any).hash = "361233304045426e6b40152bf36ecc19";

export default node;
