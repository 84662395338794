/**
 * @generated SignedSource<<f00b80185b9c94747170b3bd5fde50b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarListSuggestionOption_userProfile$data = {
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  readonly " $fragmentType": "SearchBarListSuggestionOption_userProfile";
};
export type SearchBarListSuggestionOption_userProfile$key = {
  readonly " $data"?: SearchBarListSuggestionOption_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_userProfile">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarListSuggestionOption_userProfile"
};

(node as any).hash = "42292a48d04035123f1396e43c2b0075";

export default node;
