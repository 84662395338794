import Collapse from '@bfly/ui2/Collapse';
import Form from '@bfly/ui2/Form';
import Heading from '@bfly/ui2/Heading';
import Textarea from '@bfly/ui2/Textarea';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useUpdateImmediateEffect from '@restart/hooks/useUpdateImmediateEffect';
import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import * as yup from 'yup';

import ExpandIcon from 'components/ExpandIcon';
import { useVariation } from 'components/LaunchDarklyContext';

import { useSections } from '../utils/examPageSidebar';
import { useExamSetterContext } from './ExamContext';
import { AUTOSAVE_TIMEOUT } from './WorksheetAutosaveForm';
import { ExamPageSidebarNotes_UpdateStudyInfo_Mutation } from './__generated__/ExamPageSidebarNotes_UpdateStudyInfo_Mutation.graphql';
import { ExamPageSidebarNotes_study$data as Study } from './__generated__/ExamPageSidebarNotes_study.graphql';

const messages = defineMessages({
  notes: {
    id: 'examPageSidebar.studyNotesHeader',
    defaultMessage: 'Notes',
  },
  noExamNotes: {
    id: 'examPageSidebarNotes.noExamNotes',
    defaultMessage: 'No Exam Notes',
  },
});

interface Props {
  study: Study;
}

function ExamPageSidebarNotes({ study }: Props) {
  const canUseWorksheets = useVariation('worksheets');

  const notesFieldDisabled = useVariation('disable-notes-field');

  const { showWorksheets } = useSections(study);

  const [notesExpanded, setNotesExpanded] = useState(
    !!study.notes || !showWorksheets,
  );

  const { setSaving } = useExamSetterContext();

  const [notesValue, setNotesValue] = useState(study.notes ?? '');

  const isDraft = !study.finalizedAt;

  const schema = yup.object({
    notes: yup.string().default('').trim(),
  });

  const updateStudyInfoMutation = graphql`
    mutation ExamPageSidebarNotes_UpdateStudyInfo_Mutation(
      $input: UpdateStudyInfoInput!
    ) {
      updateStudyInfoOrError(input: $input) {
        ... on UpdateStudyInfoPayload {
          study {
            notes
          }
        }
        ...RelayForm_error @relay(mask: false)
      }
    }
  `;

  const [updateStudy] =
    useMutationWithError<ExamPageSidebarNotes_UpdateStudyInfo_Mutation>(
      updateStudyInfoMutation,
    );

  const debouncedUpdateStudy = useMemo(
    () =>
      debounce(async (input: { notes: string; studyId: string }) => {
        setSaving(true);
        await updateStudy({ input });
        setSaving(false);
      }, AUTOSAVE_TIMEOUT),
    [setSaving, updateStudy],
  );

  // Reset notes value upon changing studies
  useUpdateImmediateEffect(() => {
    setNotesValue(study.notes ?? '');
  }, [study.notes]);

  return (
    <>
      {canUseWorksheets && (
        <Heading
          flush
          as="button"
          // @ts-ignore
          type="button"
          className="border-y border-grey-80 py-4 px-5 w-full focus-visible:ring flex items-center justify-between"
          color="subtitle"
          variant="sm-bold"
          transform="uppercase"
          data-bni-id="ExamNotesHeader"
          onClick={() => setNotesExpanded((prev) => !prev)}
        >
          <FormattedMessage {...messages.notes} />
          <ExpandIcon expanded={notesExpanded} className="text-white" />
        </Heading>
      )}

      <Collapse in={canUseWorksheets ? notesExpanded : true}>
        <div className="py-4 px-5">
          {!notesFieldDisabled && isDraft ? (
            <Form
              schema={schema}
              value={{ notes: notesValue }}
              onChange={(input) => {
                setNotesValue(input.notes);
                debouncedUpdateStudy({
                  notes: input.notes,
                  studyId: study.id,
                });
              }}
            >
              <Form.Field
                data-bni-id="ExamPageSidebarNotesEditable"
                as={Textarea}
                name="notes"
                variant="secondary"
                rows={3}
                placeholder={messages.noExamNotes}
              />
            </Form>
          ) : (
            <p data-bni-id="ExamPageSidebarNotesNonEditable">
              {study.notes || <FormattedMessage {...messages.noExamNotes} />}
            </p>
          )}
        </div>
      </Collapse>
    </>
  );
}

export default createFragmentContainer(ExamPageSidebarNotes, {
  study: graphql`
    fragment ExamPageSidebarNotes_study on Study {
      id
      notes
      finalizedAt
      ...examPageSidebar_useSections_study
    }
  `,
});
