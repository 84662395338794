/**
 * @generated SignedSource<<11fd2643a7b917e36a4413d0fd8cb326>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteCapturesDropdownItem_study$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DeleteCapturesModal_study" | "StudyPermissions_canDeleteCaptures">;
  readonly " $fragmentType": "DeleteCapturesDropdownItem_study";
};
export type DeleteCapturesDropdownItem_study$key = {
  readonly " $data"?: DeleteCapturesDropdownItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteCapturesDropdownItem_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteCapturesDropdownItem_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteCapturesModal_study"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canDeleteCaptures",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizedAt",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_studyOrArchiveIsDeleted",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "7e8c180c696749e54dcc8b157556ea8e";

export default node;
