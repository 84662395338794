export enum ScanType {
  ASSISTED_SCAN = 'ASSISTED_SCAN',
  FREE_SCAN = 'FREE_SCAN',
}
export enum ImageCaptureMode {
  B_MODE = 'B_MODE',
  COLOR_DOPPLER = 'COLOR_DOPPLER',
}
export enum StudyProtocolType {
  WORKFLOW = 'WORKFLOW',
  INDICATION = 'INDICATION',
}

export interface ViewInterface {
  name: string;
  presetId: typeof DEEP_CARDIAC_ID;
  type: ScanType.ASSISTED_SCAN | ScanType.FREE_SCAN;
  mode: ImageCaptureMode.B_MODE | ImageCaptureMode.COLOR_DOPPLER;
}
// FIXME - DEEP_CARDIAC_ID and STATIC_VIEWS static data should be replaced in the future
export const DEEP_CARDIAC_ID =
  '93fe907e-8949-11e9-a600-8c85908620ba' as string;
// Default system protocol id
export const ALL_CARDIAC_VIEWS_ID = 'all-cardiac-views';
export const STATIC_VIEW_NAMES = [
  'PLAX',
  'PSAX-Aortic Valve',
  'PSAX-Mitral Valve',
  'PSAX-Papillary Muscle',
  'AP4',
  'AP5',
  'AP2',
  'AP3',
  'SubC4',
  'SubC-IVC',
];
export const STATIC_VIEWS: ViewInterface[] = STATIC_VIEW_NAMES.map((name) => ({
  name,
  presetId: DEEP_CARDIAC_ID,
  type: ScanType.ASSISTED_SCAN,
  mode: ImageCaptureMode.B_MODE,
}));

export const hydrateViews = (views: string[]) => {
  const STATIC_VIEW_NAMES_SET = new Set(STATIC_VIEW_NAMES);
  return views.map((elem) => {
    if (STATIC_VIEW_NAMES_SET.has(elem)) {
      return {
        name: elem,
        presetId: DEEP_CARDIAC_ID,
        type: ScanType.ASSISTED_SCAN,
        mode: ImageCaptureMode.B_MODE,
      };
    }
    return {
      name: elem,
      type: ScanType.FREE_SCAN,
      presetId: DEEP_CARDIAC_ID,
      mode: ImageCaptureMode.COLOR_DOPPLER,
    };
  });
};
