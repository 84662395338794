import { useCallback, useMemo } from 'react';

import usePreferences from 'hooks/usePreferences';

export enum StudyStatisticsColumnUserPreferenceKey {
  STUDY_STATISTICS_LIST = 'studyStatisticsList',
}

export interface StudyStatisticsColumnUserPreference {
  [StudyStatisticsColumnUserPreferenceKey.STUDY_STATISTICS_LIST]?: (
    | string[]
    | Record<string, boolean>
  )[];
  version?: string;
}

export const STUDY_STATISTICS_COLUMNS_CURRENT_VERSION = '1.0';

const useStudyStatisticsColumnsUserPreference = (
  columnsKey: StudyStatisticsColumnUserPreferenceKey,
) => {
  const [preferredColumns, setPreferredColumns] =
    usePreferences<StudyStatisticsColumnUserPreference>(
      'studyStatisticsColumns',
    );

  const setColumns = useCallback(
    (next: StudyStatisticsColumnUserPreference) => {
      setPreferredColumns({
        ...next,
        version: STUDY_STATISTICS_COLUMNS_CURRENT_VERSION,
      });
    },
    [setPreferredColumns],
  );

  const columns = useMemo(() => {
    return !preferredColumns || Array.isArray(preferredColumns)
      ? // new or invalid
        { version: STUDY_STATISTICS_COLUMNS_CURRENT_VERSION, [columnsKey]: [] }
      : // existing
        {
          ...preferredColumns,
          version: STUDY_STATISTICS_COLUMNS_CURRENT_VERSION,
          [columnsKey]: Array.isArray(preferredColumns?.[columnsKey])
            ? preferredColumns?.[columnsKey]
            : [],
        };
  }, [columnsKey, preferredColumns]);

  return [columns, setColumns] as const;
};

export default useStudyStatisticsColumnsUserPreference;
