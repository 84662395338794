import { defineMessages } from 'react-intl';

export const domainMembersGridMessages = defineMessages({
  usersChanges: {
    id: 'domainMembersPage.grid.users-changes',
    defaultMessage: `Changes will be made to {numUsers} {numUsers, plural,
      one {user}
      other {users}
    }`,
  },
  bulkActions: {
    id: 'domainMembersPage.grid.bulk-actions',
    defaultMessage: `Bulk actions`,
  },
  bulkUpdateRoles: {
    id: 'domainMembersPage.grid.bulk-update-roles',
    defaultMessage: 'Change Access Roles',
  },
  bulkDeactivateUsers: {
    id: 'domainMembersPage.grid.bulk-deactivate-users',
    defaultMessage: 'Deactivate Users',
  },
  membersSelected: {
    id: 'domainMembersPage.grid.members-selected',
    defaultMessage: `{numMembers} {numMembers, plural,
      one {member}
      other {members}
    } selected`,
  },
  discardChanges: {
    id: 'domainMembersPage.grid.discard-changes',
    defaultMessage: 'Discard changes',
  },
  discardDialogMessage: {
    id: 'domainMembersPage.grid.discard-dialog-message',
    defaultMessage:
      "Are you sure you want to discard all your local changes? This action can't be undone.",
  },
  loading: {
    id: 'domainMembersPage.grid.loading',
    defaultMessage: '{numUsers} loaded',
  },
  unsavedDialogTitle: {
    id: 'domainMembersPage.unsaved.dialog.title',
    defaultMessage: 'Leave Enterprise Members',
  },
  unsavedDialogContent: {
    id: 'domainMembersPage.unsaved.dialog.content',
    defaultMessage:
      'Do you want to leave Enterprise Members? Your unsaved changes will be discarded',
  },
  unsavedDialogConfirmation: {
    id: 'domainMembersPage.unsaved.dialog.confirm',
    defaultMessage: 'OK',
  },
});

export const domainMembersGridColumnHeaders = defineMessages({
  butterflyAccessRole: {
    id: 'domainMembersPage.grid.columnHeader.butterflyAccessRole',
    defaultMessage: 'Butterfly Access Role',
  },
  canQA: {
    id: 'domainMembersPage.grid.columnHeader.canQA',
    defaultMessage: 'Can QA',
  },
  canFinalize: {
    id: 'domainMembersPage.grid.columnHeader.canFinalize',
    defaultMessage: 'Can Finalize',
  },
  enterpriseAdmin: {
    id: 'domainMembersPage.grid.columnHeader.enterpriseAdmin',
    defaultMessage: 'Enterprise Admin',
  },
  emailAddress: {
    id: 'domainMembersPage.grid.columnHeader.emailAddress',
    defaultMessage: `Email Address`,
  },
  firstName: {
    id: 'domainMembersPage.grid.columnHeader.firstName',
    defaultMessage: `First Name`,
  },
  middleInitial: {
    id: 'domainMembersPage.grid.columnHeader.middleInitial',
    defaultMessage: `Middle Initial`,
  },
  lastName: {
    id: 'domainMembersPage.grid.columnHeader.lastName',
    defaultMessage: `Last Name`,
  },
  organizations: {
    id: 'domainMembersPage.grid.columnHeader.organizations',
    defaultMessage: `Organizations`,
  },
  interfaceCodes: {
    id: 'domainMembersPage.grid.columnHeader.interfaceCodes',
    defaultMessage: `Interface codes`,
  },
  dicomFieldMappings: {
    id: 'domainMembersPage.grid.columnHeader.dicomFieldMappings',
    defaultMessage: `DICOM field mappings`,
  },
  dateEdited: {
    id: 'domainMembersPage.grid.columnHeader.dateEdited',
    defaultMessage: `Date Edited`,
  },
  dateLastActive: {
    id: 'domainMembersPage.grid.columnHeader.dateLastActive',
    defaultMessage: `Date Last Active`,
    type: 'date',
  },
});

export const bulkDomainUsersUpdateModalMessages = defineMessages({
  title: {
    id: 'bulkDomainUsersUpdateModal.title',
    defaultMessage: `Confirm Changes`,
  },
  confirm: {
    id: 'bulkDomainUsersUpdateModal.confirm',
    defaultMessage: `Confirm Changes`,
  },
  cancel: {
    id: 'bulkDomainUsersUpdateModal.cancel',
    defaultMessage: `Back to bulk edit`,
  },
  bodyTextReview: {
    id: 'bulkDomainUsersUpdateModal.bodyTextReview',
    defaultMessage: `Please review and confirm the changes. <strong>This action cannot be undone.</strong>`,
  },
  bodyTextUpdated: {
    id: 'bulkDomainUsersUpdateModal.bodyTextUpdated',
    defaultMessage: `Information will be updated for <strong>{numUpdated} {numUpdated, plural,
      one {member}
      other {members}
    }.</strong>`,
  },
  toastError: {
    id: 'bulkDomainUsersUpdateModal.toastError',
    defaultMessage: `An unexpected error occurred.`,
  },
  toastSuccess: {
    id: 'bulkDomainUsersUpdateModal.toastSuccess',
    defaultMessage: `{numUpdated} {numUpdated, plural,
      one {user}
      other {users}
    } updated.`,
  },
});

export const bulkAddOrganizationsMessages = defineMessages({
  title: {
    id: 'bulkAddOrganizations.modal.title',
    defaultMessage: 'Bulk Edits - Add Organizations',
  },
  placeholder: {
    id: 'bulkAddOrganizations.modal.selectPlaceholder',
    defaultMessage: 'Select organizations',
  },
  typePlaceholder: {
    id: 'bulkAddOrganizations.modal.type.placeholder',
    defaultMessage: 'Type',
  },
  typeDescription: {
    id: 'bulkAddOrganizations.modal.type.description',
    defaultMessage: 'Membership type',
  },
  typeRequired: {
    id: 'bulkAddOrganizations.modal.type.required',
    defaultMessage: 'Please enter a valid membership type',
  },
});

export const bulkRemoveOrganizationsMessages = defineMessages({
  title: {
    id: 'bulkRemoveOrganizations.modal.title',
    defaultMessage: 'Bulk Edits - Remove Organizations',
  },
  placeholder: {
    id: 'bulkRemoveOrganizations.modal.selectPlaceholder',
    defaultMessage: 'Select organizations',
  },
});

export const bulkActionsMessages = defineMessages({
  cancel: {
    id: 'bulkActions.modal.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'bulkActions.modal.confirm',
    defaultMessage: 'Confirm Changes',
  },
  dropdownLabel: {
    id: 'bulkActions.dropdown.dropdownLabel',
    defaultMessage: 'Bulk actions',
  },
  addOrganizations: {
    id: 'bulkActions.dropdown.addOrganizations',
    defaultMessage: 'Add Organizations',
  },
  removeOrganizations: {
    id: 'bulkActions.dropdown.removeOrganizations',
    defaultMessage: 'Remove Organizations',
  },
  updateAccessRoles: {
    id: 'bulkActions.dropdown.updateAccessRoles',
    defaultMessage: 'Update Butterfly Access Roles',
  },
  backToEdit: {
    id: 'bulkActions.dropdown.backToEdit',
    defaultMessage: 'Back to bulk edit',
  },
  bulkReactivateUsers: {
    id: 'bulkActions.dropdown.bulkReactivateUsers',
    defaultMessage: 'Reactivate Users',
  },
});

export const bulkDomainUsersUpdateRoleModalMessages = defineMessages({
  title: {
    id: 'bulkDomainUsersUpdateRoleModal.title',
    defaultMessage: `Bulk Edits - Change Access Roles`,
  },
  confirm: {
    id: 'bulkDomainUsersUpdateRoleModal.confirm',
    defaultMessage: `Confirm Changes`,
  },
  cancel: {
    id: 'bulkDomainUsersUpdateRoleModal.cancel',
    defaultMessage: `Cancel`,
  },
  bodyTextNote: {
    id: 'bulkDomainUsersUpdateRoleModal.bodyTextNote',
    defaultMessage: `<strong>Note:</strong> this will overwrite existing roles`,
  },
  selectRole: {
    id: 'bulkDomainUsersUpdateRoleModal.selectRole',
    defaultMessage: 'Select role',
  },
});

export const bulkDomainUsersUpdateRoleConfirmationModalMessages =
  defineMessages({
    title: {
      id: 'bulkDomainUsersUpdateRoleConfirmationModalMessages.title',
      defaultMessage: `Bulk Edits - Change Access Roles`,
    },
    confirm: {
      id: 'bulkDomainUsersUpdateRoleConfirmationModalMessages.confirm',
      defaultMessage: `Confirm Changes`,
    },
    cancel: {
      id: 'bulkDomainUsersUpdateRoleConfirmationModalMessages.cancel',
      defaultMessage: `Cancel`,
    },
    bodyTextUpdated: {
      id: 'bulkDomainUsersUpdateRoleConfirmationModalMessages.bodyTextUpdated',
      defaultMessage: `Butterfly Access Roles will be updated for <strong>{numUpdated} {numUpdated, plural,
      one {member}
      other {members}
    }.</strong>`,
    },
    toastError: {
      id: 'bulkDomainUsersUpdateRoleConfirmationModalMessages.toastError',
      defaultMessage: `An unexpected error occured.`,
    },
    toastSuccess: {
      id: 'bulkDomainUsersUpdateRoleConfirmationModalMessages.toastSuccess',
      defaultMessage: `{numUpdated} {numUpdated, plural,
      one {user}
      other {users}
    } updated.`,
    },
  });

export const bulkDomainUsersDeactivateUsersMessages = defineMessages({
  title: {
    id: 'bulkDomainUsersDeactivateUsers.modal.title',
    defaultMessage: 'Deactivate Users - Confirm Changes',
  },
  bodyTextReview: {
    id: 'bulkDomainUsersDeactivateUsers.modal.bodyTextReview',
    defaultMessage: `These users will be deactivated from {domainName} and their access revoked from all organizations. They will be demoted to the Non Attesting User access role (i.e. given the lowest permissions). Their integration configs, and DICOM mappings will be deleted.`,
  },
  bodyTextUpdated: {
    id: 'bulkDomainUsersDeactivateUsers.modal.bodyTextUpdated',
    defaultMessage: `<strong>{numUpdated} {numUpdated, plural,
      one {user}
      other {users}
    }</strong> will be deactivated.`,
  },
  confirm: {
    id: 'bulkDomainUsersDeactivateUsers.modal.confirm',
    defaultMessage: `Confirm Changes`,
  },
  cancel: {
    id: 'bulkDomainUsersDeactivateUsers.modal.cancel',
    defaultMessage: 'Cancel',
  },
  toastError: {
    id: 'bulkDomainUsersDeactivateUsers.modal.toastError',
    defaultMessage: `An unexpected error occurred.`,
  },
  toastSuccess: {
    id: 'bulkDomainUsersDeactivateUsers.modal.toastSuccess',
    defaultMessage: `{numUpdated} {numUpdated, plural,
      one {user}
      other {users}
    } deactivated.`,
  },
});

export const bulkDomainUsersDeactivateUsersResultsMessages = defineMessages({
  title: {
    id: 'bulkDomainUsersDeactivateUsersResults.modal.title',
    defaultMessage: 'Deactivate Summary',
  },
  deactivated: {
    id: 'bulkDomainUsersDeactivateUsersResults.modal.deactivated',
    defaultMessage: `{numDeactivated} {numDeactivated, plural, one {user deactivated.} other {users deactivated.}}`,
  },
  deactivatedSummary: {
    id: 'bulkDomainUsersDeactivateUsersResults.modal.deactivatedSummary',
    defaultMessage:
      '{numFailed} {numFailed, plural, one {user is the last Enterprise Administrator and cannot be deactivated.} other {users are the last Enterprise Administrators and cannot be deactivated the same time.}}',
  },
  subtitle: {
    id: 'bulkDomainUsersDeactivateUsersResults.modal.subtitle',
    defaultMessage:
      'Please make sure you keep at least 1 active user with the Enterprise Administrator role',
  },
});

export const bulkDomainUsersReactivateUsersConfirmationModalMessages =
  defineMessages({
    title: {
      id: 'bulkDomainUsersReactivateUsers.modal.title',
      defaultMessage: `Reactivate Users`,
    },
    subtitle: {
      id: 'bulkDomainUsersReactivateUsers.modal.subtitle',
      defaultMessage: `Please review and confirm the changes.`,
    },
    confirm: {
      id: 'bulkDomainUsersReactivateUsers.modal.confirm',
      defaultMessage: `Confirm Changes`,
    },
    cancel: {
      id: 'bulkDomainUsersReactivateUsers.modal.cancel',
      defaultMessage: `Cancel`,
    },
    bodyTextReactivated: {
      id: 'bulkDomainUsersReactivateUsers.modal.bodyTextUpdated',
      defaultMessage: `<strong>{numUpdated} {numUpdated, plural,
    one {user}
    other {users}
  }</strong> will be reactivated.`,
    },
    maxSeatsExceededError: {
      id: 'bulkDomainUsersReactivateUsers.modal.maxSeatsExceededError',
      defaultMessage: `<strong>Cannot reactivate {confirmedUsers} users.</strong>  Please select {numAvailableSeats} or fewer users to reactivate or contact support to add additional seats.`,
    },
    toastMaxSeatsExceeded: {
      id: 'bulkDomainUsersReactivateUsersResults.modal.toastMaxSeatsExceeded',
      defaultMessage: 'Not enough available seats',
    },
    toastError: {
      id: 'bulkDomainUsersReactivateUsers.modal.toastError',
      defaultMessage: `An unexpected error occured.`,
    },
    toastSuccess: {
      id: 'bulkDomainUsersReactivateUsers.modal.toastSuccess',
      defaultMessage: `{numUpdated} {numUpdated, plural, 
      one {user}
      other {users}
    } reactivated.`,
    },
  });

export const bulkDomainUsersReactivateUsersResultsMessages = defineMessages({
  title: {
    id: 'bulkDomainUsersReactivateUsersResults.modal.title',
    defaultMessage: 'Reactivate Summary',
  },
  reactivated: {
    id: 'bulkDomainUsersReactivateUsersResults.modal.reactivated',
    defaultMessage: `{numReactivated} {numReactivated, plural, one {user reactivated.} other {users reactivated.}}`,
  },
  nonexistingSummary: {
    id: 'bulkDomainUsersReactivateUsersResults.modal.nonexistingSummary',
    defaultMessage: `{numFailed} {numFailed, plural, one {user does not exist and cannot be reactivated.} other {users do not exist and cannot be reactivated.}}`,
  },
  activeSummary: {
    id: 'bulkDomainUsersReactivateUsersResults.modal.activeSummary',
    defaultMessage: `{numFailed} {numFailed, plural, one {user is already active and cannot be reactivated.} other {users are already active and cannot be reactivated.}}`,
  },
  deletedSummary: {
    id: 'bulkDomainUsersReactivateUsersResults.modal.deletedSummary',
    defaultMessage: `{numFailed} {numFailed, plural, one {user is deleted and cannot be reinstated.} other {users are deleted and cannot be reinstated.}}`,
  },
  subtitle: {
    id: 'bulkDomainUsersReactivateUsersResults.modal.subtitle',
    defaultMessage: 'Contact support for help',
  },
});

export const domainMembersUserSeatsMessages = defineMessages({
  seatLimitReachedMessage: {
    id: 'domainMembersPage.userSeats.seatLimitReachedMessage',
    defaultMessage: `Please upgrade to add available seats, or remove inactive existing users.`,
  },
});
