import Layout from '@4c/layout';
import CineIcon from '@bfly/icons/Cine';
import { stylesheet } from 'astroturf';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import AspectRatioBox from 'components/AspectRatioBox';
import Thumbnail from 'components/Thumbnail';

import NumCommentsView from './NumCommentsView';
import { StudyImageThumbnail_image$data as StudyImage } from './__generated__/StudyImageThumbnail_image.graphql';

const styles = stylesheet`

  .container {
    width: 100%;
  }

  .image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .box {
    composes: inset-0 from global;

    position: absolute;
    padding: 0 10px 12px;

    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    );
    color: white;
  }

  .num-comments {
    color: white;
    margin-bottom: -2px; // Comment count text height.
  }

  .cine-label {
    margin-left: auto;
    height: 10px;
    width: 20px;
  }
`;

interface Props {
  image: StudyImage;
  index: number;
  numComments: number;
}

function StudyImageThumbnail({ image, index, numComments }: Props) {
  const { isVideo } = image;

  return (
    <AspectRatioBox className={styles.container}>
      <Thumbnail
        image={image}
        alt={`Preview ${index + 1}`}
        className={styles.image}
      />

      {!!(numComments || isVideo) && (
        <Layout align="flex-end" className={styles.box}>
          {!!numComments && (
            <NumCommentsView
              iconColor="white"
              numComments={numComments}
              className={styles.numComments}
            />
          )}
          {isVideo && <CineIcon className={styles.cineLabel} />}
        </Layout>
      )}
    </AspectRatioBox>
  );
}

export default createFragmentContainer(StudyImageThumbnail, {
  image: graphql`
    fragment StudyImageThumbnail_image on ImageFileInterface {
      isVideo
      ...Thumbnail_image
    }
  `,
});
