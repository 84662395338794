import { css } from 'astroturf';
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Dl from 'components/DescriptionList';
import FormFieldType from 'config/FormFieldType';

import { TemplatedFieldListItem_fieldDefinition$data as FieldDefinition } from './__generated__/TemplatedFieldListItem_fieldDefinition.graphql';

interface Props {
  fieldDefinition: FieldDefinition;
  value: any;
}

function TemplatedFieldListItem({ fieldDefinition, value }: Props) {
  const displayValue = useMemo(() => {
    let displayVal;
    switch (fieldDefinition.typename) {
      case FormFieldType.MULTI_SELECT: {
        if (!value) {
          displayVal = value;
        } else {
          const optionMap = keyBy(fieldDefinition.options, 'handle');
          // "value" will be present for "Other" entries
          const selectedLabels = value.map(
            (v) => v.value || optionMap[v.id]!.label,
          );
          displayVal = selectedLabels.join(', ');
        }
        break;
      }
      case FormFieldType.SINGLE_SELECT: {
        if (!value) {
          // value is empty
          displayVal = value;
        } else if (value.value) {
          // "value" will be present for "Other" entries
          displayVal = value.value;
        } else {
          const option = fieldDefinition.options!.find(
            (opt) => opt!.handle === value.id,
          );
          displayVal = option!.label;
        }
        break;
      }
      default: {
        displayVal = value;
      }
    }
    return displayVal;
  }, [fieldDefinition, value]);

  return (
    <>
      <Dl.Term
        className="text-body text-md break-words"
        css={css`
          &:not(:first-child) {
            margin-top: 1.5rem !important;
          }
        `}
      >
        {fieldDefinition.label}
      </Dl.Term>
      {displayValue ? (
        <Dl.Detail className="text-white pb-3 whitespace-pre-wrap break-words mt-1">
          {displayValue}
        </Dl.Detail>
      ) : (
        <div className="text-body">
          <FormattedMessage id="worksheets.emptyValue" defaultMessage="N/A" />
        </div>
      )}
    </>
  );
}

export default createFragmentContainer(TemplatedFieldListItem, {
  fieldDefinition: graphql`
    fragment TemplatedFieldListItem_fieldDefinition on FormFieldDefinitionInterface {
      typename: __typename
      label
      handle
      ... on MultiSelectFieldDefinition {
        options {
          handle
          label
        }
      }
      ... on SingleSelectFieldDefinition {
        options {
          handle
          label
        }
      }
    }
  `,
});
