/**
 * @generated SignedSource<<ac668f2feac058316b06e28fb1c4aa86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canDeleteStudy$data = {
  readonly finalizedAt: string | null;
  readonly viewerCanDelete: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "StudyPermissions_canDeleteStudy";
};
export type StudyPermissions_canDeleteStudy$key = {
  readonly " $data"?: StudyPermissions_canDeleteStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canDeleteStudy">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canDeleteStudy"
};

(node as any).hash = "5146721d73f4c37ea5375ddd9ccb25bf";

export default node;
