import React from 'react';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import PageTitle from 'components/PageTitle';

import { ArchivePageTitle_archive$data as Archive } from './__generated__/ArchivePageTitle_archive.graphql';

const messages = defineMessages({
  labelMasked: {
    id: 'archive.label.masked',
    defaultMessage: 'Archive',
  },
});

interface Props {
  archive: Archive;
  title: MessageDescriptor | null;
}

function ArchivePageTitle({ archive, title }: Props) {
  const showArchiveInDocumentTitle = useVariation(
    'show-archive-in-document-title',
  );

  const { formatMessage } = useIntl();

  const archiveLabelSafe = showArchiveInDocumentTitle
    ? archive.label!
    : formatMessage(messages.labelMasked);

  let formattedTitle: string;
  let formattedDisplayTitle: string;

  if (title) {
    formattedTitle = formatMessage(title, {
      archive: archiveLabelSafe,
    });
    formattedDisplayTitle = formatMessage(title, {
      archive: archive.label,
    });
  } else {
    formattedTitle = archiveLabelSafe;
    formattedDisplayTitle = archive.label!;
  }

  return (
    <PageTitle
      title={formattedTitle}
      displayTitle={formattedDisplayTitle}
      variant="xl"
    />
  );
}

export default createFragmentContainer(ArchivePageTitle, {
  archive: graphql`
    fragment ArchivePageTitle_archive on Archive {
      label
    }
  `,
});
