import PublicArchiveIcon from '@bfly/icons/Folder';
import PrivateArchiveIcon from '@bfly/icons/FolderPrivate';
import Link from '@bfly/ui2/Link';
import SideMenu from '@bfly/ui2/SideMenu';
import clsx from 'clsx';
import { createFragmentContainer, graphql } from 'react-relay';

import EhrForwardingIcon from 'components/EhrForwardingIcon';
import { useVariation } from 'components/LaunchDarklyContext';
import PacsForwardingIcon from 'components/PacsForwardingIcon';
import useMoveStudies from 'hooks/useMoveStudies';
import { useArchiveRoutes } from 'routes/archive';

import StudiesDropTarget from './StudiesDropTarget';
import type { ArchiveListItem_archive$data as Archive } from './__generated__/ArchiveListItem_archive.graphql';

interface Props {
  className?: string;
  activeClassName?: string;
  archive: Archive;
}

function ArchiveListItem({ className, activeClassName, archive }: Props) {
  const {
    organization,
    id,
    handle,
    label,
    pacsAssociations,
    ehrAssociations,
  } = archive;

  const archiveRoutes = useArchiveRoutes();
  const canUseDicomConnector = useVariation('dicom-connector');
  const canUseHl7Connector = useVariation('hl7-connector');

  const [moveStudies] = useMoveStudies();

  const onStudiesDropped = async (
    studyAndArchiveIds: { studyId: string; archiveId: string }[],
  ) => {
    await moveStudies(
      studyAndArchiveIds,
      id,
      organization!.id,
      organization!.id,
    );
  };

  const Icon = archive.isPrivate ? PrivateArchiveIcon : PublicArchiveIcon;

  const hasDicom =
    canUseDicomConnector && pacsAssociations && !!pacsAssociations.length;
  const hasHl7 =
    canUseHl7Connector && ehrAssociations && !!ehrAssociations.length;

  const hasConnector = hasDicom || hasHl7;

  const ehrForwardingIcon = <EhrForwardingIcon className="flex ml-1" />;

  const pacsForwardingIcon = <PacsForwardingIcon className="flex ml-1" />;

  return (
    <Link
      to={archiveRoutes.archive({
        organizationSlug: organization!.slug!,
        archiveHandle: handle!,
      })}
    >
      {(linkProps) => (
        <StudiesDropTarget
          onStudiesDropped={onStudiesDropped}
          dataKey="study-items-can-move"
        >
          <SideMenu.Link
            {...linkProps}
            badge={
              hasConnector && (
                <div className="flex mr-2">
                  {hasDicom && pacsForwardingIcon}
                  {hasHl7 && ehrForwardingIcon}
                </div>
              )
            }
            data-bni-id="SideMenuItem"
            icon={<Icon />}
            className={clsx(
              className,
              linkProps.active && activeClassName,
              hasConnector && 'pr-0',
            )}
          >
            {label}
          </SideMenu.Link>
        </StudiesDropTarget>
      )}
    </Link>
  );
}

export default createFragmentContainer(ArchiveListItem, {
  archive: graphql`
    fragment ArchiveListItem_archive on Archive {
      id
      isPrivate
      organization {
        id
        slug
      }
      handle
      label
      pacsAssociations {
        id
      }
      ehrAssociations {
        id
      }
    }
  `,
});
