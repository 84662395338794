import SearchIcon from '@bfly/icons/Search';
import Multiselect from '@bfly/ui2/Multiselect';
import useMounted from '@restart/hooks/useMounted';
import debounce from 'lodash/debounce';
import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { SearchHandler } from 'react-widgets/shared';

import { DEBOUNCE_TIMEOUT } from './utils/videoListHelpers';

const messages = defineMessages({
  search: {
    id: 'educationVideo.search',
    defaultMessage: 'Search',
  },
});

/**
 * We're using <MultiSelect /> here solely for the prepend
 * icon with form styling. To use multiselect without
 * the dropdown list we just create a component that returns
 * null. Note that it MUST be a class component for
 * <MultiSelect /> to run.
 * Also note that React-Widgets expects a class component
 */
// eslint-disable-next-line react/prefer-stateless-function
class NoopList extends React.Component {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, react/no-unused-class-component-methods
  scrollIntoView() {}

  render() {
    return null;
  }
}

interface Props {
  onChange: (val: string) => void;
  className?: string;
  placeholder?: string;
  searchTerm?: string;
  setSearchTerm: (val: string) => void;
}

function SearchField({
  onChange,
  className,
  placeholder,
  searchTerm,
  setSearchTerm,
  ...props
}: Props) {
  const { formatMessage } = useIntl();
  const isMounted = useMounted();

  const debouncedSearch = useMemo(
    () => debounce(onChange, DEBOUNCE_TIMEOUT),
    [onChange],
  );

  const handleSearchChange: SearchHandler = (term, metadata) => {
    if (metadata.action === 'clear' || !isMounted()) return;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  return (
    <Multiselect
      variant="secondary"
      prependIcon={<SearchIcon />}
      searchTerm={searchTerm}
      onSearch={handleSearchChange}
      listComponent={NoopList as any}
      placeholder={placeholder ?? formatMessage(messages.search)}
      className={className}
      {...props}
    />
  );
}

export default SearchField;
