/**
 * @generated SignedSource<<f040723f847dec39c003159e99318f9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditHumanExamInfoFieldSet_study$data = {
  readonly hasGeneratedAccessionNumber: boolean | null;
  readonly id: string;
  readonly patient: {
    readonly nameMiddle: string | null;
    readonly namePrefix: string | null;
    readonly nameSuffix: string | null;
  } | null;
  readonly " $fragmentType": "EditHumanExamInfoFieldSet_study";
};
export type EditHumanExamInfoFieldSet_study$key = {
  readonly " $data"?: EditHumanExamInfoFieldSet_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditHumanExamInfoFieldSet_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditHumanExamInfoFieldSet_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasGeneratedAccessionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "namePrefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameSuffix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameMiddle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "0b2ef29821865c40ee87010e21e847ea";

export default node;
