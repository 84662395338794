import FeatureFlags from 'config/FeatureFlags';

import { useAuth } from 'components/AuthContext';
import {
  useVariation,
  useVariationAllowMissingLdContext,
} from 'components/LaunchDarklyContext';

export default function useAdvancedAcademyFeatures(): FeatureFlags['advanced-academy-features'] {
  const butterflyAcademyAccess = useVariation('advanced-academy-features');
  const { isAuthenticated } = useAuth();
  return isAuthenticated() && butterflyAcademyAccess;
}

export function useAdvancedAcademyFeaturesWithoutLdContext(): FeatureFlags['advanced-academy-features'] {
  const butterflyAcademyAccess = useVariationAllowMissingLdContext(
    'advanced-academy-features',
  );
  const { isAuthenticated } = useAuth();
  return isAuthenticated() && butterflyAcademyAccess;
}
