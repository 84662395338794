/**
 * @generated SignedSource<<3c9aca39b78148d6c828a61a12fbaed5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamReviewedBy_userProfile$data = {
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  readonly " $fragmentType": "ExamReviewedBy_userProfile";
};
export type ExamReviewedBy_userProfile$key = {
  readonly " $data"?: ExamReviewedBy_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamReviewedBy_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamReviewedBy_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_userProfile"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "e963d66c7801d7d9cff003d38b56762d";

export default node;
