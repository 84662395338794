/**
 * @generated SignedSource<<d63e9b260132ea3f8104296d26bb4430>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainRoles_DuplicateDomainRolePage_Query$variables = {
  membershipRoleId: string;
};
export type domainRoles_DuplicateDomainRolePage_Query$data = {
  readonly membershipRole: {
    readonly " $fragmentSpreads": FragmentRefs<"DuplicateDomainRolePage_membershipRole">;
  } | null;
};
export type domainRoles_DuplicateDomainRolePage_Query = {
  response: domainRoles_DuplicateDomainRolePage_Query$data;
  variables: domainRoles_DuplicateDomainRolePage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "membershipRoleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "membershipRoleId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domainRoles_DuplicateDomainRolePage_Query",
    "selections": [
      {
        "alias": "membershipRole",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DuplicateDomainRolePage_membershipRole"
              }
            ],
            "type": "MembershipRole",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domainRoles_DuplicateDomainRolePage_Query",
    "selections": [
      {
        "alias": "membershipRole",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permissions",
                "storageKey": null
              }
            ],
            "type": "MembershipRole",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0490ab71c1b68846ac1dc00b39e9b2a7",
    "id": null,
    "metadata": {},
    "name": "domainRoles_DuplicateDomainRolePage_Query",
    "operationKind": "query",
    "text": "query domainRoles_DuplicateDomainRolePage_Query(\n  $membershipRoleId: ID!\n) {\n  membershipRole: node(id: $membershipRoleId) {\n    __typename\n    ... on MembershipRole {\n      ...DuplicateDomainRolePage_membershipRole\n    }\n    id\n  }\n}\n\nfragment DuplicateDomainRolePage_membershipRole on MembershipRole {\n  ...roles_defaultValue\n}\n\nfragment roles_defaultValue on MembershipRole {\n  id\n  name\n  description\n  permissions\n}\n"
  }
};
})();

(node as any).hash = "0da2c7688f9136477e0d8d9ba11384d7";

export default node;
