/**
 * @generated SignedSource<<1f1a1d637daf64dbb33f3b60eaf2ef66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamPageSidebarWorksheets$data = {
  readonly handle: string | null;
  readonly id: string;
  readonly templateVersion: {
    readonly title: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetAutosaveForm_worksheet" | "WorksheetDisplay_worksheet">;
  readonly " $fragmentType": "ExamPageSidebarWorksheets";
};
export type ExamPageSidebarWorksheets$key = {
  readonly " $data"?: ExamPageSidebarWorksheets$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageSidebarWorksheets">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPageSidebarWorksheets",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorksheetTemplateVersion",
      "kind": "LinkedField",
      "name": "templateVersion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorksheetAutosaveForm_worksheet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorksheetDisplay_worksheet"
    }
  ],
  "type": "Worksheet",
  "abstractKey": null
};

(node as any).hash = "871e5384c30f2c0a7d33c649eafc7e11";

export default node;
