import Layout from '@4c/layout';
import Form, { FormProps } from '@bfly/ui2/Form';
import { FieldType } from '@bfly/ui2/FormField';
import { FormFieldGroupProps } from '@bfly/ui2/FormFieldGroup';
import FormattedLabel, { LabelValue } from '@bfly/ui2/FormattedLabel';
import Text from '@bfly/ui2/Text';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import React from 'react';
import type { AnyObjectSchema } from 'yup';

import withProps from '../utils/withProps';
import MessageBox from './MessageBox';
import type { RelayFormProps, RelayOperationWithFormError } from './RelayForm';
import RelayForm from './RelayForm';

const styles = stylesheet`
  .label {
    @apply text-white block;
    margin: theme('margin.6') theme('margin.3') theme('margin.2') 2rem;
  }
`;

function AuthForm<
  T extends RelayOperationWithFormError | AnyObjectSchema,
  U extends AnyObjectSchema = never,
  TSchema extends AnyObjectSchema = T extends AnyObjectSchema ? T : U,
>({
  children,
  ...props
}: T extends RelayOperationWithFormError
  ? RelayFormProps<T, TSchema>
  : FormProps<TSchema>) {
  let Component: React.ElementType;
  let extraProps;

  if ('mutation' in props) {
    Component = RelayForm;
    extraProps = { addServerErrorsToFormErrors: true };
  } else {
    Component = Form;
    extraProps = {};
  }

  return (
    <Component {...props} {...extraProps} className="mb-3">
      {/* @ts-ignore */}
      <Form.Message>
        {(errors) => (
          <MessageBox variant="danger">{errors && errors[0]}</MessageBox>
        )}
      </Form.Message>
      {children}
    </Component>
  );
}

interface AuthFormFieldGroupProps extends FormFieldGroupProps {
  help?: LabelValue;
}

const AuthFormFieldGroup: FieldType<AuthFormFieldGroupProps> = ({
  label,
  labelClassName,
  labelSrOnly,
  help,
  ...props
}: AuthFormFieldGroupProps) => {
  return (
    <Form.FieldGroup
      {...props}
      hideError
      variant="light"
      labelSrOnly={help ? false : labelSrOnly}
      className={clsx(props.className, 'text-left mb-0')}
      labelClassName={clsx(labelClassName, styles.label)}
      label={
        help ? (
          <Layout>
            <Layout.Block grow className={labelSrOnly ? 'sr-only' : undefined}>
              <FormattedLabel label={label} />
            </Layout.Block>
            <Layout.Block>
              <Text color="body" variant="sm">
                <FormattedLabel label={help} />
              </Text>
            </Layout.Block>
          </Layout>
        ) : (
          label
        )
      }
    />
  );
};

const AuthFormSubmit = withProps(Form.Submit, {
  size: 'lg',
  className: 'mt-4 w-full',
});

AuthForm.FieldGroup = AuthFormFieldGroup;
AuthForm.Submit = AuthFormSubmit;

export default AuthForm;
