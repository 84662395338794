/**
 * @generated SignedSource<<89690c3be2b785be64460e2798697d84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DomainMembersGrid_viewer$data = {
  readonly domain: {
    readonly name: string | null;
    readonly numAvailableSeats: number | null;
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly canFinalize: boolean | null;
          readonly canQa: boolean | null;
          readonly dateLastActive: string | null;
          readonly dicomFields: ReadonlyArray<{
            readonly dicomFieldTemplate: {
              readonly id: string;
              readonly label: string | null;
            } | null;
            readonly value: string | null;
          }>;
          readonly email: string | null;
          readonly id: string;
          readonly integrationConfigs: ReadonlyArray<{
            readonly ehr: {
              readonly handle: string | null;
              readonly id: string;
            } | null;
            readonly interfaceCode: string | null;
          }>;
          readonly integrationDisplayNameFirst: string | null;
          readonly integrationDisplayNameLast: string | null;
          readonly integrationDisplayNameMiddle: string | null;
          readonly organizations: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
          } | null> | null;
          readonly profile: {
            readonly id: string;
          } | null;
          readonly role: {
            readonly id: string;
            readonly name: string | null;
          } | null;
          readonly type: DomainUserType | null;
          readonly updatedAt: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "DomainMembersGrid_viewer";
};
export type DomainMembersGrid_viewer$key = {
  readonly " $data"?: DomainMembersGrid_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMembersGrid_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 500,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDeleted"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "domain",
          "users"
        ]
      }
    ]
  },
  "name": "DomainMembersGrid_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numAvailableSeats",
          "storageKey": null
        },
        {
          "alias": "users",
          "args": [
            {
              "kind": "Variable",
              "name": "isDeleted",
              "variableName": "isDeleted"
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            },
            {
              "kind": "Literal",
              "name": "sort",
              "value": "NAME_ASC"
            }
          ],
          "concreteType": "DomainUserConnection",
          "kind": "LinkedField",
          "name": "__domain_grid_users_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DomainUserEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DomainUser",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "integrationDisplayNameFirst",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "integrationDisplayNameMiddle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "integrationDisplayNameLast",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "canQa",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "canFinalize",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DomainUserDicomField",
                      "kind": "LinkedField",
                      "name": "dicomFields",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DomainDicomFieldTemplate",
                          "kind": "LinkedField",
                          "name": "dicomFieldTemplate",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "label",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "value",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DomainUserIntegrationConfig",
                      "kind": "LinkedField",
                      "name": "integrationConfigs",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Ehr",
                          "kind": "LinkedField",
                          "name": "ehr",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "handle",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "interfaceCode",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MembershipRole",
                      "kind": "LinkedField",
                      "name": "role",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Organization",
                      "kind": "LinkedField",
                      "name": "organizations",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserProfile",
                      "kind": "LinkedField",
                      "name": "profile",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "updatedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dateLastActive",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "c8c6960ffccfa83b75a42f7e226e8edd";

export default node;
