import Layout from '@4c/layout';
import type { FlexProps } from '@4c/layout';
import CloseIcon from '@bfly/icons/CloseSheet';
import Heading from '@bfly/ui2/Heading';
import React from 'react';
import type { MouseEvent } from 'react';
import { createPortal } from 'react-dom';

function MobileOverlay(props: { children: React.ReactNode } & FlexProps) {
  if (typeof window === 'undefined') return null;
  return createPortal(
    <Layout
      direction="column"
      {...props}
      className="fixed z-modal w-full h-full top-0 left-0 bg-black"
    />,

    document.body,
  );
}

MobileOverlay.Header = function MobileOverlayHeader({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Layout
      direction="column"
      align="center"
      justify="center"
      className="h-navbar border-b border-grey-85 bg-grey-85"
    >
      <Heading flush>{children}</Heading>
    </Layout>
  );
};

MobileOverlay.HeaderWithClose = function MobileOverlayHeaderWithClose({
  onClose,
  children,
}: {
  onClose?: (e: MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
}) {
  return (
    <MobileOverlay.Header>
      <button
        type="button"
        onClick={onClose}
        className="absolute top-0 left-0 p-5 text-white cursor-pointer active:opacity-50"
      >
        <CloseIcon />
      </button>
      {children}
    </MobileOverlay.Header>
  );
};

export default MobileOverlay;
