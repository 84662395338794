import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import { graphql } from 'relay-runtime';

import { useStudyImagesQuery } from './__generated__/useStudyImagesQuery.graphql';

export type EduStudyImage = ConnectionNodeType<
  useStudyImagesQuery['response']['nodes'][number],
  'imageConnection'
>;

export default function useStudyImages(
  studyIds: string[] | null | undefined,
  skip = false,
): Record<string, EduStudyImage[]> {
  const { data } = useQuery<useStudyImagesQuery>(
    graphql`
      query useStudyImagesQuery($ids: [ID!]!) {
        nodes(ids: $ids) {
          id
          ... on EduStudy {
            studyId: id
            imageConnection(
              first: 6 # Match MAX_PREVIEWS in <StudyImagePreviewList>.
            ) {
              edges {
                node {
                  ...EduStudyCaptures_studyImages
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-or-network',
      variables: { ids: studyIds || [] },
      skip: skip || !studyIds?.length,
    },
  );

  return (data?.nodes || []).reduce((dictionary, node) => {
    if (node)
      dictionary[node!.studyId!] =
        (getNodes(node.imageConnection) as EduStudyImage[]) || [];
    return dictionary;
  }, {});
}
