/**
 * @generated SignedSource<<abe6eb9ff3b39f01f0ab1aeec7c03e74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThirdPartyUltrasoundListenerConfiguration_archive$data = {
  readonly id: string;
  readonly organization: {
    readonly hospitalConnections: ReadonlyArray<{
      readonly id?: string;
      readonly isAmbraEnabled?: boolean | null;
      readonly name: string | null;
      readonly type: string;
    } | null> | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CreateButterflyLinkControl_connectivityConfig">;
  } | null;
  readonly thirdPartyUltrasoundListener: {
    readonly autoAssignDicomFieldTemplate: {
      readonly id: string;
    } | null;
    readonly autoAssignSecondaryDicomFieldTemplate: {
      readonly id: string;
    } | null;
    readonly clientPort: number | null;
    readonly connection: {
      readonly handle: string | null;
      readonly id?: string;
    } | null;
    readonly isAmbraEnabled: boolean | null;
    readonly scuAet: string | null;
    readonly timezone: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ThirdPartyUltrasoundListenerConfigurationContent_archive" | "ThirdPartyUltrasoundLogModal_archive">;
  readonly " $fragmentType": "ThirdPartyUltrasoundListenerConfiguration_archive";
};
export type ThirdPartyUltrasoundListenerConfiguration_archive$key = {
  readonly " $data"?: ThirdPartyUltrasoundListenerConfiguration_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThirdPartyUltrasoundListenerConfiguration_archive">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAmbraEnabled",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThirdPartyUltrasoundListenerConfiguration_archive",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ThirdPartyUltrasoundListenerConfigurationContent_archive"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ThirdPartyUltrasoundListener",
      "kind": "LinkedField",
      "name": "thirdPartyUltrasoundListener",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientPort",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scuAet",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timezone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainDicomFieldTemplate",
          "kind": "LinkedField",
          "name": "autoAssignDicomFieldTemplate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainDicomFieldTemplate",
          "kind": "LinkedField",
          "name": "autoAssignSecondaryDicomFieldTemplate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "handle",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "hospitalConnections",
          "plural": true,
          "selections": [
            {
              "alias": "type",
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v1/*: any*/)
              ],
              "type": "ButterflyLinkConnection",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateButterflyLinkControl_connectivityConfig"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ThirdPartyUltrasoundLogModal_archive"
    }
  ],
  "type": "Archive",
  "abstractKey": null
};
})();

(node as any).hash = "52fa22c75d288b78200008d2ff475ee5";

export default node;
