import { useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import localStore from 'store/dist/store.modern';

import { routes as examRoutes } from 'routes/exam';

import { StudyListFilter } from './StudyConstants';

export const SELECTION_STATE_KEY = 'sidenav-study-selection-state';
export const SELECTION_AUTHOR_FILTER_KEY = 'sidenav-study-createdBy';

export enum StudiesSelectionDropdownProps {
  ALL = 'ALL',
  CUSTOM = 'CUSTOM',
}

export const selectionMessages = {
  [StudiesSelectionDropdownProps.ALL]: {
    id: 'sidepanelStudies.dropdown.allstudies',
    defaultMessage: 'All studies',
  },
  [StudiesSelectionDropdownProps.CUSTOM]: {
    id: 'sidepanelStudies.dropdown.custom',
    defaultMessage: 'Studies by {num} authors',
  },
};

export interface StudiesQuery {
  createdBy?: Array<string>;
  primaryAuthor?: Array<string>;
  status?: StudyListFilter | string;
}
export interface TodoQuery {
  canSign?: boolean;
}

export function getStudiesQuery(selection, handles: string[]): StudiesQuery {
  return {
    primaryAuthor:
      selection.id ===
      selectionMessages[StudiesSelectionDropdownProps.CUSTOM].id
        ? handles
        : undefined,
  };
}

const getInitialSelection = () => {
  const existingSelection = localStore.get(SELECTION_STATE_KEY);
  if (existingSelection) return existingSelection;
  return selectionMessages[StudiesSelectionDropdownProps.ALL];
};

export const useStudiesSelection = () => {
  const [selection, setSelection] = useState<MessageDescriptor>(
    getInitialSelection() || selectionMessages.ALL,
  );
  const selectById = (id: StudiesSelectionDropdownProps) => {
    const message = selectionMessages[id] || selectionMessages.ALL;
    localStore.set(SELECTION_STATE_KEY, message);
    setSelection(message);
  };

  return [selection, selectById] as const;
};

/* 
  Since query value can be either an array or a string and typescript will always think query is a string,
  this makes it so that we always pass an array of strings and avoid errors
*/
export const toArray = (value: string | string[]): string[] | undefined => {
  if (!value) return undefined;
  const result: string[] = [];
  return result.concat(value);
};

export const createRedirectLocation = (
  filter: { canSign?: boolean },
  organizationSlug: string,
  status: StudyListFilter,
) => ({
  pathname: `${examRoutes.examLists({
    organizationSlug,
    status: status.toLowerCase(),
  })}`,
  query: {
    ...filter,
  },
});
