/**
 * @generated SignedSource<<1a6c43a0542e122641a097297a10f2a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type mdm_SettingsPageQuery$variables = {};
export type mdm_SettingsPageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_tenant">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"EmmSettingsPage_viewer">;
  } | null;
};
export type mdm_SettingsPageQuery = {
  response: mdm_SettingsPageQuery$data;
  variables: mdm_SettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdomainLabel",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "mdm_SettingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EmmSettingsPage_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "RouteAccessControl_tenant",
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "mdm_SettingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainSettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shouldAuthorizeHostDevices",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hostDeviceSecret",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b62139afdad04bd1733b26ce8fd03125",
    "id": null,
    "metadata": {},
    "name": "mdm_SettingsPageQuery",
    "operationKind": "query",
    "text": "query mdm_SettingsPageQuery {\n  viewer {\n    ...EmmSettingsPage_viewer\n    id\n  }\n  tenant {\n    __typename\n    ...RouteAccessControl_tenant\n    id\n  }\n}\n\nfragment EmmSettingsPage_viewer on Viewer {\n  domain {\n    id\n    settings {\n      shouldAuthorizeHostDevices\n      hostDeviceSecret\n    }\n  }\n}\n\nfragment RouteAccessControl_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    subdomainLabel\n  }\n  ... on Organization {\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "321e4ef7f38541f665b6f5917930d788";

export default node;
