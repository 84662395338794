/**
 * @generated SignedSource<<e0aaccae194a202456b0d152ecfb2ae4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FinalizationCandidatesSearchControl_candidates$data = ReadonlyArray<{
  readonly email: string | null;
  readonly userProfile: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"UserSuggestionListItem_userInfo">;
  readonly " $fragmentType": "FinalizationCandidatesSearchControl_candidates";
}>;
export type FinalizationCandidatesSearchControl_candidates$key = ReadonlyArray<{
  readonly " $data"?: FinalizationCandidatesSearchControl_candidates$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinalizationCandidatesSearchControl_candidates">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "FinalizationCandidatesSearchControl_candidates",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "userProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "UserSuggestionListItem_userInfo",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "userProfile",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserSuggestion_userProfile"
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "UserInfoInterface",
          "abstractKey": "__isUserInfoInterface"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Mentionable",
  "abstractKey": null
};
})();

(node as any).hash = "e2467e672d0f37a2d5e559842bfe19a8";

export default node;
