import { createFragmentContainer, graphql } from 'react-relay';

import { StudyImage_image$data as Image } from 'components/__generated__/StudyImage_image.graphql';

import StudyImageVideo from './StudyImageVideo';

interface Props {
  image: Image;
  index: number;
  autoPlay?: boolean;
  fullscreenEnabled?: boolean;
  hideVideoControls?: boolean;
}

function StudyImage({
  image,
  index,
  hideVideoControls,
  autoPlay = true,
  fullscreenEnabled = true,
}: Props) {
  const { isVideo, dataUrl, thumbnails } = image.burnedInFile!;

  return isVideo ? (
    <StudyImageVideo
      src={dataUrl!}
      poster={thumbnails![thumbnails!.length - 1]!.url!}
      autoPlay={autoPlay}
      fullscreenEnabled={fullscreenEnabled}
      hideVideoControls={hideVideoControls}
    />
  ) : (
    <img
      data-bni-id="StudyImage"
      src={dataUrl!}
      alt={`Still ${index + 1}`}
      className="block w-full h-full object-contain"
    />
  );
}

export default createFragmentContainer(StudyImage, {
  image: graphql`
    fragment StudyImage_image on StudyImageInterface {
      burnedInFile {
        isVideo
        dataUrl
        thumbnails {
          url
        }
      }
    }
  `,
});
