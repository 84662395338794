import BlankSlate from '@bfly/ui2/BlankSlate';
import Button from '@bfly/ui2/Button';
import Dropdown from '@bfly/ui2/Dropdown';
import Form from '@bfly/ui2/Form';
import FormCheck from '@bfly/ui2/FormCheck';
import ItemGrid from '@bfly/ui2/ItemGrid';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import MeatballDropdownButton from '@bfly/ui2/MeatballDropdownButton';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import notNullish from '@bfly/utils/notNullish';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import * as yup from 'yup';

import SearchTags from 'components/SearchTags';
import actionMessages from 'messages/actions';
import {
  SearchData,
  mapStoredSearchToSearchData,
  messages,
} from 'utils/Search';
import withModal from 'utils/withModal';

import UpdateSavedSearchModal from './UpdateSavedSearchModal';
import { SavedSearchesModal_Delete_Mutation as DeleteMutation } from './__generated__/SavedSearchesModal_Delete_Mutation.graphql';
import { SavedSearchesModal_Query } from './__generated__/SavedSearchesModal_Query.graphql';

const schema = yup.object({
  selectedSearchId: yup.string().required(),
});

interface Props {
  onHide: () => void;
  onApply: (query: SearchData) => void;
  organizationSlug: string | null;
  tenantId: any;
}

const mutation = graphql`
  mutation SavedSearchesModal_Delete_Mutation(
    $input: DeleteStudySavedSearchInput!
  ) {
    delete: deleteStudySavedSearchOrError(input: $input) {
      ... on DeleteStudySavedSearchPayload {
        deletedId
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

function SavedSearchesModal({
  onHide,
  onApply,
  organizationSlug,
  tenantId,
}: Props) {
  const [editSearch, setEditSearch] = useState<any>(null);
  const [mutate, deleteLoading] =
    useMutationWithError<DeleteMutation>(mutation);

  const { data } = useQuery<SavedSearchesModal_Query>(
    graphql`
      query SavedSearchesModal_Query($slug: String) {
        tenant(slug: $slug) {
          viewerSavedSearchConnection(first: 2147483647)
            @connection(key: "Organization_viewerSavedSearchConnection") {
            edges {
              node {
                id
                name
                createdAt
                criteria {
                  ...SearchTags_studySearchCriteria
                }
                ...Search_searchToData
                ...UpdateSavedSearchModal_studySavedSearch
              }
            }
          }
        }
      }
    `,
    {
      variables: { slug: organizationSlug },
      fetchPolicy: 'store-and-network',
    },
  );

  const searches = data
    ? getNodes(data?.tenant?.viewerSavedSearchConnection)
    : [];

  const isLoading = !data;
  const hasSearches = !!searches.length;

  const handleFormSubmit = ({ selectedSearchId }) => {
    if (!selectedSearchId) return;

    const searchData = mapStoredSearchToSearchData(
      searches.find((s) => s.id === selectedSearchId)!,
    );
    onApply(searchData);
    onHide();
  };

  const handleDelete = (id: string) => {
    mutate({
      input: { studySavedSearchId: id },
      updater: (store) => {
        rangeDeleteUpdater(store, {
          parentId: tenantId,
          connectionKey: 'Organization_viewerSavedSearchConnection',
          deletedId: id,
        });
      },
    });
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...messages.savedSearches} />
        </Modal.Title>
      </Modal.Header>
      {isLoading && (
        <Modal.Body>
          <LoadingIndicator />
        </Modal.Body>
      )}
      {!isLoading && !hasSearches && (
        <Modal.Body>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                id="globalStudySearchSavedSearchesModal.noSearches"
                defaultMessage="No saved searches available"
              />
            </BlankSlate.Title>
          </BlankSlate>
        </Modal.Body>
      )}
      {!isLoading && hasSearches && (
        <Form schema={schema} onSubmit={handleFormSubmit}>
          <Modal.Body className="max-h-[70vh]">
            <Form.Field name="selectedSearchId">
              {({ value: selected, onChange: setSelected }) => (
                <ItemGrid templateColumns="35px minMax(150px, auto) auto auto 50px">
                  <thead>
                    <ItemGrid.Row>
                      <ItemGrid.Header />
                      <ItemGrid.Header className="justify-start">
                        <FormattedMessage {...messages.searchName} />
                      </ItemGrid.Header>
                      <ItemGrid.Header className="justify-start">
                        <FormattedMessage {...messages.details} />
                      </ItemGrid.Header>
                      <ItemGrid.Header className="justify-start">
                        <FormattedMessage {...messages.date} />
                      </ItemGrid.Header>
                      <ItemGrid.Header />
                    </ItemGrid.Row>
                  </thead>
                  <tbody>
                    {searches.map((item) => {
                      const { id, name, createdAt, criteria } = item;
                      return (
                        <ItemGrid.Row key={id} aria-selected={selected === id}>
                          <ItemGrid.Cell
                            direction="row"
                            onClick={() => setSelected(id)}
                            className="pl-3"
                          >
                            <FormCheck
                              onChange={() => setSelected(id)}
                              type="radio"
                              name="savedSearch"
                              checked={selected === id}
                            />
                          </ItemGrid.Cell>
                          <ItemGrid.Cell
                            onClick={() => setSelected(id)}
                            className="justify-start"
                          >
                            {name}
                          </ItemGrid.Cell>
                          <ItemGrid.Cell
                            onClick={() => setSelected(id)}
                            className="justify-start"
                          >
                            <SearchTags
                              variant="light"
                              nodes={null}
                              studySearchCriteria={criteria!.filter(
                                notNullish,
                              )}
                              wrap
                            />
                          </ItemGrid.Cell>
                          <ItemGrid.Cell
                            onClick={() => setSelected(id)}
                            className="justify-start"
                          >
                            <FormattedDate value={createdAt!} />
                          </ItemGrid.Cell>
                          <ItemGrid.ActionsCell
                            direction="column"
                            className="pr-3"
                          >
                            <MeatballDropdownButton
                              disabled={deleteLoading}
                              id={id}
                              data-bni-id={id}
                              popperConfig={{ strategy: 'fixed' }}
                            >
                              <Dropdown.Item
                                onClick={() => setEditSearch(item)}
                              >
                                <FormattedMessage
                                  id="globalStudySearchSavedSearchesModal.rename"
                                  defaultMessage="Rename"
                                />
                              </Dropdown.Item>
                              <Dropdown.Item
                                variant="danger"
                                onClick={() => handleDelete(id)}
                              >
                                <FormattedMessage {...actionMessages.delete} />
                              </Dropdown.Item>
                            </MeatballDropdownButton>
                          </ItemGrid.ActionsCell>
                        </ItemGrid.Row>
                      );
                    })}
                  </tbody>
                </ItemGrid>
              )}
            </Form.Field>
          </Modal.Body>
          <Modal.Footer>
            <Modal.ButtonGroup>
              <Form.Submit>
                <FormattedMessage {...messages.apply} />
              </Form.Submit>
              <Form.Message for="selected">
                {() => (
                  <Text
                    variant="sm"
                    color="danger"
                    className="mt-2 mr-2 ml-auto"
                  >
                    <FormattedMessage
                      id="globalStudySearchSavedSearchesModal.select"
                      defaultMessage="Select a saved search"
                    />
                  </Text>
                )}
              </Form.Message>
              <Button variant="secondary" onClick={onHide}>
                <FormattedMessage {...actionMessages.cancel} />
              </Button>
            </Modal.ButtonGroup>
          </Modal.Footer>
        </Form>
      )}
      {data && (
        <UpdateSavedSearchModal
          show={!!editSearch}
          studySavedSearch={editSearch}
          organizationSlug={organizationSlug}
          onHide={() => setEditSearch(null)}
        />
      )}
    </>
  );
}

export default withModal(SavedSearchesModal);
