import { graphql, readInlineData } from 'relay-runtime';
import { InferType, array, object, string } from 'yup';

import {
  workflowDigests_schedule$key as WorkflowDigestsKey,
  DayOfWeekType as _DayOfWeekType,
} from './__generated__/workflowDigests_schedule.graphql';

export type DayOfWeekType = ExcludeFutureAddedValue<_DayOfWeekType>;

export const deserializeDaysOfWeekOptions: Record<DayOfWeekType, string> = {
  SUNDAY: 'Sun',
  MONDAY: 'Mon',
  TUESDAY: 'Tues',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
};

const serializeDaysOfWeekOptions: Record<string, DayOfWeekType> = {
  Sun: 'SUNDAY',
  Mon: 'MONDAY',
  Tues: 'TUESDAY',
  Wed: 'WEDNESDAY',
  Thu: 'THURSDAY',
  Fri: 'FRIDAY',
  Sat: 'SATURDAY',
};

const isDayOfWeekType = (day: any): day is DayOfWeekType => {
  return Object.keys(deserializeDaysOfWeekOptions).includes(day);
};

export const schema = object({
  time: string().required(),
  timezone: string().required(),
  daysOfWeek: array(
    string()
      .enum(Object.keys(deserializeDaysOfWeekOptions) as DayOfWeekType[])
      .required()
      .transform((day: DayOfWeekType | string) => {
        // if DayOfWeekType, transform to day abbreviation string
        // otherwise, vice versa
        return isDayOfWeekType(day)
          ? deserializeDaysOfWeekOptions[day]
          : serializeDaysOfWeekOptions[day];
      }),
  )
    .required()
    .min(1),
});

export type WorkflowDigestsForm = InferType<typeof schema>;

export const deserialize = (scheduleRef: WorkflowDigestsKey | null) => {
  const schedule = readInlineData(
    graphql`
      fragment workflowDigests_schedule on WorkflowDigestSchedule @inline {
        time
        timezone
        daysOfWeek
      }
    `,
    scheduleRef,
  );

  const defaultDigest = {
    daysOfWeek: ['Mon'],
    time: '09:00:00',
    timezone: 'America/New_York',
  };

  return schedule
    ? schema.cast({
        ...schedule,
      })
    : defaultDigest;
};

export const serialize = (schedule: WorkflowDigestsForm, domainId: string) => {
  return {
    ...schedule,
    domainId,
  };
};
