/**
 * HospitalServers ("Integrations" on the UI) are servers within a hospital system that connect
 * to Butterfly Cloud via a "Connection".
 *
 * There are currently two types of Connections:
 * 1.  DICOM-TLS
 *     - DICOM-TLS connections supports PACS and Modality Worklists
 *       Integrations (using the DICOM protocol).
 * 2.  Butterfly Link
 *     - Butterfly Link connections not only supports PACS and Modality
 *       Worklists (using the DICOM protocol), but also integrates to EHR
 *       systems (using the HL7 protocol)
 *
 * The schemas in this file represent the HospitalServer for both connection types
 * (DICOM-TLS and Butterfly Link). They all share a common hospitalServer base
 * schema with additional fields for the underlying protocol (DICOM and HL7).
 *
 */

import { defineMessages } from 'react-intl';
import { array, boolean, number, object, string } from 'yup';

import ALLOWED_TIMEZONES, {
  ALLOWED_OPTIONAL_TIMEZONES,
} from './allowedTimezones';

export enum ConnectionType {
  BUTTERFLY_LINK = 'BUTTERFLY_LINK',
  DICOM_TLS = 'DICOM_TLS',
}

export enum ConnectionFieldTypeName {
  BUTTERFLY_LINK = 'ButterflyLinkConnection',
  DICOM_TLS = 'DicomTlsConnection',
}

export const CONNECTION_TYPES: Record<ConnectionFieldTypeName, string> = {
  [ConnectionFieldTypeName.BUTTERFLY_LINK]: ConnectionType.BUTTERFLY_LINK,
  [ConnectionFieldTypeName.DICOM_TLS]: ConnectionType.DICOM_TLS,
};

const messages = defineMessages({
  timezoneRequired: {
    id: 'enterpriseConnector.timezone.required',
    defaultMessage: 'You must specify a valid timezone',
  },
});

const timeout = number().integer().min(0).max(1000);

const MAX_PORT_NUMBER = 65535;

export const PORT = number().integer().min(1).max(MAX_PORT_NUMBER);

export const TIMEZONE = string()
  .oneOf(
    ALLOWED_TIMEZONES.map((i) => i.value),
    messages.timezoneRequired as any,
  )
  .required(messages.timezoneRequired as any);

const OPTIONAL_TIMEZONE = string()
  .nullable()
  .oneOf(ALLOWED_OPTIONAL_TIMEZONES.map((i) => i.value));

// -----------------------------------------------------------------------------
// Base schema for both DICOM-TLS and Butterfly Link
// -----------------------------------------------------------------------------

export const hospitalServer = object({
  id: string().optional(),
  name: string().required(),
  timeoutConnection: timeout.default(100),
  timezone: OPTIONAL_TIMEZONE,

  // Connection fields are nullable for backwards compatibility
  //  with the old enterpriseConnectors
  connectionId: string().nullable(),
  connectionType: string()
    .enum(Object.values(ConnectionType))
    .nullable()
    .default(null),
});

export function flattenConnection({ connection, ...value }: any) {
  return {
    ...value,
    connectionId: connection?.id,
    connectionType: connection?.type && CONNECTION_TYPES[connection!.type],
  };
}

// -----------------------------------------------------------------------------
// HL7 specific schema
// -----------------------------------------------------------------------------

export const HL7_ENCODING = ['UTF_8', 'ISO_8859_1'] as const;

export interface ResultPayloadContent {
  value: string;
  name: string;
}

export const resultPayloadContents = [
  { name: 'PDF', value: 'PDF' },
  { name: 'Text', value: 'TEXT' },
  { name: 'Text and PDF', value: 'TEXT_AND_PDF' },
];

export const hl7HospitalServer = hospitalServer.shape({
  hospitalHost: string().required(),
  hospitalPort: PORT.required(),
  characterEncoding: string().enum(HL7_ENCODING).required(),
  timeoutAck: timeout.default(300),
  sendingFacility: string().default('BNI'),
  receivingFacility: string(),
  resultPayloadContent: string(),
  shouldSendPreliminaryResults: boolean()
    .strict(true)
    .default(false)
    .required(),

  // FIXME: remove once parent's fields are non-nullable
  connectionId: string(),
  connectionType: string().enum(Object.values(ConnectionType)).default(null),
  timezone: TIMEZONE,
});

export const fhirIntegrationSchema = hospitalServer.shape({
  timeoutConnection: timeout.default(100).strip(),
  connectionType: string()
    .enum([null, ...Object.values(ConnectionType)])
    .nullable()
    .default(null),
  fhirApiUrl: string().required(),
  patientMrnType: string().required(),
  encounterIdType: string().default(''),
  visitTypeIdentifier: string().default(''),
  dicomClientPort: PORT.nullable().when('connectionType', {
    is: (val) => Object.values(ConnectionType).includes(val),
    then: (field) => field.required(),
    otherwise: (field) => field.default(null),
  }),
  userId: string().default(''),
});

export const updateHl7HospitalServer = hl7HospitalServer.shape({
  studyDescriptionMapping: array()
    .of(
      object({
        studyDescription: string().required().default(''),
        procedureId: string().required().default(''),
      }),
    )
    .default([]),
});

export const deserializeHl7HospitalServer = (value: any) =>
  hl7HospitalServer.transform(flattenConnection).cast(value);

export const deserializeUpdateHl7HospitalServer = (value: any) =>
  updateHl7HospitalServer.transform(flattenConnection).cast(value);

export const deserializeUpdateFhirIntegration = (value: any) =>
  fhirIntegrationSchema.transform(flattenConnection).cast(value);

// -----------------------------------------------------------------------------
// DICOM-TLS specific schema
// -----------------------------------------------------------------------------

export const DICOM_ENCODING = ['ISO_IR100', 'ISO_IR6', 'ISO_IR192'] as const;

const connectionTypePort = (connectionType: ConnectionType) =>
  PORT.nullable().when('connectionType', {
    is: connectionType,
    then: (field) => field.required(),
    otherwise: (field) => field.strip(),
  });

export const dicomHospitalServer = hospitalServer.shape({
  characterEncoding: string().enum(DICOM_ENCODING).required(),
  scuAet: string().required(),
  scpAet: string().required(),
  timeoutAcse: timeout.default(300),
  timeoutDimse: timeout.default(300),
  timezone: TIMEZONE,
  scpPort: connectionTypePort(ConnectionType.DICOM_TLS),
  hospitalPort: connectionTypePort(ConnectionType.BUTTERFLY_LINK),

  hospitalHost: string()
    .nullable()
    .when('connectionType', {
      is: ConnectionType.BUTTERFLY_LINK,
      then: (field) => field.required(),
      otherwise: (field) => field.strip(),
    }),
});

// -----------------------------------------------------------------------------
// TPUS Listener schema
// -----------------------------------------------------------------------------

export const tpusListenerSchema = object({
  timezone: TIMEZONE,
  connectionId: string().required(),
  autoAssignDicomFieldTemplateId: string(),
  autoAssignSecondaryDicomFieldTemplateId: string(),
  isAmbraEnabled: boolean().required().default(false),

  clientPort: PORT.required().when('isAmbraEnabled', {
    is: true,
    then: (field) => field.default(MAX_PORT_NUMBER),
  }),

  scuAet: string().when('isAmbraEnabled', {
    is: true,
    then: (field) => field.required(),
  }),
});

export const deserializeTpusListener = (tpusListener: any) =>
  tpusListenerSchema.cast(
    {
      ...tpusListener,
      connectionId: tpusListener.connection?.id,
      autoAssignDicomFieldTemplateId:
        tpusListener.autoAssignDicomFieldTemplate?.id,
      autoAssignSecondaryDicomFieldTemplateId:
        tpusListener.autoAssignSecondaryDicomFieldTemplate?.id,
      scuAet: tpusListener.scuAet || '',
    },
    { stripUnknown: true },
  );
