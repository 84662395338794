import Toast from '@bfly/ui2/Toast';
import useToast from '@bfly/ui2/useToast';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import { useRouter } from 'found';
import { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useMutation } from 'react-relay';

import { useExamRoutes } from 'routes/exam';
import { SelectedStudyItem } from 'utils/StudySelection';

import { useMoveStudies_MoveBulkStudy_Mutation as MoveBulkStudy } from './__generated__/useMoveStudies_MoveBulkStudy_Mutation.graphql';

const moveBulkStudyMutation = graphql`
  mutation useMoveStudies_MoveBulkStudy_Mutation($input: MoveBulkStudyInput!) {
    moveBulkStudyOrError(input: $input) {
      ... on MoveBulkStudyPayload {
        studies {
          handle
          archive {
            handle
            label
          }
          organization {
            slug
          }
          ...StudyPermissions_allStudyPermissions
        }
        # in case some of the studies were not moved
        errors {
          details
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

export type StudyItem = Pick<SelectedStudyItem, 'studyId' | 'archiveId'>;

export default function useMoveStudies() {
  const toast = useToast();
  const { router } = useRouter();
  const examRoutes = useExamRoutes();
  const [moveBulkStudy, loading] = useMutation<MoveBulkStudy>(
    moveBulkStudyMutation,
  );

  const moveStudies = useCallback(
    async (
      studyAndArchiveIds: { studyId: string; archiveId: string }[],
      destinationArchiveId: string,
      destinationOrganizationId: string,
      sourceOrganizationId: string,
      domainId = '',
    ) => {
      const response = await new Promise<MoveBulkStudy['response'] | null>(
        (resolve, reject) =>
          moveBulkStudy({
            variables: {
              input: {
                archiveId: destinationArchiveId,
                studyIds: studyAndArchiveIds.map(({ studyId }) => studyId),
              },
            },
            onError: reject,
            onCompleted: (resp) => resolve(resp),
            updater: (store, resp) => {
              if (!resp?.moveBulkStudyOrError) return;

              studyAndArchiveIds.forEach((study) => {
                /**
                 * In archive-specific views, a moved study will always be
                 * removed from the view since the archiveId will change.
                 */
                [
                  'Archive_studySearchConnection',
                  'Archive_studyConnection',
                ].forEach((connKey) => {
                  rangeDeleteUpdater(store, {
                    parentId: study.archiveId,
                    connectionKey: connKey,
                    connectionFilters: () => true,
                    deletedId: study.studyId,
                  });
                });

                /**
                 * When moving a study in the list view, it should be removed
                 * from the view if:
                 * a) It's moved in the same organization, but to an archive
                 *    that's not selected in the current filters
                 * b) It's moved to a different organization
                 */
                [
                  'Organization_studyConnection',
                  'Organization_studySearchConnection',
                ].forEach((connectionKey) => {
                  let connectionFilters = (_args: any) => true;
                  if (
                    !destinationOrganizationId ||
                    destinationOrganizationId === sourceOrganizationId
                  ) {
                    connectionFilters = (args) =>
                      args.archiveId &&
                      args.archiveId.includes(study.archiveId);
                  }
                  rangeDeleteUpdater(store, {
                    parentId: sourceOrganizationId,
                    deletedId: study.studyId,
                    connectionFilters,
                    connectionKey,
                  });
                });

                /**
                 * When moving a study in domain-based views, such as global
                 * search, it should always be removed from the view as there
                 * is no way to make a synchronous filter for the search page
                 * to determine whether a study can still be visible with the
                 * current search filters, given the delay with indexing.
                 */
                if (domainId) {
                  [
                    'Tenant_studyConnection',
                    'Tenant_studySearchConnection',
                  ].forEach((connKey) => {
                    rangeDeleteUpdater(store, {
                      parentId: domainId,
                      connectionKey: connKey,
                      connectionFilters: () => true,
                      deletedId: study.studyId,
                    });
                  });
                }
              });
            },
          }),
      );

      const numStudies = studyAndArchiveIds.length;
      let numErrors = numStudies;
      let studiesResponse: NonNullable<
        MoveBulkStudy['response']['moveBulkStudyOrError']
      >['studies'] = [];

      if (response?.moveBulkStudyOrError) {
        const { errors, studies } = response.moveBulkStudyOrError;
        numErrors = errors?.length || 0;
        studiesResponse = studies;
      }

      if (numErrors) {
        toast.error(
          <FormattedMessage
            id="study.move.failure"
            defaultMessage="Failed to move {numErrors} {numErrors, plural,
            one {study}
            other {studies}
          }"
            values={{
              numErrors,
            }}
          />,
        );
      }

      if (studiesResponse?.length) {
        let callToAction: ReactNode | undefined;

        if (studiesResponse.length === 1) {
          const organizationSlug = studiesResponse[0]?.organization?.slug;
          const studyHandle = studiesResponse[0]?.handle;

          callToAction = organizationSlug && studyHandle && (
            <Toast.Action
              onClick={() =>
                router.push(examRoutes.exam({ organizationSlug, studyHandle }))
              }
            >
              <FormattedMessage
                id="study.move.success.action"
                defaultMessage="View draft"
              />
            </Toast.Action>
          );
        }

        toast.success({
          message: (
            <FormattedMessage
              id="study.move.success"
              defaultMessage="{numStudies} {numStudies, plural,
          one {study}
          other {studies}
        } moved"
              values={{ numStudies: numStudies - numErrors }}
            />
          ),
          callToAction,
        });
      }

      return true;
    },
    [examRoutes, moveBulkStudy, router, toast],
  );

  return [moveStudies, loading] as const;
}
