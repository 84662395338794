import { defineMessages } from 'react-intl';

export default defineMessages({
  clientHeader: {
    id: 'vetPatient.clientHeader',
    defaultMessage: 'Client',
  },
  patient: {
    id: 'vetPatient',
    defaultMessage: 'Patient',
  },
  clientName: {
    id: 'vetPatient.clientName',
    defaultMessage: 'Name',
  },
  clientOrganizationName: {
    id: 'vetPatient.clientOrganizationName',
    defaultMessage: 'Business',
  },
  name: {
    id: 'vetPatient.name',
    defaultMessage: 'Name',
  },
  patientIdNumber: {
    id: 'vetPatient.patientIdNumber',
    defaultMessage: 'Patient ID',
  },
  microchipNumber: {
    id: 'vetPatient.microchipNumber',
    defaultMessage: 'MC #',
  },
  birthDate: {
    id: 'vetPatient.birthDate',
    defaultMessage: 'DOB',
  },
  species: {
    id: 'vetPatient.species',
    defaultMessage: 'Species',
  },
  speciesFreeform: {
    id: 'vetPatient.speciesFreeform',
    defaultMessage: '\u200B',
  },
  speciesFreeformPlaceholder: {
    id: 'vetPatient.speciesFreeformPlaceholder',
    defaultMessage: 'Further description of species (optional)',
  },
  breed: {
    id: 'vetPatient.breed',
    defaultMessage: 'Breed',
  },
  sex: {
    id: 'vetPatient.sex',
    defaultMessage: 'Sex',
  },
  neuterStatus: {
    id: 'vetPatient.neuterStatus',
    defaultMessage: 'Neutered',
  },
  weight: {
    id: 'vetPatient.weight',
    defaultMessage: 'Weight',
  },
  weightTemplate: {
    id: 'vetPatient.weightTemplate',
    defaultMessage: '{weightKilograms} kg',
  },
  accessionNumberLabel: {
    id: 'vetPatient.accessionNumberLabel',
    defaultMessage: 'Accession Number',
  },
});

export const speciesMessages = defineMessages({
  AVIAN: {
    id: 'vetPatient.species.AVIAN',
    defaultMessage: 'Avian',
  },
  CANINE: {
    id: 'vetPatient.species.CANINE',
    defaultMessage: 'Canine',
  },
  EQUINE: {
    id: 'vetPatient.species.EQUINE',
    defaultMessage: 'Equine',
  },
  EXOTIC: {
    id: 'vetPatient.species.EXOTIC',
    defaultMessage: 'Exotic',
  },
  FELINE: {
    id: 'vetPatient.species.FELINE',
    defaultMessage: 'Feline',
  },
  OTHER: {
    id: 'vetPatient.species.OTHER',
    defaultMessage: 'Other',
  },
  UNKNOWN: {
    id: 'vetPatient.species.UNKNOWN',
    defaultMessage: 'Unknown',
  },
});

export const sexMessages = defineMessages({
  MALE: {
    id: 'vetPatient.sex.MALE',
    defaultMessage: 'Male',
  },
  FEMALE: {
    id: 'vetPatient.sex.FEMALE',
    defaultMessage: 'Female',
  },
  UNKNOWN: {
    id: 'vetPatient.sex.UNKNOWN',
    defaultMessage: 'Unknown',
  },
});

export const neuterStatusMessages = defineMessages({
  NEUTERED: {
    id: 'vetPatient.neuterStatus.NEUTERED',
    defaultMessage: 'Yes',
  },
  INTACT: {
    id: 'vetPatient.neuterStatus.INTACT',
    defaultMessage: 'No',
  },
  UNKNOWN: {
    id: 'vetPatient.neuterStatus.UNKNOWN',
    defaultMessage: 'Unknown',
  },
});
