import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'actions.save',
    defaultMessage: 'Save',
  },
  submit: {
    id: 'actions.submit',
    defaultMessage: 'Submit',
  },
  cancel: {
    id: 'actions.cancel',
    defaultMessage: 'Cancel',
  },
  ok: {
    id: 'actions.ok',
    defaultMessage: 'Ok',
  },
  reset: {
    id: 'actions.reset',
    defaultMessage: 'Reset',
  },
  confirm: {
    id: 'actions.confirm',
    defaultMessage: 'Confirm',
  },
  close: {
    id: 'actions.close',
    defaultMessage: 'Close',
  },
  complete: {
    id: 'actions.complete',
    defaultMessage: 'Complete',
  },
  select: {
    id: 'actions.select',
    defaultMessage: 'Select',
  },
  selectAll: {
    id: 'actions.selectAll',
    defaultMessage: 'Select All',
  },
  deselect: {
    id: 'actions.deselect',
    defaultMessage: 'Deselect',
  },
  deselectAll: {
    id: 'actions.deselectAll',
    defaultMessage: 'Deselect All',
  },
  done: {
    id: 'actions.done',
    defaultMessage: 'Done',
  },
  create: {
    id: 'actions.create',
    defaultMessage: 'Create',
  },
  delete: {
    id: 'actions.delete',
    defaultMessage: 'Delete',
  },
  deactivate: {
    id: 'actions.deactivate',
    defaultMessage: 'Deactivate',
  },
  remove: {
    id: 'actions.remove',
    defaultMessage: 'Remove',
  },
  move: {
    id: 'actions.move',
    defaultMessage: 'Move',
  },
  edit: {
    id: 'actions.edit',
    defaultMessage: 'Edit',
  },
  editSelected: {
    id: 'actions.editSelected',
    defaultMessage: 'Edit selected',
  },
  exitWithoutSave: {
    id: 'actions.exitWithoutSave',
    defaultMessage: 'Exit Without Save',
  },
  saveAndExit: {
    id: 'actions.saveAndExit',
    defaultMessage: 'Save and Exit',
  },
  exit: {
    id: 'actions.exit',
    defaultMessage: 'Exit',
  },
  add: {
    id: 'actions.add',
    defaultMessage: 'Add',
  },
  update: {
    id: 'actions.update',
    defaultMessage: 'Update',
  },
  finalize: {
    id: 'actions.finalize',
    defaultMessage: 'Finalize',
  },
  sign: {
    id: 'actions.sign',
    defaultMessage: 'Sign',
  },
  signAndFinalize: {
    id: 'actions.signAndFinalize',
    defaultMessage: 'Sign and Finalize',
  },
  share: {
    id: 'actions.share',
    defaultMessage: 'Share',
  },
  download: {
    id: 'actions.download',
    defaultMessage: 'Download',
  },
  copy: {
    id: 'actions.copy',
    defaultMessage: 'Copy',
  },
  settings: {
    id: 'actions.settings',
    defaultMessage: 'Settings',
  },
  next: {
    id: 'actions.next',
    defaultMessage: 'Next',
  },
  back: {
    id: 'actions.back',
    defaultMessage: 'Back',
  },
  export: {
    id: 'actions.export',
    defaultMessage: 'Export',
  },
  view: {
    id: 'actions.view',
    defaultMessage: 'View',
  },
  disable: {
    id: 'actions.disable',
    defaultMessage: 'Disable',
  },
  enable: {
    id: 'actions.enable',
    defaultMessage: 'Enable',
  },
  continue: {
    id: 'actions.continue',
    defaultMessage: 'Continue',
  },
  createAndNext: {
    id: 'actions.createAndNext',
    defaultMessage: 'Create and Next',
  },
});
