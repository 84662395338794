/**
 * @generated SignedSource<<f65a3a07b22544a11f86b8f5e25bb413>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarListSuggestionOption_membership$data = {
  readonly userProfile: {
    readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_userProfile">;
  } | null;
  readonly " $fragmentType": "SearchBarListSuggestionOption_membership";
};
export type SearchBarListSuggestionOption_membership$key = {
  readonly " $data"?: SearchBarListSuggestionOption_membership$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_membership">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarListSuggestionOption_membership"
};

(node as any).hash = "afab792d109b53812d2ea37457dcfd69";

export default node;
