/**
 * @generated SignedSource<<2c91c6f87afcb020ed3ff056e5e7febf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamPageSidebar_study$data = {
  readonly finalizedAt: string | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly viewerCanQa: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageSidebarFooter_study" | "ExamPageSidebarNotes_study" | "ExamPageSidebarQa_study" | "ExamPageSidebarWorksheets_study" | "ExamTags_study" | "SelectExamTypeControl_study" | "StudyPermissions_studyReviewStatus" | "examPageSidebar_useSections_study">;
  readonly " $fragmentType": "ExamPageSidebar_study";
};
export type ExamPageSidebar_study$key = {
  readonly " $data"?: ExamPageSidebar_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageSidebar_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalizedAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanQa",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numWorksheets",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Archive",
  "kind": "LinkedField",
  "name": "archive",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheetRequiredForQa",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v6 = {
  "kind": "InlineDataFragmentSpread",
  "name": "StudyPermissions_studyReviewStatus",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numQaEntries",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StandardQa",
      "kind": "LinkedField",
      "name": "standardQa",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isPACEExam"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPageSidebar_study",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamPageSidebarFooter_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamPageSidebarQa_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectExamTypeControl_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamTags_study"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isPACEExam",
          "variableName": "isPACEExam"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ExamPageSidebarWorksheets_study"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "examPageSidebar_useSections_study",
      "selections": [
        (v1/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "examPageSidebar_useShowQa_study",
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "StudyPermissions_studyOrArchiveIsDeleted",
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Archive",
                  "kind": "LinkedField",
                  "name": "archive",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            (v6/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamPageSidebarNotes_study"
    },
    (v6/*: any*/)
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "22334a1f4a9f954cea71919f16a5be73";

export default node;
