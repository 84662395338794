/**
 * @generated SignedSource<<bac36654d724be45c7fe265f613e14b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSuggestedExamTypesGroups_study$data = {
  readonly examTypes: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly organization: {
    readonly examTypeConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly hiddenAt: string | null;
          readonly id: string;
          readonly name: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly slug: string | null;
  } | null;
  readonly worksheets: ReadonlyArray<{
    readonly templateVersion: {
      readonly examTypes: ReadonlyArray<{
        readonly id: string;
      } | null> | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "useSuggestedExamTypesGroups_study";
};
export type useSuggestedExamTypesGroups_study$key = {
  readonly " $data"?: useSuggestedExamTypesGroups_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSuggestedExamTypesGroups_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSuggestedExamTypesGroups_study"
};

(node as any).hash = "7a44cbf26fabe2bd75107c5c86b862b9";

export default node;
