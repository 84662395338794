/**
 * @generated SignedSource<<b2c5bc923f26b825e59b2950ff2658dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type organizationQaRoutes_CreateQaTemplateForm_Query$variables = {
  organizationSlug: string;
};
export type organizationQaRoutes_CreateQaTemplateForm_Query$data = {
  readonly workflowConfig: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateQaTemplateForm_workflowConfig">;
  } | null;
};
export type organizationQaRoutes_CreateQaTemplateForm_Query = {
  response: organizationQaRoutes_CreateQaTemplateForm_Query$data;
  variables: organizationQaRoutes_CreateQaTemplateForm_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "organizationQaRoutes_CreateQaTemplateForm_Query",
    "selections": [
      {
        "alias": "workflowConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateQaTemplateForm_workflowConfig"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "organizationQaRoutes_CreateQaTemplateForm_Query",
    "selections": [
      {
        "alias": "workflowConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "organizationId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "domainId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "WorkflowConfigInterface",
            "abstractKey": "__isWorkflowConfigInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a90206a53bc372903d4523d09f66db58",
    "id": null,
    "metadata": {},
    "name": "organizationQaRoutes_CreateQaTemplateForm_Query",
    "operationKind": "query",
    "text": "query organizationQaRoutes_CreateQaTemplateForm_Query(\n  $organizationSlug: String!\n) {\n  workflowConfig: organizationBySlug(slug: $organizationSlug) {\n    ...CreateQaTemplateForm_workflowConfig\n    id\n  }\n}\n\nfragment CreateQaTemplateForm_workflowConfig on WorkflowConfigInterface {\n  __isWorkflowConfigInterface: __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ...useTenantIdInput_tenant\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "80a66b49249005a2a830e3dfdaf1beb6";

export default node;
