import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import CreateButterflyLinkControl from 'components/CreateButterflyLinkControl';
import ErrorBoundary from 'components/ErrorBoundary';
import { useVariation } from 'components/LaunchDarklyContext';
import OutageMessage from 'components/OutageMessage';
import RelayForm from 'components/RelayForm';
import useModalState from 'hooks/useModalState';
import {
  ConnectionFieldTypeName,
  deserializeTpusListener,
  tpusListenerSchema,
} from 'schemas/hospitalServer';
import { usePermissions } from 'utils/viewerContext';

import ThirdPartyUltrasoundListenerConfigurationContent, {
  TpusArchiveRouting,
} from './ThirdPartyUltrasoundListenerConfigurationContent';
import ThirdPartyUltrasoundLogModal from './ThirdPartyUltrasoundLogModal';
import type { ThirdPartyUltrasoundListenerConfiguration_SetMutation as SetMutation } from './__generated__/ThirdPartyUltrasoundListenerConfiguration_SetMutation.graphql';
import type { ThirdPartyUltrasoundListenerConfiguration_archive$data as Archive } from './__generated__/ThirdPartyUltrasoundListenerConfiguration_archive.graphql';
import type { ThirdPartyUltrasoundListenerConfiguration_viewer$data as Viewer } from './__generated__/ThirdPartyUltrasoundListenerConfiguration_viewer.graphql';

const messages = defineMessages({
  connectionPlaceholder: {
    id: 'archive.settings.tpus.connection.placeholder',
    defaultMessage: 'Select a Butterfly Link',
  },
});

const setMutation = graphql`
  mutation ThirdPartyUltrasoundListenerConfiguration_SetMutation(
    $input: SetArchiveThirdPartyUltrasoundListenerInput!
  ) {
    setArchiveThirdPartyUltrasoundListenerOrError(input: $input) {
      ... on SetArchiveThirdPartyUltrasoundListenerPayload {
        archive {
          ...ThirdPartyUltrasoundListenerConfiguration_archive
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface Props {
  archive: Archive;
  viewer: Viewer;
}

const DEFAULT_VALUE = tpusListenerSchema.getDefault();

function routingForArchive(
  archive: Archive,
  connectionId?: string,
): TpusArchiveRouting {
  const connections = archive.organization?.hospitalConnections;
  const connection = connections?.find((c) => c?.id === connectionId);
  if (connection?.isAmbraEnabled) return 'scu_aet';
  if (connectionId) return 'port';
  return 'unknown';
}

function ThirdPartyUltrasoundListenerConfiguration({
  archive,
  viewer,
}: Props) {
  const { thirdPartyUltrasoundListener, organization } = archive;

  const canUseDomainConnectivity = useVariation(
    'domain-connectivity-settings',
  );
  const { hasAdminPermission } = usePermissions();

  const [value, setValue] = useState(() =>
    thirdPartyUltrasoundListener
      ? deserializeTpusListener(thirdPartyUltrasoundListener)
      : DEFAULT_VALUE,
  );

  // editing an existing listener is not allowed
  const [editable, setEditable] = useState(!thirdPartyUltrasoundListener);

  // switch between routing fields depending on the connection type
  const routing = routingForArchive(archive, value.connectionId);

  // make sure we keep the listener up to date so that the schema can
  // correctly inform the UI
  useEffect(() => {
    const connections = archive.organization?.hospitalConnections;
    const connection = connections?.find((c) => c?.id === value.connectionId);
    const isAmbraEnabled = !!connection?.isAmbraEnabled;
    setValue((v) => Object.assign(v, { isAmbraEnabled }));
  }, [value.connectionId, archive]);

  const butterflyHospitalConnections = useMemo(
    () =>
      (organization?.hospitalConnections || []).filter(
        (connection) =>
          connection!.type === ConnectionFieldTypeName.BUTTERFLY_LINK,
      ),
    [organization],
  );

  const [showLogsModal, modalProps] = useModalState();
  const clearForm = () => {
    setValue(DEFAULT_VALUE);
    setEditable(true);
  };

  return (
    <>
      <Form.FieldSet
        legend={
          <Layout justify="space-between">
            <FormattedMessage
              id="archive.settings.tpus.title"
              defaultMessage="Third-party Integration"
            />
            <Button variant="text-primary" onClick={showLogsModal}>
              <FormattedMessage
                id="archive.settings.tpus.logs"
                defaultMessage="Logs"
              />
            </Button>
          </Layout>
        }
        className="mb-5"
      >
        {!Array.isArray(organization?.hospitalConnections) ? (
          <OutageMessage />
        ) : (
          <ErrorBoundary>
            <RelayForm<SetMutation, typeof tpusListenerSchema>
              horizontal
              value={value}
              onChange={setValue}
              schema={tpusListenerSchema}
              mutation={setMutation}
              onCompleted={() => setEditable(false)}
              getInput={({
                clientPort,
                timezone,
                connectionId,
                autoAssignDicomFieldTemplateId,
                autoAssignSecondaryDicomFieldTemplateId,
                scuAet,
              }) => ({
                timezone,
                connectionId,
                autoAssignDicomFieldTemplateId,
                autoAssignSecondaryDicomFieldTemplateId,
                // FIXME: this should probably be required in the schema if required here
                clientPort: clientPort!,
                archiveId: archive.id,
                organizationId: archive.organization!.id,
                scuAet,
              })}
              successMessage={
                <FormattedMessage
                  id="archive.settings.tpus.success"
                  defaultMessage="Third-Party Ultrasound Listener configured"
                />
              }
              data-bni-id="tpus-listener-form"
            >
              <Form.FieldGroup
                name="connectionId"
                as={DropdownList}
                data={butterflyHospitalConnections}
                disabled={
                  !butterflyHospitalConnections?.length ||
                  !editable ||
                  !hasAdminPermission('connectivityManagement')
                }
                textField="name"
                dataKey="id"
                mapFromValue={{
                  connectionId: 'id',
                }}
                placeholder={messages.connectionPlaceholder}
                description={
                  !butterflyHospitalConnections.length && (
                    <FormattedMessage
                      id="archive.settings.tpus.connection.description"
                      defaultMessage="Third-Party Listeners can only be configured via Butterfly Link. {action}"
                      values={{
                        // FIXME(ethan): make this work for domain connectivity-settings
                        action: !canUseDomainConnectivity && (
                          <FormattedMessage
                            id="archive.settings.tpus.connection.description.action"
                            defaultMessage="<a>Add Butterfly Link</a>"
                            values={{
                              a: (msg) => (
                                <CreateButterflyLinkControl
                                  as={Button}
                                  variant="text-primary"
                                  connectivityConfig={organization}
                                >
                                  {msg}
                                </CreateButterflyLinkControl>
                              ),
                            }}
                          />
                        ),
                      }}
                    />
                  )
                }
                label={
                  <FormattedMessage
                    id="archive.settings.form.connection.type"
                    defaultMessage="Type"
                  />
                }
              />
              {!!butterflyHospitalConnections.length && (
                <ThirdPartyUltrasoundListenerConfigurationContent
                  showClearButton={!!thirdPartyUltrasoundListener}
                  clearForm={clearForm}
                  viewer={viewer}
                  archive={archive}
                  editable={editable}
                  routing={routing}
                />
              )}
            </RelayForm>
          </ErrorBoundary>
        )}
      </Form.FieldSet>
      <ThirdPartyUltrasoundLogModal archive={archive} {...modalProps} />
    </>
  );
}

export default createFragmentContainer(
  ThirdPartyUltrasoundListenerConfiguration,
  {
    archive: graphql`
      fragment ThirdPartyUltrasoundListenerConfiguration_archive on Archive {
        ...ThirdPartyUltrasoundListenerConfigurationContent_archive
        id
        thirdPartyUltrasoundListener {
          clientPort
          scuAet
          isAmbraEnabled
          timezone
          autoAssignDicomFieldTemplate {
            id
          }
          autoAssignSecondaryDicomFieldTemplate {
            id
          }
          connection {
            handle
            ... on Node {
              id
            }
          }
        }
        organization {
          id
          hospitalConnections {
            type: __typename
            name
            ... on Node {
              id
            }
            ... on ButterflyLinkConnection {
              isAmbraEnabled
            }
          }
          ...CreateButterflyLinkControl_connectivityConfig
        }
        ...ThirdPartyUltrasoundLogModal_archive
      }
    `,
    viewer: graphql`
      fragment ThirdPartyUltrasoundListenerConfiguration_viewer on Viewer {
        ...ThirdPartyUltrasoundListenerConfigurationContent_viewer
      }
    `,
  },
);
