import { ButtonVariant } from '@bfly/ui2/Button';
import useDialog from '@bfly/ui2/useDialog';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { UndeleteArchiveControl_archive$data as Archive } from './__generated__/UndeleteArchiveControl_archive.graphql';

// TODO: Insert the new edge instead of refetching archives.
const mutation = graphql`
  mutation UndeleteArchiveControlMutation($input: UndeleteArchiveInput!) {
    undeleteArchiveOrError(input: $input) {
      ... on UndeleteArchivePayload {
        archive {
          deletedAt
        }
        organization {
          archiveConnection(first: 2147483647)
            @connection(key: "Organization_archiveConnection") {
            edges {
              node {
                ...ArchiveListItem_archive
              }
            }
          }
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  as: any;
  archive: Archive;
  children: ReactNode;
  variant?: ButtonVariant;
}

function UndeleteArchiveControl({
  as: Component,
  archive,
  children,
  variant,
}: Props) {
  const dialog = useDialog();
  const toast = useToast();

  const [mutate] = useMutationWithError(mutation, {
    input: {
      archiveId: archive.id,
    },
    updater: (store) => {
      rangeDeleteUpdater(store, {
        parentId: archive.organization!.id,
        connectionKey: 'Organization_deletedArchiveConnection',
        deletedId: archive.id,
      });
    },
  });

  const handleClick = async () => {
    const undeleted = await dialog.open(
      <FormattedMessage
        id="archive.undelete.dialog.content"
        defaultMessage="Restoring this archive will also restore all studies contained in it."
      />,
      {
        runConfirm: mutate,
        title: (
          <FormattedMessage
            id="archive.undelete.dialog.title"
            defaultMessage="Restore Archive?"
          />
        ),
        confirmLabel: (
          <FormattedMessage
            id="archive.undelete.dialog.confirm"
            defaultMessage="Restore"
          />
        ),
      },
    );

    if (undeleted) {
      toast.success(
        <FormattedMessage
          id="archive.undelete.success"
          defaultMessage="Archive restored successfully"
        />,
      );
    }
  };

  return (
    <Component onClick={handleClick} variant={variant}>
      {children}
    </Component>
  );
}

export default createFragmentContainer(UndeleteArchiveControl, {
  archive: graphql`
    fragment UndeleteArchiveControl_archive on Archive {
      id
      organization {
        id
      }
    }
  `,
});
