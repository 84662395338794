import Layout from '@4c/layout';
import Button, { ButtonProps } from '@bfly/ui2/Button';
import Caret from '@bfly/ui2/Caret';
import DatePicker from '@bfly/ui2/DatePicker';
import Dropdown, { DropdownToggleProps } from '@bfly/ui2/Dropdown';
import Text from '@bfly/ui2/Text';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import useRouter from 'found/useRouter';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import dates from 'react-widgets/dates';

import actionMessages from 'messages/actions';

type ReadyAt = Date | null;

const styles = stylesheet`
  .calendar {
    & :global(.rw-popup-container) {
      right: 0;
      left: auto;
    }
  }
`;

function StudyDateRangeFilterDropdown(
  props: ButtonProps & DropdownToggleProps,
) {
  const {
    match: { location, params },
    router,
  } = useRouter();
  const ref = useRef<HTMLDivElement>(null);

  const [readyAtMin, setReadyAtMin] = useState<ReadyAt>(
    location.query.readyAtMin ? new Date(location.query.readyAtMin) : null,
  );
  const [readyAtMax, setReadyAtMax] = useState<ReadyAt>(
    location.query.readyAtMax ? new Date(location.query.readyAtMax) : null,
  );

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (!ref.current || !showMenu) return;
    ref.current.focus();
  }, [ref, showMenu]);

  const handleFilter = (
    min: ReadyAt | undefined,
    max: ReadyAt | undefined,
  ) => {
    router.replace({
      ...location,
      params,
      query: {
        ...location.query,
        readyAtMin: min ? dates.startOf(min, 'day').toISOString() : undefined,
        readyAtMax: max ? dates.endOf(max, 'day').toISOString() : undefined,
      },
    });
    setShowMenu(false);
  };

  const handleReset = () => {
    setReadyAtMin(null);
    setReadyAtMax(null);

    handleFilter(undefined, undefined);
  };

  return (
    <Dropdown placement="bottom-end" show={showMenu} onToggle={setShowMenu}>
      <Dropdown.Toggle {...props} variant="text-secondary" id="study-filter">
        <Text variant="body-bold">
          {!location.query.readyAtMin && !location.query.readyAtMax ? (
            <FormattedMessage
              id="studyDateRangeFilterDropdown.dateRange"
              defaultMessage="Date Range"
            />
          ) : (
            <FormattedMessage
              id="studyDateRangeFilterDropdown.dateRange.selected"
              defaultMessage="{hasReadyAtMin, select,
                  true {{readyAtMin}}
                  other {Earliest}} - {hasReadyAtMax, select,
                    true {{readyAtMax}}
                    other {Latest}}"
              values={{
                hasReadyAtMin: !!location.query.readyAtMin,
                readyAtMin: new Date(
                  location.query.readyAtMin,
                ).toLocaleDateString(),
                hasReadyAtMax: !!location.query.readyAtMax,
                readyAtMax: new Date(
                  location.query.readyAtMax,
                ).toLocaleDateString(),
              }}
            />
          )}
        </Text>
        <Caret />
      </Dropdown.Toggle>
      <Dropdown.Menu className="px-5 pb-5" data-bni-id="StudyDateRangePicker">
        <DatePicker
          className={clsx(styles.calendar, 'my-4')}
          ref={ref}
          value={readyAtMin}
          placeholder={defineMessage({
            id: 'studyDateRangeFilterDropdown.dateRange.from',
            defaultMessage: 'From',
          })}
          max={readyAtMax ?? undefined}
          parse={(str) => {
            const parsed = new Date(str);
            // @ts-ignore
            // eslint-disable-next-line no-restricted-globals
            return isNaN(parsed) ? undefined : parsed;
          }}
          onSelect={(date) => setReadyAtMin(date)}
          onChange={(date: ReadyAt) => setReadyAtMin(date)}
          autoFocus
        />
        <DatePicker
          className={styles.calendar}
          value={readyAtMax}
          placeholder={defineMessage({
            id: 'studyDateRangeFilterDropdown.dateRange.to',
            defaultMessage: 'To',
          })}
          min={readyAtMin ?? undefined}
          parse={(str) => {
            const parsed = new Date(str);
            // @ts-ignore
            // eslint-disable-next-line no-restricted-globals
            return isNaN(parsed) ? undefined : parsed;
          }}
          onSelect={(date) => setReadyAtMax(date)}
          onChange={(date: ReadyAt) => setReadyAtMax(date)}
        />
        <Layout className="mt-5" reverse justify="space-between" grow>
          <Button onClick={() => handleFilter(readyAtMin, readyAtMax)}>
            <FormattedMessage {...actionMessages.done} />
          </Button>
          <Button variant="secondary" type="button" onClick={handleReset}>
            <FormattedMessage {...actionMessages.reset} />
          </Button>
        </Layout>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default StudyDateRangeFilterDropdown;
