/**
 * @generated SignedSource<<ed49b3bd0d4993a8bab41ecf92cea4cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarList_archive$data = {
  readonly label: string | null;
  readonly " $fragmentType": "SearchBarList_archive";
};
export type SearchBarList_archive$key = {
  readonly " $data"?: SearchBarList_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarList_archive">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarList_archive"
};

(node as any).hash = "7c383886f4ec4cd7e30aa07ecddb7236";

export default node;
