/**
 * @generated SignedSource<<63fabc2ea19cf9acec1b27d1de25e7bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArchiveMembershipType = "ADMIN" | "GUEST" | "REGULAR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ArchiveMemberItemGridRow_membership$data = {
  readonly canFinalize: boolean | null;
  readonly canQa: boolean | null;
  readonly email: string | null;
  readonly id: string;
  readonly type: ArchiveMembershipType | null;
  readonly userProfile: {
    readonly " $fragmentSpreads": FragmentRefs<"UserInfo_userProfile">;
  } | null;
  readonly viewerCanDelete: boolean | null;
  readonly " $fragmentType": "ArchiveMemberItemGridRow_membership";
};
export type ArchiveMemberItemGridRow_membership$key = {
  readonly " $data"?: ArchiveMemberItemGridRow_membership$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveMemberItemGridRow_membership">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveMemberItemGridRow_membership",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "userProfile",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserInfo_userProfile"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanDelete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canQa",
      "storageKey": null
    }
  ],
  "type": "ArchiveMembership",
  "abstractKey": null
};

(node as any).hash = "541b35890649d592e01e32b7a7f89663";

export default node;
