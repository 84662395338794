import { SystemExamTypes } from 'utils/ExamTypeProficiencyUtils';

import {
  EduStudyQueryParams,
  EduStudySort,
  ReviewStatus,
  ScanLabListRoute,
} from './types';

export const PAGE_SIZE = 25;

export const SUPPORTED_EXAM_TYPES: SystemExamTypes[] = [
  'lung',
  'cardiac',
  'efast',
];

export const STATUS_LIST: ReviewStatus[] = [
  'unsubmitted',
  'reviewRequested',
  'reviewed',
];

// first ReviewStatus is the default status for the route
export const AVAILABLE_REVIEW_STATUSES: Record<
  ScanLabListRoute,
  ReviewStatus[]
> = {
  review: STATUS_LIST.slice(1),
  portfolio: STATUS_LIST,
};

export const DEFAULT_SORT: EduStudySort = 'CAPTURED_AT_DESC' as const;

export const EDU_STUDY_SORT: Record<EduStudySort, string> = {
  CAPTURED_AT_ASC: 'CAPTURED_AT_ASC',
  CAPTURED_AT_DESC: 'CAPTURED_AT_DESC',
  CONDUCTED_AT_ASC: 'CONDUCTED_AT_ASC',
  CONDUCTED_AT_DESC: 'CONDUCTED_AT_DESC',
  READY_AT_ASC: 'READY_AT_ASC',
  READY_AT_DESC: 'READY_AT_DESC',
  REVIEW_REQUESTED_AT_ASC: 'REVIEW_REQUESTED_AT_ASC',
  REVIEW_REQUESTED_AT_DESC: 'REVIEW_REQUESTED_AT_DESC',
  REVIEWED_AT_ASC: 'REVIEWED_AT_ASC',
  REVIEWED_AT_DESC: 'REVIEWED_AT_DESC',
  ZONES_CAPTURED_ASC: 'ZONES_CAPTURED_ASC',
  ZONES_CAPTURED_DESC: 'ZONES_CAPTURED_DESC',
  REVIEWED_IMAGE_QUALITY_ASC: 'REVIEWED_IMAGE_QUALITY_ASC',
  REVIEWED_IMAGE_QUALITY_DESC: 'REVIEWED_IMAGE_QUALITY_DESC',
} as const;

export const QUERY_PARAMS_DEFAULT: EduStudyQueryParams = {
  sort: [DEFAULT_SORT],
  author: null,
  isReviewed: false,
  isReviewRequested: false,
  groupId: null,
};

export const REVIEW_STATUS_QUERY_PARAMS: Record<
  ReviewStatus | 'none',
  Pick<EduStudyQueryParams, 'isReviewRequested' | 'isReviewed'>
> = {
  none: {
    isReviewRequested: undefined,
    isReviewed: undefined,
  },
  unsubmitted: {
    isReviewed: false,
    isReviewRequested: false,
  },
  reviewRequested: {
    isReviewed: false,
    isReviewRequested: true,
  },
  reviewed: {
    isReviewed: true,
    isReviewRequested: undefined,
  },
};

export function getReviewStatus(study: {
  standardQa: { createdAt: any | null } | null;
  reviewRequestedAt: any | null;
}): ReviewStatus {
  if (study.standardQa?.createdAt) {
    return 'reviewed';
  }
  if (study.reviewRequestedAt) {
    return 'reviewRequested';
  }
  return 'unsubmitted';
}
