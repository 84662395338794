/**
 * @generated SignedSource<<7c965f61bd90c0e458144e90a72738a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EduStudyCaptures_study$data = {
  readonly capturedAt: string | null;
  readonly handle: string | null;
  readonly numImages: number;
  readonly " $fragmentType": "EduStudyCaptures_study";
};
export type EduStudyCaptures_study$key = {
  readonly " $data"?: EduStudyCaptures_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"EduStudyCaptures_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EduStudyCaptures_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capturedAt",
      "storageKey": null
    }
  ],
  "type": "EduStudy",
  "abstractKey": null
};

(node as any).hash = "479745ad8c5170592326cf758295414b";

export default node;
