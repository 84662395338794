/**
 * @generated SignedSource<<3151426935b48d19d6ee623026e1fe3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EducationContentPage_educationContent$data = {
  readonly isPremium: boolean | null;
  readonly localId: string | null;
  readonly name: string | null;
  readonly nextRecord: {
    readonly handle: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"NextContentPopout_educationContent">;
  } | null;
  readonly prevRecord: {
    readonly handle: string | null;
  } | null;
  readonly viewerHasAccess: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"EducationContentInfo_educationContent" | "EducationContentPlayer_educationContent" | "Thumbnail_image">;
  readonly " $fragmentType": "EducationContentPage_educationContent";
};
export type EducationContentPage_educationContent$key = {
  readonly " $data"?: EducationContentPage_educationContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationContentPage_educationContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationContentPage_educationContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPremium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContent",
      "kind": "LinkedField",
      "name": "prevRecord",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContent",
      "kind": "LinkedField",
      "name": "nextRecord",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NextContentPopout_educationContent"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasAccess",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Thumbnail_image"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EducationContentPlayer_educationContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EducationContentInfo_educationContent"
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};
})();

(node as any).hash = "a37f101029f542c4590225f74211a887";

export default node;
