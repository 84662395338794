import Highlighter from '@bfly/ui2/Highlighter';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';
import ListOption, { ListOptionProps } from 'react-widgets/ListOption';
import { graphql, readInlineData } from 'relay-runtime';

import Avatar from './Avatar';
import type { SearchSuggestionResult } from './SearchBarGlobal';
import { SearchBarListSuggestionOption_archive$key as ArchiveKey } from './__generated__/SearchBarListSuggestionOption_archive.graphql';
import { SearchBarListSuggestionOption_examType$key as ExamTypeKey } from './__generated__/SearchBarListSuggestionOption_examType.graphql';
import { SearchBarListSuggestionOption_membership$key as MembershipKey } from './__generated__/SearchBarListSuggestionOption_membership.graphql';
import { SearchBarListSuggestionOption_organization$key as OrganizationKey } from './__generated__/SearchBarListSuggestionOption_organization.graphql';
import { SearchBarListSuggestionOption_studyTag$key as StudyTagKey } from './__generated__/SearchBarListSuggestionOption_studyTag.graphql';
import { SearchBarListSuggestionOption_userProfile$key as UserProfileKey } from './__generated__/SearchBarListSuggestionOption_userProfile.graphql';
import { SearchBarListSuggestionOption_worksheetTemplate$key as WorksheetTemplateKey } from './__generated__/SearchBarListSuggestionOption_worksheetTemplate.graphql';

export type ArchiveItem = SearchSuggestionResult<'ARCHIVE', ArchiveKey>;
export type UserProfileItem = SearchSuggestionResult<
  'USER_PROFILE',
  UserProfileKey
>;
export type DomainMembershipItem = SearchSuggestionResult<
  'DOMAIN_MEMBERSHIP',
  MembershipKey
>;
export type OrganizationMembershipItem = SearchSuggestionResult<
  'ORGANIZATION_MEMBERSHIP',
  MembershipKey
>;

export type StudyTagItem = SearchSuggestionResult<'STUDY_TAG', StudyTagKey>;
export type ExamTypeItem = SearchSuggestionResult<'EXAM_TYPE', ExamTypeKey>;
export type WorksheetTemplateItem = SearchSuggestionResult<
  'WORKSHEET_TEMPLATE',
  WorksheetTemplateKey
>;
export type OrganizationItem = SearchSuggestionResult<
  'ORGANIZATION',
  OrganizationKey
>;

export type SearchSuggestion =
  | ArchiveItem
  | UserProfileItem
  | StudyTagItem
  | ExamTypeItem
  | WorksheetTemplateItem
  | OrganizationItem
  | DomainMembershipItem
  | OrganizationMembershipItem;

function renderHighlighted(text: string, search: string) {
  return (
    <Highlighter
      text={text}
      search={search}
      highlightedClassName="text-base font-extrabold text-primary"
    />
  );
}

function renderArchive({
  dataItem,
  searchTerm,
}: ListOptionProps<ArchiveItem>) {
  const archive = readInlineData(
    graphql`
      fragment SearchBarListSuggestionOption_archive on Archive @inline {
        label
      }
    `,
    dataItem.node,
  );

  return renderHighlighted(archive.label!, searchTerm!);
}

function renderStudyTag({
  dataItem,
  searchTerm,
}: ListOptionProps<StudyTagItem>) {
  const studyTag = readInlineData(
    graphql`
      fragment SearchBarListSuggestionOption_studyTag on StudyTag @inline {
        name
      }
    `,
    dataItem.node,
  );

  return renderHighlighted(studyTag.name!, searchTerm!);
}

function renderExamType({
  dataItem,
  searchTerm,
}: ListOptionProps<ExamTypeItem>) {
  const examType = readInlineData(
    graphql`
      fragment SearchBarListSuggestionOption_examType on ExamType @inline {
        name
      }
    `,
    dataItem.node,
  );

  return renderHighlighted(examType.name!, searchTerm!);
}

function renderWorksheetTemplate({
  dataItem,
  searchTerm,
}: ListOptionProps<WorksheetTemplateItem>) {
  const template = readInlineData(
    graphql`
      fragment SearchBarListSuggestionOption_worksheetTemplate on WorksheetTemplate
      @inline {
        latestVersion {
          title
        }
      }
    `,
    dataItem.node,
  );

  return renderHighlighted(template.latestVersion!.title!, searchTerm!);
}

function renderOrganization({
  dataItem,
  searchTerm,
}: ListOptionProps<OrganizationItem>) {
  const organization = readInlineData(
    graphql`
      fragment SearchBarListSuggestionOption_organization on Organization
      @inline {
        name
      }
    `,
    dataItem.node,
  );

  return renderHighlighted(organization.name!, searchTerm!);
}

function renderUserProfile({
  dataItem,
  searchTerm,
}: {
  dataItem: UserProfileItem;
  searchTerm: string | undefined;
}) {
  const userProfile = readInlineData(
    graphql`
      fragment SearchBarListSuggestionOption_userProfile on UserProfile
      @inline {
        name
        ...Avatar_userProfile
      }
    `,
    dataItem.node,
  );

  return (
    <span className="flex items-center">
      <Avatar width={20} className="mr-2" userProfile={userProfile} />
      <span>{renderHighlighted(userProfile.name!, searchTerm!)}</span>
    </span>
  );
}

function renderMembership({
  dataItem,
  searchTerm,
}: ListOptionProps<DomainMembershipItem | OrganizationMembershipItem>) {
  const membership = readInlineData(
    graphql`
      fragment SearchBarListSuggestionOption_membership on UserInfoInterface
      @inline {
        userProfile {
          ...SearchBarListSuggestionOption_userProfile
        }
      }
    `,
    dataItem.node,
  );

  return renderUserProfile({
    dataItem: {
      type: 'USER_PROFILE',
      node: membership.userProfile!,
    },
    searchTerm,
  });
}

const renderByType = {
  STUDY_TAG: renderStudyTag,
  EXAM_TYPE: renderExamType,
  ORGANIZATION: renderOrganization,
  WORKSHEET_TEMPLATE: renderWorksheetTemplate,
  ORGANIZATION_MEMBERSHIP: renderMembership,
  DOMAIN_MEMBERSHIP: renderMembership,
  USER_PROFILE: renderUserProfile,
  ARCHIVE: renderArchive,
};

const optionTypes = {
  STUDY_TAG: (
    <FormattedMessage
      id="searchBarListSuggestionOption.studyTagType"
      defaultMessage="Study tag"
    />
  ),
  EXAM_TYPE: (
    <FormattedMessage
      id="searchBarListSuggestionOption.examType"
      defaultMessage="Exam type"
    />
  ),
  WORKSHEET_TEMPLATE: (
    <FormattedMessage
      id="searchBarListSuggestionOption.WORKSHEET_TEMPLATE"
      defaultMessage="Worksheet template"
    />
  ),
  USER_PROFILE: (
    <FormattedMessage
      id="searchBarListSuggestionOption.userProfile"
      defaultMessage="Study author"
    />
  ),
  ARCHIVE: (
    <FormattedMessage
      id="searchBarListSuggestionOption.archive"
      defaultMessage="Archive"
    />
  ),
  ORGANIZATION: (
    <FormattedMessage
      id="searchBarListSuggestionOption.ORGANIZATION"
      defaultMessage="Organization"
    />
  ),
};

type Props = ListOptionProps<SearchSuggestion>;

function SearchBarListSuggestionOption(props: Props) {
  const renderFn = renderByType[props.dataItem.type];

  return (
    <ListOption {...props} className="flex items-center">
      {renderFn?.(props as any)}
      <Text color="subtitle" className="ml-auto">
        {optionTypes[props.dataItem.type] || ''}
      </Text>
    </ListOption>
  );
}

export default SearchBarListSuggestionOption;
