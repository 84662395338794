/**
 * @generated SignedSource<<8e907745f37337dacae2b98e89dec0e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedUserAvatars_users$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"AvatarCollage_userProfiles" | "Avatar_userProfile" | "RelatedUsersPopoverListItems_users">;
  readonly " $fragmentType": "RelatedUserAvatars_users";
}>;
export type RelatedUserAvatars_users$key = ReadonlyArray<{
  readonly " $data"?: RelatedUserAvatars_users$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedUserAvatars_users">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RelatedUserAvatars_users",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RelatedUsersPopoverListItems_users"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarCollage_userProfiles"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_userProfile"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "1f20cd398b232b26826bc3919d0e66ce";

export default node;
