import Button from '@bfly/ui2/Button';
import FormCheck from '@bfly/ui2/FormCheck';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useToast from '@bfly/ui2/useToast';
import { decodeHandle, isUuid } from '@bfly/utils/codecs';
import getNodes from '@bfly/utils/getNodes';
import useMounted from '@restart/hooks/useMounted';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import {
  FormattedMessage,
  IntlShape,
  defineMessages,
  useIntl,
} from 'react-intl';
import { createFragmentContainer, graphql, useMutation } from 'react-relay';
import * as yup from 'yup';

import actionMessages from 'messages/actions';
import withModal from 'utils/withModal';

import { useMemberCountUpdate } from '../utils/MemberCounts';
import BulkUploadResults from './BulkUploadResults';
import { BulkDomainMemberUploadModalMutation as Mutation } from './__generated__/BulkDomainMemberUploadModalMutation.graphql';
import { BulkDomainMemberUploadModal_domain$data as Domain } from './__generated__/BulkDomainMemberUploadModal_domain.graphql';

const papaparse = import('papaparse');

const knownHeaders = [
  'email',
  'firstName',
  'middleInitial',
  'lastName',
] as const;

const EMPTY_ROWS = [{ email: '' }, { email: '' }];
// currently capping bulk user uploads to 200 users in order to prevent
// false failures from appearing in results modal ("x users are already in the system")
const MAX_UPLOAD_VALUE = 200;

export const columnNames = defineMessages({
  emailAddress: {
    id: 'domainMembersPage.emailAddress',
    defaultMessage: 'Email address',
  },
  firstName: {
    id: 'domainMembersPage.firstName',
    defaultMessage: 'First name',
  },
  middleInitial: {
    id: 'domainMembersPage.csvTitle',
    defaultMessage: 'Middle initial',
  },
  lastName: {
    id: 'domainMembersPage.lastName',
    defaultMessage: 'Last name',
  },
  membershipRole: {
    id: 'domainMembersPage.membershipRole',
    defaultMessage: 'Role: {roleName}',
  },
  interfaceCode: {
    id: 'domainMembersPage.interfaceCode',
    defaultMessage: 'interface code: {ehrNameAndId}',
    description:
      'A column header for an "interface Code" it contains the name of the system it is for as well as an id used by the application',
  },
  canQa: {
    id: 'domainMembersPage.canQa',
    defaultMessage: 'Can QA',
    description:
      'Can perform quality assurance, values: yes/true/x no/false/(empty)',
  },
  canFinalize: {
    id: 'domainMembersPage.canFinalize',
    defaultMessage: 'Can Sign',
    description: 'Can sign the studies, values: yes/true/x no/false/(empty)',
  },
  isNurse: {
    id: 'domainMembersPage.isNurse',
    defaultMessage: 'Is Nurse',
    description: 'Is a Nurse User, values: yes/true/x no/false/(empty)',
  },
});

export async function createCsvTemplate(
  ehrs: Array<{ handle: string | null; name: string | null }>,
  dicomFieldTemplates: Array<{ label: string | null }>,
  membershipRoles: Array<{
    id: string;
    name: string | null;
    isDisabled: boolean | null;
  }>,
  intl: IntlShape,
) {
  const { unparse } = await papaparse;

  return unparse(EMPTY_ROWS, {
    columns: (
      [
        intl.formatMessage(columnNames.emailAddress),
        intl.formatMessage(columnNames.firstName),
        intl.formatMessage(columnNames.middleInitial),
        intl.formatMessage(columnNames.lastName),
        ...membershipRoles.map((role) =>
          !role.isDisabled
            ? intl.formatMessage(columnNames.membershipRole, {
                roleName: role.name,
              })
            : null,
        ),
        ...ehrs.map((ehr) =>
          // The interface code column needs to include the id of the EHR in order
          // to map it back to the correct one later. I am afraid that handles would
          // require additional escaping to be used in a CSV
          intl.formatMessage(columnNames.interfaceCode, {
            ehrNameAndId: `"${ehr.name!}" | ${decodeHandle(ehr.handle!)}`,
          }),
        ),
        ...dicomFieldTemplates.map((template) => template.label!),
      ] as any
    ).filter(Boolean),
  });
}

const messages = defineMessages({
  unknownColumns: {
    id: 'bulkDomainMemberUploadModal.unknownColumns',
    defaultMessage: `
      Unknown {numColumns, plural,
        =1 {column}
        other {columns}
      }: {columnNames}
    `,
  },

  invalidEhrs: {
    id: 'bulkDomainMemberUploadModal.invalidColumns',
    defaultMessage: `
      Unrecognized EHR interface code {numColumns, plural,
        =1 {column}
        other {columns}
      }: {columnNames}
    `,
  },

  parseError: {
    id: 'bulkDomainMemberUploadModal.parseError',
    defaultMessage:
      'There was an error parsing the CSV. It may be incorrectly exported or formatted.',
  },
  missingEmails: {
    id: 'bulkDomainMemberUploadModal.missingEmails',
    defaultMessage: `Missing email addresses for {numMissing} {numMissing, plural,
      =1 {row}
      other {rows}
    }.`,
  },
  invalidEmails: {
    id: 'bulkDomainMemberUploadModal.invalidEmails',
    defaultMessage: `Invalid email addresses for {numInvalid} {numInvalid, plural,
      =1 {row}
      other {rows}
    }.`,
  },
  invalidCanQa: {
    id: 'bulkDomainMemberUploadModal.invalidCanQa',
    defaultMessage: `Can Qa field should be yes/true/x no/false/(empty) for {numInvalidCanQa} {numInvalidCanQa, plural,
      =1 {row}
      other {rows}
    }.`,
  },
  invalidCanFinalize: {
    id: 'bulkDomainMemberUploadModal.invalidCanFinalize',
    defaultMessage: `Can Sign field should be yes/true/x no/false/(empty) for {numInvalidCanFinalize} {numInvalidCanFinalize, plural,
      =1 {row}
      other {rows}
    }.`,
  },
  invalidIsNurse: {
    id: 'bulkDomainMemberUploadModal.invalidIsNurse',
    defaultMessage: `Is Nurse field should be yes/true/x no/false/(empty) for {numInvalidIsNurse} {numInvalidIsNurse, plural,
      =1 {row}
      other {rows}
    }.`,
  },
  invalidMembershipRole: {
    id: 'bulkDomainMembershipUploadModal.invalidMembershipRole',
    defaultMessage: `Role Field should be yes/true/x no/false/(empty) for {numInvalidMembershipRole} {numInvalidMembershipRole, plural,
      =1 {row}
      other {rows}
    }.`,
  },
  multipleMembershipRolesAssigned: {
    id: 'bulkDomainMembershipUploadModal.multipleMembershipRolesAssigned',
    defaultMessage:
      'Only one Butterfly Access Role should be assigned per person. Use True, X, Yes to specify.',
  },
  noMembershipRoleAssigned: {
    id: 'bulkDomainMembershipUploadModal.noMembershipRoleAssigned',
    defaultMessage: 'Users must be assigned a role.',
  },
  maxSeatsExceeded: {
    id: 'bulkDomainMembershipUploadModal.maxSeatsExceeded',
    defaultMessage:
      'Cannot complete upload. CSV contains more users than there are available seats. Please re-upload a CSV with {numAvailableSeats} or fewer users or upgrade your plan to add additional seats.',
  },
  maxUploadsExceeded: {
    id: 'bulkDomainMembershipUploadModal.maxUploadsExceeded',
    defaultMessage:
      'CSV contains more than 200 users. Please re-upload a CSV with 200 or fewer users to avoid timeouts.',
  },
  duplicateDicomFields: {
    id: 'bulkDomainMembershipUploadModal.duplicateDicomFields',
    defaultMessage: `Duplicated dicom field values for the following {numDuplicateDicomFields} emails: {duplicateDicomFieldsList}`,
  },
});

interface Props {
  onHide: () => void;
  domain: Domain;
  file: File | null;
}

const emailSchema = yup.string().email().required();

const mutation = graphql`
  mutation BulkDomainMemberUploadModalMutation(
    $input: BulkCreateDomainUsersInput!
  ) {
    bulkCreateDomainUsers(input: $input) {
      users {
        edges {
          node {
            id
            ...DomainMemberSettingsPage_domainUser
          }
        }
      }
      failures {
        ...BulkUploadResults_failures
      }
      undeletedUsers {
        edges {
          node {
            id
            ...BulkUploadResults_undeletedUsers
          }
        }
      }
    }
  }
`;

const truthyBooleans = ['yes', 'true', 'x'];

const falsyBooleans = ['no', 'false', ''];

const csvBooleans = [...truthyBooleans, ...falsyBooleans];

function parseBoolean(csvBoolean: string) {
  const normalizedCsvBoolean = csvBoolean.toString().toLowerCase().trim();

  if (truthyBooleans.includes(normalizedCsvBoolean)) {
    return true;
  }

  if (falsyBooleans.includes(normalizedCsvBoolean)) {
    return false;
  }

  return normalizedCsvBoolean;
}

function isValidBoolean(value: string | boolean) {
  return csvBooleans.includes(value.toString().toLowerCase().trim());
}

function setRoleId(user) {
  let numInvalidBooleans = 0;
  let numSelectedRoles = 0;
  let selectedRoleId = '';

  for (const role of user.membershipRoles) {
    if (!isValidBoolean(role.value)) {
      numInvalidBooleans++;
    } else if (role.value) {
      selectedRoleId = role.roleId;
      numSelectedRoles++;
    }
  }

  user.roleId = selectedRoleId;

  return { numInvalidBooleans, numSelectedRoles };
}

function handleDuplicateDicomFields(
  user,
  duplicateDicomFields: Map<string, string>,
  domainUserDicomFields: Record<string, string>,
) {
  const { dicomFields, email } = user;
  let numDuplicates = 0;
  dicomFields.forEach((field) => {
    const fieldKey = field.templateId + field.value;
    if (domainUserDicomFields[fieldKey]) {
      numDuplicates += 1;
      if (!duplicateDicomFields.has(domainUserDicomFields[fieldKey])) {
        duplicateDicomFields.set(domainUserDicomFields[fieldKey], fieldKey);
        numDuplicates += 1;
      }
      duplicateDicomFields.set(email, fieldKey);
    }
    domainUserDicomFields[fieldKey] = email;
  });
  return { numDuplicates };
}

function deserializeResults(
  results,
  ehrUuidToId: Map<string, string>,
  dicomTemplateLabeltoUuid: Map<string, string>,
  membershipRoleToId: Map<string, string>,
) {
  return results.data
    .map(
      ({
        email,
        firstName,
        middleInitial,
        lastName,
        canQa,
        canFinalize,
        isNurse,
        ...otherFields
      }) => {
        return {
          email: email.trim(),
          integrationDisplayNameFirst: firstName,
          integrationDisplayNameMiddle: middleInitial,
          integrationDisplayNameLast: lastName,
          canQa: canQa == null ? false : parseBoolean(canQa),
          canFinalize: canFinalize == null ? false : parseBoolean(canFinalize),
          isNurse: isNurse == null ? false : parseBoolean(isNurse),
          integrationConfigs: Object.entries(otherFields)
            .filter(
              ([uuid, interfaceCode]) =>
                interfaceCode && ehrUuidToId.has(uuid),
            )
            .map(([ehrUuid, interfaceCode]: [string, string]) => ({
              interfaceCode,
              integrationId: ehrUuidToId.get(ehrUuid)!,
            })),
          dicomFields: Object.entries(otherFields)
            .filter(
              ([templateLabel, value]) =>
                value && dicomTemplateLabeltoUuid.has(templateLabel),
            )
            .map(([templateLabel, value]: [string, string]) => ({
              value,
              templateId: dicomTemplateLabeltoUuid.get(templateLabel)!,
            })),
          // deserializing all the roles columns here, membershipRole values will be
          // checked and finalized as a single roleId later in useCsvData
          membershipRoles: Object.entries(otherFields)
            .filter(([roleName, _]) => membershipRoleToId.has(roleName))
            .map(([roleName, value]: [string, string]) => ({
              value: parseBoolean(value),
              roleId: membershipRoleToId.get(roleName),
            })),
        };
      },
    )
    .filter(
      // filter out blanks
      (v) =>
        v.email ||
        v.integrationConfigs.length ||
        v.membershipRoles.length ||
        v.integrationDisplayNameFirst ||
        v.integrationDisplayNameLast ||
        v.integrationDisplayNameMiddle ||
        v.canQa ||
        v.canFinalize ||
        v.isNurse,
    );
}

function useCsvData(
  csv: File | null,
  ehrs: any[],
  dicomTemplates: any[],
  membershipRoles: any[],
  allowedColumns: Set<string>,
  numAvailableSeats: number | null,
) {
  type State = {
    users: any[] | null;
    errors: string[] | null;
    loading: boolean;
  };

  const isMounted = useMounted();
  const intl = useIntl();

  const [state, setState] = useState<State>({
    users: null,
    errors: null,
    loading: true,
  });

  useEffect(() => {
    if (!csv) return;

    const ehrUuidToId = new Map<string, string>(
      ehrs.map((ehr) => [decodeHandle(ehr.handle!)!, ehr.id!]),
    );

    const dicomTemplateLabeltoUuid = new Map<string, string>(
      dicomTemplates.map((template) => [template.label!, template.id!]),
    );

    const membershipRoleToId = new Map<string, string>(
      membershipRoles.map((role) => [role.name, role.id]),
    );

    const badColumns = [] as string[];
    const invalidEhrs = [] as string[];

    papaparse.then(({ parse }) => {
      if (!isMounted()) return;

      const columnMapping = new Map<string, typeof knownHeaders[number]>([
        [intl.formatMessage(columnNames.emailAddress).toLowerCase(), 'email'],
        [intl.formatMessage(columnNames.firstName).toLowerCase(), 'firstName'],
        [
          intl.formatMessage(columnNames.middleInitial).toLowerCase(),
          'middleInitial',
        ],
        [intl.formatMessage(columnNames.lastName).toLowerCase(), 'lastName'],
      ]);

      parse(csv, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => {
          // check if it's a dicomTemplate column
          const isDicomTemplate = dicomTemplateLabeltoUuid.has(header);
          if (isDicomTemplate) return header;

          const maybeId = header.split(' | ').pop() || '';
          // check if it's definiately a EHR column or might be one
          // to provide more specific errors if it's not valid
          const isEhr =
            isUuid(maybeId) || header.toLowerCase().includes('interface code');

          if (isEhr) {
            if (!ehrUuidToId.has(maybeId)) {
              invalidEhrs.push(header);
            }
            return maybeId;
          }

          // check if it is a membershipRole column
          const isMembershipRole = header.includes('Role:');
          if (isMembershipRole) {
            const maybeRole = header.split(': ').pop() || '';
            if (membershipRoleToId.has(maybeRole)) {
              return maybeRole;
            }
          }

          const parsed = columnMapping.get(header.toLowerCase());

          if (!parsed || !allowedColumns.has(parsed)) {
            badColumns.push(header);
            return header;
          }
          return parsed;
        },
        complete(results) {
          if (!isMounted()) return;

          if (invalidEhrs.length || badColumns.length) {
            const errors = [] as string[];
            if (badColumns.length) {
              errors.push(
                intl.formatMessage(messages.unknownColumns, {
                  numColumns: badColumns.length,
                  columnNames: badColumns.join(', '),
                }),
              );
            }
            if (invalidEhrs.length) {
              errors.push(
                intl.formatMessage(messages.invalidEhrs, {
                  numColumns: invalidEhrs.length,
                  columnNames: invalidEhrs.join(', '),
                }),
              );
            }

            setState({
              users: null,
              loading: false,
              errors,
            });
            return;
          }

          const users = deserializeResults(
            results,
            ehrUuidToId,
            dicomTemplateLabeltoUuid,
            membershipRoleToId,
          );
          let numMissing = 0;
          let numInvalid = 0;
          let numInvalidCanQa = 0;
          let numInvalidCanFinalize = 0;
          let numInvalidIsNurse = 0;
          let numInvalidMembershipRole = 0;
          let numMultipleMembershipRolesAssigned = 0;
          let numNoMembershipRoleAssigned = 0;
          let maxSeatsExceeded = false;
          let maxUploadsExceeded = false;
          let numDuplicateDicomFields = 0;
          const domainUserDicomFields = {};
          const duplicateDicomFields = new Map();

          for (const user of users) {
            if (!user.email) numMissing++;
            else if (!emailSchema.isValidSync(user.email)) numInvalid++;
            if (!isValidBoolean(user.canQa)) numInvalidCanQa++;
            if (!isValidBoolean(user.canFinalize)) numInvalidCanFinalize++;
            if (!isValidBoolean(user.isNurse)) numInvalidIsNurse++;

            // process membership roles
            if (user.membershipRoles.length) {
              const { numInvalidBooleans, numSelectedRoles } = setRoleId(user);
              if (numInvalidBooleans) numInvalidMembershipRole++;
              if (numSelectedRoles > 1) numMultipleMembershipRolesAssigned++;
              if (numSelectedRoles === 0) numNoMembershipRoleAssigned++;
            }
            delete user.membershipRoles;

            if (user.dicomFields.length) {
              const { numDuplicates } = handleDuplicateDicomFields(
                user,
                duplicateDicomFields,
                domainUserDicomFields,
              );
              numDuplicateDicomFields += numDuplicates;
            }
          }
          const duplicateDicomFieldsList = [
            ...duplicateDicomFields.keys(),
          ].join(', ');

          if (numAvailableSeats != null && users.length > numAvailableSeats) {
            maxSeatsExceeded = true;
          }

          if (users.length > MAX_UPLOAD_VALUE) {
            maxUploadsExceeded = true;
          }

          const isSomethingInvalid =
            numMissing ||
            numInvalid ||
            numInvalidCanQa ||
            numInvalidCanFinalize ||
            numInvalidIsNurse ||
            numInvalidMembershipRole ||
            numMultipleMembershipRolesAssigned ||
            numNoMembershipRoleAssigned ||
            maxSeatsExceeded ||
            maxUploadsExceeded ||
            numDuplicateDicomFields;
          if (isSomethingInvalid) {
            setState({
              users,
              loading: false,
              errors: [
                numMissing &&
                  intl.formatMessage(messages.missingEmails, { numMissing }),
                numInvalid &&
                  intl.formatMessage(messages.invalidEmails, { numInvalid }),
                numInvalidCanQa &&
                  intl.formatMessage(messages.invalidCanQa, {
                    numInvalidCanQa,
                  }),
                numInvalidCanFinalize &&
                  intl.formatMessage(messages.invalidCanFinalize, {
                    numInvalidCanFinalize,
                  }),
                numInvalidIsNurse &&
                  intl.formatMessage(messages.invalidIsNurse, {
                    numInvalidIsNurse,
                  }),
                numInvalidMembershipRole &&
                  intl.formatMessage(messages.invalidMembershipRole, {
                    numInvalidMembershipRole,
                  }),
                numMultipleMembershipRolesAssigned &&
                  intl.formatMessage(messages.multipleMembershipRolesAssigned),
                numNoMembershipRoleAssigned &&
                  intl.formatMessage(messages.noMembershipRoleAssigned),
                maxSeatsExceeded &&
                  intl.formatMessage(messages.maxSeatsExceeded, {
                    numAvailableSeats,
                  }),
                maxUploadsExceeded &&
                  intl.formatMessage(messages.maxUploadsExceeded),
                numDuplicateDicomFields &&
                  intl.formatMessage(messages.duplicateDicomFields, {
                    numDuplicateDicomFields,
                    duplicateDicomFieldsList,
                  }),
              ].filter((v): v is string => !!v),
            });
            return;
          }

          setState({ users, errors: null, loading: false });
        },
        error() {
          if (!isMounted()) return;

          setState({
            users: null,
            errors: [intl.formatMessage(messages.parseError)],
            loading: false,
          });
        },
      });
    });
  }, [
    csv,
    ehrs,
    dicomTemplates,
    membershipRoles,
    isMounted,
    intl,
    allowedColumns,
    numAvailableSeats,
  ]);

  return state;
}

const invalid = 'bg-red/20';

function BulkDomainMemberUploadModal({ domain, file, ...props }: Props) {
  const memberCountUpdate = useMemberCountUpdate();

  const [result, setResult] = useState<any>(null);

  const toast = useToast();
  const ehrs = useMemo(
    () => getNodes(domain.ehrConnection),
    [domain.ehrConnection],
  );
  const dicomFieldTemplates = useMemo(
    () => getNodes(domain.dicomFieldTemplateConnection),
    [domain.dicomFieldTemplateConnection],
  );
  const membershipRoles = useMemo(
    () => getNodes(domain.membershipRoles).filter((role) => !role.isDisabled),
    [domain.membershipRoles],
  );

  const [mutate, mutationLoading] = useMutation<Mutation>(mutation);

  // TODO: What is this for? Do we need useMemo here?
  const allowedColumns = new Set(knownHeaders);

  const { numAvailableSeats } = domain;

  const { users, loading, errors } = useCsvData(
    file,
    ehrs,
    dicomFieldTemplates,
    membershipRoles,
    allowedColumns,
    numAvailableSeats,
  );

  const detailsSpan = allowedColumns.size;

  if (result) {
    return <BulkUploadResults {...result} onHide={props.onHide} />;
  }

  function handleUploadUsers() {
    mutate({
      variables: { input: { users: users!, domainId: domain.id } },
      onError() {
        toast?.error(
          <FormattedMessage
            id="bulkDomainMemberUploadModal.toastError"
            defaultMessage="An unexpected error occurred."
          />,
        );
      },
      onCompleted({ bulkCreateDomainUsers }) {
        const hasUndeletedUsers =
          !!bulkCreateDomainUsers!.undeletedUsers!.edges!.length;
        if (!bulkCreateDomainUsers!.failures!.length && !hasUndeletedUsers) {
          props.onHide();
          memberCountUpdate();
          toast?.success(
            <FormattedMessage
              id="bulkDomainMemberUploadModal.toastSuccess"
              defaultMessage="{numCreated} new users added."
              values={{
                // 0 gets ignored if not a string
                numCreated: String(
                  bulkCreateDomainUsers!.users!.edges!.length,
                ),
              }}
            />,
          );
          return;
        }

        memberCountUpdate();
        setResult({
          failures: bulkCreateDomainUsers!.failures!,
          undeletedUsers: getNodes(bulkCreateDomainUsers!.undeletedUsers!),
          numAdded: bulkCreateDomainUsers!.users!.edges!.length,
        });
      },
    });
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="bulkDomainMemberUploadModal.title"
            defaultMessage="Confirm upload"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <LoadingIndicator />}
        <Text as="p">
          <FormattedMessage
            id="bulkDomainMemberUploadModal.body"
            defaultMessage="Note: Any CSV rows containing email addresses that already exist in the system will be ignored."
          />
        </Text>
        <Text as="p">
          <FormattedMessage
            id="bulkDomainMemberUploadModal.bodyReject"
            defaultMessage="Any CSV containing more than 200 users or containing duplicated dicom field values will be rejected."
          />
        </Text>
        <Text as="p">
          <FormattedMessage
            id="bulkDomainMemberUploadModal.bodyInactiveUsers"
            defaultMessage="Any existing inactive users will be reactivated."
          />
        </Text>
        {errors && (
          <ul className="p-0 mb-4">
            {errors.map((error, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i} className="text-sm text-danger">
                {error}
              </li>
            ))}
          </ul>
        )}
        {/* XXX: this is mostly the same as the new DataGrid stuff, we need to combine them into a reusable component */}
        {users && (
          <table
            css={css`
              composes: scrollable-y border-b border-divider bg-white from global;

              overflow: auto;
              max-height: 35rem;
              display: grid;
              grid-template-columns: auto repeat(
                  ${detailsSpan +
                  membershipRoles.length +
                  ehrs.length +
                  dicomFieldTemplates.length},
                  minmax(min-content, 200px)
                );

              thead,
              tbody,
              tr {
                display: contents;
              }

              tr:last-of-type > th,
              tr:not(:last-of-type) > td {
                @apply border-b border-black/10;
              }

              th {
                @apply sticky top-0 text-sm text-subtitle bg-white;

                z-index: 1;
              }
              td,
              th {
                @apply py-2 px-3 truncate;
              }
            `}
          >
            <thead>
              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th />
                <th
                  css={`
                    grid-column: span ${detailsSpan};
                  `}
                >
                  <FormattedMessage
                    id="bulkDomainMemberUploadModal.details"
                    defaultMessage="Details"
                  />
                </th>
                {!!membershipRoles.length && (
                  <th
                    css={css`
                      grid-column: span ${membershipRoles.length};
                    `}
                  >
                    <FormattedMessage
                      id="bulkDomainMemberUploadModal.membershipRoles"
                      defaultMessage="Membership Roles"
                    />
                  </th>
                )}
                {!!ehrs.length && (
                  <th
                    css={css`
                      grid-column: span ${ehrs.length};
                    `}
                  >
                    <FormattedMessage
                      id="bulkDomainMemberUploadModal.interfaceCodes"
                      defaultMessage="Interface codes"
                    />
                  </th>
                )}
                {!!dicomFieldTemplates.length && (
                  <th
                    css={css`
                      grid-column: span ${dicomFieldTemplates.length};
                    `}
                  >
                    <FormattedMessage
                      id="bulkDomainMemberUploadModal.dicomFieldMappings"
                      defaultMessage="Dicom field mappings"
                    />
                  </th>
                )}
              </tr>

              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th />
                <th>
                  <FormattedMessage {...columnNames.emailAddress} />
                </th>
                <th>
                  <FormattedMessage {...columnNames.firstName} />
                </th>
                <th>
                  <FormattedMessage {...columnNames.middleInitial} />
                </th>
                <th>
                  <FormattedMessage {...columnNames.lastName} />
                </th>
                {membershipRoles.map((role) => (
                  <th key={role.id}>{role.name}</th>
                ))}
                {ehrs.map((ehr) => (
                  <th key={ehr.id}>{ehr.name}</th>
                ))}
                {dicomFieldTemplates.map((template) => (
                  <th key={template.id}>{template.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((user, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={idx}>
                  <td>
                    <Text color="subtitle">{idx + 1}</Text>
                  </td>
                  <td
                    className={clsx(
                      errors &&
                        !emailSchema.isValidSync(user.email) &&
                        invalid,
                    )}
                  >
                    {user.email}
                  </td>
                  <td>{user.integrationDisplayNameFirst}</td>
                  <td>{user.integrationDisplayNameMiddle}</td>
                  <td>{user.integrationDisplayNameLast}</td>
                  {membershipRoles.map((role) => (
                    <td key={role.id}>
                      <FormCheck
                        disabled
                        className="justify-center"
                        checked={role.id === user.roleId}
                      />
                    </td>
                  ))}
                  {ehrs.map((ehr) => (
                    <td key={ehr.id}>
                      {
                        user.integrationConfigs.find(
                          (c) => c.integrationId === ehr.id,
                        )?.interfaceCode
                      }
                    </td>
                  ))}
                  {dicomFieldTemplates.map((template) => (
                    <td key={template.id}>
                      {
                        user.dicomFields.find(
                          (c) => c.templateId === template.id,
                        )?.value
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Modal.ButtonGroup>
          {errors || !users?.length ? (
            <Button
              onClick={props.onHide}
              busy={mutationLoading}
              data-bni-id="BulkDomainMemberUploadModalClose"
            >
              <FormattedMessage {...actionMessages.close} />
            </Button>
          ) : (
            users?.length && (
              <>
                <Button
                  onClick={handleUploadUsers}
                  busy={mutationLoading}
                  data-bni-id="BulkDomainMemberUploadModalConfirm"
                >
                  <FormattedMessage {...actionMessages.confirm} />
                </Button>
                <Button variant="secondary" onClick={props.onHide}>
                  <FormattedMessage {...actionMessages.cancel} />
                </Button>
              </>
            )
          )}
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(
  withModal(BulkDomainMemberUploadModal, { variant: 'dark' }),
  {
    domain: graphql`
      fragment BulkDomainMemberUploadModal_domain on Domain {
        id
        numAvailableSeats
        ehrConnection(first: 2147483647)
          @connection(key: "Domain_ehrConnection") {
          edges {
            node {
              id
              handle
              name
            }
          }
        }
        dicomFieldTemplateConnection(first: 2147483647)
          @connection(key: "Domain_dicomFieldTemplateConnection") {
          edges {
            node {
              id
              handle
              label
            }
          }
        }
        membershipRoles(
          sort: NAME_ASC
          roleType: [SYSTEM_DEFINED, USER_DEFINED]
        ) {
          edges {
            node {
              id
              name
              isDisabled
            }
          }
        }
      }
    `,
  },
);
