/**
 * @generated SignedSource<<e9032eb9b09e69c3aa5bbb26f37a56de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudySource = "BUTTERFLY" | "THIRD_PARTY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SplitStudyRefreshModalSelectContent_sourceStudy$data = {
  readonly id: string;
  readonly numImages: number | null;
  readonly organization: {
    readonly handle: string | null;
    readonly id: string;
  } | null;
  readonly source: StudySource | null;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyArchiveField_sourceStudy" | "SplitStudyAuthorField_sourceStudy" | "SplitStudyExamTypeDropdown_study" | "SplitStudyPatientField_sourceStudy" | "SplitStudyTagsField_sourceStudy" | "SplitStudyWorksheetsField_sourceStudy" | "StudyCaptureSelector_study">;
  readonly " $fragmentType": "SplitStudyRefreshModalSelectContent_sourceStudy";
};
export type SplitStudyRefreshModalSelectContent_sourceStudy$key = {
  readonly " $data"?: SplitStudyRefreshModalSelectContent_sourceStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyRefreshModalSelectContent_sourceStudy">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyRefreshModalSelectContent_sourceStudy",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyCaptureSelector_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitStudyTagsField_sourceStudy"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitStudyExamTypeDropdown_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitStudyWorksheetsField_sourceStudy"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitStudyPatientField_sourceStudy"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitStudyAuthorField_sourceStudy"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SplitStudyArchiveField_sourceStudy"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "38f2cc70695a7a1f9b2f638cbb4ad15c";

export default node;
