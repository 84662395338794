import DragHandlesIcon from '@bfly/icons/DragHandles';
import useWindow from '@bfly/ui2/useWindow';
import {
  Active,
  DndContext,
  DndContextProps,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useDndContext,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  SortableContextProps,
  arrayMove,
  arraySwap,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import clsx from 'clsx';
import React, { ReactNode, createContext, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';

export { arrayMove, arraySwap };

interface SortableListProps
  extends SortableContextProps,
    Omit<
      React.ComponentPropsWithoutRef<'div'>,
      'children' | 'onDragEnd' | 'onDragOver' | 'onDragStart'
    > {
  allowDropFromOutside?: boolean;
  as?: React.ElementType;
}

function SortableList({
  allowDropFromOutside,
  children,
  items,
  strategy,
  id,
  as: Tag = 'div',
  ...props
}: SortableListProps) {
  const { setNodeRef } = useDroppable({
    id: `${id}/container`,
    disabled: !allowDropFromOutside,
  });

  return (
    <SortableContext id={id} items={items} strategy={strategy}>
      <Tag ref={setNodeRef} {...props}>
        {children}
      </Tag>
    </SortableContext>
  );
}

const SortableItemContext = createContext<null | any>(null);

export interface DragAction {
  isComplete: boolean;
  destination?: {
    droppableId?: string;
    index?: number;
    id: string;
  };
  source: {
    droppableId?: string;
    index?: number;
    id: string;
  };
}

interface SortableDndContextProps extends DndContextProps {
  children?: ReactNode;
  onDragAction?: (DragAction) => void;
}

export function SortableDndContext({
  children,
  onDragAction,
  ...props
}: SortableDndContextProps & any) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragAction(
    isComplete: boolean,
    result: DragEndEvent | DragOverEvent,
  ) {
    if (!onDragAction) return;

    const { active: source, over } = result;

    const sourceDroppableId = source.data.current?.sortable?.containerId;
    const overDroppableId =
      over?.data.current?.sortable?.containerId ||
      over?.id.split('/container')[0];

    if (
      !over ||
      source.id === over.id ||
      (!isComplete && sourceDroppableId === overDroppableId)
    ) {
      return;
    }

    onDragAction({
      isComplete,
      source: {
        id: source.id,
        index: source.data.current?.sortable?.index,
        droppableId: sourceDroppableId,
      },
      destination: {
        id: over.id,
        index: over.data.current?.sortable.index || 0,
        droppableId: overDroppableId,
      },
    });
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      {...props}
      onDragStart={(e) => {
        props.onDragStart?.(e);
      }}
      onDragOver={(e) => {
        handleDragAction(false, e);
      }}
      onDragEnd={(e) => {
        props.onDragEnd?.(e);
        handleDragAction(true, e);
      }}
      onDragCancel={(e) => {
        props.onDragCancel?.(e);
      }}
    >
      {children}
    </DndContext>
  );
}

export function SortableItem({
  id,
  as: Tag = 'div',
  draggingClassName,
  data,
  children,
  ...props
}: any) {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, data });

  const value = useMemo(
    () => ({ listeners, attributes, isDragging }),
    [attributes, listeners, isDragging],
  );

  const sortableProps = {
    ref: setNodeRef,
    style: transform
      ? {
          ...props.style,
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          transition,
        }
      : undefined,
    className: clsx(
      props.className,
      isDragging && 'cursor-[grab]',
      isDragging && draggingClassName,
    ),
  };

  return (
    <SortableItemContext.Provider value={value}>
      {typeof children === 'function' ? (
        children({
          isDragging,
          handleProps: { ...attributes, ...listeners },
          props: sortableProps,
        })
      ) : (
        <Tag {...props} {...sortableProps}>
          {children}
        </Tag>
      )}
    </SortableItemContext.Provider>
  );
}

export function SortableDragOverlay({
  className,
  style,
  children,
}: {
  className?: string;
  style?: any;
  children: ((active: Active) => ReactNode) | ReactNode;
}) {
  const window = useWindow();
  const { active } = useDndContext();

  return (
    <>
      {active &&
        window &&
        createPortal(
          <DragOverlay style={style} className={className}>
            {typeof children === 'function' ? children(active) : children}
          </DragOverlay>,
          window!.document.body,
        )}
    </>
  );
}

export function SortableDragHandle({
  children,
  ...props
}: React.ComponentPropsWithoutRef<'button'>) {
  const { listeners, attributes, isDragging } =
    useContext(SortableItemContext) || {};

  return (
    <button
      type="button"
      {...props}
      {...attributes}
      {...listeners}
      className={clsx(
        props.className,
        attributes?.className,
        'focus-visible:ring',
        isDragging ? 'cursor-[grabbing]' : 'cursor-[grab]',
      )}
    >
      {children || <DragHandlesIcon />}
    </button>
  );
}

export default Object.assign(SortableList, {
  Item: SortableItem,
  DragHandle: SortableDragHandle,
});
