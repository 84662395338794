import { usePermissions } from 'utils/viewerContext';

export default function useArchiveSettings() {
  const { hasBasicPermission } = usePermissions();

  return (
    hasBasicPermission('archiveManagement') ||
    hasBasicPermission('connectivityManagement') ||
    hasBasicPermission('studyDocumentationManagement') ||
    hasBasicPermission('userManagement') ||
    hasBasicPermission('bulkDataExport')
  );
}
