import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Text from '@bfly/ui2/Text';
import { Link, LocationDescriptor } from 'found';
import { FormattedMessage } from 'react-intl';

interface Props {
  buttonText: string;
  to: LocationDescriptor;
}

function AlternativeLoginSection({ buttonText, to }: Props) {
  return (
    <section data-bni-id="AlternativeLoginSection">
      <Layout align="center">
        <hr className="w-full border-b border-grey-50 my-0" />
        <Text className="m-4">
          <FormattedMessage
            id="login.alternative.section.text"
            defaultMessage="OR"
          />
        </Text>
        <hr className="w-full border-b border-grey-50 my-0" />
      </Layout>
      <Link
        as={Button}
        variant="secondary"
        size="lg"
        className="w-full"
        to={to}
      >
        {buttonText}
      </Link>
    </section>
  );
}

export default AlternativeLoginSection;
