import { graphql, readInlineData } from 'react-relay';
import type { Environment } from 'relay-runtime';
import { InferType, SchemaOf, date, mixed, object, string } from 'yup';

import asyncCommitMutation from 'utils/asyncCommitMutation';

import { baseStudyInfo_selectedWorklistItemData$key as WorklistItemKey } from './__generated__/baseStudyInfo_selectedWorklistItemData.graphql';
import { humanStudyInfoUpdateMutation as HumanStudyInfoUpdateMutation } from './__generated__/humanStudyInfoUpdateMutation.graphql';
import { humanStudyInfo_study$key as StudyKey } from './__generated__/humanStudyInfo_study.graphql';
import studyInfoSchema, {
  dicomString,
  formatWorklistItem,
  nameField,
} from './baseStudyInfo';

export const formatWorklistHumanStudy = (worklistItemRef: WorklistItemKey) => {
  return formatWorklistItem(worklistItemRef);
};

const flattenPatient = (value) => {
  if (!value) return value;
  const { patient, ...rest } = value;
  return Object.assign(rest, patient);
};

const unflattenPatient = ({
  nameFirst,
  nameMiddle,
  nameLast,
  namePrefix,
  nameSuffix,
  sex,
  birthDate,
  medicalRecordNumber,
  internalId,
  ...rest
}) =>
  Object.assign(rest, {
    patient: rest.patient || {
      nameFirst,
      nameMiddle,
      namePrefix,
      nameSuffix,
      nameLast,
      sex,
      birthDate,
      medicalRecordNumber,
      internalId,
    },
  });

export const Sex = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
  UNKNOWN: 'UNKNOWN',
};

const patientFields = {
  nameFirst: nameField(64),
  nameMiddle: nameField(64),
  namePrefix: nameField(16),
  nameSuffix: nameField(16),
  nameLast: nameField(64),
  sex: string()
    .enum<keyof typeof Sex | null>([...Object.values(Sex), null] as any, null)
    .nullable(),
  medicalRecordNumber: dicomString(64).nullable(),
  internalId: string().nullable(),
  birthDate: date().nullable(),
};

export interface HumanPatientFields {
  nameFirst: string | undefined;
  nameMiddle: string | undefined;
  namePrefix: string | undefined;
  nameSuffix: string | undefined;
  nameLast: string | undefined;
  sex: keyof typeof Sex | null;
  medicalRecordNumber: string | null | undefined;
  internalId: string | null | undefined;
  birthDate: Date | null | undefined;
}

export const humanPatientSchema: SchemaOf<HumanPatientFields> =
  object(patientFields);

export const schema = studyInfoSchema.shape(patientFields);

const humanStudyInfoInput = studyInfoSchema
  .shape({
    patient: object({
      ...patientFields,
      birthDate: string().nullable().isoDate(),
    }),
    dicomDocument: mixed().nullable(),
  })
  .transform(unflattenPatient);

const fragment = graphql`
  fragment humanStudyInfo_study on Study @inline {
    dicomDocument
    accessionNumber
    hasAssociatedWorklistItem
    sourceWorklistItem {
      ... on Node {
        id
      }
    }
    patient {
      ...baseStudyInfo_patient @relay(mask: false)
    }
    ...baseStudyInfo_study @relay(mask: false)
  }
`;

export function hasPatientInfo(studyRef: StudyKey) {
  const { accessionNumber, studyDescription, patient } = readInlineData(
    fragment,
    studyRef,
  );

  return (
    !!accessionNumber ||
    !!studyDescription ||
    Object.values(patient!).every(Boolean)
  );
}

export const deserialize = (studyRef: StudyKey) => {
  const study = readInlineData(fragment, studyRef);

  const { patient: _p, ...result } = (
    study
      ? schema
          .transform(flattenPatient)
          .from('sourceWorklistItem.id', 'sourceWorklistItemId')
          .cast({ ...study })
      : schema.getDefault()
  ) as InferType<typeof schema> & { patient?: unknown };

  return result;
};

export const serialize = (value: any) => {
  return humanStudyInfoInput.cast(value, { stripUnknown: true });
};

const updateStudyMutation = graphql`
  mutation humanStudyInfoUpdateMutation($input: UpdateStudyInfoInput!) {
    updateStudyInfo(input: $input) {
      study {
        ...humanStudyInfo_study
      }
    }
  }
`;

export function clearPatient(studyId: string, relay: Environment) {
  return asyncCommitMutation<HumanStudyInfoUpdateMutation>(relay, {
    mutation: updateStudyMutation,
    variables: {
      input: {
        studyId,
        patient: {
          nameFirst: '',
          nameMiddle: '',
          nameLast: '',
          birthDate: null,
          medicalRecordNumber: '',
          namePrefix: '',
          nameSuffix: '',
          sex: null,
          internalId: null,
        },
        // TODO: why don't we null out acc no and study desc?
        accessionNumber: '',
        sourceWorklistItemId: null,
        dicomDocument: {},
        studyDescription: '',
      },
    },
  });
}

export function associateWithWorklistItem(
  studyId: string,
  worklistItem: WorklistItemKey,
  relay: Environment,
) {
  return asyncCommitMutation<HumanStudyInfoUpdateMutation>(relay, {
    mutation: updateStudyMutation,
    variables: {
      input: {
        studyId,
        ...serialize(formatWorklistHumanStudy(worklistItem)),
      },
    },
  });
}
