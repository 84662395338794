import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';

import actionMessages from 'messages/actions';

interface Props {
  setShowConfirmDelete: (boolean) => void;
  formValue: Record<string, any>;
}

function DeleteCapturesConfirmDelete({
  formValue,
  setShowConfirmDelete,
}: Props) {
  const numCaptures = formValue.imageIds.length;

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="deleteCapturesConfirmDelete.title"
            defaultMessage="Delete {numCaptures} {numCaptures, plural, =1 {capture} other {captures}}?"
            values={{ numCaptures }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text>
          <FormattedMessage
            id="deleteCapturesConfirmDelete.confirm"
            defaultMessage="Are you sure? This cannot be undone."
          />
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Form.Submit id="confirm-delete" variant="danger" size="lg">
            <FormattedMessage {...actionMessages.delete} />
          </Form.Submit>
          <Button
            size="lg"
            variant="secondary"
            onClick={() => setShowConfirmDelete(false)}
          >
            <FormattedMessage {...actionMessages.back} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default DeleteCapturesConfirmDelete;
