import CopyToClipboardControl from '@bfly/ui2/CopyToClipboardControl';
import type { Fetcher } from '@graphiql/toolkit';
import { GraphiQL } from 'graphiql';
import { useState } from 'react';

import podTargetingHeaders from 'utils/podTargetingHeaders';

import './playground.css';

// TODO: Olympus-Organization header: 'slug {org slug}'
// TODO: make sure router org slug and local storage org slug match, if no router org slug, take local storage org slug
const Playground = ({ accessToken }) => {
  const podOverrides = podTargetingHeaders.get();
  for (const key of Object.keys(podOverrides)) {
    if (podOverrides[key] === null) {
      delete podOverrides[key];
    }
  }

  const [headers, setHeaders] = useState({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `JWT ${accessToken}`,
    ...podOverrides,
  });

  const fetcher: Fetcher = async (graphQLParams) => {
    const endpoint = `https://api.dev.cloud.butterflynetwork.com/graphql`;
    const data = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify(graphQLParams),
      credentials: 'same-origin',
    });
    return data.json().catch(() => data.text());
  };

  return (
    <>
      <GraphiQL
        fetcher={fetcher}
        defaultHeaders={JSON.stringify({ ...headers }, null, 2)}
        onEditHeaders={(headerStr) => setHeaders(JSON.parse(headerStr))}
      />
      <div className="mb-4" />
      <CopyToClipboardControl
        variant="secondary"
        value={accessToken}
        contentEditable={false}
        successMessage={<>Access Token Copied</>}
      />
    </>
  );
};

export default Playground;
