import BlankSlate from '@bfly/ui2/BlankSlate';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function EmptyModalityWorklistScreen() {
  return (
    <BlankSlate>
      <BlankSlate.Title>
        <FormattedMessage
          defaultMessage="Worklist Empty"
          id="emptyWorklist.title"
        />
      </BlankSlate.Title>
      <BlankSlate.Body>
        <FormattedMessage
          defaultMessage="There are currently no items in your worklist. Items added to your worklist can be viewed in the Butterfly Cloud."
          id="emptyWorklist.body"
        />
      </BlankSlate.Body>
    </BlankSlate>
  );
}

export function EmptyWorklistScreenFiltered() {
  return (
    <BlankSlate>
      <BlankSlate.Title>
        <FormattedMessage
          defaultMessage="No Worklist items found."
          id="emptyWorklist.title.filtered"
        />
      </BlankSlate.Title>
      <BlankSlate.Body>
        <FormattedMessage
          defaultMessage="The filter returned no results."
          id="emptyWorklist.body.filtered"
        />
      </BlankSlate.Body>
    </BlankSlate>
  );
}
