import Link from '@bfly/ui2/Link';
import Page from '@bfly/ui2/Page';
import SideMenu from '@bfly/ui2/SideMenu';
import { FormattedMessage } from 'react-intl';

import { RelayRouteRenderArgs } from 'components/Route';

import { AVAILABLE_REVIEW_STATUSES } from '../constants';
import { useScanLabContext } from '../context';
import { STATUS_MESSAGES } from '../messages';

export default function ScanLabAppPageSidePanel({
  match,
}: RelayRouteRenderArgs) {
  const { reviewStatus, canRequestReview, statusCounts, toListRoute } =
    useScanLabContext();
  const { listRoute } = match.params;

  if (!listRoute || !canRequestReview) return null;

  return (
    <Page.SidePanel className="flex flex-col justify-between pb-0 w-[215px]">
      <SideMenu.Header
        id="scanLabExamsStatus"
        aria-controls="scanLab.ExamsStatus"
        transform="capitalize"
        variant="body-bold"
      >
        <FormattedMessage id="scanLab.ExamsStatus" defaultMessage="Exams" />
      </SideMenu.Header>
      <SideMenu className="mb-auto" data-bni-id="status-sidebar">
        {AVAILABLE_REVIEW_STATUSES[listRoute].map((status) => (
          <Link to={toListRoute(status)} key={status}>
            {(linkProps) => (
              <SideMenu.ListItem data-bni-id={`status-${status}`}>
                <SideMenu.Link
                  className="tabular-nums"
                  badge={statusCounts ? `${statusCounts[status]}` : null}
                  {...linkProps}
                  active={reviewStatus === status}
                >
                  <FormattedMessage {...STATUS_MESSAGES[status]} />
                </SideMenu.Link>
              </SideMenu.ListItem>
            )}
          </Link>
        ))}
      </SideMenu>
    </Page.SidePanel>
  );
}
