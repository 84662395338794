import Dropdown from '@bfly/ui2/Dropdown';
import Navbar from '@bfly/ui2/Navbar';
import SrOnly from '@bfly/ui2/SrOnly';
import useRouter from 'found/useRouter';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import AuthContext from 'components/AuthContext';
import Avatar from 'components/Avatar';
import accountMessages from 'messages/account';
import { useViewerAllowMissingContext } from 'utils/viewerContext';

export default function UserDropdownButton() {
  const auth = useContext(AuthContext);
  const viewer = useViewerAllowMissingContext();
  const { router, match } = useRouter();

  const onClickLogOut = () => {
    auth.clearAccessToken();
    router.replace({
      ...match.location,
      query: {},
    });
  };

  if (!viewer) {
    return (
      <Navbar.Button
        onClick={() => {
          router.replace({
            ...match.location,
            state: {
              ...match.location.state,
              forceLogin: true,
            },
          });
        }}
      >
        <FormattedMessage {...accountMessages.logIn} />
      </Navbar.Button>
    );
  }

  return (
    <Dropdown className="z-100">
      <Dropdown.Toggle id="user-dropdown" as={Navbar.Button}>
        <Avatar userProfile={viewer.profile!} width={30} />
        <SrOnly>{viewer.profile!.name}</SrOnly>
      </Dropdown.Toggle>
      <Dropdown.Menu
        placement="bottom-end"
        data-bni-id="EducationAppHeaderUserDropdownMenu"
        css="width: 25rem;"
      >
        <Dropdown.Item onSelect={onClickLogOut}>
          <FormattedMessage
            id="userDropdownButton.logOut"
            defaultMessage="Log Out"
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
