/**
 * @generated SignedSource<<929bae0c049b752baa6651d34990f54a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type standardQa_examTypeProficiency$data = {
  readonly examTypes: ReadonlyArray<{
    readonly deletedAt: string | null;
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly standardQa: {
    readonly examTypeCredentialInfo: ReadonlyArray<{
      readonly countsTowardCredentialing: boolean;
      readonly examType: {
        readonly deletedAt: string | null;
        readonly id: string;
        readonly name: string | null;
      } | null;
    }> | null;
  } | null;
  readonly " $fragmentType": "standardQa_examTypeProficiency";
};
export type standardQa_examTypeProficiency$key = {
  readonly " $data"?: standardQa_examTypeProficiency$data;
  readonly " $fragmentSpreads": FragmentRefs<"standardQa_examTypeProficiency">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "standardQa_examTypeProficiency"
};

(node as any).hash = "2491cdb4b01a3cdaf03b5f17a96a893a";

export default node;
