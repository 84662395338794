import { GenericConnection } from '@bfly/utils/getNodes';
import useUpdateLayoutEffect from '@restart/hooks/useUpdateLayoutEffect';
import useRouter from 'found/useRouter';
import { useEffect, useMemo, useState } from 'react';
import type { KeyType as RelayKeyType } from 'relay-hooks/lib/RelayHooksTypes';
import { usePagination } from 'relay-hooks/lib/usePagination';
import {
  GraphQLTaggedNode,
  SingularReaderSelector,
  Variables,
  createOperationDescriptor,
  getFragment,
  getPaginationMetadata,
  getPaginationVariables,
  getSelector,
  getValueAtPath,
} from 'relay-runtime';
import { getStableStorageKey } from 'relay-runtime/lib/store/RelayStoreUtils';

import { Match, OperationType, PrepareVariables } from 'components/Route';
import { getRouteRequest } from 'utils/RouteUtils';

interface PageState {
  index: number;
  absoluteIndex: number;
  cursor: string | null;
  direction: 'forward' | 'backward';
  variables: Partial<PaginationVariables> | null;
  dataIsStale: boolean;
}

export type PaginationVariables = {
  first: number | null;
  last: number | null;
  after: string | null;
  before: string | null;
};

export interface LocationPageState {
  direction: PageState['direction'];
  cursor: string | null;
  pageSize: number;
  variables: Partial<PaginationVariables> | null;
}

function checkStoreHasData(match: Match, variables: Variables) {
  const request = getRouteRequest(match);
  const operation = createOperationDescriptor(request!, variables);
  return match.context.environment.check(operation).status === 'available';
}

/**
 * A Route `prepareVariables` function that provides the correct
 * set of variables for resuming a paginated connection. This function
 * tries to ensure that a page query with pagination isn't run against
 * the server unnecessarily (such as navigating back to a list page)
 */
export function prepareVariablesWithPagination<
  TQuery extends OperationType<PaginationVariables>,
>(prepareVariables?: PrepareVariables<TQuery>): PrepareVariables<TQuery> {
  return (variables, match) => {
    const queryVariables = prepareVariables?.(variables, match) ?? variables;
    const pageState: LocationPageState | undefined =
      match.location.state?.pageState;

    if (!pageState) {
      return queryVariables;
    }

    const fetchVariables = {
      ...queryVariables,
      ...pageState.variables,
    };

    // check if data has been fetched with overfetch amount
    // if has, use those variables to avoid refetching,
    if (checkStoreHasData(match, fetchVariables)) {
      return fetchVariables;
    }

    // If we have a cache miss then refetch with current page state
    // and mutate the location so that pagination hooks don't try
    // and hydrate from the page state
    match.location.state.pageState = null;

    return queryVariables;
  };
}

interface Edge<T> {
  cursor?: string;
  node: T | null;
}
export type PageableConnection<T = any> = {
  index: number | null;
  numEdges: number | null;
  edges: ReadonlyArray<Edge<T> | null> | null;
};

export type PagableKey<TKey extends string> = RelayKeyType<{
  [idx in TKey]?: PageableConnection | null;
}>;

export type ConnectionNodeFromKey<
  T extends RelayKeyType,
  K extends string,
> = T extends RelayKeyType<infer D>
  ? K extends keyof D
    ? NonNullable<D[K]> extends GenericConnection<infer N>
      ? N[]
      : never
    : never
  : never;

export type ConnectionFromKey<
  T extends RelayKeyType,
  K extends string,
> = T extends RelayKeyType<infer D>
  ? K extends keyof D
    ? NonNullable<D[K]> extends GenericConnection<infer N>
      ? GenericConnection<N>
      : never
    : never
  : never;

interface UsePagedConnectionOptions {
  pageSize: number;
  overfetchNumPages?: number;
}

export interface PaginationMeta {
  index: number;
  numEdges: number;
  loadNext: () => void;
  loadPrevious: () => void;
  fetching: boolean;
}

function getVariables(node: GraphQLTaggedNode, ref: any) {
  const selector = getSelector(getFragment(node), ref);

  const parentVariables = (selector as SingularReaderSelector)?.owner
    .variables;
  const fragmentVariables = (selector as SingularReaderSelector)?.variables;

  return { ...parentVariables, ...fragmentVariables };
}

function getConnectionKeyWithoutPagination(node: GraphQLTaggedNode, ref: any) {
  const {
    first: _f,
    after: _a,
    last: _l,
    before: _b,
    ...variables
  } = getVariables(node, ref);

  return getStableStorageKey('connection', variables);
}

export default function usePagedConnection<
  TQuery extends OperationType,
  TRef extends PagableKey<TKey>,
  TKey extends string,
>(
  node: GraphQLTaggedNode,
  ref: TRef | null | undefined,
  { pageSize, overfetchNumPages = 1 }: UsePagedConnectionOptions,
) {
  type NodeType = ConnectionNodeFromKey<TRef, TKey>;

  const overfetchAmount = pageSize + overfetchNumPages * pageSize;
  const { router, match } = useRouter();

  const result = usePagination<TQuery, TRef>(node, ref!);

  const connectionKey = getConnectionKeyWithoutPagination(node, ref);

  const metadata = useMemo(
    () =>
      getPaginationMetadata(
        (node as any).default ?? node,
        'usePagedConnection',
      ),
    [node],
  );

  const { data } = result;

  const connection: PageableConnection<NodeType> = getValueAtPath(
    data!,
    metadata.connectionPathInFragmentData,
  );

  const connNumEdges = connection?.numEdges ?? 0;

  function getStateFromConnection(
    conn: PageableConnection<NodeType>,
  ): PageState {
    return {
      direction: 'forward',
      variables: null,
      dataIsStale: false,
      cursor: (conn as any)?.pageInfo.startCursor,
      absoluteIndex: conn?.index ?? 0,
      index: 0,
    };
  }

  const [state, setState] = useState<PageState>(() => {
    const locationState: LocationPageState | undefined =
      match.location.state?.pageState;

    const pageState = getStateFromConnection(connection);

    if (locationState) {
      const cursor = locationState.cursor || null;

      if (cursor) {
        const idx =
          connection?.edges?.findIndex((e) => e?.cursor === cursor) ?? 0;

        if (idx !== -1) {
          pageState.cursor = locationState.cursor;
          pageState.direction = locationState.direction;
          pageState.index =
            locationState.direction === 'backward'
              ? Math.max(idx - locationState.pageSize, 0)
              : idx + 1;
        }
      }
    } else {
      return { ...pageState, absoluteIndex: pageState.index };
    }
    return pageState;
  });

  function refetchAndBackToFirstPage() {
    const nextState = getStateFromConnection(connection);

    // XXX: If a connection is refetched we should always be fetching from the start
    // if we already have data for the connection, the user may have paged forward on that connection
    // moving the index returned from the server up. We reset it to 0, since it must be the case we have the data at
    // this position
    nextState.absoluteIndex = 0;
    setState(nextState);
  }

  /** Track num edges to say if user added or removed an edge */
  const [initialNumEdges, setInitialNumEdges] = useState(
    connection?.numEdges ?? 0,
  );
  const [wasEdgeAdded, setWasEdgeAdded] = useState(false);
  useEffect(() => {
    if (initialNumEdges !== connNumEdges) {
      setWasEdgeAdded(initialNumEdges < connNumEdges);
      setInitialNumEdges(connNumEdges);
    }
  }, [connNumEdges, initialNumEdges]);

  /** Back to first page with newly added edge when we are not on the first page */
  useUpdateLayoutEffect(() => {
    const isMoreThanFirstPageFetched = connNumEdges > pageSize;
    if (isMoreThanFirstPageFetched && wasEdgeAdded) {
      refetchAndBackToFirstPage();
    }
  }, [initialNumEdges]);

  /**
   * Reset pagination state when the connection fundamentally changes.
   * e.g. when new variables are set or updated we are fetching a brand new
   * range from the beginning. We define connection identity in a similar way
   * to Relay, using they a stable hash of the variable values
   */
  useUpdateLayoutEffect(() => {
    refetchAndBackToFirstPage();
  }, [connectionKey]);

  const page = connection?.edges!.slice(state.index, state.index + pageSize);
  const connectionPage = {
    ...connection,
    // in case of deleting a page caused by deleting the only item there
    edges: page?.length ? page : connection?.edges,
  };

  function loadPrevious() {
    setState((currentState) => {
      const shouldFetch =
        currentState.index < overfetchAmount && result.hasPrevious;
      const hasData = currentState.index >= pageSize || !result.hasPrevious;

      const nextState: PageState = {
        ...currentState,
        direction: 'backward',
        absoluteIndex: Math.max(currentState.absoluteIndex! - pageSize, 0),
        cursor: page[0]?.cursor || null,
        dataIsStale: !hasData,
      };

      if (hasData) {
        nextState.index = Math.max(currentState.index - pageSize, 0);
      } else if (shouldFetch && result.isLoadingPrevious) {
        return currentState;
      }

      if (shouldFetch) {
        nextState.variables = getPaginationVariables(
          'backward',
          overfetchAmount,
          nextState.cursor,
          {},
          {},
          metadata.paginationMetadata,
        );
        result.loadPrevious(overfetchAmount);
      }

      return nextState;
    });
  }

  function loadNext() {
    const numFetched = connection.edges!.length;

    setState((currentState) => {
      const overFetchIndex = currentState.index + overfetchAmount;
      const shouldFetch = numFetched < overFetchIndex + 1 && result.hasNext;

      const nextStartIndex = currentState.index + pageSize;
      const hasData = numFetched > nextStartIndex || !result.hasNext;

      // Edge case when we dynamically add a new element via an updater
      const hasNotFullPage =
        numFetched - nextStartIndex > 0 &&
        numFetched - nextStartIndex < pageSize &&
        result.hasNext;
      const shouldRefetch = shouldFetch || hasNotFullPage;

      const nextState: PageState = {
        ...currentState,
        direction: 'forward',
        absoluteIndex: currentState.absoluteIndex + pageSize,
        cursor: page[page.length - 1]?.cursor || null,
        dataIsStale: !hasData,
      };

      if (hasData) {
        nextState.index = nextStartIndex;
      } else if (shouldFetch && result.isLoadingNext) {
        return currentState;
      }

      if (shouldRefetch) {
        nextState.variables = getPaginationVariables(
          'forward',
          overfetchAmount,
          nextState.cursor,
          {},
          {},
          metadata.paginationMetadata,
        );
        result.loadNext(overfetchAmount);
      }

      return nextState;
    });
  }

  /**
   * Sync our local connection state with the data we have every time
   * the data is updated. e.g. find our last cursor and recalculate a correct local
   * index from it, since we may have fetched less than a full pageSize
   */
  useUpdateLayoutEffect(() => {
    setState((currentState) => {
      let { cursor, index, direction } = currentState;

      const newIndex = connection?.index
        ? state.absoluteIndex - pageSize
        : undefined;
      const isOnLastPage =
        newIndex === Number(connection?.numEdges) - pageSize;
      const deletedLastRecordInPageCase = !page?.length && isOnLastPage;
      if (deletedLastRecordInPageCase) {
        setState((prevState) => ({
          ...prevState,
          absoluteIndex:
            newIndex || newIndex === 0 ? newIndex : prevState.index,
        }));
      }

      const idx =
        connection?.edges?.findIndex((c) => c?.cursor === cursor) ?? 0;

      // For first page, index should always be zero, since we can't rely on cursor
      const forwardIndex = idx === 0 ? 0 : idx + 1;
      index =
        direction === 'forward' ? forwardIndex : Math.max(idx - pageSize, 0);

      return {
        ...currentState,
        cursor,
        direction,
        index,
        dataIsStale: false,
      };
    });
  }, [data]);

  const { absoluteIndex, cursor, direction, variables } = state;

  useUpdateLayoutEffect(() => {
    const pageState: LocationPageState = {
      cursor: absoluteIndex === 0 ? null : cursor,
      direction,
      variables,
      pageSize,
    };

    router.replace({
      ...match.location,
      // @ts-expect-error this is undocumented because it's a terrible API
      doNotRerunMatch: true,
      state: {
        ...match.location.state,
        pageState,
      },
    });
  }, [cursor, direction]);

  return [
    connectionPage as ConnectionFromKey<TRef, TKey>,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {
      ...result,
      index: absoluteIndex,
      fetching:
        state.dataIsStale &&
        (result.isLoadingPrevious || result.isLoadingNext),
      numEdges: connection?.numEdges ?? 0,
      loadPrevious,
      loadNext,
    } as PaginationMeta,
  ] as const;
}
