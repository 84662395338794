import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import FormGroup from '@bfly/ui2/FormGroup';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import notNullish from '@bfly/utils/notNullish';
import { useMemo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { ContainerProps, createFragmentContainer, graphql } from 'react-relay';
import * as yup from 'yup';

import { NO_EXAM_TYPE_OPTION } from 'components/ExamTypeSelectButton';
import RelayForm, {
  RelayFormProps,
  RelayOperationWithFormError,
} from 'components/RelayForm';
import SearchTags from 'components/SearchTags';
import actionMessages from 'messages/actions';
import {
  SearchData,
  UNASSIGNED_AUTHOR,
  messages as searchMessages,
} from 'utils/Search';

import { SavedSearchModalNodesQuery } from './__generated__/SavedSearchModalNodesQuery.graphql';
import { SavedSearchModal_Query } from './__generated__/SavedSearchModal_Query.graphql';
import { SavedSearchModal_studySearchCriteria$data as StudySearchCriteria } from './__generated__/SavedSearchModal_studySearchCriteria.graphql';

const messages = defineMessages({
  uniqueNameError: {
    defaultMessage: 'A search by this name already exists',
    id: 'searchCreateUpdateModal.uniqueNameError',
  },
});

function getNodeIds({
  author,
  reviewer,
  worksheetTemplate,
  allWorksheetTemplates,
  tag,
  examType,
  organization,
  archive,
}: Partial<SearchData>) {
  const ids = [] as (string | undefined | null)[];

  return ids
    .concat(
      author
        ?.filter((item) => item.value !== UNASSIGNED_AUTHOR)
        .map((item) => item.value),
      reviewer?.map((r) => r.value),
      worksheetTemplate,
      allWorksheetTemplates,
      tag,
      examType?.filter((exam) => exam !== NO_EXAM_TYPE_OPTION.id),
      organization,
      archive,
    )
    .filter(notNullish);
}

interface Props<T extends RelayOperationWithFormError>
  extends RelayFormProps<T, typeof schema> {
  onHide: () => void;
  searchData: SearchData | null;
  studySearchCriteria: StudySearchCriteria | null;
  organizationSlug: string | null;
}

const schema = yup.object({
  name: yup
    .string()
    .required()
    .trim()
    .test(
      'test-name-uniqueness',
      messages.uniqueNameError,
      (value, ctx) =>
        !value ||
        (ctx.options.context?.searches || []).every(
          ({ name, id }) => name !== value || id === ctx.options.context!.id,
        ),
    ),
});

function SavedSearchModal<T extends RelayOperationWithFormError>({
  onHide,
  searchData,
  organizationSlug,
  studySearchCriteria,
  ...props
}: Props<T>) {
  // this is a separate query to hopefully avoid an actual request for the needs
  // since the local cache should have the data. But it doesn't seem to work right now
  const nodesQuery = useQuery<SavedSearchModalNodesQuery>(
    graphql`
      query SavedSearchModalNodesQuery($criteria: [ID!]!) {
        nodes(ids: $criteria) {
          ...SearchTags_nodes
        }
      }
    `,
    {
      fetchPolicy: 'store-or-network',
      variables: {
        criteria: searchData ? getNodeIds(searchData) : [],
      },
      skip: !searchData,
    },
  );

  const { data } = useQuery<SavedSearchModal_Query>(
    graphql`
      query SavedSearchModal_Query($slug: String) {
        tenant(slug: $slug) {
          viewerSavedSearchConnection(first: 2147483647) {
            edges {
              node {
                name
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: { slug: organizationSlug },
    },
  );

  const formContext = useMemo(
    () => ({
      searches: data
        ? getNodes(data?.tenant?.viewerSavedSearchConnection)
        : [],
      id: props.defaultValue?.id,
    }),
    [data, props.defaultValue],
  );

  return (
    <RelayForm<T, typeof schema>
      {...props}
      schema={schema}
      formContext={formContext}
      onCompleted={() => {
        onHide();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="savedSearchModal.save"
            defaultMessage="Save Search"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup horizontal label={searchMessages.searchName}>
          <Form.Field name="name" />
          <Form.Message for="name" />
          {!data ? (
            <LoadingIndicator />
          ) : (
            <Layout className="pt-4" data-bni-id="GSearchTags">
              <SearchTags
                variant="light"
                searchData={searchData}
                nodes={nodesQuery.data?.nodes!.filter(notNullish) ?? null}
                studySearchCriteria={studySearchCriteria}
                wrap
              />
            </Layout>
          )}
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Form.Submit variant="primary">
            <FormattedMessage {...actionMessages.save} />
          </Form.Submit>
          <Button variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </RelayForm>
  );
}

export default createFragmentContainer(SavedSearchModal, {
  studySearchCriteria: graphql`
    fragment SavedSearchModal_studySearchCriteria on StudySearchCriterion
    @relay(plural: true) {
      ...SearchTags_studySearchCriteria
    }
  `,
}) as <T extends RelayOperationWithFormError>(
  props: ContainerProps<Props<T>>,
) => React.ReactElement;
