/**
 * @generated SignedSource<<dae0adbb479af789040588aad55d8210>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeTagsPerformanceIndicator_tenant$data = {
  readonly studyStatistics: {
    readonly numExamTypeAssociations: number | null;
    readonly numExamTypesCountingForCredentialing: number | null;
  } | null;
  readonly type: string;
  readonly " $fragmentType": "ExamTypeTagsPerformanceIndicator_tenant";
};
export type ExamTypeTagsPerformanceIndicator_tenant$key = {
  readonly " $data"?: ExamTypeTagsPerformanceIndicator_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeTagsPerformanceIndicator_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "studyStatsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamTypeTagsPerformanceIndicator_tenant",
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "studyStatsFilter"
        }
      ],
      "concreteType": "StudyStatistics",
      "kind": "LinkedField",
      "name": "studyStatistics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numExamTypeAssociations",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numExamTypesCountingForCredentialing",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "5cd7cb063507120eab8cc28f9c286039";

export default node;
