/**
 * @generated SignedSource<<0d7b917f6a5b046babba2b2557988d80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RootPage_viewer$data = {
  readonly email: string | null;
  readonly profile: {
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "RootPage_viewer";
};
export type RootPage_viewer$key = {
  readonly " $data"?: RootPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RootPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RootPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e046f81b44d0c43dcaa54e9f0a9a266b";

export default node;
