/**
 * @generated SignedSource<<0e65b92e1ed0984c4f1fe4b9e9c5c1fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamPageBanner_study$data = {
  readonly archive: {
    readonly deletedAt: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"UndeleteArchiveControl_archive">;
  } | null;
  readonly deletedAt: string | null;
  readonly finalizedAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"UndeleteStudyControl_study">;
  readonly " $fragmentType": "ExamPageBanner_study";
};
export type ExamPageBanner_study$key = {
  readonly " $data"?: ExamPageBanner_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageBanner_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPageBanner_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UndeleteArchiveControl_archive"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UndeleteStudyControl_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "4f233b40942f357518696820c5aaa2e8";

export default node;
