import Anchor from '@bfly/ui2/Anchor';
import BlankSlate from '@bfly/ui2/BlankSlate';
import MainContent from '@bfly/ui2/MainContent';
import Navbar from '@bfly/ui2/Navbar';
import Page from '@bfly/ui2/Page';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function DomainNotFoundPage() {
  const websiteUrl = globalThis.bflyConfig.WEBSITE_URL;

  return (
    <Page>
      <Navbar>
        <Navbar.Brand as={Anchor} href={websiteUrl} />
      </Navbar>
      <Page.Container center data-bni-id="DomainNotFoundPage">
        <MainContent centerText>
          <BlankSlate>
            <BlankSlate.Title>404</BlankSlate.Title>
            <BlankSlate.Body>
              <FormattedMessage
                id="domain.404.body"
                defaultMessage="There is no Butterfly Cloud at this address."
              />
            </BlankSlate.Body>
            <BlankSlate.Action href={websiteUrl} size="lg">
              <FormattedMessage
                id="domain.404.returnHome"
                defaultMessage="Open {host}"
                values={{ host: new URL(websiteUrl).host }}
              />
            </BlankSlate.Action>
          </BlankSlate>
        </MainContent>
      </Page.Container>
    </Page>
  );
}
