import Layout from '@4c/layout';
import { createFragmentContainer, graphql } from 'react-relay';

import ArchiveSelectButton from 'components/ArchiveSelectButton';
import CredentialGroupSelectButton from 'components/CredentialGroupSelectButton';
import DateRangeSelectButton from 'components/DateRangeSelectButton';
import ExamTypeSelectButton from 'components/ExamTypeSelectButton';
import { useVariation } from 'components/LaunchDarklyContext';
import MemberSelectButton from 'components/MemberSelectButton';
import OrganizationSelectButton from 'components/OrganizationSelectButton';
import WorksheetSelectButton from 'components/WorksheetSelectButton';

import { useFilterState } from '../schemas/statsFilters';
import { StatsFilters_tenant$data as Tenant } from './__generated__/StatsFilters_tenant.graphql';

interface Props {
  tenant: Tenant;
}

function StatsFilter<T extends { [key: string]: any }>({ tenant }: Props) {
  const [filters, setFilters] = useFilterState();
  const canUseCredentialing = useVariation('credentialing-analytics-page');

  function handleChange(partialNextValue: Partial<T> | any) {
    setFilters({ ...filters, ...partialNextValue });
  }

  return (
    <Layout pad={2} wrap justify="flex-start">
      {canUseCredentialing && (
        <CredentialGroupSelectButton
          data-bni-id="CredentialGroupSelectButton"
          dataKey="id"
          tenantId={tenant.id}
          value={filters.credentialGroup || []}
          onChange={(userGroups) =>
            handleChange({
              credentialGroup: userGroups?.map((group) => group.id!),
            })
          }
        />
      )}
      <MemberSelectButton
        tenant={tenant}
        value={filters.author || []}
        onChange={(author) => handleChange({ author })}
        placement="bottom-end"
        includeDeleted
        data-bni-id="AuthorFilterButton"
      />
      {tenant.type === 'Domain' && (
        <OrganizationSelectButton
          value={filters.organization?.map((o) => o.id) ?? []}
          dataKey="id"
          onChange={(orgs) => handleChange({ organization: orgs })}
          data-bni-id="OrganizationFilterButton"
        />
      )}
      <DateRangeSelectButton
        value={filters.capturedAt}
        onChange={(capturedAt: any) => handleChange({ capturedAt })}
        placement="bottom-end"
        data-bni-id="DateRangeFilterButton"
      />
      <ExamTypeSelectButton
        dataKey="handle"
        tenantId={tenant.id}
        value={filters.examType ?? []}
        onChange={(nextExamTypes) => {
          handleChange({
            examType: nextExamTypes?.map((a) => a.handle!),
          });
        }}
        data-bni-id="ExamTypeFilterButton"
      />
      <WorksheetSelectButton
        dataKey="handle"
        tenant={tenant!}
        value={filters.worksheetTemplate ?? []}
        onChange={(worksheetTemplate) =>
          handleChange({
            worksheetTemplate: worksheetTemplate?.map((w) => w.handle),
          })
        }
        data-bni-id="WorksheetFilterButton"
      />
      <ArchiveSelectButton
        dataKey="handle"
        tenant={tenant!}
        value={filters.archive ?? []}
        onChange={(archive) =>
          handleChange({ archive: archive?.map((a) => a.handle) })
        }
        data-bni-id="ArchiveFilterButton"
      />
    </Layout>
  );
}

export default createFragmentContainer(StatsFilter, {
  tenant: graphql`
    fragment StatsFilters_tenant on TenantInterface {
      type: __typename
      ... on Organization {
        id
        handle
        slug
      }
      id
      ...WorksheetSelectButton_tenant
      ...MemberSelectButton_tenant
      ...ArchiveSelectButton_tenant
    }
  `,
});
