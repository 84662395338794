import BlankSlate from '@bfly/ui2/BlankSlate';
import Link from '@bfly/ui2/Link';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import accountMessages from 'messages/account';
import { useConfigRoutes } from 'routes/config';

function InviteUsedPage() {
  const configRoutes = useConfigRoutes();
  return (
    <Page.Container center>
      <MainContent centerText>
        <BlankSlate>
          <BlankSlate.Title>
            <FormattedMessage
              id="acceptInvite.inviteUsed.title"
              defaultMessage="This invitation has already been accepted."
            />
          </BlankSlate.Title>

          <BlankSlate.Body>
            <FormattedMessage
              id="acceptInvite.inviteUsed.body.desktop"
              defaultMessage="This link has already been used to create an account or join an organization."
            />
          </BlankSlate.Body>
          <Link as={BlankSlate.Action} to={configRoutes.rootRoute()} size="lg">
            <FormattedMessage {...accountMessages.logIn} />
          </Link>
        </BlankSlate>
      </MainContent>
    </Page.Container>
  );
}

export default InviteUsedPage;
