import useRouter from 'found/useRouter';
import { createFragmentContainer, graphql } from 'react-relay';

import ImagePreviewRoll from 'components/ImagePreviewRoll';
import useElementsNavigation from 'hooks/useElementsNavigation';
import { useArchiveRoutes } from 'routes/archive';

import DesktopSharedImageCarousel from './DesktopSharedImageCarousel';
import { DesktopSharedImageList_images$data as Images } from './__generated__/DesktopSharedImageList_images.graphql';

interface Props {
  images: Images;
}

function DesktopSharedImageList({ images }: Props) {
  const archiveRoutes = useArchiveRoutes();
  const { params } = useRouter().match;

  const [
    activeImage,
    { activeIndex, isFirst, isLast, setActiveIndex, prev, next },
  ] = useElementsNavigation(images, {
    // Handle the "index" route which is a separate behavior on mobile but
    // identical to selecting the first image on desktop
    activeHandle: params.sharedBundleImageHandle || images[0].handle!,
    to: (handle, { location }) => ({
      ...location,
      pathname: archiveRoutes.sharedBundleImage({
        sharedBundleHandle: params.sharedBundleHandle,
        sharedBundleImageHandle: handle,
      }),
    }),
  });

  if (!activeImage) {
    return null;
  }

  const showPreviews = images.length > 1;

  return (
    <>
      <DesktopSharedImageCarousel
        image={activeImage}
        imageIndex={activeIndex}
        isFirst={isFirst}
        isLast={isLast}
        onNext={next}
        onPrev={prev}
      />
      {showPreviews && (
        <ImagePreviewRoll
          study={null}
          images={images}
          activeIndex={activeIndex}
          onSelect={setActiveIndex}
        />
      )}
    </>
  );
}

export default createFragmentContainer(DesktopSharedImageList, {
  images: graphql`
    fragment DesktopSharedImageList_images on SharedBundleImage
    @relay(plural: true) {
      id
      handle
      ...DesktopSharedImageCarousel_image
      ...ImagePreviewRoll_images
    }
  `,
});
