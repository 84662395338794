/**
 * @generated SignedSource<<249596937a4faeb1b2866951ffc5a693>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClinicalInsightsCards_tenant$data = {
  readonly studyStatistics: {
    readonly accuracyAsPresentedStatistics: ReadonlyArray<{
      readonly key: string;
      readonly numFinalizedStudies: number;
    } | null> | null;
  } | null;
  readonly type: string;
  readonly " $fragmentType": "ClinicalInsightsCards_tenant";
};
export type ClinicalInsightsCards_tenant$key = {
  readonly " $data"?: ClinicalInsightsCards_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClinicalInsightsCards_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicalInsightsCards_tenant",
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "StudyStatistics",
      "kind": "LinkedField",
      "name": "studyStatistics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InterpretationStatistics",
          "kind": "LinkedField",
          "name": "accuracyAsPresentedStatistics",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numFinalizedStudies",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "841fd17282fb6e3a3f31e8523e5d2104";

export default node;
