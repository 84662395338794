import Layout from '@4c/layout';
import Form from '@bfly/ui2/Form';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import Text from '@bfly/ui2/Text';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { InferType, object, string } from 'yup';

import { usePermissions } from 'utils/viewerContext';

import { ArchiveQaNotificationsForm_Mutation as Mutation } from './__generated__/ArchiveQaNotificationsForm_Mutation.graphql';
import {
  ArchiveQaNotificationsForm_archive$data as Archive,
  DefaultQaNotificationRecipients,
} from './__generated__/ArchiveQaNotificationsForm_archive.graphql';

const mutation = graphql`
  mutation ArchiveQaNotificationsForm_Mutation($input: UpdateArchiveInput!) {
    updateArchiveOrError(input: $input) {
      ... on UpdateArchivePayload {
        archive {
          ...ArchiveQaNotificationsForm_archive
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

type QaNotificationRecipients =
  ExcludeFutureAddedValue<DefaultQaNotificationRecipients>;

const options: QaNotificationRecipients[] = ['NO_ONE', 'EVERYONE'];

const schema = object({
  defaultQaNotificationRecipients: string().enum(options).required().trim(),
});

type FormValue = InferType<typeof schema>;

function ArchiveQaNotificationsForm({ archive }: { archive: Archive }) {
  const [mutate, loading] = useMutationWithError<Mutation>(mutation);

  const { hasBasicPermission } = usePermissions();

  const canEditQANotificationSettings = hasBasicPermission('qaManagement');

  const handleChange = async (nextValue: FormValue) => {
    await mutate({
      input: {
        ...nextValue,
        archiveId: archive.id,
      },
    });
  };

  return (
    <Form
      schema={schema}
      value={{
        defaultQaNotificationRecipients:
          archive.defaultQaNotificationRecipients,
      }}
      onChange={handleChange}
      className="mb-6"
    >
      <Form.FieldSet
        legend={
          <Layout direction="column" pad={1}>
            <div>
              <FormattedMessage
                id="archive.settings.qaNotifications.title"
                defaultMessage="QA Notifications"
              />
            </div>
            <Text variant="sm" color="subtitle" css="font-weight: 400;">
              <FormattedMessage
                id="archive.settings.qaNotifications.description"
                defaultMessage="QA reviewers will always have the option to notify authors of results from the exam screen
          "
              />
            </Text>
          </Layout>
        }
        className="mb-6"
      >
        <Form.Field
          as={FormCheckGroup}
          type="radio"
          name="defaultQaNotificationRecipients"
          disabled={loading || !canEditQANotificationSettings}
          data={options}
          renderItem={(value: QaNotificationRecipients) => {
            if (value === 'EVERYONE')
              return (
                <FormattedMessage
                  id="archive.settings.qaNotifications.everyone"
                  defaultMessage="Automatically notify everyone of all QA results"
                />
              );
            if (value === 'NO_ONE')
              return (
                <FormattedMessage
                  id="archive.settings.qaNotifications.noOne"
                  defaultMessage="Only send results when “notify” is explicitly selected on the exam screen"
                />
              );
            return null;
          }}
        />
      </Form.FieldSet>
    </Form>
  );
}
export default createFragmentContainer(ArchiveQaNotificationsForm, {
  archive: graphql`
    fragment ArchiveQaNotificationsForm_archive on Archive {
      id
      defaultQaNotificationRecipients
    }
  `,
});
