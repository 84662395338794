/**
 * @generated SignedSource<<c3283c469410db3c1465c747f852394b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamAuthors_study$data = {
  readonly createdBy: {
    readonly id: string;
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile" | "RelatedUsersPopoverListItems_users">;
  } | null;
  readonly finalizedAt: string | null;
  readonly secondaryAuthors: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile" | "RelatedUsersPopoverListItems_users">;
  } | null> | null;
  readonly transcribedBy: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyAuthorSearchControl_study" | "StudyAuthorsList_study">;
  readonly " $fragmentType": "ExamAuthors_study";
};
export type ExamAuthors_study$key = {
  readonly " $data"?: ExamAuthors_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamAuthors_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "Avatar_userProfile"
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "RelatedUsersPopoverListItems_users"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamAuthors_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "secondaryAuthors",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "transcribedBy",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyAuthorsList_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyAuthorSearchControl_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "351b98b0b5911d8139c8d9f47032d393";

export default node;
