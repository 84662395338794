import { graphql } from 'react-relay';

import RenameRedirect from 'components/RenameRedirect';
import Route, { chainPrerenders } from 'components/Route';
import routes from 'routes/admin';
import { checkRoutePermission, isFetched404 } from 'utils/RouteAccessControl';

import { subscription_SubscriptionContentQuery as SubscriptionContentQuery } from './__generated__/subscription_SubscriptionContentQuery.graphql';

export default (
  <>
    <RenameRedirect
      from="subscription"
      to={(match) =>
        routes(match, {
          organizationSlug: match.params.organizationSlug,
        }).organizationSubscription()
      }
    />
    <Route<SubscriptionContentQuery>
      path="membership"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/SubscriptionContent'
        ).then((m) => m.default)
      }
      query={graphql`
        query subscription_SubscriptionContentQuery(
          $organizationSlug: String!
        ) {
          organization: organizationBySlug(slug: $organizationSlug) {
            ...SubscriptionContent_organization
            subscription {
              billingProvider
            }
          }
        }
      `}
      prerender={chainPrerenders(
        isFetched404(
          ({ props }) =>
            props?.organization?.subscription?.billingProvider ===
            'SALESFORCE',
        ),
        checkRoutePermission('organizationManagement', 'BASIC'),
      )}
    />
  </>
);
