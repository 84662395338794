import { stylesheet } from 'astroturf/react';
import clsx from 'clsx';

const styles = stylesheet`
  .wrapper {
    position: relative;
    display: inline-block;
    width: 3.75rem;
    height: 2rem;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: theme('colors.white');
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: '';
    width: 45%;
    height: 70%;
    left: 6%;
    border-radius: 50%;
    bottom: 15%;
    background-color: theme('colors.grey[80]');
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: theme('colors.blue.DEFAULT');
  }

  input:checked + .slider:before {
    transform: translateX(100%);
    background-color: theme('colors.white');
  }
`;

interface Props {
  className?: string;
  checked?: boolean;
  onChange?: any;
}

const FormCheckSwitch = ({ checked, className, onChange }: Props) => {
  return (
    <label className={clsx(styles.wrapper, className)}>
      <input
        type="checkbox"
        className="sr-only"
        checked={checked}
        onChange={onChange}
      />
      <span className={clsx(styles.slider)} />
    </label>
  );
};

export default FormCheckSwitch;
