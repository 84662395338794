import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { array, object } from 'yup';

import RelayForm from 'components/RelayForm';
import actionMessages from 'messages/actions';
import Analytics from 'utils/Analytics';
import withModal from 'utils/withModal';

import FinalizationCandidatesListbox from './FinalizationCandidatesListbox';
import type { RequestFinalizationModal_Mutation as Mutation } from './__generated__/RequestFinalizationModal_Mutation.graphql';
import type { RequestFinalizationModal_study$data as Study } from './__generated__/RequestFinalizationModal_study.graphql';

const mutation = graphql`
  mutation RequestFinalizationModal_Mutation(
    $input: RequestStudyFinalizationInput!
  ) {
    requestStudyFinalizationOrError(input: $input) {
      ... on RequestStudyFinalizationPayload {
        study {
          isPendingFinalization
          finalizationRequests {
            recipient {
              id
              name
            }
            ...ExamStatus_finalizationRequests
          }
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

const schema = object({
  finalizationRequests: array().of(object().defined()).default([]).min(1),
});

interface Props {
  study: Study;
  onHide: () => void;
  onCompleted?: () => void;
}

function RequestFinalizationModal({ study, onCompleted, onHide }: Props) {
  return (
    <RelayForm<Mutation, typeof schema>
      schema={schema}
      mutation={mutation}
      getInput={(input) => ({
        ...input,
        studyId: study.id,
        finalizationRequests: input.finalizationRequests.map(
          ({ userProfile }) => ({ recipientId: userProfile.id }),
        ),
      })}
      onCompleted={() => {
        if (onCompleted) {
          onCompleted();
          Analytics.track('studyAttestationRequested', {
            studyId: study.id,
            organizationId: study.organization?.id,
          });
        } else {
          onHide();
        }
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="requestFinalization.title"
            defaultMessage="Attestation Required"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Layout direction="column" pad={6}>
          <Text>
            <FormattedMessage
              id="requestFinalization.description.sign"
              defaultMessage="This study requires an archive member with signing permissions. It will be submitted as a “Draft (Pending attestation).”"
            />
          </Text>

          <Form.FieldGroup
            name="finalizationRequests"
            className="mb-1"
            label={
              <FormattedMessage
                id="requestFinalization.label"
                defaultMessage="Notify a member with permission"
              />
            }
          >
            {(props) => (
              <FinalizationCandidatesListbox {...props} study={study} />
            )}
          </Form.FieldGroup>
        </Layout>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Form.Submit size="lg" variant="primary">
            <FormattedMessage {...actionMessages.submit} />
          </Form.Submit>
          <Button size="lg" variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </RelayForm>
  );
}

export default createFragmentContainer(withModal(RequestFinalizationModal), {
  study: graphql`
    fragment RequestFinalizationModal_study on Study {
      id
      organization {
        id
      }
      ...FinalizationCandidatesListbox_study
    }
  `,
});
