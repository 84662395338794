import { graphql } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkIsScopedUnderTenantOr404,
  checkTenantPermission,
} from 'utils/RouteAccessControl';

import WorkflowDigestsPage from './components/WorkflowDigestsPage';

export default (
  <Route
    path="workflow-email-digests"
    Component={WorkflowDigestsPage}
    query={graphql`
      query workflowDigests_WorkflowDigestsQuery($organizationSlug: String) {
        viewer {
          ...WorkflowDigestsPage_viewer
        }
        tenant(slug: $organizationSlug) {
          ...RouteAccessControl_tenant
        }
      }
    `}
    prerender={chainPrerenders(
      checkIsScopedUnderTenantOr404,
      checkFlagsOr404((ldClient) =>
        getVariation(ldClient, 'workflow-digests'),
      ),
      checkTenantPermission('studyDocumentationManagement', 'ADMIN'),
    )}
  />
);
