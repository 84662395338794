/**
 * @generated SignedSource<<5d07fd1cd43d29643ebc35d65f01267c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type connections_DomainCreateDicomConnectionPage_Query$variables = {};
export type connections_DomainCreateDicomConnectionPage_Query$data = {
  readonly viewer: {
    readonly domain: {
      readonly " $fragmentSpreads": FragmentRefs<"CreateDicomConnectionPage_connectivityConfig">;
    } | null;
    readonly memberships: ReadonlyArray<{
      readonly organization: {
        readonly " $fragmentSpreads": FragmentRefs<"CreateDicomConnectionPage_organization">;
      } | null;
    } | null> | null;
  } | null;
};
export type connections_DomainCreateDicomConnectionPage_Query = {
  response: connections_DomainCreateDicomConnectionPage_Query$data;
  variables: connections_DomainCreateDicomConnectionPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "connections_DomainCreateDicomConnectionPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerMembership",
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CreateDicomConnectionPage_organization"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreateDicomConnectionPage_connectivityConfig"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "connections_DomainCreateDicomConnectionPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerMembership",
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationSubscription",
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "practiceType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "organizationId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Organization",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "domainId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Domain",
                        "abstractKey": null
                      }
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "type": "ConnectivityConfigInterface",
                "abstractKey": "__isConnectivityConfigInterface"
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a13137eb77d07126cac217ed6dfbed52",
    "id": null,
    "metadata": {},
    "name": "connections_DomainCreateDicomConnectionPage_Query",
    "operationKind": "query",
    "text": "query connections_DomainCreateDicomConnectionPage_Query {\n  viewer {\n    memberships {\n      organization {\n        ...CreateDicomConnectionPage_organization\n        id\n      }\n      id\n    }\n    domain {\n      ...CreateDicomConnectionPage_connectivityConfig\n      id\n    }\n    id\n  }\n}\n\nfragment CreateDicomConnectionPage_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  ...useTenantIdInput_tenant\n}\n\nfragment CreateDicomConnectionPage_organization on Organization {\n  ...DicomConnectionForm_organization\n}\n\nfragment DicomConnectionForm_organization on Organization {\n  subscription {\n    practiceType\n  }\n  ...SecurityModeInput_organization\n}\n\nfragment SecurityModeInput_organization on Organization {\n  subscription {\n    practiceType\n  }\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "738c424aea4915e34187212a9fac36cf";

export default node;
