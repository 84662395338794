/**
 * @generated SignedSource<<8c122f0d5e85a034b2a94488097877f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ImageCaptureMode = "BIPLANE" | "B_MODE" | "B_MODE_3D" | "COLOR_DOPPLER" | "M_MODE" | "POWER_DOPPLER" | "PULSED_WAVE_DOPPLER" | "%future added value";
export type StudySource = "BUTTERFLY" | "THIRD_PARTY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExamImagePage_study$data = {
  readonly archive: {
    readonly deletedAt: string | null;
    readonly label: string | null;
  } | null;
  readonly deletedAt: string | null;
  readonly finalizedAt: string | null;
  readonly imageConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly burnedInFile: {
          readonly dataUrl: string | null;
          readonly isVideo: boolean | null;
        } | null;
        readonly captureMode: ImageCaptureMode | null;
        readonly capturedAt: string | null;
        readonly handle: string | null;
        readonly id: string;
        readonly source: StudySource | null;
        readonly viewerIsFollowing: boolean | null;
        readonly " $fragmentSpreads": FragmentRefs<"ExamImagePageFooter_studyImage" | "ExamImageRoll_images" | "StudyImage_image">;
      } | null;
    } | null> | null;
  } | null;
  readonly numImages: number | null;
  readonly organization: {
    readonly viewerCanComment: {
      readonly value?: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "ExamImagePage_study";
};
export type ExamImagePage_study$key = {
  readonly " $data"?: ExamImagePage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamImagePage_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "imageConnection"
        ]
      }
    ]
  },
  "name": "ExamImagePage_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": "viewerCanComment",
          "args": [
            {
              "kind": "Literal",
              "name": "key",
              "value": "cloud.commenting.study_image"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "viewerHasEntitlement",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "type": "BooleanEntitlement",
              "abstractKey": null
            }
          ],
          "storageKey": "viewerHasEntitlement(key:\"cloud.commenting.study_image\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "imageConnection",
      "args": null,
      "concreteType": "StudyImageConnection",
      "kind": "LinkedField",
      "name": "__ExamImagePage_imageConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyImageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyImage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "handle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "capturedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "captureMode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "source",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "viewerIsFollowing",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BurnedInFile",
                  "kind": "LinkedField",
                  "name": "burnedInFile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isVideo",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dataUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudyImage_image"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ExamImageRoll_images"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ExamImagePageFooter_studyImage"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "23e40a379dcd0d55aaed75a250874d29";

export default node;
