import { useField } from '@bfly/ui2/Form';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import { createFragmentContainer, graphql } from 'react-relay';

import { Authors, ExamAuthorsFormField } from 'components/ExamAuthorsField';
import Analytics from 'utils/Analytics';

import { SplitStudyAuthorField_sourceStudy$data as SourceStudy } from './__generated__/SplitStudyAuthorField_sourceStudy.graphql';

interface Props {
  sourceStudy: SourceStudy;
}

function SplitStudyAuthorField({ sourceStudy }: Props) {
  const [createdBy] = useField('createdBy');
  const [secondaryAuthorUserIds] = useField('secondaryAuthorUserIds');
  const [transcribedBy] = useField('transcribedBy');

  const authors = {
    createdBy: createdBy.value,
    secondaryAuthors: secondaryAuthorUserIds.value,
    transcribedBy: transcribedBy.value,
  };

  if (!sourceStudy.archive?.id) return <LoadingIndicator />;

  return (
    <ExamAuthorsFormField
      value={authors}
      archiveId={sourceStudy.archive.id}
      organizationId={sourceStudy.organization!.id}
      studyId={sourceStudy.id}
      onChange={(updates: Authors) => {
        createdBy.onChange(updates.createdBy);
        secondaryAuthorUserIds.onChange(updates.secondaryAuthors);
        transcribedBy.onChange(updates.transcribedBy);
        Analytics.track('splitStudyAuthorChanged', {
          studyId: sourceStudy.id,
          organizationId: sourceStudy.organization?.id,
          previous: authors,
          updates,
        });
      }}
    />
  );
}

export default createFragmentContainer(SplitStudyAuthorField, {
  sourceStudy: graphql`
    fragment SplitStudyAuthorField_sourceStudy on Study {
      id
      archive {
        id
      }
      createdBy {
        id
        ...ExamAuthorsField_users
      }
      transcribedBy {
        id
        ...ExamAuthorsField_users
      }
      secondaryAuthors {
        id
        ...ExamAuthorsField_users
      }
      organization {
        id
      }
    }
  `,
});
