import clsx from 'clsx';

interface Props {
  width: number;
  className?: string;
}

export default function BlankAvatar({ width, className }: Props) {
  return (
    <div
      className={clsx(className, 'inline-block rounded-full bg-grey-50')}
      style={{ width, height: width }}
    />
  );
}
