import Anchor from '@bfly/ui2/Anchor';
import Link from '@bfly/ui2/Link';
import Modal from '@bfly/ui2/Modal';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { cloudStatusPageUrl, marketingWebsiteUrl } from 'config/app';
import { useAccountRoutes } from 'routes/account';
import withModal from 'utils/withModal';

import { useVariationAllowMissingLdContext } from './LaunchDarklyContext';

function AboutModal() {
  const accountRoutes = useAccountRoutes();
  const butterflyCloudVersion =
    useVariationAllowMissingLdContext('release-version');
  return (
    <div>
      <Modal.Header>
        <Modal.Title data-bni-id="AboutButterflyTitle">
          <FormattedMessage
            id="aboutModal.title"
            defaultMessage="About Butterfly Cloud"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body data-bni-id="AboutButterflyItemsList">
        <p>
          <FormattedMessage
            id="aboutModal.versionParagraph"
            defaultMessage="Butterfly Cloud {butterflyCloudVersion}"
            values={{ butterflyCloudVersion }}
          />
        </p>
        <p>Butterfly Network, Inc., 1600 District Ave, Burlington, MA 01803</p>
        <p data-bni-id="MarketingWebsiteUrl">
          <Anchor
            href={marketingWebsiteUrl}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            {marketingWebsiteUrl}
          </Anchor>
        </p>
        <p data-bni-id="EULA">
          <Link to={accountRoutes.eula()}>
            <FormattedMessage
              id="aboutModal.eula"
              defaultMessage="End-User License Agreement (EULA)"
            />
          </Link>
        </p>
        <p data-bni-id="PrivacyPolicy">
          <Anchor
            href="https://www.butterflynetwork.com/privacy-policy"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <FormattedMessage
              id="aboutModal.privacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </Anchor>
        </p>
        <p data-bni-id="CookieNotice">
          <Anchor
            href="https://www.butterflynetwork.com/cookie-notice"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <FormattedMessage
              id="aboutModal.cookieNotice"
              defaultMessage="Cookie Notice"
            />
          </Anchor>
        </p>
        <p data-bni-id="TermsOfUse">
          <Anchor
            href="https://www.butterflynetwork.com/terms-of-use"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <FormattedMessage
              id="aboutModal.termsOfUse"
              defaultMessage="Terms of Use"
            />
          </Anchor>
        </p>
        <p data-bni-id="StatusPage">
          <Anchor
            href={cloudStatusPageUrl}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <FormattedMessage
              id="aboutModal.status"
              defaultMessage="Status Page"
            />
          </Anchor>
        </p>
      </Modal.Body>
    </div>
  );
}

export default withModal(AboutModal, { backdrop: true, variant: 'dark' });
