/**
 * @generated SignedSource<<febcc86932fb0e43eec530f02db7ed12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseAppPage_searchNodes$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"AppLeadingNav_searchNodes">;
  readonly " $fragmentType": "BaseAppPage_searchNodes";
}>;
export type BaseAppPage_searchNodes$key = ReadonlyArray<{
  readonly " $data"?: BaseAppPage_searchNodes$data;
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_searchNodes">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BaseAppPage_searchNodes",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppLeadingNav_searchNodes"
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};

(node as any).hash = "a4a1449428dd7a5fac3dd9e55bbf3cf0";

export default node;
