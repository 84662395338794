import Layout from '@4c/layout';
import FailureIcon from '@bfly/icons/FailureOutline';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import ItemGrid from '@bfly/ui2/ItemGrid';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import { stylesheet } from 'astroturf';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Abbr from 'components/Abbr';
import PatientName from 'components/PatientName';
import actionMessages from 'messages/actions';
import messages, { sexMessages } from 'messages/patient';

import { SplitStudyConfirmPatientDataConflictContent_destinationStudy$data as DestinationStudy } from './__generated__/SplitStudyConfirmPatientDataConflictContent_destinationStudy.graphql';
import { SplitStudyConfirmPatientDataConflictContent_sourceStudy$data as SourceStudy } from './__generated__/SplitStudyConfirmPatientDataConflictContent_sourceStudy.graphql';

interface Props {
  sourceStudy: SourceStudy;
  destinationStudy: DestinationStudy;
  onBack: () => void;
}

const styles = stylesheet`
  .icon {
    composes: mr-1 from global;

    vertical-align: middle;
  }

  .patient-name {
    overflow-wrap: break-word;
    overflow: hidden;
    max-width: fit-content;
  }
`;

const PatientNameCell = ({ patient }) => {
  if (!patient) return null;
  return (
    <ItemGrid.Cell>
      <PatientName className={styles.patientName} patient={patient} />
    </ItemGrid.Cell>
  );
};

const PatientSexCell = ({ patient }) => {
  if (!patient) return null;
  return (
    <ItemGrid.Cell>
      {patient.sex && <FormattedMessage {...sexMessages[patient.sex]} />}
    </ItemGrid.Cell>
  );
};

const PatientDOBCell = ({ patient }) => {
  if (!patient) return null;
  return (
    <ItemGrid.Cell>
      {patient.birthDate && (
        <FormattedDate value={patient.birthDate} timeZone="UTC" />
      )}
    </ItemGrid.Cell>
  );
};

const PatientMRNCell = ({ patient }) => {
  if (!patient) return null;
  return (
    <ItemGrid.Cell>
      {patient.medicalRecordNumber || (
        <Text color="subtitle">
          <FormattedMessage {...messages.noMedicalRecordNumber} />
        </Text>
      )}
    </ItemGrid.Cell>
  );
};

function SplitStudyConfirmPatientDataConflictContent({
  sourceStudy,
  destinationStudy,
  onBack,
}: Props) {
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="splitStudy.confirmPatientDataConflict.title"
            defaultMessage="Conflicting patient details"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Layout direction="column" pad>
          <Text color="subtitle">
            <FailureIcon height={15} className={styles.icon} />
            <FormattedMessage
              id="splitStudy.confirmPatientDataConflict.warning"
              defaultMessage="The two drafts’ patient details do not match. The target draft’s patient details will not be overwritten."
            />
          </Text>
          <ItemGrid
            role="table"
            templateColumns="minmax(0, 1fr) minmax(0, 1fr) auto"
          >
            <thead>
              <ItemGrid.Row>
                <ItemGrid.Header textAlign="center"> </ItemGrid.Header>
                <ItemGrid.Header textAlign="center">
                  <FormattedMessage
                    id="splitStudyConfirmPatientDataConflictContent.originatingDraft"
                    defaultMessage="Originating draft"
                  />
                </ItemGrid.Header>
                <ItemGrid.Header textAlign="center">
                  <FormattedMessage
                    id="splitStudyConfirmPatientDataConflictContent.targetDraft"
                    defaultMessage="Target draft"
                  />
                </ItemGrid.Header>
              </ItemGrid.Row>
            </thead>

            <tbody>
              <ItemGrid.Row>
                <ItemGrid.Cell color="subtitle">
                  <FormattedMessage {...messages.name} />
                </ItemGrid.Cell>
                <PatientNameCell patient={sourceStudy.patient} />
                <PatientNameCell patient={destinationStudy.patient} />
              </ItemGrid.Row>
              <ItemGrid.Row>
                <ItemGrid.Cell color="subtitle">
                  <FormattedMessage {...messages.sex} />
                </ItemGrid.Cell>
                <PatientSexCell patient={sourceStudy.patient} />
                <PatientSexCell patient={destinationStudy.patient} />
              </ItemGrid.Row>
              <ItemGrid.Row>
                <ItemGrid.Cell color="subtitle">
                  <Abbr
                    label={messages.dateOfBirthLabel}
                    message={messages.birthDate}
                  />
                </ItemGrid.Cell>
                <PatientDOBCell patient={sourceStudy.patient} />
                <PatientDOBCell patient={destinationStudy.patient} />
              </ItemGrid.Row>
              <ItemGrid.Row>
                <ItemGrid.Cell color="subtitle">
                  <Abbr
                    label={messages.medicalRecordNumber}
                    message={messages.medicalRecordNumber}
                  />
                </ItemGrid.Cell>
                <PatientMRNCell patient={sourceStudy.patient} />
                <PatientMRNCell patient={destinationStudy.patient} />
              </ItemGrid.Row>
            </tbody>
          </ItemGrid>
        </Layout>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Form.Submit variant="primary" size="lg">
            <FormattedMessage {...actionMessages.confirm} />
          </Form.Submit>
          <Button size="lg" variant="secondary" onClick={onBack}>
            <FormattedMessage {...actionMessages.back} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(
  SplitStudyConfirmPatientDataConflictContent,
  {
    sourceStudy: graphql`
      fragment SplitStudyConfirmPatientDataConflictContent_sourceStudy on Study {
        accessionNumber
        patient {
          sex
          birthDate
          medicalRecordNumber
          ...PatientName_patient
        }
      }
    `,
    destinationStudy: graphql`
      fragment SplitStudyConfirmPatientDataConflictContent_destinationStudy on Study {
        accessionNumber
        patient {
          sex
          birthDate
          medicalRecordNumber
          ...PatientName_patient
        }
      }
    `,
  },
);
