import { graphql, readInlineData } from 'react-relay';

import { useTenantIdInput_tenant$key as TenantKey } from './__generated__/useTenantIdInput_tenant.graphql';

/**
 * Some settings can be configured either on the organization or the domain level
 * (for example, connectivity or worksheets/qa) and the mutation payload requires
 * either an organizationId or a domainId depending on the parent node type.
 */
export default function useTenantIdInput(
  tenantRef: TenantKey,
): { organizationId: string } | { domainId: string } {
  return readInlineData(
    graphql`
      fragment useTenantIdInput_tenant on Node @inline {
        ... on Organization {
          organizationId: id
        }
        ... on Domain {
          domainId: id
        }
      }
    `,
    tenantRef,
  ) as { organizationId: string } | { domainId: string };
}
