/**
 * @generated SignedSource<<1dfac17b378f3b7015909d425961cc5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MoveStudyModalRefresh_domain$data = {
  readonly id: string;
  readonly " $fragmentType": "MoveStudyModalRefresh_domain";
};
export type MoveStudyModalRefresh_domain$key = {
  readonly " $data"?: MoveStudyModalRefresh_domain$data;
  readonly " $fragmentSpreads": FragmentRefs<"MoveStudyModalRefresh_domain">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MoveStudyModalRefresh_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};

(node as any).hash = "4f399a96c753f96ce78145543bcdffb6";

export default node;
