/**
 * @generated SignedSource<<ca9b1e970bf13a8768cff4ad2af16bb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedInterpretationType = "ANATOMICAL_LABEL" | "APICAL_EF" | "BLADDER_VOLUME" | "BLINE_COUNT" | "CAPTION_ECHO" | "%future added value";
export type useSharedInterpretationImage_InterpretationsQuery$variables = {
  imageId: string;
};
export type useSharedInterpretationImage_InterpretationsQuery$data = {
  readonly sharedBundleImage: {
    readonly sharedInterpretations?: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"useInterpretationImage_interpretation">;
    } | null> | null;
    readonly type?: ReadonlyArray<{
      readonly interpretationType: SharedInterpretationType | null;
    } | null> | null;
  } | null;
};
export type useSharedInterpretationImage_InterpretationsQuery = {
  response: useSharedInterpretationImage_InterpretationsQuery$data;
  variables: useSharedInterpretationImage_InterpretationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "imageId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "imageId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "attachmentKey",
    "value": "BURNED_IN_IMAGE_FILE"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataUrl",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Literal",
    "name": "attachmentKey",
    "value": "VOLUME_RENDER_FILE"
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "attachmentKey",
    "value": "STATIC_REPORT_FILE"
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "attachmentKey",
    "value": "DL_STILL_IMAGE_FILE"
  }
],
v8 = {
  "alias": "type",
  "args": null,
  "concreteType": "SharedInterpretation",
  "kind": "LinkedField",
  "name": "sharedInterpretations",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interpretationType",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v9/*: any*/),
  (v3/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v10/*: any*/)
    ],
    "type": "Node",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSharedInterpretationImage_InterpretationsQuery",
    "selections": [
      {
        "alias": "sharedBundleImage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SharedInterpretation",
                "kind": "LinkedField",
                "name": "sharedInterpretations",
                "plural": true,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "useInterpretationImage_interpretation",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": (v2/*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "attachment",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": "attachment(attachmentKey:\"BURNED_IN_IMAGE_FILE\")"
                          },
                          {
                            "alias": "volumeRender",
                            "args": (v5/*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "attachment",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": "attachment(attachmentKey:\"VOLUME_RENDER_FILE\")"
                          },
                          {
                            "alias": "staticRender",
                            "args": (v6/*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "attachment",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": "attachment(attachmentKey:\"STATIC_REPORT_FILE\")"
                          },
                          {
                            "alias": "stillBurnIn",
                            "args": (v7/*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "attachment",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": "attachment(attachmentKey:\"DL_STILL_IMAGE_FILE\")"
                          }
                        ],
                        "type": "InterpretationInterface",
                        "abstractKey": "__isInterpretationInterface"
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "type": "SharedBundleImage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSharedInterpretationImage_InterpretationsQuery",
    "selections": [
      {
        "alias": "sharedBundleImage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SharedInterpretation",
                "kind": "LinkedField",
                "name": "sharedInterpretations",
                "plural": true,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": (v2/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachment",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": "attachment(attachmentKey:\"BURNED_IN_IMAGE_FILE\")"
                      },
                      {
                        "alias": "volumeRender",
                        "args": (v5/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachment",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": "attachment(attachmentKey:\"VOLUME_RENDER_FILE\")"
                      },
                      {
                        "alias": "staticRender",
                        "args": (v6/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachment",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": "attachment(attachmentKey:\"STATIC_REPORT_FILE\")"
                      },
                      {
                        "alias": "stillBurnIn",
                        "args": (v7/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachment",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": "attachment(attachmentKey:\"DL_STILL_IMAGE_FILE\")"
                      }
                    ],
                    "type": "InterpretationInterface",
                    "abstractKey": "__isInterpretationInterface"
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "type": "SharedBundleImage",
            "abstractKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e72fd03fc085bcc6cf16fc0feb84970",
    "id": null,
    "metadata": {},
    "name": "useSharedInterpretationImage_InterpretationsQuery",
    "operationKind": "query",
    "text": "query useSharedInterpretationImage_InterpretationsQuery(\n  $imageId: ID!\n) {\n  sharedBundleImage: node(id: $imageId) {\n    __typename\n    ... on SharedBundleImage {\n      sharedInterpretations {\n        ...useInterpretationImage_interpretation\n      }\n      type: sharedInterpretations {\n        interpretationType\n      }\n    }\n    id\n  }\n}\n\nfragment useInterpretationImage_interpretation on InterpretationInterface {\n  __isInterpretationInterface: __typename\n  attachment(attachmentKey: \"BURNED_IN_IMAGE_FILE\") {\n    __typename\n    dataUrl\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  volumeRender: attachment(attachmentKey: \"VOLUME_RENDER_FILE\") {\n    __typename\n    dataUrl\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  staticRender: attachment(attachmentKey: \"STATIC_REPORT_FILE\") {\n    __typename\n    dataUrl\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  stillBurnIn: attachment(attachmentKey: \"DL_STILL_IMAGE_FILE\") {\n    __typename\n    dataUrl\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3aa3ea16018780d587153b4e5f3e3d81";

export default node;
