import { useDialog } from '@bfly/ui2/DialogContext';
import { FormattedMessage } from 'react-intl';

import defaultMessages, { Messages } from '../messages';

export default function useSaveConfirmationModal(
  messages: Messages = defaultMessages,
) {
  const dialog = useDialog();
  return (count: number) =>
    dialog.open(
      <div
        css={`
          p:last-child {
            margin-bottom: 0;
          }
        `}
      >
        <FormattedMessage
          {...messages.confirmSaveDialogContent}
          values={{ p: (msg: string) => <p>{msg}</p>, count }}
        />
      </div>,
      {
        title: <FormattedMessage {...messages.confirmSaveDialogTitle} />,
        confirmLabel: (
          <FormattedMessage {...messages.confirmSaveDialogConfirmLabel} />
        ),
        cancelLabel: (
          <FormattedMessage {...messages.confirmSaveDialogDiscardLabel} />
        ),

        modalVariant: 'dark',
        size: 'sm',
        confirmButtonProps: {
          size: 'md',
        },
        cancelButtonProps: {
          size: 'md',
          variant: 'secondary',
        },
      },
    );
}
