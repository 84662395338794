/**
 * @generated SignedSource<<123d1941a0cd48b510a14e6298ff763d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type connections_DomainUpdateButterflyLinkPage_Query$variables = {
  hospitalConnectionHandle: string;
};
export type connections_DomainUpdateButterflyLinkPage_Query$data = {
  readonly butterflyLink: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdateButterflyLinkPage_butterflyLink">;
  } | null;
};
export type connections_DomainUpdateButterflyLinkPage_Query = {
  response: connections_DomainUpdateButterflyLinkPage_Query$data;
  variables: connections_DomainUpdateButterflyLinkPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hospitalConnectionHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "hospitalConnectionHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "connections_DomainUpdateButterflyLinkPage_Query",
    "selections": [
      {
        "alias": "butterflyLink",
        "args": (v1/*: any*/),
        "concreteType": "ButterflyLinkConnection",
        "kind": "LinkedField",
        "name": "butterflyLinkConnection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateButterflyLinkPage_butterflyLink"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "connections_DomainUpdateButterflyLinkPage_Query",
    "selections": [
      {
        "alias": "butterflyLink",
        "args": (v1/*: any*/),
        "concreteType": "ButterflyLinkConnection",
        "kind": "LinkedField",
        "name": "butterflyLinkConnection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnected",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isTestConnection",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "connectivityConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "organizationId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Organization",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "domainId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Domain",
                        "abstractKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isNode"
              }
            ],
            "type": "HospitalConnectionInterface",
            "abstractKey": "__isHospitalConnectionInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83f1ecf8c11046d115a28e670ad72ced",
    "id": null,
    "metadata": {},
    "name": "connections_DomainUpdateButterflyLinkPage_Query",
    "operationKind": "query",
    "text": "query connections_DomainUpdateButterflyLinkPage_Query(\n  $hospitalConnectionHandle: String!\n) {\n  butterflyLink: butterflyLinkConnection(handle: $hospitalConnectionHandle) {\n    ...UpdateButterflyLinkPage_butterflyLink\n    id\n  }\n}\n\nfragment ButterflyLinkForm_butterflyLink on ButterflyLinkConnection {\n  id\n  name\n}\n\nfragment ButterflyLinkStatusControl_butterflyLink on ButterflyLinkConnection {\n  isConnected\n}\n\nfragment ButterflyLinkTestModeControl_butterflyLink on ButterflyLinkConnection {\n  id\n  isTestConnection\n}\n\nfragment DeleteHospitalConnectionSection_hospitalConnection on HospitalConnectionInterface {\n  __isHospitalConnectionInterface: __typename\n  name\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  connectivityConfig {\n    __typename\n    ...useTenantIdInput_tenant\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment UpdateButterflyLinkPage_butterflyLink on ButterflyLinkConnection {\n  name\n  ...ButterflyLinkForm_butterflyLink\n  ...ButterflyLinkStatusControl_butterflyLink\n  ...ButterflyLinkTestModeControl_butterflyLink\n  ...DeleteHospitalConnectionSection_hospitalConnection\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f10c60721d6f6c8d8ceb21a2615e95f0";

export default node;
