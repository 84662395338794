/**
 * @generated SignedSource<<ea5de934df163f64fff8c9492cee0a6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyDataGrid_studiesWithSync$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"StudySyncStatus_study">;
  readonly " $fragmentType": "StudyDataGrid_studiesWithSync";
}>;
export type StudyDataGrid_studiesWithSync$key = ReadonlyArray<{
  readonly " $data"?: StudyDataGrid_studiesWithSync$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyDataGrid_studiesWithSync">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudyDataGrid_studiesWithSync",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudySyncStatus_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "98f548bb06865e7f5cf042fa7b730278";

export default node;
