import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import SendStudyToEndpointButton from 'components/SendStudyToEndpointButton';

import { SendStudyToEhrButtonMutation } from './__generated__/SendStudyToEhrButtonMutation.graphql';
import { SendStudyToEhrButton_ehr$data as Ehr } from './__generated__/SendStudyToEhrButton_ehr.graphql';
import { SendStudyToEhrButton_job$data as Job } from './__generated__/SendStudyToEhrButton_job.graphql';
import { SendStudyToEhrButton_study$data as Study } from './__generated__/SendStudyToEhrButton_study.graphql';

interface Props {
  study: Study;
  ehr: Ehr;
  job: Job | null;
}

function SendStudyToEhrButton({ study, ehr, job }: Props) {
  return (
    <SendStudyToEndpointButton<SendStudyToEhrButtonMutation>
      job={job}
      mutation={graphql`
        mutation SendStudyToEhrButtonMutation($input: CreateEhrOruJobInput!) {
          createEhrOruJobOrError(input: $input) {
            ... on CreateEhrOruJobPayload {
              study {
                ...StudyEndpointsModalContent_study
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `}
      input={{
        studyId: study.id,
        ehrId: ehr.id,
      }}
    />
  );
}

export default createFragmentContainer(SendStudyToEhrButton, {
  study: graphql`
    fragment SendStudyToEhrButton_study on Study {
      id
    }
  `,
  ehr: graphql`
    fragment SendStudyToEhrButton_ehr on Ehr {
      id
    }
  `,
  job: graphql`
    fragment SendStudyToEhrButton_job on EhrOruJob {
      id
      status
    }
  `,
});
