/**
 * @generated SignedSource<<ee2133e2b46cd9e9596f00482979ebc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EduStudySorting = "CAPTURED_AT_ASC" | "CAPTURED_AT_DESC" | "CONDUCTED_AT_ASC" | "CONDUCTED_AT_DESC" | "READY_AT_ASC" | "READY_AT_DESC" | "REVIEWED_AT_ASC" | "REVIEWED_AT_DESC" | "REVIEWED_IMAGE_QUALITY_ASC" | "REVIEWED_IMAGE_QUALITY_DESC" | "REVIEW_REQUESTED_AT_ASC" | "REVIEW_REQUESTED_AT_DESC" | "ZONES_CAPTURED_ASC" | "ZONES_CAPTURED_DESC" | "%future added value";
export type EduStudyPage_pagination_Query$variables = {
  author?: ReadonlyArray<string> | null;
  conductedAtMax?: string | null;
  conductedAtMin?: string | null;
  cursor?: string | null;
  examTypeHandles?: ReadonlyArray<string> | null;
  groupId?: ReadonlyArray<string> | null;
  isReviewRequested?: boolean | null;
  isReviewed?: boolean | null;
  sort?: ReadonlyArray<EduStudySorting> | null;
};
export type EduStudyPage_pagination_Query$data = {
  readonly viewer: {
    readonly next: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly handle: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly prev: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly handle: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type EduStudyPage_pagination_Query = {
  response: EduStudyPage_pagination_Query$data;
  variables: EduStudyPage_pagination_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "author"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conductedAtMax"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conductedAtMin"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "examTypeHandles"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isReviewRequested"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isReviewed"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v9 = {
  "kind": "Variable",
  "name": "author",
  "variableName": "author"
},
v10 = {
  "kind": "Variable",
  "name": "conductedAtMax",
  "variableName": "conductedAtMax"
},
v11 = {
  "kind": "Variable",
  "name": "conductedAtMin",
  "variableName": "conductedAtMin"
},
v12 = {
  "kind": "Variable",
  "name": "examTypeHandles",
  "variableName": "examTypeHandles"
},
v13 = {
  "kind": "Variable",
  "name": "groupId",
  "variableName": "groupId"
},
v14 = {
  "kind": "Variable",
  "name": "isReviewRequested",
  "variableName": "isReviewRequested"
},
v15 = {
  "kind": "Variable",
  "name": "isReviewed",
  "variableName": "isReviewed"
},
v16 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v17 = [
  (v9/*: any*/),
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "cursor"
  },
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  {
    "kind": "Literal",
    "name": "last",
    "value": 1
  },
  (v16/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v20 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EduStudyEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v18/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "EduStudy",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v21 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/)
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v23 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EduStudyEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v18/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "EduStudy",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v19/*: any*/),
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EduStudyPage_pagination_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "prev",
            "args": (v17/*: any*/),
            "concreteType": "EduStudyConnection",
            "kind": "LinkedField",
            "name": "eduStudyConnection",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": "next",
            "args": (v21/*: any*/),
            "concreteType": "EduStudyConnection",
            "kind": "LinkedField",
            "name": "eduStudyConnection",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v8/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "EduStudyPage_pagination_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "prev",
            "args": (v17/*: any*/),
            "concreteType": "EduStudyConnection",
            "kind": "LinkedField",
            "name": "eduStudyConnection",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          },
          {
            "alias": "next",
            "args": (v21/*: any*/),
            "concreteType": "EduStudyConnection",
            "kind": "LinkedField",
            "name": "eduStudyConnection",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f900ab502271dd3de11bda6a8febe411",
    "id": null,
    "metadata": {},
    "name": "EduStudyPage_pagination_Query",
    "operationKind": "query",
    "text": "query EduStudyPage_pagination_Query(\n  $sort: [EduStudySorting!]\n  $examTypeHandles: [String!]\n  $conductedAtMin: DateTime\n  $conductedAtMax: DateTime\n  $author: [String!]\n  $isReviewed: Boolean\n  $isReviewRequested: Boolean\n  $cursor: String\n  $groupId: [ID!]\n) {\n  viewer {\n    prev: eduStudyConnection(last: 1, before: $cursor, sort: $sort, examTypeHandles: $examTypeHandles, conductedAtMin: $conductedAtMin, conductedAtMax: $conductedAtMax, author: $author, isReviewed: $isReviewed, isReviewRequested: $isReviewRequested, groupId: $groupId) {\n      edges {\n        cursor\n        node {\n          handle\n          id\n        }\n      }\n    }\n    next: eduStudyConnection(first: 1, after: $cursor, sort: $sort, examTypeHandles: $examTypeHandles, conductedAtMin: $conductedAtMin, conductedAtMax: $conductedAtMax, author: $author, isReviewed: $isReviewed, isReviewRequested: $isReviewRequested, groupId: $groupId) {\n      edges {\n        cursor\n        node {\n          handle\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "26f17add00131a7061da97eea014c3be";

export default node;
