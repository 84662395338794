/**
 * @generated SignedSource<<f0061134ad70f910b85d9803d9d1c2a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AuthProvider_SessionInfoQuery$variables = {};
export type AuthProvider_SessionInfoQuery$data = {
  readonly viewer: {
    readonly authorizationExpiration: string | null;
    readonly domain: {
      readonly federatedIdentityProvider: {
        readonly clientId: string | null;
        readonly logoutEndpointBaseUrl: string | null;
      } | null;
      readonly id: string;
    } | null;
    readonly email: string | null;
    readonly localId: string | null;
    readonly profile: {
      readonly id: string;
    } | null;
  } | null;
};
export type AuthProvider_SessionInfoQuery = {
  response: AuthProvider_SessionInfoQuery$data;
  variables: AuthProvider_SessionInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Domain",
  "kind": "LinkedField",
  "name": "domain",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FederatedIdentityProvider",
      "kind": "LinkedField",
      "name": "federatedIdentityProvider",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoutEndpointBaseUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorizationExpiration",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthProvider_SessionInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthProvider_SessionInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a18c4f11e5ca34d13bb27d41fb004ee",
    "id": null,
    "metadata": {},
    "name": "AuthProvider_SessionInfoQuery",
    "operationKind": "query",
    "text": "query AuthProvider_SessionInfoQuery {\n  viewer {\n    localId\n    email\n    domain {\n      id\n      federatedIdentityProvider {\n        clientId\n        logoutEndpointBaseUrl\n      }\n    }\n    profile {\n      id\n    }\n    authorizationExpiration\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e5d8316b8a6dd3518d8d8c4b47a50eb";

export default node;
