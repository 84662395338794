import mapValues from 'lodash/mapValues';
import { defineMessages } from 'react-intl';
import { graphql, readInlineData } from 'react-relay';
import { DeepNonNullable } from 'utility-types';
import { mixed, object, string } from 'yup';

import { baseStudyInfo_selectedWorklistItemData$key as WorklistItemKey } from './__generated__/baseStudyInfo_selectedWorklistItemData.graphql';

export const formatWorklistItem = (worklistItemRef: WorklistItemKey) => {
  const worklistItem = readInlineData(
    graphql`
      fragment baseStudyInfo_selectedWorklistItemData on WorklistItemInterface
      @inline {
        ... on Node {
          id
        }
        accessionNumber
        studyDescription
        internalId
        dicomDocument
        ...baseStudyInfo_patient @relay(mask: false)
      }
    `,
    worklistItemRef,
  );
  const {
    id: sourceWorklistItemId,
    dicomDocument = {},
    ...result
  } = worklistItem as DeepNonNullable<typeof worklistItem>;

  return {
    ...mapValues({ sourceWorklistItemId, ...result }, (value, key) =>
      key.startsWith('name') && value === null ? '' : value,
    ),
    sourceWorklistItemId,
    dicomDocument,
  };
};

export const messages = defineMessages({
  invalidCharacters: {
    id: 'study.patient.invalidCharacters',
    defaultMessage: 'Cannot contain invalid characters',
  },
});

export const dicomString = (len: number) =>
  string()
    .max(len)
    .matches(/^[\w_\-,;:!?.'"()[\]{}@*/&#%`^+<=>|~$ ]*$/, {
      excludeEmptyString: true,
      message: messages.invalidCharacters as any,
    })
    .trim();

export const nameField = (len) =>
  string()
    .max(len)
    .matches(/^[^\\=^]*$/, {
      excludeEmptyString: true,
      message: messages.invalidCharacters as any,
    })
    .trim();

const schema = object({
  accessionNumber: dicomString(16).nullable(),
  sourceWorklistItemId: string().nullable(),
  dicomDocument: mixed().nullable().default({}),
  notes: string(),
  studyDescription: string()
    .nullable()
    .transform((v) => v || null),
});

export default schema;

{
  const _ = graphql`
    fragment baseStudyInfo_study on Study {
      notes
      studyDescription
    }
  `;
}

{
  const _ = graphql`
    fragment baseStudyInfo_patient on PatientInterface {
      nameFirst
      nameLast
      nameMiddle
      namePrefix
      nameSuffix
      sex
      birthDate
      medicalRecordNumber
    }
  `;
}
