import styled from 'astroturf/react';

const MultiTextInputGroup = styled('div')`
  & :global(.form-group) {
    margin-bottom: 0;

    & input {
      position: relative;
      border-radius: 0;
      margin-bottom: 1px;

      // pull focus ring up over input below or above
      &:focus {
        z-index: 1;
      }
    }

    &:first-child input {
      @apply rounded-t;
    }

    &:last-child input {
      @apply rounded-b;
      margin-bottom: 0;
    }
  }
`;

export default MultiTextInputGroup;
