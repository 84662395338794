import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import DropdownList from '@bfly/ui2/DropdownList';
import Form, { useField } from '@bfly/ui2/Form';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DebouncedSearchControl from 'components/DebouncedSearchControl';
import actionMessages from 'messages/actions';

import SplitStudyRefreshSelectDraftStudyList from './SplitStudyRefreshSelectDraftStudyList';
import { SplitStudyRefreshModalSelectExistingDraftDestinationQuery as ContentQuery } from './__generated__/SplitStudyRefreshModalSelectExistingDraftDestinationQuery.graphql';
import { SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy$data as SourceStudy } from './__generated__/SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy.graphql';

const messages = {
  allFolders: defineMessage({
    id: 'SplitStudyModalExistingDraftDestination.allFolders',
    defaultMessage: 'All folders',
  }),
  searchPlaceholder: defineMessage({
    id: 'SplitStudyModalExistingDraftDestination.searchPlaceholder',
    defaultMessage: 'Search by name, Patient ID, or author',
  }),
};

interface Props {
  sourceStudy: SourceStudy;
  onBack: () => void;
  onNext?: () => void;
}

function SplitStudyModalSelectExistingDraftDestination({
  sourceStudy,
  onBack,
  onNext,
}: Props) {
  const { organization } = sourceStudy.archive!;
  const archives = getNodes(organization?.archiveConnection);
  const [{ value: selectedArchive, onChange: setSelectedArchive }] = useField(
    'destinationArchive',
  );

  const [{ value: destinationStudyId }] = useField('destinationStudyId');

  const [freeText, setFreeText] = useState('');

  const { data } = useQuery<ContentQuery>(
    graphql`
      query SplitStudyRefreshModalSelectExistingDraftDestinationQuery(
        $id: ID!
        $search: StudySearchInput
      ) {
        node(id: $id) {
          ... on Organization {
            studySearchConnection(first: 200, search: $search) {
              edges {
                node {
                  ...SplitStudyRefreshSelectDraftStudyList_studies
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: {
        id: organization!.id,
        search: {
          status: ['DRAFT'],
          freeText,
          archive: selectedArchive ? [selectedArchive.id] : null,
        },
      },
    },
  );
  const nextStepDisabled = useMemo(
    () => !destinationStudyId,
    [destinationStudyId],
  );
  const studies = data?.node?.studySearchConnection
    ? getNodes(data?.node?.studySearchConnection)
    : [];

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="splitStudyRefresh.selectDestinationStudy.title"
            defaultMessage="Select existing draft"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex flex-col">
        <Layout flex className="w-full">
          <DebouncedSearchControl
            placeholder={messages.searchPlaceholder}
            debounceTimeout={500}
            value={freeText}
            onChange={setFreeText}
            className="w-1/2 pr-2"
            variant="secondary"
          />
          <DropdownList
            allowEmpty
            emptyValue=""
            data={archives}
            textField="label"
            value={selectedArchive}
            onChange={setSelectedArchive}
            className="mb-3 w-1/2"
            placeholder={messages.allFolders}
            emptyMessage={messages.allFolders}
            variant="secondary"
            menuVariant="dark"
          />
        </Layout>
        {data ? (
          <SplitStudyRefreshSelectDraftStudyList
            studies={studies}
            currentStudyId={sourceStudy.id}
            className="h-full"
            css="min-height: 6rem"
          />
        ) : (
          <LoadingIndicator />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          {!onNext ? (
            <Form.Submit
              variant="primary"
              size="lg"
              disabled={nextStepDisabled}
            >
              <FormattedMessage {...actionMessages.confirm} />
            </Form.Submit>
          ) : (
            <Button
              disabled={nextStepDisabled}
              size="lg"
              variant="primary"
              onClick={onNext}
            >
              <FormattedMessage {...actionMessages.next} />
            </Button>
          )}
          <Button size="lg" variant="secondary" onClick={onBack}>
            <FormattedMessage {...actionMessages.back} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(
  SplitStudyModalSelectExistingDraftDestination,
  {
    sourceStudy: graphql`
      fragment SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy on Study {
        id
        archive {
          id
          handle
          label
          organization {
            id
            archiveConnection(first: 2147483647) {
              edges {
                node {
                  id
                  label
                }
              }
            }
          }
        }
      }
    `,
  },
);
