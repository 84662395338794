import Layout from '@4c/layout';
import Form from '@bfly/ui2/Form';
import ItemGrid from '@bfly/ui2/ItemGrid';
import Text from '@bfly/ui2/Text';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { InferType, boolean, object } from 'yup';

import { useVariation } from 'components/LaunchDarklyContext';
import { usePermissions } from 'utils/viewerContext';

import useArchiveSettings from '../utils/useArchiveSettings';
import ArchiveStudyRestrictionListItem from './ArchiveStudyRestrictionsListItem';
import RestrictedFinalizationForm from './RestrictedFinalizationForm';
import {
  ArchiveStudyRestrictionsForm_Mutation as Mutation,
  UpdateArchiveInput,
} from './__generated__/ArchiveStudyRestrictionsForm_Mutation.graphql';
import { ArchiveStudyRestrictionsForm_archive$data as Archive } from './__generated__/ArchiveStudyRestrictionsForm_archive.graphql';

const mutation = graphql`
  mutation ArchiveStudyRestrictionsForm_Mutation($input: UpdateArchiveInput!) {
    updateArchiveOrError(input: $input) {
      ... on UpdateArchivePayload {
        archive {
          ...ArchiveStudyRestrictionsForm_archive
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

const schema = object({
  worklistRequiredToFinalize: boolean(),
  patientIdRequiredToFinalize: boolean().nullable(),
  worksheetRequiredToFinalize: boolean(),
  examTypeRequiredToFinalize: boolean(),
  studyAuthorRequiredToRequestFinalization: boolean(),
  hasRestrictedFinalization: boolean(),
  worksheetRequiredForQa: boolean(),
  excludedStudies: boolean(),
  multipleWorksheetsEnabled: boolean(),
  autoFinalizeNbdExams: boolean(),
});

const messages = defineMessages({
  defaultAttestationMessage: {
    id: 'archiveSettings.restrictFinalization.defaultNewAttestationMessage',
    // Intentionally not using smart quotes here because they do not render correctly in EHRs
    defaultMessage:
      'By tapping the "Sign" button, you are attesting that you were present when this scan was performed and agree with the findings',
  },
});

interface Props {
  archive: Archive;
}

function ArchiveStudyRestrictionsForm({ archive }: Props) {
  const intl = useIntl();
  const { hasAdminPermission, hasBasicPermission } = usePermissions();
  const canUseStudyRestrictions = useVariation('study-restrictions');
  const canUseDicomConnector = useVariation('dicom-connector');
  const canUseWorksheets = useVariation('worksheets');
  const restrictedStudyFinalization = useVariation(
    'restricted-study-finalization',
  );
  const canAutoFinalizeNbdExams = useVariation('auto-finalize-nbd-exams');

  const canQaWithoutWorksheet = useVariation('qa-without-a-worksheet');

  const canPreventMultipleWorksheets = useVariation('worksheets');

  const showPatientIdRequiredToFinalize = useVariation(
    'patient-id-required-signing',
  );

  const hasExamTypeManagementPermission = useMemo(() => {
    return hasBasicPermission('examTypeManagement');
  }, [hasBasicPermission]);

  const hasBasicStudyDocumentationManagementPermission = useMemo(() => {
    return hasBasicPermission('studyDocumentationManagement');
  }, [hasBasicPermission]);

  const hasArchiveSettingsPermission = useArchiveSettings();

  const canViewArchiveSettings =
    hasBasicStudyDocumentationManagementPermission ||
    hasArchiveSettingsPermission;

  const hasQaManagementPermission = useMemo(() => {
    return hasBasicPermission('qaManagement');
  }, [hasBasicPermission]);

  const [mutate, loading] = useMutationWithError<Mutation>(mutation);
  const [value, setValue] = useState<InferType<typeof schema>>(() =>
    schema.cast(archive, { stripUnknown: true }),
  );

  const defaultAttestationMessage = intl.formatMessage(
    messages.defaultAttestationMessage,
  );

  const handleChange = async (
    nextValue: InferType<typeof schema>,
    fieldsUpdated: string[],
  ) => {
    const input: UpdateArchiveInput = { ...nextValue, archiveId: archive.id };

    // Send up default attestation message if none set
    if (nextValue.hasRestrictedFinalization && !archive.attestationMessage) {
      input.attestationMessage = defaultAttestationMessage;
    }

    if (fieldsUpdated.includes('worklistRequiredToFinalize')) {
      nextValue.patientIdRequiredToFinalize =
        nextValue.worklistRequiredToFinalize;
    }

    await mutate({ input });
    setValue(nextValue);
  };
  const doesNotHaveSDMPermissionsOrLoading =
    loading || !hasAdminPermission('studyDocumentationManagement');

  const autoFinalizeNbdExamsDisabled = useMemo(() => {
    return Boolean(
      value.worksheetRequiredToFinalize ||
        value.examTypeRequiredToFinalize ||
        value.hasRestrictedFinalization,
    );
  }, [
    value.examTypeRequiredToFinalize,
    value.hasRestrictedFinalization,
    value.worksheetRequiredToFinalize,
  ]);

  return (
    <Form
      schema={schema}
      value={value}
      onChange={handleChange}
      className="mb-6"
      data-bni-id="ArchiveStudyRestrictionsForm"
    >
      <Form.FieldSet
        className="mb-6"
        legend={
          <Layout direction="column" pad={1}>
            <Text as="div">
              <FormattedMessage
                id="archiveSettings.restrictions"
                defaultMessage="Restrictions"
              />
            </Text>
            <Text variant="sm" color="subtitle" css="font-weight: 400;">
              <FormattedMessage
                id="archiveSettings.restrictions.message.sign"
                defaultMessage="Restrictions do not impact existing signed studies in this archive."
              />
            </Text>
          </Layout>
        }
      >
        <ItemGrid role="list" templateColumns={1}>
          <>
            {canUseDicomConnector && canUseStudyRestrictions && (
              <ArchiveStudyRestrictionListItem
                name="worklistRequiredToFinalize"
                disabled={doesNotHaveSDMPermissionsOrLoading}
                title={
                  <FormattedMessage
                    id="archiveSettings.restrictions.worklist.sign"
                    defaultMessage="Require worklist to sign studies"
                  />
                }
                subtitle={
                  <FormattedMessage
                    id="archiveSettings.restrictions.worklistLabel.sign"
                    defaultMessage="All studies in this archive require patient details from a worklist in order to sign."
                  />
                }
              />
            )}
            {showPatientIdRequiredToFinalize && (
              <ArchiveStudyRestrictionListItem
                name="patientIdRequiredToFinalize"
                disabled={value.worklistRequiredToFinalize}
                title={
                  <FormattedMessage
                    id="archiveSettings.restrictions.patientIdRequired.sign"
                    defaultMessage="Require Patient ID to sign studies"
                  />
                }
                subtitle={
                  <FormattedMessage
                    id="archiveSettings.restrictions.patientIdRequiredLabel.sign"
                    defaultMessage="All studies in this archive require at least a patient id field value in order to sign."
                  />
                }
              />
            )}

            {canUseWorksheets && canUseStudyRestrictions && (
              <ArchiveStudyRestrictionListItem
                name="worksheetRequiredToFinalize"
                disabled={
                  doesNotHaveSDMPermissionsOrLoading ||
                  value.autoFinalizeNbdExams
                }
                title={
                  <FormattedMessage
                    id="archiveSettings.restrictions.worksheet.sign"
                    defaultMessage="Require worksheet to sign studies"
                  />
                }
                subtitle={
                  <FormattedMessage
                    id="archiveSettings.restrictions.worksheetLabel.sign"
                    defaultMessage="All studies in this archive require a worksheet in order to sign."
                  />
                }
              />
            )}
          </>
          <ArchiveStudyRestrictionListItem
            name="examTypeRequiredToFinalize"
            disabled={
              loading ||
              !hasExamTypeManagementPermission ||
              value.autoFinalizeNbdExams
            }
            title={
              <FormattedMessage
                id="archiveSettings.restrictions.examType.sign"
                defaultMessage="Require exam type to sign studies"
              />
            }
            subtitle={
              <FormattedMessage
                id="archiveSettings.restrictions.examTypeLabel.sign"
                defaultMessage="All studies in this archive require an exam type in order to sign."
              />
            }
          />
          {restrictedStudyFinalization && (
            <RestrictedFinalizationForm
              archive={archive}
              disabled={doesNotHaveSDMPermissionsOrLoading}
            />
          )}
          {canQaWithoutWorksheet && (
            <ArchiveStudyRestrictionListItem
              name="worksheetRequiredForQa"
              disabled={loading || !hasQaManagementPermission}
              title={
                <FormattedMessage
                  id="archiveSettings.restrictions.worksheetRequiredForQa.sign"
                  defaultMessage="Require worksheet to QA studies"
                />
              }
              subtitle={
                <FormattedMessage
                  id="archiveSettings.restrictions.worksheetRequiredForQaLabel.sign"
                  defaultMessage="All studies in this archive require a worksheet for QA."
                />
              }
            />
          )}
        </ItemGrid>
      </Form.FieldSet>
      <Form.FieldSet
        legend={
          <Layout direction="column" pad={1}>
            <Text as="div">
              <FormattedMessage
                id="archiveSettings.workflowSettings"
                defaultMessage="Workflow Settings"
              />
            </Text>
            <Text variant="sm" color="subtitle" css="font-weight: 400;">
              <FormattedMessage
                id="archiveSettings.workflowSettings.message.sign"
                defaultMessage="Workflow settings do not impact existing signed studies in this archive."
              />
            </Text>
          </Layout>
        }
      >
        <ItemGrid role="list" templateColumns={1}>
          <>
            {canViewArchiveSettings && (
              <ArchiveStudyRestrictionListItem
                name="excludedStudies"
                title={
                  <FormattedMessage
                    id="archiveSettings.restrictions.studies.title.excludeFromToDoLists"
                    defaultMessage="Exclude studies in this archive from user to do lists"
                  />
                }
                disabled={doesNotHaveSDMPermissionsOrLoading}
              />
            )}
            {canPreventMultipleWorksheets && canViewArchiveSettings && (
              <ArchiveStudyRestrictionListItem
                name="multipleWorksheetsEnabled"
                disabled={doesNotHaveSDMPermissionsOrLoading}
                title={
                  <FormattedMessage
                    id="archiveSettings.restrictions.multipleWorksheetsEnabled.sign"
                    defaultMessage="Allow users to add multiple worksheets to exams"
                  />
                }
              />
            )}
            {(canAutoFinalizeNbdExams || value.autoFinalizeNbdExams) && (
              <ArchiveStudyRestrictionListItem
                name="autoFinalizeNbdExams"
                disabled={
                  autoFinalizeNbdExamsDisabled ||
                  doesNotHaveSDMPermissionsOrLoading
                }
                title={
                  <FormattedMessage
                    id="archiveSettings.restrictions.autoFinalizeNbdExams"
                    defaultMessage="Automatically finalize non-Butterfly device exams"
                  />
                }
                subtitle={
                  <FormattedMessage
                    id="archiveSettings.restrictions.autoFinalizeNbdExams.description"
                    defaultMessage="Studies that do not meet the minimum requirements as detailed in the restrictions section will remain as drafts."
                  />
                }
              />
            )}
          </>
        </ItemGrid>
      </Form.FieldSet>
    </Form>
  );
}

export default createFragmentContainer(ArchiveStudyRestrictionsForm, {
  archive: graphql`
    fragment ArchiveStudyRestrictionsForm_archive on Archive {
      id
      hasRestrictedFinalization
      attestationMessage
      worklistRequiredToFinalize
      worksheetRequiredToFinalize
      examTypeRequiredToFinalize
      worksheetRequiredForQa
      studyAuthorRequiredToRequestFinalization
      excludedStudies
      multipleWorksheetsEnabled
      autoFinalizeNbdExams
      patientIdRequiredToFinalize
      ...RestrictedFinalizationForm_archive
    }
  `,
});
