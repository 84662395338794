import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  SelectedItemsByKey,
  useMultiSelect,
} from 'components/MultiSelectContext';

interface SelectAllContextValues<T = any> {
  toggleAll: () => void;
  isAllSelected: boolean;
  isIndeterminate: boolean;
  setItemsByKey: Dispatch<SetStateAction<SelectedItemsByKey<T>>>;
}

const SelectAllContext = React.createContext<SelectAllContextValues | null>(
  null,
);

export default SelectAllContext;

/**
 * Select All context provider allows to integrate selection functionality on pages
 * with dynamic content (e.g. search, sort, pagination). The selected items are stored
 * in memory and do not depend on the items that are currently displayed on the page.
 * In order for the 'select all' functionality to work properly, it's required to provide
 * a list of items that are displayed on the screen (e.g. search result, current page).
 */
export function SelectAllProvider({ children }) {
  const { selectedItemsByKey, setSelectedItemsByKey } = useMultiSelect();

  const [itemsByKey, setItemsByKey] = useState<SelectedItemsByKey>(new Map());

  /**
   * "All Is Selected" means that the identifiers list is not empty, and all
   * identifiers are present in the array of selected items
   */
  const isAllSelected = useMemo(
    () =>
      !!itemsByKey.size &&
      Array.from(itemsByKey.keys()).every((itemKey) =>
        selectedItemsByKey.has(itemKey),
      ),
    [itemsByKey, selectedItemsByKey],
  );

  /**
   * "Is Indeterminate" means that at least one item (but not all) is selected
   * from the identifiers list.
   */
  const isIndeterminate = useMemo(() => {
    return (
      !isAllSelected &&
      Array.from(itemsByKey.keys()).some((itemKey) =>
        selectedItemsByKey.has(itemKey),
      )
    );
  }, [isAllSelected, itemsByKey, selectedItemsByKey]);

  /**
   * Toggles the selection for all items. If some items are not selected, than all
   * records from the identifiers list will be added. If all items are selected,
   * records that are present in the identifiers list will be removed.
   */
  const toggleAll = useCallback(() => {
    setSelectedItemsByKey((prev) => {
      const updated = new Map(prev);
      itemsByKey.forEach((value, key) => {
        if (isAllSelected) {
          updated.delete(key); // removing from the selection only IDs that are present in the current search
        } else {
          updated.set(key, value); // adding all records from the identifiers list to the list of selected items
        }
      });
      return updated;
    });
  }, [isAllSelected, itemsByKey, setSelectedItemsByKey]);

  return (
    <SelectAllContext.Provider
      value={useMemo(
        () => ({
          toggleAll,
          isAllSelected,
          isIndeterminate,
          setItemsByKey,
        }),
        [toggleAll, isAllSelected, isIndeterminate],
      )}
    >
      {children}
    </SelectAllContext.Provider>
  );
}

export function useSelectAllContext(): SelectAllContextValues {
  const context = useContext(SelectAllContext);

  if (!context) {
    throw new Error(
      'useSelectAllContext must be called within SelectionContextProvider',
    );
  }

  return context;
}
