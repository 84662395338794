import SuccessIcon from '@bfly/icons/Success';
import BlankSlate from '@bfly/ui2/BlankSlate';
import MainContent from '@bfly/ui2/MainContent';
import useMountEffect from '@restart/hooks/useMountEffect';
import { css } from 'astroturf';
import useRouter from 'found/useRouter';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import AppStoreDownloadLinks from 'components/AppStoreDownloadLinks';
import useDeviceTooSmall from 'hooks/useIsTinyViewport';
import { useConfigRoutes } from 'routes/config';

interface Props {
  title: React.ReactNode;
}

function DeviceTooSmallSuccessPage({ title }: Props) {
  const configRoutes = useConfigRoutes();
  const { match, router } = useRouter();
  const { params } = match;

  const route = params.organizationSlug
    ? configRoutes.rootOrganization({
        organizationSlug: params.organizationSlug,
      })
    : configRoutes.rootRoute();

  const isTooSmall = useDeviceTooSmall();

  // It doesn't ever make sense for this to run more than once. The slug should
  //  not change.
  useMountEffect(() => {
    if (!isTooSmall) router.replace(route);
  });

  if (!isTooSmall) {
    return null;
  }

  return (
    <MainContent>
      <BlankSlate>
        {/* We don't want the padding from <BlankSlate.Img> here. */}
        <SuccessIcon
          width={26}
          height={26}
          className="text-white"
          css={css`
            & :global(.foreground) {
              fill: black;
            }
          `}
        />
        <BlankSlate.Title>{title}</BlankSlate.Title>
        <BlankSlate.Body>
          <FormattedMessage
            id="deviceTooSmallSuccess.body"
            defaultMessage="Download the app to begin using Butterfly iQ. You can access your images on the Butterfly Cloud desktop site."
          />
        </BlankSlate.Body>
        <AppStoreDownloadLinks className="mt-4" />
      </BlankSlate>
    </MainContent>
  );
}

export default DeviceTooSmallSuccessPage;
