/**
 * @generated SignedSource<<b1c47f49218893a72ea92af4d54b09bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarListSuggestionOption_examType$data = {
  readonly name: string | null;
  readonly " $fragmentType": "SearchBarListSuggestionOption_examType";
};
export type SearchBarListSuggestionOption_examType$key = {
  readonly " $data"?: SearchBarListSuggestionOption_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_examType">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarListSuggestionOption_examType"
};

(node as any).hash = "84d27797989956b153ce0db7ecdf5bf1";

export default node;
