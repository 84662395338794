import { Match } from 'components/Route';

export default function someRouteHasProperty<TValue>(
  match: Match,
  propName: string,
): TValue | false {
  return match.routes.reduce(
    (prevProp, { [propName]: prop }) => (prop == null ? prevProp : prop),
    false,
  );
}
