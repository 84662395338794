import Layout from '@4c/layout';
import Heading, { HeadingProps } from '@bfly/ui2/Heading';
import React from 'react';

interface Props extends HeadingProps {
  children: React.ReactNode;
  className?: string;
}

function ConfigSectionTitle({ children, ...props }: Props) {
  return (
    <Heading {...props} ruled flush>
      <Layout align="center" justify="space-between" pad>
        {children}
      </Layout>
    </Heading>
  );
}
export default ConfigSectionTitle;
