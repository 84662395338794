import {
  ColDef as BaseColDef,
  ColGroupDef as BaseColGroupDef,
  ColumnState as BaseColumnState,
  ValueGetterFunc,
  ValueSetterFunc,
} from 'ag-grid-community';
import { ReactNode } from 'react';

export type TDataBase = {
  id: string;
  [key: string]: any;
};

export type ColumnComparator<TData = TDataBase> =
  BaseColDef<TData>['comparator'];

export type ColumnState = Pick<
  BaseColumnState,
  'hide' | 'colId' | 'pinned' | 'width'
>;

export type ColumnArrayOption = {
  label: string;
  value: string;
  render?: ReactNode;
};

type ColumnBase<TData = TDataBase> = Pick<
  BaseColDef<TData>,
  | 'field'
  | 'width'
  | 'editable'
  | 'initialSort'
  | 'pinned'
  | 'comparator'
  | 'sortable'
  | 'cellRenderer'
  | 'hide'
  | 'columnGroupShow'
> & {
  hideFilter?: boolean;
  secondarySort?: 'asc' | 'desc';
  type?: 'text' | 'date' | 'array';
  hide?: boolean;
  headerName: string;
  colId: string;
  field?: string;
  valueGetter?: ValueGetterFunc<TData>;
  valueSetter?: ValueSetterFunc<TData>;
};

export type ColumnText<TData = TDataBase> = ColumnBase<TData> & {
  type?: 'text';
};
export type ColumnDate<TData = TDataBase> = ColumnBase<TData> & {
  type: 'date';
};
export type ColumnArray<TData = TDataBase> = ColumnBase<TData> & {
  type: 'array';
  options: ColumnArrayOption[];
  multiple?: 'filter' | boolean;
  /** Only for multiple, show "All" or "Item[+3]" in the table cell  */
  showAllLabel?: boolean;
};

export interface ColumnGroup<TData = TDataBase>
  extends Pick<BaseColGroupDef<TData>, 'groupId' | 'columnGroupShow'> {
  headerName: string;
  children: Column[];
  groupId: string;
  width?: number;
  hide?: boolean;
  pinned?: BaseColDef<TData>['pinned'];
}

export type SelectableColumnProps = Pick<
  BaseColDef,
  | 'checkboxSelection'
  | 'headerCheckboxSelection'
  | 'showDisabledCheckboxes'
  | 'headerCheckboxSelectionFilteredOnly'
  | 'pinned'
>;

export type ColumnDef<TData = TDataBase> =
  | ColumnGroup<TData>
  | ColumnText<TData>
  | ColumnDate<TData>
  | ColumnArray<TData>;

export type Column<TData = TDataBase> =
  | ColumnText<TData>
  | ColumnDate<TData>
  | ColumnArray<TData>;

export function isColumnGroup<TData = TDataBase>(
  col: ColumnDef<TData>,
): col is ColumnGroup<TData> {
  return 'children' in col;
}
