import Heading from '@bfly/ui2/Heading';
import Popover from '@bfly/ui2/Popover';
import { Data as BaseData } from '@bfly/ui2/RingChart';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import {
  FormattedMessage,
  FormattedNumber,
  defineMessages,
  useIntl,
} from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import SkeletonCard from 'components/SkeletonCard';

import PerformanceIndicatorRingChart from './PerformanceIndicatorRingChart';
import { ExamStatusPerformanceIndicator_tenant$data as Tenant } from './__generated__/ExamStatusPerformanceIndicator_tenant.graphql';

const axisWidth = 30;

const messages = defineMessages({
  draft: {
    id: 'examStatusPerformanceIndicator.draft',
    defaultMessage: 'Draft',
  },
  pendingAttestation: {
    id: 'examStatusPerformanceIndicator.pendingAttestation',
    defaultMessage: 'Pending attestation',
  },
  finalized: {
    id: 'examStatusPerformanceIndicator.finalized',
    defaultMessage: 'Finalized',
  },
});

type Data = BaseData & { title: string };

function PopoverContent({ data, total }: { data: Data[]; total: number }) {
  return (
    <>
      {data.map((row) => (
        <div className="mt-1" key={row.title}>
          <Text
            as="div"
            style={{ color: row.color || 'var(--bni-white)' }}
            variant="sm-bold"
            className="-mb-1"
          >
            <FormattedNumber
              value={!total ? 0 : row.value / total}
              style="percent"
            />{' '}
            {row.title}
          </Text>
          <Text as="div">
            <FormattedMessage
              id="examStatusPerformanceIndicator.exams"
              defaultMessage="{exams} {exams, plural,
                =1 {Exam}
                other {Exams}
              }"
              values={{ exams: row.value }}
            />
          </Text>
        </div>
      ))}
    </>
  );
}

interface Props {
  tenant: Tenant | null;
}

function ExamStatusPerformanceIndicator({ tenant }: Props) {
  const { formatMessage } = useIntl();

  const studyStatusStats =
    tenant?.examStatusStudyStatistics?.studyStatusStatistics ?? [];
  const totalStudies = studyStatusStats.reduce(
    (sum, stat) => sum + stat!.numStudies!,
    0,
  );
  const draftStudies =
    studyStatusStats.find((stat) => stat?.key === 'DRAFT')?.numStudies ?? 0;
  const pendingStudies =
    studyStatusStats.find((stat) => stat?.key === 'PENDING_ATTESTATION')
      ?.numStudies ?? 0;
  const finalizedStudies =
    (studyStatusStats.find((stat) => stat?.key === 'FINALIZED')?.numStudies ??
      0) +
    (studyStatusStats.find((stat) => stat?.key === 'NEEDS_REVIEW')
      ?.numStudies ?? 0) +
    (studyStatusStats.find((stat) => stat?.key === 'REVIEWED')?.numStudies ??
      0);

  const draftStudiesPercent = !totalStudies ? 0 : draftStudies / totalStudies;
  const pendingStudiesPercent = !totalStudies
    ? 0
    : pendingStudies / totalStudies;

  const data: Data[] = [
    {
      title: formatMessage(messages.draft),
      value: draftStudies,
      width: axisWidth,
      color: 'var(--bni-draft)',
    },
    {
      title: formatMessage(messages.pendingAttestation),
      value: pendingStudies,
      width: axisWidth,
      color: 'var(--bni-red-40)',
    },
  ];

  return (
    <SkeletonCard
      busy={!tenant}
      className="flex-row space-x-2 px-4 pt-4 pb-5 items-center  justify-between"
    >
      <Section className="contents">
        <div className="mr-5 self-start">
          <Heading flush>
            <Text
              variant="display-sm"
              as="div"
              data-bni-id="ExamStatusPerformanceHeader"
            >
              <FormattedNumber value={totalStudies} />
            </Text>

            <div data-bni-id="ExamStatusPerformanceBody">
              <FormattedMessage
                id="ExamStatusPerformanceIndicator.header"
                defaultMessage="Total exams"
              />
            </div>
          </Heading>
          <hr className="my-2 border-divider border-b" />
          <div className="grid gap-x-2 grid-cols-[auto,auto]">
            <div
              data-bni-id="ExamStatusPerformanceDrafts"
              className="contents"
            >
              <Text color="draft" align="right">
                <FormattedNumber style="percent" value={draftStudiesPercent} />
              </Text>
              <FormattedMessage
                id="ExamStatusPerformanceIndicator.body.top"
                defaultMessage="In draft"
              />
            </div>
            <div
              data-bni-id="ExamStatusPerformancePending"
              className="contents"
            >
              <Text color="danger" align="right">
                <FormattedNumber
                  style="percent"
                  value={pendingStudiesPercent}
                />
              </Text>
              <FormattedMessage
                id="ExamStatusPerformanceIndicator.body.bottom"
                defaultMessage="Pending attestation"
              />
            </div>
          </div>
        </div>
        <Popover.Trigger
          trigger="passive"
          placement="top"
          variant="dark"
          popover={
            <PopoverContent
              data={[
                ...data,
                {
                  title: formatMessage(messages.finalized),
                  value: finalizedStudies,
                },
              ]}
              total={totalStudies}
            />
          }
        >
          <div>
            <PerformanceIndicatorRingChart data={data} total={totalStudies}>
              <FormattedMessage
                id="ExamStatusPerformanceIndicator.ringChart.innerText"
                defaultMessage="Exam{br}status"
                values={{
                  br: <br />,
                }}
              />
            </PerformanceIndicatorRingChart>
          </div>
        </Popover.Trigger>
      </Section>
    </SkeletonCard>
  );
}

export default createFragmentContainer(ExamStatusPerformanceIndicator, {
  tenant: graphql`
    fragment ExamStatusPerformanceIndicator_tenant on TenantInterface {
      examStatusStudyStatistics: studyStatistics(filter: $filter) {
        studyStatusStatistics {
          key
          numStudies
        }
      }
    }
  `,
});
