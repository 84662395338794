import Redirect from 'found/Redirect';
import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import Route from 'components/Route';
import routes from 'routes/admin';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';

export const organizationConnectionsRoute = (
  <Route
    path="connections"
    // only visible if the domain level flag is FALSE
    prerender={checkFlagsOr404(
      (ld) => !getVariation(ld, 'domain-connectivity-settings'),
    )}
  >
    <Route path="butterfly-link">
      <Redirect
        to={(match) => ({
          pathname: routes(match, {
            organizationSlug: match.params.organizationSlug,
          }).connectivity(),
        })}
      />
      <Route
        path="-/new/:hospitalConnectionHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateButterflyLinkPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query connections_CreateButterflyLinkPage_Query(
            $hospitalConnectionHandle: String!
          ) {
            butterflyLink: butterflyLinkConnection(
              handle: $hospitalConnectionHandle
            ) {
              ...CreateButterflyLinkPage_butterflyLink
            }
          }
        `}
        prerender={({ match }) => {
          if (!match.location?.state?.butterflyConnectionSecret) {
            throw new RedirectException({
              pathname: routes(match, {
                organizationSlug: match.params.organizationSlug,
              }).butterflyLink({
                hospitalConnectionHandle:
                  match.params.hospitalConnectionHandle,
              }),
              state: {},
            });
          }
        }}
      />

      <Route
        path=":hospitalConnectionHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateButterflyLinkPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query connections_UpdateButterflyLinkPage_Query(
            $hospitalConnectionHandle: String!
          ) {
            butterflyLink: butterflyLinkConnection(
              handle: $hospitalConnectionHandle
            ) {
              ...UpdateButterflyLinkPage_butterflyLink
            }
          }
        `}
      />
    </Route>

    <Route path="dicom">
      <Redirect
        to={(match) => ({
          pathname: routes(match, {
            organizationSlug: match.params.organizationSlug,
          }).dicomNew(),
        })}
      />
      <Route
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateDicomConnectionPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query connections_CreateDicomConnectionPage_Query(
            $organizationSlug: String!
          ) {
            organization: organizationBySlug(slug: $organizationSlug) {
              ...CreateDicomConnectionPage_connectivityConfig
              ...CreateDicomConnectionPage_organization
            }
          }
        `}
        renderFetched={({ Component, props }) =>
          () =>
            <Component {...props} connectivityConfig={props.organization} />}
      />
      <Route
        path=":hospitalConnectionHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateDicomConnectionPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query connections_UpdateDicomConnectionPage_Query(
            $organizationSlug: String!
            $hospitalConnectionHandle: String!
          ) {
            dicomConnection: dicomTlsConnection(
              handle: $hospitalConnectionHandle
            ) {
              ...UpdateDicomConnectionPage_dicomConnection
            }
            organization: organizationBySlug(slug: $organizationSlug) {
              ...UpdateDicomConnectionPage_organization
            }
          }
        `}
      />
    </Route>
  </Route>
);

export const domainConnectionsRoute = (
  <Route
    path="connections"
    // only visible if the domain level flag is true
    prerender={checkFlagsOr404('domain-connectivity-settings')}
  >
    <Route path="butterfly-link">
      <Redirect to={(match) => ({ pathname: routes(match).connectivity() })} />
      <Route
        path="-/new/:hospitalConnectionHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateButterflyLinkPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query connections_DomainCreateButterflyLinkPage_Query(
            $hospitalConnectionHandle: String!
          ) {
            butterflyLink: butterflyLinkConnection(
              handle: $hospitalConnectionHandle
            ) {
              ...CreateButterflyLinkPage_butterflyLink
            }
          }
        `}
        prerender={({ match }) => {
          if (!match.location?.state?.butterflyConnectionSecret) {
            throw new RedirectException({
              pathname: routes(match).butterflyLink({
                hospitalConnectionHandle:
                  match.params.hospitalConnectionHandle,
              }),
              state: {},
            });
          }
        }}
      />

      <Route
        path=":hospitalConnectionHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateButterflyLinkPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query connections_DomainUpdateButterflyLinkPage_Query(
            $hospitalConnectionHandle: String!
          ) {
            butterflyLink: butterflyLinkConnection(
              handle: $hospitalConnectionHandle
            ) {
              ...UpdateButterflyLinkPage_butterflyLink
            }
          }
        `}
      />
    </Route>

    <Route path="dicom">
      <Redirect
        to={(match) => ({
          pathname: routes(match).dicomNew(),
        })}
      />
      <Route
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateDicomConnectionPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query connections_DomainCreateDicomConnectionPage_Query {
            viewer {
              memberships {
                organization {
                  ...CreateDicomConnectionPage_organization
                }
              }
              domain {
                ...CreateDicomConnectionPage_connectivityConfig
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component
            {...props}
            connectivityConfig={props.viewer.domain}
            organization={props.viewer.memberships[0].organization}
          />
        )}
      />
      <Route
        path=":hospitalConnectionHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateDicomConnectionPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query connections_DomainUpdateDicomConnectionPage_Query(
            $hospitalConnectionHandle: String!
          ) {
            dicomConnection: dicomTlsConnection(
              handle: $hospitalConnectionHandle
            ) {
              ...UpdateDicomConnectionPage_dicomConnection
            }
            viewer {
              memberships {
                organization {
                  ...UpdateDicomConnectionPage_organization
                }
              }
            }
          }
        `}
        renderFetched={({ Component, props }) => (
          <Component
            {...props}
            organization={props.viewer.memberships[0].organization}
          />
        )}
      />
    </Route>
  </Route>
);
