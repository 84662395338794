import Layout from '@4c/layout';
import ButtonToolbar from '@bfly/ui2/ButtonToolbar';
import Caret from '@bfly/ui2/Caret';
import Dropdown from '@bfly/ui2/Dropdown';
import Page from '@bfly/ui2/Page';
import Popover from '@bfly/ui2/Popover';
import Section from '@bfly/ui2/Section';
import getNodes from '@bfly/utils/getNodes';
import { css } from 'astroturf';
import { useState } from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import FileExportButton from 'components/FileExportButton';
import PageTitle from 'components/PageTitle';
import PlusButton from 'components/PlusButton';
import { RoutePageProps } from 'components/Route';
import useModalState from 'hooks/useModalState';
import actions from 'messages/actions';
import downloadFile from 'utils/downloadFile';
import { usePermissions } from 'utils/viewerContext';

import { domainMembersUserSeatsMessages as userSeatMessages } from '../messages/DomainMembersMessages';
import { MemberCountsContextProvider } from '../utils/MemberCounts';
import AddDomainMemberModal from './AddDomainMemberModal';
import BulkDomainMemberUploadModal, {
  createCsvTemplate,
} from './BulkDomainMemberUploadModal';
import DomainUserSeatsIndicator from './DomainUserSeatsIndicator';
import DropdownFileItem from './DropdownFileItem';
import { DomainMembersPage_ExportQuery as ExportQuery } from './__generated__/DomainMembersPage_ExportQuery.graphql';
import { DomainMembersPage_viewer$data as Viewer } from './__generated__/DomainMembersPage_viewer.graphql';

interface Props extends RoutePageProps {
  viewer: Viewer;
}

function DomainMembersPage({ children, viewer }: Props) {
  const intl = useIntl();
  const [csv, setCsv] = useState<File | null>(null);
  const [openAddMember, addMemberProps] = useModalState(false);
  const { hasAdminPermission } = usePermissions();
  const hasPermission = hasAdminPermission('userManagement');

  const hasAvailableSeats =
    // does not have a maxNumSeats
    !viewer.domain?.settings?.maxNumSeats ||
    // does have numAvailableSeats
    !!viewer.domain?.settings?.numAvailableSeats;

  async function handleDownloadCsv() {
    const csvTemplate = await createCsvTemplate(
      getNodes(viewer.domain?.ehrConnection),
      getNodes(viewer.domain?.dicomFieldTemplateConnection),
      getNodes(viewer.domain?.membershipRoles),
      intl,
    );

    downloadFile(
      `data:text/csv;charset=utf-8,${encodeURIComponent(csvTemplate)}`,
      `${intl
        .formatMessage(
          defineMessage({
            id: 'domainMembersPage.csvFileName',
            defaultMessage: 'Butterfly Cloud - Enterprise Members',
            description:
              'This is file name and should not contain invalid characters including: . | > < ? \\ * : /',
          }),
        )
        .trim()}.csv`,
    );
  }

  return (
    <MemberCountsContextProvider domain={viewer.domain}>
      <Page.Container
        className="flex-col flex"
        css={css`
          height: calc(100vh - 6rem);
        `}
      >
        <Page.Header>
          <PageTitle
            title={defineMessage({
              id: 'domainMembersPage.title',
              defaultMessage: 'Enterprise Users',
            })}
          />
          {/*
          At the moment programmatic adding of domain users is limited to
          orgs that have SSO enabled */}
          <ButtonToolbar justify="center" className="ml-auto">
            {viewer.domain!.enableSamlLogin ? (
              <Dropdown>
                <Dropdown.Toggle
                  id="add-enterprise-member-menu"
                  data-bni-id="AddDomainMemberMenu"
                  as={PlusButton}
                  disabled={!hasPermission}
                >
                  <span className="pr-2">
                    <FormattedMessage {...actions.add} />
                  </span>
                  <Caret />
                </Dropdown.Toggle>
                <Dropdown.Menu role="menu" variant="dark">
                  <Popover.Trigger
                    trigger="passive"
                    placement="left"
                    variant="dark"
                    show={hasAvailableSeats ? false : undefined}
                    popover={
                      <div
                        className="mt-1"
                        data-bni-id="DomainMembersPageAddUserPopover"
                      >
                        <FormattedMessage
                          {...userSeatMessages.seatLimitReachedMessage}
                        />
                      </div>
                    }
                  >
                    <div>
                      <Dropdown.Item
                        onSelect={openAddMember}
                        disabled={!hasPermission || !hasAvailableSeats}
                      >
                        <FormattedMessage
                          id="domainMembersPage.addMember"
                          defaultMessage="Add user"
                        />
                      </Dropdown.Item>
                    </div>
                  </Popover.Trigger>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={handleDownloadCsv}
                    disabled={!hasPermission}
                  >
                    <FormattedMessage
                      id="domainMembersPage.downloadCsv"
                      defaultMessage="Download CSV template"
                    />
                  </Dropdown.Item>
                  <Popover.Trigger
                    trigger="passive"
                    placement="left"
                    variant="dark"
                    show={hasAvailableSeats ? false : undefined}
                    popover={
                      <div
                        className="mt-1"
                        data-bni-id="DomainMembersPageUploadCsvPopover"
                      >
                        <FormattedMessage
                          {...userSeatMessages.seatLimitReachedMessage}
                        />
                      </div>
                    }
                  >
                    <div>
                      <DropdownFileItem
                        onClick={setCsv}
                        disabled={!hasPermission || !hasAvailableSeats}
                      >
                        <FormattedMessage
                          id="domainMembersPage.uploadCsv"
                          defaultMessage="Upload CSV"
                        />
                      </DropdownFileItem>
                    </div>
                  </Popover.Trigger>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <PlusButton onClick={openAddMember} disabled={!hasPermission}>
                <FormattedMessage {...actions.add} />
              </PlusButton>
            )}
            <FileExportButton<ExportQuery>
              query={graphql`
                query DomainMembersPage_ExportQuery($id: ID!) {
                  domain: node(id: $id) {
                    ... on Domain {
                      bulkUsersExport {
                        ...FileExportButton_fileExport
                      }
                    }
                  }
                }
              `}
              variables={{
                id: viewer.domain!.id,
              }}
              getFile={(res) => res.domain!.bulkUsersExport}
              className="ml-3"
            />
          </ButtonToolbar>
        </Page.Header>
        <Section>
          {!hasAvailableSeats && (
            <Layout pad={2} data-bni-id="DomainUserSeatsLimitReachedMessage">
              <div className="flex-row space-x-2 pt-3 pb-1 ml-3 mt-3 items-center  justify-between">
                <FormattedMessage
                  {...userSeatMessages.seatLimitReachedMessage}
                />
              </div>
            </Layout>
          )}
          <Layout pad={2} data-bni-id="DomainUserSeatsIndicator">
            <DomainUserSeatsIndicator />
          </Layout>
        </Section>
        {children}
      </Page.Container>
      <AddDomainMemberModal
        {...addMemberProps}
        viewer={viewer}
        domain={viewer.domain!}
      />
      <BulkDomainMemberUploadModal
        file={csv}
        show={!!csv}
        domain={viewer.domain!}
        onHide={() => setCsv(null)}
      />
    </MemberCountsContextProvider>
  );
}

export default createFragmentContainer(DomainMembersPage, {
  viewer: graphql`
    fragment DomainMembersPage_viewer on Viewer {
      ...AddDomainMemberModal_viewer
      domain {
        id
        settings {
          numUsers
          maxNumSeats
          numAvailableSeats
        }
        enableSamlLogin
        ehrConnection(first: 2147483647)
          @connection(key: "Domain_ehrConnection") {
          edges {
            node {
              handle
              name
            }
          }
        }
        dicomFieldTemplateConnection(first: 2147483647)
          @connection(key: "Domain_dicomFieldTemplateConnection") {
          edges {
            node {
              handle
              label
            }
          }
        }
        membershipRoles(
          sort: NAME_ASC
          roleType: [SYSTEM_DEFINED, USER_DEFINED]
        ) {
          edges {
            node {
              id
              name
              isDisabled
            }
          }
        }
        ...AddDomainMemberModal_domain
        ...BulkDomainMemberUploadModal_domain
      }
    }
  `,
});
