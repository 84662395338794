import clsx from 'clsx';
import { createFragmentContainer, graphql } from 'react-relay';

import ArchiveDropdown, {
  renderArchiveValue,
} from 'components/ArchiveDropdown';
import useMoveStudies from 'hooks/useMoveStudies';
import { canMoveStudy } from 'utils/StudyPermissions';

import { ExamArchive_study$data as Study } from './__generated__/ExamArchive_study.graphql';

interface Props {
  study: Study;
  id?: string;
  className?: string;
}

function ExamArchive({ study, className, id }: Props) {
  const [moveStudies, loading] = useMoveStudies();
  const allowedToMove = canMoveStudy(study);

  const archive = study.archive!;

  return allowedToMove ? (
    <ArchiveDropdown
      id={id}
      busy={loading}
      data-bni-id="ExamArchiveDropdown"
      onChange={(value) => {
        if (!value || value.id === archive.id) return;

        moveStudies(
          [{ studyId: study.id, archiveId: archive.id }],
          value.id,
          study.organization!.id,
          study.organization!.id,
        );
      }}
      value={archive}
      organization={study.organization!}
      className={className}
    />
  ) : (
    <span
      className={clsx(className, 'text-headline')}
      data-bni-id="ExamArchive"
    >
      {renderArchiveValue({ item: study.archive! })}
    </span>
  );
}

export default createFragmentContainer(ExamArchive, {
  study: graphql`
    fragment ExamArchive_study on Study {
      id
      organization {
        id
        ...ArchiveDropdown_organization
      }
      archive {
        ...ArchiveDropdownItem @relay(mask: false)
      }
      ...StudyPermissions_canMoveStudy
    }
  `,
});
