/**
 * @generated SignedSource<<d4ad1d649dbf65c4834f53125a2b203c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EducationContentType = "COURSE" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EducationContentTileMobileActions_educationContent$data = {
  readonly contentType: EducationContentType | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"useSaveContentToggle_educationContent">;
  readonly " $fragmentType": "EducationContentTileMobileActions_educationContent";
};
export type EducationContentTileMobileActions_educationContent$key = {
  readonly " $data"?: EducationContentTileMobileActions_educationContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationContentTileMobileActions_educationContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationContentTileMobileActions_educationContent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useSaveContentToggle_educationContent",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerHasSaved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerHasAccess",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};
})();

(node as any).hash = "9ef479e1824aad79bf9b19ada5eb7adf";

export default node;
