/**
 * @generated SignedSource<<c522125280492332ad68a248bff96f23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnsaveEducationContentInput = {
  clientMutationId?: string | null;
  educationContentId: string;
};
export type useSaveContentToggleUnsaveContentMutation$variables = {
  input: UnsaveEducationContentInput;
};
export type useSaveContentToggleUnsaveContentMutation$data = {
  readonly unsaveEducationContentOrError: {
    readonly __typename?: string;
    readonly educationContent?: {
      readonly viewerHasSaved: boolean | null;
    } | null;
    readonly message?: string | null;
  } | null;
};
export type useSaveContentToggleUnsaveContentMutation = {
  response: useSaveContentToggleUnsaveContentMutation$data;
  variables: useSaveContentToggleUnsaveContentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasSaved",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSaveContentToggleUnsaveContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "unsaveEducationContentOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContent",
                "kind": "LinkedField",
                "name": "educationContent",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UnsaveEducationContentPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSaveContentToggleUnsaveContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "unsaveEducationContentOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContent",
                "kind": "LinkedField",
                "name": "educationContent",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UnsaveEducationContentPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14e10dcdc06bf5bff848096cf40c64e8",
    "id": null,
    "metadata": {},
    "name": "useSaveContentToggleUnsaveContentMutation",
    "operationKind": "mutation",
    "text": "mutation useSaveContentToggleUnsaveContentMutation(\n  $input: UnsaveEducationContentInput!\n) {\n  unsaveEducationContentOrError(input: $input) {\n    __typename\n    ... on UnsaveEducationContentPayload {\n      educationContent {\n        viewerHasSaved\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae44b312b64749d95fa92164de25ac4f";

export default node;
