import BlankSlate from '@bfly/ui2/BlankSlate';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import captureException from '@bfly/utils/captureException';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/types';
import useRouter from 'found/useRouter';
import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuth } from 'components/AuthContext';
import { useConfigRoutes } from 'routes/config';
import { getStoredCsrfState } from 'utils/csrfState';
import { noticeError } from 'utils/newRelic';

import { AuthType } from '../../../utils/auditLogs';

export function useFederatedLoginAttempt() {
  const rawHash = window.location.hash.substring(1);
  return useMemo(() => {
    const hash = new URLSearchParams(rawHash);

    const idToken = hash.get('id_token');
    const expiresIn = parseInt(hash.get('expires_in') || '', 10);
    const state = hash.get('state');

    if (!(idToken && expiresIn && state)) {
      Sentry.addBreadcrumb({
        level: Severity.Warning,
        message: 'Invalid hash',
        data: { rawHash },
      });
      return null;
    }

    const { csrfState, nextPath } = JSON.parse(state);

    if (!csrfState) {
      Sentry.addBreadcrumb({
        level: Severity.Warning,
        message: 'Invalid state',
        data: { state },
      });
      return null;
    }

    const storedCsrfState = getStoredCsrfState();

    if (csrfState !== storedCsrfState) {
      Sentry.addBreadcrumb({
        level: Severity.Warning,
        message: 'CSRF mismatch',
        data: { csrfState, storedCsrfState },
      });
      return null;
    }

    return {
      idToken,
      expiresIn,
      csrfState,
      nextPath,
    };
  }, [rawHash]);
}

function FederatedAuthCallbackPage() {
  const configRoutes = useConfigRoutes();
  const auth = useAuth();
  const { router } = useRouter();
  const attempt = useFederatedLoginAttempt();

  useEffect(() => {
    async function updateResponse() {
      if (!attempt) {
        const err = new Error('Login error page');
        noticeError(err);
        captureException(err);
        return;
      }

      await auth.setAccessToken(attempt.idToken, {
        trackLogin: true,
        authType: AuthType.SSO,
      });
      router.replace(attempt.nextPath || configRoutes.rootRoute());
    }

    updateResponse();
  }, [attempt, auth, configRoutes, router]);

  if (!attempt) {
    return (
      <Page>
        <Page.Container center>
          <MainContent centerText>
            <BlankSlate>
              <BlankSlate.Title>
                <FormattedMessage
                  defaultMessage="Federated Sign In Failed"
                  id="loginErrorPage.title"
                />
              </BlankSlate.Title>
              <BlankSlate.Body>
                <FormattedMessage
                  defaultMessage="You can try again by pressing the button below. If you continue receiving this message, contact support@butterflynetwork.com"
                  id="loginErrorPage.body"
                />
              </BlankSlate.Body>
              <BlankSlate.Action href="/">
                <FormattedMessage
                  defaultMessage="Open Login Page"
                  id="loginErrorPage.openLoginPage"
                />
              </BlankSlate.Action>
            </BlankSlate>
          </MainContent>
        </Page.Container>
      </Page>
    );
  }

  return null;
}

export default FederatedAuthCallbackPage;
