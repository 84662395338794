import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import useMountEffect from '@restart/hooks/useMountEffect';
import { useRouter } from 'found';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';

import { LmsRedirect_RequestTokenBasedLmsLoginMutation as RequestLmsTokenLoginMutation } from 'components/__generated__/LmsRedirect_RequestTokenBasedLmsLoginMutation.graphql';
import { useEducationRoutes } from 'routes/education';

import useButterflyAcademyAccess from '../../routes/education/components/utils/useButterflyAcademyAccess';
import LoadingIndicatorPage from './LoadingIndicatorPage';
import { LmsRedirect_RequestLmsLoginMutation as RequestLmsLoginMutation } from './__generated__/LmsRedirect_RequestLmsLoginMutation.graphql';

function useLmsRedirectData(token?: string) {
  const [data, setData] = useState<
    | RequestLmsLoginMutation['response']['requestLmsLoginOrError']
    | RequestLmsTokenLoginMutation['response']['requestTokenBasedLmsLoginOrError']
    | null
  >(null);
  const [error, setError] = useState<any>(null);

  const [requestLogin] = useMutationWithError<
    RequestLmsLoginMutation | RequestLmsTokenLoginMutation
  >(
    token
      ? graphql`
          mutation LmsRedirect_RequestTokenBasedLmsLoginMutation(
            $input: RequestTokenBasedLmsLoginInput!
          ) {
            requestTokenBasedLmsLoginOrError(input: $input) {
              ... on RequestTokenBasedLmsLoginPayload {
                lmsLogin {
                  samlResponse
                  loginUrl
                  origin
                }
              }
              ...mutationError_error @relay(mask: false)
            }
          }
        `
      : graphql`
          mutation LmsRedirect_RequestLmsLoginMutation(
            $input: RequestLmsLoginInput!
          ) {
            requestLmsLoginOrError(input: $input) {
              ... on RequestLmsLoginPayload {
                lmsLogin {
                  samlResponse
                  loginUrl
                  origin
                }
              }
              ...mutationError_error @relay(mask: false)
            }
          }
        `,
    {
      input: token ? { token } : {},
      onCompleted(resp) {
        setData(
          'requestTokenBasedLmsLoginOrError' in resp
            ? resp.requestTokenBasedLmsLoginOrError
            : resp.requestLmsLoginOrError,
        );
      },
      onError(err) {
        setError(err);
      },
    },
  );

  return [requestLogin, { data, error }] as const;
}
interface Props {
  openNewTab?: boolean;
  onOpen?: (isOpen: boolean) => void;
  keepActiveRoute?: boolean;
  contentId?: string | null;
}

export default function LmsRedirect({
  openNewTab = false,
  onOpen,
  keepActiveRoute = false,
  contentId,
}: Props) {
  const educationRoutes = useEducationRoutes();
  const { router, match } = useRouter();
  const butterflyAcademyAccess = useButterflyAcademyAccess();
  const { absorb_login_token: token } = match.location.query;

  const formRef = useRef<HTMLFormElement>(null);
  const toast = useToast();
  const [requestLogin, { data, error }] = useLmsRedirectData(token);

  const hasNotAccess = butterflyAcademyAccess !== 'enabled';

  useMountEffect(() => {
    if (hasNotAccess && !token) {
      router.replace({
        ...match.location,
        pathname: educationRoutes.education(),
      });
      return;
    }
    requestLogin().then(() => {
      formRef.current?.submit();

      if (openNewTab && !keepActiveRoute) {
        router.replace({
          ...match.location,
          pathname: educationRoutes.education(),
        });
      }
      onOpen?.(true);
    });
  });

  if (error) {
    toast?.error(
      <FormattedMessage
        id="lms.error"
        defaultMessage="An error has occurred"
      />,
    );
    throw error;
  }

  /**
   * NOTE: Standard SAML practice is to do a form POST for redirection and login.
   * We use a hidden form to POST the samlresponse to Absorb to log the user in
   * and redirect the user to the Absorb dashboard.
   * https://support.absorblms.com/hc/en-us/articles/222446087-Incoming-SAML-2-0-Single-Sign-On
   * http://saml.xml.org/wiki/idp-initiated-single-sign-on-post-binding
   */
  const action = contentId
    ? `${data?.lmsLogin?.loginUrl}/#/online-courses/${contentId}`
    : data?.lmsLogin?.loginUrl;

  return (
    <>
      <form
        ref={formRef}
        action={action}
        method="POST"
        target={openNewTab ? '_blank' : '_self'}
      >
        <input
          type="hidden"
          name="samlresponse"
          value={data?.lmsLogin?.samlResponse || ''}
        />
      </form>
      {!openNewTab && <LoadingIndicatorPage />}
    </>
  );
}
