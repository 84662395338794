/**
 * @generated SignedSource<<c84f9628006905b06e04e68930d19443>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitStudyWorksheetsField_sourceStudy$data = {
  readonly id: string;
  readonly organization: {
    readonly id: string;
  } | null;
  readonly worksheets: ReadonlyArray<{
    readonly handle: string | null;
    readonly id: string;
    readonly templateVersion: {
      readonly title: string | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "SplitStudyWorksheetsField_sourceStudy";
};
export type SplitStudyWorksheetsField_sourceStudy$key = {
  readonly " $data"?: SplitStudyWorksheetsField_sourceStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyWorksheetsField_sourceStudy">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyWorksheetsField_sourceStudy",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksheet",
      "kind": "LinkedField",
      "name": "worksheets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksheetTemplateVersion",
          "kind": "LinkedField",
          "name": "templateVersion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "532733002bf9d39223b3ab62860dcf0a";

export default node;
