import BlankSlate from '@bfly/ui2/BlankSlate';
import MainContent from '@bfly/ui2/MainContent';
import useMountEffect from '@restart/hooks/useMountEffect';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import AppPage from 'components/AppPage';
import AppStoreDownloadLinks from 'components/AppStoreDownloadLinks';
import LoadingIndicatorPage from 'components/LoadingIndicatorPage';
import ExternalRedirect from 'utils/ExternalRedirect';

import { isAndroid, isIos } from '../utils/browserInfo';
import { DownloadPage_viewer$data as Viewer } from './__generated__/DownloadPage_viewer.graphql';

interface Props {
  viewer: Viewer;
}

function DownloadPage({ viewer }: Props) {
  const ios = isIos();
  const android = isAndroid();

  const isOnlyIos = ios && !android;
  const isOnlyAndroid = android && !ios;

  useMountEffect(() => {
    if (isOnlyIos) {
      ExternalRedirect.redirect(globalThis.bflyConfig.APP_LINK_IOS);
    } else if (isOnlyAndroid) {
      ExternalRedirect.redirect(globalThis.bflyConfig.APP_LINK_ANDROID);
    }
  });

  if (isOnlyIos || isOnlyAndroid) {
    return <LoadingIndicatorPage />;
  }

  return (
    <AppPage center viewer={viewer}>
      <MainContent>
        <BlankSlate>
          <BlankSlate.Title>
            <FormattedMessage
              id="download.title"
              defaultMessage="Download App"
            />
          </BlankSlate.Title>
          <BlankSlate.Body>
            <FormattedMessage
              id="download.choose"
              defaultMessage="Choose your device to download the Butterfly iQ app."
            />
          </BlankSlate.Body>
          <AppStoreDownloadLinks className="mt-4" />
        </BlankSlate>
      </MainContent>
    </AppPage>
  );
}

export default createFragmentContainer(DownloadPage, {
  viewer: graphql`
    fragment DownloadPage_viewer on Viewer {
      ...AppPage_viewer
    }
  `,
});
