import Layout from '@4c/layout';
import DropdownIcon from '@bfly/icons/Dropdown';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useModalState from 'hooks/useModalState';

import NavbarSolidButton from './NavbarSolidButton';
import SwitchOrganizationModal, { messages } from './SwitchOrganizationModal';
import { OrganizationSwitcherButton_organization$data as Organization } from './__generated__/OrganizationSwitcherButton_organization.graphql';

interface Props {
  organization: Organization;
}

function OrganizationSwitcherButton({ organization }: Props) {
  const [open, { show, onHide }] = useModalState();

  return (
    <>
      <Tooltip.Trigger
        placement="bottom"
        tooltip={
          organization?.name || messages.allOrganizations.defaultMessage
        }
      >
        <NavbarSolidButton
          className="min-w-[13rem] max-w-[19rem] lg:max-w-[27rem]"
          onClick={open}
          data-bni-id="organization-switcher-button"
        >
          <Layout justify="space-between" align="center" className="w-full">
            <Text
              truncate
              align="left"
              color="headline"
              variant="body"
              className="mr-2"
            >
              {organization?.name || (
                <FormattedMessage {...messages.allOrganizations} />
              )}
            </Text>
            <DropdownIcon className="shrink-0 -rotate-90 text-subtitle" />
          </Layout>
        </NavbarSolidButton>
      </Tooltip.Trigger>
      <SwitchOrganizationModal
        activeOrganization={organization}
        onHide={onHide}
        show={show}
      />
    </>
  );
}

export default createFragmentContainer(OrganizationSwitcherButton, {
  organization: graphql`
    fragment OrganizationSwitcherButton_organization on Organization {
      name
      ...SwitchOrganizationModal_activeOrganization
    }
  `,
});
