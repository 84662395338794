/**
 * @generated SignedSource<<a6cc8ccc72bd96f46ed2a80e55aea5c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AllOrganizationsStudyList_tenant$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ExamQuickFilters_tenant">;
  readonly " $fragmentType": "AllOrganizationsStudyList_tenant";
};
export type AllOrganizationsStudyList_tenant$key = {
  readonly " $data"?: AllOrganizationsStudyList_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AllOrganizationsStudyList_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AllOrganizationsStudyList_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "includeAuthors",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "ExamQuickFilters_tenant"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "805fad8cde763a513263937913961599";

export default node;
