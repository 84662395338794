import { useCallback, useMemo } from 'react';

import usePreferences from 'hooks/usePreferences';

export enum ProficiencyColumnUserPreferenceKey {
  PROFICIENCY_DASHBOARD_LIST = 'proficiencyDashboardList',
}

export interface ProficiencyColumnUserPreference {
  [ProficiencyColumnUserPreferenceKey.PROFICIENCY_DASHBOARD_LIST]?: (
    | string[]
    | Record<string, boolean>
  )[];
  version?: string;
}

const useProficiencyColumnsUserPreference = (
  columnsKey: ProficiencyColumnUserPreferenceKey,
) => {
  const [preferredColumns, setPreferredColumns] =
    usePreferences<ProficiencyColumnUserPreference>(
      'proficiencyDashboardColumns',
    );

  const setColumns = useCallback(
    (next: ProficiencyColumnUserPreference) => {
      setPreferredColumns(next);
    },
    [setPreferredColumns],
  );

  const columns = useMemo(() => {
    return !preferredColumns || Array.isArray(preferredColumns)
      ? // new or invalid
        { [columnsKey]: [] }
      : // existing
        {
          ...preferredColumns,
          [columnsKey]: Array.isArray(preferredColumns?.[columnsKey])
            ? preferredColumns?.[columnsKey]
            : [],
        };
  }, [columnsKey, preferredColumns]);

  return [columns, setColumns] as const;
};

export default useProficiencyColumnsUserPreference;
