/**
 * @generated SignedSource<<54a0a7967d97422cdf80ff5f202213f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendStudyToPacsButton_pacs$data = {
  readonly id: string;
  readonly " $fragmentType": "SendStudyToPacsButton_pacs";
};
export type SendStudyToPacsButton_pacs$key = {
  readonly " $data"?: SendStudyToPacsButton_pacs$data;
  readonly " $fragmentSpreads": FragmentRefs<"SendStudyToPacsButton_pacs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SendStudyToPacsButton_pacs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Pacs",
  "abstractKey": null
};

(node as any).hash = "07ca06a9340712c4afda5a429113cd31";

export default node;
