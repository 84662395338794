/**
 * @generated SignedSource<<481d5f7dfab05f9bea9ce0e444d079f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyBatchActions_organization$data = {
  readonly handle: string | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MoveStudyModalRefresh_organization" | "MoveStudyModal_organization">;
  readonly " $fragmentType": "StudyBatchActions_organization";
};
export type StudyBatchActions_organization$key = {
  readonly " $data"?: StudyBatchActions_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyBatchActions_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyBatchActions_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MoveStudyModal_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MoveStudyModalRefresh_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "d1f443252a0f29b2a2f573a01d8ba78d";

export default node;
