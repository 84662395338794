import Layout from '@4c/layout';
import RelayInfiniteList from '@bfly/ui2/RelayInfiniteList';
import getNodes from '@bfly/utils/getNodes';
import useMountEffect from '@restart/hooks/useMountEffect';
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from 'react-relay';

import { NotificationCenterContextProvider } from 'utils/notificationCenterContext';

import SetNotificationCenterLastSeenAt from '../mutations/SetNotificationCenterLastSeenAt';
import { NotificationLoadingIndicator } from './Notification';
import NotificationCenterMarkAllReadControl from './NotificationCenterMarkAllReadControl';
import NotificationCenterViewAllControl from './NotificationCenterViewAllControl';
import NotificationNone from './NotificationNone';
import StudyFinalizationRequestedNotification from './StudyFinalizationRequestedNotification';
import StudyImageCommentNotification from './StudyImageCommentNotification';
import WorksheetReviewedNotification from './WorksheetReviewedNotification';
import { NotificationList_notificationCenter$data as NotificationCenter } from './__generated__/NotificationList_notificationCenter.graphql';

const PAGE_SIZE = 12;

const NOTIFICATION_COMPONENTS = {
  StudyFinalizationRequestedNotification,
  StudyImageCommentNotification,
  WorksheetReviewedNotification,
};

interface Props {
  notificationCenter: NotificationCenter;
  relay: RelayPaginationProp;
}

function NotificationList({ notificationCenter, relay }: Props) {
  const notifications = getNodes(notificationCenter.notificationConnection);

  const latestNotificationId = notifications?.[0]?.id;

  useMountEffect(() => {
    if (notificationCenter.numUnseenNotifications && latestNotificationId) {
      SetNotificationCenterLastSeenAt.commit(
        relay.environment,
        notificationCenter.id,
        latestNotificationId,
      );
    }
  });

  return (
    <NotificationCenterContextProvider
      notificationCenter={notificationCenter!}
    >
      {/* FIXME: this isn't keyboard accessible */}
      <NotificationCenterMarkAllReadControl
        notificationCenter={notificationCenter}
        latestNotificationId={latestNotificationId}
      />

      <Layout
        css={`
          max-height: 38rem;
        `}
        className="overflow-y-auto"
        direction="column"
      >
        {latestNotificationId ? (
          <RelayInfiniteList
            relayPagination={relay}
            pageSize={PAGE_SIZE}
            loadingIndicator={<NotificationLoadingIndicator />}
          >
            {notifications.map((notification) => {
              const NotificationComponent =
                NOTIFICATION_COMPONENTS[notification.typename];
              return (
                NotificationComponent && (
                  <NotificationComponent
                    key={notification.id}
                    notification={notification}
                    clearable
                  />
                )
              );
            })}
          </RelayInfiniteList>
        ) : (
          <NotificationNone />
        )}
      </Layout>
      <NotificationCenterViewAllControl
        notificationCenter={notificationCenter}
      />
    </NotificationCenterContextProvider>
  );
}

export default createPaginationContainer(
  NotificationList,
  {
    notificationCenter: graphql`
      fragment NotificationList_notificationCenter on NotificationCenter
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 24 } # PAGE_SIZE.
        cursor: { type: "String" }
      ) {
        id
        numUnseenNotifications
        notificationConnection(first: $count, after: $cursor)
          @connection(key: "NotificationCenter_notificationConnection") {
          edges {
            node {
              ... on Node {
                id
              }
              typename: __typename
              ... on StudyImageCommentNotification {
                ...StudyImageCommentNotification_notification
              }
              ... on WorksheetReviewedNotification {
                ...WorksheetReviewedNotification_notification
              }
              ... on StudyFinalizationRequestedNotification {
                ...StudyFinalizationRequestedNotification_notification
              }
            }
          }
        }
        ...NotificationCenterViewAllControl_notificationCenter
        ...NotificationCenterMarkAllReadControl_notificationCenter
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: ({ notificationCenter }) =>
      notificationCenter.notificationConnection,
    getVariables: ({ notificationCenter }, { count, cursor }) => ({
      notificationCenterId: notificationCenter.id,
      count,
      cursor,
    }),
    query: graphql`
      query NotificationListPaginationQuery(
        $notificationCenterId: ID!
        $count: Int!
        $cursor: String
      ) {
        notificationCenter: node(id: $notificationCenterId) {
          ...NotificationList_notificationCenter
            @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
