import { globalIdToLocalId } from '@bfly/utils/codecs';
import { RecordSourceSelectorProxy } from 'relay-runtime';

const isNumber = (val: number | null | undefined) => typeof val === 'number';

/**
 * A helper to update number of studies(numStudies) when requerying.
 */
export default function updateNumStudies(
  organizationId: string,
  store: RecordSourceSelectorProxy,
  newStudyStatistics: {
    numDraftStudiesFromViewer?: number | null | undefined;
    numDraftStudiesFromViewerAll?: number | null | undefined;
    numSignatureRequestedFromViewer?: number | null | undefined;
    numNeedsReviewFromViewer?: number | null | undefined;
    numUnassignedStudies?: number | null | undefined;
  },
  viewerId: string,
) {
  // In the main query, we use local ids to get data, so we need to do the same here
  const localViewerId = globalIdToLocalId(viewerId);
  const {
    numDraftStudiesFromViewer,
    numDraftStudiesFromViewerAll,
    numSignatureRequestedFromViewer,
    numNeedsReviewFromViewer,
    numUnassignedStudies,
  } = newStudyStatistics;
  const orgNode = store.get(organizationId)!;
  if (isNumber(numDraftStudiesFromViewer))
    orgNode?.setValue(numDraftStudiesFromViewer, 'numStudies', {
      search: {
        primaryAuthor: localViewerId,
        status: ['DRAFT'],
        excludedFromStudyLists: [false],
      },
    });
  if (isNumber(numDraftStudiesFromViewerAll))
    orgNode?.setValue(numDraftStudiesFromViewerAll, 'numStudies', {
      search: {
        author: localViewerId,
        status: ['DRAFT'],
        excludedFromStudyLists: [false],
      },
    });
  if (isNumber(numSignatureRequestedFromViewer))
    orgNode?.setValue(numSignatureRequestedFromViewer, 'numStudies', {
      search: {
        requestedFinalizer: localViewerId,
        status: ['PENDING_ATTESTATION'],
        excludedFromStudyLists: [false],
      },
    });
  if (isNumber(numNeedsReviewFromViewer))
    orgNode?.setValue(numNeedsReviewFromViewer, 'numStudies', {
      search: { status: ['NEEDS_REVIEW'], excludedFromStudyLists: [false] },
    });
  if (isNumber(numUnassignedStudies)) {
    orgNode?.setValue(numUnassignedStudies, 'numStudies', {
      search: { author: ['@@UNASSIGNED'] },
    });
  }
}
