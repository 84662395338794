import useRouter from 'found/useRouter';
import { useCallback } from 'react';

import { useConfigRoutes } from 'routes/config';

export default function useRedirectToPrevLocation() {
  const configRoutes = useConfigRoutes();
  const { router, match } = useRouter();

  return useCallback(() => {
    router.replace(
      match.location.state?.prevLocation || configRoutes.rootRoute(),
    );
  }, [router, match.location.state?.prevLocation, configRoutes]);
}
