import Layout from '@4c/layout';
import BlankSlate from '@bfly/ui2/BlankSlate';
import Link from '@bfly/ui2/Link';
import MainContent from '@bfly/ui2/MainContent';
import Navbar from '@bfly/ui2/Navbar';
import Page from '@bfly/ui2/Page';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuth } from 'components/AuthContext';
import { supportEmail } from 'config/app';

import { isInApp } from '../utils/browserInfo';

function ConnectionErrorPage() {
  const auth = useAuth();

  const onClickLogOut = useCallback(() => {
    auth.clearAccessToken(true);
  }, [auth]);

  return (
    <>
      <Navbar>
        <Navbar.Brand as={Link} to="/" />
        <Layout.Spacer />
        {auth.isAuthenticated() && !isInApp() && (
          <Navbar.Button onClick={onClickLogOut}>
            <FormattedMessage
              defaultMessage="Log Out"
              id="connectionErrorPage.logOut"
            />
          </Navbar.Button>
        )}
      </Navbar>
      <Page.Container center data-bni-id="ConnectionErrorPage">
        <MainContent centerText>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                defaultMessage="An error occurred while connecting to Butterfly Cloud."
                id="connectionErrorPage.title"
              />
            </BlankSlate.Title>
            <BlankSlate.Body>
              <FormattedMessage
                defaultMessage="To retry, refresh the page."
                id="connectionErrorPage.body"
              />
            </BlankSlate.Body>
            {/* Use email instead of Zendesk in case SSO is broken */}
            <BlankSlate.Action href={`mailto:${supportEmail}`}>
              <FormattedMessage
                defaultMessage="Contact Support"
                id="connectionErrorPage.support"
              />
            </BlankSlate.Action>
          </BlankSlate>
        </MainContent>
      </Page.Container>
    </>
  );
}

export default ConnectionErrorPage;
