import Layout from '@4c/layout';
import Highlighter from '@bfly/ui2/Highlighter';
import Spinner from '@bfly/ui2/Spinner';
import Text from '@bfly/ui2/Text';
import styled from 'astroturf/react';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, readInlineData } from 'react-relay';
import List, { ListHandle } from 'react-widgets/List';

import Avatar from 'components/Avatar';

import { SearchConstants } from './SearchBarTags';
import { SearchBarList_archive$key as ArchiveKey } from './__generated__/SearchBarList_archive.graphql';
import { SearchBarList_studyTag$key as StudyTagKey } from './__generated__/SearchBarList_studyTag.graphql';
import { SearchBarList_userProfile$key as UserProfileKey } from './__generated__/SearchBarList_userProfile.graphql';

type ArchiveItem = { type: 'Archive' } & ArchiveKey;
type UserProfileItem = { type: 'UserProfile' } & UserProfileKey;
type StudyTagItem = { type: 'StudyTag' } & StudyTagKey;

const GROUP_NAMES = {
  Archive: (
    <FormattedMessage
      id="studySearchBar.group.archive"
      defaultMessage="Archive"
    />
  ),
  UserProfile: (
    <FormattedMessage
      id="studySearchBar.group.userProfile"
      defaultMessage="Study By"
    />
  ),
  StudyTag: (
    <FormattedMessage
      id="studySearchBar.group.studyTag"
      defaultMessage="Tags"
    />
  ),
};

type SearchItem =
  | SearchConstants.FREE_TEXT_SEARCH
  | ArchiveItem
  | UserProfileItem
  | StudyTagItem;

const SpinnerWrapper = styled('div')`
  height: 3rem;
  margin: 1rem;
  text-align: center;
`;

function renderGroup({ group }) {
  if (group === SearchConstants.FREE_TEXT_SEARCH) return null;

  return (
    <Text variant="sm" transform="uppercase" color="inherit">
      {GROUP_NAMES[group]}
    </Text>
  );
}

function renderHighlighted(text: string, search: string) {
  return (
    <span>
      <Highlighter
        text={text}
        search={search}
        highlightedClassName="font-extrabold"
      />
    </span>
  );
}

function renderSearchItem({
  item,
  searchTerm,
}: {
  item: SearchItem;
  searchTerm?: string;
}): React.ReactNode {
  if (item === SearchConstants.FREE_TEXT_SEARCH) {
    return (
      <div>
        <FormattedMessage
          id="searchBar.searchFor"
          defaultMessage="Search for <highlight>“{searchTerm}”</highlight>"
          values={{
            searchTerm,
            highlight: (str: string) => (
              <span className="font-extrabold">{str}</span>
            ),
          }}
        />
      </div>
    );
  }

  if (item.type === 'Archive') {
    const archive = readInlineData(
      graphql`
        fragment SearchBarList_archive on Archive @inline {
          label
        }
      `,
      item,
    );

    return renderHighlighted(archive.label!, searchTerm!);
  }

  if (item.type === 'StudyTag') {
    const studyTag = readInlineData(
      graphql`
        fragment SearchBarList_studyTag on StudyTag @inline {
          name
        }
      `,
      item,
    );

    return renderHighlighted(studyTag.name!, searchTerm!);
  }

  if (item.type === 'UserProfile') {
    const userProfile = readInlineData(
      graphql`
        fragment SearchBarList_userProfile on UserProfile @inline {
          name
          ...Avatar_userProfile
        }
      `,
      item,
    );

    return (
      <Layout align="center">
        <Avatar width={20} className="mr-2" userProfile={userProfile} />
        {renderHighlighted(userProfile.name!, searchTerm!)}
      </Layout>
    );
  }

  throw new Error(`Unexpected item: ${item}`);
}

const SearchBarList = React.forwardRef<ListHandle, any>(
  ({ busy, ...props }, ref) => {
    return (
      <div css="overflow: hidden;">
        {busy && (
          <SpinnerWrapper>
            <Spinner size="md" />
          </SpinnerWrapper>
        )}

        {/* FIXME: actually type the data items */}
        <List<any>
          {...props}
          ref={ref}
          className={clsx(busy && 'hidden')}
          renderItem={renderSearchItem}
          renderGroup={renderGroup}
        />
      </div>
    );
  },
);

export default SearchBarList;
