import RedirectException from 'found/RedirectException';

import type { Match } from 'components/Route';

import isRoute from './isRoute';

/* TODO: Remove redirectIfNotRoute and change naming of redirectIfNotPathnameRoute to isRoute once all the name routes are removed */
export default function redirectIfNotRoute(match: Match, name: string) {
  if (!isRoute(match, name)) {
    throw new RedirectException({
      name,
      params: match.params,
      state: { prevLocation: match.location },
    });
  }
}
