/**
 * @generated SignedSource<<671e45fea85b23be9c0b4d03bed822c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSharedImageCarousel_image$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImage_image">;
  readonly " $fragmentType": "DesktopSharedImageCarousel_image";
};
export type DesktopSharedImageCarousel_image$key = {
  readonly " $data"?: DesktopSharedImageCarousel_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopSharedImageCarousel_image">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopSharedImageCarousel_image",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyImage_image"
    }
  ],
  "type": "SharedBundleImage",
  "abstractKey": null
};

(node as any).hash = "05543a8a5069baa6223c7ce58a1db50f";

export default node;
