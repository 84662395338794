import Layout from '@4c/layout';
import ArrowRightIcon from '@bfly/icons/ArrowRight';
import DrawIcon from '@bfly/icons/Draw';
import TrashCanIcon from '@bfly/icons/TrashCan';
import Button from '@bfly/ui2/Button';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import useDeleteStudiesHandler from 'hooks/useDeleteStudiesHandler';
import useModalState from 'hooks/useModalState';
import actionMessages from 'messages/actions';
import * as StudySelection from 'utils/StudySelection';
import { usePermissions } from 'utils/viewerContext';

import AssignExamTypesToStudiesModal from './AssignExamTypesToStudiesModal';
import MoveStudyModal from './MoveStudyModal';
import MoveStudyModalRefresh from './MoveStudyModalRefresh';
import { NewStudyDataGridActions_domain$data as Domain } from './__generated__/NewStudyDataGridActions_domain.graphql';
import { NewStudyDataGridActions_organization$data as Organization } from './__generated__/NewStudyDataGridActions_organization.graphql';

interface Props {
  show: boolean;
  allowDelete: boolean;
  allowMove: boolean;
  allowAssignExamTypes: boolean;
  organization: Organization;
  domain: Domain | null;
}

function NewStudyDataGridActions({
  show,
  allowDelete,
  allowMove,
  allowAssignExamTypes,
  organization,
  domain,
}: Props) {
  const gridHeaderHeight = '4rem';
  const checkboxColumnWidth = '4rem';
  const aboveGridZIndex = 10;
  const [showMoveModal, moveModalProps] = useModalState();
  const [showAssignExamTypesModal, assignExamTypesModalProps] =
    useModalState();
  const [contextValue] = StudySelection.useSelectionContextValue({
    allowDelete,
    allowMove,
    allowAssignExamTypes,
  });
  const {
    viewerCanDeleteSelected,
    viewerCanMoveSelected,
    selectedStudyItems,
    viewerCanAssignExamTypesToSelected,
  } = contextValue;

  const handleClick = useDeleteStudiesHandler(
    organization.id,
    domain ? domain.id : '',
    selectedStudyItems,
  );

  const { hasBasicPermission } = usePermissions();

  const canUseMoveStudyOrg = useVariation('move-study-org');

  if (!show) return null;

  return (
    <>
      <Layout
        className="bg-grey-85 absolute"
        css={css`
          width: calc(100% - ${checkboxColumnWidth});
          margin-left: ${checkboxColumnWidth};
          height: ${gridHeaderHeight};
          z-index: ${aboveGridZIndex};
        `}
      >
        {viewerCanMoveSelected && (
          <Layout align="center" className="mr-3" onClick={showMoveModal}>
            <Button variant="text-secondary">
              <ArrowRightIcon width={16} height={16} className="mr-2" />
              <FormattedMessage {...actionMessages.move} />
            </Button>
          </Layout>
        )}

        {viewerCanDeleteSelected && (
          <Layout align="center" className="mr-3" onClick={handleClick}>
            <Button
              variant="text-secondary"
              data-bni-id="DeleteSelectedButton"
            >
              <TrashCanIcon width={16} height={16} className="mr-2" />
              <FormattedMessage {...actionMessages.delete} />
            </Button>
          </Layout>
        )}
        {hasBasicPermission('examTypeManagement') &&
          viewerCanAssignExamTypesToSelected && (
            <Layout
              align="center"
              className="mr-3"
              onClick={showAssignExamTypesModal}
            >
              <Button
                variant="text-secondary"
                data-bni-id="AssignExamTypesToStudiesButton"
              >
                <DrawIcon width={16} height={16} className="mr-2" />
                <FormattedMessage
                  id="newStudyDataGridActions.assignExamTypesRows"
                  defaultMessage="Assign exam types"
                />
              </Button>
            </Layout>
          )}
        <Text className="text-white my-auto mx-auto pr-[30%]">
          <FormattedMessage
            id="newStudyDataGridActions.numberSelectedExams"
            defaultMessage={`
              <strong>{numSelectedExams}</strong>
              {numSelectedExams, plural, one {exam} other {exams}}
              on this page
              {numSelectedExams, plural, one {is} other {are}}
              selected
            `}
            values={{
              strong: (msg: number | string) => <strong>{msg}</strong>,
              numSelectedExams: selectedStudyItems.length,
            }}
          />
        </Text>
      </Layout>
      {canUseMoveStudyOrg ? (
        <MoveStudyModalRefresh
          {...moveModalProps}
          organization={organization}
          domain={domain}
          studyAndArchiveIds={selectedStudyItems}
        />
      ) : (
        <MoveStudyModal
          {...moveModalProps}
          organization={organization}
          domain={domain}
          studyAndArchiveIds={selectedStudyItems}
        />
      )}
      <AssignExamTypesToStudiesModal
        {...assignExamTypesModalProps}
        organization={organization}
        studyItems={selectedStudyItems}
      />
    </>
  );
}

export default createFragmentContainer(NewStudyDataGridActions, {
  organization: graphql`
    fragment NewStudyDataGridActions_organization on Organization {
      id
      handle
      ...MoveStudyModal_organization
      ...MoveStudyModalRefresh_organization
      ...AssignExamTypesToStudiesModal_organization
    }
  `,
  domain: graphql`
    fragment NewStudyDataGridActions_domain on Domain {
      id
      ...MoveStudyModal_domain
      ...MoveStudyModalRefresh_domain
    }
  `,
});
