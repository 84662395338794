import Button from '@bfly/ui2/Button';
import CopyToClipboardControl from '@bfly/ui2/CopyToClipboardControl';
import Modal from '@bfly/ui2/Modal';
import useRouter from 'found/useRouter';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';
import { useEducationRoutes } from 'routes/education';
import Analytics from 'utils/Analytics';
import withModal from 'utils/withModal';

import educationMessages from '../messages/educationMessages';
import { ShareEducationContentModal_educationContent$data as EducationContent } from './__generated__/ShareEducationContentModal_educationContent.graphql';

function getUrl(href: string) {
  if (!href) {
    return '';
  }

  return `${window.location.origin}${href}?utm_source=edu_share_modal`;
}

interface Props {
  educationContent: EducationContent | null;
  onHide: () => void;
}

function ShareEducationContentModal({ educationContent, onHide }: Props) {
  const educationRoutes = useEducationRoutes();
  const { router } = useRouter();

  const href = educationContent
    ? router.createHref(
        educationRoutes.educationContent({
          handle: educationContent.handle!,
        }),
      )
    : '';

  const url = getUrl(href);

  useEffect(() => {
    if (educationContent) {
      Analytics.track('educationVideoShared', {
        id: educationContent.localId!,
      });
    }
  }, [educationContent]);

  if (
    !educationContent?.viewerHasAccess ||
    educationContent?.contentType !== 'VIDEO'
  ) {
    return null;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...educationMessages.shareTitle} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {educationContent?.isPremium && (
          <p className="mb-4">
            <FormattedMessage {...educationMessages.subscribersOnlyContent} />
          </p>
        )}
        <CopyToClipboardControl value={url} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button size="lg" onClick={onHide}>
            <FormattedMessage {...actionMessages.close} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(withModal(ShareEducationContentModal), {
  educationContent: graphql`
    fragment ShareEducationContentModal_educationContent on EducationContent {
      localId
      handle
      isPremium
      contentType
      viewerHasAccess
    }
  `,
});
