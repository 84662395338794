import Layout from '@4c/layout';
import DuplicateRole from '@bfly/icons/DuplicateRole';
import NewRole from '@bfly/icons/NewRole';
import Button from '@bfly/ui2/Button';
import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import FormCheck from '@bfly/ui2/FormCheck';
import Heading from '@bfly/ui2/Heading';
import Modal from '@bfly/ui2/Modal';
import getNodes from '@bfly/utils/getNodes';
import { css } from 'astroturf';
import { Link } from 'found';
import { ReactNode, useState } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessage,
  defineMessages,
} from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';
import { useAdminRoutes } from 'routes/admin';
import withModal from 'utils/withModal';

import { AddDomainRoleModal_viewer$data as Viewer } from './__generated__/AddDomainRoleModal_viewer.graphql';

const messages = defineMessages({
  placeholder: {
    id: 'addDomainRoleModal.roleList.placeholder',
    defaultMessage: 'Choose a Butterfly access role...',
  },
});

interface Props {
  onHide: () => void;
  viewer: Viewer;
}

interface PickerItemProps {
  value: string;
  defaultChecked?: boolean;
  radioName: string;
  onChangeCheck?: () => void;
  message: MessageDescriptor;
  icon: ReactNode;
}

function DomainRoleTypePickerItem({
  value,
  defaultChecked = false,
  radioName,
  onChangeCheck,
  message,
  icon: Icon,
}: PickerItemProps) {
  return (
    <Layout className="mr-12">
      <FormCheck
        defaultChecked={defaultChecked}
        type="radio"
        className="text-primary items-center"
        onChange={onChangeCheck}
        name={radioName}
        value={value}
      />
      <Layout direction="column" align="center" className="ml-4">
        {Icon}
        <FormattedMessage {...message} />
      </Layout>
    </Layout>
  );
}

function AddDomainRoleModal({ onHide, viewer }: Props) {
  const adminRoutes = useAdminRoutes();
  const [isNewRole, setIsNewRole] = useState(false);
  const [membershipRoleId, setMembershipRoleId] = useState('');
  const rolesList = getNodes(viewer.membershipRoles);

  return (
    <Form>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="addDomainRoleModal.title"
            defaultMessage="Add new Butterfly Access Role"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Layout>
            <DomainRoleTypePickerItem
              value="duplicate"
              defaultChecked
              radioName="roleType"
              onChangeCheck={() => setIsNewRole(false)}
              icon={
                <DuplicateRole
                  width={50}
                  className={!isNewRole ? 'text-primary' : 'text-grey-25'}
                />
              }
              message={{
                id: 'addDomainMemberType.duplicate',
                defaultMessage: 'Duplicate',
              }}
            />
            <DomainRoleTypePickerItem
              value="new"
              radioName="roleType"
              onChangeCheck={() => setIsNewRole(true)}
              icon={
                <NewRole
                  width={50}
                  className={isNewRole ? 'text-primary' : 'text-grey-25'}
                />
              }
              message={{
                id: 'addDomainMemberType.createNew',
                defaultMessage: 'Create New',
              }}
            />
          </Layout>

          {!isNewRole && (
            <>
              <Heading ruled className="mt-2" />
              <Form.FieldGroup
                variant="secondary"
                menuVariant="dark"
                name="existingRoleId"
                data-bni-id="SelectExistingRole"
                label={defineMessage({
                  id: 'addDomainRoleModal.roleList.field',
                  defaultMessage:
                    'Existing Butterfly Access Role to duplicate',
                })}
                className="items-center"
                placeholder={messages.placeholder}
                as={DropdownList}
                data={rolesList}
                dataKey="id"
                textField="name"
                onChange={(value) => setMembershipRoleId(value.id)}
                css={css`
                  & :global(.rw-popup-container) {
                    position: relative !important;
                  }
                `}
              />
            </>
          )}
        </>
      </Modal.Body>

      <Modal.Footer>
        <Modal.ButtonGroup>
          <Link
            to={
              isNewRole
                ? adminRoutes.domainRoleNew()
                : adminRoutes.domainRoleDuplicate({ membershipRoleId })
            }
          >
            {(props) => (
              <Button
                disabled={!isNewRole && membershipRoleId === ''}
                size="flush"
                variant="primary"
                {...props}
              >
                <FormattedMessage {...actionMessages.continue} />
              </Button>
            )}
          </Link>
          <Button size="lg" variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </Form>
  );
}
export default createFragmentContainer(
  withModal(AddDomainRoleModal, { variant: 'dark' }),
  {
    viewer: graphql`
      fragment AddDomainRoleModal_viewer on Viewer {
        membershipRoles(
          sort: NAME_ASC
          roleType: [SYSTEM_DEFINED, USER_DEFINED]
        ) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
);
