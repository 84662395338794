import BlankSlate from '@bfly/ui2/BlankSlate';
import MainContent from '@bfly/ui2/MainContent';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import AuthContent from 'components/AuthContent';
import { supportEmail } from 'config/app';

import { InactiveAccountPage_viewer$data as Viewer } from './__generated__/InactiveAccountPage_viewer.graphql';

interface Props {
  viewer: Viewer;
}
function InactiveAccountPage({ viewer }: Props) {
  return (
    <MainContent centerText size="xs">
      {!viewer.domain ? (
        <>
          <AuthContent.Title>
            <FormattedMessage
              id="inactiveAccount.pageTitle"
              defaultMessage="No Butterfly Cloud Membership"
            />
          </AuthContent.Title>
          <AuthContent.Description>
            <FormattedMessage
              id="inactiveAccount.pageMessage"
              defaultMessage="A membership to at least one cloud organization
      is required. If you recently made a purchase, please complete the
      purchase via the invite link sent to your email. Contact support
      if you believe you are seeing this message in error, or if you would
      like to purchase a Butterfly Cloud Membership."
            />
          </AuthContent.Description>
          {/* Use email instead of Zendesk in case SSO is broken */}
          <BlankSlate.Action href={`mailto:${supportEmail}`}>
            <FormattedMessage
              defaultMessage="Contact Support"
              id="inactiveAccount.contactSupport"
            />
          </BlankSlate.Action>{' '}
        </>
      ) : (
        <>
          <AuthContent.Title>
            <FormattedMessage
              id="inactiveAccount.domain.pageTitle"
              defaultMessage="No Organization Memberships"
            />
          </AuthContent.Title>
          <AuthContent.Description>
            <FormattedMessage
              id="inactiveAccount.domain.pageMessage"
              defaultMessage="You are not a member of any organizations within this enterprise.
                Ask your enterprise administrator to add you to an organization to begin using Butterfly Cloud"
            />
          </AuthContent.Description>
        </>
      )}
    </MainContent>
  );
}

export default createFragmentContainer(InactiveAccountPage, {
  viewer: graphql`
    fragment InactiveAccountPage_viewer on Viewer {
      domain {
        __typename
      }
    }
  `,
});
