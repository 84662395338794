import React, {
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { SelectedItemsByKey } from 'components/MultiSelectContext';

interface UnenrollmentContextValues<T = any> {
  clear: () => void;
  selectedUsersCount: number;
  selectedItems: SelectedItemsByKey<SelectedItemsByKey<T>>;
  getSelectedUsers: (courseID: string) => SelectedItemsByKey<T> | undefined;
  setSelectedUsers: (
    courseID: string,
    next: SetStateAction<SelectedItemsByKey<T>>,
  ) => void;
}

const UnenrollmentContext =
  React.createContext<UnenrollmentContextValues | null>(null);

interface UnenrollmentContextProviderProps {
  children?: ReactNode;
}

export function UnenrollmentContextProvider({
  children,
}: UnenrollmentContextProviderProps) {
  const [selectedItems, setSelectedItems] = useState<
    SelectedItemsByKey<SelectedItemsByKey>
  >(() => new Map());

  const clear = useCallback(() => {
    setSelectedItems(new Map());
  }, []);

  const selectedUsersCount = useMemo(() => {
    return [...selectedItems.values()].reduce(
      (sum, list) => sum + list.size,
      0,
    );
  }, [selectedItems]);

  const getSelectedUsers = useCallback(
    (courseID: string) => selectedItems.get(courseID),
    [selectedItems],
  );

  const setSelectedUsers = useCallback(
    (courseID: string, next: SetStateAction<SelectedItemsByKey>) => {
      return setSelectedItems((prev) => {
        const nextValue = new Map(prev);

        nextValue.set(
          courseID,
          typeof next === 'function'
            ? next(getSelectedUsers(courseID) ?? new Map())
            : next,
        );

        return nextValue;
      });
    },
    [getSelectedUsers],
  );

  return (
    <UnenrollmentContext.Provider
      value={useMemo(
        () => ({
          clear,
          selectedItems,
          getSelectedUsers,
          setSelectedUsers,
          selectedUsersCount,
        }),
        [
          clear,
          selectedItems,
          getSelectedUsers,
          setSelectedUsers,
          selectedUsersCount,
        ],
      )}
    >
      {children}
    </UnenrollmentContext.Provider>
  );
}

export function useUnenrollmentContext(): UnenrollmentContextValues {
  const context = useContext(UnenrollmentContext);

  if (!context) {
    throw new Error(
      'useUnenrollmentContext must be called within UnenrollmentContextProvider',
    );
  }

  return context;
}
