import { Redirect } from 'found';
import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import adminRoutes from 'routes/admin';
import {
  checkFlagsOr404,
  checkIsScopedUnderTenantOr404,
  checkTenantPermission,
} from 'utils/RouteAccessControl';

import { credentialGroupsRoute_CredentialGroupsPageQuery as CredentialGroupsPageQuery } from './__generated__/credentialGroupsRoute_CredentialGroupsPageQuery.graphql';

export default (
  <>
    <Redirect
      from="credential-groups"
      to={(match) => adminRoutes(match).proficiencyGroups()}
    />
    <Route<CredentialGroupsPageQuery>
      path="proficiency-groups"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/CredentialGroupsPage'
        ).then((m) => m.default)
      }
      prepareVariables={(variables, { location: { query } }) => ({
        ...variables,
        sort: query.sort,
      })}
      prerender={chainPrerenders(
        checkFlagsOr404('credentialing-analytics-page'),
        checkIsScopedUnderTenantOr404,
        checkTenantPermission('credentialManagement', 'BASIC'),
      )}
      query={graphql`
        query credentialGroupsRoute_CredentialGroupsPageQuery(
          $organizationSlug: String
          $first: Int = 20
          $after: String
          $sort: [CredentialGroupSorting!]
          $search: String
        ) {
          tenant(slug: $organizationSlug) {
            ...RouteAccessControl_tenant
            ...CredentialGroupsPage_tenant
          }
        }
      `}
    />
  </>
);
