import Button from '@bfly/ui2/Button';
import Heading from '@bfly/ui2/Heading';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import groupBy from 'lodash/groupBy';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';

import { bulkDomainUsersDeactivateUsersResultsMessages as messages } from '../messages/DomainMembersMessages';
import { BulkDomainUsersDeactivateUsersResultsModal_failures$data as Failures } from './__generated__/BulkDomainUsersDeactivateUsersResultsModal_failures.graphql';

const LAST_ENT_ADMIN =
  'invalid_deletion.deleting_last_enterprise_admin_user_is_forbidden';

interface Props {
  numDeactivated: number;
  failures: Failures;
  onHide: () => void;
}

function BulkDeactivateUsersResultsModal({
  failures,
  numDeactivated,
  onHide,
}: Props) {
  const grouped = groupBy(failures, (f) => f.code);

  return (
    <>
      <Modal.Header>
        <Modal.Title data-bni-id="BulkDeactivateUsersResultsModalTitle">
          <FormattedMessage {...messages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Heading ruled data-bni-id="BulkDeactivateUsersResultsModalHeading">
          <FormattedMessage
            {...messages.deactivated}
            values={{ numDeactivated }}
          />
        </Heading>
        {!!grouped[LAST_ENT_ADMIN]?.length && (
          <details className="mb-4">
            <summary>
              <Text color="danger" variant="body-bold" className="mb-4">
                <FormattedMessage
                  {...messages.deactivatedSummary}
                  values={{ numFailed: grouped[LAST_ENT_ADMIN].length }}
                />
              </Text>
              <Text as="div" color="subtitle" variant="sm" className="ml-4">
                <FormattedMessage {...messages.subtitle} />
              </Text>
            </summary>
            <ul
              className="list-unstyled pl-4 mt-3"
              data-bni-id="BulkDeactivateUsersResultsModalFailures"
            >
              {grouped[LAST_ENT_ADMIN].map((failed) => (
                <li key={failed.email!}>{failed.email!}</li>
              ))}
            </ul>
          </details>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>
          <FormattedMessage {...actionMessages.done} />
        </Button>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(BulkDeactivateUsersResultsModal, {
  failures: graphql`
    fragment BulkDomainUsersDeactivateUsersResultsModal_failures on BulkDomainUserDeleteFailure
    @relay(plural: true) {
      detail
      code
      email
    }
  `,
});
