import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Tooltip from '@bfly/ui2/Tooltip';
import useDialog from '@bfly/ui2/useDialog';
import {
  CommitMutation,
  MutationParametersWithError,
} from '@bfly/ui2/useMutationWithError';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import useRouter from 'found/useRouter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { Descendant, Text } from 'slate';

import {
  getEditorStateAsString,
  isEditorStateEmpty,
} from '../utils/mentionHelpers';
import CommentEditor, { CommentEditorHandle } from './CommentEditor';
import { CommentForm_image$data as StudyImage } from './__generated__/CommentForm_image.graphql';

const MAX_CHARS = 500;

const styles = stylesheet`
  .editor {
    flex: 1;
    min-width: 0;
    width: 100%;

    :global(.public-DraftEditor-content) {
      min-height: 0;
      max-height: 2.4em;
      overflow: auto;
    }
  }
`;

const serialize = (node) => {
  if (Text.isText(node)) {
    const string = node.text;
    return string;
  }

  const children = node.children.map((n) => serialize(n)).join('');

  if (node.type === 'mention') {
    return `@[${node.user.userProfile.handle}]`;
  }

  return children;
};

interface Props<T extends MutationParametersWithError> {
  image: StudyImage;
  multiline?: boolean;
  mutate: CommitMutation<T>;
  busy?: boolean;
  className?: string;
  onSubmit: () => void;
  onFocus?: () => void;
}

const CommentForm = React.forwardRef<
  CommentEditorHandle,
  Props<MutationParametersWithError>
>(
  (
    {
      className,
      image,
      onSubmit,
      mutate,
      busy,
      multiline = false,
      onFocus,
    }: Props<MutationParametersWithError>,
    ref,
  ) => {
    const commentsDisabled =
      !image.organization!.subscription!.canAccessProFeatures;

    // Slate requires an initial state
    const initialState = useMemo(
      () =>
        [
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ] as Descendant[],
      [],
    );

    const [editorState, setEditorState] = useState<Descendant[]>(initialState);

    const dialog = useDialog();
    const { router } = useRouter();

    useEffect(() => {
      const removeNavigationListener = router.addNavigationListener(
        // This function must not be async, as we need a synchronous return
        //  value for the beforeunload handler.
        (location) => {
          if (isEditorStateEmpty(editorState)) {
            return undefined;
          }
          if (!location) {
            return false;
          }

          return dialog
            .open(
              <FormattedMessage
                id="comment.unsaved.dialog.content"
                defaultMessage="Are you sure you want to leave? Your drafted comment will be lost."
              />,
              {
                title: (
                  <FormattedMessage
                    id="comment.unsaved.dialog.title"
                    defaultMessage="You Have an Unsubmitted Comment"
                  />
                ),
              },
            )
            .then(Boolean)
            .then((confirmed) => {
              if (confirmed) {
                removeNavigationListener();
              }

              return confirmed;
            });
        },
        { beforeUnload: true },
      );

      return removeNavigationListener;
    }, [dialog, editorState, router]);

    const clear = useCallback(() => {
      setEditorState(initialState);
    }, [initialState]);

    const handleChange = useCallback(
      (nextEditorState) => {
        if (busy) {
          return;
        }
        setEditorState(nextEditorState);
      },
      [busy],
    );

    const handleSubmit = async () => {
      if (busy) {
        return;
      }

      const currentContentLength = getEditorStateAsString(editorState).length;

      if (currentContentLength > MAX_CHARS) {
        dialog.open(
          <FormattedMessage
            id="comment.tooLong.dialog.content"
            defaultMessage="The maximum comment length is {maxChars} characters."
            values={{ maxChars: MAX_CHARS }}
          />,
          {
            title: (
              <FormattedMessage
                id="comment.tooLong.dialog.title"
                defaultMessage="Your Comment Is {numExtraChars} Characters Too Long"
                values={{ numExtraChars: currentContentLength - MAX_CHARS }}
              />
            ),
            hideCancel: true,
          },
        );

        return;
      }

      const value = editorState;
      if (!value) {
        return;
      }
      const bodyValue = value.map((line) => serialize(line)).join('\n');

      try {
        await mutate({
          input: {
            body: bodyValue,
            organizationId: image.organization!.id,
            imageId: image.id,
          },
        });
        onSubmit();
        clear();
      } catch (e) {
        // eat, handled upstream
      }
    };

    return (
      <Tooltip.Trigger
        id="comment-form-disabled"
        tooltip={
          <FormattedMessage
            id="comment.expirationTooltip"
            defaultMessage="Commenting available for Pro subscribers"
          />
        }
        placement="top"
        variant="subscription"
        show={commentsDisabled ? undefined : false}
      >
        <Layout align="center" className={clsx(className, 'relative')}>
          <CommentEditor
            studyImage={image}
            multiline={multiline}
            editorState={editorState}
            disabled={!!commentsDisabled}
            className={styles.editor}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onFocus={onFocus}
            ref={ref}
          />
          <Button
            data-bni-id="CommentSaveBtn"
            variant="text-secondary"
            className="px-5 self-start"
            busy={busy}
            disabled={!!commentsDisabled || isEditorStateEmpty(editorState)}
            onClick={handleSubmit}
          >
            <FormattedMessage id="comment.submit" defaultMessage="Comment" />
          </Button>
        </Layout>
      </Tooltip.Trigger>
    );
  },
);

export default createFragmentContainer(CommentForm, {
  image: graphql`
    fragment CommentForm_image on StudyImage {
      id
      viewerIsFollowing
      organization {
        subscription {
          canAccessProFeatures
        }
        id
      }
      ...CommentEditor_studyImage
    }
  `,
});
