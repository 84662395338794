import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

export default (
  <Route
    path="members"
    getComponent={() =>
      import(
        /* webpackChunkName: "admin" */ './components/MembersContent'
      ).then((m) => m.default)
    }
    query={graphql`
      query members_MembersContent_Query($organizationSlug: String!) {
        organization: organizationBySlug(slug: $organizationSlug) {
          ...MembersContent_organization
        }
      }
    `}
  />
);
