import Layout from '@4c/layout';
import Page from '@bfly/ui2/Page';
import ToastContainer from '@bfly/ui2/ToastContainer';
import useResponsive from '@bfly/ui2/useResponsive';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useRouter } from 'found';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ExamImageOverlayControls from 'components/ExamImageOverlayControls';
import ExperiencesProvider from 'components/ExperiencesProvider';
import SubscriptionExpiredBanner from 'components/SubscriptionExpiredBanner';
import { useExamRoutes } from 'routes/exam';
import { useOrganizationRoutes } from 'routes/organization';
import { useSetLastViewedStudy } from 'utils/LastViewedStudy';
import { useViewerContext } from 'utils/viewerContext';

import { useShowQa } from '../utils/examPageSidebar';
import { ExamContextProvider } from './ExamContext';
import ExamPageBanner from './ExamPageBanner';
import ExamPageSidebar from './ExamPageSidebar';
import ExamTopNavbar from './ExamTopNavbar';
import FullScreenExamPane from './FullScreenExamPane';
import HeaderPatientInfo from './HeaderPatientInfo';
import type { ExamPage_organization$data as Organization } from './__generated__/ExamPage_organization.graphql';
import type { ExamPage_study$data as Study } from './__generated__/ExamPage_study.graphql';

interface Props {
  study: Study;
  children: React.ReactNode;
  studyListNav: React.ReactNode;
  organization: Organization;
  navigationControls: React.ReactNode;
}

function ExamPage({
  study,
  children,
  navigationControls,
  organization,
  studyListNav: _,
}: Props) {
  const intl = useIntl();
  const { match } = useRouter();
  const { location } = match;
  const isFullScreen = location.query.fullscreen;
  const viewer = useViewerContext();

  useSetLastViewedStudy(study.handle!, viewer.profile!.id);

  const orgRoutes = useOrganizationRoutes();
  const showQaColumn = useShowQa(study);

  const isLarge = useResponsive({ [showQaColumn ? 'lg' : 'md']: 'up' });

  const routes = useExamRoutes();

  return (
    <ExamContextProvider key={study.handle}>
      <ExperiencesProvider>
        <Page layout={isLarge ? 'fill' : 'overflow'}>
          <ToastContainer
            dismissAfter={5000}
            id="examPage"
            className="top-[80px]"
          />
          <Helmet bodyAttributes={{ class: 'bg-black' }}>
            <title>
              {intl.formatMessage({
                id: 'exam.title',
                defaultMessage: 'exam',
              })}
            </title>
          </Helmet>
          <ExamTopNavbar
            to={orgRoutes.organizationRoot()}
            study={study}
            navigationControls={navigationControls}
          />
          <SubscriptionExpiredBanner organization={organization} />
          <ExamPageBanner study={study} />
          <Page.Container
            className={clsx(
              'bg-body top-auto',
              showQaColumn
                ? 'lg:grid lg:grid-cols-[1fr,66rem] xl:grid-cols-[1fr,72rem]'
                : 'md:grid md:grid-cols-[1fr,48rem] xl:grid-cols-[1fr,60rem]',
            )}
          >
            <div className="page-main relative">
              <FullScreenExamPane className="flex flex-col bg-black">
                {!!isFullScreen && (
                  <Layout
                    justify="center"
                    style={{ gridArea: 'patient' }}
                    className="px-6 w-full fixed transition-opacity duration-200 opacity-0 hover:opacity-100 ease-in"
                    data-bni-id="ExamPatientInfoFullscreen"
                    css={css`
                      z-index: 1;
                      background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 1) 70%,
                        rgba(255, 255, 255, 0) 100%
                      );
                    `}
                  >
                    <HeaderPatientInfo study={study} />
                  </Layout>
                )}
                <div className="absolute top-4 right-4 z-10 flex flex-col space-y-2 m-[-1rem]">
                  <ExamImageOverlayControls
                    exam={routes.exam}
                    examGallery={routes.examGallery}
                    examImage={routes.examImage}
                  />
                </div>
                {children}
              </FullScreenExamPane>
            </div>
            <ExamPageSidebar study={study} />
          </Page.Container>
        </Page>
      </ExperiencesProvider>
    </ExamContextProvider>
  );
}

export default createFragmentContainer(ExamPage, {
  study: graphql`
    fragment ExamPage_study on Study
    @argumentDefinitions(
      isPACEExam: { type: "Boolean", defaultValue: false }
    ) {
      handle
      ...examPageSidebar_useShowQa_study
      ...ExamTopNavbar_study
      ...ExamPageBanner_study
      ...HeaderPatientInfo_study
      ...ExamPageSidebar_study @arguments(isPACEExam: $isPACEExam)
    }
  `,
  organization: graphql`
    fragment ExamPage_organization on Organization {
      slug
      ...SubscriptionExpiredBanner_organization
    }
  `,
});
