import Layout from '@4c/layout';
import Gear from '@bfly/icons/Gear';
import Heading from '@bfly/ui2/Heading';
import Page from '@bfly/ui2/Page';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import { LocationDescriptorObject } from 'farce';
import { Link } from 'found';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import CredentialGroupSelectButton from 'components/CredentialGroupSelectButton';
import ExamTypeSelectButton from 'components/ExamTypeSelectButton';
import HistoricalProficiencyLink from 'components/HistoricalProficiencyLink';
import IconButton from 'components/IconButton';
import { useVariation } from 'components/LaunchDarklyContext';
import MemberSelectButton from 'components/MemberSelectButton';
import PageTitle from 'components/PageTitle';
import { RoutePageProps } from 'components/Route';
import { useAdminRoutes } from 'routes/admin';
import {
  AnalyticsCredentialingSchema,
  serialize,
  useAnalyticsCredentialingFilterState,
} from 'schemas/analyticsFilters';
import { usePermissions } from 'utils/viewerContext';

import AnalyticsPageStickySection from './AnalyticsPageStickySection';
import { CredentialingPage_credentialGroup$data as CredentialGroup } from './__generated__/CredentialingPage_credentialGroup.graphql';
import { CredentialingPage_tenant$data as Tenant } from './__generated__/CredentialingPage_tenant.graphql';

interface CredentialingPageProps extends RoutePageProps {
  tenant: Tenant;
  credentialGroup: CredentialGroup | null;
  userProgressList: ReactElement | null;
  performanceIndicators: ReactElement | null;
  sushiRolls: ReactElement | null;
}

export function getCredentialingFilters(location: LocationDescriptorObject) {
  return location.state?.filter as AnalyticsCredentialingSchema | undefined;
}
export const serializeCredentialingFilters = serialize;

function CredentialingPage({
  tenant,
  credentialGroup,
  userProgressList,
  performanceIndicators,
  sushiRolls,
}: CredentialingPageProps) {
  const [filters, setFilters] = useAnalyticsCredentialingFilterState();
  const { hasBasicPermission } = usePermissions();
  const adminRoutes = useAdminRoutes();
  const hasCredentialManagementPermission = hasBasicPermission(
    'credentialManagement',
  );

  const canUseCredentialing =
    useVariation('credentialing-analytics-page') &&
    hasCredentialManagementPermission;

  function handleChange(partialNextValue: Partial<typeof filters>) {
    setFilters({ ...filters, ...partialNextValue });
  }

  return (
    <div data-bni-id="CredentialingPageContainer" className="w-[fit-content]">
      {/* zindex is necessary to pull this above the next section otherwise it covers the dropdown menus invisibly */}
      <AnalyticsPageStickySection pad={2} direction="column" className="z-10">
        <Page.Header
          bordered={false}
          className="h-auto py-5 justify-start border-b border-divider"
        >
          <PageTitle
            title={defineMessage({
              id: 'CredentialingPage.header',
              defaultMessage: 'Proficiency Dashboard',
            })}
            variant="display-sm"
          />
          {canUseCredentialing && (
            <Link
              to={adminRoutes.proficiencyGroups()}
              data-bni-id="CredentialingPageConfigureGroupsButton"
            >
              <IconButton
                icon={<Gear width={23} height={23} />}
                title={
                  <FormattedMessage
                    id="credentialingPage.configureButton"
                    defaultMessage="Configure groups and proficiency requirements"
                  />
                }
              />
            </Link>
          )}
          <HistoricalProficiencyLink />
          <Layout pad={2} className="ml-auto">
            <ExamTypeSelectButton
              data-bni-id="CredentialingExamTypeSelectButton"
              dataKey="handle"
              tenantId={tenant.id}
              value={filters.examType || []}
              onChange={(examTypes) =>
                handleChange({
                  examType: examTypes?.map((et) => et.handle!),
                })
              }
            />
            <CredentialGroupSelectButton
              dataKey="id"
              tenantId={tenant.id}
              value={filters.credentialGroup || []}
              onChange={(userGroups) =>
                handleChange({
                  credentialGroup: userGroups?.map((group) => group.id!),
                })
              }
            />
            <MemberSelectButton
              data-bni-id="CredentialingPageMemberSelectButton"
              tenant={tenant}
              value={filters.user || []}
              onChange={(user) => handleChange({ user })}
              placement="bottom-end"
              placeholder={
                <FormattedMessage
                  id="credentialingPage.placeholder"
                  defaultMessage="Users"
                />
              }
              includeDeleted
            />
          </Layout>
        </Page.Header>

        <div className="py-4 px-6 ml-12">{performanceIndicators}</div>

        <hr className="w-full" />

        <Section>
          <Heading className="pt-4 px-6 ml-12 flex items-center">
            <FormattedMessage
              tagName="span"
              id="CredentialingPage.examTypeBadges.header"
              defaultMessage="Exam type badges"
            />
            {credentialGroup && credentialGroup.dueAt && (
              <div
                data-bni-id="CredentialGroupDueDateHeader"
                className="text-base font-normal ml-3"
              >
                <FormattedMessage
                  defaultMessage="Due date: {dueDate}"
                  id="CredentialingPage.dueDate"
                  values={{
                    dueDate: (
                      <Text variant="body-bold">
                        <FormattedDate value={credentialGroup.dueAt} />
                      </Text>
                    ),
                  }}
                />
              </div>
            )}
          </Heading>

          <Layout className="scrollable-x pl-16 pr-6 pt-4 pb-6 mb-6 w-full border-b border-divider">
            {sushiRolls}
          </Layout>
        </Section>
      </AnalyticsPageStickySection>
      <div data-bni-id="UserCredentialingProgressGrid">{userProgressList}</div>
    </div>
  );
}

export default createFragmentContainer(CredentialingPage, {
  credentialGroup: graphql`
    fragment CredentialingPage_credentialGroup on CredentialGroup {
      dueAt
    }
  `,
  tenant: graphql`
    fragment CredentialingPage_tenant on TenantInterface {
      type: __typename
      id
      handle
      ... on Organization {
        name
        slug
      }
      ...MemberSelectButton_tenant
    }
  `,
});
