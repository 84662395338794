import Button from '@bfly/ui2/Button';
import DropdownForm, { DropdownFormProps } from '@bfly/ui2/DropdownForm';
import Form, { useFormValues } from '@bfly/ui2/Form';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import SearchFilter from '@bfly/ui2/SearchFilter';
import Text from '@bfly/ui2/Text';
import useId from '@bfly/ui2/useId';
import { css } from 'astroturf';
import clsx from 'clsx';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
  DataKeyAccessorFn,
  TextAccessorFn,
  dataItem,
  dataText,
} from 'react-widgets/Accessors';

import PopoverList from './PopoverList';

const messages = defineMessages({
  selected: {
    id: 'checkGroupButton.selected',
    defaultMessage: '{selectedCount} Selected',
    description: 'The number of items selected',
  },
  selectAll: {
    id: 'checkGroupButton.selectAll',
    defaultMessage: 'Select all',
    description: 'Select all checkbox label',
  },
  deselectAll: {
    id: 'checkGroupButton.deselectAll',
    defaultMessage: 'Deselect all',
    description: 'Deselect all checkbox label',
  },
});

function SelectedValueMessage({
  name,
  isFiltered,
}: {
  name: string;
  isFiltered: boolean;
}) {
  const value = useFormValues(name);
  const selected = (
    <Text color={value?.length ? 'primary' : 'subtitle'}>
      <FormattedMessage
        {...messages.selected}
        values={{ selectedCount: value?.length ?? 0 }}
      />
    </Text>
  );

  return isFiltered ? (
    <SearchFilter.Results className="px-4 pb-1 pt-2">
      {selected}
    </SearchFilter.Results>
  ) : (
    <div className="px-4 pb-1 pt-2 flex justify-end">{selected}</div>
  );
}

export function CheckGroupSelectButtonLabel<TData>({
  renderValue,
  renderOverflowItem = renderValue,
  dataItems,
  show,
}: {
  renderValue: ({ item }: { item: TData }) => ReactNode;
  renderOverflowItem?: ({ item }: { item: TData }) => ReactNode;
  dataItems: readonly TData[];
  show?: boolean;
}) {
  return (
    <div className="flex max-w-full">
      <span className="truncate max-w-xs">
        {renderValue({ item: dataItems[0] })}
      </span>
      {dataItems.length > 1 && (
        <PopoverList
          // hide when the dropdown is open otherwise normal behavior
          show={show ? false : undefined}
          listItems={dataItems.slice(1).map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={idx}>{renderOverflowItem({ item })}</li>
          ))}
        >
          <span className="ml-1">{`+${dataItems.length - 1}`}</span>
        </PopoverList>
      )}
    </div>
  );
}

type RenderItem<TData> = (options: { item: TData }) => ReactNode;

type CheckGroupSelectButtonProps<TData> = Omit<
  DropdownFormProps<any>,
  'onChange' | 'value' | 'children' | 'label'
> & {
  data: TData[];
  value: unknown[] | null;
  hideSelectAll?: boolean;
  type?: string;
  dataKey?: (keyof TData & string) | DataKeyAccessorFn;
  onChange: (value: TData[] | null) => void;
  placeholder?: ReactNode;
  renderValue?: RenderItem<TData>;
  renderOverflowItem?: RenderItem<TData>;
  renderItem?: RenderItem<TData>;
  filter?: (searchTerm: string, item: TData) => boolean;
  size?: 'md' | 'lg';
  textKey?: (keyof TData & string) | TextAccessorFn;
  hideFilter?: boolean; // filter is created based on textKey if this isn't set to true
  disabledItems?: TData[];
  disabled?: boolean;
  listClassName?: string;
  loading?: boolean;
} & (
    | {
        renderItem: RenderItem<TData>;
      }
    | {
        textKey: (keyof TData & string) | TextAccessorFn;
      }
  );

export type CheckGroupSelectButtonHOCProps<TData> = Omit<
  CheckGroupSelectButtonProps<TData>,
  'data' | 'renderItem' | 'filter' | 'textKey'
>;

function renderCheckboxes<TData>(
  hideSelectAll: boolean,
  data: TData[],
  renderItem: (item: { item: TData }) => ReactNode,
  dataKey: any,
  className?: string,
  disabledItems?: TData[],
  filteredIndexes?: number[],
  checkType: 'checkbox' | 'radio' = 'checkbox',
  randomName = 'value',
) {
  const selectableItems = Array.isArray(disabledItems)
    ? data.filter((item) => !disabledItems.includes(item))
    : data;

  const isRadio = checkType === 'radio';

  return (
    <>
      <div
        className={clsx('scrollable-y max-h-96 p-4 btn-toolbar', className)}
      >
        <Form.Field name="value">
          {({ onChange, value }) => {
            const allChecked = value?.length === selectableItems?.length;

            function handleChange(valueOrValues) {
              onChange(
                !valueOrValues || Array.isArray(valueOrValues)
                  ? valueOrValues
                  : [valueOrValues],
              );
            }

            return (
              <>
                {!isRadio &&
                  !hideSelectAll &&
                  (!filteredIndexes ||
                    (filteredIndexes.length > 1 &&
                      filteredIndexes.length === data.length)) && (
                    <Button
                      variant="text-primary"
                      className="mb-3"
                      data-bni-id="SelectAllButton"
                      onClick={() => {
                        handleChange(!allChecked ? selectableItems : null);
                      }}
                    >
                      <FormattedMessage
                        {...messages[allChecked ? 'deselectAll' : 'selectAll']}
                      />
                    </Button>
                  )}
                <FormCheckGroup
                  className={css`
                    :global(.__form-check-label) {
                      @apply max-w-sm truncate;
                    }
                  `}
                  data={data}
                  renderItem={(item, index: number) =>
                    (!filteredIndexes || filteredIndexes.includes(index)) &&
                    renderItem!({ item })
                  }
                  disabled={disabledItems}
                  value={value || []}
                  name={randomName}
                  dataKey={dataKey as any}
                  type={checkType}
                  onChange={handleChange}
                />
              </>
            );
          }}
        </Form.Field>
      </div>
      <hr className="mx-4 my-0" />
    </>
  );
}

function CheckGroupSelectButton<TData>({
  data,
  value = null,
  onChange,
  dataKey,
  placeholder,
  renderItem: renderItemProps,
  renderValue,
  renderOverflowItem,
  filter: filterProps,
  hideSelectAll = false,
  size,
  className,
  toggleClassName,
  listClassName,
  textKey,
  hideFilter,
  disabledItems,
  disabled = false,
  loading = false,
  type = 'checkbox',
  ...props
}: CheckGroupSelectButtonProps<TData>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const checkType = // b/c FormField will often inject the wrong type it's easier to handle here
    type === 'checkbox' || type === 'radio' ? type : 'checkbox';
  const { renderItem, filter } = useMemo(() => {
    let newRenderItem = renderItemProps;
    let newFilter = filterProps;

    if (textKey) {
      if (!newRenderItem) {
        newRenderItem = (options) => dataText(options.item, textKey);
      }

      if (!hideFilter && !newFilter)
        newFilter = (search, item) =>
          dataText(item, textKey).toLowerCase().includes(search.toLowerCase());
    }

    return {
      renderItem: newRenderItem as RenderItem<TData>,
      filter: newFilter,
    };
  }, [filterProps, hideFilter, renderItemProps, textKey]);

  const handleChange = (formValue: any) => {
    // don't pass an empty array
    onChange(formValue?.value?.length ? formValue.value : null);
  };

  const formValue = useMemo(
    () => (Array.isArray(value) && value?.length ? { value } : null),
    [value],
  );

  const valueDataItems = useMemo(
    () =>
      formValue?.value.map((item) => dataItem(data, item, dataKey)) ?? null,
    [formValue, data, dataKey],
  );

  const itemsLabel = !valueDataItems?.length ? (
    placeholder
  ) : (
    <CheckGroupSelectButtonLabel
      show={show}
      dataItems={valueDataItems}
      renderValue={renderValue || renderItem}
      renderOverflowItem={renderOverflowItem || renderItem}
    />
  );
  const randomName = useId();

  useEffect(() => {
    if (show) {
      // the first open the dropdown doesn't exist so give it a tick to render
      requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }
  }, [show]);

  const body =
    !hideFilter && filter && data.length > 1 ? (
      <SearchFilter
        filter={filter}
        inputRef={inputRef}
        items={data}
        value={searchTerm}
        onChange={setSearchTerm}
        variant={null as any}
        className="flex h-12 py-2 mx-4 border-b border-divider focus-within:border-primary transition-colors"
      >
        {({ filteredIndexes, searchTerm: debouncedSearchTerm }) => (
          <>
            {checkType !== 'radio' && (
              <SelectedValueMessage
                name="value"
                isFiltered={!!debouncedSearchTerm}
              />
            )}
            {renderCheckboxes(
              hideSelectAll,
              data,
              renderItem,
              dataKey,
              listClassName,
              disabledItems,
              filteredIndexes,
              checkType,
              randomName,
            )}
          </>
        )}
      </SearchFilter>
    ) : (
      renderCheckboxes(
        hideSelectAll,
        data,
        renderItem,
        dataKey,
        listClassName,
        disabledItems,
        undefined,
        checkType,
        randomName,
      )
    );

  return (
    <DropdownForm
      {...props}
      className={clsx(className, size === 'lg' && 'w-full')}
      toggleClassName={clsx(
        toggleClassName,
        size === 'lg' && 'h-10 px-4 w-full justify-between',
      )}
      disabled={disabled}
      show={show}
      variant="secondary"
      label={itemsLabel}
      value={formValue}
      onChange={handleChange}
      onToggle={(nextShow) => {
        setSearchTerm('');
        setShow(nextShow);
      }}
    >
      {loading ? <LoadingIndicator className="mb-8 " size="sm" /> : null}
      {!loading ? body : null}
      {!loading && <DropdownForm.Footer />}
    </DropdownForm>
  );
}

export default CheckGroupSelectButton;
