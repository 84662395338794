/**
 * @generated SignedSource<<c4099d12d61451811702d345181e54fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileSharedImageList_images$data = ReadonlyArray<{
  readonly burnedInFile: {
    readonly isVideo: boolean | null;
    readonly " $fragmentSpreads": FragmentRefs<"Thumbnail_image">;
  } | null;
  readonly handle: string | null;
  readonly id: string;
  readonly " $fragmentType": "MobileSharedImageList_images";
}>;
export type MobileSharedImageList_images$key = ReadonlyArray<{
  readonly " $data"?: MobileSharedImageList_images$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileSharedImageList_images">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MobileSharedImageList_images",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BurnedInFile",
      "kind": "LinkedField",
      "name": "burnedInFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVideo",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Thumbnail_image"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SharedBundleImage",
  "abstractKey": null
};

(node as any).hash = "febf2fbb64f955740360af3c692d6084";

export default node;
