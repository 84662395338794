export function traverseObject<T extends object>(
  func: (key: string | number, value: unknown, object: object) => void,
  object: T,
): T {
  if (typeof object !== 'object') {
    throw new Error('traverseObject only accepts objects');
  }

  if (object === null) {
    throw new Error('null was passed to traverseObject');
  }

  // reversing the order of the keys allow us to safely remove keys in removeKeysDeep
  for (const key of Object.keys(object).reverse()) {
    func(key, object[key], object);
    // func may have deleted the value at the key so we get the value afterwards
    const value = object[key];

    if (typeof value === 'object' && value !== null) {
      traverseObject(func, value);
    }
  }

  return object;
}

/**
 * Deeply recurses through an object and tests every value against the provided predicate. If the predicate returns true, the key is removed
 * from the object. If the object is an array, Array.splice is used to correctly resize the array. This function mutates the objects in
 * place. It does NOT return a clone.
 *
 * @param predicate a function that tests whether the provided value should be removed
 * @param object the initial object to start traversing
 * @returns the initial object that was passed. This does not create a copy.
 */
export default function removeKeysDeep<T extends object>(
  predicate: (value: unknown) => boolean,
  object: T,
) {
  return traverseObject((key, value, obj) => {
    if (predicate(value)) {
      try {
        if (Array.isArray(obj)) {
          obj.splice(key as number, 1);
        } else {
          delete obj[key];
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Cannot remove key from object', { key, value, object });
      }
    }
  }, object);
}
