/**
 * @generated SignedSource<<4eb745315c9bc8a7276556a382fc918b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyNotification_image$data = {
  readonly handle: string | null;
  readonly " $fragmentType": "StudyNotification_image";
};
export type StudyNotification_image$key = {
  readonly " $data"?: StudyNotification_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyNotification_image">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyNotification_image",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    }
  ],
  "type": "StudyImage",
  "abstractKey": null
};

(node as any).hash = "a9edd7aff977b1be933e3cadbe14f2bc";

export default node;
