/**
 * @generated SignedSource<<e63352feed72200ac0214be60cdbf1cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canEditStudyAuthor$data = {
  readonly finalizedAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "StudyPermissions_canEditStudyAuthor";
};
export type StudyPermissions_canEditStudyAuthor$key = {
  readonly " $data"?: StudyPermissions_canEditStudyAuthor$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canEditStudyAuthor">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canEditStudyAuthor"
};

(node as any).hash = "f0688651d4058acd7a3ae6bc0431a0d2";

export default node;
