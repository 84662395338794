import Layout from '@4c/layout';
import clsx from 'clsx';
import React from 'react';

import SortIndicator from './SortIndicator';

interface Props {
  children: React.ReactNode;
  onClick: React.EventHandler<React.MouseEvent>;
  desc?: boolean;
  className?: string;
  showIndicator?: boolean;
  size?: number;
}

function SortHeaderButton({
  children,
  desc,
  showIndicator = true,
  size = 16,
  ...props
}: Props) {
  return (
    <Layout
      as="button"
      type="button"
      align="center"
      inline
      pad={3}
      {...props}
      className={clsx(props.className, 'focus-visible:ring')}
    >
      <span>{children}</span>
      {showIndicator && <SortIndicator height={size} desc={desc} />}
    </Layout>
  );
}

export default SortHeaderButton;
