import { FormattedMessage, defineMessages } from 'react-intl';

import { StudyListFilter } from 'utils/StudyConstants';

export const messages = defineMessages({
  [StudyListFilter.ALL]: {
    id: 'studyStatusFilterMessage.all',
    defaultMessage: 'All Studies',
  },
  [StudyListFilter.COMPLETED]: {
    id: 'studyStatusFilterMessage.complete',
    defaultMessage: 'Completed Studies',
  },
  [StudyListFilter.DRAFTS]: {
    id: 'studyStatusFilterMessage.drafts',
    defaultMessage: 'Drafts',
  },
  [StudyListFilter.NEEDS_REVIEW]: {
    id: 'studyStatusFilterMessage.needsReview',
    defaultMessage: 'Needs QA Review',
  },
  [StudyListFilter.PENDING_ATTESTATION]: {
    id: 'studyStatusFilterMessage.signatureRequested',
    defaultMessage: 'Pending Attestation',
  },
  [StudyListFilter.MY_DRAFTS]: {
    id: 'studyStatusFilterMessage.myDrafts',
    defaultMessage: 'My drafts',
  },
  [StudyListFilter.MY_SIGNATURE_REQUESTED]: {
    id: 'studyStatusFilterMessage.mySignatureRequested',
    defaultMessage: 'My Signature Requested',
  },
});

interface Props {
  status: StudyListFilter;
}

function StudyListFilterMessage({ status }: Props) {
  return messages[status] ? <FormattedMessage {...messages[status]} /> : null;
}

export default StudyListFilterMessage;
