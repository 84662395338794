/**
 * @generated SignedSource<<e2460cd9b736103c3cacd1181f10ad68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyStatistics_examType$data = {
  readonly accuracyAsPresentedStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numFinalizedStudies: number;
  } | null> | null;
  readonly accuracyComparedToGoldStandardStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numFinalizedStudies: number;
  } | null> | null;
  readonly averageImageQuality: number | null;
  readonly feedbackStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numFinalizedStudies: number;
  } | null> | null;
  readonly numCountsTowardsCredentialing: number | null;
  readonly numFinalizedStudies: number | null;
  readonly numReviewedStudies: number | null;
  readonly studyStatusStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numStudies: number;
  } | null> | null;
  readonly " $fragmentType": "StudyStatistics_examType";
};
export type StudyStatistics_examType$key = {
  readonly " $data"?: StudyStatistics_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyStatistics_examType">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numFinalizedStudies",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyStatistics_examType",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numReviewedStudies",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numCountsTowardsCredentialing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageImageQuality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyStatusStatistics",
      "kind": "LinkedField",
      "name": "studyStatusStatistics",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numStudies",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InterpretationStatistics",
      "kind": "LinkedField",
      "name": "accuracyAsPresentedStatistics",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InterpretationStatistics",
      "kind": "LinkedField",
      "name": "accuracyComparedToGoldStandardStatistics",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeedbackStatistics",
      "kind": "LinkedField",
      "name": "feedbackStatistics",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ExamTypeStudyStatistics",
  "abstractKey": null
};
})();

(node as any).hash = "a2882eb29ef1bf78c5417771306f8586";

export default node;
