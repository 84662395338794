import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import { css } from 'astroturf';
import withRouter from 'found/withRouter';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { TypeOf, boolean, object, string } from 'yup';
import type { InferType } from 'yup';

import AuthContent from 'components/AuthContent';
import { withAuth } from 'components/AuthContext';
import type { AuthContextValue } from 'components/AuthContext';
import AuthForm from 'components/AuthForm';
import { RoutePageProps } from 'components/Route';
import accountMessages from 'messages/account';
import accountRouteUrls from 'routes/account';
import submitLogin from 'utils/login';

import { AuthType } from '../../../utils/auditLogs';
import passwordMessages from '../messages/password';
import signUpMessages from '../messages/signUp';
import ConfirmLogoutDialog from './ConfirmLogoutDialog';
import type { CreateUserAndAcceptInvitePageMutation } from './__generated__/CreateUserAndAcceptInvitePageMutation.graphql';
import type { CreateUserAndAcceptInvitePage_inviteInfo$data as InviteInfo } from './__generated__/CreateUserAndAcceptInvitePage_inviteInfo.graphql';

interface Props extends RoutePageProps {
  auth: AuthContextValue;
  inviteInfo: InviteInfo;
}

const messages: Record<string, any> = defineMessages({
  missingEmail: {
    id: 'signup.missingEmail',
    defaultMessage: 'Please enter an email',
  },
  createPassword: {
    id: 'signup.createPassword',
    defaultMessage: 'Create Password',
  },
});

const mutation = graphql`
  mutation CreateUserAndAcceptInvitePageMutation($input: RegisterUserInput!) {
    registerUserOrError(input: $input) {
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

const signupSchema = object({
  email: string().email().required(messages.missingEmail).default(''),
  password: string()
    .required(passwordMessages.required as any)
    .min(8, passwordMessages.length as any),
  isOptedOutOfMarketing: boolean().nullable(),
});

class CreateUserAndAcceptInvitePage extends React.Component<Props> {
  defaultValue: TypeOf<typeof signupSchema>;

  input: InferType<typeof signupSchema> | undefined;

  constructor(props: Props) {
    super(props);

    this.defaultValue = {
      email: props.inviteInfo.email || '',
      password: '',
      isOptedOutOfMarketing: props.inviteInfo.showMarketingOptOutToggle
        ? false
        : null,
    };
  }

  onCompleted = async () => {
    const { router, auth } = this.props;

    let domainHandle: string | null = null;
    try {
      domainHandle =
        this.props.inviteInfo!.organizationInfo!.domainProfile!.handle!;
    } catch (e) {
      // simply ignore undefined error
    }

    // If signup is successful, try to log in.
    try {
      const { email, password } = this.input!;
      const accessToken = await submitLogin({ email, password, domainHandle });
      await auth.setAccessToken(accessToken, {
        trackLogin: true,
        authType: AuthType.AUTH0,
      });
    } catch (e) {
      // The root redirect to user setup won't work, but we'll at least show
      // either the login page or the account created page.
    }
    router.replace(accountRouteUrls.accountCreated());
  };

  handleFederatedLogin = () => {
    const { match, router } = this.props;
    router.replace({
      ...match.location,
      state: { willCreateAccount: false },
      pathname: accountRouteUrls.acceptInvite(),
    });
  };

  render() {
    const { inviteInfo, match } = this.props;
    const { location } = match;

    return (
      <>
        <ConfirmLogoutDialog>
          <FormattedMessage
            id="signup.confirmLogout"
            defaultMessage="You are already logged in. Do you want to log out to accept this invitation?"
          />
        </ConfirmLogoutDialog>
        <AuthContent>
          <AuthContent.Title>
            <FormattedMessage
              id="signup.title"
              defaultMessage="Complete Sign Up"
            />
          </AuthContent.Title>
          <AuthForm<CreateUserAndAcceptInvitePageMutation, typeof signupSchema>
            schema={signupSchema}
            defaultValue={this.defaultValue}
            getInput={(input) => {
              // XXX: save the input to use it in onComplete. this is a little
              // dirty
              // TODO: move this on onComplete once we consolidate the
              // mutation components
              this.input = input;
              return {
                token: location.query.token || location.query.invite_token,
                password: input.password,
                isOptedOutOfMarketing: input.isOptedOutOfMarketing,
              };
            }}
            mutation={mutation}
            onCompleted={this.onCompleted}
          >
            {/*  
            This component is a weird hack to prevent autocomplete per
            https://stackoverflow.com/a/19174309/251162.
              
            we use sr-only to avoid a clear signal to the browser that this is not visible
            aria-hidden prevents SRs from reading it as if it was display: none; */}
            <input
              className="sr-only"
              aria-hidden="true"
              type="password"
              name="password"
            />
            <AuthForm.FieldGroup
              readOnly
              name="email"
              type="email"
              label={accountMessages.email}
            />
            <AuthForm.FieldGroup
              name="password"
              type="password"
              label={messages.createPassword}
              help={passwordMessages.help}
              placeholder={messages.createPassword}
            />
            {inviteInfo.showMarketingOptOutToggle && (
              <AuthForm.FieldGroup
                name="isOptedOutOfMarketing"
                type="checkbox"
                className="text-sm mx-3 mt-4"
                css={css`
                  label {
                    @apply flex text-body #{!important};
                  }
                `}
              >
                <FormattedMessage {...signUpMessages.marketingOptOut} />
              </AuthForm.FieldGroup>
            )}
            <AuthForm.Submit>
              <FormattedMessage
                id="signup.submit"
                defaultMessage="Create an Account"
              />
            </AuthForm.Submit>
          </AuthForm>

          {inviteInfo.organizationInfo?.domainProfile
            ?.federatedIdentityProvider && (
            <Layout justify="center" align="center">
              <FormattedMessage
                id="signup.sso"
                defaultMessage="Already have an SSO account?"
              />
              <Button
                variant="text-primary"
                onClick={this.handleFederatedLogin}
              >
                <FormattedMessage {...accountMessages.logIn} />
              </Button>
            </Layout>
          )}
        </AuthContent>
      </>
    );
  }
}

export default createFragmentContainer(
  withRouter(withAuth(CreateUserAndAcceptInvitePage)),
  {
    inviteInfo: graphql`
      fragment CreateUserAndAcceptInvitePage_inviteInfo on OrganizationInviteInfo {
        email
        showMarketingOptOutToggle
        organizationInfo {
          domainProfile {
            handle
            federatedIdentityProvider {
              __typename
            }
          }
        }
      }
    `,
  },
);
