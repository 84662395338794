import Form from '@bfly/ui2/Form';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Toast from '@bfly/ui2/Toast';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useQuery from '@bfly/ui2/useQuery';
import useToast from '@bfly/ui2/useToast';
import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import useRouter from 'found/useRouter';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useExamRoutes } from 'routes/exam';
import Analytics from 'utils/Analytics';
import withModal from 'utils/withModal';

import { Sex } from '../../schemas/humanStudyInfo';
import {
  NeuterStatus,
  Species,
  Sex as VetSex,
} from '../../schemas/vetStudyInfo';
import {
  SplitStudyFields,
  SplitStudySchemaType,
  splitStudySchema,
} from '../schemas/splitStudySchema';
import SplitStudyConfirmDeleteStudy from './SplitStudyConfirmDeleteStudy';
import SplitStudyConfirmPatientDataConflictContent from './SplitStudyConfirmPatientDataConflictContent';
import SplitStudyPatientEdit from './SplitStudyPatientEdit';
import SplitStudyRefreshModalSelectContent from './SplitStudyRefreshModalSelectContent';
import SplitStudyRefreshModalSelectDestination from './SplitStudyRefreshModalSelectDestination';
import SplitStudyRefreshModalSelectExistingDraftDestination from './SplitStudyRefreshModalSelectExistingDraftDestination';
import { SplitStudyRefreshModalCurrentStudyQuery as SourceStudyQuery } from './__generated__/SplitStudyRefreshModalCurrentStudyQuery.graphql';
import { SplitStudyRefreshModalDestinationStudyQuery as PatientDataConflictContentQuery } from './__generated__/SplitStudyRefreshModalDestinationStudyQuery.graphql';
import {
  SplitStudyRefreshModal_Mutation as Mutation,
  SplitStudyInput,
} from './__generated__/SplitStudyRefreshModal_Mutation.graphql';
import { SplitStudyRefreshModal_study$data as Study } from './__generated__/SplitStudyRefreshModal_study.graphql';

const mutation = graphql`
  mutation SplitStudyRefreshModal_Mutation($input: SplitStudyInput!) {
    splitStudyOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
      ... on SplitStudyPayload {
        destinationStudy {
          id
          handle
          numImages
          archive {
            handle
          }
          organization {
            slug
          }
          splitStudyImages: imageConnection(first: 2147483647) {
            edges {
              node {
                handle
              }
            }
          }
          ...ExamGalleryPage_study
          ...StudyImagePreviewList_study
        }
        destinationStudyEdge {
          node {
            numImages
            ...ExamImagePage_study
            ...StudyListItem_study
            ...StudyDataGrid_studies
            ...StudyImagePreviewList_study
          }
        }
        sourceStudy {
          numImages
          imageConnection(first: 2147483647) {
            edges {
              node {
                handle
              }
            }
          }
          worksheets {
            id
          }
        }
      }
    }
  }
`;

enum SplitStudySteps {
  // 1st Step - Select destination as new or existing draft
  SELECT_NEW_OR_EXISTING,
  // 2nd Step - Select content to be moved to new or existing draft
  SELECT_CONTENT,
  // 3rd Step (conditional) - If destination isexisting draft, select draft
  EDIT_PATIENT,
  // 3rd Step (conditional) - Display editable patient details for the split study
  SELECT_EXISTING_DRAFT_DESTINATION,
  // 4th Step (conditional) - If all captures are moved, show modal confirming user wants to delete study and move remaining worksheets
  CONFIRM_DELETE_STUDY,
  // 4th Step - 5th Step if all captures removed - (conditional)- If destination is existing draft and patient conflict exists, confirm choice
  CONFIRM_PATIENT_DATA_CONFLICT,
}

interface Props {
  study: Study;
  onHide: () => void;
}

function SplitStudyRefreshModal({ study, onHide }: Props) {
  const [stage, setStage] = useState<SplitStudySteps>(
    SplitStudySteps.SELECT_NEW_OR_EXISTING,
  );
  const [value, setValue] = useState<SplitStudyFields>({
    ...(splitStudySchema.getDefault() as SplitStudyFields),
    createdBy: study.createdBy?.id,
    secondaryAuthorUserIds: study.secondaryAuthors?.map((a) => a!.id!) || [],
    transcribedBy: study.transcribedBy?.id,
    accessionNumber: study.accessionNumber || undefined,
    sourceWorklistItemId: study.sourceWorklistItem?.id || undefined,
    dicomDocument: study.dicomDocument,
    archiveId: study.archive?.id,
  });
  const toast = useToast();
  const { router, match } = useRouter();
  const [mutate] = useMutationWithError<Mutation>(mutation);
  const examRoutes = useExamRoutes();

  const { data: destinationStudyData } =
    useQuery<PatientDataConflictContentQuery>(
      graphql`
        query SplitStudyRefreshModalDestinationStudyQuery($id: ID!) {
          node(id: $id) {
            ...SplitStudyConfirmPatientDataConflictContent_destinationStudy
            ...SplitStudyConfirmDeleteStudy_destinationStudy
          }
        }
      `,
      {
        fetchPolicy: 'store-and-network',
        variables: { id: value.destinationStudyId! },
        skip: !value.destinationStudyId,
      },
    );

  const { data: currentStudyData } = useQuery<SourceStudyQuery>(
    graphql`
      query SplitStudyRefreshModalCurrentStudyQuery($sourceStudyId: ID!) {
        sourceStudy: node(id: $sourceStudyId) {
          ... on Study {
            id
            numImages
            handle
            practiceType

            archive {
              id
              label
              handle
            }
            organization {
              id
              slug
            }
            patient {
              nameFirst
              nameLast
              nameMiddle
              nameSuffix
              namePrefix
              birthDate
              medicalRecordNumber
              sex
              internalId
              ...PatientName_patient
            }
            vetPatient {
              name
              clientNameFirst
              clientNameLast
              clientNameMiddle
              clientNamePrefix
              clientNameSuffix
              clientOrganizationName
              breed
              microchipNumber
              patientIdNumber
              birthDate
              species
              speciesFreeform
              sex
              neuterStatus
              weightKilograms
              ...VetPatientName_vetPatient
            }
            ...SplitStudyRefreshModalSelectContent_sourceStudy
            ...SplitStudyRefreshModalSelectExistingDraftDestination_sourceStudy
            ...SplitStudyPatientEdit_sourceStudy
            ...SplitStudyPatientField_sourceStudy
            ...SplitStudyConfirmPatientDataConflictContent_sourceStudy
            ...SplitStudyConfirmDeleteStudy_sourceStudy
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: { sourceStudyId: study.id },
    },
  );

  const sourceStudy = currentStudyData?.sourceStudy;

  // Set default form values
  useEffect(() => {
    if (!sourceStudy) return;
    if (
      sourceStudy.practiceType === 'HUMAN' &&
      !value.patient &&
      sourceStudy.patient &&
      value.destinationType === 'NEW'
    ) {
      const p = sourceStudy.patient;
      setValue({
        ...value,
        patient: {
          nameFirst: p.nameFirst || '',
          nameMiddle: p.nameMiddle || '',
          nameLast: p.nameLast || '',
          namePrefix: p.namePrefix || '',
          nameSuffix: p.nameSuffix || '',
          medicalRecordNumber: p.medicalRecordNumber || '',
          sex: p.sex as keyof typeof Sex,
          birthDate: p.birthDate ? new Date(p.birthDate) : null,
          internalId: p.internalId,
        },
      });
    } else if (
      sourceStudy.practiceType === 'VETERINARY' &&
      !value.vetPatient &&
      sourceStudy.vetPatient &&
      value.destinationType === 'NEW'
    ) {
      const p = sourceStudy.vetPatient;
      setValue({
        ...value,
        vetPatient: {
          clientNameFirst: p.clientNameFirst,
          clientNameMiddle: p.clientNameMiddle,
          clientNameLast: p.clientNameLast,
          clientNamePrefix: p.clientNamePrefix,
          clientNameSuffix: p.clientNameSuffix,
          clientOrganizationName: p.clientOrganizationName,
          breed: p.breed,
          name: p.name,
          patientIdNumber: p.patientIdNumber,
          microchipNumber: p.microchipNumber,
          birthDate: p.birthDate ? new Date(p.birthDate) : null,
          weightKilograms: p.weightKilograms,
          neuterStatus:
            (p.neuterStatus as keyof typeof NeuterStatus) || 'UNKNOWN',
          species: (p.species as keyof typeof Species) || 'UNKNOWN',
          speciesFreeform: p.speciesFreeform,
          sex: (p.sex as keyof typeof VetSex) || 'UNKNOWN',
        },
      });
    }
  }, [sourceStudy, setValue, value]);

  const allCapturesSelected =
    !!sourceStudy && value.imageIds?.length === sourceStudy.numImages;

  const onCompleted = useCallback(
    (mutationData) => {
      const { destinationStudy } = mutationData!.splitStudyOrError!;
      const { organization } = destinationStudy!;

      // May need to redirect if the image we are on has been moved
      // Only redirect if on study page
      if (match.params.studyHandle) {
        router.replace(
          allCapturesSelected
            ? examRoutes.examGallery({
                organizationSlug: organization.slug!,
                studyHandle: destinationStudy!.handle!,
              })
            : examRoutes.examGallery({
                organizationSlug: sourceStudy!.organization!.slug!,
                studyHandle: sourceStudy!.handle!,
              }),
        );
      }

      const linkToStudy = (
        <Toast.Action
          onClick={() =>
            router.push(
              examRoutes.examGallery({
                organizationSlug: organization!.slug!,
                studyHandle: destinationStudy!.handle!,
              }),
            )
          }
        >
          <FormattedMessage
            id="splitStudyRefresh.toast.action"
            defaultMessage="View draft"
          />
        </Toast.Action>
      );

      const toastMessageConfig = {
        message:
          value!.destinationType === 'NEW' ? (
            <FormattedMessage
              id="splitStudyRefresh.success.new"
              defaultMessage="New draft created"
            />
          ) : (
            <FormattedMessage
              id="splitStudyRefresh.success.existing"
              defaultMessage="Content moved"
            />
          ),
        callToAction: allCapturesSelected ? undefined : linkToStudy,
        container: 'examPage',
      };

      onHide();
      toast.success(toastMessageConfig);
    },
    [
      match.params.studyHandle,
      value,
      onHide,
      toast,
      router,
      allCapturesSelected,
      examRoutes,
      sourceStudy,
    ],
  );
  const submitForm = useCallback(
    async (formValue: SplitStudyFields) => {
      const {
        imageIds,
        worksheets,
        accessionNumber,
        sourceWorklistItemId,
        allowConflictingDemographics,
        destinationStudyId,
        destinationType,
        examTypeIds,
        tagIds,
        patient,
        vetPatient,
        createdBy,
        secondaryAuthorUserIds,
        transcribedBy,
        archiveId,
        dicomDocument,
      } = formValue;

      const patientProps = {} as any;
      if (
        sourceStudy?.practiceType === 'HUMAN' &&
        patient &&
        !destinationStudyId
      ) {
        patientProps.patient = {
          ...patient,
          birthDate: patient.birthDate
            ? patient.birthDate.toISOString().split('T')[0]
            : null,
        };
      } else if (
        sourceStudy?.practiceType === 'VETERINARY' &&
        vetPatient &&
        !destinationStudyId
      ) {
        patientProps.vetPatient = {
          ...vetPatient,
          birthDate: vetPatient?.birthDate
            ? vetPatient.birthDate?.toISOString().split('T')[0]
            : null,
        };
      }

      const destinationTypeFields: Partial<SplitStudyInput> =
        destinationType === 'NEW'
          ? {
              createdBy,
              secondaryAuthorUserIds,
              transcribedBy,
              archiveId,
            }
          : {
              destinationStudyId,
            };

      await mutate({
        input: {
          ...patientProps,
          ...destinationTypeFields,
          studyId: sourceStudy!.id,
          imageIds,
          examTypeIds,
          tagIds,
          accessionNumber,
          sourceWorklistItemId,
          dicomDocument,
          worksheetIds: worksheets.map((worksheet) => worksheet.id),
          // If the form is submitted during the confirm patient data stage, the user has confirmed conflict is ok.
          allowConflictingDemographics:
            stage === SplitStudySteps.CONFIRM_PATIENT_DATA_CONFLICT ||
            allowConflictingDemographics,
        },
        updater: (store) => {
          if (allCapturesSelected) {
            rangeDeleteUpdater(store, {
              parentId: sourceStudy!.archive!.id,
              connectionKey: 'Archive_studyConnection',
              connectionFilters: () => true,
              deletedId: sourceStudy!.id!,
            });
          }
          value.imageIds?.forEach((imageId) =>
            rangeDeleteUpdater(store, {
              parentId: sourceStudy!.id!,
              connections: [
                { connectionKey: 'StudyImagePreviewList_imageConnection' },
                { connectionKey: 'StudyImagePage_imageConnection' },
              ],
              deletedId: imageId!,
            }),
          );

          if (destinationType === 'NEW') {
            rangeAddUpdater(store, {
              rootFieldName: 'splitStudyOrError',
              edgeName: 'destinationStudyEdge',
              parentId: sourceStudy!.archive!.id,
              connectionKey: 'Archive_studyConnection',
              rangeBehavior: 'prepend',
              connectionFilters: (args) =>
                args.isDraft === null || args.isDraft === true,
            });
          }
        },
        onCompleted: (response) => {
          onCompleted({ ...response, formValue });
          Analytics.track('splitStudyComplete', {
            sourceStudyId: sourceStudy!.id,
            organizationId: sourceStudy!.organization?.id,
            destinationStudyId:
              response.splitStudyOrError!.destinationStudy?.id,
          });
        },
        onError: (e: any) => {
          // target study patient info different from current study patient info
          if (e.errorType === 'ConflictingDemographicsError') {
            setStage(SplitStudySteps.CONFIRM_PATIENT_DATA_CONFLICT);
          } else {
            throw e;
          }
        },
      });
    },
    [
      mutate,
      sourceStudy,
      stage,
      allCapturesSelected,
      value.imageIds,
      onCompleted,
    ],
  );

  if (!sourceStudy) {
    return (
      <span>
        <LoadingIndicator />
      </span>
    );
  }

  return (
    <Form<SplitStudySchemaType>
      schema={splitStudySchema}
      value={value}
      onChange={setValue}
      submitForm={submitForm}
      formContext={{ numCaptures: sourceStudy.numImages }}
    >
      {/* Step 1: Select destination as new draft in same archive or existing draft study */}
      {stage === SplitStudySteps.SELECT_NEW_OR_EXISTING && (
        <SplitStudyRefreshModalSelectDestination
          onCancel={() => {
            onHide();
            Analytics.track('splitStudyDismissed', {
              sourceStudyId: sourceStudy.id,
              organizationID: sourceStudy.organization?.id,
            });
          }}
          onNext={() => {
            setStage(SplitStudySteps.SELECT_CONTENT);
            Analytics.track('splitStudyDestinationContinue', {
              sourceStudyId: sourceStudy.id,
              organizationID: sourceStudy.organization?.id,
              destinationType: value.destinationType,
            });
          }}
        />
      )}
      {/* Step 2: Select captures and worksheets to be moved from current study, submit if destination is new draft */}
      {stage === SplitStudySteps.SELECT_CONTENT && (
        <>
          {currentStudyData ? (
            <SplitStudyRefreshModalSelectContent
              sourceStudy={sourceStudy}
              onEditPatient={() => setStage(SplitStudySteps.EDIT_PATIENT)}
              onBack={() => setStage(SplitStudySteps.SELECT_NEW_OR_EXISTING)}
              onNext={() =>
                allCapturesSelected && value.destinationType === 'NEW'
                  ? setStage(SplitStudySteps.CONFIRM_DELETE_STUDY)
                  : setStage(SplitStudySteps.SELECT_EXISTING_DRAFT_DESTINATION)
              }
            />
          ) : (
            <LoadingIndicator />
          )}
        </>
      )}
      {/* Step 2.A: Edit patient information */}
      {stage === SplitStudySteps.EDIT_PATIENT && (
        <SplitStudyPatientEdit
          onHide={() => setStage(SplitStudySteps.SELECT_CONTENT)}
          sourceStudy={sourceStudy}
        />
      )}
      {/* Step 3 (destination => existing draft): select existing draft to move content to */}
      {stage === SplitStudySteps.SELECT_EXISTING_DRAFT_DESTINATION && (
        <SplitStudyRefreshModalSelectExistingDraftDestination
          sourceStudy={sourceStudy}
          onBack={() => setStage(SplitStudySteps.SELECT_CONTENT)}
          onNext={
            allCapturesSelected
              ? () => setStage(SplitStudySteps.CONFIRM_DELETE_STUDY)
              : undefined
          }
        />
      )}
      {/* Step 4 (if all captures removed) - confirm user wants to move all captures and worksheets and delete study */}
      {stage === SplitStudySteps.CONFIRM_DELETE_STUDY && (
        <>
          {destinationStudyData || value.destinationType === 'NEW' ? (
            <SplitStudyConfirmDeleteStudy
              sourceStudy={sourceStudy}
              destinationIsNewDraft={value.destinationType === 'NEW'}
              onBack={() =>
                value.destinationType === 'NEW'
                  ? setStage(SplitStudySteps.SELECT_CONTENT)
                  : setStage(SplitStudySteps.SELECT_EXISTING_DRAFT_DESTINATION)
              }
              destinationStudy={destinationStudyData?.node ?? undefined}
            />
          ) : (
            <LoadingIndicator />
          )}
        </>
      )}
      {/* Step 4 or 5 (if all captures removed) (destination => existing draft && form submitted && patient info doesn't match error):
       confirm patient data conflict ok and resubmit */}
      {stage === SplitStudySteps.CONFIRM_PATIENT_DATA_CONFLICT && (
        <>
          {destinationStudyData ? (
            <SplitStudyConfirmPatientDataConflictContent
              sourceStudy={sourceStudy}
              onBack={() =>
                allCapturesSelected
                  ? setStage(SplitStudySteps.CONFIRM_DELETE_STUDY)
                  : setStage(SplitStudySteps.SELECT_EXISTING_DRAFT_DESTINATION)
              }
              destinationStudy={destinationStudyData.node!}
            />
          ) : (
            <LoadingIndicator />
          )}
        </>
      )}
    </Form>
  );
}

export default createFragmentContainer(
  withModal(SplitStudyRefreshModal, { variant: 'dark' }),
  {
    study: graphql`
      fragment SplitStudyRefreshModal_study on Study {
        id
        practiceType
        accessionNumber
        dicomDocument
        sourceWorklistItem {
          ... on Node {
            id
          }
        }
        ...SplitStudyPatientField_sourceStudy
        createdBy {
          id
        }
        secondaryAuthors {
          id
        }
        transcribedBy {
          id
        }
        archive {
          id
        }
      }
    `,
  },
);
