import Layout from '@4c/layout';
import Form from '@bfly/ui2/Form';
import FormControlSearch from '@bfly/ui2/FormControlSearch';
import MainContent from '@bfly/ui2/MainContent';
import SrOnly from '@bfly/ui2/SrOnly';
import Text from '@bfly/ui2/Text';
import useDialog from '@bfly/ui2/useDialog';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import { encodeGlobalId } from '@bfly/utils/codecs';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import { css } from 'astroturf';
import { useRouter } from 'found';
import groupBy from 'lodash/groupBy';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql } from 'react-relay';
import { ROOT_ID, generateClientID } from 'relay-runtime';

import CredentialGroupSelectButton from 'components/CredentialGroupSelectButton';
import { useVariationAllowMissingLdContext } from 'components/LaunchDarklyContext';
import OrganizationSelectButton from 'components/OrganizationSelectButton';
import SortHeaderButton from 'components/SortHeaderButton';
import actionMessages from 'messages/actions';

import educationMessages from '../messages/educationMessages';
import {
  SortingDirection,
  deserialize as deserializeDashboardFilters,
} from '../schemas/educationDashboardFilters';
import EducationDashboardTabNav from './EducationDashboardTabNav';
import FooterActions from './FooterActions';
import { useUnenrollmentContext } from './UnenrollmentContext';
import {
  EnrollmentInput,
  CourseOverviewPageContentMutation$data as MutationResponse,
} from './__generated__/CourseOverviewPageContentMutation.graphql';
import useEducationDashboardFilter from './utils/useEducationDashboardFilter';

const mutation = graphql`
  mutation CourseOverviewPageContentMutation(
    $input: BulkUnEnrollUsersFromCoursesInput!
  ) {
    bulkUnEnrollUsersFromCoursesOrError(input: $input) {
      ... on BulkUnEnrollUsersFromCoursesPayload {
        unEnrollFailures {
          code
          detail
          userId
          courseId
        }
        courseStatistics {
          id
          count
          averageProgress
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

const NAME_FIELD_KEY = 'NAME';

interface CourseOverviewPageContentProps {
  children?: ReactNode;
}

export default function CourseOverviewPageContent({
  children,
}: CourseOverviewPageContentProps) {
  const { match } = useRouter();
  const toast = useToast();
  const dialog = useDialog();
  const { formatMessage } = useIntl();
  const {
    filters,
    updateSearch,
    updateSort,
    updateOrganizationIds,
    updateCredentialGroup,
  } = useEducationDashboardFilter();

  const canUseCredentialing = useVariationAllowMissingLdContext(
    'credentialing-analytics-page',
  );

  const { selectedItems, selectedUsersCount, clear } =
    useUnenrollmentContext();
  const { organizationIds } = deserializeDashboardFilters({
    ...match.location.query,
  });
  const [mutate] = useMutationWithError(mutation, {
    input: {
      // transforms nested maps values into EnrollmentInput array
      enrollments: ([] as EnrollmentInput[]).concat(
        ...Array.from(selectedItems, ([courseId, users]) =>
          Array.from(users.keys()).map((userId) => ({ courseId, userId })),
        ),
      ),
      organizationId: organizationIds,
    },
    updater: (
      store,
      { bulkUnEnrollUsersFromCoursesOrError: response }: MutationResponse,
    ) => {
      const failures = response?.unEnrollFailures?.map(
        ({ courseId, userId }) => ({
          courseId: encodeGlobalId('LmsCourse', courseId!),
          userId: encodeGlobalId('UserProfile', userId!),
        }),
      );

      response?.courseStatistics?.forEach(({ id, count, averageProgress }) => {
        const statisticRecord = store.get(id);
        statisticRecord?.setValue(averageProgress, 'averageProgress');
        statisticRecord?.setValue(count, 'count');
      });

      selectedItems.forEach((users, courseId) => {
        users.forEach(({ id: userId, course }, profileId) => {
          const hasFailed = failures?.some(
            (failure) =>
              failure.courseId === courseId && failure.userId === profileId,
          );

          if (!hasFailed) {
            rangeDeleteUpdater(store, {
              parentId: generateClientID(ROOT_ID, 'lms'),
              connectionKey: 'CourseOverviewMemberGrid_enrollmentConnection',
              deletedId: userId,
              connectionFilters: {
                courseId: [course.handle],
                organizationId: organizationIds,
              },
            });
          }
        });
      });

      clear();
    },
    onError: (error) => {
      toast.error(<FormattedMessage {...educationMessages.failedToUnenrol} />);
      throw error;
    },
    onCompleted: ({
      bulkUnEnrollUsersFromCoursesOrError: response,
    }: MutationResponse) => {
      const failures = response?.unEnrollFailures ?? [];
      const grouped = groupBy(failures, (f) => f.detail);

      // check if there were successful unenrollments
      if (failures.length < selectedUsersCount) {
        toast.success(
          <FormattedMessage
            {...educationMessages.courseUnenrolledToastMessage}
            values={{
              count: selectedUsersCount - failures.length,
            }}
          />,
        );
      }

      for (const message of Object.keys(grouped)) {
        toast.warning(
          <FormattedMessage
            {...educationMessages.courseUnenrollmentFailureToastMessage}
            values={{
              count: grouped[message].length,
              details: message,
            }}
          />,
        );
      }
    },
  });

  const handleEnroll = async () => {
    await dialog.open(
      <FormattedMessage
        {...educationMessages.courseUnenrollmentConfirmation}
        values={{ members: selectedUsersCount }}
      />,
      {
        title: <FormattedMessage {...educationMessages.courseUnEnrollment} />,
        confirmLabel: <FormattedMessage {...actionMessages.ok} />,
        runConfirm: mutate,
      },
    );
  };

  return (
    <>
      <EducationDashboardTabNav />
      <MainContent size="lg">
        <FooterActions
          show={!!selectedUsersCount}
          onCancel={clear}
          onConfirm={handleEnroll}
          confirmLabel={formatMessage(educationMessages.unEnroll)}
          content={
            <FormattedMessage
              {...educationMessages.membersSelected}
              values={{ count: selectedUsersCount }}
            />
          }
        >
          <Layout pad={2} className="ml-auto">
            <Form.Field name="organization">
              {(props) => (
                <OrganizationSelectButton
                  {...props}
                  data-bni-id="OrganizationFilter"
                  value={filters.organizationIds as string[]}
                  dataKey="handle"
                  toggleClassName="px-4 justify-between"
                  className="pb-4"
                  placeholder={formatMessage(
                    educationMessages.selectOrganizations,
                  )}
                  onChange={(val) => {
                    updateOrganizationIds(val?.map((v) => v.handle!) ?? []);
                  }}
                />
              )}
            </Form.Field>
            {canUseCredentialing && (
              <Form.Field name="credentialGroup">
                {(props) => (
                  <CredentialGroupSelectButton
                    {...props}
                    dataKey="id"
                    value={filters.groupId || []}
                    onChange={(credentialGroup) => {
                      updateCredentialGroup(
                        credentialGroup?.map((group) => group.id!) ?? [],
                      );
                    }}
                  />
                )}
              </Form.Field>
            )}
          </Layout>
          <FormControlSearch
            value={filters.search ?? ''}
            onChange={updateSearch}
            data-bni-id="SearchField-Courses"
            placeholder={educationMessages.searchForCourseName}
            variant="secondary"
          />
          <header
            className="grid pt-6 pb-1.5 border-b border-b-grey-85"
            css={css`
              grid-template-columns:
                minmax(0, 1fr) minmax(0, 0.5fr) minmax(0, 1fr)
                40px;
            `}
          >
            <SortHeaderButton
              className="flex-1"
              desc={filters.sort?.direction === SortingDirection.ASC}
              showIndicator={filters.sort?.field === NAME_FIELD_KEY}
              onClick={() => updateSort(NAME_FIELD_KEY)}
              size={14}
              data-bni-id="CourseHeader"
            >
              <Text
                variant="sm-bold"
                color="subtitle"
                data-bni-id="CourseNameHeader"
              >
                <FormattedMessage {...educationMessages.courseName} />
              </Text>
            </SortHeaderButton>
            <Text
              variant="sm-bold"
              color="subtitle"
              data-bni-id="NumberOfEnrollmentsHeader"
            >
              <FormattedMessage {...educationMessages.numberOfEnrollments} />
            </Text>
            <Text
              variant="sm-bold"
              color="subtitle"
              data-bni-id="NumberOfCourseCompletionsHeader"
            >
              <FormattedMessage
                {...educationMessages.numberOfCourseCompletions}
              />
            </Text>
            <Text variant="sm-bold" color="subtitle">
              <SrOnly>
                <FormattedMessage {...educationMessages.expandCourseDetails} />
              </SrOnly>
            </Text>
          </header>
          {children}
        </FooterActions>
      </MainContent>
    </>
  );
}
