/**
 * @generated SignedSource<<a14b8510061f4d024f367e0b66eacfc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VetPatientName_vetPatient$data = {
  readonly clientNameLast: string | null;
  readonly clientOrganizationName: string | null;
  readonly name: string | null;
  readonly " $fragmentType": "VetPatientName_vetPatient";
};
export type VetPatientName_vetPatient$key = {
  readonly " $data"?: VetPatientName_vetPatient$data;
  readonly " $fragmentSpreads": FragmentRefs<"VetPatientName_vetPatient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VetPatientName_vetPatient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientNameLast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientOrganizationName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "VetPatient",
  "abstractKey": null
};

(node as any).hash = "6002ce7946f5e086c1e3fd13a9fbb919";

export default node;
