import Layout from '@4c/layout';
import Text from '@bfly/ui2/Text';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';

import DataGrid from 'components/DataGrid';
import PopoverList from 'components/PopoverList';

import { StudyDataGridTags_study$data as Study } from './__generated__/StudyDataGridTags_study.graphql';

const TAG_OVERFLOW = 2;

interface Props {
  study: Study;
}

function StudyDataGridTags({ study, ...props }: Props) {
  const numOverflowing = study.tags!.length - TAG_OVERFLOW;
  return (
    <PopoverList
      show={study.tags!.length > TAG_OVERFLOW ? undefined : false}
      listItems={study.tags!.map((tag) => (
        <li key={tag!.id}>{tag!.name}</li>
      ))}
    >
      <DataGrid.Cell
        className={study.tags!.length > 2 ? 'pr-0' : undefined}
        {...props}
      >
        {study.tags!.length ? (
          <Layout
            pad={2}
            flex="1 1 0px"
            className="w-full overflow-hidden max-w-sm"
          >
            {study.tags!.slice(0, TAG_OVERFLOW).map((tag) => (
              <Text
                truncate
                key={tag!.id}
                color="headline"
                className="bg-grey-80 rounded-full px-2 py-0.5 w-full max-w-[max-content]"
              >
                {tag!.name}
              </Text>
            ))}
            {numOverflowing > 0 && <Text>+{numOverflowing}</Text>}
          </Layout>
        ) : null}
      </DataGrid.Cell>
    </PopoverList>
  );
}

export default createFragmentContainer(StudyDataGridTags, {
  study: graphql`
    fragment StudyDataGridTags_study on Study {
      tags {
        id
        name
      }
    }
  `,
});
