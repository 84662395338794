/**
 * @generated SignedSource<<480ba7fc761c50ec5d1406081f40c350>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EduStudiesReviewIndicators_Query$variables = {
  author?: ReadonlyArray<string> | null;
};
export type EduStudiesReviewIndicators_Query$data = {
  readonly viewer: {
    readonly eduStudyStatistics: {
      readonly byExamType: ReadonlyArray<{
        readonly examType: {
          readonly name: string | null;
          readonly systemId: string | null;
        } | null;
        readonly numReviewRequested: number;
        readonly numReviewed: number;
        readonly numUnsubmitted: number;
      }> | null;
      readonly imageQuality: {
        readonly byQuality: ReadonlyArray<{
          readonly numStudies: number;
          readonly quality: number;
        }> | null;
      } | null;
    } | null;
  } | null;
};
export type EduStudiesReviewIndicators_Query = {
  response: EduStudiesReviewIndicators_Query$data;
  variables: EduStudiesReviewIndicators_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "author"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "authors",
    "variableName": "author"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numUnsubmitted",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numReviewRequested",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numReviewed",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "EduImageQualityStatistics",
  "kind": "LinkedField",
  "name": "imageQuality",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EduStudyImageQualityStatistics",
      "kind": "LinkedField",
      "name": "byQuality",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numStudies",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EduStudiesReviewIndicators_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EduStudyStatistics",
            "kind": "LinkedField",
            "name": "eduStudyStatistics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EduStudyExamTypeStatistics",
                "kind": "LinkedField",
                "name": "byExamType",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamType",
                    "kind": "LinkedField",
                    "name": "examType",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EduStudiesReviewIndicators_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EduStudyStatistics",
            "kind": "LinkedField",
            "name": "eduStudyStatistics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EduStudyExamTypeStatistics",
                "kind": "LinkedField",
                "name": "byExamType",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamType",
                    "kind": "LinkedField",
                    "name": "examType",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6534e57be9521f01c0ba93d1249439a4",
    "id": null,
    "metadata": {},
    "name": "EduStudiesReviewIndicators_Query",
    "operationKind": "query",
    "text": "query EduStudiesReviewIndicators_Query(\n  $author: [ID!]\n) {\n  viewer {\n    eduStudyStatistics(authors: $author) {\n      byExamType {\n        examType {\n          name\n          systemId\n          id\n        }\n        numUnsubmitted\n        numReviewRequested\n        numReviewed\n      }\n      imageQuality {\n        byQuality {\n          quality\n          numStudies\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "729542583d807bf9ae0743942f58bc43";

export default node;
