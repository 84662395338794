import DropdownForm from '@bfly/ui2/DropdownForm';
import Form from '@bfly/ui2/Form';
import FormField from '@bfly/ui2/FormField';
import { Placement } from '@bfly/ui2/Popover';
import clsx from 'clsx';
import { ReactNode, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { array, object, string } from 'yup';

import StudyStatusMessage from 'components/StudyStatus';
import { StudyStatus } from 'utils/StudyConstants';

import { CheckGroupSelectButtonLabel } from './CheckGroupSelectButton';
import IndeterminateFormCheck from './IndeterminateFormCheck';
import { useVariation } from './LaunchDarklyContext';

interface StudyStatusSelectButtonProps {
  value: StudyStatus[] | null;
  onChange?: (nextValue: StudyStatus[] | null) => void;
  placeholder?: ReactNode;
  placement?: Placement;
  size?: 'md' | 'lg';
  showDraftOption?: boolean;
}

const schema = object({
  status: array(
    string().enum(Object.values(StudyStatus)).defined(),
  ).nullable(),
}).nullable();

function StudyStatusSelectButton({
  placeholder,
  value: propValue,
  onChange,
  size = 'md',
  showDraftOption = true,
  ...props
}: StudyStatusSelectButtonProps) {
  const canQa = useVariation('worksheets-review');

  const [show, setShow] = useState(false);
  const handleChange = (
    formValue: { status: StudyStatus[] | null } | null,
  ) => {
    onChange?.(formValue?.status || null);
  };

  const formValue = useMemo(
    () =>
      Array.isArray(propValue) && propValue?.length
        ? { status: propValue }
        : null,
    [propValue],
  );
  const statuses = formValue?.status || [];

  const filteredStatuses = statuses.map((v) =>
    v === StudyStatus.REVIEWED || v === StudyStatus.NEEDS_REVIEW
      ? StudyStatus.FINALIZED
      : v,
  );
  const uniqFilteredStatuses = [...new Set(filteredStatuses)];
  const label = !statuses.length ? (
    placeholder || (
      <FormattedMessage
        id="studyStatusSelectButton.placeholder"
        defaultMessage="All study statuses"
      />
    )
  ) : (
    <CheckGroupSelectButtonLabel
      show={show}
      dataItems={uniqFilteredStatuses}
      renderValue={({ item }) => (
        <StudyStatusMessage
          colorIndicator="if-available"
          status={item}
          hideFinalized
        />
      )}
      renderOverflowItem={({ item }) => (
        <StudyStatusMessage
          colorIndicator="hidden"
          status={item}
          hideFinalized
        />
      )}
    />
  );
  const finalized = (
    <>
      <span className="inline-block h-3 w-3 mr-2 rounded-full bg-white" />
      <FormattedMessage
        id="studyStatusSelectButton.finalized"
        defaultMessage="Finalized"
      />
    </>
  );
  return (
    <DropdownForm
      {...props}
      schema={schema}
      value={formValue}
      onChange={handleChange}
      label={label}
      show={show}
      onToggle={setShow}
      className={clsx(size === 'lg' && 'w-full')}
      toggleClassName={clsx(
        size === 'lg' && 'h-10 px-4 w-full justify-between',
      )}
    >
      <div className="p-4">
        {showDraftOption && (
          <Form.Field name="status" type="checkbox" value={StudyStatus.DRAFT}>
            <StudyStatusMessage status={StudyStatus.DRAFT} />
          </Form.Field>
        )}
        <Form.Field
          name="status"
          type="checkbox"
          value={StudyStatus.PENDING_ATTESTATION}
        >
          <StudyStatusMessage status={StudyStatus.PENDING_ATTESTATION} />
        </Form.Field>

        {canQa ? (
          <>
            <FormField name="status" type="checkbox">
              {(_, { value, update }) => {
                const ineligible = value?.includes(StudyStatus.FINALIZED);
                const needsReview = value?.includes(StudyStatus.NEEDS_REVIEW);
                const reviewed = value?.includes(StudyStatus.REVIEWED);
                const allChecked = ineligible && needsReview && reviewed;
                const noneChecked = !ineligible && !needsReview && !reviewed;

                return (
                  <IndeterminateFormCheck
                    name="all-finalized"
                    checked={allChecked}
                    indeterminate={!noneChecked && !allChecked}
                    onChange={(e) => {
                      const next: StudyStatus[] = (value || []).filter(
                        (v) =>
                          v !== StudyStatus.FINALIZED &&
                          v !== StudyStatus.NEEDS_REVIEW &&
                          v !== StudyStatus.REVIEWED,
                      );

                      update(
                        e.currentTarget.checked
                          ? [
                              ...next,
                              StudyStatus.FINALIZED,
                              StudyStatus.NEEDS_REVIEW,
                              StudyStatus.REVIEWED,
                            ]
                          : next,
                      );
                    }}
                  >
                    {finalized}
                  </IndeterminateFormCheck>
                );
              }}
            </FormField>
            <hr />
            <div className="ml-6">
              <Form.Field
                name="status"
                type="checkbox"
                value={StudyStatus.FINALIZED}
              >
                <StudyStatusMessage
                  colorIndicator="hidden"
                  status={StudyStatus.FINALIZED}
                />
              </Form.Field>
              <Form.Field
                name="status"
                type="checkbox"
                value={StudyStatus.NEEDS_REVIEW}
              >
                <StudyStatusMessage
                  colorIndicator="hidden"
                  status={StudyStatus.NEEDS_REVIEW}
                />
              </Form.Field>
              <Form.Field
                name="status"
                type="checkbox"
                value={StudyStatus.REVIEWED}
              >
                <StudyStatusMessage
                  colorIndicator="hidden"
                  status={StudyStatus.REVIEWED}
                />
              </Form.Field>
            </div>
          </>
        ) : (
          <Form.Field
            name="status"
            type="checkbox"
            value={StudyStatus.FINALIZED}
          >
            {finalized}
          </Form.Field>
        )}
      </div>
      <DropdownForm.Footer />
    </DropdownForm>
  );
}

export default StudyStatusSelectButton;
