import { QueryConfig } from '@bfly/ui2/useQuery';
import { QuerySubscription } from 'found-relay';
import { GraphQLTaggedNode } from 'react-relay';
import { OperationType } from 'relay-runtime';

export default function fetchQuery<TOperation extends OperationType = never>(
  query: GraphQLTaggedNode,
  {
    variables,
    environment,
    fetchPolicy,
  }: Omit<QueryConfig<TOperation>, 'skip'>,
) {
  return new Promise<{
    data: TOperation['response'];
    retry: () => void;
  }>((resolve, reject) => {
    const querySubscription = new QuerySubscription({
      // @ts-ignore - relay types are not up to date
      environment,
      query,
      variables,
      fetchPolicy,
    });

    querySubscription.subscribe(() => {
      if (querySubscription.readyState.error)
        reject(querySubscription.readyState.error);

      if (querySubscription.readyState.props)
        resolve({
          data: querySubscription.readyState.props as TOperation['response'],
          retry: () => querySubscription.readyState.retry?.(),
        });
    });

    querySubscription.fetch();
  });
}
