/**
 * @generated SignedSource<<ba3a1ba622debae5d3393526b7ed4d8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyHistoryModalContent_movedStudyEvent$data = {
  readonly createdAt: string | null;
  readonly createdBy: {
    readonly name: string | null;
  } | null;
  readonly destinationArchive: {
    readonly deletedAt: string | null;
    readonly label: string | null;
  } | null;
  readonly destinationOrganization: {
    readonly name: string | null;
  } | null;
  readonly sourceArchive: {
    readonly deletedAt: string | null;
    readonly label: string | null;
  } | null;
  readonly sourceOrganization: {
    readonly name: string | null;
  } | null;
  readonly version: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "StudyHistoryModalContent_movedStudyEvent";
};
export type StudyHistoryModalContent_movedStudyEvent$key = {
  readonly " $data"?: StudyHistoryModalContent_movedStudyEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyHistoryModalContent_movedStudyEvent">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deletedAt",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyHistoryModalContent_movedStudyEvent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyVersion",
      "kind": "LinkedField",
      "name": "version",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "sourceArchive",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "destinationArchive",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "sourceOrganization",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "destinationOrganization",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "MovedStudyEvent",
  "abstractKey": null
};
})();

(node as any).hash = "8e477a580177cafaccaf803789ca067a";

export default node;
