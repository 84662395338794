import Button from '@bfly/ui2/Button';
import Modal from '@bfly/ui2/Modal';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import actionMessages from 'messages/actions';

interface Props {
  onHide: () => void;
  name: string;
  children: ReactNode;
}

export default function ServerLogsModal({ onHide, name, children }: Props) {
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="serverLogModal.logs"
            defaultMessage="Logs for {name}"
            values={{ name }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body data-bni-id="ServerLogsModal">{children}</Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button size="lg" onClick={onHide}>
            <FormattedMessage {...actionMessages.close} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}
