/**
 * An enum representing broad "presets" or categories of filtered study list. These are mapped to
 * specific combinations of study connection arguments to produce a filtered.
 *
 * study list filters may be used as route params or query params
 */
export enum StudyListFilter {
  ALL = 'all',
  COMPLETED = 'completed',
  FAVORITES = 'favorites',
  UNASSIGNED = 'unassigned',
  DELETED = 'deleted',

  /**
   * Filters considered a part of "To do" lists. When updating those remember to add them to isTodoFilter helper
   */
  DRAFTS = 'drafts',
  MY_DRAFTS = 'my-drafts',
  NEEDS_REVIEW = 'needs-review',
  PENDING_ATTESTATION = 'pending-attestation',
  MY_SIGNATURE_REQUESTED = 'my-signature-requested',
}

export const isTodoFilter = (status) =>
  [
    StudyListFilter.DRAFTS,
    StudyListFilter.MY_DRAFTS,
    StudyListFilter.NEEDS_REVIEW,
    StudyListFilter.PENDING_ATTESTATION,
    StudyListFilter.MY_SIGNATURE_REQUESTED,
  ].includes(status);
/**
 * Study status denotes a studies position in its overall lifecycle from
 * draft -> finalized and qa'd
 *
 * This matches the upstream values for Status
 */
export enum StudyStatus {
  DRAFT = 'DRAFT',
  PENDING_ATTESTATION = 'PENDING_ATTESTATION',
  FINALIZED = 'FINALIZED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  REVIEWED = 'REVIEWED',
}

export const STUDY_GRID_PAGE_SIZE = 25;
