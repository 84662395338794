import Layout from '@4c/layout';
import BackButton from '@bfly/ui2/BackButton';
import Form from '@bfly/ui2/Form';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import { Link } from 'found';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PageTitle from 'components/PageTitle';
import RelayForm from 'components/RelayForm';
import { useAdminRoutes } from 'routes/admin';

import roleSchema, { deserialize, serialize } from '../schemas/roles';
import { validateBucketsDependencies } from '../utils/validateBucketsDependencies';
import DomainRoleDetailFieldSet from './DomainRoleDetailsFieldSet';
import DomainRolePermissionsFieldSet from './DomainRolePermissionsFieldSet';
import { CreateDomainRolePage_AddNewRoleMutation as CreateRoleMutation } from './__generated__/CreateDomainRolePage_AddNewRoleMutation.graphql';
import { UpdateDomainRolePage_membershipRole$data as MembershipRole } from './__generated__/UpdateDomainRolePage_membershipRole.graphql';

const messages = defineMessages({
  header: {
    id: 'domain.roles.duplicateRole',
    defaultMessage: 'Add new Butterfly Access Role',
  },
});

const mutation = graphql`
  mutation DuplicateDomainRolePage_AddNewRoleMutation(
    $input: CreateMembershipRoleInput!
  ) {
    createMembershipRoleOrError(input: $input) {
      ... on CreateMembershipRolePayload {
        membershipRole {
          id
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface Props {
  membershipRole: MembershipRole;
}

function DuplicateDomainRolePage({ membershipRole }: Props) {
  const routes = useAdminRoutes();

  const { name, description, permissions } = useMemo(
    () => deserialize(membershipRole),
    [membershipRole],
  );

  const membershipRoleData = {
    name,
    description,
    permissions,
  };

  // Adding copy word to the name
  membershipRoleData.name += ' (copy)';

  const [value, setValue] = useState(membershipRoleData);
  const handleChange = (nextValue, [updatedField]) => {
    const newValue = validateBucketsDependencies(nextValue, updatedField);
    setValue(newValue);
  };

  return (
    <Page.Container>
      <MainContent>
        <Layout pad={4} className="items-center mb-10">
          <Link to={routes.domainRoles()}>
            <BackButton />
          </Link>
          <PageTitle title={messages.header} />
        </Layout>
        <RelayForm<CreateRoleMutation, typeof roleSchema>
          horizontal
          as={null}
          schema={roleSchema}
          defaultValue={membershipRoleData as any}
          value={value}
          mutation={mutation}
          getInput={(input) => {
            return serialize(input);
          }}
          onChange={handleChange}
          successMessage={
            <FormattedMessage
              id="duplicateDomainRole.permission.success"
              defaultMessage="Butterfly Access Role created"
            />
          }
        >
          <>
            <DomainRoleDetailFieldSet />
            <DomainRolePermissionsFieldSet />
            <Layout reverse>
              <Form.Submit data-bni-id="DuplicateRoleSubmitButton">
                <FormattedMessage
                  id="domainRoleDuplicateRolePage.saveButton"
                  defaultMessage="Add Butterfly Access Role"
                />
              </Form.Submit>
            </Layout>
          </>
        </RelayForm>
      </MainContent>
    </Page.Container>
  );
}

export default createFragmentContainer(DuplicateDomainRolePage, {
  membershipRole: graphql`
    fragment DuplicateDomainRolePage_membershipRole on MembershipRole {
      ...roles_defaultValue
    }
  `,
});
