/**
 * @generated SignedSource<<26165756418f3ca01a296bd3f82c79b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type measurements_ExamImageMeasurementsPage_Query$variables = {
  studyImageHandle: string;
};
export type measurements_ExamImageMeasurementsPage_Query$data = {
  readonly studyImage: {
    readonly " $fragmentSpreads": FragmentRefs<"ExamImageMeasurementsPage_studyImage">;
  } | null;
};
export type measurements_ExamImageMeasurementsPage_Query = {
  response: measurements_ExamImageMeasurementsPage_Query$data;
  variables: measurements_ExamImageMeasurementsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "studyImageHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "studyImageHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "measurements_ExamImageMeasurementsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyImage",
        "kind": "LinkedField",
        "name": "studyImage",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExamImageMeasurementsPage_studyImage"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "measurements_ExamImageMeasurementsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyImage",
        "kind": "LinkedField",
        "name": "studyImage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SetMeasurementsStudyImageEvent",
            "kind": "LinkedField",
            "name": "measurements",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "measurements",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Archive",
                "kind": "LinkedField",
                "name": "archive",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "finalizedAt",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BurnedInFile",
            "kind": "LinkedField",
            "name": "burnedInFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BModeRegion",
                "kind": "LinkedField",
                "name": "bModeRegion",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deltaXMetersPerPixel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deltaYMetersPerPixel",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rawDataUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35328bb546130aa3524be6a803d3cd57",
    "id": null,
    "metadata": {},
    "name": "measurements_ExamImageMeasurementsPage_Query",
    "operationKind": "query",
    "text": "query measurements_ExamImageMeasurementsPage_Query(\n  $studyImageHandle: String!\n) {\n  studyImage(handle: $studyImageHandle) {\n    ...ExamImageMeasurementsPage_studyImage\n    id\n  }\n}\n\nfragment ExamImageMeasurementsPage_studyImage on StudyImage {\n  id\n  handle\n  measurements {\n    id\n    measurements\n  }\n  organization {\n    id\n  }\n  study {\n    handle\n    archive {\n      handle\n      id\n    }\n    ...StudyPermissions_canSaveMeasurements\n    id\n  }\n  ...TracesField_studyImage\n  ...ExamMeasurementsToolbar_studyImage\n}\n\nfragment ExamMeasurementsToolbar_studyImage on StudyImage {\n  id\n  handle\n  study {\n    handle\n    ...StudyPermissions_canSaveMeasurements\n    id\n  }\n}\n\nfragment StudyPermissions_canSaveMeasurements on Study {\n  finalizedAt\n}\n\nfragment TracesField_studyImage on StudyImage {\n  burnedInFile {\n    dataUrl\n    bModeRegion {\n      deltaXMetersPerPixel\n      deltaYMetersPerPixel\n    }\n  }\n  ...useStudyImage_studyImage\n}\n\nfragment useStudyImage_studyImage on StudyImage {\n  burnedInFile {\n    rawDataUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "af4c75621742e6cf9125df1fcbac1f09";

export default node;
