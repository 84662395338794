import { graphql, readInlineData } from 'relay-runtime';

import { getConcreteTenant_tenant$key as TenantKey } from './__generated__/getConcreteTenant_tenant.graphql';

/**
 * Decomposes a TenantInterface type into it's concrete Domain or Organization for
 * easier interop with components that have fragments on the individual types
 */
export default function getConcreteTenant<T extends TenantKey>(tenantRef: T) {
  const tenant = readInlineData(
    graphql`
      fragment getConcreteTenant_tenant on TenantInterface @inline {
        ... on Domain {
          type: __typename
        }
        ... on Organization {
          type: __typename
        }
      }
    `,
    tenantRef,
  );

  return {
    domain: tenant?.type === 'Domain' ? tenantRef : null,
    organization: tenant?.type === 'Organization' ? tenantRef : null,
  };
}
