/**
 * @generated SignedSource<<ccad9d1e695cb581fea69c63587ff7c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarOrganizationQuery$variables = {
  organizationId: string;
  showTags: boolean;
};
export type SearchBarOrganizationQuery$data = {
  readonly organization: {
    readonly archiveConnection?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly handle: string | null;
          readonly label: string | null;
          readonly type: "Archive";
          readonly " $fragmentSpreads": FragmentRefs<"SearchBarList_archive" | "SearchBarTags_archive">;
        } | null;
      } | null> | null;
    } | null;
    readonly studyCreatorConnection?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly handle: string | null;
          readonly name: string | null;
          readonly type: "UserProfile";
          readonly " $fragmentSpreads": FragmentRefs<"SearchBarList_userProfile" | "SearchBarTags_userProfile">;
        } | null;
      } | null> | null;
    } | null;
    readonly studyTagConnection?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly handle: string | null;
          readonly name: string | null;
          readonly type: "StudyTag";
          readonly " $fragmentSpreads": FragmentRefs<"SearchBarList_studyTag" | "SearchBarTags_studyTag">;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type SearchBarOrganizationQuery = {
  response: SearchBarOrganizationQuery$data;
  variables: SearchBarOrganizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showTags"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "Avatar_userProfile"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  (v6/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchBarOrganizationQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArchiveConnection",
                "kind": "LinkedField",
                "name": "archiveConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArchiveEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Archive",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "SearchBarTags_archive",
                            "selections": (v5/*: any*/),
                            "args": null,
                            "argumentDefinitions": []
                          },
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "SearchBarList_archive",
                            "selections": (v5/*: any*/),
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "studyCreatorConnection",
                "args": null,
                "concreteType": "UserProfileConnection",
                "kind": "LinkedField",
                "name": "__Organization_studyCreatorConnection_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfileEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "SearchBarTags_userProfile",
                            "selections": [
                              (v7/*: any*/),
                              (v6/*: any*/)
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          },
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "SearchBarList_userProfile",
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "condition": "showTags",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyTagConnection",
                    "kind": "LinkedField",
                    "name": "studyTagConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyTagEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StudyTag",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v6/*: any*/),
                              {
                                "kind": "InlineDataFragmentSpread",
                                "name": "SearchBarTags_studyTag",
                                "selections": (v11/*: any*/),
                                "args": null,
                                "argumentDefinitions": []
                              },
                              {
                                "kind": "InlineDataFragmentSpread",
                                "name": "SearchBarList_studyTag",
                                "selections": (v11/*: any*/),
                                "args": null,
                                "argumentDefinitions": []
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchBarOrganizationQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArchiveConnection",
                "kind": "LinkedField",
                "name": "archiveConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArchiveEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Archive",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "UserProfileConnection",
                "kind": "LinkedField",
                "name": "studyCreatorConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfileEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Avatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Thumbnail",
                                    "kind": "LinkedField",
                                    "name": "thumbnails",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "width",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "ThumbnailedInterface",
                                "abstractKey": "__isThumbnailedInterface"
                              }
                            ],
                            "storageKey": null
                          },
                          (v12/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": "studyCreatorConnection(first:2147483647)"
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Organization_studyCreatorConnection",
                "kind": "LinkedHandle",
                "name": "studyCreatorConnection"
              },
              {
                "condition": "showTags",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyTagConnection",
                    "kind": "LinkedField",
                    "name": "studyTagConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyTagEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StudyTag",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "256bb23e784d41cd0133e75b49310335",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "organization",
            "studyCreatorConnection"
          ]
        }
      ]
    },
    "name": "SearchBarOrganizationQuery",
    "operationKind": "query",
    "text": "query SearchBarOrganizationQuery(\n  $organizationId: ID!\n  $showTags: Boolean!\n) {\n  organization: node(id: $organizationId) {\n    __typename\n    ... on Organization {\n      archiveConnection {\n        edges {\n          node {\n            type: __typename\n            handle\n            label\n            ...SearchBarTags_archive\n            ...SearchBarList_archive\n            id\n          }\n        }\n      }\n      studyCreatorConnection(first: 2147483647) {\n        edges {\n          node {\n            type: __typename\n            handle\n            name\n            ...SearchBarTags_userProfile\n            ...SearchBarList_userProfile\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      studyTagConnection @include(if: $showTags) {\n        edges {\n          node {\n            type: __typename\n            handle\n            name\n            ...SearchBarTags_studyTag\n            ...SearchBarList_studyTag\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment SearchBarList_archive on Archive {\n  label\n}\n\nfragment SearchBarList_studyTag on StudyTag {\n  name\n}\n\nfragment SearchBarList_userProfile on UserProfile {\n  name\n  ...Avatar_userProfile\n}\n\nfragment SearchBarTags_archive on Archive {\n  label\n}\n\nfragment SearchBarTags_studyTag on StudyTag {\n  name\n}\n\nfragment SearchBarTags_userProfile on UserProfile {\n  ...Avatar_userProfile\n  name\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n"
  }
};
})();

(node as any).hash = "8aa656bbe9a7ed456a3569efbedfbd78";

export default node;
