import { createFragmentContainer, graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import RelatedUserAvatars from './RelatedUserAvatars';
import { StudyAuthors_study$data as Study } from './__generated__/StudyAuthors_study.graphql';

type SecondaryAuthors = DeepNonNullable<Study>['secondaryAuthors'];
type PrimaryAuthor = DeepNonNullable<Study>['createdBy'];

interface Props {
  study: Study;
}

function StudyAuthors({ study }: Props) {
  const { secondaryAuthors, createdBy } = study;

  return (
    <RelatedUserAvatars
      users={secondaryAuthors as SecondaryAuthors}
      // 24px "grid" to match other icons.
      className="min-h-6"
      data-bni-id="studyAuthor"
      study={study}
      displayUser={createdBy as PrimaryAuthor}
    />
  );
}

export default createFragmentContainer(StudyAuthors, {
  study: graphql`
    fragment StudyAuthors_study on Study {
      createdBy {
        ...RelatedUserAvatars_displayUser
      }
      secondaryAuthors {
        ...RelatedUserAvatars_users
      }
      ...RelatedUserAvatars_study
    }
  `,
});
