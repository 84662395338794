/**
 * @generated SignedSource<<e028e1afbdc25a61a0b8945507e3082f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentList_comments$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommentListItem_comment">;
  readonly " $fragmentType": "CommentList_comments";
}>;
export type CommentList_comments$key = ReadonlyArray<{
  readonly " $data"?: CommentList_comments$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentList_comments">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CommentList_comments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentListItem_comment"
    }
  ],
  "type": "StudyImageComment",
  "abstractKey": null
};

(node as any).hash = "77ff42e9d0ce4cec02c9bc226174d6b7";

export default node;
