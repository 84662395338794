import Layout from '@4c/layout';
import Heading from '@bfly/ui2/Heading';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';

export default function IntroPage() {
  return (
    <Page.Container>
      <MainContent className="w-full" size="2xl">
        <Heading ruled className="mb-5">
          <FormattedMessage defaultMessage="Demo" id="demoPage.title" />
        </Heading>
        <Layout>
          <Text variant="body">
            This demo area is a list of custom reusable elements built in the
            frontend. Click an element on the left to view.
          </Text>
        </Layout>
      </MainContent>
    </Page.Container>
  );
}
