import { LocalStore } from 'utils/localStore';

export const POD_TARGETING_HEADERS = [
  'x-admin-graphql-target-pod',
  'x-audit-logs-target-pod',
  'x-bslink-exporter-target-pod',
  'x-bslink-fhir-target-pod',
  'x-bslink-target-pod',
  'x-edu-target-pod',
  'x-exporter-qa-dashboard-target-pod',
  'x-exporter-studies-target-pod',
  'x-graphql-socket-target-pod',
  'x-graphql-target-pod',
  'x-logfetcher-target-pod',
  'x-management-exporter-target-pod',
  'x-management-target-pod',
  'x-notification-rendering-target-pod',
  'x-search-target-pod',
  'x-storage-target-pod',
  'x-telemed-target-pod',
  'x-token-exchange-target-pod',
] as const;

export type PodTargetingHeader = typeof POD_TARGETING_HEADERS[number];

export type PodTargetingHeaders = Partial<Record<PodTargetingHeader, string>>;

const podTargetingHeaders = LocalStore<PodTargetingHeaders>(
  'bfly:podTargetingHeaders',
);

export default podTargetingHeaders;
