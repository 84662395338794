import RelayInfiniteList from '@bfly/ui2/RelayInfiniteList';
import Text from '@bfly/ui2/Text';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { RelayPaginationProp } from 'react-relay';

const PAGE_SIZE = 15;

interface Props {
  hasData: boolean;
  relayPagination: RelayPaginationProp;
  children: ReactNode;
}

function ServerLogList({ hasData, relayPagination, children }: Props) {
  if (!hasData) {
    return (
      <Text align="center">
        <FormattedMessage
          id="ServerLogList.empty"
          defaultMessage="No logs available"
        />
      </Text>
    );
  }

  return (
    // TODO: check if this is needed
    <ul className="list-none">
      <RelayInfiniteList
        relayPagination={relayPagination}
        pageSize={PAGE_SIZE}
        bottomOffset={50}
      >
        {children}
      </RelayInfiniteList>
    </ul>
  );
}

export default ServerLogList;
