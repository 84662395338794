/**
 * @generated SignedSource<<603990566dd0c0fa3db03a1d96bf2760>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PermanentlyDeleteStudyInput = {
  clientMutationId?: string | null;
  studyId: string;
};
export type PermanentlyDeleteStudyControlMutation$variables = {
  input: PermanentlyDeleteStudyInput;
};
export type PermanentlyDeleteStudyControlMutation$data = {
  readonly permanentlyDeleteStudyOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
  } | null;
};
export type PermanentlyDeleteStudyControlMutation = {
  response: PermanentlyDeleteStudyControlMutation$data;
  variables: PermanentlyDeleteStudyControlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PermanentlyDeleteStudyControlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "permanentlyDeleteStudyOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PermanentlyDeleteStudyControlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "permanentlyDeleteStudyOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f6d52e23d91da340c16db16d33cd30e",
    "id": null,
    "metadata": {},
    "name": "PermanentlyDeleteStudyControlMutation",
    "operationKind": "mutation",
    "text": "mutation PermanentlyDeleteStudyControlMutation(\n  $input: PermanentlyDeleteStudyInput!\n) {\n  permanentlyDeleteStudyOrError(input: $input) {\n    __typename\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e335fd7218c3fe1e207c7af06ae56d46";

export default node;
