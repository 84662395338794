import Layout from '@4c/layout';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';

import DataGrid, { ColumnProp } from 'components/DataGrid';
import FormattedShortDateTime from 'components/FormattedShortDateTime';

import EduStudyCaptures from './components/EduStudyCaptures';
import { EDU_STUDY_SORT } from './constants';
import { STATUS_MESSAGES } from './messages';
import { EduStudy, EduStudyContext } from './types';

const eduStudiesColumns: ColumnProp<EduStudyContext, EduStudy>[] = [
  {
    key: 'actions',
    width: () => '4rem',
    frozen: true,
    picker: { show: false },
    label: () => <DataGrid.SelectAll data-bni-id="EduStudiesBulkSelectAll" />,
    headerClassName: 'border-r-0',
    renderCell: ({ cellProps }) => {
      return (
        <DataGrid.Cell {...cellProps} to={undefined}>
          <DataGrid.RowSelect
            className="mr-3"
            data-bni-id="EduStudiesBulkSelect"
          />
        </DataGrid.Cell>
      );
    },
  },
  {
    label: (
      <FormattedMessage
        id="scanLab.portfolio.author"
        defaultMessage="Author"
      />
    ),
    key: 'author',
    render: (item) => item.createdBy?.name,
  },
  {
    label: (
      <FormattedMessage
        id="scanLab.portfolio.capturedAt"
        defaultMessage="Captured at"
      />
    ),
    key: 'capturedAt',
    renderCell: ({ context, spec, item: study, cellProps }) => (
      <EduStudyCaptures
        key={spec.key}
        study={study}
        studyImages={context.studyImages?.[study.id] || null}
        cellProps={cellProps}
        cursor={study.cursor}
      />
    ),
    sortable: [
      {
        key: EDU_STUDY_SORT.CAPTURED_AT_DESC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.capturedAtSortDesc"
            defaultMessage="Sort most recently captured first"
          />
        ),
      },
      {
        key: EDU_STUDY_SORT.CAPTURED_AT_ASC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.capturedAtSortAsc"
            defaultMessage="Sort most recently captured last"
          />
        ),
      },
    ],
  },
  {
    label: <FormattedMessage {...STATUS_MESSAGES.reviewRequested} />,
    key: 'reviewRequested',
    render: (item) =>
      item.reviewRequestedAt && (
        <span className="lowercase">
          <FormattedShortDateTime value={item.reviewRequestedAt} />
        </span>
      ),
    sortable: [
      {
        key: EDU_STUDY_SORT.REVIEW_REQUESTED_AT_DESC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.requestedReviewSortDesc"
            defaultMessage="Sort most recently requested for review first"
          />
        ),
      },
      {
        key: EDU_STUDY_SORT.REVIEW_REQUESTED_AT_ASC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.requestedReviewSortAsc"
            defaultMessage="Sort most recently requested for review last"
          />
        ),
      },
    ],
  },
  {
    label: <FormattedMessage {...STATUS_MESSAGES.reviewed} />,
    key: 'reviewed',
    render: (item) =>
      item.standardQa?.createdAt && (
        <span className="lowercase">
          <FormattedShortDateTime value={item.standardQa?.createdAt} />
        </span>
      ),
    sortable: [
      {
        key: EDU_STUDY_SORT.REVIEWED_AT_DESC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.reviewedSortDesc"
            defaultMessage="Sort most recently reviewed first"
          />
        ),
      },
      {
        key: EDU_STUDY_SORT.REVIEWED_AT_ASC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.reviewedSortAsc"
            defaultMessage="Sort most recently reviewed last"
          />
        ),
      },
    ],
  },
  {
    label: (
      <FormattedMessage
        id="scanLab.portfolio.uploadedAt"
        defaultMessage="Uploaded at"
      />
    ),
    key: 'readyAt',
    render: (item) => (
      <span className="lowercase">
        <FormattedShortDateTime value={item.readyAt!} />
      </span>
    ),
    sortable: [
      {
        key: EDU_STUDY_SORT.READY_AT_DESC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.uploadedAtSortDesc"
            defaultMessage="Sort most recently uploaded first"
          />
        ),
      },
      {
        key: EDU_STUDY_SORT.READY_AT_ASC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.uploadedAtSortAsc"
            defaultMessage="Sort most recently uploaded last"
          />
        ),
      },
    ],
  },
  {
    label: (
      <FormattedMessage
        id="scanLab.portfolio.examType"
        defaultMessage="Exam Type"
      />
    ),
    key: 'examType',
    render: (item) => item.examTypes?.[0]?.name,
  },
  {
    label: (
      <FormattedMessage
        id="scanLab.portfolio.zonesCaptured"
        defaultMessage="Views Captured"
      />
    ),
    key: 'zonesCompleted',
    render: (item) => {
      const percentage = Math.round(
        (item.zonesCompleted / item.totalZones) * 100,
      );

      return (
        <Layout justify="center" align="center" className="w-full">
          <div className="grow h-2 bg-grey-80 rounded-sm mr-2">
            <div
              className="h-2 bg-grey-50 rounded-sm"
              style={{ width: `${percentage}%` }}
            />
          </div>
          <Text as="div" variant="sm" className="w-[7rem] text-left">
            {`${item.zonesCompleted}/${item.totalZones}`} ({percentage}%)
          </Text>
        </Layout>
      );
    },
    sortable: [
      {
        key: EDU_STUDY_SORT.ZONES_CAPTURED_ASC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.zonesCapturedAsc"
            defaultMessage="Sort least to most progress"
          />
        ),
      },
      {
        key: EDU_STUDY_SORT.ZONES_CAPTURED_DESC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.zonesCapturedDesc"
            defaultMessage="Sort most to least progress"
          />
        ),
      },
    ],
  },
  {
    label: (
      <FormattedMessage
        id="scanLab.portfolio.reviewedImageQuality"
        defaultMessage="Reviewed Image Quality"
      />
    ),
    key: 'reviewedImageQuality',
    render: (item) => item.standardQa?.imageQuality || 0,
    sortable: [
      {
        key: EDU_STUDY_SORT.REVIEWED_IMAGE_QUALITY_ASC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.sortReviewedImageQualityAsc"
            defaultMessage="Sort lowest to highest reviewed image quality"
          />
        ),
      },
      {
        key: EDU_STUDY_SORT.REVIEWED_IMAGE_QUALITY_DESC,
        label: (
          <FormattedMessage
            id="scanLab.portfolio.sortReviewedImageQualityDesc"
            defaultMessage="Sort highest to lowest reviewed image quality"
          />
        ),
      },
    ],
  },
];

export default eduStudiesColumns;
