import { Params, join, route } from '@4c/spa-routing';
import { useRouter } from 'found';
import { useMemo } from 'react';

import partialRoutes from '../utils/partialRoutes';

const rootRoute = route('');
const organizationRoot = join(rootRoute, ':organizationSlug');

export const routes = {
  rootRoute,
  organizationRoot,
};

export function useOrganizationRoutes(newOrgSlug?: string) {
  const { match } = useRouter();
  // If params are passed use them, otherwise use existing ones
  const organizationSlug = newOrgSlug || match.params.organizationSlug;

  return useMemo(() => {
    const params: Params<'organizationSlug'> = {
      organizationSlug,
    };
    return partialRoutes(routes, params);
  }, [organizationSlug]);
}
