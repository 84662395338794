/* eslint-disable max-classes-per-file */
import { RedirectException, RedirectProps } from 'found';
import HttpError from 'found/HttpError';
import React from 'react';
import type { ComponentClass, ComponentType } from 'react';
import type { GraphQLTaggedNode } from 'react-relay';

import { LDClient } from 'config/FeatureFlags.d';

import {
  RelayRouteRenderArgs,
  RouteClass,
  RouteMatch,
  RouteProps,
  RouteRenderFetchedArgs,
  createRender,
} from './Route';

const DummyComponent = () => null;

export const NEEDS_LAUNCH_DARKLY = Symbol('needs LaunchDarkly');

declare module 'farce' {
  export interface Location {
    [NEEDS_LAUNCH_DARKLY]: boolean;
  }
}

interface LaunchDarklyRouteVariation {
  Component?: React.ComponentType<any>;
  getComponent?: (
    match: RouteMatch,
  ) => React.ComponentType<any> | Promise<React.ComponentType<any>>;

  query?: GraphQLTaggedNode;
  getQuery?: (
    match: RouteMatch,
  ) => GraphQLTaggedNode | Promise<GraphQLTaggedNode>;

  prepareVariables?(
    variables: Record<string, unknown>,
    routeMatch: RouteMatch,
  ): Record<string, unknown>;
}

export type Prerender = (
  renderArgs: RelayRouteRenderArgs<any> & { ldClient: LDClient },
) => void;

export type Render = (
  renderArgs: RelayRouteRenderArgs<any> & { ldClient: LDClient },
) => React.ReactElement | null | undefined;

export type RenderFetched = (
  renderArgs: RouteRenderFetchedArgs<any> & { ldClient: LDClient },
) => React.ReactElement | null | undefined | void;

export interface LaunchDarklyRouteProps
  extends Omit<RouteProps, 'render' | 'renderFetched' | 'prerender'> {
  /**
   * Return a set of route props based on the outcome of an LD variation check.
   * For cases where a route should only be accessible when a flag is enabled true or false
   * can be returned.
   */
  getVariation?: (
    ldClient: LDClient,
    match: RouteMatch,
  ) => LaunchDarklyRouteVariation | boolean | null | undefined;

  prerender?: Prerender;
  render?: Render;
  renderFetched?: RenderFetched;
}

function getRouteValueGetter<T>(
  getVariation: NonNullable<LaunchDarklyRouteProps['getVariation']>,
  ldUnavailableFallback: T,
  getGetter: (
    variation: LaunchDarklyRouteVariation,
  ) =>
    | ((this: LaunchDarklyRoute, match: RouteMatch) => T | Promise<T>)
    | undefined,
  getValue: (variation: LaunchDarklyRouteVariation) => T | undefined,
) {
  function routeValueGetter(this: LaunchDarklyRoute, match: RouteMatch) {
    const ld = match.context.launchDarkly;
    if (!ld.client) {
      // This flag is checked in Resolver after all routes are resolved. If
      // true, it redirects to itself, where the LD client will now be ready.
      // eslint-disable-next-line no-param-reassign
      match.location[NEEDS_LAUNCH_DARKLY] = true;
      return ldUnavailableFallback;
    }

    const variation = getVariation(ld.client, match);

    if (variation === true) {
      return undefined;
    }
    if (!variation) {
      throw new HttpError(404);
    }

    const getter = getGetter(variation);
    return getter ? getter.call(this, match) : getValue(variation);
  }

  return routeValueGetter;
}

function createLdRender({ prerender, render, renderFetched }) {
  const baseRender = createRender({ prerender, render, renderFetched });

  return (renderArgs: any) => {
    const { Component, match } = renderArgs;

    if (Component === DummyComponent) {
      // FIXME: upstream is unconditionally expecting an element, when it shouldn't
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return () => <></>;
    }

    return baseRender({
      ...renderArgs,
      ldClient: match.context.launchDarkly.client,
    });
  };
}

export interface LaunchDarklyRedirectProps extends LaunchDarklyRouteProps {
  to: RedirectProps['to'];
  getVariation?: (
    ldClient: LDClient,
    match: RouteMatch,
  ) => boolean | null | undefined;
}

/**
 * A specialized version of LaunchDarklyRoute designed to redirect to
 * another location if the route returns `true` from `getVariation`
 */
class LaunchDarklyRedirectClass extends RouteClass {
  constructor({
    getVariation,
    to,
    render,
    prerender,
    renderFetched,
    ...props
  }: LaunchDarklyRedirectProps) {
    const redirectVariation = (ldClient, match) => {
      if (getVariation!(ldClient, match)) {
        const { router, params } = match;
        const redirectTo =
          typeof to === 'function'
            ? to(match)
            : router.matcher.format(to, params);

        throw new RedirectException(redirectTo, props.status);
      }
      return props;
    };

    super({
      ...props,
      getComponent: getRouteValueGetter<ComponentType<any>>(
        redirectVariation,
        DummyComponent,
        (v) => v.getComponent,
        (v) => v.Component,
      ),
      getQuery: getRouteValueGetter(
        redirectVariation,
        null,
        (v) => v.getQuery,
        (v) => v.query,
      ),

      render: createLdRender({ render, prerender, renderFetched }),
    });
  }
}

class LaunchDarklyRoute extends RouteClass {
  constructor({
    getVariation,
    render,
    prerender,
    renderFetched,
    ...props
  }: LaunchDarklyRouteProps) {
    const variationProps = getVariation && {
      getComponent: getRouteValueGetter<ComponentType<any>>(
        getVariation,
        DummyComponent,
        (v) => v.getComponent,
        (v) => v.Component,
      ),
      getQuery: getRouteValueGetter(
        getVariation,
        null,
        (v) => v.getQuery,
        (v) => v.query,
      ),
      // Edge 17: Don't use method shorthand: Microsoft/ChakraCore#5030.
      prepareVariables: (variables: any, match: RouteMatch) => {
        if (!match.context.launchDarkly.client) return variables;
        const variation = getVariation(
          match.context.launchDarkly.client,
          match,
        );

        if (variation === true) {
          return variables;
        }

        return variation && variation.prepareVariables
          ? variation.prepareVariables(variables, match)
          : variables;
      },
    };

    super({
      ...variationProps,
      ...props,
      render: createLdRender({ render, prerender, renderFetched }),
    });
  }
}

export const LaunchDarklyRedirect =
  LaunchDarklyRedirectClass as ComponentClass<LaunchDarklyRedirectProps>;

export default LaunchDarklyRoute as ComponentClass<LaunchDarklyRouteProps>;
