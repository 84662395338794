/**
 * @generated SignedSource<<5c3be4586a3098621cad7c3d95e0afef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type account_RedirectIfAuthenticatedQuery$variables = {};
export type account_RedirectIfAuthenticatedQuery$data = {
  readonly viewer: {
    readonly id: string;
  } | null;
};
export type account_RedirectIfAuthenticatedQuery = {
  response: account_RedirectIfAuthenticatedQuery$data;
  variables: account_RedirectIfAuthenticatedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "account_RedirectIfAuthenticatedQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "account_RedirectIfAuthenticatedQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "400e2df10a71aa27ef809269e7720836",
    "id": null,
    "metadata": {},
    "name": "account_RedirectIfAuthenticatedQuery",
    "operationKind": "query",
    "text": "query account_RedirectIfAuthenticatedQuery {\n  viewer {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "33004237f226f87df65ea495d619ce44";

export default node;
