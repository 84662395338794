import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import BaseAppPage, { PageLayout } from './BaseAppPage';
import { AppPage_viewer$data as Viewer } from './__generated__/AppPage_viewer.graphql';

interface Props {
  viewer: Viewer | null;
  center?: boolean;
  children: React.ReactNode;
  pageLayout?: PageLayout;
}

function AppPage({ pageLayout, viewer, center, children }: Props) {
  return (
    <BaseAppPage
      tenant={null}
      viewer={viewer}
      organization={null}
      archive={null}
      searchNodes={null}
      center={center}
      pageLayout={pageLayout}
    >
      {children}
    </BaseAppPage>
  );
}

export default Object.assign(
  createFragmentContainer(AppPage, {
    viewer: graphql`
      fragment AppPage_viewer on Viewer {
        ...BaseAppPage_viewer
      }
    `,
  }),
  {
    query: graphql`
      query AppPageQuery {
        viewer {
          ...AppPage_viewer
        }
      }
    `,
  },
);
