/**
 * @generated SignedSource<<0af728a50b66efab8731d18232118c50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyFinalizationRestriction = "AUTHOR_REQUIRED" | "EXAM_TYPE_REQUIRED" | "UNKNOWN__" | "WORKLIST_REQUIRED" | "WORKSHEET_REQUIRED" | "%future added value";
export type RequestStudyFinalizationInput = {
  clientLatestStudyEventId?: string | null;
  clientMutationId?: string | null;
  clientPerformedValidationOn?: ReadonlyArray<StudyFinalizationRestriction> | null;
  finalizationRequests: ReadonlyArray<StudyInputFinalizationRequest>;
  studyId: string;
};
export type StudyInputFinalizationRequest = {
  recipientId: string;
};
export type RequestFinalizationModal_Mutation$variables = {
  input: RequestStudyFinalizationInput;
};
export type RequestFinalizationModal_Mutation$data = {
  readonly requestStudyFinalizationOrError: {
    readonly __typename?: string;
    readonly fields?: ReadonlyArray<{
      readonly message: string;
      readonly path: ReadonlyArray<string>;
    }>;
    readonly message?: string | null;
    readonly study?: {
      readonly finalizationRequests: ReadonlyArray<{
        readonly recipient: {
          readonly id: string;
          readonly name: string | null;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"ExamStatus_finalizationRequests">;
      } | null> | null;
      readonly isPendingFinalization: boolean | null;
    } | null;
  } | null;
};
export type RequestFinalizationModal_Mutation = {
  response: RequestFinalizationModal_Mutation$data;
  variables: RequestFinalizationModal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPendingFinalization",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "recipient",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvalidInputErrorField",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvalidInputError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestFinalizationModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "requestStudyFinalizationOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyFinalizationRequest",
                    "kind": "LinkedField",
                    "name": "finalizationRequests",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ExamStatus_finalizationRequests"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RequestStudyFinalizationPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestFinalizationModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "requestStudyFinalizationOrError",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyFinalizationRequest",
                    "kind": "LinkedField",
                    "name": "finalizationRequests",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "RequestStudyFinalizationPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "828a34f3d83572d43f923b18c5a5b926",
    "id": null,
    "metadata": {},
    "name": "RequestFinalizationModal_Mutation",
    "operationKind": "mutation",
    "text": "mutation RequestFinalizationModal_Mutation(\n  $input: RequestStudyFinalizationInput!\n) {\n  requestStudyFinalizationOrError(input: $input) {\n    __typename\n    ... on RequestStudyFinalizationPayload {\n      study {\n        isPendingFinalization\n        finalizationRequests {\n          recipient {\n            id\n            name\n          }\n          ...ExamStatus_finalizationRequests\n        }\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      ... on InvalidInputError {\n        fields {\n          message\n          path\n        }\n      }\n      ... on MaxSeatsExceededError {\n        message\n      }\n      __typename\n      message\n    }\n  }\n}\n\nfragment ExamStatus_finalizationRequests on StudyFinalizationRequest {\n  createdAt\n  recipient {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ebb11d29cf4cb8728312fdc8bc34b34";

export default node;
