import useMountEffect from '@restart/hooks/useMountEffect';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import LoadingIndicatorPage from 'components/LoadingIndicatorPage';
import ExternalRedirect from 'utils/ExternalRedirect';
import makeStoreUrl from 'utils/makeStoreUrl';

import { EcommerceExternalRedirect_organization$data as Organization } from './__generated__/EcommerceExternalRedirect_organization.graphql';

interface Props {
  organization: Organization | null;
}

function EcommerceExternalRedirect({ organization }: Props) {
  // This redirect should only run on mount.
  useMountEffect(() => {
    let url;

    if (organization && !organization.subscription!.hasCompletedTrial) {
      url = makeStoreUrl('free-trial', {
        organizationSlug: organization.slug!,
      });
    } else {
      url = makeStoreUrl('subscription', {
        organizationSlug: organization?.slug,
      });
    }

    ExternalRedirect.redirect(url);
  });

  return <LoadingIndicatorPage />;
}

export default createFragmentContainer(EcommerceExternalRedirect, {
  organization: graphql`
    fragment EcommerceExternalRedirect_organization on Organization {
      slug
      subscription {
        hasCompletedTrial
      }
    }
  `,
});
