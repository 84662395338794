/**
 * @generated SignedSource<<62951f688f03a543690d4365132d1204>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type BulkUpdateDomainUsersInput = {
  clientMutationId?: string | null;
  domainId: string;
  users: ReadonlyArray<BulkInputDomainUser | null>;
};
export type BulkInputDomainUser = {
  canFinalize?: boolean | null;
  canQa?: boolean | null;
  dicomFields?: ReadonlyArray<BulkInputDomainUserDicomField> | null;
  email: string;
  integrationConfigs?: ReadonlyArray<BulkInputDomainUserIntegrationConfig> | null;
  integrationDisplayNameFirst?: string | null;
  integrationDisplayNameLast?: string | null;
  integrationDisplayNameMiddle?: string | null;
  isNurse?: boolean | null;
  roleId?: string | null;
};
export type BulkInputDomainUserIntegrationConfig = {
  integrationId: string;
  interfaceCode: string;
};
export type BulkInputDomainUserDicomField = {
  templateId: string;
  value: string;
};
export type BulkDomainUsersUpdateModal_bulkUpdateMutation$variables = {
  input: BulkUpdateDomainUsersInput;
};
export type BulkDomainUsersUpdateModal_bulkUpdateMutation$data = {
  readonly bulkUpdateDomainUsers: {
    readonly failures: ReadonlyArray<{
      readonly code: string | null;
      readonly detail: string | null;
      readonly email: string | null;
      readonly statusCode: number | null;
    }>;
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly canFinalize: boolean | null;
          readonly canQa: boolean | null;
          readonly dicomFields: ReadonlyArray<{
            readonly dicomFieldTemplate: {
              readonly label: string | null;
            } | null;
            readonly value: string | null;
          }>;
          readonly email: string | null;
          readonly id: string;
          readonly integrationConfigs: ReadonlyArray<{
            readonly ehr: {
              readonly handle: string | null;
            } | null;
            readonly interfaceCode: string | null;
          }>;
          readonly integrationDisplayNameFirst: string | null;
          readonly integrationDisplayNameLast: string | null;
          readonly integrationDisplayNameMiddle: string | null;
          readonly organizations: ReadonlyArray<{
            readonly name: string | null;
          } | null> | null;
          readonly role: {
            readonly name: string | null;
          } | null;
          readonly type: DomainUserType | null;
          readonly updatedAt: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type BulkDomainUsersUpdateModal_bulkUpdateMutation = {
  response: BulkDomainUsersUpdateModal_bulkUpdateMutation$data;
  variables: BulkDomainUsersUpdateModal_bulkUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrationDisplayNameFirst",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrationDisplayNameMiddle",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integrationDisplayNameLast",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canQa",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canFinalize",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interfaceCode",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = [
  (v14/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "BulkDomainUserUpdateFailure",
  "kind": "LinkedField",
  "name": "failures",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = [
  (v14/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkDomainUsersUpdateModal_bulkUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdateDomainUsersPayload",
        "kind": "LinkedField",
        "name": "bulkUpdateDomainUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainUserDicomField",
                        "kind": "LinkedField",
                        "name": "dicomFields",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DomainDicomFieldTemplate",
                            "kind": "LinkedField",
                            "name": "dicomFieldTemplate",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainUserIntegrationConfig",
                        "kind": "LinkedField",
                        "name": "integrationConfigs",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Ehr",
                            "kind": "LinkedField",
                            "name": "ehr",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MembershipRole",
                        "kind": "LinkedField",
                        "name": "role",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organizations",
                        "plural": true,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkDomainUsersUpdateModal_bulkUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdateDomainUsersPayload",
        "kind": "LinkedField",
        "name": "bulkUpdateDomainUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainUserDicomField",
                        "kind": "LinkedField",
                        "name": "dicomFields",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DomainDicomFieldTemplate",
                            "kind": "LinkedField",
                            "name": "dicomFieldTemplate",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainUserIntegrationConfig",
                        "kind": "LinkedField",
                        "name": "integrationConfigs",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Ehr",
                            "kind": "LinkedField",
                            "name": "ehr",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MembershipRole",
                        "kind": "LinkedField",
                        "name": "role",
                        "plural": false,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organizations",
                        "plural": true,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7affcaa5d020fe8ba6ecb283d87a3ae0",
    "id": null,
    "metadata": {},
    "name": "BulkDomainUsersUpdateModal_bulkUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation BulkDomainUsersUpdateModal_bulkUpdateMutation(\n  $input: BulkUpdateDomainUsersInput!\n) {\n  bulkUpdateDomainUsers(input: $input) {\n    users {\n      edges {\n        node {\n          id\n          email\n          integrationDisplayNameFirst\n          integrationDisplayNameMiddle\n          integrationDisplayNameLast\n          canQa\n          canFinalize\n          type\n          dicomFields {\n            dicomFieldTemplate {\n              label\n              id\n            }\n            value\n          }\n          integrationConfigs {\n            ehr {\n              handle\n              id\n            }\n            interfaceCode\n          }\n          role {\n            name\n            id\n          }\n          organizations {\n            name\n            id\n          }\n          updatedAt\n        }\n      }\n    }\n    failures {\n      email\n      detail\n      statusCode\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a1368f9ae850a15901b65fd650159b0";

export default node;
