import ArrowLeftIcon from '@bfly/icons/ArrowLeft';
import SearchIcon from '@bfly/icons/Search';
import BackArrowLink from '@bfly/ui2/BackArrowLink';
import Button from '@bfly/ui2/Button';
import useFocusManager from '@restart/hooks/useFocusManager';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import clsx from 'clsx';
import { useRouter } from 'found';
import { useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { useConfigRoutes } from 'routes/config';
import someRouteHasProperty from 'utils/someRouteHasProperty';

import ErrorBoundary from './ErrorBoundary';
import SearchBarGlobal, { SearchBarHandle } from './SearchBarGlobal';
import { AppSearchGlobal_organization$data as Organization } from './__generated__/AppSearchGlobal_organization.graphql';
import { AppSearchGlobal_searchNodes$data as SearchNodes } from './__generated__/AppSearchGlobal_searchNodes.graphql';
import { AppSearchGlobal_tenant$data as Tenant } from './__generated__/AppSearchGlobal_tenant.graphql';

const MAX_WIDTH = 620;

const transition = 'transition-all duration-300';

interface Props {
  tenant: Tenant;
  organization: Organization | null;
  searchNodes: SearchNodes | null;
  show?: boolean;
  autoFocus?: boolean;
  onBack?: () => void;
  onToggle?: (nextShow: boolean) => void;
  isSearchRoute?: boolean;
}

function AppSearchGlobal({
  tenant,
  organization,
  searchNodes,
  show,
  autoFocus = false,
  onToggle,
  onBack,
  isSearchRoute,
}: Props) {
  const searchRef = useRef<SearchBarHandle>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const routes = useConfigRoutes();
  const { match } = useRouter();
  const hideSearch = !someRouteHasProperty(match, 'hideSearch');

  useMountEffect(() => {
    const container = containerRef.current;
    if (autoFocus) {
      searchRef.current?.focus();
    }
    if (show && container) {
      container.style.left = '0px';
      container.classList.add('fixed', 'transition-all');
    }
  });

  useUpdateEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const { offsetLeft } = container.parentElement!;
    if (show) {
      // Set initial positions before transition
      container.style.left = `${offsetLeft}px`;
      container.classList.add('transition-all', 'fixed');

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions -- force reflow
      container.offsetHeight;
      // trigger transition
      container.style.left = '0px';
    } else {
      container.style.left = `${offsetLeft}px`;
    }
  }, [show]);

  function handleTransitionEnd(e: React.TransitionEvent) {
    const container = containerRef.current!;

    // don't respond to bubbled transitions
    if (e.target !== container) {
      return;
    }

    if (show) {
      searchRef.current?.focus();
    } else {
      // set back to relative without transitions
      container.classList.remove('transition-all', 'fixed');
      container.style.left = '';
    }
  }

  const { onBlur: handleOnBlur, onFocus: handleOnFocus } = useFocusManager({
    isDisabled: () => false,
    onChange: (isFocused) => !isFocused && onToggle?.(false),
  });

  const handleKeyUp = ({ key }: React.KeyboardEvent<HTMLDivElement>) =>
    key === 'Escape' && onToggle?.(false);

  return (
    <div
      data-bni-id="GlobalSearch"
      className="w-px relative flex h-10"
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onKeyUp={handleKeyUp}
    >
      <div
        className="flex z-10 h-10 duration-300"
        style={{ width: MAX_WIDTH, maxWidth: '100vw' }}
        onTransitionEnd={handleTransitionEnd}
        ref={containerRef}
      >
        <div
          className={clsx(
            `flex items-center bg-grey-85  justify-center overflow-hidden shrink-0 ${transition}`,
            !show && 'opacity-0 invisible w-0',
          )}
        >
          {isSearchRoute ? (
            <BackArrowLink
              data-bni-id="GlobalSearchBackArrow"
              to={routes.rootRoute()}
              onClick={() => onBack?.()}
              className="px-app-panel-sm"
            />
          ) : (
            <Button
              data-bni-id="GlobalSearchClose"
              variant="text-secondary"
              className="px-app-panel-sm"
              onClick={() => onBack?.()}
            >
              <ArrowLeftIcon className="h-5 w-5" />
            </Button>
          )}
        </div>
        {hideSearch && (
          <div
            className={clsx(
              `bg-grey-80 rounded flex items-center justify-center m-0 relative h-10 ${transition}`,
              show ? 'grow' : 'grow-0',
            )}
          >
            {show ? (
              <ErrorBoundary>
                <SearchBarGlobal
                  ref={searchRef}
                  tenant={tenant}
                  organization={organization}
                  searchNodes={searchNodes}
                  className="w-auto grow"
                />
              </ErrorBoundary>
            ) : (
              <Button
                data-bni-id="GlobalSearchIcon"
                variant="text-secondary"
                onClick={() => onToggle?.(true)}
                className="h-full"
              >
                <SearchIcon className="h-5 w-5" />
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default createFragmentContainer(AppSearchGlobal, {
  tenant: graphql`
    fragment AppSearchGlobal_tenant on TenantInterface {
      ...SearchBarGlobal_tenant
    }
  `,
  organization: graphql`
    fragment AppSearchGlobal_organization on Organization {
      ...SearchBarGlobal_organization
    }
  `,
  searchNodes: graphql`
    fragment AppSearchGlobal_searchNodes on Node @relay(plural: true) {
      ...SearchBarGlobal_searchNodes
    }
  `,
});
