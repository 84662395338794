/**
 * @generated SignedSource<<c0f79bd02264ae3436797b98b62438b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canMoveStudy$data = {
  readonly finalizedAt: string | null;
  readonly viewerCanMove: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "StudyPermissions_canMoveStudy";
};
export type StudyPermissions_canMoveStudy$key = {
  readonly " $data"?: StudyPermissions_canMoveStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canMoveStudy">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canMoveStudy"
};

(node as any).hash = "f60d54174f5304b83836e921156730ef";

export default node;
