/**
 * @generated SignedSource<<67ddc04d1faed88bfe8a671779706e2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppLeadingNav_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AppSearchGlobal_tenant">;
  readonly " $fragmentType": "AppLeadingNav_tenant";
};
export type AppLeadingNav_tenant$key = {
  readonly " $data"?: AppLeadingNav_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppLeadingNav_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppLeadingNav_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppSearchGlobal_tenant"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "1313db87c8ae29c5ef77d8690e886b09";

export default node;
