/**
 * @generated SignedSource<<e7d33ce560d5365e7f387d19aa0cf617>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyDataGrid_domain$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewStudyDataGridActions_domain">;
  readonly " $fragmentType": "StudyDataGrid_domain";
};
export type StudyDataGrid_domain$key = {
  readonly " $data"?: StudyDataGrid_domain$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyDataGrid_domain">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyDataGrid_domain",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewStudyDataGridActions_domain"
    }
  ],
  "type": "Domain",
  "abstractKey": null
};

(node as any).hash = "f63dee2fceca299b90e7174bd876d0a0";

export default node;
