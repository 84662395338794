/**
 * @generated SignedSource<<cc1ff1ba4588259db08b86648ee014cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyEndpointsDropdownItem_study$data = {
  readonly finalizedAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyEndpointsModal_study" | "StudyPermissions_canManageEndpoints">;
  readonly " $fragmentType": "StudyEndpointsDropdownItem_study";
};
export type StudyEndpointsDropdownItem_study$key = {
  readonly " $data"?: StudyEndpointsDropdownItem_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyEndpointsDropdownItem_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyEndpointsDropdownItem_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyEndpointsModal_study"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canManageEndpoints",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_studyOrArchiveIsDeleted",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "d4a01d6ffe45c5b68bf084266c3c7747";

export default node;
