import Layout from '@4c/layout';
import Calendar from '@bfly/icons/Calendar';
import ChevronRight from '@bfly/icons/ChevronRight';
import CloseSheet from '@bfly/icons/CloseSheet';
import Button from '@bfly/ui2/Button';
import Page, { PageLayout } from '@bfly/ui2/Page';
import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import clsx from 'clsx';
import { Link } from 'found';
import difference from 'lodash/difference';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';

import ErrorBoundary from 'components/ErrorBoundary';
import ExperiencesProvider from 'components/ExperiencesProvider';
import OutageMessage from 'components/OutageMessage';
import { useActiveRoute } from 'components/isRouteActive';
import { Experience, useMarkExperienceCompleted } from 'hooks/useExperiences';
import { useAdminRoutes } from 'routes/admin';
import { routes, useEducationRoutes } from 'routes/education';
import { useIsEnterpriseOrEducation } from 'utils/isEnterpriseOrEducation';
import { useViewerAllowMissingContext } from 'utils/viewerContext';

import educationMessages from '../messages/educationMessages';
import EducationAppHeader from './EducationAppHeader';
import LmsSsoBanner from './LmsSsoBanner';
import { EducationAppPageAssignedCoursesListQuery as ContentQuery } from './__generated__/EducationAppPageAssignedCoursesListQuery.graphql';
import { THREE_DAYS_FROM_TODAY } from './utils/dueDatesHelpers';

// This is a separate component so it can use the provided experiences context.
function MarkExperienceCompleted() {
  useMarkExperienceCompleted(Experience.VIEW_EDU_PAGE);
  return null;
}

interface Props {
  canGoBack: boolean;
  children: React.ReactNode;
  pageLayout?: PageLayout;
  center?: boolean;
}

export default function EducationAppPage({
  pageLayout,
  canGoBack,
  children,
  center,
}: Props) {
  const viewer = useViewerAllowMissingContext();
  const isRouteActive = useActiveRoute();

  const adminRoutes = useAdminRoutes({
    organizationSlug: viewer?.memberships![0]?.organization?.slug,
  });
  const educationRoutes = useEducationRoutes();
  const isAssignedToMeRoute = isRouteActive(routes.assignedCourses());
  const isEduMainRoute = isRouteActive(routes.education(), { exact: true });
  const isEduDashboardRoute = isRouteActive(routes.dashboard());
  const isViewerEnterpriseOrEducation = useIsEnterpriseOrEducation();

  const { data } = useQuery<ContentQuery>(
    graphql`
      query EducationAppPageAssignedCoursesListQuery(
        $skipAssignments: Boolean!
        $dueBefore: DateTime
      ) {
        assignmentsSortedByDueDate: educationContentConnection(
          first: 2147483647
          sort: [DUE_DATE_DESC]
          viewerIsAssigned: true
          viewerHasCompleted: false
          dueBefore: $dueBefore
        )
          @connection(key: "EducationAppPage_assignmentsSortedByDueDate")
          @skip(if: $skipAssignments) {
          edges {
            node {
              id
              course {
                dueDate
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: {
        skipAssignments: !isViewerEnterpriseOrEducation,
        dueBefore: THREE_DAYS_FROM_TODAY.toISOString(),
      },
    },
  );

  const haveSaveCoursesWithDueDate =
    viewer && localStorage.getItem(`ignoredCourses-${viewer.id}`);
  const savedCoursesWithDueDateIds =
    haveSaveCoursesWithDueDate && JSON.parse(haveSaveCoursesWithDueDate);
  const currentCoursesWithDueDateIds =
    data?.assignmentsSortedByDueDate &&
    getNodes(data?.assignmentsSortedByDueDate).map((item) => item.id);

  const [showDueDateBanner, setShowDueDateBanner] = useState<boolean>(false);

  useEffect(() => {
    setShowDueDateBanner(
      difference(
        currentCoursesWithDueDateIds,
        savedCoursesWithDueDateIds ?? [],
      ).length > 0,
    );
  }, [currentCoursesWithDueDateIds, savedCoursesWithDueDateIds]);

  const isBannerVisible =
    viewer?.shouldSeeNonPaidBanner ||
    viewer?.shouldSeeProCustomWithoutEduBanner ||
    viewer?.shouldSeeMembershipsExpiredBanner;

  const {
    shouldSeeNonPaidBanner,
    shouldSeeProCustomWithoutEduBanner,
    shouldSeeMembershipsExpiredBanner,
  } = viewer || {};
  const bannerText = useMemo(() => {
    if (shouldSeeNonPaidBanner) {
      return educationMessages.nonPaidBanner;
    }
    if (shouldSeeProCustomWithoutEduBanner) {
      return educationMessages.proCustomWithoutEduBanner;
    }
    if (shouldSeeMembershipsExpiredBanner) {
      return educationMessages.membershipsExpiredBanner;
    }
    return false;
  }, [
    shouldSeeMembershipsExpiredBanner,
    shouldSeeNonPaidBanner,
    shouldSeeProCustomWithoutEduBanner,
  ]);

  return (
    <ExperiencesProvider>
      <Page layout={pageLayout}>
        <EducationAppHeader canGoBack={canGoBack} />
        {isBannerVisible && (
          <Page.Banner
            variant="expired"
            className={clsx(
              'text-center',
              isEduMainRoute ? 'relative z-0' : 'z-50',
            )}
            data-bni-id="lmsLoginUnpaidBanner"
          >
            <Link
              to={adminRoutes.organizationSubscription()}
              className="flex items-center mx-auto"
            >
              <Text className="mr-2" color="headline">
                {!!bannerText && <FormattedMessage {...bannerText} />}
              </Text>
              <ChevronRight width={6} height={12} className="text-headline" />
            </Link>
          </Page.Banner>
        )}
        {showDueDateBanner && !isAssignedToMeRoute && (
          <Page.Banner
            className={clsx(
              'flex text-center bg-edu-grey border-b border-b-grey-80 justify-center',
              isEduMainRoute ? 'relative z-0' : 'z-50',
            )}
            data-bni-id="dueDatesBanner"
          >
            <Layout>
              <Calendar width={16} height={16} className="text-white" />
              <Text className="font-bold ml-2" color="headline">
                <FormattedMessage
                  {...{
                    ...educationMessages.dueDateBanner,
                    values: {
                      count: data?.assignmentsSortedByDueDate?.edges?.length,
                    },
                  }}
                />
              </Text>
              <Link to={educationRoutes.assignedCourses()}>
                <Text className="ml-2 font-bold" color="primary">
                  <FormattedMessage
                    {...{
                      ...educationMessages.viewCourse,
                      values: {
                        count: data?.assignmentsSortedByDueDate?.edges?.length,
                      },
                    }}
                  />
                </Text>
              </Link>
            </Layout>
            <Button
              data-bni-id="closeDueDateBanner"
              variant="text-secondary"
              className="absolute right-6"
              onClick={() => {
                setShowDueDateBanner(false);
                localStorage.setItem(
                  `ignoredCourses-${viewer?.id}`,
                  JSON.stringify(currentCoursesWithDueDateIds),
                );
              }}
            >
              <CloseSheet width={10} />
            </Button>
          </Page.Banner>
        )}
        <Page.Container center={center}>
          <Page.Main>
            {isEduDashboardRoute && viewer && <LmsSsoBanner />}
            <ErrorBoundary
              fallbackComponent={<OutageMessage className="m-4" />}
            >
              {children}
            </ErrorBoundary>
          </Page.Main>
        </Page.Container>
      </Page>
      <MarkExperienceCompleted />
    </ExperiencesProvider>
  );
}
