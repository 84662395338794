import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import CheckGroupSelectButton, {
  CheckGroupSelectButtonHOCProps,
} from 'components/CheckGroupSelectButton';

import { WorksheetSelectButton_tenant$data as Tenant } from './__generated__/WorksheetSelectButton_tenant.graphql';

type Worksheet = ConnectionNodeType<
  NonNullable<Tenant>,
  'worksheetTemplateConnection'
>;

interface Props extends CheckGroupSelectButtonHOCProps<Worksheet> {
  tenant: Tenant;
  value: string[];
}

function WorksheetSelectButton({ tenant, placeholder, ...props }: Props) {
  return (
    <CheckGroupSelectButton<Worksheet>
      dataKey="id"
      {...props}
      hideSelectAll
      placeholder={
        placeholder || (
          <FormattedMessage
            id="worksheetSelectButton.placeholder"
            defaultMessage="All worksheets"
          />
        )
      }
      data={getNodes(tenant?.worksheetTemplateConnection) as Worksheet[]}
      textKey={(item) => (item as Worksheet).latestVersion?.title || ''}
    />
  );
}

export default createFragmentContainer(WorksheetSelectButton, {
  tenant: graphql`
    fragment WorksheetSelectButton_tenant on TenantInterface {
      worksheetTemplateConnection(first: 11000) {
        edges {
          node {
            id
            handle
            latestVersion {
              title
            }
          }
        }
      }
    }
  `,
});
