import useMountEffect from '@restart/hooks/useMountEffect';
import { createFragmentContainer, graphql } from 'react-relay';

import LoadingIndicatorPage from 'components/LoadingIndicatorPage';
import { RoutePageProps } from 'components/Route';
import ExternalRedirect from 'utils/ExternalRedirect';

import type { ReferredPage_referrerInfo$data as ReferrerInfo } from './__generated__/ReferredPage_referrerInfo.graphql';

interface Props extends RoutePageProps {
  referrerInfo: ReferrerInfo;
}

function ReferredPage({ referrerInfo, match }: Props) {
  useMountEffect(() => {
    const engagementMedium = match.location.query.engagement_medium;
    const { shareLinkEmail, shareLinkMobile } = referrerInfo;

    ExternalRedirect.redirect(
      engagementMedium?.toLowerCase() === 'mobile'
        ? shareLinkMobile!
        : shareLinkEmail!,
    );
  });

  return <LoadingIndicatorPage />;
}

export default createFragmentContainer(ReferredPage, {
  referrerInfo: graphql`
    fragment ReferredPage_referrerInfo on ReferrerInfo {
      shareLinkEmail
      shareLinkMobile
    }
  `,
});
