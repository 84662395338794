/**
 * @generated SignedSource<<2544d110db077fe2a0330ea348098346>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantMembershipAutoCompleteQuery$variables = {
  organizationSlug?: string | null;
  search?: string | null;
};
export type TenantMembershipAutoCompleteQuery$data = {
  readonly tenant: {
    readonly membershipConnection?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly email: string | null;
          readonly userProfile: {
            readonly handle: string | null;
            readonly id: string;
            readonly name: string | null;
          } | null;
          readonly " $fragmentSpreads": FragmentRefs<"UserSuggestionListItem_userInfo">;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type TenantMembershipAutoCompleteQuery = {
  response: TenantMembershipAutoCompleteQuery$data;
  variables: TenantMembershipAutoCompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "userProfile",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "kind": "InlineDataFragmentSpread",
    "name": "UserSuggestionListItem_userInfo",
    "selections": [
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserSuggestion_userProfile"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "type": "UserInfoInterface",
        "abstractKey": "__isUserInfoInterface"
      }
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
],
v8 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v3/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "userProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Avatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Thumbnail",
                    "kind": "LinkedField",
                    "name": "thumbnails",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ThumbnailedInterface",
                "abstractKey": "__isThumbnailedInterface"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "UserInfoInterface",
    "abstractKey": "__isUserInfoInterface"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantMembershipAutoCompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "OrganizationMembershipConnection",
                "kind": "LinkedField",
                "name": "membershipConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DomainMembershipConnection",
                "kind": "LinkedField",
                "name": "membershipConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Domain",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TenantMembershipAutoCompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "OrganizationMembershipConnection",
                "kind": "LinkedField",
                "name": "membershipConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DomainMembershipConnection",
                "kind": "LinkedField",
                "name": "membershipConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Domain",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d835b812fff112d06e11dad4910c9ec2",
    "id": null,
    "metadata": {},
    "name": "TenantMembershipAutoCompleteQuery",
    "operationKind": "query",
    "text": "query TenantMembershipAutoCompleteQuery(\n  $organizationSlug: String\n  $search: String\n) {\n  tenant(slug: $organizationSlug) {\n    __typename\n    ... on Organization {\n      membershipConnection(first: 30, search: $search) {\n        edges {\n          node {\n            userProfile {\n              id\n              name\n              handle\n            }\n            email\n            ...UserSuggestionListItem_userInfo\n            id\n          }\n        }\n      }\n    }\n    ... on Domain {\n      membershipConnection(first: 30, search: $search) {\n        edges {\n          node {\n            userProfile {\n              id\n              name\n              handle\n            }\n            email\n            ...UserSuggestionListItem_userInfo\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n\nfragment UserSuggestionListItem_userInfo on UserInfoInterface {\n  __isUserInfoInterface: __typename\n  userProfile {\n    name\n    ...UserSuggestion_userProfile\n    id\n  }\n  email\n}\n\nfragment UserSuggestion_userProfile on UserProfile {\n  name\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "9d063a1045cd6ac4d0293decc54088ff";

export default node;
