/**
 * @generated SignedSource<<abc9fd024ccc7c2cd4c9801978a86440>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamPage_organization$data = {
  readonly slug: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionExpiredBanner_organization">;
  readonly " $fragmentType": "ExamPage_organization";
};
export type ExamPage_organization$key = {
  readonly " $data"?: ExamPage_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPage_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPage_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubscriptionExpiredBanner_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7ef079f5cfdf3a8f17944f57940ea382";

export default node;
