import * as Sentry from '@sentry/browser';
import platform from 'platform';

import { AnalyticsEvent } from './AnalyticsEvent.d';

export type { AnalyticsEvent };

declare const window: Window & {
  analytics: SegmentAnalytics.AnalyticsJS | undefined;
};

// Wrap the analytics library to reliably avoid leaking query parameters.

export function getRedactedUrl(url: string) {
  return url.split('?')[0];
}

function getRedactedPageContext() {
  return {
    search: '[redacted]',
    url: getRedactedUrl(window.location.href),
    referrer: getRedactedUrl(document.referrer),
  };
}

function redactOptions(options: { context?: any }) {
  // eslint-disable-next-line no-param-reassign
  options.context = options.context || {};
  const { context } = options;
  context.page = {
    ...context.page,
    ...getRedactedPageContext(),
  };
  context.os = {
    name: platform.name,
    version: platform.version,
  };
  return options;
}

function identify(localId: string, email: string | null) {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: localId, email: email || undefined });
  });

  window.analytics?.identify(localId, { email }, redactOptions({}));
}

function track(
  event: AnalyticsEvent,
  properties: Record<string, unknown>,
  options: SegmentAnalytics.SegmentOpts = {},
) {
  window.analytics?.track(event, properties, redactOptions(options));
}

function page(
  properties: Record<string, unknown>,
  options: SegmentAnalytics.SegmentOpts = {},
) {
  window.analytics?.page(properties, redactOptions(options));
}

page({ virtual: false });

export default {
  identify,
  track,
  page,
};
