import { ReactElement, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import useModalState from 'hooks/useModalState';
import type { StudyItem } from 'hooks/useMoveStudies';

import CreateArchiveModal from './CreateArchiveModal';
import StudiesDropTarget from './StudiesDropTarget';
import { CreateArchiveControl_organization$data as Organization } from './__generated__/CreateArchiveControl_organization.graphql';

function CreateArchiveControl({
  organization,
  children,
}: {
  organization: Organization;
  children: (renderProps: { onClick: () => void }) => ReactElement;
}) {
  const [showModal, modalProps] = useModalState();
  const [initialStudies, setInitialStudies] = useState<StudyItem[] | null>(
    null,
  );

  const onStudiesDropped = (studyAndArchiveIds: StudyItem[]) => {
    setInitialStudies(studyAndArchiveIds);
    showModal();
  };

  if (!modalProps.show && initialStudies) {
    setInitialStudies(null);
  }

  return (
    <>
      <StudiesDropTarget
        onStudiesDropped={onStudiesDropped}
        dataKey="study-items-can-move"
      >
        {children({ onClick: showModal })}
      </StudiesDropTarget>
      <CreateArchiveModal
        {...modalProps}
        organization={organization}
        initialStudies={initialStudies}
      />
    </>
  );
}

export default createFragmentContainer(CreateArchiveControl, {
  organization: graphql`
    fragment CreateArchiveControl_organization on Organization {
      ...CreateArchiveModal_organization
    }
  `,
});
