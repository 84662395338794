/**
 * @generated SignedSource<<dc99e1aad483185731a50b08f05b250a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyImageCommentPostedOnImageSubscriptionInput = {
  clientSubscriptionId?: string | null;
  imageId: any;
};
export type ExamImagePageFooter_CommentPostedOnImageSubscription$variables = {
  input: StudyImageCommentPostedOnImageSubscriptionInput;
};
export type ExamImagePageFooter_CommentPostedOnImageSubscription$data = {
  readonly studyImageCommentPostedOnImage: {
    readonly studyImageCommentEdge: {
      readonly node: {
        readonly image: {
          readonly numComments: number | null;
          readonly study: {
            readonly numImageComments: number | null;
          } | null;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"CommentListItem_comment" | "CommentPreview_comments">;
      } | null;
    } | null;
  } | null;
};
export type ExamImagePageFooter_CommentPostedOnImageSubscription = {
  response: ExamImagePageFooter_CommentPostedOnImageSubscription$data;
  variables: ExamImagePageFooter_CommentPostedOnImageSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numImageComments",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numComments",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamImagePageFooter_CommentPostedOnImageSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyImageCommentPostedOnImageSubscriptionPayload",
        "kind": "LinkedField",
        "name": "studyImageCommentPostedOnImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyImageCommentEdge",
            "kind": "LinkedField",
            "name": "studyImageCommentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyImageComment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyImage",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Study",
                        "kind": "LinkedField",
                        "name": "study",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CommentPreview_comments"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CommentListItem_comment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamImagePageFooter_CommentPostedOnImageSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyImageCommentPostedOnImageSubscriptionPayload",
        "kind": "LinkedField",
        "name": "studyImageCommentPostedOnImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyImageCommentEdge",
            "kind": "LinkedField",
            "name": "studyImageCommentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyImageComment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyImage",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Study",
                        "kind": "LinkedField",
                        "name": "study",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Avatar",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Thumbnail",
                                "kind": "LinkedField",
                                "name": "thumbnails",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "width",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ThumbnailedInterface",
                            "abstractKey": "__isThumbnailedInterface"
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "mentions",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerCanDelete",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa22beca6751aef349832aa67106506f",
    "id": null,
    "metadata": {},
    "name": "ExamImagePageFooter_CommentPostedOnImageSubscription",
    "operationKind": "subscription",
    "text": "subscription ExamImagePageFooter_CommentPostedOnImageSubscription(\n  $input: StudyImageCommentPostedOnImageSubscriptionInput!\n) {\n  studyImageCommentPostedOnImage(input: $input) {\n    studyImageCommentEdge {\n      node {\n        image {\n          study {\n            numImageComments\n            id\n          }\n          numComments\n          id\n        }\n        ...CommentPreview_comments\n        ...CommentListItem_comment\n        id\n      }\n    }\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment CommentAuthor_comment on StudyImageComment {\n  createdBy {\n    ...Avatar_userProfile\n    name\n    id\n  }\n}\n\nfragment CommentBody_comment on StudyImageComment {\n  body\n  mentions {\n    handle\n    ...CommentMention_userProfile\n    id\n  }\n}\n\nfragment CommentListItem_comment on StudyImageComment {\n  createdBy {\n    ...Avatar_userProfile\n    name\n    id\n  }\n  id\n  createdAt\n  viewerCanDelete\n  ...CommentAuthor_comment\n  ...CommentBody_comment\n  ...DeleteStudyImageCommentControl_comment\n  ...CommentListItem_isCommentAuthor\n}\n\nfragment CommentListItem_isCommentAuthor on StudyImageComment {\n  createdBy {\n    id\n  }\n}\n\nfragment CommentMention_userProfile on UserProfile {\n  name\n}\n\nfragment CommentPreview_comments on StudyImageComment {\n  ...CommentAuthor_comment\n  ...CommentBody_comment\n}\n\nfragment DeleteStudyImageCommentControl_comment on StudyImageComment {\n  id\n  image {\n    id\n  }\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa1e01421f8789c97a19b4c2dcd79e62";

export default node;
