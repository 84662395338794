import CommentBubbleIcon from '@bfly/icons/CommentBubble';
import Dropdown from '@bfly/ui2/Dropdown';
import Spinner from '@bfly/ui2/Spinner';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useQuery from '@bfly/ui2/useQuery';
import useToast from '@bfly/ui2/useToast';
import getNodes from '@bfly/utils/getNodes';
import useParams from 'found/useParams';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';

import { FollowImageDropdownItemQuery } from './__generated__/FollowImageDropdownItemQuery.graphql';
import { FollowImageDropdownItem_FollowMutation as FollowMutation } from './__generated__/FollowImageDropdownItem_FollowMutation.graphql';
import { FollowImageDropdownItem_ImageQuery as ImageQuery } from './__generated__/FollowImageDropdownItem_ImageQuery.graphql';
import { FollowImageDropdownItem_UnfollowMutation as UnfollowMutation } from './__generated__/FollowImageDropdownItem_UnfollowMutation.graphql';

const followMutation = graphql`
  mutation FollowImageDropdownItem_FollowMutation(
    $input: FollowStudyImageInput!
  ) {
    followStudyImageOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
      ... on FollowStudyImagePayload {
        studyImage {
          viewerIsFollowing
        }
      }
    }
  }
`;

const unfollowMutation = graphql`
  mutation FollowImageDropdownItem_UnfollowMutation(
    $input: UnfollowStudyImageInput!
  ) {
    unfollowStudyImageOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
      ... on UnfollowStudyImagePayload {
        studyImage {
          viewerIsFollowing
        }
      }
    }
  }
`;

function FollowImageDropdownItem() {
  const toast = useToast();
  const { studyImageHandle, studyHandle } = useParams();

  // If there's no image handle in the URL, fetch the first one(using study handle) as a default
  const { data: defaultImageData } = useQuery<ImageQuery>(
    graphql`
      query FollowImageDropdownItem_ImageQuery($studyHandle: String!) {
        study(handle: $studyHandle) {
          handle
          imageConnection(first: 1) {
            edges {
              node {
                id
                handle
                viewerIsFollowing
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-or-network',
      skip: !!studyImageHandle,
      variables: {
        studyHandle,
      },
    },
  );
  const defaultImage =
    defaultImageData &&
    getNodes(defaultImageData?.study?.imageConnection)?.[0];

  const imageHandle = studyImageHandle || defaultImage?.handle;

  const { data } = useQuery<FollowImageDropdownItemQuery>(
    graphql`
      query FollowImageDropdownItemQuery($studyImageHandle: String!) {
        studyImage(handle: $studyImageHandle) {
          id
          viewerIsFollowing
        }
      }
    `,
    {
      variables: { studyImageHandle: imageHandle! },
      skip: !studyImageHandle,
      fetchPolicy: 'store-or-network',
    },
  );
  const viewerIsFollowing =
    data?.studyImage?.viewerIsFollowing || defaultImage?.viewerIsFollowing;
  const imageId = data?.studyImage?.id || defaultImage?.id;

  const [follow] = useMutationWithError<FollowMutation>(followMutation, {
    input: { imageId },
    onCompleted: () => {
      toast.success(
        <FormattedMessage
          id="followImageDropdownItem.follow.success"
          defaultMessage="Followed image"
        />,
      );
    },
    onError: (error) => {
      toast.error(
        <FormattedMessage
          id="followImageDropdownItem.follow.failure"
          defaultMessage="Failed to follow image"
        />,
      );

      throw error;
    },
  });

  const [unfollow] = useMutationWithError<UnfollowMutation>(unfollowMutation, {
    input: {
      imageId,
    },
    onCompleted: () => {
      toast.success(
        <FormattedMessage
          id="followImageDropdownItem.unfollow.success"
          defaultMessage="Unfollowed image"
        />,
      );
    },
    onError: (error) => {
      toast.error(
        <FormattedMessage
          id="followImageDropdownItem.unfollow.failure"
          defaultMessage="Failed to unfollow image"
        />,
      );

      throw error;
    },
  });

  const message = viewerIsFollowing ? (
    <FormattedMessage
      id="followImageDropdownItem.following"
      defaultMessage="Unfollow comments"
    />
  ) : (
    <FormattedMessage
      id="followImageDropdownItem.follow"
      defaultMessage="Follow comments"
    />
  );

  return (
    <Dropdown.Item
      icon={<CommentBubbleIcon />}
      onClick={() => (viewerIsFollowing ? unfollow() : follow())}
      disabled={!imageHandle}
      className="h-10"
    >
      {imageHandle ? message : <Spinner size="sm" />}
    </Dropdown.Item>
  );
}

export default FollowImageDropdownItem;
