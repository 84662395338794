/**
 * @generated SignedSource<<ca1bfd8491e3acccd269d1ab8ae712e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberSelectButton_MembersSearch_Query$variables = {
  isDeleted: ReadonlyArray<boolean>;
  search?: string | null;
  tenantId: string;
};
export type MemberSelectButton_MembersSearch_Query$data = {
  readonly tenant: {
    readonly membershipConnection?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly email: string | null;
          readonly userProfile: {
            readonly handle: string | null;
            readonly id: string;
            readonly name: string | null;
          } | null;
          readonly " $fragmentSpreads": FragmentRefs<"UserSuggestionListItem_userInfo">;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type MemberSelectButton_MembersSearch_Query = {
  response: MemberSelectButton_MembersSearch_Query$data;
  variables: MemberSelectButton_MembersSearch_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDeleted"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "userProfile",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = [
  (v7/*: any*/),
  (v8/*: any*/),
  {
    "kind": "InlineDataFragmentSpread",
    "name": "UserSuggestionListItem_userInfo",
    "selections": [
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserSuggestion_userProfile"
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "type": "UserInfoInterface",
        "abstractKey": "__isUserInfoInterface"
      }
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  },
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "kind": "Variable",
  "name": "isDeleted",
  "variableName": "isDeleted"
},
v14 = {
  "kind": "Literal",
  "name": "first",
  "value": 30
},
v15 = [
  (v14/*: any*/),
  (v4/*: any*/)
],
v16 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v5/*: any*/),
  (v9/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "userProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Avatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Thumbnail",
                    "kind": "LinkedField",
                    "name": "thumbnails",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ThumbnailedInterface",
                "abstractKey": "__isThumbnailedInterface"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "UserInfoInterface",
    "abstractKey": "__isUserInfoInterface"
  }
],
v17 = [
  (v14/*: any*/),
  (v13/*: any*/),
  (v4/*: any*/)
],
v18 = {
  "count": null,
  "cursor": null,
  "direction": "forward",
  "path": [
    "tenant",
    "membershipConnection"
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberSelectButton_MembersSearch_Query",
    "selections": [
      {
        "alias": "tenant",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "membershipConnection",
                "args": [
                  (v4/*: any*/)
                ],
                "concreteType": "DomainMembershipConnection",
                "kind": "LinkedField",
                "name": "__MemberSelectButtonDomain_membershipConnection_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Domain",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "membershipConnection",
                "args": [
                  (v13/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "OrganizationMembershipConnection",
                "kind": "LinkedField",
                "name": "__MemberSelectButtonOrganization_membershipConnection_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MemberSelectButton_MembersSearch_Query",
    "selections": [
      {
        "alias": "tenant",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "DomainMembershipConnection",
                "kind": "LinkedField",
                "name": "membershipConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "filters": [
                  "search"
                ],
                "handle": "connection",
                "key": "MemberSelectButtonDomain_membershipConnection",
                "kind": "LinkedHandle",
                "name": "membershipConnection"
              }
            ],
            "type": "Domain",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v17/*: any*/),
                "concreteType": "OrganizationMembershipConnection",
                "kind": "LinkedField",
                "name": "membershipConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v17/*: any*/),
                "filters": [
                  "search",
                  "isDeleted"
                ],
                "handle": "connection",
                "key": "MemberSelectButtonOrganization_membershipConnection",
                "kind": "LinkedHandle",
                "name": "membershipConnection"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ebc83a574379d2084b6ca518ba94297",
    "id": null,
    "metadata": {
      "connection": [
        (v18/*: any*/),
        (v18/*: any*/)
      ]
    },
    "name": "MemberSelectButton_MembersSearch_Query",
    "operationKind": "query",
    "text": "query MemberSelectButton_MembersSearch_Query(\n  $tenantId: ID!\n  $search: String\n  $isDeleted: [Boolean!]!\n) {\n  tenant: node(id: $tenantId) {\n    __typename\n    ... on Domain {\n      membershipConnection(first: 30, search: $search) {\n        edges {\n          node {\n            userProfile {\n              id\n              name\n              handle\n            }\n            email\n            ...UserSuggestionListItem_userInfo\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    ... on Organization {\n      membershipConnection(first: 30, search: $search, isDeleted: $isDeleted) {\n        edges {\n          node {\n            userProfile {\n              id\n              name\n              handle\n            }\n            email\n            ...UserSuggestionListItem_userInfo\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n\nfragment UserSuggestionListItem_userInfo on UserInfoInterface {\n  __isUserInfoInterface: __typename\n  userProfile {\n    name\n    ...UserSuggestion_userProfile\n    id\n  }\n  email\n}\n\nfragment UserSuggestion_userProfile on UserProfile {\n  name\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "1c06054d6eda223d35fd807bec996ca0";

export default node;
