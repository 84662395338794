/**
 * @generated SignedSource<<d4bba4cad4b202821c1438442b5dd453>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BulkDeleteDomainUsersInput = {
  clientMutationId?: string | null;
  domainId: string;
  users: ReadonlyArray<BulkInputDomainUser | null>;
};
export type BulkInputDomainUser = {
  canFinalize?: boolean | null;
  canQa?: boolean | null;
  dicomFields?: ReadonlyArray<BulkInputDomainUserDicomField> | null;
  email: string;
  integrationConfigs?: ReadonlyArray<BulkInputDomainUserIntegrationConfig> | null;
  integrationDisplayNameFirst?: string | null;
  integrationDisplayNameLast?: string | null;
  integrationDisplayNameMiddle?: string | null;
  isNurse?: boolean | null;
  roleId?: string | null;
};
export type BulkInputDomainUserIntegrationConfig = {
  integrationId: string;
  interfaceCode: string;
};
export type BulkInputDomainUserDicomField = {
  templateId: string;
  value: string;
};
export type BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation$variables = {
  input: BulkDeleteDomainUsersInput;
};
export type BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation$data = {
  readonly bulkDeleteDomainUsers: {
    readonly failures: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"BulkDomainUsersDeactivateUsersResultsModal_failures">;
    }>;
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly email: string | null;
          readonly id: string;
          readonly integrationDisplayNameFirst: string | null;
          readonly integrationDisplayNameLast: string | null;
          readonly integrationDisplayNameMiddle: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation = {
  response: BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation$data;
  variables: BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DomainUserConnection",
  "kind": "LinkedField",
  "name": "users",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainUserEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainUser",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "integrationDisplayNameFirst",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "integrationDisplayNameLast",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "integrationDisplayNameMiddle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkDeleteDomainUsersPayload",
        "kind": "LinkedField",
        "name": "bulkDeleteDomainUsers",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BulkDomainUserDeleteFailure",
            "kind": "LinkedField",
            "name": "failures",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BulkDomainUsersDeactivateUsersResultsModal_failures"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkDeleteDomainUsersPayload",
        "kind": "LinkedField",
        "name": "bulkDeleteDomainUsers",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BulkDomainUserDeleteFailure",
            "kind": "LinkedField",
            "name": "failures",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "236bef4bca6ee56b15e609885cbace2d",
    "id": null,
    "metadata": {},
    "name": "BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation",
    "operationKind": "mutation",
    "text": "mutation BulkDomainUsersDeactivateUsersConfirmationModal_bulkDeactivateUsersMutation(\n  $input: BulkDeleteDomainUsersInput!\n) {\n  bulkDeleteDomainUsers(input: $input) {\n    users {\n      edges {\n        cursor\n        node {\n          id\n          email\n          integrationDisplayNameFirst\n          integrationDisplayNameLast\n          integrationDisplayNameMiddle\n        }\n      }\n    }\n    failures {\n      ...BulkDomainUsersDeactivateUsersResultsModal_failures\n    }\n  }\n}\n\nfragment BulkDomainUsersDeactivateUsersResultsModal_failures on BulkDomainUserDeleteFailure {\n  detail\n  code\n  email\n}\n"
  }
};
})();

(node as any).hash = "202e0bdccb1e02fff740046fd1c2a78a";

export default node;
