import React, { useContext } from 'react';

const HeaderHeightContext = React.createContext<{
  height: number | undefined;
}>(null as any);
export default HeaderHeightContext;

export function useHeaderHeightContext() {
  return useContext(HeaderHeightContext);
}
