/**
 * @generated SignedSource<<afec6dbf22d5ada7c625ee0686fdb20f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPageWithSidePanel_viewer$data = {
  readonly profile: {
    readonly handle: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_viewer">;
  readonly " $fragmentType": "AppPageWithSidePanel_viewer";
};
export type AppPageWithSidePanel_viewer$key = {
  readonly " $data"?: AppPageWithSidePanel_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppPageWithSidePanel_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPageWithSidePanel_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BaseAppPage_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "7c094e2e678ae7a82a765a3b4804fa0c";

export default node;
