import Button from '@bfly/ui2/Button';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import getNodes from '@bfly/utils/getNodes';
import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import { useGlobalListener } from '@restart/hooks';
import clsx from 'clsx';
import { useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';

import Avatar from 'components/Avatar';
import { Experience } from 'components/ExperiencesProvider';
import useExperiences from 'hooks/useExperiences';
import actionMessages from 'messages/actions';

import { CommentEditorHandle } from './CommentEditor';
import CommentForm from './CommentForm';
import CommentList, { CommentListHandle } from './CommentList';
import { ExamImageComments_PostCommentMutation as PostCommentMutation } from './__generated__/ExamImageComments_PostCommentMutation.graphql';
import { ExamImageComments_studyImage$data as StudyImage } from './__generated__/ExamImageComments_studyImage.graphql';
import { ExamImageComments_viewer$data as Viewer } from './__generated__/ExamImageComments_viewer.graphql';

// XXX: numImageComments gets around an edge case with data consistency. See
//  https://app.asana.com/0/381397475033997/667123260760187.
const postCommentMutation = graphql`
  mutation ExamImageComments_PostCommentMutation(
    $input: PostStudyImageCommentInput!
  ) {
    postStudyImageCommentOrError(input: $input) {
      ... on PostStudyImageCommentPayload {
        studyImageCommentEdge {
          node {
            image {
              study {
                numImageComments
              }
              numComments
              viewerIsFollowing
            }
            ...CommentPreview_comments
            ...CommentListItem_comment
          }
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  viewer: Viewer;
  gridArea?: string;
  onClose: () => void;
  studyImage: StudyImage;
}

function ExamImageComments({ viewer, gridArea, onClose, studyImage }: Props) {
  const listRef = useRef<CommentListHandle>(null);
  const formRef = useRef<CommentEditorHandle>(null);

  const { targetRef, markCompleted } = useExperiences(
    Experience.INTERACT_COMMENT_BOX,
    {
      placement: 'top-start',
      header: (
        <FormattedMessage
          id="ExamImageComments.post_comment.title"
          defaultMessage="Post a comment"
        />
      ),
      message: studyImage.organization!.subscription!.isTeam ? (
        <FormattedMessage
          id="ExamImageComments.post_comment.sub.team"
          defaultMessage="Invite a team member to comment by entering an “@“ followed by their name"
        />
      ) : (
        <FormattedMessage
          id="ExamImageComments.post_comment.sub.individual"
          defaultMessage="Comment or leave notes on studies you have uploaded to the Butterfly Cloud"
        />
      ),
    },
  );

  const [postComment, loading] = useMutationWithError<PostCommentMutation>(
    postCommentMutation,
    {
      updater: (store) => {
        rangeAddUpdater(store, {
          parentId: studyImage.id,
          connectionKey: 'StudyImage_commentConnection',
          rootFieldName: 'postStudyImageCommentOrError',
          edgeName: 'studyImageCommentEdge',
        });
      },
    },
  );

  const comments = getNodes(studyImage.commentConnection);

  const focusEditor = () => {
    if (formRef.current) formRef.current.focus();
  };

  const handlePostComment = useCallback(() => {
    markCompleted();
    if (listRef.current) {
      listRef.current.scrollToBottom();
    }
  }, [markCompleted]);

  useEffect(() => {
    setTimeout(() => {
      focusEditor();
    });
  }, []);

  useGlobalListener('keydown', (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  });

  return (
    <div
      ref={targetRef}
      className="absolute bottom-0 inset-x-0 px-1 x-10"
      style={{ gridArea }}
    >
      {!!comments.length && (
        <div className="bg-grey-85 border border-grey-80 border-b-0 rounded-t px-6 pt-6 max-w-screen-lg mx-auto">
          <CommentList ref={listRef} comments={comments} />
        </div>
      )}
      <div
        className={clsx(
          'bg-grey-85 px-6 py-4 border border-grey-80  mb-1 rounded-b flex items-start max-w-screen-lg mx-auto',
          !comments.length && 'rounded-t',
        )}
      >
        <Avatar userProfile={viewer.profile} width={24} />
        <CommentForm
          multiline
          image={studyImage}
          mutate={postComment}
          busy={loading}
          className="flex-grow w-full ml-3"
          onSubmit={handlePostComment}
          ref={formRef}
        />
        <Button onClick={onClose} variant="text-secondary">
          <FormattedMessage {...actionMessages.close} />
        </Button>
      </div>
    </div>
  );
}

export default createFragmentContainer(ExamImageComments, {
  viewer: graphql`
    fragment ExamImageComments_viewer on Viewer {
      profile {
        ...Avatar_userProfile
      }
    }
  `,
  studyImage: graphql`
    fragment ExamImageComments_studyImage on StudyImage {
      id
      organization {
        subscription {
          isTeam
        }
      }
      commentConnection(first: 2147483647)
        @connection(key: "StudyImage_commentConnection") {
        edges {
          node {
            ...CommentList_comments
          }
        }
      }
      ...CommentForm_image
    }
  `,
});
