import Layout from '@4c/layout';
import SearchIcon from '@bfly/icons/Search';
import BlankSlate from '@bfly/ui2/BlankSlate';
import Button from '@bfly/ui2/Button';
import Pagination from '@bfly/ui2/Pagination';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import notNullish from '@bfly/utils/notNullish';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import NProgress from 'components/NProgress';
import usePagedConnection from 'hooks/usePagedConnection';

import AnalyticsPageStickySection from './AnalyticsPageStickySection';
import StudyStatisticsGrid from './StudyStatisticsGrid';
import { AllTimeStatsUsersPaginationQuery } from './__generated__/AllTimeStatsUsersPaginationQuery.graphql';
import { AllTimeStatsUsers_studyStatisticsByAuthor$key as StudyStatisticsByAuthorKey } from './__generated__/AllTimeStatsUsers_studyStatisticsByAuthor.graphql';
import { AllTimeStatsUsers_tenant$data as Tenant } from './__generated__/AllTimeStatsUsers_tenant.graphql';

const PAGE_SIZE = 20;
interface AllTimeStatsUsersProps {
  tenant: Tenant;
}

function AllTimeStatsUsers({ tenant }: AllTimeStatsUsersProps) {
  const [rowsExpanded, setRowsExpanded] = useState(
    () => new Map<string, Set<string>>(),
  );

  const [
    studyStatisticsByAuthorConnection,
    { index, numEdges, loadNext, loadPrevious, fetching },
  ] = usePagedConnection<
    AllTimeStatsUsersPaginationQuery,
    StudyStatisticsByAuthorKey,
    'studyStatisticsByAuthorConnection'
  >(
    graphql`
      fragment AllTimeStatsUsers_studyStatisticsByAuthor on TenantInterface
      @refetchable(queryName: "AllTimeStatsUsersPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 20 }
        after: { type: "String" }
        filter: { type: "StudyStatisticsFilterInput!" }
      ) {
        studyStatisticsByAuthorConnection(
          first: $first
          after: $after
          filter: $filter
        ) @connection(key: "tenant_studyStatisticsByAuthorConnection") {
          index
          numEdges
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              ...StudyStatisticsGrid_studyStatisticsByAuthor
                @arguments(showWorksheetStats: false)
            }
          }
        }
      }
    `,
    tenant,
    {
      pageSize: PAGE_SIZE,
      overfetchNumPages: 0,
    },
  );

  const studyStatisticsByAuthor = studyStatisticsByAuthorConnection
    ? getNodes(studyStatisticsByAuthorConnection)
    : [];

  if (!studyStatisticsByAuthor.length) {
    return (
      <BlankSlate>
        <BlankSlate.Img as={SearchIcon} height="36" width="36" />
        <BlankSlate.Title>
          <FormattedMessage
            id="AllTimeStats.noResults"
            defaultMessage="No Results Found"
          />
        </BlankSlate.Title>
      </BlankSlate>
    );
  }

  return (
    <>
      <AnalyticsPageStickySection>
        <Layout pad={8} align="center" className="px-16 mt-10 mb-2">
          <Text variant="lg" className="text-headline">
            <FormattedMessage
              id="AllTimeStats.performanceIndicatorsByUser"
              defaultMessage="Performance indicators by user"
            />
          </Text>
          {!!rowsExpanded.size && (
            <Button
              aria-controls={studyStatisticsByAuthor
                .map((d) => d.id)
                .join(' ')}
              aria-expanded
              variant="text-secondary"
              className="font-normal underline text-grey-5 mr-2"
              onClick={() => setRowsExpanded(new Map())}
            >
              <FormattedMessage
                id="AllTimeStats.collapseAll"
                defaultMessage="Collapse all"
              />
            </Button>
          )}
          <Pagination
            data-bni-id="PaginationControls"
            index={index}
            onNextPage={loadNext}
            onPreviousPage={loadPrevious}
            pageSize={PAGE_SIZE}
            numItems={numEdges}
            className="mr-3"
          />
        </Layout>
      </AnalyticsPageStickySection>
      <div data-bni-id="AllTimeStatsStudyStatisticsGrid">
        <NProgress loading={fetching} />
        <StudyStatisticsGrid
          showUpdatedColumns
          viewportScrolling
          rowsExpanded={rowsExpanded}
          onRowExpand={setRowsExpanded}
          studyStatisticsByAuthor={studyStatisticsByAuthor.filter(notNullish)}
          fetching={fetching}
        />
      </div>
    </>
  );
}

export default createFragmentContainer(AllTimeStatsUsers, {
  tenant: graphql`
    fragment AllTimeStatsUsers_tenant on TenantInterface
    @argumentDefinitions(filter: { type: "StudyStatisticsFilterInput!" }) {
      ...AllTimeStatsUsers_studyStatisticsByAuthor @arguments(filter: $filter)
    }
  `,
});
