/**
 * @generated SignedSource<<d4aa9934556ecb58a2592423a9d705fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CredentialingProgressSection_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CredentialingProgressSection_credentialStatisticsByMemberConnection">;
  readonly " $fragmentType": "CredentialingProgressSection_tenant";
};
export type CredentialingProgressSection_tenant$key = {
  readonly " $data"?: CredentialingProgressSection_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CredentialingProgressSection_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "studyStatsFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userProgressSort"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CredentialingProgressSection_tenant",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "studyStatsFilter",
          "variableName": "studyStatsFilter"
        },
        {
          "kind": "Variable",
          "name": "userProgressSort",
          "variableName": "userProgressSort"
        }
      ],
      "kind": "FragmentSpread",
      "name": "CredentialingProgressSection_credentialStatisticsByMemberConnection"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "3bc1762162ed17f3c80bd4153e864f63";

export default node;
