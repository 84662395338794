import BlankSlate from '@bfly/ui2/BlankSlate';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import { stylesheet } from 'astroturf';
import useRouter from 'found/useRouter';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import localStore from 'store/dist/store.modern';

import actionMessages from 'messages/actions';

import schema, { Filters, serialize } from '../schemas/educationVideoFilters';
import CategoriesFormField from './CategoriesFormField';
import { EducationWelcomeModal_query$data as Query } from './__generated__/EducationWelcomeModal_query.graphql';

const VIEWED_KEY = 'bfly:hasViewedEducation';

const styles = stylesheet`
  @import '../education-variables';

  .categories {
    margin-top: 3rem;
  }

  .footer {
    composes: pt-6 from global;

    border-top: 1px solid var(--bni-grey-75);
  }

  // TODO move these styles to base Modal
  @screen sm-max {
    .modal [role="dialog"] {
        position: fixed;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        max-height: 100%;
        border-radius: 0;

        left: env(safe-area-inset-left);
        right: env(safe-area-inset-right);
        bottom: env(safe-area-inset-bottom);
        top: env(safe-area-inset-top);
      
    }

    .content {
      // Adds a slight shadow indicating scrollability
      background-image: linear-gradient(var(--bni-grey-5) 30%, hsla(0, 0%, 100%, 0)),
        linear-gradient(hsla(0, 0%, 100%, 0) 10px, var(--bni-grey-5) 70%),
        radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%),
        radial-gradient(at bottom, rgba(0, 0, 0, 0.2), transparent 70%);
      background-position-y: top, bottom, top, bottom;
      background-repeat: no-repeat;
      background-size: 100% 30px, 100% 15px, 100% 10px, 100% 5px;
      background-attachment: local, local, scroll, scroll;
    }

    .button {
      height: 4rem;
      border-radius: 0;
    }

    .skip {
      composes: py-2 from global;
      composes: button;
    }

    .footer {
      padding: 0;
      border-top: 0;
    }

    // Increase specificity to beat <Layout>.
    .button-group.button-group {
      flex-direction: column-reverse;
    }

    .title {
      font-size: $welcome-modal-title-size-sm;
    }
  }
`;

interface Props {
  query: Query;
}

function EducationWelcomeModal({ query }: Props) {
  const forceUpdate = useForceUpdate();

  const hide = localStore.get(VIEWED_KEY, false);

  const [filters, setFilters] = useState<Filters>({});
  const {
    router,
    match: { location },
  } = useRouter();

  const markAsViewed = () => {
    localStore.set(VIEWED_KEY, true);
    forceUpdate();
  };

  const handleChange = (val) => setFilters(val);

  const handleSubmit = () => {
    router.replace({
      ...location,
      query: serialize(filters),
    });

    markAsViewed();
  };

  return (
    <Modal
      show={!hide}
      onHide={markAsViewed}
      className={styles.modal}
      variant="dark"
    >
      <Modal.Body className={styles.content}>
        <BlankSlate>
          <BlankSlate.Title variant="display-lg">
            <FormattedMessage
              id="education.welcome.title"
              defaultMessage="Welcome to Butterfly Education"
            />
          </BlankSlate.Title>

          <BlankSlate.Body>
            <FormattedMessage
              id="education.welcome.subtitle"
              defaultMessage="Butterfly Education is a place to view informative educational videos which will help you make the most of your Butterfly iQ. Butterfly members will have access to all videos, while people without a membership will have access to select videos only."
            />
          </BlankSlate.Body>

          <BlankSlate.Body>
            <FormattedMessage
              id="education.welcome.selectMessage"
              defaultMessage="Select a category to get started."
            />
          </BlankSlate.Body>

          <Form
            schema={schema}
            onChange={handleChange}
            className={styles.categories}
          >
            <CategoriesFormField query={query} justify="center" theme="dark" />
          </Form>
        </BlankSlate>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Modal.ButtonGroup className={styles.buttonGroup}>
          <Button
            className={styles.button}
            onClick={handleSubmit}
            disabled={!filters.category || filters.category.length === 0}
          >
            <FormattedMessage {...actionMessages.next} />
          </Button>

          <Button variant="text-secondary" onClick={markAsViewed}>
            <FormattedMessage
              id="education.welcome.skip"
              defaultMessage="Skip"
            />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default createFragmentContainer(EducationWelcomeModal, {
  query: graphql`
    fragment EducationWelcomeModal_query on Query
    @argumentDefinitions(
      practiceType: { type: "[EducationVideoPracticeType!]" }
    ) {
      ...CategoriesFormField_query @arguments(practiceType: $practiceType)
    }
  `,
});
