import { type LocationDescriptor } from 'farce';
import { RouterState } from 'found';
import { type IsActiveOptions } from 'found/Matcher';
import useRouter from 'found/useRouter';

export const isRouteActive =
  ({ match, router }: RouterState<any>) =>
  (location: LocationDescriptor, options?: IsActiveOptions) => {
    return router.isActive(match, router.createLocation(location), options);
  };

export function useActiveRoute() {
  const routerState = useRouter();
  return isRouteActive(routerState);
}

export function useActiveRouteWithState(routerState: RouterState<any>) {
  return isRouteActive(routerState);
}
