import useQuery from '@bfly/ui2/useQuery';
import { graphql } from 'react-relay';

import { useViewerContext } from 'utils/viewerContext';

import { INDICATORS as messages } from '../messages';
import IndicatorImageQualityCard from './IndicatorImageQualityCard';
import { IndicatorRingChartCardByExamType } from './IndicatorRingChartCard';
import { EduStudiesPortfolioIndicators_Query as Query } from './__generated__/EduStudiesPortfolioIndicators_Query.graphql';

function useData() {
  const viewer = useViewerContext();

  const { data } = useQuery<Query>(
    graphql`
      query EduStudiesPortfolioIndicators_Query($author: [ID!]) {
        viewer {
          eduStudyStatistics(authors: $author) {
            ...IndicatorRingChartCard_byExamType @relay(mask: false)
            ...IndicatorImageQualityCard_imageQuality @relay(mask: false)
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: {
        author: [viewer.profile!.id],
      },
    },
  );

  return data?.viewer?.eduStudyStatistics || null;
}

export default function EduStudiesPortfolioIndicators() {
  const data = useData();

  return (
    <>
      <IndicatorRingChartCardByExamType
        className="w-80"
        id="PortfolioUnsubmittedExams"
        byExamTypeData={data?.byExamType}
        getValue={(row) => row.numUnsubmitted || 0}
        headerMessage={messages.unsubmittedExams}
      />
      <IndicatorRingChartCardByExamType
        className="w-80"
        id="PortfolioSubmittedExams"
        byExamTypeData={data?.byExamType}
        getValue={(row) => row.numReviewRequested || 0}
        headerMessage={messages.submittedExams}
      />
      <IndicatorImageQualityCard
        className="w-80"
        byQualityData={data?.imageQuality?.byQuality}
        id="PortfolioImageQuality"
      />
    </>
  );
}
