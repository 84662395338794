/**
 * @generated SignedSource<<cc82c5dbe3225c5fe6891f5bb3212d6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteCapturesModal_study$data = {
  readonly id: string;
  readonly " $fragmentType": "DeleteCapturesModal_study";
};
export type DeleteCapturesModal_study$key = {
  readonly " $data"?: DeleteCapturesModal_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteCapturesModal_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteCapturesModal_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "7046a8808f5338e947d9d965a01808a7";

export default node;
