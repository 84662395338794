import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Abbr from 'components/Abbr';
import messages, { sexMessages } from 'messages/patient';
import joinNonemptyNodes from 'utils/joinNonemptyNodes';

import { PatientInfo_patient$data as Patient } from './__generated__/PatientInfo_patient.graphql';

interface Props {
  patient: Patient | null;
  accessionNumber: string | null;
}

function PatientInfo({ patient, accessionNumber }: Props) {
  if (!patient) {
    return null;
  }

  const { birthDate, medicalRecordNumber, sex } = patient;

  return (
    <>
      {joinNonemptyNodes(', ', [
        sex && sex !== 'UNKNOWN' && (
          <React.Fragment key="sex">
            <FormattedMessage {...messages.sex} />:{' '}
            <FormattedMessage {...sexMessages[sex]} />
          </React.Fragment>
        ),
        birthDate && (
          <React.Fragment key="birthDate">
            <Abbr
              label={messages.dateOfBirthLabel}
              message={messages.birthDate}
            />
            :{' '}
            {/* Explicitly specify UTC here, as birthDate is a date (which will
              get interpreted as UTC), not a datetime. */}
            <FormattedDate value={patient.birthDate!} timeZone="UTC" />
          </React.Fragment>
        ),
        medicalRecordNumber && (
          <React.Fragment key="medicalRecordNumber">
            <Abbr
              label={messages.medicalRecordNumber}
              message={messages.medicalRecordNumber}
            />
            : {medicalRecordNumber}
          </React.Fragment>
        ),
        accessionNumber && (
          <React.Fragment key="accessionNumber">
            <Abbr label={messages.accessionNumberLabel}>
              <FormattedMessage
                id="study.accessionNumber"
                defaultMessage="Acc #"
              />
            </Abbr>
            : {accessionNumber}
          </React.Fragment>
        ),
      ])}
    </>
  );
}

export default createFragmentContainer(PatientInfo, {
  patient: graphql`
    fragment PatientInfo_patient on PatientInterface {
      sex
      birthDate
      medicalRecordNumber
    }
  `,
});
