/* eslint-disable @typescript-eslint/naming-convention */
import Button from '@bfly/ui2/Button';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import styled from 'astroturf/react';
import useRouter from 'found/useRouter';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import AuthContent from 'components/AuthContent';
import BlankAppPage from 'components/BlankAppPage';
import ButterflyLoginForm from 'components/ButterflyLoginForm';
import LoginSubtitle from 'components/LoginSubtitle';
import ExternalRedirect from 'utils/ExternalRedirect';
import { generateStateCsrf } from 'utils/csrfState';

import DomainNotFoundPage from './DomainNotFoundPage';
import { DomainLoginPage_domainProfile$data as DomainProfile } from './__generated__/DomainLoginPage_domainProfile.graphql';
import { DomainLoginPage_inviteInfo$data as InviteInfo } from './__generated__/DomainLoginPage_inviteInfo.graphql';

const SSO_SIGN_IN_INTENT_DELAY_MS = 3000;

const logInWithButterflyMessage = (
  <FormattedMessage
    id="domainLogin.loginWithButterfly"
    defaultMessage="Log In with Butterfly"
  />
);

const LoginButton = styled(Button).attrs({ size: 'lg' })`
  composes: w-full mb-3 from global;

  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &.theme-link {
    @apply mt-3;
  }
`;

function makeFederatedSsoLoginUrl({
  authorizationEndpointBaseUrl,
  clientId,
  ...stateParams
}: {
  authorizationEndpointBaseUrl: string;
  clientId: string;
  nextPath: string;
}) {
  const authParams = new URLSearchParams({
    response_type: 'token',
    client_id: clientId,
    redirect_uri: `${window.location.origin}/-/federated-auth/callback`,
    state: JSON.stringify({
      csrfState: generateStateCsrf(),
      ...stateParams,
    }),
    scope: 'openid',
  });
  return `${authorizationEndpointBaseUrl}?${authParams}`;
}

function ThirdPartyLoginButton({ onClick }: { onClick: () => void }) {
  return (
    <LoginButton variant="primary" onClick={onClick}>
      <FormattedMessage
        id="domainLogin.loginWithSso"
        defaultMessage="Log In with SSO"
      />
    </LoginButton>
  );
}

function LoginPicker({
  domainProfile,
  inviteInfo,
  onClickButterflySignUp,
  onClickButterflyLogin,
  onClickThirdPartyLogin,
}: {
  domainProfile: DomainProfile;
  inviteInfo: InviteInfo | null;
  onClickButterflySignUp: () => void;
  onClickButterflyLogin: () => void;
  onClickThirdPartyLogin: () => void;
}) {
  const { butterflyIdentityProvider, federatedIdentityProvider } =
    domainProfile;

  return (
    <>
      {federatedIdentityProvider && (
        <ThirdPartyLoginButton onClick={onClickThirdPartyLogin} />
      )}

      {butterflyIdentityProvider &&
        (inviteInfo && !inviteInfo.userHasButterflyLogin ? (
          <LoginButton
            onClick={onClickButterflySignUp}
            variant={federatedIdentityProvider ? 'secondary' : 'primary'}
          >
            <FormattedMessage
              id="domainLogin.createButterflyAccount"
              defaultMessage="Create Butterfly Cloud Account"
            />
          </LoginButton>
        ) : (
          <LoginButton
            onClick={onClickButterflyLogin}
            variant={federatedIdentityProvider ? 'secondary' : 'primary'}
          >
            {logInWithButterflyMessage}
          </LoginButton>
        ))}

      {!butterflyIdentityProvider && !federatedIdentityProvider && (
        <FormattedMessage
          id="domainLogin.noLogins"
          defaultMessage="No logins available. Please contact support."
        />
      )}
    </>
  );
}

interface Props {
  domainProfile: DomainProfile | null;
  inviteInfo: InviteInfo | null;
  willSignInWithSso?: boolean;
}

function DomainLoginPage({
  domainProfile,
  willSignInWithSso,
  inviteInfo,
}: Props) {
  const { match, router } = useRouter();

  const [showButterflyLogin, setShowButterflyLogin] = useState(
    domainProfile &&
      domainProfile.butterflyIdentityProvider &&
      !domainProfile.federatedIdentityProvider,
  );

  const handleOpenButterflyLogin = () => {
    setShowButterflyLogin(true);
  };

  const handleOpenButterflySignUp = useCallback(() => {
    router.replace({ ...match.location, state: { willCreateAccount: true } });
  }, [match.location, router]);

  const handleOpenThirdPartyLogin = () => {
    const { federatedIdentityProvider } =
      domainProfile as DeepNonNullable<DomainProfile>;

    const { willSignInWithSso: _, ...query } = match.location.query;

    // FIXME: should be a redirect?
    ExternalRedirect.assign(
      makeFederatedSsoLoginUrl({
        ...federatedIdentityProvider,
        nextPath: router.createHref({
          ...match.location,
          query,
        }),
      }),
    );
  };

  useEffect(() => {
    if (!willSignInWithSso) {
      return undefined;
    }

    const redirectHandle = setTimeout(
      handleOpenThirdPartyLogin,
      SSO_SIGN_IN_INTENT_DELAY_MS,
    );

    return () => {
      clearTimeout(redirectHandle);
    };
  });

  if (!domainProfile) {
    return <DomainNotFoundPage />;
  }

  const { federatedIdentityProvider, handle, name } = domainProfile;
  const email = inviteInfo && inviteInfo.email;

  if (willSignInWithSso) {
    return (
      <BlankAppPage>
        <AuthContent>
          <AuthContent.Title>
            <FormattedMessage
              id="domainLogin.idpRedirectTitle"
              defaultMessage="Redirecting"
            />
          </AuthContent.Title>
          <AuthContent.Description>
            <FormattedMessage
              id="domainLogin.idpRedirect"
              defaultMessage="Redirecting to {name} to sign in…"
              values={{ name }}
            />
          </AuthContent.Description>
          <LoadingIndicator />
        </AuthContent>
      </BlankAppPage>
    );
  }

  return (
    <BlankAppPage>
      <AuthContent>
        <AuthContent.Title>
          <FormattedMessage
            id="domainLoginPage.title"
            defaultMessage="Log In to Butterfly Network"
          />
        </AuthContent.Title>
        <AuthContent.Description>
          <LoginSubtitle inviteInfo={inviteInfo} />
        </AuthContent.Description>
        {showButterflyLogin &&
        (!inviteInfo || inviteInfo.userHasButterflyLogin) ? (
          <ButterflyLoginForm
            domainHandle={handle!}
            email={email}
            loginMessage={logInWithButterflyMessage}
          >
            {federatedIdentityProvider && (
              <ThirdPartyLoginButton onClick={handleOpenThirdPartyLogin} />
            )}
          </ButterflyLoginForm>
        ) : (
          <LoginPicker
            domainProfile={domainProfile}
            inviteInfo={inviteInfo}
            onClickButterflyLogin={handleOpenButterflyLogin}
            onClickButterflySignUp={handleOpenButterflySignUp}
            onClickThirdPartyLogin={handleOpenThirdPartyLogin}
          />
        )}
      </AuthContent>
    </BlankAppPage>
  );
}

const DomainLoginPageContainer = createFragmentContainer(DomainLoginPage, {
  inviteInfo: graphql`
    fragment DomainLoginPage_inviteInfo on OrganizationInviteInfo {
      email
      userHasButterflyLogin
      ...LoginSubtitle_inviteInfo
    }
  `,
  domainProfile: graphql`
    fragment DomainLoginPage_domainProfile on DomainProfile {
      name
      handle
      butterflyIdentityProvider {
        __typename
      }
      federatedIdentityProvider {
        authorizationEndpointBaseUrl
        clientId
      }
    }
  `,
});

export default Object.assign(DomainLoginPageContainer, {
  query: graphql`
    query DomainLoginPage_Query($domainSubdomainLabel: String!) {
      domainProfile: domainProfileBySubdomainLabel(
        subdomainLabel: $domainSubdomainLabel
      ) {
        ...DomainLoginPage_domainProfile
      }
    }
  `,
});
