/**
 * @generated SignedSource<<9d9403572acfdb078a4c64a41fee075d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PagedStudiesListPage_organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExamQuickFilters_tenant" | "StudyListContent_organization">;
  readonly " $fragmentType": "PagedStudiesListPage_organization";
};
export type PagedStudiesListPage_organization$key = {
  readonly " $data"?: PagedStudiesListPage_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PagedStudiesListPage_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PagedStudiesListPage_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_organization"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "includeArchives",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "includeAuthors",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "ExamQuickFilters_tenant"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "87741a78179321e003eae0d67a468c53";

export default node;
