import SearchIcon from '@bfly/icons/Search';
import Multiselect from '@bfly/ui2/Multiselect';
import getNodes from '@bfly/utils/getNodes';
import useImmediateUpdateEffect from '@restart/hooks/useImmediateUpdateEffect';
import useStableMemo from '@restart/hooks/useStableMemo';
import styled, { stylesheet } from 'astroturf/react';
import useRouter from 'found/useRouter';
import escapeRegExp from 'lodash/escapeRegExp';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  QueryRenderer,
  RelayProp,
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { useArchiveRoutes } from 'routes/archive';

import { useVariation } from './LaunchDarklyContext';
import SearchBarList from './SearchBarList';
import { SearchConstants, renderTag } from './SearchBarTags';
import { SearchBarOrganizationQuery } from './__generated__/SearchBarOrganizationQuery.graphql';
import { SearchBar_archive$data as Archive } from './__generated__/SearchBar_archive.graphql';
import { SearchBar_organization$data as Organization } from './__generated__/SearchBar_organization.graphql';
import { SearchBar_studyTags$data as StudyTags } from './__generated__/SearchBar_studyTags.graphql';
import { SearchBar_userProfile$data as UserProfile } from './__generated__/SearchBar_userProfile.graphql';

import searchStyles from './Search.module.scss';

const styles = stylesheet`
  .multiselect {
    composes: multiselect from './Search.module.scss';

    & :global(.rw-list-optgroup) {
      padding: 0.5rem 1rem;
    }

    & :global(.rw-list-option) {
      padding: 0.5rem 1rem;

      &:hover,
      &:global(.rw-state-focus) {
        // change the focus/hover state to blue b/c the groups are the same gray :/
        // (this also overrides the focus-visible behavior so the first item is always _visibly_ focused)
        background-color: theme('colors.primary') !important;
        border-color: theme('colors.primary') !important;
        color: theme('colors.white') !important;
      }
    }
  }
`;

type ArchiveResult = { type: 'Archive' } & Archive;
type UserProfileResult = { type: 'UserProfile' } & UserProfile;
type StudyTagResult = { type: 'StudyTag' } & StudyTags[0];

type SearchDataItem = ArchiveResult | UserProfileResult | StudyTagResult;

type SearchResult =
  | SearchConstants.FREE_TEXT_SEARCH
  | SearchConstants.ALL_ARCHIVES
  | SearchDataItem;

type SearchValue =
  | SearchConstants.ALL_ARCHIVES
  | ArchiveResult
  | UserProfileResult
  | StudyTagResult;

type SearchValueMap = {
  Archive?: ArchiveResult;
  UserProfile?: UserProfileResult;
  StudyTag?: StudyTagResult[];
};

const Search = styled(SearchIcon)`
  @apply text-white;
`;

const messages = defineMessages({
  search: {
    id: 'studySearchBar.search',
    defaultMessage: 'Search',
  },
});

function getItemType(item: SearchResult) {
  if (typeof item === 'string') return item;
  return item.type;
}

function getItemTypeOrdering(item: SearchResult) {
  return getItemType(item) === 'UserProfile' ? 1 : 0;
}

function dataKey(item: SearchResult): string {
  const itemType = getItemType(item);

  switch (itemType) {
    case SearchConstants.ALL_ARCHIVES:
    case SearchConstants.FREE_TEXT_SEARCH:
      return item as string;
    case 'UserProfile':
      return (item as UserProfileResult).handle!;
    case 'Archive':
      return (item as ArchiveResult).handle!;
    case 'StudyTag':
      return (item as StudyTagResult).name!;

    default:
      throw new Error(`unexpected item type: ${itemType}`);
  }
}

function getTextField(item: SearchDataItem) {
  return item.type === 'Archive' ? item.label! : item.name!;
}

export interface SearchBarHandle {
  focus(): void;
}

interface Props {
  organization: Organization;
  archive: Archive | null;
  userProfile: UserProfile | null;
  studyTags: StudyTags | null;
  search?: string | null;
  autoFocus?: boolean;
  className?: string;
  style?: React.CSSProperties;
  relay: RelayProp;
  onSearch?: (valueByType: SearchValueMap, nextSearchTerm: string) => void;
}

const SearchBar = React.forwardRef<SearchBarHandle, Props>(
  (
    {
      organization,
      archive,
      userProfile,
      studyTags,
      search,
      autoFocus,
      className,
      style,
      relay,
      onSearch,
    },
    outerRef,
  ) => {
    const {
      router,
      match: { location, params },
    } = useRouter();
    const archiveRoutes = useArchiveRoutes();
    const { formatMessage } = useIntl();
    const ref = useRef<HTMLDivElement>(null);
    const showTags = useVariation('study-tags');

    const [shouldFetch, setShouldFetch] = useState(false);

    const [fetchedOrganization, setFetchedOrganization] =
      useState<SearchBarOrganizationQuery['response']['organization']>(null);

    // In case the search bar is narrow enough to need scrolling, move the
    // scroll position to the end, so the "Search" placeholder is visible.
    useEffect(() => {
      if (!ref.current) return;
      const list = ref.current.querySelector('.rw-multiselect-taglist')!;
      const { scrollWidth } = list;
      if (scrollWidth) list.scrollLeft = scrollWidth;
    }, []);

    const propsValueByType = useStableMemo<SearchValueMap>(
      () => ({
        Archive:
          (archive && {
            type: 'Archive',
            ...archive,
          }) ||
          undefined,
        UserProfile:
          (userProfile && {
            type: 'UserProfile',
            ...userProfile,
          }) ||
          undefined,
        StudyTag:
          (studyTags &&
            showTags &&
            studyTags.map((t) => ({ type: 'StudyTag', ...t }))) ||
          [],
      }),
      // archive here doesn't seem to stable? Maybe just in the sandbox?
      [
        archive?.handle,
        userProfile?.handle,
        studyTags?.map((t) => t.handle).join(','),
      ],
    );

    const propsSearchTerm = search || '';

    const [valueByType, setValueByType] = useState(propsValueByType);
    const [searchTerm, setSearchTerm] = useState(propsSearchTerm);
    const [active, setActive] = useState(false);

    useImmediateUpdateEffect(() => {
      setValueByType(propsValueByType);
    }, [propsValueByType]);

    useImmediateUpdateEffect(() => {
      setSearchTerm(propsSearchTerm);
    }, [propsSearchTerm]);

    useImmediateUpdateEffect(() => {
      setActive(false);
    }, [location]);

    const selectableItems = useMemo(
      () =>
        fetchedOrganization && [
          ...((valueByType.Archive
            ? []
            : getNodes(
                fetchedOrganization.archiveConnection!,
              )) as ArchiveResult[]),
          ...(valueByType.UserProfile
            ? []
            : (getNodes(
                fetchedOrganization.studyCreatorConnection!,
              ) as UserProfileResult[])),
          ...(!fetchedOrganization.studyTagConnection
            ? []
            : (getNodes(
                fetchedOrganization.studyTagConnection,
              ) as StudyTagResult[])),
        ],
      [fetchedOrganization, valueByType],
    );

    const data = useMemo(() => {
      if (!fetchedOrganization) {
        return [SearchConstants.FREE_TEXT_SEARCH];
      }

      const searchRegex = new RegExp(
        `(^|\\s)${escapeRegExp(searchTerm)}`,
        'i',
      );

      const matchedItems = selectableItems!.filter(
        (item) => !!getTextField(item).match(searchRegex),
      );
      const sortedItems = sortBy(matchedItems, getTextField);
      const limitedItems = sortedItems.slice(0, 5);

      // Always show archives before users.
      const items = sortBy(limitedItems, getItemTypeOrdering);

      return ([SearchConstants.FREE_TEXT_SEARCH] as any).concat(items);
    }, [fetchedOrganization, selectableItems, searchTerm]);

    const value = useMemo(() => {
      const nextValue = [
        valueByType.Archive || SearchConstants.ALL_ARCHIVES,
        valueByType.UserProfile!,
        ...(valueByType.StudyTag || []),
      ].filter(Boolean) as SearchValue[];

      return nextValue;
    }, [valueByType]);

    const busy = shouldFetch && fetchedOrganization == null;
    const open = active && !!searchTerm;

    const handleSearchUpdated = (
      nextValueByType: SearchValueMap,
      nextSearchTerm: string,
    ) => {
      if (onSearch) {
        onSearch(nextValueByType, nextSearchTerm);
      }

      router.push({
        pathname: archiveRoutes.studySearch({
          organizationSlug: params.organizationSlug,
        }),
        query: {
          ...location.query,
          archive: nextValueByType.Archive?.handle,
          tag: nextValueByType.StudyTag?.map((t) => t.handle!) as any,
          createdBy: nextValueByType.UserProfile?.handle,
          search: nextSearchTerm,
        },
      });
    };

    const handleChange = (valueRaw: SearchResult[], meta) => {
      const nextValueByType = mapValues(
        groupBy(valueRaw, 'type'),
        (v, key) => {
          return key === 'StudyTag' ? v : v[0];
        },
      );

      setValueByType(nextValueByType);

      let nextSearchTerm;
      if (
        meta.action === 'insert' &&
        meta.dataItem !== SearchConstants.FREE_TEXT_SEARCH
      ) {
        // We're searching on this as a non-free-text item. Clear the search.
        nextSearchTerm = '';
        setSearchTerm('');
      } else {
        nextSearchTerm = searchTerm;
      }

      // In the above, update the state immediately so the search UI reflects
      // the selections; no need to wait for navigation query.
      handleSearchUpdated(nextValueByType, nextSearchTerm);
    };

    const handleKeyDown = ({ key }) => {
      if (key === 'Enter' && !open) {
        handleSearchUpdated(valueByType, searchTerm);
      }
    };

    const handleSearch = useCallback((nextSearchTerm, meta) => {
      // Don't let react-widgets automatically clear the search term on blur or
      // on other events. We will manually handle the update.
      if (meta && meta.action === 'clear') {
        return;
      }

      setSearchTerm(nextSearchTerm);
    }, []);

    const handleToggle = useCallback((nextActive) => {
      if (nextActive) {
        setShouldFetch(true);
      }

      setActive(nextActive);
    }, []);

    return (
      <div
        ref={ref}
        className={className}
        style={style}
        data-bni-id="SearchBar"
      >
        {shouldFetch && (
          <QueryRenderer<SearchBarOrganizationQuery>
            environment={relay.environment}
            fetchPolicy="store-and-network"
            query={graphql`
              query SearchBarOrganizationQuery(
                $organizationId: ID!
                $showTags: Boolean!
              ) {
                organization: node(id: $organizationId) {
                  ... on Organization {
                    archiveConnection {
                      edges {
                        node {
                          type: __typename
                          ...SearchBar_archive @relay(mask: false)
                        }
                      }
                    }
                    studyCreatorConnection(first: 2147483647)
                      @connection(key: "Organization_studyCreatorConnection") {
                      edges {
                        node {
                          type: __typename
                          ...SearchBar_userProfile @relay(mask: false)
                        }
                      }
                    }
                    studyTagConnection @include(if: $showTags) {
                      edges {
                        node {
                          type: __typename
                          ...SearchBar_studyTags @relay(mask: false)
                        }
                      }
                    }
                  }
                }
              }
            `}
            variables={{
              showTags,
              organizationId: organization.id,
            }}
            render={({ props: queryProps }) => {
              if (queryProps) {
                setFetchedOrganization(queryProps.organization!);
              }

              return null;
            }}
          />
        )}
        <Multiselect
          variant="secondary"
          ref={outerRef as any}
          autoFocus={autoFocus}
          prependIcon={<Search />}
          data={data}
          focusFirstItem
          groupBy={getItemType}
          className={styles.multiselect}
          controlClassName={searchStyles.control}
          value={value}
          listProps={{ busy }}
          searchTerm={searchTerm}
          filter={false}
          dataKey={dataKey}
          open={open}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onSearch={handleSearch}
          onToggle={handleToggle}
          tagOptionComponent={renderTag}
          listComponent={SearchBarList as any}
          placeholder={formatMessage(messages.search)}
          showPlaceholderWithValues
        />
      </div>
    );
  },
);

export default createFragmentContainer(SearchBar, {
  organization: graphql`
    fragment SearchBar_organization on Organization {
      id
    }
  `,
  archive: graphql`
    fragment SearchBar_archive on Archive {
      handle
      label
      ...SearchBarTags_archive
      ...SearchBarList_archive
    }
  `,
  userProfile: graphql`
    fragment SearchBar_userProfile on UserProfile {
      handle
      name
      ...SearchBarTags_userProfile
      ...SearchBarList_userProfile
    }
  `,
  studyTags: graphql`
    fragment SearchBar_studyTags on StudyTag @relay(plural: true) {
      handle
      name
      ...SearchBarTags_studyTag
      ...SearchBarList_studyTag
    }
  `,
});
