/**
 * @generated SignedSource<<a87be50a9de728cab6711a3d498e5857>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamImageRoll_images$data = ReadonlyArray<{
  readonly handle?: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExamImageThumbnail_image">;
  readonly " $fragmentType": "ExamImageRoll_images";
}>;
export type ExamImageRoll_images$key = ReadonlyArray<{
  readonly " $data"?: ExamImageRoll_images$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamImageRoll_images">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "handle",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ExamImageThumbnail_image"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExamImageRoll_images",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "StudyImage",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "EduStudyImage",
      "abstractKey": null
    }
  ],
  "type": "StudyImageInterface",
  "abstractKey": "__isStudyImageInterface"
};
})();

(node as any).hash = "54a33f6b3d82a79d9495fa3a69946240";

export default node;
