import Layout from '@4c/layout';
import Carousel from '@bfly/ui2/Carousel';
import clsx from 'clsx';
import { createFragmentContainer, graphql } from 'react-relay';

import StudyImage from 'components/StudyImage';

import useSharedInterpretationImage from '../utils/useSharedInterpretationImage';
import MeetIqAlert from './MeetIqAlert';
import { DesktopSharedImageCarousel_image$data as Image } from './__generated__/DesktopSharedImageCarousel_image.graphql';

interface Props {
  image: Image;
  imageIndex: number;
  isFirst: boolean;
  isLast: boolean;
  onNext: () => void;
  onPrev: () => void;
  className?: string;
}

function DesktopSharedImageCarousel({
  image,
  imageIndex,
  className,
  isFirst,
  isLast,
  onNext,
  onPrev,
}: Props) {
  const { interpretationImage, showInterpretationControl, showRenderControl } =
    useSharedInterpretationImage(image.id);

  return (
    <>
      <Layout
        direction="column"
        className={clsx(className, 'relative bg-black overflow-hidden mb-4')}
        grow
      >
        {showRenderControl && (
          <Layout justify="center" className="my-2">
            {showRenderControl}
          </Layout>
        )}
        <Carousel
          isFirst={isFirst}
          isLast={isLast}
          onNext={onNext}
          onPrev={onPrev}
          className="flex-1 mx-3"
        >
          {interpretationImage || (
            <StudyImage image={image} index={imageIndex} />
          )}
        </Carousel>
        <MeetIqAlert />
      </Layout>

      {showInterpretationControl && (
        <div className="mb-4 mx-4">{showInterpretationControl}</div>
      )}
    </>
  );
}

export default createFragmentContainer(DesktopSharedImageCarousel, {
  image: graphql`
    fragment DesktopSharedImageCarousel_image on SharedBundleImage {
      id
      ...StudyImage_image
    }
  `,
});
