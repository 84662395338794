import { useField } from '@bfly/ui2/Form';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import Text from '@bfly/ui2/Text';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import Analytics from 'utils/Analytics';

import { SplitStudyWorksheetsField_sourceStudy$data as SourceStudy } from './__generated__/SplitStudyWorksheetsField_sourceStudy.graphql';

interface Props {
  sourceStudy: SourceStudy;
  selectAllWorksheets: boolean;
}
type Worksheets = DeepNonNullable<SourceStudy>['worksheets'];

function SplitStudyWorksheetsField({
  sourceStudy,
  selectAllWorksheets,
}: Props) {
  // This must be rendered inside a Form context with a schema
  // key of 'worksheets' to function properly
  const [worksheetFieldProps] = useField('worksheets');
  const { onChange: setWorksheets } = worksheetFieldProps;

  const worksheetOptions = sourceStudy.worksheets as Worksheets;
  useEffect(() => {
    if (!selectAllWorksheets) return;
    setWorksheets(worksheetOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllWorksheets]);

  if (!sourceStudy.worksheets?.length) {
    return null;
  }

  return (
    <>
      <Text>
        <FormattedMessage
          id="editStudy.selectContent.worksheets"
          defaultMessage="Worksheets"
        />
      </Text>
      <FormCheckGroup<Worksheets[0]>
        {...worksheetFieldProps}
        onChange={(value) => {
          setWorksheets(value);
          const worksheetIds = value.map((w) => w.id);
          Analytics.track('splitStudyWorksheetSelectionChanged', {
            studyId: sourceStudy.id,
            organizationId: sourceStudy.organization?.id,
            worksheetIds,
          });
        }}
        disabled={selectAllWorksheets}
        type="checkbox"
        data={worksheetOptions as any}
        dataKey="handle"
        renderItem={(worksheetOption) =>
          worksheetOption.templateVersion!.title
        }
      />
    </>
  );
}

export default createFragmentContainer(SplitStudyWorksheetsField, {
  sourceStudy: graphql`
    fragment SplitStudyWorksheetsField_sourceStudy on Study {
      id
      worksheets {
        id
        handle
        templateVersion {
          title
        }
      }
      organization {
        id
      }
    }
  `,
});
