import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';

import StudyCaptureSelector from './StudyCaptureSelector';
import { DeleteCapturesModalContent_study$data as Study } from './__generated__/DeleteCapturesModalContent_study.graphql';

interface Props {
  onCancel: () => void;
  setShowConfirmDelete: (boolean) => void;
  formValue: Record<string, any>;
  study: Study;
}

function DeleteCapturesModalContent({
  onCancel,
  setShowConfirmDelete,
  formValue,
  study,
}: Props) {
  const studyImages = getNodes(study.deleteCapturesImages);

  const nextStepDisabled = useMemo(
    () =>
      formValue.imageIds.length === studyImages.length ||
      formValue.imageIds.length === 0,
    [formValue, studyImages.length],
  );

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="deleteCapturesModalContent.deleteCaptures"
            defaultMessage="Delete captures"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Layout direction="column" pad>
          <StudyCaptureSelector
            study={study}
            heading={
              <Text variant="lg">
                <FormattedMessage
                  id="deleteCapturesModalContent.selectContent"
                  defaultMessage="{numImages} captures"
                  values={{ numImages: studyImages.length }}
                />
              </Text>
            }
          />
        </Layout>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            size="lg"
            variant="danger"
            onClick={() => setShowConfirmDelete(true)}
            disabled={nextStepDisabled}
          >
            <FormattedMessage {...actionMessages.delete} />
          </Button>
          <Button size="lg" variant="secondary" onClick={onCancel}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(DeleteCapturesModalContent, {
  study: graphql`
    fragment DeleteCapturesModalContent_study on Study {
      deleteCapturesImages: imageConnection(first: 2147483647) {
        edges {
          node {
            id
          }
        }
      }
      ...StudyCaptureSelector_study
    }
  `,
});
