import MutationButton, { MutationButtonProps } from '@bfly/ui2/MutationButton';
import { MutationParametersWithError } from '@bfly/ui2/useMutationWithError';
import { FormattedMessage } from 'react-intl';

import { usePermissions } from 'utils/viewerContext';

const resendMessage = (
  <FormattedMessage id="study.endpoints.resend" defaultMessage="Re-send" />
);

interface Job {
  status: string | null;
}

interface Props<T extends MutationParametersWithError>
  extends Pick<MutationButtonProps<T>, 'mutation' | 'input'> {
  job: Job | null;
}

function SendStudyToEndpointButton<T extends MutationParametersWithError>({
  mutation,
  input,
  job,
}: Props<T>) {
  const { hasAdminPermission } = usePermissions();
  return (
    <MutationButton<T>
      variant="text-primary"
      mutation={mutation}
      input={input}
      disabled={
        (!!job && (job.status === 'PENDING' || job.status === 'RETRYING')) ||
        !hasAdminPermission('connectivityManagement')
      }
      successMessage={resendMessage}
    >
      {job ? (
        resendMessage
      ) : (
        <FormattedMessage id="study.endpoints.send" defaultMessage="Send" />
      )}
    </MutationButton>
  );
}

export default SendStudyToEndpointButton;
