import CourseOverviewPageContent from './CourseOverviewPageContent';
import { UnenrollmentContextProvider } from './UnenrollmentContext';

export default function CourseOverviewPage({ children }) {
  return (
    <UnenrollmentContextProvider>
      <CourseOverviewPageContent>{children}</CourseOverviewPageContent>
    </UnenrollmentContextProvider>
  );
}
