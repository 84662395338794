/**
 * @generated SignedSource<<d95fe69740c70de04aca175f07b2ebb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalStudySearchList_viewer$data = {
  readonly domain: {
    readonly __typename: "Domain";
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyListContent_viewer">;
  readonly " $fragmentType": "GlobalStudySearchList_viewer";
};
export type GlobalStudySearchList_viewer$key = {
  readonly " $data"?: GlobalStudySearchList_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchList_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalStudySearchList_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "290af68efb376b97bef9430c7d222e28";

export default node;
