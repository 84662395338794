/**
 * @generated SignedSource<<e0238a818b816092fc331a4fef60e0e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewExamSection_tenant$data = {
  readonly numUnassigned: number | null;
  readonly numViewerFavorite: number | null;
  readonly " $fragmentType": "NewExamSection_tenant";
};
export type NewExamSection_tenant$key = {
  readonly " $data"?: NewExamSection_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewExamSection_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewExamSection_tenant",
  "selections": [
    {
      "alias": "numViewerFavorite",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "favoritedBy",
              "variableName": "viewerId"
            }
          ],
          "kind": "ObjectValue",
          "name": "search"
        }
      ],
      "kind": "ScalarField",
      "name": "numStudies",
      "storageKey": null
    },
    {
      "alias": "numUnassigned",
      "args": [
        {
          "kind": "Literal",
          "name": "search",
          "value": {
            "author": [
              "@@UNASSIGNED"
            ]
          }
        }
      ],
      "kind": "ScalarField",
      "name": "numStudies",
      "storageKey": "numStudies(search:{\"author\":[\"@@UNASSIGNED\"]})"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "f36e37b6bd03dead7b2916e03d5653d9";

export default node;
