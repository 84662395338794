import Button, { ButtonProps } from '@bfly/ui2/Button';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import { FormattedMessage } from 'react-intl';
import { RelayProp, createFragmentContainer, graphql } from 'react-relay';

import SyncingSpinner from 'components/SyncingSpinner';

import { RefreshModalityWorklistControlMutation } from './__generated__/RefreshModalityWorklistControlMutation.graphql';
import { RefreshModalityWorklistControl_modalityWorklist$data as ModalityWorklist } from './__generated__/RefreshModalityWorklistControl_modalityWorklist.graphql';

interface Props extends ButtonProps {
  modalityWorklist: ModalityWorklist;
  relay: RelayProp;
}

const mutation = graphql`
  mutation RefreshModalityWorklistControlMutation(
    $input: RefreshModalityWorklistInput!
  ) {
    refreshModalityWorklistOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
      ... on RefreshModalityWorklistPayload {
        modalityWorklist {
          ...RefreshModalityWorklistControl_modalityWorklist
        }
      }
    }
  }
`;

export function useMwlRefreshMutation(modalityWorklistId: string) {
  const toast = useToast();
  return useMutationWithError<RefreshModalityWorklistControlMutation>(
    mutation,
    {
      input: { modalityWorklistId },
      onError: (e) => {
        toast.error(e!.message);
      },
    },
  );
}

function RefreshModalityWorklistControl({
  modalityWorklist,
  relay: _,
  ...props
}: Props) {
  const [mutate, loading] = useMwlRefreshMutation(modalityWorklist.id);

  const isRefreshing = !!(
    modalityWorklist.refreshJob && !modalityWorklist.refreshJob.completedAt
  );

  return (
    <Button
      variant="text-secondary"
      {...props}
      onClick={() => {
        if (loading || isRefreshing) return;
        mutate();
      }}
    >
      <SyncingSpinner
        paused={!isRefreshing}
        css="height: inherit;"
        className="mr-2.5"
      />
      {isRefreshing ? (
        <FormattedMessage
          defaultMessage="Refreshing"
          id="RefreshModalityWorklistControl.refreshing"
        />
      ) : (
        <FormattedMessage
          defaultMessage="Refresh"
          id="RefreshModalityWorklistControl.refresh"
        />
      )}
    </Button>
  );
}

export default createFragmentContainer(RefreshModalityWorklistControl, {
  modalityWorklist: graphql`
    fragment RefreshModalityWorklistControl_modalityWorklist on ModalityWorklist {
      id
      refreshJob {
        completedAt
      }
    }
  `,
});
