/**
 * @generated SignedSource<<1662fb41fc63db1b4253d0069389c98f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseAppPage_archive$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AppSearch_archive">;
  readonly " $fragmentType": "BaseAppPage_archive";
};
export type BaseAppPage_archive$key = {
  readonly " $data"?: BaseAppPage_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BaseAppPage_archive",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppSearch_archive"
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "de8b2060c335d6627509b46ca5d0039c";

export default node;
