/**
 * @generated SignedSource<<98e3c703a5455142f2508a0219b390fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canTagStudy$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "StudyPermissions_canTagStudy";
};
export type StudyPermissions_canTagStudy$key = {
  readonly " $data"?: StudyPermissions_canTagStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canTagStudy">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canTagStudy"
};

(node as any).hash = "7c5988c19268e630eaf23cf84459291d";

export default node;
