import Layout from '@4c/layout';
import FormCheck from '@bfly/ui2/FormCheck';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import { css } from 'astroturf';
import { useField } from 'react-formal';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import StudyTitle from 'components/StudyTitle';
import Thumbnail from 'components/Thumbnail';

import { SplitStudyRefreshSelectDraftItem_study$data as Study } from './__generated__/SplitStudyRefreshSelectDraftItem_study.graphql';

interface Props {
  study: Study;
}

const messages = {
  birthDate: {
    id: 'SplitStudyRefreshSelectDraftItem.dob',
    defaultMessage: 'DOB: {birthDate}',
  },
  patientId: {
    id: 'SplitStudyRefreshSelectDraftItem.patientId',
    defaultMessage: 'Patient ID: {patientId}',
  },
  byAuthor: {
    id: 'SplitStudyRefreshSelectDraftItem.byAuthor',
    defaultMessage: 'Exam by: {name}',
  },
  noAuthor: {
    id: 'SplitStudyRefreshSelectDraftItem.noAuthor',
    defaultMessage: 'No author',
  },
  capturedAt: {
    id: 'SplitStudyRefreshSelectDraftItem.capturedAt',
    defaultMessage: 'Captured at {capturedAt}.',
  },
};

function PatientDetails({ study }: { study: Study }) {
  const components: React.ReactNode[] = [];

  function addComponent(component: React.ReactNode) {
    if (components.length) {
      components.push(<>,&nbsp;</>);
    }
    components.push(component);
  }

  if (study.practiceType === 'HUMAN' && study.patient) {
    if (study.patient.birthDate) {
      addComponent(
        <FormattedMessage
          {...messages.birthDate}
          values={{ birthDate: study.patient.birthDate }}
        />,
      );
    }
    if (study.patient.medicalRecordNumber) {
      addComponent(
        <FormattedMessage
          {...messages.patientId}
          values={{ patientId: study.patient.medicalRecordNumber }}
        />,
      );
    }
  }
  if (study.practiceType === 'VETERINARY' && study.vetPatient) {
    if (study.vetPatient.birthDate) {
      addComponent(
        <FormattedMessage
          {...messages.birthDate}
          values={{ birthDate: study.vetPatient.birthDate }}
        />,
      );
    }
  }
  if (study.createdBy?.name) {
    addComponent(
      <FormattedMessage
        {...messages.byAuthor}
        values={{ name: study.createdBy.name }}
      />,
    );
  } else {
    addComponent(<FormattedMessage {...messages.noAuthor} />);
  }

  return <>{components}</>;
}

function SplitStudyRefreshSelectDraftItem({ study }: Props) {
  const [destinationStudyIdProps, { update: setDestinationStudyId }] =
    useField('destinationStudyId');
  const { value: destinationStudyId } = destinationStudyIdProps;
  const isSelected = destinationStudyId === study.id;

  const studyImages = getNodes(study.imageConnection);
  const remainingImageCount = (study.numImages || 0) - studyImages.length;

  return (
    <Layout
      grow
      flex
      direction="row"
      className="cursor-pointer justify-start bg-grey-85"
      onClick={() => setDestinationStudyId(study.id)}
      data-bni-id={`SplitStudyRefreshSelectDraftItem-${study.handle}`}
    >
      <div
        css={css`
          background-color: theme('colors.draft');
          width: 3px;
        `}
      >
        {/* draft indicator */}
      </div>
      <div className="h-full ml-3 w-[5rem] align-center grid place-items-center">
        <FormCheck.Input
          {...destinationStudyIdProps}
          value={study.id}
          type="radio"
          checked={isSelected}
          className="rounded-full align-middle cursor-pointer"
        />
      </div>
      <Layout direction="column" className="ml-3 w-[50rem] pt-3 pb-3">
        <Text truncate>
          <Text variant="body-bold">
            <StudyTitle study={study} />
          </Text>
          {study.archive ? <Text>; {study.archive?.label}</Text> : null}
        </Text>
        <Text>
          <PatientDetails study={study} />
        </Text>
        <Text>
          <FormattedMessage
            {...messages.capturedAt}
            values={{
              capturedAt: <FormattedDateTime value={study.capturedAt!} />,
            }}
          />
        </Text>
      </Layout>
      <Layout direction="row" className="w-[24rem] align-items-center pt-5">
        {studyImages.map((studyImage) => (
          <Thumbnail
            className="w-12 h-12 ml-2 rounded-lg border border-grey-70"
            maxWidth={128}
            image={studyImage.burnedInFile}
          />
        ))}
        {remainingImageCount > 0 ? (
          <Text className="ml-2 mr-2 font-bold pt-3 pl-2">
            +{remainingImageCount}
          </Text>
        ) : null}
      </Layout>
    </Layout>
  );
}

export default createFragmentContainer(SplitStudyRefreshSelectDraftItem, {
  study: graphql`
    fragment SplitStudyRefreshSelectDraftItem_study on Study {
      id
      handle
      numImages
      readyAt
      capturedAt
      practiceType
      archive {
        label
      }
      createdBy {
        name
      }
      patient {
        birthDate
        medicalRecordNumber
      }
      vetPatient {
        birthDate
      }
      imageConnection(first: 3) {
        edges {
          node {
            ... on StudyImage {
              ...StudyImagePopoverThumbnail_studyImage
              burnedInFile {
                ...Thumbnail_image
              }
            }
          }
        }
      }
      ...StudyTitle_study
    }
  `,
});
