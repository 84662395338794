import React from 'react';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import NotFoundNotification from './NotFoundNotification';
import StudyNotification from './StudyNotification';
import { WorksheetReviewedNotification_notification$data as Notification } from './__generated__/WorksheetReviewedNotification_notification.graphql';

const messages = defineMessages({
  notification: {
    id: 'notificationCenter.notification.worksheetReviewed.action',
    defaultMessage: '{username} reviewed your study {studyName}',
  },
});

interface Props {
  notification: Notification;
  clearable: boolean;
}

function WorksheetReviewedNotification({ notification, clearable }: Props) {
  const { reviewer, study, worksheet } = notification;

  if (!study || study?.deletedAt) {
    return (
      <NotFoundNotification
        notification={notification}
        clearable={clearable}
      />
    );
  }

  return (
    <StudyNotification
      notification={notification}
      userProfile={reviewer!}
      study={study!}
      image={null}
      message={messages.notification}
      query={{ worksheet: worksheet?.handle ?? undefined }}
      clearable={clearable}
    />
  );
}

export default createFragmentContainer(WorksheetReviewedNotification, {
  notification: graphql`
    fragment WorksheetReviewedNotification_notification on NotificationInterface {
      ... on WorksheetReviewedNotification {
        reviewer {
          ...StudyNotification_userProfile
        }
        study {
          deletedAt
          ...StudyNotification_study
        }
        worksheet {
          handle
        }
      }
      ...NotFoundNotification_notification
      ...StudyNotification_notification
    }
  `,
});
