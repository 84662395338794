/**
 * @generated SignedSource<<d5cd5244df15642144f4187d0274806b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveMembersPage_archive$data = {
  readonly isPrivate: boolean | null;
  readonly label: string | null;
  readonly numMemberships: number | null;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"ArchivePrivacyDescription_organization">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveInviteForm_archive" | "ArchiveMemberItemGrid_archive" | "ArchivePageTitle_archive">;
  readonly " $fragmentType": "ArchiveMembersPage_archive";
};
export type ArchiveMembersPage_archive$key = {
  readonly " $data"?: ArchiveMembersPage_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveMembersPage_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveMembersPage_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPrivate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArchivePrivacyDescription_organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numMemberships",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchiveMemberItemGrid_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchivePageTitle_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchiveInviteForm_archive"
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "2e630d886eddc306470fd36f4a369204";

export default node;
