/**
 * @generated SignedSource<<89b2ac5a9609d8e3bf6b934b5b50546a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorklistItemHeader_worklistItem$data = {
  readonly scheduledProcedureStartTime: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"PatientName_patient">;
  readonly " $fragmentType": "WorklistItemHeader_worklistItem";
};
export type WorklistItemHeader_worklistItem$key = {
  readonly " $data"?: WorklistItemHeader_worklistItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorklistItemHeader_worklistItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorklistItemHeader_worklistItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledProcedureStartTime",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PatientName_patient"
    }
  ],
  "type": "WorklistItemInterface",
  "abstractKey": "__isWorklistItemInterface"
};

(node as any).hash = "2b32aee4210b053d0feb9b955da8c392";

export default node;
