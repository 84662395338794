/**
 * @generated SignedSource<<439f2fd8fd6c50eda6892bc9593c59b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query$variables = {
  templateHandle: string;
};
export type domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query$data = {
  readonly template: {
    readonly isPublished: boolean | null;
    readonly " $fragmentSpreads": FragmentRefs<"UpdateWorksheetTemplateForm_template">;
  } | null;
  readonly viewer: {
    readonly domain: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateWorksheetTemplateForm_connectivityConfig">;
    } | null;
  } | null;
};
export type domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query = {
  response: domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query$data;
  variables: domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "templateHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "templateHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPublished",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "FieldOption",
  "kind": "LinkedField",
  "name": "options",
  "plural": true,
  "selections": [
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowOther",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  },
  {
    "kind": "Literal",
    "name": "isHidden",
    "value": false
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query",
    "selections": [
      {
        "alias": "template",
        "args": (v1/*: any*/),
        "concreteType": "WorksheetTemplate",
        "kind": "LinkedField",
        "name": "worksheetTemplate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateWorksheetTemplateForm_template"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateWorksheetTemplateForm_connectivityConfig"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query",
    "selections": [
      {
        "alias": "template",
        "args": (v1/*: any*/),
        "concreteType": "WorksheetTemplate",
        "kind": "LinkedField",
        "name": "worksheetTemplate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "workflowConfig",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "organizationId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "domainId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  },
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorksheetTemplateVersion",
            "kind": "LinkedField",
            "name": "latestVersion",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "procedureId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "procedureDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamType",
                "kind": "LinkedField",
                "name": "examTypes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shouldApplyAllExamTypesToStudy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorksheetSectionDefinition",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "fieldDefinitions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": "typename",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "required",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "textStyle",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "style",
                            "storageKey": null
                          }
                        ],
                        "type": "TextFieldDefinition",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "numberStyle",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "style",
                            "storageKey": null
                          }
                        ],
                        "type": "NumberFieldDefinition",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "singleSelectStyle",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "style",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "type": "SingleSelectFieldDefinition",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "multiSelectStyle",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "style",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "type": "MultiSelectFieldDefinition",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "ExamTypeConnection",
                    "kind": "LinkedField",
                    "name": "examTypeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExamTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExamType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "examTypeConnection(first:2147483647,isHidden:false)"
                  },
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "filters": [
                      "isHidden"
                    ],
                    "handle": "connection",
                    "key": "UpdateWorksheetTemplateForm_examTypeConnection",
                    "kind": "LinkedHandle",
                    "name": "examTypeConnection"
                  }
                ],
                "type": "ConnectivityConfigInterface",
                "abstractKey": "__isConnectivityConfigInterface"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7438f29ce081b49de9992b6b717e5fdd",
    "id": null,
    "metadata": {},
    "name": "domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query",
    "operationKind": "query",
    "text": "query domainWorksheetRoutes_DomainUpdateWorksheetTemplateForm_Query(\n  $templateHandle: String!\n) {\n  template: worksheetTemplate(handle: $templateHandle) {\n    isPublished\n    ...UpdateWorksheetTemplateForm_template\n    id\n  }\n  viewer {\n    domain {\n      ...UpdateWorksheetTemplateForm_connectivityConfig\n      id\n    }\n    id\n  }\n}\n\nfragment UpdateWorksheetTemplateForm_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  examTypeConnection(first: 2147483647, isHidden: false) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UpdateWorksheetTemplateForm_template on WorksheetTemplate {\n  id\n  workflowConfig {\n    __typename\n    ...useTenantIdInput_tenant\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  latestVersion {\n    title\n    procedureId\n    procedureDescription\n    examTypes {\n      id\n      name\n    }\n    shouldApplyAllExamTypesToStudy\n    sections {\n      title\n      handle\n      fieldDefinitions {\n        __typename\n        typename: __typename\n        handle\n        required\n        label\n        ... on TextFieldDefinition {\n          textStyle: style\n        }\n        ... on NumberFieldDefinition {\n          numberStyle: style\n        }\n        ... on SingleSelectFieldDefinition {\n          singleSelectStyle: style\n          options {\n            label\n          }\n          allowOther\n        }\n        ... on MultiSelectFieldDefinition {\n          multiSelectStyle: style\n          options {\n            label\n          }\n          allowOther\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "596ed8b34eeeea216fbdbd1f0babd940";

export default node;
