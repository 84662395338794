/**
 * @generated SignedSource<<8ce0aab48ee05230c4682007007aa9ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type isLastPublicArchive_organization$data = {
  readonly archiveConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isPrivate: boolean | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "isLastPublicArchive_organization";
};
export type isLastPublicArchive_organization$key = {
  readonly " $data"?: isLastPublicArchive_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"isLastPublicArchive_organization">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "isLastPublicArchive_organization"
};

(node as any).hash = "fad58dcba2c81fcba66f4abe8e6897d9";

export default node;
