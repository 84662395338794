/**
 * @generated SignedSource<<5a6466c65a232be02d6c19f581423948>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type examPageSidebar_useShowQa_study$data = {
  readonly archive: {
    readonly worksheetRequiredForQa: boolean | null;
  } | null;
  readonly finalizedAt: string | null;
  readonly numWorksheets: number | null;
  readonly viewerCanQa: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted" | "StudyPermissions_studyReviewStatus">;
  readonly " $fragmentType": "examPageSidebar_useShowQa_study";
};
export type examPageSidebar_useShowQa_study$key = {
  readonly " $data"?: examPageSidebar_useShowQa_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"examPageSidebar_useShowQa_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "examPageSidebar_useShowQa_study"
};

(node as any).hash = "355dceb5658cd6c9bd5e614a2f2dc21c";

export default node;
