import { OpenDialogOptions } from '@bfly/ui2/DialogContext';
import Text from '@bfly/ui2/Text';
import useDialog from '@bfly/ui2/useDialog';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, readInlineData } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import actionMessages from 'messages/actions';
import Analytics, { AnalyticsEvent } from 'utils/Analytics';
import { StudyListFilter } from 'utils/StudyConstants';
import { studyRangeDeleteUpdater } from 'utils/StudyFilters';
import { isStudyAuthor } from 'utils/studyAuthors';
import updateNumStudies from 'utils/updateNumStudies';
import { useViewerContext } from 'utils/viewerContext';

import finalizeMessages from '../routes/archive/finalizeMessages';
import {
  FinalizeDraftStudyInput,
  useStudyFinalization_FinalizeMutation as Mutation,
} from './__generated__/useStudyFinalization_FinalizeMutation.graphql';
import { useStudyFinalization_study$key as StudyKey } from './__generated__/useStudyFinalization_study.graphql';
import isUnseenChangesError from './isUnseenChangesError';
import useRefreshStudyDialog from './useRefreshStudyDialog';

export enum FinalizationMode {
  FINALIZE,
  REQUEST_FINALIZATION,
  SIGN_AND_FINALIZE,
}

export enum StudyFinalizationRestriction {
  WORKLIST_REQUIRED,
  PATIENT_ID_REQUIRED,
  WORKSHEET_REQUIRED,
  EXAM_TYPE_REQUIRED,
  AUTHOR_REQUIRED,
  PRIMARY_AUTHOR_MUST_SIGN,
  SCRIBE_CANNOT_SIGN,
}

export default function useStudyFinalization(studyRef: StudyKey): {
  finalize: () => Promise<false | Mutation['response']>;
  finalizationMode: FinalizationMode;
  loading: boolean;
  restrictions: {
    isValid: boolean;
  } & Partial<Record<StudyFinalizationRestriction, boolean>>;
} {
  const viewer = useViewerContext();
  const primaryAuthorMustSign = useVariation('primary-author-must-sign');
  const autoAssignFinalizer = useVariation('auto-assign-finalizer');
  const canUsePatientIdRequiredToFinalize = useVariation(
    'patient-id-required-signing',
  );

  const canUseRestrictStudyFinalization = useVariation(
    'restricted-study-finalization',
  );

  const dialog = useDialog();
  const toast = useToast();

  const study = readInlineData(
    graphql`
      fragment useStudyFinalization_study on Study @inline {
        id
        finalizationRequiresAttestation
        viewerCanFinalize
        isPendingFinalization
        hasAssociatedWorklistItem
        hasBeenFinalized
        createdBy {
          __typename
          id
        }
        transcribedBy {
          __typename
          id
        }
        worksheets {
          __typename
        }
        examTypes {
          __typename
        }
        archive {
          attestationMessage
          worklistRequiredToFinalize
          patientIdRequiredToFinalize
          worksheetRequiredToFinalize
          examTypeRequiredToFinalize
          studyAuthorRequiredToRequestFinalization
          ehrAssociations {
            __typename
          }
        }
        patient {
          medicalRecordNumber
          nameFirst
          nameLast
          nameMiddle
          namePrefix
          nameSuffix
          sex
          birthDate
        }
        latestStudyEvent {
          type: __typename
          ... on Node {
            id
          }
        }
        organization {
          id
          viewerCanFinalize
        }
        ...StudyFilters_study
        ...useRefreshStudyDialog_study
        ...studyAuthors_study
      }
    `,
    studyRef,
  );

  const viewerIsPrimaryAuthor = study.createdBy?.id === viewer.profile?.id;

  const openRefreshDialog = useRefreshStudyDialog(study);

  // XXX: Subscriptions can update the lastStudyEvent,
  // but do not fire for new images. We cache the id to ensure
  // that we don't accidentally switch to a new study event that skips over image changes
  const latestStudyEventId = useRef(study.latestStudyEvent?.id);

  // FIXME: remove ExamPageSidebar_study when patient is not a Node
  const [mutate, loading] = useMutationWithError<Mutation>(
    graphql`
      mutation useStudyFinalization_FinalizeMutation(
        $input: FinalizeDraftStudyInput!
      ) {
        finalizeDraftStudyOrError(input: $input) {
          ... on FinalizeDraftStudyPayload {
            study {
              finalizedAt
              organization {
                studyStatistics {
                  numDraftStudiesFromViewer
                  numDraftStudiesFromViewerAll
                  numSignatureRequestedFromViewer
                  numNeedsReviewFromViewer
                }
              }
              ...ExamPageSidebar_study
              ...StudyPermissions_allStudyPermissions
            }
          }
          ...isUnseenChangesError_error
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
    {
      updater: (store, payloadData) => {
        const newStudyStatistics =
          payloadData.finalizeDraftStudyOrError?.study?.organization
            ?.studyStatistics;

        if (isStudyAuthor(study, viewer.profile!.id) && newStudyStatistics) {
          updateNumStudies(
            study!.organization!.id,
            store,
            newStudyStatistics,
            viewer.profile!.id,
          );
          studyRangeDeleteUpdater(
            store,
            study,
            [StudyListFilter.DRAFTS, StudyListFilter.MY_SIGNATURE_REQUESTED],
            viewer.profile!.id,
          );
        }
      },
    },
  );

  let finalizationMode;
  let analyticsEvent: AnalyticsEvent;
  if (
    !canUseRestrictStudyFinalization ||
    (study.viewerCanFinalize && !study.finalizationRequiresAttestation) ||
    (study.viewerCanFinalize &&
      !viewerIsPrimaryAuthor &&
      !study.hasBeenFinalized) // aka skipping the resident
  ) {
    finalizationMode = FinalizationMode.FINALIZE;
    analyticsEvent = 'studyFinalized';
  } else if (
    study.viewerCanFinalize &&
    study.finalizationRequiresAttestation
  ) {
    finalizationMode = FinalizationMode.SIGN_AND_FINALIZE;
    analyticsEvent = 'studySignedAndFinalized';
  } else if (
    !study.viewerCanFinalize &&
    study.finalizationRequiresAttestation
  ) {
    finalizationMode = FinalizationMode.REQUEST_FINALIZATION;
    analyticsEvent = 'studyAttestationRequested';
  } else if (
    !study.viewerCanFinalize &&
    !study.finalizationRequiresAttestation
  ) {
    // This shouldn't happen but for defensiveness allow the user to request finalization.
    finalizationMode = FinalizationMode.REQUEST_FINALIZATION;
    analyticsEvent = 'studyAttestationRequested';
  }

  const restrictions = useMemo(() => {
    const errors: Partial<Record<StudyFinalizationRestriction, boolean>> = {};

    if (
      study.archive!.worksheetRequiredToFinalize &&
      !study.worksheets?.length
    ) {
      errors[StudyFinalizationRestriction.WORKSHEET_REQUIRED] = true;
    }

    if (
      study.archive!.examTypeRequiredToFinalize &&
      !study.examTypes?.length
    ) {
      errors[StudyFinalizationRestriction.EXAM_TYPE_REQUIRED] = true;
    }

    if (!study.createdBy && !study.viewerCanFinalize) {
      errors[StudyFinalizationRestriction.AUTHOR_REQUIRED] =
        // An author is always required. The only time it can be optional is for requesting finalization
        finalizationMode !== FinalizationMode.REQUEST_FINALIZATION ||
        study.archive!.studyAuthorRequiredToRequestFinalization!;
    }
    if (
      autoAssignFinalizer &&
      viewer!.profile!.id === study.transcribedBy?.id
    ) {
      errors[StudyFinalizationRestriction.SCRIBE_CANNOT_SIGN] = true;
    }

    if (
      primaryAuthorMustSign &&
      canUseRestrictStudyFinalization &&
      !study!.hasBeenFinalized &&
      !(
        study.viewerCanFinalize &&
        (study.isPendingFinalization || autoAssignFinalizer)
      ) &&
      study?.createdBy?.id !== viewer!.profile!.id &&
      viewer?.organization?.viewerPermissions?.signing !== 'BASIC'
    ) {
      // Only the primary author is allowed to perform the first signature on a study
      errors[StudyFinalizationRestriction.PRIMARY_AUTHOR_MUST_SIGN] = true;
    }

    if (
      study.archive!.worklistRequiredToFinalize &&
      !study.hasAssociatedWorklistItem
    ) {
      errors[StudyFinalizationRestriction.WORKLIST_REQUIRED] = true;
    } else if (
      canUsePatientIdRequiredToFinalize &&
      study.archive!.patientIdRequiredToFinalize &&
      !study.hasAssociatedWorklistItem &&
      !study.patient?.medicalRecordNumber
    ) {
      errors[StudyFinalizationRestriction.PATIENT_ID_REQUIRED] = true;
    }

    return {
      ...errors,
      isValid: Object.values(errors).filter(Boolean).length === 0,
    };
  }, [
    study,
    autoAssignFinalizer,
    viewer,
    primaryAuthorMustSign,
    canUseRestrictStudyFinalization,
    canUsePatientIdRequiredToFinalize,
    finalizationMode,
  ]);

  const [finalizeDialogBody, finalizeDialogOptions] = useMemo((): [
    React.ReactNode,
    OpenDialogOptions<boolean>,
  ] => {
    switch (finalizationMode) {
      case FinalizationMode.FINALIZE: {
        let message: React.ReactNode = null;
        if (study.finalizationRequiresAttestation) {
          message = study.archive!.attestationMessage;
        } else if (study.archive!.ehrAssociations?.length) {
          message = (
            <FormattedMessage
              {...finalizeMessages.signEhrDialogBodyForCustomPermissions}
            />
          );
        } else {
          message = (
            <FormattedMessage
              {...finalizeMessages.signDialogBodyForCustomPermissions}
            />
          );
        }

        return [
          message,
          {
            title: <FormattedMessage {...finalizeMessages.signDialogTitle} />,
            confirmLabel: <FormattedMessage {...actionMessages.sign} />,
          },
        ];
      }
      case FinalizationMode.SIGN_AND_FINALIZE:
        return [
          study.archive!.attestationMessage!,
          {
            title: (
              <FormattedMessage {...finalizeMessages.attestDialogTitle} />
            ),
            confirmLabel: (
              <FormattedMessage {...finalizeMessages.attestDialogConfirm} />
            ),
          },
        ];
      default:
        return [null, {}];
    }
  }, [study, finalizationMode]);

  const finalize = async () => {
    const input: FinalizeDraftStudyInput = {
      studyId: study.id,
      attestationMessage: study.finalizationRequiresAttestation
        ? study.archive!.attestationMessage
        : null,
      clientLatestStudyEventId: latestStudyEventId.current,
    };
    let unseenChangesError: Error | undefined;

    if (
      !restrictions.isValid ||
      finalizationMode === FinalizationMode.REQUEST_FINALIZATION
    )
      return false;

    const finalized = await dialog.open(
      <>
        {finalizeDialogBody}
        {autoAssignFinalizer && !study.createdBy?.id && (
          <Text as="div" className="mt-6">
            <FormattedMessage
              {...finalizeMessages.signDialogNoPrimaryAuthor}
            />
          </Text>
        )}
      </>,
      {
        ...finalizeDialogOptions,
        modalVariant: 'dark',
        confirmLabel:
          autoAssignFinalizer && !study.createdBy?.id ? (
            <FormattedMessage
              {...finalizeMessages.attestDialogConfirmAsPrimaryAuthor}
            />
          ) : (
            finalizeDialogOptions.confirmLabel
          ),
        runConfirm: async () => {
          try {
            return await mutate({
              input,
            });
          } catch (e: any) {
            // XXX: Swallow unseen changes error and show the refresh dialog
            if (isUnseenChangesError(e)) {
              unseenChangesError = e;
            } else {
              throw e;
            }
            return false;
          }
        },
      },
    );

    const analyticsAttributes = {
      studyId: study.id,
      organizationId: study.organization?.id,
    };
    if (finalized) {
      Analytics.track(analyticsEvent, analyticsAttributes);
      toast.success(<FormattedMessage {...finalizeMessages.signSuccess} />);
    } else {
      Analytics.track('signModalDismissed', analyticsAttributes);
    }

    if (unseenChangesError) {
      const refreshedLatestStudyId = await openRefreshDialog();
      if (refreshedLatestStudyId) {
        latestStudyEventId.current = refreshedLatestStudyId;
      }
    }
    return finalized;
  };

  return { finalize, finalizationMode, loading, restrictions };
}
