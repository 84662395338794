/**
 * @generated SignedSource<<2b15d588272a009488a69d51d6290b75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamStatus_study$data = {
  readonly createdBy: {
    readonly name: string | null;
  } | null;
  readonly finalizationRequests: ReadonlyArray<{
    readonly createdAt: string | null;
    readonly recipient: {
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null> | null;
  readonly finalizedAt: string | null;
  readonly finalizedBy: {
    readonly name: string | null;
  } | null;
  readonly handle: string | null;
  readonly preliminarySignatureAt: string | null;
  readonly readyAt: string | null;
  readonly viewerCanFinalize: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyReviewStatus">;
  readonly " $fragmentType": "ExamStatus_study";
};
export type ExamStatus_study$key = {
  readonly " $data"?: ExamStatus_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamStatus_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamStatus_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyFinalizationRequest",
      "kind": "LinkedField",
      "name": "finalizationRequests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "recipient",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readyAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preliminarySignatureAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "finalizedBy",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_studyReviewStatus",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numWorksheets",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numQaEntries",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Archive",
          "kind": "LinkedField",
          "name": "archive",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "worksheetRequiredForQa",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StandardQa",
          "kind": "LinkedField",
          "name": "standardQa",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "730a782b128fa091ceac78a49dcde6c5";

export default node;
