import localStore from 'store/dist/store.modern';

const WHITELIST = new Set(['cloud', 'localhost', 'ngrok']);

export default function getDomainSubdomainLabel(): string | null {
  const domainLabels = window.location.hostname.split('.');
  return (
    // dev only override
    localStore.get('subdomainLabelOverride') ||
    // *.cloud.* or *.localhost.* should match to the public cloud
    // using hostname above to avoid port-related issues
    // assume others are enterprise domains
    (domainLabels.some((domainLabel) => WHITELIST.has(domainLabel))
      ? null
      : domainLabels[0])
  );
}
