/**
 * @generated SignedSource<<52aac4881ce4ec324f3a1bc241ee181e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorksheetAutosaveForm_worksheet$data = {
  readonly id: string;
  readonly templateVersion: {
    readonly sections: ReadonlyArray<{
      readonly handle: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"WorksheetSectionFieldSet_section" | "WorksheetSection_section">;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"worksheet_templateVersion">;
  } | null;
  readonly values: Record<string, any> | null;
  readonly " $fragmentType": "WorksheetAutosaveForm_worksheet";
};
export type WorksheetAutosaveForm_worksheet$key = {
  readonly " $data"?: WorksheetAutosaveForm_worksheet$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetAutosaveForm_worksheet">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FieldOption",
    "kind": "LinkedField",
    "name": "options",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowOther",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorksheetAutosaveForm_worksheet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorksheetTemplateVersion",
      "kind": "LinkedField",
      "name": "templateVersion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksheetSectionDefinition",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WorksheetSection_section"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WorksheetSectionFieldSet_section"
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "worksheet_templateVersion",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorksheetSectionDefinition",
              "kind": "LinkedField",
              "name": "sections",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "fieldDefinitions",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "fieldDefinition_fieldDefinition",
                      "selections": [
                        {
                          "alias": "typename",
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "required",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": (v1/*: any*/),
                          "type": "SingleSelectFieldDefinition",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": (v1/*: any*/),
                          "type": "MultiSelectFieldDefinition",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "values",
      "storageKey": null
    }
  ],
  "type": "Worksheet",
  "abstractKey": null
};
})();

(node as any).hash = "5e7bf83b4ab9225e9dce0efd9d79701d";

export default node;
