import MutationButton from '@bfly/ui2/MutationButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';

import { CancelPacsPushJobButtonMutation } from './__generated__/CancelPacsPushJobButtonMutation.graphql';
import { CancelPacsPushJobButton_job$data as Job } from './__generated__/CancelPacsPushJobButton_job.graphql';

function CancelPacsPushJobButton({ job }: { job: Job }) {
  return (
    <MutationButton<CancelPacsPushJobButtonMutation>
      variant="text-danger"
      input={{ pacsPushJobId: job.id }}
      mutation={graphql`
        mutation CancelPacsPushJobButtonMutation(
          $input: CancelPacsPushJobInput!
        ) {
          cancelPacsPushJobOrError(input: $input) {
            ... on CancelPacsPushJobPayload {
              study {
                ...StudyEndpointsModalContent_study
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `}
    >
      <FormattedMessage {...actionMessages.cancel} />
    </MutationButton>
  );
}

export default createFragmentContainer(CancelPacsPushJobButton, {
  job: graphql`
    fragment CancelPacsPushJobButton_job on PacsPushJob {
      id
    }
  `,
});
