import Layout from '@4c/layout';
import ArrowLeft from '@bfly/icons/ArrowLeft';
import BlankSlate from '@bfly/ui2/BlankSlate';
import Button from '@bfly/ui2/Button';
import DatePicker from '@bfly/ui2/DatePicker';
import Form from '@bfly/ui2/Form';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import { css } from 'astroturf';
import isEmpty from 'lodash/isEmpty';
import { useRef, useState } from 'react';
import { FormHandle } from 'react-formal/Form';
import { FormattedDate, FormattedMessage, defineMessages } from 'react-intl';
import { graphql } from 'relay-runtime';
import { Writable } from 'utility-types';
import { AnyObjectSchema, object, string } from 'yup';

import { useVariation } from 'components/LaunchDarklyContext';
import ListCardContainer from 'components/ListCardContainer';
import useOrganizationSlug from 'hooks/useOrganizationSlug';
import examMessages from 'messages/examMessages';

import { FhirPatientSearchModalContentQuery } from './__generated__/FhirPatientSearchModalContentQuery.graphql';

// MARK:
const messages = defineMessages({
  patientName: {
    defaultMessage: 'Patient Name',
    id: 'exams.fhirPatientSearch.patientName',
  },
  patientNamePlaceholder: {
    defaultMessage: 'Type to search patient name',
    id: 'exams.fhirPatientSearch.patientNamePlaceholder',
  },
  dob: {
    defaultMessage: 'DOB',
    id: 'exams.fhirPatientSearch.dateOfBirth',
  },
  dobPlaceholder: {
    defaultMessage: 'Date of Birth',
    id: 'exams.fhirPatientSearch.dateOfBirthPlaceholder',
  },
  dobAfterDate: {
    defaultMessage: 'Date of birth must be before today',
    id: 'exams.fhirPatientSearch.dateOfBirthValidation',
  },
  mrn: {
    defaultMessage: 'Patient ID',
    id: 'exams.fhirPatientSearch.patientId',
  },
  mrnPlaceholder: {
    defaultMessage: 'Patient ID',
    id: 'exams.fhirPatientSearch.patientId',
  },
  sex: {
    defaultMessage: 'Sex',
    id: 'exams.fhirPatientSearch.sex',
  },
  or: {
    defaultMessage: 'OR',
    id: 'exams.fhirPatientSearch.formOr',
    description: "'or' as in 'either or'",
  },
  searchPatient: {
    defaultMessage: 'Search Patient',
    id: 'exams.fhirPatientSearch.modalTitle',
  },
  next: {
    defaultMessage: 'Next: Choose Encounter',
    id: 'exams.fhirPatientSearch.nextButton',
  },
});

// -- Helper components
function EmptySearchResultsSection() {
  return (
    <BlankSlate>
      <BlankSlate.Body>
        <FormattedMessage
          defaultMessage="No results found for this search"
          id="exam.emptyTitle"
        />
      </BlankSlate.Body>
    </BlankSlate>
  );
}

function InitialResultsSection() {
  return (
    <BlankSlate>
      <BlankSlate.Title>Patient Search</BlankSlate.Title>
      <BlankSlate.Body>
        <FormattedMessage
          defaultMessage="Use the form above to search for patients. Matching patients will be shown here."
          id="exams.patientSearch.blankSlateBody"
        />
      </BlankSlate.Body>
    </BlankSlate>
  );
}

function BackButton({ onBack }: { onBack: () => void }) {
  return (
    <Button
      variant="text-secondary"
      onClick={onBack}
      className="px-0 mb-3"
      data-bni-id="BackFromWorklistButton"
    >
      <ArrowLeft width={20} className="mr-2" />
      <FormattedMessage {...examMessages.manualEntry} />
    </Button>
  );
}

function Divider() {
  return (
    <div className="flex flex-row flex-nowrap overflow-clip items-center m-2">
      <div className="h-1 border-b border-grey-80 flex-1" />
      <div className="mx-12">
        <FormattedMessage {...messages.or} />
      </div>
      <div />
      <div className="h-1 border-b border-grey-80 flex-1" />
    </div>
  );
}

function PatientInformationField<
  K extends keyof typeof messages,
  V extends string | null | undefined,
>({
  labelKey,
  value,
  includeSeparator = false,
  renderValue,
}: {
  labelKey: K;
  value: V;
  renderValue?: (value: NonNullable<V>) => JSX.Element;
  includeSeparator?: boolean;
}) {
  if (!value) {
    return null;
  }

  return (
    <Text variant="body">
      <FormattedMessage {...messages[labelKey]} />:{' '}
      {renderValue ? renderValue(value!) : value}
      {includeSeparator && ', '}
    </Text>
  );
}

// GN: react-formal requires that all fields be present in the yup schema but
// we want to allow all 3 fields to be filled in or the top 2 or the bottom one
// so we include all fields in each schema and pick the best matching one for
// the form based on user input
const dobField = string().isoDate().max(Date.now(), messages.dobAfterDate);

const patientDobSchema = object({
  patientName: string().required().min(1).trim(),
  dob: dobField.required(),
  patientId: string().nullable(),
});

// This schema is for just a patientId
const patientIdSchema = object({
  // allow patientName and DOB to be undefined
  patientName: string().trim().nullable(),
  dob: dobField.nullable(),
  patientId: string().ensure().min(1).trim(),
});

// This schema is used when the form is empty to prompt the user
// that they need to either fill out the top two fields or the
// bottom field
const emptyFormSchema = object({
  patientName: string().nullable(),
  dob: dobField.nullable(),
  patientId: string().required(
    'Select either Patient Name and DOB or Patient ID',
  ),
});

/**
 * This function returns the yup schema that best represents the state of the form.
 * This is to enable good error messages under the form fields.
 *
 * @param formValues an object containing the name/value pairs from the form
 * @returns a yup schema that best represents the state of the form
 */
function getBestSchema(formValues: Record<string, unknown>): AnyObjectSchema {
  const inputKeys = new Set(
    Object.entries(formValues)
      // remove empty key/value pairs from the set
      .filter(([_, value]) => !!value)
      .map(([key]) => key),
  );

  // if empty, default to the top two fields
  if (inputKeys.size < 1) {
    return emptyFormSchema;
  }

  if (inputKeys.has('patientId')) {
    // if we have at least the patientId, we don't need the other two fields
    // however, we will still allow searching on them
    return patientIdSchema;
  }
  // if we don't have a patientId, require the name and dob
  return patientDobSchema;
}

export type FhirPatientSearchSelection = NonNullable<
  NonNullable<
    NonNullable<
      FhirPatientSearchModalContentQuery['response']['organization']
    >['fhirPatients']
  >['0']
>;

function FhirPatientSearch({
  onBack,
  onHide,
  onSelect,
}: {
  onBack: () => void;
  onHide: () => void;
  onSelect: (patient: FhirPatientSearchSelection) => void;
}) {
  const canEditPhi = !useVariation('disable-phi-entry');
  const formHandle = useRef<FormHandle | null>();
  const [schema, setSchema] = useState(emptyFormSchema);
  const [selection, setSelection] =
    useState<FhirPatientSearchSelection | null>();
  const organizationSlug = useOrganizationSlug();
  const [queryVariables, setQueryVariables] = useState<
    FhirPatientSearchModalContentQuery['variables']
  >({
    skip: true,
  });

  // has the user made a search query yet?
  const hasSearched = !queryVariables.skip;

  const fhirPatientsQuery = useQuery<FhirPatientSearchModalContentQuery>(
    graphql`
      query FhirPatientSearchModalContentQuery(
        $name: String
        $birthDate: Date
        $medicalRecordNumber: String
        # defaulting to an empty string makes it easier to set the variables
        # and skip the query when the form is empty
        $organizationSlug: String = ""
        $skip: Boolean!
      ) {
        organization: organizationBySlug(slug: $organizationSlug)
          @skip(if: $skip) {
          fhirPatients(
            name: $name
            birthdate: $birthDate
            mrn: $medicalRecordNumber
          ) {
            name
            firstName
            lastName
            birthDate
            medicalRecordNumber
            mrnType
            sex
            patientFhirId
          }
        }
      }
    `,
    {
      variables: queryVariables,
    },
  );

  const isLoading =
    !fhirPatientsQuery.data && !fhirPatientsQuery.error && hasSearched;

  const handleSubmit = (input: typeof emptyFormSchema['__outputType']) => {
    // unset the selection
    setSelection(null);

    setQueryVariables({
      // birthDate is a "Date" and not a "DateTime" - we need to strip the time component off
      birthDate: input.dob?.split('T')?.at(0),
      medicalRecordNumber: input.patientId || null,
      name: input.patientName || null,
      organizationSlug,
      skip: false,
    });
    fhirPatientsQuery.retry?.();
  };

  const patientData = fhirPatientsQuery.data?.organization?.fhirPatients ?? [];
  const results = !isEmpty(
    fhirPatientsQuery.data?.organization?.fhirPatients,
  ) ? (
    <FormCheckGroup
      data={patientData as Writable<typeof patientData>}
      type="radio"
      onChange={(patient) => {
        setSelection(patient);
      }}
      name="patient"
      value={selection}
      renderItem={(patient: FhirPatientSearchSelection) => {
        return (
          <>
            <Text variant="body-bold">{patient.name}</Text>
            <Layout direction="column" className="mb-6 mt-2">
              <span>
                <PatientInformationField
                  labelKey="sex"
                  value={patient.sex}
                  includeSeparator={!!patient.birthDate}
                />
                <PatientInformationField
                  labelKey="dob"
                  value={patient.birthDate}
                  renderValue={(v) => (
                    <FormattedDate value={v} timeZone="UTC" />
                  )}
                />
              </span>
              <Text variant="body">MRN: {patient.medicalRecordNumber}</Text>
            </Layout>
          </>
        );
      }}
      variant="dark"
      className={css`
        label {
          align-items: center;
          width: 100%;
          display: flex;
          margin: 0 !important;

          span {
            width: 100%;
          }
        }
      `}
    />
  ) : (
    <EmptySearchResultsSection />
  );

  let containerContent: JSX.Element | null = null;
  if (isLoading) {
    containerContent = <LoadingIndicator />;
  } else if (hasSearched && fhirPatientsQuery.data) {
    containerContent = results;
  } else {
    containerContent = <InitialResultsSection />;
  }

  return (
    <>
      <div className="px-5 pt-3">
        {canEditPhi && <BackButton onBack={onBack} />}
        <Modal.Title>
          <FormattedMessage {...messages.searchPatient} />
        </Modal.Title>
      </div>

      <Modal.Body>
        <Form
          submitForm={handleSubmit}
          schema={schema}
          ref={(form) => {
            formHandle.current = form;
          }}
          onChange={(input) => {
            setSchema(getBestSchema(input));
          }}
        >
          <Form.FieldGroup
            variant="secondary"
            label={messages.patientName}
            fluid
            horizontal
            name="patientName"
            type="text"
            placeholder={messages.patientNamePlaceholder}
            autoFocus
          />
          <Form.Group label={messages.dob} fluid horizontal>
            <Form.Field
              as={DatePicker}
              variant="secondary"
              name="dob"
              placeholder={messages.dobPlaceholder}
              max={new Date()}
              valueFormat={{
                // See options in https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#parameters
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  // this triggers parsing and saving the value to the
                  // form before submitting the form
                  e.target?.blur();
                  // we want to first allow the date picker to format the date
                  // then for the form to run validations so this puts the submission
                  // on the back of the task queue. Otherwise when the user presses enter,
                  // the form won't actually submit.
                  setTimeout(() => {
                    formHandle.current?.submit();
                  }, 1);
                }
              }}
            />
            <Form.Message for="dob" />
          </Form.Group>
          <Divider />
          <Form.FieldGroup
            fluid
            horizontal
            label={messages.mrn}
            name="patientId"
            type="text"
            placeholder={messages.mrnPlaceholder}
            variant="secondary"
          />
          <Layout direction="row" justify="space-between" className="m-4">
            <Form.Submit
              size="lg"
              variant="primary"
              className="ml-auto"
              disabled={isLoading}
            >
              Search
            </Form.Submit>
          </Layout>
        </Form>
        <ListCardContainer variant="secondary">
          {containerContent}
        </ListCardContainer>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            size="lg"
            variant="primary"
            onClick={() => {
              if (selection) {
                onSelect(selection);
              }
            }}
            disabled={!selection}
          >
            <FormattedMessage {...messages.next} />
          </Button>
          <Button size="lg" variant="secondary" onClick={onHide}>
            <FormattedMessage {...examMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default FhirPatientSearch;
