import useMountEffect from '@restart/hooks/useMountEffect';
import { useRef } from 'react';

import { RoutePageProps } from 'components/Route';

// XXX: This is a nasty hack for dealing with the fact that AWS Cognito doesn't
// support HTTP-Redirect binding for logout. We just translate the redirect
// to a POST ¯\_(ツ)_/¯
function FederatedAuthLogoutCallbackPage({ match }: RoutePageProps) {
  const formRef = useRef<HTMLFormElement | null>(null);
  useMountEffect(() => {
    // We want to follow the POST back to the Cognito SLO URL, where we expect
    // to be redirected back to the cloud.
    formRef.current!.submit();
  });

  return (
    <form
      action={globalThis.bflyConfig.COGNITO_SLO_URL}
      method="POST"
      ref={formRef}
    >
      {Object.entries<string>(match.location.query).map(([name, value]) => (
        <input key={name} type="hidden" name={name} value={value} />
      ))}
    </form>
  );
}

export default FederatedAuthLogoutCallbackPage;
