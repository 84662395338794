import Text from '@bfly/ui2/Text';
import formatName from '@bfly/utils/formatName';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { VetClientName_vetPatient$data as VetPatient } from './__generated__/VetClientName_vetPatient.graphql';

interface Props {
  vetPatient: VetPatient;
  className?: string;
}

function VetClientName({ vetPatient, className }: Props) {
  const clientName = formatName({
    nameFirst: vetPatient.clientNameFirst,
    nameMiddle: vetPatient.clientNameMiddle,
    nameLast: vetPatient.clientNameLast,
    namePrefix: vetPatient.clientNamePrefix,
    nameSuffix: vetPatient.clientNameSuffix,
  });

  if (!clientName) {
    return (
      <Text variant="inherit" color="subtitle" className={className}>
        <FormattedMessage
          id="vetClientName.empty"
          defaultMessage="No Client Name"
        />
      </Text>
    );
  }

  return <span className={className}>{clientName}</span>;
}

export default createFragmentContainer(VetClientName, {
  vetPatient: graphql`
    fragment VetClientName_vetPatient on VetPatient {
      clientNameFirst
      clientNameMiddle
      clientNameLast
      clientNamePrefix
      clientNameSuffix
    }
  `,
});
