/**
 * @generated SignedSource<<87c71a41a4fe7837f9b181348045e246>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancelEhrOruJobButton_job$data = {
  readonly id: string;
  readonly " $fragmentType": "CancelEhrOruJobButton_job";
};
export type CancelEhrOruJobButton_job$key = {
  readonly " $data"?: CancelEhrOruJobButton_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancelEhrOruJobButton_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancelEhrOruJobButton_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "EhrOruJob",
  "abstractKey": null
};

(node as any).hash = "56a1e81f406c4fc4f1c2b3a0b7df97c3";

export default node;
