/**
 * @generated SignedSource<<975eabb8a4582dfb3816a37971da4228>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type organizations_OrganizationSettingsPage_Query$variables = {};
export type organizations_OrganizationSettingsPage_Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DomainOrganizationsPage_viewer">;
  } | null;
};
export type organizations_OrganizationSettingsPage_Query = {
  response: organizations_OrganizationSettingsPage_Query$data;
  variables: organizations_OrganizationSettingsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "organizations_OrganizationSettingsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainOrganizationsPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "organizations_OrganizationSettingsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationConnection",
                "kind": "LinkedField",
                "name": "organizationConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c48671f29d9da569293a2fd51ee4ed1d",
    "id": null,
    "metadata": {},
    "name": "organizations_OrganizationSettingsPage_Query",
    "operationKind": "query",
    "text": "query organizations_OrganizationSettingsPage_Query {\n  viewer {\n    ...DomainOrganizationsPage_viewer\n    id\n  }\n}\n\nfragment DomainOrganizationsPage_viewer on Viewer {\n  domain {\n    organizationConnection {\n      edges {\n        node {\n          __typename\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8e7bfa602103653e883a4030bd1a04f";

export default node;
