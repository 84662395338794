/**
 * @generated SignedSource<<ce0bec8bc0cc3ee717503d6b5956ed35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySearchPage_userProfile$data = {
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBar_userProfile">;
  readonly " $fragmentType": "StudySearchPage_userProfile";
};
export type StudySearchPage_userProfile$key = {
  readonly " $data"?: StudySearchPage_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySearchPage_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySearchPage_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBar_userProfile"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "f7fed5fc2739c8db9df4523c88ba3d7e";

export default node;
