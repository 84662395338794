/**
 * @generated SignedSource<<5d9f03699e0c544b21d75a3abd06ca54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarGlobal_organization$data = {
  readonly id: string;
  readonly " $fragmentType": "SearchBarGlobal_organization";
};
export type SearchBarGlobal_organization$key = {
  readonly " $data"?: SearchBarGlobal_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarGlobal_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBarGlobal_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a765fc5d773b172e2036f6b4d64583b6";

export default node;
