import useQuery from '@bfly/ui2/useQuery';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';

import { measurements_ExamImageMeasurementsPage_Query as ExamQuery } from './__generated__/measurements_ExamImageMeasurementsPage_Query.graphql';
import { measurements_StudyImageMeasurementsPageQuery as StudyImageMeasurementsPageQuery } from './__generated__/measurements_StudyImageMeasurementsPageQuery.graphql';

export type { ExamQuery };

const examMeasurementsQuery = graphql`
  query measurements_ExamImageMeasurementsPage_Query(
    $studyImageHandle: String!
  ) {
    studyImage(handle: $studyImageHandle) {
      ...ExamImageMeasurementsPage_studyImage
    }
  }
`;

export function usePreloadMeasurements(
  studyImageHandle: string,
  skip?: boolean,
) {
  useQuery<ExamQuery>(examMeasurementsQuery, {
    skip,
    variables: { studyImageHandle },
  });
}

export const examMeasurementRoutes = (
  <Route<ExamQuery>
    hideSidePanel
    path="measurements"
    query={examMeasurementsQuery}
    getComponent={() =>
      import(
        /* webpackChunkName: "measurements" */ './components/ExamImageMeasurementsPage'
      ).then((m) => m.default)
    }
  />
);

export default (
  <Route<StudyImageMeasurementsPageQuery>
    hideSidePanel
    pageLayout="fill"
    path=":studyImageHandle/measurements"
    prerender={checkFlagsOr404('cloud-measurements')}
    getComponent={() =>
      import(
        /* webpackChunkName: "measurements" */ './components/StudyImageMeasurementsPage'
      ).then((m) => m.default)
    }
    query={graphql`
      query measurements_StudyImageMeasurementsPageQuery(
        $studyImageHandle: String!
      ) {
        studyImage(handle: $studyImageHandle) {
          ...StudyImageMeasurementsPage_studyImage
        }
      }
    `}
  />
);
