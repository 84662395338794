import Text, { TextProps } from '@bfly/ui2/Text';
import formatName from '@bfly/utils/formatName';
import { FormattedMessage } from 'react-intl';
import { RelayProp, createFragmentContainer, graphql } from 'react-relay';

import type { PatientName_patient$data as Patient } from './__generated__/PatientName_patient.graphql';

interface Props extends TextProps {
  patient: Patient | null;
  className?: string;
  relay: RelayProp;
}

function PatientName({ patient, relay: _, ...props }: Props) {
  const patientName = patient && formatName(patient);

  if (!patientName) {
    return (
      <Text variant="inherit" {...props} color="subtitle">
        <FormattedMessage
          id="patientName.empty"
          defaultMessage="No Patient Name"
        />
      </Text>
    );
  }

  return (
    <Text variant="inherit" {...props}>
      {patientName}
    </Text>
  );
}

export default createFragmentContainer(PatientName, {
  patient: graphql`
    fragment PatientName_patient on PatientInterface {
      nameFirst
      nameLast
      nameMiddle
      namePrefix
      nameSuffix
    }
  `,
});
