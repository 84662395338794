import Layout from '@4c/layout';
import Navbar from '@bfly/ui2/Navbar';
import Page from '@bfly/ui2/Page';
import SideMenu from '@bfly/ui2/SideMenu';
import { Link } from 'found';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { demoElementRoute, demoRoute } from 'routes/demo';

import Elements from '../elements';

export default function DemoBasePage({ children }: PropsWithChildren) {
  return (
    <Page layout="fill">
      <Navbar>
        <Navbar.Brand as={Link} to={demoRoute()}>
          Demo
        </Navbar.Brand>
        <Layout.Spacer />

        <Link to="/" as={Navbar.NavItem} active={false} data-bni-id="Cloud">
          <FormattedMessage id="demoProductNav.cloud" defaultMessage="Cloud" />
        </Link>
      </Navbar>
      <Page.Container>
        <Page.SidePanel
          data-bni-id="AppSidePanel"
          className="flex flex-col justify-between pb-0 w-40"
        >
          <SideMenu.Header
            id="elements"
            aria-controls="demo.Elements"
            transform="capitalize"
            variant="body-bold"
          >
            <FormattedMessage id="demo.Elements" defaultMessage="Elements" />
          </SideMenu.Header>
          <SideMenu className="mb-auto">
            {Elements.map(({ title, path }) => (
              <Link key={path} to={demoElementRoute({ element: path })}>
                {(linkProps) => (
                  <SideMenu.ListItem id={path}>
                    <SideMenu.Link {...linkProps} active={false}>
                      {title}
                    </SideMenu.Link>
                  </SideMenu.ListItem>
                )}
              </Link>
            ))}
          </SideMenu>
        </Page.SidePanel>
        <Page.Main>{children}</Page.Main>
      </Page.Container>
    </Page>
  );
}
