import { ReactNode } from 'react';

import { ColumnArrayOption } from '../types';

export default function ColumnArrayOptions() {
  const dictionary: Record<string, { label: string; render: ReactNode }> = {};

  return {
    add: (value: string, label: string, render?: ReactNode) => {
      dictionary[value] = { label, render };
    },
    get: (): ColumnArrayOption[] =>
      Object.entries(dictionary).map(([value, { label, render }]) => ({
        value,
        label,
        render,
      })),
  };
}
