/**
 * @generated SignedSource<<5b0e2a8830140f84aca3b4f1082a4732>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyDataGrid_viewer$data = {
  readonly profile: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "StudyDataGrid_viewer";
};
export type StudyDataGrid_viewer$key = {
  readonly " $data"?: StudyDataGrid_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyDataGrid_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyDataGrid_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "60088b3b013f822de7a62bac79dea9e2";

export default node;
