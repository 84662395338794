import Layout from '@4c/layout';
import Help from '@bfly/icons/Help';
import ItemGrid from '@bfly/ui2/ItemGrid';
import RelayInfiniteList from '@bfly/ui2/RelayInfiniteList';
import Tooltip from '@bfly/ui2/Tooltip';
import getNodes from '@bfly/utils/getNodes';
import { css, stylesheet } from 'astroturf';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from 'react-relay';

import ItemGridScrollObserver from 'components/ItemGridScrollObserver';
import { useVariation } from 'components/LaunchDarklyContext';

import ArchiveMemberItemGridRow from './ArchiveMemberItemGridRow';
import { ArchiveMemberItemGrid_archive$data as Archive } from './__generated__/ArchiveMemberItemGrid_archive.graphql';

const PAGE_SIZE = 30;

const styles = stylesheet`
  .tooltip {
    max-width: 25rem;
  }
`;

interface Props {
  archive: Archive;
  relay: RelayPaginationProp;
}

function ArchiveMemberItemGrid({ archive, relay }: Props) {
  const canUseQa = useVariation('worksheets-review');

  const memberships = getNodes(archive.membershipConnection);

  let span = 3;
  if (archive.hasRestrictedFinalization) span--;
  if (canUseQa) span--;

  return (
    <ItemGrid templateColumns="minmax(0, 3fr) repeat(3, minmax(auto, 1fr))">
      <thead>
        <ItemGrid.Row>
          <ItemGrid.Header
            css={css`
              grid-column-end: span ${span};
            `}
          >
            <FormattedMessage
              id="archiveMember.table.member"
              defaultMessage="User"
            />
          </ItemGrid.Header>

          {archive.hasRestrictedFinalization && (
            <ItemGrid.Header>
              <Tooltip.Trigger
                id="restricted-finalization"
                placement="bottom"
                className={styles.tooltip}
                tooltip={
                  <FormattedMessage
                    id="archiveMember.table.sign.info"
                    defaultMessage="This archive requires an attestation message, therefore only selected members can “Sign” studies."
                  />
                }
              >
                <Layout pad={2} align="center" className="whitespace-nowrap">
                  <span>
                    <FormattedMessage
                      id="archiveMember.table.sign"
                      defaultMessage="Can sign"
                    />
                  </span>

                  <Help className="h-3 w-3" />
                </Layout>
              </Tooltip.Trigger>
            </ItemGrid.Header>
          )}

          {canUseQa && (
            <ItemGrid.Header className="whitespace-nowrap">
              <FormattedMessage
                id="archiveMember.table.qa"
                defaultMessage="Can QA"
              />
            </ItemGrid.Header>
          )}

          <ItemGrid.Header />
        </ItemGrid.Row>
      </thead>
      <tbody>
        <RelayInfiniteList
          pageSize={PAGE_SIZE}
          relayPagination={relay}
          loadingIndicator={<ItemGrid.RowLoadingIndicator />}
          renderScrollObserver={(scrollObserver) => (
            <ItemGridScrollObserver scrollObserver={scrollObserver} />
          )}
        >
          {memberships.map((membership) => (
            <ArchiveMemberItemGridRow
              span={span}
              key={membership.id}
              membership={membership}
              archive={archive}
            />
          ))}
        </RelayInfiniteList>
      </tbody>
    </ItemGrid>
  );
}

export default createPaginationContainer(
  ArchiveMemberItemGrid,
  {
    archive: graphql`
      fragment ArchiveMemberItemGrid_archive on Archive
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 30 }
        cursor: { type: "String" }
      ) {
        id
        hasRestrictedFinalization
        membershipConnection(first: $count, after: $cursor)
          @connection(key: "Archive_membershipConnection") {
          edges {
            node {
              id
              type
              userProfile {
                name
              }
              ...ArchiveMemberItemGridRow_membership
            }
          }
        }
        ...ArchiveMemberItemGridRow_archive
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: ({ archive }) => archive!.membershipConnection,
    getVariables: ({ archive }, { count, cursor }) => ({
      count,
      cursor,
      archiveId: archive!.id,
    }),
    query: graphql`
      query ArchiveMemberItemGridPaginationQuery(
        $count: Int!
        $cursor: String
        $archiveId: ID!
      ) {
        archive: node(id: $archiveId) {
          ...ArchiveMemberItemGrid_archive
            @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
