/**
 * @generated SignedSource<<8a1353b18dac6960b089d0cb6caf0b59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateArchiveControl_organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreateArchiveModal_organization">;
  readonly " $fragmentType": "CreateArchiveControl_organization";
};
export type CreateArchiveControl_organization$key = {
  readonly " $data"?: CreateArchiveControl_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateArchiveControl_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateArchiveControl_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateArchiveModal_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c05ef9ed7d8af1accef14d59dc044165";

export default node;
