/**
 * @generated SignedSource<<637dc35b2dca8f8a984cf35ebbbc710b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Notification_notification$data = {
  readonly id?: string;
  readonly readAt: string | null;
  readonly " $fragmentType": "Notification_notification";
};
export type Notification_notification$key = {
  readonly " $data"?: Notification_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"Notification_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Notification_notification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readAt",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    }
  ],
  "type": "NotificationInterface",
  "abstractKey": "__isNotificationInterface"
};

(node as any).hash = "70235efac02b99c1ba2f5b3ce0f47110";

export default node;
