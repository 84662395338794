/**
 * @generated SignedSource<<eccab277faebeffccce6fc4f594ee8ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canManageEndpoints$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "StudyPermissions_canManageEndpoints";
};
export type StudyPermissions_canManageEndpoints$key = {
  readonly " $data"?: StudyPermissions_canManageEndpoints$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canManageEndpoints">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canManageEndpoints"
};

(node as any).hash = "9c5d83a046b5bf60f2a40282c7c3eb36";

export default node;
