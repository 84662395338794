import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Text from '@bfly/ui2/Text';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import getNodes from '@bfly/utils/getNodes';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { NotificationCenterMarkAllReadControlMarkNotificationCenterAllReadMutation as MarkNotificationCenterAllReadMutation } from './__generated__/NotificationCenterMarkAllReadControlMarkNotificationCenterAllReadMutation.graphql';
import { NotificationCenterMarkAllReadControl_MarkNotificationCenterAllClearedMutation as MarkNotificationCenterAllClearedMutation } from './__generated__/NotificationCenterMarkAllReadControl_MarkNotificationCenterAllClearedMutation.graphql';
import { NotificationCenterMarkAllReadControl_notificationCenter$data as NotificationCenter } from './__generated__/NotificationCenterMarkAllReadControl_notificationCenter.graphql';

const markAllReadMutation = graphql`
  mutation NotificationCenterMarkAllReadControlMarkNotificationCenterAllReadMutation(
    $input: MarkNotificationCenterAllReadInput!
  ) {
    markNotificationCenterAllReadOrError(input: $input) {
      ... on MarkNotificationCenterAllReadPayload {
        notificationCenter {
          numUnreadNotifications
          ...NotificationList_notificationCenter
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

const markNotificationCenterAllCleared = graphql`
  mutation NotificationCenterMarkAllReadControl_MarkNotificationCenterAllClearedMutation(
    $input: MarkNotificationCenterAllClearedInput!
  ) {
    markNotificationCenterAllClearedOrError(input: $input) {
      ... on MarkNotificationCenterAllClearedPayload {
        notificationCenter {
          numUnreadNotifications
          ...NotificationList_notificationCenter
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

function NotificationCenterMarkAllReadControl({
  notificationCenter,
  latestNotificationId,
}: {
  notificationCenter: NotificationCenter;
  latestNotificationId?: string;
}) {
  const [readAllMutate] =
    useMutationWithError<MarkNotificationCenterAllReadMutation>(
      markAllReadMutation,
      {
        input: {
          notificationCenterId: notificationCenter.id,
          latestNotificationId,
        },
      },
    );

  const [removeAllMutate] =
    useMutationWithError<MarkNotificationCenterAllClearedMutation>(
      markNotificationCenterAllCleared,
      {
        input: {
          notificationCenterId: notificationCenter.id,
          latestNotificationId,
        },
      },
    );

  const hasNotifications = !!latestNotificationId;
  const hasUnreadNotifications = getNodes(
    notificationCenter.notifications,
  ).some((notification) => notification.readAt === null);

  return (
    <Layout
      align="center"
      justify="space-between"
      className="border-b border-divider"
    >
      {hasUnreadNotifications ? (
        <Button variant="text-primary" onClick={() => readAllMutate()}>
          <Text variant="sm-bold">
            <FormattedMessage
              id="organization.notificationCenter.markAllRead"
              defaultMessage="Mark all as read"
            />
          </Text>
        </Button>
      ) : (
        <Text color="subtitle" variant="sm-bold" className="px-2 py-1">
          <FormattedMessage
            id="organization.notificationCenter.noUnread"
            defaultMessage="No unread notifications"
          />
        </Text>
      )}
      {hasNotifications && (
        <Button
          variant="text-secondary"
          onClick={() => {
            removeAllMutate();
          }}
        >
          <Text variant="sm-bold" className="text-grey-50 underline">
            <FormattedMessage
              id="organization.notificationCenter.removeAll"
              defaultMessage="Remove all"
            />
          </Text>
        </Button>
      )}
    </Layout>
  );
}

export default createFragmentContainer(NotificationCenterMarkAllReadControl, {
  notificationCenter: graphql`
    fragment NotificationCenterMarkAllReadControl_notificationCenter on NotificationCenter
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 24 } # PAGE_SIZE.
      cursor: { type: "String" }
    ) {
      id
      notifications: notificationConnection(first: $count, after: $cursor)
        @connection(
          key: "NotificationCenterMarkAllReadControl_notifications"
        ) {
        edges {
          node {
            ... on Node {
              id
            }
            typename: __typename
            ... on StudyImageCommentNotification {
              readAt
            }
            ... on WorksheetReviewedNotification {
              readAt
            }
            ... on StudyFinalizationRequestedNotification {
              readAt
            }
          }
        }
      }
    }
  `,
});
