import Heading from '@bfly/ui2/Heading';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import clsx from 'clsx';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { graphql } from 'react-relay';

import RingChartCard from 'components/RingChartCard';
import SkeletonCard from 'components/SkeletonCard';
import { useViewerContext } from 'utils/viewerContext';

import { INDICATORS as messages } from '../messages';
import { IndicatorRingChartCardByExamType } from './IndicatorRingChartCard';
import {
  EduStudiesPortfolioNoQaIndicators_Query as Query,
  EduStudiesPortfolioNoQaIndicators_Query$data as QueryData,
} from './__generated__/EduStudiesPortfolioNoQaIndicators_Query.graphql';

function useData() {
  const viewer = useViewerContext();

  const { data } = useQuery<Query>(
    graphql`
      query EduStudiesPortfolioNoQaIndicators_Query($author: [ID!]) {
        viewer {
          eduStudyStatistics(authors: $author) {
            ...IndicatorRingChartCard_byExamType @relay(mask: false)
            zones {
              percentageCompletelyCaptured
            }
          }
          lastWeek: eduStudyStatistics(
            authors: $author
            capturedAt: { days: 7 }
          ) {
            ...IndicatorRingChartCard_byExamType @relay(mask: false)
          }
          last2Weeks: eduStudyStatistics(
            authors: $author
            capturedAt: { days: 14 }
          ) {
            ...IndicatorRingChartCard_byExamType @relay(mask: false)
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: {
        author: [viewer.profile!.id],
      },
    },
  );

  return data?.viewer;
}

function WeekOverWeek({
  className,
  data,
}: {
  className?: string;
  data?: QueryData['viewer'];
}) {
  const getTotals = (byExamType: any) => {
    return byExamType.reduce(
      (t: number, { numUnsubmitted, numReviewRequested }) =>
        t + numUnsubmitted + numReviewRequested,
      0,
    );
  };

  const last2Weeks = getTotals(data?.last2Weeks?.byExamType || []);

  const lastWeek = getTotals(data?.lastWeek?.byExamType || []);

  const previousWeek = last2Weeks - lastWeek;

  const noChange = lastWeek === previousWeek;

  const id = 'WeekOverWeek';

  return (
    <SkeletonCard
      data-bni-id={id}
      busy={!data}
      className={clsx(
        className,
        'flex-row space-x-2 px-4 pt-4 pb-5 items-center justify-between',
      )}
    >
      <Section className="contents">
        <div className="mr-5 self-start">
          <Heading flush>
            <Text
              as="div"
              variant="display-sm"
              data-bni-id="WeekOverWeekHeader"
            >
              <FormattedNumber value={lastWeek} />
            </Text>
            <div>
              <FormattedMessage {...messages.examsLastWeek} />
            </div>
          </Heading>

          <hr className="my-2 border-divider border-b" />

          <div data-bni-id={`${id}Body`}>
            <div className="flex flex-row items-center">
              {!noChange && (
                <div
                  className={clsx(
                    'mr-2',
                    'w-0 h-0 border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent',
                    lastWeek > previousWeek
                      ? `border-b-[10px] border-b-green`
                      : `border-t-[10px] border-t-danger`,
                  )}
                />
              )}
              <Text>
                <FormattedMessage
                  {...(noChange
                    ? messages.sameAsPreviousWeek
                    : messages.vsPreviousWeek)}
                />
              </Text>
            </div>
          </div>
        </div>
      </Section>
    </SkeletonCard>
  );
}

function AllViewsCaptured({
  className,
  data,
}: {
  className?: string;
  data?: number | null;
}) {
  const { formatMessage } = useIntl();

  const percentageCompletelyCaptured = data || 0;
  const total = percentageCompletelyCaptured ? 100 : 0;

  return (
    <RingChartCard
      loading={typeof data !== 'number'}
      className={className}
      id="AllViewsCaptured"
      data={[
        {
          title: formatMessage(messages.allViewsCaptured),
          value: percentageCompletelyCaptured,
        },
      ]}
      total={total}
      chartInside={{
        message: messages.allViews,
      }}
      header={{
        value: percentageCompletelyCaptured / 100,
        style: 'percent',
        message: messages.allViewsCaptured,
      }}
    />
  );
}

export default function EduStudiesPortfolioNoQaIndicators() {
  const data = useData();

  return (
    <>
      <IndicatorRingChartCardByExamType
        className="w-80"
        id="PortfolioNoQaUnsubmittedExams"
        byExamTypeData={data?.eduStudyStatistics?.byExamType}
        getValue={({ numUnsubmitted, numReviewRequested }) =>
          numUnsubmitted + numReviewRequested || 0
        }
        headerMessage={messages.exams}
      />
      <WeekOverWeek className="w-80" data={data} />
      <AllViewsCaptured
        className="w-80"
        data={data?.eduStudyStatistics?.zones?.percentageCompletelyCaptured}
      />
    </>
  );
}
