import { graphql, readInlineData } from 'react-relay';

import { isLastPublicArchive_archive$key as ArchiveKey } from './__generated__/isLastPublicArchive_archive.graphql';
import { isLastPublicArchive_organization$key as OrganizationKey } from './__generated__/isLastPublicArchive_organization.graphql';

export default function isLastPublicArchive(
  archiveRef: ArchiveKey,
  organizationRef: OrganizationKey,
) {
  const { isPrivate, id } = readInlineData(
    graphql`
      fragment isLastPublicArchive_archive on Archive @inline {
        isPrivate
        id
      }
    `,
    archiveRef,
  );

  const { archiveConnection } = readInlineData(
    graphql`
      fragment isLastPublicArchive_organization on Organization @inline {
        archiveConnection(first: 2147483647)
          @connection(key: "Organization_archiveConnection") {
          edges {
            node {
              isPrivate
              id
            }
          }
        }
      }
    `,
    organizationRef,
  );

  return (
    !isPrivate &&
    !archiveConnection!.edges!.find(
      (archiveEdge) =>
        !archiveEdge!.node!.isPrivate && archiveEdge!.node!.id !== id,
    )
  );
}
