/**
 * @generated SignedSource<<204c5a789f2fd920232847d0544b447f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DefaultQaNotificationRecipients = "EVERYONE" | "NO_ONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExamPageSidebarFooter_study$data = {
  readonly archive: {
    readonly defaultQaNotificationRecipients: DefaultQaNotificationRecipients | null;
    readonly examTypeRequiredToFinalize: boolean | null;
    readonly patientIdRequiredToFinalize: boolean | null;
    readonly worklistRequiredToFinalize: boolean | null;
    readonly worksheetRequiredToFinalize: boolean | null;
  } | null;
  readonly finalizationRequests: ReadonlyArray<{
    readonly recipient: {
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null> | null;
  readonly finalizedAt: string | null;
  readonly id: string;
  readonly isPendingFinalization: boolean | null;
  readonly organization: {
    readonly id: string;
    readonly slug: string | null;
  } | null;
  readonly worksheets: ReadonlyArray<{
    readonly id: string;
    readonly templateVersion: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"worksheet_templateVersion">;
    } | null;
    readonly values: Record<string, any> | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageSidebarQaFooter_study" | "RequestFinalizationModal_study" | "StudyPermissions_canFinalizeStudy" | "StudyPermissions_studyReviewStatus" | "examPageSidebar_useSections_study" | "useStudyFinalization_study">;
  readonly " $fragmentType": "ExamPageSidebarFooter_study";
};
export type ExamPageSidebarFooter_study$key = {
  readonly " $data"?: ExamPageSidebarFooter_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamPageSidebarFooter_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalizedAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPendingFinalization",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worklistRequiredToFinalize",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "patientIdRequiredToFinalize",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worksheetRequiredToFinalize",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "examTypeRequiredToFinalize",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FieldOption",
    "kind": "LinkedField",
    "name": "options",
    "plural": true,
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowOther",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanFinalize",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = [
  (v11/*: any*/),
  (v0/*: any*/)
],
v13 = [
  (v11/*: any*/)
],
v14 = [
  (v0/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v16 = {
  "kind": "InlineDataFragmentSpread",
  "name": "StudyPermissions_studyOrArchiveIsDeleted",
  "selections": [
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v15/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numWorksheets",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Archive",
  "kind": "LinkedField",
  "name": "archive",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheetRequiredForQa",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "kind": "InlineDataFragmentSpread",
  "name": "StudyPermissions_studyReviewStatus",
  "selections": [
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numQaEntries",
      "storageKey": null
    },
    (v18/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StandardQa",
      "kind": "LinkedField",
      "name": "standardQa",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamPageSidebarFooter_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyFinalizationRequest",
      "kind": "LinkedField",
      "name": "finalizationRequests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "recipient",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultQaNotificationRecipients",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksheet",
      "kind": "LinkedField",
      "name": "worksheets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksheetTemplateVersion",
          "kind": "LinkedField",
          "name": "templateVersion",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "worksheet_templateVersion",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WorksheetSectionDefinition",
                  "kind": "LinkedField",
                  "name": "sections",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "fieldDefinitions",
                      "plural": true,
                      "selections": [
                        (v8/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "fieldDefinition_fieldDefinition",
                          "selections": [
                            {
                              "alias": "typename",
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            },
                            (v8/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "required",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v9/*: any*/),
                              "type": "SingleSelectFieldDefinition",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v9/*: any*/),
                              "type": "MultiSelectFieldDefinition",
                              "abstractKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "values",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useStudyFinalization_study",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizationRequiresAttestation",
          "storageKey": null
        },
        (v10/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasAssociatedWorklistItem",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasBeenFinalized",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "transcribedBy",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Worksheet",
          "kind": "LinkedField",
          "name": "worksheets",
          "plural": true,
          "selections": (v13/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamType",
          "kind": "LinkedField",
          "name": "examTypes",
          "plural": true,
          "selections": (v13/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Archive",
          "kind": "LinkedField",
          "name": "archive",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "attestationMessage",
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "studyAuthorRequiredToRequestFinalization",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EhrArchiveAssociation",
              "kind": "LinkedField",
              "name": "ehrAssociations",
              "plural": true,
              "selections": (v13/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Patient",
          "kind": "LinkedField",
          "name": "patient",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "medicalRecordNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameFirst",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameLast",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameMiddle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "namePrefix",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameSuffix",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sex",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "birthDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "latestStudyEvent",
          "plural": false,
          "selections": [
            {
              "alias": "type",
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v14/*: any*/),
              "type": "Node",
              "abstractKey": "__isNode"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyFilters_study",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": (v14/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": (v14/*: any*/),
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useRefreshStudyDialog_study",
          "selections": [
            (v8/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "studyAuthors_study",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "secondaryAuthors",
              "plural": true,
              "selections": (v14/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfile",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": (v14/*: any*/),
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canFinalizeStudy",
      "selections": [
        (v1/*: any*/),
        (v16/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "examPageSidebar_useSections_study",
      "selections": [
        (v1/*: any*/),
        (v17/*: any*/),
        (v18/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "examPageSidebar_useShowQa_study",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "viewerCanQa",
              "storageKey": null
            },
            (v1/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v16/*: any*/),
            (v19/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v19/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequestFinalizationModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamPageSidebarQaFooter_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "c7f0aad3a29c23e682c37d432a82e543";

export default node;
