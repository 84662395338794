/**
 * @generated SignedSource<<4290c32552af1753ebcd0af6bd4c9f9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AvatarCollage_userProfiles$data = ReadonlyArray<{
  readonly avatar: {
    readonly " $fragmentSpreads": FragmentRefs<"Thumbnail_image">;
  } | null;
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentType": "AvatarCollage_userProfiles";
}>;
export type AvatarCollage_userProfiles$key = ReadonlyArray<{
  readonly " $data"?: AvatarCollage_userProfiles$data;
  readonly " $fragmentSpreads": FragmentRefs<"AvatarCollage_userProfiles">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AvatarCollage_userProfiles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Thumbnail_image"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "355931aecc4b7b985fcb569afc31fac6";

export default node;
