import DashboardIcon from '@bfly/icons/Dashboard';
import GearIcon from '@bfly/icons/Gear';
import MembersIcon from '@bfly/icons/Members';
import Link, { LinkToProp } from '@bfly/ui2/Link';
import Pagination from '@bfly/ui2/Pagination';
import React, {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  SVGProps,
} from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ExamQuickFilters from 'components/ExamQuickFilters';
import IconButton from 'components/IconButton';
import { useVariation } from 'components/LaunchDarklyContext';
import StudyListContent from 'components/StudyListContent';
import { PaginationMeta } from 'hooks/usePagedConnection';
import { StudyDataColumnUserPreferenceKey } from 'hooks/useStudyDataColumnsUserPreference';
import { useAnalyticsRoutes } from 'routes/analytics';
import { useArchiveRoutes } from 'routes/archive';
import { STUDY_GRID_PAGE_SIZE } from 'utils/StudyConstants';

import useArchiveSettings from '../utils/useArchiveSettings';
import ArchivePageTitle from './ArchivePageTitle';
import { PagedArchivePage_archive$data as Archive } from './__generated__/PagedArchivePage_archive.graphql';
import { PagedArchivePage_viewer$data as Viewer } from './__generated__/PagedArchivePage_viewer.graphql';

interface Props {
  archive: Archive;
  loading: React.ReactNode;
  viewer: Viewer;
  paginationMeta: PaginationMeta;
  studies: any;
}

type SVG = ForwardRefExoticComponent<
  PropsWithoutRef<SVGProps<SVGSVGElement>> & RefAttributes<SVGSVGElement>
>;

function HeaderIconLink({
  linkTo,
  icon: Icon,
  message,
  testId,
}: {
  linkTo: LinkToProp;
  icon: SVG;
  message: MessageDescriptor;
  testId?: string;
}) {
  return (
    <Link to={linkTo}>
      {({ href, onClick }) => (
        <IconButton
          data-bni-id={testId}
          icon={Icon}
          href={href}
          onClick={onClick}
          title={<FormattedMessage {...message} />}
          placement="bottom"
          className="px-2 self-center"
        />
      )}
    </Link>
  );
}

const archiveMessages = defineMessages({
  members: {
    id: 'archivePage.members',
    defaultMessage: 'Archive Members',
  },
  statisticsDashboard: {
    id: 'archivePage.statisticsDashboard',
    defaultMessage: 'Dashboard',
  },
  statisticsATS: {
    id: 'archivePage.statisticsATS',
    defaultMessage: 'Stats',
  },
  settings: {
    id: 'archivePage.settings',
    defaultMessage: 'Archive Settings',
  },
});

function PagedArchivePage({
  archive,
  loading,
  viewer,
  paginationMeta,
  studies,
}: Props) {
  const { organization } = archive;
  const { index, numEdges, loadNext, loadPrevious, fetching } = paginationMeta;

  const canUseReviews = useVariation('worksheets-review');

  const hasArchiveSettingsPermission = useArchiveSettings();

  const archiveRoutes = useArchiveRoutes();
  const analyticsRoutes = useAnalyticsRoutes();

  const shouldShowMemberButton =
    archive!.organization!.subscription!.isTeam && archive.isPrivate;

  const shouldShowDashboardButton = !archive.deletedAt && canUseReviews;

  const archiveActions = (
    <>
      {shouldShowMemberButton && (
        <HeaderIconLink
          testId="ArchiveMembersIcon"
          linkTo={archiveRoutes.archiveMembers()}
          icon={MembersIcon}
          message={archiveMessages.members}
        />
      )}
      {shouldShowDashboardButton && (
        <HeaderIconLink
          testId="StatsDashboardButton"
          linkTo={{
            pathname: canUseReviews
              ? analyticsRoutes.stats()
              : analyticsRoutes.qaDashboard(),
            // XXX: this would be better safer typed, but
            // it's currently hard to organize code such that
            // things are all in the top level shared folder.
            state: {
              filter: {
                archive: [archive.handle],
                organization: [archive.organization],
              },
            },
          }}
          icon={DashboardIcon}
          message={
            canUseReviews
              ? archiveMessages.statisticsATS
              : archiveMessages.statisticsDashboard
          }
        />
      )}
      {hasArchiveSettingsPermission && !archive.deletedAt && (
        <HeaderIconLink
          testId="ArchiveSettingsIcon"
          linkTo={archiveRoutes.archiveSettings()}
          icon={GearIcon}
          message={archiveMessages.settings}
        />
      )}
    </>
  );

  const headerActions = (
    <>
      <ExamQuickFilters
        tenant={archive.organization!}
        includeAuthors
        includeStatuses
      />
      <Pagination
        data-bni-id="PaginationControls"
        index={index}
        onNextPage={loadNext}
        onPreviousPage={loadPrevious}
        pageSize={STUDY_GRID_PAGE_SIZE}
        numItems={numEdges}
        className="mr-3"
      />
    </>
  );

  return (
    <StudyListContent
      allowMove
      allowDelete={!archive.deletedAt}
      loading={fetching || !!loading}
      lazyLoadImages
      allowStatusFiltering
      navKind="ARCHIVE"
      studies={studies.edges as any}
      title={<ArchivePageTitle archive={archive} title={null} />}
      viewer={viewer}
      archive={archive}
      domain={null}
      organization={organization!}
      headerActions={headerActions}
      archiveActions={archiveActions}
      columnsPreferenceKey={StudyDataColumnUserPreferenceKey.STUDY_LIST}
    />
  );
}

export default createFragmentContainer(PagedArchivePage, {
  archive: graphql`
    fragment PagedArchivePage_archive on Archive {
      id
      handle
      organization {
        id
        handle
        slug
        subscription {
          isTeam
        }
        ...StudyListContent_organization
        ...ExamQuickFilters_tenant @arguments(includeAuthors: true)
      }
      deletedAt
      isPrivate
      ...StudyListContent_archive
      ...ArchivePageTitle_archive
    }
  `,
  viewer: graphql`
    fragment PagedArchivePage_viewer on Viewer {
      ...StudyListContent_viewer
    }
  `,
});
