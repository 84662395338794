/**
 * @generated SignedSource<<554b28f9898015a0a2410d34ee954489>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type OrganizationStudyFinalizedInput = {
  clientSubscriptionId?: string | null;
  organizationId: any;
};
export type StudyListContent_OrganizationStudyFinalizedSubscription$variables = {
  input: OrganizationStudyFinalizedInput;
};
export type StudyListContent_OrganizationStudyFinalizedSubscription$data = {
  readonly organizationStudyFinalized: {
    readonly studyEdge: {
      readonly node: {
        readonly finalizedAt: string | null;
        readonly finalizedBy: {
          readonly name: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type StudyListContent_OrganizationStudyFinalizedSubscription = {
  response: StudyListContent_OrganizationStudyFinalizedSubscription$data;
  variables: StudyListContent_OrganizationStudyFinalizedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalizedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StudyListContent_OrganizationStudyFinalizedSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationStudyFinalizedPayload",
        "kind": "LinkedField",
        "name": "organizationStudyFinalized",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyEdge",
            "kind": "LinkedField",
            "name": "studyEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "finalizedBy",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StudyListContent_OrganizationStudyFinalizedSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationStudyFinalizedPayload",
        "kind": "LinkedField",
        "name": "organizationStudyFinalized",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyEdge",
            "kind": "LinkedField",
            "name": "studyEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "finalizedBy",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "563b62ac964ad12bac8b7df53bfc25aa",
    "id": null,
    "metadata": {},
    "name": "StudyListContent_OrganizationStudyFinalizedSubscription",
    "operationKind": "subscription",
    "text": "subscription StudyListContent_OrganizationStudyFinalizedSubscription(\n  $input: OrganizationStudyFinalizedInput!\n) {\n  organizationStudyFinalized(input: $input) {\n    studyEdge {\n      node {\n        finalizedAt\n        finalizedBy {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41e812787e87ff01b25d45c429699285";

export default node;
