import { graphql, readInlineData } from 'react-relay';

import { studyAuthors_study$key as StudyKey } from './__generated__/studyAuthors_study.graphql';

export function getStudyAuthorIds(studyRef: StudyKey) {
  const study = readInlineData(
    graphql`
      fragment studyAuthors_study on Study @inline {
        secondaryAuthors {
          id
        }
        createdBy {
          id
        }
      }
    `,
    studyRef,
  );

  return [
    study?.createdBy?.id,
    ...(study?.secondaryAuthors || []).map((e) => e?.id),
  ];
}

export function isStudyAuthor(studyRef: StudyKey, viewerId: string) {
  return getStudyAuthorIds(studyRef).includes(viewerId);
}
