import Layout from '@4c/layout';
import Calendar from '@bfly/icons/Calendar';
import CheckMarkSmall from '@bfly/icons/CheckMarkSmall';
import ExternalLinkIcon from '@bfly/icons/ExternalLink';
import LockIcon from '@bfly/icons/Lock';
import Logo from '@bfly/icons/Logo';
import PlayIcon from '@bfly/icons/PlayCircleInverted';
import ShareIcon from '@bfly/icons/ShareCircle';
import Button from '@bfly/ui2/Button';
import Heading from '@bfly/ui2/Heading';
import Link from '@bfly/ui2/Link';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import useMediaQuery from '@restart/hooks/useMediaQuery';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import AspectRatioBox from 'components/AspectRatioBox';
import { useAuth } from 'components/AuthContext';
import { useVariation } from 'components/LaunchDarklyContext';
import LmsRedirect from 'components/LmsRedirect';
import Thumbnail from 'components/Thumbnail';
import { useActiveRoute } from 'components/isRouteActive';
import { routes, useEducationRoutes } from 'routes/education';

import educationMessages from '../messages/educationMessages';
import EducationContentTileMobileActions from './EducationContentTileMobileActions';
import { EducationContentTile_educationContent$data as EducationContent } from './__generated__/EducationContentTile_educationContent.graphql';
import useSaveContentToggle from './useSaveContentToggle';
import { getDueDates } from './utils/dueDatesHelpers';
import useButterflyAcademyAccess from './utils/useButterflyAcademyAccess';

interface Props {
  educationContent: EducationContent;
  onShare: () => void;
}

function EducationContentTile({ educationContent, onShare }: Props) {
  const isRouteActive = useActiveRoute();
  const { isAuthenticated } = useAuth();
  const educationRoutes = useEducationRoutes();
  const hasPremiumContentAccess = useVariation('edu-premium-video-access');
  const butterflyAcademyAccess = useButterflyAcademyAccess();
  const hoverSupported = !useMediaQuery('(any-hover: none)');
  const { message, mutate, loading, showToggle } =
    useSaveContentToggle(educationContent);
  const [lmsRedirect, setLmsRedirect] = useState(false);

  const isAssignedToMeRoute = isRouteActive(routes.assignedCourses());

  const isContentLocked =
    !hasPremiumContentAccess && educationContent.isPremium;

  const viewerHasAccess =
    educationContent.contentType === 'VIDEO'
      ? educationContent.viewerHasAccess && !isContentLocked
      : isAuthenticated() && butterflyAcademyAccess === 'enabled';

  const redirectToAbsorb = () => {
    if (butterflyAcademyAccess === 'enabled') {
      setLmsRedirect(true);
    }
  };

  const daysToDueDate = useMemo(() => {
    return (
      educationContent.course?.dueDate &&
      getDueDates(educationContent.course?.dueDate)
    );
  }, [educationContent]);

  return (
    <AspectRatioBox
      as={Section}
      className={clsx(
        'relative rounded shadow-lg overflow-hidden cursor-pointer pointer-events-none',
        hoverSupported && viewerHasAccess && 'group',
      )}
      data-bni-id="EducationContentTile"
    >
      {educationContent.contentType === 'VIDEO' ? (
        <Link
          className="absolute inset-0 pointer-events-auto"
          to={educationRoutes.educationContent({
            handle: educationContent.handle!,
          })}
        />
      ) : (
        <div
          className="absolute inset-0 pointer-events-auto"
          onClick={redirectToAbsorb}
          onKeyDown={redirectToAbsorb}
        />
      )}

      {educationContent.thumbnails?.length && (
        <Thumbnail
          image={educationContent}
          className={clsx(
            'w-full object-contain',
            !viewerHasAccess && 'opacity-50',
          )}
        />
      )}

      {educationContent.contentType === 'VIDEO' ? (
        <PlayIcon className="w-12 h-12 absolute left-1/2 top-1/2 text-white -translate-x-1/2 -translate-y-1/2 transition-opacity duration-200 opacity-0 group-hover:opacity-80" />
      ) : (
        <>
          {lmsRedirect ? (
            <LoadingIndicator />
          ) : (
            <ExternalLinkIcon className="w-8 h-8 absolute left-1/2 top-1/2 text-white -translate-x-1/2 -translate-y-1/2 transition-opacity duration-200 opacity-0 group-hover:opacity-80" />
          )}
        </>
      )}

      <Layout
        direction="column"
        className="absolute p-4 group-hover:p-3 w-full h-full inset-0 z-10 bg-black/75 transition-transform duration-200 translate-y-1/2 group-hover:translate-y-[calc(100%-5rem)] group-focus-within:translate-y-[calc(100%-5rem)]"
      >
        <Layout className="mb-1 group-hover:hidden group-focus-within:hidden">
          {educationContent.contentType === 'VIDEO' ? (
            <>{educationContent.category!.name}</>
          ) : (
            <Layout
              align="center"
              className="pointer-events-auto group-hover:hidden group-focus-within:hidden"
            >
              <Logo
                width={16}
                height={16}
                className="mr-1"
                css={css`
                  path {
                    fill: var(--bni-blue) !important;
                  }
                `}
              />
              <FormattedMessage {...educationMessages.butterflyAcademy} />
              <ExternalLinkIcon
                width={12}
                height={12}
                className="ml-2 opacity-70"
              />
            </Layout>
          )}

          <Layout.Spacer />

          {!viewerHasAccess ? (
            <LockIcon
              className="w-6 h-6"
              data-bni-id="EducationContentLockIcon"
            />
          ) : (
            <>
              {educationContent.contentType === 'VIDEO' && (
                <PlayIcon className="w-6 h-6" />
              )}
            </>
          )}

          {!hoverSupported && (
            <EducationContentTileMobileActions
              onShare={onShare}
              educationContent={educationContent}
            />
          )}
        </Layout>
        <Layout
          direction="column"
          className="min-h-[25%] group-hover:hidden group-focus-within:hidden"
        >
          <Heading className="mb-2 line-clamp-2">
            {educationContent.name}
          </Heading>

          <Text color="body">
            <FormattedMessage
              {...(educationContent.duration && educationContent.duration > 60
                ? {
                    ...educationMessages.minutes,
                    values: {
                      count: Math.floor(educationContent.duration / 60),
                    },
                  }
                : educationMessages.lessThanMinute)}
            />
          </Text>
        </Layout>
        {educationContent.contentType === 'COURSE' &&
          educationContent.course?.viewerHasCompleted && (
            <Text
              color="body"
              className="flex items-center group-hover:hidden group-focus-within:hidden"
              data-bni-id="EducationContentCompletedLabel"
            >
              <CheckMarkSmall width={14} height={10} className="mr-2" />
              <FormattedMessage {...educationMessages.completed} />
            </Text>
          )}
        {educationContent.course?.dueDate &&
          !educationContent.course?.viewerHasCompleted &&
          isAssignedToMeRoute && (
            <Layout
              className="flex items-center group-hover:hidden group-focus-within:hidden"
              data-bni-id="EducationContentDueDateLabel"
            >
              <Calendar width={15} height={15} />
              <Text
                className={clsx(daysToDueDate! <= 3 && 'text-red', 'ml-3')}
              >
                <FormattedMessage
                  {...(daysToDueDate! < 0 && {
                    ...educationMessages.dueDayAgo,
                    values: {
                      count: daysToDueDate && Math.abs(daysToDueDate),
                    },
                  })}
                  {...(daysToDueDate! >= 0 && {
                    ...educationMessages.dueDays,
                    values: {
                      count: daysToDueDate && daysToDueDate,
                    },
                  })}
                />
              </Text>
            </Layout>
          )}
        <Layout
          justify={
            showToggle &&
            educationContent.contentType === 'VIDEO' &&
            educationContent.viewerHasAccess
              ? 'space-between'
              : 'center'
          }
          className="invisible opacity-0 group-hover:visible group-hover:opacity-100 group-focus-within:visible group-focus-within:opacity-100"
        >
          {showToggle && (
            <Button
              variant="text-secondary"
              className="pointer-events-auto"
              onClick={mutate}
              disabled={loading}
              busy={loading}
              data-bni-id="EducationContentSaveButton"
            >
              {message}
            </Button>
          )}
          {educationContent.contentType === 'VIDEO' &&
            educationContent.viewerHasAccess && (
              <Button
                variant="text-secondary"
                onClick={onShare}
                className="pointer-events-auto"
                data-bni-id="EducationContentShareButton"
              >
                <FormattedMessage {...educationMessages.share} />
                <ShareIcon className="ml-3" />
              </Button>
            )}
        </Layout>
      </Layout>

      {lmsRedirect && educationContent.contentType === 'COURSE' && (
        <LmsRedirect
          contentId={educationContent.course?.absorbId}
          openNewTab
          keepActiveRoute
          onOpen={(isOpen) => {
            if (isOpen) setLmsRedirect(false);
          }}
        />
      )}
    </AspectRatioBox>
  );
}

export default createFragmentContainer(EducationContentTile, {
  educationContent: graphql`
    fragment EducationContentTile_educationContent on EducationContent {
      id
      handle
      name
      viewerHasAccess
      duration
      isPremium
      contentType
      category {
        name
      }
      video {
        vimeoId
      }
      course {
        viewerHasCompleted
        absorbId
        dueDate
      }
      thumbnails {
        url
      }
      ...Thumbnail_image
      ...useSaveContentToggle_educationContent
      ...EducationContentTileMobileActions_educationContent
    }
  `,
});
