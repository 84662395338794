import { join, root, route } from '@4c/spa-routing';

const rootOrganization = join(root, ':organizationSlug');

const referrals = join(rootOrganization, 'referrals');

export const routes = {
  // this should be handled nicer upstream,
  // if you use the provided root you get '' instead of '/'
  rootRoute: route('/'),
  rootOrganization,
  referrals,
};

export const useConfigRoutes = () => {
  return routes;
};
