import { widths } from '@bfly/ui2/tailwind/screens';
import useMediaQuery from '@restart/hooks/useMediaQuery';

const maxSm = widths.md - 0.02;

export const isTinyViewPortMediaQuery = `(max-width: ${maxSm}px), (max-height: 500px)`;

export default function useIsTinyViewPort() {
  return useMediaQuery(isTinyViewPortMediaQuery);
}
