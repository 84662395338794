import useDialog from '@bfly/ui2/useDialog';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useAuth } from 'components/AuthContext';
import ExportSearchProvider from 'components/ExportSearchProvider';
import { useVariation } from 'components/LaunchDarklyContext';
import useKeyboardNavListener from 'hooks/useKeyboardNavListener';
import sessionStore from 'utils/sessionStore';

import { SHOW_SIGN_IN_CONFIRMATION_STATE_KEY } from './AuthProvider';
import { RootPage_viewer$data as Viewer } from './__generated__/RootPage_viewer.graphql';

interface Props {
  children: React.ReactNode;
  viewer: Viewer;
}

function RootPage({ children, viewer }: Props) {
  const dialog = useDialog();
  const isKeyboardNavigating = useKeyboardNavListener();
  const { isAuthenticated, clearAccessToken } = useAuth();
  const shouldConfirmLogIn = useVariation('confirm-logged-in-as');

  // This makes it easier for things to target this via CSS
  useEffect(() => {
    document.body.dataset.keyboardIntent = String(isKeyboardNavigating);
  }, [isKeyboardNavigating]);

  useEffect(() => {
    const showConfirmation = sessionStore.get(
      SHOW_SIGN_IN_CONFIRMATION_STATE_KEY,
      true,
    );

    if (
      viewer &&
      shouldConfirmLogIn &&
      isAuthenticated() &&
      showConfirmation
    ) {
      dialog
        .open(
          <FormattedMessage
            id="rootPage.loggedInUser"
            defaultMessage="You are logged in to Butterfly as {userName} ({email})."
            values={{
              userName: <b>{viewer.profile?.name}</b>,
              email: viewer.email,
            }}
          />,
          {
            title: (
              <FormattedMessage
                id="rootPage.confirmLogIn"
                defaultMessage="Confirm Log In"
              />
            ),
            cancelLabel: (
              <FormattedMessage
                id="rootPage.continueAs"
                defaultMessage="Continue as {user}"
                values={{ user: viewer.profile?.name }}
              />
            ),
            confirmLabel: (
              <FormattedMessage
                id="rootPage.signInAsDifferentUser"
                defaultMessage="Sign in as a different user"
              />
            ),
            runConfirm: () => {
              return clearAccessToken(true);
            },
            backdrop: 'static',
            keyboard: false,
            modalVariant: 'dark',
          },
        )
        .then(() => {
          sessionStore.set(SHOW_SIGN_IN_CONFIRMATION_STATE_KEY, false);
        });
    }
  }, [clearAccessToken, dialog, isAuthenticated, shouldConfirmLogIn, viewer]);

  return <ExportSearchProvider>{children}</ExportSearchProvider>;
}

export default createFragmentContainer(RootPage, {
  viewer: graphql`
    fragment RootPage_viewer on Viewer {
      email
      profile {
        name
      }
    }
  `,
});
