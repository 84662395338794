import CheckMark from '@bfly/icons/CheckMarkThin';
import Spinner from '@bfly/ui2/Spinner';
import { PropsWithChildren, SetStateAction, useRef, useState } from 'react';

/**
 * A hook that wraps useState and triggers a callback after a debounce.
 * Also returns displaying status of the auto saving with checkmark and spinner.
 * @param {(next: T) => Promise<void>} saveCallback Callback to send updated state after debounce
 * @param debounceMs=1000
 * @param successMs=2000
 */
export default function useAutoSaveState<T>(
  defaultValue: T,
  saveCallback: (next: T) => Promise<void>,
  debounceMs = 1000,
  successMs = 2000,
): [
  state: T,
  setState: (next: SetStateAction<T>, immediate?: boolean) => void,
  component: (props: PropsWithChildren) => JSX.Element,
] {
  const savingTimeout = useRef<ReturnType<typeof setTimeout>>();
  const savedTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [status, setStatus] = useState<'saving' | 'saved' | null>(null);

  const [state, setState] = useState<T>(defaultValue);

  const setStateTrigger = (next: SetStateAction<T>, immediate?: boolean) => {
    setStatus('saving');

    if (savingTimeout.current) clearTimeout(savingTimeout.current);
    if (savedTimeout.current) clearTimeout(savedTimeout.current);

    setState((prev) => {
      const nextState =
        typeof next === 'function'
          ? (next as (prevState: T) => T)(prev)
          : next;

      savingTimeout.current = setTimeout(
        () => {
          saveCallback(nextState).then(() => {
            setStatus('saved');
            savingTimeout.current = setTimeout(
              () => {
                setStatus(null);
              },
              immediate ? 10 : successMs,
            );
          });
        },
        immediate ? 5 : debounceMs,
      );

      return nextState;
    });
  };

  const component = ({ children }: PropsWithChildren) => (
    <>
      {status === 'saved' && <CheckMark className="w-4 text-success" />}
      {status === 'saving' && <Spinner size="sm" />}
      {status === null && <>{children}</>}
    </>
  );

  return [state, setStateTrigger, component];
}
