import TrashCanIcon from '@bfly/icons/TrashCan';
import { NestedForm } from '@bfly/ui2/Form';
import Heading from '@bfly/ui2/Heading';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DeleteControl from 'components/DeleteControl';
import Dl from 'components/DescriptionList';
import FieldDefinitionFormFieldGroup from 'components/FieldDefinitionFormFieldGroup';
import IconButton from 'components/IconButton';

import TemplatedFieldListItem from './TemplatedFieldListItem';
import { ExamQa_templateVersion$data as TemplateVersion } from './__generated__/ExamQa_templateVersion.graphql';

interface Props {
  readOnly: boolean;
  onDelete: () => void;
  templateVersion: TemplateVersion;
  values?: Record<string, any> | null;
  name: string;
}

function ExamQa({ onDelete, readOnly, values, templateVersion, name }: Props) {
  const { title, fieldDefinitions } = templateVersion;

  return (
    <>
      <Heading flush className="mb-0 flex">
        <span>{title}</span>
        {!readOnly && (
          <div className="ml-3 grow">
            <DeleteControl
              as={IconButton}
              variant={null}
              className="h-5 px-1"
              runCommit={() => Promise.resolve(onDelete())}
              dialogTitle={
                <FormattedMessage
                  id="examQa.deleteQa.dialog.title"
                  defaultMessage="Delete this Review Card?"
                />
              }
              dialogMessage={
                <FormattedMessage
                  id="examQa.deleteQa.dialog.message"
                  defaultMessage="Deleting this Review Card will remove all the content it contains at the time of deletion."
                />
              }
              title={
                <FormattedMessage
                  id="examQa.deleteQa.title"
                  defaultMessage="Remove Review Card"
                />
              }
              icon={<TrashCanIcon className="text-grey-50 m-0" width={14} />}
              data-bni-id="DeleteQaEntry"
            />
          </div>
        )}
      </Heading>

      {readOnly ? (
        <Dl>
          {fieldDefinitions!.map((fieldDefinition) => (
            <TemplatedFieldListItem
              key={fieldDefinition!.handle!}
              fieldDefinition={fieldDefinition!}
              value={values?.[fieldDefinition!.handle!]}
            />
          ))}
        </Dl>
      ) : (
        <NestedForm name={name}>
          <Dl>
            {fieldDefinitions!.map((fieldDefinition) => (
              <FieldDefinitionFormFieldGroup
                key={fieldDefinition!.handle!}
                fieldDefinition={fieldDefinition!}
              />
            ))}
          </Dl>
        </NestedForm>
      )}
    </>
  );
}

export default createFragmentContainer(ExamQa, {
  templateVersion: graphql`
    fragment ExamQa_templateVersion on QaTemplateVersion {
      id
      title
      fieldDefinitions {
        handle
        ...TemplatedFieldListItem_fieldDefinition
        ...FieldDefinitionFormFieldGroup_fieldDefinition
      }
    }
  `,
});
