/**
 * @generated SignedSource<<635a060c6c6a0193647fe1c66acaeee2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPageWithSidePanel_searchNodes$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_searchNodes">;
  readonly " $fragmentType": "AppPageWithSidePanel_searchNodes";
}>;
export type AppPageWithSidePanel_searchNodes$key = ReadonlyArray<{
  readonly " $data"?: AppPageWithSidePanel_searchNodes$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppPageWithSidePanel_searchNodes">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AppPageWithSidePanel_searchNodes",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BaseAppPage_searchNodes"
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};

(node as any).hash = "636420fa221bdd21c04e7bdea92589e4";

export default node;
