import canUseDOM from 'dom-helpers/canUseDOM';

interface OldStuffNavigator extends Navigator {
  userLanguage?: string;
  browserLanguage?: string;
}

// These are already lower-cased from the download script. The map to lowercase
// here is to be more lenient on test variables.
const AVAILABLE_LOCALES = (globalThis.bflyConfig.AVAILABLE_LOCALES || []).map(
  (a) => a.toLowerCase(),
);

export const DEFAULT_LOCALE = 'en';

const nav: false | OldStuffNavigator =
  typeof navigator !== 'undefined' && navigator;

// https://stackoverflow.com/a/50460605/2108893 for more IE context
const USER_LOCALES =
  canUseDOM &&
  nav &&
  (nav.languages && nav.languages.length
    ? nav.languages
    : [
        nav.userLanguage ||
          nav.language ||
          nav.browserLanguage ||
          DEFAULT_LOCALE,
      ]);

export default function getClosestLocale(
  availableLocales = AVAILABLE_LOCALES,
) {
  if (!USER_LOCALES) return DEFAULT_LOCALE;

  const availableLocalesSet = new Set(availableLocales);

  for (const locale of USER_LOCALES) {
    const tags = locale.toLowerCase().split('-');

    while (tags.length) {
      const candidate = tags.join('-');
      if (availableLocalesSet.has(candidate)) {
        return candidate;
      }

      tags.pop();
    }
  }

  return DEFAULT_LOCALE;
}
