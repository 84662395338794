/**
 * @generated SignedSource<<93b1b6132aefd43180a9618587f59f1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type connections_CreateDicomConnectionPage_Query$variables = {
  organizationSlug: string;
};
export type connections_CreateDicomConnectionPage_Query$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateDicomConnectionPage_connectivityConfig" | "CreateDicomConnectionPage_organization">;
  } | null;
};
export type connections_CreateDicomConnectionPage_Query = {
  response: connections_CreateDicomConnectionPage_Query$data;
  variables: connections_CreateDicomConnectionPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "connections_CreateDicomConnectionPage_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateDicomConnectionPage_connectivityConfig"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateDicomConnectionPage_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "connections_CreateDicomConnectionPage_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "organizationId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "domainId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "ConnectivityConfigInterface",
            "abstractKey": "__isConnectivityConfigInterface"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "practiceType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6f18f9e4f418d1a1225831e72ff498c",
    "id": null,
    "metadata": {},
    "name": "connections_CreateDicomConnectionPage_Query",
    "operationKind": "query",
    "text": "query connections_CreateDicomConnectionPage_Query(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    ...CreateDicomConnectionPage_connectivityConfig\n    ...CreateDicomConnectionPage_organization\n    id\n  }\n}\n\nfragment CreateDicomConnectionPage_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  ...useTenantIdInput_tenant\n}\n\nfragment CreateDicomConnectionPage_organization on Organization {\n  ...DicomConnectionForm_organization\n}\n\nfragment DicomConnectionForm_organization on Organization {\n  subscription {\n    practiceType\n  }\n  ...SecurityModeInput_organization\n}\n\nfragment SecurityModeInput_organization on Organization {\n  subscription {\n    practiceType\n  }\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3467c892a87ddedc023987593d0a0bdd";

export default node;
