/**
 * @generated SignedSource<<1bb59f9da164291596aba2e13c59ea98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type EduStandardQaFeedback = "ADJUST_DEPTH" | "ADJUST_GAIN" | "INCOMPLETE_MEASUREMENTS" | "INCORRECT_ORIENTATION" | "INCORRECT_PRESET" | "INCORRECT_TRANSDUCER" | "MISSING_STANDARD_VIEWS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type eduStandardQa_eduStudy$data = {
  readonly standardQa: {
    readonly comments: string | null;
    readonly feedback: ReadonlyArray<EduStandardQaFeedback> | null;
    readonly imageQuality: number;
  } | null;
  readonly " $fragmentType": "eduStandardQa_eduStudy";
};
export type eduStandardQa_eduStudy$key = {
  readonly " $data"?: eduStandardQa_eduStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"eduStandardQa_eduStudy">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "eduStandardQa_eduStudy"
};

(node as any).hash = "cd24af93c43f3829afa6a6632d04f295";

export default node;
