/**
 * @generated SignedSource<<0aad1924c2786193ee0877b664eb6440>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppSearchGlobal_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarGlobal_tenant">;
  readonly " $fragmentType": "AppSearchGlobal_tenant";
};
export type AppSearchGlobal_tenant$key = {
  readonly " $data"?: AppSearchGlobal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppSearchGlobal_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppSearchGlobal_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBarGlobal_tenant"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "e7f83625adb52316d25124a353ecdfe3";

export default node;
