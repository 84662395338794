/**
 * @generated SignedSource<<e01b43cad6d1e141d2e1c04c2eff41f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EducationContentType = "COURSE" | "VIDEO" | "%future added value";
export type EmptyContentListScreen_Query$variables = {
  contentType?: ReadonlyArray<EducationContentType> | null;
};
export type EmptyContentListScreen_Query$data = {
  readonly lms: {
    readonly educationContentConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"EducationContentTile_educationContent" | "ShareEducationContentModal_educationContent">;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type EmptyContentListScreen_Query = {
  response: EmptyContentListScreen_Query$data;
  variables: EmptyContentListScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contentType"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contentType",
    "variableName": "contentType"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 8
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": [
      "RANKING_ASC"
    ]
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmptyContentListScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContentConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EducationContent",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EducationContentTile_educationContent"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ShareEducationContentModal_educationContent"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmptyContentListScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lms",
        "kind": "LinkedField",
        "name": "lms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContentConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EducationContent",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerHasAccess",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPremium",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EducationContentCategory",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EducationContentVideo",
                        "kind": "LinkedField",
                        "name": "video",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "vimeoId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EducationContentCourse",
                        "kind": "LinkedField",
                        "name": "course",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerHasCompleted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "absorbId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dueDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Thumbnail",
                        "kind": "LinkedField",
                        "name": "thumbnails",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerHasSaved",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "localId",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Thumbnail",
                            "kind": "LinkedField",
                            "name": "thumbnails",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "width",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "ThumbnailedInterface",
                        "abstractKey": "__isThumbnailedInterface"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72991e8b2377b5afecfb96f53b606b7a",
    "id": null,
    "metadata": {},
    "name": "EmptyContentListScreen_Query",
    "operationKind": "query",
    "text": "query EmptyContentListScreen_Query(\n  $contentType: [EducationContentType!]\n) {\n  lms {\n    educationContentConnection(first: 8, contentType: $contentType, sort: [RANKING_ASC]) {\n      edges {\n        node {\n          id\n          ...EducationContentTile_educationContent\n          ...ShareEducationContentModal_educationContent\n        }\n      }\n    }\n  }\n}\n\nfragment EducationContentTileMobileActions_educationContent on EducationContent {\n  id\n  contentType\n  ...useSaveContentToggle_educationContent\n}\n\nfragment EducationContentTile_educationContent on EducationContent {\n  id\n  handle\n  name\n  viewerHasAccess\n  duration\n  isPremium\n  contentType\n  category {\n    name\n    id\n  }\n  video {\n    vimeoId\n  }\n  course {\n    viewerHasCompleted\n    absorbId\n    dueDate\n  }\n  thumbnails {\n    url\n  }\n  ...Thumbnail_image\n  ...useSaveContentToggle_educationContent\n  ...EducationContentTileMobileActions_educationContent\n}\n\nfragment ShareEducationContentModal_educationContent on EducationContent {\n  localId\n  handle\n  isPremium\n  contentType\n  viewerHasAccess\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n\nfragment useSaveContentToggle_educationContent on EducationContent {\n  id\n  viewerHasSaved\n  viewerHasAccess\n}\n"
  }
};
})();

(node as any).hash = "07e9a42b9832365a5989487e1e4c4f84";

export default node;
