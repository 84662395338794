/**
 * @generated SignedSource<<654aadf1805358c08199c43fa9cb0b6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteAccessControl_tenant$data = {
  readonly slug?: string | null;
  readonly subdomainLabel?: string | null;
  readonly " $fragmentType": "RouteAccessControl_tenant";
};
export type RouteAccessControl_tenant$key = {
  readonly " $data"?: RouteAccessControl_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_tenant">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "RouteAccessControl_tenant"
};

(node as any).hash = "158ab882a14243722def88118f876417";

export default node;
