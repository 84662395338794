/**
 * @generated SignedSource<<6534c480aa8dabd1dcaa592b675a38ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveDropdownItem$data = {
  readonly ehrAssociations: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly id: string;
  readonly label: string | null;
  readonly pacsAssociations: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
};
export type ArchiveDropdownItem$key = {
  readonly " $data"?: ArchiveDropdownItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveDropdownItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "ArchiveDropdownItem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PacsArchiveAssociation",
      "kind": "LinkedField",
      "name": "pacsAssociations",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EhrArchiveAssociation",
      "kind": "LinkedField",
      "name": "ehrAssociations",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};
})();

(node as any).hash = "8b8fd0ca9d410eeae3026a9153a66866";

export default node;
