/**
 * @generated SignedSource<<055ce7ec087b577019894362c4e8bc74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudyPermissions_canGenerateStudyPdf$data = {
  readonly finalizedAt: string | null;
  readonly practiceType: StudyPracticeType | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_studyOrArchiveIsDeleted">;
  readonly " $fragmentType": "StudyPermissions_canGenerateStudyPdf";
};
export type StudyPermissions_canGenerateStudyPdf$key = {
  readonly " $data"?: StudyPermissions_canGenerateStudyPdf$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canGenerateStudyPdf">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StudyPermissions_canGenerateStudyPdf"
};

(node as any).hash = "8e7a5370536da9aa9b037bd6f211ee50";

export default node;
