/**
 * @generated SignedSource<<e382c5e7b51f1a0f6cb542d891bc5d78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudySearchSuggestionType = "ARCHIVE" | "DOMAIN_MEMBERSHIP" | "EXAM_TYPE" | "ORGANIZATION" | "ORGANIZATION_MEMBERSHIP" | "STUDY_STATUS" | "STUDY_TAG" | "USER_PROFILE" | "WORKSHEET_TEMPLATE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SearchBarGlobal_suggestionResult$data = {
  readonly node: {
    readonly __typename?: "Archive";
    readonly handle?: string | null;
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_archive">;
  } | {
    readonly __typename?: "DomainMembership";
    readonly userProfile?: {
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_membership">;
  } | {
    readonly __typename?: "ExamType";
    readonly handle?: string | null;
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_examType">;
  } | {
    readonly __typename?: "Organization";
    readonly handle?: string | null;
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_organization">;
  } | {
    readonly __typename?: "OrganizationMembership";
    readonly userProfile?: {
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_membership">;
  } | {
    readonly __typename?: "StudyTag";
    readonly handle?: string | null;
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_studyTag">;
  } | {
    readonly __typename?: "UserProfile";
    readonly handle?: string | null;
    readonly id?: string;
    readonly name?: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_userProfile">;
  } | {
    readonly __typename?: "WorksheetTemplate";
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_worksheetTemplate">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly type: StudySearchSuggestionType | null;
  readonly " $fragmentType": "SearchBarGlobal_suggestionResult";
};
export type SearchBarGlobal_suggestionResult$key = {
  readonly " $data"?: SearchBarGlobal_suggestionResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarGlobal_suggestionResult">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "kind": "InlineDataFragmentSpread",
  "name": "SearchBarListSuggestionOption_userProfile",
  "selections": [
    (v2/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_userProfile"
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v4 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "SearchBarListSuggestionOption_membership",
    "selections": [
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "type": "UserInfoInterface",
        "abstractKey": "__isUserInfoInterface"
      }
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserProfile",
    "kind": "LinkedField",
    "name": "userProfile",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = [
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBarGlobal_suggestionResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "SearchBarListSuggestionOption_archive",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "Archive",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "type": "UserProfile",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "DomainMembership",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "OrganizationMembership",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "SearchBarListSuggestionOption_studyTag",
                  "selections": (v5/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "StudyTag",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "SearchBarListSuggestionOption_examType",
                  "selections": (v5/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "ExamType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "SearchBarListSuggestionOption_organization",
                  "selections": (v5/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "Organization",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "SearchBarListSuggestionOption_worksheetTemplate",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WorksheetTemplateVersion",
                      "kind": "LinkedField",
                      "name": "latestVersion",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "WorksheetTemplate",
              "abstractKey": null
            }
          ],
          "type": "Node",
          "abstractKey": "__isNode"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudySearchSuggestionResult",
  "abstractKey": null
};
})();

(node as any).hash = "394e767f760597f7da79ad331c20feb4";

export default node;
