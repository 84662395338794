import Button from '@bfly/ui2/Button';
import ItemGrid from '@bfly/ui2/ItemGrid';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from 'components/Avatar';
import ListCardContainer from 'components/ListCardContainer';
import useSearchQuery from 'hooks/useSearchQuery';

import FinalizationCandidatesSearchControl from './FinalizationCandidatesSearchControl';
import { FinalizationCandidatesListbox_Query as Query } from './__generated__/FinalizationCandidatesListbox_Query.graphql';
import { FinalizationCandidatesListbox_study$data as Study } from './__generated__/FinalizationCandidatesListbox_study.graphql';

interface Props {
  study: Study;
  value: any[];
  onChange: (candidates: any[]) => void;
}

function useFinalizationCandidatesData(studyId: string, search: string) {
  return useSearchQuery<Query>(
    graphql`
      query FinalizationCandidatesListbox_Query(
        $studyId: ID!
        $search: String
      ) {
        study: node(id: $studyId) {
          ... on Study {
            id
            finalizationRequestRecipientCandidateConnection(
              first: 30
              search: $search
            ) {
              edges {
                node {
                  userProfile {
                    id
                    name
                    ...Avatar_userProfile
                  }
                  email
                  ...FinalizationCandidatesSearchControl_candidates
                }
              }
            }
          }
        }
      }
    `,
    search,
    { studyId },
  );
}

function FinalizationCandidatesListbox({ study, value, onChange }: Props) {
  const [search, setSearch] = useState<string | null>(null);

  const { data, loading } = useFinalizationCandidatesData(
    study.id!,
    search || '',
  );

  const candidates = data
    ? getNodes(data?.study?.finalizationRequestRecipientCandidateConnection)
    : [];

  const handleSelect = (candidate) => {
    const current = candidates.find(
      (c) => c.userProfile!.id === candidate.userProfile.id,
    );
    if (!current) return;
    onChange([...value, current]);
  };

  const handleRemove = (candidate) => {
    onChange(
      value.filter((c) => c.userProfile.id !== candidate.userProfile.id),
    );
  };

  return (
    <>
      <FinalizationCandidatesSearchControl
        loading={loading}
        onSelect={handleSelect}
        onSearch={setSearch}
        search={search || ''}
        candidates={candidates}
      />
      <ListCardContainer className=" mt-2">
        <ItemGrid role="list" templateColumns="auto minmax(0, 1fr) auto">
          {value?.map((candidate) => (
            <ItemGrid.Row key={candidate!.userProfile!.id!}>
              <ItemGrid.Cell css="border-bottom: none;">
                <Avatar width={40} userProfile={candidate!.userProfile!} />
              </ItemGrid.Cell>
              <ItemGrid.Cell>
                <Text color="headline" truncate>
                  {candidate!.userProfile!.name!}
                </Text>
                <Text variant="sm" color="body" truncate>
                  {candidate!.email}
                </Text>
              </ItemGrid.Cell>
              <ItemGrid.ActionsCell>
                <Button
                  variant="text-primary"
                  onClick={() => handleRemove(candidate)}
                >
                  <FormattedMessage
                    id="finalizationCandidateListItem.remove"
                    defaultMessage="Remove"
                  />
                </Button>
              </ItemGrid.ActionsCell>
            </ItemGrid.Row>
          ))}
        </ItemGrid>
      </ListCardContainer>
    </>
  );
}

export default createFragmentContainer(FinalizationCandidatesListbox, {
  study: graphql`
    fragment FinalizationCandidatesListbox_study on Study {
      id
    }
  `,
});
