import notNullish from '@bfly/utils//notNullish';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import withModal from 'utils/withModal';

import SavedSearchModal from './SavedSearchModal';
import { UpdateSavedSearchModalMutation } from './__generated__/UpdateSavedSearchModalMutation.graphql';
import { UpdateSavedSearchModal_studySavedSearch$data as StudySavedSearch } from './__generated__/UpdateSavedSearchModal_studySavedSearch.graphql';

interface StaticContainerProps {
  children: React.ReactNode;
  shouldUpdate?: boolean;
}

const StaticContainer = React.memo(
  ({ children }: StaticContainerProps) => children as React.ReactElement,
  (_, { shouldUpdate }: StaticContainerProps) => !shouldUpdate,
);

interface Props {
  onHide: () => void;
  studySavedSearch: StudySavedSearch;

  organizationSlug: string | null;
}

function UpdateSavedSearchModal({
  studySavedSearch,
  organizationSlug,
  onHide,
}: Props) {
  return (
    <StaticContainer shouldUpdate={!!studySavedSearch}>
      <SavedSearchModal<UpdateSavedSearchModalMutation>
        onHide={onHide}
        successMessage={
          <FormattedMessage
            id="updateSavedSearchModal.success"
            defaultMessage="Search updated"
          />
        }
        failureMessage={
          <FormattedMessage
            id="updateSavedSearchModal.failedUpdate"
            defaultMessage="Search not updated"
          />
        }
        defaultValue={studySavedSearch}
        searchData={null}
        studySearchCriteria={studySavedSearch?.criteria!.filter(notNullish)}
        getInput={(data) => ({
          name: data.name,
          studySavedSearchId: studySavedSearch.id,
        })}
        organizationSlug={organizationSlug}
        mutation={graphql`
          mutation UpdateSavedSearchModalMutation(
            $input: UpdateStudySavedSearchInput!
          ) {
            updateStudySavedSearchOrError(input: $input) {
              ... on UpdateStudySavedSearchPayload {
                studySavedSearch {
                  name
                  id
                }
              }
              ...RelayForm_error @relay(mask: false)
            }
          }
        `}
      />
    </StaticContainer>
  );
}

export default createFragmentContainer(withModal(UpdateSavedSearchModal), {
  studySavedSearch: graphql`
    fragment UpdateSavedSearchModal_studySavedSearch on StudySavedSearch {
      id
      name
      criteria {
        ...SavedSearchModal_studySearchCriteria
      }
    }
  `,
});
