/**
 * @generated SignedSource<<311a1266caec4002c4a0041e585f3352>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveInviteForm_archive$data = {
  readonly id: string;
  readonly isPrivate: boolean | null;
  readonly organization: {
    readonly id: string;
    readonly slug: string | null;
  } | null;
  readonly " $fragmentType": "ArchiveInviteForm_archive";
};
export type ArchiveInviteForm_archive$key = {
  readonly " $data"?: ArchiveInviteForm_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveInviteForm_archive">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveInviteForm_archive",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPrivate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};
})();

(node as any).hash = "803c0e8edc8ce060217abd9928e06e64";

export default node;
