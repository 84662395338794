/**
 * @generated SignedSource<<52eadca18effb4ed7934cde15d11bc7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchivePageTitle_archive$data = {
  readonly label: string | null;
  readonly " $fragmentType": "ArchivePageTitle_archive";
};
export type ArchivePageTitle_archive$key = {
  readonly " $data"?: ArchivePageTitle_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchivePageTitle_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchivePageTitle_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "f8fbcb04c236e7eb783165c73734a7d2";

export default node;
