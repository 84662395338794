import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { dataItem } from 'react-widgets/Accessors';
import { DeepNonNullable } from 'utility-types';

import CheckGroupSelectButton, {
  CheckGroupSelectButtonHOCProps,
} from 'components/CheckGroupSelectButton';
import getConcreteTenant from 'utils/getConcreteTenant';

import { ArchiveSelectButton_tenant$data as Tenant } from './__generated__/ArchiveSelectButton_tenant.graphql';

type Archive =
  DeepNonNullable<Tenant>['archiveConnection']['edges'][0]['node'];

interface Props extends CheckGroupSelectButtonHOCProps<Archive> {
  tenant: Tenant;
  dataKey?: keyof Archive;
}

function ArchiveSelectButton({ tenant, placeholder, ...props }: Props) {
  const { domain } = getConcreteTenant(tenant);

  const archives = useMemo(
    () => getNodes(tenant.archiveConnection) as Archive[],
    [tenant],
  );

  // FIXME: this should handled by CheckGroupSelectButton
  // It covers a case where dropdownForm uses the raw form value instead of the data items
  const valueArchives =
    props.value?.map((item) => dataItem(archives, item, 'id')) ?? null;

  return (
    <CheckGroupSelectButton<Archive>
      dataKey="id"
      {...props}
      value={valueArchives}
      renderValue={({ item }) => item.label}
      renderItem={({ item }) =>
        domain ? (
          <>
            <div>{item.label}</div>
            <Text variant="sm" color="subtitle">
              {item.organization?.name}
            </Text>
          </>
        ) : (
          item.label
        )
      }
      placeholder={
        placeholder || (
          <FormattedMessage
            id="archiveSelectButton.placeholder"
            defaultMessage="All archives"
          />
        )
      }
      data={archives}
      textKey="label"
    />
  );
}

export default createFragmentContainer(ArchiveSelectButton, {
  tenant: graphql`
    fragment ArchiveSelectButton_tenant on TenantInterface {
      archiveConnection(first: 2147483647)
        @connection(key: "Organization_archiveConnection") {
        edges {
          node {
            id
            handle
            label
            organization {
              name
            }
          }
        }
      }
      ...getConcreteTenant_tenant
    }
  `,
});
