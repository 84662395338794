/**
 * @generated SignedSource<<1530c67c71a4b7483c2547e4a25d3ef2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchPagedArchivePage_archive$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"SearchPagedArchivePage_studies_search">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PagedArchivePage_archive" | "StudyListContent_archive">;
  readonly " $fragmentType": "SearchPagedArchivePage_archive";
};
export type SearchPagedArchivePage_archive$key = {
  readonly " $data"?: SearchPagedArchivePage_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchPagedArchivePage_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchPagedArchivePage_archive",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PagedArchivePage_archive"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_archive"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SearchPagedArchivePage_studies_search"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "f7d290e941c39c65f7a17731bb4d861c";

export default node;
