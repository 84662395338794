import clsx from 'clsx';
import React, { useState } from 'react';

interface Props {
  onStudiesDropped(data: any): void;
  dataKey: 'study-items-can-delete' | 'study-items-can-move';
  children: React.ReactElement;
}

export default function StudiesDropTarget({
  onStudiesDropped,
  dataKey,
  children: child,
  ...props
}: Props) {
  const [isHovering, setIsHovering] = useState(false);

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault();

    if (e.dataTransfer.types.includes(dataKey)) {
      setIsHovering(true);
    }
  };

  const onDragLeave = (e: React.DragEvent) => {
    e.preventDefault();

    if (e.dataTransfer.types.includes(dataKey)) {
      setIsHovering(false);
    }
  };

  const onDrop = (e: React.DragEvent) => {
    if (!e.dataTransfer.types.includes(dataKey)) {
      return;
    }

    onStudiesDropped(JSON.parse(e.dataTransfer.getData('study-items')));

    setIsHovering(false);
  };

  return React.cloneElement(child, {
    ...props,
    onDrop,
    onDragLeave,
    onDragOver,
    className: clsx(child.props.className, isHovering && 'bg-grey-85'),
  });
}
