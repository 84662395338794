/**
 * @generated SignedSource<<0c272358c9f12153faa0da260356c709>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyStatisticsGrid_studyStatisticsByAuthor$data = ReadonlyArray<{
  readonly accuracyAsPresentedStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numFinalizedStudies: number;
  } | null> | null;
  readonly accuracyComparedToGoldStandardStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numFinalizedStudies: number;
  } | null> | null;
  readonly averageImageQuality: number | null;
  readonly examTypeStatistics: ReadonlyArray<{
    readonly accuracyAsPresentedStatistics: ReadonlyArray<{
      readonly key: string;
      readonly numFinalizedStudies: number;
    } | null> | null;
    readonly accuracyComparedToGoldStandardStatistics: ReadonlyArray<{
      readonly key: string;
      readonly numFinalizedStudies: number;
    } | null> | null;
    readonly averageImageQuality: number | null;
    readonly examType: {
      readonly handle: string | null;
      readonly name: string | null;
    } | null;
    readonly feedbackStatistics: ReadonlyArray<{
      readonly key: string;
      readonly numFinalizedStudies: number;
    } | null> | null;
    readonly numCountsTowardsCredentialing: number | null;
    readonly numFinalizedStudies: number | null;
    readonly numReviewedStudies: number | null;
    readonly studyStatusStatistics: ReadonlyArray<{
      readonly key: string;
      readonly numStudies: number;
    } | null> | null;
    readonly type: "ExamTypeStudyStatistics";
    readonly worksheetStatistics?: ReadonlyArray<{
      readonly numFinalizedStudies: number | null;
      readonly numReviewedStudies: number | null;
      readonly type: "ExamTypeWorksheetsStatistics";
      readonly worksheetTemplates: ReadonlyArray<{
        readonly latestVersion: {
          readonly id: string;
          readonly title: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  readonly feedbackStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numFinalizedStudies: number;
  } | null> | null;
  readonly id: string;
  readonly numCountsTowardsCredentialing: number | null;
  readonly numExamTypeAssociations: number | null;
  readonly numFinalizedStudies: number | null;
  readonly numReviewedStudies: number | null;
  readonly studyStatusStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numStudies: number;
  } | null> | null;
  readonly type: "StudyStatisticsByAuthor";
  readonly userHistoricalProficiency: {
    readonly examTypeCounts: ReadonlyArray<{
      readonly count: number | null;
      readonly examType: {
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly userInfo: {
    readonly email: string | null;
  } | null;
  readonly userProfile: {
    readonly handle: string | null;
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "StudyStatisticsGrid_studyStatisticsByAuthor";
}>;
export type StudyStatisticsGrid_studyStatisticsByAuthor$key = ReadonlyArray<{
  readonly " $data"?: StudyStatisticsGrid_studyStatisticsByAuthor$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyStatisticsGrid_studyStatisticsByAuthor">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numFinalizedStudies",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numReviewedStudies",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numCountsTowardsCredentialing",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageImageQuality",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "StudyStatusStatistics",
  "kind": "LinkedField",
  "name": "studyStatusStatistics",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numStudies",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v4/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "InterpretationStatistics",
  "kind": "LinkedField",
  "name": "accuracyAsPresentedStatistics",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "InterpretationStatistics",
  "kind": "LinkedField",
  "name": "accuracyComparedToGoldStandardStatistics",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "FeedbackStatistics",
  "kind": "LinkedField",
  "name": "feedbackStatistics",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "showWorksheetStats"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StudyStatisticsGrid_studyStatisticsByAuthor",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "userInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "userProfile",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numExamTypeAssociations",
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserHistoricalProficiency",
      "kind": "LinkedField",
      "name": "userHistoricalProficiency",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamTypeCount",
          "kind": "LinkedField",
          "name": "examTypeCounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamType",
              "kind": "LinkedField",
              "name": "examType",
              "plural": false,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamTypeStudyStatistics",
      "kind": "LinkedField",
      "name": "examTypeStatistics",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamType",
          "kind": "LinkedField",
          "name": "examType",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v9/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        {
          "condition": "showWorksheetStats",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamTypeWorksheetsStatistics",
              "kind": "LinkedField",
              "name": "worksheetStatistics",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WorksheetTemplate",
                  "kind": "LinkedField",
                  "name": "worksheetTemplates",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WorksheetTemplateVersion",
                      "kind": "LinkedField",
                      "name": "latestVersion",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v4/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    (v7/*: any*/),
    (v9/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/)
  ],
  "type": "StudyStatisticsByAuthor",
  "abstractKey": null
};
})();

(node as any).hash = "b5a5ad723afd2bf0c7b0f9b133b54c67";

export default node;
