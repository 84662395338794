/**
 * @generated SignedSource<<aae259f8a9126cdc2665b8ec0a3917ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RoleType = "PUBLIC_ORG" | "SYSTEM_DEFINED" | "USER_DEFINED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UpdateDomainRolePage_membershipRole$data = {
  readonly roleType: RoleType | null;
  readonly " $fragmentSpreads": FragmentRefs<"DomainRoleDeleteFieldSet_membershipRole" | "roles_defaultValue">;
  readonly " $fragmentType": "UpdateDomainRolePage_membershipRole";
};
export type UpdateDomainRolePage_membershipRole$key = {
  readonly " $data"?: UpdateDomainRolePage_membershipRole$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateDomainRolePage_membershipRole">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateDomainRolePage_membershipRole",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "roles_defaultValue",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permissions",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DomainRoleDeleteFieldSet_membershipRole"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleType",
      "storageKey": null
    }
  ],
  "type": "MembershipRole",
  "abstractKey": null
};

(node as any).hash = "b80761087a5bdc6be25895d6a0eb46ae";

export default node;
