/**
 * @generated SignedSource<<6aa2d29287b957b12099e40d2add9499>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainRoles_DomainRolesPage_Query$variables = {};
export type domainRoles_DomainRolesPage_Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DomainRolesPage_viewer">;
  } | null;
};
export type domainRoles_DomainRolesPage_Query = {
  response: domainRoles_DomainRolesPage_Query$data;
  variables: domainRoles_DomainRolesPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "domainRoles_DomainRolesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainRolesPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "domainRoles_DomainRolesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "roleType",
                "value": [
                  "SYSTEM_DEFINED",
                  "USER_DEFINED"
                ]
              },
              {
                "kind": "Literal",
                "name": "sort",
                "value": "NAME_ASC"
              }
            ],
            "concreteType": "MembershipRoleConnection",
            "kind": "LinkedField",
            "name": "membershipRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembershipRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "membershipRoles(roleType:[\"SYSTEM_DEFINED\",\"USER_DEFINED\"],sort:\"NAME_ASC\")"
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81de0d3ea79baf92d84dba16cfd556a3",
    "id": null,
    "metadata": {},
    "name": "domainRoles_DomainRolesPage_Query",
    "operationKind": "query",
    "text": "query domainRoles_DomainRolesPage_Query {\n  viewer {\n    ...DomainRolesPage_viewer\n    id\n  }\n}\n\nfragment AddDomainRoleModal_viewer on Viewer {\n  membershipRoles(sort: NAME_ASC, roleType: [SYSTEM_DEFINED, USER_DEFINED]) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment DomainRolesPage_viewer on Viewer {\n  ...AddDomainRoleModal_viewer\n}\n"
  }
};
})();

(node as any).hash = "822539beaf67083fd77856009eda8654";

export default node;
