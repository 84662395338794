import Layout from '@4c/layout';
import FailureIcon from '@bfly/icons/Failure';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import Textarea from '@bfly/ui2/Textarea';
import formatName from '@bfly/utils/formatName';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RelayProp, createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import PromptButton from 'components/PromptButton';
import RelayForm from 'components/RelayForm';
import examMessages from 'messages/examMessages';
import studyMessages from 'messages/study';
import Analytics from 'utils/Analytics';
import withModal from 'utils/withModal';

import * as HumanStudyInfo from '../schemas/humanStudyInfo';
import * as VetStudyInfo from '../schemas/vetStudyInfo';
import AssociateExamWorklistItemModalContent from './AssociateExamWorklistItemModalContent';
import EditHumanExamInfoFieldSet from './EditHumanExamInfoFieldSet';
import EditVetExamInfoFieldSet from './EditVetExamInfoFieldSet';
import { EditExamInfoModalMutation } from './__generated__/EditExamInfoModalMutation.graphql';
import { EditExamInfoModal_study$data as Study } from './__generated__/EditExamInfoModal_study.graphql';

interface Props {
  study: Study;
  onHide: () => void;
  target?: 'WORKLIST' | 'EDIT';
  relay: RelayProp;
}

function useEditInfoFunctions(study: Study) {
  if (study.practiceType === 'HUMAN') {
    return HumanStudyInfo;
  }
  if (study.practiceType === 'VETERINARY') {
    return VetStudyInfo;
  }

  throw new Error(`Unknown practiceType: ${study.practiceType}`);
}

function EditExamInfoModal({ study, target, onHide, relay }: Props) {
  const {
    serialize,
    deserialize,
    schema,
    clearPatient,
    hasPatientInfo,
    associateWithWorklistItem,
  } = useEditInfoFunctions(study)!;

  const [isClearing, setIsClearing] = useState(false);
  const [value, setValue] = useState(() => deserialize(study));
  const isPushing = study.pacsPushStatus !== 'NOT_PUSHING';
  const canEditPhi = !useVariation('disable-phi-entry');

  const showAssociateWorklistButton =
    !study.finalizedAt && !!study.organization!.primaryWorklist;

  const [showAssociateWorklistItem, setShowAssociateWorklistItem] = useState(
    showAssociateWorklistButton &&
      (target === 'WORKLIST' ||
        (target !== 'EDIT' &&
          !hasPatientInfo(study) &&
          (study.hasAssociatedWorklistItem! ||
            study.archive!.worklistRequiredToFinalize!))),
  );

  const [resetForm, setResetForm] = useState(false);

  useEffect(() => {
    if (resetForm) {
      const nextValue = deserialize(study);
      setValue(nextValue);
      setResetForm(false);
    }
  }, [study, resetForm, deserialize]);

  const disabledInput =
    !!study.finalizedAt || !!value.sourceWorklistItemId || !canEditPhi;

  const noPatientData = !!(
    study.patient &&
    !formatName(study.patient) &&
    !study.patient.medicalRecordNumber
  );

  const showWorklistRequired = !!(
    study.practiceType === 'HUMAN' &&
    study.archive!.worklistRequiredToFinalize &&
    !study.hasAssociatedWorklistItem
  );

  if (showAssociateWorklistItem) {
    return (
      <AssociateExamWorklistItemModalContent
        primaryWorklist={study.organization!.primaryWorklist!}
        onHide={onHide}
        onBack={() => {
          setShowAssociateWorklistItem(false);
        }}
        onSelect={async (worklistItem) => {
          await associateWithWorklistItem(
            study.id,
            worklistItem,
            relay.environment,
          );
          onHide();
        }}
      />
    );
  }

  return (
    <RelayForm<EditExamInfoModalMutation, typeof schema>
      horizontal
      schema={schema}
      variant="secondary"
      value={value}
      onChange={setValue}
      mutation={graphql`
        mutation EditExamInfoModalMutation($input: UpdateStudyInfoInput!) {
          updateStudyInfoOrError(input: $input) {
            ... on UpdateStudyInfoPayload {
              study {
                ...EditExamInfoModal_study
              }
            }
            ...RelayForm_error @relay(mask: false)
          }
        }
      `}
      onCompleted={onHide}
      getInput={(input) => {
        const serialized = serialize(input);
        return { ...serialized, studyId: study.id };
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...examMessages.editPatient} />
        </Modal.Title>
        {showWorklistRequired && noPatientData && (
          <FormattedMessage {...examMessages.requiresSelection} />
        )}
        {showWorklistRequired && !noPatientData && (
          <Layout align="center" className="text-danger mt-2">
            <FailureIcon
              className="mr-1"
              height={15}
              css={`
                :global(.foreground) {
                  fill: transparent;
                }
              `}
            />
            <FormattedMessage {...examMessages.requiresSelection} />
          </Layout>
        )}
        {showAssociateWorklistButton && (
          <PromptButton
            variant="secondary"
            onClick={() => {
              setShowAssociateWorklistItem(true);
              Analytics.track('chooseFromWorklistClicked', {
                studyId: study.id,
                worklistId: study.organization!.primaryWorklist!.id,
                worklistType: study.organization!.primaryWorklist!.type,
              });
            }}
            className="w-full mt-3"
            data-bni-id="ChooseFromWorklistButton"
          >
            {value.sourceWorklistItemId ? (
              <FormattedMessage {...examMessages.changePatient} />
            ) : (
              <FormattedMessage {...examMessages.chooseFromWorklist} />
            )}
          </PromptButton>
        )}
      </Modal.Header>
      <Modal.Body>
        {study.practiceType === 'HUMAN' && (
          <EditHumanExamInfoFieldSet study={study} disabled={disabledInput} />
        )}
        {study.practiceType === 'VETERINARY' && (
          <EditVetExamInfoFieldSet study={study} disabled={disabledInput} />
        )}
        <Form.FieldGroup
          fluid
          disabled
          as={Textarea}
          name="studyDescription"
          label={studyMessages.description}
          placeholder={studyMessages.description}
        />
        {isPushing && (
          <p className="text-sm text-body my-0 mx-5 text-center">
            <FormattedMessage {...examMessages.saveNote} />
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Layout pad={5}>
            {!study.finalizedAt && (
              <Button
                data-bni-id="ResetPatientInfoButton"
                size="lg"
                variant="text-secondary"
                onClick={async () => {
                  setIsClearing(true);
                  try {
                    await clearPatient(study.id, relay.environment);
                  } finally {
                    setIsClearing(false);
                    setResetForm(true);
                  }
                }}
                busy={isClearing}
                disabled={isClearing}
              >
                <FormattedMessage {...examMessages.clear} />
              </Button>
            )}

            <Form.Submit data-bni-id="SavePatientInfoButton" size="lg">
              <FormattedMessage {...examMessages.save} />
            </Form.Submit>
          </Layout>

          <Button size="lg" variant="secondary" onClick={onHide}>
            <FormattedMessage {...examMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </RelayForm>
  );
}

export default createFragmentContainer(
  withModal(EditExamInfoModal, { backdrop: true, variant: 'dark' }),
  {
    study: graphql`
      fragment EditExamInfoModal_study on Study {
        id
        pacsPushStatus
        studyDescription
        practiceType
        finalizedAt
        accessionNumber
        hasAssociatedWorklistItem
        hasGeneratedAccessionNumber
        organization {
          id
          primaryWorklist {
            type: __typename
            ... on Node {
              id
            }
            ...AssociateExamWorklistItemModalContent_primaryWorklist
          }
        }
        archive {
          worklistRequiredToFinalize
        }
        patient {
          medicalRecordNumber
          nameFirst
          nameLast
          nameMiddle
          namePrefix
          nameSuffix
        }
        ...EditHumanExamInfoFieldSet_study
        ...EditVetExamInfoFieldSet_study
        ...humanStudyInfo_study
        ...vetStudyInfo_study
      }
    `,
  },
);
