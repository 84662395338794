/**
 * @generated SignedSource<<dcbc9081efc3cc627e1773162cba2167>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PatientDescriptionList_study$data = {
  readonly accessionNumber: string | null;
  readonly patient: {
    readonly birthDate: string | null;
    readonly medicalRecordNumber: string | null;
    readonly sex: PatientSex | null;
    readonly " $fragmentSpreads": FragmentRefs<"PatientName_patient">;
  } | null;
  readonly readyAt: string | null;
  readonly sourceWorklistItem: {
    readonly visitNumber?: string | null;
  } | null;
  readonly studyDescription: string | null;
  readonly " $fragmentType": "PatientDescriptionList_study";
};
export type PatientDescriptionList_study$key = {
  readonly " $data"?: PatientDescriptionList_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"PatientDescriptionList_study">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "visitNumber",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PatientDescriptionList_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readyAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medicalRecordNumber",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PatientName_patient"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sourceWorklistItem",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "EncounterBasedWorklistItem",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "FhirEncounter",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "c8d5e791880288fd2bb7dc093886f699";

export default node;
