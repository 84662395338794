import MutationButton from '@bfly/ui2/MutationButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';

import { CancelEhrOruJobButtonMutation } from './__generated__/CancelEhrOruJobButtonMutation.graphql';
import { CancelEhrOruJobButton_job$data as Job } from './__generated__/CancelEhrOruJobButton_job.graphql';

interface Props {
  job: Job;
}

function CancelEhrOruJobButton({ job }: Props) {
  return (
    <MutationButton<CancelEhrOruJobButtonMutation>
      variant="text-danger"
      mutation={graphql`
        mutation CancelEhrOruJobButtonMutation($input: CancelEhrOruJobInput!) {
          cancelEhrOruJobOrError(input: $input) {
            ... on CancelEhrOruJobPayload {
              study {
                ...StudyEndpointsModalContent_study
              }
            }
            ...mutationError_error @relay(mask: false)
          }
        }
      `}
      input={{
        ehrOruJobId: job.id,
      }}
    >
      <FormattedMessage {...actionMessages.cancel} />
    </MutationButton>
  );
}

export default createFragmentContainer(CancelEhrOruJobButton, {
  job: graphql`
    fragment CancelEhrOruJobButton_job on EhrOruJob {
      id
    }
  `,
});
