/**
 * @generated SignedSource<<84178009b0593549ad34eb55924c23c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFileExport_fileExport$data = {
  readonly url: string | null;
  readonly " $fragmentType": "useFileExport_fileExport";
};
export type useFileExport_fileExport$key = {
  readonly " $data"?: useFileExport_fileExport$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFileExport_fileExport">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useFileExport_fileExport"
};

(node as any).hash = "912e6f198f7a29ab2a90c72b3afb2cf1";

export default node;
