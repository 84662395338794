/**
 * @generated SignedSource<<32e766e099d42fe327cc883fe3aa4da2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorksheetSectionFieldSet_section$data = {
  readonly fieldDefinitions: ReadonlyArray<{
    readonly handle: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"FieldDefinitionFormFieldGroup_fieldDefinition">;
  } | null> | null;
  readonly title: string | null;
  readonly " $fragmentType": "WorksheetSectionFieldSet_section";
};
export type WorksheetSectionFieldSet_section$key = {
  readonly " $data"?: WorksheetSectionFieldSet_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorksheetSectionFieldSet_section">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorksheetSectionFieldSet_section",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "fieldDefinitions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FieldDefinitionFormFieldGroup_fieldDefinition"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorksheetSectionDefinition",
  "abstractKey": null
};

(node as any).hash = "4b874c3b9d49289d23aad02825c8ddcf";

export default node;
