/**
 * @generated SignedSource<<95d214187b720a86834b387e96b0c0fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type integrations_CreateEncounterBasedWorklistIntegrationPage_Query$variables = {
  organizationSlug: string;
};
export type integrations_CreateEncounterBasedWorklistIntegrationPage_Query$data = {
  readonly connectivityConfig: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateEncounterBasedWorklistIntegrationPage_connectivityConfig">;
  } | null;
};
export type integrations_CreateEncounterBasedWorklistIntegrationPage_Query = {
  response: integrations_CreateEncounterBasedWorklistIntegrationPage_Query$data;
  variables: integrations_CreateEncounterBasedWorklistIntegrationPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "integrations_CreateEncounterBasedWorklistIntegrationPage_Query",
    "selections": [
      {
        "alias": "connectivityConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateEncounterBasedWorklistIntegrationPage_connectivityConfig"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "integrations_CreateEncounterBasedWorklistIntegrationPage_Query",
    "selections": [
      {
        "alias": "connectivityConfig",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "EhrConnection",
                "kind": "LinkedField",
                "name": "ehrConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EhrEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Ehr",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "connection",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v4/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "type": "Node",
                                "abstractKey": "__isNode"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EncounterBasedWorklist",
                            "kind": "LinkedField",
                            "name": "encounterBasedWorklist",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ehrConnection(first:2147483647)"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Organization_ehrConnection",
                "kind": "LinkedHandle",
                "name": "ehrConnection"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "organizationId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "domainId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "ConnectivityConfigInterface",
            "abstractKey": "__isConnectivityConfigInterface"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55d366e3fe59e18d9eeec35efa981520",
    "id": null,
    "metadata": {},
    "name": "integrations_CreateEncounterBasedWorklistIntegrationPage_Query",
    "operationKind": "query",
    "text": "query integrations_CreateEncounterBasedWorklistIntegrationPage_Query(\n  $organizationSlug: String!\n) {\n  connectivityConfig: organizationBySlug(slug: $organizationSlug) {\n    ...CreateEncounterBasedWorklistIntegrationPage_connectivityConfig\n    id\n  }\n}\n\nfragment CreateEncounterBasedWorklistIntegrationPage_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  ...EncounterBasedWorklistForm_connectivityConfig\n  ...useTenantIdInput_tenant\n}\n\nfragment EncounterBasedWorklistForm_connectivityConfig on ConnectivityConfigInterface {\n  __isConnectivityConfigInterface: __typename\n  ehrConnection(first: 2147483647) {\n    edges {\n      node {\n        id\n        name\n        connection {\n          __typename\n          name\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n        encounterBasedWorklist {\n          __typename\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c820ac59944441869f99c71eadef2571";

export default node;
