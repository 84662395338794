import FieldSet from '@bfly/ui2/FieldSet';
import Form from '@bfly/ui2/Form';
import React from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PermissionTooltip from 'components/PermissionTooltip';
import { usePermissions } from 'utils/viewerContext';

import { DomainMemberIntegrationConfigsFieldSet_ehrs$data as Ehrs } from './__generated__/DomainMemberIntegrationConfigsFieldSet_ehrs.graphql';

interface Props {
  fluid?: boolean;
  ehrs: Ehrs;
}

function DomainMemberIntegrationConfigsFieldSet({ ehrs, fluid }: Props) {
  const { formatMessage } = useIntl();
  const { hasAdminPermission } = usePermissions();
  const hasPermission = hasAdminPermission('userManagement');

  return ehrs.length ? (
    <FieldSet
      className="my-6"
      legend={formatMessage(
        defineMessage({
          id: 'domainMemberIntegrationConfigsFieldSet.title',
          defaultMessage: 'Interface codes (optional)',
        }),
      )}
    >
      {ehrs.map((ehr, idx) => (
        <PermissionTooltip hide={hasPermission} key={ehr!.id}>
          <Form.FieldGroup
            fluid={fluid}
            key={ehr!.id}
            name={`integrationConfigs[${idx}].interfaceCode`}
            label={ehr!.name!}
            disabled={!hasPermission}
          />
        </PermissionTooltip>
      ))}
    </FieldSet>
  ) : null;
}

export default createFragmentContainer(
  DomainMemberIntegrationConfigsFieldSet,
  {
    ehrs: graphql`
      fragment DomainMemberIntegrationConfigsFieldSet_ehrs on Ehr
      @relay(plural: true) {
        id
        name
      }
    `,
  },
);
