import Button from '@bfly/ui2/Button';
import Modal from '@bfly/ui2/Modal';
import Multiselect from '@bfly/ui2/Multiselect';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import withModal from 'utils/withModal';

import {
  bulkAddOrganizationsMessages,
  bulkDomainUsersUpdateModalMessages,
  bulkRemoveOrganizationsMessages,
} from '../messages/DomainMembersMessages';
import { Organization } from '../shared/DomainMembersGridTypes';

interface BulkRemoveOrganizationModalProps {
  organizations: Organization[];
  onHide: () => void;
  onSuccess: (handleSetOrganizations: Organization[]) => void;
}

const BulkRemoveOrganizationModal = ({
  organizations,
  onHide,
  onSuccess,
}: BulkRemoveOrganizationModalProps) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Organization[]
  >([]);

  const handleSetOrganizations = useCallback(() => {
    if (selectedOrganizations) {
      onSuccess(selectedOrganizations);
    }
  }, [selectedOrganizations, onSuccess]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...bulkRemoveOrganizationsMessages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5 h-64">
        <Multiselect
          variant="secondary"
          menuVariant="dark"
          // inline
          placeholder={bulkAddOrganizationsMessages.placeholder}
          data-bni-id="RemoveOrganizationsDropdown"
          data={organizations}
          textField="name"
          className="w-full mt-0 mb-10"
          dataKey="id"
          value={selectedOrganizations}
          onChange={setSelectedOrganizations}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            data-bni-id="BulkRemoveOrganizationModalConfirm"
            size="lg"
            onClick={handleSetOrganizations}
            disabled={selectedOrganizations.length === 0}
          >
            <FormattedMessage
              {...bulkDomainUsersUpdateModalMessages.confirm}
            />
          </Button>
          <Button size="lg" variant="secondary" onClick={onHide}>
            <FormattedMessage {...bulkDomainUsersUpdateModalMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
};

export default withModal(BulkRemoveOrganizationModal, {
  variant: 'dark',
});
