/**
 * @generated SignedSource<<8c5af1228705954edd7a22df9ebd2634>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainMemberIntegrationConfigsFieldSet_ehrs$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentType": "DomainMemberIntegrationConfigsFieldSet_ehrs";
}>;
export type DomainMemberIntegrationConfigsFieldSet_ehrs$key = ReadonlyArray<{
  readonly " $data"?: DomainMemberIntegrationConfigsFieldSet_ehrs$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMemberIntegrationConfigsFieldSet_ehrs">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DomainMemberIntegrationConfigsFieldSet_ehrs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Ehr",
  "abstractKey": null
};

(node as any).hash = "34bf976c996bacf0403c75622f7ac962";

export default node;
