import Layout from '@4c/layout';
import Highlighter from '@bfly/ui2/Highlighter';
import { RelayProp, createFragmentContainer, graphql } from 'react-relay';

import { AVATAR_WIDTH_PX } from 'components/UserInfo';

import Avatar from './Avatar';
import { UserSuggestion_userProfile$data as UserProfile } from './__generated__/UserSuggestion_userProfile.graphql';

interface Props {
  userProfile: UserProfile;
  email?: string;
  searchTerm?: string;
  highlightedClassName: string;
  className?: string;
  relay: RelayProp;
}

function UserSuggestion({
  userProfile,
  email,
  searchTerm,
  highlightedClassName,
  relay: _,
  ...props
}: Props) {
  return (
    <Layout {...props} align="center">
      <Avatar
        userProfile={userProfile}
        width={AVATAR_WIDTH_PX}
        className="mr-3 flex-none"
      />
      <div className="flex-grow min-w-0 ">
        <div className="text-base truncate">
          {searchTerm == null ? (
            userProfile.name!
          ) : (
            <Highlighter
              text={userProfile.name!}
              search={searchTerm}
              highlightedClassName={highlightedClassName}
            />
          )}
        </div>
        {email && (
          <div className="text-sm truncate">
            {searchTerm == null ? (
              email
            ) : (
              <Highlighter
                text={email}
                search={searchTerm}
                highlightedClassName={highlightedClassName}
              />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}

export default createFragmentContainer(UserSuggestion, {
  userProfile: graphql`
    fragment UserSuggestion_userProfile on UserProfile {
      name
      ...Avatar_userProfile
    }
  `,
});
