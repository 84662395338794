import fetchFromRouterUpstream from './fetchFromRouterUpstream';

const forUser = async (
  email: string,
): Promise<{
  [region: string]: string;
} | null> => {
  const response = await fetchFromRouterUpstream(
    `/users/${encodeURIComponent(email)}`,
  );
  return response?.apiUrlsByRegion;
};

const forRegion = async (region: string): Promise<string | null> => {
  const response = await fetchFromRouterUpstream(`/regions/${region}`);
  return response?.apiUrl;
};

export default {
  forUser,
  forRegion,
};
