/**
 * @generated SignedSource<<724cf31399c91a353ffd7d954088f53a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppLeadingNav_organization$data = {
  readonly name: string | null;
  readonly slug: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"AppSearchGlobal_organization" | "AppSearchGlobal_tenant" | "OrganizationSwitcherButton_organization">;
  readonly " $fragmentType": "AppLeadingNav_organization";
};
export type AppLeadingNav_organization$key = {
  readonly " $data"?: AppLeadingNav_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppLeadingNav_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppLeadingNav_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppSearchGlobal_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppSearchGlobal_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationSwitcherButton_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "8ab402f9824d87dada016177aac27de8";

export default node;
