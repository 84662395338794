import Layout from '@4c/layout';
import Card from '@bfly/ui2/Card';
import Text from '@bfly/ui2/Text';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import SkeletonCard from 'components/SkeletonCard';

import { EduCoursePerformanceIndicator_tenant$data as Tenant } from './__generated__/EduCoursePerformanceIndicator_tenant.graphql';

interface Props {
  tenant: Tenant | null;
}

function EduCoursePerformanceIndicator({ tenant }: Props) {
  const { totalUsersEnrolled = 0, totalUsersCompletedAllEnrollments = 0 } =
    tenant?.lmsStatistics ?? {};

  return (
    <SkeletonCard busy={!tenant}>
      <Layout direction="column" className="ml-2">
        <Layout pad={2}>
          <div data-bni-id="EduCoursePerformanceIndicator">
            <Card.Header>
              <Text variant="display-sm">
                <FormattedNumber
                  style="percent"
                  value={
                    totalUsersEnrolled
                      ? totalUsersCompletedAllEnrollments! / totalUsersEnrolled
                      : 0
                  }
                />
              </Text>
            </Card.Header>
            <Card.Body className="-mt-5">
              <Text variant="lg" color="headline">
                <FormattedMessage
                  id="EduCoursePerformanceIndicator.header"
                  defaultMessage="Completed Academy"
                />
              </Text>
              <div>
                <Text>
                  <FormattedMessage
                    id="EduCoursePerformanceIndicator.description"
                    defaultMessage="{totalUsersCompletedAllEnrollments} of {totalUsersEnrolled} Users"
                    values={{
                      totalUsersCompletedAllEnrollments,
                      totalUsersEnrolled,
                    }}
                  />
                </Text>
              </div>
            </Card.Body>
          </div>
        </Layout>
      </Layout>
    </SkeletonCard>
  );
}

export default createFragmentContainer(EduCoursePerformanceIndicator, {
  tenant: graphql`
    fragment EduCoursePerformanceIndicator_tenant on TenantInterface {
      lmsStatistics(filter: $lmsStatisticsFilterInputValue) {
        totalUsersEnrolled
        totalUsersCompletedAllEnrollments
      }
    }
  `,
});
