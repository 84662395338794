import captureException from '@bfly/utils/captureException';
import HttpError from 'found/HttpError';
import RedirectException from 'found/RedirectException';
import makeRouteConfig from 'found/makeRouteConfig';
import { graphql } from 'react-relay';
import localStore from 'store/dist/store.modern';

import AppPage from 'components/AppPage';
import BaseAppPage from 'components/BaseAppPage';
import LaunchDarklyContext, {
  getVariation,
} from 'components/LaunchDarklyContext';
import ReferralsPage from 'components/ReferralsPage';
import Route, {
  RelayRouteRenderArgs,
  renderStaleWhileFetching,
} from 'components/Route';
import { prepareVariablesWithPagination } from 'hooks/usePagedConnection';
import { routes } from 'routes/config';
import { routes as eduRoutes } from 'routes/education';
import {
  clearLastOrganizationSlug,
  clearRedirectLocation,
  getLastOrganizationSlug,
  getRedirectLocation,
  setRedirectLocation,
} from 'utils/EagerRedirectUtils';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';
import { StudyListFilter } from 'utils/StudyConstants';
import { prepareNewOrganizationStudyQueryVariables } from 'utils/StudyFilters';
import getDomainSubdomainLabel from 'utils/getDomainSubdomainLabel';
import isRoute from 'utils/isRoute';
import redirectIfNotRoute from 'utils/redirectIfNotRoute';
import someRouteHasProperty from 'utils/someRouteHasProperty';
import { createRedirectLocation } from 'utils/studiesSelection';
import {
  ViewerContextProvider,
  hasPermissionWithState,
} from 'utils/viewerContext';

import { routeConfig_AppPageRenderer_Query as AppPageRendererQuery } from './__generated__/routeConfig_AppPageRenderer_Query.graphql';
import { routeConfig_OrganizationQuery as OrganizationQuery } from './__generated__/routeConfig_OrganizationQuery.graphql';
import { routeConfig_OrganizationSetupPageQuery as OrganizationSetupPageQuery } from './__generated__/routeConfig_OrganizationSetupPageQuery.graphql';
import { routeConfig_ReferralsPageQuery as ReferralsPageQuery } from './__generated__/routeConfig_ReferralsPageQuery.graphql';
import { routeConfig_ReferredPageQuery as ReferredPageQuery } from './__generated__/routeConfig_ReferredPageQuery.graphql';
import { routeConfig_RootPage_Query as RootPageQuery } from './__generated__/routeConfig_RootPage_Query.graphql';
import AllOrganizationsStudyListPage from './components/AllOrganizationsStudyListPage';
import AppPageRenderer from './components/AppPageRenderer';
import AppPageWithSidePanel from './components/AppPageWithSidePanel';
import DownloadPage from './components/DownloadPage';
import ReferredPage from './components/ReferredPage';
import RootPage from './components/RootPage';
import accountRoutes from './routes/account';
import { adminRoutes, enterpriseSettings } from './routes/admin';
import { analyticsRoutes } from './routes/analytics';
import authRoutes from './routes/auth';
import demoRoute from './routes/demo';
import ecommerceExternalRedirectRoute from './routes/ecommerceExternalRedirect';
import educationRoutes from './routes/education';
import { examListRoutes, examRoutes, rootExamRoutes } from './routes/exams';
import AllOrganizationsStudyList from './routes/exams/components/AllOrganizationsStudyList';
import notificationsRoutes from './routes/notifications';
import scanlabRoutes from './routes/scanLab';
import { stateMergedFromViewer } from './utils/LaunchDarklyManager';
import { isInApp } from './utils/browserInfo';
import organizationRedirect, {
  OrganizationRedirectQuery,
} from './utils/organizationRedirect';
import rootRedirect, { RootRedirectQuery } from './utils/rootRedirect';

function rootPrerender({
  props,
  match,
}: RelayRouteRenderArgs<RootPageQuery['response']>) {
  const viewer = props?.viewer;
  const inApp = isInApp();
  const enterpriseDomain = getDomainSubdomainLabel();
  const isEnterprise = !!enterpriseDomain;

  if (!viewer) {
    return;
  }

  if (isEnterprise && match.location.query?.lms === 'forceLogin') {
    throw new RedirectException({
      pathname: eduRoutes.lms(),
    });
  }

  if (isRoute(match, 'name_acceptInvite')) {
    // Users with inactive accounts can still accept invitations. This is a
    // workaround for the below.
    return;
  }

  if (!inApp && !viewer.setupAt) {
    redirectIfNotRoute(match, 'name_userSetup');
  } else if (
    isRoute(match, 'name_accountCreated') ||
    isRoute(match, 'name_userSetup') ||
    isRoute(match, 'name_TnC')
  ) {
    // Don't trigger the below branches.
  } else if (!viewer.hasAcceptedLatestEula) {
    redirectIfNotRoute(match, 'name_eula');
  }
}

function checkOrganizationSetUp({
  props,
  match,
  viewerContext,
}: RelayRouteRenderArgs<OrganizationQuery['response']>) {
  if (!props) return;

  if (!props.organization!.setupAt) {
    if (
      hasPermissionWithState(
        viewerContext,
        'organizationManagement',
        'BASIC',
        'organization',
      )
    ) {
      redirectIfNotRoute(match, 'name_orgSetup');
    }

    // Let non-admins view. This should not happen, though, so we report it.
    captureException(new Error('Non-admin member exists for non-setup org'));
  }
}

export default makeRouteConfig(
  <Route<RootPageQuery>
    path="/"
    Component={RootPage}
    query={graphql`
      query routeConfig_RootPage_Query(
        $organizationSlug: String!
        $hasOrganizationSlug: Boolean!
      ) {
        viewer {
          ...RootPage_viewer
          ...viewerContext_viewer @relay(mask: false)
        }
        organization: organizationBySlug(slug: $organizationSlug)
          @include(if: $hasOrganizationSlug) {
          id
          slug
          viewerCanFinalize
          viewerCanQa
          viewerLaunchDarklyConfig(platform: CLOUD) {
            user
            hash
            state
          }
        }
        launchDarklyConfig(platform: CLOUD) @skip(if: $hasOrganizationSlug) {
          user
          hash
          state
        }
      }
    `}
    prepareVariables={(variables, { params }) => ({
      ...variables,
      hasOrganizationSlug: !!params.organizationSlug,
      // needs to be type String but we won't run the organization query in the
      // latter case
      organizationSlug: params.organizationSlug || '',
    })}
    prerender={rootPrerender}
    renderFetched={({ Component, props, match }) => {
      const { organization, viewer } = props;
      const { launchDarkly, viewerManager } = match.context;
      const organizationSlug = getLastOrganizationSlug(match);

      let launchDarklyConfigState =
        organization?.viewerLaunchDarklyConfig?.state ||
        props.launchDarklyConfig?.state;

      let launchDarklyConfigStateMerged = launchDarklyConfigState;

      if (!viewer?.domain) {
        // user is a public cloud user
        launchDarklyConfigStateMerged = stateMergedFromViewer(viewer);
        // we are not in the organization scope so reset the state to the merged state
        if (!match.params.organizationSlug)
          launchDarklyConfigState = launchDarklyConfigStateMerged;
      }

      if (!launchDarklyConfigState) {
        // Hitting this means we tried to load the launchDarklyConfig but failed

        // Root redirects don't check for valid targets to avoid an extra round trip.
        // If it turns out we redirected to somewhere invalid, clear the cache and actually ask the server where to go.
        const { state } = match.location;
        if (state?.isRootRedirect) {
          clearLastOrganizationSlug(match);
          throw new RedirectException(state.prevLocation);
        }

        // Case when user has been deleted from org but LS keeps old org slug.
        // Clear stale data from LS.
        // Redirect to root to be then redirected to first available org.
        if (organizationSlug) {
          clearRedirectLocation(match, organizationSlug);
          clearLastOrganizationSlug(match);
          throw new RedirectException(routes.rootRoute());
        }

        throw new HttpError(404);
      }

      // This needs to be in renderFetched so that subsequent route render
      // methods can access it.
      const ldclient = launchDarkly.init(
        launchDarklyConfigState,
        launchDarklyConfigStateMerged,
      );
      const viewerContextValue = viewerManager.init(viewer, organizationSlug);

      // Temporarily skip the data collection page for existing users if the
      //  place of work flag is off, even if specialty is missing, to avoid
      //  showing prompt twice.
      if (
        viewer &&
        !isInApp() &&
        !localStore.get('bfly:hasSeenDataCollection') &&
        getVariation(ldclient, 'collect-place-of-work') &&
        !(
          viewer.specialty &&
          (viewer.placeOfWork || viewer.placeOfWorkFreeform)
        )
      ) {
        redirectIfNotRoute(match, 'name_userSetup');
      }

      // [OCT-239] turn of T&C as backend returns eligibleForTermsAndConditions true for all
      if (
        getVariation(ldclient, 'distributor-tcs') &&
        viewer &&
        viewer.eligibleForTermsAndConditions &&
        !viewer.acceptedTermsAndConditionsUrl &&
        !isRoute(match, 'name_accountCreated') &&
        !isRoute(match, 'name_userSetup')
      ) {
        redirectIfNotRoute(match, 'name_TnC');
      }

      const redirect = getRedirectLocation(match, organizationSlug);

      // We need this so that ecomm redirects are not being broken by to-do redirects
      // This supresses redirect if the ecomm redirect flag has been set in ecommerceExternalRedirect/index.ts
      let comingFromSubActivate = false;
      if (match?.location?.state?.comingFromSubActivate) {
        comingFromSubActivate = true;
        // Immidiately clear the state
        match.location.state = {
          ...match.location?.state,
          comingFromSubActivate: undefined,
        };
      }

      // Assign redirect logic if its not already there
      // For cases where flags/permissions were granted or revoked, these are handled inside the route itself
      if (organization && !redirect) {
        /*
        This saves the state of to-do list redirects
        useTodoNav & canUseDrafts => redirect to 'My drafts'
        useTodoNav & !canUseDrafts & canUseQa => redirect to 'Needs QA review'
      */
        const canSign = getVariation(
          ldclient,
          'restricted-study-finalization',
        );
        const canUseDrafts = getVariation(ldclient, 'draft-studies');
        const canUseQa = getVariation(ldclient, 'worksheets-review');
        const qaRights = organization.viewerCanQa && canUseQa;
        const signRights = organization.viewerCanFinalize && canSign;

        let newLocation;
        if (canUseDrafts) {
          newLocation = createRedirectLocation(
            signRights ? { canSign: true } : {},
            organization.slug!,
            StudyListFilter.MY_DRAFTS,
          );
        } else if (qaRights) {
          newLocation = createRedirectLocation(
            {},
            organization.slug!,
            StudyListFilter.NEEDS_REVIEW,
          );
        }
        if (newLocation && !comingFromSubActivate) {
          setRedirectLocation(match, newLocation);
          throw new RedirectException(newLocation);
        }
      }

      return (children) => (
        <ViewerContextProvider value={viewerContextValue}>
          <LaunchDarklyContext.Provider value={ldclient}>
            <Component {...props}>{children}</Component>
          </LaunchDarklyContext.Provider>
        </ViewerContextProvider>
      );
    }}
  >
    <Route<RootRedirectQuery>
      getQuery={rootRedirect.getQuery}
      prerender={rootRedirect}
    />
    <Route path="-">
      <Route<ReferredPageQuery>
        allowPublic
        mobileFriendly
        path="referred"
        Component={ReferredPage}
        query={graphql`
          query routeConfig_ReferredPageQuery($token: String!) {
            referrerInfo: referrerInfoByToken(token: $token) {
              ...ReferredPage_referrerInfo
            }
          }
        `}
        prepareVariables={(_, { location: { query } }) => ({
          token: query.token,
        })}
        prerender={({ props }) => {
          if (!props) return;
          const { referrerInfo } = props;
          if (!referrerInfo) throw new HttpError(404);
        }}
      />
      <Route
        allowPublic
        mobileFriendly
        path="download"
        Component={DownloadPage}
        query={graphql`
          query routeConfig_Download_Query {
            viewer {
              ...DownloadPage_viewer
            }
          }
        `}
      />
      {demoRoute}
      {authRoutes}
      {enterpriseSettings}
      {accountRoutes}
      {rootExamRoutes}
      {educationRoutes}
      {scanlabRoutes}
      {analyticsRoutes}
    </Route>
    <Route<OrganizationQuery>>
      <Route
        path="-/exams/list/:status"
        render={renderStaleWhileFetching}
        prerender={checkFlagsOr404('cross-org-study-list')}
        query={graphql`
          query routeConfig_AllOrganizationsStudyListPage_Query(
            $viewerId: [ID!]
          ) {
            viewer {
              ...AllOrganizationsStudyListPage_viewer
            }
          }
        `}
        Component={AllOrganizationsStudyListPage}
        prepareVariables={(variables, match) => {
          const { context } = match;
          const viewerId = context.viewerLocalId;

          return {
            ...variables,
            viewerId,
          };
        }}
      >
        <Route
          query={graphql`
            query routeConfig_GlobalPagedStudiesListPage_Query(
              $first: Int
              $after: String
              $last: Int
              $before: String
              $search: StudySearchInput
              $sort: [StudySorting!]!
            ) {
              viewer {
                ...AllOrganizationsStudyList_viewer
              }
              tenant {
                ...AllOrganizationsStudyList_tenant
              }
            }
          `}
          Component={AllOrganizationsStudyList}
          render={renderStaleWhileFetching}
          prepareVariables={prepareVariablesWithPagination(
            (variables, match) =>
              prepareNewOrganizationStudyQueryVariables(
                variables,
                match,
                true,
              ),
          )}
        />
      </Route>
      <Route
        path=":organizationSlug"
        query={graphql`
          query routeConfig_OrganizationQuery($organizationSlug: String!) {
            organization: organizationBySlug(slug: $organizationSlug) {
              id
              slug
              setupAt
            }
          }
        `}
        prerender={checkOrganizationSetUp}
      >
        {ecommerceExternalRedirectRoute}
        {adminRoutes}
        <Route<AppPageRendererQuery>
          query={graphql`
            query routeConfig_AppPageRenderer_Query(
              $organizationSlug: String!
              $archiveHandle: String
              $hasArchive: Boolean!
              $viewerId: [ID!]
              $searchIds: [ID!]!
            ) {
              viewer {
                ...AppPage_viewer
                ...BaseAppPage_viewer
                ...AppPageWithSidePanel_viewer
              }
              organization: organizationBySlug(slug: $organizationSlug) {
                ...BaseAppPage_organization
                ...AppPageWithSidePanel_organization
                ...AppPageRenderer_organization
              }
              tenant {
                ...BaseAppPage_tenant
              }
              archive(handle: $archiveHandle) @include(if: $hasArchive) {
                ...BaseAppPage_archive
                ...AppPageWithSidePanel_archive
              }
              searchNodes: nodes(ids: $searchIds) {
                ...AppPageWithSidePanel_searchNodes
              }
            }
          `}
          prepareVariables={(variables, { params, context }) => {
            return {
              ...variables,
              searchIds: [],
              archiveHandle: params.archiveHandle,
              hasArchive: !!params.archiveHandle,
              viewerId: context.viewerLocalId,
            };
          }}
          render={({ props, match }) => {
            // XXX: Instead of using getVariation, it's better to determine the
            //  component here, to avoid always doing a second round-trip on
            //  initial load to this route and to avoid odd behavior when
            //  rendering the LD placeholder with <AppPageRenderer>.
            let Component;

            if (isRoute(match, 'name_orgSetup')) {
              Component = AppPage;
            } else {
              Component = someRouteHasProperty(match, 'hideSidePanel')
                ? BaseAppPage
                : AppPageWithSidePanel;
            }

            return (
              <AppPageRenderer
                Component={Component}
                props={props}
                match={match}
                center={someRouteHasProperty<boolean>(match, 'center')}
                pageLayout={
                  someRouteHasProperty(match, 'pageLayout') || 'overflow'
                }
              />
            );
          }}
        >
          <Route<OrganizationRedirectQuery>
            getQuery={organizationRedirect.getQuery}
            prerender={organizationRedirect}
          />
          <Route<ReferralsPageQuery>
            hideSidePanel
            path="referrals"
            Component={ReferralsPage}
            query={graphql`
              query routeConfig_ReferralsPageQuery(
                $organizationSlug: String!
              ) {
                organization: organizationBySlug(slug: $organizationSlug) {
                  ...ReferralsPage_organization
                }
              }
            `}
            prerender={checkFlagsOr404('referral-web')}
          />
          {examListRoutes}
          {analyticsRoutes}
          {notificationsRoutes}
          <Route<OrganizationSetupPageQuery>
            center
            path="setup"
            name="name_orgSetup"
            getComponent={() =>
              import(
                /* webpackChunkName: "admin" */ './components/OrganizationSetupPage'
              ).then((m) => m.default)
            }
            query={graphql`
              query routeConfig_OrganizationSetupPageQuery(
                $organizationSlug: String!
              ) {
                organization: organizationBySlug(slug: $organizationSlug) {
                  setupAt
                  ...OrganizationSetupPage_organization
                }
              }
            `}
            prerender={({ props }) => {
              if (!props) return;
              if (props.organization?.setupAt) throw new HttpError(404);
            }}
          />
        </Route>

        {examRoutes}
      </Route>
    </Route>
  </Route>,
);
