import Form from '@bfly/ui2/Form';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useDebouncedValue from '@restart/hooks/useDebouncedValue';
import useEventCallback from '@restart/hooks/useEventCallback';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormHandle } from 'react-formal/Form';
import { Errors } from 'react-formal/types';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';

import WorksheetSection from 'components/WorksheetSection';
import WorksheetSectionFieldSet from 'components/WorksheetSectionFieldSet';
import {
  deserializeFromSchema,
  templateVersionToSchema,
} from 'schemas/worksheet';

import { useExamSetterContext } from './ExamContext';
import { WorksheetAutosaveFormMutation } from './__generated__/WorksheetAutosaveFormMutation.graphql';
import { WorksheetAutosaveForm_worksheet$data as Worksheet } from './__generated__/WorksheetAutosaveForm_worksheet.graphql';

export const AUTOSAVE_TIMEOUT = 1000;

interface Props {
  worksheet: Worksheet;
  onError?: (errors: Errors) => void;
}

function WorksheetAutosaveForm({ worksheet, onError }: Props) {
  const formRef = useRef<FormHandle>(null);
  const { setSaving, registerWorksheetSubmit } = useExamSetterContext();
  const { templateVersion } = worksheet;
  const schema = useMemo(
    () => templateVersionToSchema(templateVersion!),
    [templateVersion],
  );

  const [formValue, setFormValue] = useState(() =>
    deserializeFromSchema(worksheet, schema),
  );

  const debouncedFormValue = useDebouncedValue(formValue, AUTOSAVE_TIMEOUT);

  const [saveWorksheet] =
    useMutationWithError<WorksheetAutosaveFormMutation>(graphql`
      mutation WorksheetAutosaveFormMutation($input: UpdateWorksheetInput!) {
        updateWorksheetOrError(input: $input) {
          ... on UpdateWorksheetPayload {
            study {
              worksheets {
                ...ExamPageSidebarWorksheets
              }
            }
          }
          ...RelayForm_error @relay(mask: false)
        }
      }
    `);

  const autoSaveWorksheet = useEventCallback((nextValues) => {
    setSaving(true);
    saveWorksheet({
      input: {
        values: nextValues,
        worksheetId: worksheet.id,
      },
    }).finally(() => setSaving(false));
  });

  useEffect(() => {
    // react-formal's ref changes on every render so we have to wrap it in a closure
    return registerWorksheetSubmit(() =>
      Promise.resolve(formRef.current?.submit() ?? true),
    );
  }, [formRef, registerWorksheetSubmit]);

  useUpdateEffect(() => {
    const nextWorksheet = schema.cast(debouncedFormValue, {
      stripUnknown: true,
    });

    autoSaveWorksheet(nextWorksheet.values);
  }, [autoSaveWorksheet, debouncedFormValue, schema]);

  return (
    <Form
      ref={formRef}
      schema={schema}
      value={formValue}
      variant="secondary"
      onChange={setFormValue}
      onError={onError}
      warnOnUnsavedNavigation={false}
    >
      {templateVersion!.sections!.map((section) => (
        <WorksheetSection
          key={section!.handle!}
          section={section!}
          ruled={false}
        >
          <WorksheetSectionFieldSet section={section!} />
        </WorksheetSection>
      ))}
    </Form>
  );
}

export default createFragmentContainer(WorksheetAutosaveForm, {
  worksheet: graphql`
    fragment WorksheetAutosaveForm_worksheet on Worksheet {
      id
      templateVersion {
        sections {
          handle
          ...WorksheetSection_section
          ...WorksheetSectionFieldSet_section
        }
        ...worksheet_templateVersion
      }
      values
    }
  `,
});
