import Transition, { reflow } from '@bfly/ui2/Transition';
import useGlobalKeyDownListener from '@bfly/ui2/useGlobalKeyDownListener';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import clsx from 'clsx';
import setStyle from 'dom-helpers/css';
import useRouter from 'found/useRouter';
import {
  ComponentPropsWithoutRef,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import useTabTrap from 'react-widgets/useTabTrap';

const FullScreenExamPaneContent = forwardRef<
  HTMLDivElement,
  { expanded: boolean } & ComponentPropsWithoutRef<'div'>
>(({ expanded, ...props }, ref) => {
  const {
    router,
    match: { location },
  } = useRouter();
  const divRef = useRef<HTMLElement>(null);
  const tabTrap = useTabTrap(divRef);

  useGlobalKeyDownListener((e) => {
    if (e.key === 'Escape' && expanded) {
      router.replace({
        ...location,
        query: { ...location.query, fullscreen: undefined },
      });
    }
  });

  useEffect(() => {
    if (expanded) {
      tabTrap.start();
    } else {
      tabTrap.stop();
    }
  }, [tabTrap, expanded]);

  return (
    <div {...props} ref={useMergedRefs(ref, divRef)} aria-modal={expanded} />
  );
});

interface Props {
  className?: string;
  children?: ReactNode;
}

function FullScreenExamPane({ className, children }: Props) {
  const {
    match: { location },
  } = useRouter();

  const { fullscreen } = location.query;

  function setInlineRect(element: HTMLElement) {
    const { y, width } = element.parentElement!.getBoundingClientRect();
    setStyle(element, {
      top: `${y}px`,
      left: '0',
      right: `${window.innerWidth - width}px`,
      bottom: '0',
    });
    reflow(element);
  }
  function clearInlineRect(element: HTMLElement) {
    setStyle(element, { top: '', left: '', right: '', bottom: '' });
  }
  return (
    // @ts-expect-error classNames is not required
    <Transition
      in={!!fullscreen}
      onExit={setInlineRect}
      onExited={clearInlineRect}
      onEnter={setInlineRect}
      onEntered={clearInlineRect}
    >
      {(status, { ref }) => (
        <FullScreenExamPaneContent
          ref={ref}
          expanded={!!fullscreen}
          className={clsx(
            className,
            'duration-500 transition-all',
            status === 'exited' && 'relative h-full',
            status === 'exiting' && 'fixed z-fixed',
            (status === 'entering' || status === 'entered') &&
              'fixed inset-0 z-fixed',
          )}
        >
          {children}
        </FullScreenExamPaneContent>
      )}
    </Transition>
  );
}

export default FullScreenExamPane;
