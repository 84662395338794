import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';

import usePagedConnection from 'hooks/usePagedConnection';
import { STUDY_GRID_PAGE_SIZE } from 'utils/StudyConstants';

import PagedStudiesListPage from './PagedStudiesListPage';
import { PagedStudiesListPageWithSearchQuery } from './__generated__/PagedStudiesListPageWithSearchQuery.graphql';
import { SearchPagedStudiesListPage_organization$data as Organization } from './__generated__/SearchPagedStudiesListPage_organization.graphql';
import { SearchPagedStudiesListPage_studies$key as OrganizationSearchKey } from './__generated__/SearchPagedStudiesListPage_studies.graphql';
import { SearchPagedStudiesListPage_viewer$data as Viewer } from './__generated__/SearchPagedStudiesListPage_viewer.graphql';

interface Props {
  organization: Organization;
  viewer: Viewer;
  loading?: boolean;
  shouldClearStatus: boolean;
}

const SearchPagedStudiesListPage = ({
  organization,
  viewer,
  loading,
  shouldClearStatus,
}: Props) => {
  const [studies, paginationMeta] = usePagedConnection<
    PagedStudiesListPageWithSearchQuery,
    OrganizationSearchKey,
    'studySearchConnection'
  >(
    graphql`
      fragment SearchPagedStudiesListPage_studies on Organization
      @refetchable(queryName: "PagedStudiesListPageWithSearchQuery") {
        studySearchConnection(
          first: $first
          after: $after
          last: $last
          before: $before
          sort: $sort
          search: $search
        ) @connection(key: "Organization_studySearchConnection") {
          index
          numEdges
          edges {
            ...StudyListContent_studies @arguments(lazyLoadImages: true)
            node {
              id
            }
          }
        }
      }
    `,
    organization,
    {
      pageSize: STUDY_GRID_PAGE_SIZE,
      overfetchNumPages: 0,
    },
  );
  return (
    <PagedStudiesListPage
      organization={organization}
      viewer={viewer}
      loading={loading}
      shouldClearStatus={shouldClearStatus}
      paginationMeta={paginationMeta}
      studies={studies}
    />
  );
};

export default createFragmentContainer(SearchPagedStudiesListPage, {
  organization: graphql`
    fragment SearchPagedStudiesListPage_organization on Organization {
      ...PagedStudiesListPage_organization
      ...SearchPagedStudiesListPage_studies
      ...StudyListContent_organization
    }
  `,
  viewer: graphql`
    fragment SearchPagedStudiesListPage_viewer on Viewer {
      ...StudyListContent_viewer
      ...PagedStudiesListPage_viewer
    }
  `,
});
