/**
 * @generated SignedSource<<7b0db3d285d948248fe785b9aef9f9c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalStudySearchPage_organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchSubNav_tenant">;
  readonly " $fragmentType": "GlobalStudySearchPage_organization";
};
export type GlobalStudySearchPage_organization$key = {
  readonly " $data"?: GlobalStudySearchPage_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchPage_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalStudySearchPage_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GlobalStudySearchSubNav_tenant"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c20accd2d717261f39c52a6a74783cde";

export default node;
