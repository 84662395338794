import Layout from '@4c/layout';
import Heading from '@bfly/ui2/Heading';
import Section from '@bfly/ui2/Section';
import Text, { TextProps } from '@bfly/ui2/Text';
import { css, stylesheet } from 'astroturf';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useHeaderHeightContext } from 'utils/HeaderHeightContext';

import { WorksheetSection_section$data as WorksheetSectionSection } from './__generated__/WorksheetSection_section.graphql';

const styles = stylesheet`

  .section {
    margin: 1.5rem 0;
  }

  .title {
    overflow: hidden;
    overflow-wrap: break-word;
  }

  .count {
    white-space: nowrap;
  }
`;

interface Props {
  section: WorksheetSectionSection;
  children: React.ReactNode;
  titleVariant?: TextProps['variant'];
  ruled?: boolean;
}

function WorksheetSection({
  section,
  children,
  titleVariant = 'body',
  ruled = true,
}: Props) {
  const headerContext = useHeaderHeightContext();
  return (
    <Section className={styles.section}>
      <Heading
        ruled={ruled}
        variant={titleVariant}
        css={css`
          position: sticky;
          top: ${headerContext?.height}px;
          background-color: theme('backgroundColor.body');
          padding-top: 0.6rem;
          z-index: 1;
        `}
      >
        <Layout align="baseline" justify="space-between" wrap={false}>
          <Text variant="body" color="inherit" className={styles.title}>
            {section!.title}
          </Text>
          <Text variant="sm" color="subtitle" className={styles.count}>
            <FormattedMessage
              id="worksheets.numQuestions"
              defaultMessage="{numQuestions} {numQuestions, plural, one {Question} other {Questions}}"
              values={{ numQuestions: section!.fieldDefinitions!.length }}
            />
          </Text>
        </Layout>
      </Heading>
      {children}
    </Section>
  );
}

export default createFragmentContainer(WorksheetSection, {
  section: graphql`
    fragment WorksheetSection_section on WorksheetSectionDefinition {
      title
      fieldDefinitions {
        __typename
      }
    }
  `,
});
