/**
 * @generated SignedSource<<83a9f3db3736183f2fa3605a28089054>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddDomainMemberModalEmailCheckQuery$variables = {
  domainId: string;
  email: string;
};
export type AddDomainMemberModalEmailCheckQuery$data = {
  readonly domain: {
    readonly isUserEmailAvailable?: {
      readonly answer: boolean;
    } | null;
  } | null;
};
export type AddDomainMemberModalEmailCheckQuery = {
  response: AddDomainMemberModalEmailCheckQuery$data;
  variables: AddDomainMemberModalEmailCheckQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "domainId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "email",
          "variableName": "email"
        }
      ],
      "concreteType": "DomainUserEmailAvailability",
      "kind": "LinkedField",
      "name": "isUserEmailAvailable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddDomainMemberModalEmailCheckQuery",
    "selections": [
      {
        "alias": "domain",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddDomainMemberModalEmailCheckQuery",
    "selections": [
      {
        "alias": "domain",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2023925155b255175875e2c8e4aad47",
    "id": null,
    "metadata": {},
    "name": "AddDomainMemberModalEmailCheckQuery",
    "operationKind": "query",
    "text": "query AddDomainMemberModalEmailCheckQuery(\n  $domainId: ID!\n  $email: String!\n) {\n  domain: node(id: $domainId) {\n    __typename\n    ... on Domain {\n      isUserEmailAvailable(email: $email) {\n        answer\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5ebd996b8dcc8ef7b51552f18af3c83";

export default node;
