/**
 * @generated SignedSource<<56b62d3d84d81fd44029c4e5ce291335>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyNotification_notification$data = {
  readonly createdAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Notification_notification">;
  readonly " $fragmentType": "StudyNotification_notification";
};
export type StudyNotification_notification$key = {
  readonly " $data"?: StudyNotification_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyNotification_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyNotification_notification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Notification_notification"
    }
  ],
  "type": "NotificationInterface",
  "abstractKey": "__isNotificationInterface"
};

(node as any).hash = "720c654a992973d276a567052da703b0";

export default node;
