import { defineMessages } from 'react-intl';

export default defineMessages({
  patient: {
    id: 'study.patient',
    defaultMessage: 'Patient',
  },
  notes: {
    id: 'study.notes',
    defaultMessage: 'Notes',
  },
  notesEmpty: {
    id: 'study.notes.empty',
    defaultMessage: 'No study notes',
  },
  studyDescription: {
    id: 'study.studyDescription',
    defaultMessage: 'Study Description',
  },
  description: {
    id: 'study.description',
    defaultMessage: 'Description',
  },
  studyDescriptionEmpty: {
    id: 'study.studyDescription.empty',
    defaultMessage: 'No study description',
  },
  studyAuthorRequired: {
    id: 'study.studyAuthor.required',
    defaultMessage: 'A study author is required',
  },
  primaryAuthorMustSign: {
    id: 'study.primaryAuthor.signatureRequired',
    defaultMessage:
      'Only the study’s primary author is permitted to perform the first signature.',
  },
  worklistRequired: {
    id: 'study.worklist.required',
    defaultMessage: 'Patient details from a worklist are required',
  },
  worksheetRequired: {
    id: 'study.worksheet.required',
    defaultMessage: 'Worksheet is required',
  },
  examTypeRequired: {
    id: 'study.examType.required',
    defaultMessage: 'Exam type is required',
  },
  editStudyDetails: {
    id: 'editStudyInfo.title',
    defaultMessage: 'Edit Study Details',
  },
});
