import Layout from '@4c/layout';
import Form from '@bfly/ui2/Form';
import FormControlSearch from '@bfly/ui2/FormControlSearch';
import MainContent from '@bfly/ui2/MainContent';
import SrOnly from '@bfly/ui2/SrOnly';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import useRouter from 'found/useRouter';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CredentialGroupSelectButton from 'components/CredentialGroupSelectButton';
import IndeterminateFormCheck from 'components/IndeterminateFormCheck';
import { useVariationAllowMissingLdContext } from 'components/LaunchDarklyContext';
import { useMultiSelect } from 'components/MultiSelectContext';
import OrganizationSelectButton from 'components/OrganizationSelectButton';
import { useSelectAllContext } from 'components/SelectAllContext';
import SortHeaderButton from 'components/SortHeaderButton';
import { useEducationRoutes } from 'routes/education';

import educationMessages from '../messages/educationMessages';
import { SortingDirection } from '../schemas/educationDashboardFilters';
import EducationDashboardTabNav from './EducationDashboardTabNav';
import FooterActions from './FooterActions';
import useEducationDashboardFilter from './utils/useEducationDashboardFilter';

const NAME_FIELD_KEY = 'NAME';
const COUNT_FIELD_KEY = 'COUNT';
const AVERAGE_PROGRESS_FIELD_KEY = 'AVERAGE_PROGRESS';

interface UsersOverviewPageProps {
  children?: ReactNode;
}

export default function UsersOverviewPage({
  children,
}: UsersOverviewPageProps) {
  const { router } = useRouter();
  const { formatMessage } = useIntl();
  const educationRoutes = useEducationRoutes();
  const {
    filters,
    updateSearch,
    updateSort,
    updateOrganizationIds,
    updateCredentialGroup,
  } = useEducationDashboardFilter();
  const canUseCredentialing = useVariationAllowMissingLdContext(
    'credentialing-analytics-page',
  );

  const { selectedItemsByKey: selected, setSelectedItemsByKey: setSelected } =
    useMultiSelect();

  const { isIndeterminate, isAllSelected, toggleAll } = useSelectAllContext();

  const clear = () => setSelected(new Map());

  const handleEnroll = () => {
    router.push(educationRoutes.dashboardSelectCourses());
  };

  return (
    <>
      <EducationDashboardTabNav />
      <MainContent size="lg">
        <Layout pad={2} className="ml-auto">
          <Form.Field name="organization">
            {(props) => (
              <OrganizationSelectButton
                {...props}
                data-bni-id="OrganizationFilter"
                value={filters.organizationIds as string[]}
                dataKey="handle"
                toggleClassName="px-4 justify-between"
                className="pb-4"
                placeholder={formatMessage(
                  educationMessages.selectOrganizations,
                )}
                onChange={(val) => {
                  updateOrganizationIds(val?.map((v) => v.handle!) ?? []);
                }}
              />
            )}
          </Form.Field>
          {canUseCredentialing && (
            <Form.Field name="credentialGroup">
              {(props) => (
                <CredentialGroupSelectButton
                  {...props}
                  dataKey="id"
                  value={filters.groupId || []}
                  onChange={(userGroup) => {
                    updateCredentialGroup(
                      userGroup?.map((group) => group.id!) ?? [],
                    );
                  }}
                />
              )}
            </Form.Field>
          )}
        </Layout>
        <FooterActions
          show={!!selected.size}
          onCancel={clear}
          onConfirm={handleEnroll}
          confirmLabel={formatMessage(educationMessages.startEnrollment)}
          content={
            <FormattedMessage
              {...educationMessages.membersSelected}
              values={{ count: selected.size }}
            />
          }
        >
          <FormControlSearch
            value={filters.search ?? ''}
            onChange={updateSearch}
            placeholder={educationMessages.searchForMemberName}
            data-bni-id="SearchField-Users"
            variant="secondary"
          />
          <header
            className="grid pt-6 pb-1.5 border-b border-b-grey-85"
            css={css`
              grid-template-columns:
                minmax(0, 1.1fr) minmax(0, 0.5fr) minmax(0, 1fr)
                50px;
            `}
          >
            <Layout align="center" className="pl-2">
              <IndeterminateFormCheck
                type="checkbox"
                className="mb-0"
                checked={isAllSelected}
                indeterminate={isIndeterminate}
                onChange={toggleAll}
              >
                <SrOnly>
                  <FormattedMessage {...educationMessages.selectAllUsers} />
                </SrOnly>
              </IndeterminateFormCheck>
              <SortHeaderButton
                className="flex-1"
                desc={filters.sort?.direction === SortingDirection.ASC}
                showIndicator={filters.sort?.field === NAME_FIELD_KEY}
                onClick={() => updateSort(NAME_FIELD_KEY)}
                size={14}
              >
                <Text
                  variant="sm-bold"
                  color="subtitle"
                  data-bni-id="UsersTableNameHeader"
                >
                  <FormattedMessage {...educationMessages.name} />
                </Text>
              </SortHeaderButton>
            </Layout>
            <SortHeaderButton
              className="flex-1"
              desc={filters.sort?.direction === SortingDirection.ASC}
              showIndicator={filters.sort?.field === COUNT_FIELD_KEY}
              onClick={() => updateSort(COUNT_FIELD_KEY)}
              size={14}
            >
              <Text
                variant="sm-bold"
                color="subtitle"
                data-bni-id="UsersTableCoursesHeader"
              >
                <FormattedMessage {...educationMessages.enrolledCourses} />
              </Text>
            </SortHeaderButton>
            <SortHeaderButton
              className="flex-1"
              desc={filters.sort?.direction === SortingDirection.ASC}
              showIndicator={
                filters.sort?.field === AVERAGE_PROGRESS_FIELD_KEY
              }
              onClick={() => updateSort(AVERAGE_PROGRESS_FIELD_KEY)}
              size={14}
            >
              <Text
                variant="sm-bold"
                color="subtitle"
                data-bni-id="UsersTableProgressHeader"
              >
                <FormattedMessage {...educationMessages.coursesProgress} />
              </Text>
            </SortHeaderButton>
            <Text variant="sm-bold" color="subtitle">
              <SrOnly>
                <FormattedMessage
                  {...educationMessages.expandCoursesInfoCell}
                />
              </SrOnly>
            </Text>
          </header>
          {children}
        </FooterActions>
      </MainContent>
    </>
  );
}
