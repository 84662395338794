/**
 * @generated SignedSource<<99ed2c4a7f50588a3c8e60252add6484>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CredentialGroupSelectButton_Viewer_Query$variables = {
  id: string;
};
export type CredentialGroupSelectButton_Viewer_Query$data = {
  readonly viewer: {
    readonly memberships?: ReadonlyArray<{
      readonly organization: {
        readonly credentialGroupConnection?: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly description: string | null;
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type CredentialGroupSelectButton_Viewer_Query = {
  response: CredentialGroupSelectButton_Viewer_Query$data;
  variables: CredentialGroupSelectButton_Viewer_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2147483647
        }
      ],
      "concreteType": "CredentialGroupConnection",
      "kind": "LinkedField",
      "name": "credentialGroupConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CredentialGroupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CredentialGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "credentialGroupConnection(first:2147483647)"
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CredentialGroupSelectButton_Viewer_Query",
    "selections": [
      {
        "alias": "viewer",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ViewerMembership",
                "kind": "LinkedField",
                "name": "memberships",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Viewer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CredentialGroupSelectButton_Viewer_Query",
    "selections": [
      {
        "alias": "viewer",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ViewerMembership",
                "kind": "LinkedField",
                "name": "memberships",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Viewer",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de6764b6c71863012ca872308dc7ac06",
    "id": null,
    "metadata": {},
    "name": "CredentialGroupSelectButton_Viewer_Query",
    "operationKind": "query",
    "text": "query CredentialGroupSelectButton_Viewer_Query(\n  $id: ID!\n) {\n  viewer: node(id: $id) {\n    __typename\n    ... on Viewer {\n      memberships {\n        organization {\n          ... on TenantInterface {\n            __isTenantInterface: __typename\n            credentialGroupConnection(first: 2147483647) {\n              edges {\n                node {\n                  id\n                  name\n                  description\n                }\n              }\n            }\n          }\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "dab16e9a637eccc473ef3979ae29f2a4";

export default node;
