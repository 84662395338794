/**
 * @generated SignedSource<<c107d2ad24d3431e8d67884d784aaba0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RefreshModalityWorklistInput = {
  clientMutationId?: string | null;
  modalityWorklistId: any;
};
export type RefreshModalityWorklistControlMutation$variables = {
  input: RefreshModalityWorklistInput;
};
export type RefreshModalityWorklistControlMutation$data = {
  readonly refreshModalityWorklistOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
    readonly modalityWorklist?: {
      readonly " $fragmentSpreads": FragmentRefs<"RefreshModalityWorklistControl_modalityWorklist">;
    } | null;
  } | null;
};
export type RefreshModalityWorklistControlMutation = {
  response: RefreshModalityWorklistControlMutation$data;
  variables: RefreshModalityWorklistControlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RefreshModalityWorklistControlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshModalityWorklistOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ModalityWorklist",
                "kind": "LinkedField",
                "name": "modalityWorklist",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RefreshModalityWorklistControl_modalityWorklist"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RefreshModalityWorklistPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RefreshModalityWorklistControlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshModalityWorklistOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ModalityWorklist",
                "kind": "LinkedField",
                "name": "modalityWorklist",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModalityWorklistRefreshJob",
                    "kind": "LinkedField",
                    "name": "refreshJob",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completedAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RefreshModalityWorklistPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88069441335030e722830f47e7d1584d",
    "id": null,
    "metadata": {},
    "name": "RefreshModalityWorklistControlMutation",
    "operationKind": "mutation",
    "text": "mutation RefreshModalityWorklistControlMutation(\n  $input: RefreshModalityWorklistInput!\n) {\n  refreshModalityWorklistOrError(input: $input) {\n    __typename\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n    ... on RefreshModalityWorklistPayload {\n      modalityWorklist {\n        ...RefreshModalityWorklistControl_modalityWorklist\n        id\n      }\n    }\n  }\n}\n\nfragment RefreshModalityWorklistControl_modalityWorklist on ModalityWorklist {\n  id\n  refreshJob {\n    completedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "a46abaa89879dd885e9b1e8a572a649b";

export default node;
