import { graphql } from 'react-relay';

import useInterpretationImage from 'hooks/useInterpretationImage';

import { useSharedInterpretationImage_InterpretationsQuery as InterpretationsQuery } from './__generated__/useSharedInterpretationImage_InterpretationsQuery.graphql';

export default function useSharedInterpretationImage(imageId: string) {
  // FIXME: We can't retrieve the shared bundle image in non-US regions.
  return useInterpretationImage<InterpretationsQuery>({
    query: graphql`
      query useSharedInterpretationImage_InterpretationsQuery($imageId: ID!) {
        sharedBundleImage: node(id: $imageId) {
          ... on SharedBundleImage {
            sharedInterpretations {
              ...useInterpretationImage_interpretation
            }

            type: sharedInterpretations {
              interpretationType
            }
          }
        }
      }
    `,
    variables: {
      imageId,
    },
    getInterpretations: (data) =>
      data.sharedBundleImage?.sharedInterpretations,
    getInterpretationTypes: (data) => {
      return data.sharedBundleImage?.type?.map((i) => {
        if (i?.interpretationType === 'APICAL_EF') {
          return 'APICAL_EF';
        }
        if (i?.interpretationType === 'BLADDER_VOLUME') {
          return 'BLADDER_VOLUME';
        }
        if (i?.interpretationType === 'BLINE_COUNT') {
          return 'BLINE_COUNT';
        }
        if (i?.interpretationType === 'CAPTION_ECHO') {
          return 'CAPTION_ECHO';
        }
        if (i?.interpretationType === 'ANATOMICAL_LABEL') {
          return 'ANATOMICAL_LABEL';
        }
        return null;
      });
    },
  });
}
