/**
 * @generated SignedSource<<850c829d12476ac466bf4b3fe39430a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileSharedStudyImage_image$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"StudyImage_image">;
  readonly " $fragmentType": "MobileSharedStudyImage_image";
};
export type MobileSharedStudyImage_image$key = {
  readonly " $data"?: MobileSharedStudyImage_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileSharedStudyImage_image">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileSharedStudyImage_image",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyImage_image"
    }
  ],
  "type": "SharedBundleImage",
  "abstractKey": null
};

(node as any).hash = "ca6d430d402ebbc20beb1a9344a88549";

export default node;
