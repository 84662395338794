/**
 * @generated SignedSource<<c345f8d075be2f6402476b91ea52c5fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImageToggleBookmarkButton_studyImage$data = {
  readonly id: string;
  readonly isImportant: boolean | null;
  readonly " $fragmentType": "ImageToggleBookmarkButton_studyImage";
};
export type ImageToggleBookmarkButton_studyImage$key = {
  readonly " $data"?: ImageToggleBookmarkButton_studyImage$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImageToggleBookmarkButton_studyImage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageToggleBookmarkButton_studyImage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isImportant",
      "storageKey": null
    }
  ],
  "type": "StudyImage",
  "abstractKey": null
};

(node as any).hash = "eaa0e2e292ac4e3bed2701fd04c8e39e";

export default node;
