/**
 * @generated SignedSource<<727e92d06bc61d5127c43b746724df13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalStudySearchPage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchSubNav_viewer">;
  readonly " $fragmentType": "GlobalStudySearchPage_viewer";
};
export type GlobalStudySearchPage_viewer$key = {
  readonly " $data"?: GlobalStudySearchPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalStudySearchPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalStudySearchPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GlobalStudySearchSubNav_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e99c69e4a5749781c4888cba12bf2951";

export default node;
