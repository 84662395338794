import Layout from '@4c/layout';
import Card from '@bfly/ui2/Card';
import Text from '@bfly/ui2/Text';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import SkeletonCard from 'components/SkeletonCard';

import { CredentialGroupMembersPerformanceIndicator_tenant$data as Tenant } from './__generated__/CredentialGroupMembersPerformanceIndicator_tenant.graphql';

interface CredentialGroupMembersPerformanceIndicatorProps {
  tenant: Tenant | null;
}

function CredentialGroupMembersPerformanceIndicator({
  tenant,
}: CredentialGroupMembersPerformanceIndicatorProps) {
  const numUsers = tenant?.credentialStatistics?.numUsers || 0;
  const numUsersCredentialed =
    tenant?.credentialStatistics?.numUsersCredentialed || 0;

  return (
    <SkeletonCard busy={!tenant}>
      <Layout direction="column" className="ml-2">
        <Layout pad={2}>
          <div>
            <Card.Header>
              <Text variant="display-sm">
                <FormattedNumber
                  style="percent"
                  value={numUsers ? numUsersCredentialed / numUsers : 0}
                />
              </Text>
            </Card.Header>
            <Card.Body className="-mt-5">
              <Text variant="lg" color="headline">
                <FormattedMessage
                  id="CredentialGroupMembersPerformanceIndicator.header"
                  defaultMessage="Completed Proficiency"
                />
              </Text>
              <div>
                <Text>
                  <FormattedMessage
                    id="CredentialGroupMembersPerformanceIndicator.description"
                    defaultMessage="{numUsersCredentialed} of {numUsers} Users"
                    values={{ numUsersCredentialed, numUsers }}
                  />
                </Text>
              </div>
            </Card.Body>
          </div>
        </Layout>
      </Layout>
    </SkeletonCard>
  );
}

export default createFragmentContainer(
  CredentialGroupMembersPerformanceIndicator,
  {
    tenant: graphql`
      fragment CredentialGroupMembersPerformanceIndicator_tenant on TenantInterface {
        type: __typename
        credentialStatistics(filter: $filter) {
          numUsers
          numUsersPartiallyCredentialed
          numUsersCredentialed
        }
      }
    `,
  },
);
