/**
 * @generated SignedSource<<44a2b18638e27777a9ddba0be939b613>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainRoleDeleteFieldSet_membershipRole$data = {
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentType": "DomainRoleDeleteFieldSet_membershipRole";
};
export type DomainRoleDeleteFieldSet_membershipRole$key = {
  readonly " $data"?: DomainRoleDeleteFieldSet_membershipRole$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainRoleDeleteFieldSet_membershipRole">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainRoleDeleteFieldSet_membershipRole",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "MembershipRole",
  "abstractKey": null
};

(node as any).hash = "7c1556d2f293b1f6ea3cb344ef01f562";

export default node;
