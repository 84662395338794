import { LocationDescriptorObject, Query } from 'farce';
import useRouter from 'found/useRouter';
import pick from 'lodash/pick';
import { useCallback, useMemo } from 'react';
import {
  AnyObjectSchema,
  AnySchema,
  InferType,
  TypeOf,
  array,
  date,
  number,
  object,
  string,
} from 'yup';
import type { OptionalArraySchema } from 'yup/lib/array';

import { Match } from 'components/Route';

export const arrayFromString = array(string()).split(',');

const anyString = string();

const isObjectSchema = (a: AnySchema): a is AnyObjectSchema =>
  a.type === 'object';

const Serializers = {
  date: () =>
    string()
      .nullable()
      .transform((parsedValue, value) =>
        value instanceof Date
          ? value.toISOString().split('T')[0]
          : parsedValue,
      ),
  array: (deserializer: AnySchema) => {
    return string()
      .nullable()
      .transform((value) => {
        return deserializer.cast(value)?.join(',');
      });
  },
};

export const Deserializers = {
  string,
  number,
  date,
  array<T extends AnySchema = AnySchema>(
    type?: T,
  ): OptionalArraySchema<T, any, TypeOf<T>[] | null | undefined> {
    return array(type).split(',').nullable();
  },
};

const defaultSerializer = string().nullable();

export function deserialize<T extends AnyObjectSchema>(
  query: Query,
  deserializer: T,
): InferType<T> {
  return deserializer.cast(query, {
    stripUnknown: true,
  });
}

function useDeserializedValue(schema: AnySchema, source: any) {
  return useMemo(() => {
    let result = schema.cast(source);

    if (isObjectSchema(schema)) {
      // stripUnknown has a bug where it will leave unknown keys when there are no known keys
      result = pick(result, Object.keys(schema.fields));

      // If there are no values for our query subset return null instead of an empty object
      return Object.keys(result).length ? result : null;
    }

    return result;
  }, [schema, source]);
}

export interface SetLocationOptions {
  action?: 'replace' | 'push';
  pathname?: string;
}

export type SetState<S> = (nextState: S, options?: SetLocationOptions) => void;

function useLocationPersistedState<T extends AnySchema>({
  storageKey,
  namespace,
  deserializer,
  serializer,
}: {
  storageKey: 'state' | 'query';
  namespace?: string;
  action?: 'replace' | 'push';
  deserializer: T;
  serializer?: AnySchema;
}): [InferType<T>, SetState<InferType<T>>] {
  const { router, match } = useRouter();

  const { location } = match;
  const storage = location[storageKey];

  const value = useDeserializedValue(
    deserializer,
    namespace ? storage?.[namespace] : storage,
  );

  const toLocation = useCallback(
    (nextValue: InferType<T>, pathname?: string) => {
      nextValue = serializer?.cast(nextValue) ?? nextValue;
      nextValue = namespace ? { [namespace]: nextValue } : nextValue;

      const mergedValue =
        typeof nextValue === 'object' && nextValue
          ? {
              ...location[storageKey],
              ...nextValue,
            }
          : nextValue;

      return {
        ...location,
        pathname: pathname || location.pathname,
        [storageKey]: mergedValue,
      };
    },
    [location, serializer, storageKey, namespace],
  );

  return [
    value,
    useCallback(
      (nextValue, { action = 'replace', pathname }: any = {}) => {
        router[action](toLocation(nextValue, pathname));
      },
      [router, toLocation],
    ),
  ];
}

function useQueryState<T extends AnyObjectSchema>(
  deserializer: T,
): [InferType<T>, SetState<T>] {
  const serializer = useMemo(() => {
    const shape = {};
    for (const [key, value] of Object.entries<AnySchema>(
      deserializer.fields,
    )) {
      if (value.type === 'object') {
        throw new TypeError(
          'Nested objects are not supported for query serialization',
        );
      }

      shape[key] = Serializers[value.type]?.(value) || defaultSerializer;
    }

    return object(shape).nullable();
  }, [deserializer]);

  // Because query strings are not namespaced, they may share the query
  // with other values so we must spread the existing value in. To
  // ensure that we don't update a partial value we default all owned fields to nothing
  // so they are cleared out when omitted in a state update
  const defaultValue = useMemo(() => {
    return Object.fromEntries(
      Object.keys(deserializer.fields).map((k) => [k, undefined]),
    );
  }, [deserializer]);

  const [queryValue, setQueryValue] = useLocationPersistedState({
    storageKey: 'query',
    deserializer,
    serializer,
  });

  return [
    queryValue,
    useCallback(
      (nextValue: InferType<T>) => {
        setQueryValue({ ...defaultValue, ...nextValue });
      },
      [defaultValue, setQueryValue],
    ),
  ];
}

function useSimpleQueryState(key: string, defaultValue: string | null = null) {
  const [value, setValue] = useLocationPersistedState({
    namespace: key,
    storageKey: 'query',
    deserializer: anyString
      .nullable()
      .defined()
      .default(defaultValue ?? null),
  });

  return [value || defaultValue, setValue] as const;
}

function useLocationState<T extends AnySchema>(schema: T, namespace: string) {
  return useLocationPersistedState({
    namespace,
    storageKey: 'state',
    deserializer: schema,
    serializer: schema,
  });
}

export type ToLocation<T> = (
  location?: Partial<LocationDescriptorObject>,
  nextState?: Partial<T> | ((previousState: T) => Partial<T>),
) => LocationDescriptorObject;

export type UseRouterStateProps<T> = {
  routerState: T;
  setRouterState: (nextState: Partial<T>) => void;
  toLocation: ToLocation<T>;
};

function useRouterState<T>(
  namespace: string,
  defaultState: (match: Match) => T,
): UseRouterStateProps<T> {
  const { router, match } = useRouter();

  const { location } = match;
  const routerState = location.state?.[namespace] || defaultState(match);

  const toLocation: ToLocation<T> = (nextLocation, nextState) => ({
    ...location,
    ...nextLocation,
    state: {
      ...location.state,
      ...nextLocation?.state,
      [namespace]: {
        ...routerState,
        ...(typeof nextState === 'function'
          ? nextState(routerState)
          : nextState),
      },
    },
  });

  const setRouterState = (nextState: Partial<T>) => {
    router.replace(toLocation(location, nextState));
  };

  return { routerState, setRouterState, toLocation };
}

export {
  useLocationState,
  useQueryState,
  useSimpleQueryState,
  useRouterState,
};
