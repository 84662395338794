import React from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

interface Props {
  label: MessageDescriptor;
  message?: MessageDescriptor;
  children?: React.ReactNode;
}

/** aria-label used instead of title to avoid hover behavior */
function Abbr({ label, message, children }: Props) {
  const { formatMessage } = useIntl();

  return (
    <abbr aria-label={formatMessage(label)}>
      {message ? <FormattedMessage {...message} /> : children}
    </abbr>
  );
}

export default Abbr;
