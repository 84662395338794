import { graphql, readInlineData } from 'react-relay';

import useFileExport from 'hooks/useFileExport';

import { useStudyStatisticsExportQuery as ExportQuery } from './__generated__/useStudyStatisticsExportQuery.graphql';
import { useStudyStatisticsExport_tenant$key as Tenant } from './__generated__/useStudyStatisticsExport_tenant.graphql';

export default function useStudyStatisticsExport({
  filter,
  tenant,
  ...variables
}: Omit<Partial<ExportQuery['variables']>, 'tenantHandle'> & {
  tenant: Tenant;
}) {
  const { id: tenantId } = readInlineData(
    graphql`
      fragment useStudyStatisticsExport_tenant on TenantInterface @inline {
        id
      }
    `,
    tenant,
  );
  // need the userTimezone to format the filename from the exporter
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [requestDownloadFile, isExportLoading] = useFileExport<ExportQuery>({
    query: graphql`
      query useStudyStatisticsExportQuery(
        $tenantId: ID!
        $filter: StudyStatisticsFilterInput!
        $exportType: StudyStatisticsExportType!
        $pivot: StudyStatisticsExportPivotType
        $includePivot: Boolean!
        $timezone: String
      ) {
        tenant: node(id: $tenantId) {
          ... on TenantInterface {
            studyStatistics(filter: $filter) {
              exportWithPivot: export(
                exportType: $exportType
                pivot: $pivot
                timezone: $timezone
              ) @include(if: $includePivot) {
                ...useFileExport_fileExport
              }
              exportWithoutPivot: export(
                exportType: $exportType
                timezone: $timezone
              ) @skip(if: $includePivot) {
                ...useFileExport_fileExport
              }
            }
          }
        }
      }
    `,
    variables: {
      ...variables,
      filter: filter ?? {},
      tenantId,
      timezone,
    },
    getFile: (res) =>
      res?.tenant?.studyStatistics?.exportWithPivot ||
      res?.tenant?.studyStatistics?.exportWithoutPivot,
  });

  /**
   * GraphQL has no way to check if a parameter is defined
   * so we added a boolean parameter in our queries and
   * this wrapper function to set the boolean parameter.
   */
  const requestDownloadFileWithPivotCheck = (
    vars: Omit<
      Partial<ExportQuery['variables']>,
      'tenantHandle' | 'includePivot'
    >,
  ) =>
    requestDownloadFile({
      ...vars,
      includePivot: !!vars.pivot,
    });

  return [requestDownloadFileWithPivotCheck, isExportLoading] as const;
}
