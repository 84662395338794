/**
 * @generated SignedSource<<e843e26edd758833abc37a73bce67391>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MarkStudyAsDraftControl_study$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MarkStudyAsDraftControl_useRevertToDraft_study">;
  readonly " $fragmentType": "MarkStudyAsDraftControl_study";
};
export type MarkStudyAsDraftControl_study$key = {
  readonly " $data"?: MarkStudyAsDraftControl_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"MarkStudyAsDraftControl_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MarkStudyAsDraftControl_study",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "MarkStudyAsDraftControl_useRevertToDraft_study",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_canMarkAsDraft",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "viewerCanMarkAsDraft",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "16463ee532f7ae4c259877f40c692893";

export default node;
