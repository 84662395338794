import { NotifyShortcutProps, useToast } from '@bfly/ui2/ToastContext';
import { ReactNode } from 'react';

export default function useCopyTextToClipboard() {
  const toast = useToast();

  return (value: string, toastProps?: ReactNode | NotifyShortcutProps) => {
    const copy = navigator.clipboard.writeText(value);
    if (toastProps) toast?.success(toastProps);
    return copy;
  };
}
