import Layout from '@4c/layout';
import ButtonToggleGroup from '@bfly/ui2/ButtonToggleGroup';
import Form from '@bfly/ui2/Form';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import RelayForm from 'components/RelayForm';
import actionMessages from 'messages/actions';
import { useViewerContext } from 'utils/viewerContext';

import {
  deserialize,
  deserializeDaysOfWeekOptions,
  schema,
  serialize,
} from '../schemas/workflowDigests';
import TimeDropdowns from './TimeDropdowns';
import { WorkflowDigestsForm_UpdateMutation as UpdateMutation } from './__generated__/WorkflowDigestsForm_UpdateMutation.graphql';
import { WorkflowDigestsForm_schedule$data as WorkflowDigestSchedule } from './__generated__/WorkflowDigestsForm_schedule.graphql';

const updateWorkflowDigestMutation = graphql`
  mutation WorkflowDigestsForm_UpdateMutation(
    $input: UpdateWorkflowDigestScheduleInput!
  ) {
    updateWorkflowDigestScheduleOrError(input: $input) {
      ... on UpdateWorkflowDigestSchedulePayload {
        workflowDigestSchedule {
          daysOfWeek
          time
          timezone
        }
        clientMutationId
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface WorkflowDigestsSelectionProps {
  schedule: WorkflowDigestSchedule | null;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

const WorkflowDigestsForm = ({
  schedule,
  setUnsavedChanges,
}: WorkflowDigestsSelectionProps) => {
  const viewer = useViewerContext();

  const [formValue, setFormValue] = useState(() => deserialize(schedule));
  const [error, setError] = useState({});

  return (
    <Layout direction="column" justify="left" pad={4}>
      <div>
        <FormattedMessage
          id="WorkflowDigestsForm.buttonGroupInfo"
          defaultMessage="Workflow email digests will be sent out on the following days (select all that apply):"
        />
      </div>
      <RelayForm<UpdateMutation, typeof schema>
        mutation={updateWorkflowDigestMutation}
        schema={schema}
        getInput={(inputValue) => serialize(inputValue, viewer.domain!.id)}
        value={formValue}
        onChange={(changeValue) => {
          setUnsavedChanges(true);
          setFormValue(changeValue);
        }}
        onSubmitFinished={() => {
          setUnsavedChanges(false);
        }}
        successMessage={
          <FormattedMessage
            id="workflowDigestsForm.setSchedule.success"
            defaultMessage="Workflow Email Digest setting saved"
          />
        }
        onError={(e) => setError(e)}
        updater={(store, data) => {
          const newSchedule =
            data.updateWorkflowDigestScheduleOrError?.workflowDigestSchedule;
          const storeDomain = store.get(viewer.domain!.id);
          if (storeDomain) {
            const storeWorkflowDigest = storeDomain.getOrCreateLinkedRecord(
              'workflowDigestSchedule',
              'WorkflowDigestSchedule',
            );
            if (newSchedule && storeWorkflowDigest) {
              storeWorkflowDigest.setValue(
                [...newSchedule.daysOfWeek],
                'daysOfWeek',
              );
              storeWorkflowDigest.setValue(newSchedule.time, 'time');
              storeWorkflowDigest.setValue(newSchedule.timezone, 'timezone');
            }
          }
        }}
      >
        <Form.FieldGroup name="daysOfWeek">
          {(props) => (
            <ButtonToggleGroup
              {...props}
              type="checkbox"
              allowUnselectingRadios={undefined}
              className="min-w-full"
            >
              {Object.values(deserializeDaysOfWeekOptions).map((day) => (
                <ButtonToggleGroup.Item value={day} key={day} />
              ))}
            </ButtonToggleGroup>
          )}
        </Form.FieldGroup>
        <TimeDropdowns />
        <Layout direction="column" align="end">
          <Form.Submit
            variant="primary"
            disabled={error && !!Object.keys(error).length}
          >
            <FormattedMessage {...actionMessages.save} />
          </Form.Submit>
        </Layout>
      </RelayForm>
    </Layout>
  );
};

export default createFragmentContainer(WorkflowDigestsForm, {
  schedule: graphql`
    fragment WorkflowDigestsForm_schedule on WorkflowDigestSchedule {
      ...workflowDigests_schedule
      id
      daysOfWeek
      time
      timezone
    }
  `,
});
