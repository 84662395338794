import CloseSmIcon from '@bfly/icons/CloseSm';
import Button from '@bfly/ui2/Button';
import {
  ColDef as BaseColDef,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';
import clsx from 'clsx';
import {
  PropsWithChildren,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { Column } from '../types';
import useGridEvents from './gridEvents';

interface FilterComponentProps<T> {
  setFilterValue: (next: T) => void;
  filterValue: T;
  hidePopupMenu: () => void;
}

export type DoesFilterPass<T> = (
  data: IDoesFilterPassParams['data'],
  currentValue: T | undefined,
) => boolean;

/**
 * Wraps Filter UI providing set and get filterValue, and popup hide
 * @param FilterComponent
 * @returns Ag Grid ready filter component
 */
export function filterWrapper<T>(
  FilterComponent: React.FC<FilterComponentProps<T>>,
  doesFilterPass: DoesFilterPass<T>,
) {
  return forwardRef(
    ({ filterChangedCallback, api, column }: IFilterParams, ref) => {
      const [currentValue, setCurrentValue] = useState<T>();

      useEffect(() => {
        filterChangedCallback();
      }, [currentValue, filterChangedCallback]);

      const { closePopupMenu } = useGridEvents({
        onClearFilter: (colId) => {
          if (colId === column.getColId()) setCurrentValue(undefined);
        },
      });

      useImperativeHandle(ref, () => ({
        doesFilterPass: (params: IDoesFilterPassParams) =>
          doesFilterPass(params.data, currentValue),
        isFilterActive: () => !!currentValue,
        getModel: () => currentValue,
        setModel: setCurrentValue,
      }));

      return (
        <FilterComponent
          filterValue={currentValue as T}
          setFilterValue={(next) => {
            setCurrentValue(next);
          }}
          hidePopupMenu={() => {
            api.hidePopupMenu();
            closePopupMenu();
          }}
        />
      );
    },
  );
}

export function FloatingFilterWrapper<T>({
  children,
  hasFocus,
  currentValue,
  onClear,
  onClick,
}: PropsWithChildren<{
  hasFocus: boolean;
  currentValue: T;
  onClear: () => void;
  onClick?: () => void;
}>) {
  return (
    <div className="px-4 max-w-full" onKeyDown={onClick} onClick={onClick}>
      <div
        className={clsx(
          'form-control w-full flex bg-grey-85 rounded-[4px] h-8 p-0 items-center',
          hasFocus && 'ring',
        )}
      >
        {children}
        {currentValue !== undefined && currentValue !== null && (
          <Button
            data-clear-filter
            className="bg-grey-85 px-2 flex-shrink-1 h-full items-center flex"
            variant={null}
            onClickCapture={onClear}
          >
            <CloseSmIcon width={12} height={12} />
          </Button>
        )}
      </div>
    </div>
  );
}

type ColDefTypeProps = Column &
  Pick<
    BaseColDef,
    | 'filter'
    | 'floatingFilterComponent'
    | 'floatingFilter'
    | 'floatingFilterComponentParams'
    | 'suppressMenu'
    | 'editable'
    | 'cellEditor'
    | 'cellRenderer'
    | 'cellEditorPopup'
    | 'cellEditorPopupPosition'
  >;

/**
 * ColDefType
 * Converts simple column definition to an AG Grid ready column definition with all rendering and filtering
 * @param colDef Basic column definition
 * @param filterComponent What appears in the filter popup
 * @param formatValue A conversion of the value to the display label
 * @param cellRenderer
 * Provide your own cell Renderer component for this column's cells.
 * See [Cell Renderer](https://www.ag-grid.com/javascript-data-grid/component-cell-renderer/) for framework specific implementation details.
 * @returns AG Grid ready Column Definition
 */
export default function colDefType(props: ColDefTypeProps): ColDefTypeProps {
  const { hideFilter, ...colDefProps } = props;

  if (hideFilter)
    return {
      ...colDefProps,
      type: undefined,
      filter: false,
    };

  return {
    ...colDefProps,
    type: undefined,
    floatingFilter: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: true,
  };
}
