import styled, { stylesheet } from 'astroturf/react';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, readInlineData } from 'react-relay';
import MultiselectTag, {
  MultiselectTagProps,
} from 'react-widgets/MultiselectTag';

import Avatar from 'components/Avatar';

import { SearchBarTags_archive$key as ArchiveKey } from './__generated__/SearchBarTags_archive.graphql';
import { SearchBarTags_studyTag$key as StudyTagKey } from './__generated__/SearchBarTags_studyTag.graphql';
import { SearchBarTags_userProfile$key as UserProfileKey } from './__generated__/SearchBarTags_userProfile.graphql';

export enum SearchConstants {
  FREE_TEXT_SEARCH = '@@freeTextSearch',
  ALL_ARCHIVES = '@@allArchives',
}

type ArchiveValue = { type: 'Archive' } & ArchiveKey;
type UserProfileValue = { type: 'UserProfile' } & UserProfileKey;
type StudyTagValue = { type: 'StudyTag' } & StudyTagKey;

type SearchTag =
  | SearchConstants.ALL_ARCHIVES
  | ArchiveValue
  | UserProfileValue
  | StudyTagValue;

const styles = stylesheet`
  .static-tag {
    & :global(.rw-multiselect-tag-label) {
      @apply rounded bg-grey-50 border-grey-50;
    }

    & :global(.rw-multiselect-tag-btn) {
      display: none;
    }
  }
`;

const TagAvatar = styled(Avatar).attrs({ width: 20 })`
  composes: mr-1 -ml-1 inline-block from global;
`;

function renderTagLabel(item: SearchTag) {
  if (item === SearchConstants.ALL_ARCHIVES) {
    return (
      <FormattedMessage
        id="studySearchBar.allArchives"
        defaultMessage="All Archives"
      />
    );
  }

  if (item.type === 'Archive') {
    const archive = readInlineData(
      graphql`
        fragment SearchBarTags_archive on Archive @inline {
          label
        }
      `,
      item,
    );

    return archive.label!;
  }

  if (item.type === 'UserProfile') {
    const userProfile = readInlineData(
      graphql`
        fragment SearchBarTags_userProfile on UserProfile @inline {
          ...Avatar_userProfile
          name
        }
      `,
      item,
    );

    return (
      <>
        <TagAvatar userProfile={userProfile} />
        <span css="vertical-align: middle">{userProfile.name}</span>
      </>
    );
  }
  if (item.type === 'StudyTag') {
    const studyTag = readInlineData(
      graphql`
        fragment SearchBarTags_studyTag on StudyTag @inline {
          name
        }
      `,
      item,
    );

    return studyTag.name!;
  }

  throw new Error(`Unexpected item: ${item}`);
}

export function renderTag(props: MultiselectTagProps) {
  return (
    <MultiselectTag
      {...props}
      className={clsx(
        props.className,
        props.dataItem === SearchConstants.ALL_ARCHIVES && styles.staticTag,
      )}
    >
      {renderTagLabel(props.dataItem as SearchTag)}
    </MultiselectTag>
  );
}
