/**
 * @generated SignedSource<<5f9652dfd4b2581470534eeb6dcbbed0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SavedSearchModal_studySearchCriteria$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SearchTags_studySearchCriteria">;
  readonly " $fragmentType": "SavedSearchModal_studySearchCriteria";
}>;
export type SavedSearchModal_studySearchCriteria$key = ReadonlyArray<{
  readonly " $data"?: SavedSearchModal_studySearchCriteria$data;
  readonly " $fragmentSpreads": FragmentRefs<"SavedSearchModal_studySearchCriteria">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SavedSearchModal_studySearchCriteria",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchTags_studySearchCriteria"
    }
  ],
  "type": "StudySearchCriterion",
  "abstractKey": null
};

(node as any).hash = "622f0b150d25ff09269669d800331fcb";

export default node;
