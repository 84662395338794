/**
 * @generated SignedSource<<2e084e604e5ab1f41cfade0a4a1c9046>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteStudySavedSearchInput = {
  clientMutationId?: string | null;
  studySavedSearchId: any;
};
export type SearchBarGlobal_Delete_Mutation$variables = {
  input: DeleteStudySavedSearchInput;
};
export type SearchBarGlobal_Delete_Mutation$data = {
  readonly deleteStudySavedSearchOrError: {
    readonly __typename: string;
  } | null;
};
export type SearchBarGlobal_Delete_Mutation = {
  response: SearchBarGlobal_Delete_Mutation$data;
  variables: SearchBarGlobal_Delete_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchBarGlobal_Delete_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteStudySavedSearchOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchBarGlobal_Delete_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteStudySavedSearchOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isDeleteStudySavedSearchOrErrorPayload"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6169224d75711308b9bc43eb66c386d2",
    "id": null,
    "metadata": {},
    "name": "SearchBarGlobal_Delete_Mutation",
    "operationKind": "mutation",
    "text": "mutation SearchBarGlobal_Delete_Mutation(\n  $input: DeleteStudySavedSearchInput!\n) {\n  deleteStudySavedSearchOrError(input: $input) {\n    __typename\n    __isDeleteStudySavedSearchOrErrorPayload: __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ef71d03db201076baa2d1367ab49d40";

export default node;
