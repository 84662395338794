import { createFragmentContainer, graphql } from 'react-relay';

import Dl from 'components/DescriptionList';
import WorksheetSection from 'components/WorksheetSection';

import TemplatedFieldListItem from './TemplatedFieldListItem';
import { WorksheetDisplay_worksheet$data as Worksheet } from './__generated__/WorksheetDisplay_worksheet.graphql';

interface Props {
  worksheet: Worksheet;
}

function WorksheetDisplay({ worksheet }: Props) {
  const { templateVersion, values } = worksheet;

  return (
    <>
      {templateVersion!.sections!.map((section) => (
        <WorksheetSection key={section!.handle!} section={section!}>
          <Dl className="mt-4">
            {section!.fieldDefinitions!.map((fieldDefinition) => (
              <TemplatedFieldListItem
                key={fieldDefinition!.handle!}
                fieldDefinition={fieldDefinition!}
                value={values![fieldDefinition!.handle || '']}
              />
            ))}
          </Dl>
        </WorksheetSection>
      ))}
    </>
  );
}

export default createFragmentContainer(WorksheetDisplay, {
  worksheet: graphql`
    fragment WorksheetDisplay_worksheet on Worksheet {
      values
      templateVersion {
        sections {
          handle
          fieldDefinitions {
            handle
            ...TemplatedFieldListItem_fieldDefinition
          }
          ...WorksheetSection_section
          ...WorksheetSectionFieldSet_section
        }
      }
    }
  `,
});
