import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';

import { auditLogs_DomainAuditLogsList_Query as DomainAuditLogsListQuery } from './__generated__/auditLogs_DomainAuditLogsList_Query.graphql';
import { auditLogs_checkIsDomainAdmin_Query as AuditLogsCheckQuery } from './__generated__/auditLogs_checkIsDomainAdmin_Query.graphql';

export const domainAuditLogsRoute = (
  <Route<AuditLogsCheckQuery>
    path="audit-logs"
    query={graphql`
      query auditLogs_checkIsDomainAdmin_Query {
        tenant {
          ...RouteAccessControl_tenant
        }
      }
    `}
    getComponent={() =>
      import(
        /* webpackChunkName: "admin" */ './components/DomainAuditLogsPage'
      ).then((m) => m.default)
    }
    prerender={chainPrerenders(checkFlagsOr404('audit-logs-view'))}
  >
    <Route<DomainAuditLogsListQuery>
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/DomainAuditLogsList'
        ).then((m) => m.default)
      }
      query={graphql`
        query auditLogs_DomainAuditLogsList_Query(
          $eventAction: String
          $eventCategory: String
        ) {
          viewer {
            ...DomainAuditLogsList_viewer
              @arguments(
                eventAction: $eventAction
                eventCategory: $eventCategory
              )
          }
        }
      `}
    />
  </Route>
);
