/**
 * @generated SignedSource<<45da1396da9502862dfde45ab9023318>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SplitStudyPatientVetFields_study$data = {
  readonly finalizedAt: string | null;
  readonly vetPatient: {
    readonly clientNameMiddle: string | null;
    readonly clientNamePrefix: string | null;
    readonly clientNameSuffix: string | null;
  } | null;
  readonly " $fragmentType": "SplitStudyPatientVetFields_study";
};
export type SplitStudyPatientVetFields_study$key = {
  readonly " $data"?: SplitStudyPatientVetFields_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyPatientVetFields_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyPatientVetFields_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientNamePrefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientNameSuffix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientNameMiddle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "e3bef99c138ca2c304ec107c8f46912e";

export default node;
