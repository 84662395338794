/**
 * @generated SignedSource<<5aad1b68587b43c14dea9a6cd88ec15b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type connections_DomainUpdateDicomConnectionPage_Query$variables = {
  hospitalConnectionHandle: string;
};
export type connections_DomainUpdateDicomConnectionPage_Query$data = {
  readonly dicomConnection: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdateDicomConnectionPage_dicomConnection">;
  } | null;
  readonly viewer: {
    readonly memberships: ReadonlyArray<{
      readonly organization: {
        readonly " $fragmentSpreads": FragmentRefs<"UpdateDicomConnectionPage_organization">;
      } | null;
    } | null> | null;
  } | null;
};
export type connections_DomainUpdateDicomConnectionPage_Query = {
  response: connections_DomainUpdateDicomConnectionPage_Query$data;
  variables: connections_DomainUpdateDicomConnectionPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hospitalConnectionHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "hospitalConnectionHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "connections_DomainUpdateDicomConnectionPage_Query",
    "selections": [
      {
        "alias": "dicomConnection",
        "args": (v1/*: any*/),
        "concreteType": "DicomTlsConnection",
        "kind": "LinkedField",
        "name": "dicomTlsConnection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateDicomConnectionPage_dicomConnection"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerMembership",
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UpdateDicomConnectionPage_organization"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "connections_DomainUpdateDicomConnectionPage_Query",
    "selections": [
      {
        "alias": "dicomConnection",
        "args": (v1/*: any*/),
        "concreteType": "DicomTlsConnection",
        "kind": "LinkedField",
        "name": "dicomTlsConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAnonymous",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSecured",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "qpathClassicSecurityMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scpPublicKey",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scpHost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scuPublicKey",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "connectivityConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "organizationId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Organization",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "domainId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "Domain",
                        "abstractKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isNode"
              }
            ],
            "type": "HospitalConnectionInterface",
            "abstractKey": "__isHospitalConnectionInterface"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerMembership",
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationSubscription",
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "practiceType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ef40a409ea629a735d7b89e8cbf99b0",
    "id": null,
    "metadata": {},
    "name": "connections_DomainUpdateDicomConnectionPage_Query",
    "operationKind": "query",
    "text": "query connections_DomainUpdateDicomConnectionPage_Query(\n  $hospitalConnectionHandle: String!\n) {\n  dicomConnection: dicomTlsConnection(handle: $hospitalConnectionHandle) {\n    ...UpdateDicomConnectionPage_dicomConnection\n    id\n  }\n  viewer {\n    memberships {\n      organization {\n        ...UpdateDicomConnectionPage_organization\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment DeleteHospitalConnectionSection_hospitalConnection on HospitalConnectionInterface {\n  __isHospitalConnectionInterface: __typename\n  name\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  connectivityConfig {\n    __typename\n    ...useTenantIdInput_tenant\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment DicomConnectionForm_dicomConnection on DicomTlsConnection {\n  id\n  name\n  isAnonymous\n  isSecured\n  qpathClassicSecurityMode\n  scpPublicKey\n  scpHost\n  scuPublicKey\n}\n\nfragment DicomConnectionForm_organization on Organization {\n  subscription {\n    practiceType\n  }\n  ...SecurityModeInput_organization\n}\n\nfragment SecurityModeInput_organization on Organization {\n  subscription {\n    practiceType\n  }\n}\n\nfragment UpdateDicomConnectionPage_dicomConnection on DicomTlsConnection {\n  id\n  name\n  ...DicomConnectionForm_dicomConnection\n  ...DeleteHospitalConnectionSection_hospitalConnection\n}\n\nfragment UpdateDicomConnectionPage_organization on Organization {\n  ...DicomConnectionForm_organization\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b45573635c1ae95a9ea3eb717b1951bc";

export default node;
