import Layout from '@4c/layout';
import Text from '@bfly/ui2/Text';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from './Avatar';
import { UserInfo_userProfile$data as UserProfile } from './__generated__/UserInfo_userProfile.graphql';

export const AVATAR_WIDTH_PX = 30;

interface Props {
  userProfile: UserProfile | null;
  email?: string;
  className?: string;
}

function UserInfo({ userProfile, email, className, ...props }: Props) {
  return (
    <Layout grow align="center" className={className} {...props}>
      <Avatar
        userProfile={userProfile}
        width={AVATAR_WIDTH_PX}
        className="mr-3 flex-none"
      />
      <Layout direction="column" css="min-width: 0;">
        <Text color="headline" truncate>
          {userProfile?.name || email}
        </Text>
        <Text variant="sm" color="body" truncate>
          {email}
        </Text>
      </Layout>
    </Layout>
  );
}

export default createFragmentContainer(UserInfo, {
  userProfile: graphql`
    fragment UserInfo_userProfile on UserProfile {
      name
      ...Avatar_userProfile
    }
  `,
});
