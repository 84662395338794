/**
 * @generated SignedSource<<23cc40f494287a70ee5f8a23c08e6473>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainQaRoutes_DomainUpdateQaTemplateForm_Query$variables = {
  templateHandle: string;
};
export type domainQaRoutes_DomainUpdateQaTemplateForm_Query$data = {
  readonly template: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdateQaTemplateForm_template">;
  } | null;
};
export type domainQaRoutes_DomainUpdateQaTemplateForm_Query = {
  response: domainQaRoutes_DomainUpdateQaTemplateForm_Query$data;
  variables: domainQaRoutes_DomainUpdateQaTemplateForm_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "templateHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "templateHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "FieldOption",
  "kind": "LinkedField",
  "name": "options",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowOther",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domainQaRoutes_DomainUpdateQaTemplateForm_Query",
    "selections": [
      {
        "alias": "template",
        "args": (v1/*: any*/),
        "concreteType": "QaTemplate",
        "kind": "LinkedField",
        "name": "qaTemplate",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateQaTemplateForm_template"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domainQaRoutes_DomainUpdateQaTemplateForm_Query",
    "selections": [
      {
        "alias": "template",
        "args": (v1/*: any*/),
        "concreteType": "QaTemplate",
        "kind": "LinkedField",
        "name": "qaTemplate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "workflowConfig",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "organizationId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "domainId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Domain",
                    "abstractKey": null
                  },
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "QaTemplateVersion",
            "kind": "LinkedField",
            "name": "latestVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "fieldDefinitions",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": "typename",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "required",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "textStyle",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "style",
                        "storageKey": null
                      }
                    ],
                    "type": "TextFieldDefinition",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "numberStyle",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "style",
                        "storageKey": null
                      }
                    ],
                    "type": "NumberFieldDefinition",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "singleSelectStyle",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "style",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "type": "SingleSelectFieldDefinition",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "multiSelectStyle",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "style",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "type": "MultiSelectFieldDefinition",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26fbc310e431d0c1bcb1fb04ba91cbcb",
    "id": null,
    "metadata": {},
    "name": "domainQaRoutes_DomainUpdateQaTemplateForm_Query",
    "operationKind": "query",
    "text": "query domainQaRoutes_DomainUpdateQaTemplateForm_Query(\n  $templateHandle: String!\n) {\n  template: qaTemplate(handle: $templateHandle) {\n    ...UpdateQaTemplateForm_template\n    id\n  }\n}\n\nfragment UpdateQaTemplateForm_template on QaTemplate {\n  id\n  workflowConfig {\n    __typename\n    ...useTenantIdInput_tenant\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  latestVersion {\n    title\n    fieldDefinitions {\n      __typename\n      typename: __typename\n      handle\n      required\n      label\n      ... on TextFieldDefinition {\n        textStyle: style\n      }\n      ... on NumberFieldDefinition {\n        numberStyle: style\n      }\n      ... on SingleSelectFieldDefinition {\n        singleSelectStyle: style\n        options {\n          handle\n          label\n        }\n        allowOther\n      }\n      ... on MultiSelectFieldDefinition {\n        multiSelectStyle: style\n        options {\n          handle\n          label\n        }\n        allowOther\n      }\n    }\n    id\n  }\n}\n\nfragment useTenantIdInput_tenant on Node {\n  __isNode: __typename\n  ... on Organization {\n    organizationId: id\n  }\n  ... on Domain {\n    domainId: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc47e8b34c49ee4392811b6905e80f01";

export default node;
