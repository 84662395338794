import Layout from '@4c/layout';
import Multiselect from '@bfly/ui2/Multiselect';
import Text from '@bfly/ui2/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useHandleExamTypeChange from 'hooks/useHandleExamTypeChange';
import useSuggestedExamTypesGroups from 'hooks/useSuggestedExamTypesGroups';
import studyMessages from 'messages/study';

import ExamPageSidebarHeading from './ExamPageSidebarHeading';
import { SelectExamTypeControl_study$data as Study } from './__generated__/SelectExamTypeControl_study.graphql';

interface Props {
  study: Study;
  invalid?: boolean;
  sugggestedExamTypeIds?: string[];
  className?: string;
}
const suggestedGroupTitle = (
  <FormattedMessage
    id="SelectExamTypeControl.suggested"
    defaultMessage="Suggested"
  />
);
const remainingGroupTitle = (
  <FormattedMessage
    id="SelectExamTypeControl.othertypes"
    defaultMessage="All Exam Types"
  />
);

const SelectExamTypeControl = ({
  study,
  invalid = false,
  sugggestedExamTypeIds = [],
  className,
}: Props) => {
  const { formatMessage } = useIntl();

  const { groupBy, examTypes } = useSuggestedExamTypesGroups(
    study,
    suggestedGroupTitle,
    remainingGroupTitle,
    sugggestedExamTypeIds,
    true,
  );

  const handleChange = useHandleExamTypeChange(study.id);

  return (
    <Layout
      direction="column"
      align="flex-start"
      pad={4}
      className={className}
    >
      <ExamPageSidebarHeading invalid={invalid}>
        <FormattedMessage id="examtype.header" defaultMessage="Exam type" />
      </ExamPageSidebarHeading>
      {invalid && (
        <Text color="danger">
          <FormattedMessage {...studyMessages.examTypeRequired} />
        </Text>
      )}
      <Multiselect
        groupBy={groupBy}
        placeholder={formatMessage({
          id: 'examType.select',
          defaultMessage: 'Exam type not selected',
        })}
        data-bni-id="ExamTypeDropdown"
        data={examTypes}
        textField="name"
        variant="secondary"
        className="w-full mt-0"
        menuVariant="dark"
        dataKey="id"
        onChange={handleChange}
        focusFirstItem
        value={study.examTypes?.map((e) => e)}
      />
    </Layout>
  );
};

export default createFragmentContainer(SelectExamTypeControl, {
  study: graphql`
    fragment SelectExamTypeControl_study on Study {
      ...useSuggestedExamTypesGroups_study
      id
      examTypes {
        id
        name
      }
    }
  `,
});
