import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Link from '@bfly/ui2/Link';
import MainContent from '@bfly/ui2/MainContent';
import { css } from 'astroturf';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import sanitizeHtml from 'sanitize-html';
import { object, string } from 'yup';

import AuthContent from 'components/AuthContent';
import { useAuth } from 'components/AuthContext';
import AuthForm from 'components/AuthForm';
import useRedirectToPrevLocation from 'hooks/useRedirectToPrevLocation';
import { useConfigRoutes } from 'routes/config';

import messages from '../messages/termsAndConditions';
import { TermsAndConditionsPageMutation } from './__generated__/TermsAndConditionsPageMutation.graphql';
import { TermsAndConditionsPage_latestTermsAndConditions$data as TermsAndConditions } from './__generated__/TermsAndConditionsPage_latestTermsAndConditions.graphql';
import { TermsAndConditionsPage_viewer$data as Viewer } from './__generated__/TermsAndConditionsPage_viewer.graphql';

const mutation = graphql`
  mutation TermsAndConditionsPageMutation(
    $input: AcceptTermsAndConditionsInput!
  ) {
    acceptTermsAndConditionsOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
      ... on AcceptTermsAndConditionsPayload {
        viewer {
          id
          acceptedTermsAndConditionsUrl
        }
      }
    }
  }
`;

const termsAndConditionsSchema = object({
  url: string().url(),
});

interface Props {
  viewer: Viewer;
  latestTermsAndConditions: TermsAndConditions;
}

function TermsAndConditionsPage({ viewer, latestTermsAndConditions }: Props) {
  const configRoutes = useConfigRoutes();
  const auth = useAuth();

  const { url, textHtml } = latestTermsAndConditions || {};
  const { acceptedTermsAndConditionsUrl } = viewer;
  const redirectToPrevLocation = useRedirectToPrevLocation();

  return (
    <MainContent centerText size="sm">
      <AuthContent.Title>
        <FormattedMessage {...messages.title} />
      </AuthContent.Title>
      <div
        className="mb-3 scrollable-y text-left flex-1"
        css={css`
          max-height: 55vh;

          ul {
            list-style-type: disc;
            padding-left: 40px;
            margin-bottom: 8px;
          }

          ol {
            list-style-type: decimal;
            padding-left: 40px;
            margin-bottom: 8px;
          }
        `}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: sanitizeHtml(textHtml!),
        }}
      />
      <AuthForm<
        TermsAndConditionsPageMutation,
        typeof termsAndConditionsSchema
      >
        mutation={mutation}
        schema={termsAndConditionsSchema}
        getInput={() => ({ url: url! })}
        onCompleted={redirectToPrevLocation}
      >
        {!acceptedTermsAndConditionsUrl && (
          <Form.Submit size="lg" className="mt-4">
            <FormattedMessage {...messages.accept} />
          </Form.Submit>
        )}
      </AuthForm>
      {!acceptedTermsAndConditionsUrl && (
        <Link
          as={Button}
          variant="text-primary"
          onClick={() => auth.clearAccessToken(true)}
          to={configRoutes.rootRoute()}
        >
          <FormattedMessage {...messages.decline} />
        </Link>
      )}
    </MainContent>
  );
}

export default createFragmentContainer(TermsAndConditionsPage, {
  viewer: graphql`
    fragment TermsAndConditionsPage_viewer on Viewer {
      acceptedTermsAndConditionsUrl
    }
  `,
  latestTermsAndConditions: graphql`
    fragment TermsAndConditionsPage_latestTermsAndConditions on TermsAndConditions {
      url
      textHtml
    }
  `,
});
