/**
 * @generated SignedSource<<2dde5e8239816b87469e2e9eed36cc9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type annotationExtensions_UpdateAnnotationExtensionQuery$variables = {
  extensionHandle: string;
  organizationSlug: string;
};
export type annotationExtensions_UpdateAnnotationExtensionQuery$data = {
  readonly annotationExtension: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdateAnnotationExtensionPage_annotationExtension">;
  } | null;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdateAnnotationExtensionPage_organization">;
  } | null;
};
export type annotationExtensions_UpdateAnnotationExtensionQuery = {
  response: annotationExtensions_UpdateAnnotationExtensionQuery$data;
  variables: annotationExtensions_UpdateAnnotationExtensionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "extensionHandle"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "extensionHandle"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "annotationExtensions_UpdateAnnotationExtensionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AnnotationExtension",
        "kind": "LinkedField",
        "name": "annotationExtension",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateAnnotationExtensionPage_annotationExtension"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateAnnotationExtensionPage_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "annotationExtensions_UpdateAnnotationExtensionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AnnotationExtension",
        "kind": "LinkedField",
        "name": "annotationExtension",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AnnotationExtensionRelease",
            "kind": "LinkedField",
            "name": "latestRelease",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6e94e158665b02efb2b301a0f4c27cd",
    "id": null,
    "metadata": {},
    "name": "annotationExtensions_UpdateAnnotationExtensionQuery",
    "operationKind": "query",
    "text": "query annotationExtensions_UpdateAnnotationExtensionQuery(\n  $organizationSlug: String!\n  $extensionHandle: String!\n) {\n  annotationExtension(handle: $extensionHandle) {\n    ...UpdateAnnotationExtensionPage_annotationExtension\n    id\n  }\n  organization: organizationBySlug(slug: $organizationSlug) {\n    ...UpdateAnnotationExtensionPage_organization\n    id\n  }\n}\n\nfragment UpdateAnnotationExtensionPage_annotationExtension on AnnotationExtension {\n  id\n  name\n  latestRelease {\n    version\n    id\n  }\n}\n\nfragment UpdateAnnotationExtensionPage_organization on Organization {\n  id\n}\n"
  }
};
})();

(node as any).hash = "d9794ea7e6695ba940e60f07b89c3837";

export default node;
