import { LDClient } from 'config/FeatureFlags';

import { useLaunchDarklyClientAllowMissingContext } from 'components/LaunchDarklyContext';
import {
  ViewerContext,
  hasPermissionWithState,
  useViewerAllowMissingContext,
} from 'utils/viewerContext';

export function canAccessPortfolio(
  viewer: ViewerContext | null,
  ldClient: LDClient | null,
) {
  if (!viewer || !ldClient) return false;

  if (viewer.domain) {
    return ldClient.variation('scanlab-learner-view');
  }

  return ldClient.variationAnyOrganization('scanlab-learner-view');
}

const CAN_ACCESS_REVIEW_DEFAULT = {
  canRequestReview: false,
  canReview: false,
};

export function canAccessReview(
  viewer: ViewerContext | null,
  ldClient: LDClient | null,
): {
  canRequestReview: boolean;
  canReview: boolean;
} {
  if (!viewer || !ldClient) return CAN_ACCESS_REVIEW_DEFAULT;

  // domain users

  if (viewer.domain) {
    if (!ldClient.variation('scanlab-learner-view'))
      return CAN_ACCESS_REVIEW_DEFAULT;

    const canRequestReview =
      ldClient.variation('scanlab-admin-view') &&
      ldClient.variation('worksheets-review');

    return {
      canRequestReview,
      canReview:
        canRequestReview && hasPermissionWithState(viewer, 'qa', 'BASIC'),
    };
  }

  // public cloud users
  if (!ldClient.variationAnyOrganization('scanlab-learner-view'))
    return CAN_ACCESS_REVIEW_DEFAULT;

  const canRequestReview =
    ldClient.variationAnyOrganization('scanlab-admin-view') &&
    ldClient.variationAnyOrganization('worksheets-review');

  return {
    canRequestReview,
    canReview: canRequestReview && hasPermissionWithState(viewer, 'qa'),
  };
}

export function useCanAccessScanLab() {
  const viewer = useViewerAllowMissingContext();
  const ldClient = useLaunchDarklyClientAllowMissingContext();

  return {
    review: canAccessReview(viewer, ldClient).canReview,
    portfolio: canAccessPortfolio(viewer, ldClient),
  };
}
