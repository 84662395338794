/**
 * @generated SignedSource<<503dda91254e4cc8072b16378d80bdb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppHeaderUserDropdownButton_organization$data = {
  readonly name: string | null;
  readonly slug: string | null;
  readonly " $fragmentType": "AppHeaderUserDropdownButton_organization";
};
export type AppHeaderUserDropdownButton_organization$key = {
  readonly " $data"?: AppHeaderUserDropdownButton_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppHeaderUserDropdownButton_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppHeaderUserDropdownButton_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "8692b2179d3d3b9ab5bebdfe3d69ee04";

export default node;
