/**
 * @generated SignedSource<<141f7cac639c941aa857979c017a5ec5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type account_UserSignUpPage_Query$variables = {
  inviteToken: string;
};
export type account_UserSignUpPage_Query$data = {
  readonly inviteInfo: {
    readonly " $fragmentSpreads": FragmentRefs<"UserSignUpPage_inviteInfo">;
  } | null;
};
export type account_UserSignUpPage_Query = {
  response: account_UserSignUpPage_Query$data;
  variables: account_UserSignUpPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inviteToken"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "inviteToken"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "account_UserSignUpPage_Query",
    "selections": [
      {
        "alias": "inviteInfo",
        "args": (v1/*: any*/),
        "concreteType": "UserInviteInfo",
        "kind": "LinkedField",
        "name": "userInviteInfoByToken",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSignUpPage_inviteInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "account_UserSignUpPage_Query",
    "selections": [
      {
        "alias": "inviteInfo",
        "args": (v1/*: any*/),
        "concreteType": "UserInviteInfo",
        "kind": "LinkedField",
        "name": "userInviteInfoByToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainProfile",
            "kind": "LinkedField",
            "name": "domainProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "handle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FederatedIdentityProvider",
                "kind": "LinkedField",
                "name": "federatedIdentityProvider",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a63ae60ed72d5061cb7e45f308b21c85",
    "id": null,
    "metadata": {},
    "name": "account_UserSignUpPage_Query",
    "operationKind": "query",
    "text": "query account_UserSignUpPage_Query(\n  $inviteToken: String!\n) {\n  inviteInfo: userInviteInfoByToken(token: $inviteToken) {\n    ...UserSignUpPage_inviteInfo\n  }\n}\n\nfragment UserSignUpPage_inviteInfo on UserInviteInfo {\n  email\n  domainProfile {\n    handle\n    federatedIdentityProvider {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbf41da007632534801ed5e6864b5e47";

export default node;
