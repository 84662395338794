/**
 * @generated SignedSource<<8aa83797084240e97f19396fbd884b84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationStudyPacsPushStatusUpdatedInput = {
  clientSubscriptionId?: string | null;
  organizationId: any;
};
export type StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription$variables = {
  input: OrganizationStudyPacsPushStatusUpdatedInput;
};
export type StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription$data = {
  readonly organizationStudyPacsPushStatusUpdated: {
    readonly studyEdge: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"StudySyncStatus_study">;
      } | null;
    } | null;
  } | null;
};
export type StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription = {
  response: StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription$data;
  variables: StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationStudyPacsPushStatusUpdatedPayload",
        "kind": "LinkedField",
        "name": "organizationStudyPacsPushStatusUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyEdge",
            "kind": "LinkedField",
            "name": "studyEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StudySyncStatus_study"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationStudyPacsPushStatusUpdatedPayload",
        "kind": "LinkedField",
        "name": "organizationStudyPacsPushStatusUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyEdge",
            "kind": "LinkedField",
            "name": "studyEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pacsPushStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pacsPushHasPausedJob",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ehrOruStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ehrOruHasPausedJob",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f643df2bd8c0ae5ef2e46fa1c538cfa",
    "id": null,
    "metadata": {},
    "name": "StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription(\n  $input: OrganizationStudyPacsPushStatusUpdatedInput!\n) {\n  organizationStudyPacsPushStatusUpdated(input: $input) {\n    studyEdge {\n      node {\n        ...StudySyncStatus_study\n        id\n      }\n    }\n  }\n}\n\nfragment StudySyncStatus_study on Study {\n  pacsPushStatus\n  pacsPushHasPausedJob\n  ehrOruStatus\n  ehrOruHasPausedJob\n}\n"
  }
};
})();

(node as any).hash = "2e1d0c93b64227c99d4d4a61c27963b6";

export default node;
