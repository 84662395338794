import React from 'react';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import NotFoundNotification from './NotFoundNotification';
import StudyNotification from './StudyNotification';
import { StudyFinalizationRequestedNotification_notification$data as Notification } from './__generated__/StudyFinalizationRequestedNotification_notification.graphql';

const messages = defineMessages({
  notification: {
    id: 'notificationCenter.notification.requestStudyFinalization.action',
    defaultMessage:
      '{username} requested you sign and finalize the study {studyName}',
  },
});

interface Props {
  notification: Notification;
  clearable: boolean;
}

function StudyFinalizationRequestedNotification({
  notification,
  clearable,
}: Props) {
  const { study } = notification;

  if (!study || study?.deletedAt) {
    return (
      <NotFoundNotification
        notification={notification}
        clearable={clearable}
      />
    );
  }

  return (
    <StudyNotification
      notification={notification}
      userProfile={study.createdBy}
      study={study}
      message={messages.notification}
      image={null}
      clearable={clearable}
    />
  );
}

export default createFragmentContainer(
  StudyFinalizationRequestedNotification,
  {
    notification: graphql`
      fragment StudyFinalizationRequestedNotification_notification on NotificationInterface {
        ... on StudyFinalizationRequestedNotification {
          study {
            deletedAt
            createdBy {
              ...StudyNotification_userProfile
            }
            ...StudyNotification_study
          }
        }
        ...NotFoundNotification_notification
        ...StudyNotification_notification
      }
    `,
  },
);
