/**
 * @generated SignedSource<<8cc6f6ece43f9a67e09b673f62129743>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DomainMemberSettingsPage_domainUser$data = {
  readonly email: string | null;
  readonly id: string;
  readonly profile: {
    readonly name: string | null;
  } | null;
  readonly type: DomainUserType | null;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMemberDeleteFieldSet_domainUser" | "DomainMemberDetailFieldSet_domainUser" | "domainUser_defaultValue">;
  readonly " $fragmentType": "DomainMemberSettingsPage_domainUser";
};
export type DomainMemberSettingsPage_domainUser$key = {
  readonly " $data"?: DomainMemberSettingsPage_domainUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainMemberSettingsPage_domainUser">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainMemberSettingsPage_domainUser",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "domainUser_defaultValue",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canQa",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canFinalize",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isNurse",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipRole",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainUserIntegrationConfig",
          "kind": "LinkedField",
          "name": "integrationConfigs",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Ehr",
              "kind": "LinkedField",
              "name": "ehr",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "interfaceCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainUserDicomField",
          "kind": "LinkedField",
          "name": "dicomFields",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DomainDicomFieldTemplate",
              "kind": "LinkedField",
              "name": "dicomFieldTemplate",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisplayNameFirst",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisplayNameMiddle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisplayNameLast",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DomainMemberDetailFieldSet_domainUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DomainMemberDeleteFieldSet_domainUser"
    }
  ],
  "type": "DomainUser",
  "abstractKey": null
};
})();

(node as any).hash = "36047c1fc4daa74c6191f594ec409f05";

export default node;
