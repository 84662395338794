import FormattedDateTime from '@bfly/ui2/FormattedDateTime';

import { ColumnComparator, ColumnDate } from '../types';
import colDefType from './colDefType';

const customDateComparator: ColumnComparator = (
  valueA,
  valueB,
  _nodeA,
  _nodeB,
  isDescending,
) => {
  if (valueA === valueB) {
    return 0;
  }
  if (!valueA) {
    return isDescending ? 1 : -1;
  }
  if (!valueB) {
    return isDescending ? -1 : 1;
  }
  return valueA > valueB ? -1 : 1;
};

export default function colDefDate(colDef: ColumnDate) {
  return colDefType({
    ...colDef,
    comparator: customDateComparator,
    cellRenderer: ({ value }) => <FormattedDateTime value={value} />,
    editable: false,
  });
}
