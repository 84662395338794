/**
 * @generated SignedSource<<7bffa98b063d73a445be4167262ce419>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyEhrJobDetail_ehr$data = {
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SendStudyToEhrButton_ehr">;
  readonly " $fragmentType": "StudyEhrJobDetail_ehr";
};
export type StudyEhrJobDetail_ehr$key = {
  readonly " $data"?: StudyEhrJobDetail_ehr$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyEhrJobDetail_ehr">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyEhrJobDetail_ehr",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendStudyToEhrButton_ehr"
    }
  ],
  "type": "Ehr",
  "abstractKey": null
};

(node as any).hash = "54b26617605865c8469493126a25980d";

export default node;
