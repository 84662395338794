import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import keyBy from 'lodash/keyBy';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import CommentMention from './CommentMention';
import { CommentBody_comment$data as Comment } from './__generated__/CommentBody_comment.graphql';

const styles = stylesheet`
  .body {
    overflow-wrap: break-word;
    overflow: hidden;
    margin: 0;
  }
`;

// This captures just the handle part of every mention when splitting.
const MENTION_REGEX = /@\[([\w\d|-]+)]/;

interface Props {
  comment: Comment;
  className?: string;
}

function CommentBody({ comment, className }: Props) {
  const mentionsByHandle = keyBy(comment.mentions!, 'handle');

  return (
    <p className={clsx(className, styles.body)}>
      {comment.body!.split(MENTION_REGEX).map((piece, index) => {
        // The split gives an array with alternating text and mentions. The
        // even elements are the comment text. We render those directly.
        if (!(index % 2)) {
          return piece;
        }

        return (
          <CommentMention
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            userProfile={mentionsByHandle[piece]}
          />
        );
      })}
    </p>
  );
}

export default createFragmentContainer(CommentBody, {
  comment: graphql`
    fragment CommentBody_comment on StudyImageComment {
      body
      mentions {
        handle
        ...CommentMention_userProfile
      }
    }
  `,
});
