import Pagination from '@bfly/ui2/Pagination';
import { GenericConnection } from '@bfly/utils/getNodes';
import { useRouter } from 'found';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ExamQuickFilters from 'components/ExamQuickFilters';
import PageTitle from 'components/PageTitle';
import StudyListContent from 'components/StudyListContent';
import { PaginationMeta } from 'hooks/usePagedConnection';
import { StudyDataColumnUserPreferenceKey } from 'hooks/useStudyDataColumnsUserPreference';
import { STUDY_GRID_PAGE_SIZE, StudyListFilter } from 'utils/StudyConstants';

import { PagedStudiesListPage_organization$data as Organization } from './__generated__/PagedStudiesListPage_organization.graphql';
import { PagedStudiesListPage_viewer$data as Viewer } from './__generated__/PagedStudiesListPage_viewer.graphql';

const newListMessages = defineMessages({
  [StudyListFilter.MY_DRAFTS]: {
    id: 'studiesListPage.MY_DRAFTS',
    defaultMessage: 'Drafts',
  },
  [StudyListFilter.MY_SIGNATURE_REQUESTED]: {
    id: 'studiesListPage.MY_SIGNATURE_REQUESTED',
    defaultMessage: 'Signature',
  },
  [StudyListFilter.NEEDS_REVIEW]: {
    id: 'studiesListPage.NEEDS_REVIEW',
    defaultMessage: 'Needs QA',
  },
  [StudyListFilter.UNASSIGNED]: {
    id: 'studiesListPage.UNASSIGNED',
    defaultMessage: 'Unassigned',
  },
  [StudyListFilter.FAVORITES]: {
    id: 'studiesListPage.FAVORITES',
    defaultMessage: 'Favorites',
  },
  [StudyListFilter.ALL]: {
    id: 'studiesListPage.ALL',
    defaultMessage: 'Exams',
  },
  [StudyListFilter.DELETED]: {
    id: 'studiesListPage.DELETED',
    defaultMessage: 'Deleted',
  },
});

interface Props {
  organization: Organization;
  loading?: boolean;
  viewer: Viewer;
  shouldClearStatus?: boolean;
  paginationMeta: PaginationMeta;
  studies: GenericConnection<any>;
}

const PagedStudiesListPage = ({
  organization,
  viewer,
  loading,
  shouldClearStatus = true,
  paginationMeta,
  studies,
}: Props) => {
  const { index, numEdges, loadNext, loadPrevious, fetching } = paginationMeta;
  const { match } = useRouter();

  const currentFilter = (match.params.status || 'all') as StudyListFilter;

  return (
    <StudyListContent
      allowDelete
      allowMove
      navKind="SEARCH"
      lazyLoadImages
      loading={fetching || loading}
      shouldClearStatus={shouldClearStatus}
      title={
        <PageTitle
          variant="xl"
          title={
            newListMessages[currentFilter] ||
            newListMessages[StudyListFilter.ALL]
          }
        />
      }
      viewer={viewer}
      archive={null}
      domain={null}
      organization={organization!}
      studies={studies.edges as any}
      columnsPreferenceKey={StudyDataColumnUserPreferenceKey.STUDY_LIST}
      headerActions={
        <>
          <ExamQuickFilters
            tenant={organization}
            includeArchives
            includeAuthors={
              (currentFilter === StudyListFilter.MY_DRAFTS &&
                !!match.location.query.canSign) ||
              currentFilter === StudyListFilter.NEEDS_REVIEW ||
              currentFilter === StudyListFilter.FAVORITES ||
              currentFilter === StudyListFilter.ALL ||
              currentFilter === StudyListFilter.DELETED
            }
            includeStatuses={
              currentFilter === StudyListFilter.ALL ||
              currentFilter === StudyListFilter.FAVORITES ||
              currentFilter === StudyListFilter.UNASSIGNED ||
              currentFilter === StudyListFilter.DELETED
            }
          />
          <Pagination
            data-bni-id="PaginationControls"
            index={index}
            onNextPage={loadNext}
            onPreviousPage={loadPrevious}
            pageSize={STUDY_GRID_PAGE_SIZE}
            numItems={numEdges}
            className="mr-3"
          />
        </>
      }
    />
  );
};

export default createFragmentContainer(PagedStudiesListPage, {
  organization: graphql`
    fragment PagedStudiesListPage_organization on Organization {
      ...StudyListContent_organization
      ...ExamQuickFilters_tenant
        @arguments(includeArchives: true, includeAuthors: true)
    }
  `,
  viewer: graphql`
    fragment PagedStudiesListPage_viewer on Viewer {
      ...StudyListContent_viewer
    }
  `,
});
