// This needs to be broken out into an exportable property
// to be tested, due to the fact that window.location.assign
// cannot be mocked out

const ExternalRedirect = window[Symbol.for('ExternalRedirect')] || {
  redirect: (path: string): void => {
    window.location.replace(path);
  },
  assign: (path: string): void => {
    window.location.assign(path);
  },
};

export default ExternalRedirect;
