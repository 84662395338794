import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Errors } from 'react-formal/types';

import useMountMemo from 'hooks/useMountMemo';

import { NavbarMeta } from '../utils/examPageTopNavbar';
import { StudyFinalizationRestriction } from '../utils/useStudyFinalization';

interface FinalizationErrors {
  [StudyFinalizationRestriction.AUTHOR_REQUIRED]: boolean;
  [StudyFinalizationRestriction.EXAM_TYPE_REQUIRED]: boolean;
  [StudyFinalizationRestriction.WORKLIST_REQUIRED]: boolean;
  [StudyFinalizationRestriction.PATIENT_ID_REQUIRED]: boolean;
  [StudyFinalizationRestriction.WORKSHEET_REQUIRED]: boolean;
  [StudyFinalizationRestriction.SCRIBE_CANNOT_SIGN]: boolean;
  [StudyFinalizationRestriction.PRIMARY_AUTHOR_MUST_SIGN]: boolean;
  worksheetsErrors: Record<string, Errors> | null;
}

export interface ExamContextValue {
  saving: boolean;
  validateWorksheets: () => Promise<boolean>;
  finalizationErrors: FinalizationErrors | null;
  navbarMeta?: NavbarMeta;
  qaErrors: Errors | null;
}

export interface ExamSetterContextValue {
  setSaving(saving: boolean): void;
  setShowErrors(showErrors: boolean): void;
  registerWorksheetSubmit: (submitFn: () => Promise<boolean>) => () => void;
  setWorksheetsErrors: (errors: Record<string, Errors>) => void;
  setQaErrors: (errors: Errors) => void;
  setRestrictions: (
    restrictions: Partial<Record<StudyFinalizationRestriction, boolean>>,
  ) => void;
  setNavbarMeta: (navbarMeta: NavbarMeta) => void;
}

const ExamContext = React.createContext<ExamContextValue>(null as any);

const ExamSetterContext = React.createContext<ExamSetterContextValue>(
  null as any,
);

/**
 * Provides details about the study state to the entire exam page.
 * Contexts are split between read and write to avoid consumers of the
 * never-changing setters (worksheet forms in particular) from updating
 * when the broad state changes. This is an optimization to allow
 * specific leaf components to read potentially noisy state (like saving)
 * without the rest of the page updating.
 */
export function ExamContextProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const submitsRef = useRef(new Set<() => Promise<boolean>>());
  const [saving, setSaving] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [restrictions, setRestrictions] = useState<
    Partial<Record<StudyFinalizationRestriction, boolean>>
  >({});
  const [navbarMeta, setNavbarMeta] = useState<NavbarMeta>();

  const [worksheetsErrors, setWorksheetsErrors] = useState<Record<
    string,
    Errors
  > | null>({});

  const [qaErrors, setQaErrors] = useState<Errors | null>(null);

  const validateWorksheets = useCallback(() => {
    return Promise.all(Array.from(submitsRef.current, (s) => s())).then(
      (values) => values.every((valid) => valid === true),
    );
  }, []);

  const setterContext = useMountMemo<ExamSetterContextValue>(() => ({
    setSaving,
    setShowErrors,
    setQaErrors(nextErrors) {
      const allEmpty = !nextErrors || !Object.keys(nextErrors).length;
      setQaErrors(allEmpty ? null : nextErrors);

      if (allEmpty) setShowErrors(false);
    },
    setWorksheetsErrors(nextErrors) {
      const allEmpty = Object.values(nextErrors || {}).every(
        (e) => !e || !Object.keys(e).length,
      );
      setWorksheetsErrors(allEmpty ? null : nextErrors);
    },
    setNavbarMeta,
    setRestrictions,
    registerWorksheetSubmit(submitFn) {
      submitsRef.current.add(submitFn);
      return () => submitsRef.current.delete(submitFn);
    },
  }));

  const valueContext = useMemo<ExamContextValue>(
    (): ExamContextValue => ({
      saving,
      validateWorksheets,
      navbarMeta,
      qaErrors: showErrors ? qaErrors : null,
      finalizationErrors:
        showErrors &&
        (Object.values(restrictions).filter(Boolean).length ||
          worksheetsErrors)
          ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ({
              ...restrictions,
              worksheetsErrors,
            } as FinalizationErrors)
          : null,
    }),
    [
      saving,
      validateWorksheets,
      navbarMeta,
      showErrors,
      restrictions,
      worksheetsErrors,
      qaErrors,
    ],
  );

  return (
    <ExamSetterContext.Provider value={setterContext}>
      <ExamContext.Provider value={valueContext}>
        {children}
      </ExamContext.Provider>
    </ExamSetterContext.Provider>
  );
}

export function useExamContext() {
  return useContext(ExamContext);
}

export function useExamSetterContext() {
  return useContext(ExamSetterContext);
}
