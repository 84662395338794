/**
 * @generated SignedSource<<229769caa8ab17064e3615ea556bffdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DayOfWeekType = "FRIDAY" | "MONDAY" | "SATURDAY" | "SUNDAY" | "THURSDAY" | "TUESDAY" | "WEDNESDAY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkflowDigestsForm_schedule$data = {
  readonly daysOfWeek: ReadonlyArray<DayOfWeekType | null>;
  readonly id: string;
  readonly time: any | null;
  readonly timezone: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"workflowDigests_schedule">;
  readonly " $fragmentType": "WorkflowDigestsForm_schedule";
};
export type WorkflowDigestsForm_schedule$key = {
  readonly " $data"?: WorkflowDigestsForm_schedule$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkflowDigestsForm_schedule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysOfWeek",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkflowDigestsForm_schedule",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "workflowDigests_schedule",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v2/*: any*/),
    (v0/*: any*/),
    (v1/*: any*/)
  ],
  "type": "WorkflowDigestSchedule",
  "abstractKey": null
};
})();

(node as any).hash = "3fe4da50e07e2ed073c4618eff2d1726";

export default node;
