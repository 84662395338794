import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import { createFragmentContainer, graphql } from 'react-relay';

import { RoutePageProps } from 'components/Route';

import GlobalStudySearchSubNav from './GlobalStudySearchSubNav';
import { GlobalStudySearchPage_organization$data as Organization } from './__generated__/GlobalStudySearchPage_organization.graphql';
import { GlobalStudySearchPage_viewer$data as Viewer } from './__generated__/GlobalStudySearchPage_viewer.graphql';

interface Props extends RoutePageProps {
  organization: Organization;
  viewer: Viewer;
  loading?: boolean;
}

function GlobalStudySearchPage({
  organization,
  viewer,
  children,
  loading,
}: Props) {
  // XXX: this route should not use staleWhileFetching but needs too until we remove the old search route
  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <GlobalStudySearchSubNav tenant={organization} viewer={viewer} />
      {children}
    </>
  );
}

export default createFragmentContainer(GlobalStudySearchPage, {
  organization: graphql`
    fragment GlobalStudySearchPage_organization on Organization {
      ...GlobalStudySearchSubNav_tenant
    }
  `,

  viewer: graphql`
    fragment GlobalStudySearchPage_viewer on Viewer {
      ...GlobalStudySearchSubNav_viewer
    }
  `,
});
