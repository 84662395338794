import Layout from '@4c/layout';
import ChevronLeftIcon from '@bfly/icons/ChevronLeft';
import Heading from '@bfly/ui2/Heading';
import ItemGrid from '@bfly/ui2/ItemGrid';
import Link from '@bfly/ui2/Link';
import getNodes from '@bfly/utils/getNodes';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import CheckGroupSelectButton from 'components/CheckGroupSelectButton';
import ConfigSectionTitle from 'components/ConfigSectionTitle';
import { useArchiveRoutes } from 'routes/archive';

import { WorkflowDigestsExcludedArchives_archiveConnection$data as ArchiveConnection } from './__generated__/WorkflowDigestsExcludedArchives_archiveConnection.graphql';

interface WorkflowDigestsExcludedArchivesProps {
  archiveConnection: ArchiveConnection | null;
}

const WorkflowDigestsExcludedArchives = ({
  archiveConnection,
}: WorkflowDigestsExcludedArchivesProps) => {
  const [archivesByOrganization, organizations] = useMemo(() => {
    const archives = getNodes(archiveConnection).map((a) => ({
      handle: a.handle!,
      label: a.label!,
      organization: {
        name: a.organization!.name!,
        slug: a.organization!.slug!,
      },
    }));

    type Archive = typeof archives[0];

    const orgs: Archive['organization'][] = [];
    const byOrg: Record<string, Archive[]> = {};

    if (archives.length) {
      for (const archive of archives) {
        if (archive.organization.slug) {
          if (!byOrg[archive.organization.slug]) {
            byOrg[archive.organization.slug] = [];
            orgs.push(archive.organization);
          }
          byOrg[archive.organization.slug].push(archive);
        }
      }

      // alpha order org names and archive labels
      orgs.sort((a, b) => a.name!.localeCompare(b.name!));
      orgs.forEach((org) => {
        byOrg[org.slug] = byOrg[org.slug].sort((a, b) =>
          a.label.localeCompare(b.label),
        );
      });
    }

    return [byOrg, orgs];
  }, [archiveConnection]);

  const [closedOrganization, setClosedOrganization] = useState<string[]>([]);

  const archiveRoutes = useArchiveRoutes();

  const [orgFilterValues, setOrgFilterValues] = useState<string[]>([]);

  return (
    <>
      <ConfigSectionTitle>
        <Layout align="center" alignContent="flex-start">
          <FormattedMessage
            id="workflowDigestsPage.excludedArchives"
            defaultMessage="Excluded archives"
          />
        </Layout>
      </ConfigSectionTitle>
      <div className="my-2">
        <FormattedMessage
          id="workflowDigestsPage.excludedArchivesDescription"
          defaultMessage="The following archive(s) in this domain are excluded from To Do Lists and will not be included in workflow email 
digests. Organizations that are not listed here do not have any excluded archives."
        />
      </div>
      {!archivesByOrganization && (
        <div className="my-2">
          <FormattedMessage
            id="workflowDigestsPage.noExcludedArchives"
            defaultMessage="There are no excluded archives."
          />
        </div>
      )}
      {archivesByOrganization && (
        <Layout direction="column" pad={8} className="mt-8">
          <CheckGroupSelectButton
            value={organizations.filter((o) =>
              orgFilterValues.includes(o.slug || ''),
            )}
            dataKey="slug"
            placeholder={
              <FormattedMessage
                id="organizationSelectButton.placeholder"
                defaultMessage="All organizations"
              />
            }
            onChange={(values) =>
              setOrgFilterValues(values?.map((v) => v.slug) || [])
            }
            data={organizations}
            textKey="name"
          />
          {organizations.map(
            ({ slug: organizationSlug }) =>
              (!orgFilterValues.length ||
                orgFilterValues.includes(organizationSlug)) && (
                <section key={organizationSlug}>
                  <Heading
                    className="text-md pb-2 mb-0 border-b border-b-grey-70 flex items-center cursor-pointer"
                    onClick={() => {
                      setClosedOrganization((prev) => {
                        const isClosed = prev.includes(organizationSlug);
                        return isClosed
                          ? prev.filter((h) => h !== organizationSlug)
                          : [...prev, organizationSlug];
                      });
                    }}
                  >
                    {
                      archivesByOrganization[organizationSlug][0].organization
                        .name
                    }
                    <ChevronLeftIcon
                      width={8}
                      className={clsx(
                        'ml-3',
                        closedOrganization.includes(organizationSlug)
                          ? '-rotate-90'
                          : 'rotate-90',
                      )}
                    />
                  </Heading>
                  <div
                    className={clsx(
                      closedOrganization.includes(organizationSlug) &&
                        'hidden',
                    )}
                  >
                    <ItemGrid role="table" templateColumns={2}>
                      <tbody>
                        {archivesByOrganization[organizationSlug].map(
                          (archive, i) => (
                            <ItemGrid.Row key={i as any}>
                              <ItemGrid.Cell color="headline">
                                {archive.label}
                              </ItemGrid.Cell>
                              <ItemGrid.Cell>
                                <Link
                                  to={archiveRoutes.archiveSettings({
                                    organizationSlug,
                                    archiveHandle: archive.handle!,
                                  })}
                                >
                                  <FormattedMessage
                                    id="workflowDigestsPage.settings"
                                    defaultMessage="Settings"
                                  />
                                </Link>
                              </ItemGrid.Cell>
                            </ItemGrid.Row>
                          ),
                        )}
                      </tbody>
                    </ItemGrid>
                  </div>
                </section>
              ),
          )}
        </Layout>
      )}
    </>
  );
};

export default createFragmentContainer(WorkflowDigestsExcludedArchives, {
  archiveConnection: graphql`
    fragment WorkflowDigestsExcludedArchives_archiveConnection on ArchiveConnection {
      edges {
        node {
          organization {
            name
            slug
          }
          ... on Archive {
            label
            handle
          }
        }
      }
    }
  `,
});
