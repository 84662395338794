/**
 * @generated SignedSource<<bbd32cfa4c937a823b57410036f41082>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferralsPage_organization$data = {
  readonly viewerReferrerInfo: {
    readonly referrerToken: string | null;
  } | null;
  readonly " $fragmentType": "ReferralsPage_organization";
};
export type ReferralsPage_organization$key = {
  readonly " $data"?: ReferralsPage_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferralsPage_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferralsPage_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReferrerInfo",
      "kind": "LinkedField",
      "name": "viewerReferrerInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referrerToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "e67096d1adb44209d2bbf87396fd6b42";

export default node;
