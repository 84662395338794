/**
 * @generated SignedSource<<9934be93b87fbc1624e27ecb843d75ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type analyticsRoutes_CredentialingPageQuery$variables = {
  credentialGroupId: string;
  hasCredentialGroup: boolean;
  organizationSlug?: string | null;
};
export type analyticsRoutes_CredentialingPageQuery$data = {
  readonly credentialGroup?: {
    readonly " $fragmentSpreads": FragmentRefs<"CredentialingPage_credentialGroup">;
  } | null;
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"CredentialingPage_tenant">;
  } | null;
};
export type analyticsRoutes_CredentialingPageQuery = {
  response: analyticsRoutes_CredentialingPageQuery$data;
  variables: analyticsRoutes_CredentialingPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "credentialGroupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasCredentialGroup"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "credentialGroupId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "analyticsRoutes_CredentialingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CredentialingPage_tenant"
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasCredentialGroup",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "credentialGroup",
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CredentialingPage_credentialGroup"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "analyticsRoutes_CredentialingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          {
            "alias": "type",
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasCredentialGroup",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "credentialGroup",
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dueAt",
                    "storageKey": null
                  }
                ],
                "type": "CredentialGroup",
                "abstractKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "2e51dad0c7585f037c8a7e208b2d9c50",
    "id": null,
    "metadata": {},
    "name": "analyticsRoutes_CredentialingPageQuery",
    "operationKind": "query",
    "text": "query analyticsRoutes_CredentialingPageQuery(\n  $organizationSlug: String\n  $credentialGroupId: ID!\n  $hasCredentialGroup: Boolean!\n) {\n  tenant(slug: $organizationSlug) {\n    __typename\n    ...CredentialingPage_tenant\n    id\n  }\n  credentialGroup: node(id: $credentialGroupId) @include(if: $hasCredentialGroup) {\n    __typename\n    ...CredentialingPage_credentialGroup\n    id\n  }\n}\n\nfragment CredentialingPage_credentialGroup on CredentialGroup {\n  dueAt\n}\n\nfragment CredentialingPage_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  type: __typename\n  id\n  handle\n  ... on Organization {\n    name\n    slug\n  }\n  ...MemberSelectButton_tenant\n}\n\nfragment MemberSelectButton_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  id\n}\n"
  }
};
})();

(node as any).hash = "428b7433a2bdcc15659d677a1e8a74b3";

export default node;
