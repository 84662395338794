import { defineMessages } from 'react-intl';

export default defineMessages({
  password: {
    id: 'account.password.password',
    defaultMessage: 'Password',
  },
  prevPassword: {
    id: 'account.password.prevPassword',
    defaultMessage: 'Current Password',
  },
  newPassword: {
    id: 'account.password.newPassword',
    defaultMessage: 'New Password',
  },
  confirmPassword: {
    id: 'account.password.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  help: {
    id: 'account.password.password.help',
    defaultMessage: 'Must be at least 8 characters long',
  },
  required: {
    id: 'account.password.required',
    defaultMessage: 'Please enter a password',
  },
  length: {
    id: 'account.password.password.length',
    defaultMessage: 'Password must be at least 8 characters long',
  },
  mismatch: {
    id: 'account.password.passwordMismatch',
    defaultMessage: "Passwords don't match",
  },
});
