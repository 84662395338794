import Layout from '@4c/layout';
import ItemGrid from '@bfly/ui2/ItemGrid';
import Link from '@bfly/ui2/Link';
import MutationButton from '@bfly/ui2/MutationButton';
import Text from '@bfly/ui2/Text';
import Textarea from '@bfly/ui2/Textarea';
import { useState } from 'react';
import { useFormValues } from 'react-formal';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import actionMessages from 'messages/actions';
import { useArchiveRoutes } from 'routes/archive';

import ArchiveStudyRestrictionListItem from './ArchiveStudyRestrictionsListItem';
import type { RestrictedFinalizationForm_Mutation as Mutation } from './__generated__/RestrictedFinalizationForm_Mutation.graphql';
import type { RestrictedFinalizationForm_archive$data as Archive } from './__generated__/RestrictedFinalizationForm_archive.graphql';

const mutation = graphql`
  mutation RestrictedFinalizationForm_Mutation($input: UpdateArchiveInput!) {
    updateArchiveOrError(input: $input) {
      ... on UpdateArchivePayload {
        archive {
          ...RestrictedFinalizationForm_archive
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface Props {
  archive: Archive;
  disabled?: boolean;
}

function RestrictedFinalizationForm({ archive, disabled }: Props) {
  const canUseStudyRestrictions = useVariation('study-restrictions');
  const archiveRoutes = useArchiveRoutes();

  const autoFinalizeNbdExams: boolean | undefined = useFormValues(
    'autoFinalizeNbdExams',
  );

  const [attestationMessage, setAttestationMessage] = useState(
    archive.attestationMessage,
  );

  return (
    <ArchiveStudyRestrictionListItem
      name="hasRestrictedFinalization"
      disabled={disabled || autoFinalizeNbdExams}
      title={
        <FormattedMessage
          id="archiveSettings.restrictFinalization.title.sign"
          defaultMessage="Require an attestation message to sign studies"
        />
      }
      subtitle={
        <FormattedMessage
          id="archiveSettings.restrictFinalization.description.sign"
          defaultMessage="Only selected <a>archive members</a> can sign studies."
          values={{
            a: (msg) => <Link to={archiveRoutes.archiveMembers()}>{msg}</Link>,
          }}
        />
      }
    >
      {archive.hasRestrictedFinalization && (
        <ItemGrid role="list" templateColumns={1}>
          <ItemGrid.Row>
            <ItemGrid.Cell>
              <Layout direction="column" className="w-full" pad={3}>
                <Layout direction="row" pad={4}>
                  <Text as="div">
                    <FormattedMessage
                      id="archiveSettings.restrictFinalization.attestationMessage.label"
                      defaultMessage="Attestation Message"
                    />
                  </Text>
                  <Textarea
                    data-bni-id="ArchiveSettingsRestrictFinalizationAttestationMessageTextarea"
                    className="grow"
                    value={attestationMessage}
                    onChange={(event) =>
                      setAttestationMessage(event.target.value)
                    }
                  />
                </Layout>
                <Layout reverse>
                  <MutationButton<Mutation>
                    data-bni-id="ArchiveSettingsRestrictFinalizationAttestationMessageSaveButton"
                    variant="primary"
                    mutation={mutation}
                    input={{ archiveId: archive.id, attestationMessage }}
                    successToastMessage={
                      <FormattedMessage
                        id="archiveSettings.restrictFinalization.success"
                        defaultMessage="Attestation message updated"
                      />
                    }
                  >
                    <FormattedMessage {...actionMessages.save} />
                  </MutationButton>
                </Layout>
              </Layout>
            </ItemGrid.Cell>
          </ItemGrid.Row>
          {canUseStudyRestrictions && (
            <ArchiveStudyRestrictionListItem
              name="studyAuthorRequiredToRequestFinalization"
              disabled={disabled}
              title={
                <FormattedMessage
                  id="archiveSettings.restrictions.author"
                  defaultMessage="Require author to request attestation"
                />
              }
              subtitle={
                <FormattedMessage
                  id="archiveSettings.restrictions.authorLabel"
                  defaultMessage="All studies in this archive require an author in order to request attestation."
                />
              }
            />
          )}
        </ItemGrid>
      )}
    </ArchiveStudyRestrictionListItem>
  );
}

export default createFragmentContainer(RestrictedFinalizationForm, {
  archive: graphql`
    fragment RestrictedFinalizationForm_archive on Archive {
      id
      hasRestrictedFinalization
      attestationMessage
    }
  `,
});
