/**
 * @generated SignedSource<<2d92d2b5b1142d3d1600df574e396e14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TermsAndConditionsPage_viewer$data = {
  readonly acceptedTermsAndConditionsUrl: string | null;
  readonly " $fragmentType": "TermsAndConditionsPage_viewer";
};
export type TermsAndConditionsPage_viewer$key = {
  readonly " $data"?: TermsAndConditionsPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TermsAndConditionsPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TermsAndConditionsPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptedTermsAndConditionsUrl",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "0437b0e2e50fae2f269970e46f065fc5";

export default node;
