/**
 * @generated SignedSource<<4403a8d32a532ef8bf927f6f2aeaf869>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BulkDomainMemberUploadModal_domain$data = {
  readonly dicomFieldTemplateConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly handle: string | null;
        readonly id: string;
        readonly label: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly ehrConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly handle: string | null;
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly id: string;
  readonly membershipRoles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isDisabled: boolean | null;
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly numAvailableSeats: number | null;
  readonly " $fragmentType": "BulkDomainMemberUploadModal_domain";
};
export type BulkDomainMemberUploadModal_domain$key = {
  readonly " $data"?: BulkDomainMemberUploadModal_domain$data;
  readonly " $fragmentSpreads": FragmentRefs<"BulkDomainMemberUploadModal_domain">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "ehrConnection"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "dicomFieldTemplateConnection"
        ]
      }
    ]
  },
  "name": "BulkDomainMemberUploadModal_domain",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numAvailableSeats",
      "storageKey": null
    },
    {
      "alias": "ehrConnection",
      "args": null,
      "concreteType": "EhrConnection",
      "kind": "LinkedField",
      "name": "__Domain_ehrConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EhrEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Ehr",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "dicomFieldTemplateConnection",
      "args": null,
      "concreteType": "DomainDicomFieldTemplateConnection",
      "kind": "LinkedField",
      "name": "__Domain_dicomFieldTemplateConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DomainDicomFieldTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DomainDicomFieldTemplate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "roleType",
          "value": [
            "SYSTEM_DEFINED",
            "USER_DEFINED"
          ]
        },
        {
          "kind": "Literal",
          "name": "sort",
          "value": "NAME_ASC"
        }
      ],
      "concreteType": "MembershipRoleConnection",
      "kind": "LinkedField",
      "name": "membershipRoles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipRoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MembershipRole",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isDisabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "membershipRoles(roleType:[\"SYSTEM_DEFINED\",\"USER_DEFINED\"],sort:\"NAME_ASC\")"
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
})();

(node as any).hash = "5ca834a591b0d12127f4fceb800bb309";

export default node;
