import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import MainContent from '@bfly/ui2/MainContent';
import useRouter from 'found/useRouter';
import React, { useEffect } from 'react';

import { useAuth } from 'components/AuthContext';
import ExternalRedirect from 'utils/ExternalRedirect';

import { isValidAuthorizeRedirect } from '../utils/RedirectUtils';

function OAuthAuthorizePage() {
  const { match, router } = useRouter();
  const auth = useAuth();

  useEffect(() => {
    const redirectUri = match.location.query.redirect_uri;
    const clientId = match.location.query.client_id;
    const { state } = match.location.query;

    const { accessToken, expiresAt } = auth.getTokenInfo()!;

    const expiresIn = expiresAt - Date.now();

    if (!isValidAuthorizeRedirect(redirectUri, clientId)) {
      router.replace({
        ...match.location,
        state: { foundHttpError: 403 },
      });
    } else {
      const hashParams = new URLSearchParams([
        ['token_type', 'bearer'],
        ['access_token', accessToken],
        ['state', state],
      ]);

      if (Number.isFinite(expiresIn)) {
        hashParams.append('expires_in', expiresIn.toString());
      }

      ExternalRedirect.redirect(`${redirectUri}#${hashParams}`);
    }
  }, [auth, match, router]);

  return (
    <MainContent>
      <LoadingIndicator />
    </MainContent>
  );
}

export default OAuthAuthorizePage;
