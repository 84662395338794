import Modal, { ModalProps } from '@bfly/ui2/Modal';
import React from 'react';

export interface InjectedProps {
  onHide: () => void;
}

export interface NeedsProps extends InjectedProps {
  show: boolean;
  onExit?: () => void;
  onExited?: () => void;
  onEnter?: () => void;
  onEntered?: () => void;
}

/**
 * This HoC is useful when the underlying modal component state should be
 * discarded when the modal is closed.
 */
export default function withModal<
  C extends React.ComponentType<InjectedProps>,
>(ModalContent: C, modalProps?: ModalProps) {
  function Wrapper({
    onHide,
    show,
    onExit,
    onExited,
    onEnter,
    onEntered,
    ...props
  }: React.ComponentProps<C> & NeedsProps) {
    return (
      <Modal
        show={show}
        onHide={onHide}
        onExit={onExit}
        onExited={onExited}
        onEnter={onEnter}
        onEntered={onEntered}
        {...modalProps}
      >
        <ModalContent {...(props as any)} onHide={onHide} show={show} />
      </Modal>
    );
  }

  Wrapper.displayName = `withModal(${
    ModalContent.displayName || ModalContent.name
  })`;

  return Wrapper;
}
