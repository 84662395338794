/**
 * @generated SignedSource<<fa0b56c2af2f862292ceb0e46606260f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PatientSex = "FEMALE" | "MALE" | "OTHER" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SplitStudyConfirmPatientDataConflictContent_destinationStudy$data = {
  readonly accessionNumber: string | null;
  readonly patient: {
    readonly birthDate: string | null;
    readonly medicalRecordNumber: string | null;
    readonly sex: PatientSex | null;
    readonly " $fragmentSpreads": FragmentRefs<"PatientName_patient">;
  } | null;
  readonly " $fragmentType": "SplitStudyConfirmPatientDataConflictContent_destinationStudy";
};
export type SplitStudyConfirmPatientDataConflictContent_destinationStudy$key = {
  readonly " $data"?: SplitStudyConfirmPatientDataConflictContent_destinationStudy$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitStudyConfirmPatientDataConflictContent_destinationStudy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitStudyConfirmPatientDataConflictContent_destinationStudy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medicalRecordNumber",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PatientName_patient"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "b77a3ab4b525d42482728e8ac6fd30f7";

export default node;
