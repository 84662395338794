/**
 * @generated SignedSource<<8c698f375daca1753fce378a256b8ef7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StudyPracticeType = "HUMAN" | "VETERINARY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StudySubtitle_study$data = {
  readonly accessionNumber: string | null;
  readonly finalizedAt: string | null;
  readonly notes: string | null;
  readonly numQaEntries: number | null;
  readonly practiceType: StudyPracticeType | null;
  readonly vetPatient: {
    readonly " $fragmentSpreads": FragmentRefs<"VetPatientInfo_vetPatient">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PhysicianInfo_study" | "StudyInfo_study">;
  readonly " $fragmentType": "StudySubtitle_study";
};
export type StudySubtitle_study$key = {
  readonly " $data"?: StudySubtitle_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySubtitle_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySubtitle_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numQaEntries",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalizedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VetPatient",
      "kind": "LinkedField",
      "name": "vetPatient",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VetPatientInfo_vetPatient"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyInfo_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PhysicianInfo_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "a84f519bbf78da27540d4d38d95a9840";

export default node;
