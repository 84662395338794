import Button from '@bfly/ui2/Button';
import ButtonToolbar from '@bfly/ui2/ButtonToolbar';
import MainContent from '@bfly/ui2/MainContent';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import { PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import actionMessages from 'messages/actions';

interface FooterActionsProps {
  show?: boolean;
  disabled?: boolean;
  content?: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  confirmLabel: ReactNode;
}

export default function FooterActions({
  show = true,
  disabled = false,
  content,
  children,
  onCancel,
  onConfirm,
  confirmLabel,
}: PropsWithChildren<FooterActionsProps>) {
  return (
    <>
      <div
        css={css`
          padding-bottom: ${show ? '6rem' : '0'};
        `}
      >
        {children}
      </div>
      {show && (
        <div className="fixed bottom-0 left-0 w-screen bg-grey-85 z-10">
          <MainContent className="flex items-center justify-between" size="lg">
            <Text variant="body-bold" data-bni-id="SelectionCounter">
              {content}
            </Text>
            <ButtonToolbar>
              <Button
                className="mr-2"
                variant="secondary"
                onClick={onCancel}
                data-bni-id="CancelButton"
              >
                <FormattedMessage {...actionMessages.cancel} />
              </Button>
              <Button
                onClick={onConfirm}
                variant="primary"
                disabled={disabled}
                data-bni-id="EnrollButton"
              >
                {confirmLabel}
              </Button>
            </ButtonToolbar>
          </MainContent>
        </div>
      )}
    </>
  );
}
