/**
 * @generated SignedSource<<66725525c5a1ddf752383ca854b7e184>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchPagedStudiesListPage_organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PagedStudiesListPage_organization" | "SearchPagedStudiesListPage_studies" | "StudyListContent_organization">;
  readonly " $fragmentType": "SearchPagedStudiesListPage_organization";
};
export type SearchPagedStudiesListPage_organization$key = {
  readonly " $data"?: SearchPagedStudiesListPage_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchPagedStudiesListPage_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchPagedStudiesListPage_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PagedStudiesListPage_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchPagedStudiesListPage_studies"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudyListContent_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ba58223ed46e561643b231e4023683d1";

export default node;
