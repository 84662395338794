import Layout from '@4c/layout';
import DropdownIcon from '@bfly/icons/Dropdown';
import Help from '@bfly/icons/Help';
import SearchIcon from '@bfly/icons/Search';
import BlankSlate from '@bfly/ui2/BlankSlate';
import Link from '@bfly/ui2/Link';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Popover from '@bfly/ui2/Popover';
import Progress from '@bfly/ui2/Progress';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import useQuery from '@bfly/ui2/useQuery';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  defineMessage,
  useIntl,
} from 'react-intl';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';

import DataGrid, { ColumnProp } from 'components/DataGrid';
import {
  type LaunchDarklyClient,
  getVariation,
  useLaunchDarklyClient,
} from 'components/LaunchDarklyContext';
import { useEducationRoutes } from 'routes/education';
import {
  getExamTypeProgressVariant,
  sortByExamType,
} from 'utils/ExamTypeProficiencyUtils';
import { useSimpleQueryState } from 'utils/LocationUtils';
import { useCanAccessEduDashboard } from 'utils/canAccessEduDashboard';

import useProficiencyColumnsUserPreference, {
  ProficiencyColumnUserPreferenceKey,
} from '../hooks/useProficiencyColumnsUserPreference';
import {
  ExpandedHistoricalProficiency,
  ExpandedStudyStatistics,
  expandHistoricalProficiency,
  expandStudyStatistics,
} from '../utils/StudyStatistics';
import { UserCredentialingProgressGridUserEnrollmentsQuery } from './__generated__/UserCredentialingProgressGridUserEnrollmentsQuery.graphql';
import { UserCredentialingProgressGrid_credentialStatisticsByMembers$data as CredentialStatisticsByMembers } from './__generated__/UserCredentialingProgressGrid_credentialStatisticsByMembers.graphql';

interface UserProgressColumnContext {
  ldClient: LaunchDarklyClient;
  setRowExpanded(id: string): void;
  isRowExpanded(id: string): boolean;
  canEnrollUsers: boolean;
}

type UserProgressStatistic = CredentialStatisticsByMembers[0] &
  ExpandedStudyStatistics &
  ExpandedHistoricalProficiency;

type ExamTypeStudyStatistic = NonNullable<
  NonNullable<UserProgressStatistic['studyStatistics']>['examTypeStatistics']
>[0];

type ExamTypeProgressStatistic = NonNullable<
  CredentialStatisticsByMembers[0]['examTypeProgress'][0]
> & {
  id: string;
  studyStatistics:
    | ExamTypeStudyStatistic
    | UserProgressStatistic['studyStatistics'];
} & ExpandedStudyStatistics &
  ExpandedHistoricalProficiency;

type DataItem = UserProgressStatistic | ExamTypeProgressStatistic;

const styles = stylesheet`
  .non-author-row {
    & td {
      @apply min-h-6 border-b-grey-90;
    }
  }
  .author-row + .non-author-row,
  .non-author-row + .author-row {
    & td {
      @apply mt-2;
    }
  }

  .non-author-row + .author-row {
    & td {
      @apply border-t border-grey-85;
    }
  }

  th .header {
    @apply py-2 whitespace-normal items-center flex flex-1 w-[10rem];
  }
`;

const HeaderLabel = ({ children }: { children: ReactNode }) => (
  <span className={styles.header}>{children}</span>
);

function AcademyProgressBarTooltip({
  userId,
  numModulesRequired,
}: {
  userId: string;
  numModulesRequired: number;
}) {
  const { data } = useQuery<UserCredentialingProgressGridUserEnrollmentsQuery>(
    graphql`
      query UserCredentialingProgressGridUserEnrollmentsQuery(
        $userId: String!
      ) {
        lms {
          enrollmentConnection(userId: [$userId], first: 500) {
            edges {
              node {
                courseCompletedAt
              }
            }
          }
        }
      }
    `,
    { fetchPolicy: 'store-or-network', variables: { userId } },
  );

  if (!data) {
    return <LoadingIndicator size="sm" />;
  }

  return (
    <div>
      <FormattedMessage
        tagName="div"
        id="userCredentialingProgressGrid.butterflyAcademyProgressTooltip"
        defaultMessage="{numModulesCompleted} of {numModulesRequired}"
        values={{
          numModulesCompleted: data.lms?.enrollmentConnection?.edges?.reduce(
            (completed, course) =>
              completed + (course!.node!.courseCompletedAt !== null ? 1 : 0),
            0,
          ),
          numModulesRequired,
        }}
      />
      <Text color="body" className="font-normal">
        <FormattedMessage
          id="userCredentialingProgressGrid.modulesComplete"
          defaultMessage="Modules complete"
        />
      </Text>
    </div>
  );
}

function AcademyProgressBar({
  item,
  canEnrollUsers,
}: {
  item: UserProgressStatistic;
  canEnrollUsers: boolean;
}) {
  // TODO: Investigate the following:
  //   1. lazy loading the data for this component
  //   2. moving the needed data from lmsEnrollmentConnection to the lmsUserStatistics node
  // See ticket https://butterflynetwork.atlassian.net/browse/SHARK-1393
  const intl = useIntl();
  const eduRoutes = useEducationRoutes();

  const isEnrolledInCourses = (item.lmsUserStatistics?.count || 0) > 0;

  if (!isEnrolledInCourses) {
    if (canEnrollUsers) {
      return (
        <div className="academy-progress">
          <Link
            to={{
              pathname: eduRoutes.dashboardSelectCourses(),
              state: {
                user: {
                  id: item.userProfile!.id,
                  name: item.userProfile!.name,
                },
              },
            }}
          >
            <FormattedMessage
              id="userCredentialingProgressGrid.enroll"
              defaultMessage="Enroll"
            />
          </Link>
        </div>
      );
    }

    return (
      <div className="academy-progress">
        <FormattedMessage
          id="userCredentialingProgressGrid.notEnrolled"
          defaultMessage="Not enrolled"
        />
      </div>
    );
  }

  const tooltip = (
    <AcademyProgressBarTooltip
      userId={item.userProfile?.handle || ''}
      numModulesRequired={item.lmsUserStatistics?.count || 0}
    />
  );

  let progress: JSX.Element | null = null;

  if (Math.floor(item.lmsUserStatistics.averageProgress ?? 0) === 100) {
    progress = (
      <Tooltip.Trigger tooltip={tooltip} placement="right">
        <div>
          <FormattedMessage
            id="UserCredentialingProgressGrid.butterflyAcademyProgressCompletedOn"
            defaultMessage="Completed on {completedOn}"
            values={{
              completedOn: (
                <FormattedDate
                  value={item.lmsUserStatistics.lastCompletedAt!}
                  dateStyle="short"
                />
              ),
            }}
          />
        </div>
      </Tooltip.Trigger>
    );
  } else {
    progress = (
      <>
        <Tooltip.Trigger tooltip={tooltip} placement="right">
          <div className="mr-2">
            <Progress
              value={item.lmsUserStatistics.averageProgress!}
              maxValue={100}
              variant="white"
              className="w-64 min-w-64"
              title={intl.formatMessage(
                defineMessage({
                  id: 'UserCredentialingProgressGrid.butterflyAcademyProgressTitle',
                  defaultMessage: 'Butterfly Academy progress',
                }),
              )}
            />
          </div>
        </Tooltip.Trigger>
        <FormattedNumber
          value={item.lmsUserStatistics.averageProgress! / 100}
          style="percent"
        />
      </>
    );
  }

  return (
    <Layout flex="2 1" align="center" className="academy-progress">
      {progress}
    </Layout>
  );
}

interface ProgressBarForUserProps {
  item: DataItem;
  className?: string;
  onClick: () => void;
  tooltip: ReactElement;
}

function ProgressBarForUser({
  item,
  className,
  onClick,
  tooltip,
}: ProgressBarForUserProps) {
  const intl = useIntl();
  const isExamType =
    item.type === 'CredentialStatisticsByMemberExamTypeProgress';

  const progressBar = (
    <Layout flex="2 1" align="center" onClick={onClick} className={className}>
      <Tooltip.Trigger tooltip={tooltip} placement="right">
        <div className="mr-2 w-64 min-w-64">
          {isExamType ? (
            <Progress
              value={Math.min(item.numExamsCompleted!, item.numExamsRequired!)}
              maxValue={item.numExamsRequired!}
              height="sm"
              variant="white"
              title={intl.formatMessage(
                defineMessage({
                  id: 'UserCredentialingProgressGrid.progressTitle',
                  defaultMessage: 'Proficiency progress',
                }),
              )}
            />
          ) : (
            // @ts-expect-error types are wrong value is not required
            <Progress data-bni-id="UserProgressBar">
              {[...item.examTypeProgress]
                .filter((p) => !!p?.numExamsCompleted)
                .sort((a, b) => sortByExamType(a!.examType, b!.examType))
                .map((p) => (
                  <Progress.Bar
                    key={p!.examType!.id}
                    variant={getExamTypeProgressVariant(p!.examType!.systemId)}
                    value={Math.min(
                      p!.numExamsCompleted!,
                      p!.numExamsRequired!,
                    )}
                    maxValue={item!.numExamsRequired!}
                  />
                ))}
            </Progress>
          )}
        </div>
      </Tooltip.Trigger>
      <Text className="tabular-nums min-w-10 w-10 text-right">
        {isExamType ? (
          <FormattedNumber
            value={
              Math.min(item.numExamsCompleted!, item.numExamsRequired!) /
              item.numExamsRequired!
            }
            style="percent"
          />
        ) : (
          <FormattedNumber
            value={
              [...item.examTypeProgress].reduce(
                (sum, progress) =>
                  sum +
                  Math.min(
                    progress!.numExamsCompleted ?? 0,
                    progress!.numExamsRequired ?? 1,
                  ),
                0,
              ) / item.numExamsRequired!
            }
            style="percent"
          />
        )}
      </Text>
    </Layout>
  );

  return progressBar;
}

type ProgressColumnProp = ColumnProp<UserProgressColumnContext, DataItem> & {
  columns?: ColumnProp<UserProgressColumnContext, DataItem>[];
};

const columns: ProgressColumnProp[] = [
  {
    key: 'name',
    width: '40rem',
    frozen: true,
    picker: { show: false },
    sortable: [
      {
        key: 'USER_SORT_ASC',
        label: (
          <FormattedMessage
            id="userCredentialingProgressGrid.sortNameAsc"
            defaultMessage="Sort A-Z"
          />
        ),
      },
      {
        key: 'USER_SORT_DESC',
        label: (
          <FormattedMessage
            id="userCredentialingProgressGrid.sortNameDesc"
            defaultMessage="Sort Z-A"
          />
        ),
      },
    ],
    label: (
      <Text className="ml-12 pl-2">
        <FormattedMessage
          id="UserCredentialingProgressGrid.name"
          defaultMessage="Name"
        />
      </Text>
    ),
    renderCell({
      cellProps,
      item,
      context: { setRowExpanded, isRowExpanded },
    }) {
      if (item.type === 'CredentialStatisticsByMemberExamTypeProgress') {
        return (
          <DataGrid.Cell {...cellProps} className="pl-16">
            <Text truncate>{item.examType?.name}</Text>
          </DataGrid.Cell>
        );
      }

      const { id } = item;
      const expanded = isRowExpanded(id);

      return (
        <DataGrid.Cell
          {...cellProps}
          aria-controls={id}
          aria-expanded={expanded}
          onClick={() => setRowExpanded(id)}
        >
          <span className="w-8 p-0 ml-6 text-headline">
            <DropdownIcon className={expanded ? '-rotate-180' : undefined} />
          </span>
          <Text truncate>{item.userProfile?.name}</Text>
        </DataGrid.Cell>
      );
    },
  },
  {
    key: 'credentialingRequirements',
    label: (
      <FormattedMessage
        id="userCredentialingProgressGrid.requirements"
        defaultMessage="Proficiency requirements"
      />
    ),
    columns: [
      {
        key: 'examTypesCredentialed',
        width: 'auto',
        label: (
          <FormattedMessage
            id="userCredentialingProgressGrid.examTypesCredentialed"
            defaultMessage="Exam types progress"
          />
        ),
        sortable: [
          {
            key: 'TOTAL_PROGRESS_PERCENT_ASC',
            label: (
              <FormattedMessage
                id="userCredentialingProgressGrid.sortProgressAsc"
                defaultMessage="Sort least to most progress"
              />
            ),
          },
          {
            key: 'TOTAL_PROGRESS_PERCENT_DESC',
            label: (
              <FormattedMessage
                id="userCredentialingProgressGrid.sortProgressDesc"
                defaultMessage="Sort most to least progress"
              />
            ),
          },
        ],
        render: (item, { setRowExpanded }) => {
          let cappedNumExamsComplete = 0;
          if (item.type === 'CredentialStatisticsByMember') {
            for (const progress of item.examTypeProgress) {
              if (progress) {
                cappedNumExamsComplete += Math.min(
                  progress.numExamsCompleted || 0,
                  progress.numExamsRequired || 0,
                );
              }
            }
          } else {
            cappedNumExamsComplete = Math.min(
              item.numExamsCompleted || 0,
              item.numExamsRequired || 0,
            );
          }

          return (
            <ProgressBarForUser
              item={item}
              className="cursor-pointer"
              onClick={() => setRowExpanded(item.id)}
              tooltip={
                item.type === 'CredentialStatisticsByMember' ||
                item.credentialGroupProgress!.length === 1 ? (
                  <div data-bni-id="UserCredentialingProgressTooltip">
                    <FormattedMessage
                      tagName="div"
                      id="userCredentialingProgressGrid.userProgressTooltip"
                      defaultMessage="{numExamsCompleted} of {numExamsRequired}"
                      values={{
                        numExamsCompleted: cappedNumExamsComplete,
                        numExamsRequired: item.numExamsRequired,
                      }}
                    />
                    <Text color="body" className="font-normal">
                      <FormattedMessage
                        id="userCredentialingProgressGrid.examsComplete"
                        defaultMessage="Exams complete"
                      />
                    </Text>
                  </div>
                ) : (
                  <div>
                    {item.credentialGroupProgress!.map((egp) => (
                      <div key={egp!.credentialGroup!.name}>
                        <FormattedMessage
                          tagName="div"
                          id="userCredentialingProgressGrid.userProgressTooltip"
                          defaultMessage="{numExamsCompleted} of {numExamsRequired}"
                          values={{
                            numExamsCompleted: Math.min(
                              egp!.numExamsCompleted || 0,
                              egp!.numExamsRequired || 0,
                            ),
                            numExamsRequired: egp!.numExamsRequired,
                          }}
                        />
                        <Text color="body" className="font-normal">
                          {egp!.credentialGroup?.name}
                        </Text>
                      </div>
                    ))}
                  </div>
                )
              }
            />
          );
        },
      },
      {
        key: 'butterflyAcademyProgress',
        width: 'auto',
        disabled: ({ ldClient }) =>
          getVariation(ldClient, 'butterfly-academy-access') !== 'enabled',
        label: (
          <FormattedMessage
            id="userCredentialingProgressGrid.butterflyAcademyProgress"
            defaultMessage="Butterfly Academy progress"
          />
        ),
        render: (item, { canEnrollUsers }) => {
          if (item.type === 'CredentialStatisticsByMember') {
            return (
              <AcademyProgressBar
                item={item}
                canEnrollUsers={canEnrollUsers}
              />
            );
          }
          return null;
        },
      },
    ],
  },

  {
    key: 'allTimeStats',
    label: (
      <FormattedMessage
        id="userCredentialingProgressGrid.allTimeStats"
        defaultMessage="All time stats"
      />
    ),
    columns: [
      {
        key: 'examTypeTagCounts',
        label: (
          <HeaderLabel>
            <div className="flex-1">
              <FormattedMessage
                id="userCredentialingProgressGrid.numExamTypeAssociations"
                defaultMessage="Exam type tag counts"
              />
            </div>
            <Tooltip.Trigger
              tooltip={
                <FormattedMessage
                  id="userCredentialingProgressGrid.examTypeTagsCountDesc"
                  defaultMessage="Total number of exam type tags associated with a user’s exams"
                />
              }
            >
              <Help className="w-4 h-4 shrink-0" />
            </Tooltip.Trigger>
          </HeaderLabel>
        ),

        picker: {
          show: true,
          label: (
            <FormattedMessage
              id="userCredentialingProgressGrid.numExamTypeAssociations2"
              defaultMessage="Exam type tag counts"
            />
          ),
        },
        render: (item) => {
          switch (item.type) {
            case 'CredentialStatisticsByMember':
              return (
                <span>
                  {item.studyStatistics?.numExamTypeAssociations ?? 0}
                </span>
              );
            case 'CredentialStatisticsByMemberExamTypeProgress':
              return <span>{item.studyExamTypeTotal ?? 0}</span>;
            default:
              return null;
          }
        },
      },
      {
        key: 'numCountsTowardsCredentialing',
        label: (
          <HeaderLabel>
            <FormattedMessage
              id="userCredentialingProgressGrid.numCountsTowardsCredentialing"
              defaultMessage="Counts towards proficiency"
            />
          </HeaderLabel>
        ),
        render: (item, { ldClient }) => {
          const butterflyCount =
            item.type === 'CredentialStatisticsByMember'
              ? item.numCountsTowardCredentialingButterfly
              : item.studyStatistics?.numCountsTowardsCredentialing;
          if (!getVariation(ldClient, 'historical-proficiency')) {
            return <span>{butterflyCount}</span>;
          }
          const count =
            item.type === 'CredentialStatisticsByMember'
              ? item.numCountsTowardCredentialingWithHistorical
              : (item.studyStatistics?.numCountsTowardsCredentialing || 0) +
                item.numCountsTowardCredentialingHistorical;
          const historicalCount = item.numCountsTowardCredentialingHistorical;
          return (
            <Popover.Trigger
              trigger="passive"
              placement="top"
              variant="dark"
              popover={
                <span>
                  <FormattedMessage
                    id="userCredentialingProgressGrid.numCountsTowardCredentialingPopover"
                    defaultMessage="Includes {historicalCount} count from outside Butterfly"
                    values={{ historicalCount }}
                  />
                </span>
              }
            >
              <span>{count}</span>
            </Popover.Trigger>
          );
        },
      },
      {
        key: 'numCountsTowardsCredentialingHistorical',
        label: (
          <HeaderLabel>
            <FormattedMessage
              id="userCredentialingProgressGrid.numCountsTowardCredentialingHistorical"
              defaultMessage="Counts towards proficiency: manually entered data"
            />
          </HeaderLabel>
        ),
        disabled: ({ ldClient }) =>
          !getVariation(ldClient, 'historical-proficiency'),
        render: (item) => {
          return <span>{item.numCountsTowardCredentialingHistorical}</span>;
        },
      },
      {
        key: 'studyStatusStatisticsTotal',
        label: (
          <FormattedMessage
            id="userCredentialingProgressGrid.studyStatusStatisticsTotal"
            defaultMessage="Total exams"
          />
        ),
        render: (item) => {
          return item.type === 'CredentialStatisticsByMember' ? (
            <span>{item.studyStatusStatisticsTotal ?? 0}</span>
          ) : null;
        },
      },
      {
        key: 'totalFinalizedExams',
        label: (
          <FormattedMessage
            id="userCredentialingProgressGrid.totalFinalizedExams"
            defaultMessage="Total finalized exams"
          />
        ),
        render: (item) => {
          return <span>{item.studyStatistics?.numFinalizedStudies ?? 0}</span>;
        },
      },

      {
        key: 'studyStatusStatisticsDrafts',
        label: (
          <FormattedMessage
            id="userCredentialingProgressGrid.studyStatusStatisticsDrafts"
            defaultMessage="Total drafts"
          />
        ),
        render: (item) => {
          return <span>{item.studyStatusStatisticsDrafts ?? 0}</span>;
        },
      },
      {
        key: 'studyStatusStatisticsPendingAttestation',
        label: (
          <FormattedMessage
            id="userCredentialingProgressGrid.studyStatusStatisticsPendingAttestation"
            defaultMessage="Total pending attestation"
          />
        ),
        render: (item) => {
          return (
            <span>{item.studyStatusStatisticsPendingAttestation ?? 0}</span>
          );
        },
      },
      {
        key: 'totalQAedExams',
        label: (
          <FormattedMessage
            id="userCredentialingProgressGrid.totalQAedExams"
            defaultMessage="Total QA'ed exams"
          />
        ),
        render: (item) => {
          return <span>{item.studyStatistics?.numReviewedStudies ?? 0}</span>;
        },
      },
      {
        key: 'totalNeedsQa',
        label: (
          <HeaderLabel>
            <FormattedMessage
              id="userCredentialingProgressGrid.totalNeedsQA"
              defaultMessage="Total exams needing QA"
            />
          </HeaderLabel>
        ),
        render: (item) => {
          return <span>{item.studyStatusStatisticsNeedsReview}</span>;
        },
      },
    ],
  },
];

function RowComponent({
  item,
  children,
  ...props
}: {
  item: DataItem;
  children: ReactNode;
}) {
  return (
    <DataGrid.Row
      aria-level={item.type === 'CredentialStatisticsByMember' ? 1 : 2}
      className={clsx(
        item.type === 'CredentialStatisticsByMember'
          ? styles.authorRow
          : styles.nonAuthorRow,
      )}
      {...props}
    >
      {children}
    </DataGrid.Row>
  );
}

interface Props {
  credentialStatisticsByMembers: CredentialStatisticsByMembers;
}

function UserCredentialingProgressGrid({
  credentialStatisticsByMembers,
}: Props) {
  const ldClient = useLaunchDarklyClient();
  const canEnrollUsers = useCanAccessEduDashboard();
  const columnsPreferenceKey =
    ProficiencyColumnUserPreferenceKey.PROFICIENCY_DASHBOARD_LIST;
  const [expandedRows, setRowsExpanded] = useState(() => new Set<string>());
  const [sort, setSort] = useSimpleQueryState('sort');

  const columnContext: UserProgressColumnContext = useMemo(
    () => ({
      ldClient,
      canEnrollUsers,
      setRowExpanded(id: string) {
        expandedRows[!expandedRows.has(id) ? 'add' : 'delete'](id);
        setRowsExpanded(new Set(expandedRows));
      },
      isRowExpanded: (id: string) => expandedRows.has(id),
    }),
    [expandedRows, ldClient, canEnrollUsers],
  );

  const [userColumns, setUserColumns] = useProficiencyColumnsUserPreference(
    columnsPreferenceKey,
  );

  const [columnOrder, columnVisibility] = useMemo(() => {
    const order =
      (userColumns[columnsPreferenceKey]?.[0] as string[]) || undefined;

    let visibility =
      (userColumns[columnsPreferenceKey]?.[1] as Record<string, boolean>) ||
      undefined;
    if (
      visibility &&
      !('numCountsTowardCredentialingHistorical' in visibility)
    ) {
      visibility = {
        ...visibility,
        numCountsTowardCredentialingHistorical: false,
      };
    }
    return [order, visibility] as const;
  }, [userColumns, columnsPreferenceKey]);

  function handleColumnChange(
    nextOrder: string[],
    nextVisibility: Record<string, boolean>,
  ) {
    const nextColumns = [nextOrder, nextVisibility];
    setUserColumns({
      ...userColumns,
      [columnsPreferenceKey]: nextColumns,
    });
  }

  const flatData = useMemo(() => {
    const data = [] as DataItem[];

    let memberCounter = 0;
    for (const member of credentialStatisticsByMembers) {
      const memberId = `${member.id}/${++memberCounter}`;
      data.push({
        ...member,
        ...expandStudyStatistics(member.studyStatistics || {}),
        ...expandHistoricalProficiency(member.studyStatistics || {}),
        id: memberId,
      });

      if (expandedRows.has(memberId)) {
        for (const examTypeStat of member.examTypeProgress) {
          const examTypeId = examTypeStat!.examType!.id;
          const examTypeName = examTypeStat!.examType!.name;
          const studyStatistics =
            member.studyStatistics?.examTypeStatistics?.find(
              (es) => es!.examType?.id === examTypeId,
            ) ?? null;

          data.push({
            id: `${memberId}:${examTypeId}`,
            ...examTypeStat!,
            studyStatistics,
            ...expandStudyStatistics(studyStatistics || {}),
            ...expandHistoricalProficiency(
              member.studyStatistics || {},
              examTypeName,
            ),
          });
        }
      }
    }
    return data;
  }, [expandedRows, credentialStatisticsByMembers]);

  if (!flatData.length) {
    return (
      <BlankSlate>
        <BlankSlate.Img as={SearchIcon} height="36" width="36" />
        <BlankSlate.Title>
          <FormattedMessage
            id="UserCredentialingProgressGrid.noResults"
            defaultMessage="No Results Found"
          />
        </BlankSlate.Title>
      </BlankSlate>
    );
  }

  return (
    <DataGrid
      data={flatData}
      columns={columns}
      columnContext={columnContext}
      columnVisibility={columnVisibility}
      columnOrder={columnOrder}
      onColumnVisibilityChange={(nextVisiblity) =>
        handleColumnChange(columnOrder, nextVisiblity)
      }
      onColumnOrderChange={(nextOrder) => {
        handleColumnChange(nextOrder, columnVisibility);
      }}
      sort={sort}
      onSort={setSort}
      viewportScrolling
      data-bni-id="UserCredentialingProgressGrid"
      rowComponent={RowComponent}
      scrollKey="credential-stats"
      className="w-full min-h-96"
    />
  );
}

export default createFragmentContainer(UserCredentialingProgressGrid, {
  credentialStatisticsByMembers: graphql`
    fragment UserCredentialingProgressGrid_credentialStatisticsByMembers on CredentialStatisticsByMember
    @relay(plural: true)
    @argumentDefinitions(
      studyStatsFilter: { type: "StudyStatisticsFilterInput" }
    ) {
      type: __typename
      id
      userProfile {
        name
        handle
        id
      }
      numExamsRequired
      numExamsCompleted
      studyStatistics(filter: $studyStatsFilter) {
        numCountsTowardsCredentialing
        numExamTypeAssociations
        numFinalizedStudies
        numReviewedStudies
        userHistoricalProficiency {
          examTypeCounts {
            examType {
              name
            }
            count
          }
        }
        studyStatusStatistics {
          key
          numStudies
        }
        examTypeStatistics {
          examType {
            id
          }
          numFinalizedStudies
          numReviewedStudies
          numCountsTowardsCredentialing
          studyStatusStatistics {
            key
            numStudies
          }
        }
      }
      examTypeProgress @required(action: THROW) {
        type: __typename
        examType {
          id
          systemId
          name @required(action: THROW)
        }
        numExamsRequired
        numExamsCompleted
        credentialGroupProgress {
          credentialGroup {
            name
          }
          numExamsRequired
          numExamsCompleted
        }
      }
      lmsUserStatistics @required(action: THROW) {
        type: __typename
        averageProgress
        lastCompletedAt
        count
      }
    }
  `,
});
