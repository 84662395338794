/* eslint-disable relay/must-colocate-fragment-spreads */

import useLocation from 'found/useLocation';
import { createContext, useContext, useMemo } from 'react';
import { ReactRelayContext } from 'react-relay';
import type { Variables } from 'react-relay';

export type StudyListNavKind =
  | 'ARCHIVE'
  | 'SEARCH'
  | 'DOMAIN_SEARCH'
  | 'GLOBAL_SEARCH'
  | 'DELETED';

export interface StudyListNavConfig {
  kind: StudyListNavKind;
  variables: Variables;
  backIndex: number;
}

const Context = createContext<StudyListNavConfig | null>(null);

export function useStudyListNavConfig() {
  return useContext(Context);
}

export function StudyListNavProvider({
  kind,
  backIndex: backIndexProp,
  children,
}: {
  kind: StudyListNavKind;
  backIndex?: number;
  children?: React.ReactNode;
}) {
  const { variables } = useContext(ReactRelayContext) as any;
  const { index } = useLocation();
  const backIndex = backIndexProp ?? index;

  const config = useMemo(
    () => ({
      variables,
      backIndex,
      kind,
    }),
    [backIndex, kind, variables],
  );

  return <Context.Provider value={config}>{children}</Context.Provider>;
}
