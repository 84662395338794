/**
 * @generated SignedSource<<35e29a139d4fe7cea79f9a4e5d66fe25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type baseStudyInfo_study$data = {
  readonly notes: string | null;
  readonly studyDescription: string | null;
  readonly " $fragmentType": "baseStudyInfo_study";
};
export type baseStudyInfo_study$key = {
  readonly " $data"?: baseStudyInfo_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"baseStudyInfo_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "baseStudyInfo_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyDescription",
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "b70d1706af3f7d237d8679d38ed632fd";

export default node;
