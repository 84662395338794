export function isValidDate(value: unknown): value is Date {
  return !!value && value instanceof Date && Number.isFinite(+value);
}

/** Converts a DateTime to an ISO date string. The Date object is not converted to UTC first. Useful for cases like birth dates where the timezone is not relevant */
export function toIsoDateString(value: Date | null | undefined) {
  if (!isValidDate(value)) return value;

  const month = String(value.getMonth() + 1).padStart(2, '0');
  const date = String(value.getDate()).padStart(2, '0');

  return `${value.getFullYear()}-${month}-${date}`;
}
