import { Params, join, root } from '@4c/spa-routing';
import { useRouter } from 'found';
import { useMemo } from 'react';

import partialRoutes from 'utils/partialRoutes';

const organizationRoot = join(root, ':organizationSlug');

const exams = join(organizationRoot, 'exams');
const examListRoot = join(exams, 'list');
const examLists = join(examListRoot, ':status');

const search = join(root, ':slug/search');

const exam = join(exams, ':studyHandle');
const examGallery = join(exam, 'gallery');
const examImage = join(exam, ':studyImageHandle');

const measurements = join(examImage, 'measurements');

export const routes = {
  search,
  exams,
  examLists,
  exam,
  examGallery,
  examImage,
  measurements,
};

export function useExamRoutes(newOrgSlug?: string) {
  const { match } = useRouter();
  // If params are passed use them, otherwise use existing ones
  const organizationSlug = newOrgSlug || match.params.organizationSlug;

  return useMemo(() => {
    const params: Params<'organizationSlug'> = {
      organizationSlug,
    };
    return partialRoutes(routes, params);
  }, [organizationSlug]);
}
