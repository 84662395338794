import { join, route } from '@4c/spa-routing';

const scanlabRoot = route('/-/scanlab');
const portfolio = join(scanlabRoot, 'portfolio');
const review = join(scanlabRoot, 'review');
const exam = join(scanlabRoot, 'exams/:studyHandle');
const examImage = join(exam, ':studyImageHandle');
const examGallery = join(exam, 'gallery');

export const routes = {
  portfolio,
  review,
  exam,
  examGallery,
  examImage,
};
