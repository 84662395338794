import { Location, LocationDescriptorObject } from 'farce';
import useRouter from 'found/useRouter';
import { useEffect } from 'react';

import sessionStore from 'utils/sessionStore';

const DestinationLocationToStudyHandle = new WeakMap<
  Location | LocationDescriptorObject,
  string
>();

export function useLastViewedStudy(viewerProfileId: string) {
  const { match } = useRouter();

  const matchStudyHandle = DestinationLocationToStudyHandle.get(
    match.location,
  );

  if (!matchStudyHandle) {
    const lastViewedExamKey = `${viewerProfileId}:lastViewedStudy`;
    return sessionStore.get(lastViewedExamKey);
  }

  return matchStudyHandle;
}

export function useSetLastViewedStudy(
  studyHandle: string,
  viewerProfileId: string,
) {
  const { router } = useRouter();

  useEffect(() => {
    return router.addNavigationListener(
      (nextLocation) => {
        if (nextLocation) {
          DestinationLocationToStudyHandle.set(nextLocation, studyHandle);

          const lastViewedExamKey = `${viewerProfileId}:lastViewedStudy`;
          sessionStore.set(lastViewedExamKey, studyHandle);
        }
        return null;
      },
      { beforeUnload: false },
    );
  }, [router, studyHandle, viewerProfileId]);
}
