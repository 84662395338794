/**
 * @generated SignedSource<<291bbe2d1684483bc27b7e98634592e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorklistItemDicomDocument_worklistItem$data = {
  readonly admittedAt?: string | null;
  readonly studyDescription: string | null;
  readonly typename: string;
  readonly visitLocation?: string | null;
  readonly visitNumber?: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ModalityWorklistItemPhysicianInformation_worklistItem">;
  readonly " $fragmentType": "WorklistItemDicomDocument_worklistItem";
};
export type WorklistItemDicomDocument_worklistItem$key = {
  readonly " $data"?: WorklistItemDicomDocument_worklistItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorklistItemDicomDocument_worklistItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorklistItemDicomDocument_worklistItem",
  "selections": [
    {
      "alias": "typename",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyDescription",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ModalityWorklistItemPhysicianInformation_worklistItem"
        }
      ],
      "type": "ModalityWorklistItem",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visitNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visitLocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "admittedAt",
          "storageKey": null
        }
      ],
      "type": "EncounterBasedWorklistItem",
      "abstractKey": null
    }
  ],
  "type": "WorklistItemInterface",
  "abstractKey": "__isWorklistItemInterface"
};

(node as any).hash = "691090e6a963c396673664d4841fc7bf";

export default node;
