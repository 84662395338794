import { useFormValues } from 'react-formal';
import { SchemaOf, array, boolean, mixed, object, string } from 'yup';

import { StudyDestinationType } from 'utils/examEnums';

import {
  HumanPatientFields,
  humanPatientSchema,
} from '../../schemas/humanStudyInfo';
import {
  VetPatientFields,
  vetPatientSchema,
} from '../../schemas/vetStudyInfo';

export interface SplitStudyFields {
  allowConflictingDemographics: boolean;
  destinationType: StudyDestinationType;
  destinationArchive: { id: string | undefined } | undefined;
  destinationStudyId: string | null;
  examTypeIds: string[];
  imageIds: string[];
  patient: HumanPatientFields | undefined;
  vetPatient: VetPatientFields | undefined;
  tagIds: string[];
  worksheets: { id: string }[];
  createdBy: string | undefined;
  secondaryAuthorUserIds: string[];
  transcribedBy: string | undefined;
  accessionNumber: string | undefined;
  sourceWorklistItemId: string | undefined;
  archiveId: string | undefined;
  dicomDocument: any;
}

export const useSplitStudyFormValue = <T extends keyof SplitStudyFields>(
  fieldName: T,
) => useFormValues(fieldName) as SplitStudyFields[T];

const optionalId = () =>
  string()
    .default(() => undefined)
    .transform((v, o) => (o === '' ? undefined : v));

export const splitStudySchema: SchemaOf<SplitStudyFields> = object({
  allowConflictingDemographics: boolean()
    .required()
    .default(false)
    .when('destinationType', {
      is: 'EXISTING',
      then: (f) => f.required(),
    }),
  destinationType: string()
    .enum(Object.values(StudyDestinationType))
    .required()
    .default(StudyDestinationType.NEW),
  destinationArchive: object({
    id: string().required(),
  }).default(() => undefined),
  destinationStudyId: string()
    .nullable()
    .default(() => null)
    .when('destinationType', {
      is: 'EXISTING',
      then: (f) => f.required(),
    }),
  imageIds: array()
    .of(string().required())
    .default(() => [])
    .when('destinationType', {
      is: 'NEW',
      then: (f) => f.min(1),
    }),
  examTypeIds: array()
    .of(string().required())
    .default(() => []),
  tagIds: array()
    .of(string().required())
    .default(() => []),
  patient: humanPatientSchema.default(() => undefined),
  vetPatient: vetPatientSchema.default(() => undefined),
  worksheets: array()
    .of(
      object({
        id: string().required(),
      }),
    )
    .default(() => []),
  createdBy: optionalId(),
  transcribedBy: optionalId(),
  secondaryAuthorUserIds: array()
    .of(string().required())
    .default(() => []),
  archiveId: optionalId(),
  accessionNumber: optionalId(),
  sourceWorklistItemId: optionalId(),
  dicomDocument: mixed().nullable().default({}),
});

export type SplitStudySchemaType = typeof splitStudySchema;
