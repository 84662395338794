import DateRangePicker, {
  DateRangePickerProps,
  DateRangePickerValue,
} from '@bfly/ui2/DateRangePicker';
import DropdownForm, { DropdownFormProps } from '@bfly/ui2/DropdownForm';
import Form from '@bfly/ui2/Form';
import { useMemo, useState } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { object } from 'yup';

import dateRangePickerMessages from 'messages/dateRangePicker';
import { dateRangeWithValidation } from 'schemas/dateRange';
import { isValidDateRangeValue } from 'utils/dateRangePickerHelpers';

export { type DateRangePickerValue };

export type NormalizedDateRangeValue = {
  startDate: Date | null;
  endDate: Date | null;
};

const messages = defineMessages({
  validRange: {
    id: 'DateRangeDropdownForm.validRange',
    defaultMessage: 'End date must be after the start date',
  },
  placeholder: {
    id: 'DateRangeDropdownForm.placeholder',
    defaultMessage: 'Date range',
  },
});

interface Props
  extends Omit<
      DateRangePickerProps,
      'value' | 'onChange' | 'meta' | 'invalid'
    >,
    Omit<
      DropdownFormProps<any, any>,
      'label' | 'children' | 'value' | 'onChange'
    > {
  name?: string;
  value?: DateRangePickerValue | null;
  placeholder?: MessageDescriptor;
  onChange?: (nextDateRange: DateRangePickerValue | null) => void;
}

const dateValueSchema = object({
  value: dateRangeWithValidation.nullable().default(null).notRequired(),
}).nullable();

function DateRangeSelectButton({
  value,
  placeholder = messages.placeholder,
  onChange,
  schema = dateValueSchema,
  ...props
}: Props) {
  const [showEndDate, setShowEndDate] = useState(
    props.showEndDateDefault ?? false,
  );
  const formValue = useMemo(() => (value ? { value } : null), [value]);

  return (
    <DropdownForm<
      typeof dateValueSchema,
      { value: DateRangePickerValue } | null
    >
      {...props}
      schema={schema}
      onChange={(nextFormValue) => {
        const isInvalidRange = !isValidDateRangeValue(nextFormValue?.value);

        if (!nextFormValue?.value || isInvalidRange) {
          onChange?.(null);
          return;
        }
        // When we submit, a user may have selected an end date and then hidden it.
        // we want to leave the date in the input as a convenience if they change their mind immediately.
        // However we don't want to use this date if they ultimately submit a value with the input hidden
        // so we set it to the start date matching the onChange logic
        let nextDateRange = nextFormValue.value;
        if (!showEndDate && !DateRangePicker.isValidDayValue(nextDateRange)) {
          nextDateRange = {
            ...nextDateRange,
            endDate: nextDateRange.startDate
              ? new Date(nextDateRange.startDate!)
              : null,
          };
        }

        onChange?.(nextDateRange);
      }}
      value={formValue}
      label={
        <DateRangePicker.FormattedValue
          value={value}
          placeholder={placeholder}
        />
      }
    >
      <div className="p-4">
        <Form.Field name="value">
          {(dateRangeProps) => {
            return (
              <>
                <DateRangePicker
                  {...props}
                  {...dateRangeProps}
                  showRangeMessage={dateRangePickerMessages.customRangeMessage}
                  customOptionMessage={
                    dateRangePickerMessages.customOptionMessage
                  }
                  datePlaceholder={
                    dateRangePickerMessages.customDatePlaceholder
                  }
                  hideRangeMessage={
                    dateRangePickerMessages.customHideRangeMessage
                  }
                  showEndDate={showEndDate}
                  onToggleEndDate={setShowEndDate}
                  className="w-72"
                  onChange={(nextValue) => {
                    if (
                      !DateRangePicker.isValidDayValue(nextValue) &&
                      (!nextValue.endDate || !showEndDate)
                    ) {
                      nextValue.endDate = nextValue.startDate
                        ? new Date(nextValue.startDate)
                        : null;
                    }

                    dateRangeProps.onChange(nextValue);
                  }}
                />
                <Form.Message for="value" />
                <DropdownForm.Footer />
              </>
            );
          }}
        </Form.Field>
      </div>
    </DropdownForm>
  );
}

export default DateRangeSelectButton;
