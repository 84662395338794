/**
 * @generated SignedSource<<019595bee783269992e084f5e464e0b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyStatistics_studyAuthor$data = {
  readonly accuracyAsPresentedStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numFinalizedStudies: number;
  } | null> | null;
  readonly accuracyComparedToGoldStandardStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numFinalizedStudies: number;
  } | null> | null;
  readonly averageImageQuality: number | null;
  readonly feedbackStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numFinalizedStudies: number;
  } | null> | null;
  readonly numExamTypeAssociations: number | null;
  readonly numFinalizedStudies: number | null;
  readonly numReviewedStudies: number | null;
  readonly studyStatusStatistics: ReadonlyArray<{
    readonly key: string;
    readonly numStudies: number;
  } | null> | null;
  readonly " $fragmentType": "StudyStatistics_studyAuthor";
};
export type StudyStatistics_studyAuthor$key = {
  readonly " $data"?: StudyStatistics_studyAuthor$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyStatistics_studyAuthor">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numFinalizedStudies",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyStatistics_studyAuthor",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numReviewedStudies",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numExamTypeAssociations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageImageQuality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyStatusStatistics",
      "kind": "LinkedField",
      "name": "studyStatusStatistics",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numStudies",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InterpretationStatistics",
      "kind": "LinkedField",
      "name": "accuracyAsPresentedStatistics",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InterpretationStatistics",
      "kind": "LinkedField",
      "name": "accuracyComparedToGoldStandardStatistics",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeedbackStatistics",
      "kind": "LinkedField",
      "name": "feedbackStatistics",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "StudyStatisticsByAuthor",
  "abstractKey": null
};
})();

(node as any).hash = "aabfe2a287913d36fdd9d2223b3cace1";

export default node;
