import Dropdown from '@bfly/ui2/Dropdown';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useRouter from 'found/useRouter';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useTenantIdInput from 'hooks/useTenantIdInput';
import connectionMessages from 'messages/connections';
import { useAdminRoutes } from 'routes/admin';
import { ConnectionFieldTypeName } from 'schemas/hospitalServer';

import { CreateButterflyLinkControl_Mutation as CreateMutation } from './__generated__/CreateButterflyLinkControl_Mutation.graphql';
import { CreateButterflyLinkControl_connectivityConfig$data as ConnectivityConfig } from './__generated__/CreateButterflyLinkControl_connectivityConfig.graphql';

const messages = defineMessages({
  defaultName: {
    id: 'butterflyLink.defaultName',
    defaultMessage: 'Link {count}',
  },
});

interface Props {
  connectivityConfig: ConnectivityConfig;
  as?: React.ElementType;
  children?: React.ReactNode;
}

function CreateButterflyLinkControl<As extends React.ElementType>({
  connectivityConfig,
  as: Component = Dropdown.Item,
  children = <FormattedMessage {...connectionMessages.butterflyLink} />,
  ...props
}: Props & React.ComponentPropsWithoutRef<As>) {
  const intl = useIntl();
  const { router } = useRouter();
  const routes = useAdminRoutes();

  const numButterflyLinks = useMemo(
    () =>
      (connectivityConfig.hospitalConnections || []).filter(
        (connection) =>
          connection!.type === ConnectionFieldTypeName.BUTTERFLY_LINK,
      ).length,
    [connectivityConfig],
  );

  const connectivityConfigIdInput = useTenantIdInput(connectivityConfig);

  const [mutate] = useMutationWithError<CreateMutation>(
    graphql`
      mutation CreateButterflyLinkControl_Mutation(
        $input: CreateButterflyLinkConnectionInput!
      ) {
        createButterflyLinkConnectionOrError(input: $input) {
          ... on CreateButterflyLinkConnectionPayload {
            secret
            butterflyLinkConnection {
              handle
              name
            }
          }
          ...mutationError_error @relay(mask: false)
        }
      }
    `,
    {
      input: {
        ...connectivityConfigIdInput,
        name: intl.formatMessage(messages.defaultName, {
          count: numButterflyLinks + 1,
        }),
      },
      updater: (store) => {
        const connectionKey = 'hospitalConnections';
        const parent = store.get(connectivityConfig.id!);

        if (!parent) {
          return;
        }

        const connections = parent?.getLinkedRecords(connectionKey) || [];

        const newHospitalConnection = store
          .getRootField('createButterflyLinkConnectionOrError')
          .getLinkedRecord('butterflyLinkConnection');

        parent.setLinkedRecords(
          [newHospitalConnection, ...connections],
          connectionKey,
        );
      },
    },
  );

  const createAndEdit = useCallback(async () => {
    const { createButterflyLinkConnectionOrError } = await mutate();

    const butterflyConnectionSecret =
      createButterflyLinkConnectionOrError!.secret;
    const hospitalConnectionHandle =
      createButterflyLinkConnectionOrError!.butterflyLinkConnection!.handle!;

    router.push({
      pathname: routes.butterflyLinkNew({
        hospitalConnectionHandle,
      }),
      state: { butterflyConnectionSecret },
    });
  }, [router, mutate, routes]);

  return (
    <Component {...props} onClick={createAndEdit}>
      {children}
    </Component>
  );
}

export default createFragmentContainer(CreateButterflyLinkControl, {
  connectivityConfig: graphql`
    fragment CreateButterflyLinkControl_connectivityConfig on ConnectivityConfigInterface {
      ... on Node {
        id
      }
      hospitalConnections {
        type: __typename
      }
      ...useTenantIdInput_tenant
    }
  `,
});
