/**
 * @generated SignedSource<<6c5fe8fc318dc0e471dbcac50e678dd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyPacsJobDetail_study$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SendStudyToPacsButton_study">;
  readonly " $fragmentType": "StudyPacsJobDetail_study";
};
export type StudyPacsJobDetail_study$key = {
  readonly " $data"?: StudyPacsJobDetail_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPacsJobDetail_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyPacsJobDetail_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendStudyToPacsButton_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "384d1e666929422902d234b8a59cd237";

export default node;
