/**
 * @generated SignedSource<<21488d685a2eed218ed9d088fb410858>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarListSuggestionOption_archive$data = {
  readonly label: string | null;
  readonly " $fragmentType": "SearchBarListSuggestionOption_archive";
};
export type SearchBarListSuggestionOption_archive$key = {
  readonly " $data"?: SearchBarListSuggestionOption_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBarListSuggestionOption_archive">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SearchBarListSuggestionOption_archive"
};

(node as any).hash = "51e0ff020215645d12a2f4582a508c5e";

export default node;
