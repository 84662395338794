/**
 * @generated SignedSource<<792c0a81092bfb5e8e46857b8926cd37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentEditor_studyImage$data = {
  readonly organization: {
    readonly subscription: {
      readonly isTeam: boolean | null;
    } | null;
  } | null;
  readonly study: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "CommentEditor_studyImage";
};
export type CommentEditor_studyImage$key = {
  readonly " $data"?: CommentEditor_studyImage$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentEditor_studyImage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentEditor_studyImage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Study",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationSubscription",
          "kind": "LinkedField",
          "name": "subscription",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isTeam",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyImage",
  "abstractKey": null
};

(node as any).hash = "62ea8ba6468437c0256cfce801fe4de4";

export default node;
