import Redirect from 'found/Redirect';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import adminRouteUrls from 'routes/admin';

import { orgAdminRoutes_OrganizationAdminPage_Query as OrganizationAdminPageQuery } from './__generated__/orgAdminRoutes_OrganizationAdminPage_Query.graphql';
import { organizationConnectivityRoute } from './routes/connectivity';
import credentialGroupsRoute from './routes/credentialGroups';
import { organizationExamTypesRoutes } from './routes/examTypes';
import historicalProficiencyRoute from './routes/historicalProficiency';
import { orgSettingsRoutes } from './routes/organizations';
import { organizationQaRoute } from './routes/qa';
import { organizationStudyTagsRoute } from './routes/study-tags';
import { organizationStudyProtocolsRoute } from './routes/studyProtocols';
import { organizationWorksheetsRoute } from './routes/worksheets';

export const adminRoutes = (
  <>
    <Redirect
      // Backwards-compatibility
      from="settings"
      to={(match) => adminRouteUrls(match).organizationGeneral()}
    />
    <Route<OrganizationAdminPageQuery>
      hideSearch
      path="admin"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/OrganizationAdminPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query orgAdminRoutes_OrganizationAdminPage_Query(
          $organizationSlug: String!
        ) {
          viewer {
            ...OrganizationAdminPage_viewer
          }
          organization: organizationBySlug(slug: $organizationSlug) {
            ...OrganizationAdminPage_organization
          }
        }
      `}
    >
      <Redirect to={(match) => adminRouteUrls(match).organizationMembers()} />
      {orgSettingsRoutes}
      {organizationConnectivityRoute}
      {organizationStudyTagsRoute}
      {credentialGroupsRoute}
      {historicalProficiencyRoute}
      {organizationQaRoute}
      {organizationWorksheetsRoute}
      {organizationExamTypesRoutes}
      {organizationStudyProtocolsRoute}
    </Route>
  </>
);
