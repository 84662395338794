import { css } from 'astroturf';
import clsx from 'clsx';

export default function LoadingIndicatorLine({
  className,
}: {
  className?: string;
}) {
  return (
    <div
      className={clsx(
        className,
        css`
          @apply inset-0 bg-grey-80 opacity-50 w-full absolute overflow-hidden;
          height: 2px;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          border-radius: 20px;

          &:before {
            content: '';
            @apply bg-primary absolute;
            left: -50%;
            height: 3px;
            width: 40%;
            -webkit-animation: lineAnim 1s linear infinite;
            -moz-animation: lineAnim 1s linear infinite;
            animation: lineAnim 1s linear infinite;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
          }

          @keyframes lineAnim {
            0% {
              left: -40%;
            }
            50% {
              left: 20%;
              width: 80%;
            }
            100% {
              left: 100%;
              width: 100%;
            }
          }
        `,
      )}
    />
  );
}
