/**
 * @generated SignedSource<<a537e3a1762f95f7015a42802edc4f53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamQuickFilters_tenant$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveSelectButton_tenant" | "MemberSelectButton_tenant">;
  readonly " $fragmentType": "ExamQuickFilters_tenant";
};
export type ExamQuickFilters_tenant$key = {
  readonly " $data"?: ExamQuickFilters_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamQuickFilters_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeArchives"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeAuthors"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamQuickFilters_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "condition": "includeAuthors",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MemberSelectButton_tenant"
        }
      ]
    },
    {
      "condition": "includeArchives",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArchiveSelectButton_tenant"
        }
      ]
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "4f0665f19db0bfd0aa3a132bf3707caf";

export default node;
