/**
 * @generated SignedSource<<256774fd647ef88c944e4e82981ed96d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EcommerceExternalRedirect_organization$data = {
  readonly slug: string | null;
  readonly subscription: {
    readonly hasCompletedTrial: boolean | null;
  } | null;
  readonly " $fragmentType": "EcommerceExternalRedirect_organization";
};
export type EcommerceExternalRedirect_organization$key = {
  readonly " $data"?: EcommerceExternalRedirect_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EcommerceExternalRedirect_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EcommerceExternalRedirect_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasCompletedTrial",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2728a4d558dd05fc8be93aa7b579260f";

export default node;
