/**
 * @generated SignedSource<<74ef6b15bd477c8ece3ff311a9942c38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalStudySearchRecentSearchesModal_Query$variables = {
  slug?: string | null;
};
export type GlobalStudySearchRecentSearchesModal_Query$data = {
  readonly tenant: {
    readonly viewerRecentSearchConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly criteria: ReadonlyArray<{
            readonly " $fragmentSpreads": FragmentRefs<"SearchTags_studySearchCriteria">;
          } | null> | null;
          readonly id: string;
          readonly lastExecutedAt: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"Search_searchToData">;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type GlobalStudySearchRecentSearchesModal_Query = {
  response: GlobalStudySearchRecentSearchesModal_Query$data;
  variables: GlobalStudySearchRecentSearchesModal_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastExecutedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v7 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "Organization",
  "abstractKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "name",
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "Archive",
  "abstractKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "ExamType",
  "abstractKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "StudyTag",
  "abstractKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalStudySearchRecentSearchesModal_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "StudyRecentSearchConnection",
            "kind": "LinkedField",
            "name": "viewerRecentSearchConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyRecentSearchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyRecentSearch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudySearchCriterion",
                        "kind": "LinkedField",
                        "name": "criteria",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SearchTags_studySearchCriteria"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "Search_searchToData",
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StudySearchCriterion",
                                "kind": "LinkedField",
                                "name": "criteria",
                                "plural": true,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v7/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v9/*: any*/),
                                        "type": "UserProfile",
                                        "abstractKey": null
                                      },
                                      (v10/*: any*/),
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "WorksheetTemplateVersion",
                                            "kind": "LinkedField",
                                            "name": "latestVersion",
                                            "plural": false,
                                            "selections": [
                                              (v13/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "WorksheetTemplate",
                                        "abstractKey": null
                                      },
                                      (v14/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "StudySearchRequestInterface",
                            "abstractKey": "__isStudySearchRequestInterface"
                          }
                        ],
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "viewerRecentSearchConnection(first:2147483647)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GlobalStudySearchRecentSearchesModal_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "StudyRecentSearchConnection",
            "kind": "LinkedField",
            "name": "viewerRecentSearchConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyRecentSearchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyRecentSearch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudySearchCriterion",
                        "kind": "LinkedField",
                        "name": "criteria",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isNode"
                              },
                              (v3/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Avatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Thumbnail",
                                            "kind": "LinkedField",
                                            "name": "thumbnails",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "url",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "width",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "ThumbnailedInterface",
                                        "abstractKey": "__isThumbnailedInterface"
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "UserProfile",
                                "abstractKey": null
                              },
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WorksheetTemplateVersion",
                                    "kind": "LinkedField",
                                    "name": "latestVersion",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "WorksheetTemplate",
                                "abstractKey": null
                              },
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isStudySearchRequestInterface"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "viewerRecentSearchConnection(first:2147483647)"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1e36eb7c36819622d5380b296e5f91c",
    "id": null,
    "metadata": {},
    "name": "GlobalStudySearchRecentSearchesModal_Query",
    "operationKind": "query",
    "text": "query GlobalStudySearchRecentSearchesModal_Query(\n  $slug: String\n) {\n  tenant(slug: $slug) {\n    __typename\n    viewerRecentSearchConnection(first: 2147483647) {\n      edges {\n        node {\n          id\n          lastExecutedAt\n          criteria {\n            ...SearchTags_studySearchCriteria\n          }\n          ...Search_searchToData\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  avatar {\n    ...Thumbnail_image\n  }\n  name\n}\n\nfragment SearchTags_studySearchCriteria on StudySearchCriterion {\n  key\n  value\n  node {\n    __typename\n    __isNode: __typename\n    id\n    type: __typename\n    ... on UserProfile {\n      name\n      ...Avatar_userProfile\n    }\n    ... on Organization {\n      name\n    }\n    ... on Archive {\n      id\n      name: label\n    }\n    ... on ExamType {\n      id\n      name\n    }\n    ... on WorksheetTemplate {\n      latestVersion {\n        title\n        id\n      }\n    }\n    ... on StudyTag {\n      name\n    }\n  }\n}\n\nfragment Search_searchToData on StudySearchRequestInterface {\n  __isStudySearchRequestInterface: __typename\n  criteria {\n    key\n    value\n    node {\n      __typename\n      id\n      type: __typename\n      ... on UserProfile {\n        name\n      }\n      ... on Organization {\n        name\n      }\n      ... on Archive {\n        name: label\n      }\n      ... on ExamType {\n        name\n      }\n      ... on WorksheetTemplate {\n        latestVersion {\n          title\n          id\n        }\n      }\n      ... on StudyTag {\n        name\n      }\n    }\n  }\n}\n\nfragment Thumbnail_image on ThumbnailedInterface {\n  __isThumbnailedInterface: __typename\n  thumbnails {\n    url\n    width\n  }\n}\n"
  }
};
})();

(node as any).hash = "49a198a4e24b932a862ec4434ab44310";

export default node;
