import ShareIcon from '@bfly/icons/Share';
import Dropdown from '@bfly/ui2/Dropdown';
import Tooltip from '@bfly/ui2/Tooltip';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useModalState from 'hooks/useModalState';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { studyOrArchiveIsDeleted } from 'utils/StudyPermissions';

import ShareStudyModal from './ShareStudyModal';
import { ShareStudyDropdownItem_study$data as Study } from './__generated__/ShareStudyDropdownItem_study.graphql';

interface Props {
  study: Study;
  children?: React.ReactNode;
}

function ShareStudyDropdownItem({ study, children }: Props) {
  const [showModal, modalProps] = useModalState();
  const { hasStudySharing } = useSubscriptionInfo(study.organization!);

  if (hasStudySharing === 'hidden' || studyOrArchiveIsDeleted(study)) {
    return null;
  }

  const disabled = hasStudySharing === 'disabled';

  return (
    <>
      <Tooltip.Trigger
        placement="top"
        variant="subscription"
        show={disabled ? undefined : false}
        tooltip={
          <FormattedMessage
            id="shareStudyControl.createDeidentifiedStudy.disabled"
            defaultMessage="Upgrade your membership to get access to this feature."
          />
        }
      >
        <Dropdown.Item
          disabled={disabled}
          data-bni-id="ShareStudy"
          icon={<ShareIcon />}
          onClick={showModal}
        >
          {children}
        </Dropdown.Item>
      </Tooltip.Trigger>

      <ShareStudyModal {...modalProps} study={study} />
    </>
  );
}

export default createFragmentContainer(ShareStudyDropdownItem, {
  study: graphql`
    fragment ShareStudyDropdownItem_study on Study {
      ...StudyPermissions_studyOrArchiveIsDeleted
      ...ShareStudyModal_study
      organization {
        ...useSubscriptionInfo_organization
      }
    }
  `,
});
