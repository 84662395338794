import Anchor from '@bfly/ui2/Anchor';
import BlankSlate from '@bfly/ui2/BlankSlate';
import MainContent from '@bfly/ui2/MainContent';
import Navbar from '@bfly/ui2/Navbar';
import Page from '@bfly/ui2/Page';
import { FormattedMessage } from 'react-intl';

import { supportEmail } from 'config/app';

function ErrorPage() {
  return (
    <Page>
      <Navbar>
        <Navbar.Brand as={Anchor} href="/" />
      </Navbar>
      <Page.Container center data-bni-id="ErrorPage">
        <MainContent centerText>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                defaultMessage="Looks like something went wrong."
                id="errorPage.title"
              />
            </BlankSlate.Title>
            <BlankSlate.Body>
              <FormattedMessage
                defaultMessage="To retry, refresh the page."
                id="errorPage.body"
              />
            </BlankSlate.Body>
            {/* Use email instead of Zendesk in case SSO is broken */}
            <BlankSlate.Action href={`mailto:${supportEmail}`}>
              <FormattedMessage
                defaultMessage="Contact Support"
                id="errorPage.support"
              />
            </BlankSlate.Action>
          </BlankSlate>
        </MainContent>
      </Page.Container>
    </Page>
  );
}

export default ErrorPage;
