import { commitMutation } from 'react-relay';
import {
  Environment,
  MutationConfig,
  MutationParameters,
} from 'relay-runtime';

export default function asyncCommitMutation<T extends MutationParameters>(
  environment: Environment,
  config: MutationConfig<T>,
) {
  return new Promise<T['response']>((resolve, reject) => {
    commitMutation(environment, {
      ...config,
      onError: (error) => {
        reject(error);
      },
      onCompleted: (response, errors) => {
        if (errors) {
          // contra documentation this doesn't get fired?
          // eslint-disable-next-line no-console
          console.error('unexpected errors', errors);
          reject(new Error('unexpected state'));
        } else {
          resolve(response);
        }
      },
    });
  });
}
