/**
 * @generated SignedSource<<4d3229a9fa170051edb08fdf1cf52c73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveEducationContentInput = {
  clientMutationId?: string | null;
  educationContentId: string;
};
export type useSaveContentToggleSaveContentMutation$variables = {
  input: SaveEducationContentInput;
};
export type useSaveContentToggleSaveContentMutation$data = {
  readonly saveEducationContentOrError: {
    readonly __typename?: string;
    readonly educationContent?: {
      readonly viewerHasSaved: boolean | null;
    } | null;
    readonly educationContentEdge?: {
      readonly node: {
        readonly id: string;
      } | null;
    } | null;
    readonly message?: string | null;
  } | null;
};
export type useSaveContentToggleSaveContentMutation = {
  response: useSaveContentToggleSaveContentMutation$data;
  variables: useSaveContentToggleSaveContentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasSaved",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "EducationContentEdge",
  "kind": "LinkedField",
  "name": "educationContentEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationContent",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSaveContentToggleSaveContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "saveEducationContentOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContent",
                "kind": "LinkedField",
                "name": "educationContent",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "type": "SaveEducationContentPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSaveContentToggleSaveContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "saveEducationContentOrError",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContent",
                "kind": "LinkedField",
                "name": "educationContent",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "type": "SaveEducationContentPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29c148a5fa6ee7e0579f5f7df5699eb9",
    "id": null,
    "metadata": {},
    "name": "useSaveContentToggleSaveContentMutation",
    "operationKind": "mutation",
    "text": "mutation useSaveContentToggleSaveContentMutation(\n  $input: SaveEducationContentInput!\n) {\n  saveEducationContentOrError(input: $input) {\n    __typename\n    ... on SaveEducationContentPayload {\n      educationContent {\n        viewerHasSaved\n        id\n      }\n      educationContentEdge {\n        node {\n          id\n        }\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "962a9796d42d5784bf3a60e379d602a1";

export default node;
