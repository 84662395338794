/**
 * @generated SignedSource<<7036d5c9c995463f86431f975d76087b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CredentialGroupMembersPerformanceIndicator_tenant$data = {
  readonly credentialStatistics: {
    readonly numUsers: number | null;
    readonly numUsersCredentialed: number | null;
    readonly numUsersPartiallyCredentialed: number | null;
  } | null;
  readonly type: string;
  readonly " $fragmentType": "CredentialGroupMembersPerformanceIndicator_tenant";
};
export type CredentialGroupMembersPerformanceIndicator_tenant$key = {
  readonly " $data"?: CredentialGroupMembersPerformanceIndicator_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CredentialGroupMembersPerformanceIndicator_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CredentialGroupMembersPerformanceIndicator_tenant",
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "CredentialStatistics",
      "kind": "LinkedField",
      "name": "credentialStatistics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUsers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUsersPartiallyCredentialed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUsersCredentialed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "069fe7ff07d27add37928f8b08e2263e";

export default node;
