import Button from '@bfly/ui2/Button';
import FormCheck from '@bfly/ui2/FormCheck';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useToast from '@bfly/ui2/useToast';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useMutation } from 'react-relay';

import withModal from 'utils/withModal';

import { bulkDomainUsersUpdateRoleConfirmationModalMessages as messages } from '../messages/DomainMembersMessages';
import {
  BulkDomainUsersUpdateRoleConfirmationModal_bulkUpdateMutation,
  BulkInputDomainUser,
} from './__generated__/BulkDomainUsersUpdateRoleConfirmationModal_bulkUpdateMutation.graphql';

const bulkUpdateMutation = graphql`
  mutation BulkDomainUsersUpdateRoleConfirmationModal_bulkUpdateMutation(
    $input: BulkUpdateDomainUsersInput!
  ) {
    bulkUpdateDomainUsers(input: $input) {
      users {
        edges {
          node {
            id
            email
            integrationDisplayNameFirst
            integrationDisplayNameMiddle
            integrationDisplayNameLast
            canQa
            canFinalize
            type
            dicomFields {
              dicomFieldTemplate {
                label
              }
              value
            }
            integrationConfigs {
              ehr {
                handle
              }
              interfaceCode
            }
            role {
              name
            }
            organizations {
              name
            }
            updatedAt
          }
        }
      }
      failures {
        email
        detail
        statusCode
        code
      }
    }
  }
`;

interface Props {
  onHide: () => void;
  onSuccess: () => void;
  domainId: string;
  selectedUsers: {
    email: string;
    roleId: string;
    integrationDisplayNameFirst: string;
    integrationDisplayNameLast: string;
  }[];
}

function BulkDomainUsersUpdateRoleConfirmationModal({
  selectedUsers,
  domainId,
  onHide,
  onSuccess,
}: Props) {
  const toast = useToast();
  const [mutate] =
    useMutation<BulkDomainUsersUpdateRoleConfirmationModal_bulkUpdateMutation>(
      bulkUpdateMutation,
    );

  const [confirmedUsersEmails, setConfirmedUsersEmails] = useState(
    new Set(selectedUsers.map(({ email }) => email)),
  );
  const editedUsers = useMemo(() => [...selectedUsers], [selectedUsers]);

  const toggleUser = useCallback(
    (email: string) => {
      setConfirmedUsersEmails((prev) => {
        const confirmedUsers = new Set(prev);
        if (prev.has(email)) {
          confirmedUsers.delete(email);
        } else {
          confirmedUsers.add(email);
        }
        return confirmedUsers;
      });
    },
    [setConfirmedUsersEmails],
  );

  const handleUpdateUsers = useCallback(() => {
    const users: BulkInputDomainUser[] = selectedUsers.filter(({ email }) =>
      confirmedUsersEmails.has(email),
    );

    mutate({
      variables: {
        input: {
          domainId,
          users,
        },
      },
      onError() {
        toast?.error(<FormattedMessage {...messages.toastError} />);
      },
      onCompleted({ bulkUpdateDomainUsers }) {
        if (!bulkUpdateDomainUsers!.failures!.length) {
          onSuccess();
          toast?.success(
            <FormattedMessage
              {...messages.toastSuccess}
              values={{
                numUpdated: String(
                  bulkUpdateDomainUsers!.users!.edges!.length,
                ),
              }}
            />,
          );
        }
      },
    });
  }, [
    onSuccess,
    mutate,
    selectedUsers,
    confirmedUsersEmails,
    domainId,
    toast,
  ]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...messages.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Text as="p" className="px-5">
          <FormattedMessage
            {...messages.bodyTextUpdated}
            values={{
              numUpdated: String(confirmedUsersEmails.size),
              strong: (msg: string) => <strong>{msg}</strong>,
            }}
          />
        </Text>
        <div className="px-5 w-full">
          <table className="w-full">
            <tbody>
              {editedUsers.map((user) => {
                return (
                  <tr key={user.email} className="border-b border-b-grey-70">
                    <td className="w-8 h-12">
                      <FormCheck
                        className="m-0"
                        checked={confirmedUsersEmails.has(user.email)}
                        onChange={() => toggleUser(user.email!)}
                      />
                    </td>
                    <td className="h-12">{user.email}</td>
                    <td className="h-12">
                      {user.integrationDisplayNameFirst}{' '}
                      {user.integrationDisplayNameLast}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            data-bni-id="BulkUpdateRoleModalConfirm"
            size="lg"
            onClick={handleUpdateUsers}
            disabled={confirmedUsersEmails.size === 0}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
          <Button
            data-bni-id="BulkUpdateRoleModalCancel"
            variant="secondary"
            size="lg"
            onClick={onHide}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default withModal(BulkDomainUsersUpdateRoleConfirmationModal, {
  style: { maxWidth: '600px' },
  variant: 'dark',
});
