import DropdownList from '@bfly/ui2/DropdownList';
import Form, { FormProps } from '@bfly/ui2/Form';
import Text from '@bfly/ui2/Text';
import useMutationWithError, {
  type MutationParametersWithError,
} from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import invariant from 'invariant';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { MessageDescriptor } from 'react-intl';
import { GraphQLTaggedNode } from 'relay-runtime';
import { array, object, string } from 'yup';
import type { InferType } from 'yup';

import errorMessages from 'messages/error';
import { usePermissions } from 'utils/viewerContext';

function isDisabledAssoc(current: any, idx, list: any[]) {
  // Do not allow e.g. setting the third association if the second is unset.
  return current == null && idx > 0 && list[idx - 1] == null;
}

const schema = object({
  associations: array().of(
    object({
      id: string(),
      name: string().nullable(),
    }).nullable(),
  ),
});

type Association = NonNullable<InferType<typeof schema>['associations']>[0];

interface Props<
  T extends MutationParametersWithError,
  TAssociation extends Association,
> extends Omit<FormProps<typeof schema>, 'onChange' | 'defaultValue'> {
  mutation: GraphQLTaggedNode;
  getInput: (associations: TAssociation[]) => T['variables']['input'];
  currentAssociations: TAssociation[];
  data: TAssociation[];
  maxAssociations: number;
  formMessages: {
    fieldLabel: MessageDescriptor;
    emptyDropdownMenu: MessageDescriptor;
    emptyDropdownPlaceholder: MessageDescriptor;
  };
}

function HospitalServerArchiveAssociationForm<
  T extends MutationParametersWithError,
  TAssociation extends Association,
>({
  mutation,
  getInput,
  currentAssociations,
  data,
  formMessages,
  maxAssociations,
  ...props
}: Props<T, TAssociation>) {
  const { hasAdminPermission } = usePermissions();
  const toast = useToast();
  const intl = useIntl();

  const [mutate, loading] = useMutationWithError(mutation);

  const defaultValue = useMemo(
    () =>
      schema.cast({
        associations: Array.from({ length: maxAssociations }).map(
          (_, i) => currentAssociations[i] || null,
        ),
      }),
    [currentAssociations, maxAssociations],
  );

  const handleChange = useCallback(
    async ({ associations }, fieldNames) => {
      invariant(
        fieldNames.length === 1,
        'only expected one field to be updated',
      );

      // XXX: onChange is fired twice, once with `associations` and once
      // with `associations[idx]`. this check avoids double committing
      if (fieldNames.includes('associations')) return;

      try {
        await mutate({
          input: getInput(associations),
        });
      } catch (e) {
        toast.error(<FormattedMessage {...errorMessages.request} />);
      }
    },
    [getInput, mutate, toast],
  );

  return (
    <Form
      {...props}
      horizontal
      schema={schema}
      defaultValue={defaultValue}
      onChange={handleChange}
    >
      <Form.FieldArray name="associations">
        {(values) =>
          values.map((value, idx, list) => (
            <Form.FieldGroup
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              name={`associations[${idx}]`}
              label={intl.formatMessage(formMessages.fieldLabel, {
                index: idx + 1,
              })}
            >
              {(fieldProps) => (
                <DropdownList
                  allowEmpty
                  {...fieldProps}
                  disabled={
                    loading ||
                    isDisabledAssoc(value, idx, list) ||
                    !hasAdminPermission('connectivityManagement')
                  }
                  data={data}
                  dataKey="id"
                  textField="name"
                  data-bni-id="ArchiveAssociationDropdown"
                  emptyMessage={formMessages.emptyDropdownPlaceholder}
                  placeholder={formMessages.emptyDropdownPlaceholder}
                  messages={{
                    emptyList: () => (
                      <Text align="center" as="div" className="p-4">
                        {intl.formatMessage(formMessages.emptyDropdownMenu, {
                          br: <br />,
                        })}
                      </Text>
                    ),
                  }}
                />
              )}
            </Form.FieldGroup>
          ))
        }
      </Form.FieldArray>
    </Form>
  );
}

export default HospitalServerArchiveAssociationForm;
