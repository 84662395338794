import clsx from 'clsx';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import UserSuggestion from 'components/UserSuggestion';

import { CommentSuggestionEntry_mentionable$data as Mentionable } from './__generated__/CommentSuggestionEntry_mentionable.graphql';

interface Props {
  mentionable: Mentionable;
  searchValue: string;
  isFocused?: boolean;
  onMouseDown?: React.ComponentProps<'div'>['onMouseDown'];
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onKeyDown?: (e: React.KeyboardEventHandler<any>) => void;
}

function CommentSuggestionEntry({
  mentionable: { userProfile, email },
  searchValue,
  isFocused,
  ...props
}: Props) {
  return (
    <UserSuggestion
      {...props}
      userProfile={userProfile!}
      email={email!}
      searchTerm={searchValue}
      className={clsx(
        'py-1 px-3 cursor-pointer first:mt-2 last:mb-2',
        isFocused && 'bg-primary text-white',
      )}
      highlightedClassName={isFocused ? 'text-white' : 'text-grey-90'}
    />
  );
}

export default createFragmentContainer(CommentSuggestionEntry, {
  mentionable: graphql`
    fragment CommentSuggestionEntry_mentionable on Mentionable {
      userProfile {
        ...UserSuggestion_userProfile
      }
      email
    }
  `,
});
