import Layout from '@4c/layout';
import SplitExistingDraftIcon from '@bfly/icons/SplitExistingDraft';
import SplitNewDraftIcon from '@bfly/icons/SplitNewDraft';
import Button from '@bfly/ui2/Button';
import { useField } from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import clsx from 'clsx';
import { UseFieldProps } from 'react-formal/useField';
import { FormattedMessage } from 'react-intl';

import actionMessages from 'messages/actions';
import { StudyDestinationType } from 'utils/examEnums';

function getButtonProps(focused: boolean) {
  // returns props for a custom button style
  return {
    variant: null,
    className: clsx(
      'w-10/12 flex items-center justify-start btn border-solid border text-white btn--xl',
      focused ? 'border-primary ring' : 'border-white',
    ),
  };
}

interface Props {
  onCancel: () => void;
  onNext: () => void;
}

function SplitStudyRefreshModalSelectDestination({ onCancel, onNext }: Props) {
  const [{ value, onChange }]: [UseFieldProps<StudyDestinationType>, any] =
    useField('destinationType');

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="SplitStudyRefreshModalSelectDestination.chooseDestination"
            defaultMessage="Move content to"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Layout direction="column" align="center" pad className="my-8">
          <Button
            {...getButtonProps(value === StudyDestinationType.NEW)}
            onClick={() => onChange(StudyDestinationType.NEW)}
          >
            <SplitNewDraftIcon width={40} className="mr-8" />
            <FormattedMessage
              id="SplitStudyRefreshModalSelectDestination.newDraftButton"
              defaultMessage="New draft"
            />
          </Button>
          <Button
            {...getButtonProps(value === StudyDestinationType.EXISTING)}
            onClick={() => onChange(StudyDestinationType.EXISTING)}
          >
            <SplitExistingDraftIcon width={40} className="mr-8" />
            <FormattedMessage
              id="SplitStudyRefreshModalSelectDestination.existingDraftButton"
              defaultMessage="Existing draft"
            />
          </Button>
        </Layout>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button size="lg" onClick={onNext}>
            <FormattedMessage {...actionMessages.continue} />
          </Button>

          <Button size="lg" variant="secondary" onClick={onCancel}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default SplitStudyRefreshModalSelectDestination;
