import Layout from '@4c/layout';
import DropdownIcon from '@bfly/icons/Dropdown';
import useId from '@bfly/ui2/useId';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import useToggler from 'hooks/useToggler';

const styles = stylesheet`
  .dropdown {
    transition: 0.1s all;
  }

  .expanded {
    transform: rotate(180deg);
  }
`;

export enum JobType {
  PUSH,
  ECHO,
  REFRESH,
  RECEIVE,
}

interface Props {
  status: string;
  jobType: JobType;
  createdAt: string | Date;
  children: (expanded: boolean) => ReactNode;
}

function ServerLogListItem({ status, jobType, createdAt, children }: Props) {
  const [expanded, toggleExpanded] = useToggler();
  const id = useId();
  return (
    <li>
      <Layout
        as="button"
        className="text-lg my-3 w-full cursor-pointer"
        justify="space-between"
        align="center"
        onClick={() => toggleExpanded()}
        aria-expanded={expanded}
        aria-controls={id}
      >
        <FormattedMessage
          id="ServerLogListItem.title"
          defaultMessage={`{status, select,
            COMPLETE {Successful}
            FAILED {Unsuccessful}
            other {}
          } {jobType, select,
            0 {send}
            1 {echo}
            2 {refresh}
            3 {receive}
            other {}
          } at {createdAt, date, medium} {createdAt, time, medium}`}
          values={{
            status,
            jobType,
            createdAt: new Date(createdAt),
          }}
        />
        <DropdownIcon
          className={clsx(styles.dropdown, expanded && styles.expanded)}
        />
      </Layout>
      <div id={id}>{children(expanded)}</div>
      <div className="border-b border-divider" />
    </li>
  );
}

export default ServerLogListItem;
