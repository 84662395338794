/**
 * @generated SignedSource<<4e1e8b3b952dec958f20364c5b88577a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewStudyDataGridCaptures_study$data = {
  readonly capturedAt: string | null;
  readonly handle: string | null;
  readonly numImages: number | null;
  readonly " $fragmentType": "NewStudyDataGridCaptures_study";
};
export type NewStudyDataGridCaptures_study$key = {
  readonly " $data"?: NewStudyDataGridCaptures_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewStudyDataGridCaptures_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewStudyDataGridCaptures_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numImages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capturedAt",
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "5940233ab8a551f525a4e2a1046159c0";

export default node;
