/**
 * @generated SignedSource<<51116d79a9ed1f4eb5e58888a0c43cf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UndeleteStudyInput = {
  archiveId?: string | null;
  clientMutationId?: string | null;
  studyId: string;
};
export type UndeleteStudyControlMutation$variables = {
  input: UndeleteStudyInput;
};
export type UndeleteStudyControlMutation$data = {
  readonly undeleteStudyOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
    readonly study?: {
      readonly deletedAt: string | null;
      readonly imageConnection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly handle: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type UndeleteStudyControlMutation = {
  response: UndeleteStudyControlMutation$data;
  variables: UndeleteStudyControlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UndeleteStudyControlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "undeleteStudyOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "StudyImageConnection",
                    "kind": "LinkedField",
                    "name": "imageConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyImageEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StudyImage",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "imageConnection(first:1)"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UndeleteStudyPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UndeleteStudyControlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "undeleteStudyOrError",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "StudyImageConnection",
                    "kind": "LinkedField",
                    "name": "imageConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyImageEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StudyImage",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "imageConnection(first:1)"
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UndeleteStudyPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e113a836b2d0f0146d9334f6dc6121f9",
    "id": null,
    "metadata": {},
    "name": "UndeleteStudyControlMutation",
    "operationKind": "mutation",
    "text": "mutation UndeleteStudyControlMutation(\n  $input: UndeleteStudyInput!\n) {\n  undeleteStudyOrError(input: $input) {\n    __typename\n    ... on UndeleteStudyPayload {\n      study {\n        deletedAt\n        imageConnection(first: 1) {\n          edges {\n            node {\n              handle\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "abf7b59b4bfa5887784221eee1457568";

export default node;
