/**
 * @generated SignedSource<<ee71580aec842ec4a9f4f7219d0b31d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveExamTypeInput = {
  clientMutationId?: string | null;
  examTypeId: string;
  studyId: string;
};
export type useHandleExamTypeChange_RemoveMutation$variables = {
  input: RemoveExamTypeInput;
};
export type useHandleExamTypeChange_RemoveMutation$data = {
  readonly removeExamTypeOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
    readonly study?: {
      readonly examTypes: ReadonlyArray<{
        readonly name: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useHandleExamTypeChange_RemoveMutation$rawResponse = {
  readonly removeExamTypeOrError: {
    readonly __typename: "RemoveExamTypePayload";
    readonly __isErrorInterface: "RemoveExamTypePayload";
    readonly message: string | null;
    readonly study: {
      readonly examTypes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
      } | null> | null;
      readonly id: string;
    } | null;
  } | {
    readonly __typename: string;
    readonly __isErrorInterface: string;
    readonly message: string | null;
  } | null;
};
export type useHandleExamTypeChange_RemoveMutation = {
  rawResponse: useHandleExamTypeChange_RemoveMutation$rawResponse;
  response: useHandleExamTypeChange_RemoveMutation$data;
  variables: useHandleExamTypeChange_RemoveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useHandleExamTypeChange_RemoveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "removeExamTypeOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamType",
                    "kind": "LinkedField",
                    "name": "examTypes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RemoveExamTypePayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHandleExamTypeChange_RemoveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "removeExamTypeOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Study",
                "kind": "LinkedField",
                "name": "study",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamType",
                    "kind": "LinkedField",
                    "name": "examTypes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "RemoveExamTypePayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0ce6b6696668b4ef55bc02c5d055465",
    "id": null,
    "metadata": {},
    "name": "useHandleExamTypeChange_RemoveMutation",
    "operationKind": "mutation",
    "text": "mutation useHandleExamTypeChange_RemoveMutation(\n  $input: RemoveExamTypeInput!\n) {\n  removeExamTypeOrError(input: $input) {\n    __typename\n    ... on RemoveExamTypePayload {\n      study {\n        examTypes {\n          name\n          id\n        }\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86676333cfce461acf3ca58b5cda3415";

export default node;
