/**
 * @generated SignedSource<<75c68bef01b45168d515566501f02244>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_viewer">;
  readonly " $fragmentType": "AppPage_viewer";
};
export type AppPage_viewer$key = {
  readonly " $data"?: AppPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BaseAppPage_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "ee02033ba949be9d7014a54a9ac4472a";

export default node;
