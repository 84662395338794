import { defineMessage } from 'react-intl';
import { graphql, readInlineData } from 'relay-runtime';
import { array, object, string } from 'yup';
import type { AnySchema } from 'yup';

import FormFieldType from 'config/FormFieldType';

import type { fieldDefinition_fieldDefinition$key as FieldDefinitionKey } from './__generated__/fieldDefinition_fieldDefinition.graphql';

const fieldDefinitionFragment = graphql`
  fragment fieldDefinition_fieldDefinition on FormFieldDefinitionInterface
  @inline {
    typename: __typename
    handle
    required
    ... on SingleSelectFieldDefinition {
      options {
        handle
        label
      }
      allowOther
    }
    ... on MultiSelectFieldDefinition {
      options {
        handle
        label
      }
      allowOther
    }
  }
`;

function fieldDefinitionToOptionSchema(
  fieldDefinitionRef: FieldDefinitionKey,
  stripIncompatible = false,
  partial = false,
) {
  const fieldDefinition = readInlineData(
    fieldDefinitionFragment,
    fieldDefinitionRef,
  );

  if (!fieldDefinition.options) {
    return false;
  }

  const validIds = fieldDefinition.options!.map((option) => option!.handle!);

  const schema = fieldDefinition.allowOther
    ? object({
        id: string().test(
          'is-valid-choice',
          'not a valid id',
          (value) => validIds.includes(value!) || value!.startsWith('@@other'),
        ),
        value: string(),
      }).default(undefined)
    : object({
        id: string().oneOf(validIds).required(),
      }).default(undefined);

  if (stripIncompatible) {
    const blank = partial ? undefined : null;
    return schema
      .nullable()
      .transform((value) => (validIds.includes(value?.id) ? value : blank));
  }

  return schema;
}

const objTag = {}.toString();

/**
 * Generate a Yup schema from a worksheet template field definition.
 */
export function fieldDefinitionToSchema(
  fieldDefinitionRef: FieldDefinitionKey,
  {
    partial = false,
    stripIncompatible = false,
  }: { partial?: boolean; stripIncompatible?: boolean } = {},
): AnySchema<unknown> {
  const optionSchema = fieldDefinitionToOptionSchema(
    fieldDefinitionRef,
    stripIncompatible,
    partial,
  );

  const fieldDefinition = readInlineData(
    fieldDefinitionFragment,
    fieldDefinitionRef,
  );

  const typeToSchema = {
    [FormFieldType.TEXT]: string(),
    [FormFieldType.NUMBER]: string(),
    [FormFieldType.MULTI_SELECT]: (optionSchema
      ? array(optionSchema)
      : array()
    ).transform((value) => value.filter((val) => !!val)), // val will be undefined for outdated multi-select options, omit from cast value to prevent 422 from backend
    [FormFieldType.SINGLE_SELECT]: optionSchema,
  };

  let schema = typeToSchema[fieldDefinition.typename!];
  if (fieldDefinition.required && !partial) {
    schema = schema.required();
    if (fieldDefinition.typename! === FormFieldType.MULTI_SELECT) {
      schema = schema.min(
        1,
        defineMessage({
          id: 'fieldDefinitionToSchema.required',
          defaultMessage: 'Required',
        }),
      );
    }
  }

  // Allow incompatible values to be deserialized as null.
  //  This allows the prefill form to load values from an old template version.
  //  This works because calling nullable() on a required() field, still requires
  //  the user to fill out the value if the current value is null.
  //
  if (stripIncompatible) {
    const blank = partial ? undefined : null;
    schema = schema.nullable().transform((value, originalValue) =>
      // TODO: Remove objTag check when https://github.com/jquense/yup/pull/991 is released
      !schema.isType(value) ||
      value === objTag ||
      (Array.isArray(originalValue) && !Array.isArray(value))
        ? blank
        : value,
    );
  }

  return schema;
}
