import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import { ReactNode, useMemo } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import actionMessages from 'messages/actions';
import { useSplitStudyFormValue } from 'schemas/splitStudySchema';
import Analytics from 'utils/Analytics';
import { StudyDestinationType, StudySource } from 'utils/examEnums';

import SplitStudyArchiveField from './SplitStudyArchiveField';
import SplitStudyAuthorField from './SplitStudyAuthorField';
import SplitStudyExamTypeDropdown from './SplitStudyExamTypeDropdown';
import SplitStudyPatientField from './SplitStudyPatientField';
import SplitStudyTagsField from './SplitStudyTagsField';
import SplitStudyWorksheetsField from './SplitStudyWorksheetsField';
import StudyCaptureSelector from './StudyCaptureSelector';
import { SplitStudyRefreshModalSelectContent_sourceStudy$data as SourceStudy } from './__generated__/SplitStudyRefreshModalSelectContent_sourceStudy.graphql';

const messages = defineMessages({
  author: {
    id: 'SplitStudyRefreshModalSelectContent.author',
    defaultMessage: 'Author',
  },
  patient: {
    id: 'SplitStudyRefreshModalSelectContent.patient',
    defaultMessage: 'Patient',
  },
  examTypes: {
    defaultMessage: 'Exam types',
    id: 'SplitStudyRefreshModalSelectContent.examTypes',
  },
  archive: {
    defaultMessage: 'Archive',
    id: 'SplitStudyRefreshModalSelectContent.archive',
  },
  existingDisclaimer: {
    defaultMessage:
      '{fieldName} information will match the existing draft you select in the next step.',
    id: 'SplitStudyRefreshModalSelectContent.existingDisclaimer',
  },
});

function StudyDetailsField({
  children,
  label,
  existingEdit = false,
}: {
  children: ReactNode;
  label: MessageDescriptor;
  existingEdit?: boolean;
}) {
  const destinationType = useSplitStudyFormValue('destinationType');
  return (
    <Layout pad={3} align="center" alignContent="center">
      <Text as="div" className="min-w-20">
        <FormattedMessage {...label} />
      </Text>
      {!existingEdit && destinationType === 'EXISTING' ? (
        <Text className="italic">
          <FormattedMessage
            {...messages.existingDisclaimer}
            values={{ fieldName: <FormattedMessage {...label} /> }}
          />
        </Text>
      ) : (
        <>{children}</>
      )}
    </Layout>
  );
}

interface Props {
  onNext: () => void;
  onBack: () => void;
  sourceStudy: SourceStudy;
  onEditPatient?: () => void;
}

function SplitStudyRefreshModalSelectContent({
  onNext,
  onBack,
  sourceStudy,
  onEditPatient,
}: Props) {
  const imageIds = useSplitStudyFormValue('imageIds');
  const destinationType = useSplitStudyFormValue('destinationType');
  const worksheets = useSplitStudyFormValue('worksheets');

  const allImagesSelected = imageIds.length === sourceStudy.numImages;

  const nextStepDisabled = useMemo(
    () =>
      (!imageIds.length && destinationType === 'NEW') ||
      (!imageIds.length && !worksheets.length),
    [imageIds, destinationType, worksheets],
  );

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {destinationType === 'NEW' ? (
            <FormattedMessage
              id="SplitStudyRefreshModalSelectContent.titleNew"
              defaultMessage="Move content to new exam"
            />
          ) : (
            <FormattedMessage
              id="SplitStudyRefreshModalSelectContent.titleExisting"
              defaultMessage="Select content to move"
            />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Layout direction="column" pad>
          <Text color="subtitle">
            <FormattedMessage
              id="SplitStudyRefreshModalSelectContent.note"
              defaultMessage="Note: Selected worksheets and captures will be moved from this exam to the target exam."
            />
          </Text>
          <Text variant="lg">
            <FormattedMessage
              id="SplitStudyRefreshModalSelectContent.studyDetailsHeading"
              defaultMessage="Exam details"
            />
          </Text>
          <Layout direction="column" pad={3}>
            <StudyDetailsField label={messages.author}>
              <SplitStudyAuthorField sourceStudy={sourceStudy} />
            </StudyDetailsField>

            <StudyDetailsField label={messages.patient}>
              <SplitStudyPatientField
                sourceStudy={sourceStudy}
                onEditPatient={() => {
                  onEditPatient?.();
                  Analytics.track('splitStudyEditPatient', {
                    studyId: sourceStudy.id,
                    organizationId: sourceStudy.organization?.id,
                  });
                }}
              />
            </StudyDetailsField>

            <StudyDetailsField label={messages.examTypes} existingEdit>
              <SplitStudyExamTypeDropdown
                fieldName="examTypeIds"
                study={sourceStudy}
              />
            </StudyDetailsField>

            <StudyDetailsField label={messages.archive}>
              <SplitStudyArchiveField sourceStudy={sourceStudy} />
            </StudyDetailsField>
          </Layout>
          <Text variant="lg" className="mt-5">
            <FormattedMessage
              id="SplitStudyRefreshModalSelectContent.contentToMove"
              defaultMessage="Content to move"
            />
          </Text>
          <SplitStudyTagsField sourceStudy={sourceStudy} />
          <SplitStudyWorksheetsField
            selectAllWorksheets={allImagesSelected}
            sourceStudy={sourceStudy}
          />
          {allImagesSelected && (
            <Text color="danger">
              <FormattedMessage
                id="SplitStudyRefreshModalSelectContent.warning"
                defaultMessage="Moving the final capture will delete the exam and all associated information."
              />
            </Text>
          )}
          <StudyCaptureSelector
            study={sourceStudy}
            heading={
              <FormattedMessage
                id="SplitStudyRefreshModalSelectContent.captures"
                defaultMessage="Captures (required)"
              />
            }
          />
          {destinationType === StudyDestinationType.EXISTING &&
            sourceStudy.source === StudySource.THIRD_PARTY && (
              <Text color="danger" className="mt-2">
                <FormattedMessage
                  id="SplitStudyRefreshModalSelectContent.thirdPartyUltrasoundWarning"
                  defaultMessage="Warning: You are about to move images from a third party ultrasound system to a different exam. These images could have patient information on them that is inconsistent with the target exam - please review."
                />
              </Text>
            )}
        </Layout>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          {destinationType === StudyDestinationType.EXISTING ||
          allImagesSelected ? (
            <Button size="lg" onClick={onNext} disabled={nextStepDisabled}>
              <FormattedMessage {...actionMessages.next} />
            </Button>
          ) : (
            <Form.Submit
              variant="primary"
              size="lg"
              disabled={nextStepDisabled}
            >
              <FormattedMessage {...actionMessages.confirm} />
            </Form.Submit>
          )}
          <Button size="lg" variant="secondary" onClick={onBack}>
            <FormattedMessage {...actionMessages.back} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(SplitStudyRefreshModalSelectContent, {
  sourceStudy: graphql`
    fragment SplitStudyRefreshModalSelectContent_sourceStudy on Study {
      id
      numImages
      source
      organization {
        id
        handle
      }
      ...StudyCaptureSelector_study
      ...SplitStudyTagsField_sourceStudy
      ...SplitStudyExamTypeDropdown_study
      ...SplitStudyWorksheetsField_sourceStudy
      ...SplitStudyPatientField_sourceStudy
      ...SplitStudyAuthorField_sourceStudy
      ...SplitStudyArchiveField_sourceStudy
    }
  `,
});
