import Bookmark from '@bfly/icons/Bookmark';
import Clock from '@bfly/icons/Clock';
import { DateRangePickerValue as DateRangeValue } from '@bfly/ui2/DateRangePicker';
import clsx from 'clsx';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import { defineMessages } from 'react-intl';
import { graphql, readInlineData } from 'react-relay';
import { CamelCase, SnakeCase } from 'type-fest';

import { StudySearchCriterionKey } from 'components/__generated__/SearchTags_studySearchCriteria.graphql';
import { deserializeDateRange } from 'schemas/dateRange';
import { StudyStatus } from 'utils/StudyConstants';

import { Search_searchToData$key as SearchDataKey } from './__generated__/Search_searchToData.graphql';

export const UNASSIGNED_AUTHOR = '@@UNASSIGNED';

export enum SearchConstants {
  FREE_TEXT_SEARCH = '@@freeTextSearch',
  SAVED_SEARCH = '@@savedSearch',
  RECENT_SEARCH = '@@recentSearch',
}

export const RecentSearchIcon = function icon({
  className,
}: {
  className?: string;
}) {
  return (
    <span className={clsx('inline-flex', className)}>
      <Clock width={14} height={14} style={{ marginRight: '5px' }} />
    </span>
  );
};

export const SavedSearchIcon = function icon({
  className,
}: {
  className?: string;
}) {
  return (
    <span className={clsx('inline-flex', className)}>
      <Bookmark width={18} />
    </span>
  );
};

export const messages = defineMessages({
  apply: {
    id: 'searchGlobal.apply',
    defaultMessage: 'Apply',
  },
  reset: {
    id: 'searchGlobal.reset',
    defaultMessage: 'Reset',
  },
  searchName: {
    id: 'searchGlobal.searchName',
    defaultMessage: 'Search name',
  },
  recentSearches: {
    id: 'searchGlobal.recentSearches',
    defaultMessage: 'Recent searches',
  },
  savedSearches: {
    id: 'searchGlobal.savedSearches',
    defaultMessage: 'Saved searches',
  },
  details: {
    id: 'searchGlobal.details',
    defaultMessage: 'Details',
  },
  date: {
    id: 'searchGlobal.date',
    defaultMessage: 'Date',
  },
});

enum MetaKey {
  ARCHIVE = 'archive',
  AUTHOR = 'author',
  PRIMARY = 'primary',
  CAPTURE_DATE = 'capturedAt',
  EXAM_TYPE = 'examType',
  FREE_TEXT = 'freeText',
  ORGANIZATION = 'organization',
  REVIEWED_BY = 'reviewer',
  STATUS = 'status',
  TAG = 'tag',
  WORKSHEET = 'worksheetTemplate',
  ALL_WORKSHEETS = 'allWorksheetTemplates',
  PATIENT_FIRST_NAME = 'patientNameFirst',
  PATIENT_MIDDLE_NAME = 'patientNameMiddle',
  PATIENT_LAST_NAME = 'patientNameLast',
  PATIENT_ID = 'patientMedicalRecordNumber',
  PATIENT_ACCESSION_NUMBER = 'accessionNumber',
  PATIENT_DOB = 'patientBirthDate',
}

export enum AuthorType {
  PRIMARY_ONLY = 'primaryOnly',
  UNASSIGNED_AUTHORS = 'unassignedAuthors',
}

interface SerializedDateRange {
  days?: number;
  startDate?: string;
  endDate?: string;
}

export interface LabeledValue {
  label: string | null | undefined;
  value: string | null | undefined;
}

function toSearchKey<T extends MetaKey>(key: T) {
  return snakeCase(key).toUpperCase() as Uppercase<SnakeCase<T>>;
}

function toMetaKey<T extends StudySearchCriterionKey>(key: T) {
  return camelCase(key) as CamelCase<T>;
}

export function getSearchStateDefault(
  org?: {
    id: string;
  } | null,
) {
  const defaultState: SearchData = {
    organization: org ? [org.id] : null,
    archive: null,
    author: null,
    primary: false,
    capturedAt: null,
    examType: null,
    freeText: '',
    accessionNumber: null,
    patientBirthDate: null,
    patientNameFirst: null,
    patientMedicalRecordNumber: null,
    patientNameLast: null,
    patientNameMiddle: null,
    reviewer: null,
    status: null,
    tag: null,
    worksheetTemplate: null,
    allWorksheetTemplates: null,
  };

  return defaultState;
}

export { MetaKey as SearchMetaKey };

type SearchDataBase = { [key in MetaKey]: any };

type ArrayKeys = {
  [K in keyof SearchData]-?: NonNullable<SearchData[K]> extends unknown[]
    ? K
    : never;
}[keyof SearchData];

type MetaKeyArrayValues = `${ArrayKeys}`;

export interface SearchData extends SearchDataBase {
  [MetaKey.ARCHIVE]: string[] | null;
  [MetaKey.AUTHOR]: LabeledValue[] | null | undefined;
  [MetaKey.PRIMARY]: boolean | null;
  [MetaKey.CAPTURE_DATE]: DateRangeValue | null;
  [MetaKey.EXAM_TYPE]: string[] | null;
  [MetaKey.FREE_TEXT]: string | null;
  [MetaKey.ORGANIZATION]: string[] | null;
  [MetaKey.REVIEWED_BY]: LabeledValue[] | null | undefined;
  [MetaKey.STATUS]: StudyStatus[] | null;
  [MetaKey.TAG]: string[] | null;
  [MetaKey.WORKSHEET]: string[] | null;
  [MetaKey.ALL_WORKSHEETS]: string[] | null;
  [MetaKey.PATIENT_DOB]: DateRangeValue | null;
  [MetaKey.PATIENT_FIRST_NAME]: string | null;
  [MetaKey.PATIENT_MIDDLE_NAME]: string | null;
  [MetaKey.PATIENT_LAST_NAME]: string | null;
  [MetaKey.PATIENT_ID]: string | null;
  [MetaKey.PATIENT_ACCESSION_NUMBER]: string | null;
}

interface GenericSearchCriterion<N extends Record<string, unknown> = any> {
  key: StudySearchCriterionKey | null;
  value: string | SerializedDateRange | null;
  node: N | null;
}

const isLabeledValue = (v: unknown): v is LabeledValue =>
  typeof v === 'object' && v != null && 'label' in v && 'value' in v;

export function mapSearchDataToCriteria<N extends { id: string }>(
  searchData: Partial<SearchData>,
  nodes?: readonly N[] | null,
) {
  const criteria: GenericSearchCriterion<N>[] = [];
  const NodesById = nodes
    ? Object.fromEntries(nodes.map((n) => [n.id, n]))
    : {};

  function pushCriteria(key: StudySearchCriterionKey, value: any) {
    if (Array.isArray(value)) {
      value.forEach((arrayValue) => {
        pushCriteria(key, arrayValue);
      });
      return;
    }

    if (isLabeledValue(value)) {
      value = value.value;
    }

    if (value != null) {
      criteria.push({ key, value, node: NodesById[value] ?? null });
    }
  }

  for (const [key, value] of Object.entries(searchData) as [
    MetaKey,
    SearchData[keyof SearchData],
  ][]) {
    switch (key) {
      case 'freeText':
      case 'archive':
      case 'organization':
      case 'worksheetTemplate':
      case 'allWorksheetTemplates':
      case 'examType':
      case 'tag':
      case 'reviewer':
      case 'capturedAt':
      case 'patientNameFirst':
      case 'patientNameMiddle':
      case 'patientNameLast':
      case 'patientMedicalRecordNumber':
      case 'accessionNumber':
      case 'patientBirthDate':
      case 'status': {
        pushCriteria(toSearchKey(key), value);
        break;
      }
      case 'author': {
        const authorKey = searchData.primary ? 'PRIMARY_AUTHOR' : 'AUTHOR';
        pushCriteria(authorKey, value);
        break;
      }
      default:
        break;
    }
  }
  return criteria;
}

export function mapStoredSearchToSearchData(searchRef: SearchDataKey) {
  const search = readInlineData(
    graphql`
      fragment Search_searchToData on StudySearchRequestInterface @inline {
        criteria {
          key
          value
          node {
            id
            type: __typename
            ... on UserProfile {
              name
            }
            ... on Organization {
              name
            }
            ... on Archive {
              name: label
            }
            ... on ExamType {
              name
            }
            ... on WorksheetTemplate {
              latestVersion {
                title
              }
            }
            ... on StudyTag {
              name
            }
          }
        }
      }
    `,
    searchRef,
  );

  const searchData: Partial<SearchData> = getSearchStateDefault();

  function push<K extends MetaKeyArrayValues>(key: K, newValue: any) {
    if (newValue == null) return;

    // the enum key values don't assign to the string values of the actual keys
    const value = (searchData as any)[key];
    (searchData as any)[key] =
      value != null ? ([] as any[]).concat(value, newValue) : [newValue];
  }
  search.criteria!.forEach((criterion) => {
    if (!criterion?.key) return;

    const { key, value: rawvalue, node } = criterion;

    const value = node?.id || rawvalue;

    switch (key) {
      case 'ARCHIVE':
      case 'ORGANIZATION':
      case 'WORKSHEET_TEMPLATE':
      case 'ALL_WORKSHEET_TEMPLATES':
      case 'EXAM_TYPE':
      case 'TAG':
      case 'STATUS': {
        push(toMetaKey(key), value);
        break;
      }
      case 'REVIEWER': {
        push(toMetaKey(key), { label: node?.name, value });
        break;
      }
      case 'PRIMARY_AUTHOR':
      case 'AUTHOR':
        searchData.author = [
          ...(searchData?.author ?? []),
          {
            label: node?.name,
            value,
          },
        ];
        searchData.primary = key === 'PRIMARY_AUTHOR';
        break;
      case 'CAPTURED_AT':
      case 'PATIENT_BIRTH_DATE': {
        const metaKey = toMetaKey(key);
        searchData[metaKey] = value && deserializeDateRange(value);
        break;
      }
      default: {
        const metaKey = toMetaKey(key);
        if (metaKey in searchData) searchData[metaKey] = value;
        break;
      }
    }
  });

  return searchData as SearchData;
}
