import Layout from '@4c/layout';
import CommentIcon from '@bfly/icons/CommentBubble';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import React from 'react';

interface Props {
  numComments: number;
  className?: string;
  iconColor: string;
}

export default function NumCommentsView({
  numComments,
  className,
  iconColor = 'default',
}: Props) {
  return (
    <Layout align="flex-end" pad className={className}>
      <CommentIcon
        css={css`
          margin-bottom: 2px;
          color: ${iconColor};
        `}
      />
      <Text variant="sm">{numComments}</Text>
    </Layout>
  );
}
