import VideoPlayer from './VideoPlayer';

interface Props {
  src: string;
  poster?: string;
  autoPlay?: boolean;
  fullscreenEnabled?: boolean;
  hideVideoControls?: boolean;
}

function StudyImageVideo({
  src,
  poster,
  hideVideoControls,
  autoPlay = true,
  fullscreenEnabled = true,
}: Props) {
  return (
    <VideoPlayer
      className="min-w-0 h-full"
      fullscreenEnabled={fullscreenEnabled}
      src={src}
      poster={poster}
      autoPlay={autoPlay}
      data-bni-id="StudyImageVideo"
      hideVideoControls={hideVideoControls}
      loop
      muted
    />
  );
}

export default StudyImageVideo;
