import CanceledIcon from '@bfly/icons/Canceled';
import FailureIcon from '@bfly/icons/Failure';
import SuccessIcon from '@bfly/icons/Success';
import Text from '@bfly/ui2/Text';
import { stylesheet } from 'astroturf';
import React from 'react';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import SyncingSpinner from 'components/SyncingSpinner';

import { StudySyncStatus_study$data as Study } from './__generated__/StudySyncStatus_study.graphql';

const messages = defineMessages({
  pacsAndEhr: {
    id: 'studySyncStatus.pacsAndEhr',
    defaultMessage: 'PACS,EHR',
  },
  pacs: {
    id: 'studySyncStatus.pacs',
    defaultMessage: 'PACS',
  },
  ehr: {
    id: 'studySyncStatus.ehr',
    defaultMessage: 'EHR',
  },
});

const styles = stylesheet`
  .icon {
    composes: mr-2 w-4 h-4 from global;
    vertical-align: top;
  }
  .failure {
    composes: text-danger from global;
    composes: icon;
  }
  .success {
    composes: icon;
    & :global(.foreground) {
      fill: theme('colors.grey[90]');
    }
  }
`;

export type StudySyncStatusType =
  | 'syncing'
  | 'failure'
  | 'cancelled'
  | 'success'
  | 'paused';

interface Props {
  study: Study | null;
}

function getSyncStatusText(
  pacsHasStatus: boolean,
  ehrHasStatus: boolean,
  studyStatus: StudySyncStatusType,
  intl: IntlShape,
): { text: string; studyStatus: StudySyncStatusType } | null {
  if (!pacsHasStatus && !ehrHasStatus) {
    return null;
  }

  if (pacsHasStatus && ehrHasStatus) {
    return {
      text: intl.formatMessage(messages.pacsAndEhr),
      studyStatus,
    };
  }

  if (pacsHasStatus) {
    return {
      text: intl.formatMessage(messages.pacs),
      studyStatus,
    };
  }

  return {
    text: intl.formatMessage(messages.ehr),
    studyStatus,
  };
}

export function getSyncStatus(
  pacsPushStatus: string,
  ehrOruStatus: string,
  pacsPushHasPausedJob: boolean,
  ehrOruHasPausedJob: boolean,
  intl: IntlShape,
): { text: string; studyStatus: StudySyncStatusType } | null {
  return (
    getSyncStatusText(
      pacsPushStatus === 'PENDING' || pacsPushStatus === 'RETRYING',
      ehrOruStatus === 'PENDING' || ehrOruStatus === 'RETRYING',
      'syncing',
      intl,
    ) ||
    getSyncStatusText(
      pacsPushStatus === 'FAILED',
      ehrOruStatus === 'FAILED',
      'failure',
      intl,
    ) ||
    getSyncStatusText(
      pacsPushStatus === 'CANCELED',
      ehrOruStatus === 'CANCELED',
      'cancelled',
      intl,
    ) ||
    getSyncStatusText(
      pacsPushStatus === 'COMPLETE',
      ehrOruStatus === 'COMPLETE',
      'success',
      intl,
    ) ||
    getSyncStatusText(
      pacsPushHasPausedJob!,
      ehrOruHasPausedJob!,
      'paused',
      intl,
    )
  );
}

function StudySyncStatus({ study }: Props) {
  const intl = useIntl();

  if (!study)
    return (
      <div className="w-full min-w-20 rounded bg-grey-85 animate-pulse p-2.5">
        <div className="h-1.5 bg-grey-55 rounded-sm" />
      </div>
    );

  const {
    pacsPushStatus,
    pacsPushHasPausedJob,
    ehrOruStatus,
    ehrOruHasPausedJob,
  } = study;

  const status = getSyncStatus(
    pacsPushStatus!,
    ehrOruStatus!,
    pacsPushHasPausedJob!,
    ehrOruHasPausedJob!,
    intl,
  );

  if (status === null) {
    return null;
  }

  const { studyStatus, text } = status;
  let icon: React.ReactNode;

  switch (studyStatus) {
    case 'syncing':
      icon = <SyncingSpinner className={styles.icon} />;
      break;
    case 'failure':
      icon = (
        <FailureIcon className={styles.failure} data-bni-id="failureIcon" />
      );
      break;
    case 'cancelled':
      icon = (
        <CanceledIcon className={styles.icon} data-bni-id="canceledIcon" />
      );
      break;
    case 'success':
      icon = (
        <SuccessIcon className={styles.success} data-bni-id="completeIcon" />
      );
      break;
    case 'paused':
      icon = <SyncingSpinner className={styles.icon} paused />;
      break;
    default:
  }

  return (
    <Text variant="sm">
      {icon}
      {text}
    </Text>
  );
}

export default createFragmentContainer(StudySyncStatus, {
  study: graphql`
    fragment StudySyncStatus_study on Study {
      pacsPushStatus
      pacsPushHasPausedJob
      ehrOruStatus
      ehrOruHasPausedJob
    }
  `,
});
