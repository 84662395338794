/**
 * @generated SignedSource<<74b21db1abe24841e3c5b90c898a500e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ecommerceExternalRedirect_OrganizationQuery$variables = {
  organizationSlug: string;
};
export type ecommerceExternalRedirect_OrganizationQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"EcommerceExternalRedirect_organization">;
  } | null;
};
export type ecommerceExternalRedirect_OrganizationQuery = {
  response: ecommerceExternalRedirect_OrganizationQuery$data;
  variables: ecommerceExternalRedirect_OrganizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ecommerceExternalRedirect_OrganizationQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EcommerceExternalRedirect_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ecommerceExternalRedirect_OrganizationQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasCompletedTrial",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab6bd307a840f56f6b41c4752fcb333d",
    "id": null,
    "metadata": {},
    "name": "ecommerceExternalRedirect_OrganizationQuery",
    "operationKind": "query",
    "text": "query ecommerceExternalRedirect_OrganizationQuery(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    ...EcommerceExternalRedirect_organization\n    id\n  }\n}\n\nfragment EcommerceExternalRedirect_organization on Organization {\n  slug\n  subscription {\n    hasCompletedTrial\n  }\n}\n"
  }
};
})();

(node as any).hash = "377137b5c59bc03c0939fe82dbb56ae9";

export default node;
