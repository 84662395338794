import Bookmark from '@bfly/icons/Bookmark';
import BookmarkOutline from '@bfly/icons/BookmarkOutline';
import TrashCan from '@bfly/icons/TrashCan';
import Form from '@bfly/ui2/Form';
import PreviewGrid from '@bfly/ui2/PreviewGrid';
import Text from '@bfly/ui2/Text';
import useDialog from '@bfly/ui2/useDialog';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import getNodes from '@bfly/utils/getNodes';
import { css } from 'astroturf';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import IconButton from 'components/IconButton';
import StudyCaptureSelectorImagePreviewItem from 'components/StudyCaptureSelectorImagePreviewItem';
import { useCanDeleteCaptures } from 'utils/StudyPermissions';

import { getCapturesUpdater } from '../utils/examPageFooter';
import { ExamGalleryPageDeleteMutation as DeleteMutation } from './__generated__/ExamGalleryPageDeleteMutation.graphql';
import { ExamGalleryPageUpdateIsImportantMutation as Mutation } from './__generated__/ExamGalleryPageUpdateIsImportantMutation.graphql';
import type { ExamGalleryPage_study$data as Study } from './__generated__/ExamGalleryPage_study.graphql';

interface Props {
  study: Study;
}

function ExamGalleryPage({ study }: Props) {
  const dialog = useDialog();
  const canDeleteCaptures = useCanDeleteCaptures(study);
  const [value, setValue] = useState({ imageIds: [] as string[] });
  const images = getNodes(study.imageConnection);

  const selectedImages = images.filter((img) =>
    value.imageIds.includes(img.id),
  );
  const allSelectedAreImportant = selectedImages.every(
    (img) => img.isImportant,
  );

  const [updateIsImportant] = useMutationWithError<Mutation>(graphql`
    mutation ExamGalleryPageUpdateIsImportantMutation(
      $input: UpdateIsImportantStudyImageInput!
    ) {
      updateIsImportantStudyImageOrError(input: $input) {
        ... on UpdateIsImportantStudyImagePayload {
          studyImage {
            isImportant
          }
        }
        ...RelayForm_error @relay(mask: false)
      }
    }
  `);
  const [deleteCaptures] = useMutationWithError<DeleteMutation>(
    graphql`
      mutation ExamGalleryPageDeleteMutation(
        $input: BulkDeleteCapturesInput!
      ) {
        bulkDeleteCapturesOrError(input: $input) {
          ...RelayForm_error @relay(mask: false)
          ... on BulkDeleteCapturesPayload {
            study {
              numImages
              imageConnection(first: 2147483647) {
                edges {
                  node {
                    handle
                  }
                }
              }
            }
          }
        }
      }
    `,
  );

  function handleUpdateImages(isImportant: boolean) {
    Promise.allSettled(
      selectedImages.map((img) =>
        updateIsImportant({
          input: {
            imageId: img.id!,
            isImportant,
          },
        }),
      ),
    );
  }

  async function handleDeleteImages() {
    if (selectedImages.length === study.numImages) {
      return;
    }

    const confirmed = await dialog.open(
      <FormattedMessage
        id="examGalleryPage.delete.body"
        defaultMessage="Are you sure? This cannot be undone."
      />,
      {
        title: (
          <FormattedMessage
            id="examGalleryPage.delete.title"
            defaultMessage="Delete {numCaptures} {numCaptures, plural, =1 {capture} other {captures}}?"
            values={{ numCaptures: selectedImages.length }}
          />
        ),
      },
    );

    if (confirmed) {
      await deleteCaptures({
        input: {
          imageIds: selectedImages.map((i) => i.id),
          studyId: study.id,
        },
        updater: getCapturesUpdater(value.imageIds, study.id),
      });

      setValue((prev) => ({ ...prev, imageIds: [] }));
    }
  }

  return (
    <Form
      value={value}
      onChange={setValue}
      className="flex flex-col pl-12 h-full"
    >
      <div
        className="h-full pt-12 pr-12 overflow-y-auto"
        data-bni-id="ExamGalleryGrid"
      >
        <PreviewGrid
          css={css`
            grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
            grid-gap: theme('spacing.8');
          `}
        >
          {images.map((image, index) => (
            <StudyCaptureSelectorImagePreviewItem
              key={image.handle!}
              image={image}
              index={index}
              shape="round"
            />
          ))}
        </PreviewGrid>
      </div>
      {!!value.imageIds?.length && (
        <div
          data-bni-id="ExamGalleryToolbar"
          className="bg-black border-grey-85 border-t flex-shrink-0 pr-12 pt-4 pb-[1.7rem] flex justify-end items-center
            sticky bottom-0 right-0"
        >
          <Text color="subtitle">
            <FormattedMessage
              id="examGalleryPage.numSelected"
              defaultMessage="{numSelected} of {total} selected"
              values={{
                numSelected: value.imageIds?.length,
                total: study.numImages!,
              }}
            />
          </Text>

          {allSelectedAreImportant ? (
            <IconButton
              data-bni-id="ExamGalleryImportantButton"
              icon={<BookmarkOutline className="h-4 stroke-2" />}
              onClick={() => handleUpdateImages(false)}
              title={
                <FormattedMessage
                  id="examGalleryPage.markUnimportant"
                  defaultMessage="Remove bookmark"
                />
              }
            />
          ) : (
            <IconButton
              data-bni-id="ExamGalleryImportantButton"
              icon={<Bookmark className="h-4 stroke-2" />}
              onClick={() => handleUpdateImages(true)}
              title={
                <FormattedMessage
                  id="examGalleryPage.markImportant"
                  defaultMessage="Bookmark captures"
                />
              }
            />
          )}
          {canDeleteCaptures && (
            <IconButton
              icon={TrashCan}
              onClick={handleDeleteImages}
              data-bni-id="ExamGalleryDeleteButton"
              disabled={selectedImages.length === study.numImages}
              title={
                <FormattedMessage
                  id="examGalleryPage.deleteCaptures"
                  defaultMessage="Delete captures"
                />
              }
            />
          )}
        </div>
      )}
    </Form>
  );
}

export default createFragmentContainer(ExamGalleryPage, {
  study: graphql`
    fragment ExamGalleryPage_study on Study {
      id
      imageConnection(first: 2147483647)
        @connection(key: "ExamImagePage_imageConnection") {
        edges {
          node {
            id
            handle
            isImportant
            ...StudyCaptureSelectorImagePreviewItem_image
          }
        }
      }
      numImages
      ...StudyPermissions_canDeleteCaptures
    }
  `,
});
