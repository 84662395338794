/**
 * @generated SignedSource<<15d236801b7b90ad87bef41c2886732d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhysicianInfo_study$data = {
  readonly dicomOperatorsName: string | null;
  readonly dicomReferringPhysician: string | null;
  readonly dicomRequestingPhysician: string | null;
  readonly " $fragmentType": "PhysicianInfo_study";
};
export type PhysicianInfo_study$key = {
  readonly " $data"?: PhysicianInfo_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"PhysicianInfo_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PhysicianInfo_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomOperatorsName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomReferringPhysician",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomRequestingPhysician",
      "storageKey": null
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "25c11f52e8a95fea84e5a16a3f71a4a7";

export default node;
