import { Redirect } from 'found';
import HttpError from 'found/HttpError';
import RedirectException from 'found/RedirectException';
import React from 'react';
import { graphql } from 'react-relay';

import AppPage from 'components/AppPage';
import BlankAppPage from 'components/BlankAppPage';
import EnterpriseLoginPage from 'components/EnterpriseLoginPage';
import Route, { RelayRouteRenderArgs } from 'components/Route';
import { routes } from 'routes/config';
import { getLastOrganizationSlug } from 'utils/EagerRedirectUtils';
import sessionStore from 'utils/sessionStore';
import someRouteHasProperty from 'utils/someRouteHasProperty';

import { SHOW_SIGN_IN_CONFIRMATION_STATE_KEY } from '../../components/AuthProvider';
import { account_AcceptInvitePage_Query as AcceptInvitePage_Query } from './__generated__/account_AcceptInvitePage_Query.graphql';
import { account_UserSignUpPage_Query as UserSignUpPage_Query } from './__generated__/account_UserSignUpPage_Query.graphql';
import AcceptInvitePage from './components/AcceptInvitePage';
import AccountCreatedPage from './components/AccountCreatedPage';
import EulaPage from './components/EulaPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import InactiveAccountPage from './components/InactiveAccountPage';
import InviteAcceptedPage from './components/InviteAcceptedPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import TermsAndConditionsPage from './components/TermsAndConditionsPage';
import UserSignUpPage from './components/UserSignUpPage';
import VerifyChangeEmailPage from './components/VerifyEmailChangePage';

function checkForInviteInfo({
  props,
}: RelayRouteRenderArgs<{ inviteInfo: any }>) {
  if (!props) return;

  const { inviteInfo } = props;
  if (!inviteInfo) throw new HttpError(404);
  sessionStore.set(SHOW_SIGN_IN_CONFIRMATION_STATE_KEY, false);
}

function prepareInviteToken(variables, { location: { query } }) {
  return {
    ...variables,
    inviteToken: query.token || query.invite_token,
  };
}

export default (
  <>
    <Route
      Component={AppPage}
      query={AppPage.query}
      renderFetched={({ Component, props, match }) => (
        <Component {...props} center={someRouteHasProperty(match, 'center')} />
      )}
    >
      <Route
        hideSearch
        path="settings"
        getComponent={() =>
          import(
            /* webpackChunkName: "account" */ './components/AccountSettingsPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query account_AccountSettingsPage_Query {
            viewer {
              ...AccountSettingsPage_viewer
            }
            specialties {
              ...AccountSettingsPage_specialties
            }
          }
        `}
      />
      <Route
        center
        mobileFriendly
        name="name_userSetup"
        path="user-setup"
        getComponent={() =>
          import(
            /* webpackChunkName: "account" */ './components/UserSetupPage'
          ).then((m) => m.default)
        }
        query={graphql`
          query account_UserSetupPage_Query {
            viewer {
              setupAt
              ...UserSetupPage_viewer
            }
            specialties {
              ...UserSetupPage_specialties
            }
          }
        `}
      />
      <Route
        center
        mobileFriendly
        hideSearch
        name="name_eula"
        path="eula"
        Component={EulaPage}
        query={graphql`
          query account_EulaPage_Query {
            viewer {
              ...EulaPage_viewer
            }
            latestEula {
              ...EulaPage_latestEula
            }
          }
        `}
      />
      <Route
        center
        mobileFriendly
        hideSearch
        name="name_TnC"
        path="tnc"
        Component={TermsAndConditionsPage}
        query={graphql`
          query account_TermsAndConditions_Query {
            viewer {
              ...TermsAndConditionsPage_viewer
            }
            latestTermsAndConditions {
              ...TermsAndConditionsPage_latestTermsAndConditions
            }
          }
        `}
      />
      <Route
        center
        path="inactive"
        Component={InactiveAccountPage}
        query={graphql`
          query account_InactiveAccountPage_Query {
            viewer {
              domain {
                __typename
              }
              memberships {
                id
              }
              ...InactiveAccountPage_viewer
            }
          }
        `}
        prerender={({ props }) => {
          if (props && props.viewer.memberships.length) {
            throw new HttpError(404);
          }
        }}
      />
      <Route
        mobileFriendly
        path="verify-email-change"
        Component={VerifyChangeEmailPage}
      />
    </Route>
    <Route Component={BlankAppPage}>
      <Route allowPublic path="referrals">
        <Redirect
          to={(match) => {
            const organizationSlug = getLastOrganizationSlug(match);

            if (organizationSlug) {
              return routes.referrals({ organizationSlug });
            }
            throw new HttpError(404);
          }}
        />
      </Route>
      <Route
        allowPublic
        mobileFriendly
        path="forgot-password"
        Component={ForgotPasswordPage}
        query={graphql`
          query account_RedirectIfAuthenticatedQuery {
            viewer {
              id
            }
          }
        `}
        prerender={({ props }) => {
          if (props && props.viewer) {
            throw new RedirectException(routes.rootRoute());
          }
        }}
      />
      <Route
        allowPublic
        mobileFriendly
        name="enterprise-login"
        path="enterprise-login"
        Component={EnterpriseLoginPage}
        query={graphql`
          query account_EnterpriseLoginPageQuery {
            viewer {
              id
            }
          }
        `}
        prerender={({ props, match }) => {
          const viewer = props?.viewer;
          /* When the Absorb LMS redirects back to cloud with an unauthenticated session,
          it needs to skip straight to the "choose your domain" prompt
          and then redirect back to the LMS. */
          const lmsForceLogin = match.location.query?.lms === 'forceLogin';
          sessionStore.set(SHOW_SIGN_IN_CONFIRMATION_STATE_KEY, false);

          if (lmsForceLogin) return;
          if (viewer) throw new RedirectException(routes.rootRoute());
        }}
      />

      <Route
        allowPublic
        mobileFriendly
        path="reset-password"
        Component={ResetPasswordPage}
        prerender={() => {
          sessionStore.set(SHOW_SIGN_IN_CONFIRMATION_STATE_KEY, false);
        }}
      />
      <Route<UserSignUpPage_Query>
        allowPublic
        mobileFriendly
        path="signup"
        Component={UserSignUpPage}
        query={graphql`
          query account_UserSignUpPage_Query($inviteToken: String!) {
            inviteInfo: userInviteInfoByToken(token: $inviteToken) {
              ...UserSignUpPage_inviteInfo
            }
          }
        `}
        prepareVariables={prepareInviteToken}
        prerender={checkForInviteInfo}
      />
      <Route<AcceptInvitePage_Query>
        allowPublic
        mobileFriendly
        name="name_acceptInvite"
        path="accept-invite"
        Component={AcceptInvitePage}
        query={graphql`
          query account_AcceptInvitePage_Query($inviteToken: String!) {
            inviteInfo: organizationInviteInfoByToken(token: $inviteToken) {
              ...AcceptInvitePage_inviteInfo
            }
            viewer {
              ...AcceptInvitePage_viewer
            }
          }
        `}
        prepareVariables={prepareInviteToken}
        prerender={checkForInviteInfo}
      />
    </Route>
    <Route
      mobileFriendly
      name="name_accountCreated"
      path="account-created"
      Component={AccountCreatedPage}
    />
    <Route
      mobileFriendly
      path=":organizationSlug/invite-accepted"
      Component={InviteAcceptedPage}
    />
  </>
);
