/**
 * @generated SignedSource<<91836a08b758e22bedaf16f7c2ff6018>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EhrOruJobStatus = "CANCELED" | "COMPLETE" | "FAILED" | "PENDING" | "RETRYING" | "%future added value";
export type StudyEhrOruStatusUpdatedSubscriptionInput = {
  clientSubscriptionId?: string | null;
  studyId: string;
};
export type StudyEndpointsModalContentEhrSubscription$variables = {
  input: StudyEhrOruStatusUpdatedSubscriptionInput;
};
export type StudyEndpointsModalContentEhrSubscription$data = {
  readonly studyEhrOruStatusUpdated: {
    readonly study: {
      readonly ehrOruJobs: ReadonlyArray<{
        readonly ehr: {
          readonly id: string;
        } | null;
        readonly status: EhrOruJobStatus | null;
        readonly " $fragmentSpreads": FragmentRefs<"EhrSyncStatus_job" | "SendStudyToEhrButton_job">;
      } | null> | null;
      readonly " $fragmentSpreads": FragmentRefs<"StudySyncStatus_study">;
    } | null;
  } | null;
};
export type StudyEndpointsModalContentEhrSubscription = {
  response: StudyEndpointsModalContentEhrSubscription$data;
  variables: StudyEndpointsModalContentEhrSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StudyEndpointsModalContentEhrSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyEhrOruStatusUpdatedSubscriptionPayload",
        "kind": "LinkedField",
        "name": "studyEhrOruStatusUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StudySyncStatus_study"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EhrOruJob",
                "kind": "LinkedField",
                "name": "ehrOruJobs",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EhrSyncStatus_job"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SendStudyToEhrButton_job"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Ehr",
                    "kind": "LinkedField",
                    "name": "ehr",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StudyEndpointsModalContentEhrSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyEhrOruStatusUpdatedSubscriptionPayload",
        "kind": "LinkedField",
        "name": "studyEhrOruStatusUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Study",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pacsPushStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pacsPushHasPausedJob",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ehrOruStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ehrOruHasPausedJob",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EhrOruJob",
                "kind": "LinkedField",
                "name": "ehrOruJobs",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Ehr",
                    "kind": "LinkedField",
                    "name": "ehr",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paused",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOutdated",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a1ab66bf08f44fb6a1588ad58d0fe5a",
    "id": null,
    "metadata": {},
    "name": "StudyEndpointsModalContentEhrSubscription",
    "operationKind": "subscription",
    "text": "subscription StudyEndpointsModalContentEhrSubscription(\n  $input: StudyEhrOruStatusUpdatedSubscriptionInput!\n) {\n  studyEhrOruStatusUpdated(input: $input) {\n    study {\n      ...StudySyncStatus_study\n      ehrOruJobs {\n        ...EhrSyncStatus_job\n        ...SendStudyToEhrButton_job\n        ehr {\n          id\n        }\n        status\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment EhrSyncStatus_job on EhrOruJob {\n  completedAt\n  ehr {\n    name\n    paused\n    id\n  }\n  status\n  isOutdated\n}\n\nfragment SendStudyToEhrButton_job on EhrOruJob {\n  id\n  status\n}\n\nfragment StudySyncStatus_study on Study {\n  pacsPushStatus\n  pacsPushHasPausedJob\n  ehrOruStatus\n  ehrOruHasPausedJob\n}\n"
  }
};
})();

(node as any).hash = "ab053b8925d648ef8d2d5d45d58d0778";

export default node;
