import Layout, { LayoutProps } from '@4c/layout';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from 'components/Avatar';
import AvatarCollage from 'components/AvatarCollage';
import PopoverList from 'components/PopoverList';
import RelatedUsersPopoverListItems from 'components/RelatedUsersPopoverListItems';

import { RelatedUserAvatars_displayUser$data as DisplayUser } from './__generated__/RelatedUserAvatars_displayUser.graphql';
import { RelatedUserAvatars_study$data as Study } from './__generated__/RelatedUserAvatars_study.graphql';
import { RelatedUserAvatars_users$data as Users } from './__generated__/RelatedUserAvatars_users.graphql';

interface Props extends LayoutProps {
  users: Users;
  className?: string;
  displayUser: DisplayUser | null;
  study: Study | null;
}

function RelatedUserAvatars({
  className,
  users,
  displayUser,
  study,
  ...props
}: Props) {
  return (
    <PopoverList
      show={users.length ? undefined : false}
      listItems={
        <RelatedUsersPopoverListItems
          users={[displayUser, ...users] as any}
          createdBy={study?.createdBy?.id}
          transcribedBy={study?.transcribedBy?.id}
        />
      }
    >
      {({ ref, ...triggerProps }) => (
        <Layout
          grow
          inline
          pad={2}
          align="center"
          {...triggerProps}
          className={className}
          {...props}
        >
          <Avatar width={20} userProfile={displayUser} className="align-top" />

          {!!users!.length && (
            <AvatarCollage
              ref={ref}
              width={20}
              className="align-top"
              userProfiles={users}
            />
          )}
          <Text color="headline" variant="sm-bold" truncate>
            <FormattedMessage
              id="relatedUsers.names"
              defaultMessage={`
                 {numOtherUsers, plural,
                  =0 {{displayName}}
                  other {{displayName}, +{numOtherUsers}}
                }
              `}
              values={{
                displayName: displayUser?.name ?? (
                  <FormattedMessage
                    id="relatedUserAvatars.unassigned"
                    defaultMessage="Unassigned"
                  />
                ),
                numOtherUsers: users!.length,
              }}
            />
          </Text>
        </Layout>
      )}
    </PopoverList>
  );
}

export default createFragmentContainer(RelatedUserAvatars, {
  study: graphql`
    fragment RelatedUserAvatars_study on Study {
      createdBy {
        id
      }
      transcribedBy {
        id
      }
    }
  `,
  users: graphql`
    fragment RelatedUserAvatars_users on UserProfile @relay(plural: true) {
      id
      name
      ...RelatedUsersPopoverListItems_users
      ...AvatarCollage_userProfiles
      ...Avatar_userProfile
    }
  `,
  displayUser: graphql`
    fragment RelatedUserAvatars_displayUser on UserProfile {
      id
      name
      ...Avatar_userProfile
      ...RelatedUsersPopoverListItems_users
    }
  `,
});
