import { LocationDescriptorObject } from 'found';
import localStore from 'store/dist/store.modern';

import type { Match } from 'components/Route';

import { StudyListFilter } from './StudyConstants';

function getLastOrganizationSlugKey({ context: { viewerLocalId } }: Match) {
  return `${viewerLocalId}:lastOrganizationSlug`;
}

function getLastArchiveHandleKey(
  { context: { viewerLocalId } }: Match,
  organizationSlug: string,
) {
  return `${viewerLocalId}:lastArchiveHandle/${organizationSlug}`;
}

export function getLastOrganizationSlug(match: Match) {
  return localStore.get(getLastOrganizationSlugKey(match));
}

export function getLastArchiveHandle(match: Match, organizationSlug: string) {
  return localStore.get(getLastArchiveHandleKey(match, organizationSlug));
}

export function clearLastOrganizationSlug(match: Match) {
  return localStore.remove(getLastOrganizationSlugKey(match));
}

export function clearLastArchiveHandle(
  match: Match,
  organizationSlug: string,
) {
  return localStore.remove(getLastArchiveHandleKey(match, organizationSlug));
}

export function setLastOrganization(match: Match) {
  const { organizationSlug } = match.params;

  localStore.set(getLastOrganizationSlugKey(match), organizationSlug);
}

export function setLastOrganizationAndArchive(match: Match) {
  const { organizationSlug, archiveHandle } = match.params;

  setLastOrganization(match);
  localStore.set(
    getLastArchiveHandleKey(match, organizationSlug),
    archiveHandle,
  );
}

export function getRedirectLocationKey(
  { context: { viewerLocalId } }: Match,
  organizationSlug: string,
) {
  return `${viewerLocalId}:redirectLocation/${organizationSlug}`;
}

export type StripedLocation = Pick<
  LocationDescriptorObject,
  'pathname' | 'query'
>;

export function setRedirectLocation(match: Match, link: StripedLocation) {
  const { organizationSlug } = match.params;
  setLastOrganization(match);
  localStore.set(getRedirectLocationKey(match, organizationSlug), link);
}

export function getRedirectLocation(
  match: Match,
  organizationSlug: string,
): StripedLocation {
  const redirectLocation = localStore.get(
    getRedirectLocationKey(match, organizationSlug),
  ) as StripedLocation;

  // This can be removed fairly quickly, it coerces an old enum
  // value into the current one
  if (redirectLocation)
    redirectLocation.pathname = redirectLocation.pathname.replace(
      'needs-qa-review',
      StudyListFilter.NEEDS_REVIEW,
    );

  return redirectLocation;
}

export function clearRedirectLocation(match: Match, organizationSlug: string) {
  localStore.remove(getRedirectLocationKey(match, organizationSlug));
}
