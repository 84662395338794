/**
 * @generated SignedSource<<c33e43a5621007b6c836da4bdecf9b3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DomainLoginPage_domainProfile$data = {
  readonly butterflyIdentityProvider: {
    readonly __typename: "ButterflyIdentityProvider";
  } | null;
  readonly federatedIdentityProvider: {
    readonly authorizationEndpointBaseUrl: string | null;
    readonly clientId: string | null;
  } | null;
  readonly handle: string | null;
  readonly name: string | null;
  readonly " $fragmentType": "DomainLoginPage_domainProfile";
};
export type DomainLoginPage_domainProfile$key = {
  readonly " $data"?: DomainLoginPage_domainProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"DomainLoginPage_domainProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainLoginPage_domainProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ButterflyIdentityProvider",
      "kind": "LinkedField",
      "name": "butterflyIdentityProvider",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FederatedIdentityProvider",
      "kind": "LinkedField",
      "name": "federatedIdentityProvider",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "authorizationEndpointBaseUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DomainProfile",
  "abstractKey": null
};

(node as any).hash = "1d94eb65e1c8b14196e306eb518a3c25";

export default node;
