/**
 * @generated SignedSource<<2067068527bcefc61c0a7799a8bd4db3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamImageComments_viewer$data = {
  readonly profile: {
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  } | null;
  readonly " $fragmentType": "ExamImageComments_viewer";
};
export type ExamImageComments_viewer$key = {
  readonly " $data"?: ExamImageComments_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamImageComments_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamImageComments_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_userProfile"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "567637bd76bd4dfa7c6e1a66eb95d806";

export default node;
