/**
 * @generated SignedSource<<bd0a349abe4866451c66ba6c66ade64b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSubscriptionInfo_organization$data = {
  readonly subscription: {
    readonly canAccessProFeatures: boolean | null;
    readonly isInGracePeriod: boolean | null;
    readonly planType: string | null;
  } | null;
  readonly " $fragmentType": "useSubscriptionInfo_organization";
};
export type useSubscriptionInfo_organization$key = {
  readonly " $data"?: useSubscriptionInfo_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionInfo_organization">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSubscriptionInfo_organization"
};

(node as any).hash = "6c8b573f462ca128eb66760b0c2bbeef";

export default node;
