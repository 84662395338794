/**
 * @generated SignedSource<<373bbc0d4fb186800c465efe8d1f7df9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type organizations_OrganizationSettingsContent_Query$variables = {
  organizationSlug: string;
};
export type organizations_OrganizationSettingsContent_Query$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsContent_organization">;
  } | null;
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsContent_tenant">;
  } | null;
};
export type organizations_OrganizationSettingsContent_Query = {
  response: organizations_OrganizationSettingsContent_Query$data;
  variables: organizations_OrganizationSettingsContent_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "organizations_OrganizationSettingsContent_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsContent_organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsContent_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "organizations_OrganizationSettingsContent_Query",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkflowSettings",
            "kind": "LinkedField",
            "name": "workflowSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxCineLength",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "numNoExamTypes",
            "args": [
              {
                "kind": "Literal",
                "name": "search",
                "value": {
                  "examType": "@@NO_EXAM_TYPE"
                }
              }
            ],
            "kind": "ScalarField",
            "name": "numStudies",
            "storageKey": "numStudies(search:{\"examType\":\"@@NO_EXAM_TYPE\"})"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "studyBarcodeField",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sharedBundleLifetimeDays",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5175fe36b2fb1b4f1e3a0780f8ade85a",
    "id": null,
    "metadata": {},
    "name": "organizations_OrganizationSettingsContent_Query",
    "operationKind": "query",
    "text": "query organizations_OrganizationSettingsContent_Query(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    ...OrganizationSettingsContent_organization\n    id\n  }\n  tenant(slug: $organizationSlug) {\n    __typename\n    ...OrganizationSettingsContent_tenant\n    id\n  }\n}\n\nfragment ExamTypesMissingBanner_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Organization {\n    slug\n  }\n}\n\nfragment OrganizationSettingsContent_organization on Organization {\n  id\n  name\n  slug\n  workflowSettings {\n    maxCineLength\n  }\n  numNoExamTypes: numStudies(search: {examType: \"@@NO_EXAM_TYPE\"})\n  ...SetOrganizationStudyBarcodeFieldForm_organization\n  ...SetOrganizationSharedBundleExpirationSettingsForm_organization\n}\n\nfragment OrganizationSettingsContent_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ...ExamTypesMissingBanner_tenant\n}\n\nfragment SetOrganizationSharedBundleExpirationSettingsForm_organization on Organization {\n  id\n  sharedBundleLifetimeDays\n}\n\nfragment SetOrganizationStudyBarcodeFieldForm_organization on Organization {\n  id\n  studyBarcodeField\n}\n"
  }
};
})();

(node as any).hash = "bc4a5ed2424c8b4e2912eded5022555a";

export default node;
