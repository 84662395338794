import Layout from '@4c/layout';
import ShareCircle from '@bfly/icons/ShareCircle';
import Button from '@bfly/ui2/Button';
import Heading from '@bfly/ui2/Heading';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useModalState from 'hooks/useModalState';
import messages from 'messages/actions';

import ShareEducationContentModal from './ShareEducationContentModal';
import { EducationContentInfo_educationContent$data as EducationContent } from './__generated__/EducationContentInfo_educationContent.graphql';
import useSaveContentToggle from './useSaveContentToggle';

interface Props {
  educationContent: EducationContent;
}

function EducationContentInfo({ educationContent }: Props) {
  const intl = useIntl();
  const [showShareModal, modalProps] = useModalState();
  const { message, mutate, loading, showToggle } = useSaveContentToggle(
    educationContent,
    { hideMessage: true },
  );

  return (
    <>
      <ShareEducationContentModal
        educationContent={educationContent}
        {...modalProps}
      />
      <Layout direction="column" pad>
        <Heading>{educationContent.name}</Heading>

        <div className="text-white">
          <div>{educationContent!.video!.authoredBy!.name}</div>

          <FormattedMessage
            id="educationContent.createdAt"
            defaultMessage="Uploaded on {date}"
            values={{
              date: <FormattedDate value={educationContent.createdAt!} />,
            }}
          />
        </div>

        <p className="pt-3 text-white">{educationContent.description}</p>

        <Text
          color="subtitle"
          className="mb-3"
          data-bni-id="EducationVideoInfoDisclaimer"
        >
          <FormattedMessage
            id="educationContent.disclaimer"
            defaultMessage="Disclaimer: These videos, including all texts and graphics, are for informational purposes only and are intended to show the potential uses of the Butterfly iQ. They are not a substitute for clinical training or experience. Clinicians are solely responsible for patient care and for exercising their independent clinical judgement at all times. Not all presets, imaging modes and features are available everywhere. Check for availability in your country.  For healthcare professionals only."
          />
        </Text>

        <Layout className="first:ml-2.5">
          <Tooltip.Trigger
            placement="top"
            id={`edu-share-action-${educationContent.id}`}
            tooltip={<FormattedMessage {...messages.share} />}
          >
            <Button
              variant="text-secondary"
              aria-label={intl.formatMessage(messages.share)}
              onClick={showShareModal}
            >
              <ShareCircle className="w-6 h-6 bg-grey-55 rounded-full" />
            </Button>
          </Tooltip.Trigger>
          {showToggle && (
            <Tooltip.Trigger
              placement="top"
              id={`edu-save-action-${educationContent.id}`}
              tooltip={<FormattedMessage {...messages.save} />}
            >
              <Button
                variant="text-secondary"
                onClick={mutate}
                disabled={loading}
                busy={loading}
              >
                {message}
              </Button>
            </Tooltip.Trigger>
          )}
        </Layout>
      </Layout>
    </>
  );
}

export default createFragmentContainer(EducationContentInfo, {
  educationContent: graphql`
    fragment EducationContentInfo_educationContent on EducationContent {
      id
      name
      description
      createdAt
      video {
        authoredBy {
          name
        }
      }
      ...useSaveContentToggle_educationContent
      ...ShareEducationContentModal_educationContent
    }
  `,
});
