import Dropdown from '@bfly/ui2/Dropdown';
import MeatballDropdownButton from '@bfly/ui2/MeatballDropdownButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import educationMessages from '../messages/educationMessages';
import { EducationContentTileMobileActions_educationContent$data as EducationContent } from './__generated__/EducationContentTileMobileActions_educationContent.graphql';
import useSaveContentToggle from './useSaveContentToggle';

interface Props {
  onShare: () => void;
  educationContent: EducationContent;
}

function EducationContentTileMobileActions({
  educationContent,
  onShare,
}: Props) {
  const { message, loading, mutate, showToggle } = useSaveContentToggle(
    educationContent,
    { hideIcon: true },
  );

  return (
    <div className="ml-4 pointer-events-auto">
      <MeatballDropdownButton id={educationContent.id}>
        {educationContent.contentType === 'VIDEO' && (
          <Dropdown.Item onClick={onShare}>
            <FormattedMessage {...educationMessages.share} />
          </Dropdown.Item>
        )}
        {showToggle && (
          <Dropdown.Item
            className="w-full justify-start"
            disabled={loading}
            onClick={mutate}
          >
            {message}
          </Dropdown.Item>
        )}
      </MeatballDropdownButton>
    </div>
  );
}

export default createFragmentContainer(EducationContentTileMobileActions, {
  educationContent: graphql`
    fragment EducationContentTileMobileActions_educationContent on EducationContent {
      id
      contentType
      ...useSaveContentToggle_educationContent
    }
  `,
});
