import { Param, RouteFactory } from '@4c/spa-routing';
import { type ConcreteRequest, getRequest } from 'relay-runtime';
import { Optional } from 'utility-types';

import type { Match } from 'components/Route';

export function getRouteRequest(
  match: Match,
): ConcreteRequest | undefined | null {
  const { route } = match as any;
  const query = route.getQuery ? route.getQuery(match) : route.query;
  return query && getRequest(query);
}

export function getNullDataProps(match: Match) {
  const request = getRouteRequest(match);
  const missing: Record<string, null> = {};

  // loop through the relay query metadata to get the top level query fields
  // that will become props, and default them to null to shut relay up about missing data
  if (request) {
    const selections = [...request.operation.selections];
    for (const selection of selections) {
      if (selection.kind === 'LinkedField' && 'name' in selection) {
        missing[selection.alias || selection.name] = null;
      }
      // top level fields may be nested under a condition (@include directive)
      // Make a reasonanble attempt to handle the case
      else if (selection.kind === 'Condition' && 'condition' in selection) {
        selections.push(...selection.selections);
      }
    }
  }
  return missing;
}
/**
 * Takes a set of Route url factories and makes a specific param optional
 */
export type MakeParamOptional<
  TRoutes extends Record<string, RouteFactory<any>>,
  TParam extends Param<any, any>,
> = {
  [Key in keyof TRoutes]: Parameters<TRoutes[Key]>[0] extends TParam
    ? // check if the param is the _only_ param
      keyof Omit<Parameters<TRoutes[Key]>[0], keyof TParam> extends never
      ? // in which case make the whole parameter optional
        (params?: TParam) => string
      : // otherwise make the param key optional
        (params: Optional<Parameters<TRoutes[Key]>[0], keyof TParam>) => string
    : // If the route doesn't contain this param leave it alone
      TRoutes[Key];
};
