import { graphql, readInlineData } from 'react-relay';

import { formatDomainUserName_domainUser$key as DomainUserKey } from './__generated__/formatDomainUserName_domainUser.graphql';

export default function formatDomainUserName(domainUserRef: DomainUserKey) {
  const domainUser = readInlineData(
    graphql`
      fragment formatDomainUserName_domainUser on DomainUser @inline {
        integrationDisplayNameFirst
        integrationDisplayNameMiddle
        integrationDisplayNameLast
        profile {
          name
        }
      }
    `,
    domainUserRef,
  );

  return (
    [
      domainUser.integrationDisplayNameFirst,
      domainUser.integrationDisplayNameMiddle,
      domainUser.integrationDisplayNameLast,
    ]
      .filter(Boolean)
      .join(' ') || domainUser.profile!.name
  );
}
