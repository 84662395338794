/**
 * @generated SignedSource<<4899f68c4530ffc65a9fd96be8b69416>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamAuthorsField_users$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile" | "RelatedUsersPopoverListItems_users">;
  readonly " $fragmentType": "ExamAuthorsField_users";
}>;
export type ExamAuthorsField_users$key = ReadonlyArray<{
  readonly " $data"?: ExamAuthorsField_users$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamAuthorsField_users">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExamAuthorsField_users",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_userProfile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RelatedUsersPopoverListItems_users"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "527e16cd046f59f1ce0477d796ec6b90";

export default node;
