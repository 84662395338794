export default function downloadFile(
  url: string,
  filename?: string,
): Promise<void> {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = filename || '';
  anchor.style.display = 'none';

  // Async to allow for successive downloads in a row
  return new Promise((resolve) => {
    setTimeout(() => {
      anchor.click();
      resolve();
    }, 0);
  });
}
