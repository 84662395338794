import Layout from '@4c/layout';
import PublicArchiveIcon from '@bfly/icons/Folder';
import PrivateArchiveIcon from '@bfly/icons/FolderPrivate';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { ArchivePrivacyDescription_organization$data as Organization } from './__generated__/ArchivePrivacyDescription_organization.graphql';

interface Props {
  organization: Organization;
  isPrivate: boolean;
}

function ArchivePrivacyDescription({ organization, isPrivate }: Props) {
  return (
    <Layout grow align="center">
      <Layout className="pr-3 flex-none text-headline">
        {isPrivate ? <PrivateArchiveIcon /> : <PublicArchiveIcon />}
      </Layout>
      <Layout direction="column" grow>
        <Text color="headline">
          {isPrivate ? (
            <FormattedMessage
              id="archiveSettings.privateArchive.title"
              defaultMessage="Private archive"
            />
          ) : (
            <FormattedMessage
              id="archiveSettings.publicArchive.title"
              defaultMessage="Public archive"
            />
          )}
        </Text>
        <Text color="subtitle" variant="sm">
          {isPrivate ? (
            <FormattedMessage
              id="archiveSettings.privateArchive.description"
              defaultMessage="Only specific invited members can access this archive."
            />
          ) : (
            <FormattedMessage
              id="archiveSettings.publicArchive.description"
              defaultMessage="All members in {organizationName} can access this archive."
              values={{ organizationName: organization.name }}
            />
          )}
        </Text>
      </Layout>
    </Layout>
  );
}

export default createFragmentContainer(ArchivePrivacyDescription, {
  organization: graphql`
    fragment ArchivePrivacyDescription_organization on Organization {
      name
    }
  `,
});
