import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import DateRangePicker, {
  DateRangePickerProps,
} from '@bfly/ui2/DateRangePicker';
import Form from '@bfly/ui2/Form';
import FormCheck from '@bfly/ui2/FormCheck';
import { getSharedManager } from '@bfly/ui2/Modal';
import Multiselect from '@bfly/ui2/Multiselect';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import { ConnectionNodeType } from '@bfly/utils/types';
import Modal from '@restart/ui/Modal';
import { css } from 'astroturf';
import clsx from 'clsx';
import every from 'lodash/every';
import { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ArchiveSelectButton from 'components/ArchiveSelectButton';
import ExamTypeSelectButton from 'components/ExamTypeSelectButton';
import { useVariation } from 'components/LaunchDarklyContext';
import OrganizationSelectButton from 'components/OrganizationSelectButton';
import StudyStatusSelectButton from 'components/StudyStatusSelectButton';
import TenantMembershipAutoComplete, {
  TenantMembershipOption,
} from 'components/TenantMembershipAutoComplete';
import actionMessages from 'messages/actions';
import dateRangePickerMessages from 'messages/dateRangePicker';
import searchData from 'schemas/searchData';
import {
  AuthorType,
  SearchMetaKey as MetaKey,
  SearchData,
  UNASSIGNED_AUTHOR,
  messages as searchMessages,
} from 'utils/Search';
import mapFromValue from 'utils/mapFromValue';
import removeKeysDeep from 'utils/removeKeysDeep';

import { GlobalStudySearchAdvanced_tenant$data as Tenant } from './__generated__/GlobalStudySearchAdvanced_tenant.graphql';

const MODAL_OFFSET_TOP = 116;

const messages = defineMessages({
  done: {
    id: 'SearchAdvanced.done',
    defaultMessage: 'Done',
  },
  patientInformation: {
    id: 'SearchAdvanced.patientInformation',
    defaultMessage: 'Patient Information',
  },
  studyInformation: {
    id: 'SearchAdvanced.studyInformation',
    defaultMessage: 'Study Information',
  },
  lastName: {
    id: 'SearchAdvanced.LastName',
    defaultMessage: 'Last name',
  },
  patientLastName: {
    id: 'SearchAdvanced.PatientLastName',
    defaultMessage: 'Patient last name',
  },
  firstName: {
    id: 'SearchAdvanced.FirstName',
    defaultMessage: 'First name',
  },
  patientFirstName: {
    id: 'SearchAdvanced.PatientFirstName',
    defaultMessage: 'Patient first name',
  },
  middleName: {
    id: 'SearchAdvanced.MiddleName',
    defaultMessage: 'Middle name',
  },
  patientMiddleName: {
    id: 'SearchAdvanced.PatientMiddleName',
    defaultMessage: 'Patient middle name',
  },
  DOB: {
    id: 'SearchAdvanced.DOB',
    defaultMessage: 'DOB',
  },
  patientID: {
    id: 'SearchAdvanced.PatientID',
    defaultMessage: 'Patient ID',
  },
  MRN: {
    id: 'SearchAdvanced.MRN',
    defaultMessage: 'MRN',
  },
  accessionNumber: {
    id: 'SearchAdvanced.AccessionNumber',
    defaultMessage: 'Accession Number',
  },
  studyAuthors: {
    id: 'SearchAdvanced.StudyAuthors',
    defaultMessage: 'Study Authors',
  },
  typeToSearchAuthor: {
    id: 'SearchAdvanced.TypeToSearchAuthor',
    defaultMessage: 'Type to search author',
  },
  captureDate: {
    id: 'SearchAdvanced.CaptureDate',
    defaultMessage: 'Capture Date',
  },
  reviewedBy: {
    id: 'SearchAdvanced.QAReviewedBy',
    defaultMessage: 'QA Reviewed by',
  },
  worksheet: {
    id: 'SearchAdvanced.Worksheet',
    defaultMessage: 'Worksheets',
  },
  tags: {
    id: 'SearchAdvanced.Tags',
    defaultMessage: 'Tags',
  },
  allTags: {
    id: 'SearchAdvanced.allTags',
    defaultMessage: 'All tags',
  },
  typeToSearchReviewer: {
    id: 'SearchAdvanced.typeToSearchReviewer',
    defaultMessage: 'Type to search reviewer',
  },
  allWorkSheets: {
    id: 'SearchAdvanced.allWorkSheets',
    defaultMessage: 'All Worksheets',
  },
  includeAllWorksheets: {
    id: 'SearchAdvanced.includeAllWorksheets',
    defaultMessage: 'Must include all',
  },
  [AuthorType.PRIMARY_ONLY]: {
    id: 'SearchAdvanced.primaryOnly',
    defaultMessage: 'Primary only',
  },
  [AuthorType.UNASSIGNED_AUTHORS]: {
    id: 'SearchAdvanced.unassignedAuthors',
    defaultMessage: 'Unassigned primary authors',
  },
});

function SingleDateOrDateRangePicker({
  value,
  ...props
}: DateRangePickerProps) {
  return (
    <DateRangePicker
      {...props}
      value={value}
      showRangeMessage={dateRangePickerMessages.customRangeMessage}
      customOptionMessage={dateRangePickerMessages.customOptionMessage}
      datePlaceholder={dateRangePickerMessages.customDatePlaceholder}
      hideRangeMessage={dateRangePickerMessages.customHideRangeMessage}
      onToggleEndDate={(nextShowEndDate) => {
        if (!nextShowEndDate && !DateRangePicker.isValidDayValue(value)) {
          props.onChange({
            ...value,
            endDate: value.startDate ? new Date(value.startDate) : null,
          });
        }
      }}
      onChange={(nextValue) => {
        if (
          !DateRangePicker.isValidDayValue(nextValue) &&
          !nextValue.endDate &&
          nextValue.startDate
        ) {
          nextValue = { ...nextValue, endDate: new Date(nextValue.startDate) };
        }

        props.onChange(nextValue);
      }}
    />
  );
}

function PatientInformation() {
  return (
    <Layout direction="column" className="w-6/12 py-4 pr-6 pl-12">
      <Text variant="body-bold" className="mb-4">
        <FormattedMessage {...messages.patientInformation} />
      </Text>
      <Form.FieldGroup
        horizontal
        name={MetaKey.PATIENT_FIRST_NAME}
        label={messages.firstName}
        placeholder={messages.patientFirstName}
      />
      <Form.FieldGroup
        horizontal
        name={MetaKey.PATIENT_MIDDLE_NAME}
        label={messages.middleName}
        placeholder={messages.patientMiddleName}
      />
      <Form.FieldGroup
        horizontal
        name={MetaKey.PATIENT_LAST_NAME}
        label={messages.lastName}
        placeholder={messages.patientLastName}
      />
      <Form.FieldGroup
        horizontal
        label={messages.DOB}
        name={MetaKey.PATIENT_DOB}
      >
        {(props) => (
          <SingleDateOrDateRangePicker {...props} horizontal presets={false} />
        )}
      </Form.FieldGroup>
      <Form.FieldGroup
        horizontal
        name={MetaKey.PATIENT_ID}
        label={messages.patientID}
        placeholder={messages.MRN}
      />
      <Form.FieldGroup
        horizontal
        name={MetaKey.PATIENT_ACCESSION_NUMBER}
        label={messages.accessionNumber}
        placeholder={messages.accessionNumber}
      />
    </Layout>
  );
}

interface AuthorValue {
  authors: TenantMembershipOption[];
  primary: boolean;
}

function AuthorFilter({
  value,
  onChange,
  tenant,
}: {
  value: AuthorValue | null;
  onChange: (nextValue: AuthorValue) => void;
  tenant: Tenant;
}) {
  const { authors = [], primary = false } = value || {};

  const includesUnassigned = !!authors.find(
    (itm) => itm.value === UNASSIGNED_AUTHOR,
  );

  const authorsWithoutUnassigned = includesUnassigned
    ? authors.filter((p) => p.value !== UNASSIGNED_AUTHOR)
    : authors;

  return (
    <Form.Group horizontal label={messages.studyAuthors}>
      <TenantMembershipAutoComplete
        multiple
        tenant={tenant}
        value={authorsWithoutUnassigned}
        data-bni-id="GSA:Authors"
        className="mb-2"
        placeholder={messages.typeToSearchAuthor}
        onChange={(nextAuthors) => {
          if (includesUnassigned) {
            nextAuthors.push({
              label: UNASSIGNED_AUTHOR,
              value: UNASSIGNED_AUTHOR,
            });
          }
          onChange({ authors: nextAuthors, primary });
        }}
      />

      <Layout pad={3}>
        <FormCheck
          data-bni-id="GSA:PrimaryAuthorsCheckbox"
          onChange={() => onChange({ authors, primary: !primary })}
          checked={primary}
        >
          {messages[AuthorType.PRIMARY_ONLY] && (
            <FormattedMessage {...messages[AuthorType.PRIMARY_ONLY]} />
          )}
        </FormCheck>
        <FormCheck
          data-bni-id="GSA:UnassignedPrimaryAuthorCheckbox"
          checked={includesUnassigned}
          onChange={(e) => {
            onChange({
              authors: e.currentTarget.checked
                ? [
                    ...authorsWithoutUnassigned,
                    { label: UNASSIGNED_AUTHOR, value: UNASSIGNED_AUTHOR },
                  ]
                : authorsWithoutUnassigned,
              primary,
            });
          }}
        >
          {messages[AuthorType.UNASSIGNED_AUTHORS] && (
            <FormattedMessage {...messages[AuthorType.UNASSIGNED_AUTHORS]} />
          )}
        </FormCheck>
      </Layout>
    </Form.Group>
  );
}

interface WorksheetValue {
  [MetaKey.WORKSHEET]: SearchData[MetaKey.WORKSHEET];
  [MetaKey.ALL_WORKSHEETS]: SearchData[MetaKey.ALL_WORKSHEETS];
}

type WorksheetOptions = ConnectionNodeType<
  Tenant,
  'worksheetTemplateConnection'
>;

function WorksheetsFilter({
  value,
  onChange,
  tenant,
}: {
  value: WorksheetValue | null;
  onChange: (nextValue: WorksheetValue) => void;
  tenant: Tenant;
}) {
  const { worksheetTemplate, allWorksheetTemplates } = value || {};
  const [matchAll, setMatchAll] = useState(!!allWorksheetTemplates);

  useEffect(() => {
    setMatchAll(!!allWorksheetTemplates);
  }, [worksheetTemplate, allWorksheetTemplates]);

  return (
    <>
      <Multiselect<WorksheetOptions>
        className="mb-2"
        filter="contains"
        value={allWorksheetTemplates ?? worksheetTemplate ?? []}
        textField={(item: WorksheetOptions) => item.latestVersion!.title!}
        dataKey="id"
        data={getNodes(tenant.worksheetTemplateConnection)}
        onChange={(nextValue) => {
          const worksheetTemplateIds = nextValue.map((ws) => ws.id);

          onChange(
            matchAll
              ? {
                  allWorksheetTemplates: worksheetTemplateIds,
                  worksheetTemplate: null,
                }
              : {
                  worksheetTemplate: worksheetTemplateIds,
                  allWorksheetTemplates: null,
                },
          );
        }}
        placeholder={messages.allWorkSheets}
        data-bni-id="GSA:Worksheets"
      />
      <FormCheck
        data-bni-id="GSA:WorksheetsIncludeAll"
        onChange={() => {
          onChange(
            !matchAll
              ? {
                  allWorksheetTemplates: worksheetTemplate ?? null,
                  worksheetTemplate: null,
                }
              : {
                  worksheetTemplate: allWorksheetTemplates ?? null,
                  allWorksheetTemplates: null,
                },
          );

          setMatchAll(!matchAll);
        }}
        checked={matchAll}
      >
        <FormattedMessage {...messages.includeAllWorksheets} />
      </FormCheck>
    </>
  );
}

function StudyInformation({ tenant }: { tenant: Tenant }) {
  const { formatMessage } = useIntl();
  const canUseWorksheets = useVariation('worksheets');
  const canUseWorkSheetsReview = useVariation('worksheets-review');
  const canUseTags = useVariation('study-tags');

  return (
    <Layout
      direction="column"
      className="w-6/12 py-4 pr-12 pl-6 border-l border-divider"
    >
      <Text variant="body-bold" className="mb-4">
        <FormattedMessage {...messages.studyInformation} />
      </Text>
      <Form.Field
        name="author"
        as={AuthorFilter}
        tenant={tenant}
        validates={['author', 'primary']}
        mapToValue={(data: SearchData) => ({
          authors: data.author || [],
          primary: data.primary,
        })}
        mapFromValue={{
          author: (d: AuthorValue) => d.authors,
          primary: (d: AuthorValue) => d.primary,
        }}
      />
      <Form.FieldGroup
        horizontal
        label={messages.captureDate}
        name={MetaKey.CAPTURE_DATE}
      >
        {(props) => <SingleDateOrDateRangePicker {...props} horizontal />}
      </Form.FieldGroup>
      {canUseWorkSheetsReview && (
        <Form.FieldGroup
          horizontal
          label={messages.reviewedBy}
          name={MetaKey.REVIEWED_BY}
        >
          {(props) => (
            <TenantMembershipAutoComplete
              {...props}
              multiple
              tenant={tenant}
              value={props.value || []}
              placeholder={messages.typeToSearchReviewer}
              data-bni-id="GSA:QAReviewedBy"
            />
          )}
        </Form.FieldGroup>
      )}
      {canUseWorksheets && (
        <Form.Group horizontal label={messages.worksheet}>
          <Form.Field
            name={MetaKey.WORKSHEET}
            as={WorksheetsFilter}
            tenant={tenant}
            mapToValue={(data: SearchData) => ({
              worksheetTemplate: data.worksheetTemplate,
              allWorksheetTemplates: data.allWorksheetTemplates,
            })}
            mapFromValue={{
              [MetaKey.WORKSHEET]: (value: WorksheetValue) =>
                value.worksheetTemplate,
              [MetaKey.ALL_WORKSHEETS]: (value: WorksheetValue) =>
                value.allWorksheetTemplates,
            }}
          />
        </Form.Group>
      )}
      {canUseTags && (
        <Form.FieldGroup horizontal label={messages.tags} name={MetaKey.TAG}>
          {(props) => (
            <Multiselect
              {...props}
              filter="contains"
              value={props.value || []}
              textField="name"
              dataKey="id"
              data={getNodes(tenant.studyTagConnection)}
              onChange={mapFromValue('id', props)}
              placeholder={formatMessage(messages.allTags)}
              data-bni-id="GSA:Tags"
            />
          )}
        </Form.FieldGroup>
      )}
    </Layout>
  );
}

interface Props {
  active: boolean;
  tenant: Tenant;
  values: SearchData;
  onChange: (values: SearchData) => void;
  onSave: (values: SearchData) => void;
}

// eslint-disable-next-line react/function-component-definition
function GlobalStudySearchAdvanced({
  active,
  tenant,
  values: propValues,
  onChange,
  onSave,
}: Props) {
  const canUseDraftStudies = useVariation('draft-studies');

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState(propValues);

  useEffect(() => setFormValue(propValues), [propValues]);

  return (
    <Form
      variant="secondary"
      value={formValue}
      onChange={setFormValue}
      schema={searchData}
      onSubmit={(newValue) => {
        removeKeysDeep((value: SearchData[keyof SearchData]) => {
          // truthy check non-booleans
          if (typeof value !== 'boolean' && !value) return true;
          // remove empty arrays
          if (Array.isArray(value)) return value.length === 0;
          // remove empty objects (that are not dates)
          if (typeof value === 'object' && !(value instanceof Date))
            return every(
              value,
              (m) => m === null || m === undefined || m === '',
            );
          return false;
        }, newValue);
        onChange(newValue!);
        setShow(false);
      }}
    >
      <Button
        variant={null}
        onClick={() => setShow(!show)}
        data-bni-id="AdvancedSearchButton"
        className={clsx(
          'bg-contrast bg-opacity-10 border px-2',
          'hover:text-opacity-60 active:text-opacity-50 hover:filter-none active:filter-none',
          show && 'border-blue-40',
          active || show ? 'text-primary' : 'text-contrast text-opacity-[.75]',
        )}
      >
        <FormattedMessage
          id="globalStudySearchAdvanced.advancedSearch"
          defaultMessage="Advanced search"
        />
      </Button>

      <Modal
        show={show}
        backdrop="static"
        onHide={() => setShow(false)}
        manager={getSharedManager()}
        renderBackdrop={() => (
          <Layout className="fixed z-modal inset-0 bg-black bg-opacity-70" />
        )}
        className="fixed z-modal mt-36 inset-x-0  focus:outline-none"
        css={css`
          top: ${MODAL_OFFSET_TOP}px;
        `}
      >
        <Layout direction="column" className="bg-grey-90 text-white">
          <Layout
            pad={1}
            className="py-4 px-12 border-b border-divider items-stretch"
          >
            {tenant.type === 'Domain' && (
              <div className="w-3/12">
                <Form.Field name={MetaKey.ORGANIZATION}>
                  {(fieldProps) => (
                    <OrganizationSelectButton
                      {...fieldProps}
                      hideSelectAll
                      onChange={mapFromValue('id', fieldProps)}
                      size="lg"
                    />
                  )}
                </Form.Field>
              </div>
            )}
            <div className="w-3/12">
              <Form.Field name={MetaKey.ARCHIVE}>
                {(fieldProps) => (
                  <ArchiveSelectButton
                    hideSelectAll
                    tenant={tenant}
                    {...fieldProps}
                    onChange={mapFromValue('id', fieldProps)}
                    size="lg"
                    data-bni-id="GSA:Archives"
                  />
                )}
              </Form.Field>
            </div>
            <div className="w-3/12">
              <Form.Field name={MetaKey.EXAM_TYPE}>
                {(fieldProps) => (
                  <ExamTypeSelectButton
                    includeNoExamTypeOption
                    tenantId={tenant.id}
                    {...fieldProps}
                    onChange={mapFromValue('id', fieldProps)}
                    size="lg"
                    dataKey="id"
                  />
                )}
              </Form.Field>
            </div>
            <div className="w-3/12">
              <Form.Field name={MetaKey.STATUS}>
                {(fieldProps) => (
                  <StudyStatusSelectButton
                    {...fieldProps}
                    size="lg"
                    showDraftOption={canUseDraftStudies}
                  />
                )}
              </Form.Field>
            </div>
          </Layout>
          <Layout>
            <PatientInformation />
            <StudyInformation tenant={tenant} />
          </Layout>
          <Layout className="py-4 px-12 border-t border-divider">
            <Button
              variant="secondary"
              onClick={() => {
                setFormValue(propValues);
                setShow(false);
              }}
            >
              <FormattedMessage {...actionMessages.cancel} />
            </Button>
            <Button
              variant="secondary"
              className="border-blue-40 text-primary ml-4"
              onClick={() => setFormValue({} as any)}
            >
              <FormattedMessage {...searchMessages.reset} />
            </Button>
            <Button
              variant="secondary"
              className="ml-auto mr-4"
              onClick={() => onSave(formValue)}
            >
              <FormattedMessage {...actionMessages.save} />
            </Button>
            <Form.Submit>
              <FormattedMessage {...searchMessages.apply} />
            </Form.Submit>
          </Layout>
        </Layout>
      </Modal>
    </Form>
  );
}

export default createFragmentContainer(GlobalStudySearchAdvanced, {
  tenant: graphql`
    fragment GlobalStudySearchAdvanced_tenant on TenantInterface {
      type: __typename
      id
      studyTagConnection(first: 2147483647) {
        edges {
          node {
            id
            name
          }
        }
      }
      worksheetTemplateConnection(first: 2147483647) {
        edges {
          node {
            id
            latestVersion {
              title
            }
          }
        }
      }
      ... on Organization {
        id
        name
        slug
      }
      ...ArchiveSelectButton_tenant
      ...TenantMembershipAutoComplete_tenant
    }
  `,
});
