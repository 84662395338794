import { useState } from 'react';
import { graphql, useMutation } from 'react-relay';

import { useViewerContext } from 'utils/viewerContext';

import { usePreferencesDelete_Mutation } from './__generated__/usePreferencesDelete_Mutation.graphql';
import { usePreferencesSet_Mutation } from './__generated__/usePreferencesSet_Mutation.graphql';

type Setter<TValue> = (next: TValue) => TValue;

export default function usePreferences<TValue>(
  preferenceKey: string,
  defaultValue: Setter<TValue> = (prev) => prev,
): [
  value: TValue,
  set: (next: TValue | Setter<TValue>) => void,
  loading: boolean,
  clear: () => void,
] {
  const { id: userId, userPreferences } = useViewerContext();

  const [value, setValue] = useState<TValue>(
    defaultValue
      ? defaultValue(userPreferences[preferenceKey])
      : userPreferences[preferenceKey],
  );

  const [mutateSet, loadingSet] = useMutation<usePreferencesSet_Mutation>(
    graphql`
      mutation usePreferencesSet_Mutation($input: SetUserPreferenceInput!)
      @raw_response_type {
        setUserPreference(input: $input) {
          userPreference {
            id
            value
          }
        }
      }
    `,
  );

  const [mutateDelete, loadingDelete] =
    useMutation<usePreferencesDelete_Mutation>(
      graphql`
        mutation usePreferencesDelete_Mutation(
          $input: DeleteUserPreferenceInput!
        ) @raw_response_type {
          deleteUserPreference(input: $input) {
            deletedId
          }
        }
      `,
    );

  const set = (valueOrCallback: TValue | Setter<TValue>) => {
    const nextValue =
      typeof valueOrCallback !== 'function'
        ? valueOrCallback
        : (valueOrCallback as Setter<TValue>)(value);
    mutateSet({
      variables: {
        input: { userId, preferenceKey, value: nextValue },
      },
    });
    setValue(nextValue);
  };

  const clear = () => {
    mutateDelete({
      variables: { input: { preferenceKey, userId } },
    });
    setValue(defaultValue(null as any) as any);
  };

  return [
    value || (defaultValue(value) as TValue),
    set,
    loadingSet || loadingDelete,
    clear,
  ];
}
