import Layout from '@4c/layout';
import CloseSmIcon from '@bfly/icons/CloseSm';
import Anchor from '@bfly/ui2/Anchor';
import Button from '@bfly/ui2/Button';
import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import FormControl from '@bfly/ui2/FormControl';
import Text from '@bfly/ui2/Text';
import Toast from '@bfly/ui2/Toast';
import { useToast } from '@bfly/ui2/ToastContext';
import clsx from 'clsx';
import { useState } from 'react';

import useAutoSaveState from 'hooks/useAutoSave';
import localStore from 'utils/localStore';
import podTargetingHeaders, {
  POD_TARGETING_HEADERS,
} from 'utils/podTargetingHeaders';

import { useDevContext } from './devContext';

export default function HeaderOverrides({
  accessToken,
}: {
  accessToken: string | null;
}) {
  const toast = useToast();

  const successToast = () => {
    const props = {
      key: 1234,
      message: <>All overrides saved</>,
      callToAction: (
        <Toast.Action onClick={() => window.location.reload()}>
          Refresh Page
        </Toast.Action>
      ),
    };

    toast.removeToast(props);

    toast.success(props);
  };

  const sandboxEnvLogin = () => {
    localStorage.setItem(
      'bfly:token',
      JSON.stringify({
        accessToken: 'access-token',
        expiresAt: Date.now() + 24 * 60 * 60 * 1000,
        localId: 'local-id',
      }),
    );
    window.location.reload();
  };

  const { setDevState, headerOverrideOrder } = useDevContext();

  const setHeaderFields = (fn: (keys: string[]) => string[]) => {
    setDevState((p) => ({
      ...p,
      headerOverrideOrder: fn(p.headerOverrideOrder),
    }));
  };

  const [formState, setFormState, AutoSave] = useAutoSaveState(
    {
      ...podTargetingHeaders.get(),
      subdomainLabelOverride: localStore.get('subdomainLabelOverride'),
    },
    (state) => {
      const { subdomainLabelOverride, ...nextHeaders } = state;

      localStore.set('subdomainLabelOverride', subdomainLabelOverride);

      podTargetingHeaders.set(nextHeaders);

      successToast();

      return Promise.resolve();
    },
  );

  const handleClear = () => {
    // clear all but subdomainLabelOverride
    setFormState(
      ({ subdomainLabelOverride }) => ({ subdomainLabelOverride }),
      true,
    );
    setHeaderFields(() => []);
  };

  const resetOverrides = () => {
    setFormState((p) => {
      const next = { ...p };
      Object.keys(next).forEach((header) => delete next[header]);
      setHeaderFields(() => []);
      return next;
    }, true);
    setFormState(
      (p) => ({
        ...p,
        subdomainLabelOverride: null,
      }),
      true,
    );
  };

  const [subdomainField, setSubdomainField] = useState(false);

  return (
    <>
      <Layout justify="space-between">
        <Text as="p">
          {'See '}
          <Anchor
            target="_blank"
            href="https://butterflynetwork.atlassian.net/wiki/spaces/SW/pages/2974875649/Local+Development+with+Kubernetes"
          >
            this page
          </Anchor>{' '}
          for more information on how to use this set of features.
        </Text>
        <AutoSave>
          <Button
            disabled={
              !Object.keys(formState).some((key: any) =>
                POD_TARGETING_HEADERS.includes(key),
              )
            }
            onClick={handleClear}
            variant="text-danger"
            className="h-auto px-0"
          >
            Clear
          </Button>
        </AutoSave>
      </Layout>

      <Layout direction="column" pad={3} className="-mr-2 pr-2">
        <Layout justify="space-between" pad={3}>
          <Form.Label className="m-0">Pod Targeting Headers</Form.Label>
        </Layout>
        {[...headerOverrideOrder, ''].map((header, index) => {
          const value = formState[header] || '';
          return (
            <Layout pad={4} className="items-center" key={`${header + index}`}>
              <DropdownList
                filter="contains"
                variant="secondary"
                data={POD_TARGETING_HEADERS.filter(
                  (h) =>
                    !headerOverrideOrder
                      .filter((hf) => hf !== header)
                      .includes(h),
                )}
                value={header}
                onChange={(nextHeader) => {
                  if (header) setFormState((p) => ({ ...p, [header]: null }));

                  setHeaderFields((p) => {
                    if (!header) return [...p, nextHeader];
                    const next = [...p];
                    next[index] = nextHeader;
                    return next;
                  });
                }}
                className="grow"
                menuVariant="dark"
                placeholder={{
                  id: 'developerControls.selectHeader',
                  defaultMessage: 'Select header to override',
                }}
              />
              <FormControl
                className={clsx(
                  'w-1/2 disabled',
                  !header && 'pointer-events-none',
                )}
                variant="secondary"
                value={value}
                onChange={(event) => {
                  setFormState((p) => {
                    const next = {
                      ...p,
                      [header]: (event.target as HTMLInputElement).value,
                    };
                    if (!next[header]) delete next[header];
                    return next;
                  });
                }}
              />
              <Button
                variant="text-danger"
                className={clsx(
                  'ml-0 pr-0',
                  !header && 'opacity-0 pointer-events-none',
                )}
                onClick={() => {
                  setFormState((p) => {
                    const next = { ...p };
                    delete next[header];
                    return next;
                  }, true);
                  setHeaderFields((p) => p.filter((h) => h !== header));
                }}
              >
                <CloseSmIcon className="w-4" />
              </Button>
            </Layout>
          );
        })}
      </Layout>
      {!accessToken && (
        <Layout
          pad={4}
          className="items-center mt-4 border-t border-t-grey-75 pt-4"
        >
          <Form.Label className="pt-2">Login Options</Form.Label>
          <Button
            variant="secondary"
            onClick={() => {
              setFormState(
                (p) => ({
                  ...p,
                  subdomainLabelOverride: null,
                }),
                true,
              );
              if (bflyConfig.IS_SANDBOX) {
                sandboxEnvLogin();
              } else window.location.reload();
            }}
          >
            Public Cloud
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setFormState(
                (p) => ({
                  ...p,
                  subdomainLabelOverride: 'sandbox',
                }),
                true,
              );
              if (bflyConfig.IS_SANDBOX) {
                sandboxEnvLogin();
              } else window.location.reload();
            }}
          >
            Sandbox Domain
          </Button>
          {!bflyConfig.IS_SANDBOX && (
            <Button
              variant="secondary"
              onClick={() => {
                setSubdomainField(!subdomainField);
              }}
            >
              Custom Subdomain
            </Button>
          )}
        </Layout>
      )}
      {subdomainField && (
        <Layout pad={4} className="items-center mt-4">
          <Form.Label className="m-0">Subdomain Label</Form.Label>
          <FormControl
            className="grow"
            key="subdomainLabelOverride"
            variant="secondary"
            value={formState.subdomainLabelOverride || ''}
            onChange={(event) =>
              setFormState((p) => ({
                ...p,
                subdomainLabelOverride: event.target.value,
              }))
            }
            placeholder="cloud"
          />
          <Button
            variant="text-danger"
            className={clsx(
              'ml-0 pr-0',
              !formState.subdomainLabelOverride &&
                'opacity-0 pointer-events-none',
            )}
            onClick={() => {
              setFormState(
                (p) => ({
                  ...p,
                  subdomainLabelOverride: null,
                }),
                true,
              );
            }}
          >
            <CloseSmIcon className="w-4" />
          </Button>
        </Layout>
      )}
      <Layout className="items-center mt-4">
        <Button
          variant="outline-danger"
          onClick={() => {
            resetOverrides();
          }}
        >
          Reset Overrides
        </Button>
      </Layout>
    </>
  );
}
