/**
 * @generated SignedSource<<dd13a15e4e6806a419fc652153a348a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImagePreviewRoll_study$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ImagePreviewItem_study">;
  readonly " $fragmentType": "ImagePreviewRoll_study";
};
export type ImagePreviewRoll_study$key = {
  readonly " $data"?: ImagePreviewRoll_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImagePreviewRoll_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImagePreviewRoll_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ImagePreviewItem_study"
    }
  ],
  "type": "Study",
  "abstractKey": null
};

(node as any).hash = "91497647b3745165a1dceae0736d0b5f";

export default node;
