/**
 * @generated SignedSource<<184c288c66bbb01ea9dd3ee5dcc92c89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPageWithSidePanel_archive$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_archive">;
  readonly " $fragmentType": "AppPageWithSidePanel_archive";
};
export type AppPageWithSidePanel_archive$key = {
  readonly " $data"?: AppPageWithSidePanel_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppPageWithSidePanel_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPageWithSidePanel_archive",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BaseAppPage_archive"
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "6607e1fa778ab60d636a9166907cc195";

export default node;
