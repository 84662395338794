import Navbar from '@bfly/ui2/Navbar';
import { css } from 'astroturf';
import useRouter from 'found/useRouter';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import SwipeableViews from 'react-swipeable-views';

import StudyImage from 'components/StudyImage';
import useElementsNavigation from 'hooks/useElementsNavigation';
import { useArchiveRoutes } from 'routes/archive';

import MeetIqAlert from './MeetIqAlert';
import MobileOverlay from './MobileOverlay';
import MobileSharedStudyImage from './MobileSharedStudyImage';
import type { MobileSharedImageDetail_images$data as Images } from './__generated__/MobileSharedImageDetail_images.graphql';

interface Props {
  images: Images;
  hideClose?: boolean;
}

function MobileSharedImageDetail({ images, hideClose }: Props) {
  const {
    router,
    match: { location, params },
  } = useRouter();
  const archiveRoutes = useArchiveRoutes();

  const handleClose = useCallback(() => {
    if (location.index) {
      router.go(-1);
    } else {
      router.push(
        archiveRoutes.sharedBundle({
          sharedBundleHandle: params.sharedBundleHandle,
        } as any),
      );
    }
  }, [archiveRoutes, location.index, params.sharedBundleHandle, router]);

  const [activeImage, { activeIndex, setActiveIndex }] = useElementsNavigation(
    images,
    {
      activeHandle: params.sharedBundleImageHandle || images[0].handle!,
      to: (handle) => ({
        ...location,
        pathname: archiveRoutes.sharedBundleImage({
          sharedBundleHandle: params.sharedBundleHandle,
          sharedBundleImageHandle: handle,
        }),
      }),
    },
  );

  if (!activeImage) {
    return null;
  }

  return (
    <MobileOverlay>
      {hideClose ? (
        <MobileOverlay.Header>
          <Navbar.Brand />
        </MobileOverlay.Header>
      ) : (
        <MobileOverlay.HeaderWithClose onClose={handleClose}>
          <FormattedMessage
            id="mobileSharedImageDetail.title"
            defaultMessage="{activeIndexDisplay} of {numImages}"
            values={{
              activeIndexDisplay: activeIndex + 1,
              numImages: images.length,
            }}
          />
        </MobileOverlay.HeaderWithClose>
      )}
      <SwipeableViews
        index={activeIndex}
        onChangeIndex={setActiveIndex}
        css={css`
          &,
          & :global(.react-swipeable-view-container) {
            /* During rerenders the slide doesn't stretch full width, making the 3d toggle look weird for a ms. */
            display: flex;
            flex: 1;
          }
        `}
      >
        {images.map((image, index) =>
          index === activeIndex ? (
            <MobileSharedStudyImage
              key={image.id}
              image={image}
              index={index}
            />
          ) : (
            <StudyImage
              key={image.id}
              image={image}
              index={index}
              autoPlay={false}
              fullscreenEnabled={false}
            />
          ),
        )}
      </SwipeableViews>
      <MeetIqAlert mobile />
    </MobileOverlay>
  );
}

export default createFragmentContainer(MobileSharedImageDetail, {
  images: graphql`
    fragment MobileSharedImageDetail_images on SharedBundleImage
    @relay(plural: true) {
      id
      handle
      ...StudyImage_image
      ...MobileSharedStudyImage_image
    }
  `,
});
