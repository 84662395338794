/**
 * @generated SignedSource<<4555dde75f9af8e820d9e65c12ca241d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriptionExpiredBanner_organization$data = {
  readonly slug: string | null;
  readonly subscription: {
    readonly canAccessProFeatures: boolean | null;
    readonly hasCompletedTrial: boolean | null;
    readonly hasStartedActiveSubscription: boolean | null;
    readonly isInGracePeriod: boolean | null;
  } | null;
  readonly " $fragmentType": "SubscriptionExpiredBanner_organization";
};
export type SubscriptionExpiredBanner_organization$key = {
  readonly " $data"?: SubscriptionExpiredBanner_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionExpiredBanner_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriptionExpiredBanner_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasCompletedTrial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasStartedActiveSubscription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canAccessProFeatures",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isInGracePeriod",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "f3b1a2b04931c732f6ac01fda57ce37a";

export default node;
