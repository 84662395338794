import { stylesheet } from 'astroturf';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import FieldDefinitionFormFieldGroup from './FieldDefinitionFormFieldGroup';
import { WorksheetSectionFieldSet_section$data as Section } from './__generated__/WorksheetSectionFieldSet_section.graphql';

const styles = stylesheet`
  .fieldset {
    margin-top: 1.5rem;
  }
`;

interface Props {
  section: Section;
}

function WorksheetSectionFieldSet({ section }: Props) {
  return (
    <fieldset className={styles.fieldset}>
      <legend className="sr-only">{section.title}</legend>
      {section.fieldDefinitions!.map((fieldDefinition) => (
        <FieldDefinitionFormFieldGroup
          key={fieldDefinition!.handle!}
          fieldDefinition={fieldDefinition!}
        />
      ))}
    </fieldset>
  );
}

export default createFragmentContainer(WorksheetSectionFieldSet, {
  section: graphql`
    fragment WorksheetSectionFieldSet_section on WorksheetSectionDefinition {
      title
      fieldDefinitions {
        handle
        ...FieldDefinitionFormFieldGroup_fieldDefinition
      }
    }
  `,
});
