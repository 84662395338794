import LockIcon from '@bfly/icons/Lock';
import Carousel from '@bfly/ui2/Carousel';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import { RoutePageProps } from 'components/Route';
import Thumbnail from 'components/Thumbnail';
import { useEducationRoutes } from 'routes/education';
import Analytics from 'utils/Analytics';

import { isInApp } from '../../../utils/browserInfo';
import EducationContentInfo from './EducationContentInfo';
import EducationContentPlayer from './EducationContentPlayer';
import EducationPageHeader from './EducationPageHeader';
import { Grid, MainSection, SecondarySection } from './GridSection';
import LockedContentHero from './LockedContentHero';
import NextContentPopout from './NextContentPopout';
import type { EducationContentPage_educationContent$data as EducationContent } from './__generated__/EducationContentPage_educationContent.graphql';
import type { EducationContentPage_viewer$data as Viewer } from './__generated__/EducationContentPage_viewer.graphql';

const styles = stylesheet`
  .carousel-container {
    @screen lg-max {
      /* Limit max video height to 480px for narrow desktop views, though. */
      max-height: 480px;
      height: 100vw;

      @media (orientation: landscape) and (max-height: 680px) {
        /* In mobile landscape views, don't make the video too tall. */
        min-height: 240px;
        max-height: calc(100vh - 200px);
      }
    }
  }
`;

interface Props extends RoutePageProps {
  educationContent: EducationContent;
  viewer: Viewer;
}

function getSource(query) {
  if (query && query.utm_source) {
    return query.utm_source;
  }

  if (isInApp()) {
    return 'app_webview';
  }

  return null;
}

function EducationContentPage({
  educationContent,
  viewer,
  match,
  router,
}: Props) {
  const hasPremiumVideoAccess = useVariation('edu-premium-video-access');
  const isVideoLocked = !hasPremiumVideoAccess && educationContent.isPremium;

  const educationRoutes = useEducationRoutes();
  const { location } = match;

  const [showPopout, setShowPopout] = useState(false);

  const setActiveContentHandle = useCallback(
    (handle) => {
      router.replace(educationRoutes.educationContent({ handle }));
    },
    [educationRoutes, router],
  );

  const handleNext = useCallback(() => {
    setActiveContentHandle(educationContent!.nextRecord!.handle);
  }, [setActiveContentHandle, educationContent]);

  const handlePrev = useCallback(() => {
    setActiveContentHandle(educationContent!.prevRecord!.handle);
  }, [setActiveContentHandle, educationContent]);

  useEffect(() => {
    if (educationContent) {
      Analytics.track('playEducationVideo', {
        id: educationContent.localId!,
        source: getSource(location.query),
      });
    }
  }, [educationContent, location.query]);

  return (
    <>
      <EducationPageHeader title={educationContent.name!} />

      <Grid className="h-auto md:h-full">
        <MainSection
          className={clsx(
            styles.carouselContainer,
            'relative w-full lg:h-full bg-black overflow-hidden',
          )}
        >
          {!educationContent.viewerHasAccess || isVideoLocked ? (
            <LockedContentHero viewer={viewer} />
          ) : (
            <Carousel
              isFirst={!educationContent.nextRecord}
              isLast={!educationContent.nextRecord}
              onNext={handleNext}
              onPrev={handlePrev}
              enableControlFocus
              className="flex-1 mx-3"
            >
              <EducationContentPlayer
                educationContent={educationContent}
                onEnded={() => setShowPopout(true)}
              />

              {educationContent.nextRecord && showPopout && (
                <NextContentPopout
                  educationContent={educationContent.nextRecord}
                  onClose={() => setShowPopout(false)}
                />
              )}
            </Carousel>
          )}
        </MainSection>

        <SecondarySection className="py-10 px-12 relative" pad>
          {!educationContent.viewerHasAccess ||
            (isVideoLocked && (
              <div className="relative w-80 h-80 mb-5 overflow-hidden">
                <LockIcon />
                <Thumbnail
                  image={educationContent}
                  className="opacity-54 max-w-full h-auto"
                />
              </div>
            ))}

          <EducationContentInfo educationContent={educationContent} />
        </SecondarySection>
      </Grid>
    </>
  );
}

export default createFragmentContainer(EducationContentPage, {
  educationContent: graphql`
    fragment EducationContentPage_educationContent on EducationContent {
      localId
      name
      isPremium
      prevRecord {
        handle
      }
      nextRecord {
        handle
        ...NextContentPopout_educationContent
      }
      viewerHasAccess
      ...Thumbnail_image
      ...EducationContentPlayer_educationContent
      ...EducationContentInfo_educationContent
    }
  `,

  viewer: graphql`
    fragment EducationContentPage_viewer on Viewer {
      ...LockedContentHero_viewer
    }
  `,
});
