import Layout from '@4c/layout';
import DragHandlesIcon from '@bfly/icons/DragHandles';
import FormCheck from '@bfly/ui2/FormCheck';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import ListItem from '@bfly/ui2/ListItem';
import Tooltip from '@bfly/ui2/Tooltip';
import useResponsive from '@bfly/ui2/useResponsive';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import Link from 'found/Link'; // eslint-disable-line no-restricted-imports
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useMultiSelectable } from 'components/MultiSelectContext';
import StudySubtitle from 'components/StudySubtitle';
import StudySyncStatus from 'components/StudySyncStatus';
import StudyTitle from 'components/StudyTitle';
import useExperiences, { Experience } from 'hooks/useExperiences';
import { useExamRoutes } from 'routes/exam';
import { useStudyListNavConfig } from 'utils/StudyListNavUtils';
import { useGetSelectedStudyItem } from 'utils/StudySelection';

import StudyArchive from './StudyArchive';
import StudyAttribution from './StudyAttribution';
import StudyControls from './StudyControls';
import StudyHistoryDropdownItem from './StudyHistoryDropdownItem';
import StudyImagePreviewList from './StudyImagePreviewList';
import type { StudyListItem_study$data as Study } from './__generated__/StudyListItem_study.graphql';
import { StudyListItem_studyImages$data as StudyImages } from './__generated__/StudyListItem_studyImages.graphql';

const styles = stylesheet`
  .container {
    position: relative;
    padding-left: 28px; // checkbox size
  }

  .fade-in {
    visibility: hidden;
    opacity: 0;
    transition: opacity 100ms linear;

    &.visible,
    .container:hover & {
      // Setting visibility instead of just opacity makes the drag handles fade
      // in but not out. This is intentional.
      visibility: visible;
      opacity: 1;
    }

    @media (pointer: coarse) {
      visibility: visible;
    }
  }

  .checkbox {
    composes: fade-in;

    top: 0;
    left: 0;
    position: absolute;
    visibility: hidden;
  }

  .item {
    position: relative;
    width: 100%;

    &[draggable='true'] * {
      -webkit-user-drag: none;
    }
  }

  .draft {
    // Avoid the border-radius being coerced into a smaller radius.
    $visual-width: 3px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background: linear-gradient(
        to right,
        theme('colors.draft') $visual-width,
        transparent $visual-width
      );
      width: theme('borderRadius.lg');
      height: 100%;
      border-top-left-radius: theme('borderRadius.lg');
      border-bottom-left-radius: theme('borderRadius.lg');
    }

    &.pending-finalization {
      &::before {
        background: linear-gradient(
          to right,
          theme('colors.red[40]') $visual-width,
          transparent $visual-width
        );
      }
    }
  }

  .selected {
    background-color: theme('colors.grey[80]');
  }

  .link {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .drag-handle {
    composes: fade-in;

    position: absolute;
    left: 1%;
    width: 8px;
    margin-top: 0.4rem;
    color: theme('colors.white');
    cursor: grab;
  }

  .body {
    position: relative;
    pointer-events: none;

    & a,
    & button,
    & select,
    & input,
    // For other elements that still need to be focusable and hoverable, add tabindex 1.
    & [tabindex],
    & :global(.dropdown) {
      pointer-events: all;
    }
  }

  .ready-at {
    padding: 0 1rem;
    // Safari does unneeded odd wrapping in some cases
    white-space: nowrap;
  }

  .controls {
    height: theme('spacing.6');
    width: theme('spacing.6');
    background-color: theme('colors.grey[80]');
    border-radius: theme('spacing.6');
    padding: 0;
  }
`;

interface DragPreviewDetails {
  studyId: string;
  studyTitle: React.ReactNode;
}

interface Props {
  study: Study;
  studyImages: StudyImages | null;
  index: number;
  cursor?: string;
  allowSelect: boolean;
  draggable: boolean;
  onDragStart(e: React.DragEvent, info: DragPreviewDetails): void;
  onDragEnd(e: React.DragEvent): void;

  /**
   * Manually enable the checkbox visibility, normally visible only on hover.
   */
  isSelectionVisible?: boolean;
}

function StudyListItem({
  study,
  studyImages,
  index,
  cursor,
  allowSelect,
  draggable,
  onDragStart,
  onDragEnd,
  isSelectionVisible,
}: Props) {
  const studyListNavConfig = useStudyListNavConfig();
  const examRoutes = useExamRoutes();
  const isSmall = useResponsive('sm', 'down');
  const isXSmall = useResponsive('xs', 'down');
  const { selected, onChange } = useMultiSelectable(study.id);
  const getSelectedStudyItem = useGetSelectedStudyItem();

  const { markCompleted, targetRef } = useExperiences(
    Experience.VIEW_STUDY_DETAIL,
    {
      placement: 'bottom-start',
      header: (
        <FormattedMessage
          id="tutorial.study_detail.title"
          defaultMessage="Review your study in detail"
        />
      ),
      message: (
        <FormattedMessage
          id="tutorial.study_detail.sub"
          defaultMessage="Click on an image to enter the Detail View. You can comment and review your images there"
        />
      ),
    },
  );

  const isDraft = !study.finalizedAt;

  const capturedAt = (
    <FormattedMessage
      id="studyListItem.capturedAt"
      defaultMessage="Captured {value}"
      values={{ value: <FormattedDateTime value={study.capturedAt!} /> }}
    />
  );

  const studyDraggableIcon = (
    <DragHandlesIcon
      data-bni-id="StudyDraggableIcon"
      className={clsx(styles.dragHandle, isSelectionVisible && styles.visible)}
    />
  );

  return (
    <Layout
      align="flex-start"
      className={styles.container}
      data-bni-id="StudyListItem"
    >
      {allowSelect && (
        <Tooltip.Trigger
          id={`study-select-${study.id}`}
          placement="top"
          tooltip={
            <FormattedMessage
              id="tooltip.checkbox.text"
              defaultMessage="Select to move or delete"
            />
          }
        >
          <label // eslint-disable-line jsx-a11y/label-has-associated-control
            className={clsx(
              styles.checkbox,
              isSelectionVisible && styles.visible,
            )}
          >
            <FormCheck.Input
              type="checkbox"
              data-bni-id="StudyItemCheckbox"
              checked={selected}
              onChange={(e) => {
                onChange(e.currentTarget.checked, getSelectedStudyItem(study));
              }}
            />
          </label>
        </Tooltip.Trigger>
      )}
      <ListItem
        draggable={draggable}
        onDragStart={(e) => {
          onDragStart!(e, {
            studyId: study.id,
            studyTitle: <StudyTitle study={study} />,
          });
        }}
        onDragEnd={onDragEnd}
        className={clsx(
          styles.item,
          selected && styles.selected,
          study.isPendingFinalization && styles.pendingFinalization,
          isDraft && styles.draft,
        )}
      >
        <Link
          to={{
            pathname: examRoutes.exam({
              organizationSlug: study.organization!.slug!,
              studyHandle: study.handle!,
            }),
            state: {
              studyListNavConfig,
              cursor,
            },
          }}
          tabIndex={-1}
          className={styles.link}
          onClick={markCompleted}
          draggable={false}
        />
        {draggable &&
          (study.finalizedAt ? (
            studyDraggableIcon
          ) : (
            <Tooltip.Trigger
              id={`drag-handle-${study.id}`}
              tooltip={
                <FormattedMessage
                  id="tooltip.handle.text"
                  defaultMessage="Hold and drag to desired archive"
                />
              }
              placement="top"
            >
              {studyDraggableIcon}
            </Tooltip.Trigger>
          ))}
        <div className={styles.body}>
          <ListItem.Header>
            <Layout grow align="baseline" justify="space-between">
              <ListItem.Title size="large" data-bni-id="StudyTitle">
                <StudyTitle study={study} />
              </ListItem.Title>
              {!isXSmall && (
                <span className={styles.readyAt}>{capturedAt}</span>
              )}
            </Layout>
            {/* 
              Currently all controls are hidden when the archive is deleted, but 
              there is no good way of knowing that from here.  
            */}
            {!study.archive!.deletedAt && (
              <ListItem.Controls>
                <StudyControls
                  study={study}
                  className={styles.controls}
                  data-bni-id="StudyControls"
                >
                  {!study.deletedAt && (
                    <StudyHistoryDropdownItem study={study} />
                  )}
                </StudyControls>
              </ListItem.Controls>
            )}
          </ListItem.Header>
          {!isSmall && <StudySubtitle study={study} />}
          <div className="mt-4" ref={index === 0 ? targetRef : null}>
            {study.numImages ? (
              <StudyImagePreviewList
                study={study}
                studyCursor={cursor}
                studyImages={studyImages}
                studyListNavConfig={studyListNavConfig!}
              />
            ) : (
              <FormattedMessage
                tagName="em"
                id="study.images.empty"
                defaultMessage="This study is empty"
              />
            )}
            {/* // markCompleted is in StudyImagePreviewList. */}
          </div>

          <Layout align="center" justify="space-between" className="mt-4">
            <Layout pad={4} align="center">
              <StudyAttribution study={study} />
              {study.archiveAttribution && (
                <StudyArchive
                  linkToArchive
                  archive={study.archiveAttribution}
                />
              )}
            </Layout>
            <Layout pad={4} align="center">
              <StudySyncStatus study={study} />
            </Layout>
          </Layout>
        </div>
      </ListItem>
    </Layout>
  );
}

export default createFragmentContainer(StudyListItem, {
  study: graphql`
    fragment StudyListItem_study on Study
    @argumentDefinitions(
      showArchive: { type: "Boolean", defaultValue: false }
    ) {
      id
      organization {
        slug
      }
      capturedAt
      finalizedAt
      handle
      readyAt
      deletedAt
      archive {
        id
        handle
        deletedAt
        organization {
          slug
        }
      }
      createdBy {
        id
      }
      practiceType
      patient {
        nameFirst
        nameLast
        nameMiddle
        namePrefix
        nameSuffix
      }
      vetPatient {
        clientNameLast
        clientOrganizationName
        name
      }
      archiveAttribution: archive @include(if: $showArchive) {
        ...StudyArchive_archive
      }
      isPendingFinalization
      numImages
      viewerCanDelete
      viewerCanMove
      examTypes {
        id
      }
      ...StudyTitle_study
      ...StudySubtitle_study
      ...StudyControls_study
      ...StudyImagePreviewList_study
      ...StudyAttribution_study
      ...StudySyncStatus_study
      ...StudyHistoryDropdownItem_study
    }
  `,
  studyImages: graphql`
    fragment StudyListItem_studyImages on StudyImage @relay(plural: true) {
      ...StudyImagePreviewList_studyImages
    }
  `,
});
