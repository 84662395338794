import Button from '@bfly/ui2/Button';
import Link from '@bfly/ui2/Link';
import useRouter from 'found/useRouter';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { object, string } from 'yup';

import { useAuth } from 'components/AuthContext';
import AuthForm from 'components/AuthForm';
import MultiTextInputGroup from 'components/MultiTextInputGroup';
import accountMessages from 'messages/account';
import sharedErrorMessages from 'messages/error';
import { useAccountRoutes } from 'routes/account';
import submitLogin from 'utils/login';
import regionApiUrls from 'utils/regionApiUrls';

import { AuthType } from '../../utils/auditLogs';
import getGraphqlUpstreamKey from '../utils/regionKeys';
import { Props as RegionSelectProps } from './UserRegionSelectForm';

const messages = defineMessages({
  password: {
    id: 'login.password',
    defaultMessage: 'Password',
  },
  missingEmail: {
    id: 'login.missingEmail',
    defaultMessage: 'Please enter your email',
  },
  missingPassword: {
    id: 'login.missingPassword',
    defaultMessage: 'Please enter your password',
  },
});

const errorMessages = defineMessages({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  invalid_grant: {
    id: 'login.error.invalid_grant',
    defaultMessage:
      'This email/password combination cannot be found in our system',
  },
});

const loginSchema = object({
  email: string()
    .email()
    .required(messages.missingEmail as unknown as string)
    .default(''),
  password: string()
    .required(messages.missingPassword as unknown as string)
    .default(''),
});

interface Props {
  domainHandle?: string;
  email?: string | null;
  loginMessage?: React.ReactNode;
  children?: React.ReactNode;
  setRegionSelect?: (value: RegionSelectProps) => void;
}

function ButterflyLoginForm({
  domainHandle,
  email: propsEmail,
  loginMessage,
  children,
  setRegionSelect,
}: Props) {
  const accountRoutes = useAccountRoutes();
  const auth = useAuth();
  const { match } = useRouter();
  const [errors, setErrors] = useState<Record<string, any>>({});

  const handleSubmit = useCallback(
    async ({ email, password }) => {
      try {
        const accessToken = await submitLogin({
          email,
          password,
          domainHandle: domainHandle || null,
        });

        if (setRegionSelect) {
          const apiUrlsByRegion = (await regionApiUrls.forUser(email)) || {};

          const apiUrlKeys =
            (apiUrlsByRegion && Object.keys(apiUrlsByRegion)) || [];

          if (apiUrlKeys.length > 1) {
            setRegionSelect({ apiUrlsByRegion, accessToken });
            return;
          }

          // User may just be a member of an EU public cloud org
          if (apiUrlKeys.length === 1) {
            const KEY = getGraphqlUpstreamKey();
            const apiUrlUpstream = apiUrlsByRegion[apiUrlKeys[0]];
            sessionStorage.setItem(KEY, apiUrlUpstream);
            globalThis.bflyConfig.GRAPHQL_UPSTREAM = apiUrlUpstream;
          }
        }

        await auth.setAccessToken(accessToken, {
          trackLogin: true,
          authType: AuthType.AUTH0,
        });
      } catch (e: any) {
        const errorMessage = errorMessages[e.code];
        setErrors({
          '': errorMessage || sharedErrorMessages.request,
        });

        if (!errorMessage) {
          throw e;
        }
      }
    },
    [auth, domainHandle, setRegionSelect],
  );

  const queryEmail = match.location.query.user_email;

  const defaultValue = useMemo(() => {
    const defaultValueWithEmail = loginSchema.getDefault();

    // TODO: Remove support for getting email from query.
    const defaultEmail = propsEmail || queryEmail;
    if (defaultEmail) {
      defaultValueWithEmail.email = defaultEmail;
    }

    return defaultValueWithEmail;
  }, [propsEmail, queryEmail]);

  return (
    <>
      <AuthForm<typeof loginSchema>
        schema={loginSchema}
        submitForm={handleSubmit}
        defaultValue={defaultValue}
        errors={errors}
        onError={setErrors}
      >
        <MultiTextInputGroup className="mb-3">
          <AuthForm.FieldGroup
            name="email"
            type="email"
            data-bni-id="emailField"
            validateOn="blur"
            labelSrOnly
            autoCapitalize="off"
            label={accountMessages.email}
            placeholder={accountMessages.email}
          />
          <AuthForm.FieldGroup
            name="password"
            type="password"
            data-bni-id="passwordField"
            labelSrOnly
            label={messages.password}
            placeholder={messages.password}
            validateOn="blur"
          />
        </MultiTextInputGroup>
        <AuthForm.Submit data-bni-id="loginButton">
          {loginMessage || <FormattedMessage {...accountMessages.logIn} />}
        </AuthForm.Submit>
      </AuthForm>
      {children}
      <Link
        as={Button}
        variant="text-primary"
        to={{
          pathname: accountRoutes.forgotPassword(),
          state: {
            email: propsEmail,
            prevLocation: match.location,
          },
        }}
      >
        <FormattedMessage
          id="login.forgotPassword"
          defaultMessage="Trouble Logging In?"
        />
      </Link>
    </>
  );
}

export default ButterflyLoginForm;
