/**
 * @generated SignedSource<<67a453b8d1c0c6f1a25e3b145ddb2463>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamArchive_study$data = {
  readonly archive: {
    readonly ehrAssociations: ReadonlyArray<{
      readonly id: string;
    } | null> | null;
    readonly id: string;
    readonly label: string | null;
    readonly pacsAssociations: ReadonlyArray<{
      readonly id: string;
    } | null> | null;
  } | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ArchiveDropdown_organization">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyPermissions_canMoveStudy">;
  readonly " $fragmentType": "ExamArchive_study";
};
export type ExamArchive_study$key = {
  readonly " $data"?: ExamArchive_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamArchive_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamArchive_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArchiveDropdown_organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archive",
      "kind": "LinkedField",
      "name": "archive",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PacsArchiveAssociation",
          "kind": "LinkedField",
          "name": "pacsAssociations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EhrArchiveAssociation",
          "kind": "LinkedField",
          "name": "ehrAssociations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "StudyPermissions_canMoveStudy",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanMove",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizedAt",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "StudyPermissions_studyOrArchiveIsDeleted",
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Archive",
              "kind": "LinkedField",
              "name": "archive",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Study",
  "abstractKey": null
};
})();

(node as any).hash = "b2e0ef55311bc9850eca0a8ce0dd8f7b";

export default node;
